import { types } from "mobx-state-tree";
import { getSprintDaysRate } from "../../utils/getSprintDaysRate";

export const RufreeRoleModel = types
  .model("RufreeRole", {
    role: types.string,
    wage: types.number,
    sprints: types.array(types.boolean)
  })
  .views(self => ({
    get totalSprintDays() {
      return self.sprints.reduce((sum, sprint) => (sprint ? sum + 1 : sum), 0);
    },
    get sprintsToNumber() {
      const sprints: number[] = [];

      self.sprints.forEach((s, index) => {
        if(!!s) sprints.push(index);
      });

      return sprints;
    }
  }))
  .views(self => ({
    get wageWithTax() {
      return Math.floor(self.wage * 1.1);
    },
    get totalWage() {
      return self.totalSprintDays * self.wage;
    }
  }))
  .views(self => ({
    wageWithTaxBySprintDaysRate({
      sprintIndex,
      sprintDays
    }: {
      sprintIndex: number;
      sprintDays: number;
    }) {
      return getSprintDaysRate({
        sprintIndex,
        sprintDays,
        originalPrice: self.wageWithTax
      });
    }
  }));

type RufreeRoleType = typeof RufreeRoleModel.Type;
export interface RufreeRole extends RufreeRoleType {}
