import { IMSTArray, ISimpleType } from "mobx-state-tree";

export function toggleArrayItem(
  array: IMSTArray<ISimpleType<string>>,
  key: string
) {
  const hasItem = !!array.find(x => x === key);

  if (hasItem) {
    array.remove(key);
  } else {
    array.push(key);
  }
}

export function putItemForArray<T>({
  array,
  item,
  key
}: {
  array: IMSTArray<any>;
  item: T;
  key: string | number;
}) {
  const existsIndex = array.findIndex(x => x[key] === key);

  if (existsIndex) {
    array.splice(existsIndex, 1, item);
  } else {
    array.push(item);
  }
}

export function putRufreeMatching({
  array,
  item,
  key
}: {
  array: IMSTArray<any>;
  item: any;
  key: string | number;
}) {

  let existsIndex = false;
  array.map((el) => {
    el[key] == item[key] ? existsIndex = true : existsIndex = existsIndex 
  })  
  if (existsIndex) {
    array.splice(existsIndex, 1, item);
  } else {
    array.push(item);
  }
}
