import React from "react";

import { Sprint } from './Sprint';

import { ResourceWapper as Wrapper } from './_wrapper';

export interface IResourceProps {
  role: string;
  sprints: number[];
}

export const Resource: React.FC<IResourceProps> = (props) => {
  return (
    <Wrapper>
      <h5>{ props.role }</h5>

      <div>
      {
        props.sprints.map(sprint =>
          <Sprint
            key={sprint}
            title={ sprint === 0 ? 'PRE' : `S${sprint}`} />
        )
      }
      </div>
    </Wrapper>
  )
}
