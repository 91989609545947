import { types, flow } from "mobx-state-tree";
import { ClientRoleFormModel, ClientRoleForm } from "./ClientRoleForm";
import { InspectionFileResponse, InspectionResponse } from "../../types/inspection";
import { RufreeRoleFormModel, RufreeRoleForm } from "./RufreeRoleForm";
import { SeesoServiceFormModel, SeesoServiceForm } from "./SeesoServiceForm";
import axios from "axios";
import moment from "moment";
import { createDefaultSprints, createDefaultServiceSprints, SprintCheckModel } from "./SprintCheck";
import { MAX_SPRINTS } from "../../constants/constants";
import {
  ReceptionSelectionFormModel,
  ReceptionSelectionForm
} from "./ProjectGroupForm/ReceptionSelectionForm";
import { ReferenceLinkModel, ReferenceLink, InspectionFileModel, InspectionFile } from "./ReferenceLinkModel";
import { InspectionChangeReasonResponse } from "../../types/changeReason";
import { ConsultSelectionForm, ConsultSelectionFormModel } from "./ProjectGroupForm/ConsultSelectionForm";
import { IInspectionTemplate } from "../models/InspectionTemplate";

export const SprintDayModel = types
  .model("SprintDay", {
    value: types.optional(types.number, 14)
  })
  .actions(self => ({
    setValue(value: number) {
      self.value = value;
    }
  }));
type SprintDayType = typeof SprintDayModel.Type;
export interface SprintDay extends SprintDayType {}
  

interface ValidSprintDay {
  dayLength: number;
  isValid: boolean; // 이 스프린트에 체크된 클라이언트/알유프리/시소서비스 가 있는지.
}

export const InspectionForm = types
  .model("InspectionForm", {
    inspectionId: types.optional(types.string, ""),
    client: types.optional(types.string, ""),

    sprintDays: types.array(SprintDayModel),
    clientRoles: types.array(ClientRoleFormModel),
    rufreeRoles: types.array(RufreeRoleFormModel),
    seesoServices: types.array(SeesoServiceFormModel),

    title: types.optional(types.string, ""),
    requesterName: types.optional(types.string, ""),
    requesterEmail: types.optional(types.string, ""),

    inspector: types.maybeNull(types.number),
    // inspectorName: types.optional(types.string, ""), // Readonly 필드로 변경됨
    // inspectorEmail: types.optional(types.string, ""), // Readonly 필드로 변경됨
    vatIncluded: types.maybeNull(types.boolean),

    references: types.array(ReferenceLinkModel),
    referenceLinks: types.array(types.string), // Deprecated
    inspection_files: types.array(InspectionFileModel),
    dateStart: types.optional(types.string, new Date().toISOString()),
    review: types.optional(types.string, ""),
    publishedDate: types.maybe(types.string),
    attention: types.optional(types.string, ""),
    receptions: types.array(ReceptionSelectionFormModel),
    consults: types.array(ConsultSelectionFormModel),
    changeReason: types.optional(types.string, "")
  })
  .views(self => ({
    rufreeNumInSprint(sprintIndex: number) {
      let sum = 0;

      self.rufreeRoles.forEach(rufreeRole => {
        if (rufreeRole.sprints[sprintIndex].value) {
          sum++;
        }
      });

      return sum;
    }
  }))
  .views(self => ({
    get expectedEndDate() {
      // check 가 한개라도 있는 sprint 만 필터링.
      const validSprintDays: ValidSprintDay[] = self.sprintDays.map(
        sprintDay => ({
          dayLength: sprintDay.value,
          isValid: false
        })
      );

      self.clientRoles.forEach(clientRole => {
        clientRole.sprints.forEach((sprint, index) => {
          validSprintDays[index].isValid =
            validSprintDays[index].isValid || sprint.value;
        });
      });

      self.rufreeRoles.forEach(rufreeRole => {
        rufreeRole.sprints.forEach((sprint, index) => {
          validSprintDays[index].isValid =
            validSprintDays[index].isValid || sprint.value;
        });
      });

      self.seesoServices.forEach(seesoService => {
        seesoService.sprints.forEach((sprint, index) => {
          validSprintDays[index].isValid =
            validSprintDays[index].isValid || sprint.value;
        });
      });

      const expectedDays = validSprintDays
        .filter(x => x.isValid)
        .reduce((sum, sprintDay) => sum + sprintDay.dayLength, 0);

      return moment(self.dateStart)
        .add(expectedDays, "days")
        .toDate();
    }
  }))
  .views(self => ({
    get totalCost() {
      let cost = 0;

      // 각 RufreeRole 에 대하여, 자신이 포함되어있는 스프린트 만큼 전체 금액 축적
      // (해당 스프린트의 기간에 대한 비율을 곱하여 비용 산정)
      self.rufreeRoles.forEach(rufreeRole => {
        cost += rufreeRole.totalWage;
      });

      // 각 SeesoService에 대하여, 자신이 포함되어 있는 스프린트 만큼 전체 금액에 축적
      // (해당 스프린트의 기간에 대한 비율을 곱하여 가격 산정)
      // 이때 SeesoService가 individual (동일한 스프린트에 투입된 RufreeRole 수만큼 배율) 인지 체크
      self.seesoServices.forEach(seesoService => {
        cost += seesoService.totalCost;
      });

      return cost;
    },
    getTotalCostOfOneSprint(sprintIndex: number) {
      let rufreeNumberInThatSprint = 0;
      let totalCost = 0;

      self.rufreeRoles.forEach(rufreeRole => {
        const isIncludedInThatSprint = rufreeRole.sprints[sprintIndex].value;

        if (isIncludedInThatSprint) {
          const sprintDays = self.sprintDays[sprintIndex].value;
          totalCost += rufreeRole.wageBySprintDaysRate({
            sprintIndex,
            sprintDays
          });

          rufreeNumberInThatSprint += 1;
        }
      });

      self.seesoServices.forEach(seesoService => {
        const isIncludedInThatSprint = seesoService.sprints[sprintIndex].value;

        if (isIncludedInThatSprint) {
          const sprintDays = self.sprintDays[sprintIndex].value;
          const costBySprintDaysRate = seesoService.costBySprintDaysRate({
            sprintIndex,
            sprintDays
          });

          // 해당 스프린트에 투입된 알유프리만큼 금액을 곱하는 기능
          if (seesoService.individual) {
            totalCost += costBySprintDaysRate * rufreeNumberInThatSprint;
          } else {
            totalCost += costBySprintDaysRate;
          }
        }
      });

      return totalCost;
    }
  }))
  .actions(self => ({

    setVatIncluded(value: boolean) {
      self.vatIncluded = value;
    },
    setClient(value: string) {
      self.client = value;
    },
    setTitle(value: string) {
      self.title = value;
    },
    setRequesterName(value: string) {
      self.requesterName = value;
    },
    setRequesterEmail(value: string) {
      self.requesterEmail = value;
    },

    setInspector(value: number | null) {
      self.inspector = value;
    },

    setDateStart(value: string) {
      self.dateStart = value;
    },
    setReview(value: string) {
      self.review = value;
    },
    setPublishedDate(value: string) {
      self.publishedDate = value;
    },
    setAttention(value: string) {
      self.attention = value;
    },
    setChangeReason(value: string) {
      self.changeReason = value;
    },

    addClientRole() {
      self.clientRoles.push(
        ClientRoleFormModel.create({
          sprints: createDefaultSprints()
        })
      );
    },
    addRufreeRole() {
      self.rufreeRoles.push(
        RufreeRoleFormModel.create({
          sprints: createDefaultSprints()
        })
      );
    },
    addSeesoService() {
      self.seesoServices.push(
        SeesoServiceFormModel.create({
          sprints: createDefaultServiceSprints()
        })
      );
    },
    addReferenceLink() {
      self.references.push(ReferenceLinkModel.create());
    },
    addReceptionSelection() {
      self.receptions.push(ReceptionSelectionFormModel.create());
    },
    addConsultSelection() {
      self.consults.push(ConsultSelectionFormModel.create());
    },
    removeConsultSelection(consultSelection: ConsultSelectionForm) {
      self.consults.remove(consultSelection);
    },
    removeReferenceLink(target: ReferenceLink) {
      self.references.remove(target);
    },
    removeInspectionFile(target: InspectionFile) {
      self.inspection_files.remove(target);
    },
    removeClientRole(target: ClientRoleForm) {
      self.clientRoles.remove(target);
    },
    removeRufreeRole(target: RufreeRoleForm) {
      self.rufreeRoles.remove(target);
    },
    removeSeesoService(target: SeesoServiceForm) {
      self.seesoServices.remove(target);
    },
    removeReceptionSelection(target: ReceptionSelectionForm) {
      self.receptions.remove(target);
    },
    setSprintDays(sprintDaysList: number[]) {
      self.sprintDays.replace(sprintDaysList.map(item => SprintDayModel.create({ value: item })));
    },
    setClientRoles(clientRoles: ClientRoleForm[]) {
      self.clientRoles.replace(clientRoles.map(item => {
        return ClientRoleFormModel.create({
          role: item.role,
          sprints: item.sprints.map(sprint => SprintCheckModel.create({ value: sprint.value }))
        });
      }));
    },
    setRufreeRoles(rufreeRoles: RufreeRoleForm[]) {
      self.rufreeRoles.replace(rufreeRoles.map(item => {
        return RufreeRoleFormModel.create({
          role: item.role,
          roleTextBufferForETC: item.roleTextBufferForETC,
          wage: item.wage,
          sprints: item.sprints.map(sprint => SprintCheckModel.create({ value: sprint.value }))
        });
      }));
    },
    setSeesoServices(seesoService: SeesoServiceForm[]){
      self.seesoServices.replace(seesoService.map(item =>{
        return SeesoServiceFormModel.create({
          service: item.service,
          cost: item.cost,
          individual: item.individual,
          sprints: item.sprints.map(sprint => SprintCheckModel.create({ value: sprint.value }))
        })
      }))
    }
  }));

type InspectionFormType = typeof InspectionForm.Type;

export interface InspectionForm extends InspectionFormType {
}

export type TResourceType = 'client' | 'rufree' | 'service' | '';
export const InspectionFormStoreModel = types
  .model("InspectorFormStore", {
    currentForm: types.optional(InspectionForm, {}),

    selectedResource: types.optional(types.string, ''), // client: 클라이언트, rufree: 알유프리, service: 서비스
    selectedRole: types.optional(types.string, ''),
    selectedSprintNumber: types.optional(types.number, -1)
  })
  .actions(self => {
    const setSelectedSprint = (
      resource: string,
      role: string,
      sprintNumber: number
    ) => {
      self.selectedResource = resource;
      self.selectedRole = role;
      self.selectedSprintNumber = sprintNumber;
    }
    const initForm = () => {
      self.currentForm = InspectionForm.create({
        inspector: 4, // 기본값: 박병규 id
        references: [ReferenceLinkModel.create()],
        sprintDays: Array(MAX_SPRINTS)
          .fill(null)
          .map((_, index) =>
            SprintDayModel.create({ value: index === 0 ? 0 : 14 })
          ),
        clientRoles: [
          {
            role: "",
            sprints: createDefaultSprints()
          }
        ],
        vatIncluded: true,
        rufreeRoles: [
          {
            role: "백엔드",
            wage: 2000000,
            sprints: createDefaultSprints()
          },
          {
            role: "프론트엔드",
            wage: 2000000,
            sprints: createDefaultSprints()
          }
        ],
        seesoServices: [
          {
            service: "CS 매니지먼트",
            cost: 0,
            individual: true,
            sprints: createDefaultServiceSprints()
          },
          {
            service: "워크플로우 설계",
            cost: 0,
            individual: true,
            sprints: createDefaultServiceSprints()
          },
          // {
          //   service: "업무 프로세스 교육",
          //   cost: 150000,
          //   individual: false,
          //   sprints: createDefaultServiceSprints()
          // },
          // {
          //   service: "프로젝트 환경 구성",
          //   cost: 200000,
          //   individual: false,
          //   sprints: createDefaultServiceSprints()
          // },
          // {
          //   service: "업무 분석 및 최초 업무 카드 세팅",
          //   cost: 3600000,
          //   individual: false,
          //   sprints: createDefaultServiceSprints()
          // },
          // {
          //   service: "스프린트 운영",
          //   cost: 150000,
          //   individual: true,
          //   sprints: createDefaultServiceSprints()
          // },
          // {
          //   service: "실무자 리스크 관리",
          //   cost: 100000,
          //   individual: true,
          //   sprints: createDefaultServiceSprints()
          // },
          // {
          //   service: "(옵션)스프린트 업무카드 검수",
          //   cost: 750000,
          //   individual: false,
          //   sprints: createDefaultServiceSprints()
          // },
          // {
          //   service: "(옵션)통합QA 서비스",
          //   cost: 3000000,
          //   individual: false,
          //   sprints: createDefaultServiceSprints()
          // },
          // {
          //   service: "(옵션)기획리뷰 레포트 서비스",
          //   cost: 500000,
          //   individual: false,
          //   sprints: createDefaultServiceSprints()
          // }
        ]
      });
    };

    const overideTemplate = (template: IInspectionTemplate) => {
      self.currentForm.review = template.review;
      self.currentForm.attention = template.attention;

      self.currentForm.setSprintDays(template.sprintDays.map(item => item.value));
      self.currentForm.setClientRoles(template.clientRoles);
      self.currentForm.setRufreeRoles(template.rufreeRoles);
      self.currentForm.setSeesoServices(template.seesoServices);
    };
    const setForm = (val: any) => {
      self.currentForm = val;
    };
    const mapFormDataForRequest = () => {
      const form = self.currentForm;

      return {
        client: form.client,
        sprint_days: form.sprintDays.map(x => x.value),
        client_roles: form.clientRoles
          .filter(x => !!x.role)
          .map(clientRole => ({
            role: clientRole.role,
            sprints: clientRole.sprints.map(x => x.value)
          })),
        rufree_roles: form.rufreeRoles
          .filter(x => !!x.role)
          .map(rufreeRole => ({
            role:
              rufreeRole.role === "기타"
                ? rufreeRole.roleTextBufferForETC
                : rufreeRole.role,
            wage: rufreeRole.wage,
            sprints: rufreeRole.sprints.map(x => x.value)
          })),
        seeso_services: form.seesoServices
          .filter(x => !!x.service)
          .map(seesoService => ({
            service: seesoService.service,
            cost: seesoService.cost,
            individual: seesoService.individual,
            sprints: seesoService.sprints.map(x => x.value)
          })),
        title: form.title || "제목없음",
        requester_name: form.requesterName,
        requester_email: form.requesterEmail,
        inspector: form.inspector,
        references: form.references.map(x => {

          let created_date = x.created_date;
          if (x.link != x.org_link || x.comment != x.org_comment) {
            created_date = new Date().toISOString();
          }

          return ({
            link: x.link,
            comment: x.comment,
            created_date: created_date,
          });
        }),
        reference_links: [],
        date_start: moment(form.dateStart).format("YYYY-MM-DD"),
        date_end: moment(form.expectedEndDate).format("YYYY-MM-DD"),
        vat_included: form.vatIncluded,
        budget: form.totalCost,
        review: form.review,
        published_date: form.publishedDate
          ? moment(form.publishedDate).format("YYYY-MM-DD")
          : null,
        attention: form.attention,
        receptions: form.receptions.map(x => x.value),
        consults: form.consults.filter(x => x.value).map(x => x.value)
      };
    };

    const postInspection = flow(function* () {
      try {
        const {
          data: inspection
        }: { data: InspectionResponse } = yield axios.post(
          "/inspections",
          mapFormDataForRequest()
        );

        return inspection;
      } catch (e) {
        throw e;
      }
    });

    const putInspection = flow(function* (extraData?: object) {
      try {
        const form = self.currentForm;
        const reqData = Object.assign(
          mapFormDataForRequest(),
          extraData
        );

        const {
          data: inspection
        }: { data: InspectionResponse } = yield axios.put(
          `/inspections/${form.inspectionId}`,
          reqData
        );

        return inspection;
      } catch (e) {
        throw e;
      }
    });

    const fetchInspection = flow(function* (inspectionId: string) {
      try {
        const {
          data: inspection
        }: { data: InspectionResponse } = yield axios.get(
          `/inspections/${inspectionId}`
        );

        const clientRoles = inspection.client_roles.map(clientRole => {
          return {
            ...clientRole,
            sprints: clientRole.sprints.map(sprint =>
              SprintCheckModel.create({ value: sprint })
            )
          };
        });

        const rufreeRoles = inspection.rufree_roles.map(rufreeRole => {
          return {
            ...rufreeRole,
            sprints: rufreeRole.sprints.map(sprint =>
              SprintCheckModel.create({ value: sprint })
            )
          };
        });

        const seesoServices = inspection.seeso_services.map(seesoService => {
          return {
            ...seesoService,
            sprints: seesoService.sprints.map(sprint =>
              SprintCheckModel.create({ value: sprint })
            )
          };
        });

        const newForm = InspectionForm.create({
          inspectionId: inspection.inspection_id,
          client: inspection.client || undefined,
          title: inspection.title,
          requesterName: inspection.requester_name,
          requesterEmail: inspection.requester_email,
          inspector: inspection.inspector,
          references: inspection.references.map(reference => ReferenceLinkModel.create({
            link: reference.link,
            comment: reference.comment,
            org_link: reference.link,
            org_comment: reference.comment,
            created_date: reference.created_date
          })),
          referenceLinks: inspection.reference_links,
          inspection_files: inspection.inspection_files,
          dateStart: inspection.date_start || undefined,
          review: inspection.review,
          publishedDate: inspection.published_date || undefined,
          sprintDays: inspection.sprint_days.map(sprintDay =>
            SprintDayModel.create({ value: sprintDay })
          ),
          attention: inspection.attention,
          consults: inspection.consults.map(consult =>
            ConsultSelectionFormModel.create({ value: consult })
          ),
          receptions: inspection.receptions.map(reception =>
            ReceptionSelectionFormModel.create({ value: reception })
          ),
          vatIncluded: inspection.vat_included,
          clientRoles,
          rufreeRoles,
          seesoServices
        });
        self.currentForm = newForm;
      } catch (e) {
        throw e;
      }
    });

    const postChangeReason = flow(function* () {
      try {
        const {
          data
        }: { data: InspectionChangeReasonResponse } = yield axios.post(
          `/inspections/${self.currentForm.inspectionId}/changeReason`,
          {
            reason: self.currentForm.changeReason
          }
        );

        return data;
      } catch (e) {
        throw e;
      }
    });

    return {
      initForm,
      setForm,
      overideTemplate,
      postInspection,
      putInspection,
      fetchInspection,
      postChangeReason,
      setSelectedSprint
    };
  });

type InspectionFormStoreType = typeof InspectionFormStoreModel.Type;

export interface InspectionFormStore extends InspectionFormStoreType {
}
