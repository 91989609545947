import React, { Component, ChangeEvent, FormEvent } from "react";
import { inject, observer } from "mobx-react";
import { computed, observable } from "mobx";
import { AppStore } from "../../../store/AppStore";
import { FormMode } from "../../../types/formMode";

import moment from "moment";
import styled from "styled-components/macro";
import { Alert, Button, Dialog, Classes, FormGroup, InputGroup, Intent, TextArea, HTMLSelect, IOptionProps, NumericInput, FileInput, Spinner } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import { getMomentFormatter } from "../../../utils/date";
import { PrimaryButton, SecondaryButton, NormalText, HelpText } from "../../atoms/ParttimeComponents/ParttimeComponents";


import { PMUser } from "../../../store/models/PMUser";
import { Client } from "../../../store/models/Client";
import { ProjectGroup } from "../../../store/models/ProjectGroup";
import PMUserSelection from "../../molecules/PMUserSelection/PMUserSelection";
import ClientSelection from "../../molecules/ClientSelection/ClientSelection";
import OutcomeFormRow from "../../molecules/ParttimeRow/OutcomeFormRow";
import ProjectGroupSelection from "../ProjectGroupSelection/ProjectGroupSelection";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";

import { TaskStatusData } from "../../../store/models/Parttime";
import { TaskType, RufreeRole } from "../../../types/parttime";
import { TaskOutcomeFormModel } from "../../../store/forms/ParttimeForm/TaskOutcomeForm";

interface TaskFormDialogProps {
    requestId: number;
    taskId: number;
    mode: FormMode;
    isOpen: boolean;
    onClose: () => void;
}

interface InjectedProps extends TaskFormDialogProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class TaskFormDialog extends Component<TaskFormDialogProps> {
    @observable readyToShow: boolean = true;
    @observable onSubmit: boolean = false;
    @observable currentCriteria: string = '';
    @observable currentCriteriaIdx: number = -1;
    @observable showCriteriaDeleteAlert: boolean = false;

    get injected() {
        return this.props as InjectedProps;
    }

    @computed
    get formStore() {
        return this.injected.appStore.parttimeTaskFormStore
    }

    @computed
    get currentForm() {
        return this.formStore.taskForm;
    }

    @computed
    get selectedClient() {
        return this.injected.appStore.clientStore.clients.find(
            x => x.clientId === this.currentForm.client
        );
    }

    @computed
    get selectedPM() {
        return this.injected.appStore.pmUserStore.pmUsers.find(
            x => x.id === this.currentForm.manager.id
        );
    }

    @computed
    get selectedProjectGroup() {
        return this.injected.appStore.projectGroupStore.projectGroups.find(
            x => x.groupId === this.currentForm.projectGroup.groupId
        );
    }

    initForm = async () => {
        await this.formStore.initForm();

        const { requestId, taskId, mode } = this.props;

        if (mode === FormMode.Edit) {
            this.formStore.fetchTask(requestId, taskId);
        } else if (mode === FormMode.Create){
            // `request`에서 필요한 정보를 가져옴 (클라이언트번호 등)
            this.formStore.fetchClient(requestId);
        }

        this.currentForm.setRequestId(requestId);
    };

    handleOpening = () => {};

    handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.currentForm.setTitle(e.target.value);
    };

    handleClientChange = (client: Client) => {
        this.currentForm.setClient(client.clientId);
    };

    handleProjectGroupChange = (projectGroup: ProjectGroup) => {
        this.currentForm.setProjectGroup(projectGroup.groupId);
    };
    
    handleTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        this.currentForm.setType(e.currentTarget.value);
    };

    handleRufreeRoleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        this.currentForm.setRole(e.currentTarget.value);
    };

    handleExpectedDueDateChange = (selectedDate: Date) => {
        this.currentForm.setExpectedDueDate(selectedDate ? selectedDate.toISOString() : "");
    };

    handleEstimatedDueDateChange = (selectedDate: Date) => {
        this.currentForm.setEstimatedDueDate(selectedDate ? selectedDate.toISOString() : "");
    };

    handleEstimatedWorkingTimeChange = (value: number) => {
        if(value > 99){
            alert('최대 99시간까지 입력 가능합니다.');
            this.currentForm.setEstimatedWorkingTime(null);
            return;
        }

        this.currentForm.setEstimatedWorkingTime(value);
    }

    handleCriteriaChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.currentCriteria = e.target.value;
    };
    handleAddCriteria = () => {
        if(this.currentForm.criteria.length >= 10){
            alert('완료조건은 최대 10개까지 등록이 가능합니다.');
            this.currentCriteria = '';
            return;
        }
        if(this.currentCriteria !== ''){
            this.currentForm.addCriteria(this.currentCriteria);
            this.currentCriteria = '';
        }
    };
    handleRemoveCriteria = () => {
        this.currentForm.removeCriteria(this.currentCriteriaIdx);
        this.showCriteriaDeleteAlert = false;
    };

    handleDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        this.currentForm.setDescription(e.target.value);
    };

    handleTotalAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.currentForm.setTotalAmount(e.target.value);
    };

    handlePaymentRemarkChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        this.currentForm.setPaymentRemark(e.target.value);
    };

    handleMemoChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        this.currentForm.setMemo(e.target.value);
    };

    handleSubmit = async () => {
        const { onClose, mode } = this.props;

        try {
            if (mode === FormMode.Create) {
                this.onSubmit = true;
                await this.formStore.createTask();
                this.onSubmit = false;
                this.initForm();
            } else if(mode === FormMode.Edit) {
                this.onSubmit = true;
                await this.formStore.patchTask();
                this.onSubmit = false;
                this.initForm();
            }
            onClose();
        } catch (e) {
            const error = JSON.stringify(e.response.data);
            AppToaster.show({
                message: "오류: " + error,
                intent: Intent.DANGER
            });
            this.onSubmit = false;
        }
      };
    
    async componentDidMount() {
        this.initForm();
    }
    render() {
        const { isOpen, onClose, mode } = this.props;

        const typeOptions: IOptionProps[] = [
            {label: '선택', value: ''},
            {label: '시급제', value: TaskType.PER_HOUR},
            {label: '총비용', value: TaskType.OVERALL}
          ]

        const rufreeRoleOptions: IOptionProps[] = [
            {label: '선택', value: ''},
            {label: '기획', value: RufreeRole.PLANNING},
            {label: '디자인', value: RufreeRole.DESIGN},
            {label: '퍼블리싱', value: RufreeRole.PUBLISHING},
            {label: '프론트', value: RufreeRole.FRONTEND},
            {label: '백엔드', value: RufreeRole.BACKEND},
            {label: '프론트+백', value: RufreeRole.FULLSTACK},
            {label: 'QA', value: RufreeRole.QA},
            {label: '기타', value: RufreeRole.ETC}
          ]

        const title = (mode === FormMode.Create) ? `TASK 추가` : `TASK 수정` ;

        return (
            <Dialog
                onOpening={this.handleOpening}
                isOpen={isOpen}
                onClose={onClose}
                usePortal={true}
                autoFocus={true}
                enforceFocus={true}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                title={title}
                style={{backgroundColor: "white"}}
            >
            { !this.readyToShow ? (
                <Spinner />
            ) : (
                <>
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup label={<strong>담당매니저</strong>} labelInfo="*" disabled={this.onSubmit}>
                        <PMUserSelection
                            onChange={(selectedValue: PMUser) => {this.currentForm.setManager(selectedValue.id);}}
                            currentValue={this.selectedPM}
                            blockFetchingData={false}
                        />
                        </FormGroup>
        
                        <FormGroup label={<strong>정산요청대상</strong>} labelInfo="*" disabled={this.onSubmit}>
                        <ClientSelection
                        onChange={this.handleClientChange}
                        currentValue={this.selectedClient}
                        blockFetchingData={false}
                        />
                        </FormGroup>
        
                        <FormGroup label={<strong>프로젝트그룹</strong>} disabled={this.onSubmit}>
                            <ProjectGroupSelection
                                onChange={this.handleProjectGroupChange}
                                items={this.injected.appStore.projectGroupStore.projectGroups}
                                currentValue={this.selectedProjectGroup}
                                blockFetchingData={false}
                            />
                        </FormGroup>
                        <FormGroup label={<strong>제목</strong>} labelInfo="*" disabled={this.onSubmit}>
                            <InputGroup
                                placeholder="TASK 제목을 입력해주세요. (최대 100자)"
                                value={this.currentForm.title}
                                onChange={this.handleTitleChange}
                                maxLength={100}
                                disabled={this.onSubmit}
                            />
                        </FormGroup>
                        <FormGroup label={<strong>타입</strong>} labelInfo="*" disabled={this.onSubmit}>
                            <HTMLSelect
                                value={this.currentForm.type}
                                onChange={this.handleTypeChange}
                                options={typeOptions}
                                disabled={this.onSubmit}
                            />
                        </FormGroup>
                        <FormGroup label={<strong>알유프리 역할</strong>} labelInfo="*" disabled={this.onSubmit}>
                            <HTMLSelect
                                value={this.currentForm.role}
                                onChange={this.handleRufreeRoleChange}
                                options={rufreeRoleOptions}
                                disabled={this.onSubmit}
                            />
                        </FormGroup>
                        <FormGroup label={<strong>희망 완료일</strong>} style={{marginTop: "30px"}} disabled={this.onSubmit}>
                            <DateInput
                            {...getMomentFormatter("YYYY/MM/DD")}
                            locale="ko"
                            closeOnSelection={true}
                            value={this.currentForm.expectedDueDate ? moment(this.currentForm.expectedDueDate).toDate() : null}
                            onChange={this.handleExpectedDueDateChange}
                            maxDate={new Date("2050-01-01")}
                            disabled={this.onSubmit}
                            />
                        </FormGroup>
                        <FormGroup label={<strong>예상작업시간</strong>} disabled={this.onSubmit}>
                            <NumericInput
                                placeholder="00"
                                min={0}
                                max={99}
                                value={this.currentForm.estimatedWorkingTime ? this.currentForm.estimatedWorkingTime : undefined}
                                onValueChange={this.handleEstimatedWorkingTimeChange}
                                disabled={this.onSubmit}
                            />
                            { this.currentForm.estimatedWorkingTime ? (
                                <HelpText style={{marginTop: "10px", marginLeft: "5px", color: "#2F2F2F"}}>
                                    {this.currentForm.estimatedWorkingTime * 30000}원 (시간당 3만원)<br/>
                                    클라이언트에서 책정한 예산 기준이며, 실제작업시간과는 다를 수 있음.
                                </HelpText>
                            ) : '' }
                        </FormGroup>
                        <FormGroup label={<strong>작업내용</strong>} labelInfo="*" style={{marginTop: "30px"}} disabled={this.onSubmit}>
                            <TextArea
                                rows={5}
                                value={this.currentForm.description}
                                placeholder="현재 상태 또는 오류를 입력해주세요. (최대 1000자)"
                                onChange={this.handleDescriptionChange}
                                style={{ width: "100%" }}
                                maxLength={1000}
                                disabled={this.onSubmit}
                            />
                        </FormGroup>
        
                        <FormGroup label={<strong>완료조건</strong>} style={{marginTop: "30px"}} disabled={this.onSubmit}>
                            <InputGroup
                                placeholder="완료조건을 입력해주세요. (최대 100자)"
                                value={this.currentCriteria}
                                onChange={this.handleCriteriaChange}
                                maxLength={100}
                                disabled={this.onSubmit}
                            />
                            <SecondaryButton 
                                icon="caret-down" 
                                text={"추가"} 
                                onClick={this.handleAddCriteria}
                                style={{width: "20%", margin: "5px", marginLeft: "40%"}}
                                disabled={this.onSubmit}
                            />
                            <CriteriaWrapper>
                            {(Array.isArray(this.currentForm.criteria) && this.currentForm.criteria.length)? this.currentForm.criteria.map((description, idx) => (
                                <CriteriaRow key={idx}>
                                    <Button
                                        icon="minus"
                                        minimal={true}
                                        small={true}
                                        intent={Intent.DANGER}
                                        onClick={() => {this.showCriteriaDeleteAlert=true; this.currentCriteriaIdx = idx;}}
                                        disabled={this.onSubmit} 
                                    />
                                    <NormalText style={{display: "inline", marginLeft: "10px"}}>{description}</NormalText>
                                    
                                </CriteriaRow>
                            )) : (
                                <DefaultTextWrapper>
                                    <DefaultText>
                                        TASK 완료시 제공되어야 할 완료조건 <br/>(최대 10개)
                                    </DefaultText>
                                </DefaultTextWrapper>
                            )}
                            </CriteriaWrapper> 
                        </FormGroup>
                        {(this.currentForm.status === TaskStatusData.completed.db) && (
                            <FormGroup label={<strong>총비용</strong>} style={{marginTop: "30px"}} disabled={this.onSubmit}>
                                <InputGroup
                                    placeholder="총비용을 입력해주세요."
                                    value={this.currentForm.totalAmount}
                                    onChange={this.handleTotalAmountChange}
                                    maxLength={30}
                                    disabled={this.onSubmit}
                                />
                            </FormGroup>
                        )}
                        {(this.currentForm.status === TaskStatusData.completed.db) && (
                            <FormGroup label={<strong>정산 비고</strong>} disabled={this.onSubmit}>
                                <TextArea
                                    rows={5}
                                    value={this.currentForm.paymentRemark}
                                    placeholder="내용을 입력해주세요. (최대 1000자)"
                                    onChange={this.handlePaymentRemarkChange}
                                    style={{ width: "100%" }}
                                    maxLength={1000}
                                    disabled={this.onSubmit}
                                />
                            </FormGroup>
                        )}
                        {(this.currentForm.status === TaskStatusData.completed.db) && (
                            <FormGroup label={<strong>결과물</strong>} style={{marginTop: "30px"}} disabled={this.onSubmit}>
                                <FileInput 
                                    text="파일 추가" 
                                    fill={true} 
                                    disabled={this.onSubmit}
                                    onInputChange={(e: FormEvent<HTMLInputElement>) => {
                                        if (e.currentTarget.files) {
                                            const file = TaskOutcomeFormModel.create();
                                            file.setFile(e.currentTarget.files[0], e.currentTarget.files[0].name);
                                            this.currentForm.addOutcomes(file);
                                    }
                                }} />
                                <OutcomeBoard>
                                    {Array.isArray(this.currentForm.outcomes) && this.currentForm.outcomes.length 
                                    ? (<OutcomeWrapper>
                                            {this.currentForm.outcomes.map((file, index) => (
                                                <OutcomeFormRow
                                                    key={index}
                                                    file={file}
                                                    onRemove={this.currentForm.removeOutcomes}
                                                ></OutcomeFormRow>
                                            ))}
                                        </OutcomeWrapper>)
                                    : (
                                        <DefaultTextWrapper>
                                            <DefaultText>
                                            TASK 완료와 관련된 파일 또는 이미지
                                            </DefaultText>
                                        </DefaultTextWrapper>
                                    )}
                                </OutcomeBoard>
                            </FormGroup>
                        )}
                        <FormGroup label={<strong>관리자메모</strong>} style={{marginTop: "30px"}} disabled={this.onSubmit}>
                            <TextArea
                                rows={5}
                                value={this.currentForm.memo ? this.currentForm.memo : undefined}
                                placeholder="관리자메모 또는 인수인계 사항을 입력해주세요. (최대 1000자)"
                                onChange={this.handleMemoChange}
                                style={{ width: "100%" }}
                                maxLength={1000}
                                disabled={this.onSubmit}
                            />
                        </FormGroup>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <PrimaryButton text={(mode === FormMode.Create) ? "추가" : "수정"} style={{width: "100%"}} onClick={this.handleSubmit} disabled={this.onSubmit}/>
                    </div>
                    </div>
                </>
            )}
            <Alert 
                icon="trash"
                intent={Intent.DANGER}
                isOpen={this.showCriteriaDeleteAlert}
                onCancel={() => this.showCriteriaDeleteAlert=false}
                onConfirm={this.handleRemoveCriteria}
                confirmButtonText={'확인'}
                cancelButtonText={'닫기'}
              ><p>선택하신 완료조건을 삭제합니다.</p>
            </Alert>
          </Dialog>
        );
    }
}

export default TaskFormDialog;

const CriteriaWrapper = styled.div`
    background-color: #eaeaea;
    min-height: 140px;
    padding: 20px;
`;
const CriteriaRow = styled.div`
    margin-bottom: 10px;
`;

const DefaultTextWrapper = styled.div`
    display: flex;
    min-height: 140px;
    flex-direction: column;
    justify-content: center; 
    align-items: center;  
`;

const DefaultText = styled.div`
    text-align: center;
    color: #9e9e9e;
`;

const OutcomeBoard = styled.div`
    background-color: #f3f3f3;
    height: 150px;
    margin-top: 5px;
    overflow: overlay;
`;
const OutcomeWrapper = styled.div`
    padding: 5px 0;
`;
