import { types, flow } from "mobx-state-tree";
import axios from "axios";
import sortBy from "lodash/sortBy";
import { BaseModel } from "./BaseModel";
import { Rufree } from "./Rufree";

export const PHASE = {
    pending: 'PENDING',
    submitted: 'SUBMITTED',
    accepted: 'ACCEPTED',
    rejected: 'REJECTED',
    expired: 'EXPIRED',
    matched: 'MATCHED',
    declined: 'DECLINED',
    canceled: 'CANCELED'
};

// `알유프리매칭히스토리` status-phase 정의
export const STATUS : { [key: string]: any } = {
    'PENDING': {
        'phase': 'PENDING',
        'status': '제안전',
        'title': ''
    },
    'SUBMITTED': {
        'phase': 'SUBMITTED',
        'status': '응답대기중',
        'title': ' 후 만료'
    },
    'ACCEPTED': {
        'phase': 'ACCEPTED',
        'status': '확정대기중',
        'title': '제안 수락해주셔서 정말 감사합니다.'
    },
    'REJECTED': {
        'phase': 'REJECTED',
        'status': '제안거절',
        'title': '제안을 검토해주셔서 감사합니다.'
    },
    'EXPIRED': {
        'phase': 'EXPIRED',
        'status': '제안만료',
        'title': '응답 기간 만료로 자동으로 거절되었습니다.'
    },
    'MATCHED': {
        'phase': 'MATCHED',
        'status': '최종확정',
        'title': '축하합니다! 프로젝트 알유프리 매칭이 최종 확정되었습니다.'
    },
    'DECLINED': {
        'phase': 'DECLINED',
        'status': '매칭실패',
        'title': '죄송합니다. 프로젝트 최종 매칭이 성사되지 않았습니다.'
    },
    'CANCELED': {
        'phase': 'CANCELED',
        'status': '취소',
        'title': ''
    }
};

export const RufreeMatchingHistoryCommentModel = BaseModel.named(
  "RufreeMatchingHistoryComment"
)
  .props({
    id: types.identifierNumber,
    createdAt: types.string,
    updatedAt: types.string,
    creator: types.string,
    isRufree: types.boolean,
    comment: types.string
  })


export const RufreeMatchingHistoryModel = BaseModel.named(
  "RufreeMatchingHistory"
)
  .props({
    id: types.identifierNumber,
    rufree: types.string,
    status: types.string,
    phase: types.string,
    createdAt: types.string,
    updatedAt: types.string,
    dateSend: types.maybeNull(types.string),
    dateResponse: types.maybeNull(types.string),
    dateViewed: types.maybeNull(types.string),
    dateAccepted: types.maybeNull(types.string),
    isAccepted: types.boolean,
    comments: types.array(RufreeMatchingHistoryCommentModel),
    rufreeMatching: types.number,
    rufreeName: types.string,
      rufreeEmail: types.string,
      rufreePhone: types.string,
      rejectReason: types.string,
  })
  .views(self => ({
    get rufreeInstance(): Rufree | undefined {
      return self.root.rufreeStore.rufreeById(self.rufree);
    }
  }))
  .views(self => ({
    get sortedComments() {
      return sortBy(
        self.comments,
        'id'
      ).reverse();
    }
  }))
  .actions(self => {
    const sendRequest = flow(function*() {
      try {
        yield axios.get(`/rufreeMatchingHistory/${self.id}/send`);
      } catch (e) {
        console.error("sendRequest error", e);
        throw e;
      }
    });

    const deleteHistory = flow(function*() {
      try {
        yield axios.delete(`/rufreeMatchingHistory/${self.id}`);
      } catch (e) {
        console.error("deleteHistory error", e);
        throw e;
      }
    });

    const electRequest = flow(function*() {
      try {
        yield axios.get(`/rufreeMatchingHistory/${self.id}/elect`);
      } catch (e) {
        console.error("electRequest error", e);
        throw e;
      }
    });

    const cancelRequest = flow(function*() {
      try {
        yield axios.get(`/rufreeMatchingHistory/${self.id}/cancel`);
      } catch (e) {
        console.error("electRequest error", e);
        throw e;
      }
    });


    return {
      sendRequest,
      electRequest,
      cancelRequest,      
      deleteHistory
    };
  });

type RufreeMatchingHistoryType = typeof RufreeMatchingHistoryModel.Type;
export interface RufreeMatchingHistory extends RufreeMatchingHistoryType {}
