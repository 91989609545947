import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { Intent } from "@blueprintjs/core";
import { ProjectGroup } from "../../../store/models/ProjectGroup";
import SprintBoardRowGroup from "../../molecules/SprintReportRowGroup/SprintBoardRowGroup";
import {
  FlexRow,
  ContentColumn,
  GreyLabel
} from "../../atoms/DetailReportComponents/DetailReportComponents";
import { AppToaster } from "../AppToaster/AppToaster";

const Wrapper = styled.div`
  margin-bottom: 50px;
`;

const Title = styled(GreyLabel)`
  border-bottom: solid 1px #7f8390;
  padding-bottom: 8px;
  margin-bottom: 20px;
  font-weight: bold;
`;

const RufreeHeader = styled(FlexRow)`
  height: 40px;
  background-color: #f8f8f8;
  align-items: center;
`;

const Sync = styled.span`
  font-size: 12px;
`;

interface SprintBoardTableProps {
  projectGroup: ProjectGroup;
}

interface InjectedProps extends SprintBoardTableProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class SprintBoardTable extends Component<SprintBoardTableProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  reloadProjectGroup = async() => {
    this.injected.appStore.projectGroupStore.fetchProjectGroup(this.props.projectGroup.groupId);
  }

  handleSync = async() => {
    try {
      await this.props.projectGroup.syncSprint();

      AppToaster.show({
        message: "운영일정과 동기화되었습니다.",
        intent: Intent.SUCCESS
      });

      this.reloadProjectGroup()
    } catch (e) {
      const error = JSON.stringify(e.response.data);

      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  }

  render() {
    const { projectGroup } = this.props;
    const { reloadProjectGroup } = this;

    return (
      <Wrapper>
        <FlexRow>
          <ContentColumn>
            <Title>
            스프린트 종료현황
            (<Sync>운영일정 수정 시, <b>자동 동기화</b> - 알유프리 정보가 입력된 운영일정만 스프린트가 생성됩니다.</Sync>)
            </Title>
          </ContentColumn>
        </FlexRow>

        {projectGroup.sprintGroupsByRufree.map((sprintGroup, index) => (
          <SprintBoardRowGroup
            key={index}
            projectGroupName={projectGroup.name}
            sprintGroup={sprintGroup}
            onPopupClose={reloadProjectGroup} />
        ))}
      </Wrapper>
    );
  }
}

export default SprintBoardTable;
