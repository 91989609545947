import { types, flow } from "mobx-state-tree";
import axios from "axios";

export const ReferenceLinkFormModel = types
    .model("ReferenceLink", {
        id: types.maybeNull(types.number),
        url: types.optional(types.string, ""),
        description: types.optional(types.string, "")
    })
    .actions(self => ({
        setId(value: number) {
            self.id = value;
        },
        setURL(value: string) {
            self.url = value;
        },
        setDescription(value: string) {
            self.description = value;
        },
    })); 

type ReferenceLinkFormType = typeof ReferenceLinkFormModel.Type;
export interface ReferenceLinkForm extends ReferenceLinkFormType {}


export const ReferenceLinkFormStoreModel = types
.model("ReferenceLinkFormStore", {
  currentForm: types.optional(ReferenceLinkFormModel, {})
})
.actions(self => ({
  initForm() {
    self.currentForm = ReferenceLinkFormModel.create();
  }
}))
.actions(self => {
  const edit = flow(function*(requestId: number) {
    try {
      const form = self.currentForm;

      yield axios.patch(`/parttime/requests/${requestId}/reference-links/${form.id}`, {
        description: form.description
      });
    } catch (e) {
      console.log("edit error", e);
      throw e;
    }
  });

  const deleteReferenceLink = flow(function*(requestId: number) {
    try {
      const form = self.currentForm;

      yield axios.delete(`/parttime/requests/${requestId}/reference-links/${form.id}`);
    } catch (e) {
      console.log("edit error", e);
      throw e;
    }
  });

  return {
    edit,
    deleteReferenceLink
  };
});

type ReferenceLinkFormStoreType = typeof ReferenceLinkFormStoreModel.Type;
export interface ReferenceLinkFormStore extends ReferenceLinkFormStoreType {}