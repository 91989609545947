/*
  파트너 회원 리스트
*/
import React from 'react';
import { observer } from "mobx-react";
import { Link } from "react-router-dom";

import styled from "styled-components/macro";
import moment from "moment";

import { IPartner } from '../../../../store/models/Partner';
import { Button, HTMLTable, Intent, IButtonProps, Spinner } from '@blueprintjs/core';

const Wrapper = styled.div`
  & table {
    width: 100%;
    margin-bottom: 5px;
  }
  & table.bp3-html-table td {
    text-align: center;
    &.actions {
      padding: 3px 0;
      button + button { margin-left: 5px; }
      & > button > span { font-weight: bold; font-size: 13px; }
    }
  }
`;
const PartnerList = (observer((props: {
  isFetching: boolean,
  partners: IPartner[],
  onApprove: (partner: IPartner) => void,
  onRevoke: (partner: IPartner) => void,
  onReject: (partner: IPartner) => void
}) => {
  const { isFetching, partners, onApprove, onRevoke, onReject } = props;
  const tableHeaders = [
    "ID",
    "회사 이름",
    "이름",
    "전화번호",
    "이메일",
    "가입일 (승인요청일)",
    "승인/거절일",
    "상태",
    ""
  ];

  const headerRenderer = () => tableHeaders.map( (header, i) => <th key={ i }>{ header }</th> );
  const bodyRenderer = (partners: IPartner[]) => {
    return partners.map( partner => {
      const updated = partner.dateApprovedOrRejected;

      return <tr key={ partner.id }>
        <td><Link to={`/secondteam/partner/${ partner.id }`}>{ partner.code }</Link></td>
        <td>{ partner.company_name }</td>
        <td>{ partner.name }</td>
        <td>{ partner.phone }</td>
        <td>{ partner.email }</td>
        <td>{ moment(partner.created).format('YYYY-MM-DD') }</td>
        <td>{ updated ? moment(updated).format('YYYY-MM-DD') : '' }</td>
        <td>{ partner.statusToString }</td>
        <td className='actions'>
          {
            updated ?
              <RevokeButton onClick={ () => onRevoke(partner) } /> :
              <>
                <ApproveButton onClick={ () => onApprove(partner) } />
                <RejectButton onClick={ () => onReject(partner) } />
              </>
          }
        </td>
      </tr>
    });
  }

  return (
    <Wrapper>
      <HTMLTable
        condensed={ true }
        interactive={ true }
        striped={ true} >
        <thead>
          <tr>
            { headerRenderer() }
            <th />
          </tr>
        </thead>

        <tbody>
          {
            isFetching ? 
            <tr><td colSpan={ tableHeaders.length }><Spinner /></td></tr> : bodyRenderer(partners)
          }
        </tbody>
    </HTMLTable>
  </Wrapper>
  )
}));
export default PartnerList;

/*
  파트너 회원 승인/거절 버튼
*/
const ActionButton = (props: IButtonProps) => <Button intent={ Intent.PRIMARY } minimal={ true } small={ true } { ...props } />;
const ApproveButton = (props: IButtonProps) => <ActionButton text='승인' { ...props } />;
const RejectButton = (props: IButtonProps) => <ActionButton intent={ Intent.DANGER } text='거절' { ...props } />;
const RevokeButton = (props: IButtonProps) => <ActionButton intent={ undefined } text='취소' { ...props } />;