import { types, flow } from "mobx-state-tree";

import axios from "axios";

import { BaseModel } from "./models/BaseModel";

export const SeesoBotStoreModel = BaseModel.named("SeesoBotStore")
  .props({
    boAppId: types.optional(types.string, ""),
    boAppInstalled: types.optional(types.boolean, false),
    boAppInstallUrl: types.optional(types.string, ""),
    boClientId: types.optional(types.string, ""),
    boClientSecret: types.optional(types.string, ""),

    prjAppId: types.optional(types.string, ""),
    prjAppInstalled: types.optional(types.boolean, false),
    prjAppInstallUrl: types.optional(types.string, ""),
    prjClientId: types.optional(types.string, ""),
    prjClientSecret: types.optional(types.string, ""),
  })
  .views(self => ({

  }))
  .actions(self => ({
    setBoAppId(value: string) { self.boAppId = value; },
    setBoAppInstalled(value: boolean) { self.boAppInstalled = value; },
    setBoAppInstallUrl(value: string) { self.boAppInstallUrl = value; },
    setBoClientId(value: string) { self.boClientId = value; },
    setBoClientSecret(value: string) { self.boClientSecret = value; },
    setPrjAppId(value: string) { self.prjAppId = value; },
    setPrjAppInstalled(value: boolean) { self.prjAppInstalled = value; },
    setPrjAppInstallUrl(value: string) { self.prjAppInstallUrl = value; },
    setPrjClientId(value: string) { self.prjClientId = value; },
    setPrjClientSecret(value: string) { self.prjClientSecret = value; },
  }))
  .actions(self => {
    const installCheck = flow(function*(project_group_id:string = '') {
      try {
        const { data }: { data: any } = yield axios.get(
          `/seesobot/install-check?project-group=${project_group_id}`
        );

        if(project_group_id) {
          self.setPrjAppId(data.app_id);
          self.setPrjAppInstalled(data.installed);
          self.setPrjAppInstallUrl(data.install_url);
          self.setPrjClientId(data.client_id);
          self.setPrjClientSecret(data.client_secret);
        } else {
          self.setBoAppId(data.app_id);
          self.setBoAppInstalled(data.installed);
          self.setBoAppInstallUrl(data.install_url);
          self.setBoClientId(data.client_id);
          self.setBoClientSecret(data.client_secret);
        }

      } catch (e) {
        console.log("SeesoBotStore > installCheck error", e);
        throw e;
      }
    });

    const sync = flow(function*(project_group_id:string) {
      try {
        const { data }: { data: any } = yield axios.put(
          `/seesobot/sync`,
          {
            project_group_id: project_group_id
          }
        );
      } catch (e) {
        console.log("SeesoBotStore > sync error", e);
        throw e;
      }
    });

    // 프로젝트보드 설정 관련
    const getBoardSettings = flow(function*(project_group_id:string = '') {
      try {
        const { data }: { data: any } = yield axios.get(
          `/seesobot/board/settings?project-group=${project_group_id}`
        );
        return data;
      } catch (e) {
        console.log("SeesoBotStore > board/getSettings error", e);
        throw e;
      }
    });

    const putBoardSettings = flow(function* (project_group_id: string, slack_channel: string, slack_member_mapping_updates: {[k:string]: number}) {
      try {
        const { data }: { data: object } = yield axios.put(
          `/seesobot/board/settings`,
          {
            project_group: project_group_id,
            channel: slack_channel,
            member_mapping_updates: slack_member_mapping_updates
          }
        );

      } catch (e) {
        console.log("putBoardSettings error", e);
        throw e;
      }
    });    
    
    return {
      installCheck,
      sync,

      // 프로젝트보드 설정 관련
      getBoardSettings,
      putBoardSettings,
    };
  });

type SeesoBotStoreModelType = typeof SeesoBotStoreModel.Type;
export interface SeesoBotStore extends SeesoBotStoreModelType {
}
