import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { observable } from "mobx";
import { RouteComponentProps } from "react-router";
import { FormMode } from "../../../types/formMode";
import { Tabs, Tab, TabId, H3 } from "@blueprintjs/core";

import ClientFormPanel from "./ClientFormPanel";
import ClientCalculatePanel from "./ClientCalculatePanel";

const Wrapper = styled.div``;

const Title = styled(H3)`
`;

interface PageParams {
  clientId: string;
}

interface ClientFormPageProps extends RouteComponentProps<PageParams> {
  mode: FormMode;
}

interface InjectedProps extends ClientFormPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ClientFormPage extends Component<ClientFormPageProps> {
  @observable currentTab: TabId = "info";

  // get injected() {
  //   return this.props as InjectedProps;
  // }

  // get formStore() {
  //   return this.injected.appStore.clientFormStore;
  // }

  // @computed
  // get currentForm() {
  //   return this.formStore.currentForm;
  // }

  // @computed
  // get bizForm() {
  //   return this.formStore.bizFileForm;
  // }

  // @computed
  // get bnkForm() {
  //   return this.formStore.bnkFileForm;
  // }

  handleChangeTab = (navbarTabId: TabId) => {
    this.currentTab = navbarTabId;
  };

  async componentDidMount() {
  }

  render() {
    const { clientId } = this.props.match.params;

    return (
      <Wrapper>
        <Title>
          클라이언트 번호: { clientId }
        </Title>
        <hr />

        <Tabs
          id="ClientTabs"
          onChange={this.handleChangeTab}
          selectedTabId={this.currentTab}
          renderActiveTabPanelOnly={true}
        >
          <Tab
            id="info"
            title="상세정보"
            panel={<ClientFormPanel {...this.props} />}
          />

          <Tab
            id="calculate"
            title="입/출금 내역"
            panel={<ClientCalculatePanel clientId={clientId} />}
          />
        </Tabs>

      </Wrapper>
    );
  }
}

export default ClientFormPage;
