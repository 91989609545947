import React, { Component, KeyboardEvent } from "react";
import styled from "styled-components/macro";
import { RouteComponentProps } from "react-router";
import { computed, observable } from "mobx";
import { inject, observer } from "mobx-react";

import TaskRow from "../../molecules/ParttimeRow/TaskRow";
import Paginator from "../../molecules/Paginator/Paginator";

import { AppStore } from "../../../store/AppStore";
import { Task, TaskStatusData } from "../../../store/models/Parttime";
import LargeContainer from "../../atoms/LargeContainer/LargeContainer";
import { TableHeader, Header } from "../../atoms/CardTable/CardTable";
import { PageNavWrapper } from "../../atoms/PageNav/PageNav";
import { BreadCrumb } from "../../molecules/BreadCrumb/BreadCrumb";
import {
    Button,
    Checkbox,
    InputGroup,
    Card,
    Label,
  } from "@blueprintjs/core";

const Wrapper = styled.div``;

const Divider = styled.div`
  margin: 40px 0 40px 0;
  border: 1px solid #d6d6d6;
`;

const TableBody = styled.div`
  min-height: 500px;
`;
const ToolsRow = styled.div`
  margin-bottom: 30px;
  display: flex;
  height: 20px;

  & > * + * {
    margin-left: 10px;
  }
`;
const InputContainer = styled.div`
  margin-bottom: 30px;
`;

const checkboxStyle = {
    display: 'inline',
    marginRight: '30px'
}

interface TaskListPageProps extends RouteComponentProps {}

interface InjectedProps extends TaskListPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class PartTimeTaskListPage extends Component<TaskListPageProps> {
    @observable readyToShow = false;
    @observable searchText = "";

    get injected() {
        return this.props as InjectedProps;
    }

    @computed
    get taskStore() {
        return this.injected.appStore.parttimeTaskStore;
    }

    handleStatus = (e: any) => {
        this.taskStore.setStatus(e.target.value, e.target.checked);
    };

    handleSearch = async () => {
        this.readyToShow = false;
        await this.taskStore.fetchTasks({searchText: this.searchText});
        this.readyToShow = true;
    };

    handleSearchText = (e: any) => {
        this.searchText = e.target.value;
    };

    handleSearchTextKeyDown = async(event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
          await this.handleSearch();
        }
    }

    fetchTasks = async () => {
        // [START] query string으로 status 값이 넘어온 경우
        const query = new URLSearchParams(this.props.location.search);
        const status = query.get('status');
        
        if(status) {
            this.taskStore.clearStatus();
            this.taskStore.setStatus(status, true);
        }

        this.props.location.search = ''
        // [END] query string으로 status 값이 넘어온 경우

        try {
          this.readyToShow = false;
          await this.taskStore.fetchTasks({searchText: this.searchText});
        } catch (e) {
        } finally {
          this.readyToShow = true;
        }
    }
    
    async componentDidMount() {
        this.taskStore.clear();
        await this.fetchTasks();
    }

    handleTaskClick = (task: Task) => {
        this.props.history.push(`/parttime/requests/${ task.request.id }/tasks/${ task.id }`);
    };

    render() {
        const { tasks } = this.taskStore;

        return (
            <LargeContainer>
                <PageNavWrapper>
                    <BreadCrumb
                        items={[
                            { name: "대시보드", link: "/parttime/" },
                            "TASK 목록"
                        ]}
                    />
                </PageNavWrapper>

                <Card style={{ marginBottom: "20px"}}>
                    <InputContainer>
                        <Label>검색어</Label>  
                        <InputGroup
                        placeholder="TASK 제목, TASK 번호, 소속명 또는 알유프리"
                        value={this.searchText}
                        onChange={this.handleSearchText}
                        onKeyDown={this.handleSearchTextKeyDown}
                        />
                    </InputContainer>
                    <div>
                        <Label>상태</Label>
                        {/* <Checkbox {...this.state} value={TaskStatusData.created.db} label={TaskStatusData.created.text} checked={this.taskStore.created} onChange={this.handleStatus} style={checkboxStyle} /> */}
                        {/* <Checkbox {...this.state} value={TaskStatusData.arranging.db} label={TaskStatusData.arranging.text} checked={this.taskStore.arranging} onChange={this.handleStatus} style={checkboxStyle} /> */}
                        {/* <Checkbox {...this.state} value={TaskStatusData.matched.db} label={TaskStatusData.matched.text} checked={this.taskStore.matched} onChange={this.handleStatus} style={checkboxStyle} /> */}
                        <Checkbox {...this.state} value={TaskStatusData.inProgress.db} label={TaskStatusData.inProgress.text} checked={this.taskStore.inProgress} onChange={this.handleStatus} style={checkboxStyle} />
                        {/* <Checkbox {...this.state} value={TaskStatusData.review.db} label={TaskStatusData.review.text} checked={this.taskStore.review} onChange={this.handleStatus} style={checkboxStyle} /> */}
                        {/* <Checkbox {...this.state} value={TaskStatusData.settlement.db} label={TaskStatusData.settlement.text} checked={this.taskStore.settlement} onChange={this.handleStatus} style={checkboxStyle} /> */}
                        <Checkbox {...this.state} value={TaskStatusData.completed.db} label={TaskStatusData.completed.text} checked={this.taskStore.completed} onChange={this.handleStatus} style={checkboxStyle} />
                        <Checkbox {...this.state} value={TaskStatusData.calculated.db} label={TaskStatusData.calculated.text} checked={this.taskStore.calculated} onChange={this.handleStatus} style={checkboxStyle} />
                        {/* <Checkbox {...this.state} value={TaskStatusData.canceled.db} label={TaskStatusData.canceled.text} checked={this.taskStore.canceled} onChange={this.handleStatus} style={checkboxStyle} /> */}
                    </div>
                    <div style={{ textAlign: "center", marginTop: "20px"}}>
                        <Button
                            text="검색"
                            alignText="center"
                            style={{ width: "150px", borderRadius: "15px", backgroundColor: "#353535", backgroundImage: "none", color: "white"}}
                            onClick={this.fetchTasks}
                        />
                    </div>
                </Card>

                <Divider></Divider>

                <TableHeader>
                    <Header style={{ maxWidth: "104px" }}>번호</Header>
                    <Header style={{ maxWidth: "250px" }}>TASK 제목</Header>
                    <Header style={{ maxWidth: "270px" }}>역할</Header>
                    <Header style={{ maxWidth: "200px" }}>소속명</Header>
                    <Header style={{ maxWidth: "200px" }}>알유프리</Header>
                    <Header style={{ maxWidth: "283px" }}>상태</Header>
                    <Header style={{ maxWidth: "200px" }}>작업시간</Header>
                </TableHeader>

                <TableBody style={{ minHeight: "auto" }}>
                    { tasks.map(task => (
                        <TaskRow
                            key={ task.id }
                            task={ task }
                            onClick={() => this.handleTaskClick(task)}
                        />
                    )) }
                </TableBody>

                <Paginator
                    pageGroup={this.taskStore.pageGroup}
                    totalPages={this.taskStore.totalPages}
                    currentPage={this.taskStore.currentPage}
                    setPageGroup={this.taskStore.setPageGroup}
                    setCurentPage={this.taskStore.setCurentPage}
                    onFetch={this.fetchTasks}
                />
            </LargeContainer>
        );
    }
}

export default PartTimeTaskListPage;