import React from 'react';
import styled from "styled-components/macro";

import {
  Button
} from '@blueprintjs/core';

export const FilterRow = styled.div`
  display: flex;
  margin-bottom: 20px;

  & > div + div {
    margin-left: 15px;
  }
`;
export const Content = styled.div``;

export const StyledDateRangeSelector = styled.div`
  & > label {
    margin-right: 10px;
  }
  & .bp3-input {
    width: 100px;
  }
`;
export const StyledSearchBar = styled.div`
  width: 300px;
`;

export const StyledHeader = styled.thead`
  font-size: 0.8rem;
  & > tr > th {
    // text-align: center;
  }
`;
export const StyledTr = styled.tr`
  font-size: 0.85rem;

  // & > *:nth-child(2) {
  //   width: 150px;
  // }
  // & > *:nth-child(3) {
  //   width: 80px;
  // }
  // & > *:nth-child(4), & > *:nth-child(7) {
  //   width: 55px;
  // }
`;
export const SearchButton = () => <div><Button text="검색" /></div>;
