import React, { Component, ChangeEvent } from "react";
import axios from "axios";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import FormContainer from "../../atoms/FormContainer/FormContainer";
import FormSection from "../../atoms/FormSection/FormSection";
import { FormGroup, InputGroup, Button, Intent, Spinner, Icon, Card, Classes, Colors } from "@blueprintjs/core";
import { FormMode } from "../../../types/formMode";
import { computed, observable } from "mobx";
import { Client } from "../../../store/models/Client";
import { ApprovedClientContract } from "../../../store/models/ApprovedContract";
import { Inspection } from "../../../store/models/Inspection";
import ClientSelection from "../../molecules/ClientSelection/ClientSelection";
import ProjectGroupConsultSelection from "./ProjectGroupConsultSelection";
// import InspectionSelectionFormView from "./InpsectionSelectionFormView";
import InspectionSelection from "../../molecules/InspectionSelection/InspectionSelection";
import ProjectGroupWorkerFormView from "./ProjectGroupWorkerFormView";
import {
  ItemRow,
  RemoveButtonContainer,
  RemoveButton
} from "../../atoms/RemoveItemButton/RemoveItemButton";
import {
  AddItemButton,
  AddItemButtonContainer
} from "../../atoms/AddItemButton/AddItemButton";
import ContentContainer from "../../atoms/ContentContainer/ContentContainer";
import { RouteComponentProps, Redirect } from "react-router";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import ProjectGroupManagerFormView from "./ProjectGroupManagerFormView";
import ProjectGroupReceptionSelection from "./ProjectGroupReceptionSelection";
import ReferenceLinkFormView from "../../molecules/ReferenceLinkFormView/ReferenceLinkFormView";
import ApprovedClientContractSelection from "../../molecules/ApprovedClientContractSelection/ApprovedClientContractSelection";
import moment from "moment";
import { DateInput } from "@blueprintjs/datetime";
import { getMomentFormatter } from "../../../utils/date";
import TemporaryStorage from "../../molecules/Utility/TemporaryStorage/TemporaryStorage";

const Wrapper = styled.div`
  & > div {
    max-width: 600px;
  }`
;

const ButtonsRow = styled.div`
  /* text-align: right; */

  & > * + * {
    margin-left: 5px;
  }
`;
const ClearIcon = styled(Icon)`
  margin-left: 5px;
  cursor: pointer;
  color: #5C7080;
`;

interface PageParams {
  projectGroupId: string;
}

interface ProjectGroupFormPageProps extends RouteComponentProps<PageParams> {
  mode: FormMode;
}

interface InjectedProps extends ProjectGroupFormPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ProjectGroupFormPage extends Component<ProjectGroupFormPageProps> {
  @observable isFormInitiated = false;
  @observable isPending = false;
  @observable isCreateSuccess = false;
  @observable isEditSuccess = false;
  @observable isHiddenMode = false;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get formStore() {
    return this.injected.appStore.projectGroupFormStore;
  }

  @computed
  get currentForm() {
    return this.formStore.currentForm;
  }

  @computed
  get selectedClient() {
    const { client } = this.currentForm;
    return this.injected.appStore.clientStore.clients.find(
      x => x.clientId === client
    );
  }

  @computed
  get selectedContract() {
    const { approvedContractId } = this.currentForm;
    return this.injected.appStore.contractStore.approvedClientContracts.find(
      x => x.approvedContractId === approvedContractId
    );
  }

  @computed
  get selectedInspection() {
    const inspection_id = this.currentForm.inspections.length > 0 ? this.currentForm.inspections[0].value : '';

    return this.injected.appStore.inspectionStore.inspections.find(
      x => x.inspectionId === inspection_id
    );
  }

  async fetchAllRufreesByGraphQL() {
    const graphql_q = `{
      inspections {
        inspectionId,
        title,
        references
      }
      receptions {
        id,
        receptionId,
        title,
        dateRegister
      },
      users {
        id,
        name,
        phone,
        email,
        client {
          clientId
        },
        uuid,
        affiliation,
        affiliationType
      },
      approvedClientContracts {
        approvedContractId
      }
    }`;

    try {
      const { data }: { data: { data: {
        inspections: { inspectionId: string; title: string; references: any[]; }[];
        receptions: { id: string; receptionId: string; title: string; dateRegister: string; }[];
        users: { id: string; name: string; phone: string; email: string; client: { clientId: string; }, uuid: string; affiliation: string; affiliationType: string; }[];
        approvedClientContracts: { approvedContractId: string; }[];
      } }} = await axios.post(
        `graphql/`,
        {
          query: graphql_q,
        }
      );
      return data.data;
    } catch (e) {
      throw e;
    }
  };

  async componentDidMount() {
    await this.formStore.initForm();

    await this.injected.appStore.pmUserStore.fetchPMUsers();
    // await this.injected.appStore.clientUserStore.fetchClientUsers();
    const { inspections, receptions, users } = await this.fetchAllRufreesByGraphQL();
    // 클라이언트 유저 세팅
    this.injected.appStore.clientUserStore.setClientUsers(users);
    // 검수서 세팅
    this.injected.appStore.inspectionStore.setInspections(inspections);
    // 접수서 세팅
    this.injected.appStore.receptionStore.setReceptions(receptions);


    const { mode } = this.props;
    const { projectGroupId } = this.props.match.params;

    const query = new URLSearchParams(this.props.location.search);
    const hidden = query.get('hidden');

    if(hidden !== null) {
        this.isHiddenMode = true;
    }

    if (mode !== FormMode.Create && projectGroupId) {
      // 수정일 경우.
      await this.formStore.fetchProjectGroup(projectGroupId);
    } else {
      // 신규 등록일 경우.
      // 1. 그룹번호 자동 생성
      // 2. 검수서가 있을경우, 검수서 선택

      if(hidden == null) {
        const group_id = await this.injected.appStore.projectGroupStore.getNextGroupId();
        this.currentForm.setGroupId(group_id);
      }
      const inspectionId = query.get('inspection');
      if(inspectionId) {
        await this.injected.appStore.inspectionStore.fetchInspectionById(inspectionId);
        const inspection = this.injected.appStore.inspectionStore.inspections.find(inspection => inspection.inspectionId === inspectionId);
        if(inspection) {
          this.handleInspectionChange(inspection);
        }
      } 
    }

    if(this.currentForm.inspections.length === 0) {
      this.currentForm.addInspectionSelection();
    }

    this.isFormInitiated = true;
  }

  handleGroupIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.currentForm.setGroupId(e.target.value);
  };

  handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.currentForm.setName(e.target.value);
  };

  handleClientChange = (client: Client) => {
    this.currentForm.setClient(client.clientId);
  };

  handleContractChange = (contract?: ApprovedClientContract) => {
    if(contract) {
      this.currentForm.setApprovedContractId(contract.approvedContractId);
    }
    else {
      this.currentForm.setApprovedContractId('');
    }
  };

  handleInspectionChange = (inspection: Inspection) => {
    if(this.currentForm.inspections.length > 0) {
      this.currentForm.removeReferencesFromInspection();
      this.currentForm.inspections[0].setValue(inspection.inspectionId);

      const currentLinks = this.currentForm.references.map(reference => reference.link);
      inspection.references.map(reference => {
        if(!currentLinks.includes(reference.link)) {
          this.currentForm.addReferenceLinkWithValue(reference.link, reference.comment, true);
        }
      })
      // 선택한 검수서의 references값으로 주요링크값 미리 지정.
    }
  };

  handleSubmit = async () => {
    if (this.isCreateSuccess) {
      AppToaster.show({
        message: "이미 저장된 그룹입니다",
        intent: Intent.DANGER
      });

      return;
    }

    this.isPending = true;

    try {
      await this.formStore.postProjectGroup();
      AppToaster.show({
        message: "새로운 프로젝트 그룹이 생성되었습니다.",
        intent: Intent.SUCCESS
      });
      this.isCreateSuccess = true;
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    } finally {
      this.isPending = false;
    }
  };

  handleCancel = () => {
    if (this.props.mode === FormMode.Create) {
      this.props.history.push("/project-groups");
    } else {
      this.props.history.push(
        `/project-groups/${this.props.match.params.projectGroupId}`
      );
    }
  };

  handleEditSubmit = async () => {
    this.isPending = true;

    try {
      await this.formStore.patchProjectGroup();
      AppToaster.show({
        message: "수정되었습니다.",
        intent: Intent.SUCCESS
      });

      this.isEditSuccess = true;
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    } finally {
      this.isPending = false;
    }
  };

  render() {
    if (!this.isFormInitiated) {
      return <Spinner />;
    }

    if (this.isCreateSuccess || this.isEditSuccess) {
      return (
        <Redirect
          to={`/project-groups/${this.currentForm.groupId}`}
          push={true}
        />
      );
    }

    return (
      <Wrapper>
        <FormContainer>
          <FormSection>
            <TemporaryStorage keyName={"ProjectGroup"} title={"name"} setForm={(val) => {
              this.formStore.setForm(val);
            }} currentForm ={this.currentForm} />
            <FormGroup label={<strong>프로젝트 그룹 번호 (자동생성)</strong>}>
              <InputGroup
                value={this.currentForm.groupId}
                onChange={this.handleGroupIdChange}
                disabled={ this.isHiddenMode ? false : true}
              />
            </FormGroup>
            <FormGroup label={<strong>프로젝트 그룹 명</strong>}>
              <InputGroup
                value={this.currentForm.name}
                onChange={this.handleNameChange}
              />
            </FormGroup>
            <FormGroup label={<strong>클라이언트 선택</strong>}>
              <ClientSelection
                onChange={this.handleClientChange}
                currentValue={this.selectedClient}
              />
            </FormGroup>
            <FormGroup label={<strong>계약서</strong>}>
              <ApprovedClientContractSelection
                currentValue={this.selectedContract}
                onChange={this.handleContractChange}
              />
              <ClearIcon
                iconSize={14}
                icon={'eraser'}
                onClick={() => this.handleContractChange()} />
            </FormGroup>
            <FormGroup label={<strong>의뢰서</strong>}>
              <AddItemButtonContainer>
                <AddItemButton
                  onClick={this.currentForm.addReceptionSelection}
                />
              </AddItemButtonContainer>
              {this.currentForm.receptions.map(reception => (
                <ItemRow key={reception.id}>
                  <RemoveButtonContainer>
                    <RemoveButton
                      onClick={() =>
                        this.currentForm.removeReceptionSelection(reception)
                      }
                    />
                  </RemoveButtonContainer>
                  <ProjectGroupReceptionSelection
                    key={reception.id}
                    receptionSelection={reception}
                  />
                </ItemRow>
              ))}
            </FormGroup>
            <FormGroup label={<strong>영업상담</strong>}>
              <AddItemButtonContainer>
                <AddItemButton onClick={this.currentForm.addConsultSelection} />
              </AddItemButtonContainer>
              {this.currentForm.consults.map(consult => (
                <ItemRow key={consult.id}>
                  <RemoveButtonContainer>
                    <RemoveButton
                      onClick={() =>
                        this.currentForm.removeConsultSelection(consult)
                      }
                    />
                  </RemoveButtonContainer>
                  <ProjectGroupConsultSelection
                    key={consult.id}
                    consultSelection={consult}
                  />
                </ItemRow>
              ))}
            </FormGroup>
            <FormGroup label={<strong>검수서
              <div style={{fontSize: '12px', paddingTop: '8px', color: 'grey'}}>
                선택한 검수서에 검수자료가 존재 할 경우, 아래 '주요링크'에 자동으로 세팅됩니다.
              </div></strong>}>

              <InspectionSelection
                blockFetchingData={ true }
                onChange={this.handleInspectionChange}
                currentValue={this.selectedInspection}
              />
              
            </FormGroup>
            <FormGroup label={<strong>담당자</strong>}>
              <AddItemButtonContainer>
                <AddItemButton onClick={this.currentForm.addWorker} />
              </AddItemButtonContainer>
              {this.currentForm.workers.map((worker, index) => (
                <ItemRow key={index}>
                  <RemoveButtonContainer>
                    <RemoveButton
                      onClick={() => this.currentForm.removeWorker(worker)}
                    />
                  </RemoveButtonContainer>
                  <ProjectGroupWorkerFormView
                    client={this.selectedClient}
                    worker={worker} />
                </ItemRow>
              ))}
            </FormGroup>
            <FormGroup label={<strong>매니저</strong>}>
              <AddItemButtonContainer>
                <AddItemButton onClick={this.currentForm.addManager} />
              </AddItemButtonContainer>
              {this.currentForm.managers.map((manager, index) => (
                <ItemRow key={index}>
                  <RemoveButtonContainer>
                    <RemoveButton
                      onClick={() => this.currentForm.removeManager(manager)}
                    />
                  </RemoveButtonContainer>
                  <ProjectGroupManagerFormView manager={manager} />
                </ItemRow>
              ))}
            </FormGroup>
            <FormGroup label={<strong>주요링크</strong>}>
              <AddItemButtonContainer>
                <AddItemButton onClick={this.currentForm.addReferenceLink} />
              </AddItemButtonContainer>
              {this.currentForm.references.map((reference, index) => (
                <ReferenceLinkFormView
                  key={index}
                  referenceLink={reference}
                  onRemove={this.currentForm.removeReferenceLink}
                />
              ))}
            </FormGroup>

            {
              // (this.currentForm.dateComplete || this.currentForm.dateHolding) &&
                <>
                  <FormGroup label={<strong>프로젝트홀딩일</strong>}>
                    <DateInput
                      {...getMomentFormatter("YYYY/MM/DD")}
                        locale="ko"
                        canClearSelection={true}
                        closeOnSelection={true}
                        value={
                          this.currentForm.dateHolding ? moment(this.currentForm.dateHolding + "").toDate() : null
                        }
                        onChange={(selectedDate: Date) => this.currentForm.setDateHolding(selectedDate ? moment(selectedDate).format("YYYY-MM-DD") : '')}
                        maxDate={new Date("2050-01-01")}
                    />
                  </FormGroup>

                  <Card>

                    {
                      !this.currentForm.dateComplete &&
                        <div
                          className={Classes.TEXT_SMALL}
                          style={{marginBottom: '5px', color: Colors.BLUE2}}>
                          <div>아래 운영일정 섹션은 종료처리 이후 편집 가능합니다.</div>
                          <div>종료처리는 상세 페이지의 '종료'버튼을 이용해주세요.</div>
                        </div>
                    }
                    

                  <FormGroup label={<>
                    <strong>프로젝트종료일</strong>
                    {
                      this.currentForm.dateComplete &&
                        <span style={{fontSize: '12px', marginLeft: '5px'}}>
                          (<Icon icon={'info-sign'} iconSize={12} intent={Intent.WARNING} /> 종료일 삭제 시, 등록된 하자/유지보수 관련 일정이 존재할 경우, 함께 삭제됩니다.)
                        </span>
                    }
                    
                  </>}>
                    <DateInput
                      {...getMomentFormatter("YYYY/MM/DD")}
                        locale="ko"
                        canClearSelection={true}
                        closeOnSelection={true}
                        value={
                          this.currentForm.dateComplete ? moment(this.currentForm.dateComplete + "").toDate() : null
                        }
                        onChange={ (selectedDate: Date) => {
                          if(selectedDate) {
                            this.currentForm.setDateComplete(moment(selectedDate).format("YYYY-MM-DD"));
                          } else {
                            this.currentForm.setDateComplete('');
                            this.currentForm.setDateFlawing('');
                            this.currentForm.setDateFlawingComplete('');
                            this.currentForm.setDateKeeping('');
                            this.currentForm.setDateKeepingComplete('');
                          }
                        } }
                        maxDate={new Date("2050-01-01")}
                        disabled={ !this.currentForm.dateComplete }
                    />
                  </FormGroup>

                  <FormGroup label={<strong>프로젝트하자보수 시작일~종료일</strong>}>
                    <DateInput
                      {...getMomentFormatter("YYYY/MM/DD")}
                        locale="ko"
                        canClearSelection={true}
                        closeOnSelection={true}
                        value={
                          this.currentForm.dateFlawing ? moment(this.currentForm.dateFlawing + "").toDate() : null
                        }
                        onChange={(selectedDate: Date) => this.currentForm.setDateFlawing(selectedDate ? moment(selectedDate).format("YYYY-MM-DD") : '')}
                        maxDate={new Date("2050-01-01")}
                        disabled={ !this.currentForm.dateComplete }
                    />
                    ~
                    <DateInput
                      {...getMomentFormatter("YYYY/MM/DD")}
                        locale="ko"
                        canClearSelection={true}
                        closeOnSelection={true}
                        value={
                          this.currentForm.dateFlawingComplete ? moment(this.currentForm.dateFlawingComplete + "").toDate() : null
                        }
                        onChange={(selectedDate: Date) => this.currentForm.setDateFlawingComplete(selectedDate ? moment(selectedDate).format("YYYY-MM-DD") : '')}
                        maxDate={new Date("2050-01-01")}
                        disabled={ !this.currentForm.dateComplete }
                    />
                  </FormGroup>

                  <FormGroup label={<strong>프로젝트유지보수 시작일~종료일</strong>}>
                    <DateInput
                      {...getMomentFormatter("YYYY/MM/DD")}
                        locale="ko"
                        canClearSelection={true}
                        closeOnSelection={true}
                        value={
                          this.currentForm.dateKeeping ? moment(this.currentForm.dateKeeping + "").toDate() : null
                        }
                        onChange={(selectedDate: Date) => this.currentForm.setDateKeeping(selectedDate ? moment(selectedDate).format("YYYY-MM-DD") : '')}
                        maxDate={new Date("2050-01-01")}
                        disabled={ !this.currentForm.dateComplete }
                    />
                    ~
                    <DateInput
                      {...getMomentFormatter("YYYY/MM/DD")}
                        locale="ko"
                        canClearSelection={true}
                        closeOnSelection={true}
                        value={
                          this.currentForm.dateKeepingComplete ? moment(this.currentForm.dateKeepingComplete + "").toDate() : null
                        }
                        onChange={(selectedDate: Date) => this.currentForm.setDateKeepingComplete(selectedDate ? moment(selectedDate).format("YYYY-MM-DD") : '')}
                        maxDate={new Date("2050-01-01")}
                        disabled={ !this.currentForm.dateComplete }
                    />
                  </FormGroup>

                  </Card>
                </>
            }

              
          </FormSection>
        </FormContainer>

        <ContentContainer>
          {this.props.mode === FormMode.Create && (
            <ButtonsRow>
              <Button text="취소" onClick={this.handleCancel} />
              <Button
                text="저장"
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit}
                loading={this.isPending}
              />
            </ButtonsRow>
          )}

          {this.props.mode === FormMode.Edit && (
            <ButtonsRow>
              <Button text="취소" onClick={this.handleCancel} />
              <Button
                text="수정사항 적용"
                intent={Intent.PRIMARY}
                onClick={this.handleEditSubmit}
                loading={this.isPending}
              />
            </ButtonsRow>
          )}
        </ContentContainer>
      </Wrapper>
    );
  }
}

export default ProjectGroupFormPage;