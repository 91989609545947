/*
  파트너 회원 승인/거절 취소 팝업
*/
import React from 'react';
import { Alert, IAlertProps, Intent } from '@blueprintjs/core';

const RevokePopup = (props: IAlertProps) => {
  return (
    <Alert
      confirmButtonText="확인"
      cancelButtonText="취소"
      intent={ Intent.WARNING } 
      { ...props }
    >
      <p>
        승인(거절)을 취소하시겠습니까?
      </p>
    </Alert>
  )
}
export default RevokePopup;