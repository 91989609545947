import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { FormMode } from "../../../types/formMode";

import { 
    Alert,
    Intent,
    Menu,
    MenuItem,
    Popover,
    Position
} from "@blueprintjs/core";

import { 
    Wrapper,
    SecondaryButton,
    StyledCard, 
    CardTitle, 
    CardContent, 
    Table, 
    TableRow, 
    HeadColumn, 
    ContentColumn,
    GrayLabel,
    EmphasizedText
} from "../../atoms/ParttimeComponents/ParttimeComponents";

import { AppToaster } from "../../organisms/AppToaster/AppToaster";

import { Task} from "../../../store/models/Parttime";
import RufreeMatchDialog from "../../molecules/ParttimeDialog/RufreeMatchDialog";
import {Link} from "react-router-dom";

interface RepresentativePanelProps extends RouteComponentProps {
  task: Task;
  updateTask: (updatedTask: Task | any) => void;
}

interface InjectedProps extends RepresentativePanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class RepresentativePanel extends Component<RepresentativePanelProps> {
    @observable isRufreeMatchDialogOpen: boolean = false;
    @observable showRufreeMatchCancelAlert: boolean = false;

  get injected() {
    return this.props as InjectedProps;
  }

  handleRufreeMatchDialogOpen = () => {
    this.isRufreeMatchDialogOpen = true;
  };

  handleRufreeMatchDialogClose = async() => {
    const { task, updateTask } = this.props;
    this.isRufreeMatchDialogOpen = false;

    // Update parent's state
    const updatedTask = await this.injected.appStore.parttimeTaskStore.fetchTaskById(task.request.id, task.id);
    updateTask(updatedTask);
  }; 

  handleRufreeMatchCancelSubmit = async () => {
    try {
        const { task, updateTask } = this.props;
        await this.injected.appStore.parttimeTaskFormStore.cancelRufreeMatch(task.request.id, task.id);
        this.showRufreeMatchCancelAlert = false;
        
        // Update parent's state
        const updatedTask = await this.injected.appStore.parttimeTaskStore.fetchTaskById(task.request.id, task.id);
        updateTask(updatedTask);
        
        AppToaster.show({
            message: "알유프리 매칭이 취소 되었습니다.",
            intent: Intent.SUCCESS
        });
    } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
            message: "오류: " + error,
            intent: Intent.DANGER
        });
    }    
  }

  render() {
    const { isPM } = this.injected.appStore.userStore;
    const { task } = this.props;

    return (
        <Wrapper>
            <StyledCard>
            <CardTitle>시소매니저</CardTitle>
            <CardContent>
                <Table>
                    <TableRow>
                    <HeadColumn>
                        <GrayLabel>이름</GrayLabel>
                    </HeadColumn>
                    <ContentColumn>
                        <EmphasizedText>{ (task.manager && task.manager.name) ? task.manager.name : '-' }</EmphasizedText>
                    </ContentColumn>
                    </TableRow>
                    <TableRow>
                    <HeadColumn>
                        <GrayLabel>연락처</GrayLabel>
                    </HeadColumn>
                    <ContentColumn>
                        <EmphasizedText>{ (task.manager && task.manager.phone) ? task.manager.phone : '-' }</EmphasizedText>
                    </ContentColumn>
                    </TableRow>
                    <TableRow>
                    <HeadColumn>
                        <GrayLabel>이메일</GrayLabel>
                    </HeadColumn>
                    <ContentColumn>
                        <EmphasizedText>{ (task.manager && task.manager.email) ? task.manager.email : '-' }</EmphasizedText>
                    </ContentColumn>
                    </TableRow>
                </Table>
            </CardContent>
            </StyledCard>

            <StyledCard>
            <CardTitle>정산요청대상</CardTitle>
            <CardContent>
                <Table>
                    <TableRow>
                    <HeadColumn>
                        <GrayLabel>이름</GrayLabel>
                    </HeadColumn>
                    <ContentColumn>
                        <EmphasizedText>{ task.clientName ? task.clientName : '-' }</EmphasizedText>
                    </ContentColumn>
                    </TableRow>
                </Table>
            </CardContent>
            </StyledCard>

            <StyledCard>
                <CardTitle>
                    알유프리
                    { isPM && task.rufree && (
                        <div style={{float: "right"}}>
                            <Popover 
                                content={
                                    <Menu>
                                        <MenuItem text="알유프리 수정" onClick={this.handleRufreeMatchDialogOpen}/>
                                        <MenuItem text="알유프리 매칭 취소" onClick={() => {this.showRufreeMatchCancelAlert=true;}}/>
                                    </Menu>
                                } 
                                position={Position.RIGHT_TOP}>
                                <SecondaryButton icon="more"/>
                            </Popover>
                        </div>
                    )}
                </CardTitle>
                <CardContent>
                    { task.rufree ? (
                        <Table>
                            <TableRow>
                            <HeadColumn>
                                <GrayLabel>알유프리</GrayLabel>
                            </HeadColumn>
                            <ContentColumn>
                                <EmphasizedText>
                                    <Link to={`/rufrees?rufree_id=${task.rufree.rufreeId}`} target="_blank">
                                    { task.rufree.name ? `${task.rufree.name}(${task.rufree.rufreeId})` : '-' }
                                    </Link>
                                </EmphasizedText>
                            </ContentColumn>
                            </TableRow>
                            <TableRow>
                            <HeadColumn>
                                <GrayLabel>연락처</GrayLabel>
                            </HeadColumn>
                            <ContentColumn>
                                <EmphasizedText>{ task.rufree.phone ? task.rufree.phone : '-' }</EmphasizedText>
                            </ContentColumn>
                            </TableRow>
                            <TableRow>
                            <HeadColumn>
                                <GrayLabel>이메일</GrayLabel>
                            </HeadColumn>
                            <ContentColumn>
                                <EmphasizedText>{ task.rufree.email ? task.rufree.email : '-' }</EmphasizedText>
                            </ContentColumn>
                            </TableRow>
                        </Table>
                
                    ) : (
                        <>
                        알유프리 매칭이 완료되면 연락처가 오픈 됩니다.<br/>
                        { !isPM && "자세한 문의는 시소매니저에게 연락해 주세요." }
                        </>
                    )}
                    </CardContent>
            </StyledCard>

            <Alert 
                icon="undo"
                intent={Intent.DANGER}
                isOpen={this.showRufreeMatchCancelAlert}
                onCancel={() => this.showRufreeMatchCancelAlert=false}
                onConfirm={this.handleRufreeMatchCancelSubmit}
                confirmButtonText={'확인'}
                cancelButtonText={'닫기'}
            ><p>알유프리 매칭을 취소합니다.</p>
            </Alert>

            <RufreeMatchDialog
                task={ task }
                mode={ FormMode.Edit }
                onClose={ this.handleRufreeMatchDialogClose }
                isOpen={ this.isRufreeMatchDialogOpen } 
            />
        </Wrapper>
    );
  }
}

export default RepresentativePanel;
