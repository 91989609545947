import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Colors, Position, Text, Tooltip } from "@blueprintjs/core";
import moment from "moment";

import styled from "styled-components/macro";


const Wrapper = styled.div`
  min-height: 300px;
  margin-top: 50px;
  margin-bottom: 50px;
  
  table.bp3-html-table{
    font-size: 12px
  }

  table.bp3-html-table tbody td {
    border-bottom: solid 1px ${ Colors.LIGHT_GRAY1 };
  }

  table.bp3-html-table td {
    padding: 3px;
    color: ${ Colors.DARK_GRAY1 };
  }

  table.bp3-html-table td:nth-child(even) {
    width: 180px;
  }
  table.bp3-html-table td:nth-child(odd) {
    width: 110px;
    font-size: 11px;
  }

  table.bp3-html-table td:first-child {
    color: ${ Colors.DARK_GRAY5 };
    font-weight: bold;
    width: 20px;
  }

  span.bp3-popover-wrapper {
    display: block;
  }

  span.bp3-popover-wrapper + span.bp3-popover-wrapper {
    border-top: solid 1px ${ Colors.LIGHT_GRAY1 };
  }

  span.bp3-popover-wrapper:hover {
    background-color: rgba(191,204,214,.3);
    cursor: pointer;
  }
`;

const TextWrapper = styled(Text)`
  width: 180px;
`;

const Row = styled.div`
  display: flex;

  padding: 5px 0px;

  & > div:nth-child(even) {
    width: 180px;
    font-size: 12px
  }
  & > div:nth-child(odd) {
    width: 110px;
    font-size: 11px;
  }
  & > div:first-child {
    color: ${ Colors.DARK_GRAY5 };
    font-weight: bold;
    width: 30px;
  }
`;
const HeaderRow = styled(Row)`
  margin-bottom: 10px;
  font-weight: bold;
`;
const EmptyRow = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: 12px;
  color: ${ Colors.DARK_GRAY5 };
`;

const TooltipContentWrapper = styled.div`
  font-size: 11px;
`;


interface IItem {
  id: string;
  title: string;
  date: string;
}

interface IData {
  id: string;
  title: string;
  date: string;
  linked_item: {
    project: IItem[];
    inspection: IItem[];
    projectgroup: IItem[];
  }
}

const HeaderRowComponent: FC = () => {
  return (
    <HeaderRow>
      <div></div>
      <div>접수서</div>
      <div></div>
      <div>영업상담서</div>
      <div></div>
      <div>검수보고서(최초발행일)</div>
      <div></div>
      <div>프로젝트그룹(킥오프)</div>
      <div></div>
    </HeaderRow>
  )
}

const ProjectConversionRateTable: FC<{
  data: IData[]
}> = ({ data }) => {

  if(data.length === 0) {
    return (
      <Wrapper>
        <HeaderRowComponent />
        <EmptyRow>
          * 해당 기간에 접수된 의뢰서가 없습니다.
        </EmptyRow>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <HeaderRowComponent />

      {
        data.map( (data, i) => {
          const { project, inspection, projectgroup } = data.linked_item;
          const reception_date = moment(data.date);
          const project_date = project.length > 0 ? moment(project[0].date) : null;
          const inspection_date = inspection.length > 0 ? moment(inspection[0].date) : null;
          const projectgroup_date = projectgroup.length > 0 && projectgroup[0].date ? moment(projectgroup[0].date) : null;

          const project_diff = project_date ? moment.duration(project_date.diff(reception_date)) : 0;
          const inspection_diff = project_date && inspection_date ? moment.duration(inspection_date.diff(project_date)) : 0;
          const kickoff_diff = inspection_date && projectgroup_date ? moment.duration(projectgroup_date.diff(inspection_date)) : 0;

          const tooltipContent = <TooltipContentWrapper>
            <p>
              의뢰서
              { project_diff ? <strong>> { project_diff.days() > 0 && `${project_diff.days()}일`} {project_diff.hours()}시간</strong> : `` } > 영업상담서
              { inspection_diff ? <strong>> { inspection_diff.days() > 0 && `${inspection_diff.days()}일`} {inspection_diff.hours()}시간</strong> : `` } > 검수서
              { kickoff_diff ? <strong>> { kickoff_diff.days() > 0 && `${kickoff_diff.days()}일`} {kickoff_diff.hours()}시간</strong> : `` } > 킥오프
            </p>
            <strong>의뢰서:</strong> { data.title } ({ reception_date.format('YYYY-MM-DD HH:mm') })<br/>
            <strong>영업상담서</strong>: { project_date ? `${project[0].title} (${ project_date.format('YYYY-MM-DD HH:mm') })` : ''}<br/>
            <strong>검수서</strong>: { inspection_date ? `${inspection[0].title} (${ inspection_date.format('YYYY-MM-DD HH:mm') })` : ''}<br/>
            <strong>킥오프</strong>: { projectgroup.length > 0 ? `${projectgroup[0].title} (${ projectgroup_date ? projectgroup_date.format('YYYY-MM-DD HH:mm') : '미정' })` : ''}

          </TooltipContentWrapper>
          return (
            <Tooltip
              key={ i }
              content={tooltipContent}
              position={Position.BOTTOM}
              usePortal={false}
            >
              <Row>
                <div>{ i + 1 }</div>
                <div>
                  <Link to={`/receptions/${ data.id }`} target="_blank">
                    <TextWrapper ellipsize={true}>{ data.title }</TextWrapper>
                  </Link>
                </div>
                <div>
                  { reception_date.format('YYYY-MM-DD HH:mm') }
                </div>

                { project_date ? 
                  <>
                    <div>
                      <Link to={`/consulting/projects/${ project[0].id }`} target="_blank">
                        <TextWrapper ellipsize={true}>{ project[0].title }</TextWrapper>
                      </Link>
                    </div>
                    <div> { project_date.format('YYYY-MM-DD HH:mm') }</div>
                  </> : <>
                    <div></div>
                    <div></div>
                  </>
                }

                {
                  inspection_date ? 
                    <>
                      <div>
                        <Link to={`/inspections/${ inspection[0].id }/read`} target="_blank">
                          <TextWrapper ellipsize={true}>{ inspection[0].title }</TextWrapper>
                        </Link>
                      </div>
                      <div> { inspection_date.format('YYYY-MM-DD HH:mm') } </div>
                    </> : <>
                      <div></div>
                      <div></div>
                    </>
                  }
                
                {
                  projectgroup.length > 0 ? 
                    <>
                      <div>
                        <Link to={`/project-groups/${ projectgroup[0].id }`} target="_blank">
                          <TextWrapper ellipsize={true}>{ projectgroup[0].title }</TextWrapper>
                        </Link>
                      </div>
                      <div> { projectgroup[0].date ? moment(projectgroup[0].date).format('YYYY-MM-DD HH:mm') : '미정' } </div>
                    </> : <>
                      <div></div>
                      <div></div>
                    </>
                  }
              </Row>
            </Tooltip>
          )
        })
      }
    </Wrapper>
  );
}

export default ProjectConversionRateTable;