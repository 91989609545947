import React, { FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components/macro";
import { Sprint } from "../../../store/models/Sprint";
import { SprintReport } from "../../../store/models/SprintReport";
import { SeesoService } from "../../../store/models/SeesoService";
import { ProjectGroup } from "../../../store/models/ProjectGroup";
import { Icon, EditableText, Intent } from "@blueprintjs/core";
import { FormMode } from "../../../types/formMode";
import moment from "moment";

import IconRightChevron from "./right-chevron.svg";
import IconDownload from "./download.svg";
import IconEnvelope from "./envelope.svg";



const Wrapper = styled.div`
  .bp3-icon { vertical-align: middle !important; }
  a { color: #000000; }
  a:hover { text-decoration: underline }
  .bp3-editable-text { color: #d9822b; font-weight: 500; }
  .inspection-icon {
    display: inline-block;
    position: absolute;
    left: 65px;
    top: 5px;
    width: 15px;
    height: 15px;
    background: url(${IconRightChevron}) no-repeat center center;
  }
`;


interface Props {
  mode: FormMode;
  isGenerateHtml: boolean;
  projectGroup: ProjectGroup;
  sprintNumber: number;
  inspectionId?: string;
  sprints: Sprint[];
  report: SprintReport | undefined;
  datePublish: string | null;
  postfix: string;
  comment: string;
  roles: string[];
  rufrees: string[];
  services: string[];
  seesoServices: SeesoService[];
  handleChangePostfix: (postfix: string) => void;
  handleChangeComment: (comment: string) => void;
  handleChangeRoles: (roles: string[], rufrees: string[]) => void;
  handleResetRoles: () => void;
  handleChangeServices: (services: string[]) => void;
  handleResetServices: () => void;
}

const ReportTemplate: FC<Props> = observer( props => {
  const {
    projectGroup,
    inspectionId,
    report,
    sprints,
    sprintNumber,
    postfix,
    comment,
    roles,
    rufrees,
    services,
    seesoServices,
    datePublish,
    handleChangePostfix,
    handleChangeComment,
    handleChangeRoles,
    handleResetRoles,
    handleChangeServices,
    handleResetServices
  } = props;
  const client = projectGroup.clientName;
  const projectGroupName = projectGroup.name;
  const pm = projectGroup.managers[0].name;
  const isEditMode = props.mode === FormMode.Read ? false : true;
  const editedSprints = roles.map(role => sprints.find(sprint => sprint.role === role));
  const editedServices = services.map(role => seesoServices.find(service => service.service === role));
  const totalRole = editedSprints.reduce( (acc, cur) => acc + (cur ? cur.payment+cur.commission : 0), 0 );
  const totalService = editedServices ? editedServices.reduce( (acc, cur) => acc + (cur ? cur.cost * (cur.individual ? editedSprints.length : 1) : 0), 0 ) : 0;

  if(!props.isGenerateHtml && props.mode === FormMode.Read && report) {
    return <div dangerouslySetInnerHTML={{__html: report.htmlData}} />
  }

  return (
    <Wrapper
      style={{
        backgroundColor: '#ffffff'
      }}>
        <section
          className='top'
          style={{
            'height': '124px',
            'backgroundColor': '#1effc5',
            'fontSize': '18px',
            'fontWeight': 'bold',
          }}>
          <div
          style={{
            position: 'relative',
              left: '40px',
              top: '30px',
              lineHeight: '28px'
          }}>{ client }</div>
          <div
            style={{
              position: 'relative',
              left: '40px',
              top: '37px',
              lineHeight: '28px'
            }}
          >{ projectGroupName }</div>
        </section>


        <section
          style={{
            'padding': '30px 40px 0 40px'
          }}>

          <div style={{
            'fontSize': '22px',
            'fontWeight': 'bold',
            'marginBottom': '30px'
          }}>
            <span style={{
            }}>
              <span>스프린트 보고서</span>
              <span style={{
                'paddingLeft': '15px'
              }}>{ sprintNumber }</span>
              <span style={{ 'paddingLeft': '15px' }}>
              {
                isEditMode ?
                  <EditableText
                    intent={Intent.WARNING}
                    placeholder='파일명(optional)'
                    minWidth={250}
                    onChange={(newPostfix: string) => {
                      handleChangePostfix(newPostfix);
                    }}
                    value={postfix}
                  />
                  : <span>{ postfix }</span>
              }
              </span>
            </span>
            
            <span
              style={{
                fontSize: '14px',
                fontWeight: 'normal',
                float: 'right',
                padding: '13px 0px 0px 0px',
                width: '300px',
                textAlign: 'right'
              }}>
              <span style={{ marginRight: '8px' }}>발행일</span>
                {
                  props.mode === FormMode.Read
                    ? datePublish && <span>
                      { moment(datePublish).format('YYYY.MM.DD HH:mm') }
                    </span>
                    : report && <span style={{fontSize: '10px', color: '#5C7080'}}>
                      임시저장중
                    </span>
                }
              <span
                style ={{
                  margin: '0px 12px 0 12px'
              }}>|</span>
              <span style={{ marginRight: '8px' }}>담당 PM</span>
              <span>{ pm }</span>
            </span>
          </div>


          <div>
            <div style={{
              'display': 'flex',
              'justifyContent': 'space-between',
              'fontSize': '16px',
              'fontWeight': 'bold',
              'color': '#5834ff',
              'marginBottom': '15px'
            }}>스프린트 리뷰</div>

            <div>
              <section>
                <div
                  style={{
                    fontSize: '14px',
                    fontWeight: 500,
                    marginBottom: '11px',
                    display: 'flex'
                  }}
                >
                  <div>종료일정</div>
                  {
                    isEditMode &&
                      <div
                        style={{ fontSize: '12px', fontWeight: 'bold', cursor: 'pointer', color: '#d9822b', margin: '3px 0px 0px 10px' }}
                        onClick={ handleResetRoles }>초기화</div>
                  }
                </div>

                <div style={{
                  borderTop: '1px solid #bbbbbb',
                  borderBottom: '1px solid #bbbbbb'
                }}>
                  { 
                    editedSprints.map((sprint, i) => (
                      sprint &&
                      <div
                        key={i}
                        style={ Object.assign(
                          {
                            lineHeight: '46px',
                            fontSize: '13px',
                            fontWeight: 'normal'
                          },
                          i > 0 ? {'borderTop': '1px solid #ebebeb'} : {}
                        )}
                      >
                        <span style={{ width: '14px', display: 'inline-block' }}>
                          {
                            isEditMode &&
                              <Icon
                                icon='small-minus'
                                color='#d9822b'
                                iconSize={12}
                                onClick={ () => {
                                  let newRoles = Object.assign([], roles);
                                  const idx = roles.findIndex(item => item === sprint.role);
                                  if (idx > -1) newRoles.splice(idx, 1);

                                  let newRufrees = Object.assign([], rufrees);
                                  const idx_r = rufrees.findIndex(item => item === sprint.rufreeName);
                                  if (idx_r > -1) newRufrees.splice(idx_r, 1);
                                  handleChangeRoles(newRoles, newRufrees);
                                } } />
                          }
                        </span>
                        <span style={{'width': '146px', display: 'inline-block'}}>{ sprint.rufreeName }</span>
                        <span style={{'width': '380px', display: 'inline-block'}}>{ sprint.role }</span>
                        <span className='column date'>
                          { moment(sprint.dateStart).format('YYYY.MM.DD') } - { moment(sprint.expectedDateEnd).format('YYYY.MM.DD') }</span>
                      </div>
                    ))
                  }
                </div>
              </section>

              <section>
                <div
                  style={{
                    fontSize: '14px',
                    fontWeight: 500,
                    margin: '25px 0px 11px 0px',
                  }}
                >코멘트</div>

                <div
                  style={{
                    'borderTop': '1px solid #bbbbbb',
                    'borderBottom': '1px solid #bbbbbb'
                  }}
                >
                  <div
                    style={{
                      'padding': '13px 15px 14px 15px'
                    }}
                  >
                  {
                    isEditMode ?
                      <EditableText
                        intent={Intent.WARNING}
                        minLines={3}
                        multiline={true}
                        placeholder='코멘트를 입력하세요.'
                        onChange={ (comment: string) => {
                          handleChangeComment(comment);
                        } }
                        value={ comment }
                      /> : <div
                            style={{
                              'fontSize': '14px'
                            }}>
                        {
                          comment.split('\n').map(line => <div>{line}</div>)
                        }
                      </div>
                  }
                  </div>
                </div>
              </section>
            </div>
          </div>

          <div>
            <div style={{
              fontSize: '16px',
              fontWeight: 'bold',
              color: '#5834ff',
              margin: '50px 0px 15px 0px'
            }}>
              <span>스프린트 비용 청구 내역</span>
              {
                isEditMode &&
                  <span
                    style={{ fontSize: '12px', cursor: 'pointer', color: '#d9822b', marginLeft: '10px' }}
                    onClick={ handleResetServices }>초기화</span>
                }
                {
                  inspectionId &&
                    <div style={{
                      fontSize: '13px',
                      fontWeight: 'bold',
                      color: '#000000',
                      float: 'right',
                      padding: '5px 0px 0px 0px',
                      position: 'relative',
                      width: '85px'
                    }}>
                      <a
                        style={{
                          color: '#000000',
                          position: 'absolute',
                          left: 0
                        }}
                        href={`http://seeso.surge.sh/inspection/${inspectionId}`}
                        target='_blank'>플랜 보기</a>
                      <span
                        className={'inspection-icon'} />
                    </div>
                }
            </div>

            <div>
              <section>
                <div style={{ borderBottom: '1px solid #bbbbbb' }}>
                  <div
                    style={{
                      paddingTop: '7px',
                      fontSize: '14px',
                      height: '32px',
                      backgroundColor: '#fafafa',
                      borderBottom: '1px solid #bbbbbb'
                    }}
                  >
                    <span
                      style={{
                        'width': '90px', 'fontSize': '14px',
                        'paddingLeft': '15px', display: 'inline-block'
                      }}>투입 인력</span>
                    <span
                      style={{
                        'width': '110px', 'fontSize': '14px',
                        display: 'inline-block'
                      }}>종류</span>
                    <span
                      style={{
                        'width': '205px', 'fontSize': '14px',
                        display: 'inline-block'
                      }}>내역</span>
                    <span
                      style={{
                        'width': '60px', 'fontSize': '14px',
                        display: 'inline-block'
                      }}>x인원수</span>
                    <span
                      style={{
                        'width': '120px', 'fontSize': '14px',
                        display: 'inline-block'
                      }}>금액</span>
                    <span
                      style={{
                        'width': '120px', 'fontSize': '14px',
                        'fontWeight': 'bold', display: 'inline-block'
                      }}>VAT포함</span>
                  </div>

                  {
                    editedSprints.map((sprint, i) => (
                      sprint &&
                      <div
                        key={i}
                        style={ Object.assign(
                          {
                          lineHeight: '46px',
                          fontSize: '13px',
                          fontWeight: 'normal'
                        },
                        i > 0 ? {'borderTop': '1px solid #ebebeb'} : {}
                        )}
                      >
                        <span
                          style={{
                            'width': '90px',
                            'paddingLeft': '15px', display: 'inline-block'
                          }}>{ sprint.rufreeName }</span>
                        <span
                          style={{
                            'width': '110px',
                            display: 'inline-block'}}>알유프리</span>
                        <span
                          style={{
                            'width': '205px', display: 'inline-block'
                          }}>{ sprint.role }</span>
                        <span
                          style={{
                            'width': '60px', display: 'inline-block'
                          }}>1</span>
                        <span
                          style={{
                            'width': '120px',
                            display: 'inline-block'
                          }}> ￦ { (sprint.payment+sprint.commission).toLocaleString("en-US") }</span>
                        <span
                          style={{
                            'width': '120px',
                            'fontWeight': 'bold', display: 'inline-block'
                          }}>￦ { ((sprint.payment+sprint.commission) * 1.1).toLocaleString("en-US") }</span>
                      </div>
                    ))
                  }

                  {
                    editedServices &&
                    editedServices.map((service, i) => (
                      service &&
                      <div
                        key={i}
                        style={ Object.assign(
                          {
                          lineHeight: '46px',
                          fontSize: '13px',
                          fontWeight: 'normal'
                        },
                        i !== editedServices.length ? {'borderTop': '1px solid #ebebeb'} : {}
                        )}
                      >

                        {
                          isEditMode &&
                          <span style={{ width: '14px', display: 'inline-block' }}>
                            <Icon
                              icon='small-minus'
                              color='#d9822b'
                              iconSize={12}
                              onClick={ () => {
                                let newValues = Object.assign([], services);
                                const idx = services.findIndex(item => item === service.service);
                                if (idx > -1) newValues.splice(idx, 1);
                                handleChangeServices(newValues);
                              } } />
                          </span>
                        }

                        <span
                          style={{ width: `${isEditMode ? '75px' : '90px' }`, paddingLeft: `${isEditMode ? '0px' : '15px'}`, display: 'inline-block' }}>시소 서비스</span>
                        <span
                          style={{
                            'width': '110px', display: 'inline-block'}}>프로젝트 운영</span>
                        <span
                          style={{
                            'width': '205px', display: 'inline-block'
                          }}>{ service.service }</span>
                        <span
                          style={{
                            'width': '60px', display: 'inline-block'
                          }}>{ service.individual ? editedSprints.length : 1 }</span>
                        <span
                          style={{
                            'width': '120px', display: 'inline-block'
                          }}> ￦ { (service.cost * (service.individual ? editedSprints.length : 1)).toLocaleString("en-US") }</span>
                        <span
                          style={{
                            'width': '120px',
                            'fontWeight': 'bold', display: 'inline-block'
                          }}> ￦ { (service.cost * (service.individual ? editedSprints.length : 1) * 1.1).toLocaleString("en-US") }</span>
                      </div>
                    ))
                  }
                </div>

                <div style={{
                  'margin': '10px 0px 15px 0px',
                  'textAlign': 'right',
                  'fontSize': '12px',
                  'fontWeight': 500
                }}>
                  <span>총 청구액</span>
                  <span
                    style={{
                      'marginLeft': '15px',
                      'fontSize': '18px',
                      'fontWeight': 'bold'
                    }}>{ ((totalRole + totalService) * 1.1).toLocaleString("en-US") }원</span>
                </div>

                <div
                  style={{
                    textAlign: 'right',
                    fontSize: '11px',
                    fontWeight: 'normal',
                    color: '#666666',
                    paddingBottom: '60px'
                  }}
                >
                  <div>
                    * 스프린트 보고서 발송일 기준 1~2일 내 해당 비용에 대한 세금계산서가 발행됩니다.<br/>
                    세금계산서는 발행 후 담당자 이메일로 전송됩니다. (단, 충전금을 사용하는 경우 제외)
                  </div>
                </div>
              </section>

            </div>
          </div>
        </section>
    </Wrapper>
  );
})

export default ReportTemplate;
