import React, { Component, ChangeEvent, KeyboardEvent } from "react";

import { observable, computed } from "mobx";
import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import { Button, Icon, TextArea, Intent, Alert } from "@blueprintjs/core";
import { FocusStyleManager } from "@blueprintjs/core";

import moment from "moment";

import { AppStore } from "../../../store/AppStore";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";

const TaskContainer = styled.div`
  position: relative;
  border-radius: 3px;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  padding: 10px;
  cursor: pointer;
  max-width: 250px;
  margin-bottom: 7px;
  min-width: 230px;
  &:hover {
    background-color: #f0f0f0;
    color: #000;
    cursor: grab;
  }
  user-select: none;
`;
const TaskHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;

  color: #000;
`;
const TaskTitle = styled.div`
  width: 100%;
  padding: 5px 0px;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  white-space: normal;
  word-break: break-all;
`;
const TaskFooter = styled.div`
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;  

  color: #000;
`;
const TaskTag = styled.span`
  border-radius: 3px;
`;
const TaskHeaderTag = styled(TaskTag as any)`
  padding: 1px 5px;
  margin-right: 2px;
  margin-bottom: 2px;
`;
const TaskDueDate = styled(TaskHeaderTag as any)`
  background-color: #f3e1e1;
`;
const TaskDueDateOver = styled(TaskHeaderTag as any)`
  color: white;
  background-color: #ff5200;
`;
const TaskSprint = styled(TaskHeaderTag as any)`
  background-color: #abf0e9;
`;
const TaskLabel = styled(TaskHeaderTag as any)`
  background-color: #ffe277;
`;
const TaskPersonInCharge = styled(TaskHeaderTag as any)`
  color: white;
  background-color: green;
`;
const TaskFooterTag = styled(TaskTag as any)`
  padding: 6px 10px 6px 1px;
`;
const TaskSubTask = styled(TaskFooterTag as any)`
`;
const TaskAttatchment = styled(TaskFooterTag as any)`
`;
const TaskComment = styled(TaskFooterTag as any)`
`;

// 마우스 오버시 나타나는 수정 버튼 컨테이너
const TaskEditButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;

  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 30px;
  z-index: 2;
`;
const TaskEditButton = styled(Button)`
`;

// 태스크 제목 수정 에디터
const TitleEditor = styled(TextArea)`
`;

const TitleEditButton = styled(Button)`
`;

const TitleCancelButton = styled(Button)`
`;

interface TaskProps {
  title: string;
  person_in_charge_list: Array<number>;
  metadata: any;
  onClick: (e: any) => void;
}

interface InjectedProps extends TaskProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class Task extends Component<TaskProps> {
  @observable showEditButtons: boolean = false;
  @observable inputComponent: any = null;
  @observable title: string = '';
  @observable showDeleteAlert: boolean = false;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get store() {
    return this.injected.appStore.taskManagerStore;
  }

  @computed
  get board() {
    return this.store.currentBoard;
  }

  @computed
  get showTitleEditor() {
    return this.store.taskEditing.includes(this.props.metadata.taskId);
  }

  componentDidMount() {
    FocusStyleManager.onlyShowFocusOnTabs();

    this.title = this.props.title;
  }
  componentDidUpdate() {
    if(this.showTitleEditor &&
      this.inputComponent &&
      this.inputComponent !== document.activeElement
    ) {
      this.inputComponent.focus();
      this.inputComponent.select();
      this.inputComponent.onkeypress = this.handleOnKeyPress;
    }
  }

  toggleStartEditing = () => {
    this.store.setDraggable(false);
    this.store.addEditingTaskId(this.props.metadata.taskId);
  }

  toggleFinishEditing = () => {
    this.store.setDraggable(true);
    this.store.delEditingTaskId(this.props.metadata.taskId);
  }

  handleTitleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.title = e.target.value;
  };
  handleOnKeyPress = async(event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      if(this.title.trim() !== '') {
        this.handleSubmit();
      }
    }
  }
  handleCancel = () => {
    this.title = this.props.title;
    this.toggleFinishEditing();
  }
  handleSubmit = async () => {
    try {
      const { metadata: meta } = this.props;
      await this.injected.appStore.taskManagerStore.updateTaskTitle(meta.taskId, this.title);

      this.toggleFinishEditing();
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
      // 업데이트 중 오료 발생 시 DB의 최신값을 반영
      await this.injected.appStore.taskManagerStore.fetchTaskBoard(this.board ? this.board.projectGroup : '');
    }
  }
  handleRestoreSubmit = async () => {
    try {
      const { metadata: meta } = this.props;
      await this.injected.appStore.taskManagerStore.archiveTask(meta.taskId, false);

    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
      // 업데이트 중 오료 발생 시 DB의 최신값을 반영
      await this.injected.appStore.taskManagerStore.fetchTaskBoard(this.board ? this.board.projectGroup : '');
    }
  }
  handleDeleteSubmit = async (completeRemove: boolean) => {
    try {
      const { metadata: meta } = this.props;
      if(completeRemove) {
        await this.injected.appStore.taskManagerStore.deleteTask(meta.taskId);
        // 업데이트된 내용을 반영: (TODO) 향후 archiveTask 처럼 프론트에서 처리하는것으로 변경해야 함
        await this.injected.appStore.taskManagerStore.fetchTaskBoard(this.board ? this.board.projectGroup : '');
      } else {
        await this.injected.appStore.taskManagerStore.archiveTask(meta.taskId, true);
      }

      this.showDeleteAlert = false;

    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });

      // 업데이트 중 오료 발생 시 DB의 최신값을 반영
      await this.injected.appStore.taskManagerStore.fetchTaskBoard(this.board ? this.board.projectGroup : '');
    }
  }

  render() {
    const meta = this.props.metadata;
    let person_in_charge_list = this.board ?
      this.props.person_in_charge_list.map((person_in_charge_id) => {
        return this.board ? this.board.members.find(member => member.id === person_in_charge_id) : null;
      }) : null;

    return (
      <TaskContainer
        onMouseEnter={(e: any) => {this.showEditButtons=true}}
        onMouseLeave={(e: any) => {this.showEditButtons=false}}
        onClick={(e) => {this.props.onClick(e);}}
        style={
          this.injected.appStore.taskManagerStore.taskMultiSelect.task_ids.includes(this.props.metadata.taskId) ?
          {backgroundColor:'#fef9e7'} : {}
        }
      >
        {this.showTitleEditor ? (
          <>
          <div>
            <TitleEditor
              fill={true}
              inputRef={(input: any) => {this.inputComponent=input}}
              placeholder={'Task 타이틀'}
              value={ this.title }
              onChange={this.handleTitleChange}
              onClick={(e: any) => {e.stopPropagation();}}
            />
          </div>
          <div style={{height:'5px'}}>&nbsp;</div>
          <div style={{display:'flex'}}>
            <TitleCancelButton
                minimal={true}
                onClick={(e: any) => {this.handleCancel(); e.stopPropagation();}}
                text={'취소'}
                style={{marginLeft: 'auto'}}
            />
            <TitleEditButton
              small={true} disabled={ this.title === ''}
              onClick={(e: any) => { this.handleSubmit(); e.stopPropagation(); }}
              text={'수정'}
              intent={Intent.PRIMARY}
            />
          </div>
          </>
        ) : (
          <>
          <TaskHeader>
            {person_in_charge_list && person_in_charge_list.map((p) => {
              return p && (<TaskPersonInCharge key={p.id}>{p.name}</TaskPersonInCharge>);
            })}
            {meta.dueDate && (
              <>
              {moment().isBefore(moment(meta.dueDate)) ? (
                <TaskDueDate>{moment(meta.dueDate).format('MM/DD')}</TaskDueDate>
              ) : (
                <TaskDueDateOver>{moment(meta.dueDate).format('MM/DD')}</TaskDueDateOver>
              )}
              </>
            )}
            {meta.sprints.map( (sprint:string, index:number) => {
              return <TaskSprint key={index}>{sprint}</TaskSprint>;
            })}
            {meta.labels.map( (label:string, index:number) => {
              if (label && label.length < 10) {
                return <TaskLabel key={index}>{label}</TaskLabel>;
              } else {
                return (
                  <TaskLabel key={index} title={label}>
                    {label.substring(0, 10)+'...'}
                  </TaskLabel>
                )
              }
            })}
          </TaskHeader>
          <TaskTitle>
            {this.props.title}
          </TaskTitle>
          <TaskFooter>
          <>
          {meta.countSubTasksAll > 0 && (
            <TaskSubTask>
              <Icon icon="tick-circle" iconSize={14} style={{color:'gray'}} />
              <span>{' : ' + meta.countSubTasksDone + ' / ' + meta.countSubTasksAll}</span>
            </TaskSubTask>
          )}
          {meta.countAttachments > 0 && (
            <TaskAttatchment>
              <Icon icon="document" iconSize={14} style={{color:'gray'}}/>
              <span>{' : '+meta.countAttachments}</span>
            </TaskAttatchment>
          )}
          {meta.countComments > 0 && (
            <TaskComment>
              <Icon icon="comment" iconSize={14} style={{color:'gray'}}/>
              <span>{' : '+meta.countComments}</span>
            </TaskComment>
          )}
          </>
        </TaskFooter>
          {(this.showEditButtons && !meta.archived) && (
          <TaskEditButtonContainer>
            <TaskEditButton
                icon="edit"
                minimal={true}
                onClick={(e: any) => { this.toggleStartEditing(); e.stopPropagation();}
                }
              />
            <TaskEditButton
                icon="trash"
                minimal={true}
                onClick={(e: any) => {this.showDeleteAlert=true; e.stopPropagation();}}
            />
          </TaskEditButtonContainer>
          )}

            {(this.showEditButtons && meta.archived) && (
              <TaskEditButtonContainer>
                <TaskEditButton
                  icon="reset"
                  minimal={true}
                  onClick={(e: any) => {this.handleRestoreSubmit(); e.stopPropagation();}}
                />
                <TaskEditButton
                  icon="trash"
                  minimal={true}
                  onClick={(e: any) => {this.showDeleteAlert=true; e.stopPropagation();}}
                />
              </TaskEditButtonContainer>
            )}
          </>
        )}
        <Alert
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.showDeleteAlert}
          onCancel={(e: any) => {this.showDeleteAlert=false; e.stopPropagation();}}
          onConfirm={(e: any) => {this.handleDeleteSubmit(meta.archived); e.stopPropagation();}}
          confirmButtonText={'예'}
          cancelButtonText={'아니오'}>
          {meta.archived ? <p>선택한 Task를 완전 삭제하시겠습니까?</p>:<p>선택한 Task를 삭제하시겠습니까?</p>}
        </Alert>
      </TaskContainer>
    );
  }
}

export default Task;