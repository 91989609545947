import React, { Component } from "react";

import { inject, observer } from "mobx-react";
import { observable } from "mobx";

import { AppStore } from "../../../store/AppStore";

import styled from "styled-components/macro";
import { Button, Spinner, Intent } from "@blueprintjs/core";

import { AppToaster } from "../../organisms/AppToaster/AppToaster";


const Wrapper = styled.div`
  margin-bottom: 10px;
  & > * + * {
    margin-top: 10px;
  }
`;
const LeftMargin = styled.div`
  margin-left: 10px;
`;
const BotRegStatus = styled.div`
`;
const VacationSection = styled.div`
`;

interface SlackbotPanelProps {
}

interface InjectedProps extends SlackbotPanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class SlackbotPanel extends Component<SlackbotPanelProps> {
  @observable readyToShow: boolean = false;
  @observable popupRef: any = null;

  get injected() {
    return this.props as InjectedProps;
  }

  get store() {
    return this.injected.appStore.seesobotStore;
  }

  async componentDidMount() {
    this.readyToShow = false;

    await this.store.installCheck();
    window.addEventListener("message", this.handleMessageEvent);

    this.readyToShow = true;
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleMessageEvent);
  }

  handleMessageEvent = (e:any) => {
    if(e.data.ok === "True") {
      this.store.setBoAppInstalled(true);
      AppToaster.show({
        message: "등록을 완료했습니다.",
        intent: Intent.SUCCESS
      });
    } else if(e.data.ok === "False"){
      AppToaster.show({
        message: e.data.message,
        intent: Intent.DANGER
      });
    }    
  }

  render() {
    return (
      <Wrapper>
        {!this.readyToShow ? (
          <Spinner />
        ) : (
          <LeftMargin>
            <BotRegStatus>
              <span>슬랙 봇:&nbsp;&nbsp;</span>
              {this.store.boAppInstalled ? (
                <span>등록 완료</span>
              ) : (
                <Button 
                  text={'등록'}
                  onClick={async () => {
                    this.popupRef = window.open(
                      `${this.store.boAppInstallUrl}&client_id=${this.store.boClientId}&state=${this.injected.appStore.userStore.userId}||${this.store.boClientId}|${this.store.boClientSecret}`, 
                      "_blank"
                    );
                  }}
                />
              )}
            </BotRegStatus>
          </LeftMargin>
        )}

      </Wrapper>
    );
  }
}

export default SlackbotPanel;