import React, { Component, createRef } from "react";
import styled from "styled-components/macro";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import domtoimage from 'dom-to-image';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { AppStore } from "../../../store/AppStore";
import { ProjectGroup } from "../../../store/models/ProjectGroup";
import {
  FlexRow,
  ContentColumn,
  GreyLabel
} from "../../atoms/DetailReportComponents/DetailReportComponents";
import { LeftColumn } from "../SprintTableClient/SprintTableClient";
import ExpenseRowGroup from "../../molecules/ExpenseRowGroup/ExpenseRowGroup";
import {
  Table,
  THead,
  FirstColumnTH,
  SubColumnTH,
  GreyBackground,
  TR,
  TD,
  RightAlignTD,
  SprintSpan
} from "../../atoms/ExpenseTable/ExpenseTable";
import { RoundedButton } from "../../atoms/RoundedButton/RoundedButton";
import { Checkbox } from "@blueprintjs/core";

const Wrapper = styled.div`
  margin-bottom: 50px;
`;
const LastWrapper = styled.div`
  position: relative;
`;
const ExpenseTableGreyLabel = styled(GreyLabel)`
  margin-right: 25px;
`;
const StyledLeftColumn = styled(LeftColumn)`
  width: 222px;
`;
const ExpenseTableContentColumn = styled(ContentColumn)`
  display: flex;
`;
const VATCheckbox = styled(Checkbox)`
  font-size: 12px;
  padding-top: 10px;
  padding-left: 50px;
`;
const RightAlignedContentColumn = styled(ContentColumn)`
  text-align: right;
  padding-right: 132px;
  padding-top: 10px;
`;



interface ExpenseTableProps {
  projectGroup: ProjectGroup;
}

interface InjectedProps extends ExpenseTableProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ExpenseTable extends Component<ExpenseTableProps> {
  @observable isIncludeVAT = true;
  private expenseTableRef = createRef<HTMLDivElement>();
  private displayRef = createRef<HTMLDivElement>();

  get injected() {
    return this.props as InjectedProps;
  }

  handlePrint = async () => {
    const { projectGroup } = this.props;
    const title = `${projectGroup.groupId}프로젝트 지출내역`


    // window.open(
    //   `/project-groups/${projectGroup.groupId}/expense-table/print?vat=${this.isIncludeVAT}`,
    //   title,
    //   "width=800,height=500"
    // );


    

    const printArea = this.expenseTableRef.current;
    const displayArea = this.displayRef.current;




    if(printArea && displayArea) {

      // const input = document.getElementById('divToOfferInfo');
          const pdf = new jsPDF({
            'orientation': 'p',
            'unit': 'mm',
            'format': 'a4'
          });
          if (pdf) {
            domtoimage.toPng(printArea)
              .then(function (dataUrl) {
                  var img = new Image();
                  img.src = dataUrl;
                  var nWindow = window.open('');
                  nWindow && nWindow.document.body.appendChild(img);
                  nWindow && nWindow.focus();
                  nWindow && nWindow.print();
                  // window.open(img)
                  // pdf.addImage(img, 'JPEG', 20, 20, 180, 160);
                  // pdf.save(PDFFilename)
              });
          }

      // const canvas = await html2canvas(printArea, {
      //   allowTaint: true,
      //   useCORS: true
      // });
      // displayArea.appendChild(canvas);
      // console.log(printArea);

      // await html2canvas(printArea, {
      //   allowTaint: true,
      //   useCORS: true
      // }).then(canvas => {
      //   displayArea.appendChild(canvas)
      // });

      // html2canvas(printArea).then(canvas => {
      //   console.log(printArea);
      //   displayArea.appendChild(canvas)
      //     // const imgData = canvas.toDataURL('image/png');
      //     // const pdf = new jsPDF({
      //     //   'orientation': 'p',
      //     //   'unit': 'mm',
      //     //   'format': 'a4'
      //     // });

      //     // pdf.addImage(imgData, 'JPEG', 20, 20, 180, 160);
      //     // pdf.save('saved.pdf')
      // })
    }


    // console.log('print');
    // const node = this.expenseTableRef.current;
    // console.log(node);
    // console.log(document.body)
    // if(node) {
    //   html2canvas(node).then(function(canvas) {
    //     console.log(canvas);
    //     // document.body.appendChild(canvas);
    //     var imgData = canvas.toDataURL('image/png');
    //     var imgWidth = 210;
    //     var pageHeight = imgWidth * 1.414;
    //     var imgHeight = canvas.height * imgWidth / canvas.width;

    //     var doc = new jsPDF({
    //       'orientation': 'p',
    //       'unit': 'mm',
    //       'format': 'a4'
    //     });

    //     doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
    //     doc.save(PDFFilename);
    //   });
    // }
  };

  handleIncludeVATChange = (e: any) => {
    this.isIncludeVAT = !this.isIncludeVAT;
  };

  render() {
    const { projectGroup } = this.props;
    const { sprintExpensesGroups, extraExpenses } = projectGroup;

    let totalExpense = 0;
    let current_sprint = 0;
    sprintExpensesGroups.map((expenses) => {
      current_sprint = current_sprint < expenses[0].sprint ? expenses[0].sprint : current_sprint;
      totalExpense += expenses.reduce((acc, expense) =>
          acc + expense.payment + expense.serviceFee, 0)
    })
    totalExpense += extraExpenses
          .filter(x => x.sprint <= current_sprint)
          .reduce((acc, expense) =>
          acc + expense.serviceFee, 0);

    return (
      <Wrapper>
        <FlexRow>
          <LeftColumn>
            <GreyLabel>지출내역</GreyLabel>
            <VATCheckbox
              label="VAT포함" 
              checked={this.isIncludeVAT}
              onChange={this.handleIncludeVATChange} />
          </LeftColumn>

          <RightAlignedContentColumn>
            {
            //   <RoundedButton 
            //   style={{ boxShadow: "none" }}
            //   onClick={this.handlePrint}>
            //     {"인쇄하기"}
            // </RoundedButton>
            }  
          </RightAlignedContentColumn>
        </FlexRow>

        <div ref={this.expenseTableRef}>
          {sprintExpensesGroups.length > 0 && (
            <ExpenseRowGroup
              key={'pre'}
              isIncludeVAT={this.isIncludeVAT}
              extraExpenses={extraExpenses} />
          )}
          {
            sprintExpensesGroups.map((expenseGroup, index) => (
            <ExpenseRowGroup
              key={index}
              isIncludeVAT={this.isIncludeVAT}
              expenses={expenseGroup}
              extraExpenses={extraExpenses} />
           ))
          }

          <LastWrapper>
            <GreyBackground />
            <Table>
                <THead>
                  <tr>
                    <FirstColumnTH>
                      총 지출액
                    </FirstColumnTH>
                    <SubColumnTH></SubColumnTH>
                    <SubColumnTH></SubColumnTH>
                    <SubColumnTH></SubColumnTH>
                  </tr>
                </THead>
                <tbody>
                  <TR>
                    <TD>
                      <SprintSpan>
                        {sprintExpensesGroups.length} 스프린트
                      </SprintSpan>
                    </TD>
                    <TD></TD>
                    <TD></TD>
                    <RightAlignTD>{(this.isIncludeVAT ? totalExpense * 1.1 : totalExpense).toLocaleString("en-US")}</RightAlignTD>
                  </TR>
                </tbody>
              </Table>
            </LastWrapper>
          </div>
          <div ref={this.displayRef} />
      </Wrapper>
    );
  }
}

export default ExpenseTable;
