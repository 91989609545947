import { types, flow } from "mobx-state-tree";
import { PMReportModel } from "./models/PMReport";
import axios from "axios";
import { PMReportListResponse, PMReportResponse } from "../types/pmReport";
import moment from "moment";
import sortBy from "lodash/sortBy";

const mapReport = (x: PMReportResponse) => {
  return {
    id: x.id,
    pm: x.pm,
    projectGroup: x.project_group,
    projectGroupName: x.project_group_name,
    projectGroupSprints: x.project_group_sprints,
    createdAt: x.created_at,
    updatedAt: x.updated_at,
    date: x.date,
    text: x.text,
    mood: x.mood
  };
};

export const PMReportStoreModel = types
  .model("PMReportStore", {
    reports: types.array(PMReportModel),

    // For Query
    currentPage: types.optional(types.number, 1),
    pageGroup: types.optional(types.number, 1),
    pageSize: types.optional(types.number, 50),
    totalPages: types.optional(types.number, 1),

    dateStart: types.optional(types.string, ""),
    dateEnd: types.optional(types.string, ""),
    pmName: types.optional(types.string, ""),
    groupId: types.optional(types.string, ""),
    projectName: types.optional(types.string, ""),
    text: types.optional(types.string, "")
  })
  .views(self => ({
    get sortedReports() {
      return sortBy(self.reports, report => -1 * report.id);
    },
    get sortedReportsByUpdateAt() {
      return sortBy(self.reports, report => report.updatedAt).reverse();
    }
  }))
  .actions(self => ({
    setCurentPage(value: number) {
      self.currentPage = value;
    },
    setPageGroup(value: number) {
      self.pageGroup = value;
    },
    setDateStart(value: string) {
      self.dateStart = value;
    },
    setDateEnd(value: string) {
      self.dateEnd = value;
    },
    setPmName(value: string) {
      self.pmName = value;
    },
    setGroupId(value: string) {
      self.groupId = value;
    },
    setProjectName(value: string) {
      self.projectName = value;
    },
    setText(value: string) {
      self.text = value;
    },
    clear() {
      self.currentPage = 1;
      self.pageGroup = 1;

      self.dateStart = "";
      self.dateEnd = "";

      self.pmName = "";
      self.groupId = "";
      self.projectName = "";
      self.text = "";
    }
  }))
  .actions(self => {
    const fetchReports = flow(function*() {
      try {
        const { data }: { data: PMReportListResponse } = yield axios.get(
          "/pmReports"
        );

        const reports = data.results.map(x =>
          PMReportModel.create(mapReport(x))
        );

        self.reports.replace(reports);
      } catch (e) {
        console.log("fetchReports error", e);
        throw e;
      }
    });

    const searchReports = flow(function*(is_support?: boolean) {
      try {
        const { data }: { data: PMReportListResponse } = yield axios.get(
          "/pmReports",
          {
            params: {
              page: self.currentPage,
              page_size: self.pageSize,
              pm: self.pmName,
              project_group: self.groupId,
              project_group_name: self.projectName,
              date_after: self.dateStart,
              date_before: self.dateEnd,
              text: self.text,
              is_support: is_support ? true : false
            }
          }
        );

        self.totalPages = data.total_pages;

        const reports = data.results.map(x =>
          PMReportModel.create(mapReport(x))
        );

        self.reports.replace(reports);
      } catch (e) {
        console.log("searchReports error", e);
        throw e;
      }
    });

    return {
      fetchReports,
      searchReports
    };
  });

type PMReportStoreType = typeof PMReportStoreModel.Type;
export interface PMReportStore extends PMReportStoreType {}
