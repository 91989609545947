import React, { Component } from "react";
import styled from "styled-components/macro";
import { observer } from "mobx-react";
import { ProjectGroupRisk } from "../../../store/models/ProjectGroupRisk";
import { GreyLabel } from "../../atoms/DetailReportComponents/DetailReportComponents";
import { RoundedButton } from "../../atoms/RoundedButton/RoundedButton";

const Wrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 50px;
`;

const HeaderRow = styled.div`
  display: flex;
  margin-bottom: 25px;
`;

const RiskItem = styled.div`
  margin-bottom: 25px;
`;

const RiskTitle = styled.div`
  border-left: solid 3px grey;

  height: 40px;
  background-color: #f8f8f8;
  padding-top: 4px;
  padding-left: 30px;
  margin-bottom: 10px;

  
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #161d2e;
`;

const RiskContent = styled.div`
  /* color: #106ba3; */
  white-space: pre-wrap;

  
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #161d2e;

  padding-left: 33px;
  padding-right: 33px;
`;

const RightButton = styled(RoundedButton)`
  margin-left: auto;
  margin-right: 0;
`;

const ContentSection = styled.div``;

interface ProjectGroupRiskListProps {
  risks: ProjectGroupRisk[];
  isEdiable: boolean;
  onCreateRiskClick: () => void;
}

@observer
class ProjectGroupRiskList extends Component<ProjectGroupRiskListProps> {
  render() {
    const { risks, isEdiable, onCreateRiskClick } = this.props;

    return (
      <Wrapper>
        <HeaderRow>
          <GreyLabel>프로젝트 리스크</GreyLabel>
          {isEdiable && risks.length === 0 && (
            <RightButton onClick={onCreateRiskClick}>리스크 체크</RightButton>
          )}
          {isEdiable && risks.length !== 0 && (
            <RightButton onClick={onCreateRiskClick}>
              리스크 수정하기
            </RightButton>
          )}
        </HeaderRow>
        <ContentSection>
          {/* {risks.every(risk => !risk.checked)} */}
          {risks
            .filter(risk => risk.checked)
            .map((risk, index) => (
              <RiskItem key={index}>
                <RiskTitle>{risk.title}</RiskTitle>
                <RiskContent>{risk.content}</RiskContent>
              </RiskItem>
            ))}
        </ContentSection>
      </Wrapper>
    );
  }
}

export default ProjectGroupRiskList;
