import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";

import { 
    Wrapper,
    StyledCard, 
    CardTitle, 
    CardContent, 
    Table, 
    TableRow, 
    HeadColumn, 
    ContentColumn,
    GrayLabel,
    TruncatedText,
    EmphasizedText
} from "../../atoms/ParttimeComponents/ParttimeComponents";

import { Task} from "../../../store/models/Parttime";

interface OutcomePanelProps extends RouteComponentProps {
  task: Task;
}

interface InjectedProps extends OutcomePanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class OutcomePanel extends Component<OutcomePanelProps> {

  get injected() {
    return this.props as InjectedProps;
  }

  render() {
    const { task } = this.props;

    return (
        <Wrapper>
          <StyledCard>
              <CardTitle>정산</CardTitle>
              <CardContent>
              <Table>
                  <TableRow>
                    <HeadColumn>
                        <GrayLabel>총비용</GrayLabel>
                    </HeadColumn>
                    <ContentColumn>
                        <EmphasizedText>{ task.totalAmount && task.totalAmount.toLocaleString("en-US") }원</EmphasizedText>
                    </ContentColumn>
                  </TableRow>
                  <TableRow>
                    <HeadColumn>
                        <GrayLabel>비고</GrayLabel>
                    </HeadColumn>
                    <ContentColumn>
                        <EmphasizedText>{ task.paymentRemark ? task.paymentRemark : '-' }</EmphasizedText>
                    </ContentColumn>
                  </TableRow>
              </Table>
              </CardContent>
          </StyledCard>
            <StyledCard>
                <CardTitle>결과 파일</CardTitle>
                <CardContent>
                <Table>
                    { (Array.isArray(task.outcomes) && task.outcomes.length) ? (task.outcomes.map(file => (
                        <TableRow>
                        <TruncatedText>- <a href={file.file} target="_blank">{ file.name }</a></TruncatedText>
                        {/* <NormalText style={{display: "inline-block", margin: "0 10px"}}>{ file.format }</NormalText>  */}
                        </TableRow>
                    ))) : '-' }
                </Table>
                </CardContent>
            </StyledCard>
        </Wrapper>
    );
  }
}

export default OutcomePanel;
