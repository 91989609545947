import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import {
  Classes,
  Dialog, EditableText
} from "@blueprintjs/core";

import { AppStore } from "../../../store/AppStore";
import { GreyLabel } from "../../atoms/DetailReportComponents/DetailReportComponents";
import { computed, observable } from "mobx";
import {
  TaskBulletinModel,
  TaskBulletinPost,
  TaskBulletinPostModel,
  TaskBulletin
} from "../../../store/models/TaskManager";
import { types } from "mobx-state-tree";
import BulletinAttachmentListForm from "./BulletinAttachmentListForm";


interface TaskBulletinPostPopupProps {
  id: number;
  title: string;
  isOpen: boolean;
  task_bulletin: string;
  onClose: () => void;
}

interface InjectedProps extends TaskBulletinPostPopupProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class TaskBulletinPostPopup extends Component<TaskBulletinPostPopupProps> {

  /*@observable bulletinPost: TaskBulletinPost = TaskBulletinPostModel.create({
    id: -1,
    createdAt: "",
    updatedAt: "",
    order: 0,
    task_bulletin_post_id: "",
    title: "Empty",
    content: "Empty",
    task_bulletin: "Empty",
    writer: "Empty"
  });*/

  get injected() {
    return this.props as InjectedProps;
  }

  handleOnOpening = () => {
  };

  @computed
  get formStore() {
    return this.injected.appStore.taskManagerStore;
  }

  @computed
  get currentForm(): TaskBulletinPost {
    const currentBulletin = this.formStore.currentBulletins
      .find((bulletin: TaskBulletin) => bulletin.task_bulletin_id === this.props.task_bulletin);
    const bulletinPost = currentBulletin && currentBulletin.task_bulletin_posts
      .find((bulletinPost) => bulletinPost.id === this.props.id);

    if (bulletinPost) {
      return bulletinPost;
    } else {
      return TaskBulletinPostModel.create({
        id: -1,
        createdAt: "",
        updatedAt: "",
        order: 0,
        task_bulletin_post_id: "",
        title: "Empty",
        content: "Empty",
        task_bulletin: "Empty",
        writer: "Empty"
      });
    }
  }

  async componentDidMount() {

  }

  async componentDidUpdate(prevProps: any) {
    // 전형적인 사용 사례 (props 비교를 잊지 마세요)
    // if (this.bulletinPost.id !== this.props.id && this.props.isOpen) {
    // const post = await this.formStore.fetchTaskBulletinPost(this.props.id);
    // if (post)
    //   this.bulletinPost = post;
    //  = this.currentForm.
    // console.log("this.bulletinPost", this.bulletinPost);
    // }
  }


  render() {
    const { isOpen, onClose } = this.props;

    return (
      <Dialog
        icon="annotation"
        onClose={onClose}
        title={"운영정보 항목"}
        isOpen={isOpen}
        usePortal={true}
        autoFocus={true}
        canEscapeKeyClose={true}
        enforceFocus={true}
        onOpening={this.handleOnOpening}
        style={{ width: "800px" }}
      >
        <div className={Classes.DIALOG_BODY}>
          {this.currentForm.id != -1 ?
            <div>
              <div style={{width:"300px"}} >
                <EditableText multiline={false} value={this.currentForm.title}
                                 onChange={(value) => {
                                   this.currentForm.setTitle(value);
                                   this.formStore.putTaskBulletinPost(this.currentForm.id, this.currentForm.title, this.currentForm.content);
                                 }}/>
              </div>
              <div style={{ marginTop: "15px" }}>
                <EditableText value={this.currentForm.content} onChange={(value: string) => {
                  this.currentForm.setContent(value);
                  this.formStore.putTaskBulletinPost(this.currentForm.id, this.currentForm.title, this.currentForm.content);
                }} multiline={true} minLines={3} maxLines={12} placeholder={"Task 의 대한 설명을 입력해주세요"}/>
              </div>

              <BulletinAttachmentListForm task_bulletin={this.currentForm.task_bulletin}
                                          bulletinPostId={this.currentForm.task_bulletin_post_id}
                                          files={this.currentForm.files} links={this.currentForm.links}/>
            </div> : <div>Empty</div>}
        </div>
      </Dialog>
    );
  }
}

export default TaskBulletinPostPopup;