import React from "react";

import { RoleButton } from "./RoleButton";

import { ResourceFormWrapper as Wrapper } from './_wrapper';



export interface IResourceFormProps {
  title: string;
  onAdd: () => void;
}

export const ResourceForm: React.FC<IResourceFormProps> = (props) => {
  return (
    <Wrapper>
      <h3>{ props.title }</h3>

      { props.children }
      
      <RoleButton
        onClick={ props.onAdd }
       />
    </Wrapper>
  )
}
