import { types } from "mobx-state-tree";

export const ProjectGroupManagerFormModel = types
  .model("ProjectGroupManagerForm", {
    name: types.optional(types.string, ""),
    phone: types.optional(types.string, ""),
    email: types.optional(types.string, "")
  })
  .actions(self => ({
    setName(value: string) {
      self.name = value;
    },
    setPhone(value: string) {
      self.phone = value;
    },
    setEmail(value: string) {
      self.email = value;
    }
  }));

type ProjectGroupManagerFormType = typeof ProjectGroupManagerFormModel.Type;
export interface ProjectGroupManagerForm extends ProjectGroupManagerFormType {}
