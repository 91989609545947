import React, { ChangeEvent, Component, MouseEvent } from "react";
import styled from "styled-components/macro";
import { HTMLTable, Button, Spinner, InputGroup, Intent } from "@blueprintjs/core";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { computed, observable } from "mobx";
import ContentContainer from "../../atoms/ContentContainer/ContentContainer";
import { Project } from "../../../store/models/Project";
import { RouteComponentProps } from "react-router";
import Paginator from "../../molecules/Paginator/Paginator";

const Wrapper = styled.div``;
const ToolsRow = styled.div`
  margin-bottom: 40px;
  display: flex;
  height: 20px;

  & > * + * {
    margin-left: 10px;
  }
`;

const InputContainer = styled.div`
  max-width: 120px;
  margin-bottom: 30px;
`;

interface ConsultingListPanelProps extends RouteComponentProps {
}

interface InjectedProps extends ConsultingListPanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
export class ConsultingListPanel extends Component<ConsultingListPanelProps> {
  @observable readyToShow = false;
  @observable search = "";

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get projectStore() {
    return this.injected.appStore.projectStore;
  }

  private fetchList = async () => {
    this.readyToShow = false;
    try {
      await this.projectStore.fetchPageableProjects({ search: this.search });
    } catch (e) {
    }
    this.readyToShow = true;
  };

  async componentDidMount() {
    await this.fetchList();
  }

  searchList = async () => {
    await this.projectStore.setCurentPage(1);
    await this.projectStore.setPageGroup(1);
    await this.fetchList();
  };
  handleProjectClick = (project: Project) => {
    this.props.history.push(`/consulting/projects/${project.projectId}`);
  };

  handleEditClick = (e: MouseEvent<HTMLElement>, project: Project) => {
    e.stopPropagation();
    this.props.history.push(`/consulting/projects/${project.projectId}/edit`);
  };

  render() {
    const {
      sortedProjects,
      pageGroup,
      totalPages,
      currentPage,
      setPageGroup,
      setCurentPage
    } = this.projectStore;

    return (
      <Wrapper>
        <ContentContainer>
          <ToolsRow>
            <InputContainer>
              <InputGroup
                placeholder="상담번호/프로젝트명"
                value={this.search}
                onChange={(e: ChangeEvent<HTMLInputElement>) => this.search = e.target.value}
                onKeyPress={(event) => {
                  if (event.key === "Enter")
                    this.searchList();
                }}
              />
            </InputContainer>
            <Button
              icon="search"
              text="검색"
              intent={Intent.PRIMARY}
              onClick={this.searchList}
            />
          </ToolsRow>
          <HTMLTable
            bordered={true}
            striped={true}
            interactive={true}
            style={{ width: "100%" }}
          >
            <thead>
            <tr>
              <th>상담번호</th>
              <th>프로젝트명</th>
              <th>상담일</th>
              <th>수정</th>
            </tr>
            </thead>
            <tbody>
            {this.readyToShow ? sortedProjects.map(project => (
              <tr
                key={project.projectId}
                onClick={() => this.handleProjectClick(project)}
              >
                <td>{project.projectId}</td>
                <td>{project.name}</td>
                <td>{project.formattedConsultedDate}</td>
                <td>
                  <Button
                    text="수정하기"
                    small={true}
                    onClick={(e: MouseEvent<HTMLElement>) =>
                      this.handleEditClick(e, project)
                    }
                  />
                </td>
              </tr>
            )) : <Spinner key={"rufreeListSpinner"}/>}
            </tbody>
          </HTMLTable>
          <div style={{ marginTop: "15px" }}>
            <Paginator
              pageGroup={pageGroup}
              totalPages={totalPages}
              currentPage={currentPage}
              setPageGroup={setPageGroup}
              setCurentPage={setCurentPage}
              onFetch={() => this.fetchList()}
            />
          </div>
        </ContentContainer>
      </Wrapper>
    );
  }
}

export default ConsultingListPanel;
