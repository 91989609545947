import { types } from "mobx-state-tree";

export const PdfToUploadFormModel = types
  .model("PdfToUploadForm", {})
  .volatile(self => ({
    file: null as File | null
  }))
  .actions(self => ({
    setFile(file: File) {
      self.file = file;
    }
  }));

type PdfToUploadFormType = typeof PdfToUploadFormModel.Type;
export interface PdfToUploadForm extends PdfToUploadFormType {}
