import React, { Component } from "react";
import { observer } from "mobx-react";
import { PMReport } from "../../../store/models/PMReport";
import { Button } from "@blueprintjs/core";
import { Link } from "react-router-dom";
import { renderMood } from "../MoodSelection/MoodSelection";
import { PMReportMood } from "../../../store/forms/PMReportForm/PMReportForm";
import moment from 'moment';

interface PMReportRowProps {
  report: PMReport;
  onPmNameClick: (pmName: string) => void;
  onGroupIdClick: (groupId: string) => void;
}

@observer
class PMReportRow extends Component<PMReportRowProps> {
  handlePmNameClick = () => {
    this.props.onPmNameClick(this.props.report.pm);
  };

  handleGroupIdClick = () => {
    if(this.props.report.projectGroup) {
      this.props.onGroupIdClick(this.props.report.projectGroup);
    }
  };

  render() {
    const { report } = this.props;

    let sprints = null;

    if (report.projectGroupSprints) {
      const { client = [], rufree = [] } = report.projectGroupSprints;

      sprints = [...client, ...rufree].join(", ");
    }

    return (
      <tr>
        <td>
          {report.date}
          <br/>
          <small style={{color: "gray"}}>{moment(report.updatedAt).format("YYYY-MM-DD HH:mm")}</small>
        </td>
        <td style={{ whiteSpace: "nowrap" }}>
          <a onClick={this.handlePmNameClick}>{report.pm}</a>
        </td>
        <td>
          <a onClick={this.handleGroupIdClick}>{report.projectGroup}</a>
          <br/>
          {report.projectGroupName}
        </td>
        <td style={{ wordBreak: "keep-all" }}>
          {report.mood && renderMood(report.mood as PMReportMood)}
        </td>
        <td style={{ whiteSpace: "pre-wrap" }}><p><strong>{sprints}</strong></p>{report.text}</td>
        <td>
          <Link to={`/pm-reports/${report.id}/edit`}>
            <Button
              text="수정하기"
              small={true}
              style={{ whiteSpace: "nowrap" }}
            />
          </Link>
        </td>
      </tr>
    );
  }
}

export default PMReportRow;
