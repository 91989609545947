import React, { Component, ChangeEvent } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { Spinner, HTMLSelect, InputGroup, Button, Intent } from "@blueprintjs/core";
import { RouteComponentProps } from "react-router";
import { computed, observable } from "mobx";
import LargeContainer from "../../atoms/LargeContainer/LargeContainer";
import { PageNavText, PageNavWrapper } from "../../atoms/PageNav/PageNav";
import { TableHeader, Header } from "../../atoms/CardTable/CardTable";
import ReceptionRow from "../../molecules/ReceptionRow/ReceptionRow";
import { ReceptionStatus } from "../../../store/models/Reception";
import Paginator from "../../molecules/Paginator/Paginator";
import ProjectGroupRow from "../../molecules/ProjectGroupRow/ProjectGroupRow";

const SelectionWrapper = styled.div`
  margin-bottom: 40px;
  display: flex;
  height: 20px;

  & > * + * {
    margin-left: 10px;
  }
`;
const InputContainer = styled.div`
  max-width: 220px;
  margin-bottom: 30px;
`;

interface ReceptionListPageProps extends RouteComponentProps {
}

interface InjectedProps extends ReceptionListPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ReceptionListPage extends Component<ReceptionListPageProps> {
  @observable readyToShow = false;
  @observable statusFilter = "";
  @observable searchKeyword = "";

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get appStore() {
    return this.injected.appStore;
  }

  async componentDidMount() {
    try {
      await this.appStore.receptionStore.fetchReceptions();
      await this.appStore.clientUserStore.fetchClientUsers();
      await this.appStore.pmUserStore.fetchPMUsers();
      // await this.injected.appStore.projectGroupStore.fetchProjectGroups();

      this.readyToShow = true;
    } catch (e) {
    }
  }

  handleRowClick = (receptionId: string) => {
    this.props.history.push(`/receptions/${receptionId}`);
  };

  handleFilterSelect = async (e: ChangeEvent<HTMLSelectElement>) => {
    this.readyToShow = false;
    this.statusFilter = e.currentTarget.value;
    await this.appStore.receptionStore.setStatusFilter(this.statusFilter);
    await this.appStore.receptionStore.fetchReceptions();
    this.readyToShow = true;
  };

  handleSearchSelect = async () => {
    this.readyToShow = false;
    await this.appStore.receptionStore.setCurentPage(1);
    await this.appStore.receptionStore.setPageGroup(1);
    await this.appStore.receptionStore.setSearchFilter(this.searchKeyword);
    await this.appStore.receptionStore.fetchReceptions();
    this.readyToShow = true;
  };

  render() {
    // if (!this.readyToShow) {
    //   return <Spinner/>;
    // }

    let content = [];

    const {
      sortedReceptions,
      receptions,
      pageGroup,
      totalPages,
      currentPage,
      setPageGroup,
      setCurentPage
    } = this.appStore.receptionStore;

    if (this.readyToShow) {
      content.push(sortedReceptions
        // .filter(reception => this.statusFilter === "" || reception.status === this.statusFilter)
        .map(reception => (
          <ReceptionRow
            key={`${reception.id}r`}
            reception={reception}
            onClick={() => this.handleRowClick(reception.receptionId||"")}
          />
        )));
    } else {
      content.push(<Spinner key={"receptionSpinner"}/>);
    }

    return (
      <LargeContainer>
        <PageNavWrapper>
          <PageNavText>프로젝트 의뢰 목록</PageNavText>
        </PageNavWrapper>
        <SelectionWrapper>
          <HTMLSelect onChange={this.handleFilterSelect}>
            <option selected={this.statusFilter === ""} value={""}>
              전체
            </option>
            <option
              selected={this.statusFilter === ReceptionStatus.Pending}
              value={ReceptionStatus.Pending}
            >
              대기중
            </option>
            <option
              selected={this.statusFilter === ReceptionStatus.Accepted}
              value={ReceptionStatus.Accepted}
            >
              승인됨
            </option>
            <option
              selected={this.statusFilter === ReceptionStatus.Rejected}
              value={ReceptionStatus.Rejected}
            >
              거절됨
            </option>
          </HTMLSelect>
          <InputContainer>
            <InputGroup
              style={{ width: "200px" }}
              placeholder="프로젝트명/접수자/소속"
              value={this.searchKeyword}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                this.searchKeyword = e.target.value;
              }}
              onKeyPress={(event => {
                if (event.key === "Enter")
                  this.handleSearchSelect();
              })}
            />
          </InputContainer>
          <Button
            icon="search"
            text="검색"
            intent={Intent.PRIMARY}
            onClick={this.handleSearchSelect}
          />
        </SelectionWrapper>
        <TableHeader>
          <Header style={{ maxWidth: "160px" }}>접수번호</Header>
          <Header style={{ maxWidth: "200px" }}>프로젝트명</Header>
          <Header style={{ maxWidth: "130px" }}>접수자<br/>(소속)</Header>
          <Header style={{ maxWidth: "170px" }}>미팅 희망일</Header>
          <Header style={{ maxWidth: "130px" }}>접수일</Header>
          <Header style={{ maxWidth: "130px" }}>접수상태<br/>(검수담당자)</Header>
          <Header style={{ maxWidth: "130px" }}>영업상담서</Header>
          <Header style={{ maxWidth: "130px" }}>검수보고서</Header>
        </TableHeader>
        {content}
        <Paginator
          pageGroup={pageGroup}
          totalPages={totalPages}
          currentPage={currentPage}
          setPageGroup={setPageGroup}
          setCurentPage={setCurentPage}
          onFetch={() => this.appStore.receptionStore.fetchReceptions()}
        />
      </LargeContainer>
    );
  }
}

export default ReceptionListPage;
