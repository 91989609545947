import { types } from "mobx-state-tree";

export const ProjectGroupSprintModel = types.model("ProjectGroupSprint", {
  client: types.array(types.string),
  rufree: types.array(types.string)
});

export const PMReportModel = types.model("PMReport", {
  id: types.identifierNumber,
  pm: types.string,
  projectGroup: types.maybeNull(types.string),
  projectGroupName: types.maybeNull(types.string),
  projectGroupSprints: types.maybeNull(ProjectGroupSprintModel),
  createdAt: types.string,
  updatedAt: types.string,
  date: types.string,
  text: types.string,
  mood: types.optional(types.string, "")
});

type PMReportType = typeof PMReportModel.Type;
export interface PMReport extends PMReportType {}
