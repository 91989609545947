import React, { FC, useRef, useEffect } from "react";
import { select, scaleLinear, axisBottom, scaleBand, scalePoint, axisLeft, line as d3Line, event, ScaleLinear } from "d3";
import moment from "moment";

import styled from "styled-components/macro";


const BarWrapper = styled.svg`
  width: 700px;
  height: 95%;
  rect {
    fill: #137CBD;
  }
  .bar-text {
    font-size: 12px;
    font-weight: 600;
  }
  text {
    font-size: 12px;
  }
`;

interface IData {
  title: string,
  count: number,
  ratio: number,
  duration: number
}

const ProjectConversionRateGraph: FC<{
  className?: string,
  data: IData[]
}> = ({ className, data }) => {
  console.log(data)
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if(svgRef && data.length > 0) {
      const svg = select(svgRef.current);
      svg.selectAll("*").remove();

      const barMargin = 60;
      const margin = {top: 30, right: 0, bottom: 0, left: 30};
      const width = svgRef.current ? svgRef.current.getBoundingClientRect().width : 0;
      const height = svgRef.current ? svgRef.current.getBoundingClientRect().height-20 : 0;
      const maxCount = Math.max.apply(null, data.map(d => d.count));
      const yScaleMax = maxCount < 10 ? 10 : maxCount+1;
      const barHeight = (height - margin.top - margin.bottom) / yScaleMax;

      const xScale = scaleBand()
        .domain(data.map(d => d.title))
        .range([0, width-margin.left-margin.right]);
      const yScale = scaleLinear()
        .domain([0, yScaleMax])
        .range([height - margin.bottom, margin.top]);

      const xAxis = axisBottom(xScale);
      const yAxis = axisLeft(yScale);
      

      /*
        * 전환율(막대그래프)
      */
      // bar 그리기.
      svg.selectAll("rect")
        .data(data)
        .enter().append("rect")
          .attr("height", (d, i) => d.count * barHeight)
          .attr("width", xScale.bandwidth() - barMargin)
          .attr("x", (d, i) => (xScale.bandwidth() * i) + margin.left + (barMargin / 2))
          .attr("y", (d, i) => ((height - d.count * barHeight)));
        
      // bar 타이틀 표시.
      svg.selectAll("text")
        .data(data)
        .enter().append("text")
        .text( (d, i) => d.count > 0 ? `${d.count}(${Math.trunc(d.ratio * 100)}%)` : '')
          .attr("class", "bar-text")
          .attr("x", (d, i) => (xScale.bandwidth() * i) + (xScale.bandwidth()/2))
          .attr("y", (d, i) => ((height - d.count * barHeight) - 3));


      /*
        * 소요시간(라인그래프)
      */
      svg.append("path")
        .datum(data)
        .attr("fill", "none")
        .attr("stroke", "green")
        .attr("stroke-linejoin", "round")
        .attr("stroke-linecap", "round")
        .attr("stroke-width", 3)
        .attr("d", d3Line<IData>()
          // @ts-ignore
          .x(function(d: IData) { return xScale(d.title) + 113})
          // @ts-ignore
          .y(function(d: IData) { return yScale(moment.duration(d.duration).days()) }));


      // y축 그리기
      svg.append("g")
        .attr("transform", `translate(${margin.left}, 0)`)
        .call(yAxis);

      // x축 그리기
      svg.append("g")
        .attr("transform", `translate(${margin.left}, ${height - margin.bottom})`)
        .call(xAxis)
    }
  }, [ data, className ]);

  return (
      <BarWrapper
        className={className} ref={svgRef} />
  );
}

export default ProjectConversionRateGraph;