import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import FormContainer from "../../atoms/FormContainer/FormContainer";
import FormSection from "../../atoms/FormSection/FormSection";
import { FormGroup, Button, Intent, H3, Spinner } from "@blueprintjs/core";
import ContentContainer from "../../atoms/ContentContainer/ContentContainer";
import { FormMode } from "../../../types/formMode";
import { RouteComponentProps, Redirect } from "react-router";
import { computed, observable } from "mobx";
import PMReportFormView from "../../molecules/PMReportFormView/PMReportFormView";
import {
  ItemRow,
  RemoveButton,
  RemoveButtonContainer
} from "../../atoms/RemoveItemButton/RemoveItemButton";
import { DateInput } from "@blueprintjs/datetime";
import { getMomentFormatter } from "../../../utils/date";
import moment from "moment";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";

const Wrapper = styled.div``;

const Padding = styled.div`
  height: 30px;
`;

const ButtonsRow = styled.div`
  margin-bottom: 50px;
  & > * + * {
    margin-left: 5px;
  }
`;

interface PageParams {
  pmReportId: string;
}

interface PMReportFormPageProps extends RouteComponentProps<PageParams> {
  mode: FormMode;
}

interface InjectedProps extends PMReportFormPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class PMReportFormPage extends Component<PMReportFormPageProps> {
  @observable readyToShow = false;
  @observable isCreateSuccess = false;
  @observable isEditSuccess = false;
  @observable lastUpdate = {};

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get formStore() {
    return this.injected.appStore.pmReportFormStore;
  }

  @computed
  get currentForm() {
    return this.formStore.currentForm;
  }

  @computed
  get pmProjectGroups() {
    const { userStore, projectGroupStore } = this.injected.appStore;

    const { username } = userStore;
    return projectGroupStore.projectGroups.filter(x =>
      {
        // 조건1: 현재 PM이 포함된 프로젝트 중에서 아직 스케쥴 데이터가 없는 경우
        // 조건2: 현재 PM이 포함된 프로젝트 그룹 중에 스케쥴 데이터의 종료일이 지나지 않은 경우
        return x.managers.find(manager => manager.name === username) &&
        (
          !x.schedule ||  // 조건1
          moment(x.schedule.dateEnd).isSameOrAfter(moment(), 'day')  // 조건2
        );
      }
    );
  }

  async componentDidMount() {
    this.formStore.initForm();

    const { 
      projectGroupStore, 
      pmReportStore, 
      userStore 
    } = this.injected.appStore;

    await projectGroupStore.fetchProjectGroupsReportTargets();

    pmReportStore.setPmName(userStore.username);
    await pmReportStore.searchReports();

    const { mode } = this.props;
    const { pmReportId } = this.props.match.params;

    if (mode !== FormMode.Create && pmReportId) {
      await this.formStore.fetchPMReport(pmReportId);
    }

    this.readyToShow = true;
  }

  handleDateChange = (selectedDate: Date) => {
    this.currentForm.setDate(moment(selectedDate).format("YYYY-MM-DD"));
  };

  handleCancel = () => {
    this.props.history.push("/pm-reports");
  };

  handleSubmit = async () => {
    if (this.isCreateSuccess) {
      AppToaster.show({
        message: "이미 저장되었습니다.",
        intent: Intent.WARNING
      });

      return;
    }

    if (this.currentForm.pmReports.find(reportForm => reportForm.projectGroup === '')) {
      AppToaster.show({
        message: "모든 일지는 프로젝트 그룹을 선택해야 합니다.",
        intent: Intent.DANGER
      });

      return;
    }

    try {
      await this.formStore.postPMReport();

      AppToaster.show({
        message: "새로운 일지가 생성되었습니다.",
        intent: Intent.SUCCESS
      });

      this.isCreateSuccess = true;
    } catch (e) {
      const error = JSON.stringify(e.response.data);

      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  handleEditSubmit = async () => {
    const { pmReportId } = this.props.match.params;

    try {
      await this.formStore.patchPMReport(pmReportId);

      AppToaster.show({
        message: "수정사항이 반영되었습니다.",
        intent: Intent.SUCCESS
      });

      this.isEditSuccess = true;
    } catch (e) {
      const error = JSON.stringify(e.response.data);

      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  handleDelete = async () => {
    const { pmReportId } = this.props.match.params;

    const isAgreed = window.confirm("삭제하시겠습니까?");

    if (isAgreed) {
      try {
        await this.formStore.deletePMReport(pmReportId);

        AppToaster.show({
          message: "삭제되었습니다.",
          intent: Intent.SUCCESS
        });

        this.props.history.push("/pm-reports");
      } catch (e) {
        const error = JSON.stringify(e.response.data);

        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }
    }
  };

  render_last_update(projectGroupId: string) {
    const { sortedReportsByUpdateAt: allReports } = this.injected.appStore.pmReportStore;

    let lastReport = allReports.find(report => report.projectGroup === projectGroupId)
    return (
      lastReport ? 
      moment().diff(moment(lastReport.updatedAt), 'days') + '일전'
      :
      '정보없음'
    );
  }

  render() {
    if (!this.readyToShow) {
      return <Spinner />;
    }

    if (this.isCreateSuccess || this.isEditSuccess) {
      return <Redirect to={`/pm-reports`} push={true} />;
    }

    const { mode } = this.props;
    const { username } = this.injected.appStore.userStore;

    return (
      <Wrapper>
        <H3>{username} 매니저의 일지</H3>
        <Padding />

        <FormContainer>
          <FormSection>
            <FormGroup label={<strong>날짜</strong>}>
              <DateInput
                {...getMomentFormatter("YYYY-MM-DD")}
                locale="ko"
                closeOnSelection={true}
                value={moment(this.currentForm.date + "").toDate()}
                onChange={this.handleDateChange}
                maxDate={new Date("2050-01-01")}
              />
            </FormGroup>

            <FormGroup label={<strong>프로젝트 그룹 명</strong>} />
            {mode === FormMode.Create && (
              <>
              {this.pmProjectGroups.map(projectGroup => (
                <Button
                  key={projectGroup.nameWithId}
                  icon="add"
                  text={projectGroup.nameWithId + ' (' + this.render_last_update(projectGroup.groupId) + ')'}
                  onClick={() => this.currentForm.addReport(projectGroup.groupId)}
                />                
              ))}
                <Button
                  icon="add"
                  text='기타프로젝트'
                  onClick={() => this.currentForm.addReport('')}
                />
                <p>&nbsp;</p>
              </>
            )}
            {mode === FormMode.Create && (
              <>
                {this.currentForm.pmReports.map((reportForm, index) => (
                  <ItemRow key={index}>
                    <RemoveButtonContainer>
                      <RemoveButton
                        onClick={() =>
                          this.currentForm.removeReport(reportForm)
                        }
                      />
                    </RemoveButtonContainer>
                    <PMReportFormView reportForm={reportForm} />
                  </ItemRow>
                ))}
              </>
            )}
            {mode === FormMode.Edit &&
              this.currentForm.pmReports.map((reportForm, index) => (
                <PMReportFormView key={index} reportForm={reportForm} />
              ))}
          </FormSection>
        </FormContainer>

        <ContentContainer>
          {mode === FormMode.Create && (
            <ButtonsRow>
              <Button text="취소" onClick={this.handleCancel} />
              <Button
                icon="floppy-disk"
                text="저장"
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit}
              />
            </ButtonsRow>
          )}
          {mode === FormMode.Edit && (
            <ButtonsRow>
              <Button text="취소" onClick={this.handleCancel} />
              <Button
                text="수정"
                intent={Intent.SUCCESS}
                onClick={this.handleEditSubmit}
              />
              <Button
                text="삭제"
                intent={Intent.DANGER}
                onClick={this.handleDelete}
              />
            </ButtonsRow>
          )}
        </ContentContainer>
      </Wrapper>
    );
  }
}

export default PMReportFormPage;
