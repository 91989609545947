import { types, flow } from "mobx-state-tree";
import { ClientModel, ClientFileModel } from "./models/Client";
import { ClientResponse, ClientFileResponse } from "../types/cilent";
import axios from "axios";

export const mapDataToClient = (data: ClientResponse) => ({
  clientId: data.client_id,
  name: data.name,
  createdAt: data.created_at,
  updatedAt: data.updated_at,
  identityNumber: data.identity_number,
  clientType: data.client_type,
  representativeName: data.representative_name,
  address: data.address,
  contractPerson: {
    name: data.contract_person.name,
    email: data.contract_person.email,
    phone: data.contract_person.phone
  },
  taxPerson: {
    name: data.tax_person.name,
    email: data.tax_person.email,
    phone: data.tax_person.phone
  }
});

export const ClientStoreModel = types
  .model("ClientStore", {
    clients: types.array(ClientModel)
  })
  .views(self => ({
    get sortedClients() {
      return self.clients.slice().sort((a, b) => {
        const aId = parseInt(b.clientId.slice(2), 10);
        const bId = parseInt(a.clientId.slice(2), 10);

        return aId - bId;
      });
    },
    findById(clientId: string) {
      return self.clients.find(x => x.clientId === clientId);
    }
  }))
  .actions(self => {
    const fetchClients = flow(function*(
      searchText: string=''
    ) {
      try {
        const { data }: { data: ClientResponse[] } = yield axios.get(
          "/clients",
          {
            params: {
              search_text: searchText
            }
          }
        );

        const clients = data.map(clientData =>
          ClientModel.create(mapDataToClient(clientData))
        );

        self.clients.replace(clients.reverse());
      } catch (e) {
        console.log("fetchClients error", e);
        throw e;
      }
    });

    const fetchClient = flow(function*(clientId: string) {
      try {
        const { data }: { data: ClientResponse } = yield axios.get(
          `/clients/${clientId}`
        );

        const client = ClientModel.create(mapDataToClient(data));

        const exists = self.clients.find(x => x.clientId === clientId);

        if (exists) {
          self.clients.remove(exists);
        }

        self.clients.push(client);
        
        return client;
      } catch (e) {
        console.log("fetchClient error", e);
      }
    });

    const postClient = flow(function*({ name }: { name: string }) {
      try {
        const { data }: { data: ClientResponse } = yield axios.post(
          "/clients",
          {
            name
          }
        );

        // const newClient = ClientModel.create({
        //   clientId: data.client_id,
        //   name: data.name,
        //   createdAt: data.created_at,
        //   updatedAt: data.updated_at
        // });
        const newClient = ClientModel.create(mapDataToClient(data))

        self.clients.push(newClient);
      } catch (e) {
        console.log("postClient error", e);
        throw e;
      }
    });

    const patchClient = flow(function*({
      clientId,
      name
    }: {
      clientId: string;
      name: string;
    }) {
      try {
        yield axios.patch(`/clients/${clientId}`, {
          name
        });
      } catch (e) {
        console.log("patchClient error", e);
        throw e;
      }
    });

    return {
      fetchClients,
      fetchClient,
      postClient,
      patchClient
    };
  });

type ClientStoreType = typeof ClientStoreModel.Type;
export interface ClientStore extends ClientStoreType {}
