import React, { Component, ChangeEvent, KeyboardEvent } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { observable } from "mobx";
import { 
  Button, H6, InputGroup, Text, Classes
} from "@blueprintjs/core";
import moment from "moment";
import {
  EtcLinkWrapper, EtcItemWrapper, EtcStatus, EtcTitle, EtcDate, EtcSection, EtcSectionBody, EtcSectionTitle, H6Wrapper
} from "../../atoms/DashboardComponents/DashboardComponents";
import { getStatusText } from "../../pages/ReceptionDetailPage/ReceptionDetailPage";
import OngoingRufreeMatching from "../../organisms/Dashboard/OngoingRufreeMatching";
import RegistedRufreeSection from "../../organisms/Dashboard/RegistedRufreeSection";
import sortBy from "lodash/sortBy";

const Wrapper = styled.div`
  margin-bottom: 10px;
  & > * + * {
    margin-top: 10px;
  }
`;
const InfoSection = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 10px;
`;
const InfoText = styled(Text)`
  padding-top: 8px;
`;
const InputGroupWrapper = styled(InputGroup)`
  width: 40px;
  margin: 0 5px;
  input {
    height: 18px;
  }
`;
const SectionWrapper = styled.div`
  min-height: 200px;
  display: flex;
  & > * + * {
    margin-left: 10px;
  }
  margin-bottom: 10px;
`;

const NoItemText = styled(Text)`
  text-align: center;
  font-size: 12px;
  color: #5C7080;
  margin-top: 80px;
`;




interface EtcPanelProps {
}

interface InjectedProps extends EtcPanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class EtcPanel extends Component<EtcPanelProps> {
  @observable readyToShow = false;
  @observable daysbefore: number = 7;
  @observable tempDaysbefore: number = this.daysbefore;
  @observable baseDate: moment.Moment = moment().subtract(this.daysbefore, 'days');

  get injected() {
    return this.props as InjectedProps;
  }

  get inspectionStore() {
    return this.injected.appStore.inspectionStore;
  }

  get projectStore() {
    return this.injected.appStore.projectStore;
  }

  get receptionStore() {
    return this.injected.appStore.receptionStore;
  }

  get rufreeMatchingStore() {
    return this.injected.appStore.rufreeMatchingStore;
  }

  fetch = async() => {
    this.readyToShow = false;
    const created_after = this.baseDate.format('YYYY-MM-DDT00:00:00Z');

    await this.inspectionStore.fetchInspections(created_after);
    await this.projectStore.fetchProjects(created_after);
    // await this.receptionStore.fetchReceptions(created_after);
    await this.receptionStore.fetchAllReceptions(created_after);

    this.readyToShow = true;
  }

  handleChangeDaysbefore = (e: ChangeEvent<HTMLInputElement>) => {
    if(e.target.value === '') {
      this.tempDaysbefore = 0;
    }
    else {
      this.tempDaysbefore = parseInt(e.target.value);
    }
  };

  onKeyDown = async(event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      this.daysbefore = this.tempDaysbefore;
      this.baseDate = moment().subtract(this.daysbefore, 'days');
      await this.fetch();
    }
  }

  async componentDidMount() {
    await this.fetch();
  }

  render() {
    const { inspections } = this.inspectionStore;
    const { projects } = this.projectStore;
    const { receptions } = this.receptionStore;
    

    return (
      <Wrapper>

        <InfoSection>
          <InfoText>
            *의뢰 / 검수보고서 / 영업상담은 최근 
          </InfoText>
          <InputGroupWrapper
            onChange={this.handleChangeDaysbefore}
            small={true}
            value={this.tempDaysbefore+''}
            onKeyDown={this.onKeyDown}
          />
          <InfoText>
            일 기준. ({this.baseDate.format('YYYY-MM-DD')} ~ 오늘)
          </InfoText>
        </InfoSection>

        <SectionWrapper>
          <EtcSection>
            <EtcSectionTitle>
              <H6Wrapper>최근 접수된 의뢰</H6Wrapper>
            </EtcSectionTitle>
            <EtcSectionBody
              className={this.readyToShow ? '' : Classes.SKELETON}
            >
              {
                receptions.length > 0 ? (
                  sortBy(receptions, reception => moment(reception.dateRegister)).reverse().map(reception => (
                    <EtcLinkWrapper
                      key={`nr_${reception.id}`}
                      to={`/receptions/${reception.id}`}
                      target="_blank"
                    >
                      <EtcItemWrapper>
                        <EtcStatus>{getStatusText(reception.status)}</EtcStatus>
                        <EtcTitle>{reception.title}</EtcTitle>
                        <EtcDate>{moment(reception.dateRegister).format('MM-DD')}</EtcDate>
                      </EtcItemWrapper>
                    </EtcLinkWrapper>
                  ))
                ) : (
                  <NoItemText>최근 접수된 의뢰가 없습니다.</NoItemText> 
                )
              }
            </EtcSectionBody>
          </EtcSection>

          <EtcSection>
            <EtcSectionTitle>
              <H6Wrapper>새 검수보고서</H6Wrapper>
            </EtcSectionTitle>
            <EtcSectionBody
              className={this.readyToShow ? '' : Classes.SKELETON}
            >


            {
              inspections.length > 0 ? (
                sortBy(inspections, inspection => moment(inspection.createdAt)).reverse().map(inspection => (
                  <EtcLinkWrapper
                    key={`ni_${inspection.inspectionId}/read`}
                    to={`/inspections/${inspection.inspectionId}/read`}
                    target="_blank"
                  >
                    <EtcItemWrapper>
                      <EtcStatus>{inspection.publishedDate ? "발행완료" : "미발행"}</EtcStatus>
                      <EtcTitle>{inspection.title}</EtcTitle>
                      <EtcDate>{moment(inspection.createdAt).format('MM-DD')}</EtcDate>
                    </EtcItemWrapper>
                  </EtcLinkWrapper>
                ))
              ) : (
                <NoItemText>새 검수보고서가 없습니다.</NoItemText> 
              )
            }
            </EtcSectionBody>
          </EtcSection>

          <EtcSection>
            <EtcSectionTitle>
              <H6Wrapper>새 영업상담</H6Wrapper>
            </EtcSectionTitle>
            <EtcSectionBody
              className={this.readyToShow ? '' : Classes.SKELETON}
            >
              {
                projects.length > 0 ? (
                  sortBy(projects, project => moment(project.consultedDate)).reverse().map(project => (
                    <EtcLinkWrapper
                      key={`np_${project.projectId}`}
                      to={`/consulting/projects/${project.projectId}`}
                      target="_blank"
                    >
                      <EtcItemWrapper>
                        <div style={{ width: '100%' }}>{project.name}</div>
                        <EtcDate>{moment(project.consultedDate).format('MM-DD')}</EtcDate>
                      </EtcItemWrapper>
                    </EtcLinkWrapper>
                  ))
                ) : (
                  <NoItemText>새 영업상담이 없습니다.</NoItemText> 
                )
              }
            </EtcSectionBody>
          </EtcSection>
        </SectionWrapper>

        <SectionWrapper>

          <OngoingRufreeMatching />

          <RegistedRufreeSection
            daysbefore={ this.daysbefore } />

        </SectionWrapper>
      </Wrapper>
    );
  }
}

export default EtcPanel;