import { types, flow } from "mobx-state-tree";
import axios from "axios";
  
export const TaskOutcomeFormModel = types
    .model("TaskOutcome", {
        id: types.maybeNull(types.number),
        name: types.optional(types.string, ""),
        format: types.optional(types.string, ""),
    })
    .volatile(self => ({
        file: null as File | null,
    }))
    .actions(self => ({
        setId(value: number) {
          self.id = value;
        },
        setFile(file: File, name: string) {
            self.file = file;
            self.name = name;
        }
    })); 

type TaskOutcomeFormType = typeof TaskOutcomeFormModel.Type;
export interface TaskOutcomeForm extends TaskOutcomeFormType {}
