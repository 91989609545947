/*
  파트너 회원 리스트
*/
import React from 'react';
import { observer } from "mobx-react";
import { Link } from "react-router-dom";

import styled from "styled-components/macro";
import moment from "moment";

import { HTMLTable, Spinner } from '@blueprintjs/core';
import { IInvitation } from '../../../../store/models/Invitation';

const Wrapper = styled.div`
  & table {
    width: 100%;
    margin-bottom: 5px;
  }
  & table.bp3-html-table td {
    text-align: center;
    &.actions {
      padding: 3px 0;
      button + button { margin-left: 5px; }
      & > button > span { font-weight: bold; font-size: 13px; }
    }
  }
  & {
    .job + .job:before {
      content: ', ';
    }
  }
`;
const InvitationList = (observer((props: {
  isFetching: boolean,
  invitations: IInvitation[]
}) => {
  const { isFetching, invitations } = props;
  const tableHeaders = [
    "초대코드",
    "지급일",
    "타입",
    "지급대상",
    "사용일",
    "초대한 멤버"
  ];

  const headerRenderer = () => tableHeaders.map( (header, i) => <th key={ i }>{ header }</th> );
  const bodyRenderer = (invitations: IInvitation[]) => {
    return invitations.map( invitation => {

      return <tr key={ invitation.id }>
        <td>{ invitation.code }</td>
        <td>{ moment(invitation.date_issued).format('YYYY-MM-DD') }</td>
        <td>{ invitation.type }</td>
        <td>
          { invitation.from_member ? 
            <Link to={`/secondteam/member/${invitation.from_member.id}`}>{invitation.from_member.code} {invitation.from_member.name}</Link>
            : '' }
        </td>
        <td>{ invitation.date_used ? moment(invitation.date_used).format('YYYY-MM-DD') : '' }</td>
        <td>
          { invitation.to_member?
          <Link to={`/secondteam/member/${invitation.to_member.id}`}>{invitation.to_member.code} {invitation.to_member.name}</Link> : '' }
        </td>
      </tr>
    });
  }

  return (
    <Wrapper>
      <HTMLTable
        condensed={ true }
        interactive={ true }
        striped={ true} >
        <thead>
          <tr>
            { headerRenderer() }
            <th />
          </tr>
        </thead>

        <tbody>
          {
            isFetching ? 
            <tr><td colSpan={ tableHeaders.length }><Spinner /></td></tr> : bodyRenderer(invitations)
          }
        </tbody>
    </HTMLTable>
  </Wrapper>
  )
}));
export default InvitationList;
