import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components/macro";
import {
  HTMLSelect,
  IOptionProps
} from "@blueprintjs/core";

import { AppStore } from "../../../store/AppStore";

const Section = styled.section`
`;

const DEFAULT_OPTION: IOptionProps = {
  label: '템플릿을 선택하세요.', value: ''
}

interface Props {
  onlySms?: boolean;
  // excludeSupport?: boolean;
  restrictedCategories?: string[];
  value?: number;
  handleChange: (id: number) => void;
}
interface InjectedProps extends Props {
  appStore: AppStore;
}
const NotificationTemplateSelection: FC<Props> = inject("appStore")(observer((props => {
  const store = (props as InjectedProps).appStore.notificationTemplateStore;

  const [ options, setOptions ] = useState<IOptionProps[]>([]);

  useEffect(() => {

    (async () => {
      await store.fetch();
      let templates: any[] = store.templates;

      // if(props.excludeSupport) {
      //   templates = templates.filter(template => template.category !== 'SUPPORT')
      // }
      // 
      if(props.restrictedCategories) {
        templates = templates.filter(template => props.restrictedCategories && props.restrictedCategories.findIndex(category => category === template.category) > -1);
      }
      if(props.onlySms) {
        templates = templates.filter(template => template.textBody !== '');
      }
      
      setOptions([DEFAULT_OPTION].concat(
        templates.map( (template: any) => ({
          label: template.title,
          value: template.id,
      }))));
    })();

  }, [ props.restrictedCategories ]);

  return (
    <Section>
      <HTMLSelect
        value={ props.value }
        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
          props.handleChange(parseInt(e.currentTarget.value));
        }}
        options={options}
      />
    </Section>
  );
})));

export default NotificationTemplateSelection;
