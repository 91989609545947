import React, { Component, ChangeEvent, KeyboardEvent } from "react";

import { observable, computed } from "mobx";
import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import { Button, Intent, Alert, InputGroup } from "@blueprintjs/core";
import { FocusStyleManager } from "@blueprintjs/core";

import { AppStore } from "../../../store/AppStore";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";

import TaskBulletinPostPopup from "./TaskBulletinPostPopup";

const TitleContainer = styled.div`
  position: relative;
  width: 280px;
  line-height: 30px;
  padding-left: 10px;

  &:hover {
    background-color: #f0f0f0;
    color: #000;
  }  
`;
const TitleView = styled.div`
`;

// 마우스 오버시 나타나는 수정 버튼 컨테이너
const EditButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;

  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;

  &:hover {
    cursor: grab;
  }    
`;

// 에디터
const TitleEditor = styled(InputGroup)`
`;

interface TaskBulletinPostProps {
  task_bulletin: string;
  id: number;
  title: string;
}

interface InjectedProps extends TaskBulletinPostProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class TaskBulletinPost extends Component<TaskBulletinPostProps> {
  @observable showEditButtons: boolean = false;
  @observable showTitleEditor: boolean = false;
  @observable inputComponent: any = null;
  @observable title: string = '';
  @observable showDeleteAlert: boolean = false;

  @observable isOpenPopup: boolean = false;

  @observable bulletinPost: TaskBulletinPost | undefined

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get store() {
    return this.injected.appStore.taskManagerStore;
  }
  @computed
  get board() {
    return this.store.currentBoard;
  }

  async componentDidMount() {
    FocusStyleManager.onlyShowFocusOnTabs();
    this.title = this.props.title;
    // const post = await this.store.fetchTaskBulletinPost(this.props.id)

  }
  async componentDidUpdate() {
    if(this.showTitleEditor && 
      this.inputComponent && 
      this.inputComponent !== document.activeElement
    ) {
      this.inputComponent.focus();
      this.inputComponent.select();
      this.inputComponent.onkeypress = this.handleOnKeyPress;


    }        
  }  

  handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.title = e.target.value;
  };
  handleOnKeyPress = async(event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      if(this.title.trim() !== '') {
        this.handleSubmit();
      }
    }
  }
  handleCancel = () => {
    this.title = this.props.title;
    this.showTitleEditor = false;
  }  
  handleSubmit = async () => {
    try {
      
      await this.store.updateTaskBulletinPostTitle(this.props.task_bulletin, this.props.id, this.title);

      this.showTitleEditor = false;

      // 업데이트된 내용을 반영
      await this.store.fetchTaskBulletin(this.board ? this.board.projectGroup : '');
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  }
  handleDeleteSubmit = async () => {
    try {
      await this.injected.appStore.taskManagerStore.deleteTaskBulletinPost(
        this.props.id
      );
      AppToaster.show({
        message: "운영정보 항목이 삭제되었습니다.",
        intent: Intent.SUCCESS
      });

      // 업데이트된 내용을 반영
      await this.store.fetchTaskBulletin(this.board ? this.board.projectGroup : '');
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }    
  }  

  render() {
    return (
      <>
        <TitleContainer
          onMouseEnter={(e: any) => {this.showEditButtons=true}}
          onMouseLeave={(e: any) => {this.showEditButtons=false}}
        >
          {this.showTitleEditor ? (
            <TitleEditor
              inputRef={(input: any) => {this.inputComponent=input}}
              placeholder={'운영정보항목 타이틀'}
              rightElement={
                <>
                <Button
                  text={'수정'}
                  small={true}
                  disabled={this.title.trim() === ''}
                  onClick={this.handleSubmit}
                />
                <Button
                  text={'X'}
                  minimal={true}
                  onClick={this.handleCancel}
                />
                </>
              }
              value={this.title}
              onChange={this.handleTitleChange}
            />
          ) : (
            <>
            <TitleView>- {this.props.title}</TitleView>
            {this.showEditButtons && (
              <EditButtonContainer
                onClick={(e: any) => {
                  this.isOpenPopup = true;
                  e.stopPropagation();
                }}
              >
              <Button 
                  icon="edit" 
                  minimal={true} 
                  onClick={(e:any) => {this.showTitleEditor=true; e.stopPropagation();}}
              />
              <Button 
                  icon="trash" 
                  minimal={true} 
                  onClick={(e:any) => {this.showDeleteAlert=true; e.stopPropagation();}} 
              />
              </EditButtonContainer>
            )}
            </>
          )}
          {/*task_bulletin*/}
          <TaskBulletinPostPopup
            id={this.props.id}
            title={this.props.title}
            task_bulletin={this.props.task_bulletin}
            isOpen={this.isOpenPopup}
            onClose={() => {
              this.isOpenPopup = false
              this.injected.appStore.taskManagerStore.fetchTaskBulletin(this.board ? this.board.projectGroup : '');
            }}
          />          
          <Alert 
            icon="trash"
            intent={Intent.DANGER}
            isOpen={this.showDeleteAlert}
            onCancel={() => {this.showDeleteAlert=false;}}
            onConfirm={() => {this.handleDeleteSubmit();}}
            confirmButtonText={'예'}
            cancelButtonText={'아니오'}
          ><p>선택한 항목을 삭제하시겠습니까?</p><p>운영정보 항목은 완전히 삭제되어 되돌릴 수 없습니다.</p></Alert>                 
        </TitleContainer>   
      </>
    );
  }
}

export default TaskBulletinPost;