import { types } from "mobx-state-tree";
import sortBy from "lodash/sortBy";

export const TaskMultiSelectModel = types.model("TaskMultiSelect", {
  task_ids: types.optional(types.array(types.number), []),  
  person_in_charge_list: types.optional(types.array(types.number), []),
  duedate: types.optional(types.string, ""),
  label_title_list: types.optional(types.array(types.string), []),
  sprint_title_list: types.optional(types.array(types.string), []),
})
type TaskMultiSelectType = typeof TaskMultiSelectModel.Type;
export interface TaskMultiSelect extends TaskMultiSelectType {
}

export const TaskFilterModel = types.model("TaskFilter", {
  my_id: types.optional(types.number, 0),
  show_only_my_tasks: types.optional(types.boolean, false),
  person_in_charges: types.optional(types.array(types.number), []),
  sprint_title_list: types.optional(types.array(types.string), []),
  label_title_list: types.optional(types.array(types.string), []),
  use_duedate: types.optional(types.boolean, false),
  duedate_start: types.optional(types.string, ""),
  duedate_end: types.optional(types.string, "")
});
type TaskFilterType = typeof TaskFilterModel.Type;

export interface TaskFilter extends TaskFilterType {
}

export const BulletinPostLinkModel = types.model("BulletinPostLink", {
  id: types.number,
  created_at: types.string,
  updated_at: types.string,
  link: types.string,
  task_bulletin_post: types.string,
  writer: types.number
});
type BulletinPostLinkType = typeof BulletinPostLinkModel.Type;

export interface BulletinPostLink extends BulletinPostLinkType {
}


export const BulletinPostFileModel = types.model("BulletinPostFile", {
  id: types.number,
  created_at: types.string,
  updated_at: types.string,
  file: types.string,
  task_bulletin_post: types.string,
  writer: types.number,
  is_image: types.boolean,
  filename: types.string,
});
type BulletinPostFileType = typeof BulletinPostFileModel.Type;

export interface BulletinPostFile extends BulletinPostFileType {
}

export const TaskBulletinPostModel = types.model("TaskBulletinPost", {
  id: types.number,
  createdAt: types.string,
  updatedAt: types.string,
  order: types.number,
  task_bulletin_post_id: types.string,
  title: types.string,
  content: types.string,
  task_bulletin: types.string,
  writer: types.string,
  links: types.array(BulletinPostLinkModel),
  files: types.array(BulletinPostFileModel)

}).actions(self => ({
  setTitle(value: string) {
    self.title = value;
  },
  setContent(value: string) {
    self.content = value;
  },
  setOrder(value: number) {
    self.order = value;
  },
}));

type TaskBulletinPostType = typeof TaskBulletinPostModel.Type;

export interface TaskBulletinPost extends TaskBulletinPostType {
}

export const TaskBulletinModel = types.model("TaskBulletin", {
  id: types.number,
  createdAt: types.string,
  updatedAt: types.string,
  order: types.number,
  task_bulletin_id: types.string,
  title: types.string,
  taskBoardId: types.string,
  task_bulletin_posts: types.array(TaskBulletinPostModel)
}).views(self => ({
  get sortedTaskBulletinPosts() {
    return sortBy(
      self.task_bulletin_posts.slice(), 
      ['order']
    );
  },
}));
type TaskBulletinType = typeof TaskBulletinModel.Type;

export interface TaskBulletin extends TaskBulletinType {
}



export const TaskLinkModel = types.model("TaskLink", {
  id: types.number,
  created_at: types.string,
  updated_at: types.string,
  link: types.string,
  task: types.string,
  writer: types.number
});
type TaskLinkType = typeof TaskLinkModel.Type;

export interface TaskLink extends TaskLinkType {
}

export const TaskFileModel = types.model("TaskFile", {
  id: types.number,
  created_at: types.string,
  updated_at: types.string,
  file: types.string,
  task: types.string,
  writer: types.number,
  is_image: types.boolean,
  filename: types.string,
});
type TaskFileType = typeof TaskFileModel.Type;

export interface TaskFile extends TaskFileType {
}

export const TaskCommentModel = types.model("TaskComment", {
  id: types.number,
  created_at: types.string,
  updated_at: types.string,
  comment: types.string,
  task: types.string,
  writer: types.number
});
type TaskCommentType = typeof TaskCommentModel.Type;

export interface TaskComment extends TaskCommentType {
}

export const SubTaskModel = types.model("SubTask", {
  id: types.number,
  created_at: types.string,
  updated_at: types.string,
  order: types.number,
  sub_task_id: types.string,
  title: types.string,
  checked: types.boolean,
  sub_task_list: types.string
});
type SubTaskType = typeof SubTaskModel.Type;

export interface SubTask extends SubTaskType {
}

export const SubTaskListModel = types.model("SubTaskList", {
  id: types.number,
  created_at: types.string,
  updated_at: types.string,
  order: types.number,
  sub_task_list_id: types.string,
  title: types.string,
  task: types.string,
  sub_tasks: types.array(SubTaskModel)
});
type SubTaskListType = typeof SubTaskListModel.Type;

export interface SubTaskList extends SubTaskListType {
}

export const TaskLabelModel = types.model("TaskLabel", {
  id: types.number,
  title: types.string,
  task: types.string,
  task_label: types.string
});

// export const TaskLabelModel = types.model("TaskLabel", {
//   id: types.number,
//   title: types.string,
//   task: types.string,
//   taskLabel: types.string
// });
type TaskLabelType = typeof TaskLabelModel.Type;

export interface TaskLabel extends TaskLabelType {
}

export const TaskSprintModel = types.model("TaskSprint", {
  id: types.number,
  title: types.string,
  task: types.string,
  task_sprint: types.string
});
type TaskSprintType = typeof TaskSprintModel.Type;

export interface TaskSprint extends TaskSprintType {
}

export const TaskModel = types.model("Task", {
  id: types.number,
  created_at: types.string,
  updated_at: types.string,
  order: types.number,
  task_id: types.string,
  task_no: types.number,
  title: types.string,
  description: types.string,
  due_date: types.union(types.string, types.null),
  is_archived: types.boolean,
  date_archived: types.union(types.string, types.null),
  task_board: types.string,
  task_list: types.string,
  person_in_charge_list: types.optional(types.array(types.number), []),
  sprint_list: types.array(TaskSprintModel),
  label_list: types.array(TaskLabelModel),
  sub_task_lists: types.array(SubTaskListModel),
  task_links: types.array(TaskLinkModel),
  task_files: types.array(TaskFileModel),
  task_comments: types.array(TaskCommentModel)
});
type TaskType = typeof TaskModel.Type;

export interface Task extends TaskType {
}

export const TaskListModel = types.model("TaskList", {
  id: types.number,
  created_at: types.string,
  updated_at: types.string,
  order: types.number,
  task_list_id: types.string,
  title: types.string,
  is_editable: types.boolean,
  color: types.string,
  person_in_charge_list: types.optional(types.array(types.number), []),
  task_board: types.string,
  tasks: types.array(TaskModel)
});
type TaskListType = typeof TaskListModel.Type;

export interface TaskList extends TaskListType {
}

export const TaskBoardMemberModel = types.model("TaskBoardMember", {
  id: types.number,
  name: types.string,
  email: types.string
});
type TaskBoardMemberType = typeof TaskBoardMemberModel.Type;

export interface TaskBoardMember extends TaskBoardMemberType {
}

export const TaskBoardModel = types.model("TaskBoard", {
  id: types.number,
  createdAt: types.string,
  updatedAt: types.string,
  taskBoardId: types.string,
  projectGroup: types.string,
  projectGroupName: types.string,
  sprintTitleList: types.array(types.string),
  labelTitleList: types.array(types.string),
  members: types.array(TaskBoardMemberModel),
  taskLists: types.array(TaskListModel)
});
type TaskBoardType = typeof TaskBoardModel.Type;

export interface TaskBoard extends TaskBoardType {
}
