import React, { Component } from "react";
import styled from "styled-components/macro";

const CustomAddLaneLink = styled.button`
  background: lightgray;
  border: none;
  color: #fff;
  transition: background 0.3s ease;
  min-height: 32px;
  padding: 4px 16px;
  vertical-align: top;
  margin-top: 0;
  margin-right: 0px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  margin-bottom: 0;
`;
const CustomAddLaneSectionContainer = styled.section`
  width: 260px;
  background-color: lightgray;
  border-radius: 3px;
  margin: 5px;
  position: relative;
  padding: 5px;
  display: inline-flex;
  height: auto;
  flex-direction: column;
`;
export const AddTaskListView = ({onClick, t}: any) => (
  <CustomAddLaneSectionContainer>
    <CustomAddLaneLink 
      onClick={onClick} 
      onMouseOver={(e) => {e.currentTarget.style.backgroundColor='gray'}}
      onMouseLeave={(e) => {e.currentTarget.style.backgroundColor='lightgray'}}
    >
      {'+ 추가'}
    </CustomAddLaneLink>
  </CustomAddLaneSectionContainer>
);