import { types } from "mobx-state-tree";
import { ClientScheduleModel } from "./ClientSchedule";
import { RufreeScheduleModel } from "./RufreeSchedule";

export const ProjectScheduleModel = types.model("ProjectSchedule", {
  startAt: types.string,
  title: types.string
});
type ProjectScheduleType = typeof ProjectScheduleModel.Type;
export interface ProjectSchedule extends ProjectScheduleType {}

export const ScheduleModel = types.model("Schedule", {
  clientSchedules: types.array(ClientScheduleModel),
  rufreeSchedules: types.array(RufreeScheduleModel),
  createdAt: types.string,
  updatedAt: types.string,
  dateStart: types.string,
  dateEnd: types.string,
  sprintsCount: types.model({
    total_count: types.number,
    ongoing_count: types.number,
    done_count: types.number
  })
});

type ScheduleType = typeof ScheduleModel.Type;
export interface Schedule extends ScheduleType {}
