import { types } from "mobx-state-tree";

export const ConsultFunctionalityFormModel = types
  .model("ConsultFunctionalityForm", {
    checked: types.optional(types.boolean, false),
    etc: types.optional(types.boolean, false),
    text: types.optional(types.string, "")
  })
  .actions(self => ({
    setChecked(value: boolean) {
      self.checked = value;
    },
    setEtc(value: boolean) {
      self.etc = value;
    },
    setText(value: string) {
      self.text = value;
    }
  }));

type ConsultFunctionalityFormType = typeof ConsultFunctionalityFormModel.Type;
export interface ConsultFunctionalityForm
  extends ConsultFunctionalityFormType {}
