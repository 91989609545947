import React, { Component } from "react";
import styled, { css } from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { observable, computed } from "mobx";
import { RouteComponentProps } from "react-router";
import { getValueFromSearch } from '../../../utils/getValueFromSearch';
import { 
  Spinner,
  Intent,
  Alert,
  Classes,
  Menu,
  MenuItem,
  Icon,
  Card,
  Collapse
} from "@blueprintjs/core";
import {
  EtcSection, EtcSectionBody, EtcSectionTitle, H6Wrapper
} from "../../atoms/DashboardComponents/DashboardComponents";
import { Link } from "react-router-dom";
import AccountHistory from "../../organisms/AccountHistory/AccountHistory";
import ProjectGroupSchedule from "../../organisms/ProjectGroupSchedule/ProjectGroupSchedule";
import ProjectGroupRiskList from "../../molecules/ProjectGroupRiskList/ProjectGroupRiskList";
import SprintBoardTable from "../../organisms/SprintReportTable/SprintBoardTable";
import ExpenseTable from '../../organisms/ExpenseTable/ExpenseTable';
import SprintReviewTable from '../../organisms/SprintReviewTable/SprintReviewTable';
import { PlanReadSection } from "../../organisms/Plan/PlanReadSection";
import ProjectGroupSettingsPopup from "../../organisms/ProjectGroupSettingsPopup/ProjectGroupSettingsPopup";
import ProjectGroupCompletePopup from "../../organisms/ProjectGroupCompletePopup/ProjectGroupCompletePopup";
import {
  DefaultButton,
  VerticallyCenteredRow,
  GreyLabel,
  ContentColumn,
  DashedDividerLine,
  LeftColumn,
  LargeText,
  NormalText,
  LabelPadding,
  MetaHead,
  SubTitle,
  Title,
  HeadMenu,
  CardContainer
} from "../../atoms/DetailReportComponents/DetailReportComponents";
import { ConvertLinkTextFormat }  from "../../atoms/ConvertLinkTextFormat/ConvertLinkTextFormat";
import LargeContainer from "../../atoms/LargeContainer/LargeContainer";
import {
  PageNavWrapper,
  PageNavLink,
  PageNavText
} from "../../atoms/PageNav/PageNav";
import { BreadCrumb } from "../../molecules/BreadCrumb/BreadCrumb";
import ProjectGroupStatus from "../../molecules/ProjectGroupStatus/ProjectGroupStatus";
import ProjectGroupTools from '../../molecules/ProjectGroupTools/ProjectGroupTools';
import ScheduleEditPopup from "../../molecules/ScheduleItem/ScheduleEditPopup";
import { AddCircle } from "styled-icons/remix-line/AddCircle/AddCircle";
import { RoundedButton } from "../../atoms/RoundedButton/RoundedButton";
import IconEdit from "./ic-edit.svg";
import { RepositoryTemplateType } from "../../../types/projectGroup";
import ProjectGroupRepositoryAddPopup from "../../organisms/ProjectGroupRepositoryAddPopup/ProjectGroupRepositoryAddPopup";
import ProjectGroupRepositoryEditPopup from "../../organisms/ProjectGroupRepositoryEditPopup/ProjectGroupRepositoryEditPopup";
import ProjectGroupLinkEditPopup from "../../organisms/ProjectGroupLinkEditPopup/ProjectGroupLinkEditPopup";
import { Repository } from "../../../store/models/Repository";
import { ScheduleManager } from "../../../store/models/ScheduleManager";
import { RufreeRole } from "../../../store/models/RufreeRole";
import { ETEMPLATETYPE } from "../../../store/models/NotificationTemplate";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import TasktaskInfoPopup from "../../organisms/TasktaskInfoPopup/TasktaskInfoPopup";
import TaskBoardOpenPopup from "../../organisms/TaskManager/TaskBoardOpenPopup";
import moment from "moment";
import { FormMode } from "../../../types/formMode";
import ProjectGroupComment from "../../organisms/ProjectGroupComment/ProjectGroupComment";
import SendDialog from "../../organisms/NotificationTemplate/SendDialog";
import {
  ScheduleBrief
} from '../../molecules/Plan/ScheduleBrief';
import {
  ScheduleIcon
} from '../../molecules/Plan/_wrapper';

const Wrapper = styled.div`
  margin-bottom: 10px;
`;
const TasktaskInfoButton = styled(DefaultButton)`
  width: 150px;
  min-width: 150px;
  height: 38px;
`;
const TasktaskRequestButton = styled(RoundedButton)`
  width: 150px;
  min-width: 150px;
  height: 38px;

  font-size: 12px;
  margin-left: 20px;
`;
const EditButton = styled(RoundedButton)`
  // width: 99px;
  min-width: 99px;
  height: 42px;

  font-size: 12px;
`;
const ProjectBoardButton = styled(EditButton)`
  background-color: gray;
`;
const CompleteButton = styled(EditButton)`
  background-color: #DB3737;
`;
const HoldingButton = styled(EditButton)`
  background-color: #752F75;
`;
const EditScheduleButton = styled.button`
  display: inline-block;
  width: 60px;
  height: 40px;
  text-align: center;
  background: none;
  border: none;
  cursor: pointer;
`;
const EditScheduleButtonIcon = styled.div<{ src: string }>`
  background: url(${p => p.src}) no-repeat center center;
  width: 57px;
  height: 57px;
  margin: auto;
`;
const ManagerItem = styled.div`
  margin-bottom: 40px;
`;
const RightAlignedContentColumn = styled(ContentColumn)`
  text-align: right;
`;
const TasktaskSection = styled.section`
  margin-bottom: 35px;
`;
const ScheduleSection = styled.section`
  margin-bottom: 35px;
`;
const SprintSection = styled.section`
  margin-bottom: 50px;
`;
const ExpenseSection = styled.section`
  margin-bottom: 50px;
`;
const SprintReviewSection = styled.section`
  margin-bottom: 50px;
`;

const ColumnLayout = styled.div<{ column: number }>`
  display: flex;
  margin-bottom: 15px;
  & > * + * {
    margin-left: 10px;
  }

  ${p =>
    css`
      & > div {
        width: calc(100% / ${p.column});
      }
    `
  }
`;
const PlanSection = styled.div`
  & > div:first-child {
    display: flex;
    border-bottom: 1px solid #666666;
    padding-bottom: 16px;
    margin-bottom: 45px;

    span {
      margin-top: 15px;
      margin-right: 6px;
    }

    div.title {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      padding-top: 12px;
      margin-right: 30px;
    }
    div.schedule {
      padding-top: 10px;
    }
    a {
      margin-left: auto;
    }
  }
`;

const TabMenu =  styled.div`
  margin-bottom: 50px;
  & > * + * {
    margin-left: 5px;
  }
`;
const TabButton = styled(RoundedButton)<{ active?: boolean, minimal?: boolean }>`
  font-size: 12px;
  height: 35px;
  min-width: 100px;
  padding: 0px 10px;

  ${p =>
    p.minimal &&
      css`
      background-color: #000000;
        text-decoration: underline;
        .active { color: #ffffff; }
      `}

  ${p =>
    !p.active &&
    css`
      background-color: #CED9E0;
      color: #000000;
    `}
`;
const TabButtonMenu = styled(Menu)<{ active?: boolean }>`
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 0px;
  background: inherit;
  min-width: 50px;
  padding: 0px;
  .bp3-overlay.bp3-overlay-inline {
    display: block !important;
    overflow: hidden !important;
  }
  ${p =>
    !p.active &&
    css`
    .bp3-text-overflow-ellipsis bp3-fill { color: #000000; }
    `}
`;
const TabContent = styled.div<{ active?: boolean }>`
  display: block;

  ${p =>
    !p.active &&
    css`
      display: none;
    `}
`;
const EditableListItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;

  div > * + * {
    margin-left: 10px;
  }
  svg {
    width: 12px;
    height: 12px;
  }
`;

const InspectionTextLabel = styled.div`
  font-weight: bold;
  color: #106ba3;
  padding-left: 27px;
  border-left: 3px solid #106ba3;
`;

const InspectionAttentionText = styled.div`
  white-space: pre-line;
  word-break: keep-all;
  max-width: 650px;
  padding: 10px 0 0 30px;
`;

interface PageParams {
  projectGroupId: string;
}
interface ProjectGroupDetailPageProps extends RouteComponentProps<PageParams> {}

interface InjectedProps extends ProjectGroupDetailPageProps {
  appStore: AppStore;
}

const templateCodeToText = (code: RepositoryTemplateType): string => {
  return {
    WF: "웹 프론트엔드",
    WB: "웹 기본형",
    "--": "기본형"
  }[code];
};

@inject("appStore")
@observer
class ProjectGroupDetailPage extends Component<ProjectGroupDetailPageProps> {
  @observable readyToShow = false;
  @observable currentTab = 'info';
  @observable currentReportId = '';
  @observable highlightedInspectionId = "";
  @observable highlightedRufree = "";
  @observable repoToEdit: Repository | null = null;
  @observable repoToDelete: Repository | null = null;
  @observable scheduleToEdit: ScheduleManager | null = null;
  @observable isCompleteOpen = false;
  @observable isSettingsOpen = false;
  @observable isRepoRemoveAlertOpen = false;
  @observable isHoldingAlertOpen = false;
  @observable isTasktaskPopupOpen = false;
  @observable isRepositoryOpen = false;
  @observable isRepositoryEditOpen = false;
  @observable isScheduleEditorOpen = false;
  @observable isLinkEditOpen = false;
  @observable isTaskBoardPopupOpen = false;
  @observable isInspectionAttentionOpen = true;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get projectGroup() {
    const { projectGroupId } = this.props.match.params;
    return this.injected.appStore.projectGroupStore.projectGroups.find(
      x => x.groupId === projectGroupId
    );
  }

  @computed
  get tasktaskStore() {
    return this.injected.appStore.tasktaskStore;
  }

  @computed
  get notificationTemplateStore() {
    return this.injected.appStore.notificationTemplateStore;
  }

  @computed
  get rufreeRolesInInspection(): RufreeRole[] {

    if (this.projectGroup) {
      const inspectionId = this.projectGroup.inspections[0];

      const inspection = this.injected.appStore.inspectionStore.inspections.find(inspection => inspection.inspectionId === inspectionId);
      if(inspection) {
        return inspection.rufreeRoles;
      }
      
    }

    // if(!this.projectGroup) {
    //   return [];
    // }

    // if(this.projectGroup !== undefined) {
    // if(this.projectGroup.inspections.length > 0) {
    //   const inspection = this.injected.appStore.inspectionStore.inspections.find(inspection => inspection.inspectionId === this.projectGroup.inspections[0])
    //   // const { role } = rufreeScheduleForm;
    //   return inspection.rufreeRoles;
    // }
    // }
    

    return [];
  }

  private async fetchProjectGroupById(groupId: string) {
    try {
      await this.injected.appStore.projectGroupStore.fetchProjectGroup(groupId);

      if (this.projectGroup) {
        await Promise.all(
          this.projectGroup.inspections.map(async inspectionId => {
            await this.injected.appStore.inspectionStore.fetchInspectionById(
              inspectionId
            );
          })
        );
        await this.tasktaskStore.fetchTasktask(groupId);
        await this.injected.appStore.scheduleManagerStore.fetchSchedules(groupId);
      }
    } catch (e) {
    } finally {
    }
  }

  async componentDidUpdate(prevProps: any) {
    if (this.props.match.params.projectGroupId !== prevProps.match.params.projectGroupId) {
      const { projectGroupId } = this.props.match.params;
      try {
        this.readyToShow = false;
        await this.fetchProjectGroupById(projectGroupId);
      } catch (e) {
      } finally {
        this.readyToShow = true;
      }
    }
  }

  async componentDidMount() {
    try {
      await this.injected.appStore.pmUserStore.fetchPMUsers();

      const { projectGroupId } = this.props.match.params;
      await this.fetchProjectGroupById(projectGroupId);
    } catch (e) {
    } finally {
      const tabId = getValueFromSearch('tab', this.props.location.search);
      const reportId = getValueFromSearch('reportId', this.props.location.search);
      this.currentTab = tabId ? tabId : this.currentTab;
      this.currentReportId = reportId ? reportId : this.currentReportId;

      this.readyToShow = true;
    }
  }

  handleTasktaskInfo = () => {
    window.open('https://www.notion.so/seesorufree/4426c23188b944f1a68a3c5e07d6a5e9', '_blank');
  };

  handleTasktaskRequestClick = () => {
    const { projectGroupId } = this.props.match.params;
    const tasktask = this.tasktaskStore.findByProjectGroup(projectGroupId);

    if(tasktask) {
      this.props.history.push(`/project-groups/${projectGroupId}/tasktask/${tasktask.id}`);
    } else {
      this.props.history.push(`/project-groups/${projectGroupId}/tasktask/-1`);
    }
  };

  handleScheduleClick = () => {
    const { projectGroupId } = this.props.match.params;
    this.props.history.push(`/project-groups/${projectGroupId}/schedule`);
  };

  handleRiskClick = () => {
    const { projectGroupId } = this.props.match.params;
    this.props.history.push(`/project-groups/${projectGroupId}/risk`);
  };

  toggleSettings = () => {
    this.isSettingsOpen = !this.isSettingsOpen;
  };

  toggleComplete = () => {
    this.isCompleteOpen = !this.isCompleteOpen;
  };

  toggleHolding = () => {
    this.isHoldingAlertOpen = !this.isHoldingAlertOpen;
  };

  toggleRepository = () => {
    this.isRepositoryOpen = !this.isRepositoryOpen;
  };

  toggleRepositoryEdit = () => {
    this.isRepositoryEditOpen = !this.isRepositoryEditOpen;
  };

  toggleRepoRemoveAlert = () => {
    this.isRepoRemoveAlertOpen = !this.isRepoRemoveAlertOpen;
  };

  toggleLinkEdit = () => {
    this.isLinkEditOpen = !this.isLinkEditOpen;
  }

  handleRepoEditClick = (repo: Repository) => {
    this.repoToEdit = repo;
    this.isRepositoryEditOpen = true;
  };

  handleRepoDeleteClick = (repo: Repository) => {
    this.repoToDelete = repo;
    this.isRepoRemoveAlertOpen = true;
  };

  handleScheduleEditClick = (schedule: ScheduleManager | null) => {
    this.scheduleToEdit = schedule;
    this.isScheduleEditorOpen = true;
  }

  handleToggleHolding = async () => {
    if(this.projectGroup) {
      try {
        await this.projectGroup.patchHolding(!this.projectGroup.isHolding);

        AppToaster.show({
          message: `프로젝트가 ${this.projectGroup.isHolding ? '홀드' : '재개'} 되었습니다.`,
          intent: Intent.SUCCESS
        });
        
      } catch (e) {
        const error = JSON.stringify(e.response.data);

        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }
    }

    this.isHoldingAlertOpen = false;
  }

  handleRepoDelete = async () => {
    try {
      if (!this.projectGroup || !this.repoToDelete) {
        return;
      }

      await this.projectGroup.removeRepository(this.repoToDelete.id);

      AppToaster.show({
        message: "저장소가 삭제되었습니다.",
        intent: Intent.SUCCESS
      });

      this.toggleRepoRemoveAlert();
    } catch (e) {
      const error = JSON.stringify(e.response.data);

      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  handleCancel = () => {
    this.props.history.push("/project-groups");
  };

  handleEdit = () => {
    this.props.history.push(
      `/project-groups/${this.props.match.params.projectGroupId}/edit`
    );
  };

  handleTabClick = (tab: string) => {
    this.currentTab = tab;
  }

  getManagerById = (managerId: string) => {
    const manager = this.injected.appStore.pmUserStore.pmUsers.find(
      x => x.id + "" === managerId
    );
    return manager;
  };

  setHightlight = ({
    inspectionId,
    rufreeRole
  }: {
    inspectionId: string;
    rufreeRole: string;
  }) => {
    this.highlightedInspectionId = inspectionId;
    this.highlightedRufree = rufreeRole;

    var inspection = document.getElementById(inspectionId);

    if (inspection) {
      inspection.scrollIntoView(true);
    }
  };

  render() {
    if (!this.readyToShow) {
      return <Spinner />;
    }

    if (!this.projectGroup) {
      return <Wrapper>Project Group을 가져오지 못했습니다.</Wrapper>;
    }

    const {
      clientName,
      client,
      name,
      approvedContractId,
      schedule,
      risks,
      repositories,
      receptions,
      consults,
      references,
      isComplete,
      canComplete,
      dateComplete,
      dateKeeping,
      dateKeepingComplete,
      dateFlawing,
      dateFlawingComplete,
      isHolding,
      dateHolding,
      dueDateFlaw,
      dueDateKeep,
      isFlawing,
      isKeeping,
    } = this.projectGroup;

    const { isPM, isClient, userId, email } = this.injected.appStore.userStore;
    const remainedFlawDays = dueDateFlaw ? moment(dueDateFlaw).diff(moment(), 'days') : 0;
    const remainedKeepDays = dueDateKeep ? moment(dueDateKeep).diff(moment(), 'days') : 0;
    const tasktask = this.tasktaskStore.findByProjectGroup(this.projectGroup.groupId);
    const { upcomingSchedules } = this.injected.appStore.scheduleManagerStore;

    return (
      <LargeContainer>
        <PageNavWrapper>
          <BreadCrumb
            items={[
              { name: "프로젝트 그룹", link: "/project-groups" },
              this.projectGroup.name
            ]}
          />
          {isPM && (
            <PageNavLink to="/project-groups/new">
              <PageNavText>
                <AddCircle size={21} /> <span>새 프로젝트 그룹</span>
              </PageNavText>
            </PageNavLink>
          )}
        </PageNavWrapper>

        <MetaHead style={{ marginBottom: "33px" }}>
          <SubTitle>{this.projectGroup.groupId}</SubTitle>
          <Title>
            {this.projectGroup.name}
            <SubTitle
              style={{ display: 'flex' }}>
              <ProjectGroupStatus status={this.projectGroup.status} />
              {
                isHolding && dateHolding &&
                  <span style={{color: '#394B59', marginLeft: '10px'}}>
                    (홀드일: {moment(dateHolding).format('YYYY-MM-DD')})
                  </span>
              }
              {
                isFlawing && dateFlawing && dateFlawingComplete &&
                  <span style={{color: '#394B59', marginLeft: '10px'}}>
                    - 하자보수 완료({moment(dateFlawing).format('YYYY-MM-DD')} ~ {moment(dateFlawingComplete).format('YYYY-MM-DD')})
                  </span>
              }
              {
                isFlawing && dateFlawing && !dateFlawingComplete &&
                  <span style={{color: '#394B59', marginLeft: '10px'}}>
                    - 하자보수 진행중(시작일: {moment(dateFlawing).format('YYYY-MM-DD')})
                  </span>
              }
              {
                isKeeping && dateKeeping && dateKeepingComplete &&
                  <span style={{color: '#394B59', marginLeft: '10px'}}>
                    - 유지보수 완료({moment(dateKeeping).format('YYYY-MM-DD')} ~ {moment(dateKeepingComplete).format('YYYY-MM-DD')})
                  </span>
              }
              {
                isKeeping && dateKeeping && !dateKeepingComplete &&
                  <span style={{color: '#394B59', marginLeft: '10px'}}>
                    - 유지보수 진행중(시작일: {moment(dateKeeping).format('YYYY-MM-DD')})
                  </span>
              }
              
            </SubTitle>
          </Title>

          {isPM && (
            <HeadMenu>

              <ProjectGroupTools
                toolLinks={this.projectGroup.toolLinks} />

              <ProjectBoardButton 
                onClick={() => {
                  this.notificationTemplateStore.setFormMode(FormMode.Read);
                  this.notificationTemplateStore.setOpenPopup(true);
                }}
              >
                <Icon
                  icon='envelope'
                  style={{marginRight: '1px'}} />
                이메일/문자 발송
              </ProjectBoardButton>

              <>
                <ProjectBoardButton onClick={() => {
                  // this.isTaskBoardPopupOpen = true;
                  alert('준비중입니다')
                }}>태스크보드</ProjectBoardButton>
                <TaskBoardOpenPopup
                  projectGroupId={this.projectGroup.groupId}
                  isOpen={this.isTaskBoardPopupOpen}
                  onClose={() => {this.isTaskBoardPopupOpen = false}}
                />
              </>

              {!isComplete && canComplete && (
                <CompleteButton onClick={this.toggleComplete}>종료</CompleteButton>
              )}

              {!isComplete && (
                <>
                  <HoldingButton
                    onClick={this.toggleHolding}
                  >
                    {isHolding ? '재개' : '홀드'}
                  </HoldingButton>
                  <Alert
                    cancelButtonText="취소"
                    confirmButtonText={isHolding ? '재개' : '홀드'}
                    icon={<Icon icon={"pause"} color={'#752F75'} iconSize={40} />}
                    isOpen={this.isHoldingAlertOpen}
                    onCancel={this.toggleHolding}
                    onConfirm={this.handleToggleHolding}
                    canEscapeKeyCancel={true}
                    canOutsideClickCancel={true}
                  >
                    <p>{name} 프로젝트를 {isHolding ? '재개' : '홀드'}하시겠습니까?</p>
                  </Alert>
                </>
              )}

              { isComplete && !dateFlawing && remainedFlawDays > 0 && (
                <>
                  <HoldingButton
                    style={{ width: '120px'}}
                    onClick={this.toggleHolding}
                    disabled={ isFlawing }
                  >
                    { `하자보수 진행${isFlawing ? '중' : ''}` }
                  </HoldingButton>
                  <Alert
                    cancelButtonText="취소"
                    confirmButtonText='하자보수 진행'
                    icon={<Icon icon={"build"} color={'#752F75'} iconSize={40} />}
                    isOpen={this.isHoldingAlertOpen}
                    onCancel={this.toggleHolding}
                    onConfirm={ async() => {
                      if(this.projectGroup) {
                        try {
                          await this.projectGroup.patchFlawing();
                  
                          AppToaster.show({
                            message: `프로젝트가 하자보수 진행 중 상태로 설정되었습니다.`,
                            intent: Intent.SUCCESS
                          });
                        } catch (e) {
                          const error = JSON.stringify(e.response.data);
                  
                          AppToaster.show({
                            message: "오류: " + error,
                            intent: Intent.DANGER
                          });
                        }
                      this.isHoldingAlertOpen = false;
                      }
                    }}
                    canEscapeKeyCancel={true}
                    canOutsideClickCancel={true}
                  >
                    <p>{name} 프로젝트를 하자보수 진행중 상태로 설정하시겠습니까?</p>
                  </Alert>
                </>
              )}

              { isComplete && dateFlawing && !dateFlawingComplete && (
                <>
                  <HoldingButton
                    style={{ width: '120px'}}
                    onClick={ async() => {
                      if(this.projectGroup) {
                        try {
                          await this.projectGroup.patchFlawingComplete();
                  
                          AppToaster.show({
                            message: `프로젝트가 하자보수가 완료되었습니다.`,
                            intent: Intent.SUCCESS
                          });
                        } catch (e) {
                          const error = JSON.stringify(e.response.data);
                  
                          AppToaster.show({
                            message: "오류: " + error,
                            intent: Intent.DANGER
                          });
                        }
                      }
                    }}
                  >
                    { `하자보수 완료` }
                  </HoldingButton>
                </>
              )}

              { isComplete && !dateKeeping && (dateFlawingComplete || (remainedFlawDays < 0 && remainedKeepDays > 0))  && (
                <>
                  <HoldingButton
                    style={{ width: '120px'}}
                    onClick={this.toggleHolding}
                    disabled={ isKeeping }
                  >
                    { `유지보수 진행${isKeeping ? '중' : ''}` }
                  </HoldingButton>
                  <Alert
                    cancelButtonText="취소"
                    confirmButtonText='유지보수 진행'
                    icon={<Icon icon={"build"} color={'#752F75'} iconSize={40} />}
                    isOpen={this.isHoldingAlertOpen}
                    onCancel={this.toggleHolding}
                    onConfirm={ async() => {
                      if(this.projectGroup) {
                        try {
                          await this.projectGroup.patchKeeping();
                  
                          AppToaster.show({
                            message: `프로젝트가 유지보수 진행 중 상태로 설정되었습니다.`,
                            intent: Intent.SUCCESS
                          });
                        } catch (e) {
                          const error = JSON.stringify(e.response.data);
                  
                          AppToaster.show({
                            message: "오류: " + error,
                            intent: Intent.DANGER
                          });
                        }
                      this.isHoldingAlertOpen = false;
                      }
                    }}
                    canEscapeKeyCancel={true}
                    canOutsideClickCancel={true}
                  >
                    <p>{name} 프로젝트를 유지보수 진행중 상태로 설정하시겠습니까?</p>
                  </Alert>
                </>
              )}

              { isComplete && dateKeeping && !dateKeepingComplete && (
                <>
                  <HoldingButton
                    style={{ width: '120px'}}
                    onClick={ async() => {
                      if(this.projectGroup) {
                        try {
                          await this.projectGroup.patchKeepingComplete();
                  
                          AppToaster.show({
                            message: `프로젝트가 유지보수가 완료되었습니다.`,
                            intent: Intent.SUCCESS
                          });
                        } catch (e) {
                          const error = JSON.stringify(e.response.data);
                  
                          AppToaster.show({
                            message: "오류: " + error,
                            intent: Intent.DANGER
                          });
                        }
                      }
                    }}
                  >
                    { `유지보수 완료` }
                  </HoldingButton>
                </>
              )}

              <DefaultButton onClick={this.toggleSettings}>설정</DefaultButton>
              <EditButton onClick={this.handleEdit}>수정 하기</EditButton>
            </HeadMenu>
          )}
        </MetaHead>

        <CardContainer>

          <TabMenu>
            <TabButton
              active={this.currentTab === 'info' || this.currentTab === 'comment' ? true : false}
              onClick={() => this.handleTabClick('info')}
            >
              운영정보
            </TabButton>

            {
              receptions.length ? (
                receptions.length > 1 ? (
                  <TabButton>
                    <TabButtonMenu>
                      <MenuItem text="의뢰서">
                      {
                        receptions.map((reception, index) => (
                          <Link key={index} to={`/receptions/${reception}`} target="_blank">
                            <MenuItem text={`${reception}`} />
                          </Link>
                        ))
                      }
                      </MenuItem>
                    </TabButtonMenu>
                  </TabButton>
                ) : (
                  <Link to={`/receptions/${receptions[0]}`} target="_blank">
                    <TabButton>
                      의뢰서
                    </TabButton>
                  </Link>
                )
              ) : (
                <TabButton 
                  disabled={true}
                >
                  의뢰서
                </TabButton>
              )
            }

            {
              isPM && (
                consults.length ? (
                  consults.length > 1 ? (
                    <TabButton>
                      <TabButtonMenu>
                        <MenuItem text="영업상담서">
                        {
                          consults.map((consult, index) => (
                            <Link key={index} to={`/consulting/projects/${consult}`} target="_blank">
                              <MenuItem text={`${consult}`} />
                            </Link>
                          ))
                        }
                        </MenuItem>
                      </TabButtonMenu>
                    </TabButton>
                  ) : (
                    <Link to={`/consulting/projects/${consults[0]}`} target="_blank">
                      <TabButton>
                        영업상담서
                      </TabButton>
                    </Link>
                  )
                ) : (
                  <TabButton 
                    disabled={true}
                  >
                    영업상담서
                  </TabButton>
                )
              )
            }

            <TabButton
              active={this.currentTab === 'inspection' ? true : false}
              onClick={() => this.handleTabClick('inspection')}
              disabled={this.projectGroup.inspections.length > 0 ? false : true}
            >
              프로젝트플랜
            </TabButton>

            <Link to={`/contract/client/${approvedContractId}/read`} target="_blank">
              <TabButton 
                disabled={approvedContractId ? false : true}
              >
                계약서
              </TabButton>
            </Link>

            <TabButton
              active={this.currentTab === 'risk' ? true : false}
              onClick={() => this.handleTabClick('risk')}
            >
              프로젝트리스크
            </TabButton>

            <TabButton 
              active={this.currentTab === 'sprint' ? true : false}
              onClick={() => this.handleTabClick('sprint')}
            >
              종료현황
            </TabButton>

            <TabButton
              active={this.currentTab === 'review' ? true : false}
              onClick={() => this.handleTabClick('review')}
            >
              리뷰
            </TabButton>

            <TabButton 
              active={this.currentTab === 'calculate' ? true : false}
              onClick={() => this.handleTabClick('calculate')}
            >
              영수증
            </TabButton>
          </TabMenu>

          <TabContent active={this.currentTab === 'info' ? true : false}>
            {isComplete &&(
              <TasktaskSection>
                <VerticallyCenteredRow>
                  <LeftColumn style={{ display: "flex", alignItems: "center" }}>
                      <GreyLabel>하자보수</GreyLabel>
                      
                  </LeftColumn>

                  <RightAlignedContentColumn>
                    {
                      !dateFlawingComplete && remainedFlawDays > 0 && 
                        <LargeText>하자보수 요청기간 {remainedFlawDays}일 남음</LargeText>
                    }
                    {
                      (remainedKeepDays > 0 && remainedFlawDays < 0) || dateFlawingComplete && 
                        <LargeText>유지보수 요청기간 {remainedKeepDays}일 남음</LargeText>
                    }
                  </RightAlignedContentColumn>
                </VerticallyCenteredRow>

                <VerticallyCenteredRow style={{marginTop: '10px'}}>
                  <RightAlignedContentColumn>
                    <TasktaskInfoButton onClick={this.handleTasktaskInfo}>하자/유지 보수 안내</TasktaskInfoButton>
                    {
                      remainedFlawDays > 0 ? (
                        tasktask ? (
                          tasktask.requestAt ? (
                            <TasktaskRequestButton onClick={() => {this.isTasktaskPopupOpen = true;}}>하자보수 내역</TasktaskRequestButton>
                            ) : (
                            <TasktaskRequestButton onClick={this.handleTasktaskRequestClick}>하자보수 수정</TasktaskRequestButton>
                          )
                        ) : (
                          <TasktaskRequestButton onClick={this.handleTasktaskRequestClick}>하자보수 요청</TasktaskRequestButton>
                        )
                      ) : (
                        tasktask && (
                          <TasktaskRequestButton onClick={() => {this.isTasktaskPopupOpen = true;}}>하자보수 내역</TasktaskRequestButton>
                        )
                      )
                    }
                    
                  </RightAlignedContentColumn>
                </VerticallyCenteredRow>
              </TasktaskSection>
            )}

            <ScheduleSection>
              {
                this.projectGroup.inspectionInstances.length > 0 &&
                this.projectGroup.inspectionInstances[0].attention &&
                <Card>
                  <InspectionTextLabel>
                    매니징시(검수서) 주의사항
                    <span
                      style={{paddingLeft: '20px', color: '#5C7080', fontWeight: 400, fontSize: '12px', cursor: 'pointer'}}
                      onClick={() => this.isInspectionAttentionOpen = !this.isInspectionAttentionOpen}>
                      { this.isInspectionAttentionOpen ? '닫기' : '열기' }
                    </span>
                  </InspectionTextLabel>
                  <Collapse isOpen={this.isInspectionAttentionOpen}>
                    <InspectionAttentionText><ConvertLinkTextFormat>{this.projectGroup.inspectionInstances[0].attention}</ConvertLinkTextFormat></InspectionAttentionText>
                  </Collapse>
                </Card>
              }
            </ScheduleSection>

            <ScheduleSection>
              <VerticallyCenteredRow>
                <LeftColumn style={{ display: "flex", alignItems: "center" }}>
                  <GreyLabel>스프린트 일정</GreyLabel>
                  {(isPM && !isComplete) && (
                    <EditScheduleButton onClick={this.handleScheduleClick}>
                      <EditScheduleButtonIcon src={IconEdit} />
                    </EditScheduleButton>
                  )}
                </LeftColumn>
                <RightAlignedContentColumn>
                  {schedule && schedule.dateStart && moment(schedule.dateStart) < moment(schedule.dateEnd) ? (
                    <LargeText>
                      {schedule.dateStart} ~ {schedule.dateEnd}
                    </LargeText>
                  ) : (
                    <LargeText>없음</LargeText>
                  )}
                  {isComplete && (
                    <span>(종료일자:{dateComplete}) </span>
                  )}
                </RightAlignedContentColumn>
              </VerticallyCenteredRow>
            </ScheduleSection>

            <DashedDividerLine style={{ marginBottom: "34px" }} />



            <ColumnLayout column={2}>
              <div>
                <GreyLabel>클라이언트</GreyLabel>
                <LabelPadding />
                {
                  clientName &&
                    <Link to={`/clients/${ client }`} target="_blank"> { clientName }</Link>
                }
                {this.projectGroup.workers.map((worker, i) => (
                  <ManagerItem key={i}>
                    <LargeText>{worker.name}</LargeText>
                    <NormalText>{worker.email}</NormalText>
                    <NormalText>{worker.phone}</NormalText>
                  </ManagerItem>
                ))}
              </div>

              <div>
                <GreyLabel>시소 매니저</GreyLabel>
                <LabelPadding />
                {this.projectGroup.managers.map((manager, index) => {
                  const managerInstance = this.getManagerById(manager.phone);

                  if (!managerInstance) {
                    return <ManagerItem>알 수 없음</ManagerItem>;
                  }
                  return (
                    <ManagerItem key={index}>
                      <LargeText>{managerInstance.name}</LargeText>
                      <NormalText>{managerInstance.email}</NormalText>
                      <NormalText>{managerInstance.phone}</NormalText>
                    </ManagerItem>
                  );
                })}
              </div>
            </ColumnLayout>


            {schedule && (
              <section>
                <ProjectGroupSchedule
                  projectGroup={this.projectGroup}
                  rufreeRolesInInspection={this.rufreeRolesInInspection}
                  schedule={schedule}
                  onHighlightRufree={this.setHightlight}
                />
              </section>
            )}

            <ColumnLayout column={2}>

              <EtcSection>
                <EtcSectionTitle>
                  <H6Wrapper>일정</H6Wrapper>
                  <Icon
                    icon={'add'}
                    color={'#106ba3'}
                    onClick={() => this.handleScheduleEditClick(null)}/>
                </EtcSectionTitle>
                <EtcSectionBody>
                  {
                    upcomingSchedules.map((schedule, index) => {
                      const date = moment(schedule.startAt);

                      return (
                        <EditableListItem key={index}>
                          <div>
                            <span>
                              {`${date.format('MM-DD')} ${date.format('HH:mm') !== '00:00' ? date.format('HH:mm') : ''}`}
                            </span>
                            <span>{schedule.title}</span>
                          </div>

                          <div>
                            <Icon
                              icon="edit"
                              onClick={() =>this.handleScheduleEditClick(schedule)}
                            />

                            <Icon
                              icon='trash'
                              onClick={() => {}}
                            />
                          </div>
                        </EditableListItem>
                      )}
                    )
                  }
                </EtcSectionBody>
              </EtcSection>

              <EtcSection>
                <EtcSectionTitle>
                  <H6Wrapper>스프린트 일정 목록</H6Wrapper>
                </EtcSectionTitle>
                <EtcSectionBody>
                  {
                    this.projectGroup.projectSchedules
                    .filter(x => moment(x.startAt).isSameOrAfter(moment()) === true)
                    .map((schedule, index) => (
                      <EditableListItem key={index}>
                        <div>
                          <span>{moment(schedule.startAt).format('MM-DD')}</span>
                          <span>{schedule.title}</span>
                        </div>
                      </EditableListItem>
                    ))
                  }
                </EtcSectionBody>
              </EtcSection>
            </ColumnLayout>

            <ColumnLayout column={2}>

              <EtcSection>
                <EtcSectionTitle>
                  <H6Wrapper>Github</H6Wrapper>
                  <Icon
                    icon={'add'}
                    color={'#106ba3'}
                    onClick={this.toggleRepository} />
                </EtcSectionTitle>
                <EtcSectionBody
                  className={this.readyToShow ? '' : Classes.SKELETON}
                >
                  {
                    isPM && (
                      repositories.map((repo, index) => (
                        <EditableListItem key={index}>
                          <div>
                            {
                              templateCodeToText(
                                repo.template as RepositoryTemplateType
                              )
                            }
                          </div>

                          <div>
                            <a href={repo.url} target="_blank">
                              {repo.description}
                            </a>
                          </div>

                          <div>
                            <Icon
                              icon="edit"
                              onClick={() =>
                                this.handleRepoEditClick(repo as any)
                              }
                            />

                            <Icon
                              icon='trash'
                              onClick={() =>
                                this.handleRepoDeleteClick(repo as any)
                              }
                            />
                          </div>

                        </EditableListItem>
                      ))
                    )
                  }
                </EtcSectionBody>
              </EtcSection>

              <EtcSection>
                <EtcSectionTitle>
                  <H6Wrapper>주요링크</H6Wrapper>
                  <Icon
                    icon={'annotation'}
                    color={'#106ba3'}
                    onClick={this.toggleLinkEdit} />
                </EtcSectionTitle>
                <EtcSectionBody
                  className={this.readyToShow ? '' : Classes.SKELETON}
                >
                  {
                    isPM && (
                      references.map((reference, index) => (
                        <EditableListItem key={index}>
                          <a href={reference.link} target="_blank">
                            { reference.comment ? reference.comment : reference.link }
                          </a>
                        </EditableListItem>
                      ))
                    )
                  }
                </EtcSectionBody>
              </EtcSection>

            </ColumnLayout>

            <DashedDividerLine style={{ margin: "34px 0px"}} />

            <LeftColumn>
              <GreyLabel>메모</GreyLabel>
            </LeftColumn>
            <ProjectGroupComment
              currentUserEmail={ email }
              comments={ this.projectGroup.comments }
              onCreate={ (comment: string) =>  this.projectGroup && this.projectGroup.addComment(comment)}
              onUpdate={ (id: number, comment: string) =>  this.projectGroup &&  this.projectGroup.updateComment(id, comment)}
              onDelete={ (id: number) => this.projectGroup &&  this.projectGroup.deleteComment(id)} />

          </TabContent>

          
          <TabContent active={this.currentTab === 'inspection' ? true : false}>
            {
              (isPM || isClient) &&
                this.projectGroup.inspectionInstances.length > 0 &&
                  <PlanSection>
                    <div>
                      <ScheduleIcon />
                      <div className='title'>예상 기간</div>
                      <ScheduleBrief
                        className='schedule'
                        days={ this.projectGroup.inspectionInstances[0].days } />
                      <Link to={`/inspections/${this.projectGroup.inspectionInstances[0].inspectionId}/read`} target="_blank">
                        <RoundedButton>링크</RoundedButton>
                      </Link>{" "}
                    </div>
                  <PlanReadSection
                    title={ '전체 예산 구성' }
                    showVATToggle={ true }
                    showAllPaymentDetail={ false }
                    inspection={ this.projectGroup.inspectionInstances[0] } />
                  </PlanSection>
            }
          </TabContent>

          <TabContent active={this.currentTab === 'risk' ? true : false}>
            {isPM && (
              <ProjectGroupRiskList
                risks={risks}
                isEdiable={!isComplete}
                onCreateRiskClick={this.handleRiskClick}
              />
            )}
          </TabContent>

          <TabContent active={this.currentTab === 'calculate' ? true : false}>
            {isPM && (
              <ExpenseSection>
                <ExpenseTable projectGroup={this.projectGroup} />
              </ExpenseSection>
            )}
          </TabContent>

          <TabContent active={this.currentTab === 'sprint' ? true : false}>
            {isPM && (
              <SprintSection>
                <SprintBoardTable projectGroup={this.projectGroup} />
                <AccountHistory projectGroup={this.projectGroup} />
              </SprintSection>
            )}
          </TabContent>

          <TabContent active={this.currentTab === 'review' ? true : false}>
            <SprintReviewSection>
              <SprintReviewTable
                projectGroup={this.projectGroup}
                currentReportId={this.currentReportId} />
            </SprintReviewSection>
          </TabContent>
          <TabContent active={this.currentTab === 'consult' ? true : false}>영업상담서</TabContent>

        </CardContainer>



        {
          /*
            Popups
          */
        }
        <ProjectGroupSettingsPopup
          isOpen={this.isSettingsOpen}
          onClose={this.toggleSettings}
          projectGroup={this.projectGroup}
        />
        <ProjectGroupCompletePopup
          isOpen={this.isCompleteOpen}
          onClose={this.toggleComplete}
          projectGroup={this.projectGroup}
        />
        <ProjectGroupRepositoryAddPopup
          isOpen={this.isRepositoryOpen}
          onClose={this.toggleRepository}
          projectGroup={this.projectGroup}
        />
        {this.repoToEdit && (
          <ProjectGroupRepositoryEditPopup
            isOpen={this.isRepositoryEditOpen}
            onClose={this.toggleRepositoryEdit}
            projectGroup={this.projectGroup}
            repository={this.repoToEdit}
          />
        )}
        <Alert
          cancelButtonText="취소"
          confirmButtonText="삭제"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isRepoRemoveAlertOpen}
          onCancel={this.toggleRepoRemoveAlert}
          onConfirm={this.handleRepoDelete}
          canEscapeKeyCancel={true}
          canOutsideClickCancel={true}
        >
          <p>저장소를 삭제하시겠습니까?</p>
        </Alert>

        { tasktask && (
          <TasktaskInfoPopup
            tasktask={this.isTasktaskPopupOpen ? tasktask : null}
            onClose={() => {this.isTasktaskPopupOpen = false;}}
            onRequestCancel={() => {}}
          />
        )}
        <ScheduleEditPopup
          schedule={this.scheduleToEdit}
          mode={this.scheduleToEdit ? FormMode.Edit : FormMode.Create}
          projectGroup={this.projectGroup}
          creator={userId}
          onClose={
            async() => {
              this.scheduleToEdit = null;
              this.isScheduleEditorOpen = false;

              this.projectGroup &&
                await this.injected.appStore.scheduleManagerStore.fetchSchedules(this.projectGroup.groupId);
            }
          }
          isOpened={this.isScheduleEditorOpen} />

        <ProjectGroupLinkEditPopup
          isOpen={this.isLinkEditOpen}
          onClose={this.toggleLinkEdit}
          projectGroup={this.projectGroup} />
        
        <SendDialog
          templateType={ETEMPLATETYPE.EMAIL}
          excludeSupport={true}
          restrictedCategories={['PM', 'BUSINESS', 'ETC']} />
        
      </LargeContainer>
    );
  }
}

export default ProjectGroupDetailPage;
