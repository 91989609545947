import React, { ChangeEvent, Component } from "react";
import { observer } from "mobx-react";
import { Button, Checkbox, InputGroup } from "@blueprintjs/core";
import { SubTask } from "../../../store/models/SubTask";
import styled from "styled-components/macro";
import { observable } from "mobx";

const EditButton = styled(Button)`
`;

interface Props {
  subTask: SubTask;
  onUpdateSubTask: (subTask: SubTask) => void;
  onEdit: (title: string) => void;
  onRemove: () => void;
  disabled: boolean | undefined;
  // onRemove: (id: number) => void;
  // taskFiles: Array<TaskFile>;
  // taskId: string;
}

@observer
class SubTaskView extends Component<Props> {
  @observable tempTitle = "";
  @observable onHover = false;
  @observable editMode = false;
  @observable selectedSubTask: SubTask | undefined;
  @observable selectedSubTaskId = "";
  @observable selectedSubTaskListId = "";
  @observable showDeleteAlert: boolean = false;

  render() {
    const { subTask, onUpdateSubTask, disabled } = this.props;
    const textDecoration = subTask.checked ? "line-through" : "none";
    return (

      <div key={subTask.id} style={{ textDecoration: textDecoration, lineHeight: "34px" }} onMouseEnter={() => {
        if (!disabled)
          this.onHover = true;
      }} onMouseLeave={() => {
        this.onHover = false;
      }}>
        <div style={{ display: "inline-block" }}>
          {!this.editMode ?
            <Checkbox style={{margin: "0px"}}
              disabled={disabled} onClick={(e) => {
              subTask.toggleChecked();
              onUpdateSubTask(subTask);
            }} checked={subTask.checked} label={subTask.title}/>
            :
            <div>
              <div style={{ float: "left" }}>
                <InputGroup placeholder="내용을 입력해주세요." value={this.tempTitle}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              this.tempTitle = e.target.value;
                            }}
                            onKeyDown={(event => {
                              if (event.key === "Enter") {
                                this.props.onEdit(this.tempTitle);
                                this.tempTitle = "";
                                this.editMode = false;
                              }
                            })}
                />
              </div>
              <div style={{ float: "left" }}>
                <Button text={"수정"} onClick={() => {
                  this.props.onEdit(this.tempTitle);
                  this.tempTitle = "";
                  this.editMode = false;
                }}/>
                <Button text={"취소"} onClick={() => {
                  this.tempTitle = "";
                  this.editMode = false;
                }}/>
              </div>
            </div>
          }
        </div>


        {this.editMode === false && this.onHover ?
          <div style={{ display: "inline-block" }}>
            <EditButton
              icon="edit"
              minimal={true}
              onClick={(e: any) => {
                this.editMode = true;
                this.tempTitle = subTask.title;
                e.stopPropagation();
              }}/>
            <EditButton
              icon="trash"
              minimal={true}
              onClick={(e: any) => {
                this.props.onRemove();
                // this.showDeleteAlert = true;
                // this.selectedSubTask = subTask;
                // this.selectedSubTaskId = subTask.subTaskId;
                // this.selectedSubTaskListId = subTask.subTaskListId;
                e.stopPropagation();
              }}/>
          </div> : null}
      </div>
    );
  }
}

export default SubTaskView;