import { types } from "mobx-state-tree";

export const RufreeExperienceModel = types.model("RufreeExperience", {
  title: types.string,
  workingTime: types.string,
  detail: types.string,
  startAt: types.maybeNull(types.string),
  endAt: types.maybeNull(types.string),
  role: types.maybeNull(types.string),
  tools: types.array(types.string),
  languages: types.array(types.string),
  outcomeAos: types.maybeNull(types.string),
  outcomeIos: types.maybeNull(types.string),
  outcomeWeb: types.maybeNull(types.string)
});

type RufreeExperienceType = typeof RufreeExperienceModel.Type;
export interface RufreeExperience extends RufreeExperienceType {}
