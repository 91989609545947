import { types, flow } from "mobx-state-tree";
import axios from "axios";
import moment from "moment";
import sortBy from "lodash/sortBy";
import { ServiceCalculateResponse } from "../../types/Calculate";


export const ServiceCalculateModel = types
  .model("ServiceCalculate", {
    projectGroupId: types.string,
    projectGroupName: types.string,
    contractId: types.string,
    clientName: types.string,
    service: types.number,
    cumulative: types.number,
    status: types.string
  })
  .views(self => ({
  }))
  .actions(self => {


    return{
    };
  });

type ServiceCalculateModelType = typeof ServiceCalculateModel.Type;
export interface ServiceCalculate extends ServiceCalculateModelType {}
