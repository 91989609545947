const getFontSize = () => {

  const lessThanEight = ['1', '2', '4', '6'];
  const greaterThanEight = ['24', '30', '36', '48', '60', '72', '96'];

  const eightToTwenty = [];
  for (let i = 8; i <= 20; i++) {
    eightToTwenty.push(i.toString())
  }

  return lessThanEight.concat(eightToTwenty).concat(greaterThanEight);
};

const getFontFamily = () => {
  return {
    'Arial,Helvetica,sans-serif': 'Arial',
    'Georgia,serif': 'Georgia',
    'Impact,Charcoal,sans-serif': 'Impact',
    'Tahoma,Geneva,sans-serif': 'Tahoma',
    "'Times New Roman',Times,serif": 'Times New Roman',
    'Verdana,Geneva,sans-serif': 'Verdana',
    'Noto Sans KR,sans-serif': 'Noto Sans KR'
  }
};

export const FroalaConfigs = {
  key: 'DUA2yF3H3A1A7A3B5pZGCTRSAPJWTLPLZHTQQe1JGZxC4B3A3C2B5A1F1E4I1A2==',
  colorsHEXInput: true,
  fontFamilySelection: true,
  fontFamily: getFontFamily(),
  fontSizeSelection: true,
  fontSize: getFontSize(),
  paragraphDefaultSelection: 'Paragraph',
  paragraphFormatSelection: true,
  attribution: false,
  width: '793.7007874',
  toolbarButtons: {
    'moreText': {
      'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
    },
    'moreParagraph': {
      'buttons': ['align', 'lineHeight', 'formatUL', 'formatOLSimple', 'formatOL', 'paragraphFormat', 'paragraphStyle', 'outdent', 'indent', 'quote']
    },
    'moreRich': {
      'buttons': ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR']
    },
    'moreMisc': {
      'buttons': [ 'html', 'undo', 'redo', 'print', 'fullscreen', 'help'],
      'buttonsVisible': 5
    }
  }
}
