import { types } from "mobx-state-tree";
import { BaseModel } from "./BaseModel";
import { CommentModel, RufreePortfolioModel, RufreeToolModel, MatchingHistoryModel, FolloweeModel } from "./Rufree";


const MatchingCandidateExperienceModel = BaseModel.named(
		"MatchingCandidateExperienceModel"
	)
	.props({
		title: types.string, 
		workingTime: types.string, 
		detail: types.string,
		startAt: types.maybeNull(types.string),
		endAt: types.maybeNull(types.string),
		role: types.maybeNull(types.string),
		tools: types.array(types.string),
		languages: types.array(types.string),
		outcomeAos: types.maybeNull(types.string),
		outcomeIos: types.maybeNull(types.string),
		outcomeWeb: types.maybeNull(types.string)
	})

const MatchingCandidatePGModel = BaseModel.named(
		"MatchingCandidatePGModel"
	)
	.props({
		pgId: types.string, 
		pgName: types.string, 
		role: types.string, 
		dateComplete: types.string,
	})

const MatchingCandidateCurrentMatchingModel = BaseModel.named(
		"MatchingCandidateCurrentMatchingModel"
	)
	.props({
		pgId: types.string, 
		pgName: types.string, 
		isAccepted: types.boolean,	
	})

export const RufreeMatchingCandidateModel = types.model("RufreeMatchingCandidateModel", {
	id: types.number,
	uuid: types.string,
	rufreeId: types.string,
	name: types.string,
	email: types.string,
	phone: types.string,
	address: types.string,
	affiliation: types.string,
	workType: types.string,
	// roles: types.array(types.string),
	// rolesEtc: types.string,
	portfolio: RufreePortfolioModel,
	// portfolioLinks: types.array(types.string),
	// expert: types.string,
	mainSkills: types.array(types.string),
	subSkills: types.array(types.string),
	// oldMainSkills: types.array(types.string),
	// oldSubSkills: types.array(types.string),
	languages: types.array(types.string),
	tools: types.array(types.string),
	toolAccounts: types.array(RufreeToolModel),
	tags: types.array(types.string),
	references: types.array(types.string),
	interview: types.string,
	workingStyle: types.string,
	workingTime: types.array(types.string),
	recommender: types.string,
	// interests: types.array(types.string),
	pmComment: types.string,
	comments: types.array(CommentModel),
	followee: types.array(FolloweeModel), // 같이 일하고 싶은 멤버
	// featureWords: types.array(types.string),
	experiences: types.array(MatchingCandidateExperienceModel),
	pgHistories: types.array(MatchingCandidatePGModel),
	matchingHistory: types.array(MatchingHistoryModel),
	currentMatchings: types.array(MatchingCandidateCurrentMatchingModel),
	getOffer: types.boolean,
	getOfferChangedAt: types.maybeNull(types.string),
	createdAt: types.string
})

type RufreeMatchingCandidateType = typeof RufreeMatchingCandidateModel.Type;
export interface RufreeMatchingCandidate extends RufreeMatchingCandidateType {}
