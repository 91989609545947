import { types, flow, isAlive, getRoot } from "mobx-state-tree";
import { RufreeMatchingHistoryModel } from "./RufreeMatchingHistory";
import axios from "axios";
import { MatchingHistoryResponse } from "../../types/rufreeMatching";
import { Rufree } from "./Rufree";
import { AppStore } from "../AppStore";

export const RufreeMatchingModel = types
  .model("RufreeMatching", {
    id: types.identifierNumber,
    manager: types.maybeNull(types.string),
    macthingType: types.maybeNull(types.string),
    projectGroup: types.maybeNull(types.string),
    projectGroupName: types.maybeNull(types.string),
    pGStartOrNot: types.boolean,
    role: types.maybeNull(types.string),
    status: types.string,
    rufreeOfferSend: types.optional(types.boolean, false),
    sprintWage: types.optional(types.number, 0),
    sprintFee: types.optional(types.number, 0),
    sprintNum: types.optional(types.number, 0),
    dateDue: types.maybeNull(types.string),
    dateRequest: types.maybeNull(types.string),
    reference: types.maybeNull(types.string),
    roleDetail: types.maybeNull(types.string),
    pmManagerComment: types.optional(types.string, ""),
    matchingManagerComment: types.optional(types.string, ""),
    finalMatchingManagerComment: types.optional(types.string, ""),
    needUpdate: types.optional(types.boolean, false),
    onRecommend: types.optional(types.boolean, false),
    inspections: types.maybeNull(types.string),
    matchedRufree: types.maybeNull(types.string),
    matchedRufreeName: types.maybeNull(types.string),
    matchedTime: types.maybeNull(types.string),
    matchingHistory: types.array(RufreeMatchingHistoryModel),
    currentTab: types.string,
    createdAt: types.string,
    updatedAt: types.string
  })
  .views(self => ({
    get matchedRufreeInstance(): Rufree | undefined {
      if(self.matchedRufree)
        return getRoot<AppStore>(self).rufreeStore.rufreeById(self.matchedRufree);
    },
    get historyCount() {
      return self.matchingHistory.length;
    },
    get acceptedCount() {
      return self.matchingHistory.reduce((sum, history) => {
        return history.isAccepted ? sum + 1 : sum;
      }, 0);
    },
    statusCount(status: string) {
      return self.matchingHistory.reduce((sum, history) => {
        return history.status === status ? sum + 1 : sum;
      }, 0);
    }
  }))
  .actions(self => {
    const toggleOnRecommend = flow(function*() {
      try {
        const { data } = yield axios.get(
          `/rufreeMatching/${self.id}/toggleRecommend`
        );

        if (isAlive(self)) {
          self.onRecommend = data.on_recommend;
        }
      } catch (e) {
        console.error("toggleOnRecommend error", e);
        throw e;
      }
    });

    const cancelMatchedRufree = flow(function*() {
      try {
        const { data } = yield axios.get(
          `/rufreeMatching/${self.id}/cancelMatchedRufree`
        );
      } catch (e) {
        console.error("toggleOnRecommend error", e);
        throw e;
      }
    });

    const addRufree = flow(function*({ rufree }: { rufree: string }) {
      try {
        const { data }: { data: MatchingHistoryResponse } = yield axios.post(
          `/rufreeMatching/${self.id}/addRufree`,
          {
            rufree
          }
        );
      } catch (e) {
        console.error("addRufree error", e);
        throw e;
      }
    });

    const deleteA = flow(function*() {
      try {
        const { data } = yield axios.get(
          `/rufreeMatching/${self.id}/cancelMatchedRufree`
        );
      } catch (e) {
        console.error("toggleOnRecommend error", e);
        throw e;
      }
    });

    

    return {
      toggleOnRecommend,
      cancelMatchedRufree,
      addRufree
    };
  });

type RufreeMatchingType = typeof RufreeMatchingModel.Type;
export interface RufreeMatching extends RufreeMatchingType {}
