import React, { ChangeEvent, FormEvent, FC, useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components/macro";
import {
  Alert,
  Button,
  Classes,
  Colors,
  Dialog,
  FormGroup,
  Intent,
  InputGroup,
  Switch,
  TextArea,
  RadioGroup,
  Radio,
  Tag
} from "@blueprintjs/core";
import { FroalaConfigs } from "../../../utils/FroalaConfigs";
import FroalaEditor from 'react-froala-wysiwyg';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

import { AppStore } from "../../../store/AppStore";
import { INotificationTemplate, ETEMPLATETYPE } from "../../../store/models/NotificationTemplate";
import { FormMode } from "../../../types/formMode";

import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import CategorySelection from "../../molecules/NotificationTemplate/CategorySelection";

const DialogWrapper = styled(Dialog)`
  width: 800px;
  max-height: 1000px;

  .bp3-dialog-body {
    position: relative;
  }
  .bp3-dialog-body .bp3-form-group label {
    width: 50px;
  }
  label.bp3-radio {
    width: fit-content !important;
  }
  .bp3-dialog-body div.bp3-form-content {
    line-height: 30px; width: calc(100% - 50px);
  }
  .bp3-form-group.message {
    margin-bottom: 0px;
  }
  .bp3-form-group.category > .bp3-form-content {
    display: flex;
    .bp3-input-group {
      width: 200px;
    }
    * + * {
      margin-left: 5px;
    }
  }

  .dialog-form-mode {
    position: absolute;
    right: 0;
  }

  textarea {
    width: 100%;
  }

  /* FroalaEditorView */
  .bp3-dialog-body > div > .fr-view {
    background-color: ${ Colors.LIGHT_GRAY5 };
    height: 600px;
    overflow-y: auto;
  }
`;

const dialogSettings = {
  autoFocus: true,
  canEscapeKeyClose: true,
  canOutsideClickClose: true,
  enforceFocus: true,
  isOpen: true,
  usePortal: true,
};

interface Props {
  template?: INotificationTemplate;
  templateType: string;
  handleClose: () => void;
}
interface InjectedProps extends Props {
  appStore: AppStore;
}
const DetailDialog: FC<Props> = inject("appStore")(observer((props => {
  const store = (props as InjectedProps).appStore.notificationTemplateStore;
  const userStore = (props as InjectedProps).appStore.userStore;

  const { templateType, handleClose } = props;
  const { selectedTemplate, openPopup, formMode } = store;
  
  // state 준비.
  const [ isEmail, setIsEmail ] = useState(templateType === ETEMPLATETYPE.EMAIL);
  const [ isOpenDeleteConfirm, setIsOpenDeleteConfirm ] = useState(false);
  const [ isRead, setIsRead] = useState(selectedTemplate ? true : false);
  const [ category, setCategory] = useState('');
  const [ categoryETC, setCategoryETC ] = useState('');
  const [ name, setName ] = useState('');
  const [ subject, setSubject ] = useState('');
  const [ message, setMessage ] = useState('');
  const [ sms, setSms ] = useState('');

  const icon = isEmail ? 'envelope' : 'mobile-phone';

  // functions
  const _handleClose = () => {
    _resetStates();

    if(handleClose) {
      handleClose();
    }
  };

  const _resetStates = () => {
    setIsEmail(true);
    setIsOpenDeleteConfirm(false);
    setIsRead(false);
    setCategory('');
    setCategoryETC('');
    setName('');
    setSubject('');
    setMessage('');
    setSms('');
  }

  useEffect(() => {
    if(selectedTemplate) {
      const { name, htmlBody, htmlSubject, textBody, category, categoryEtc } = selectedTemplate;
      // const message = isEmail ? htmlBody : textBody;

      setCategory(category);
      setCategoryETC(categoryEtc);
      setName(name);
      setSubject(htmlSubject);
      setMessage(htmlBody);
      setSms(textBody);

      if(formMode === FormMode.Read) {
        setIsRead(true);
      }
    } else {
      _resetStates();
    }
  }, [ selectedTemplate ]);

  if(!openPopup) {
    return (<></>)
  }

  return (
    <DialogWrapper
      icon={ icon }
      onClose={ _handleClose }
      title={ selectedTemplate ? selectedTemplate.name : '템플릿 추가' }
      { ...dialogSettings }
    >

      <div className={Classes.DIALOG_BODY}>

        {
          selectedTemplate && selectedTemplate.condition &&
          <FormGroup
            label={<Tag style={{ fontSize: '10px' }}>자동발송</Tag>}
            inline={true}
            style={{ color: '#5c7080' }}
          >
            { selectedTemplate.condition.comment } ( { selectedTemplate.condition.name } )
          </FormGroup>
        }

        <Switch
          checked={ !isRead }
          disabled={ selectedTemplate ? false : true }
          label={'편집모드'}
          className={'dialog-form-mode'}
          onChange={ () => setIsRead(!isRead) }/>

        <div className={ store.isLoading ? Classes.LOADING : ''}>

        {
          selectedTemplate &&
            <FormGroup
              label={<strong>편집자</strong>}
              inline={true}
            >
              { userStore.username }
            </FormGroup>
        }
        
        
        <FormGroup
          label={<strong>카테고리</strong>}
          inline={true}
          className={'category'}
        >
          <CategorySelection
            readOnly={ isRead }
            value={ category }
            handleChange={ (category: string) => {
              setCategory(category);
            } } />
          {
            category === 'ETC' &&
              <InputGroup
                value={ categoryETC }
                readOnly={ isRead }
                onChange={
                  (e: ChangeEvent<HTMLInputElement>) =>
                    setCategoryETC(e.target.value)
                }
              />
          }
          
        </FormGroup>
        
        <FormGroup
          label={<strong>템플릿명</strong>}
          inline={true}
        >
          <InputGroup
            value={ name }
            readOnly={ isRead }
            onChange={
              (e: ChangeEvent<HTMLInputElement>) =>
                setName(e.target.value)
            }
          />
        </FormGroup>

        {
          isEmail &&
            <FormGroup
              label={<strong>제목</strong>}
              inline={true}
            >
              <InputGroup
                value={ subject }
                readOnly={ isRead }
                onChange={
                  (e: ChangeEvent<HTMLInputElement>) =>
                    setSubject(e.target.value)
                }
              />
            </FormGroup>
        }

        <FormGroup
          label={<strong>내용</strong>}
          inline={true}
          className={'message'}
        >
          <RadioGroup
            label=""
            inline={true}
            onChange={(e: FormEvent<HTMLInputElement>) => {e.currentTarget.value === ETEMPLATETYPE.EMAIL ? setIsEmail(true) : setIsEmail(false)}}
            selectedValue={ isEmail ? ETEMPLATETYPE.EMAIL : ETEMPLATETYPE.SMS }
          >
            <Radio label="이메일" value={ ETEMPLATETYPE.EMAIL } />
            <Radio label="문자" value={ ETEMPLATETYPE.SMS } />
          </RadioGroup>
        </FormGroup>

        {
          isEmail ?
            isRead ?
              <FroalaEditorView
                model={ message }
                config={{
                  width: '100%',
                  height: 600
                }}
              />
              : <FroalaEditor
                  model={ message }
                  config={{
                    ...FroalaConfigs,
                    width: '100%',
                    height: 600
                  }}
                  onModelChange={(model: string) => {
                    setMessage(model);
                  }} />
          : <TextArea
              value={ sms }
              readOnly={ isRead }
              growVertically={true}
              onChange={
                (e: ChangeEvent<HTMLTextAreaElement>) =>
                  setSms(e.target.value)
              }
            />
        }

        </div>
    </div>
    <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {
            selectedTemplate &&
            <>
              <Button
                intent={ Intent.DANGER}
                minimal={ true }
                onClick={ () => setIsOpenDeleteConfirm(true)}
                text="삭제" />
              <Alert
                cancelButtonText="취소"
                confirmButtonText="삭제"
                icon="trash"
                intent={Intent.DANGER}
                isOpen={isOpenDeleteConfirm}
                onCancel={() => setIsOpenDeleteConfirm(false)}
                onConfirm={ async () => {
                  setIsOpenDeleteConfirm(false);
                  await selectedTemplate.remove();
                  store.fetch();

                  _handleClose();
                } }
              >
                <p>
                    <strong>{ selectedTemplate.title }</strong> 템플릿을 삭제하시겠습니까?
                </p>
              </Alert>
            </>
          }

          <Button
            onClick={ _handleClose }
          >
            취소
          </Button>

          {
            !isRead &&
              <Button
                intent={Intent.PRIMARY}
                onClick={ async () => {
                  if(!category || !name || !subject) {
                    const message = `${!category ? '카테고리': ''} ${!name ? '템플릿명': ''} ${!subject ? '제목': ''}은 필수입력입니다.`;

                    AppToaster.show({
                      message: message,
                      intent: Intent.WARNING
                    });
                  } else {
                    try {
                      if(selectedTemplate) {
                          await selectedTemplate.patch({
                            category: category,
                            category_etc: categoryETC,
                            name: name,
                            html_subject: subject,
                            html_body: message,
                            text_body: sms
                          })  // NotificationTemplate types파일 내, ITemplatePatchParams 인터페이스 참고.  
                      } else {
                          await store.post({
                            category: category,
                            category_etc: categoryETC,
                            name: name,
                            html_subject: subject ? subject : name,
                            html_body: message,
                            text_body: sms
                          })
                      }
  
                      AppToaster.show({
                        message: "저장되었습니다.",
                        intent: Intent.SUCCESS
                      });

                    } catch(e) {
                      const error = JSON.stringify(e.response.data);
                      AppToaster.show({
                        message: "오류: " + error,
                        intent: Intent.DANGER
                      });
                    }
  
                    store.fetch();
  
                    setIsRead(true);
                  }
                }}>
                저장
              </Button>
          }
        </div>
    </div>
    </DialogWrapper>
  );
})));

export default DetailDialog;
