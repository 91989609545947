import { types } from "mobx-state-tree";

export const ProjectGroupWorkerFormModel = types
  .model("ProjectGroupWorkerForm", {
    name: types.optional(types.string, ""),
    phone: types.optional(types.string, ""),
    email: types.optional(types.string, ""),

    clientUserId: types.optional(types.number, -1)
  })
  .actions(self => ({
    setName(value: string) {
      self.name = value;
    },
    setPhone(value: string) {
      self.phone = value;
    },
    setEmail(value: string) {
      self.email = value;
    },
    setClientUserId(value: number) {
      self.clientUserId = value;
    }
  }));

type ProjectGroupWorkerFormType = typeof ProjectGroupWorkerFormModel.Type;
export interface ProjectGroupWorkerForm extends ProjectGroupWorkerFormType {}
