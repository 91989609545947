import { types, flow, IMSTArray, ISimpleType } from "mobx-state-tree";
import axios from "axios";
import {
  RufreeExperienceFormModel,
  RufreeExperienceForm
} from "./RufreeExperienceForm";
import {
  PortfolioLinksFormModel,
  PortfolioLinksForm
} from "./PortfolioLinksForm";
import { PortfolioPdfFormModel, PortfolioPdfForm } from "./PortfolioPdfForm";
import { RufreeResponse } from "../../../types/rufree";
import { PdfToUploadFormModel, PdfToUploadForm } from "./PdfToUploadForm";

export const RufreeFormModel = types
  .model("RufreeForm", {
    rufreeId: types.optional(types.string, ""),
    name: types.optional(types.string, ""),
    email: types.optional(types.string, ""),
    phone: types.optional(types.string, ""),
    address: types.optional(types.string, ""),
    affiliation: types.optional(types.string, ""),

    experiences: types.array(RufreeExperienceFormModel),
    workType: types.optional(types.string, ""),
    roles: types.array(types.string), // Checkbox 인 경우에는 서브모델을 사용하지 않는다.
    rolesEtc: types.optional(types.string, ""),
    portfolio: types.optional(PortfolioLinksFormModel, {}),
    pdfsToUpload: types.array(PdfToUploadFormModel),

    interview: types.optional(types.string, ""),
    mainSkills: types.array(types.string),
    subSkills: types.array(types.string),
    expert: types.optional(types.string, ""),
    workingTime: types.array(types.string),
    workingStyle: types.optional(types.string, ""),
    recommender: types.optional(types.string, ""),
    createdAt: types.optional(types.string, ""),
    updatedAt: types.optional(types.string, ""),
    pmComment: types.optional(types.string, ""),

  })
  .views(self => ({
    isRoleChecked(key: string) {
      return !!self.roles.find(x => x === key);
    },
    isWorkingTimeChecked(key: string) {
      return !!self.workingTime.find(x => x === key);
    }
  }))
  .actions(self => ({
    toggleArrayItem(array: IMSTArray<ISimpleType<string>>, key: string) {
      const hasItem = !!array.find(x => x === key);

      if (hasItem) {
        array.remove(key);
      } else {
        array.push(key);
      }
    }
  }))
  .actions(self => ({
    setRufreeId(value: string) {
      self.rufreeId = value;
    },
    setName(value: string) {
      self.name = value;
    },
    setEmail(value: string) {
      self.email = value;
    },
    setPhone(value: string) {
      self.phone = value;
    },
    setAddress(value: string) {
      self.address = value;
    },
    setAffiliation(value: string) {
      self.affiliation = value;
    },
    setWorkType(value: string) {
      self.workType = value;
    },
    setRolesEtc(value: string) {
      self.rolesEtc = value;
    },
    setExpert(value: string) {
      self.expert = value;
    },
    setWorkingStyle(value: string) {
      self.workingStyle = value;
    },
    setRecommender(value: string) {
      self.recommender = value;
    },
    setCreatedAt(value: string) {
      self.createdAt = value;
    },
    setInterview(value: string) {
      self.interview = value;
    },
    setMainSkills(values: string[]) {
      self.mainSkills.replace(values);
    },
    setSubSkills(values: string[]) {
      self.subSkills.replace(values);
    },
    setPmComment(value: string) {
      self.pmComment = value;
    },

    // Add
    addExperience() {
      self.experiences.push(RufreeExperienceFormModel.create());
    },

    addPdfToUpload(file: File) {
      const newNode = PdfToUploadFormModel.create();
      newNode.setFile(file);
      self.pdfsToUpload.push(newNode);
    },

    // Remove
    removeExperience(target: RufreeExperienceForm) {
      self.experiences.remove(target);
    },
    // removePortfolioLink(target: PortfolioLinksForm) {
    //   self.portfolio.remove(target);
    // },
    // removePortfolioPdf(target: PortfolioPdfForm) {
    //   self.portfolioPdfs.remove(target);
    // },
    removePdfToUpload(target: PdfToUploadForm) {
      self.pdfsToUpload.remove(target);
    },

    // Toggle (Checkbox)
    toggleRole(key: string) {
      self.toggleArrayItem(self.roles, key);
    },
    toggleWorkingTime(key: string) {
      self.toggleArrayItem(self.workingTime, key);
    }
  }));

type RufreeFormType = typeof RufreeFormModel.Type;
export interface RufreeForm extends RufreeFormType {}

export const RufreeFormStoreModel = types
  .model("RufreeFormStore", {
    currentForm: types.optional(RufreeFormModel, {})
  })
  .actions(self => {
    const initForm = () => {
      self.currentForm = RufreeFormModel.create({
        experiences: [RufreeExperienceFormModel.create()]
      });
    };

    const mapFormDataForRequest = () => {
      const form = self.currentForm;

      return {
        rufree_id: form.rufreeId,
        name: form.name,
        email: form.email,
        phone: form.phone,
        address: form.address,
        affiliation: form.affiliation,
        // experiences: form.experiences.map(x => ({
        //   title: x.title,
        //   working_time: x.workingTime,
        //   detail: x.detail
        // })),
        work_type: form.workType || undefined,
        roles: form.roles,
        roles_etc: form.rolesEtc,
        // portfolio: {
        //   type: form.portfolio.type,
        //   file: form.portfolio.file,
        //   link: form.portfolio.link
        // },
        interview: form.interview,
        main_skills: form.mainSkills,
        sub_skills: form.subSkills,
        expert: form.expert,
        working_time: form.workingTime,
        working_style: form.workingStyle || undefined,
        recommender: form.recommender || undefined,
        pm_comment: form.pmComment
      };
    };

    const uploadPdfsToUpload = flow(function*(rufreeId: string) {
      yield Promise.all(
        self.currentForm.pdfsToUpload.map(pdfToUpload => {
          const formData = new FormData();
          formData.append("file", pdfToUpload.file!);

          return axios.post(`/rufrees/${rufreeId}/pdfs`, formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
        })
      );
    });

    const postRufree = flow(function*() {
      try {
        // create rufree first
        const { data }: { data: RufreeResponse } = yield axios.post(
          `/rufrees`,
          mapFormDataForRequest()
        );

        // send pdf files
        yield uploadPdfsToUpload(data.rufree_id);
      } catch (e) {
        console.log("postRufreeForm error", e);
        throw e;
      }
    });

    const patchRufree = flow(function*() {
      try {
        const params = mapFormDataForRequest();
        delete params['email'];
        
        const { data }: { data: RufreeResponse } = yield axios.patch(
          `/rufrees/${self.currentForm.rufreeId}`,
          params
        );

        yield uploadPdfsToUpload(data.rufree_id);
      } catch (e) {
        console.log("patchRufree error", e);
        throw e;
      }
    });

    const fetchRufree = flow(function*(rufreeId: string) {
      try {
        const { data }: { data: RufreeResponse } = yield axios.get(
          `/rufrees/${rufreeId}`
        );

        const newForm = RufreeFormModel.create({
          rufreeId: data.rufree_id,
          name: data.name,
          email: data.email,
          phone: data.phone,
          address: data.address,
          affiliation: data.affiliation,

          // experiences: data.experiences.map(x =>
          //   RufreeExperienceFormModel.create({
          //     title: x.title,
          //     workingTime: x.working_time,
          //     detail: x.detail
          //   })
          // ),
          workType: data.work_type,
          roles: data.roles,
          rolesEtc: data.roles_etc,
          // portfolio: PortfolioLinksFormModel.create({
          //     type: data.portfolio ? data.portfolio.type : '',
          //     file: data.portfolio ? data.portfolio.file : '',
          //     link: data.portfolio ? data.portfolio.link : ''
          //   }),
          interview: data.interview,
          mainSkills: data.main_skills,
          subSkills: data.sub_skills,
          expert: data.expert,
          // S12: "밤/새벽 " 을 "밤/새벽"으로 표시하기 위해 임시로 매핑
          workingTime: data.working_time.map(x => {
            if (x === "밤/새벽\u001b") {
              return "밤/새벽";
            }

            return x;
          }),
          workingStyle: data.working_style,
          recommender: data.recommender,
          createdAt: data.created_at,
          updatedAt: data.updated_at,
          pmComment: data.pm_comment
        });

        self.currentForm = newForm;
      } catch (e) {
        console.log("fetchRufree error", e);
        throw e;
      }
    });

    return {
      initForm,
      postRufree,
      patchRufree,
      fetchRufree
    };
  });

type RufreeFormStoreType = typeof RufreeFormStoreModel.Type;
export interface RufreeFormStore extends RufreeFormStoreType {}
