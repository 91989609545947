import { types, getParent, flow } from "mobx-state-tree";
import { BaseModel } from "./BaseModel";
import axios from "axios";

export const ExpenseModel = BaseModel.named("Expense")
  .props({
    sprint: types.number,
    title: types.string,
    payment: types.number,
    serviceFee: types.number
  })
  .views(self => ({
  }))
  .actions(self => {
    return {};
  });

type ExpenseType = typeof ExpenseModel.Type;
export interface Expense extends ExpenseType {}
