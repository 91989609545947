import React from 'react';
import {
  Classes,
  HTMLSelect,
  HTMLTable,
  IOptionProps,
  InputGroup
} from '@blueprintjs/core';

import {
  FilterRow,
  Content,
  StyledSearchBar,
  SearchButton,
  StyledHeader,
  StyledTr
} from './styledComponents';



const DepositPanel = () => {
  return (
    <>
      <FilterRow>
        <StatusSelector />
        <SearchBar />

        <SearchButton />
      </FilterRow>
      <Content>
        <HTMLTable
          className={ Classes.SMALL }
          condensed={ true }
          striped={ true }
          interactive={ true }
        >
          <TableHeader />
        </HTMLTable>
      </Content>
    </>
  )
};
export default DepositPanel;

const StatusSelector = () => {
  const options: Array<IOptionProps> = [
    { label: '전체', value: '' },
    { label: '입금완료', value: 'deposit' },
    { label: '미입금', value: 'notdeposit' }
  ];

  return (
    <HTMLSelect
      options={ options } />
  )
};
const SearchBar = () => {
  return (
    <StyledSearchBar>
      <InputGroup
        placeholder="정산ID/세컨팀/멤버"
        // value={this.searchText}
        // onChange={this.handleSearchText}
        // onKeyDown={this.handleSearchTextKeyDown}
      />
    </StyledSearchBar>
  )
}

const TableHeader = () => {
  return (
    <StyledHeader>
      <StyledTr>
        <th>정산ID</th>
        <th>세컨팀</th>
        <th>S(n)</th>
        <th>파트너 회사</th>
        <th>세금계산서 발행일</th>
        <th>입금요청 금액</th>
        <th>총 입금액</th>
        <th>최종 입금일</th>
        <th>비고</th>
        <th />
      </StyledTr>
    </StyledHeader>
  )
};