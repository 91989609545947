import React, { Component, ChangeEvent } from "react";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { Dialog, Classes, FormGroup, Intent, TextArea } from "@blueprintjs/core";
import { PrimaryButton, SecondaryButton } from "../../atoms/ParttimeComponents/ParttimeComponents";

import { PMUser } from "../../../store/models/PMUser";
import { computed } from "mobx";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";

import { Request } from "../../../store/models/Parttime";
import PMUserSelection from "../../molecules/PMUserSelection/PMUserSelection";

interface RequestCancelDialogProps {
    request: Request;
    isOpen: boolean;
    onClose: () => void;
}

interface InjectedProps extends RequestCancelDialogProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class RequestCancelDialog extends Component<RequestCancelDialogProps> {
    get injected() {
        return this.props as InjectedProps;
    }

    @computed
    get formStore() {
        return this.injected.appStore.parttimeRequestCancelFormStore
    }

    @computed
    get currentForm() {
        return this.formStore.currentForm;
    }

    @computed
    get selectedPM() {
        return this.injected.appStore.pmUserStore.pmUsers.find(
            x => x.id === this.currentForm.manager
        );
    }
   
    handleOpening = () => {};

    handleSubmit = async () => {
        const { request, onClose } = this.props;

        try {
            this.currentForm.setId(this.props.request.id);
            await this.formStore.cancelRequest();
            onClose();
            await this.injected.appStore.parttimeRequestStore.fetchRequestById(request.id);
            AppToaster.show({
                message: "취소가 정상적으로 처리되었습니다.",
                intent: Intent.SUCCESS
            });
        
            } catch (e) {
            const error = JSON.stringify(e.response.data);
            AppToaster.show({
                message: "오류: " + error,
                intent: Intent.DANGER
            });
        }
      };
    

    render() {
        const { isOpen, onClose, request } = this.props;
        const { currentForm } = this;
    
        const title = `신청취소`;
    
        return (
            <Dialog
                onOpening={this.handleOpening}
                isOpen={isOpen}
                onClose={onClose}
                usePortal={true}
                autoFocus={true}
                enforceFocus={true}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                title={title}
            >
            <div className={Classes.DIALOG_BODY}>
                <FormGroup label={<strong>담당매니저</strong>} labelInfo="*">
                <PMUserSelection
                    onChange={(selectedValue: PMUser) => {currentForm.setManager(selectedValue.id);}}
                    currentValue={this.selectedPM}
                    blockFetchingData={false}
                />
                </FormGroup>
                <FormGroup label={<strong>취소사유</strong>} labelInfo="*">
                    <TextArea
                        rows={2}
                        placeholder="현재 상태 또는 오류를 입력해주세요. (최대 200자)"
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                            currentForm.setCancelReason(e.target.value);
                          }}
                        style={{ width: "100%" }}
                    />
                </FormGroup>
            </div>
    
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <SecondaryButton text="취소" onClick={onClose}/>
                <PrimaryButton text={"신청 취소"} onClick={this.handleSubmit}/>
              </div>
            </div>
          </Dialog>
        );
    }
}

export default RequestCancelDialog;
