import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { INotificationLog } from '../../../store/models/NotificationLog';
import {
  Callout,
  Classes,
  Colors,
  Drawer,
  H3,
  HTMLTable,
  Icon,
  Intent
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import Paginator from "../../molecules/Paginator/Paginator";

import { RouteComponentProps } from "react-router";
import moment from "moment";
import { range } from "lodash";
import { observable } from "mobx";


const Wrapper = styled.div`
  max-width: 1400px;

  table {
    margin-bottom: 20px;
    margin-top: 15px;
    width: 100%;
  }
  th.id, th.type {
    width: 72px;
  }
  th.time {
    width: 162px;
  }
  td {
    font-size: 13px;
  }
  td.noitem {
    color: ${ Colors.DARK_GRAY5 };
    text-align: center;
    font-size: 12px;
  }
  td.log-id {
    text-align: right;
    width: 80px;
  }
  td.type {
    text-align: center;
    span.icon + span.icon {
      margin-left: 5px;
    }
  }
  td.receiver {
    span + span:before {
      content: ', '
    }
  }

  div.callout {
    font-size: 12px;
  }
`;
interface Props extends RouteComponentProps {}
interface InjectedProps extends Props {
  appStore: AppStore;
}

@inject("appStore")
@observer
class NotificationLogPanel extends Component<Props> {
  get injected() {
    return this.props as InjectedProps;
  }

  get store() {
    return this.injected.appStore.notificationLogStore;
  }

  async componentDidMount() {
    await this.handleFetch();
  }

  handleFetch = async () => {
    await this.store.fetch();
  };

  renderThead = () => {
    return (
      <thead>
        <tr>
          <th className='id'>발송번호</th>
          <th>단계</th>
          <th>템플릿</th>
          <th className='type'>발송타입</th>
          <th className='time'>발송시각</th>
          <th>수신자</th>
          <th>참조</th>
          <th>숨은참조</th>
          <th>관련멤버</th>
        </tr>
      </thead>
    )
  }

  renderTbody = () => {
    const {
      // isFetching,
      logs
    } = this.store;


    // if(isFetching) {
    //   console.log('sss')
    //   return (
    //     range(10).map(key => <tr key={key}><td className={ 'modifier' } colSpan={ 9 }>.</td></tr>)
    //   )
    // }

    if(logs.length === 0) {
      return (
        <tr>
          <td
            colSpan={ 9 }
            className='noitem'
          >
            발송된 아이템이 없습니다.
          </td>
        </tr>
      )
    }

    return (
      logs.map( (log: INotificationLog, i: number) => {
        return <tr
          key={ i }
        >
          <td className='log-id'>{ log.id }</td>
          <td>{ log.template && log.template.condition ? log.template.condition.comment : '' } </td>
          <td>
            {
              log.template &&
                <Link to={`/templates?tab=email&id=${ log.template.id }`} target="_blank">
                  { log.template ? log.template.name : '' }
                </Link>
            }
          </td>
          <td className='type'>
            <Icon
              className="icon"
              iconSize={ 14 }
              icon={ IconNames.ENVELOPE }
              color={ log.receiver.to_mail.length > 0 ? Colors.BLUE2 : Colors.LIGHT_GRAY1 } />
            <Icon
              className="icon"
              iconSize={ 14 }
              icon={ IconNames.MOBILE_PHONE }
              color={ log.receiver.to_text.length > 0 ? Colors.BLUE2 : Colors.LIGHT_GRAY1 } />
          </td>
          <td>{ moment(log.dateCreated).format('YYYY-MM-DD HH:mm:ss') }</td>
          <td
            className='receiver'
          >
            { log.receiver.to_mail.map((mail, i) => <span key={ i }>{ mail }</span>) }
            { log.receiver.to_text.map((text, i) => <span key={ i }>{ text }</span>) }
          </td>
          <td
            className='receiver'
          >
            { log.receiver.cc.map((mail, i) => <span key={ i }>{ mail }</span>) }
          </td>
          <td
            className='receiver'
          >
            { log.receiver.bcc.map((mail, i) => <span key={ i }>{ mail }</span>) }
          </td>
          <td>{ log.sender ? log.sender.name : '' }</td>
        </tr>
      })
    )
  }

  render() {
    const {
      isFetching,
      pageGroup,
      totalPages,
      currentPage,

      setPageGroup,
      setCurentPage
    } = this.store;

    return (
      <Wrapper>
        <H3>발송목록</H3>

        <Callout
          className='callout'
          intent={ Intent.WARNING }
          // icon={ IconNames.ISSUE }
          icon={ <Icon iconSize={15} icon={ IconNames.ISSUE} /> }
        >
          이메일 발송 내역은 파트너 계정 보낸 편지함에서 확인, 문자는 발송 상세내역 확인은 불가능함
        </Callout>
        <HTMLTable>
          
          { this.renderThead() }

          <tbody className={ isFetching ? Classes.SKELETON : "" }>
            { this.renderTbody() }
          </tbody>
        </HTMLTable>

        <Paginator
          pageGroup={pageGroup}
          totalPages={totalPages}
          currentPage={currentPage}
          setPageGroup={setPageGroup}
          setCurentPage={setCurentPage}
          onFetch={this.handleFetch}
        />

      </Wrapper>
    );
  }
}

export default NotificationLogPanel;
