import { types } from "mobx-state-tree";
import moment from "moment";

export const mapForDepositForm = (form: ClientDepositForm) => {
  return {
    date: form.date,
    payout: form.payoutWithoutVAT
  }
}

export const ClientDepositFormModel = types
  .model("ClientDepositForm", {
    date: types.optional(types.string, moment().toDate().toISOString()),
    payout: types.optional(types.number, 0),
  })
  .views(self => ({
    get payoutWithoutVAT() {
      return Math.round(self.payout / 1.1)
    },
    get payoutWithVATForRead() {
      return Math.floor(Math.round(self.payout * 1.1)/10) * 10;
    }
  }))
  .actions(self => ({
    setDate(value: string) {
      self.date = value;
    },

    setPayout(value: number) {
      self.payout = value;
    }
  }));

type ClientDepositFormType = typeof ClientDepositFormModel.Type;
export interface ClientDepositForm extends ClientDepositFormType {}

export const ClientCalculateFormModel = types
  .model("ClientCalculateForm", {
    id: types.maybeNull(types.number),
    contract: types.optional(types.string, ''),
    projectGroupId: types.optional(types.string, ''),
    sprints: types.array(types.number),
    clientId: types.maybeNull(types.string),
    dateIssue: types.optional(types.string, moment().toDate().toISOString().substring(0, 10)),
    payout: types.optional(types.number, 0),
    deposits: types.array(ClientDepositFormModel),
    comment: types.optional(types.string, "")
  })
  .views(self => ({
    get payoutWithVAT() {
      return Math.round(self.payout * 1.1)
    }
  }))
  .actions(self => ({
    setContract(value: string) {
      self.contract = value;
    },

    setClient(value?: string) {
      if(value) {
        self.clientId = value;
      }
    },

    setProjectGroupId(value: string) {
      self.projectGroupId = value;
    },

    setDateIssue(value: string) {
      self.dateIssue = value;
    },

    setPayout(value: number) {
      self.payout = value;
    },

    setComment(value: string) {
      self.comment = value;
    },

    resetSprints() {
      self.sprints.replace([]);
    },

    setSprint(value: number) {
      if(value > 0) {
        self.sprints.replace([value]);
      } else {
        self.sprints.clear();
      }
      
    },

    addSprints(value: number) {
      !self.sprints.includes(value) && self.sprints.push(value);
    },

    removeSprints(value: number) {
      const index = self.sprints.indexOf(value);
      if (index > -1) {
        self.sprints.splice(index, 1);
      }
    },

    addDeposit() {
      self.deposits.push(
        ClientDepositFormModel.create()
      );
    },

    removeDeposit(deposit: ClientDepositForm) {
      self.deposits.remove(deposit);
    }
  }));

type ClientCalculateFormType = typeof ClientCalculateFormModel.Type;
export interface ClientCalculateForm extends ClientCalculateFormType {}
