import React from "react";

import { RoleButtonWrapper as Wrapper } from './_wrapper';


export interface IRoleButton {
  onClick?: () => void;
};

export const RoleButton: React.FC<IRoleButton> = (props) => {
  return (
    <Wrapper
      onClick={ props.onClick }
     />
  );
}
