import React, { ChangeEvent, Component, MouseEvent } from "react";
import { observable } from "mobx";
import { Button, InputGroup } from "@blueprintjs/core";
import { observer } from "mobx-react";

// import { SubTaskListForm } from "../../../store/forms/TaskManagerForm/SubTaskListForm";

interface Props {
  // subTaskListForms: SubTask[];
  // onNewSubTaskList: (val: string) => void;
  disabled: boolean | undefined;
  onNewSubTaskTitle: (val: string) => void;
}

@observer
class AddSubTaskListForm extends Component<Props> {
  @observable newSubTaskTitle: string = "";
  @observable enabled = false;

  handleNewSubTask = async () => {
    await this.props.onNewSubTaskTitle(this.newSubTaskTitle);
    this.newSubTaskTitle = "";
  };

  render() {
    const { onNewSubTaskTitle, disabled } = this.props;
    return (
      <div style={{marginTop:"10px"}}>
        {this.enabled ?
          <div>
            <div style={{ float: "left", marginLeft: "5px", marginRight: "10px" }}>
              <InputGroup id="text-input" placeholder="Subtask를 입력해주세요."
                          value={this.newSubTaskTitle}
                          autoFocus
                // onBlur={() => {
                //   this.newSubTaskTitle = "";
                //   this.newSubTasks[subTaskList.subTaskListId] = false;
                // }}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            this.newSubTaskTitle = e.target.value;
                          }}
                          onKeyPress={async (event) => {
                            if (event.key === "Enter") {
                              event.preventDefault();
                              event.stopPropagation();
                              if (this.newSubTaskTitle.trim() !== "")
                                await this.handleNewSubTask();
                            }
                          }}
                          fill={true}
              />
            </div>

            <div style={{ float: "left" }}>
              <Button style={{ display: "inline-block", marginRight: "5px" }} text="추가"
                      onClick={async (e: MouseEvent<HTMLElement>) => {
                        console.log("추가");
                        await this.handleNewSubTask();
                      }}/>
              <Button style={{ display: "inline-block" }} text="취소" onClick={() => {
                this.enabled = false;
                this.newSubTaskTitle = "";
              }}/>
            </div>
          </div> :
          <Button text={"Subtask 추가"}
                  disabled={disabled}
                  onClick={() => {
                    this.enabled = true;
                  }}/>}
        <div style={{ clear: "left" }}></div>
      </div>

    );
  }
}

export default AddSubTaskListForm;