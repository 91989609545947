import React, { Component } from "react";
import styled from "styled-components/macro";
import { observable, computed } from "mobx";
import { inject, observer } from "mobx-react";
import { Classes, Dialog, FormGroup, ButtonGroup, Button, Intent, Icon, Divider, Spinner, Alert } from "@blueprintjs/core";
import moment from 'moment';

import { AppStore } from "../../../store/AppStore";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import DashboardOfficeHourPopupInputUnit from "../../atoms/DashboardComponents/DashboardOfficeHourPopupInputUnit";

// 스타일
const FormGroupLabel = styled.div`
  margin-top: 5px;
  font-weight: bold;
  width: 120px;
  line-height: 18px;
`;

const IconButton = styled(Icon)`
  margin-left: 10px;
  cursor: pointer;
`;

const VacationText = styled.span`
  margin-left: 10px;
  color: #ff0000;
`;

const WeekSelectionContainer = styled.div`
  margin: 0 auto;
  padding-top: 20px;
`;

const SelectedWeekButton = styled(Button)`
  font-weight: bold;
  font-size: 1.5em;
`;

// 상태 관리
interface DashboardOfficeHourPopupProps {
  isOpen: boolean;
  onClose: () => void;
}
interface InjectedProps extends DashboardOfficeHourPopupProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class DashboardOfficeHourPopup extends Component<DashboardOfficeHourPopupProps> {
  @observable readyToShow: boolean = false;
  @observable thisWeek: boolean = true;
  @observable myVacations: Array<string> = [];
  @observable holidays: Array<string> = [];
  @observable isAlertOpen: boolean = false;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get formStore() {
    return this.injected.appStore.dashboardOfficeHourFormStore;
  }

  @computed
  get currentForm() {
    return this.formStore.currentForm;
  }

  // 이벤트 처리
  handleOnOpening = async() => {
    this.thisWeek = true;
    this.readyToShow = false;
    
    // 기 입력된 근무시간 정보를 호출
    const creator = this.injected.appStore.userStore.userId;
    await this.formStore.fetchForm(creator);

    // 휴가일을 가져오기 위해 이번주 / 다음주의 대시보드 스케줄 호출
    const scheduleStore = this.injected.appStore.scheduleManagerStore;
    await scheduleStore.fetchDashBoardSeesoSchedules();

    // 휴가일을 저장해두었다가 근무시간을 입력하지 못하게 처리
    this.myVacations = [];
    scheduleStore.dashboardSeesoSchedules.map(schedule => {
      if(schedule.category === 'sch' && schedule.schCategory === 'vac' && schedule.creator === creator) {
        let s = moment(schedule.startAt)
        let e = moment(schedule.endAt)
        
        while(s.isSameOrBefore(e)) {
          this.myVacations.push(s.format("MMDD"));
          s.add(1, 'days')
        }
      }
    })

    // 공휴일에 근무시간을 입력하지 못하게 처리
    this.holidays = [];
    await this.injected.appStore.scheduleManagerStore.fetchWeekdayHolidays();
    scheduleStore.weekdayHolidays.map(x => {
      this.holidays.push(moment(x.date).format("YYYYMMDD"));
    });

    // 정보를 가져온 후 화면을 볼 수 있도록 처리
    this.readyToShow = true;
  }

  handleWeekChange = (thisWeek: boolean) => {
    this.thisWeek = thisWeek;
  }

  // 일정 추가
  handleAddClick = (dayOfWeek: number) => {
    const creator = this.injected.appStore.userStore.userId;
    this.currentForm.newSchedule(creator, dayOfWeek);
  }

  // 일정 삭제
  handleDeleteClick = (dayOfWeek: number, rowKey: number) => {
    this.currentForm.deleteSchedule(dayOfWeek, rowKey);
  }

  // 일정 수정
  handleStartAtChange = (dayOfWeek: number, rowKey: number, hour: string, minute: string) => {
    this.currentForm.updateStartAt(dayOfWeek, rowKey, hour, minute);

    // 종료시간을 자동으로 +9 설정한다
    let startAt = moment(this.currentForm.schedules[dayOfWeek][rowKey].startAt);
    let endAt = moment(startAt).add(9, 'hours');
    if(startAt.dates() == endAt.dates()) {
      this.currentForm.updateEndAt(dayOfWeek, rowKey, String(endAt.hours()), minute);
    } else {
      this.currentForm.updateEndAt(dayOfWeek, rowKey, "23", "59");
    }

  }

  handleEndAtChange = (dayOfWeek: number, rowKey: number, hour: string, minute: string) => {
    if (
      moment(this.currentForm.schedules[dayOfWeek][rowKey].startAt)
      >=
      moment(this.currentForm.schedules[dayOfWeek][rowKey].endAt)
        .hour(Number(hour))
        .minute(Number(minute))
    ) {
      alert('시작시간은 종료시간보다 앞서야 합니다.');
      return;
    }  
    this.currentForm.updateEndAt(dayOfWeek, rowKey, hour, minute);
  }

  handlePlaceChange = (dayOfWeek: number, rowKey: number, place: string) => {
    this.currentForm.updatePlace(dayOfWeek, rowKey, place);
  }

  // 일정 복사
  handleCopyClick = async () => {
    this.currentForm.copySchedule(this.myVacations, this.holidays);
  }

  // 일정 저장
  handleSaveClick = async () => {
    // 파트너계정으로 근무시간 입력하면 경고창을 띄움
    if(this.injected.appStore.userStore.email === 'partner@seeso.kr') {
      this.isAlertOpen = true;
    } else {
      this.saveForm();
    }
  }

  saveForm = async () => {
    try {
      await this.formStore.saveForm().then( () => {
        this.props.onClose();
      } ) ;
      
      AppToaster.show({
        message: "근무시간이 업데이트 되었습니다.",
        intent: Intent.SUCCESS
      }); 

    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }   
  }

  render() {
    const { isOpen, onClose } = this.props;
    const dateNames = [
      '월요일','화요일', '수요일', '목요일', '금요일', '토요일', '일요일',
      '월요일','화요일', '수요일', '목요일', '금요일', '토요일', '일요일'
    ];

    return (
      <Dialog
        icon="mugshot"
        onClose={onClose}
        title={"근무시간"}
        isOpen={isOpen}
        usePortal={true}
        autoFocus={true}
        canEscapeKeyClose={true}
        enforceFocus={true}
        style={{width: "540px"}}
        onOpening={this.handleOnOpening}
      >
        {!this.readyToShow ? (
          <div className={Classes.DIALOG_BODY}>
            <Spinner />
          </div>
        ) : (
          <>
            <WeekSelectionContainer>
              <ButtonGroup minimal={true} vertical={false}>
                {this.thisWeek ? (
                  <>
                  <SelectedWeekButton text={'이번주'} large={this.thisWeek} onClick={() => this.handleWeekChange(true)} /> 
                  <Divider />
                  <Button text={'다음주'} large={!this.thisWeek} onClick={() => this.handleWeekChange(false)} />
                  </>
                ) : (
                  <>
                  <Button text={'이번주'} large={this.thisWeek} onClick={() => this.handleWeekChange(true)} /> 
                  <Divider />
                  <SelectedWeekButton text={'다음주'} large={!this.thisWeek} onClick={() => this.handleWeekChange(false)} />
                  </>
                )}
              </ButtonGroup>
            </WeekSelectionContainer>
            <div className={Classes.DIALOG_BODY}>
              {
                this.currentForm.schedules.map((schedules, d_idx) => {
                  return (
                    <div key={d_idx}>
                    {
                      ((this.thisWeek && d_idx < 7) || (!this.thisWeek && 7 <= d_idx)) && (
                        <FormGroup
                          label={
                            <FormGroupLabel>
                              {dateNames[d_idx]} ({this.formStore.targetDates[d_idx].substring(4)}) 
                              {
                                !this.myVacations.includes(this.formStore.targetDates[d_idx].substring(4)) && (
                                  <IconButton icon="add" iconSize={14} intent={Intent.PRIMARY} onClick={() => this.handleAddClick(d_idx)} />
                                )
                              }
                            </FormGroupLabel>
                          } 
                          inline={true}
                        >            
                        {
                          this.myVacations.includes(this.formStore.targetDates[d_idx].substring(4)) ? (
                            <VacationText>휴가</VacationText>
                          ) : (
                            <>
                            {
                              this.holidays.includes(this.formStore.targetDates[d_idx]) ? (
                                <VacationText>공휴일</VacationText>
                              ) : (
                                <>
                                {
                                  schedules.map((schedule, s_idx) => (
                                    <DashboardOfficeHourPopupInputUnit 
                                      key={s_idx} 
                                      rowKey={s_idx} 
                                      dayOfWeek={d_idx} 
                                      startAt={schedule.startAt} 
                                      endAt={schedule.endAt} 
                                      title={schedule.title} 
                                      onStartAtChange={this.handleStartAtChange}
                                      onEndAtChange={this.handleEndAtChange}
                                      onPlaceChange={this.handlePlaceChange}
                                      onDeleteClick={this.handleDeleteClick} 
                                    />
                                  ))
                                }
                                </>
                              )
                            }
                            </>
                          )
                        }
                        </FormGroup>              
                      )
                    }
                    </div>
                  );
                })
              }
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                { !this.thisWeek && (
                  <Button text="복사" intent={Intent.SUCCESS} onClick={this.handleCopyClick}></Button>
                )}
                <Button text="취소" intent={Intent.DANGER} onClick={onClose}></Button>
                <Button text="저장" intent={Intent.PRIMARY} onClick={this.handleSaveClick}></Button>
                <Alert
                    cancelButtonText="취소"
                    confirmButtonText="입력"
                    icon="warning-sign"
                    intent={Intent.DANGER}
                    isOpen={this.isAlertOpen}
                    onCancel={() => { this.isAlertOpen = false; }}
                    onConfirm={() => { this.isAlertOpen = false; this.saveForm(); }}
                >
                    <p>파트너계정으로 로그인되어 있습니다. 근무시간을 입력하시겠습니까?</p>
                </Alert>
              </div>
            </div>
          </>
        )}
      </Dialog>      
    );
  }
}

export default DashboardOfficeHourPopup;
