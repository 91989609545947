import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { ItemPredicate, Select, IItemRendererProps } from "@blueprintjs/select";
import { RufreeRole } from "../../../store/models/RufreeRole";
import { MenuItem, Button } from "@blueprintjs/core";
import { highlightText } from "../../../utils/BlueprintSelectionHelpers";

export const filterItem: ItemPredicate<RufreeRole> = (
  query,
  item,
  _index,
  exactMatch
) => {
  const normalizedTitle = item.role.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
    return normalizedTitle === normalizedQuery;
  } else {
    return `${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
  }
};

const ItemSelect = Select.ofType<RufreeRole>();

interface RufreeRoleSelectionProps {
  onChange: (rufreeRole: RufreeRole) => void;
  currentValue?: RufreeRole;
  items: RufreeRole[];
}

interface InjectedProps extends RufreeRoleSelectionProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class RufreeRoleSelection extends Component<RufreeRoleSelectionProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  itemRenderer = (
    item: RufreeRole,
    { handleClick, query, modifiers }: IItemRendererProps
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        key={item.role}
        active={modifiers.active}
        text={highlightText(item.role, query)}
        onClick={handleClick}
      />
    );
  };

  render() {
    const { onChange, currentValue, items } = this.props;

    return (
      <ItemSelect
        key={items.length}
        items={items}
        onItemSelect={onChange}
        itemRenderer={this.itemRenderer}
        itemPredicate={filterItem}
        popoverProps={{ minimal: true }}
      >
        <Button
          rightIcon="caret-down"
          fill={true}
          text={currentValue ? currentValue.role : "선택해주세요"}
        />
      </ItemSelect>
    );
  }
}

export default RufreeRoleSelection;
