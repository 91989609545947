import React, { FC, useState, useEffect } from "react";
import axios from "axios";
import { Select, IItemRendererProps } from "@blueprintjs/select";
import { MenuItem, Button } from "@blueprintjs/core";
import { highlightText } from "../../../utils/BlueprintSelectionHelpers";

export interface IItem {
  id: number;
  name: string;
  email: string;
  phone: string;
}

const AsyncSelect = Select.ofType<IItem>();

interface Props {
  onChange: (item: IItem) => void;
  currentValue?: string;
}
export const UserSelectionAsync: FC<Props> = props => {

  const { onChange, currentValue } = props;

  const [ query, setQuery ] = useState('');
  const [ items, setItems ] = useState([]);

  useEffect(() => {
    query.length > 2 && fetch(query);
  }, [query]);

  const fetch = async(query: string) => {
    try {
      const response: any = await axios.get(
        "/auth/users",
        {
          params: { search: query }
        }
      );

      setItems(
        response.data.map( (d: any) => ({
          id: d.id,
          name: d.name,
          email: d.email,
          phone: d.phone
        }))
      )

    } catch (e) { }
  }

  const itemRenderer = () => (
    item: IItem,
    { handleClick, query, modifiers }: IItemRendererProps
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        key={item.id}
        active={modifiers.active}
        text={highlightText(`${item.name} - ${item.email}`, query)}
        onClick={handleClick}
      />
    );
  };

  return (
    <AsyncSelect
      items={items}
      resetOnSelect={true}
      resetOnQuery={true}
      resetOnClose={true}
      itemRenderer={ itemRenderer() }
      onItemSelect={ (item) => {
        onChange(item)
      }  }
      inputProps={{
        placeholder: '사용자 이름',
        style: {minWidth: '220px'}
      }}
      onQueryChange={ async (q:string, e:any) => {
        if(e && query !== q) {
          setQuery(q);
        }
      }}
      noResults={<MenuItem disabled={true} text="검색된 아이템이 없습니다." />}
    >

      <Button
        rightIcon="caret-down"
        text={currentValue ? currentValue : '선택해주세요'}
      />

    </AsyncSelect>
  )
};
