import { types, flow } from "mobx-state-tree";
import axios from "axios";
import { CreateReportsFormModel } from "./CreateReportsForm";
import { PMReportResponse } from "../../../types/pmReport";

export const PMReportFormStoreModel = types
  .model("PMReportFormStore", {
    currentForm: types.optional(CreateReportsFormModel, {})
  })
  .actions(self => ({
    initForm() {
      self.currentForm = CreateReportsFormModel.create();
    }
  }))
  .actions(self => {
    const postPMReport = flow(function*() {
      try {
        yield Promise.all(
          self.currentForm.pmReports.map(async report => {
            await axios.post("/pmReports", {
              project_group: report.projectGroup,
              text: report.text,
              date: self.currentForm.date,
              mood: report.mood
            });
          })
        );
      } catch (e) {
        console.log("postPMReport error", e);
        throw e;
      }
    });

    const fetchPMReport = flow(function*(pmReportId: string) {
      try {
        const { data }: { data: PMReportResponse } = yield axios.get(
          `/pmReports/${pmReportId}`
        );

        const newForm = CreateReportsFormModel.create({
          date: data.date,
          pmReports: [
            {
              projectGroup: data.project_group || '',
              text: data.text,
              date: data.date,
              mood: data.mood
            }
          ]
        });

        self.currentForm = newForm;
      } catch (e) {
        console.log("fetchPMReport error", e);
        throw e;
      }
    });

    const patchPMReport = flow(function*(pmReportId: string) {
      try {
        const reportForm = self.currentForm.pmReports[0];

        yield axios.patch(`/pmReports/${pmReportId}`, {
          project_group: reportForm.projectGroup,
          text: reportForm.text,
          date: self.currentForm.date,
          mood: reportForm.mood
        });
      } catch (e) {
        console.log("patchPMReport error", e);
        throw e;
      }
    });

    const deletePMReport = flow(function*(pmReportId: string) {
      try {
        yield axios.delete(`/pmReports/${pmReportId}`);
      } catch (e) {
        console.log("deletePMReport error", e);
        throw e;
      }
    });

    return {
      postPMReport,
      fetchPMReport,
      patchPMReport,
      deletePMReport
    };
  });

type PMReportFormStoreType = typeof PMReportFormStoreModel.Type;
export interface PMReportFormStore extends PMReportFormStoreType {}
