/*
  파트너 회원 검색바
*/
import React, { KeyboardEvent } from "react";
import styled from "styled-components/macro";

import { StatusOptions } from '../../../../store/models/Partner';
import { Button, Classes, HTMLSelect } from '@blueprintjs/core';


const Wrapper = styled.div`
  & {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  & input {
    width: 350px;
    margin-right: 5px;
  }
`;
interface IProps {
  status: string
  search: string,
  onChangeStatus: (status: string) => void,
  onChangeSearch: (search: string) => void,
  onClear: () => void,
  onSearch: () => void
}
const FilterBar = (props: IProps) => {
  return (
    <Wrapper>
      <HTMLSelect
        value={ props.status }
        onChange={ (e) => props.onChangeStatus(e.target.value) }
        options={ StatusOptions } />
      <div>
      <input
        className={ Classes.INPUT }
        value={ props.search }
        onChange={ (e) => props.onChangeSearch(e.target.value) }
        onKeyDown={ async(event: KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Enter') {
            await props.onSearch();
          }
        } } />
      <Button text='검색' onClick={ props.onSearch } />
      <Button text='초기화' minimal={ true } onClick={ props.onClear } />
      </div>
    </Wrapper>
  )
};
export default FilterBar;