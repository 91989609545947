import React, { Component, ChangeEvent, FormEvent } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { FormMode } from "../../../types/formMode";
import { RouteComponentProps, Redirect } from "react-router";
import { computed, observable } from "mobx";
import {
  Spinner,
  FormGroup,
  InputGroup,
  RadioGroup,
  Radio,
  Checkbox,
  TagInput,
  TextArea,
  Button,
  Intent,
  FileInput
} from "@blueprintjs/core";
import FormSection from "../../atoms/FormSection/FormSection";
import RufreeExperienceFormView from "../../molecules/RufreeExperienceFormView/RufreeExperienceFormView";
import {
  ItemRow,
  RemoveButton,
  RemoveButtonContainer
} from "../../atoms/RemoveItemButton/RemoveItemButton";
import { AddItemButton } from "../../atoms/AddItemButton/AddItemButton";
import ContentContainer from "../../atoms/ContentContainer/ContentContainer";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";


interface PageParams {
  rufreeId: string;
}

interface RufreeFormPageProps extends RouteComponentProps<PageParams> {
  mode: FormMode;
}

interface InjectedProps extends RufreeFormPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class RufreeFormPage extends Component<RufreeFormPageProps> {
  @observable isCreateSuccess = false;
  @observable isEditSuccess = false;
  @observable isFormInitiated = false;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get formStore() {
    return this.injected.appStore.rufreeFormStore;
  }

  @computed
  get currentForm() {
    return this.formStore.currentForm;
  }

  async componentDidMount() {
    await this.formStore.initForm();

    const { mode } = this.props;
    const { rufreeId } = this.props.match.params;

    if (mode !== FormMode.Create && rufreeId) {
      await this.formStore.fetchRufree(rufreeId);
    }

    this.isFormInitiated = true;
  }

  handleRufreeIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.currentForm.setRufreeId(e.target.value);
  };

  handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.currentForm.setName(e.target.value);
  };

  handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.currentForm.setEmail(e.target.value);
  };

  handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.currentForm.setPhone(e.target.value);
  };

  handleAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.currentForm.setAddress(e.target.value);
  };

  handleAffiliationChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.currentForm.setAffiliation(e.target.value);
  };

  handleWorkTypeChange = (e: FormEvent<HTMLInputElement>) => {
    this.currentForm.setWorkType(e.currentTarget.value);
  };

  handleAddPdfButtonClick = () => {
    const fileInput = document.getElementById("file-input");

    if (fileInput) {
      fileInput.click();
    }
  };

  handlePdfToUploadChange = (e: FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      this.currentForm.addPdfToUpload(e.currentTarget.files[0]);
    }
  };

  handleRoleChange = (key: string) => {
    this.currentForm.toggleRole(key);
  };

  handleRolesEtcChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.currentForm.setRolesEtc(e.target.value);
  };

  handleExpertChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.currentForm.setExpert(e.target.value);
  };

  handleWorkingTimeChange = (key: string) => {
    this.currentForm.toggleWorkingTime(key);
  };

  handleWorkingStyleChange = (e: FormEvent<HTMLInputElement>) => {
    this.currentForm.setWorkingStyle(e.currentTarget.value);
  };

  renderRoleCheckbox = (key: string) => {
    return (
      <Checkbox
        checked={this.currentForm.isRoleChecked(key)}
        onChange={() => this.handleRoleChange(key)}
        disabled={true}
      >
        {key}
      </Checkbox>
    );
  };

  renderWorkingTimeCheckbox = (key: string) => {
    return (
      <Checkbox
        checked={this.currentForm.isWorkingTimeChecked(key)}
        onChange={() => this.handleWorkingTimeChange(key)}
        disabled={true}
      >
        {key}
      </Checkbox>
    );
  };

  handleMainSkillChange = (values: React.ReactNode[]) => {
    this.currentForm.setMainSkills(values.map(x => x + ""));
  };

  handleSubSkillChange = (values: React.ReactNode[]) => {
    this.currentForm.setSubSkills(values.map(x => x + ""));
  };

  handleRecommender = (e: ChangeEvent<HTMLInputElement>) => {
    this.currentForm.setRecommender(e.target.value);
  };

  handleInterviewChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.currentForm.setInterview(e.target.value);
  };

  handleSubmit = async () => {
    if (this.isCreateSuccess) {
      AppToaster.show({
        message: "이미 저장된 알유프리입니다.",
        intent: Intent.WARNING
      });

      return;
    }

    try {
      await this.formStore.postRufree();
      AppToaster.show({
        message: "새로운 알유프리가 생성되었습니다.",
        intent: Intent.SUCCESS
      });

      this.isCreateSuccess = true;
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  handleCancel = () => {
    this.props.history.push("/rufrees");
  };

  handleEditSubmit = async () => {
    try {
      await this.formStore.patchRufree();

      const { rufreeId } = this.currentForm;

      this.formStore.initForm();

      await this.formStore.fetchRufree(rufreeId);

      AppToaster.show({
        message: "수정사항이 반영되었습니다.",
        intent: Intent.SUCCESS
      });

      this.isEditSuccess = true;
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  render() {
    if (!this.isFormInitiated) {
      return <Spinner />;
    }

    if (this.isCreateSuccess || this.isEditSuccess) {
      return <Redirect to="/rufrees" push={true} />;
    }

    return (
      <Wrapper>
        <FlexContainer>
          <FormContainer>
            <FormSection>
              <FormGroup label={<strong>알유프리번호</strong>} inline={true}>
                <InputGroup
                  value={this.currentForm.rufreeId}
                  onChange={this.handleRufreeIdChange}
                  disabled={true}
                />
              </FormGroup>
              <FormGroup label={<strong>이름</strong>}>
                <InputGroup
                  value={this.currentForm.name}
                  onChange={this.handleNameChange}
                  disabled={true}
                />
              </FormGroup>
              <FormGroup label={<strong>이메일</strong>}>
                <InputGroup
                  value={this.currentForm.email}
                  onChange={this.handleEmailChange}
                  disabled={true}
                />
              </FormGroup>
              <FormGroup label={<strong>전화번호</strong>}>
                <InputGroup
                  value={this.currentForm.phone}
                  onChange={this.handlePhoneChange}
                  disabled={true}
                />
              </FormGroup>
              <FormGroup label={<strong>주소</strong>}>
                <InputGroup
                  value={this.currentForm.address}
                  onChange={this.handleAddressChange}
                  disabled={true}
                />
              </FormGroup>
              <FormGroup label={<strong>현재 소속</strong>}>
                <InputGroup
                  value={this.currentForm.affiliation}
                  onChange={this.handleAffiliationChange}
                  disabled={true}
                />
              </FormGroup>

              {/*<FormGroup label={<strong>주요경력</strong>}>*/}
              {/*  {this.currentForm.experiences.map((experience, index) => (*/}
              {/*    <ItemRow key={index}>*/}
              {/*      <RufreeExperienceFormView experience={experience} />*/}
              {/*    </ItemRow>*/}
              {/*  ))}*/}
              {/*</FormGroup>*/}

              <FormGroup label={<strong>작업가능시간</strong>}>
                <RadioGroup
                  selectedValue={this.currentForm.workType}
                  onChange={this.handleWorkTypeChange}
                  disabled={true}
                >
                  <Radio
                    label="파트타임 참여만 가능합니다 (평일기준 하루 최대 3-4시간)"
                    value="파트타임"
                  />
                  <Radio
                    label="풀타임 근무가 가능합니다 (평일기준 하루 최소 7-8시간)"
                    value="풀타임"
                  />
                </RadioGroup>
              </FormGroup>

              <FormGroup label={<strong>전문분야를 선택해주세요</strong>}>
                {this.renderRoleCheckbox("웹 기획")}
                {this.renderRoleCheckbox("앱 기획")}
                {this.renderRoleCheckbox("웹 디자인")}
                {this.renderRoleCheckbox("앱 디자인")}
                {this.renderRoleCheckbox("기타 디자인(BI, 프린트, etc.)")}
                {this.renderRoleCheckbox("백엔드 개발")}
                {this.renderRoleCheckbox("프론트엔드 개발")}
                {this.renderRoleCheckbox("퍼블리싱")}
                {this.renderRoleCheckbox("iOS 개발")}
                {this.renderRoleCheckbox("안드로이드 개발")}
                {this.renderRoleCheckbox("React Native")}
                {this.renderRoleCheckbox("QA테스팅")}
                {this.renderRoleCheckbox("PM")}
                {this.renderRoleCheckbox("기타")}
                {this.currentForm.isRoleChecked("기타") && (
                  <InputGroup
                    placeholder="기타 전문분야"
                    value={this.currentForm.rolesEtc}
                    onChange={this.handleRolesEtcChange}
                  />
                )}
              </FormGroup>

              {/*<FormGroup*/}
              {/*  label={*/}
              {/*    <strong>*/}
              {/*      전문분야와 관련된 포트폴리오 링크 혹은 PDF파일을 첨부해주세요*/}
              {/*    </strong>*/}
              {/*  }*/}
              {/*>*/}
              {/*  { this.currentForm.portfolio ? (this.currentForm.portfolio.type === 'LINK' ?*/}
              {/*      <ItemRow>*/}
              {/*        <InputGroup*/}
              {/*            placeholder="URL"*/}
              {/*            value={ this.currentForm.portfolio.link }*/}
              {/*            leftIcon="link"*/}
              {/*            onChange={(e: ChangeEvent<HTMLInputElement>) => {*/}
              {/*              this.currentForm.portfolio.setType('link');*/}
              {/*              this.currentForm.portfolio.setLink(e.target.value);*/}
              {/*            }}*/}
              {/*            disabled={true}*/}
              {/*        />*/}
              {/*      </ItemRow> :*/}
              {/*      <ItemRow>*/}
              {/*        <InputGroup*/}
              {/*            value={ this.currentForm.portfolio.file }*/}
              {/*            leftIcon="floppy-disk"*/}
              {/*            disabled={ true }*/}
              {/*        />*/}
              {/*      </ItemRow>) : ''*/}
              {/*  }*/}
              {/*</FormGroup>*/}
              <FormGroup
                label={
                  <strong>
                    메인으로 활용하는 언어, 프레임워크, 툴 또는 서비스를
                    적어주세요
                  </strong>
                }
              >
                <TagInput
                  large={true}
                  values={this.currentForm.mainSkills}
                  onChange={this.handleMainSkillChange}
                  inputProps={{ style: { fontSize: "14px" } }}
                  disabled={true}
                />
              </FormGroup>
              <FormGroup
                label={
                  <strong>
                    서브로 활용하는 언어, 프레임워크, 툴 또는 서비스를 적어주세요
                  </strong>
                }
              >
                <TagInput
                  large={true}
                  values={this.currentForm.subSkills}
                  onChange={this.handleSubSkillChange}
                  inputProps={{ style: { fontSize: "14px" } }}
                  disabled={true}
                />
              </FormGroup>
              <FormGroup label={<strong>특기 분야가 있다면 적어주세요</strong>}>
                <TextArea
                  value={this.currentForm.expert}
                  onChange={this.handleExpertChange}
                  style={{ width: "100%" }}
                  disabled={true}
                />
              </FormGroup>
            </FormSection>

            <FormSection>
              <FormGroup
                label={<strong>선호하시는 업무 시간대를 선택해주세요</strong>}
              >
                {this.renderWorkingTimeCheckbox("오전")}
                {this.renderWorkingTimeCheckbox("오후")}
                {this.renderWorkingTimeCheckbox("밤/새벽")}
              </FormGroup>
              <FormGroup
                label={<strong>선호하시는 업무방식을 선택해주세요</strong>}
              >
                <RadioGroup
                  selectedValue={this.currentForm.workingStyle}
                  onChange={this.handleWorkingStyleChange}
                  disabled={true}
                >
                  <Radio
                    label="장시간에 걸쳐 단계적으로 꼼꼼하게"
                    value="장시간"
                  />
                  <Radio label="단기간 안에 빠르고 집중력 있게" value="단기간" />
                </RadioGroup>
              </FormGroup>
              <FormGroup label={<strong>추천인 혹은 알게된 경로</strong>}>
                <InputGroup
                  value={this.currentForm.recommender}
                  onChange={this.handleRecommender}
                  disabled={true}
                />
              </FormGroup>

              <FormGroup label={<strong>알유프리 인터뷰</strong>}>
                <InputGroup
                  value={this.currentForm.interview}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => { this.handleInterviewChange(e); }}
                />
              </FormGroup>
            </FormSection>
          </FormContainer>

          {/*<FormContainer>
            <FormSection>
              <FormGroup label={<strong>PM 코멘트</strong>}>
                <TextArea
                  rows={20}
                  value={this.currentForm.pmComment}
                  onChange={
                    (e: ChangeEvent<HTMLTextAreaElement>) => {
                      this.currentForm.setPmComment(e.target.value);
                    }
                  }
                  style={{ width: "100%" }}
                />
              </FormGroup>
            </FormSection>
          </FormContainer>*/}
        </FlexContainer>

        <ContentContainer>
          {this.props.mode === FormMode.Create && (
            <ButtonsRow>
              <Button text="취소" onClick={this.handleCancel} />
              <Button
                text="저장"
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit}
              />
            </ButtonsRow>
          )}
          {this.props.mode === FormMode.Edit && (
            <ButtonsRow>
              <Button text="취소" onClick={this.handleCancel} />
              <Button
                text="수정"
                intent={Intent.PRIMARY}
                onClick={this.handleEditSubmit}
              />
            </ButtonsRow>
          )}
        </ContentContainer>
      </Wrapper>
    );
  }
}

export default RufreeFormPage;


const Wrapper = styled.div``;
const FlexContainer = styled.div`
  display: flex;
  * + * {
    margin-right: 10px;
  }
`;
const FormContainer = styled.div`
  width: 600px;
`;

const PortfolioButtonRow = styled.div``;

const ButtonsRow = styled.div`
  float: right;
  margin-bottom: 50px;
  & > * + * {
    margin-left: 10px;
  }
`;