import { types, flow } from "mobx-state-tree";
import axios from "axios";

import { TasktaskResponse, BugTaskResponse } from "../../types/tasktask";

export const mapBugtask = (x: BugTaskResponse) => {
  return {
    id: x.id,
    title: x.title,
    comment: x.comment,
    isComplete: x.is_complete
  }
}

export const mapTasktask = (x: TasktaskResponse) => {
  return {
    id: x.id,
    projectGroup: x.project_group,
    projectGroupName: x.project_group_name,
    createdAt: x.created_at,
    updatedAt: x.updated_at,
    requestAt: x.request_at || '',
    isComplete: x.is_complete,
    isCancel: x.is_cancel,
    requester: x.requestor,
    typeTask: x.type_task,
    status: x.status,
    tasks: x.tasks.map(mapBugtask)
  }
}

export const BugTaskModel = types
  .model("BugTask", {
    id: types.number,
    title: types.string,
    comment: types.string,
    isComplete: types.boolean, 
  })
  .views(self => ({
  }))
  .actions(self => ({
  }));

type BugTaskType = typeof BugTaskModel.Type;
export interface BugTask extends BugTaskType {}

export const TasktaskModel = types
  .model("Tasktask", {
    id: types.number,
    projectGroup: types.string,
    projectGroupName: types.string,
    createdAt: types.string,
    updatedAt: types.string,
    requestAt: types.string,
    isComplete: types.boolean,
    isCancel: types.boolean,
    requester: types.number,
    typeTask: types.string,
    status: types.string,
    tasks: types.array(BugTaskModel)
  })
  .actions(self => {
    const requestCancel = flow(function*() {
      try {
        const { data }: { data: TasktaskResponse } = yield axios.post(
          `/tasktasks/${self.id}/requestCancel`
        );
        self.requestAt = '';
      } catch (e) {
        throw e;
      }
    });

    return {
      requestCancel,
    };
  });

type TasktaskType = typeof TasktaskModel.Type;
export interface Tasktask extends TasktaskType {}
