import { types } from "mobx-state-tree";

export const ClientRoleModel = types
  .model("ClientRole", {
    role: types.string,
    sprints: types.array(types.boolean)
  })
  .views(self => ({
    get totalSprintDays() {
      return self.sprints.reduce((sum, sprint) => (sprint ? sum + 1 : sum), 0);
    },
    get sprintsToNumber() {
      const sprints: number[] = [];

      self.sprints.forEach((s, index) => {
        if(!!s) sprints.push(index);
      });

      return sprints;
    }
  }));

type ClientRoleType = typeof ClientRoleModel.Type;
export interface ClientRole extends ClientRoleType {}
