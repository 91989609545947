import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import LargeContainer from "../../atoms/LargeContainer/LargeContainer";
import { PageNavWrapper } from "../../atoms/PageNav/PageNav";
import { BreadCrumb } from "../../molecules/BreadCrumb/BreadCrumb";
import {
  MetaHead,
  SubTitle,
  Title,
  CardContainer,
  FlexRow,
  LeftColumn,
  ContentColumn,
  GreyLabel,
  LargeText,
  NormalText,
  DividerLine
} from "../../atoms/DetailReportComponents/DetailReportComponents";
import { computed, observable } from "mobx";
import { RouteComponentProps } from "react-router";
import { Spinner, Button, Intent, MenuItem } from "@blueprintjs/core";
import { RoundedButton } from "../../atoms/RoundedButton/RoundedButton";
import { ConvertLinkTextFormat } from "../../atoms/ConvertLinkTextFormat/ConvertLinkTextFormat";
import PMUserSelection from "../../molecules/PMUserSelection/PMUserSelection";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import { PMUser } from "../../../store/models/PMUser";
import { Link } from "react-router-dom";
import moment from "moment";

const Wrapper = styled.div``;

const Section = styled.section`
  margin-bottom: 65px;
`;

const StyledRoundedButton = styled(RoundedButton)`
  width: 100%;
  text-align: left;
  text-overflow: ellipsis;
`;

const StatusButtonRow = styled.div`
  display: flex;

  & > * + * {
    margin-left: 8px;
  }
`;

const StatusText = styled.span`
  font-weight: bold;
`;

export const getTypeText = (type: string) => {
  switch (type) {
    case "web":
      return "웹사이트";
    case "app":
      return "앱(어플)";
    case "etc":
      return "기타";
    case "existed":
      return "기존";
  }
};
//기획/디자인/개발/기타
export const getAreaText = (area: string) => {
  switch (area) {
    case "develop":
      return "개발";
    case "planning":
      return "기획";
    case "design":
      return "디자인";
    case "pm":
      return "PM";
    case "qa_tester":
      return "QA테스터";
    case "etc":
      return "기타";
  }
};

export const getPreparedText = (data: string) => {
  switch (data) {
    case "true":
      return "예, 필요한 자료가 모두 준비되어 있습니다.";
    case "false":
      return "아니요, 무엇이 필요한지도 상담을 통해 알고 싶습니다.";
    case "on_progress":
      return "아니요, 작성 중에 있습니다.";
    case "proposal_true":
      return "기획서가 준비되어 있습니다.";
    case "design_true":
      return "디자인 원본과 가이드가 준비되어 있습니다.";
    case "develop_true":
      return "개발 소스코드 및 문서가 준비되어 있습니다.";
  }
};

export const getStatusText = (status: string) => {
  switch (status) {
    case "pending":
      return "대기중";
    case "accepted":
      return "승인됨";
    case "rejected":
      return "거절됨";
    default:
      return "알수없음";
  }
};

export const getEtcText = (data: string) => {
  switch (data) {
    case "reference":
      return "참고할만한 레퍼런스가 있습니다.";
    case "communication":
      return "커뮤니케이션을 맡을 전문 담당자가 있습니다.";
    case "planned":
      return "추후 업데이트 등 중장기적 플랜이 있습니다.";
    case "nda":
      return "비밀 유지 계약이 필요합니다.";
    case "existed_team":
      return "추후 유지보수를 위한 개발인력이 있습니다.";
    case "existed":
      return "기존";
  }
};

export const getFeatureText = (data: string) => {
  switch (data) {
    case "signup":
      return "이용자들이 로그인을 해야 사용할 수 있는 기능들이 있습니다.";
    case "post":
      return "이용자, 관리자가 글 혹은 사진 등의 게시물을 등록, 수정, 삭제할 수 있습니다.";
    case "message":
      return "이용자간 쪽지/채팅 등의 방식으로 소통할 수 있습니다.";
    case "shop":
      return "이용자가 카드 등을 이용해 등록된 상품을 결제할 수 있습니다.";
    case "admin":
      return "제작물 혹은 이용자의 게시물을 관리하거나, 조회 및 통계 기능을 활용하여 현황을 살펴볼 수 있습니다.";
    case "existed":
      return "기존";
  }
};

interface PageParams {
  receptionId: string;
}

interface ReceptionDetailPageProps extends RouteComponentProps<PageParams> {
}

interface InjectedProps extends ReceptionDetailPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ReceptionDetailPage extends Component<ReceptionDetailPageProps> {
  @observable readyToShow = false;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get receptionStore() {
    return this.injected.appStore.receptionStore;
  }

  @computed
  get projectFormStore() {
    return this.injected.appStore.createProjectFormStore;
  }


  @computed
  get reception() {
    const { receptionId } = this.props.match.params;

    return this.injected.appStore.receptionStore.receptions.find(
      x => x.receptionId === String(receptionId)
    );
  }

  @computed
  get clientUser() {
    if (!this.reception || !this.reception.clientUser) {
      return null;
    }

    const { clientUser } = this.reception;

    return this.injected.appStore.clientUserStore.clientUsers.find(
      x => x.id === clientUser
    );
  }

  @computed
  get manager() {
    if (!this.reception || !this.reception.manager) {
      return null;
    }

    const { manager } = this.reception;

    return this.injected.appStore.pmUserStore.pmUsers.find(
      x => x.id === manager
    );
  }

  @computed
  get selectedPMUser() {
    if (!this.reception) {
      return undefined;
    }

    return this.injected.appStore.pmUserStore.pmUsers.find(
      x => x.id === this.reception!.inspector
    );
  }

  async componentDidMount() {
    const { receptionId } = this.props.match.params;

    try {
      await this.receptionStore.fetchReception(receptionId);

      if (this.reception) {
        const { clientUser, manager } = this.reception;

        if (clientUser) {
          await this.injected.appStore.clientUserStore.fetchClientUser(clientUser);
        }

        if (manager) {
          await this.injected.appStore.pmUserStore.fetchPMUsers();
        }
      }
    } catch (e) {
    } finally {
      this.readyToShow = true;
    }
  }

  handleAccept = async () => {
    try {
      if (this.reception) {
        await this.reception.acceptReception();
      }
    } catch (e) {
      const error = JSON.stringify(e.response.data);

      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  handleReject = async () => {
    try {
      if (this.reception) {
        await this.reception.rejectReception();
      }
    } catch (e) {
      const error = JSON.stringify(e.response.data);

      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  handlePMUserChange = async (selectedValue: PMUser) => {
    if (!this.reception) {
      return;
    }

    try {
      await this.reception.changeInspector(selectedValue.id);
      AppToaster.show({
        message: "검수담당자가 변경되었습니다.",
        intent: Intent.SUCCESS
      });

      // this.props.history.push("/receptions");
    } catch (e) {
      const error = JSON.stringify(e.response.data);

      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  render() {
    if (!this.readyToShow) {
      return <Spinner/>;
    }

    const { reception } = this;

    if (!reception) {
      return <Wrapper>Reception 을 가져오지 못했습니다.</Wrapper>;
    }

    let priority = <span></span>;
    if (reception.priority.includes("fast")) {
      priority =
        <span><strong>빠른 런칭</strong>, 풀타임 작업이 가능한 고급 인력으로 팀을 구성하여 요청하신 기간안에 런칭, <br/>빠른 시장테스트가 필요하거나 오픈일자가 정해진 고객에게 추천합니다.</span>;
    } else if (reception.priority.includes("perfection")) {
      priority =
        <span><strong>완성도 높은 런칭</strong>, 디테일한 매니징과 검수를 통해 확실한 결과물 런칭,<br/>  안정된 결과물로 신뢰를 얻어야 하는 고객에게 추천합니다.</span>;
    } else if (reception.priority.includes("cost")) {
      priority =
        <span><strong>저비용 런칭</strong>, 꼭 필요한 부분만 엄선 제작하여 최소비용으로 런칭, <br/> 한정된 예산안에 결과물이 필요한 고객에게 추천합니다.</span>;
    }

    const { isPM } = this.injected.appStore.userStore;

    console.log("reception.areaTypeEtcText", reception.areaTypeEtcText);
    return (
      <LargeContainer>
        <PageNavWrapper></PageNavWrapper>

        <MetaHead>
          <SubTitle>접수일 {reception.dateRegisterString}</SubTitle>
          <Title>{reception.title}</Title>
        </MetaHead>

        <CardContainer>
          {reception.serviceType && (
            <Section>
              <FlexRow>
                <LeftColumn>
                  <GreyLabel>서비스 종류</GreyLabel>
                </LeftColumn>
                <ContentColumn>
                  <LargeText>
                    {reception.serviceType == 'normal' && ('일반의뢰')}
                    {reception.serviceType == 'second' && ('세컨팀')}
                  </LargeText>
                </ContentColumn>
              </FlexRow>
            </Section>
          )}
          <Section>
            <FlexRow>
              <LeftColumn>
                <GreyLabel>프로젝트 종류</GreyLabel>
              </LeftColumn>
              <ContentColumn>
                <LargeText>
                  {reception.areaType.map((val) => {
                    if (val === "etc")
                      return getTypeText(val) + `(${reception.areaTypeEtcText})`;
                    else
                      return getTypeText(val);
                  }).join(" / ")}
                </LargeText>
              </ContentColumn>
            </FlexRow>
          </Section>

          <Section>
            <FlexRow>
              <LeftColumn>
                <GreyLabel>프로젝트 작업범위</GreyLabel>
              </LeftColumn>
              <ContentColumn>
                <LargeText>
                  {reception.area.map(val => {
                    const add = val === "etc" ? `(${reception.areaEtcText})` : "";
                    return getAreaText(val) + add;
                  }).join(" / ")}
                </LargeText>
              </ContentColumn>
            </FlexRow>
          </Section>

          <Section>
            <FlexRow>
              <LeftColumn>
                <GreyLabel>희망하는 운영 방식</GreyLabel>
              </LeftColumn>
              <ContentColumn>
                <LargeText>
                  {priority}
                </LargeText>
              </ContentColumn>
            </FlexRow>
          </Section>

          {/* <OperationSection>
            <FlexRow>
              <LeftColumn>
                <GreyLabel>희망 운영 방식</GreyLabel>
              </LeftColumn>
              <ContentColumn>
                <LargeText>빠른 런칭</LargeText>
                <NormalText>
                  풀타임 작업이 가능한 고급 인력으로 팀을 구성하여 요청하신
                  기간안에 런칭.
                </NormalText>
              </ContentColumn>
            </FlexRow>
          </OperationSection> */}

          <Section>
            <FlexRow>
              <LeftColumn>
                <GreyLabel>의뢰자료</GreyLabel>
              </LeftColumn>
              <ContentColumn>
                {reception.dataPrepared.map(prepared => (
                  <NormalText>{getPreparedText(prepared)}</NormalText>
                ))}
                <br/>
                {reception.referenceText}
              </ContentColumn>
            </FlexRow>
          </Section>

          <Section>
            <FlexRow>
              <LeftColumn>
                <GreyLabel>기타</GreyLabel>
              </LeftColumn>
              <ContentColumn>
                {reception.etcReference.map(etc => (
                  <NormalText>{getEtcText(etc)}</NormalText>
                ))}
              </ContentColumn>
            </FlexRow>
          </Section>

          <Section>
            <FlexRow>
              <LeftColumn>
                <GreyLabel>필수 기능</GreyLabel>
              </LeftColumn>
              <ContentColumn>
                {reception.feature.map(feature => (
                  <NormalText>{getFeatureText(feature)}</NormalText>
                ))}
              </ContentColumn>
            </FlexRow>
          </Section>

          <DividerLine/>

          <Section>
            <FlexRow>
              <LeftColumn>
                <GreyLabel>서비스 프로젝트 명</GreyLabel>
              </LeftColumn>
              <ContentColumn>
                <LargeText>{reception.title}</LargeText>
              </ContentColumn>
            </FlexRow>
          </Section>

          <Section>
            <FlexRow>
              <LeftColumn>
                <GreyLabel>의뢰인</GreyLabel>
              </LeftColumn>
              <ContentColumn>
                {this.clientUser ? (
                  <>
                    <LargeText>{this.clientUser.name}</LargeText>
                    <NormalText>{this.clientUser.email}</NormalText>
                    <NormalText>{this.clientUser.phone}</NormalText>
                    <NormalText>{this.clientUser.affiliation}</NormalText>
                  </>
                ) : (
                  "찾을 수 없음"
                )}
              </ContentColumn>
            </FlexRow>
          </Section>

          <Section>
            <FlexRow>
              <LeftColumn>
                <GreyLabel>영업담당</GreyLabel>
              </LeftColumn>
              <ContentColumn>
                {this.manager ? (
                  <>
                    <LargeText>{this.manager.name}</LargeText>
                  </>
                ) : (
                  "없음"
                )}
              </ContentColumn>
            </FlexRow>
          </Section>

          <Section>
            <FlexRow>
              <LeftColumn>
                <GreyLabel>미팅 희망일</GreyLabel>
              </LeftColumn>
              <ContentColumn>
                <LargeText>{reception.dateMeetingString}</LargeText>
              </ContentColumn>
            </FlexRow>
          </Section>

          <Section>
            <FlexRow>
              <LeftColumn>
                <GreyLabel>프로젝트 의뢰서</GreyLabel>
              </LeftColumn>
              <ContentColumn>
                <NormalText>
                  <ConvertLinkTextFormat>{reception.detail}</ConvertLinkTextFormat>
                </NormalText>
              </ContentColumn>
            </FlexRow>
          </Section>

          <Section>
            <FlexRow>
              <LeftColumn>
                <GreyLabel>관련자료</GreyLabel>
              </LeftColumn>
              <ContentColumn>
                {reception.googleDriveLink && (
                  <a target="_blank" href={reception.googleDriveLink}>
                    <StyledRoundedButton>링크 바로가기</StyledRoundedButton>
                  </a>
                )}
                {reception.driveLinks.map((links,index)=> {
                  return (
                    <a target="_blank" href={links}>
                      <StyledRoundedButton>링크 바로가기 {index}</StyledRoundedButton>
                    </a>
                  )
                })}
                {reception.dataFile && (
                  <a target="_blank" href={reception.dataFile}>
                    <StyledRoundedButton>
                      {reception.dataFile}
                    </StyledRoundedButton>
                  </a>
                )}
              </ContentColumn>
            </FlexRow>
          </Section>

          <Section>
            <FlexRow>
              <LeftColumn>
                <GreyLabel>검수담당자</GreyLabel>
              </LeftColumn>
              <ContentColumn>
                {isPM ? (
                  <PMUserSelection
                    onChange={this.handlePMUserChange}
                    currentValue={this.selectedPMUser}
                    blockFetchingData={false}
                    // items={this.injected.appStore.pmUserStore.receptionAuthors}
                  />
                ) : (
                  <NormalText>
                    {this.selectedPMUser && this.selectedPMUser.name}
                  </NormalText>
                )}
              </ContentColumn>
            </FlexRow>
          </Section>

          {isPM && (
            <Section>
              <FlexRow>
                <LeftColumn>
                  <GreyLabel>접수결과</GreyLabel>
                </LeftColumn>
                <ContentColumn>
                  {(reception.status === "" ||
                    reception.status === "pending") && (
                    <StatusButtonRow>
                      <Button
                        text="검수하기"
                        onClick={this.handleAccept}
                        intent={Intent.PRIMARY}
                      />
                      <Button
                        text="거절하기"
                        onClick={this.handleReject}
                        intent={Intent.DANGER}
                      />
                    </StatusButtonRow>
                  )}
                  {reception.status === "accepted" && (
                    <StatusText>승인되었습니다.</StatusText>
                  )}
                  {reception.status === "rejected" && (
                    <StatusText>거절되었습니다.</StatusText>
                  )}
                </ContentColumn>
              </FlexRow>
            </Section>
          )}


          {reception.consults.length > 0 &&
            <Section>
            <FlexRow>
              <LeftColumn>
                <GreyLabel>영업상담서</GreyLabel>
              </LeftColumn>
              <ContentColumn>
                {
                  reception.consults.map((consult, index) => (
                    <div key={index} style={{ margin: "5px" }}>
                      <Link to={`/consulting/projects/${consult.projectId}`} target="_blank">
                        <RoundedButton style={{ boxShadow: "none" }}>
                          {`${consult.name} - ${moment(consult.updatedAt).format("YYYY년MM월DD일")}`}
                        </RoundedButton>
                      </Link>
                    </div>
                  ))
                }


              </ContentColumn>
            </FlexRow>
          </Section>}

          {reception.inspection &&
          <Section>
            <FlexRow>
              <LeftColumn>
                <GreyLabel>검수보고서</GreyLabel>
              </LeftColumn>
              <ContentColumn>
                <Link to={`/inspections/${reception.inspection.inspection_id}/read`} target="_blank">
                  <RoundedButton style={{ boxShadow: "none" }}>
                    {`${reception.inspection.title} - ${moment(reception.inspection.updated_at ? reception.inspection.updated_at : new Date()).format("YYYY년MM월DD일")}`}
                    {/*{`${reception.inspection.title} - ${moment(reception.inspection.updated_at).format("YYYY년MM월DD일")}`}*/}
                  </RoundedButton>
                </Link>
              </ContentColumn>
            </FlexRow>
          </Section>}


          {reception.consults.length <1 &&
            <Section>
              <FlexRow>
                <LeftColumn>
                </LeftColumn>
                <ContentColumn>
                  <Button onClick={async () => {
                    let memo = "";
                    reception.dataPrepared.forEach(prepared => {
                        const text = getPreparedText(prepared);
                        if (text)
                          memo += `<p>${text}</p>\n`;
                      }
                    );
                    memo += `${reception.referenceText}\n`;
                    memo += `${reception.detail}`;
                    memo = memo
                      .replace("</p>", "\n")
                      .replace("<p>", "")
                      .replace("<br/>", "\n")
                      .replace(/(<([^>]+)>)/ig, "");
                    const selectedPMId = this.selectedPMUser ? this.selectedPMUser.id : 4;
                    const { receptionId } = this.props.match.params;

                    await this.projectFormStore.initNewFormWithParams({
                      clientName: this.clientUser && this.clientUser.name,
                      clientEmail: this.clientUser && this.clientUser.email,
                      clientPhone: this.clientUser && this.clientUser.phone,
                      name: reception.title,
                      receptionId: receptionId,
                      memo: memo,
                      consultant: selectedPMId
                    });
                    this.props.history.push("/consulting/new");
                  }}>위 프로젝트 의뢰서로 새 상담하기
                  </Button>
                  {/*<Link to="/consulting/new">위 프로젝트 의뢰서로 새 상담하기</Link>*/}
                </ContentColumn>
              </FlexRow>
            </Section>
          }
        </CardContainer>
      </LargeContainer>
    );
  }
}

export default ReceptionDetailPage;
