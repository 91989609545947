import { types } from "mobx-state-tree";
import format from "date-fns/format";

export const ProjectModel = types
  .model("Project", {
    projectId: types.identifier,
    createdAt: types.string,
    updatedAt: types.string,
    name: types.string,
    consultedDate: types.optional(types.string, ""),

    consultant: types.number,
    consultantName: types.string,
    consultantEmail: types.string,

    clientName: types.string,
    clientEmail: types.string,
    clientPhone: types.string,

    receptionId: types.string
  })
  .views(self => ({
    get formattedConsultedDate() {
      return format(new Date(self.consultedDate), "yyyy/MM/dd");
    },
    get formattedCreatedAt() {
      return format(new Date(self.createdAt), "yyyy/MM/dd");
    },
    get nameWithId() {
      return `${self.projectId} - ${self.name}`;
    }
  }));

type ProjectType = typeof ProjectModel.Type;
export interface Project extends ProjectType {}
