import styled from "styled-components/macro";
import { Button, Card, Text } from "@blueprintjs/core";

export const Wrapper = styled.div``;

export const PrimaryButton = styled(Button)`
border-radius: 20px;
background-image: none !important; 
background-color: #ff6565 !important;
box-shadow:none !important; 
color: white !important;
`;

export const SecondaryButton = styled(Button)`
border: 1px solid #9c9c9c;  
border-radius: 20px;
background-image: none !important; 
background-color: white !important;
box-shadow:none !important; 
color: #ff6565 !important;
`;

export const StyledCard = styled(Card)`
  margin-bottom: 30px;
`;

export const CardTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 2px;
  margin-bottom: 20px;
`;

export const CardContent= styled.div`
  display: block;
`;

export const Table = styled.div``;

export const TableRow = styled.div`
  display: flex;
  margin: 15px;
`;

export const HeadColumn = styled.div`
  width: 120px;
  flex-shrink: 0;  
  margin-top: 3px;
`;

export const ContentColumn = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;

export const GrayLabel = styled.div`
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7f8390;
`;

export const t = styled.div`
  font-size: 15px;
  letter-spacing: normal;
  color: #161d2e;
  word-break: keep-all;
  white-space: pre-line;
`;

export const NormalText = styled.div`
  font-size: 15px;
  letter-spacing: normal;
  color: #161d2e;
  word-break: keep-all;
  white-space: pre-line;
`;

export const EmphasizedText = styled.div`
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #161d2e;
  word-break: keep-all;
  white-space: pre-line;
`;

export const TruncatedText = styled.div`
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HelpText = styled.div`
  font-size: 11px;
  font-weight: bold;
  letter-spacing: normal;
  color: #161d2e;
  word-break: keep-all;
  white-space: pre-line;
`;

export const ItemCard = styled(Card)`
  padding: 15px;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const Paragraph = styled.div`
  white-space: pre-wrap;
`;
