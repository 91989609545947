import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { Dialog, Classes, Intent } from "@blueprintjs/core";
import { PrimaryButton, SecondaryButton } from "../../atoms/ParttimeComponents/ParttimeComponents";

import { AppToaster } from "../../organisms/AppToaster/AppToaster";

import { Request } from "../../../store/models/Parttime";

interface RequestRestoreConfirmDialogProps {
    request: Request;
    isOpen: boolean;
    onClose: () => void;
}

interface InjectedProps extends RequestRestoreConfirmDialogProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class RequestRestoreConfirmDialog extends Component<RequestRestoreConfirmDialogProps> {  
    get injected() {
        return this.props as InjectedProps;
    }
    
    handleOpening = () => {};

    handleSubmit = async () => {
        const { request, onClose } = this.props;

        try {
            await this.injected.appStore.parttimeRequestStore.restoreRequest(request.id);
            onClose();
            await this.injected.appStore.parttimeRequestStore.fetchRequestById(request.id);

            AppToaster.show({
                message: "신청상태로 정상적으로 처리되었습니다.",
                intent: Intent.SUCCESS
            });
        } catch (e) {
            const error = JSON.stringify(e.response.data);
            AppToaster.show({
                message: "오류: " + error,
                intent: Intent.DANGER
            });
        }
    };
    

    render() {
        const { isOpen, onClose } = this.props;
    
        const title = `신청 활성화`;
    
        return (
            <Dialog
                onOpening={this.handleOpening}
                isOpen={isOpen}
                onClose={onClose}
                usePortal={true}
                autoFocus={true}
                enforceFocus={true}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                title={title}
            >
            <div className={Classes.DIALOG_BODY}>
                <>신청상태로 되돌립니다.</>
            </div>
    
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <SecondaryButton text={"취소"}onClick={onClose}/>
                <PrimaryButton text={"확인"} onClick={this.handleSubmit}/>
              </div>
            </div>
          </Dialog>
        );
      }

}

export default RequestRestoreConfirmDialog;
