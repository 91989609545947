import React, { Component, ChangeEvent } from "react";
import ReactDOMServer from 'react-dom/server';
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { FormMode } from "../../../types/formMode";
import {
  FormGroup,
  InputGroup,
  TextArea,
  Button,
  Intent,
  Spinner,
  Dialog,
  Classes,
  HTMLSelect,
  Collapse,
  Icon
} from "@blueprintjs/core";
import { computed, observable } from "mobx";
import { Client } from "../../../store/models/Client";
import ClientSelection from "../../molecules/ClientSelection/ClientSelection";
import { DateInput } from "@blueprintjs/datetime";
import { getMomentFormatter } from "../../../utils/date";
import moment from "moment";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
// import SprintTableClient from "../../organisms/SprintTableClient/SprintTableClient";
import FormContainer from "../../atoms/FormContainer/FormContainer";
// import HalfColumn from "../../atoms/HalfColumn/HalfColumn";
// import InputWithFormat, {
//   KoreanCurrencyOptions
// } from "../../atoms/InputWithFormat/InputWithFormat";
import { RouteComponentProps } from "react-router";
import { InspectionResponse } from "../../../types/inspection";
import PMUserSelection from "../../molecules/PMUserSelection/PMUserSelection";
import { PMUser } from "../../../store/models/PMUser";
import {
  AddItemButtonContainer,
  AddItemButton
} from "../../atoms/AddItemButton/AddItemButton";
import {
  ItemRow,
  RemoveButton
} from "../../atoms/RemoveItemButton/RemoveItemButton";
import ProjectGroupReceptionSelection from "../../pages/ProjectGroupFormPage/ProjectGroupReceptionSelection";
import ReferenceLinkFormView from "../../molecules/ReferenceLinkFormView/ReferenceLinkFormView";
import ProjectGroupConsultSelection from "../../pages/ProjectGroupFormPage/ProjectGroupConsultSelection";
import ReferenceFileFormView from "../../molecules/ReferenceLinkFormView/ReferenceFileFormView";
import TemporaryStorage from "../../molecules/Utility/TemporaryStorage/TemporaryStorage";
import { PlanResourceEditSection } from "../../organisms/Plan/PlanResourceEditSection";
import { PlanReadSection } from "../../organisms/Plan/PlanReadSection";

import { FroalaConfigs } from "../../../utils/FroalaConfigs";
import FroalaEditor from 'react-froala-wysiwyg';

const Wrapper = styled.div`
  margin-bottom: 50px;
`;
const Text = styled.div`
  white-space: pre-wrap;
  margin-top: 5px;
`;
const Section = styled.section`
  margin-bottom: 40px;
  width: 1053px;
`;
const SprintContainer = styled.div`
  max-width: 1060px;
`;
const Buttonwrapper = styled.div`
  padding: 3px;
`;
const InputGroupWrapper = styled.div`
  & > * {
    margin-top: 5px;
  }
`;
const ButtonsRow = styled.div`
  /* text-align: right; */

  & > * + * {
    margin-left: 5px;
  }
`;
const RemoveButtonContainer = styled.div`
  position: absolute;
  left: -30px;
  top: 0px;
`;
const ManagingSectionTitle = styled.h3`
  cursor: pointer;
  font-size: 16px;
`;

interface InspectionFormPanelProps extends RouteComponentProps {
  mode: FormMode;
  inspectionId?: string;
}

interface InjectedProps extends InspectionFormPanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class InspectionFormPanel extends Component<InspectionFormPanelProps> {
  @observable isEditSuccess = false;
  @observable isFormInitiated = false;
  @observable isChangeReasonOpened = false;
  @observable isPending = false;
  @observable tempFormTitle = "";
  @observable popoverOpened = false;
  @observable selectedTemplate = -1;
  @observable isManagingSectionOpen = false;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get formStore() {
    return this.injected.appStore.inspectionFormStore;
  }

  @computed
  get inspection() {
    const { inspectionId } = this.props;
    return this.injected.appStore.inspectionStore.inspections.find(
      x => x.inspectionId === inspectionId + ""
    );
  }

  @computed
  get currentForm() {
    return this.formStore.currentForm;
  }

  @computed
  get selectedClient() {
    const { client } = this.currentForm;
    return this.injected.appStore.clientStore.clients.find(
      x => x.clientId === client
    );
  }
  @computed
  get templateOptions() {
    let arr = this.injected.appStore.inspectionTemplateStore.templates.map(item=>{
      return { label: `${item.name}`, value: item.id }
    })
    arr.push({ label: `선택`, value: -1 })
    return arr
  }

  @computed
  get selectedInspector() {
    const { inspector } = this.currentForm;
    return this.injected.appStore.pmUserStore.pmUsers.find(
      x => x.id === inspector
    );
  }

  async saveToLocalStorage() {
    localStorage.setItem(`storedInspectionForm`, JSON.stringify(this.currentForm));
  }

  async componentDidMount() {

    await this.formStore.initForm();
    await this.injected.appStore.inspectionTemplateStore.fetch()

    const { mode, inspectionId } = this.props;

    if (mode !== FormMode.Create && inspectionId) {
      await this.formStore.fetchInspection(inspectionId);
    }

    await this.injected.appStore.pmUserStore.fetchPMUsers();

    this.isFormInitiated = true;
  }

  handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.currentForm.setTitle(e.target.value);
  };

  handleInspectorChange = (selectedValue: PMUser) => {
    this.currentForm.setInspector(selectedValue.id);
  };

  handleClientChange = async (client: Client) => {
    this.currentForm.setClient(client.clientId);
    const clientUser = await this.injected.appStore.inspectionStore.fetchContractor(client.clientId);
    if (clientUser) {
      this.currentForm.setRequesterName(clientUser.name);
      this.currentForm.setRequesterEmail(clientUser.email);
    } else {
      client.name && this.currentForm.setRequesterName(client.representativeName);
    }

  };

  handleRequesterNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.currentForm.setRequesterName(e.target.value);
  };

  handleRequesterEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.currentForm.setRequesterEmail(e.target.value);
  };

  handleDateStartChange = (selectedDate: Date) => {
    this.currentForm.setDateStart(selectedDate.toISOString());
  };

  handlePublishedDateChange = (selectedDate: Date) => {
    this.currentForm.setPublishedDate(selectedDate.toISOString());
  };

  handleReviewChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.currentForm.setReview(e.target.value);
  };

  handleAttentionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.currentForm.setAttention(e.target.value);
  };

  handleChangeReasonChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.currentForm.setChangeReason(e.target.value);
  };

  handleChangeReasonClose = () => {
    this.isChangeReasonOpened = false;
  };

  handleAfterSave = async () => {
    // 검수서 생성/수정 시, 리소스 섹션 랜더링html 코드도 함께 전송한다. (for PDF)
    const {
      fetchInspectionById
    } = this.injected.appStore.inspectionStore;
    await fetchInspectionById(this.formStore.currentForm.inspectionId);

    if(this.inspection) {
      const resourceHtmlCode = ReactDOMServer.renderToString(
        <>
          <div className='schedule-brief'>
            <h2>예상 기간</h2>
            <div>시작일로부터 <span>약 { Math.round(this.inspection.days / 7) }주 ({ this.inspection.days }일)</span></div>
          </div>
          <PlanReadSection
            title={'리소스 구성'}
            inspection={ this.inspection }
            showVATToggle={ false }
            showAllPaymentDetail={ true } />
        </>
      );
      await this.formStore.putInspection({resource_html_code: resourceHtmlCode});
    }
  }

  handleSubmit = async () => {
    try {
      const created: InspectionResponse = await this.formStore.postInspection();
      AppToaster.show({
        message: "새로운 검수서가 생성되었습니다.",
        intent: Intent.SUCCESS
      });

      this.props.history.push(`/inspections/${created.inspection_id}/read`);
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  handleEditSubmit = () => {
    this.isChangeReasonOpened = true;
  };

  handleSave = async () => {
    this.isPending = true;

    try {
      await this.formStore.putInspection();
      await this.handleAfterSave(); // 수정 수, 리소스 섹션 html코드 전송.
  
      await this.injected.appStore.inspectionStore.fetchInspections();
      await this.injected.appStore.inspectionFormStore.postChangeReason();
      AppToaster.show({
        message: "수정되었습니다.",
        intent: Intent.SUCCESS
      });

      this.props.history.push(
        `/inspections/${this.currentForm.inspectionId}/read`
      );
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    } finally {
      this.isPending = false;
    }
  };

  handleCancel = () => {
    const { mode, history, inspectionId } = this.props;

    if (mode === FormMode.Edit) {
      history.push(`/inspections/${inspectionId}/read`);
    } else {
      history.push("/inspections");
    }
  };

  render() {
    if (!this.isFormInitiated) {
      return <Spinner/>;
    }

    return (
      <Wrapper key={"inspectionkey"}>
        <FormContainer>
          <Section>
            <TemporaryStorage keyName={"Inspection"} title={"title"} setForm={(val) => {
              this.formStore.setForm(val);
            }} currentForm ={this.currentForm} />


            <ManagingSectionTitle
              onClick={ () => this.isManagingSectionOpen = !this.isManagingSectionOpen }
            >
              <Icon
                icon={ this.isManagingSectionOpen ? 'chevron-down' : 'chevron-right'}
                iconSize={ 20 }
                style={ { marginRight: '10px' }} />
              매니징 정보
            </ManagingSectionTitle>

            <Collapse
              isOpen={ this.isManagingSectionOpen }
            >
              <FormGroup label={<strong>템플릿</strong>}>
                {this.injected.appStore.inspectionTemplateStore.templates &&
                  <div>
                    <HTMLSelect
                      value={this.selectedTemplate}
                      onChange={(e)=>{
                        this.selectedTemplate = Number(e.target.value);
                        if (this.selectedTemplate != -1){
                          const template = this.injected.appStore.inspectionTemplateStore.templates.find((item)=>{
                            return item.id == this.selectedTemplate
                          })
                          template && this.formStore.overideTemplate(template)

                        }
                      }}
                      options={this.templateOptions}
                      style={{width:"130px"}}
                    />
                  </div>
                }
              </FormGroup>
            
              <FormGroup label={<strong>플랜 담당자</strong>}>
                <PMUserSelection
                  onChange={this.handleInspectorChange}
                  currentValue={this.selectedInspector}
                  blockFetchingData={true}
                />
              </FormGroup>
              <FormGroup label={<strong>클라이언트</strong>}>
                <ClientSelection
                  onChange={this.handleClientChange}
                  currentValue={this.selectedClient}
                />
              </FormGroup>
              <FormGroup label={<strong>의뢰자명</strong>}>
                <InputGroup
                  placeholder="의뢰자명"
                  value={this.currentForm.requesterName}
                  onChange={this.handleRequesterNameChange}
                />
              </FormGroup>
              <FormGroup label={<strong>의뢰자 이메일</strong>}>
                <InputGroup
                  placeholder="의뢰자 이메일"
                  value={this.currentForm.requesterEmail}
                  onChange={this.handleRequesterEmailChange}
                />
              </FormGroup>
              <FormGroup label={<strong>의뢰서</strong>}>
                <AddItemButtonContainer>
                  <AddItemButton
                    onClick={this.currentForm.addReceptionSelection}
                  />
                </AddItemButtonContainer>
                {this.currentForm.receptions.map(reception => (
                  <ItemRow key={reception.id}>
                    <RemoveButtonContainer>
                      <RemoveButton
                        onClick={() =>
                          this.currentForm.removeReceptionSelection(reception)
                        }
                      />
                    </RemoveButtonContainer>
                    <ProjectGroupReceptionSelection
                      key={reception.id}
                      receptionSelection={reception}
                    />
                  </ItemRow>
                ))}
              </FormGroup>

              <FormGroup label={<strong>영업상담</strong>}>
                <AddItemButtonContainer>
                  <AddItemButton
                    onClick={this.currentForm.addConsultSelection}
                  />
                </AddItemButtonContainer>
                {this.currentForm.consults.map(consult => (
                  <ItemRow key={consult.id}>
                    <RemoveButtonContainer>
                      <RemoveButton
                        onClick={() =>
                          this.currentForm.removeConsultSelection(consult)
                        }
                      />
                    </RemoveButtonContainer>
                    <ProjectGroupConsultSelection
                      key={consult.id}
                      consultSelection={consult}
                    />
                  </ItemRow>
                ))}
              </FormGroup>
              <FormGroup label={<strong>플랜 검토 자료</strong>}>
                <Buttonwrapper>
                  <Button
                    icon="add"
                    minimal={true}
                    intent={Intent.PRIMARY}
                    text="추가"
                    onClick={this.currentForm.addReferenceLink}
                  />
                </Buttonwrapper>
                <div style={{ color: "#d53939" }}>⚠️정상적인 url 형식이 아닐시 항상 https가 붙습니다⚠</div>
                <InputGroupWrapper>
                  {this.currentForm.references.map((referenceLink, index) => (
                    <ReferenceLinkFormView
                      key={index}
                      referenceLink={referenceLink}
                      onRemove={this.currentForm.removeReferenceLink}
                    />
                  ))}
                </InputGroupWrapper>
                <InputGroupWrapper>
                  {this.currentForm.inspection_files.map((inspection_file, index) => (
                    <ReferenceFileFormView
                      key={index}
                      inspectionFile={inspection_file}
                      onRemove={this.currentForm.removeInspectionFile}
                    />
                  ))}
                </InputGroupWrapper>
              </FormGroup>
              {this.currentForm.publishedDate && (
                <FormGroup label={<strong>검수서 발행일</strong>}>
                  <DateInput
                    {...getMomentFormatter("YYYY/MM/DD")}
                    locale="ko"
                    closeOnSelection={true}
                    value={moment(this.currentForm.publishedDate + "").toDate()}
                    onChange={this.handlePublishedDateChange}
                    maxDate={new Date("2050-01-01")}
                  />
                </FormGroup>
              )}
              <FormGroup label={<strong>예상일</strong>}>
                <DateInput
                  {...getMomentFormatter("YYYY/MM/DD")}
                  locale="ko"
                  closeOnSelection={true}
                  value={moment(this.currentForm.dateStart + "").toDate()}
                  onChange={this.handleDateStartChange}
                  maxDate={new Date("2050-01-01")}
                />{" "}
                ~{" "}
                <DateInput
                  {...getMomentFormatter("YYYY/MM/DD")}
                  locale="ko"
                  closeOnSelection={true}
                  value={this.currentForm.expectedEndDate}
                  maxDate={new Date("2050-01-01")}
                  disabled={true}
                />
                <Text>
                  {this.currentForm.dateStart && this.currentForm.expectedEndDate
                    ? moment(this.currentForm.expectedEndDate + "").diff(moment(this.currentForm.dateStart), "week")
                    : null}주
                  ({this.currentForm.dateStart && this.currentForm.expectedEndDate
                  ? moment(this.currentForm.expectedEndDate + "").diff(moment(this.currentForm.dateStart), "day")
                  : null}일)
                </Text>

              </FormGroup>

              <FormGroup label={<strong>VAT 포함정산(해외는N)</strong>}>

                <HTMLSelect
                  value={this.currentForm.vatIncluded ? "Y" : "N"}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    if (e.currentTarget.value === "Y") {
                      this.currentForm.setVatIncluded(true);
                    } else {
                      this.currentForm.setVatIncluded(false);
                    }
                  }}
                  options={["Y", "N"]}
                />
              </FormGroup>

              <Section>
                  <FormGroup label={<strong>매니징시 주의사항</strong>}>
                    {/* <TextArea
                      placeholder="주의사항"
                      fill={true}
                      value={this.currentForm.attention}
                      onChange={this.handleAttentionChange}
                      style={{ minHeight: "200px" }}
                    /> */}
                    <FroalaEditor
                      model={ this.currentForm.attention }
                      config={{
                        ...FroalaConfigs,
                        width: '100%',
                        height: 385     
                      }}
                      onModelChange={(model: string) => {
                        this.currentForm.setAttention(model);
                      }} />
                  </FormGroup>
              </Section>
            </Collapse>
          </Section>
        </FormContainer>

        <Section>
          <FormGroup label={<strong>타이틀</strong>}>
            <InputGroup
              placeholder="제목"
              value={this.currentForm.title}
              onChange={this.handleTitleChange}
            />
          </FormGroup>

            <FormGroup label={<strong>설계 내용</strong>}>
              {/* <TextArea
                placeholder="설계 내용"
                fill={true}
                value={this.currentForm.review}
                onChange={this.handleReviewChange}
                style={{ minHeight: "400px" }}
              /> */}
              <FroalaEditor
                model={ this.currentForm.review }
                config={{
                  ...FroalaConfigs,
                  width: '100%',
                  height: 385     
                }}
                onModelChange={(model: string) => {
                  this.currentForm.setReview(model);
                }} />
            </FormGroup>
        </Section>

        <SprintContainer>
          <PlanResourceEditSection currentForm={ this.currentForm } />
        </SprintContainer>

        {/* <SprintContainer>
          <Section>
            <SprintTableClient currentForm={this.currentForm}/>
          </Section>
        </SprintContainer> */}

        <Section>
          {this.props.mode === FormMode.Create && (
            <ButtonsRow>
              <Button text="취소" onClick={this.handleCancel}/>
              <Button
                text="저장"
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit}
              />
            </ButtonsRow>
          )}

          {this.props.mode === FormMode.Edit && (
            <ButtonsRow>
              <Button text="취소" onClick={this.handleCancel}/>
              <Button
                text="수정사항 적용"
                intent={Intent.PRIMARY}
                onClick={this.handleEditSubmit}
              />
            </ButtonsRow>
          )}
        </Section>

        <Dialog
          isOpen={this.isChangeReasonOpened}
          usePortal={true}
          autoFocus={true}
          enforceFocus={true}
          canEscapeKeyClose={false}
          canOutsideClickClose={false}
          isCloseButtonShown={false}
          title="수정사유 입력"
        >
          <div className={Classes.DIALOG_BODY}>
            <TextArea
              style={{
                width: "100%",
                minHeight: "300px"
              }}
              value={this.currentForm.changeReason}
              onChange={this.handleChangeReasonChange}
              disabled={this.isPending}
            />
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button text="취소" onClick={this.handleChangeReasonClose}/>
              <Button
                text="저장"
                onClick={this.handleSave}
                intent={Intent.PRIMARY}
                loading={this.isPending}
              />
            </div>
          </div>
        </Dialog>
      </Wrapper>
    );
  }
}

export default InspectionFormPanel;
