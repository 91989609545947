import { types, getParent, flow } from "mobx-state-tree";
import { BaseModel } from "./BaseModel";
import axios from "axios";

export const SprintReviewModel = BaseModel.named("SprintReview")
  .props({
    id: types.number,
    createdAt: types.string,
    updatedAt: types.string,
    sprint: types.number,
    creator: types.number,
    creatorName: types.string,
    comment: types.string
  });
type SprintReviewType = typeof SprintReviewModel.Type;
export interface SprintReview extends SprintReviewType {}

export const SprintModel = BaseModel.named("Sprint")
  .props({
    id: types.number,
    projectGroupId: types.string,
    payment: types.number,
    calculatePayment: types.number,
    calculateCommission: types.number,
    commission: types.number,
    serviceFee: types.number,
    status: types.string,
    rufree: types.string,
    rufreeName: types.string,
    role: types.string,
    manager: types.string,
    sprint: types.number,
    dateStart: types.string,
    dateEnd: types.string,
    expectedDateEnd: types.string,
    dateCalculate: types.string,
    comment: types.string,
    reviews: types.array(SprintReviewModel),
  })
  .views(self => ({
    get projectGroup() {
      return getParent(self, 2);
    }
  }))
  .actions(self => {
    const confirm = flow(function*() {
      const { projectGroup } = self;

      if (!projectGroup) {
        throw new Error("PROJECT_GROUP_NOT_FOUND");
      }

      yield axios.post(`/projectGroups/${projectGroup.groupId}/sprints/${self.id}/confirm`, {
      });
    });

    const cancleConfirm = flow(function*() {
      const { projectGroup } = self;

      if (!projectGroup) {
        throw new Error("PROJECT_GROUP_NOT_FOUND");
      }

      yield axios.get(`/projectGroups/${projectGroup.groupId}/sprints/${self.id}/cancle_confirm`);

    });

    const createReview = flow(function*(userId: number, comment: string) {
      const { projectGroup, id } = self;

      if (!projectGroup) {
        throw new Error("PROJECT_GROUP_NOT_FOUND");
      }

      yield axios.post(`/projectGroups/${projectGroup.groupId}/sprints/${id}/review`, {
        sprint: id,
        creator: userId,
        comment: comment
      });
    });

    const updateReview = flow(function*(id: number, comment: string) {
      const { projectGroup } = self;

      if (!projectGroup) {
        throw new Error("PROJECT_GROUP_NOT_FOUND");
      }

      yield axios.patch(`/projectGroups/${projectGroup.groupId}/sprints/${self.id}/review/${id}`, {
        comment: comment,
      });
    });


    return{
      confirm,
      cancleConfirm,
      createReview,
      updateReview
    };
  });

type SprintType = typeof SprintModel.Type;
export interface Sprint extends SprintType {}
