/*
  멤버 회원 상세보기
*/
import React, { useEffect } from 'react';
import { inject, observer } from "mobx-react";
import { RouteComponentProps } from "react-router";
import styled from "styled-components/macro";
import moment from "moment";

import { AppStore } from '../../../../store/AppStore';
import { IMemberStore } from '../../../../store/MemberStore';

import { Spinner, Card, Colors } from '@blueprintjs/core';
import { Comments } from '.';


const Wrapper = styled.div`
  & {
    max-width: 800px;
    h2:first-child {
      margin-block-end: 0;
    }
    h2 + h2 {
      margin-block-start: 0;
    }
    .general > span + span:before {
      content: ' / ';
    }
    .bp3-card {
      h4 {
        color: ${ Colors.BLUE2 };
        margin-block-start: 0;
      }
      div + div {
        margin-top: 5px;
      }
    }
    .bp3-card {
      margin-top: 15px;
    }
    div.reference {
      margin-left: 20px;
      display: flex;
      label {
        width: 100px;
        display: block;
      }
    }
  }
`;
interface IProps extends RouteComponentProps<{code: string;}> {
  appStore: AppStore
}
const SecondteamMemberDetail = inject("appStore")(observer((props: IProps) => {
  const userStore = (props as { appStore: AppStore }).appStore.userStore;
  const store: IMemberStore = (props as { appStore: AppStore }).appStore.memberStore;
  const { member, fetchMember } = store;

  useEffect(() => {
    const fetch = async () => {
      await fetchMember(props.match.params.code);
    }

    fetch();
  }, []);

  if(member === null) {
    return (
      <Spinner />
    )
  }

  return (
    <Wrapper>
      <h2>{ member.code }</h2>
      <h2>{ member.name }</h2><br/>
      <div className='general'>
        <span>{ member.nickname}</span>
        <span>{ member.email }</span>
        <span>{ member.phone }</span>
        <span>{ moment(member.created).format('YYYY-MM-DD') }</span>
        <span>마케팅 정보 수신 동의 여부 : { member.marketing_agreement ? '동의' : '미동의' } </span>
      </div>

      <Card>
        <h4>프로필</h4>
        <Field label='직군 / 직무'>
          { member.job_category.map(job => <span className='job'>{ job.name }</span>) } / 
          { member.job_category.map(job => <span className='job'>{ job.parent }</span>) }
        </Field>
        <Field label='경력'>
          { member.work_experience_year }년 이상
        </Field>
        <Field label='소속'>
          { member.company_name } { member.employmentTypeToString }
        </Field>
        <Field label='테크스텍'>
          <Field label='협업툴'>
            { member.coworking_tools.map(tool => <span>{ tool }</span>) }
          </Field>
          <Field label='개발언어 및 프레임워크'>
            { member.frameworks.map(framework => <span>{ framework }</span>) }
          </Field>
        </Field>
        <Field label='포트폴리오'>
          { member.portfolio &&
            <a href={ member.portfolio.type === 'LINK' ? member.portfolio.link : member.portfolio.file } target='_blank'>
              { member.portfolio.type === 'LINK' ? member.portfolio.link : member.portfolio.name }
            </a>
          }
        </Field>
        <Field label='추가정보'>
          { member.references
            .filter(ref => ref.link)
            .map(ref =>
              <div className='reference'>
                <label>{ ref.display }</label>
                <a href={ ref.link } target='_blank'>{ ref.link }</a>
              </div>
              
          ) }
        </Field>
        <Field label='협업 가능한 시간대'>
        { member.coworkingTimeToString.map(time => <span>{ time }</span>) }
        </Field>
      </Card>

      <Card>
        <h4>맞춤 정보</h4>
        <Field label='구직의향'>
          { member.availabilityToString }
        </Field>
        <Field label='근무 가능한 조건'>
          { member.workTypeToString }
        </Field>
        <Field label='세컨팀 멤버로 기대하는 바'>
          { member.expectationsToString.map(expectation => <span> { expectation }</span>) }
        </Field>
      </Card>

      {/* <Card>
        <h4>초대한 멤버</h4>
      </Card> */}

      <Comments
        currentUserEmail={ userStore.email }
        member={ member }
        fetchMember={ fetchMember } />
    </Wrapper>
  )
}));
export default SecondteamMemberDetail;


const FieldWrapper = styled.div`
  & > & {
    margin-left: 20px;
  }
  & > span + span:before {
    content: ', ';
  }
`;
const Field = (props: any) => {
  return <FieldWrapper>
    <label>{ props.label } : </label>
    { props.children }
  </FieldWrapper>;
}