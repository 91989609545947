import { types, flow } from "mobx-state-tree";
import axios from "axios";
import { DashboardScheduleFormModel } from './DashboardScheduleForm';
import { ScheduleManagerResponse } from "../../../types/scheduleManager";
import { mapSchedule } from "../../ScheduleManagerStore";


export const DashboardScheduleFormStoreModel = types
  .model("DashboardScheduleFormStore", {
    currentForm: types.optional(DashboardScheduleFormModel, {})
  })
  .actions(self => {
    const initForm = (creator: number, creatorName: string) => {
      self.currentForm = DashboardScheduleFormModel.create({
        schCategory: 'vac',
        title: '연차',
        creator: creator,
        creatorName: creatorName,
        creatorPmName: creatorName,
        allDay: true,
        participants: [0]
      });
    };

    const mapFormDataForRequest = () => {
      const form = self.currentForm;
      let obj: any = {
        id: form.id,
        category: form.category ? form.category : 'sch',
        sch_category: form.schCategory,
        start_at: form.startAt,
        end_at: form.endAt ? form.endAt : null,
        all_day: form.allDay,
        title: form.title,
        comment: form.comment,
        creator: form.creator,
        participants: form.participants
      };
      if(form.schCategory === 'vac') {
        obj.end_at = form.endAt
      }
      return obj;
    };


    const postSchedule = flow(function*() {
      try {
        const { data }: { data: ScheduleManagerResponse } = yield axios.post(
          `/schedule`,
          mapFormDataForRequest()
        );

      } catch (e) {
        console.log("postDashboardScheduleForm error", e);
        throw e;
      }
    });

    const putSchedule = flow(function*() {
      try {
        const { data }: { data: ScheduleManagerResponse } = yield axios.put(
          `/schedule/${self.currentForm.id}`,
          mapFormDataForRequest()
        );

      } catch (e) {
        console.log("postDashboardScheduleForm error", e);
        throw e;
      }
    });

    const fetchSchedule = flow(function*(id: number) {
      try {
        const { data }: { data: ScheduleManagerResponse } = yield axios.get(
          `/schedule/${id}`
        );

        const newForm = DashboardScheduleFormModel.create(mapSchedule(data));
        self.currentForm = newForm;

      } catch (e) {
        console.log("fetchSchedule Form error", e);
        throw e;
      }
    });

    // 일정 삭제
    const deleteSchedule = flow(function*() {
      try {
        yield axios.delete(`/schedule/${self.currentForm.id}`);
      } catch (e) {
        console.log("deleteSchedule error", e);
        throw e;
      }
    });    
    return {
      initForm,
      postSchedule,
      fetchSchedule,
      putSchedule,
      deleteSchedule
    };
  });

type DashboardScheduleFormStoreType = typeof DashboardScheduleFormStoreModel.Type;
export interface DashboardScheduleFormStore extends DashboardScheduleFormStoreType {}