import React from "react";
import Cleave from "cleave.js/react";
import { Props as CleaveProps } from "cleave.js/react/props";
import { CleaveOptions } from "cleave.js/options";
import "cleave.js/dist/addons/cleave-phone.kr";

export default (props: CleaveProps) => (
  <div className="bp3-input-group">
    <Cleave className="bp3-input" {...props} />
  </div>
);

export const KoreanCurrencyOptions: CleaveOptions = {
  prefix: "₩ ",
  numeral: true,
  numeralThousandsGroupStyle: "thousand",
  numeralIntegerScale: 9,
  numeralPositiveOnly: true
};
