import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { Popover, Dialog, Classes, FormGroup, Button, Intent, Icon, Colors, HTMLTable, PopoverInteractionKind, Position } from "@blueprintjs/core";
import { Rufree } from "../../../store/models/Rufree";
import { PHASE, STATUS } from "../../../store/models/RufreeMatchingHistory";
import { observable } from "mobx";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import moment from "moment";
import CommonComment from "../../molecules/Common/CommonComment";
import { ConvertLinkTextFormat } from "../../atoms/ConvertLinkTextFormat/ConvertLinkTextFormat";
import {Link} from "react-router-dom";
import { SNS_TYPE } from '../../../constants/constants';
import { getLink } from "../../../utils/url";

const Wrapper = styled.div`
`;

const CommentSection = styled.div``;
const ButtonSection = styled.div`
  button {
    margin-top: 10px;
    margin-right: 5px;
  }
`;
const Title = styled.h3`
  margin-top: 4px;
  margin-bottom: 0px;
`;

interface RufreeInfoPopupProps {
  rufree: Rufree | null;
  onClose: () => void;
}

interface InjectedProps extends RufreeInfoPopupProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class RufreeInfoPopup extends Component<RufreeInfoPopupProps> {
  @observable pmComment = "";

  get injected() {
    return this.props as InjectedProps;
  }

  handleRufreeHomeBtnClick = () => {
    if (this.props.rufree) {
      window.open("https://rufree.kr/members/" + this.props.rufree.uuid, "_blank");
    }
  };

  componentDidUpdate(prevProps: RufreeInfoPopupProps) {
    // Typical usage (don't forget to compare props):
    if (prevProps.rufree && this.props.rufree && this.props.rufree.rufreeId !== prevProps.rufree.rufreeId) {
      console.log(this.props.rufree.pmComment);
      this.pmComment = this.props.rufree.pmComment;
    }
  }

  handleCopyURL = (rufreeId: string, rufreeName: string) => {
    let url = window.location.href;
    url = url.indexOf("?") > 0 ? url.substring(0, url.indexOf("?")) : url;
    const link = `${url}?rufree_id=${rufreeId}`;

    let tempElem = document.createElement("textarea");
    tempElem.value = link;
    document.body.appendChild(tempElem);

    tempElem.select();
    document.execCommand("copy");
    document.body.removeChild(tempElem);

    AppToaster.show({
      message: `알유프리 [${rufreeId}] ${rufreeName}님의 바로 가기 링크가 복사되었습니다`,
      intent: Intent.SUCCESS
    });
  }

  handleAddComment = async (comment: string) => {
    const { rufree } = this.props;
    if (rufree) {
      try {
        await this.injected.appStore.rufreeStore.addComment(rufree.rufreeId, comment);

        AppToaster.show({
          message: "코멘트가 저장되었습니다.",
          intent: Intent.SUCCESS
        });
      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }
    }
  };
  handleUpdateComment = async (id: number, comment: string) => {
    const { rufree } = this.props;
    if (rufree) {
      try {
        await this.injected.appStore.rufreeStore.updateComment(rufree.rufreeId, id, comment);

        AppToaster.show({
          message: "코멘트가 수정되었습니다.",
          intent: Intent.SUCCESS
        });

      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }
    }
  };
  handleDeleteComment = async (id: number) => {
    const { rufree } = this.props;

    if (rufree) {
      try {
        await this.injected.appStore.rufreeStore.deleteComment(rufree.rufreeId, id);

        AppToaster.show({
          message: "코멘트가 삭제되었습니다.",
          intent: Intent.SUCCESS
        });

      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }
    }
  };
  render() {
    const { rufree, onClose } = this.props;

    return (
      <Dialog
        icon="mugshot"
        onClose={onClose}
        title={rufree && rufree.name}
        isOpen={rufree !== null}
        usePortal={true}
        autoFocus={true}
        canEscapeKeyClose={true}
        enforceFocus={true}
      >
        <div
          className={Classes.DIALOG_BODY}>
          {rufree && (
            <Wrapper>
              <Button
                  text={'바로가기 링크 복사'}
                  icon={<Icon icon={'link'} iconSize={12} color={Colors.GREEN3} />}
                  // outlined={true}
                  small={true}
                  style={{
                    fontSize: '12px'
                  }}
                  onClick={ (e: any) => {
                    e.stopPropagation();
                    this.handleCopyURL(rufree.rufreeId, rufree.name);
                  }
                  } />
              <HTMLTable
               bordered={true}
               condensed={true}
               interactive={true}
               striped={true}
               style={{ width: "100%", textAlign: 'center', marginTop: '10px'}}
              >
                <thead>
                <tr>
                  <th>번호</th>
                  <th>이름</th>
                  <th>이메일</th>
                  <th>연락처</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>{ rufree.rufreeId }</td>
                  <td>{ rufree.name }</td>
                  <td>{ rufree.email }</td>
                  <td>{ rufree.phone }</td>
                </tr>
                </tbody>
              </HTMLTable>
              {/*<FormGroup label={<strong>알유프리번호</strong>}>*/}
              {/*  {rufree.rufreeId}*/}
              {/*  <Button*/}
              {/*    text={'바로가기 링크 복사'}*/}
              {/*    icon={<Icon icon={'link'} iconSize={12} color={Colors.GREEN3} />}*/}
              {/*    // outlined={true}*/}
              {/*    small={true}*/}
              {/*    style={{*/}
              {/*      fontSize: '12px',*/}
              {/*      marginLeft: '15px'*/}
              {/*    }}*/}
              {/*    onClick={ (e: any) => {*/}
              {/*      e.stopPropagation();*/}
              {/*      this.handleCopyURL(rufree.rufreeId, rufree.name);*/}
              {/*    }*/}
              {/*    } />*/}
              {/*</FormGroup>*/}

              <FormGroup label={<Title style={{ marginTop: '25px' }}>프로젝트 제안받기</Title>}>
                {rufree.getOffer ? (<span  style={{ 'fontWeight': 'bold', 'color': Colors.GREEN3}}>Yes</span>) : (<span style={{ 'fontWeight': 'bold', 'color': Colors.RED3}}>No</span>)}
              </FormGroup>
              <FormGroup label={<Title>가입일</Title>}>
                {moment(rufree.createdAt).format("YYYY-MM-DD")}
              </FormGroup>
              {/*<FormGroup label={<strong>이름</strong>}>{rufree.name}</FormGroup>*/}
              {/*<FormGroup label={<strong>이메일</strong>}>*/}
              {/*  {rufree.email}*/}
              {/*</FormGroup>*/}
              {/*<FormGroup label={<strong>연락처</strong>}>*/}
              {/*  {rufree.phone}*/}
              {/*</FormGroup>*/}
              <FormGroup label={<Title>주소</Title>}>
                {rufree.address}
              </FormGroup>
              <FormGroup label={<Title>현재 소속</Title>}>
                {rufree.affiliation}
              </FormGroup>
              <FormGroup label={<Title>시소에서 진행한 프로젝트</Title>}>
                { rufree.projectGroupHistory.length > 0 ? rufree.projectGroupHistory.map(history => (
                    <div key={history.pgId} style={{ padding: "3px"}}>
                      <Link to={`/project-groups/${history.pgId}`} target="_blank">
                        { `${ history.pgName }` }
                      </Link>
                      <span>{ ` (${ history.role })` }</span>
                      <span style={{ color: "gray", fontSize: "10px" }}>{ history.dateStart && ` ${ history.dateStart } ~ ${ history.dateComplete }` }</span>
                    </div>
                )) : <span>없음</span>}
              </FormGroup>
              <FormGroup label={<Title>매칭 히스토리</Title>}>
                { rufree.matchingHistory.length > 0 ?
                  rufree.matchingHistory.map(history => (
                    <div key={history.pgId} style={{ padding: "3px"}}>
                      <Link to={`/project-groups/${history.pgId}`} target="_blank">
                        { `${ history.pgName }` }
                      </Link>
                      <span>{ ` (${ history.role }): ` }</span>
                      <span>
                        { STATUS[history.phase]['status'] }
                        { history.phase === PHASE.submitted && history.dateSubmitted }
                        { history.phase === PHASE.accepted && history.dateAccepted && `(${ moment(history.dateAccepted).format("YYYY-MM-DD") })` }
                        { history.phase === PHASE.rejected && `(${ history.rejectReason })` }
                        { history.phase === PHASE.expired && history.dateExpired }
                        { history.phase === PHASE.matched && history.dateMatched }
                        { history.phase === PHASE.declined && history.dateDeclined }
                        { history.phase === PHASE.canceled && history.dateCanceled }
                      </span>
                        { history.comments && history.comments.length > 0 &&
                          <Popover
                              interactionKind={PopoverInteractionKind.HOVER}
                              popoverClassName="bp3-popover-content-sizing"
                              position={Position.RIGHT}
                          >
                            <Icon icon={'chat'} iconSize={16} style={{color: "#687bd8", marginLeft: "5px"}}></Icon>
                            <div>
                              { history.comments.map(comment => (
                                  <p>
                                    { `[${comment.creator}] ${comment.comment}` }
                                    <span style={{float: "right"}}>{ moment(comment.createdAt).format("YYYY-MM-DD HH:MM") }</span>
                                  </p>
                              ))}
                            </div>
                          </Popover>
                        }
                    </div>
                  )) :
                  <span>없음</span>
                }
              </FormGroup>
              <FormGroup label={<Title>주요경력</Title>}>
                { rufree.experiences.length > 0 ? rufree.experiences.map((exp, idx) => (
                  <FormGroup key={exp.title}>
                    <div>{ `${ idx + 1 }. ${ exp.title }`}</div>
                    { exp.role && <div>{`수행역할 - ${exp.role}`}</div> }
                    <div>
                      <span>{ exp.startAt }</span>
                      <span>{ exp.startAt && (exp.endAt ? ` - ${exp.endAt}` :`- current`)}</span>
                    </div>
                    <div style={{ whiteSpace: "pre-line" }}>
                      <ConvertLinkTextFormat>{exp.detail}</ConvertLinkTextFormat>
                    </div>
                    { exp.outcomeAos &&
                      <a href={ exp.outcomeAos } target="_blank">
                        <Button
                            text={`안드로이드 앱 다운로드 링크`}
                            small={true}
                            intent={Intent.PRIMARY}
                            style={{ marginRight: "5px" }}
                        />
                      </a> }
                    { exp.outcomeIos &&
                    <a href={ exp.outcomeIos } target="_blank">
                      <Button
                          text={`iOS 앱 다운로드 링크`}
                          small={true}
                          intent={Intent.PRIMARY}
                          style={{ marginRight: "5px" }}
                      />
                    </a> }
                    { exp.outcomeWeb &&
                    <a href={ exp.outcomeWeb } target="_blank">
                      <Button
                          text={`웹사이트 링크`}
                          small={true}
                          intent={Intent.PRIMARY}
                          style={{ marginRight: "5px" }}
                      />
                    </a> }
                  </FormGroup>
                )) : <span>없음</span> }
              </FormGroup>
              <FormGroup label={<Title>작업가능시간</Title>}>
                {rufree.workType}
              </FormGroup>
              <FormGroup label={<Title>포트폴리오</Title>}>
                { (rufree.portfolio && rufree.portfolio.type) ? (rufree.portfolio.type === 'LINK' ?
                    <a href={ rufree.portfolio.link ? getLink(rufree.portfolio.link) : '' } target="_blank">
                      <Button
                          text={`포트폴리오 링크`}
                          small={true}
                          intent={Intent.PRIMARY}
                          style={{ marginRight: "5px" }}
                      />
                    </a> :
                    <a href={ rufree.portfolio.file ? rufree.portfolio.file : '' } target="_blank">
                      <Button
                          text={`포트폴리오 파일`}
                          small={true}
                          intent={Intent.PRIMARY}
                          style={{ marginRight: "5px" }}
                      />
                    </a>) : ''
                }
              </FormGroup>

              <FormGroup label={<Title>주분야</Title>}>
                { rufree.mainSkills.join(', ') }
                {/*{rufree.mainSkills.map(mainSkill => (*/}
                {/*  <div key={mainSkill}>{mainSkill}</div>*/}
                {/*))}*/}
              </FormGroup>
              <FormGroup label={<Title>보조 분야</Title>}>
                { rufree.subSkills.join(', ') }
                {/*{rufree.subSkills.map(subSkill => (*/}
                {/*  <div key={subSkill}>{subSkill}</div>*/}
                {/*))}*/}
              </FormGroup>
              <FormGroup label={<Title>개발언어 및 프레임워크</Title>}>
                { rufree.languages.join(', ') }
                {/*{rufree.languages.map(language => (*/}
                {/*  <div key={language}>{language}</div>*/}
                {/*))}*/}
              </FormGroup>
              <FormGroup label={<Title>사용 툴</Title>}>
                { rufree.tools.join(', ') }
                {/*{rufree.tools.map(tool => (*/}
                {/*  <div key={tool}>{tool}</div>*/}
                {/*))}*/}
              </FormGroup>
              <FormGroup label={<Title>협업 툴 정보</Title>}>
                { rufree.toolAccounts.map(tool => {
                   if(tool.account){
                     return <div key={tool.tool}>{tool.label}: {tool.account}</div> }
                })}
              </FormGroup>
              { rufree.tags &&
                <FormGroup label={<Title>나를 소개하는 키워드 3가지</Title>}>
                  { rufree.tags.map((tag, index) =>
                      <span key={ index }>{ `#${ tag } ` }</span>
                  )}
                </FormGroup>
              }
              { rufree.references.length > 0 &&
                <FormGroup label={<Title>참고자료</Title>}>
                  <div className="sns-w">
                  <ul style={{ margin: '0' }}>
                    {
                      rufree.references.map((ref, index) => {
                        let snsType = {
                          'name': '',
                          'keyword': ''
                        };
                        Object.entries(SNS_TYPE).forEach(([key, value]) => {
                          if(ref.includes(value.keyword)){
                            snsType = value;
                          }
                        });

                        return <li key={ref}><a href={ getLink(ref) } target="_blank"><span>{ snsType.name ? snsType.name : ref }</span></a></li>
                      })
                    }
                  </ul>
                </div>
                </FormGroup>
              }
              <FormGroup label={<Title>추천인 혹은 알게된 경로</Title>}>
                {rufree.recommender}
              </FormGroup>
              <FormGroup label={<Title>선호 시간</Title>}>
                { rufree.workingTime.join(', ') }
              </FormGroup>
              <FormGroup label={<Title>선호 업무방식</Title>}>
                { rufree.workingStyle }
              </FormGroup>

              <FormGroup label={<Title>알유프리 인터뷰</Title>}>
                {rufree.interview ? rufree.interview : '없음'}
              </FormGroup>

              { rufree.followee.length > 0 &&
                <FormGroup label={<Title>함께 협업하고 싶은 멤버</Title>}>
                  <ul style={{ margin: '0' }}>
                    {rufree.followee.map((member, index) =>
                      <li>
                        <Link to={`/rufrees?uuid=${member.uuid}`} target="_blank">
                          { `${ member.name }(${ member.rufreeId })` }
                        </Link>
                      </li>
                      )}
                  </ul>
                </FormGroup>
              }

              <CommentSection>
                <div className={Classes.CARD} style={{ marginTop: "15px", paddingBottom: "45px" }}>
                  <CommonComment
                    comments={rufree.comments}
                    onAddComment={this.handleAddComment}
                    onDeleteComment={this.handleDeleteComment}
                    onUpdateComment={this.handleUpdateComment}
                  />
                </div>
              </CommentSection>

              <ButtonSection>
                <Button intent={Intent.PRIMARY} text="알유프리 유저페이지 바로가기" onClick={this.handleRufreeHomeBtnClick}/>
                <Button text="닫기" onClick={onClose}/>
              </ButtonSection>

            </Wrapper>
          )}
        </div>
      </Dialog>
    );
  }
}

export default RufreeInfoPopup;
