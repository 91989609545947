import React, { Component, FormEvent } from "react";
import { RouteComponentProps } from "react-router";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { ChangeEvent } from "cleave.js/react/props";
import { AppStore } from "../../../store/AppStore";
import { observable } from "mobx";
import { 
  Button,
  Classes,
  HTMLTable,
  Intent,
  InputGroup,
  HTMLSelect,
  Spinner
} from "@blueprintjs/core";
import moment from "moment";

export const ToolsRow = styled.div`
  margin-bottom: 30px;
  display: flex;
  height: 20px;

  & > * + * {
    margin-left: 10px;
  }
`;
const InputContainer = styled.div`
  max-width: 120px;
  margin-bottom: 30px;
`;


interface ContractTemplateListPanelProps extends RouteComponentProps {
  isSupportPM: boolean;
}

interface InjectedProps extends ContractTemplateListPanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ContractTemplateListPanel extends Component<ContractTemplateListPanelProps> {
  @observable readyToShow = false;

  get injected() {
    return this.props as InjectedProps;
  }

  get contractStore() {
    return this.injected.appStore.contractStore;
  }

  fetchList = async () => {
    this.readyToShow = false;

    try {
      await this.contractStore.fetchTemplates();
    } catch (e) {}

    this.readyToShow = true;
  };

  handleTemplateCreateButtonClick = () => {
    this.props.history.push("/contract-templates/new");
  }
  handleRowClick = (id: number) => {
    this.props.history.push(`/contract-templates/${id}/read`);
  }
  handleEditButtonClick = (id: number) => {
    this.props.history.push(`/contract-templates/${id}/edit`);
  }

  async componentDidMount() {
    this.readyToShow = false;

    await this.fetchList();
  }

  render() {
    const { handleRowClick, handleEditButtonClick } = this;
    const { sortedTemplates } = this.contractStore;

    return (
      <>
        <ToolsRow>
          <Button
            text="새 계약 양식"
            onClick={this.handleTemplateCreateButtonClick}
            intent={Intent.PRIMARY}
          />
        </ToolsRow>

        <HTMLTable
          className={Classes.SMALL}
          striped={true}
          interactive={true}
          style={{ width: "100%", tableLayout: "fixed" }}
        >
          <thead>
            <tr>
              <th style={{width: '80px'}}>번호</th>
              <th>양식</th>
              <th style={{width: '120px'}}>수정일</th>
              <th style={{width: '90px'}}></th>
            </tr>
          </thead>

          <tbody>
            {
              this.readyToShow ? (
                sortedTemplates.map(template => (
                  <tr
                    key={template.template_id}
                    onClick={() => handleRowClick(template.id)}
                  >
                    <td>{template.template_id}</td>
                    <td>{template.template_name}</td>
                    <td>{moment(template.updated_at).format('YYYY-MM-DD')}</td>
                    <td>
                    {
                      // <Button
                      //   text="수정하기"
                      //   onClick={() => handleEditButtonClick(template.id)}
                      //   intent={Intent.PRIMARY}
                      //   minimal={true} small={true}
                      // />
                    }
                    </td>
                  </tr>
                ))) : (
                  <tr>
                  <td colSpan={4}><Spinner /></td>
                </tr>
              )
            }
          </tbody>
        </HTMLTable>
      </>
    );
  }
}

export default ContractTemplateListPanel;
