import React, { Component, ChangeEvent } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import {H3, InputGroup, Button, Intent, HTMLTable, Icon, Spinner} from "@blueprintjs/core";
import { observable, reaction } from "mobx";
import { RouteComponentProps } from "react-router";

import Paginator from "../../molecules/Paginator/Paginator";
import RufreeDashboardPanel from "../../panels/RufreeDashboardPanel/RufreeDashboardPanel";
import LargeContainer from "../../atoms/LargeContainer/LargeContainer";
import moment from "moment";

const Wrapper = styled.div`
  th.center {
    text-align: center;
  }
  td.center {
    text-align: center;
  }

  th.order-by {
    width: 80px;
    cursor: pointer;
    color: #106ba3;

    .active {
      text-decoration: underline;
    }
  }

  th.order-by.active {
    text-decoration: underline;
  }
`;

const ButtonsRow = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
`;

const ToolsRow = styled.div`
  margin-bottom: 40px;
  display: flex;
  height: 20px;

  & > * + * {
    margin-left: 10px;
  }
`;

const InputContainer = styled.div`
  max-width: 300px;
  margin-bottom: 30px;
`;

const TabRow = styled.div`
  display: flex;

  & > * + * {
    margin-left: 30px;
  }
`;

const Tab = styled.span<{ selected: boolean }>`
  font-size: 20px;
  font-weight: ${p => (p.selected ? "bold" : "normal")};
  cursor: pointer;
`;

const OrderByIcon = styled(Icon)`
  float: right;
  margin-top: 3px;
`;

interface PageParams {
  tab: string;
}
interface RufreeMatchingListPageProps extends RouteComponentProps<PageParams> {
}

interface InjectedProps extends RufreeMatchingListPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class RufreeMatchingListPage extends Component<RufreeMatchingListPageProps> {
  @observable readyToShow = false;
  @observable currentTab = "대시보드";
  @observable manager = "";
  @observable projectGroupId = "";
  @observable projectName = "";
  @observable role = "";
  @observable searchInput = "";
  @observable orderBy: string = 'id';
  @observable orderAsc: boolean = false;

  get injected() {
    return this.props as InjectedProps;
  }

  async componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const tab = query.get("tab")
    
    if(tab){
      this.currentTab = String(tab);
      this.props.location.search="?tab=ddd"
    }

    await this.fetchList();

    reaction(
      () => this.currentTab,
      () => {
        this.fetchList();
      }
    );
  }

  handleChangeTab = (tab: string) => {
    this.currentTab = tab;
  };

  handleSearchText = (e: ChangeEvent<HTMLInputElement>) => {
    this.searchInput = e.target.value;
  };

  handleSearch = async () => {
    try {
      await this.injected.appStore.rufreeMatchingStore.searchQueryMatchings({
        searchInput: this.searchInput,
        status: this.currentTab,
        orderBy: this.orderBy,
        orderAsc: this.orderAsc
      });
    } catch (e) {
    }
  };

  fetchList = async () => {
    try {
      this.readyToShow = false;
      await this.injected.appStore.rufreeMatchingStore.searchMatchings({
        projectGroup: this.projectGroupId,
        projectName: this.projectName,
        manager: this.manager,
        role: this.role,
        status: this.currentTab == "대시보드" ? "" : this.currentTab
      });
    } catch (e) {
    } finally {
      this.readyToShow = true;
    }
  };

  handleRowClick = ({ matchingId }: { matchingId: number }) => {
    this.props.history.push({
      pathname: `/rufree-matchings/${matchingId}`
    });
  };

  handleOrderBy = async (key: string, asc: boolean=false) => {
    this.orderAsc = this.orderBy === key ? asc : false;
    this.orderBy = key;

    await this.handleSearch();
  }

  calDuration = (matching: any) => {
    const startTime = moment(matching.dateRequest, "YYYY-MM-DD");
    let endTime;
    let hours = "";
    if (!matching.matchedTime && matching.matchedRufree) { // 매칭이 되었어지만 매칭시간이 없는 경우(기존)
      return "";
    } else if (matching.matchedTime && matching.matchedRufree) {
      endTime = moment(matching.matchedTime, "YYYY-MM-DD");
    } else {
      endTime = moment(new Date());
    }
    const duration = moment.duration(endTime.diff(startTime));
    Number(duration.asHours().toFixed(2)) > 24 ? hours = `${(duration.asHours() / 24).toFixed()}일` : hours = `< 1일`;
    if (matching.status == "취소") {
      hours = "-";
    }
    return hours;
  };

  convertStatus = (matching: any) => {
    const status = matching.status;
    let statusTxt;
    switch (status) {
      case "취소":
        statusTxt = (<span style={{ color: "red" }}>DROP</span>);
        break;
      case "매칭완료":
        statusTxt = (
          <span
            style={{ color: "#000" }}>매칭성공<br/>({`${matching.matchedRufree} ${matching.matchedRufreeName}`})</span>);
        break;
      case "추천요청":
        statusTxt = (<span style={{ color: "#2965CC" }}>추천받는중</span>);
        break;
      case "수정요망":
        break;
      case "제안중": // 완료
        let rufreeAccept = false;
        const matchingHistory = Object.assign({}, matching.matchingHistory);
        const matchingHistoriesList = Object.keys(matchingHistory).map(function(key) {
          if (matchingHistory[key].isAccepted && rufreeAccept === false) { // 확정대기중 사용하기 위함(한명이라도 수락했을 때)
            rufreeAccept = true;
          }
          return matchingHistory[key];
        });
        !rufreeAccept ? statusTxt = (<span style={{ color: "#00aa00" }}>제안중({matchingHistoriesList.length}명)</span>) :
          statusTxt = (<span style={{ color: "#00aa00" }}>확정대기중</span>);
        break;
      case "요청대기" :
        statusTxt = (<span style={{ color: "#FFA500" }}>요청대기</span>);
        break;
      case "작성중": // 완료
        statusTxt = (<span style={{ color: "#FF5F1E" }}>작성중</span>);
        break;
      default:
        statusTxt = status;
    }
    return statusTxt;
  };

  LinkToSimpleMatching = () => {
    alert("단순소개 페이지 완성후 해당페이지로 연결됩니다");
  };

  handleSearchKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      await this.handleSearch();
    }
  };

  render() {
    const { rufreeMatchings, pageGroup, totalPages, currentPage, setPageGroup, setCurentPage } = this.injected.appStore.rufreeMatchingStore;
    
    const OrderIcon = <OrderByIcon
      icon={this.orderAsc ? 'caret-up' : 'caret-down'}
      intent={Intent.PRIMARY} />;
    
    return (
      <LargeContainer>
      <Wrapper>
        <H3>알유프리 매칭</H3>
        <ButtonsRow>
          <Button
            icon="document"
            text="단순소개"
            intent={Intent.PRIMARY}
            onClick={this.LinkToSimpleMatching}
          />
        </ButtonsRow>
        <ButtonsRow>
          <TabRow>
            <Tab
              selected={this.currentTab === "대시보드"}
              onClick={() => this.handleChangeTab("대시보드")}
            >
              대시보드
            </Tab>
            <Tab
              selected={this.currentTab === "제안전"}
              onClick={() => this.handleChangeTab("제안전")}
            >
              제안전
            </Tab>
            <Tab
              selected={this.currentTab === "제안중"}
              onClick={() => this.handleChangeTab("제안중")}
            >
              제안중
            </Tab>
            <Tab
              selected={this.currentTab === "완료"}
              onClick={() => this.handleChangeTab("완료")}
            >
              완료
            </Tab>
          </TabRow>
        </ButtonsRow>

        <ToolsRow>
          <InputContainer
          >
            <InputGroup
              placeholder="요청자, 그룹번호, 프로젝트명, 역할명"
              style={{ width: "300px" }}
              value={this.searchInput}
              onChange={this.handleSearchText}
              onKeyDown={this.handleSearchKeyDown}
            />
          </InputContainer>
          <Button
            icon="search"
            text="검색"
            intent={Intent.PRIMARY}
            onClick={this.handleSearch}
          />
        </ToolsRow>
        
        {this.currentTab === "대시보드" ? (
          <RufreeDashboardPanel handleRowClick={this.handleRowClick} {...this.props}/> ) : (
            !this.readyToShow ? (
                <Spinner />) : (
                <>
                  <HTMLTable
                      bordered={true}
                      interactive={true}
                      style={{ width: "100%", tableLayout: "fixed", border: "1px solid #dcdcdd" }}
                  >
                    <thead style={{ backgroundColor: "#e8e8e8" }}>
                    <tr>
                      <th
                          className={"center order-by" + (this.orderBy === 'id' ? " active" : "")}
                          style={{ width: '80px', cursor: 'pointer', color: '#106ba3' }}
                          onClick={() => {this.handleOrderBy('id', !this.orderAsc)}}
                      >
                        ID { this.orderBy === 'id' ? OrderIcon : <Icon icon="double-caret-vertical"/> }
                      </th>
                      <th className={"center"}>요청자</th>
                      <th className={"center"}>타입</th>
                      <th className={"center"}>프로젝트그룹<br></br>번호</th>
                      <th className={"center"}>프로젝트그룹<br></br>이름</th>
                      <th className={"center"}>역할</th>
                      <th
                          className={"center order-by" + (this.orderBy === 'date_due' ? " active" : "")}
                          onClick={() => {this.handleOrderBy('date_due', !this.orderAsc)}}
                      >
                        희망일 { this.orderBy === 'date_due' ? OrderIcon : <Icon icon="double-caret-vertical"/> }
                      </th>
                      <th
                          className={"center order-by" + (this.orderBy === 'created_at' ? " active" : "")}
                          onClick={() => {this.handleOrderBy('created_at', !this.orderAsc)}}
                      >
                        요청일 { this.orderBy === 'created_at' ? OrderIcon : <Icon icon="double-caret-vertical"/> }
                      </th>
                      {this.currentTab === "완료" && (
                          <th
                              className={"center order-by" + (this.orderBy === 'matched_time' ? " active" : "")}
                              onClick={() => {this.handleOrderBy('matched_time', !this.orderAsc)}}
                          >
                            확정일 { this.orderBy === 'matched_time' ? OrderIcon : <Icon icon="double-caret-vertical"/> }
                          </th>
                      )}
                      <th className={"center"}>소요시간</th>
                      <th className={"center"}>상태</th>
                    </tr>
                    </thead>
                    <tbody>
                    {rufreeMatchings.map(matching => (
                        <tr
                            key={matching.id}
                            onClick={() =>
                                this.handleRowClick({
                                  matchingId: matching.id
                                })
                            }
                        >
                          <td className={"center"}>{ matching.id }</td>
                          <td className={"center"}>{matching.manager}</td>
                          <td className={"center"}>{matching.macthingType ? "단순소개" : "프로젝트그룹 "}</td>
                          {/* 타입이 매칭요청인 경우 클라번호가 뜨도록 */}
                          <td className={"center"}>{matching.projectGroup}</td>
                          {/* 타입이 매칭요청인 경우 클라번호가 뜨도록 */}
                          <td className={"center"}>{matching.projectGroupName}</td>
                          {/* 타입이 매칭요청인 경우 클라번호가 뜨도록 */}
                          <td className={"center"}>{matching.role}</td>
                          <td className={"center"}>{matching.dateDue}</td>
                          <td className={"center"}>{moment(matching.createdAt).format("YYYY-MM-DD")}</td>
                          {this.currentTab === "완료" && (<td className={"center"}>{matching.matchedTime ? moment(matching.matchedTime).format("YYYY-MM-DD") : ''}</td>)}
                          <td className={"center"}>{this.calDuration(matching)} </td>
                          <td className={"center"}>{this.convertStatus(matching)} </td>
                        </tr>
                    ))}
                    </tbody>
                  </HTMLTable>

                  { rufreeMatchings.length > 0 && (
                      <Paginator
                          pageGroup={pageGroup}
                          totalPages={totalPages}
                          currentPage={currentPage}
                          setPageGroup={setPageGroup}
                          setCurentPage={setCurentPage}
                          onFetch={() => this.handleSearch()}
                      />
                  )}
                </>
            )
        )}
      </Wrapper>
      </LargeContainer>
    );
  }
}

export default RufreeMatchingListPage;
