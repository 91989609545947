import React, { Component, FC } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { computed, observable } from "mobx";
import { Intent, Spinner, H5, HTMLTable, Classes, Icon } from "@blueprintjs/core";
import { ITransaction } from "../../../store/models/CalculateByClient";

import moment from "moment";

const Wrapper = styled.div`
  table {
    margin-top: 30px;
  }
  th:nth-child(1) {
    width: 130px;
  }
  th:nth-child(3), th:nth-child(4), th:nth-child(5) {
    width: 120px;
    text-align: center;
  }
  td:nth-child(3), td:nth-child(4), td:nth-child(5) {
    text-align: right;
  }
  th:nth-child(6) {
    width: 400px;
  }
`;

const OrderIcon: FC<{
  asc: boolean,
  handleClick: () => void
}> = ({ asc, handleClick }) => {
  return (
    <Icon
      icon={asc ? 'arrow-up' : 'arrow-down'}
      iconSize={12}
      intent={Intent.PRIMARY}
      style={{
        float: 'right',
        marginTop: '3px',
        cursor: 'pointer'
      }}
      onClick={ handleClick } />
  )
};
const AmountComponent: FC<{
  amount: number | undefined,
  title: string
}> = ({ amount, title }) => {
  return (
    <H5>{ title } : 
      <span className={ amount !== undefined ? '' : Classes.SKELETON}>
        ₩ { amount !== undefined && amount.toLocaleString("en-US") }
      </span>
    </H5>
  )
};




interface ITransactionWithBalance extends ITransaction {
  balance: number;
}
interface ClientCalculatePanelProps {
  clientId: string;
}
interface InjectedProps extends ClientCalculatePanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ClientCalculatePanel extends Component<ClientCalculatePanelProps> {
  @observable order_by = 'date';
  @observable order_asc = false;

  get injected() {
    return this.props as InjectedProps;
  }

  get calculateStore() {
    return this.injected.appStore.calculateStore;
  }

  @computed
  get calculate() {
    return this.calculateStore.calculateByClient
      .find(calculate => calculate.clientId === this.props.clientId);
  }

  @computed
  get transactions() {
    const calculate = this.calculate;

    if(calculate) {
      let transactions: ITransactionWithBalance[] = calculate.transactionHistory;

      if(this.order_asc) {
        return transactions.sort((a, b) => {
          return moment(a.id).diff(b.id);
        }).sort((a, b) => {
          return moment(a.date).diff(b.date);
        });
      } else{
        return transactions.sort((a, b) => {
          return moment(b.id).diff(a.id);
        }).sort((a, b) => {
          return moment(b.date).diff(a.date);
        });
      }
    }

    return [];
  }

  async componentDidMount() {
    if(!this.calculate) {
      this.calculateStore.fetchCalculateByClient();
    }
  }

  render() {
    return (
      <Wrapper>
        <AmountComponent
          title={'누적 입금액'}
          amount={ this.calculate ? this.calculate.depositAmountWithVAT : undefined } />
        <AmountComponent
          title={'누적 출금액'}
          amount={ this.calculate ? this.calculate.payoutAmount : undefined } />
        <AmountComponent
          title={'잔액(충전금)'}
          amount={ this.calculate ? this.calculate.prePaid : undefined } />

        <HTMLTable
          className={Classes.SMALL}
          striped={true}
          interactive={true}
          style={{ width: "100%", tableLayout: "fixed" }}
        >
          <thead>
            <tr>
              <th>
                거래일자
                <OrderIcon
                  asc={this.order_asc}
                  handleClick={ () => {
                    this.order_asc = !this.order_asc;
                  } } />
              </th>
              <th>내용</th>
              <th>입금액</th>
              <th>출금액</th>
              <th>잔액(충전금)</th>
              <th>비고</th>
            </tr>
          </thead>

          <tbody>

          {
            this.calculate ?
              this.transactions
                .map( (transaction: ITransactionWithBalance, i) => {
                  
                  return <tr key={i}>
                    <td>{ transaction.date ? moment(transaction.date).format('YYYY-MM-DD') : '' }</td>
                    <td>{ transaction.title }</td>
                    <td>{ transaction.isDeposit && transaction.amountWithVAT.toLocaleString("en-US") }</td>
                    <td>{ transaction.isPayout && transaction.amount.toLocaleString("en-US") }</td>
                    <td>{ transaction.balance.toLocaleString("en-US") }</td>
                    <td>{ transaction.comment }</td>
                  </tr>
                })
                
              : <tr>
                <td colSpan={6}>
                  <Spinner 
                    intent={Intent.PRIMARY}
                    size={18} />
                </td>
              </tr>
          }
        </tbody>
        </HTMLTable>
        
      </Wrapper>
    );
  }
}

export default ClientCalculatePanel;
