import React, { ChangeEvent, Component, KeyboardEvent } from "react";
import { AppStore } from "../../../store/AppStore";
import { inject, observer } from "mobx-react";
import { SubTaskListForm } from "../../../store/forms/TaskManagerForm/SubTaskListForm";
import { computed, observable } from "mobx";
import { GreyLabel } from "../../atoms/DetailReportComponents/DetailReportComponents";
import { Alert, Button, InputGroup, Intent, ProgressBar } from "@blueprintjs/core";
import SubTaskView from "./SubTaskView";
import AddSubTaskListForm from "./AddSubTaskListForm";
import sortBy from "lodash/sortBy";
import {
  Droppable, DroppableProvided, DroppableStateSnapshot,
  Draggable, DraggableProvided, DraggableStateSnapshot
} from "react-beautiful-dnd";

interface Props {
  subTaskList: SubTaskListForm;
  disabled: boolean | undefined;
}

interface InjectedProps extends Props {
  appStore: AppStore;
}

@inject("appStore")
@observer
class SubTaskListItemView extends Component<Props> {
  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get formStore() {
    return this.injected.appStore.taskManagerTaskFormStore;
  }

  @computed
  get currentForm() {
    return this.formStore.currentForm;
  }

  @observable onTitleHover = false;
  @observable onTitleEdit = false;
  @observable onTitleDelete = false;

  @observable titleTemp = "";

  render() {
    const { subTaskList, disabled } = this.props;
    const completeCount = subTaskList.subTasks.filter((subTask) => subTask.checked).length;
    const allCount = subTaskList.subTasks.length;

    return (
      <div key={subTaskList.id}>
        <GreyLabel style={{ lineHeight: "34px" }}
                   onMouseEnter={() => {
                     if (!disabled)
                       this.onTitleHover = true;
                   }}
                   onMouseLeave={() => this.onTitleHover = false}>
          {this.onTitleEdit ?
            <div style={{ width: "200px", display: "inline-block" }}>
              <InputGroup value={this.titleTemp}
                          autoFocus
                          disabled={disabled}
                          onFocus={() => this.titleTemp = subTaskList.title}
                          onBlur={async () => {
                            const data = await this.formStore.updateSubTaskList({
                              "id": subTaskList.id,
                              "title": this.titleTemp
                            }, "patch");
                            data && subTaskList.setTitle(data.title);
                            this.onTitleEdit = false;
                          }}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            this.titleTemp = event.target.value;
                          }}
                          onKeyPress={async (event) => {
                            if (event.key === "Enter") {
                              event.preventDefault();
                              event.stopPropagation();
                              // subTaskList.setTitle(this.titleTemp);
                              const data = await this.formStore.updateSubTaskList({
                                "id": subTaskList.id,
                                "title": this.titleTemp
                              }, "patch");
                              data && subTaskList.setTitle(data.title);
                              this.onTitleEdit = false;

                            }
                          }}
              />
            </div> :
            <span>{subTaskList.title}</span>
          }
          {(this.onTitleHover && !this.onTitleEdit) &&
          <div style={{ display: "inline-block" }}>
            <Button
              icon="edit"
              minimal={true}
              onClick={(e: any) => {
                this.onTitleEdit = !this.onTitleEdit;
                e.stopPropagation();
              }}/>
            <Button
              icon="trash"
              minimal={true}
              onClick={(e: any) => {
                this.onTitleDelete = true;
                e.stopPropagation();
              }}/>
          </div>
          }
        </GreyLabel>


        <div style={{ width: "300px", display: "grid", gridTemplateColumns: "90% 10%" }}>
          <div style={{ marginRight: "5px", padding: "5px" }}>
            <ProgressBar value={completeCount / allCount} stripes={subTaskList.subTasks.length == 0}
                         animate={false}/>
          </div>
          <div>
            {completeCount}/{allCount}
          </div>
        </div>

        <Droppable droppableId={`l-${subTaskList.id}`} type={'task'}>
          {(droppableProvided: DroppableProvided, droppableSnapshot: DroppableStateSnapshot) => (
            <div
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
              style={{background: droppableSnapshot.isDraggingOver ? "lightgrey": ""}}
            >
              {sortBy(subTaskList.subTasks, ['order']).map((subTask, index) => (
                <Draggable key={subTask.id} draggableId={`t-${subTask.id}`} index={index}>
                  {(draggableProvided: DraggableProvided, draggableSnapshot: DraggableStateSnapshot) => (
                    <div
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                      ref={draggableProvided.innerRef}
                      style={{
                        ...draggableProvided.draggableProps.style
                      }}
                    >
                      <SubTaskView key={`${subTask.id}ST`} subTask={subTask}
                                  onUpdateSubTask={(subTask) => {
                                    // console.log("subTask", subTask);
                                    this.formStore.updateSubTask({
                                      "id": subTask.id,
                                      "title": subTask.title,
                                      "checked": subTask.checked
                                    }, "patch");
                                  }}/*onUpdateSubTask(subTask)}*/
                                  onEdit={(title: string) => {
                                    subTask.setTitle(title);
                                    this.formStore.updateSubTask({
                                      id: subTask.id,
                                      title: subTask.title,
                                      checked: subTask.checked
                                    }, "patch");
                                  }}
                                  onRemove={() => {
                                    this.formStore.updateSubTask({
                                      subTaskListId: subTask.subTaskListId,
                                      subTaskId: subTask.subTaskId,
                                      id: subTask.id
                                    }, "delete");
                                  }}
                                  disabled={disabled}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>

        <AddSubTaskListForm
          disabled={disabled}
          onNewSubTaskTitle={(val: string) => {
            // onNewSubTaskTitle(subTaskList.subTaskListId, val);
            this.formStore.updateSubTask({ subTaskListId: subTaskList.subTaskListId, title: val }, "post");
          }}/>

        <Alert
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.onTitleDelete}
          onCancel={() => this.onTitleDelete = false}
          onConfirm={() => {
            this.onTitleDelete = false;
            this.formStore.updateSubTaskList({ id: subTaskList.id }, "delete");
          }}
          confirmButtonText={"예"}
          cancelButtonText={"아니오"}
        ><p>선택한 목록을 삭제하시겠습니까?</p><p>포함된 항목도 완전히 삭제되며, 되돌릴 수 없습니다.</p></Alert>
      </div>
    );
  }
}

export default SubTaskListItemView;
