import React, { Component, FC } from "react";
import styled from "styled-components/macro";
import moment from "moment";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { Switch } from "@blueprintjs/core";
import { ProjectGroup } from "../../../store/models/ProjectGroup";
import { ClientCalculate } from "../../../store/models/ClientCalculate";
import orderBy from "lodash/orderBy";


/*
 * Components
*/
const Row = styled.div`
  display:flex;
  font-size: 13px;

  .info {
    color: #7f8390;
  }
`;
const SwitchWrapper = styled(Switch)`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0px;
  margin-left: 30px;
`;
const TaxToggle: FC<{
  checked: boolean;
  handleChange: () => void;
}> = ({ checked, handleChange }) => {
  return (
    <SwitchWrapper
      checked={checked}
      label={'부가세(VAT) 포함 보기'}
      onChange={() => handleChange() } />
  )
}

const SummaryItemWrapper = styled.div<{
  highlight?: boolean;
  amountColor?: string;
}>`
  line-height: 33px;
  font-size: ${ p => p.highlight ? '18px;' : '16px' };
  margin-top: 10px;
  font-weight: 500;

  &::before {
    padding: 50px;
    content: '|';
  }
  &:first-child::before {
    padding: 0px;
    content: '';
  }
  
  span:last-child {
    color: ${ p => p.amountColor ? p.amountColor : '#000000' };
  }
  span:last-child::before {
    content: '₩ '
  }
  span + span {
    margin-left: 20px;
  }
`;
const SummaryItem: FC<{
  amount: number;
  amountColor?: string;
  highlight?: boolean;
  title: string;
}> = ({ amount, amountColor, highlight, title }) => {
  return (
    <SummaryItemWrapper
      amountColor={ amountColor }
      highlight={ highlight }>
      <span>{ title }</span>
      <span>{ amount.toLocaleString("en-US") }</span>
    </SummaryItemWrapper>
  )
};

const SummaryWrapper = styled.div`
  border-radius: 10px;
  border: solid 1px #bbbbbb;
  background-color: #f9f9ff;
  padding: 20px 0px 20px 35px;
  margin: 30px;
`;
const Summary: FC<{
  accumulated: number;
  balance: number;
  needed: number;
}> = ({ accumulated, balance, needed }) => {
  return (
    <SummaryWrapper>
      <Row>
        <span className='info'>
          { moment().format('YYYY.MM.DD hh:mm') } 기준
        </span>
      </Row>
      <Row>
        <SummaryItem
          title={'입금필요'}
          highlight={ true }
          amount={ needed }
          amountColor={'#5834ff'} />
          {
            balance > 0 && 
              <SummaryItem
                title={'잔액'}
                amount={ balance }
                amountColor={'#ff5f1d'} />
          }
        <SummaryItem
          title={'입금누적'}
          amount={ accumulated } />
      </Row>
    </SummaryWrapper>
  )
}

const StatusWrapper = styled.div<{isDeposited: boolean}>`
  width: 80px !important;
  height: 30px;
  line-height: 30px;
  border-radius: 23.5px;
  font-weight: 500;
  text-align: center;
  border: 1px solid rgb(88, 52, 255);
  background-color: rgb(255, 255, 255);
  color: rgb(88, 52, 255);

  ${ p => p.isDeposited && `
    border: none;
    background-color: rgb(154, 154, 154);
    color: rgb(255, 255, 255);
  `}
`;
const Status: FC<{
  isDeposited: boolean;
}> = ({ isDeposited }) => {
  return (
    <StatusWrapper
    isDeposited={ isDeposited }>
      {
        isDeposited ? '입금완료' : '입금대기'
      }
    </StatusWrapper>
  )
}


const DepositWrapper = styled.div`
  background-color: #ffffff;
  margin: 0px 30px;
  padding: 15px 0px;
  display: flex;
  & + & {
    margin-top: 10px;
    border-top: solid 1px #bbbbbb;
  }

  div:nth-child(2) {
    font-size: 16px;
    font-weight: bold;
    padding-top: 20px;
    width: 110px;

    ::before {
      content: '₩ ';
    }
  }
  div:nth-child(3) {
    font-size: 14px;
    font-weight: 500;
    margin-left: 30px;
    padding-top: 15px;
  }

  div:first-child {
    width: calc(100% - 230px);
    div:nth-child(1) {
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 5px;
      padding-top: 0px;
    }
    div:nth-child(2) {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
      padding-top: 0px;
      width: auto;
      ::before {
        content: '';
      }
    }
    div:nth-child(3) {
      font-size: 12px;
      font-weight: normal;
      color: #5834ff;
      margin-bottom: 5px;
      margin-left: 0px;
      padding-top: 0px;
    }
  }

  .isDeposited {
    color: #333333 !important;
  }
`;
const Deposit: FC<{
  includeTax: boolean;
  deposit: ClientCalculate;
}> = ({ deposit, includeTax }) => {
  const { projectGroup, title, payout, receivedPayout, dateLastDeposit, dateIssue } = deposit;
  const isDeposited = payout <= receivedPayout;
  const amount = includeTax ? payout * 1.1 : payout;

  return (
    <DepositWrapper>
      <div>
        <div>{ projectGroup }</div>
        <div>{ title }</div>
        <div
          className={ isDeposited ? 'isDeposited' : '' }>
          { 
            dateLastDeposit && isDeposited ? `입금일 - ${ moment(dateLastDeposit).format('YYYY.MM.DD') }`
            : dateIssue ? `세금계산서 발행 완료 - ${ moment(dateIssue).format('YYYY.MM.DD') }` : '세금계산서 발행 전' }
        </div>
      </div>
      <div>
        { amount.toLocaleString("en-US") }
      </div>
      <div>
        <Status isDeposited={ isDeposited } />
      </div>
    </DepositWrapper>
  )
}
/*
 * Components END
*/


const Wrapper = styled.div`
  margin-bottom: 50px;
  margin-top: 100px;
`;
const TitleRow = styled.div`
  display: flex;
  font-size: 15px;
  font-weight: bold;
  border-bottom: solid 1px #7f8390;
  padding-bottom: 8px;
  margin-bottom: 20px;
  color: #7f8390;
`;
interface Props {
  projectGroup: ProjectGroup;
}
interface InjectedProps extends Props {
  appStore: AppStore;
}

@inject("appStore")
@observer
class AccountHistory extends Component<Props> {
  @observable isTaxChecked = true;

  get injected() {
    return this.props as InjectedProps;
  }

  get deposits() {
    return this.injected.appStore.calculateStore.clientCalculates;
  }

  get payouts() {
    return this.injected.appStore.calculateStore.rufreeCalculates;
  }

  get accumulated() {
    const deposited = this.deposits.reduce((s, deposit) => s + deposit.receivedPayout, 0);
    return this.isTaxChecked ? deposited * 1.1 : deposited;
  }

  get total() {
    const accumulated = this.deposits.reduce((s, deposit) => s + deposit.payout, 0);
    return this.isTaxChecked ? accumulated * 1.1 : accumulated;
  }

  private async fetchCalculates(clientId: string) {
    const { calculateStore } = this.injected.appStore;
    await calculateStore.fetchAllByClientId(clientId);
  }


  async componentDidMount() {
    const { clientName } = this.props.projectGroup;
    if(clientName) {
      await this.fetchCalculates(clientName);
    }
  }

  render() {
    const { clientName } = this.props.projectGroup;
    const { accumulated, total } = this;

    return (
      <Wrapper>
        <TitleRow>
          { clientName } 클라이언트 입금/잔액 현황
          <TaxToggle
            checked={this.isTaxChecked}
            handleChange={ () => this.isTaxChecked = !this.isTaxChecked }  />
        </TitleRow>

        <Summary
          accumulated={ accumulated }
          balance={ total - accumulated > 0 ? 0 : accumulated - total }
          needed={ total - accumulated > 0 ? total - accumulated : 0 } />
        
        {
          orderBy(this.deposits.slice(), ['projectGroup'], ['desc'])
          .map((deposit, i) => 
            <Deposit
              key={ i }
              includeTax={ this.isTaxChecked }
              deposit={ deposit } />
          )
        }
        
      </Wrapper>
    );
  }
}

export default AccountHistory;
