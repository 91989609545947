import React, { FC } from "react";
import styled from "styled-components/macro";
import { IButtonProps } from "@blueprintjs/core/lib/esm/components/button/abstractButton";
import { Button, Intent } from "@blueprintjs/core";

export const ItemRow = styled.div`
  position: relative;
  margin-bottom: 5px;
`;

export const RemoveButtonContainer = styled.div`
  position: absolute;
  left: -30px;
  top: 0px;
  /* bottom: 0px;
  margin: auto;
  height: 34px; */
`;

export const RemoveButton: FC<IButtonProps> = props => {
  return (
    <Button
      {...props}
      icon="minus"
      minimal={true}
      small={true}
      intent={Intent.DANGER}
    />
  );
};
