import { getParent, types } from "mobx-state-tree";
import { SprintCheckModel } from "./SprintCheck";
import { getSprintDaysRate } from "../../utils/getSprintDaysRate";
import { InspectionForm } from "./InspectionForm";

export const RufreeRoleFormModel = types
  .model("RufreeRoleForm", {
    role: types.optional(types.string, ""),
    roleTextBufferForETC: types.optional(types.string, "기타"), // 새로 '기타'를 추가할 때만 사용가능. DB에 저장되는 순간 '기타'인지 구분하는 것이 불가.
    wage: types.optional(types.number, 0),
    sprints: types.array(SprintCheckModel)
  })
  .views(self => ({
    wageBySprintDaysRate({
      sprintIndex,
      sprintDays
    }: {
      sprintIndex: number;
      sprintDays: number;
    }) {
      return getSprintDaysRate({
        sprintIndex,
        sprintDays,
        originalPrice: self.wage
      });
    },
    get totalWage() {
      const parent: InspectionForm = getParent(self, 2);
      let totalWage = 0;
      
      // if(parent && parent.hasOwnProperty('wageBySprintDaysRate')) {
        self.sprints.forEach((sprint, sprintIndex) => {
          if (sprint.value) {
            const sprintDays = parent.sprintDays[sprintIndex].value;
            totalWage += this.wageBySprintDaysRate({
              sprintIndex,
              sprintDays
            });
          }
        });
      // }

      return totalWage;
    },
    get sprintsToNumber() {
      const sprints: number[] = [];

      self.sprints.forEach((s, index) => {
        if(!!s.value) sprints.push(index);
      });

      return sprints;
    }
  }))
  .actions(self => ({
    setRole(value: string) {
      self.role = value;
    },
    setRoleTextBufferForEtc(value: string) {
      self.roleTextBufferForETC = value;
    },
    setWage(value: number) {
      self.wage = value;
    }
  }));

type RufreeRoleFormType = typeof RufreeRoleFormModel.Type;
export interface RufreeRoleForm extends RufreeRoleFormType {}
