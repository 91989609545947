import { types, flow } from "mobx-state-tree";
import axios from "axios";
import { NotificationTemplateModel, mapper, INotificationTemplate } from "./models/NotificationTemplate";
import {
  ITemplateResponse,
  ITemplatePatchParams
} from "../types/notificationTemplate";
import { orderBy } from "lodash";



export const NotificationTemplateStoreModel = types
  .model("NotificationTemplateStore", {
    templates: types.array(NotificationTemplateModel),

    isLoading: types.boolean,
    formMode: types.number ,// FormMode
    openPopup: types.boolean,
    selectedTemplate: types.maybe(NotificationTemplateModel),

    // 검색 정보
    category: types.maybe(types.string),
    searchText: types.maybe(types.string),

    // 초기 수신인
    emails: types.array(types.string),
    phones: types.array(types.string),

    // 템플릿 데이터
    name: types.maybe(types.string),
    projectName: types.maybeNull(types.string),
    rufreeName: types.maybe(types.string)
  })
  .views(self => ({
    get descTemplates() {
      return orderBy(self.templates, ['id'], ['desc'])
    }
  }))
  .actions(self => ({
    setFetching() {
      self.isLoading = true;
    },
    setFetched() {
      self.isLoading = false;
    },
    setFormMode(mode: number) {
      self.formMode = mode;
    },
    setOpenPopup(open: boolean) {
      self.openPopup = open;
    },
    setSelectedTemplate(template?: INotificationTemplate) {
      if(template) {
        self.selectedTemplate = { 
          ...template,
          condition: template.condition ? { ...template.condition } : null
        };
      } else {
        self.selectedTemplate = undefined;
      }
    },

    //검색
    setCategory(category: string) {
      self.category = category;
    },
    setSearchText(text: string) {
      self.searchText = text;
    },

    setPhones(phones: string[]) {
      self.phones.replace(phones);
    },
    setEmails(emails: string[]) {
      self.emails.replace(emails);
    },

    setName(name: string) {
      self.name = name;
    },
    setProjectName(projectName: string | null) {
      self.projectName = projectName;
    },
    setRufreeName(rufreeName: string) {
      self.rufreeName = rufreeName;
    },
  }))
  .actions(self => ({
    getTemplatesByType(type: string) {
      let templates: INotificationTemplate[] = [];
      if(type === 'all') {
        templates = self.templates;
      } else if(type === 'email') {
        templates = self.templates.filter(template => template.htmlBody || (!template.htmlBody && !template.textBody))
      } else if(type === 'sms') {
        templates = self.templates.filter(template => template.textBody)
      }

      return orderBy(templates, ['id'], ['desc']);
    }
  }))
  .actions(self => {
    const fetchById = flow(function*(id: number) {
      
      try {
        self.setFetching();

        const { data }: { data: ITemplateResponse } = yield axios.get(
          `/template/${id}`
        );

        self.setFetched();

        return NotificationTemplateModel.create(mapper(data));
      } catch (e) {
        throw e;
      }
      
    });
    
    return {
      fetchById
    };
  })
  .actions(self => {

    const fetch = flow(function*() {
      
      try {
        self.setFetching();

        const { data }: { data: ITemplateResponse[] } = yield axios.get(
          `/template?${self.category ? `category=${self.category}` : ''}${self.searchText?`&search=${self.searchText}`:''}`
        );

        const templates = data.map(x =>
          NotificationTemplateModel.create(mapper(x))
        );
        self.templates.replace(templates);
      } catch (e) {
        throw e;
      }
      self.setFetched();
    });

    const post = flow(function*(params: ITemplatePatchParams) {
      try {
        self.setFetching();

        const { data }: { data: ITemplateResponse } = yield axios.post(
          `/template`,
          { ...params }
        );

        // const templates = data.map(x =>
        //   NotificationTemplateModel.create(mapper(x))
        // );
        // self.templates.replace(templates);
      } catch (e) {
        throw e;
      }
      self.setFetched();
    })

    return {
      fetch,
      post
    };
  });

type NotificationTemplateStoreType = typeof NotificationTemplateStoreModel.Type;
export interface NotificationTemplateStore extends NotificationTemplateStoreType {}
