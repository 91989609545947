import React, { Component } from "react";
import styled from "styled-components/macro";
import { computed } from "mobx";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { SectionLabel } from "../SprintTableClient/SprintTableClient";
// import { InputGroup, Button, Intent } from "@blueprintjs/core";
import { ScheduleForm } from "../../../store/forms/ScheduleForm/ScheduleForm";
import { InspectionForm } from "../../../store/forms/InspectionForm";
// import { Rufree } from "../../../store/models/Rufree";
import {
  Row,
  // Column,
  LabelColumn
} from "../../atoms/ScheduleForm/ScheduleForm";
import RufreeScheduleFormView from "../../molecules/RufreeScheduleFormView/RufreeScheduleFormView";
import { Inspection } from "../../../store/models/Inspection";
import {
  ItemRow,
  RemoveButtonContainer,
  RemoveButton
} from "../../atoms/RemoveItemButton/RemoveItemButton";
// import ClientScheduleFormView from "../../molecules/ClientScheduleFormView/ClientScheduleFormView";
import { MAX_SPRINTS } from "../../../constants/constants";
import { ProjectGroup } from "../../../store/models/ProjectGroup";

const sprintArray = Array(MAX_SPRINTS).fill(null);

const Wrapper = styled.div``;

const ScrollArea = styled.div`
  ::-webkit-scrollbar {
    display: none;
  }

  overflow-x: auto;
  margin-left: -34px;
  padding-left: 34px;
  padding-top: 10px;
  padding-bottom: 8px;

  & > * {
    white-space: nowrap;
  }
`;

const Section = styled.div`
  margin-bottom: 30px;
`;

interface ScheduleTableProps {
  currentForm: ScheduleForm;
  inspectionForm?: InspectionForm;
  inspection: Inspection | undefined | null;
  projectGroup: ProjectGroup;
  onChange: () => {};
}

interface InjectedProps extends ScheduleTableProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ScheduleTable extends Component<ScheduleTableProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get selectedRufreeRole() {
    return this.props.currentForm.rufreeSchedules.map(x => x.role)
  }

  render() {
    const { currentForm, inspectionForm, inspection, projectGroup } = this.props;

    if (!inspection) {
      return (
        <Wrapper>
          연결된 검수서가 없습니다.
        </Wrapper>
      );
    }
    
    return (
      <Wrapper>
        {/* <Section>
          <Row>
            <SectionLabel>클라이언트</SectionLabel>
          </Row>
          <Row>
            <ScrollArea>
              <Row>
                <LabelColumn style={{ width: "140px" }} />
                <LabelColumn style={{ width: "220px" }}>
                  <strong>검수서</strong>
                </LabelColumn>
                <LabelColumn style={{ width: "150px" }}>
                  <strong>역할</strong>
                </LabelColumn>
                <LabelColumn style={{ width: "50px" }}>
                  <strong>검수서</strong>
                </LabelColumn>
                <LabelColumn style={{ width: "50px" }}>
                  <strong>스케쥴</strong>
                </LabelColumn>
                {sprintArray.map((_, index) => (
                  <LabelColumn
                    key={index}
                    style={{ width: "200px", textAlign: "center" }}
                  >
                    <strong>{index === 0 ? "PRE" : `S${index}`}</strong>
                  </LabelColumn>
                ))}
              </Row>
              {currentForm.clientSchedules.map((schedule, index) => (
                <ItemRow key={index}>
                  <RemoveButtonContainer>
                    <RemoveButton
                      onClick={() => currentForm.removeClientSchedule(schedule)}
                    />
                  </RemoveButtonContainer>
                  <ClientScheduleFormView
                    clientScheduleForm={schedule}
                    inspections={inspections}
                    projectGroup={projectGroup}
                  />
                </ItemRow>
              ))}
            </ScrollArea>
            <Row>
              <Button
                icon="add"
                text="클라이언트 추가"
                minimal={true}
                intent={Intent.PRIMARY}
                onClick={currentForm.addClientSchedule}
              />
            </Row>
          </Row>
        </Section> */}

        <Section>
          <Row>
            <SectionLabel>알유프리</SectionLabel>
          </Row>
          <Row>
            <ScrollArea>
              <Row>
                <LabelColumn style={{ width: "200px" }}>
                  <strong>역할</strong>
                </LabelColumn>
                {
                  // <LabelColumn style={{ width: "220px" }}>
                  //   <strong>의뢰내용 검수보고서</strong>
                  // </LabelColumn>
                }
                <LabelColumn style={{ width: "200px" }}>
                  <strong>알유프리</strong>
                </LabelColumn>
                <LabelColumn style={{ width: "110px" }}>
                  <strong>임금/S</strong>
                </LabelColumn>
                {sprintArray.map((_, index) => (
                  <LabelColumn
                    key={index}
                    style={{ width: "200px", textAlign: "center" }}
                  >
                    <strong>{index === 0 ? "PRE" : `S${index}`}</strong>
                  </LabelColumn>
                ))}
              </Row>
              {currentForm.rufreeSchedules.map((schedule, index) => (
                <ItemRow key={index}>
                  {
                    <RemoveButtonContainer>
                      <RemoveButton
                        onClick={() => currentForm.removeRufreeSchedule(schedule)}
                      />
                    </RemoveButtonContainer>
                  }
                  <RufreeScheduleFormView
                    rufreeScheduleForm={schedule}
                    selectedRoles={this.selectedRufreeRole}
                    inspectionRufreeScheduleForm={inspectionForm ? inspectionForm.rufreeRoles : null}
                    inspection={inspection}
                    projectGroup={projectGroup}
                    onChange={this.props.onChange}
                  />
                </ItemRow>
              ))}
            </ScrollArea>

            {/* <Row>
              <Button
                icon="add"
                text="알유프리 추가"
                minimal={true}
                intent={Intent.PRIMARY}
                onClick={currentForm.addRufreeSchedule}
              />
            </Row> */}
          </Row>
        </Section>
      </Wrapper>
    );
  }
}

export default ScheduleTable;
