import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { Reception } from "../../../store/models/Reception";
import { Item, Column } from "../../atoms/CardTable/CardTable";
import { getStatusText } from "../../pages/ReceptionDetailPage/ReceptionDetailPage";
import { computed } from "mobx";
import moment from "moment";
import { Button, Menu, MenuItem } from "@blueprintjs/core";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components/macro";


const TabButton = styled(Button)<{ active?: boolean, minimal?: boolean }>`
  padding: 0px 10px;
  width: 70px;
  ${p =>
  p.minimal &&
  css`
      background-color: #000000;
        text-decoration: underline;
        .active { color: #ffffff; }
      `}

  ${p =>
  !p.active &&
  css`
      background-color: #CED9E0;
      color: #000000;
    `}
`;
const TabButtonMenu = styled(Menu)<{ active?: boolean }>`
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 0px;
  background: inherit;
  min-width: 50px;
  padding: 0px;
  .bp3-overlay.bp3-overlay-inline {
    display: block !important;
    overflow: hidden !important;
  }
  ${p =>
  !p.active &&
  css`
    .bp3-text-overflow-ellipsis bp3-fill { color: #000000; }
    `}
`;

interface ReceptionRowProps {
  reception: Reception;
  onClick: () => void;
}

interface InjectedProps extends ReceptionRowProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ReceptionRow extends Component<ReceptionRowProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get client() {
    const clientId = this.props.reception.client;

    if (clientId) {
      return this.injected.appStore.clientStore.findById(clientId);
    }

    return null;
  }

  @computed
  get clientUser() {
    const clientUser = this.props.reception.clientUser;

    if (clientUser) {
      return this.injected.appStore.clientUserStore.clientUsers.find(
        x => x.id === clientUser
      );
    }

    return null;
  }

  @computed
  get manager() {
    if (!this.props.reception || !this.props.reception.manager) {
      return null;
    }

    const { manager } = this.props.reception;

    return this.injected.appStore.pmUserStore.pmUsers.find(
      x => x.id === manager
    );
  }

  render() {
    const { reception, onClick } = this.props;
    return (
      <Item onClick={onClick}>
        <Column style={{ maxWidth: "160px" }}>
          {/*{reception.receptionNumber}*/}
          {reception.receptionId}
        </Column>
        <Column style={{ maxWidth: "200px" }}>{reception.title}</Column>
        <Column style={{ maxWidth: "130px" }}>
          {this.clientUser && this.clientUser.name}
          {reception.clientName && <><br/>({reception.clientName})</>}
        </Column>
        <Column style={{ maxWidth: "170px" }}>
          {moment(reception.dateMeeting).format("YYYY년 MM월 DD일")}<br/>
          {moment(reception.dateMeeting).format("hh:mmA")}
        </Column>
        <Column style={{ maxWidth: "130px" }}>
          {reception.dateRegisterString}
        </Column>
        <Column style={{ maxWidth: "130px" }}>
          {getStatusText(reception.status)}<br/>
          {reception.inspectorName}
        </Column>
        <Column style={{ maxWidth: "130px" }}>
          {reception.consultsId &&
            <Link to={`/consulting/projects/${reception.consultsId}`} onClick={(e) => e.stopPropagation()}
                    target="_blank">
              <TabButton
                // text="영업"
                // small={true}
                // style={{ whiteSpace: "nowrap" }}
              >영업</TabButton>
            </Link>
          }
        </Column>
        <Column style={{ maxWidth: "130px" }}>
          {reception.inspection &&
          <Link to={`/inspections/${reception.inspection.inspection_id}/read`} onClick={(e) => e.stopPropagation()} target="_blank">
            {/*<Button
              text="검수"
              small={true}
              style={{ whiteSpace: "nowrap" }}
            />*/}
            <TabButton>검수</TabButton>
          </Link>}
        </Column>
      </Item>
    );
  }
}

export default ReceptionRow;
