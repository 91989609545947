import React, { Component, ChangeEvent } from "react";

import { computed, observable } from "mobx";
import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import {
  Intent,
  Classes,
  Dialog,
  TextArea,
  Button
} from "@blueprintjs/core";

import { AppStore } from "../../../store/AppStore";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";


const ContentContainer = styled.div`
  padding: 20px 20px 0px 20px;
`;
const ButtonContainer = styled.div`
  padding-top: 20px;

  display: flex;
  justify-content: flex-end;
`;

interface AddManyTaskPopupProps {
  laneId: number;
  isOpen: boolean;
  onClose: () => void;
}

interface InjectedProps extends AddManyTaskPopupProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class AddManyTaskPopup extends Component<AddManyTaskPopupProps> {
  @observable task_titles:string = '';

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get board() {
    return this.injected.appStore.taskManagerStore.currentBoard;
  }    

  async componentDidMount() {}

  handleSubmit = async () => {
    try {
      let titles = this.task_titles.split('\n');
      // 백엔드에 부하가 덜 가도록 병렬화 하지 않고 순차 실행
      for(let i=0; i<titles.length; i++) {
        if(titles[i].trim().length > 0) {
          await this.injected.appStore.taskManagerStore.postTask(String(this.props.laneId), titles[i]);
        }        
      }

      this.props.onClose();      
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
      await this.injected.appStore.taskManagerStore.fetchTaskBoard(this.board ? this.board.projectGroup : "");
    }
  }

  render() {
    const { isOpen, onClose } = this.props;

    return (
      <Dialog
        icon="annotation"
        onClose={onClose}
        title={"Task 추가"}
        isOpen={isOpen}
        usePortal={true}
        autoFocus={true}
        canEscapeKeyClose={true}
        enforceFocus={true}
        onOpening={() => {this.task_titles = ''}}
        style={{ width: "400px" }}
      >
        <ContentContainer>
          <p>줄바꿈된 텍스트마다 개별 Task 로 생성됩니다.</p>
          <TextArea 
            placeholder={'태스크제목'}
            className={Classes.INPUT} 
            style={{width:'100%'}} 
            value={this.task_titles}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
              this.task_titles = e.target.value;
            }}
          />
          <ButtonContainer>
              <Button
                text={'취소'}
                onClick={onClose}
              />
              &nbsp;
              <Button
                text={'추가'}
                intent={Intent.PRIMARY}
                disabled={this.task_titles.trim().length <= 0}
                onClick={this.handleSubmit}
              />
          </ButtonContainer>
        </ContentContainer>
      </Dialog>
    );
  }
}

export default AddManyTaskPopup;