import React, { FC } from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components/macro";
import {
  Button,
  Intent,
  InputGroup
} from "@blueprintjs/core";
import { 
  ToolsRow
} from "../../atoms/CalculateTable/CalculateTable";

import { AppStore } from "../../../store/AppStore";
import CategorySelection from "../../molecules/NotificationTemplate/CategorySelection";

interface Props {
}
interface InjectedProps extends Props {
  appStore: AppStore;
}

const SearchBar: FC<Props> = inject("appStore")(observer((props => {
  const store = (props as InjectedProps).appStore.notificationTemplateStore;

  const BtnSearchTextClear =
    <Button
      icon='cross'
      minimal={true}
      onClick={() => {
        store.setSearchText('');
        store.fetch();
      }} />;

    return (
      <ToolsRow>
        <CategorySelection
          value={ store.category ? store.category : '' }
          handleChange={ (category: string) => {
            store.setCategory(category);
          } } />

        <div style={{ 'width': '400px'}}>
          <InputGroup
            placeholder="템플릿명/최종 수정자명"
            rightElement={ BtnSearchTextClear }
            value={ store.searchText ? store.searchText : '' }
            onChange={ (e: any) => {
              store.setSearchText(e.target.value);
            } }
            // onKeyDown={this.handleSearchTextKeyDown}
          />
        </div>

        <Button
          icon="search"
          text="검색"
          intent={Intent.PRIMARY}
          onClick={ () => {
            store.fetch();
          } }
        />

        <Button
          icon="reset"
          text="초기화"
          minimal={ true }
          small={ true }
          style={{ 'marginTop': '8px' }}
          intent={ Intent.PRIMARY }
          onClick={ () => {
            store.setCategory('');
            store.setSearchText('');

            store.fetch();
          } }
        />
      </ToolsRow>
    );
  // }
})));

export default SearchBar;
