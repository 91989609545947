import React, { Component } from "react";
import { Button } from "@blueprintjs/core";
import styled from "styled-components/macro";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { Sprint } from "../../../store/models/Sprint";
import SprintBoardRow from "../SprintReportRow/SprintBoardRow";

const Wrapper = styled.div`
  position: relative;
`;

const Table = styled.table`
  width: 100%;
  background: none;
  z-index: 1;
  position: relative;
`;

const THead = styled.thead`
  tr {
    height: 40px;
    text-align: left;
  }
`;
const FirstColumnTH = styled.th`
  width: 280px;
`
const SubColumnTH = styled.th`
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7f8390;
  text-align: center;
  width: 100px;
`;

const GreyBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 40px;
  background: #f8f8f8;
  z-index: 0;
`;

const TD = styled.td`
  color: #161d2e;
`;

const RightAlignTD = styled(TD)`
  text-align: right;
`

const SprintSpan = styled.span`
  font-weight: bold;
  padding-right: 15px;
`

const HighlightStatus = styled.span`
  color: red;
`

interface SprintBoardRowGroupProps {
  projectGroupName: string;
  sprintGroup: Sprint[];
  onPopupClose: () => {}
}

@observer
class SprintBoardRowGroup extends Component<SprintBoardRowGroupProps> {
  @observable isConfirmOpen = false;

  render() {
    const { projectGroupName, sprintGroup, onPopupClose } = this.props;

    return (
      <Wrapper>
        <GreyBackground />
        <Table>
          <THead>
            <tr>
              <FirstColumnTH>
                {sprintGroup[0].rufreeName} ({sprintGroup[0].role})
              </FirstColumnTH>
              <SubColumnTH>알유프리</SubColumnTH>
              <SubColumnTH>수수료</SubColumnTH>
              <SubColumnTH>시소서비스</SubColumnTH>
              <SubColumnTH>상태</SubColumnTH>
              <SubColumnTH>지급관련메모</SubColumnTH>
              <SubColumnTH></SubColumnTH>
            </tr>
          </THead>
          <tbody>
            {sprintGroup.map((sprint, index) => (
              <SprintBoardRow
                key={index}
                sequential_id={index+1}
                projectGroupName={projectGroupName}
                sprint={sprint}
                onPopupClose={onPopupClose}
              />
            ))}
          </tbody>
        </Table>
      </Wrapper>
    );
  }
}

export default SprintBoardRowGroup;
