import React from "react";

import { IProps } from '../../common/props';
import { VATCheckboxWrapper as Wrapper } from './_wrapper';


export interface IVATCheckbox extends IProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
};


export const VATCheckbox: React.FC<IVATCheckbox> = (props) => {
  return (
    <Wrapper
      className={ `${ props.className || '' }` }
    >
      <input
        type="checkbox"
        checked={ props.checked }
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange(e.target.checked)}  />
      <span className='indicator'></span>
      VAT 포함보기
    </Wrapper>
  );
}
