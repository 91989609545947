import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { RufreeCalculate } from "../../../store/models/RufreeCalculate";
// import { Sprint } from "../../../store/models/Sprint";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import { Intent, Dialog, Classes, TextArea, Button, FormGroup, RadioGroup, Radio } from "@blueprintjs/core";
import { observable } from "mobx";
import { ChangeEvent } from "cleave.js/react/props";
import InputWithFormat from "../../atoms/InputWithFormat/InputWithFormat";
import { DateInput } from "@blueprintjs/datetime";
import { getMomentFormatter, koFormat } from "../../../utils/date";
import moment from "moment";

const FormGroupLabel = styled.div`
  font-weight: bold;
  width: 120px;
  line-height: 18px;
`;
const FormGroupValue = styled.div`
`;
const StyledTextArea = styled(TextArea)`
  width: 100%;
`;

const DialogBodyWrapper = styled.div`
  .bp3-label { font-weight: bold; width: 120px; line-height: 18px !important; }
  .bp3-form-content { width: 100%; }
  .bp3-form-group.sub {
    font-size: 13px;
    color: #5C7080;
    margin-bottom: 5px;

    label { padding-left: 15px; }
  }

  .sub:last-child {
    margin-bottom: 15px;
  }
`;

interface RufreeCalculateConfirmPopupProps {
  calculate: RufreeCalculate;
  isOpened: boolean;
  onClose: () => void;
}

interface InjectedProps extends RufreeCalculateConfirmPopupProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class RufreeCalculateConfirmPopup extends Component<RufreeCalculateConfirmPopupProps> {
  @observable typeTax = this.props.calculate.typeTax ? this.props.calculate.typeTax : "PE";
  @observable payment = this.props.calculate.expectedPayment ? this.props.calculate.expectedPayment : 0;
  @observable datePayment: Date | null = null;
  @observable comment = this.props.calculate.comment ? this.props.calculate.comment : "";

  get injected() {
    return this.props as InjectedProps;
  }

  get calculateStore() {
    return this.injected.appStore.calculateStore
  }

  updatePayment = () => {
    const { expectedPayment } = this.props.calculate;
    const base_payment = expectedPayment ? expectedPayment : 0;

    if(this.typeTax === 'PE')
      this.payment = base_payment - (base_payment * 0.033);
    if(this.typeTax === 'BU')
      this.payment = base_payment + (base_payment * 0.1);
  }

  handleOpening = () => {
    const {
      typeTax,
      expectedPayment,
      datePayment,
      comment
    } = this.props.calculate;

    this.typeTax = typeTax ? typeTax : "PE";
    this.payment = expectedPayment ? expectedPayment : 0;
    this.datePayment = datePayment ? moment(datePayment).toDate() : this.datePayment;
    this.comment = comment ? comment : "";

    this.updatePayment();
  };

  handleTypeTax = (e: any) => {
    this.typeTax = e.target.value;
    this.updatePayment();
  };
  handleDatePayment = (selectedDate: Date) => {
    this.datePayment = selectedDate;
  };
  handleCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.comment = e.target.value;
  };


  handleSave = async () => {
    try {
      await this.props.calculate.pay(
        this.datePayment ? this.typeTax : '',
        this.datePayment ? this.payment : 0,
        this.datePayment ? koFormat(this.datePayment, 'yyyy-MM-dd') : null,
        this.comment
      );

      AppToaster.show({
        message: "저장 되었습니다.",
        intent: Intent.SUCCESS
      });

      this.props.onClose();
    } catch (e) {
      const error = JSON.stringify(e.response.data);

      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  handleCancelPayment = async () => {
    try {
      await this.props.calculate.cancelPay();

      AppToaster.show({
        message: "지급이 취소되었습니다.",
        intent: Intent.SUCCESS
      });

      this.props.onClose();
    } catch (e) {
      const error = JSON.stringify(e.response.data);

      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  }

  render() {
    const { isOpened, onClose, calculate } = this.props;
    const { sprint, rufreeName, calculateType } = calculate;

    const title = calculateType === 'sprint' ?
      `${rufreeName} ${sprint ? sprint.role : ""} ${ sprint ? "S" + calculate.sprintSequentialId : "PRE" } 스프린트 정산`
      : `${rufreeName} 시간제태스크 정산`;

    return (
      <Dialog
        onOpening={this.handleOpening}
        isOpen={isOpened}
        usePortal={true}
        autoFocus={true}
        enforceFocus={true}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        isCloseButtonShown={false}
        title={title}
      >
        <DialogBodyWrapper className={Classes.DIALOG_BODY}>
          <FormGroup label={<FormGroupLabel>프로젝트그룹</FormGroupLabel>} inline={true}>
            <FormGroupValue>
              <Link to={`/project-groups/${calculate.projectGroup}`} target="_blank">
                {calculate.projectGroup}
              </Link>
            </FormGroupValue>
          </FormGroup>
          <FormGroup label={<FormGroupLabel>프로젝트그룹명</FormGroupLabel>} inline={true}>
            <FormGroupValue>{calculate.projectGroupName}</FormGroupValue>
          </FormGroup>
          {
            calculate.calculateType === 'sprint' ?
              <FormGroup label={<FormGroupLabel>클라이언트계약번호</FormGroupLabel>} inline={true}>
                {calculate.clientContract}
              </FormGroup>
              : <FormGroup label={<FormGroupLabel>시간제태스크</FormGroupLabel>} inline={true}>
                  {
                    calculate.parttimeTask &&
                      <Link to={`/parttime/requests/${calculate.parttimeTask.request.id}/tasks/${calculate.parttimeTask.id}`} target="_blank">
                        {calculate.parttimeTask.title}
                      </Link>
                  }
                </FormGroup>
          }
          
          <FormGroup label={<FormGroupLabel>클라이언트명</FormGroupLabel>} inline={true}>
            {calculate.clientName}
          </FormGroup>
          <FormGroup label={<FormGroupLabel>알유프리명</FormGroupLabel>} inline={true}>
            {calculate.rufreeName}
          </FormGroup>

            {
              calculate.rufreeContract.contractId &&
              <>
                {
                  calculate.rufreeContract.info === '대리인' &&
                  <>
                    <FormGroup
                      label={'대리인'}
                      inline={true}
                      className='sub'
                    >
                      { calculate.rufreeContract.agentName }
                    </FormGroup>
                  </>
                }

                <FormGroup
                  label={'계약요청서'}
                  inline={true}
                  className='sub'
                >
                  <a href={ calculate.rufreeContract.cpmsUrl } target='_blank'>
                    { calculate.rufreeContract.cpmsUrl }
                  </a>
                </FormGroup>
              </>
            }

          
            {
              sprint &&
                <FormGroup label={<FormGroupLabel>스프린트</FormGroupLabel>} inline={true}>
                  {
                    `S${calculate.sprintSequentialId} 
                    (운영일정: ${sprint.dateStart} ~ ${sprint.expectedDateEnd})`
                  }
                </FormGroup>
            }
          
          <FormGroup label={<FormGroupLabel>담당PM</FormGroupLabel>} inline={true}>
            {calculate.managerName}
          </FormGroup>
          <FormGroup label={<FormGroupLabel>알유프리비용</FormGroupLabel>} inline={true}>
            {calculate.expectedPayment ? calculate.expectedPayment.toLocaleString("en-US") : 0}
          </FormGroup>
          {
            sprint &&
              <FormGroup label={<FormGroupLabel>수수료</FormGroupLabel>} inline={true}>
                {calculate.commission ? calculate.commission.toLocaleString("en-US") : 0}
              </FormGroup>
          }
          {
            <FormGroup label={<FormGroupLabel>지급관련메모</FormGroupLabel>} inline={true}>
              { calculate.pmComment && calculate.pmComment.split('\n').map((item, i) => <div key={i}>{item}</div>) }
            </FormGroup>
          }

          <FormGroup label={<FormGroupLabel>세액</FormGroupLabel>} inline={true}>
            <RadioGroup
                inline={true}
                selectedValue={this.typeTax}
                onChange={this.handleTypeTax}
              >
                <Radio
                  label="-3.3%"
                  value="PE"
                />
                <Radio
                  label="+10%"
                  value="BU"
                />
              </RadioGroup>
          </FormGroup>
          <FormGroup label={<FormGroupLabel>실 지급액</FormGroupLabel>} inline={true}>
            <InputWithFormat
              disabled={true}
              value={this.payment}
              onChange={() => {}}
              options={{ numeral: true }}
            />
          </FormGroup>
          <FormGroup label={<FormGroupLabel>지급일</FormGroupLabel>} inline={true}>
            <DateInput
              {...getMomentFormatter("YYYY/MM/DD")}
              locale="ko"
              closeOnSelection={true}
              canClearSelection={true}
              value={this.datePayment}
              showActionsBar={true}
              onChange={this.handleDatePayment}
              maxDate={new Date("2050-01-01")}
            />
          </FormGroup>
          <FormGroup label={<FormGroupLabel>비고</FormGroupLabel>} inline={true} style={{width: '100%'}}>
            <StyledTextArea
              value={this.comment}
              onChange={this.handleCommentChange}
            />
          </FormGroup>
        </DialogBodyWrapper>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            
            {
              calculate.datePayment && (
                <Button
                  text="지급취소"
                  minimal={true}
                  small={true}
                  onClick={this.handleCancelPayment}
                  intent={Intent.DANGER}
                />
              )
            }
            <Button text="취소" onClick={onClose} />
            <Button
              text="저장"
              onClick={this.handleSave}
              intent={Intent.PRIMARY}
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

export default RufreeCalculateConfirmPopup;
