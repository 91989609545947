import { types, flow } from "mobx-state-tree";
import { RufreeMatchingModel, RufreeMatching } from "./models/RufreeMatching";
import {
  RufreeMatchingResponse,
  RufreeMatchingListResponse,
  MatchingHistoryResponse,
  HistoryCommentResponse
} from "../types/rufreeMatching";
import axios from "axios";
import { putRufreeMatching } from "../utils/MSTUtils";
import sortBy from "lodash/sortBy";

const mapResponseToHistoryComment = (data: HistoryCommentResponse) => ({
  id: data.id,
  createdAt: data.created_at,
  updatedAt: data.updated_at,
  creator: data.creator,
  isRufree: data.is_rufree,
  comment: data.comment
})

const mapResponseToMatchingHistory = (data: MatchingHistoryResponse) => ({
  id: data.id,
  rufree: data.rufree,
  status: data.status,
  phase: data.phase,
  createdAt: data.created_at,
  updatedAt: data.updated_at,
  dateSend: data.date_send,
  dateResponse: data.date_response,
  dateViewed: data.date_viewed,
  dateAccepted: data.date_accepted,
  isAccepted: data.is_accepted,
  comments: data.comments.map(
    mapResponseToHistoryComment
  ) as any,
  rufreeMatching: data.rufree_matching,
  rufreeName: data.rufree_name,
  rufreeEmail: data.rufree_email,
  rufreePhone: data.rufree_phone,
  rejectReason: data.reject_reason
});

const mapResponseToRufreeMatching = (data: RufreeMatchingResponse) => ({
  id: data.id,
  manager: data.manager,
  projectGroup: data.project_group,
  projectGroupName: data.project_group_name,
  pGStartOrNot: data.pg_start_or_not,
  rufreeOfferSend: data.rufree_offer_send,
  role: data.role,
  status: data.status,
  roleDetail: data.role_detail,
  sprintWage: data.sprint_wage,
  sprintFee: data.sprint_fee,
  sprintNum: data.sprint_num,
  dateDue: data.date_due,
  dateRequest: data.date_request,
  reference: data.reference,
  pmManagerComment: data.pm_manager_comment,
  matchingManagerComment: data.matching_manager_comment,
  finalMatchingManagerComment: data.final_matching_manager_comment,
  needUpdate: data.need_update,
  onRecommend: data.on_recommend,
  inspections: data.inspections,
  matchedRufree: data.matched_rufree,
  matchedRufreeName: data.matched_rufree_name,
  matchedTime: data.matched_time,
  currentTab: data.current_tab,
  matchingHistory: sortBy(data.matching_history.slice(), history => history.id).map(
    mapResponseToMatchingHistory
  ) as any,
  createdAt: data.created_at,
  updatedAt: data.updated_at
});

export const RufreeMatchingStoreModel = types
  .model("RufreeMatchingStore", {
    rufreeMatchings: types.array(RufreeMatchingModel),
    allRufreeMatchings: types.array(RufreeMatchingModel),
    currentMatchingId: types.optional(types.number, 0),

    currentPage: types.optional(types.number, 1),
    pageGroup: types.optional(types.number, 1),
    pageSize: types.optional(types.number, 10),
    totalPages: types.optional(types.number, 1)
  })
  .views(self => ({
    get sortedMatchings() {
      return self.rufreeMatchings;
      // return sortBy(self.rufreeMatchings.slice(), matching => matching.id);
    },
    get currentMatchings() {
      if (self.currentMatchingId > 0 ) {
        return self.rufreeMatchings.filter(matching => self.currentMatchingId == matching.id )[0]
      } else {
        return {}
      }
    }
  }))
  .actions(self => ({
    setCurentPage(value: number) {
      self.currentPage = value;
    },
    setPageGroup(value: number) {
      self.pageGroup = value;
    }
  }))
  .actions(self => {
    const addRufreeMatching = (rufreeMatching: RufreeMatching) => {
      // 여러 element 를 한번에 addRufreeMatching 하면 마지막 element 만 되는듯?
      const modifiedMatchings = putRufreeMatching({
        array: self.rufreeMatchings,
        item: rufreeMatching,
        key: "id"
      });
    };

    const fetchRufreeMatching = flow(function*(id: number) {
      try {
        self.currentMatchingId = id;
        const { data }: { data: RufreeMatchingResponse } = yield axios.get(
          `/rufreeMatching/${id}`
        );
        const rufreeMatching = RufreeMatchingModel.create(
          mapResponseToRufreeMatching(data)
        );
        return rufreeMatching;
        // addRufreeMatching(rufreeMatching);
      } catch (e) {
        console.error("fetchRufreeMatching error", e);
        throw e;
      }
    });

    const deleteRufreeMatching = flow(function*(id: number) {
      try {
        yield axios.delete(`/rufreeMatching/${id}`);
      } catch (e) {
        console.error("deleteRufreeMatching error", e);
        throw e;
      }
    });

    const cancelRufreeMatching = flow(function*(id: number) {
      try {
        yield axios.post(`/rufreeMatching/${id}/cancelRufreeMatching`);
      } catch (e) {
        console.error("cancelRufreeMatching error", e);
        throw e;
      }
    });    

    const restoreRufreeMatching = flow(function*(id: number) {
      try {
        yield axios.post(`/rufreeMatching/${id}/restoreRufreeMatching`);
      } catch (e) {
        console.error("restoreRufreeMatching error", e);
        throw e;
      }
    });    

    const saveRufreeHistoryComment = flow(function*(id: number, comment: string) {
      try {
        yield axios.post(`/rufreeMatchingHistory/${id}/comment`, {
          comment: comment
        });
      } catch (e) {
        console.error("deleteRufreeMatching error", e);
        throw e;
      }
    });

    const storeRufreeMatchingResponses = (
      matchingResponses: RufreeMatchingResponse[]
    ) => {
      self.rufreeMatchings.replace(
        matchingResponses.map(mapResponseToRufreeMatching as any)
      );
    };

    const searchMatchings = flow(function*({
      projectGroup,
      projectName,
      manager,
      role,
      status
    }: {
      projectGroup: string;
      projectName: string;
      manager: string;
      role: string;
      status: string;
    }) {
      try {
        const { data }: { data: RufreeMatchingListResponse } = yield axios.get(
          "/rufreeMatching",
          {
            params: {
              project_group: projectGroup,
              project_group_name: projectName,
              manager: manager,
              role: role,
              status: status,
              page: self.currentPage,
              page_size: self.pageSize
            }
          }
        );
        
        const rufreeMachings = data.results.map(x =>
          RufreeMatchingModel.create(mapResponseToRufreeMatching(x))
        );

        self.totalPages = data.total_pages;
        self.rufreeMatchings.replace(rufreeMachings);
      } catch (e) {
        console.error("searchMatchings error", e);
        throw e;
      }
    });

    const searchQueryMatchings = flow(function*({
      searchInput,
      status,
      orderBy,
      orderAsc
    }: {
      searchInput: string;
      status: string;
      orderBy: string;
      orderAsc: boolean;
    }) {
      try {
        const { data }: { data: RufreeMatchingListResponse } = yield axios.get(
          "/rufreeMatching",
          {
            params: {
              search: searchInput,
              status: status,
              order_by: orderAsc ? orderBy : '-' + orderBy,
              page: self.currentPage,
              page_size: self.pageSize
            }
          }
        );

        const rufreeMachings = data.results.map(x =>
          RufreeMatchingModel.create(mapResponseToRufreeMatching(x))
        );

        self.totalPages = data.total_pages;
        self.rufreeMatchings.replace(rufreeMachings);
      } catch (e) {
        console.error("searchMatchings error", e);
        throw e;
      }
    });

    const fetchAllRufreeMatchings = flow(function*() {
      try {
        const { data }: { data: RufreeMatchingListResponse } = yield axios.get(
            "/rufreeMatching",
            {
              params: {
                project_group: '',
                project_group_name: '',
                manager: '',
                role: '',
                status: '',
                page: 1,
                page_size: 100
              }
            }
        );

        const rufreeMachings = data.results.map(x =>
            RufreeMatchingModel.create(mapResponseToRufreeMatching(x))
        );

        // self.totalPages = data.total_pages;
        self.allRufreeMatchings.replace(rufreeMachings);
      } catch (e) {
        console.error("fetchAllRufreeMatchings error", e);
        throw e;
      }
    });

    return {
      addRufreeMatching,
      fetchRufreeMatching,
      deleteRufreeMatching,
      cancelRufreeMatching,
      restoreRufreeMatching,
      storeRufreeMatchingResponses,
      searchMatchings,
      searchQueryMatchings,
      saveRufreeHistoryComment,
      fetchAllRufreeMatchings
    };
  });

type RufreeMatchingStoreType = typeof RufreeMatchingStoreModel.Type;
export interface RufreeMatchingStore extends RufreeMatchingStoreType {}
