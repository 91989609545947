import React from "react";

import { SprintButtonWrapper as Wrapper } from './_wrapper';


export interface ISprintButton {
  name?: string;  // undefined: 추가, 'PRE, S1~S20': 스프린트
  disabled?: boolean;
  selected?: boolean;
  isActive?: boolean;
  isPre?: boolean;
  
  onClick?: () => void;
};

export const SprintButton: React.FC<ISprintButton> = (props) => {
  const classes: string[] = [];
  props.isActive && classes.push('active');
  props.disabled && classes.push('disabled');
  props.isPre && classes.push('pre');
  props.selected && classes.push('selected');

  return (
    <Wrapper
      className={ classes.join(' ') }
      onClick={ props.onClick }
    >
      {
        props.name ?
        <div>{ props.name }</div>
        : <button />
      }
    </Wrapper>
  );
}
