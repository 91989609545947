import { types, flow, applySnapshot, getParent } from "mobx-state-tree";
import axios from "axios";

import { IOptionProps } from '@blueprintjs/core';

export const STATUS = {
  ALL: 'ALL',
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  // COMPLETED: '승인완료',
  REJECTED: 'REJECTED'
};
export const RESOURCE = {
  PM: 'MNG',
  DESIGNER: 'DES',
  DEVELOPER: 'DEV'
};
export const StatusOptions: IOptionProps[] = [
  { label: '전체', value: STATUS.ALL },
  { label: '승인대기중', value: STATUS.PENDING },
  { label: '승인완료', value: STATUS.APPROVED },
  { label: '승인거절', value: STATUS.REJECTED }
];
export const ResourceOptions: IOptionProps[] = [
  { label: '기획자(운영)', value: RESOURCE.PM },
  { label: '디자이너', value: RESOURCE.DESIGNER },
  { label: '개발자', value: RESOURCE.DEVELOPER },
];
export const CommentModel = types.model("Comment", {
  id: types.number,
  user: types.number,
  user_name: types.string,
  user_email: types.string,
  comment: types.string,
  submit_date: types.string
})
.views(self => ({
  get partnerId() {
    const root: any = getParent(self, 2);
    console.log(root.id);
    return root.id;
  }
}))
.actions(self => {
  const update = flow(function*(comment: string) {
    const parentId = self.partnerId;

    if(!parentId) {
      return;
    }
    try {
      yield axios.patch(
        `/admin/partners/${parentId}/comment/`,
        {
          id: self.id,
          comment: comment
        }
      );
    } catch (e) {
      throw e;
    }
  });

  const remove = flow(function*() {
    const parentId = self.partnerId;

    if(!parentId) {
      return;
    }
    try {
      yield axios.delete(
        `/admin/partners/${parentId}/comment/`,{
          data: {
            id_list: [self.id]
          }
        }
      );
    } catch (e) {
      throw e;
    }
  });
  return {
    remove,
    update
  };
});
type CommentType = typeof CommentModel.Type;
export interface IComment extends CommentType {};


export const PartnerModel = types.model("Partner", {
  id: types.number,
  code: types.identifier,
  email: types.string,
  name: types.string,
  phone: types.string,
  company_name: types.string,
  business_area: types.maybe(types.string),
  in_house_resource: types.array(types.string),
  marketing_agreement:types.boolean,
  // status: types.string, //가입 신청 상태(PENDING, APPROVED, COMPLETED, REJECTED)
  status: STATUS.PENDING || STATUS.APPROVED || STATUS.REJECTED || 'COMPLETED',
  status_detail: types.string,
  date_approved: types.maybeNull(types.string),
  date_rejected: types.maybeNull(types.string),
  date_completed: types.maybeNull(types.string),  //가입 완료 일시(=온보딩 완료 일시)
  created: types.string,
  modified: types.string,
  comments: types.array(CommentModel)
})
.views(self => ({
  get statusToString() {
    // return (STATUS as any)[self.status];
    const finded = StatusOptions.find(status => status.value === self.status);
    return finded ? finded.label : '';
  },
  get resourceToString() {
    return self.in_house_resource.map(resource => {
      const finded = ResourceOptions.find(res => res.value === resource);
      return finded ? finded.label : '';
    })
  },
  get isApproved() {
    return self.status === STATUS.APPROVED;
  },
  get isRejected() {
    return self.status === STATUS.REJECTED;
  },
  get dateApprovedOrRejected(): string | null {
    if(this.isApproved) {
      return self.date_approved;
    } else if (this.isRejected) {
      return self.date_rejected;
    } else {
      return null;
    }
  }
}))
.actions(self => ({
  setRejectComment (comment: string) {
    self.status_detail = comment;
  }
}))
.actions(self => {
  const approve = flow(function*() {
    try {
      const { data } : { data: IPartner } = yield axios.put(
        `/admin/partners/${self.id}/approve/`
      );
      applySnapshot(self, data);
    } catch (e) {
      throw e;
    }
  });
  const revoke = flow(function*() {
    try {
      const { data } : { data: IPartner } = yield axios.put(
        `/admin/partners/${self.id}/revoke/`
      );
      applySnapshot(self, data);
    } catch (e) {
      throw e;
    }
  });
  const reject = flow(function*() {
    try {
      const { data } : { data: IPartner } = yield axios.put(
        `/admin/partners/${self.id}/reject/`, {
          detail: self.status_detail
        }
      );
      applySnapshot(self, data);
    } catch (e) {
      throw e;
    }
  });
  const addComment = flow(function*(comment: string) {
    try {
      const data = yield axios.post(
        `/admin/partners/${self.id}/comment/`, {
          comment: comment
        }
      );
    } catch (e) {
      throw e;
    }
  });

  return {
    approve,
    revoke,
    reject,
    addComment
  };
});

type PartnerType = typeof PartnerModel.Type;
export interface IPartner extends PartnerType {};
