import { types } from "mobx-state-tree";


export const DashboardScheduleFormModel = types
  .model("DashboardScheduleForm", {
    id: types.optional(types.number, -1),
    category: types.optional(types.string, ""),
    schCategory: types.optional(types.string, ""),
    startAt: types.optional(types.string, ""),
    endAt: types.optional(types.string, ""),
    allDay: types.optional(types.boolean, false),
    title: types.optional(types.string, ""),
    comment: types.optional(types.string, ""),
    creator: types.optional(types.number, -1),
    creatorName: types.optional(types.string, ""),
    creatorPmName: types.optional(types.string, ""),
    projectGroup: types.optional(types.string, ""),
    projectGroupName: types.optional(types.string, ""),
    participants: types.optional(types.array(types.number), []),
  })
  .views(self => ({
  }))
  .actions(self => ({
  }))
  .actions(self => ({
    setId(value: number) {
      self.id = value;
    },
    setCategory(value: string) {
      self.category = value;
    },
    setSchCategory(value: string) {
      self.schCategory = value;
    },    
    setStartAt(value: string) {
      self.startAt = value;
    },
    setEndAt(value: string) {
      self.endAt = value;
    },
    setAllDay(value: boolean) {
      self.allDay = value;
    },
    setTitle(value: string) {
      self.title = value;
    },
    setComment(value: string) {
      self.comment = value;
    },
    setCreator(value: number) {
      self.creator = value;
    },
    setCreatorName(value: string) {
      self.creatorName = value;
    }, 
    setCreatorPmName(value: string) {
      self.creatorPmName = value;
    },
    setProjectGroup(value: string) {
      self.projectGroup = value;
    },
    addParticipant(value: number) {
      self.participants.push(value)
    },
    setParticipant(idx: number, value: number) {
      self.participants[idx] = value;
    },
    delParticipant(idx:number) {
      self.participants.splice(idx, 1)
    },
    trimParticipant() {  // 관련자가 선택되지 않은 경우 제거
      self.participants.replace(
        self.participants.filter(x => x > 0)
      )
    }

  }));

type DashboardScheduleFormType = typeof DashboardScheduleFormModel.Type;
export interface DashboardScheduleForm extends DashboardScheduleFormType {}
