import { types } from "mobx-state-tree";


export const TaskListFormModel = types
  .model("TaskListForm", {
    title: types.optional(types.string, ""),
    taskBoardId: types.optional(types.string, ""),
  })
  .views(self => ({
  }))
  .actions(self => ({
    setTitle(value: string) {
      self.title = value;
    },
    setTaskBoardId(value: string) {
      self.taskBoardId = value
    }
  }));

type TaskListFormType = typeof TaskListFormModel.Type;
export interface TaskListForm extends TaskListFormType {}
