import { types } from "mobx-state-tree";

export const InspectionMailModel = types
  .model("Inspection", {
    subject: types.string,
    html: types.string
    // html: types.maybeNull(types.string)
  })

type InspectionMailType = typeof InspectionMailModel.Type;
export interface InspectionMail extends InspectionMailType {}
