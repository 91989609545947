import React, { FC, useState, useEffect } from "react";
import { observer } from "mobx-react";
import styled from "styled-components/macro";
import moment from 'moment';

import { ProjectGroupComment as Comment } from '../../../store/models/ProjectGroup';

import { H6, Button, TextArea, Intent } from '@blueprintjs/core';


////////Sub Cmponent///////////////////////////////////////
const CommentFormWrapper = styled.section`
  display: flex;
  padding: 30px;
  min-height: 120px;

  button {
    width: 70px;
    margin-left: 10px;
  }
`;

interface CommentFormProps {
  value: string,
  onSave: (comment: string) => void
}
const CommentForm: FC<CommentFormProps> = props => {
  const [ comment, setComment ] = useState('');

  useEffect(() => {
    setComment(props.value)
  }, [props.value]);
  
  const textAreaProps = {
    // growVertically: true,
    fill: true,
    value: comment,
    // rows: 5
  }

  return (
    <CommentFormWrapper>
      <TextArea
        { ...textAreaProps }
        onChange={ (e) => setComment(e.target.value) }
      />
      <Button
        text={ props.value ? '수정' : '저장' }
        intent={ Intent.PRIMARY }
        onClick={ () => {
          props.onSave(comment);
          setComment('');
        }} />
    </CommentFormWrapper>
  )
};
//////////////////////////////////////////////////////////


const Wrapper = styled.section`
  h6 { font-weight: bold; }
  .row {
    display: flex;
    * + * {
      margin-left: 15px;
    }
    font-size: 11px;
  }
  .action {
    font-size: 12px;
    color: #137cbd;
    font-weight: bold;
    cursor: pointer;
  }
  .action + .action { margin-left: 5px;}
  div.comment {
    padding: 15px; 0px;
  }
  div.comment + div.comment { border-top: 1px solid #5C7080; }
`;

interface Props {
  currentUserEmail: string,
  comments: Comment[],
  onCreate: (comment: string) => void,
  onUpdate: (id: number, comment: string) => void,
  onDelete: (id: number) => void
}
const ProjectGroupComment: FC<Props> = observer((props) => {
  const [ comments, setComments ] = React.useState<Comment[]>([]);
  const [ editingId, setEditingId ] = useState(0);
  const [ editingComment, setEditingComment ] = useState('');

  useEffect(() => {
    setComments(props.comments)
  }, [props.comments]);

  return (
    <Wrapper>

      <CommentForm
        value={ editingComment }
        onSave={ async (comment: string) => {
          if(editingId) {
            await props.onUpdate(editingId, comment);

            setEditingId(0);
            setEditingComment('');
          } else {
            props.onCreate(comment);
          }
        } } />

      {
        comments.map(comment => {
          const canModify = props.currentUserEmail === 'partner@seeso.kr'
            || props.currentUserEmail === comment.user_email;

          return (
            <div
              className='comment'
              key={ comment.id }>

                <div className='row'>
                  <H6>
                    @{ comment.user_name }
                  </H6>

                  <div>{ moment(comment.submit_date).format('YYYY-MM-DD HH:mm') }</div>

                  {
                    canModify &&
                    <>
                      <div
                        className='action'
                        onClick={ () => {
                          setEditingId(comment.id);
                          setEditingComment(comment.comment);
                        }}>
                        수정
                      </div>
                      <div 
                        className='action'
                        onClick={ () => props.onDelete(comment.id) }>
                        삭제
                      </div>
                    </>
                  }
                  
                </div>

                <p>
                    { comment.comment.split('\n').map((line, i) => <div key={i}>{ line }</div>) }
                </p>

            </div>
          )
        })
      }
    </Wrapper>
  )
});

export default ProjectGroupComment;
