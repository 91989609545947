import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { RouteComponentProps } from "react-router";
import { computed, observable } from "mobx";
import { Spinner } from "@blueprintjs/core";
import SprintReportCard from "../../organisms/SprintReportCard/SprintReportCard";
import Helmet from "react-helmet";
import moment from "moment";

const Wrapper = styled.div`
  padding-left: 35px;
`;

interface PageParams {
  projectGroupId: string;
  sprintReportId: string;
}

interface SprintReportPrintPageProps extends RouteComponentProps<PageParams> {}

interface InjectedProps extends SprintReportPrintPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class SprintReportPrintPage extends Component<SprintReportPrintPageProps> {
  @observable readyToShow = false;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get projectGroup() {
    const { projectGroupId } = this.props.match.params;

    return this.injected.appStore.projectGroupStore.projectGroups.find(
      x => x.groupId === projectGroupId
    );
  }

  @computed
  get sprintReport() {
    if (!this.projectGroup) {
      return null;
    }

    const { sprintReportId } = this.props.match.params;
    return this.projectGroup.sprintReports.find(
      x => x.compoundKey === sprintReportId
    );
  }

  async componentDidMount() {
    try {
      const { projectGroupId } = this.props.match.params;

      const { projectGroupStore, inspectionStore } = this.injected.appStore;

      await projectGroupStore.fetchProjectGroup(projectGroupId);

      if (this.projectGroup) {
        await Promise.all(
          this.projectGroup.inspections.map(async inspectionId => {
            await inspectionStore.fetchInspectionById(inspectionId);
          })
        );
      }
    } catch (e) {
    } finally {
      this.readyToShow = true;
    }
  }

  render() {
    if (!this.readyToShow) {
      return <Spinner />;
    }

    if (!this.sprintReport) {
      return <div>표시할 SprintReport 가 없습니다.</div>;
    }

    return (
      <Wrapper>
        <Helmet>
          <title>{`[시소] ${this.projectGroup &&
            this.projectGroup.name} - 스프린트 보고서 - ${moment().format(
            "YYYYMMDD"
          )}`}</title>
        </Helmet>
        <SprintReportCard sprintReport={this.sprintReport} />
      </Wrapper>
    );
  }
}

export default SprintReportPrintPage;
