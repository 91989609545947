import React, { Component, ChangeEvent } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { computed, observable } from "mobx";
import { Button, Dialog, Classes, FormGroup, InputGroup, TextArea, Intent, Checkbox } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import moment from 'moment';

import { AppStore } from "../../../store/AppStore";
import { FormMode } from "../../../types/formMode";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import { getMomentFormatter } from "../../../utils/date";

const FormGroupLabel = styled.div`
  font-weight: bold;
  width: 120px;
  line-height: 18px;
`;
const DialogFooterLeftWrapper = styled.span`
  position: relative;
  left: -10px;
  margin-left: 0px;
  margin-right: auto;
`;

interface DashboardTaskPopupProps {
  id: number;
  isOpened: boolean;
  onClose: () => void;
}

interface InjectedProps extends DashboardTaskPopupProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class DashboardTaskPopup extends Component<DashboardTaskPopupProps> {
  @observable startDate: Date | undefined = undefined;
  @observable formMode: FormMode = FormMode.Create;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get formStore() {
    return this.injected.appStore.dashboardTaskFormStore;
  }

  @computed
  get currentForm() {
    return this.formStore.currentForm;
  }

  handleAllDayChanged = () => {
    this.currentForm.setAllDay(!this.currentForm.allDay);
    if(this.currentForm.allDay) {
      this.startDate = undefined;
      this.currentForm.setStartAt("");
    } else {
      this.startDate = moment().toDate();
      this.handleSetStartAt();
    }
  }

  handleStartDate = (selectedDate: Date, isUserChange:boolean) => {
    if(isUserChange) {
      this.startDate = selectedDate ? selectedDate : undefined;
    }    
    this.handleSetStartAt();
  }

  handleSetStartAt = () => {
    this.currentForm.setStartAt(`${moment(this.startDate).format('YYYY-MM-DD')}T00:00`)
  }

  handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.currentForm.setTitle(e.target.value);
  };

  handleCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.currentForm.setComment(e.target.value);
  };

  handleChangeToEdit = async () => {
    this.formMode = FormMode.Edit;
  }

  handleSubmit = async () => {
    if(this.currentForm.title === '') {
      AppToaster.show({
        message: "제목은 필수입니다",
        intent: Intent.DANGER
      });      
      return;
    }
    try {
      await this.formStore.postSchedule();
      AppToaster.show({
        message: "새로운 할일이 등록되었습니다.",
        intent: Intent.SUCCESS
      });

      this.props.onClose();
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  }
  handleEditSubmit = async () => {
    if(this.currentForm.title.trim() === '') {
      AppToaster.show({
        message: "제목은 필수입니다",
        intent: Intent.DANGER
      });      
      return;
    }
    try {
      await this.formStore.putSchedule();
      AppToaster.show({
        message: "할일이 수정되었습니다.",
        intent: Intent.SUCCESS
      });

      this.props.onClose();
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  }
  handleDeleteSubmit = async () => {
    try {
      await this.formStore.deleteSchedule();
      AppToaster.show({
        message: "할일이 삭제되었습니다.",
        intent: Intent.SUCCESS
      });

      this.props.onClose();
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }      
  }
  handleDoneSubmit = async () => {
    try {
      this.currentForm.setTaskDone(true);
      await this.formStore.putSchedule();
      AppToaster.show({
        message: "할일을 완료했습니다.",
        intent: Intent.SUCCESS
      });

      this.props.onClose();
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }       
  }

  async componentDidMount() {
  }

  handleOpening = async() => {
     const { id } = this.props;
     const { userId, username } = this.injected.appStore.userStore;

      const pmUser = this.injected.appStore.pmUserStore.pmUsers.find(x => x.user_id === userId)
      await this.formStore.initForm(userId, (typeof(pmUser) == "undefined" || pmUser == null) ? username: pmUser.name);

      if(id > 0) {
        this.formMode = FormMode.Read;
        await this.formStore.fetchSchedule(id);
        if (this.currentForm.startAt === '') {
          this.startDate = undefined;
        } else {
          this.startDate = moment(this.currentForm.startAt).toDate();
        }
      } else {
        this.formMode = FormMode.Create;
        this.startDate = moment().toDate();
      }

      this.handleSetStartAt();
  };

  render() {
    const { isOpened, onClose } = this.props;
    const { userId } = this.injected.appStore.userStore;

    return (
      <Dialog
        onOpening={this.handleOpening}
        isOpen={isOpened}
        usePortal={true}
        autoFocus={true}
        enforceFocus={true}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        isCloseButtonShown={false}
        title={this.formMode === FormMode.Create ? '할일 등록': this.currentForm.title}
      >

      <div className={Classes.DIALOG_BODY}>
        {this.currentForm && (
          <>
            <FormGroup label={<FormGroupLabel>작성자</FormGroupLabel>} inline={true}>
              <div>{this.currentForm.creatorPmName}</div>
            </FormGroup>
            <FormGroup label={<FormGroupLabel>할일제목</FormGroupLabel>} inline={true}>
              {this.formMode === FormMode.Read ? (
                  <div>{this.currentForm.title}</div>
                ) : (
                  <InputGroup
                    value={this.currentForm.title}
                    onChange={this.handleTitleChange}
                    style={{ width: "300px" }}
                  />
              )}
            </FormGroup>

            <FormGroup label={<FormGroupLabel>할일내용(옵션)</FormGroupLabel>} inline={true}>
              {this.formMode === FormMode.Read ? (
                <div>{this.currentForm.comment}</div>
                ) : (
                <TextArea
                  value={this.currentForm.comment}
                  onChange={this.handleCommentChange}
                  style={{ width: "300px" }}
                />
              )}
            </FormGroup>

            <FormGroup label={<FormGroupLabel>날짜</FormGroupLabel>} inline={true}>
            {this.formMode === FormMode.Read ? (
                <div>{this.currentForm.allDay ? '미정' : moment(this.startDate).format("YYYY/MM/DD")}</div>
                ) : (
                <>
                <Checkbox checked={this.currentForm.allDay}
                label="미정"
                onChange={this.handleAllDayChanged}
                inline={true}
                />
                <DateInput
                  {...getMomentFormatter("YYYY-MM-DD")}
                  locale="ko"
                  closeOnSelection={true}
                  placeholder={""}
                  formatDate={(date: Date) => moment(date).format("YYYY-MM-DD")}
                  parseDate={(str) => new Date(str)}
                  value={this.startDate}
                  disabled={this.currentForm.allDay}
                  onChange={this.handleStartDate}
                  maxDate={new Date("2050-01-01")}
                />
                </>
            )}              
            </FormGroup>

            {this.formMode === FormMode.Read && (
              <FormGroup label={<FormGroupLabel>완료여부</FormGroupLabel>} inline={true}>
                <div>{this.currentForm.taskDone ? '예': '아니오'}</div>
              </FormGroup>          
            )}

          </>
        )}
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {
            this.formMode === FormMode.Create && (
              <>
              <Button text="등록" intent={Intent.PRIMARY} onClick={this.handleSubmit} />
              <Button text="취소" onClick={onClose} />
              </>
            )
          }
          {
            this.formMode === FormMode.Read && (
              <>
              {this.currentForm.creator === userId && (
                <DialogFooterLeftWrapper>
                  {this.currentForm.taskDone === false && (
                    <Button text="완료" intent={Intent.SUCCESS} onClick={this.handleDoneSubmit} />
                  )}
                  <Button text="삭제" intent={Intent.DANGER} onClick={this.handleDeleteSubmit} />
                </DialogFooterLeftWrapper>
              )}              
              {this.currentForm.creator === userId && (
                <Button text="수정" intent={Intent.PRIMARY} onClick={this.handleChangeToEdit} />
              )}
              <Button text="닫기" onClick={onClose} />
              </>
            )
          }
          {
            this.formMode === FormMode.Edit && (
              <>
              <Button text="저장" intent={Intent.PRIMARY} onClick={this.handleEditSubmit} />
              <Button text="취소" onClick={onClose} />
              </>
            )
          }
          </div>
      </div>
      </Dialog>
    );
  }
}

export default DashboardTaskPopup;
