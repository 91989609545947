import { types, flow } from "mobx-state-tree";
import axios from "axios";
import { ScheduleManagerFormModel } from './ScheduleManagerForm';
import { ScheduleManagerResponse } from "../../../types/scheduleManager";
import { mapSchedule } from "../../ScheduleManagerStore";


export const ScheduleManagerFormStoreModel = types
  .model("ScheduleManagerFormStore", {
    currentForm: types.optional(ScheduleManagerFormModel, {})
  })
  .actions(self => {
    const initForm = (projectGroup: string, projectGroupName: string, creator: number) => {
      self.currentForm = ScheduleManagerFormModel.create({
        creator: creator,
        projectGroup: projectGroup,
        projectGroupName: projectGroupName
      });
    };

    const mapFormDataForRequest = () => {
      const form = self.currentForm;

      return {
        id: form.id,
        category: 'prg',
        start_at: form.startAt,
        // end_at: form.endAt,
        // all_day: form.allDay,
        title: form.title,
        comment: form.comment,
        creator: form.creator,
        project_group: form.projectGroup
      };
    };


    const postSchedule = flow(function*() {
      try {
        const { data }: { data: ScheduleManagerResponse } = yield axios.post(
          `/schedule`,
          mapFormDataForRequest()
        );

      } catch (e) {
        console.log("postRufreeForm error", e);
        throw e;
      }
    });

    const putSchedule = flow(function*() {
      try {
        const { data }: { data: ScheduleManagerResponse } = yield axios.put(
          `/schedule/${self.currentForm.id}`,
          mapFormDataForRequest()
        );

      } catch (e) {
        console.log("postRufreeForm error", e);
        throw e;
      }
    });

    const fetchSchedule = flow(function*(id: number) {
      try {
        const { data }: { data: ScheduleManagerResponse } = yield axios.get(
          `/schedule/${id}`
        );

        const newForm = ScheduleManagerFormModel.create(mapSchedule(data));
        self.currentForm = newForm;

      } catch (e) {
        console.log("fetchSchedule Form error", e);
        throw e;
      }
    });

    return {
      initForm,
      postSchedule,
      fetchSchedule,
      putSchedule
    };
  });

type ScheduleManagerFormStoreType = typeof ScheduleManagerFormStoreModel.Type;
export interface ScheduleManagerFormStore extends ScheduleManagerFormStoreType {}