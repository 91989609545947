import React from "react";
import styled from "styled-components/macro";

const Wrapper = styled.div`
  border-top: 1px solid #EDEDFF;
  margin-top: 40px;
  padding-top: 15px;
  text-align: right;

  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #7E838F;
  }
  span:first-child {
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    color: #333333;
    margin-right: 8px;
  }
  span.amount {
    font-weight: bold;
    font-size: 24px;
    line-height: 35px;
    color: #6241FE;
    margin-left: 40px;
  }
  span.amount::after {
    content: '원';
    margin-left: 2px;
  }
`;

export interface IResourceSummary {
  amount: number;
}

export const ResourceSummary: React.FC<IResourceSummary> = (props) => {
  return (
    <Wrapper>
      <span>예상 총 비용</span>
      <span>VAT 별도</span>
      <span className='amount'>{ props.amount.toLocaleString("en-US") }</span>
    </Wrapper>
  )
}
