import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import FormContainer from "../../atoms/FormContainer/FormContainer";
import { Intent, Spinner, H4, FormGroup, Button } from "@blueprintjs/core";
import { observable, computed } from "mobx";
import { RouteComponentProps } from "react-router";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import ProjectGroupRiskFormView from "../../molecules/ProjectGroupRiskFormView/ProjectGroupRiskFormView";
import {
  ItemRow,
  RemoveButtonContainer,
  RemoveButton
} from "../../atoms/RemoveItemButton/RemoveItemButton";

const Wrapper = styled.div``;

const Section = styled.section`
  margin-bottom: 20px;
`;

const AddButtonContainer = styled.div`
  margin-bottom: 30px;
`;

const ButtonsRow = styled.div`
  & > * + * {
    margin-left: 5px;
  }
`;

interface PageParams {
  projectGroupId: string;
}

interface ProjectGroupRiskFormPageProps
  extends RouteComponentProps<PageParams> {}

interface InjectedProps extends ProjectGroupRiskFormPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ProjectGroupRiskFormPage extends Component<
  ProjectGroupRiskFormPageProps
> {
  @observable isFormInitiated = false;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get formStore() {
    return this.injected.appStore.projectGroupFormStore;
  }

  @computed
  get currentForm() {
    return this.formStore.currentForm;
  }

  async componentDidMount() {
    const { projectGroupId } = this.props.match.params;

    await this.formStore.initForm();
    await this.formStore.fetchProjectGroup(projectGroupId);

    if (!this.formStore.currentForm.risks.length) {
      this.formStore.fillDefaultRisks();
    }

    this.isFormInitiated = true;
  }

  handleAddEtc = () => {
    this.currentForm.addEtcRisk();
  };

  handleEdit = async () => {
    try {
      await this.formStore.patchProjectGroup();

      AppToaster.show({
        message: "수정사항이 반영되었습니다.",
        intent: Intent.SUCCESS
      });
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  handleCancel = () => {
    this.props.history.push(
      `/project-groups/${this.props.match.params.projectGroupId}`
    );
  };

  render() {
    if (!this.isFormInitiated) {
      return <Spinner />;
    }

    if (!this.currentForm) {
      return <div>프로젝트그룹을 찾을 수 없습니다.</div>;
    }

    return (
      <Wrapper>
        <FormContainer>
          <Section>
            <FormGroup
              label={
                <H4>
                  <strong>
                    {this.currentForm.groupId} - {this.currentForm.name}
                  </strong>
                </H4>
              }
            />
          </Section>
          <Section>
            {this.currentForm.risks.map((risk, index) => {
              if (risk.etc) {
                return (
                  <ItemRow key={index}>
                    <RemoveButtonContainer>
                      <RemoveButton
                        onClick={() => this.currentForm.removeRisk(risk)}
                      />
                    </RemoveButtonContainer>
                    <ProjectGroupRiskFormView risk={risk} />
                  </ItemRow>
                );
              }

              return (
                <ItemRow key={index}>
                  <ProjectGroupRiskFormView risk={risk} />
                </ItemRow>
              );
            })}
          </Section>
          <AddButtonContainer>
            <Button icon="add" text="항목 추가" onClick={this.handleAddEtc} />
          </AddButtonContainer>
          <ButtonsRow>
            <Button text="취소" onClick={this.handleCancel} />
            <Button
              text="저장"
              intent={Intent.PRIMARY}
              onClick={this.handleEdit}
            />
          </ButtonsRow>
        </FormContainer>
      </Wrapper>
    );
  }
}

export default ProjectGroupRiskFormPage;
