import { types } from "mobx-state-tree";

export const SubTaskModel = types.model("SubTask", {
  id: types.number,
  subTaskId: types.string,
  subTaskListId: types.string, //연관 섭테스크 리스트 아이디

  title: types.string,
  checked: types.boolean,

  order: types.integer,
  createdAt: types.string,
  updatedAt: types.string
}).actions(
  self => ({
    setTitle(value: string) {
      self.title = value;
    },
    setChecked(value: boolean) {
      self.checked = value;
    },
    toggleChecked() {
      self.checked = !self.checked;
    }
  }));

type SubTaskType = typeof SubTaskModel.Type;

export interface SubTask extends SubTaskType {
}