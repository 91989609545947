import React, { Component } from "react";
import styled from "styled-components/macro";

const Wrapper = styled.div`
  margin-bottom: 40px;
`;

interface FormSectionProps {}

class FormSection extends Component<FormSectionProps> {
  render() {
    return <Wrapper>{this.props.children}</Wrapper>;
  }
}

export default FormSection;
