import React, { Component } from "react";
import { computed } from "mobx";

import { inject, observer } from "mobx-react";

import { Draggable, Droppable } from "react-beautiful-dnd";

import { AppStore } from "../../../store/AppStore";

import TaskBulletinPost from "./TaskBulletinPost";

interface TaskBulletinPostListProps {
  id: number,
  listId: string,
  listType: string,
  style?: Object,
  internalScroll: boolean,
  isCombineEnabled: boolean,
  useClone: boolean;
}

interface InjectedProps extends TaskBulletinPostListProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class TaskBulletinPostList extends Component<TaskBulletinPostListProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get store() {
    return this.injected.appStore.taskManagerStore;
  }

  render() {
    const bulletin = this.store.currentBulletins.find(b => b.id === this.props.id);

    return (
      <>
      {bulletin && (
        <Droppable droppableId={`b-${bulletin.id}`} type={'post'}>
          {(droppableProvided, droppableSnapshot) => (
            <div
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
              style={{background: droppableSnapshot.isDraggingOver ? "lightgrey": ""}}
            >
              {bulletin.sortedTaskBulletinPosts.map((post, index) => (
                <Draggable key={post.id} draggableId={`p-${post.id}`} index={index}>
                  {(draggableProvided, draggableSnapshot) => (
                    <div
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                      ref={draggableProvided.innerRef}
                      style={{
                        ...draggableProvided.draggableProps.style
                      }}
                    >
                      <TaskBulletinPost 
                        task_bulletin={post.task_bulletin}
                        id={post.id} 
                        title={post.title}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      )}
      </>

    );
  }
}

export default TaskBulletinPostList;