import { types } from "mobx-state-tree";
import moment from 'moment';

import { ScheduleManagerFormModel } from "./ScheduleManagerForm";

// 입력 개체 선언
export const DashboardOfficeHourFormModel = types
  .model("DashboardOfficeHourForm", {
    schedules: types.array(types.array(ScheduleManagerFormModel)),  // 요일 리스트 >> 요일 별 일정 리스트
    scheduleIdsTobeDeleted: types.array(types.number)               // 삭제할 일정 저장 용도
  })
  .views(self => ({

  }))
  .actions(self => ({
    // 새로운 일정 추가 (기본값)
    newSchedule(creator:number, dayOfWeek:number) {
      let doW = dayOfWeek + 1;

      // 일요일에 입력하면 moment 가 다음주 기준이 되어 보정
      if(moment().isoWeekday() === 7) {
        doW -= 7;
      }

      // 새로운 값을 추가
      self.schedules[dayOfWeek].push(
        ScheduleManagerFormModel.create({
          // 아래는 상수이므로 변경하면 안됨
          id: 0,
          category: 'ofh',
          creator: creator,
          // 기본값은 변경 가능
          startAt: moment().day(doW).hour(9).minute(0).format("YYYY-MM-DDTHH:mm"),
          endAt: moment().day(doW).hour(18).minute(0).format("YYYY-MM-DDTHH:mm"),
          title: ''
        })
      );
    },

    // fetch 해서 가져온 일정 추가
    addSchedule(id:number, creator: number, dayOfWeek: number, startAt: string, endAt: string, place: string) {
      self.schedules[dayOfWeek].push(
        ScheduleManagerFormModel.create({
          id: id,
          category: 'ofh',
          creator: creator,
          startAt: startAt,
          endAt: endAt,
          title: place
        })
      );
    },

    deleteSchedule(dayOfWeek:number, idx: number) {
      let scheduleId = self.schedules[dayOfWeek][idx].id;
      if (scheduleId !== 0) {
        // + 버튼을 눌러서 새로 만든 일정은 id=0 이므로, 추후 db에서 삭제할 필요 없음
        self.scheduleIdsTobeDeleted.push(scheduleId)
      }
      
      self.schedules[dayOfWeek].splice(idx, 1)  // 삭제
    },

    // 시작시간 수정
    updateStartAt(dayOfWeek: number, idx: number, hour: string, minute: string) {
      self.schedules[dayOfWeek][idx].startAt = 
        moment(self.schedules[dayOfWeek][idx].startAt)
          .hour(Number(hour))
          .minute(Number(minute))
          .format("YYYY-MM-DDTHH:mm");
    },

    // 종료시간 수정
    updateEndAt(dayOfWeek: number, idx: number, hour: string, minute: string) {
      self.schedules[dayOfWeek][idx].endAt = 
        moment(self.schedules[dayOfWeek][idx].endAt)
          .hour(Number(hour))
          .minute(Number(minute))
          .format("YYYY-MM-DDTHH:mm");
    },

    // 장소 수정
    updatePlace(dayOfWeek: number, idx: number, place: string) {
      self.schedules[dayOfWeek][idx].title = place;
    },

    // 근무시간 복사
    copySchedule(vacations: Array<string>, holidays: Array<string>) {
      // 현재 입력된 값이 있다면 삭제 처리
      for(let i=7; i<14; i++) {
        let daily_schedules = self.schedules[i];

        for(let j=0; j<daily_schedules.length; j++) {
          let scheduleId = daily_schedules[j].id;
          if (scheduleId !== 0) {
            // + 버튼을 눌러서 새로 만든 일정은 id=0 이므로, 추후 db에서 삭제할 필요 없음
            self.scheduleIdsTobeDeleted.push(scheduleId)
          }
        }
        daily_schedules.clear();
      }

      // 이번주에 해당하는 값을 다음주로 새로 추가
      for(let i=0; i<7; i++) {
        let daily_schedules = self.schedules[i];

        for(let j=0; j<daily_schedules.length; j++) {
          let prev_schedule = daily_schedules[j];
          let start_at = moment(prev_schedule.startAt, "YYYY-MM-DDTHH:mm").add(7, 'days');
          let end_at = moment(prev_schedule.endAt, "YYYY-MM-DDTHH:mm").add(7, 'days');

          // 다음주에 휴일/휴가가 있으면 복사하지 않는다
          if(vacations.includes(start_at.format("MMDD"))) {
            continue;
          }

          // 다음주에 공휴일이 있으면 복사하지 않는다.
          if(holidays.includes(start_at.format("YYYYMMDD"))) {
            continue;
          }

          // 다음주에 이번주 값을 추가함
          self.schedules[i+7].push(
            ScheduleManagerFormModel.create({
              // 아래는 상수이므로 변경하면 안됨
              id: 0,
              category: 'ofh',
              creator: prev_schedule.creator,
              // 기본값은 변경 가능
              startAt: start_at.format("YYYY-MM-DDTHH:mm"),
              endAt: end_at.format("YYYY-MM-DDTHH:mm"),
              title: prev_schedule.title
            })
          );          
        }
      }      
    },

    clearSchedule() {
      self.schedules.clear();
      self.scheduleIdsTobeDeleted.clear();
    },

  }));

type DashboardOfficeHourFormType = typeof DashboardOfficeHourFormModel.Type;
export interface DashboardOfficeHourForm extends DashboardOfficeHourFormType {}