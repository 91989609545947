import { types, flow } from "mobx-state-tree";
import { PMUserModel } from "./models/PMUser";
import axios from "axios";
import { PmUserResponse } from "../types/pmUser";

export const PMUserStoreModel = types
  .model("PMUserStore", {
    pmUsers: types.array(PMUserModel)
  })
  .views(self => ({
    get receptionAuthors() {
      const names = ["박병규", "이동근"];

      return [
        { id: -1, user_id: -1, name: "미정", nickname: "미정", email: "", phone: "", color: "", 
          is_support: false, is_manager: false, is_intern: false, date_joined: '2016-08-01' },
        ...self.pmUsers.filter(x => names.includes(x.name))
      ];
    },
    get needReload() {
      if (self.pmUsers.length < 1) {
        return true
      }
      return false;
    },
    pmById(pmId: number) {
      return self.pmUsers.find(x => x.id === pmId);
    },
    pmByUserId(pmUserId: number) {
      return self.pmUsers.find(x => x.user_id === pmUserId);
    },
    pmByEmail(email: string) {
      return self.pmUsers.find(x => x.email === email);
    }
  }))
  .actions(self => {
    const fetchPMUsers = flow(function*() {
      try {
        const { data }: { data: PmUserResponse[] } = yield axios.get(
          "/pmUsers"
        );
        self.pmUsers.replace(data);
      } catch (e) {
        console.log("fetchPMUsers error ", e);
        throw e
      }
    });

    return {
      fetchPMUsers
    };
  });

type PMUserStoreType = typeof PMUserStoreModel.Type;
export interface PMUserStore extends PMUserStoreType {}
