import styled from "styled-components/macro";

export const Wrapper = styled.div`
  font-family: 'Noto Sans KR';
  width: 1053px;
  h2, h3, h4 {
    margin: 0;
  }
  h3 {
    margin-bottom: 10px;
  }
  h2 {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    margin-bottom: 20px;
  }
`;

export const ResourceSection = styled.section`
width: 1053px;
background: #FCFCFF;
border: 1px solid #EDEDFF;
box-sizing: border-box;
border-radius: 10px;
padding: 25px 30px 60px 25px;

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;

  color: #6241FE;
}
`;