import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { NavLink } from "react-router-dom";
import IconApplication from "./ic-menu-application.svg";
import IconDoc from "./ic-menu-doc.svg";
import IconProject from "./ic-menu-project.svg";
import IconStats from "./ic-menu-stats.svg"
import IconMenuMy from "./ic-menu-my.svg";
import IconLogout from "./ic-menu-logout.svg";
import IconConsult from "./ic-menu-consult.svg";
import IconClient from "./ic-menu-client.svg";
import IconRufree from "./ic-menu-ruf.svg";
import IconRufP from "./ic-menu-ruf-p.svg";
import IconManager from "./ic-menu-manager.svg";
import IconCalculate from "./ic-menu-cal.svg";
import IconContract from "./ic-menu-contract.svg";
import IconFix from "./ic-menu-fix.svg";
import IconTemplate from "./ic-menu-template.svg";
import { Button, Tooltip, Position, Icon } from "@blueprintjs/core"
import { observable, computed } from "mobx";

import SideBarMenu from "./SideBarMenu";

const Wrapper = styled.div<{collapsed:boolean}>`
  z-index: ${ p => p.collapsed ? 'initial' : '999 !important' };
  width: ${ p => p.collapsed ? '70px' : '250px' } !important;
  min-height: 100vh;
  position: relative;
  background: #f8f8f8;
  padding: 40px 25px;
  overflow-y: ${ p => p.collapsed ? 'unset' : 'auto' };
`;

const List = styled.div`
  & > * + * {
    margin-top: 20px;
  }
`;

const Item = styled(NavLink)`
  display: flex;
  align-items: center;
  cursor: pointer;

  .bp3-icon { margin-top: 5px; }
`;

const SubMenu = styled.div`
`;

interface NewSideBarProps {
  collapsed: boolean;
}

interface InjectedProps extends NewSideBarProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class NewSideBar extends Component<NewSideBarProps> {
  @observable isLoggedOut = false;
  @observable isLoadingProjectGroups = false;

  @observable collapsed = this.props.collapsed;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get currentPM() {
    const { email } = this.injected.appStore.userStore;
    return this.injected.appStore.pmUserStore.pmByEmail(email);
  }

  async componentDidMount() {
    const {
      isLoggedIn,
      isPM,
      isIntern,
      myProjectGroups,
      fetchMyProjectGroups
    } = this.injected.appStore.userStore;

    if (isLoggedIn && isPM && !isIntern && myProjectGroups.length === 0) {
      this.isLoadingProjectGroups = true;
      await fetchMyProjectGroups();
      this.isLoadingProjectGroups = false;
    }

    if (isLoggedIn) {
      if(this.injected.appStore.pmUserStore.needReload) {
        await this.injected.appStore.pmUserStore.fetchPMUsers();
      }
    }
  }

  handleSubItem = (e: any, path: string) => {
    e.preventDefault();
    window.location.href = path;
  }

  handleLogout = () => {
    this.injected.appStore.userStore.logout();

    alert("로그아웃 되었습니다.");

    window.location.href = "/";
  };

  render() {
    const { isLoggedIn, email, isPM, isIntern, myProjectGroups } = this.injected.appStore.userStore;

    return (
      <Wrapper collapsed={this.collapsed}>
        {
          isPM &&
            <List>

              {
                !window.location.href.includes('taskmgr') &&
                  <Button
                    icon={ this.collapsed ? 'double-chevron-right' : 'double-chevron-left'}
                    minimal={true}
                    style={{
                      position: 'absolute',
                      right: '20px',
                      top: '20px'
                    }}
                    onClick={ () => { this.collapsed = !this.collapsed }} />
              }

              <SideBarMenu
                collapsed={this.collapsed}
                icon={IconMenuMy}
                title={ this.currentPM ? this.currentPM.nickname : email }
                to={"/"}
              >
                <SubMenu
                  onClick={(e: any) => {this.handleSubItem(e, '/auth')}}
                >
                  비밀번호수정
                </SubMenu>
              </SideBarMenu>

              {
                !isIntern &&
                  <SideBarMenu
                    collapsed={this.collapsed}
                    title={'세컨팀'}
                    to={"/secondteam"}
                  >
                    <SubMenu onClick={(e: any) => this.handleSubItem(e, `/secondteam/partner`)} >
                      파트너 회원 관리
                    </SubMenu>
                    <SubMenu onClick={(e: any) => this.handleSubItem(e, `/secondteam/member`)} >
                      세컨팀 멤버 관리
                    </SubMenu>
                    <SubMenu onClick={(e: any) => this.handleSubItem(e, `/secondteam/invitation`)} >
                      초대코드 관리
                    </SubMenu>
                    {/* <SubMenu onClick={(e: any) => this.handleSubItem(e, `/secondteam/calculate`)} >
                      정산
                    </SubMenu> */}
                  </SideBarMenu>
              }

            {
              !isIntern &&
                <SideBarMenu
                  collapsed={this.collapsed}
                  icon={IconApplication}
                  title={'프로젝트 의뢰'}
                  to={"/receptions"} />
            }

            {
              !isIntern &&
                <SideBarMenu
                  collapsed={this.collapsed}
                  icon={IconConsult}
                  title={'영업상담'}
                  to={"/consulting"} />
            }

            {
              !isIntern &&
                <SideBarMenu
                  collapsed={this.collapsed}
                  icon={IconDoc}
                  title={'프로젝트 플랜'}
                  to={"/inspections"} />
            }

            {
              !isIntern &&
                <SideBarMenu
                  collapsed={this.collapsed}
                  icon={IconProject}
                  title={'프로젝트 그룹'}
                  to={"/project-groups"}
                >
                  {
                    myProjectGroups.length > 0 &&
                    <>
                      {
                        myProjectGroups.map(p => (
                          <SubMenu key={p.groupId} onClick={(e: any) => this.handleSubItem(e, `/project-groups/${p.groupId}`)} >
                            <strong>[{p.groupId}]</strong> {p.name}
                          </SubMenu>
                        ))
                      }
                    </>
                  }
                </SideBarMenu>
            }

            {
              !isIntern &&
                <SideBarMenu
                  collapsed={this.collapsed}
                  icon={IconClient}
                  title={'클라이언트'}
                  to={"/clients"} />
            }

            {
              !isIntern &&
                <SideBarMenu
                  collapsed={this.collapsed}
                  icon={IconRufree}
                  title={'알유프리'}
                  to={"/rufrees"} />
            }

            {
              !isIntern &&
                <SideBarMenu
                  collapsed={this.collapsed}
                  icon={IconRufP}
                  title={'알유프리 매칭'}
                  to={"/rufree-matchings"} />
            }

            {
              !isIntern &&
                <SideBarMenu
                  collapsed={this.collapsed}
                  icon={IconManager}
                  title={'매니저 일지'}
                  to={"/pm-reports"} />
            }

            {
              !isIntern &&
                <SideBarMenu
                  collapsed={this.collapsed}
                  icon={IconContract}
                  title={'계약'}
                  to={"/contract"} />
            }

            {
              !isIntern &&
                <SideBarMenu
                  collapsed={this.collapsed}
                  icon={IconCalculate}
                  title={'정산'}
                  to={"/calculate"} />
            }

            {
              !isIntern &&
                <SideBarMenu
                  collapsed={this.collapsed}
                  icon={IconFix}
                  title={'보수요청'}
                  to={"/tasktask"} />
            }

            {
              !isIntern &&
                <SideBarMenu
                  collapsed={this.collapsed}
                  icon={IconProject}
                  title={'시간제 태스크'}
                  to={"/parttime"} />
            }

            {
              !isIntern &&
                <SideBarMenu
                  collapsed={this.collapsed}
                  icon={IconTemplate}
                  title={'템플릿 관리'}
                  to={"/templates"} />
            }

            {
              !isIntern &&
                <SideBarMenu
                  collapsed={this.collapsed}
                  icon={IconStats}
                  title={'통계'}
                  to={"/receptions/stats"} />
            }

            {
              isLoggedIn &&
                <SideBarMenu
                  collapsed={this.collapsed}
                  icon={IconLogout}
                  title={'로그아웃'}
                  onClick={this.handleLogout} />
                // <Item to="#" onClick={this.handleLogout}>
                //   {this.props.collapsed ? (
                //     <Tooltip content={'로그아웃'} position={Position.RIGHT}>
                //       <ItemIcon src={IconLogout} />
                //     </Tooltip>              
                //   ) : (
                //     <>
                //       <ItemIcon src={IconLogout} />
                //       <ItemLabel>로그아웃</ItemLabel>
                //     </>
                //   )}               
                // </Item>
            }

            {
              !isIntern &&
                <Item to="" onClick={() => {
                  const newWindow = window.open("about:blank");
                    if(newWindow) {
                      newWindow.location.href='https://www.notion.so/Product-Updates-0a42bee1ee2245e48bdb0dffa884c436'
                    }
                }}>
                  {this.collapsed ? (
                    <Tooltip content={'Product Updates'} position={Position.RIGHT}>
                      <Icon
                        icon={'flame'}
                        style={{paddingRight: '10px'}} />
                    </Tooltip>  
                  ) : (
                    <>
                    <Icon
                      icon={'flame'}
                      style={{paddingRight: '10px'}} />
                      <strong>Product Updates</strong>
                    </>
                  )}
                </Item>
            }
          </List>
        }
        
      </Wrapper>
    );
  }
}

export default NewSideBar;
