import React, { Component } from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { ItemPredicate, Select, IItemRendererProps } from "@blueprintjs/select";
import { ApprovedClientContract } from "../../../store/models/ApprovedContract";
import { computed } from "mobx";
import { MenuItem, Button, Alignment } from "@blueprintjs/core";
import { highlightText } from "../../../utils/BlueprintSelectionHelpers";


export const filterItem: ItemPredicate<ApprovedClientContract> = (
  query,
  item,
  _index,
  exactMatch
) => {
  const normalizedTitle = item.contractName.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
    return normalizedTitle === normalizedQuery;
  } else {
    return `${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
  }
};

const ItemSelect = Select.ofType<ApprovedClientContract>();

interface ApprovedClientContractSelectionProps {
  onChange: (contract: ApprovedClientContract) => void;
  currentValue?: ApprovedClientContract;
  items?: ApprovedClientContract[]; // items 를 지정하면 전체목록을 불러오지 않고 해당 컬렉션 내에서만 검색
  blockFetchingData?: boolean;
}

interface InjectedProps extends ApprovedClientContractSelectionProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ApprovedClientContractSelection extends Component<ApprovedClientContractSelectionProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get items() {
    return this.injected.appStore.contractStore.approvedClientContracts;
  }

  async componentDidMount() {
    await this.injected.appStore.contractStore.fetchApprovedClientContracts({
      status: '',
      searchText: ''
    });
  }

  itemRenderer = (
    item: ApprovedClientContract,
    { handleClick, query, modifiers }: IItemRendererProps
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        key={item.approvedContractId}
        active={modifiers.active}
        text={highlightText(`${item.approvedContractId} - ${item.contractName}`, query)}
        onClick={handleClick}
      />
    );
  };

  render() {
    const { onChange, currentValue } = this.props;

    return (
        <ItemSelect
          key={this.items.length}
          items={this.items}
          onItemSelect={onChange}
          itemRenderer={this.itemRenderer}
          itemPredicate={filterItem}
          popoverProps={{ minimal: true }}
        >
          <Button
            rightIcon="caret-down"
            fill={true}
            text={currentValue ? `${currentValue.approvedContractId} - ${currentValue.contractName}` : "선택해주세요"}
          />
        </ItemSelect>
    );
  }
}

export default ApprovedClientContractSelection;
