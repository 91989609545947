import { types, Instance } from "mobx-state-tree";
import { UserStoreModel } from "./UserStore";
import { ProjectStoreModel } from "./ProjectStore";
import { ConsultStoreModel } from "./ConsultStore";
import { RiskStoreModel } from "./models/RiskStore";
import { CreateProjectFormStoreModel } from "./forms/CreateProjectForm";
import { ClientStoreModel } from "./ClientStore";
import { InspectionStoreModel } from "./InspectionStore";
import { InspectionTemplateStoreModel } from "./InspectionTemplateStore";
import { InspectionFormStoreModel } from "./forms/InspectionForm";
import { RufreeStoreModel } from "./RufreeStore";
import { RufreeFormStoreModel } from "./forms/RufreeForm/RufreeForm";
import { ProjectGroupFormStoreModel } from "./forms/ProjectGroupForm/ProjectGroupForm";
import { ProjectGroupStoreModel } from "./ProjectGroupStore";
import { ScheduleFormStoreModel } from "./forms/ScheduleForm/ScheduleFormStore";
import { PMReportFormStoreModel } from "./forms/PMReportForm/PMReportFormStore";
import { PMReportStoreModel } from "./PMReportStore";
import { PMUserStoreModel } from "./PMUserStore";
import { ReceptionStoreModel } from "./ReceptionStore";
import { ClientUserStoreModel } from "./ClientUserStore";
import { RufreeMatchingStoreModel } from "./RufreeMatchingStore";
import { RufreeMatchingFormStoreModel } from "./forms/RufreeMatchingForm/RufreeMatchingFormStore";
import { RufreeMatchingCandidateStoreModel } from "./RufreeMatchingCandidateStore"
import { ClientFormStoreModel } from "./forms/ClientForm/ClientFormStore";
import { CalculateStoreModel } from "./CalculateStore";
import { ContractStoreModel } from "./ContractStore";
import { TasktaskStoreModel } from "./TasktaskStore";
import { CalculateFormStoreModel } from "./forms/CalculateForm/CalculateFormStore";
import { TasktaskFormStoreModel } from "./forms/TasktaskForm/TasktaskFormStore";
import { ScheduleManagerStoreModel } from "./ScheduleManagerStore";
import { ScheduleManagerFormStoreModel } from "./forms/ScheduleManagerForm/ScheduleManagerFormStore";
import { DashboardOfficeHourFormStoreModel } from "./forms/ScheduleManagerForm/DashboardOfficeHourFormStore";
import { DashboardTaskFormStoreModel } from "./forms/ScheduleManagerForm/DashboardTaskFormStore";
import { DashboardScheduleFormStoreModel } from "./forms/ScheduleManagerForm/DashboardScheduleFormStore";
import { ContractFormStoreModel } from "./forms/ContractForm/ContractFormStore";
import { ContractTemplateFormStoreModel } from "./forms/ContractTemplateForm/ContractTemplateFormStore";
import { TaskManagerStoreModel } from "./TaskManagerStore";
import { TaskListFormStoreModel } from "./forms/TaskManagerForm/TaskListFormStore";
import { TaskFormStoreModel } from "./forms/TaskManagerForm/TaskFormStore";
import { ParttimeRequestStoreModel, ParttimeTaskStoreModel } from "./ParttimeStore";
import { ReferenceFileFormStoreModel } from "./forms/ParttimeForm/ReferenceFileForm";
import { ReferenceLinkFormStoreModel } from "./forms/ParttimeForm/ReferenceLinkForm";
import { RequestCancelFormStoreModel } from "./forms/ParttimeForm/RequestCancelFormStore";
import { ParttimeTaskFormStoreModel } from "./forms/ParttimeForm/TaskForm";
import { TaskCompleteFormStoreModel } from "./forms/ParttimeForm/TaskCompleteForm";
import { ParttimeRequestFormStoreModel } from "./forms/ParttimeForm/RequestForm";
import { SeesoBotStoreModel } from "./SeesoBotStore";
import { NotificationTemplateStoreModel } from "./NotificationTemplateStore";
import { NotificationLogStoreModel } from "./NotificationLogStore";


/*
  세컨팀 관련 store
*/
import { MemberStoreModel } from './MemberStore';
import { PartnerStoreModel } from './PartnerStore';
import { InvitationStoreModel } from './InvitationStore';

import { FormMode } from "../types/formMode";

export const AppStoreModel = types.model("AppStore", {
  userStore: types.optional(UserStoreModel, {}),
  projectStore: types.optional(ProjectStoreModel, {}),
  consultStore: types.optional(ConsultStoreModel, {}),
  riskStore: types.optional(RiskStoreModel, {}),
  clientStore: types.optional(ClientStoreModel, {}),
  inspectionStore: types.optional(InspectionStoreModel, {}),
  inspectionTemplateStore: types.optional(InspectionTemplateStoreModel, {templates: [], isLoading: false, openPopup: false, formMode: FormMode.Read}),
  rufreeStore: types.optional(RufreeStoreModel, {}),
  projectGroupStore: types.optional(ProjectGroupStoreModel, {}),
  pmReportStore: types.optional(PMReportStoreModel, {}),
  pmUserStore: types.optional(PMUserStoreModel, {}),
  receptionStore: types.optional(ReceptionStoreModel, {}),
  clientUserStore: types.optional(ClientUserStoreModel, {}),
  rufreeMatchingStore: types.optional(RufreeMatchingStoreModel, {}),
  calculateStore: types.optional(CalculateStoreModel, {}),
  contractStore: types.optional(ContractStoreModel, {}),
  tasktaskStore: types.optional(TasktaskStoreModel, {}),
  scheduleManagerStore: types.optional(ScheduleManagerStoreModel, {}),
  taskManagerStore: types.optional(TaskManagerStoreModel, {}),
  RufreeMatchingCandidateStore: types.optional(RufreeMatchingCandidateStoreModel, {}),
  parttimeRequestStore: types.optional(ParttimeRequestStoreModel, {}),
  parttimeTaskStore: types.optional(ParttimeTaskStoreModel, {}),
  seesobotStore: types.optional(SeesoBotStoreModel, {}),
  notificationTemplateStore: types.optional(NotificationTemplateStoreModel, {templates: [], isLoading: false, openPopup: false, formMode: FormMode.Read}),
  notificationLogStore: types.optional(NotificationLogStoreModel, { isFetching: false }),

  /*
    세컨팀 관련 store
  */
 partnerStore: types.optional(PartnerStoreModel, {}),
 memberStore: types.optional(MemberStoreModel, {}),
 invitationStore: types.optional(InvitationStoreModel, {}),

  // Forms
  createProjectFormStore: types.optional(CreateProjectFormStoreModel, {}),
  inspectionFormStore: types.optional(InspectionFormStoreModel, {}),
  rufreeFormStore: types.optional(RufreeFormStoreModel, {}),
  projectGroupFormStore: types.optional(ProjectGroupFormStoreModel, {}),
  scheduleFormStore: types.optional(ScheduleFormStoreModel, {}),
  pmReportFormStore: types.optional(PMReportFormStoreModel, {}),
  rufreeMatchingFormStore: types.optional(RufreeMatchingFormStoreModel, {}),
  clientFormStore: types.optional(ClientFormStoreModel, {}),
  calculateFormStore: types.optional(CalculateFormStoreModel, {}),
  tasktaskFormStore: types.optional(TasktaskFormStoreModel, {}),
  scheduleManagerFormStore: types.optional(ScheduleManagerFormStoreModel, {}),
  dashboardOfficeHourFormStore: types.optional(DashboardOfficeHourFormStoreModel, {}),
  dashboardTaskFormStore: types.optional(DashboardTaskFormStoreModel, {}),
  dashboardScheduleFormStore: types.optional(DashboardScheduleFormStoreModel, {}),
  contractFromStore: types.optional(ContractFormStoreModel, {}),
  contractTemplateFromStore: types.optional(ContractTemplateFormStoreModel, {}),
  taskManagerTaskListFormStore: types.optional(TaskListFormStoreModel, {}),
  taskManagerTaskFormStore: types.optional(TaskFormStoreModel,{}),
  parttimeRequestCancelFormStore: types.optional(RequestCancelFormStoreModel,{}),
  referenceFileFormStore: types.optional(ReferenceFileFormStoreModel,{}),
  ReferenceLinkFormStore: types.optional(ReferenceLinkFormStoreModel,{}),
  parttimeTaskFormStore: types.optional(ParttimeTaskFormStoreModel,{}),
  parttimeTaskCompleteFormStore: types.optional(TaskCompleteFormStoreModel,{}),
  parttimeRequestFormStore: types.optional(ParttimeRequestFormStoreModel,{}),
});

export interface AppStore extends Instance<typeof AppStoreModel> {}
