import React, { Component } from "react";
import ReactDOMServer from 'react-dom/server';

import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { computed } from "mobx";
import InspectionCard from "../../organisms/InspectionCard/InspectionCard";
import { PlanReadSection } from '../../organisms/Plan/PlanReadSection';
import { Helmet } from "react-helmet";

import { Inspection } from '../../../store/models/Inspection';

import { Wrapper } from './printStyles';

interface PlanPrintSectionProps {
  inspectionId: string;
}

interface InjectedProps extends PlanPrintSectionProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class PlanPrintSection extends Component<PlanPrintSectionProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get inspection() {
    return this.injected.appStore.inspectionStore.inspections.find(
      x => x.inspectionId === this.props.inspectionId
    );
  }

  async componentDidMount() {
    try {
      const { inspectionId } = this.props;
      await this.injected.appStore.inspectionStore.fetchInspectionById(
        inspectionId
      );
    } catch (e) {}
  }

  render() {
    const { inspection } = this;
    
    if (inspection) {
      // const resourceSection = <>
      //   <div className='schedule-brief'>
      //     <h2>예상 기간</h2>
      //     <div>시작일로부터 <span>약 { Math.round(inspection.days / 7) }주 ({ inspection.days }일)</span></div>
      //   </div>
      //   <PlanReadSection
      //     title={'리소스 구성'}
      //     inspection={ inspection }
      //     showVATToggle={ false }
      //     showAllPaymentDetail={ true } />
      // </>;

      const resourceHtmlCode = ReactDOMServer.renderToString(
        // <PlanReadSection
        //   inspection={ this.inspection }
        //   showVATToggle={ false } />
        <>
          <div className='schedule-brief'>
            <h2>예상 기간</h2>
            <div>시작일로부터 <span>약 { Math.round(inspection.days / 7) }주 ({ inspection.days }일)</span></div>
          </div>
          <PlanReadSection
            title={'리소스 구성'}
            inspection={ inspection }
            showVATToggle={ false }
            showAllPaymentDetail={ true } />
        </>
      );

      return (
        <Wrapper>
{/*           
          <div className='page'>
            <div className='title-section'>
              <h1>{ inspection.title }</h1>
              <div>
                <label>의뢰인</label>
                <div>{ inspection.requesterName }</div>
              </div>
              <div>
                <label>플랜 담당자</label>
                <div>{ inspection.inspectorName } / { inspection.inspectorEmail }</div>
              </div>
              <div>
                <label>플랜 발행인</label>
                <div>{ inspection.publishedDate }</div>
              </div>
            </div>

            <div className='plan-comment'>
              
              <h2>플랜 설계 내용</h2>
              <div>
              { inspection.review.split('\n').map(row => <>{row}<br/></>) }
              { inspection.review.split('\n').map(row => <>{row}<br/></>) }
              </div>
            </div>
          </div>
          <span className="new-page" /> */}


          <div className='page resource' dangerouslySetInnerHTML={ {__html: resourceHtmlCode} }>
          </div>
          {/* <span className="new-page" /> */}
        </Wrapper>
      );
    }
    return <div />
  }
}

export default PlanPrintSection;
