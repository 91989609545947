import React, { Component, FormEvent } from "react";
import { RouteComponentProps } from "react-router";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { ChangeEvent } from "cleave.js/react/props";
import { AppStore } from "../../../store/AppStore";
import { observable } from "mobx";
import Paginator from "../../molecules/Paginator/Paginator";
import {
  Button,
  ButtonGroup,
  Classes,
  HTMLTable,
  Intent,
  InputGroup,
  HTMLSelect,
  IOptionProps,
  Spinner, AnchorButton
} from "@blueprintjs/core";
import moment from "moment";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";

export const ToolsRow = styled.div`
  margin-bottom: 30px;
  display: flex;
  height: 20px;

  & > * + * {
    margin-left: 10px;
  }
`;
const InputContainer = styled.div`
  width: 250px;
  margin-bottom: 30px;
`;
const PaginationContainer = styled.div`
  text-align: center;
`;


interface ContractRequestListPanelProps extends RouteComponentProps {
  isSupportPM: boolean;
}

interface InjectedProps extends ContractRequestListPanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ContractRequestListPanel extends Component<ContractRequestListPanelProps> {
  @observable readyToShow = false;
  @observable contractTarget = "전체";
  @observable status = "";
  @observable searchText = "";
  @observable orderingList: string[] = [];

  get injected() {
    return this.props as InjectedProps;
  }

  get contractStore() {
    return this.injected.appStore.contractStore;
  }

  get pmUserStore() {
    return this.injected.appStore.pmUserStore;
  }

  get clientStore() {
    return this.injected.appStore.clientStore;
  }

  get rufreeStore() {
    return this.injected.appStore.rufreeStore;
  }

  getPmUser = (id: number) => {
    return this.pmUserStore.pmUsers.find(
      x => x.id === id
    );
  };

  getClient = (id: string) => {
    return this.clientStore.findById(id);
  };

  getRufreeUser = (id: string) => {
    return this.rufreeStore.rufreeById(id);
  };

  fetchList = async () => {
    this.readyToShow = false;
    try {
      if (this.contractTarget === "클라이언트") {
        await this.contractStore.fetchClientContracts({
          status: this.status,
          searchText: this.searchText,
          orderingList: this.orderingList
        });
      } else if (this.contractTarget === "알유프리") {
        await this.contractStore.fetchRufreeContracts({
          status: this.status,
          searchText: this.searchText,
          orderingList: this.orderingList
        });
      } else if (this.contractTarget === "전체") {
        await this.contractStore.fetchAllRequestContracts({
          status: this.status,
          searchText: this.searchText,
          orderingList: this.orderingList
        });
      } else {
        alert("Error with contractType: " + this.contractTarget);
      }
    } catch (e) { throw e; }
    this.readyToShow = true;
  };
  drawArrow = (subject: string) => {
    if (this.orderingList.includes(`+${subject}`)) {
      return "sort-asc";
    } else if (this.orderingList.includes(`-${subject}`)) {
      return "sort-desc";
    } else {
      return "small-minus";
    }
  };
  handleOrderingChange = (subject: string) => {
    if(this.orderingList.length > 0 && !this.orderingList[0].includes(subject)) {
      this.orderingList = [];
    }

    if (this.orderingList.includes(`+${subject}`)) {
      const idx = this.orderingList.indexOf(`+${subject}`);
      this.orderingList[idx] = `-${subject}`;
    } else if (this.orderingList.includes(`-${subject}`)) {
      const idx = this.orderingList.indexOf(`-${subject}`);
      this.orderingList.splice(idx, 1);
    } else {
      this.orderingList.push(`+${subject}`);
    }

    this.fetchList();
  };
  handleClientContractCreateButtonClick = () => {
    this.props.history.push("/contract-request/client/new");
  };
  handleRufreeContractCreateButtonClick = () => {
    this.props.history.push("/contract-request/rufree/new");
  };
  handleClientContractRowClick = (id: string) => {
    this.props.history.push(`/contract-request/client/${id}/read`);
  };
  handleRufreeContractRowClick = (id: string) => {
    this.props.history.push(`/contract-request/rufree/${id}/read`);
  };
  handleContractTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    this.contractTarget = e.currentTarget.value;
    this.status = "";
    this.searchText = "";
    this.contractStore.setCurentPage(1);
    this.orderingList.splice(0,this.orderingList.length);
    this.fetchList();
  };
  handleStatusChange = (e: ChangeEvent<HTMLSelectElement>) => {
    this.status = e.currentTarget.value;
    // this.fetchList();
  };
  handleSearchTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.searchText = e.currentTarget.value;
  };

  async componentDidMount() {
    this.readyToShow = false;

    // await this.injected.appStore.pmUserStore.fetchPMUsers();
    // await this.injected.appStore.rufreeStore.fetchAllRufrees();
    // await this.injected.appStore.clientStore.fetchClients();

    const contractType = this.props.location.search.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function(str, key, value) {
        return (key === "type") ? value : "";
      }
    );
    switch (contractType) {
      case "rufree":
        this.contractTarget = "알유프리";
        break;
      case "client":
        this.contractTarget = "클라이언트";
        break;
      default:
        this.contractTarget = "전체";
    }

    await this.fetchList();
  }


  renderTBody = (contractTarget: string) => {
    switch (contractTarget) {
      case "클라이언트":
        return this.contractStore.clientContracts.map(contract => {
          const pmUser = contract.pm_user_detail;
          const client = contract.clientDetail;

          return (
            <tr
              key={`${contract.contractId}${contract.id}`}
              onClick={() => this.handleClientContractRowClick(contract.contractId)}
            >
              <td>{contract.contractId}</td>
              <td>{contract.contractName}</td>
              <td>{contractTarget}</td>
              <td>{client ? client.clientId : ""}</td>
              <td>{client ? client.name : ""}</td>
              <td>{pmUser ? pmUser.name : ""}</td>
              <td>{contract.status}</td>
              <td>{moment(contract.updatedAt).format("YYYY-MM-DD")}</td>
              <td>{moment(contract.createdAt).format("YYYY-MM-DD")}</td>
            </tr>
          );
        });
        break;
      case "알유프리":
        return this.contractStore.rufreeContracts.map(contract => {
          const pmUser = contract.pm_user_detail;
          const rufreeUser = contract.rufreeUserDetail;

          return (
            <tr
              key={`${contract.contractId}${contract.id}`}
              onClick={() => this.handleRufreeContractRowClick(contract.contractId)}
            >
              <td>{contract.contractId}</td>
              <td>{contract.contractName}</td>
              <td>{contractTarget}</td>
              <td>{contract.rufreeUser}</td>
              <td>{rufreeUser ? rufreeUser.name : ""}</td>
              <td>{pmUser ? pmUser.name : ""}</td>
              <td>{contract.status}</td>
              <td>{moment(contract.updatedAt).format("YYYY-MM-DD")}</td>
              <td>{moment(contract.createdAt).format("YYYY-MM-DD")}</td>
            </tr>
          );
        });
      default:
        return this.contractStore.allRequestContracts.map((contract,index) => {
          return (
            <tr key={`${contract.contractId}${index}`}
                onClick={() => {
                  if(contract.contractTarget === "클라이언트"){
                    this.handleClientContractRowClick(contract.contractId)
                  }else{
                    this.handleRufreeContractRowClick(contract.contractId)
                  }
                }}
            >
              <td>{contract.contractId}</td>
              <td>{contract.contractName}</td>
              <td>{contract.contractTarget}</td>
              <td>{contract.contractorId}</td>
              <td>{contract.contractorName}</td>
              <td>{contract.pmUserName}</td>
              <td>{contract.contractStatus}</td>
              <td>{moment(contract.contractUpdatedAt).format("YYYY-MM-DD")}</td>
              <td>{moment(contract.contractCreatedAt).format("YYYY-MM-DD")}</td>
            </tr>
          );
        });
    }
  };

  render() {
    const {
      pageGroup,
      totalPages,
      currentPage,
      setPageGroup,
      setCurentPage
    } = this.contractStore;

    const targetOptions: IOptionProps[] = [
      { label: "전체(상태)", value: "" },
      { label: "작성중", value: "작성중" },
      { label: "반려", value: "반려" },
      { label: "결재대기", value: "결재대기" },
      { label: "결재완료", value: "결재완료" },
      { label: "계약보류", value: "계약보류" },
      { label: "계약파기", value: "계약파기" }
    ];

    return (
      <>
        <ToolsRow>
          <Button
            text="새 클라이언트 계약요청"
            onClick={this.handleClientContractCreateButtonClick}
            intent={Intent.PRIMARY}
          />
          <Button
            text="새 알유프리 계약요청"
            onClick={this.handleRufreeContractCreateButtonClick}
            intent={Intent.PRIMARY}
          />
        </ToolsRow>

        <ToolsRow>

          <HTMLSelect
            value={this.contractTarget}
            onChange={this.handleContractTypeChange}
            options={["전체", "클라이언트", "알유프리"]}
          />

          <HTMLSelect
            value={this.status}
            onChange={this.handleStatusChange}
            options={targetOptions}
          />

          <InputContainer>
            <InputGroup
              placeholder="번호/계약명/고유번호/계약자"
              value={this.searchText}
              onChange={this.handleSearchTextChange}
            />
          </InputContainer>

          <Button
            icon="search"
            text="검색"
            intent={Intent.PRIMARY}
            onClick={this.fetchList}
          />
        </ToolsRow>

        <HTMLTable
          className={Classes.SMALL}
          striped={true}
          interactive={true}
          style={{ width: "100%", tableLayout: "fixed" }}
        >
          <thead>
          <tr>
            <th style={{ width: "100px" }}>번호
              <Button icon={this.drawArrow("contractId")} minimal={true} onClick={() => {
                this.handleOrderingChange("contractId");
              }}/>
            </th>
            <th>계약명</th>
            <th style={{ width: "90px" }}>대상</th>
            <th style={{ width: "80px" }}>고유번호</th>
            <th>계약자</th>
            <th>담당매니저</th>
            <th style={{ width: "80px" }}>상태</th>
            <th style={{ width: "110px" }}>수정일
              <Button icon={this.drawArrow("updatedAt")} minimal={true} onClick={() => {
                this.handleOrderingChange("updatedAt");
              }}/>
            </th>
            <th style={{ width: "110px" }}>생성일
              <Button icon={this.drawArrow("createdAt")} minimal={true} onClick={() => {
                this.handleOrderingChange("createdAt");
              }}/>
            </th>
          </tr>
          </thead>

          <tbody>

          {
            this.readyToShow ? (
              this.renderTBody(this.contractTarget)
            ) : (
              <tr>
                <td colSpan={9}><Spinner/></td>
              </tr>
            )
          }

          </tbody>
        </HTMLTable>

        <Paginator
          pageGroup={pageGroup}
          totalPages={totalPages}
          currentPage={currentPage}
          setPageGroup={setPageGroup}
          setCurentPage={setCurentPage}
          onFetch={this.fetchList}
        />
      </>
    );
  }
}

export default ContractRequestListPanel;
