import React, { useState } from "react";
import { inject, observer } from "mobx-react";

import { AppStore } from "../../../store/AppStore";
import { TResourceType } from '../../../store/forms/InspectionForm';

import {
  PopoverPosition,
  PopoverInteractionKind,
} from "@blueprintjs/core";

import { SprintPopup, ISprintPopupValue } from './SprintPopup';

import { SprintFieldWrapper as Wrapper } from './_wrapper';
import { SprintButton } from "./SprintButton";

export interface ISprintField {
  isPre?: boolean;
  isActive?: boolean;
  resourceType: TResourceType;
  disabled?: boolean;
  sprint?: number;
  sprintdays: number;
  role: string;
  onDelete: () => void;
  onSave: (values: ISprintPopupValue) => void;
}
interface InjectedProps extends ISprintField {
  appStore: AppStore;
}

export const SprintField: React.FC<ISprintField> = inject("appStore")(observer((props) => {
  const {
    selectedResource,
    selectedRole,
    selectedSprintNumber,
    setSelectedSprint
  } = (props as InjectedProps).appStore.inspectionFormStore;
  const sprintName = props.sprint !== undefined ? props.sprint === 0 ? 'PRE' : `S${props.sprint}` : undefined;
  const [ showPopup, setShowPopup ] = useState(false);
  const popoverProps = {
    enforceFocus: false,
    interactionKind: PopoverInteractionKind.CLICK,
    isOpen: showPopup,
    minimal: true,
    position: PopoverPosition.RIGHT_TOP,
    disabled: props.disabled,
    popoverClassName: 'sprintfield-popup',
    usePortal: false,
    targetTagName: "div",
    wrapperTagName: "div",
    boundary: "viewport"
  }

  const handleClose = () => {
    setShowPopup(false);
    setSelectedSprint('', '', -1);
  }

  if(props.isPre) {
    return (
      <Wrapper
        disabled={ true }
      >
        <SprintButton
          name={ sprintName }
          onClick={ () => {
            if(props.isActive) {
              props.onDelete();
            } else {
              props.onSave({sprintNumber: 0, days: 0});
            }
          } }
          { ...props } />
      </Wrapper>
    )
  }
  return (
    <Wrapper
      { ...popoverProps }
      onClose={ handleClose }
      wrapperTagName={ 'div' }
      targetTagName={ 'div' }
      boundary={ "viewport" }
      content={
        <SprintPopup
          isEdit={ props.isActive ? true : false }
          isServiceSprint={ props.resourceType === 'service' }
          sprintNumber={ props.sprint ? props.sprint : -1 }
          days={ props.sprintdays }
          perPerson={ true }
          onDelete={ props.onDelete }
          onConfirm={ (values: ISprintPopupValue) => props.onSave(values) }
          onClose={ handleClose }
        />
      }
    >
      <SprintButton
        name={ sprintName }
        { ...props }
        selected={
          selectedSprintNumber > 0 &&
            selectedResource === props.resourceType &&
            selectedRole === props.role &&
            selectedSprintNumber === props.sprint ? true : false
        }
        onClick={ () => {
          // 선택한 스프린트 저장 후,
          setSelectedSprint(props.resourceType, props.role, props.sprint ? props.sprint : -1);
          // 팝업 오픈.
          setShowPopup(!showPopup)
        } } />
  </Wrapper>
  )
}))
