import { types } from "mobx-state-tree";
import shortid from "shortid";

export const ReceptionSelectionFormModel = types
  .model("ReceptionSelectionForm", {
    id: types.optional(types.string, ""),
    value: types.maybeNull(types.number)
  })
  .actions(self => ({
    afterCreate() {
      self.id = shortid.generate();
    },
    setValue(value: number) {
      self.value = value;
    }
  }));

type ReceptionSelectionFormType = typeof ReceptionSelectionFormModel.Type;
export interface ReceptionSelectionForm extends ReceptionSelectionFormType {}
