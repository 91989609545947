import React, { Component, ChangeEvent } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import {
  Colors,
  Dialog,
  Classes,
  Button,
  Intent,
  FormGroup,
  InputGroup,
  H5,
  Tab, Tabs, TabId,
  Spinner,
  HTMLSelect,
  HTMLTable,
} from "@blueprintjs/core";
import { observable } from "mobx";
import { ProjectGroup } from "../../../store/models/ProjectGroup";
import { AppToaster } from "../AppToaster/AppToaster";

import { EProjectGroupTool } from '../../../constants/constants'; 

const H5Wrapper = styled(H5)`
  color: ${ Colors.BLUE2 };

  & + & {
    margin-top: 35px;
  }
`;

interface ProjectGroupSettingsPopupProps {
  onClose: () => void;
  isOpen: boolean;
  projectGroup: ProjectGroup;
}

interface InjectedProps extends ProjectGroupSettingsPopupProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ProjectGroupSettingsPopup extends Component<
  ProjectGroupSettingsPopupProps
> {
  @observable webhookUrl = "";

  @observable toolTrello = "";
  @observable toolSlack = "";
  @observable toolZeplin = "";
  @observable toolGdrive = "";
  @observable toolGithup = "";
  @observable toolHangout = "";
  @observable isPending = false;

  // 시소봇 설정
  @observable selectedTabId: TabId = "project";

  @observable seesobotAppId = "";
  @observable seesobotClientId = "";
  @observable seesobotClientSecret = "";

  @observable popupRef: any = null;

  // 시소봇 - 프로젝트보드 설정 변수
  @observable boardExists: boolean = false;
  @observable slackChannel: string = '';
  @observable slackChannels: Array<any> = [];
  @observable boardMembers: Array<any> = [];
  @observable slackMembers: Array<any> = [];
  @observable slackMemberMappings: {[k:number]: string} = {};  // k: id, value: slack_user_id
  @observable slackMemberMappingUpdates: {[k:string]: number} = {};  // k: slack_user_id, value: id

  get injected() {
    return this.props as InjectedProps;
  }

  get seesobotStore() {
    return this.injected.appStore.seesobotStore;
  }

  handleOpening = () => {
    const { slackWebhooks, toolLinks } = this.props.projectGroup;
    this.webhookUrl = slackWebhooks.length ? slackWebhooks[0].url : "";

    const trello = toolLinks.find(tool => tool.tool === EProjectGroupTool.TRELLO);
    const zeplin = toolLinks.find(tool => tool.tool === EProjectGroupTool.ZEPLIN);
    const slack = toolLinks.find(tool => tool.tool === EProjectGroupTool.SLACK);
    const gdrive = toolLinks.find(tool => tool.tool === EProjectGroupTool.GDRIVE)
    const github = toolLinks.find(tool => tool.tool === EProjectGroupTool.GITHUP);
    const hangout = toolLinks.find(tool => tool.tool === EProjectGroupTool.HANGOUT);

    this.toolTrello = trello ? trello.url : '';
    this.toolSlack = slack ? slack.url : '';
    this.toolZeplin = zeplin ? zeplin.url : '';
    this.toolGdrive = gdrive ? gdrive.url : '';
    this.toolGithup = github ? github.url : '';
    this.toolHangout = hangout ? hangout.url : '';
  };

  handleTabChanged = async (tabId: TabId) => {
    this.selectedTabId = tabId;
    if(tabId == 'seesobot') {
      try {
        this.isPending = true;
  
        await this.seesobotStore.installCheck(this.props.projectGroup.groupId);

        // 기 설치된 경우 처리
        if(this.seesobotStore.prjAppInstalled) {
          // 설치된 앱 정보
          this.seesobotAppId = this.seesobotStore.prjAppId;
          this.seesobotClientId = this.seesobotStore.prjClientId;
          this.seesobotClientSecret = this.seesobotStore.prjClientSecret;

          // 슬랙 채널, 멤버 동기화
          await this.seesobotStore.sync(this.props.projectGroup.groupId);

          // 프로젝트 보드 관련 설정 가져오기
          const settings:any = await this.seesobotStore.getBoardSettings(this.props.projectGroup.groupId);
          this.boardExists = settings.board_exists;
          this.slackChannels = [];
          this.boardMembers = [];
          this.slackMembers = [];
          this.slackMemberMappings = {};
          this.slackMemberMappingUpdates = {};
      
          this.slackChannel = settings.target_channel;
          settings.channels.map((channel:any) => { this.slackChannels.push(channel) });
          settings.board_members.map((board_member: any) => { this.boardMembers.push(board_member) })
          settings.slack_members.map((slack_member: any) => { this.slackMembers.push(slack_member) })
          settings.board_members.map((board_member: any) => {
            let matched_slack_member = this.slackMembers.find(slack_member => slack_member.seeso_user_id === board_member.id);
            this.slackMemberMappings[board_member.id] = matched_slack_member ?  matched_slack_member.slack_user_id : '';
          });          
        }
        window.addEventListener("message", this.handleMessageEvent);
    
      } catch(e) {
        const error = JSON.stringify(e.response.data);
  
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      } finally {
        this.isPending = false;
      }
    }
  }

  handleMessageEvent = (e:any) => {
    if(e.data.ok === "True") {
      this.seesobotStore.setPrjAppInstalled(true);
      AppToaster.show({
        message: "등록을 완료했습니다.",
        intent: Intent.SUCCESS
      });
    } else if(e.data.ok === "False"){
      AppToaster.show({
        message: e.data.message,
        intent: Intent.DANGER
      });
    }    
  }

  handleSlackMemberMappingChanged = (member_id: number, value: string) => {
    this.slackMemberMappings[member_id] = value;
    this.slackMemberMappingUpdates[value] = member_id;
  }

  handleProjectBoardSettingsSaved = async () => {
    try {
      this.seesobotStore.putBoardSettings(this.props.projectGroup.groupId, this.slackChannel, this.slackMemberMappingUpdates);
      AppToaster.show({
        message: "프로젝트보드 설정을 업데이트 했습니다.",
        intent: Intent.SUCCESS
      });
    } catch(e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  }
  
  handleWebhookUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.webhookUrl = e.target.value;
  };

  handleSave = async () => {
    try {
      this.isPending = true;

      await this.props.projectGroup.patchSlackWebhooks([
        {
          name: "프로젝트그룹",
          url: this.webhookUrl
        }
      ]);

      await this.props.projectGroup.patchToolLinks([
        {
          tool: EProjectGroupTool.TRELLO,
          url: this.toolTrello
        },
        {
          tool: EProjectGroupTool.SLACK,
          url: this.toolSlack
        },
        {
          tool: EProjectGroupTool.ZEPLIN,
          url: this.toolZeplin
        },
        {
          tool: EProjectGroupTool.GDRIVE,
          url: this.toolGdrive
        },
        {
          tool: EProjectGroupTool.GITHUP,
          url: this.toolGithup
        },
        {
          tool: EProjectGroupTool.HANGOUT,
          url: this.toolHangout
        }
      ]);

      AppToaster.show({
        message: "설정이 저장되었습니다.",
        intent: Intent.SUCCESS
      });

      this.props.onClose();
    } catch (e) {
      const error = JSON.stringify(e.response.data);

      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    } finally {
      this.isPending = false;
    }
  };

  handleClose = () => {
    window.removeEventListener("message", this.handleMessageEvent);
    this.props.onClose();
  };

  render() {
    const { isOpen, onClose } = this.props;

    return (
      <Dialog
        onOpening={this.handleOpening}
        onClose={onClose}
        isOpen={isOpen}
        title="프로젝트 설정"
        usePortal={true}
        autoFocus={true}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        isCloseButtonShown={false}
        enforceFocus={true}
      >
        <div className={Classes.DIALOG_BODY}>
          <Tabs id="tab" large={true} onChange={this.handleTabChanged} selectedTabId={this.selectedTabId}>
            <Tab id="project" title="프로젝트" panel={
              <div>
                <H5Wrapper>
                  슬랙
                </H5Wrapper>
                <FormGroup label="슬랙 웹훅URL">
                  <InputGroup
                    placeholder="Webhook URL"
                    value={this.webhookUrl}
                    onChange={this.handleWebhookUrlChange}
                  />
                </FormGroup>

                <H5Wrapper>
                  협업툴
                </H5Wrapper>
                <FormGroup label="트렐로">
                  <InputGroup
                    placeholder="트렐로"
                    value={this.toolTrello}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      this.toolTrello = e.target.value;
                    }}
                  />
                </FormGroup>
                <FormGroup label="슬랙">
                  <InputGroup
                    placeholder="슬랙"
                    value={this.toolSlack}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      this.toolSlack = e.target.value;
                    }}
                  />
                </FormGroup>
                <FormGroup label="제플린">
                  <InputGroup
                    placeholder="제플린"
                    value={this.toolZeplin}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      this.toolZeplin = e.target.value;
                    }}
                  />
                </FormGroup>
                <FormGroup label="깃헙">
                  <InputGroup
                    placeholder="깃헙"
                    value={this.toolGithup}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      this.toolGithup = e.target.value;
                    }}
                  />
                </FormGroup>
                <FormGroup label="구글 드라이브">
                  <InputGroup
                    placeholder="구글 드라이브"
                    value={this.toolGdrive}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      this.toolGdrive = e.target.value;
                    }}
                  />
                </FormGroup>
                <FormGroup label="구글 행아웃">
                  <InputGroup
                    placeholder="구글 행아웃"
                    value={this.toolHangout}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      this.toolHangout = e.target.value;
                    }}
                  />
                </FormGroup>
              </div>
            } 
            />

            <Tab id="seesobot" title="시소봇" panel={
              <div>
                {this.isPending ? (
                  <Spinner />
                ) : (
                  <div>
                    <H5Wrapper>
                      시소봇 설치 정보 <small><a href="https://docs.google.com/presentation/d/1p1Ccx7Y0DYmiBOiEeLp6kY3C2xyLXH0d3K6wbJhCgF0/edit#slide=id.p" target="_blank" rel="noopener noreferrer" style={{color:'indianred'}}>(설치 메뉴얼)</a></small>
                    </H5Wrapper>
                    <FormGroup label="App ID">
                      <InputGroup
                        placeholder="앱 ID"
                        value={this.seesobotAppId}
                        disabled={this.seesobotStore.prjAppInstalled}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          this.seesobotAppId = e.target.value;
                        }}
                      />
                    </FormGroup>
                    <FormGroup label="Client ID">
                      <InputGroup
                        placeholder="클라이언트 ID"
                        value={this.seesobotClientId}
                        disabled={this.seesobotStore.prjAppInstalled}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          this.seesobotClientId = e.target.value;
                        }}
                      />
                    </FormGroup>
                    <FormGroup label="Client Secret">
                      <InputGroup
                        placeholder="클라이언트 Secret"
                        value={this.seesobotClientSecret}
                        disabled={this.seesobotStore.prjAppInstalled}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          this.seesobotClientSecret = e.target.value;
                        }}
                      />
                    </FormGroup>
                    {!this.seesobotStore.prjAppInstalled && (
                      <FormGroup>
                        <Button
                          text="앱 설치"
                          fill={true}
                          disabled={this.seesobotAppId === '' || this.seesobotClientId === '' || this.seesobotClientSecret === ''}
                          onClick={() => {
                            this.popupRef = window.open(
                              `${this.seesobotStore.prjAppInstallUrl}&client_id=${this.seesobotClientId}` +
                              `&state=${this.injected.appStore.userStore.userId}|${this.props.projectGroup.groupId}|${this.seesobotClientId}|${this.seesobotClientSecret}`, 
                              "_blank"
                            );
                          }}
                        />
                      </FormGroup>
                    )}
                    {this.seesobotStore.prjAppInstalled && this.boardExists && (
                      <div>
                        <H5Wrapper>
                        프로젝트 보드
                        </H5Wrapper>                    
                        <FormGroup label="알림 메시지 채널">
                          <HTMLSelect
                            fill={true}
                            disabled={this.slackChannel === '' && this.slackChannels.length === 0}
                            value={this.slackChannel}
                            onChange={(e: any) => {
                              this.slackChannel = e.target.value;
                            }}
                          >
                            <option value={''}>정보 없음</option>
                            {this.slackChannels.map((channel: any) => {
                              return (
                                <option key={channel.slack_channel_id} value={channel.slack_channel_id}>{channel.slack_channel_name}</option>
                              )
                            })}
                          </HTMLSelect>
                        </FormGroup>
                        <FormGroup>
                          <HTMLTable condensed={true} style={{width:'100%'}}>
                            <thead>
                              <tr>
                                <th>프로젝트그룹 멤버</th>
                                <th>슬랙 멤버 (멘션 설정)</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                this.boardMembers.map(member => {
                                  let matched_slack_member = this.slackMembers.find(slack_member => slack_member.seeso_user_id === member.id);

                                  return (
                                    <tr key={member.id}>
                                      <td style={{lineHeight:'30px'}}>{member.name}</td>
                                      <td style={{lineHeight:'30px'}}>
                                        <HTMLSelect
                                          fill={true}
                                          disabled={matched_slack_member || this.slackMembers.length === 0}
                                          value={this.slackMemberMappings[member.id]}
                                          onChange={(e:any) => {this.handleSlackMemberMappingChanged(member.id, e.target.value)}}
                                        >
                                          <option value={''}>정보 없음</option>
                                          {this.slackMembers.map((slack_member: any) => {
                                            if(matched_slack_member) {
                                              // 매칭된 멤버가 있을 경우에는 해당 멤버만 선택지에 추가해줌
                                              if(member.id === slack_member.seeso_user_id) {
                                                return (
                                                  <option key={slack_member.slack_user_id} value={slack_member.slack_user_id}>{slack_member.slack_name}</option>
                                                );
                                              }
                                            } else {
                                              // 매칭된 멤버가 없을 경우에는, 매칭되지 않은 슬랙 멤버를 선택지에 추가해서 선택가능하게 함
                                              if(!slack_member.seeso_user_id) {
                                                return (
                                                  <option key={slack_member.slack_user_id} value={slack_member.slack_user_id}>{slack_member.slack_name}</option>
                                                );
                                              }
                                            }
                                          })}
                                        </HTMLSelect>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </HTMLTable>
                        </FormGroup>
                        <FormGroup>
                          <Button
                            text="프로젝트 보드 설정 저장"
                            fill={true}
                            onClick={this.handleProjectBoardSettingsSaved}
                          />
                        </FormGroup>                        
                      </div>
                    )}
                  </div>
                )}
              </div>
            }
            />
          </Tabs>
        </div>
        {this.selectedTabId === 'project' && (
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button text="취소" onClick={this.handleClose} />
              <Button
                text="저장"
                intent={Intent.PRIMARY}
                onClick={this.handleSave}
                loading={this.isPending}
              />
            </div>
          </div>
        )}
      </Dialog>
    );
  }
}

export default ProjectGroupSettingsPopup;
