import React, { FC, useState, useEffect } from "react";
import axios from "axios";
import { Select, IItemRendererProps } from "@blueprintjs/select";
import { MenuItem, Button } from "@blueprintjs/core";
import { highlightText } from "../../../utils/BlueprintSelectionHelpers";

interface IItem {
  id: string;
  name: string;
}
const AsyncSelect = Select.ofType<IItem>();

interface Props {
  onChange: (id: string) => void;
  currentValue?: string;
}


const ApprovedClientContractSelectionAsync: FC<Props> = props => {
  const { onChange, currentValue } = props;

  const [ query, setQuery ] = useState('');
  const [ items, setItems ] = useState([]);

  useEffect(() => {
    query.length > 1 && fetch(query);
  }, [query]);

  const fetch = async(query: string) => {
    try {
      const response: any = await axios.get(
        "/approvedClientContract",
        {
          params: { search: query }
        }
      );

      setItems(
        response.data.results.map( (item: any) => ({
          id: item.approved_contract_id,
          name: item.contract_name
        }))
      )

    } catch (e) { }
  }

  const itemRenderer = () => (
    item: IItem,
    { handleClick, query, modifiers }: IItemRendererProps
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        key={item.id}
        active={modifiers.active}
        text={highlightText(`${item.id} - ${item.name}`, query)}
        onClick={handleClick}
      />
    );
  };

  return (
    <AsyncSelect
      items={items}
      resetOnSelect={true}
      itemRenderer={ itemRenderer() }
      onItemSelect={ (item) => {
        onChange(item.id)
      }  }
      inputProps={{
        placeholder: '계약서번호 혹은 계약서명',
        style: {minWidth: '220px'}
      }}
      onQueryChange={ async (q:string, e:any) => {
        if(e && query !== q) {
          setQuery(q);
        }
      }}
      noResults={<MenuItem disabled={true} text="검색된 아이템이 없습니다." />}
    >

      <Button
        rightIcon="caret-down"
        text={currentValue ? currentValue : '선택해주세요'}
      />

    </AsyncSelect>
  )
};

export default ApprovedClientContractSelectionAsync;
