import axios from "axios";
import moment from "moment";
import { types, flow, IMSTArray, ISimpleType } from "mobx-state-tree";

import { RequestResponse, ReferenceFileResponse, ReferenceLinkResponse } from "../../../types/parttime";
import { ReferenceLinkFormModel, ReferenceLinkForm } from "./ReferenceLinkForm";
import { ReferenceFileFormModel, ReferenceFileForm } from "./ReferenceFileForm";

export const RequestFormModel = types
  .model("RequestForm", {
    id: types.maybeNull(types.number),
    serviceName: types.optional(types.string, ""),
    client: types.optional(types.string, ""),
    os: types.array(types.string),
    title: types.optional(types.string, ""),
    type: types.optional(types.string, ""),
    asis: types.optional(types.string, ""),
    tobe: types.optional(types.string, ""),
    accessInfo: types.optional(types.string, ""),
    expectedDueDate: types.maybeNull(types.string),
    referenceFiles: types.array(ReferenceFileFormModel),
    referenceLinks: types.array(ReferenceLinkFormModel),
  })
  .actions(self => ({
    toggleArrayItem(array: IMSTArray<ISimpleType<string>>, key: string) {
      const hasItem = !!array.find(x => x === key);

      if (hasItem) {
        array.remove(key);
      } else {
        array.push(key);
      }
    }
  }))
  .actions(self => ({
    setId(value: number) {
      self.id = value;
    },
    setServiceName(value: string) {
      self.serviceName = value;
    },
    setClient(value: string) {
      self.client = value;
    },
    setOs(value: string[]) {
      self.os.replace(value);
    },
    toggleOs(key: string) {
      self.toggleArrayItem(self.os, key);
    },
    setTitle(value: string) {
      self.title = value;
    },
    setType(value: string) {
      self.type = value;
    },
    setAsis(value: string) {
      self.asis = value;
    },
    setTobe(value: string) {
      self.tobe = value;
    },
    setAccessInfo(value: string) {
      self.accessInfo = value;
    },
    setExpectedDueDate(value: string) {
      self.expectedDueDate = value;
    },
    addReferenceLink() {
      self.referenceLinks.push(ReferenceLinkFormModel.create());
    },
    removeReferenceLink(target: ReferenceLinkForm) {
      self.referenceLinks.remove(target);
      try {
        axios.delete(`/parttime/requests/${self.id}/reference-links/${target.id}`);
      } catch (e) {
        console.log("edit error", e);
        throw e;
      }
    },
    addReferenceFile(target: ReferenceFileForm) {
      self.referenceFiles.push(target);
    },
    removeReferenceFile(target: ReferenceFileForm) {
      self.referenceFiles.remove(target);
      if(target.id !== null){
        try {
          axios.delete(`/parttime/requests/${self.id}/reference-files/${target.id}`);
        } catch (e) {
          console.log("edit error", e);
          throw e;
        }
      }
    },
  }));
type RequestFormType = typeof RequestFormModel.Type;
export interface RequestForm extends RequestFormType {}


const mapFormToRequest = (form: RequestForm) => {
    return {
      service_name: form.serviceName,
      client: form.client,
      os: form.os,
      title: form.title,
      type: form.type,
      asis: form.asis,
      tobe: form.tobe,
      access_info: form.accessInfo,
      expected_due_date: form.expectedDueDate
        ? moment(form.expectedDueDate).format("YYYY-MM-DD")
        : undefined,
      reference_links: form.referenceLinks.filter(x => x.url.length > 0).map(x => ({
        id: x.id,
        url: x.url,
        description: x.description
      })),
    }
  };
  
  
const mapToReferenceFile = (data: ReferenceFileResponse) => ({
    id: data.id,
    file: data.file,
    name: data.name,
    format: data.format,
    description: data.description
});

const mapToReferenceLink = (data: ReferenceLinkResponse) => ({
    id: data.id,
    url: data.url,
    description: data.description
});

const mapResponseToForm = (data: RequestResponse) => ({
    id: data.id,
    code: data.code,
    status: data.status,
    os: data.os,
    type: data.type,
    title: data.title,
    serviceName: data.service_name,
    asis: data.asis,
    tobe: data.tobe,
    accessInfo: data.access_info,
    cancelReason: data.cancel_reason,
    cancelConsultant: data.cancel_consultant,
    cancelDate: data.cancel_date,
    expectedDueDate: data.expected_due_date,
    referenceFiles: data.reference_files ? data.reference_files.map(mapToReferenceFile) : [],
    referenceLinks: data.reference_links.length ? data.reference_links.map(mapToReferenceLink) : [ReferenceLinkFormModel.create()],
    client: data.client.client_id,
    tasks: data.tasks,
    created: data.created,
    modified: data.modified,
});
  
  export const ParttimeRequestFormStoreModel = types
  .model("RequestFormStore", {
    requestForm: types.optional(RequestFormModel, {})
  })
  .actions(self => ({
    initForm() {
      self.requestForm = RequestFormModel.create({
        referenceLinks: [ReferenceLinkFormModel.create()],
      });
    }
  }))
  .actions(self => {
    const createRequest = flow(function*() {
      try {
        const form = self.requestForm;
  
        const { data }: { data: RequestResponse } = yield axios.post(
          `/parttime/requests`, mapFormToRequest(form)
          );
  
        self.requestForm.id = data.id;

        // 참고파일 저장
        const formData = new FormData();
        if(form.referenceFiles.length > 0){
          form.referenceFiles.forEach(item => {
            if(item.id === null){
              formData.append('reference_files', item.file!);
            }
          });
          if(formData.has('reference_files')){
            yield axios.post(`/parttime/requests/${form.id}/reference-files`, formData, {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            });
          }
        } 
      } catch (e) {
        console.log("createRequest error", e);
        throw e;
      }
    });

    const patchRequest = flow(function*() {
        try {
          // 참고파일을 제외한 나머지 필드 저장
          const form = self.requestForm;
          yield axios.patch(`/parttime/requests/${form.id}`, mapFormToRequest(form));

          // 참고파일 저장
          const formData = new FormData();
          if(form.referenceFiles.length > 0){
            form.referenceFiles.forEach(item => {
              if(item.id === null){
                formData.append('reference_files', item.file!);
              }
            });
            if(formData.has('reference_files')){
              yield axios.post(`/parttime/requests/${form.id}/reference-files`, formData, {
                  headers: {
                      'content-type': 'multipart/form-data'
                  }
              });
            }
          }
        } catch (e) {
            console.log("patchTask error", e);
        throw e;
        }
    });

    const fetchRequest = flow(function*(id: number) {
        try {
            const { data }: { data: RequestResponse } = yield axios.get(
                `/parttime/requests/${id}`
        );  
            const newForm = RequestFormModel.create(mapResponseToForm(data));
            self.requestForm = newForm;
        } catch (e) {
            throw e;
        }
      });
  
    return {
      createRequest,
      patchRequest,
      fetchRequest
    };
  });
  
  type ParttimeRequestFormStoreType = typeof ParttimeRequestFormStoreModel.Type;
  export interface ParttimeRequestFormStore extends ParttimeRequestFormStoreType {}