import React, { Component } from "react";
import { observable } from "mobx";
import { DateRange, DateRangeInput } from "@blueprintjs/datetime";
import axios from "axios";
import moment from "moment";
import {
  Button,
  ButtonGroup,
  Classes,
  HTMLSelect,
  HTMLTable,
  IOptionProps,
  Radio,
  RadioGroup
} from "@blueprintjs/core";
import { LargeText } from "../../atoms/DetailReportComponents/DetailReportComponents";
import { TD, TR } from "../../atoms/CalculateTable/CalculateTable";
import styled from "styled-components/macro";
import { observer } from "mobx-react";


const ToolsRow = styled.div`
  margin-bottom: 40px;
  display: flex;
  height: 20px;

  & > * + * {
    margin-left: 10px;
  }
`;

const InputContainer = styled.div`
  max-width: 220px;
  margin-bottom: 30px;
`;

const Container = styled.div``;

interface NewReceptionAreaType {
  "web": number;
  "app": number;
  "etc": number;
  "all": number;
  "date": string;
}

interface NewReceptionArea {
  "develop": number;
  "planning": number;
  "design": number;
  "etc": number;
  "all": number;
  "date": string;
}

const keyword_dictionary: { [key: string]: string } = {
  "develop": "개발",
  "planning": "기획",
  "design": "디자인",
  "etc": "기타",
  "web": "웹사이트",
  "app": "앱"
};

interface Props {

}

interface InjectedProps extends Props {
}

@observer
class ToProjectState extends Component {
  @observable selectedIndicatorChoice = 0;
  @observable dateRangeType = "전체";
  @observable dateRange: DateRange = [undefined, undefined];
  @observable data = {
    "area_type": {
      "web_count": -1,
      "app_count": -1,
      "etc_count": -1,
      "all_count": -1,
      "web_project_move_on_count": 14,
      "app_project_move_on_count": 8,
      "etc_project_move_on_count": 0,
      "all_project_move_on_count": 22,
      "web_ratio_count": 8.235294117647058,
      "app_ratio_count": 6.896551724137931,
      "etc_ratio_count": 0.0,
      "all_ratio_count": 8.906882591093117
    },
    "area": {
      "develop": -1,
      "planning": -1,
      "design": -1,
      "etc": -1,
      "all_count": -1,
      "area_develop_project_move_on_count": 19,
      "area_planning_project_move_on_count": 8,
      "area_design_project_move_on_count": 11,
      "area_etc_project_move_on_count": 3,
      "all_area_count_project_move_on_count": 22,
      "area_develop_ratio": 7.2796934865900385,
      "area_planning_ratio": 6.896551724137931,
      "area_design_ratio": 6.962025316455696,
      "area_etc_ratio": 13.636363636363635,
      "all_area_ratio": 6.41399416909621
    },
    "top_n_list": [{ key: [""], area: [""], area_type: [""], count: 0,toProjectCount:0,ratio:0 }]
  };


  //전환 건수
  @observable newDataAreaTypeList: NewReceptionAreaType[] = [];
  @observable newDataAreaList: NewReceptionArea[] = [];

  @observable newReceptionPeriod = "월별";
  @observable topNnumber = "10";

  async componentDidMount() {
    await this.loadAllReceptionCount();
    await this.loadNewReceptionCount();
  }

  reGroupToDateAT(sourceList: any, destination: { [key: string]: NewReceptionAreaType }, period: string, valueKey: string) {
    sourceList.forEach((item: any) => {
      const date = item[period];
      const count = item["c"];

      if (!destination[date]) {
        destination[date] = {
          "web": 0,
          "app": 0,
          "etc": 0,
          "all": 0,
          "date": date
        };
      }

      // @ts-ignore
      destination[date][valueKey] = count;
    });
    return destination;
  }

  reGroupToDateA(sourceList: any, destination: { [key: string]: NewReceptionArea }, period: string, valueKey: string) {
    sourceList.forEach((item: any) => {
      const date = item[period];
      const count = item["c"];

      if (!destination[date]) {
        destination[date] = {
          "develop": 0,
          "planning": 0,
          "design": 0,
          "etc": 0,
          "all": 0,
          "date": date
        };
      }

      // @ts-ignore
      destination[date][valueKey] = count;
    });
    return destination;
  }

  async loadAllReceptionCount() {
    const dateStart = this.dateRange[0] ? moment(this.dateRange[0]).format("YYYY-MM-DD HH:mm") : "";
    const dateEnd = this.dateRange[1] ? moment(this.dateRange[1]).format("YYYY-MM-DD HH:mm") : "";

    if (this.dateRangeType == "전체") {
      const { data }: { data: any } = await
        axios.get(`/stats/total_project_reception_move_on?n=${this.topNnumber}`);
      this.data = data;
    } else {
      const { data }: { data: any } = await
        axios.get(`/stats/total_project_reception_move_on?n=${this.topNnumber}&dateStart=${dateStart}&dateEnd=${dateEnd}`);
      this.data = data;
    }

  }

  async loadNewReceptionCount() {
    const dateStart = this.dateRange[0] ? moment(this.dateRange[0]).format("YYYY-MM-DD HH:mm") : "";
    const dateEnd = this.dateRange[1] ? moment(this.dateRange[1]).format("YYYY-MM-DD HH:mm") : "";

    let period = "month";
    if (this.newReceptionPeriod == "주별") period = "week";
    else if (this.newReceptionPeriod == "일별") period = "day";

    console.log(period, "period");
    if (this.dateRangeType == "전체") {
      var { data }: { data: any } = await
        axios.get(`/stats/new_project_reception_move_on?period=${period}`);
    } else {
      var { data }: { data: any } = await
        axios.get(`/stats/new_project_reception_move_on?period=${period}&dateStart=${dateStart}&dateEnd=${dateEnd}`);
    }
    let areaTypeDateMap: { [key: string]: NewReceptionAreaType } = {};
    let areaDateMap: { [key: string]: NewReceptionArea } = {};

    //areaTypeDateMap
    areaTypeDateMap = this.reGroupToDateAT(data.area_type.area_type_web_list, areaTypeDateMap, period, "web");
    areaTypeDateMap = this.reGroupToDateAT(data.area_type.area_type_app_list, areaTypeDateMap, period, "app");
    areaTypeDateMap = this.reGroupToDateAT(data.area_type.area_type_etc_list, areaTypeDateMap, period, "etc");
    areaTypeDateMap = this.reGroupToDateAT(data.area_type.area_type_all_list, areaTypeDateMap, period, "all");
    //areaDateMap
    areaDateMap = this.reGroupToDateA(data.area.area_develop_list, areaDateMap, period, "develop");
    areaDateMap = this.reGroupToDateA(data.area.area_planning_list, areaDateMap, period, "planning");
    areaDateMap = this.reGroupToDateA(data.area.area_design_list, areaDateMap, period, "design");
    areaDateMap = this.reGroupToDateA(data.area.area_etc_list, areaDateMap, period, "etc");
    areaDateMap = this.reGroupToDateA(data.area.area_all_list, areaDateMap, period, "all");

    /*this.newDataAreaType = areaTypeDateMap;
    this.newDataArea = areaDateMap;*/

    this.newDataAreaTypeList = [...Object.typedKeys(areaTypeDateMap)]
      .map(date => areaTypeDateMap[date])
      .sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf());

    this.newDataAreaList = [...Object.typedKeys(areaDateMap)]
      .map(date => areaDateMap[date])
      .sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf());

  }

  render() {
    const indicatorChoices: IOptionProps[] = [
      { label: "프로젝트 종류별", value: 0 },
      { label: "작업 범위별", value: 1 },
      { label: "프로젝트 & 작업범위", value: 2 }];
    return (
      <div>
        <ToolsRow>
          <InputContainer>
            <HTMLSelect
              value={this.selectedIndicatorChoice}
              onChange={(e: any) => {
                // console.log("e.target.value", e.target.value);
                // console.log(typeof e.target.value);
                this.selectedIndicatorChoice = e.target.value;
                // this.handleCreatorChange(e);
              }}
              options={indicatorChoices}
            />
          </InputContainer>
          <InputContainer>
            <div>
              <RadioGroup
                selectedValue={this.dateRangeType}
                onChange={(e: any) => {
                  this.dateRangeType = e.target.value;
                  this.loadAllReceptionCount();
                  this.loadNewReceptionCount();
                }}
                inline={true}
              >
                <Radio
                  label="전체"
                  value="전체"
                  inline={true}
                  style={{ verticalAlign: "sub" }}
                />
                <Radio
                  label="기간선택"
                  value="기간선택"
                  inline={true}
                  style={{ verticalAlign: "sub" }}
                />
              </RadioGroup>
            </div>
            {(this.selectedIndicatorChoice == 2) &&
            <div style={{width: "400px",marginTop:"15px"}}>
              <RadioGroup
                selectedValue={this.topNnumber}
                onChange={(e: any) => {
                  this.topNnumber = e.target.value;
                  this.loadAllReceptionCount();
                  this.loadNewReceptionCount();
                }}
                inline={true}
              >
                <Radio
                  label="상위 10"
                  value="10"
                  inline={true}
                  style={{ verticalAlign: "sub" }}
                />
                <Radio
                  label="상위 20"
                  value="20"
                  inline={true}
                  style={{ verticalAlign: "sub" }}
                />
                <Radio
                  label="상위 50"
                  value="50"
                  inline={true}
                  style={{ verticalAlign: "sub" }}
                />
                <Radio
                  label="전체"
                  value="100"
                  inline={true}
                  style={{ verticalAlign: "sub" }}
                />
              </RadioGroup>
            </div>}
          </InputContainer>
          <InputContainer>
            <DateRangeInput
              disabled={this.dateRangeType !== "기간선택"}
              formatDate={(date: Date) => moment(date).format("YYYY-MM-DD")}
              parseDate={(str) => new Date(str)}
              onChange={async (range) => {
                this.dateRange = range;
                this.loadAllReceptionCount();
                this.loadNewReceptionCount();
              }}
              value={this.dateRange}
              allowSingleDayRange={true}
              singleMonthOnly={true}
              selectAllOnFocus={true}
              startInputProps={{ style: { width: "100px" } }}
              endInputProps={{ style: { width: "100px" } }}
            />
          </InputContainer>

        </ToolsRow>
        <Container>
          <div>
            <LargeText>
              총 프로젝트 전환 건수
            </LargeText>
            {(this.selectedIndicatorChoice == 0) ?
              <HTMLTable
                className={Classes.LARGE}
                striped={false}
                interactive={false}
                style={{ width: "700px", tableLayout: "fixed" }}
              >
                <thead>
                {/*area_type*/}
                <TR>
                  <th></th>
                  <th>웹</th>
                  <th>앱</th>
                  <th>기타</th>
                  <th>총합</th>
                </TR>
                </thead>
                <tbody>
                <TR>
                  <TD>의뢰 접수 건수</TD>
                  <TD>{this.data.area_type.web_count}</TD>
                  <TD>{this.data.area_type.app_count}</TD>
                  <TD>{this.data.area_type.etc_count}</TD>
                  <TD>{this.data.area_type.all_count}</TD>
                </TR>
                <TR>
                  <TD>프로젝트 전환 건수</TD>
                  <TD>{this.data.area_type.web_project_move_on_count}</TD>
                  <TD>{this.data.area_type.app_project_move_on_count}</TD>
                  <TD>{this.data.area_type.etc_project_move_on_count}</TD>
                  <TD>{this.data.area_type.all_project_move_on_count}</TD>
                </TR>
                <TR>
                  {/*(Math.round(num * 100) / 100).toFixed(2);*/}
                  <TD>전환 비율</TD>
                  <TD>{this.data.area_type.web_ratio_count.toFixed(2)}%</TD>
                  <TD>{this.data.area_type.app_ratio_count.toFixed(2)}%</TD>
                  <TD>{this.data.area_type.etc_ratio_count.toFixed(2)}%</TD>
                  <TD>{this.data.area_type.all_ratio_count.toFixed(2)}%</TD>
                </TR>
                </tbody>
              </HTMLTable> : null}

            {(this.selectedIndicatorChoice == 1) ?
              <HTMLTable
                className={Classes.LARGE}
                striped={false}
                interactive={false}
                style={{ width: "800px", tableLayout: "fixed" }}
              >
                <thead>
                {/*area*/}
                <TR>
                  <th></th>
                  <th>개발</th>
                  <th>기획자</th>
                  <th>디자인</th>
                  <th>기타</th>
                  <th>총합</th>
                </TR>
                </thead>
                <tbody>
                <TR>
                  <TD>의뢰 접수 건수</TD>
                  <TD>{this.data.area.develop}</TD>
                  <TD>{this.data.area.planning}</TD>
                  <TD>{this.data.area.design}</TD>
                  <TD>{this.data.area.etc}</TD>
                  <TD>{this.data.area.all_count}</TD>
                </TR>
                <TR>
                  <TD>프로젝트 전환 건수</TD>
                  <TD>{this.data.area.area_develop_project_move_on_count}</TD>
                  <TD>{this.data.area.area_planning_project_move_on_count}</TD>
                  <TD>{this.data.area.area_design_project_move_on_count}</TD>
                  <TD>{this.data.area.area_etc_project_move_on_count}</TD>
                  <TD>{this.data.area.all_area_count_project_move_on_count}</TD>
                </TR>
                <TR>
                  <TD>전환 비율</TD>
                  <TD>{this.data.area.area_develop_ratio.toFixed(2)}%</TD>
                  <TD>{this.data.area.area_planning_ratio.toFixed(2)}%</TD>
                  <TD>{this.data.area.area_design_ratio.toFixed(2)}%</TD>
                  <TD>{this.data.area.area_etc_ratio.toFixed(2)}%</TD>
                  <TD>{this.data.area.all_area_ratio.toFixed(2)}%</TD>
                </TR>
                </tbody>
              </HTMLTable> : null}

            {(this.selectedIndicatorChoice == 2) &&
            <HTMLTable
              className={Classes.LARGE}
              striped={false}
              interactive={false}
              style={{ width: "800px", tableLayout: "fixed" }}
            >
              <thead>
              {/*area*/}
              <tr>
                <th>#</th>
                <th colSpan={2}>프로젝트 종류</th>
                <th colSpan={2}>작업 범위</th>
                <th colSpan={2}>의뢰건수</th>
                <th colSpan={2}>프로젝트 전환 건수</th>
                <th colSpan={2}>전환 비율</th>
              </tr>

              </thead>
              <tbody>
              {this.data.top_n_list.map((item, index) =>
                <TR>
                  <TD>#{index + 1}</TD>
                  <TD colSpan={2}>{item.area_type.map((i, index) => {
                    if (index === 0)
                      return (keyword_dictionary[i]);
                    else
                      return (`, ${keyword_dictionary[i]}`);
                  })}
                  </TD>
                  <TD colSpan={2}>{item.area.map((i, index) => {
                    if (index === 0)
                      return (keyword_dictionary[i]);
                    else
                      return (`, ${keyword_dictionary[i]}`);
                  })}
                  </TD>
                  <TD colSpan={2}>{item.count}</TD>
                  <TD colSpan={2}>{item.toProjectCount}</TD>
                  <TD colSpan={2}>{item.ratio.toFixed(2)}%</TD>
                </TR>
              )}
              </tbody>
            </HTMLTable>
            }
          </div>
          {(this.selectedIndicatorChoice != 2) &&
          <div style={{ marginTop: "30px" }}>
            <LargeText>
              신규 프로젝트 전환 건수
            </LargeText>
            <ButtonGroup style={{ minWidth: 200 }}>
              <Button style={{ width: "100px", backgroundColor: this.newReceptionPeriod == "월별" ? "#ff1" : "#fff" }}
                      onClick={async () => {
                        this.newReceptionPeriod = "월별";
                        await this.loadNewReceptionCount();
                      }}>{"월별"}</Button>
              <Button style={{ width: "100px", backgroundColor: this.newReceptionPeriod == "주별" ? "#ff1" : "#fff" }}
                      onClick={async () => {
                        this.newReceptionPeriod = "주별";
                        console.log("E");
                        await this.loadNewReceptionCount();
                      }}>{"주별"}</Button>
              <Button style={{ width: "100px", backgroundColor: this.newReceptionPeriod == "일별" ? "#ff1" : "#fff" }}
                      onClick={async () => {
                        this.newReceptionPeriod = "일별";
                        console.log("E");
                        await this.loadNewReceptionCount();
                      }}>{"일별"}</Button>
            </ButtonGroup>
            {/*AREA TYPE*/}
            {(this.selectedIndicatorChoice != 1) ?
              <HTMLTable
                className={Classes.LARGE}
                striped={false}
                interactive={false}
                style={{ width: "700px", tableLayout: "fixed" }}
              >
                <thead>
                {/*area_type NEW*/}
                <tr>
                  <th></th>
                  <th>웹</th>
                  <th>앱</th>
                  <th>기타</th>
                  <th>총합</th>
                </tr>
                </thead>
                <tbody>
                {
                  this.newDataAreaTypeList.map(item => {
                    // const areaType = this.newDataAreaType[date];
                    const areaType = item;
                    let formatedDate = "";
                    if (this.newReceptionPeriod == "주별") {
                      const dateA = moment(areaType.date).format("YYYY/MM/DD");
                      const dateB = moment(areaType.date).add("days", 6).format("YYYY/MM/DD");
                      formatedDate = `${dateA}-${dateB}`;
                    } else if (this.newReceptionPeriod == "일별") {
                      formatedDate = moment(areaType.date).format("YYYY/MM/DD");
                    } else {
                      formatedDate = moment(areaType.date).format("YYYY/MM");
                    }

                    return (
                      <TR>
                        <TD>{formatedDate}</TD>
                        <TD>{areaType.web}</TD>
                        <TD>{areaType.app}</TD>
                        <TD>{areaType.etc}</TD>
                        <TD>{areaType.all}</TD>
                      </TR>
                    );

                  })
                }
                </tbody>
              </HTMLTable> : null}
            {/*AREA*/}
            {(this.selectedIndicatorChoice != 0) ?
              <HTMLTable
                className={Classes.LARGE}
                striped={false}
                interactive={false}
                style={{ width: "800px", tableLayout: "fixed" }}
              >
                <thead>
                {/*area NEW*/}
                <tr>
                  <th></th>
                  <th>개발</th>
                  <th>기획자</th>
                  <th>디자인</th>
                  <th>기타</th>
                  <th>총합</th>
                </tr>
                </thead>
                <tbody>
                {
                  this.newDataAreaList.map(item => {
                    // const areaType = this.newDataAreaType[date];
                    let formatedDate = "";
                    if (this.newReceptionPeriod == "주별") {
                      const dateA = moment(item.date).utcOffset('+0900').format("YYYY/MM/DD");
                      const dateB = moment(item.date).utcOffset('+0900').add("days", 6).format("YYYY/MM/DD");
                      formatedDate = `${dateA}-${dateB}`;
                    } else if (this.newReceptionPeriod == "일별") {
                      formatedDate = moment(item.date).utcOffset('+0900').format("YYYY/MM/DD");
                    } else {
                      formatedDate = moment(item.date).utcOffset('+0900').format("YYYY/MM");
                    }

                    return (
                      <TR>
                        <TD>{formatedDate}</TD>
                        <TD>{item.develop}</TD>
                        <TD>{item.planning}</TD>
                        <TD>{item.design}</TD>
                        <TD>{item.etc}</TD>
                        <TD>{item.all}</TD>
                      </TR>
                    );

                  })
                }
                {/*<TR>
                  <TD>{this.data.area.develop}</TD>
                  <TD>{this.data.area.planning}</TD>
                  <TD>{this.data.area.design}</TD>
                  <TD>{this.data.area.etc}</TD>
                  <TD>{this.data.area.all_count}</TD>
                </TR>*/}
                </tbody>
              </HTMLTable> : null}
          </div>
          }

        </Container>
      </div>
    );
  }
}

export default ToProjectState;