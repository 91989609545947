import { types } from "mobx-state-tree";


export const InvitationMemberModel = types.model('InvitationMember', {
  id: types.number,
  code: types.string,
  name: types.string
})

export const InvitationModel = types.model("Invitation", {
  id: types.number,
  code: types.string,
  date_issued: types.string,
  date_used: types.maybeNull(types.string),
  from_member: types.maybeNull(InvitationMemberModel),
  to_member: types.maybeNull(InvitationMemberModel),
  type: types.string
})
.views(self => ({
}))
// .actions(self => {
  // const addComment = flow(function*(comment: string) {
  //   try {
  //     yield axios.post(
  //       `/admin/members/${self.id}/comment/`, {
  //         comment: comment
  //       }
  //     );
  //   } catch (e) {
  //     throw e;
  //   }
  // });

  // return {
  //   addComment
  // };
// });
type InvitationType = typeof InvitationModel.Type;
export interface IInvitation extends InvitationType {};
