import React from "react";
import { observer } from "mobx-react";
import shortid from "shortid";

import * as style from './editStyles';

import {
  InspectionForm,
  TResourceType
} from '../../../store/forms/InspectionForm';
import { ClientRoleForm } from '../../../store/forms/ClientRoleForm';
import { RufreeRoleForm } from '../../../store/forms/RufreeRoleForm';
import { SeesoServiceForm } from '../../../store/forms/SeesoServiceForm';
import { IInspectionTemplate } from "../../../store/models/InspectionTemplate";

import {
  ResourceForm,
  ResourceSummary,
  ResourceField, IResourceFieldProps
} from "../../molecules/Plan";

const RUFREE_ROLES = [
  "기획",
  "웹디자인",
  "앱디자인",
  "기타디자인",
  "풀스택",
  "백엔드",
  "프론트엔드",
  "퍼블리싱",
  "iOS",
  "AOS",
  "RN",
  "WP개발자",
  "QA테스트",
  "PM",
  "기타"
];
const SERVICE_ROLES = [
  "CS 매니지먼트",
  "워크플로우 설계",
  "기타"
]

const convertClient = (resource: ClientRoleForm) => {
  return Object.assign (
    {
      id: shortid(),
      resourceType: 'client'
    },
    resource
  )
}
const convertRufree = (resource: RufreeRoleForm) => {
  return Object.assign (
    {
      id: shortid(),
      resourceType: 'rufree',
      wage: resource.wage,
      disabledpre: true,
      roleOptions: RUFREE_ROLES,
      totalWage: resource.totalWage
    },
    resource
  )
}
const convertService = (resource: SeesoServiceForm) => {
  return Object.assign(
    {
      id: shortid(),
      resourceType: 'service',
      role: resource.service,
      sprints: resource.sprints,
      wage: resource.cost,
      wageTitle: '비용',
      isIndividual: resource.individual,
      totalWage: resource.totalCost,
      roleOptions: SERVICE_ROLES
    }
  )
}

export interface IResource extends IResourceFieldProps {
  resourceType: TResourceType;
  id: string;
}
export interface IPlanResourceEditSectionProps {
  currentForm: IInspectionTemplate | InspectionForm;
};

export const PlanResourceEditSection: React.FC<IPlanResourceEditSectionProps> = observer((props) => {
  const {
    sprintDays,
    clientRoles,
    rufreeRoles,
    seesoServices,
    addClientRole,
    addRufreeRole,
    addSeesoService,
    removeClientRole,
    removeRufreeRole,
    removeSeesoService
  } = props.currentForm;

  return (
    <style.Wrapper>
      <h2>리소스 구성</h2>

      <style.ResourceSection>

          <ResourceForm
            title='클라이언트'
            onAdd={ addClientRole }
          >
            {
              clientRoles
              .map((clientRole, i) =>
                <ResourceField
                  { ...convertClient(clientRole) }
                  key={ i }
                  resourceType={ 'client' }
                  sprintDays={ sprintDays }
                  onChangeRole={ (role: string) => clientRole.setRole(role) }
                  onRemove = { () => removeClientRole(clientRole) }
                  />
              )
            }
          </ResourceForm>

          <ResourceForm
            title='알유프리'
            onAdd={ () => addRufreeRole() }
          >
            {
              rufreeRoles
              .map((rufreeRole, i) =>
                <ResourceField
                  { ...convertRufree(rufreeRole) }
                  key={ i }
                  resourceType={ 'rufree' }
                  sprintDays={ sprintDays }
                  onChangeRole={ (role: string) => rufreeRole.setRole(role) }
                  onChangeWage = { (wage: number) => rufreeRole.setWage(wage) }
                  onRemove = { () => removeRufreeRole(rufreeRole) }
                  />
              )
            }
          </ResourceForm>

          <ResourceForm
            title='시소 서비스'
            onAdd={ () => addSeesoService() }
          >
            {
              seesoServices
              .map((seesoService, i) =>
                <ResourceField
                  { ...convertService(seesoService) }
                  key={ i }
                  sprintDays={ sprintDays }
                  onChangeRole={ (role: string) => {seesoService.setService(role)} }
                  onChangeWage={ (wage: number) => seesoService.setCost(wage) }
                  onRemove = { () => removeSeesoService(seesoService) }
                  onToggleIndividual={ () =>seesoService.setIndividual(!seesoService.individual) }
                  />
              )
            }
          </ResourceForm>

        {
          props.currentForm.hasOwnProperty('totalCost') &&
            <ResourceSummary
              amount={ props.currentForm.totalCost } />
        }

      </style.ResourceSection>
    </style.Wrapper>
  );
})
