const DEFAULT_DAYS = 14;

export const getSprintDaysRate = ({
  sprintIndex,
  sprintDays,
  originalPrice
}: {
  sprintIndex: number;
  sprintDays: number;
  originalPrice: number;
}) => {
  // S11 특별 조건 1: Pre 스프린트인 경우 비율 계산 없음.
  if (sprintIndex === 0) {
    return originalPrice;
  }

  // S11 특별 조건 : 스프린트 기간이 0일 때는 비율 없이 계산
  if (sprintDays === 0) {
    return originalPrice;
  }

  const rate = sprintDays / DEFAULT_DAYS;
  return Math.floor(originalPrice * rate);
};
