import { types, flow } from "mobx-state-tree";
import axios from "axios";
import { ContractTemplateResponse } from "../../types/contract";

export const ContractTemplateModel = types
  .model("ContractTemplate", {
    id: types.number,
    template_id: types.string,
    created_at: types.string,
    updated_at: types.string,
    template_name: types.string,
    template_body: types.string,
    author: types.number
  })
  .views(self => ({
  }))
  .actions(self => {

    return{
    };
  });
type ContractTemplateType = typeof ContractTemplateModel.Type;
export interface ContractTemplate extends ContractTemplateType {}