import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { ConsultSelectionForm } from "../../../store/forms/ProjectGroupForm/ConsultSelectionForm";
import ProjectSelection from "../../molecules/ProjectSelection/ProjectSelection";
import { Project } from "../../../store/models/Project";
import { computed } from "mobx";

const Wrapper = styled.div``;

interface ProjectSelectionFormProps {
  consultSelection: ConsultSelectionForm;
}

interface InjectedProps extends ProjectSelectionFormProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ProjectSelectionForm extends Component<ProjectSelectionFormProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get selectedProject() {
    return this.injected.appStore.projectStore.projects.find(
      project => project.projectId === this.props.consultSelection.value
    );
  }

  handleChange = (project: Project) => {
    this.props.consultSelection.setValue(project.projectId);
  };

  render() {
    return (
      <Wrapper>
        <ProjectSelection
          onChange={this.handleChange}
          currentValue={this.selectedProject}
        />
      </Wrapper>
    );
  }
}

export default ProjectSelectionForm;
