import { types, flow } from "mobx-state-tree";
import { ProjectGroupModel, ProjectGroup, ProjectGroupCommentModel } from "./models/ProjectGroup";
import axios from "axios";
import {
  ProjectGroupResponse,
  ProjectGroupListResponse,
  RepositoryResponse,
  CollaboratorResponse
} from "../types/projectGroup";
import { ScheduleResponse } from "../types/schedule";
import { BaseModel } from "./models/BaseModel";

const mapCollaborator = (x: CollaboratorResponse) => {
  return {
    ...x
  };
};

const mapRepository = (x: RepositoryResponse) => {
  return {
    id: x.id,
    name: x.name,
    description: x.description,
    url: x.url,
    template: x.template,
    collaborators: x.collaborators.map(mapCollaborator)
  };
};



export const mapProjectGroup = (x: ProjectGroupResponse) => {
  const inspectionSprints: number[] = [];
  x.inspection_rufree_roles.forEach(role => role.sprints.forEach((sprint, i) => { if(sprint && !inspectionSprints.includes(i)) inspectionSprints.push(i); }));

  return {
    groupId: x.group_id,
    name: x.name,
    client: x.client,
    clientName: x.client_name,
    approvedContractId: x.approved_contract_id,
    consults: x.consults,
    inspections: x.inspections,
    receptions: x.receptions,
    references: x.references,
    workers: x.workers,
    managers: x.managers,
    createdAt: x.created_at,
    updatedAt: x.updated_at,
    risks: x.risks,
    inspectionSprints: inspectionSprints,
    slackWebhooks: x.slack_webhooks || [],
    toolLinks: x.tool_links || [],
    dateComplete: x.date_complete,
    isHolding: x.is_holding,
    dateHolding: x.date_holding,
    dueDateFlaw: x.due_date_flaw,
    dueDateKeep: x.due_date_keep,
    dateFlawing: x.date_flawing,
    dateFlawingComplete: x.date_flawing_complete,
    dateKeeping: x.date_keeping,
    dateKeepingComplete: x.date_keeping_complete,
    projectSchedules: x.project_schedules ? x.project_schedules.map(x => ({
      startAt: x.start_at || '',
      title: x.title
    })) : [],
    comments: x.comments.map(comment => ProjectGroupCommentModel.create(comment))
  };
};

export const mapSchedule = (x: ScheduleResponse) => {
  return {
    rufreeSchedules: x.rufree_schedules.map(x => ({
      rufreeId: x.rufree_id,
      rufreeName: x.rufree_name,
      inspectionId: x.inspection_id,
      role: x.role,
      sprints: x.sprints.map(sprint => ({
        dateStart: sprint && sprint.date_start ? sprint.date_start : "",
        dateEnd: sprint && sprint.date_end ? sprint.date_end : "",
        manager: sprint ? sprint.manager : "",
        isValid: !!sprint
      })),
      numInspectionSprints: x.num_inspection_sprints,
      numScheduleSprints: x.num_schedule_sprints
    })),
    createdAt: x.created_at,
    updatedAt: x.updated_at,
    dateStart: x.date_start || "",
    dateEnd: x.date_end || "",
    sprintsCount: x.sprints_count || {
      total_count: 0,
      ongoing_count: 0,
      done_count: 0
    }
  };
};

export const ProjectGroupStoreModel = BaseModel.named("ProjectGroupStore")
  .props({
    projectGroups: types.array(ProjectGroupModel),

    // For Query
    currentPage: types.optional(types.number, 1),
    pageGroup: types.optional(types.number, 1),
    pageSize: types.optional(types.number, 10),
    totalPages: types.optional(types.number, 1),

    // for saving filter.
    filterStatus: types.optional(types.string, ''),
    filterPM: types.optional(types.string, ''),
    filterQuery: types.optional(types.string, ''),
    filteExceptInner: types.optional(types.boolean, false),

    // for ordering
    orderBy: types.optional(types.string, 'order_by_status'),
    orderAsc: types.optional(types.boolean, false),
  })
  .views(self => ({
  }))
  .actions(self => ({
    setCurentPage(value: number) {
      self.currentPage = value;
    },
    setPageGroup(value: number) {
      self.pageGroup = value;
    },
    setFilterStatus(value: string) {
      self.filterStatus = value;
    },
    setFilterPM(value: string) {
      self.filterPM = value;
    },
    setFilterQuery(value: string) {
      self.filterQuery = value;
    },
    setFilteExceptInner(value: boolean) {
      self.filteExceptInner = value;
    },
    setOrderBy(value: string) {
      self.orderBy = value;
    },
    setOrderAsc(value: boolean) {
      self.orderAsc = value;
    }
  }))
  .actions(self => {
    const fetchProjectGroups = flow(function*() {
      try {
        const { data }: { data: ProjectGroupListResponse } = yield axios.get(
          "/projectGroups",
          {
            params: {
              order_by: self.orderAsc ? self.orderBy : '-'+self.orderBy,
              page: self.currentPage,
              page_size: self.pageSize,
              managers: self.filterPM,
              status: self.filterStatus,
              search_text: self.filterQuery
            }
          }
        );

        let projectGroups: ProjectGroup[] = [];

        yield Promise.all(
          data.results.map(async res => {
            return projectGroups.push(
              ProjectGroupModel.create({
                ...mapProjectGroup(res),
                schedule: res.schedule ? mapSchedule(res.schedule) : undefined
              })
            );
          })
        );

        self.totalPages = data.total_pages;
        self.projectGroups.replace(projectGroups);
      } catch (e) {
        console.log("fetchProjectGroups error", e);
        throw e;
      }
    });

    const fetchProjectGroupsReportTargets = flow(function*() {
      try {
        const { data }: { data: ProjectGroupResponse[] } = yield axios.get(
          "/projectGroups/reportTargets",
          {
            params: {
              page: self.currentPage,
              page_size: self.pageSize
            }
          }
        );

        let projectGroups: ProjectGroup[] = [];

        yield Promise.all(
          data.map(async res => {
            try {
              const {
                data: scheduleData
              }: { data: ScheduleResponse } = await axios.get(
                `/projectGroups/${res.group_id}/schedule`
              );
              // const {
              //   data: repositoriesData
              // }: { data: RepositoryResponse[] } = await axios.get(
              //   `/projectGroups/${res.group_id}/repositories`
              // );

              return projectGroups.push(
                ProjectGroupModel.create({
                  ...mapProjectGroup(res),
                  schedule: mapSchedule(scheduleData),
                  repositories: []
                })
              );
            } catch (e) {
              return projectGroups.push(
                ProjectGroupModel.create(mapProjectGroup(res))
              );
            }
          })
        );

        self.projectGroups.replace(projectGroups);
      } catch (e) {
        console.log("fetchProjectGroupsReportTargets error", e);
        throw e;
      }
    });

    const fetchProjectgroupsDashboardTargets = flow(function*() {
      try {
        const { data }: { data: ProjectGroupResponse[] } =  yield axios.get(
          `/projectGroups/dashboardTargets`
        );

        let projectGroups: ProjectGroup[] = [];

        yield Promise.all(
          data
          .filter(x => x.client_name !== "시소" && !x.name.startsWith('[TEST]'))
          .map(async x => {
            try {
              const {
                data: scheduleData
              }: { data: ScheduleResponse } = await axios.get(
                `/projectGroups/${x.group_id}/schedule`
              );

              return projectGroups.push(
                ProjectGroupModel.create({
                  ...mapProjectGroup(x),
                  schedule: mapSchedule(scheduleData),
                  repositories: []
                })
              );
            } catch (e) {
              return projectGroups.push(
                ProjectGroupModel.create(mapProjectGroup(x))
              );
            }
          })
        );

        self.projectGroups.replace(projectGroups);
      } catch (e) {
        throw e;
      }
    });
    
    const fetchProjectGroup = flow(function*(groupId: string) {
      try {
        const { data }: { data: ProjectGroupResponse } = yield axios.get(
          `/projectGroups/${groupId}`
        );

        const projectGroupSnapshot = {
          ...mapProjectGroup(data),
          schedule: null as any,
          repositories: [] as any
        };

        try {
          const {
            data: scheduleData
          }: { data: ScheduleResponse } = yield axios.get(
            `/projectGroups/${groupId}/schedule`
          );

          projectGroupSnapshot.schedule = mapSchedule(scheduleData);
        } catch (e) {
          console.log("fetchProjectGroup schedule error", e);
        }

        try {
          const {
            data: repositoriesData
          }: { data: RepositoryResponse[] } = yield axios.get(
            `/projectGroups/${groupId}/repositories`
          );

          projectGroupSnapshot.repositories = repositoriesData.map(
            mapRepository
          );
        } catch (e) {
          console.log("fetchProjectGroup repository error", e);
        }

        const projectGroup = ProjectGroupModel.create(projectGroupSnapshot);
        projectGroup.fetchSprint();
        projectGroup.fetchSprintReports();

        self.projectGroups.forEach(x => {
          if (x.groupId === projectGroup.groupId) {
            self.projectGroups.remove(x);
          }
        });

        self.projectGroups.push(projectGroup);

        // store rufree matchings
        self.root.rufreeMatchingStore.storeRufreeMatchingResponses(
          data.rufree_matching
        );
      } catch (e) {
        console.log("fetchProjectGroup error", e);
        throw e;
      }
    });

    const fetchProjectGroupSelection = flow(function*(
    ) {
      try {
        const { data }: { data: ProjectGroupResponse[] } = yield axios.get("/projectGroups/selections");

        const projectGroups = data.map(data =>
            ProjectGroupModel.create({
              groupId: data.group_id,
              name: data.name,
              client: null,
              clientName: null,
              approvedContractId: '',
              consults: [],
              inspections: [],
              receptions: [],
              references: [],
              workers: [],
              managers: [],
              createdAt: '',
              updatedAt: '',
              risks: [],
              slackWebhooks: [],
              toolLinks: [],
              dateComplete: null,
              isHolding: false,
              dateHolding: null,
              dueDateFlaw: '',
              dueDateKeep: '',
              dateFlawing: null,
              dateFlawingComplete: null,
              dateKeeping: null,
              dateKeepingComplete: null,
              projectSchedules: [],
              comments: []
            })
        );

        self.projectGroups.replace(projectGroups);
      } catch (e) {
        console.log("fetchProjectGroupSelection error", e);
        throw e;
      }
    });

    const getNextGroupId = flow(function*() {
      try {
        const { data }: { data: {group_id : string} } = yield axios.get(
          `/projectGroups/nextGroupId`
        );

        return data ? data.group_id : '';
      } catch (e) {
        throw e;
      }
    });


    return {
      fetchProjectGroups,
      fetchProjectgroupsDashboardTargets,
      fetchProjectGroupsReportTargets,
      fetchProjectGroup,
      fetchProjectGroupSelection,
      getNextGroupId
    };
  });

type ProjectGroupStoreType = typeof ProjectGroupStoreModel.Type;
export interface ProjectGroupStore extends ProjectGroupStoreType {}
