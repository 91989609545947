import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { observable, computed } from "mobx";
import { ClientUser } from "../../../store/models/ClientUser";
import ClientUserSelection from "../../molecules/ClientUserSelection/ClientUserSelection";
import { ClientUserForm } from "../../../store/forms/ClientForm/ClientUserForm";
import { FormGroup, InputGroup, Button } from "@blueprintjs/core";
import ClientUserCompanyInfoPopup from "./ClientUserCompanyInfoPopup";

import axios from "axios";

const Wrapper = styled.div`
  margin-bottom: 10px;
  white-space: nowrap;

  & > * {
    margin-right: 8px;
    display: inline-block;
  }
`;

interface ClientUserSelectionFormViewProps {
  clientUserForm: ClientUserForm;
}

interface InjectedProps extends ClientUserSelectionFormViewProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ClientUserSelectionFormView extends Component<
  ClientUserSelectionFormViewProps
> {
  @observable slackComponent: any = null;
  @observable trelloComponent: any = null;
  @observable zeplinComponent: any = null;
  @observable githubComponent: any = null;
  @observable isPopupOpen: boolean = false;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get selectedClientUser() {
    const clientUser = this.injected.appStore.clientUserStore.clientUsers.find(
      x => x.id === this.props.clientUserForm.value
    );
    if(clientUser) {
      this.fetchToolAccounts(clientUser.id);
    }
    return clientUser;
  }

  handleClientUserChange = async (selectedValue: ClientUser) => {
    this.props.clientUserForm.setValue(selectedValue.id);
  };

  fetchToolAccounts = async (userId: number) => {
    axios.get(
      `/clientPersonal?auth_id=${userId}`
    )
    .then((response) => {

      const data = response.data;
      if(data.length > 1) {
        let slack_account = data[1].tool_accounts.find((tool_account: any) => tool_account.tool === 'slack');
        if(this.slackComponent) {
          this.slackComponent.value = slack_account ? slack_account.account : "";
        }

        let trello_account = data[1].tool_accounts.find((tool_account: any) => tool_account.tool === 'trello');
        if(this.trelloComponent) {
          this.trelloComponent.value = trello_account ? trello_account.account : "";
        }

        let zeplin_account = data[1].tool_accounts.find((tool_account: any) => tool_account.tool === 'zeplin');
        if(this.zeplinComponent) {
          this.zeplinComponent.value = zeplin_account ? zeplin_account.account : "";
        }

        let github_account = data[1].tool_accounts.find((tool_account: any) => tool_account.tool === 'github');
        if(this.githubComponent) {
          this.githubComponent.value = github_account ? github_account.account : "";
        }

      } else {

        if(this.slackComponent && this.trelloComponent && this.zeplinComponent && this.githubComponent) {
          this.slackComponent.value = '';
          this.trelloComponent.value = '';
          this.zeplinComponent.value = '';
          this.githubComponent.value = '';
        }

      }
      this.forceUpdate();
    });
  };

  handleCompanyInfoClicked = () => {
    this.isPopupOpen = true;
  };

  handlePopupClose = () => {
    this.isPopupOpen = false;
  };

  render() {
    return (
      <>
      <Wrapper>
        <ClientUserSelection
          onChange={this.handleClientUserChange}
          currentValue={this.selectedClientUser}
          blockFetchingData={true}
        />
        {this.selectedClientUser && (
          <Button
            text={'회사정보(사용자입력) 가져오기'}
            onClick={this.handleCompanyInfoClicked}
          />
        )}
      </Wrapper>
      {this.selectedClientUser && (
        <div style={{display: 'flex'}}>
        <div style={{width:'300px'}}>
          <FormGroup label={<strong>담당자 이름</strong>}>
            <InputGroup
              disabled={true}
              value={this.selectedClientUser.name}
            />
          </FormGroup>

          <FormGroup label={<strong>담당자 이메일</strong>}>
            <InputGroup
              disabled={true}
              value={this.selectedClientUser.email}
            />
          </FormGroup>

          <FormGroup label={<strong>담당자 연락처</strong>}>
            <InputGroup
              disabled={true}
              value={this.selectedClientUser.phone}
            />
          </FormGroup>
        </div>

        <div style={{width:'300px', marginLeft:'5px'}}>
          <FormGroup label={<strong>Slack 슬랙</strong>}>
            <InputGroup
              placeholder={'등록된 계정의 이메일 주소'}
              disabled={true}
              inputRef={(input: any) => {this.slackComponent = input}}
            />
          </FormGroup>

          <FormGroup label={<strong>Trello 트렐로</strong>}>
            <InputGroup
              placeholder={'등록된 계정의 이메일 주소'}
              disabled={true}
              inputRef={(input: any) => {this.trelloComponent = input}}
            />
          </FormGroup>

          <FormGroup label={<strong>Zeplin 제플린</strong>}>
            <InputGroup
              placeholder={'등록된 계정의 이메일 주소'}
              disabled={true}
              inputRef={(input: any) => {this.zeplinComponent = input}}
            />
          </FormGroup>

          <FormGroup label={<strong>Github 깃헙</strong>}>
            <InputGroup
              placeholder={'등록된 계정의 유저 아이디'}
              disabled={true}
              inputRef={(input: any) => {this.githubComponent = input}}
            />
          </FormGroup>
          <ClientUserCompanyInfoPopup 
            id={this.selectedClientUser.id}
            isOpened={this.isPopupOpen}
            onClose={this.handlePopupClose}
          />         
        </div>
        </div>
      )}
      </>
    );
  }
}

export default ClientUserSelectionFormView;
