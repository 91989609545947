import React, { useState } from 'react';

import {
  Tab,
  Tabs
} from '@blueprintjs/core';

import DepositPanel from './DepositPanel';
import SprintPanel from './SprintPanel';


const TabId = 'second-calculate-Tabs';
const TabIds = {
  payout: `${ TabId }-payout`,
  deposit: `${ TabId }-deposit`,
}
const SecondteamCalculatePage = () => {
  const [ selectedTabId, setSelectedTabId ] = useState(TabIds.deposit);
  return (
    <>
    <Tabs
      id={ TabId }
      selectedTabId={ selectedTabId }
      onChange={ (tabId: string) => setSelectedTabId(tabId) }
    >
      <Tab
        id={ TabIds.payout }
        title={ '스프린트 정산' }
        panel={ <SprintPanel /> } />
      <Tab
        id={ TabIds.deposit }
        title={ '입금 관리' }
        panel={ <DepositPanel /> } />
    </Tabs>
    </>
  )
};
export default SecondteamCalculatePage;
