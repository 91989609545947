import React, { FC } from "react";
import styled from "styled-components/macro";
import moment from "moment";


const Wrapper = styled.div`
margin-left: 130px;
`;

const TimebarWrapper = styled.div`
  position: relative;
  height: 30px;
  overflow: hidden;
  line-height: 40px;
  border-bottom: 1px solid #eee;
  display: flex;
`;

const TimebarCellWrapper = styled.div`
  text-align: center;
  background-color: #fff;
  border-left: 1px solid #eee;
  font-size: 10px;
  font-weight: lighter;
`;


interface DashboardTimelineHeaderProps {
  dateWidth: number;
  baseDate: string;
}

export const DashboardTimelineHeader: FC<DashboardTimelineHeaderProps> = props => {
  const totalDateNum = 7 * 6;
  let date = moment(props.baseDate).subtract(6, 'd');

  const timebarCells = []
  for (let i = 0; i < totalDateNum; i++) {
    let cellStyle = {'width': props.dateWidth, 'color': '#000' } as React.CSSProperties;
    if(i === 6) {
      cellStyle['color'] = '#FF7373';
      cellStyle['fontWeight'] = 'bold';
      cellStyle['fontSize'] = '13px';
    }

    timebarCells.push(
      <TimebarCellWrapper key={ i } style={ cellStyle }>
        {date.format('DD')}
      </TimebarCellWrapper>
    );
    date = date.add(1, 'd');
  }

  return (
    <Wrapper>
    	<TimebarWrapper>
    		{ timebarCells }
    	</TimebarWrapper>
    </Wrapper>
  );
};
