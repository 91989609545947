import React from "react";

import { ScheduleBriefWrapper as Wrapper } from './_wrapper';


const DAYS = 7;
export interface IScheduleBrief extends React.HTMLAttributes<HTMLDivElement> {
  days: number;
};

export const ScheduleBrief: React.FC<IScheduleBrief> = (props) => {
  return (
    <Wrapper
      className={ props.className }
    >
      시작일로부터 <span className='highlight'>약 { Math.round(props.days / DAYS) }주 ({ props.days }일)</span>
    </Wrapper>
  );
}

