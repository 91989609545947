import { types, flow, IMSTArray, ISimpleType } from "mobx-state-tree";
import axios from "axios";
import {
  InspectionSelectionFormModel,
  InspectionSelectionForm
} from "../ProjectGroupForm/InspectionSelectionForm";

export const ContractTemplateFormModel = types
  .model("ContractTemplateForm", {
    id: types.optional(types.number, -1),
    template_id: types.optional(types.string, ""),
    template_name: types.optional(types.string, ""),
    template_body: types.optional(types.string, ""),
    author: types.optional(types.number, -1)
  })
  .views(self => ({
  }))
  .actions(self => ({
    setTemplateName(value: string) {
      self.template_name = value;
    },
    setTemplateBody(value: string) {
      self.template_body = value;
    },
    setAuthor(value: number) {
      self.author = value;
    }
  }))

type ContractTemplateFormType = typeof ContractTemplateFormModel.Type;
export interface ContractTemplateForm extends ContractTemplateFormType {}
