import { types, getRoot, flow } from "mobx-state-tree";
import { ProjectGroupWorkerModel } from "./ProjectGroupWorker";
import { Client } from "./Client";
import { ProjectGroupManagerModel } from "./ProjectGroupManager";
import { ProjectGroupRiskModel } from "./ProjectGroupRisk";
import { SprintModel } from "./Sprint";
import { Expense, ExpenseModel } from "./Expense";
import { ScheduleModel, ProjectScheduleModel } from "./Schedule";
import { SprintReportModel } from "./SprintReport";
import { SlackWebhookModel, SlackWebhook } from "./SlackWebhook";
import { ToolLinkModel, ToolLink } from './ToolLink';
import { RepositoryModel, Repository } from "./Repository";
import sortBy from "lodash/sortBy";
import groupBy from "lodash/groupBy";
import values from "lodash/values";
import { Inspection } from "./Inspection";
import axios from "axios";
import moment from 'moment';
import {
  RepositoryTemplateType,
  CollaboratorPermissionType
} from "../../types/projectGroup";
import { ReferenceLinkModel } from "../forms/ReferenceLinkModel";
import { BaseModel } from "./BaseModel";
import { RufreeMatching } from "./RufreeMatching";
import {
  SprintResponse,
  SprintReviewResponse,
  SprintReportReponse,
  CommentResponse,
} from "../../types/projectGroup";

export const mapSprintReview = (x: SprintReviewResponse) => {
  return {
    id: x.id,
    createdAt: x.created_at,
    updatedAt: x.updated_at,
    sprint: x.sprint,
    creator: x.creator,
    creatorName: x.creator_name,
    comment: x.comment || ''
  };
};

export const mapSprint = (x: SprintResponse) => {
  return {
    id: x.id,
    projectGroupId: x.project_group,
    payment: x.payment - x.commission,
    calculatePayment: x.calculate_payment,
    calculateCommission: x.calculate_commission,
    commission: x.commission,
    serviceFee: x.service_fee,
    status: x.status,
    rufree: x.rufree,
    rufreeName: x.rufree_name,
    role: x.role,
    manager: x.manager || "",
    sprint: x.sprint,
    dateStart: x.date_start,
    dateEnd: x.date_end || "",
    expectedDateEnd: x.expected_date_end || "",
    dateCalculate: x.date_calculate || "",
    comment: x.comment || "",
    reviews: x.reviews.map(
      mapSprintReview
    ) as any,
  };
};

export const mapSprintReport = (x: SprintReportReponse) => {
  return {
    id: x.id,
    projectGroupId: x.project_group,
    sprint: x.sprint,
    reportId: x.report_id,
    version: x.version,
    datePublish: x.date_publish,
    rawData: x.raw_data,
    htmlData: x.html_data,
    createdAt: x.created_at,
    updatedAt: x.updated_at
  };
};

export const ProjectGroupCommentModel = types.model("ProjectGroupComment", {
  id: types.number,
  object_pk: types.string,
  user: types.maybeNull(types.integer),
  user_name: types.string,
  user_email: types.string,
  comment: types.string,
  submit_date: types.string
});
type ProjectGroupCommentType = typeof ProjectGroupCommentModel.Type;
export interface ProjectGroupComment extends ProjectGroupCommentType {}

export const ProjectGroupModel = BaseModel.named("ProjectGroup")
  .props({
    groupId: types.identifier,
    name: types.string,
    client: types.maybeNull(types.string),
    clientName: types.maybeNull(types.string),
    approvedContractId: types.string,
    consults: types.array(types.string),
    consultLinks: types.array(types.string),
    inspections: types.array(types.string),
    inspectionLinks: types.array(types.string),
    workers: types.array(ProjectGroupWorkerModel),
    managers: types.array(ProjectGroupManagerModel),
    createdAt: types.string,
    updatedAt: types.string,
    risks: types.array(ProjectGroupRiskModel),
    sprints: types.array(SprintModel),
    inspectionSprints: types.array(types.number),
    sprintReports: types.array(SprintReportModel),
    slackWebhooks: types.array(SlackWebhookModel),
    repositories: types.array(RepositoryModel),
    receptions: types.array(types.number),
    references: types.array(ReferenceLinkModel),
    dateComplete: types.maybeNull(types.string),
    isHolding: types.boolean,
    dateHolding: types.maybeNull(types.string),
    dateFlawing: types.maybeNull(types.string),
    dateFlawingComplete: types.maybeNull(types.string),
    dateKeeping: types.maybeNull(types.string),
    dateKeepingComplete: types.maybeNull(types.string),
    toolLinks: types.array(ToolLinkModel),
    dueDateKeep: types.string,
    dueDateFlaw: types.string,

    comments: types.array(ProjectGroupCommentModel),

    // Custom Fields
    schedule: types.maybeNull(ScheduleModel),
    projectSchedules: types.array(ProjectScheduleModel)
  })
  .views(self => ({
    get nameWithId() {
      return `${self.groupId} - ${self.name}`;
    },
    get isComplete() {
      return self.dateComplete ? true : false;
    },
    get canComplete() {
      if (!self.schedule) {
        return true;
      } else {
        return self.sprints.every((sprint) => sprint.dateEnd !== "")
      }

      return false;
    },
    get status() {
      let status: string = '진행중';

      if(!self.schedule) {
        status = '미진행';
      } else {
        const { dateStart } = self.schedule;
        const start = dateStart ? moment(dateStart) : moment('9999-12-31');

        if(start > moment()) {
          status = '미진행';
        }
      }

      if(self.isHolding) {
        status = '홀딩중';
      }

      if(self.dateComplete) {
        status = '종료';
      }

      return status;
    },
    get isFlawing() {
      return !!self.dateFlawing;
    },
    get isKeeping() {
      return !!self.dateKeeping;
    }
  }))
  .views(self => ({
    get clientInstance(): Client | undefined {
      return self.root.clientStore.clients.find(
        (client: Client) => client.clientId === self.client
      );
    },

    get inspectionInstances(): Inspection[] {
      const { inspections } = self.root.inspectionStore;

      let instances: Inspection[] = [];

      self.inspections.forEach(inspectionId => {
        const found = inspections.find(
          (x: Inspection) => x.inspectionId === inspectionId
        );

        if (found) {
          instances.push(found);
        }
      });

      return instances;
    },

    get sprintGroupsByRufree() {
      const sortedSprints = sortBy(self.sprints, sprint => sprint.sprint);
      const groups = groupBy(
        sortedSprints,
        sprint => `${sprint.rufree}${sprint.role}`
      );

      return values(groups);
    },

    get sprintBySprint() {
      const sortedSprints = sortBy(self.sprints, sprint => sprint.sprint);
      const groups = groupBy(
        sortedSprints,
        sprint => `S${sprint.sprint}`
      );
      return values(groups);
    },

    

    get extraExpenses() {
      let expenses:Expense[] = [];
      const { inspections } = self.root.inspectionStore;
      // TODO: 등록된 검수서가 여러개 일 경우, 루틴 변경 필요.
      const inspection = inspections.find(
        (x: Inspection) => x.inspectionId === self.inspections[0]
      );

      if(inspection) {
        inspection.seesoServices.map((service) => {
          if(!service.individual) {
            service.sprints.map((sprint, index) =>{
              if(sprint) {
                expenses.push(
                  ExpenseModel.create({
                    title: service.service,
                    sprint: index,
                    payment: 0,
                    serviceFee: service.cost,
                  })
                )
              }
            })
          }
        })
      }

      return expenses
    },

    get sprintExpensesGroups() {
      const endedSprints = self.sprints.filter(x => x.dateCalculate)
      const sortedSprints = sortBy(endedSprints, sprint => sprint.sprint);
      const expenses = sortedSprints.map((sprint) => {
        return (
        ExpenseModel.create({
          title: `${sprint.rufreeName}(${sprint.role}) : ${sprint.dateStart} ~ ${sprint.expectedDateEnd}`,
          sprint: sprint.sprint,
          payment: sprint.calculatePayment+sprint.calculateCommission,
          serviceFee: sprint.serviceFee,
        })
        )
      }
      );
      const groups = groupBy(
        expenses,
        sprint => `${sprint.sprint}`
      );

      return values(groups);
    },

    get onGoingSprints() {
      return self.sprints.filter(sprint => sprint.dateEnd === "");
    },

    get rufreeMatchings(): RufreeMatching[] {
      return self.root.rufreeMatchingStore.rufreeMatchings.filter(
        matching => matching.projectGroup === self.groupId
      );
    }
  }))
  .actions(self => ({
    reportsBySprint(sprint: number) {
      return self.sprintReports.filter(report => report.sprint === sprint)
    }
  }))
  .actions(self => {
    const getExtraServicefee = flow(function*(sprint: number) {
      try {
        yield axios.get(`/projectGroups/${self.groupId}/extraServicefee?sprint=${sprint}`);
      } catch (e) {
        console.log("getExtraServicefee error", e);
        throw e;
      }
    });

    const syncSprint = flow(function*() {
      try {
        yield axios.get(`/projectGroups/${self.groupId}/syncSprint`);
      } catch (e) {
        console.log("syncSprint error", e);
        throw e;
      }
    });

    const fetchSprint = flow(function*() {
      try {
        const { data }: { data: SprintResponse[] } = yield axios.get(
          `/projectGroups/${self.groupId}/sprints`
        );

        const sprints = data.filter(d => d.rufree).map(x =>
          SprintModel.create(mapSprint(x))
        );
        self.sprints.replace(sprints);

      } catch (e) {
        console.log("fetchSprint error", e);
        throw e;
      }
    });

    const fetchSprintReports = flow(function*() {
      try {
        const { data }: { data: SprintReportReponse[] } = yield axios.get(
          `/projectGroups/${self.groupId}/sprintReports`
        );

        const sprintReports = data.map(x =>
          SprintReportModel.create(mapSprintReport(x))
        );
        self.sprintReports.replace(sprintReports);

      } catch (e) {
        console.log("fetchSprintReports error", e);
        throw e;
      }
    });

    const addSprintReport = flow(function*(
      sprint: number,
      version: number,
      // is_editing: boolean,
      postfix: string,
      comment: string,
      roles: string[],
      rufrees: string[],
      services: string[],
      reportHtmlCode: string
    ) {
      yield axios.post(`/projectGroups/${self.groupId}/sprintReports`, {
        project_group: self.groupId,
        sprint: sprint,
        version: version,
        raw_data: {
          // is_editing: is_editing,
          postfix: postfix,
          comment: comment,
          roles: roles,
          rufrees: rufrees,
          services: services
        },
        html_data: reportHtmlCode
      });
    });

    const patchSlackWebhooks = flow(function*(newWebhooks: SlackWebhook[]) {
      try {
        yield axios.patch(`/projectGroups/${self.groupId}`, {
          slack_webhooks: newWebhooks
        });

        self.slackWebhooks.replace(newWebhooks);
      } catch (e) {
        console.log("patchSlackWebhooks error", e);
        throw e;
      }
    });

    const patchToolLinks = flow(function*(links: ToolLink[]) {
      try {
        yield axios.patch(`/projectGroups/${self.groupId}`, {
          tool_links: links
        });

        self.toolLinks.replace(links);
      } catch (e) {
        throw e;
      }
    });

    const patchDateComplete = flow(function*(dateComplete: string) {
      try {
        yield axios.patch(`/projectGroups/${self.groupId}`, {
          date_complete: dateComplete
        });

        self.dateComplete = dateComplete;
      } catch (e) {
        console.log("patchdateComplete error", e);
        throw e;
      }
    });

    const patchKeeping = flow(function*() {
      const today = moment().format('YYYY-MM-DD');
      try {
        yield axios.patch(`/projectGroups/${self.groupId}`, {
          date_keeping: today
        });

        self.dateKeeping = today;
      } catch (e) {
        throw e;
      }
    });

    const patchKeepingComplete = flow(function*() {
      const today = moment().format('YYYY-MM-DD');
      try {
        yield axios.patch(`/projectGroups/${self.groupId}`, {
          date_keeping_complete: today
        });

        self.dateKeepingComplete = today;
      } catch (e) {
        throw e;
      }
    });

    const patchFlawing = flow(function*() {
      const today = moment().format('YYYY-MM-DD');
      try {
        yield axios.patch(`/projectGroups/${self.groupId}`, {
          date_flawing: today
        });

        self.dateFlawing = today;
      } catch (e) {
        throw e;
      }
    });

    const patchFlawingComplete = flow(function*() {
      const today = moment().format('YYYY-MM-DD');
      try {
        yield axios.patch(`/projectGroups/${self.groupId}`, {
          date_flawing_complete: today
        });

        self.dateFlawingComplete = today;
      } catch (e) {
        throw e;
      }
    });

    const addRepository = flow(function*(
      name: string,
      description: string,
      template: RepositoryTemplateType
    ) {
      try {
        yield axios.post(`/projectGroups/${self.groupId}/repositories`, {
          name,
          description,
          template
        });
        const { data }: { data: Repository[] } = yield axios.get(
          `/projectGroups/${self.groupId}/repositories`
        );
        self.repositories.replace(data);
      } catch (e) {
        console.log("addRepository error", e);
        throw e;
      }
    });

    const editRepository = flow(function*(
      repo_id: number,
      name: string,
      description: string
    ) {
      try {
        yield axios.put(
          `/projectGroups/${self.groupId}/repositories/${repo_id}`,
          {
            name,
            description,
            template: "--" // FIXME: 이렇게 둬도 아무 영향 없음. 추후 API 수정시 해당 라인 삭제
          }
        );
        const { data }: { data: Repository[] } = yield axios.get(
          `/projectGroups/${self.groupId}/repositories`
        );
        self.repositories.replace(data);
      } catch (e) {
        console.log("editRepository error", e);
        throw e;
      }
    });

    const removeRepository = flow(function*(repo_id: number) {
      try {
        yield axios.delete(
          `/projectGroups/${self.groupId}/repositories/${repo_id}`
        );
        const { data }: { data: Repository[] } = yield axios.get(
          `/projectGroups/${self.groupId}/repositories`
        );
        self.repositories.replace(data);
      } catch (e) {
        console.log("removeRepository error", e);
        throw e;
      }
    });

    const addCollaborator = flow(function*(
      repo_id: number,
      username: string,
      permission: CollaboratorPermissionType
    ) {
      try {
        yield axios.post(
          `/projectGroups/${self.groupId}/repositories/${repo_id}/collaborators`,
          {
            username,
            permission
          }
        );
        const { data }: { data: Repository[] } = yield axios.get(
          `/projectGroups/${self.groupId}/repositories`
        );
        self.repositories.replace(data);
      } catch (e) {
        console.log("addCollaborator error", e);
        throw e;
      }
    });

    const removeCollaborator = flow(function*(
      repo_id: number,
      collaborator_id: number
    ) {
      try {
        yield axios.delete(
          `/projectGroups/${self.groupId}/repositories/${repo_id}/collaborators/${collaborator_id}`
        );
        const { data }: { data: Repository[] } = yield axios.get(
          `/projectGroups/${self.groupId}/repositories`
        );
        self.repositories.replace(data);
      } catch (e) {
        console.log("addCollaborator error", e);
        throw e;
      }
    });

    const patchHolding = flow(function*(
      isHolding: boolean
    ) {
      try {
        yield axios.patch(`/projectGroups/${self.groupId}`, {
          is_holding: isHolding,
          date_holding: moment().format('YYYY-MM-DD')
        });

        self.isHolding = isHolding;
      } catch (e) {
        throw e;
      }
    });



    //////
    const addComment = flow(function* (
      comment: string
    ) {
      try {
        const { data }: { data: CommentResponse[] } = yield axios.post(
          `/projectGroups/${self.groupId}/comment`,
          {
            comment: comment
          }
        );

        const comments = data.map(x => ProjectGroupCommentModel.create(x));
        self.comments.replace(comments);

      } catch (e) {
        throw e;
      }
    });

    const updateComment = flow(function* (
      id: number,
      comment: string
    ) {
      try {
        const { data }: { data: CommentResponse[] } = yield axios.patch(
          `/projectGroups/${self.groupId}/comment`,
          {
            id: id,
            comment: comment
          }
        );

        const comments = data.map(x => ProjectGroupCommentModel.create(x));
        self.comments.replace(comments);

      } catch (e) {
        throw e;
      }
    });
    const deleteComment = flow(function* (
      id: number
    ) {
      try {
        const { data }: { data: CommentResponse[] } = yield axios.delete(
          `/projectGroups/${self.groupId}/comment`,
          {
            data:{ id_list: [id]}
          }
        );

        const comments = data.map(x => ProjectGroupCommentModel.create(x));
        self.comments.replace(comments);

      } catch (e) {
        throw e;
      }
    });

    

    return {
      syncSprint,
      fetchSprint,
      addSprintReport,
      fetchSprintReports,
      getExtraServicefee,
      patchSlackWebhooks,
      patchToolLinks,

      patchDateComplete,
      patchHolding,
      patchKeeping,
      patchKeepingComplete,
      patchFlawing,
      patchFlawingComplete,

      addRepository,
      editRepository,
      removeRepository,
      addCollaborator,
      removeCollaborator,

      // comments
      addComment,
      updateComment,
      deleteComment
    };
  });

type ProjectGroupType = typeof ProjectGroupModel.Type;
export interface ProjectGroup extends ProjectGroupType {}
