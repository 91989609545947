import { types } from "mobx-state-tree";
import { PMReportFormModel, PMReportForm } from "./PMReportForm";
import moment from "moment";

export const CreateReportsFormModel = types
  .model("CreateReportsForm", {
    date: types.optional(types.string, moment().format("YYYY-MM-DD")),
    pmReports: types.array(PMReportFormModel)
  })
  .actions(self => ({
    setDate(value: string) {
      self.date = value;
    },
    addReport(projectGroup: string) {
      self.pmReports.push(PMReportFormModel.create({
        projectGroup: projectGroup
      }));
    },

    removeReport(pmReport: PMReportForm) {
      self.pmReports.remove(pmReport);
    }
  }));

type CreateReportsFormType = typeof CreateReportsFormModel.Type;
export interface CreateReportsForm extends CreateReportsFormType {}
