import React, { Component, ChangeEvent } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { computed, observable } from "mobx";
import ContractComments from "../../organisms/ContractComments/ContractComments";
import { ApprovedRufreeContract } from "../../../store/models/ApprovedContract";
import { 
  Button, H3, H4, Spinner, HTMLTable, Intent
} from "@blueprintjs/core";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
// import moment from "moment";

const ContainerWrapper = styled.div`
  display: flex;
  width: 100%;
`;
const Container = styled.div`
  margin-right: 20px;
`;
const ButtonsRow = styled.div`
  margin-top: 25px;
  float: right;
  & > * + * {
    margin-left: 5px;
  }
`;
const ContractTitleRow = styled.div`
  display: flex;
  & > * + * {
    margin-left: 10px;
  }
`;
const BodySection = styled.div`
  padding: 10px;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 10px;
`;
const FlexRow = styled.div`
  display: flex;
  margin-bottom: 50px;
  & > * + * {
    margin-left: 50px;
  }
`;


interface ApprovedRufreeContractDetailPageProps {
  id: string;
}

interface InjectedProps extends ApprovedRufreeContractDetailPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ApprovedRufreeContractDetailPage extends Component<ApprovedRufreeContractDetailPageProps> {
  @observable contract: ApprovedRufreeContract | null = null;

  get injected() {
    return this.props as InjectedProps;
  }

  get contractStore() {
    return this.injected.appStore.contractStore;
  }

  handlePdfDownload = async () => {
    if(this.contract) {
      window.open(
        `/contract/rufree/${this.contract.approvedContractId}/print`,
        '',
        "width=800,height=500"
      );
    }
  };

  handleAddComment = async (comment: string) => {
    if(this.contract) {
      try {
        await this.contract.addComment(comment);

        AppToaster.show({
          message: "코멘트가 저장되었습니다.",
          intent: Intent.SUCCESS
        });

        this.contract.fetchComments();
      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }
    }
  };

  handleUpdateComment = async (id: number, comment: string) => {
    if(this.contract) {
      try {
        await this.contract.updateComment(id, comment);

        AppToaster.show({
          message: "코멘트가 수정되었습니다.",
          intent: Intent.SUCCESS
        });

        this.contract.fetchComments();
      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }
    }
  };

  handleDeleteComment = async (id: number) => {
    if(this.contract) {
      try {
        await this.contract.deleteComment(id);

        AppToaster.show({
          message: "코멘트가 삭제되었습니다.",
          intent: Intent.SUCCESS
        });

        this.contract.fetchComments();
      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }
    }
  };

  async componentDidMount() {
    const { id } = this.props;
    this.contract = await this.injected.appStore.contractStore.fetchApprovedRufreeContractById(id);
  }

  render() {
    const { contract, handleAddComment } = this;

    if (!contract) {
      return <Spinner />;
    }

    return (
      <>
        <H3>알유프리 계약서</H3>

        <ContractTitleRow>
          <H4>{contract.approvedContractId}</H4>
          <H4>{contract.templateName}</H4>
          <H4>{contract.contractName}</H4>
        </ContractTitleRow>

        <FlexRow>
          <HTMLTable
            bordered={false}
            condensed={true}
            style={{ minWidth: "793.701px", tableLayout: "fixed" }}
          >
            <thead>
              <tr>
                <th>계약요청서</th>
                <th>종류</th>
                <th>고유번호</th>
                <th>계약자</th>
                <th>담당메니저</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Link
                    to={`/contract-request/rufree/${contract.rufreeContract}/read`}
                    target={"_blank"}>
                    {contract.rufreeContract}
                  </Link>
                </td>
                <td>알유프리</td>
                <td>{contract.contractorId}</td>
                <td>{contract.contractorName}</td>
                <td>{contract.managerName}</td>
              </tr>
            </tbody>
          </HTMLTable>

          <ButtonsRow>
            <Link to={`/contract?tab=contract&type=rufree`}>
              <Button text="취소" />
            </Link>
            <Button
              text="PDF 다운로드"
              intent={Intent.PRIMARY}
              onClick={this.handlePdfDownload}
            />
            <Link to={`/contract/rufree/${contract.approvedContractId}/edit`}>
              <Button
                text="수정하기"
                intent={Intent.PRIMARY}
              />
            </Link>
          </ButtonsRow>
        </FlexRow>


        <ContainerWrapper>

        <Container style={{minWidth: '793.701px'}}>
          { contract.editorContent && (
            <BodySection>
              <FroalaEditorView model={contract.editorContent} />
            </BodySection>
          )}
        </Container>


        <Container>
          {
            contract.commentOpened && (
              <ContractComments
                comments={contract.contractComments}
                onAddComment={this.handleAddComment}
                onDeleteComment={this.handleDeleteComment}
                onUpdateComment={this.handleUpdateComment}
              />
            )
          }
        </Container>
        
        </ContainerWrapper>
      </>
    );
  }
}

export default ApprovedRufreeContractDetailPage;

