import { types, flow, destroy } from "mobx-state-tree";
import axios from "axios";
import {
  ConsultSelectionFormModel,
  ConsultSelectionForm
} from "./ConsultSelectionForm";
import {
  ProjectGroupWorkerFormModel,
  ProjectGroupWorkerForm
} from "./ProjectGroupWorkerForm";
import {
  InspectionSelectionFormModel,
  InspectionSelectionForm
} from "./InspectionSelectionForm";
import { ProjectGroupResponse } from "../../../types/projectGroup";
import {
  ProjectGroupManagerFormModel,
  ProjectGroupManagerForm
} from "./ProjectGroupManagerForm";
import {
  ProjectGroupRiskFormModel,
  ProjectGroupRiskForm
} from "./ProjectGroupRiskForm";
import {
  ReceptionSelectionFormModel,
  ReceptionSelectionForm
} from "./ReceptionSelectionForm";
import { ReferenceLinkModel, ReferenceLink } from "../ReferenceLinkModel";
import { nullType, reference } from "mobx-state-tree/dist/internal";

const DefaultRisks = [
  "시소에서 확인하지 않은 기획서 활용",
  "시소에서 매니징 하지 않는 클라이언트 측 인력과의 협업",
  "기존 작업을 인수인계 받아서 해야하는 업무",
  "시소에서 시도해보지 않은 종류의 업무",
  "백엔드와 프론트의 동시 작업",
  "결정권자와의 직접 소통창구 부재",
  "심사 혹은 연동 등의 과정이 필요한 외부 시스템 활용",
  "클라이언트가 PM하던 업무를 인수인계 받는 경우",
  "론칭날짜에 계약이 만료",
  "IE에서 까다로울 수 있는 웹 업무",
  "론칭날짜가 고정",
  "작업을 위한 사전자료의 미흡",
  "제3의 관계자와 협업 필수 (팟프리카-아프리카)",
  "사전자료가 있는 경우",
  "iOS 프로젝트",
  "너무 많은 비용의 선지급",
  "방대한 작업 범위로 불확실한 일정 예측",
  "aws 비용 설명 필요",
  "시소 작업 프로세스 무경험 실무자",
  "시소 작업 프로세스 무경험 클라이언트",
  "기존 서비스 이해 필요 (업데이트 작업)"
];

export const ProjectGroupFormModel = types
  .model("ProjectGroupForm", {
    groupId: types.optional(types.string, ""),
    name: types.optional(types.string, ""),
    client: types.optional(types.string, ""),
    approvedContractId: types.optional(types.string, ""),
    consults: types.array(ConsultSelectionFormModel),
    inspections: types.array(InspectionSelectionFormModel),
    receptions: types.array(ReceptionSelectionFormModel),
    workers: types.array(ProjectGroupWorkerFormModel),
    managers: types.array(ProjectGroupManagerFormModel),
    risks: types.array(ProjectGroupRiskFormModel),
    references: types.array(ReferenceLinkModel),
    dateComplete: types.optional(types.string, ""),
    dateHolding: types.optional(types.string, ""),
    dateFlawing: types.optional(types.string, ""),
    dateFlawingComplete: types.optional(types.string, ""),
    dateKeeping: types.optional(types.string, ""),
    dateKeepingComplete: types.optional(types.string, "")
  })
  .actions(self => ({
    setGroupId(value: string) {
      self.groupId = value;
    },
    setName(value: string) {
      self.name = value;
    },
    setClient(value: string) {
      self.client = value;
    },
    setApprovedContractId(value: string) {
      self.approvedContractId = value;
    },
    setDateComplete(value: string) {
      self.dateComplete = value;
    },
    setDateHolding(value: string) {
      self.dateHolding = value;
    },
    setDateFlawing(value: string) {
      self.dateFlawing = value;
    },
    setDateFlawingComplete(value: string) {
      self.dateFlawingComplete = value;
    },
    setDateKeeping(value: string) {
      self.dateKeeping = value;
    },
    setDateKeepingComplete(value: string) {
      self.dateKeepingComplete = value;
    },

    addConsultSelection() {
      self.consults.push(ConsultSelectionFormModel.create());
    },
    addInspectionSelection() {
      self.inspections.push(InspectionSelectionFormModel.create());
    },
    addReceptionSelection() {
      self.receptions.push(ReceptionSelectionFormModel.create());
    },
    addWorker() {
      self.workers.push(ProjectGroupWorkerFormModel.create());
    },
    addManager() {
      self.managers.push(ProjectGroupManagerFormModel.create());
    },
    addEtcRisk() {
      self.risks.push(
        ProjectGroupRiskFormModel.create({ etc: true, checked: true })
      );
    },
    addReferenceLink() {
      self.references.push(ReferenceLinkModel.create());
    },
    addReferenceLinkWithValue(link: string, comment: string, fromInspection?: boolean) {
      self.references.push(ReferenceLinkModel.create({
        link: link,
        comment: comment,
        isFromInspection: fromInspection
      }));
    },
    removeReferencesFromInspection() {
      self.references
      .filter(reference => reference.isFromInspection)
      .map(reference => destroy(reference));
    },

    removeConsultSelection(consultSelection: ConsultSelectionForm) {
      self.consults.remove(consultSelection);
    },
    removeInspectionSelection(inspectionSelection: InspectionSelectionForm) {
      self.inspections.remove(inspectionSelection);
    },
    removeReceptionSelection(receptionSelection: ReceptionSelectionForm) {
      self.receptions.remove(receptionSelection);
    },
    removeWorker(worker: ProjectGroupWorkerForm) {
      self.workers.remove(worker);
    },
    removeManager(manager: ProjectGroupManagerForm) {
      self.managers.remove(manager);
    },
    removeRisk(risk: ProjectGroupRiskForm) {
      self.risks.remove(risk);
    },
    removeReferenceLink(referenceLink: ReferenceLink) {
      self.references.remove(referenceLink);
    }
  }));

type ProjectGroupFormType = typeof ProjectGroupFormModel.Type;
export interface ProjectGroupForm extends ProjectGroupFormType {}

export const ProjectGroupFormStoreModel = types
  .model("ProjectGroupFormStore", {
    currentForm: types.optional(ProjectGroupFormModel, {})
  })
  .actions(self => {
    const initForm = () => {
      self.currentForm = ProjectGroupFormModel.create({
        consults: [ConsultSelectionFormModel.create()],
        inspections: [InspectionSelectionFormModel.create()],
        receptions: [ReceptionSelectionFormModel.create()],
        workers: [ProjectGroupWorkerFormModel.create()],
        managers: [ProjectGroupManagerFormModel.create()],
        risks: []
      });
    };

    const fillDefaultRisks = () => {
      self.currentForm.risks.replace(
        DefaultRisks.map(riskTitle =>
          ProjectGroupRiskFormModel.create({
            title: riskTitle,
            etc: false,
            checked: false
          })
        )
      );
    };

    const mapFormDataForRequest = () => {
      const form = self.currentForm;

      return {
        client: form.client,
        approved_contract_id: form.approvedContractId,
        consults: form.consults.filter(x => x.value).map(x => x.value),
        inspections: form.inspections.filter(x => x.value).map(x => x.value),
        receptions: form.receptions.filter(x => x.value).map(x => x.value),
        workers: form.workers
          .filter(x => x.name || x.phone || x.email) // 세 필드 모두 비면 없는것으로 처리
          .map(x => ({
            name: x.name,
            phone: x.phone,
            email: x.email
          })),
        managers: form.managers
          .filter(x => x.name || x.phone || x.email)
          .map(x => ({
            name: x.name,
            phone: x.phone,
            email: x.email
          })),
        group_id: form.groupId,
        name: form.name || "이름없음",
        risks: form.risks
          .filter(x => !x.etc || x.title)
          .map(x => ({
            title: x.title,
            content: x.content,
            checked: x.checked,
            etc: x.etc
          })),
        references: form.references
          .map(x => {
            return {
            ...x,
            link:  x.link.startsWith('http') ? x.link : `http://${x.link}`
          }}),
        // TODO: Rufree Matching
        rufree_matching: [],
        date_complete: form.dateComplete || null,
        date_holding: form.dateHolding || null,
        date_flawing: form.dateFlawing || null,
        date_flawing_complete: form.dateFlawingComplete || null,
        date_keeping: form.dateKeeping || null,
        date_keeping_complete: form.dateKeepingComplete || null
      };
    };

    const postProjectGroup = flow(function*() {
      const reqData = mapFormDataForRequest();
      delete reqData.date_complete;

      try {
        const { data }: { data: ProjectGroupResponse } = yield axios.post(
          "/projectGroups",
          reqData
        );

        self.currentForm.setGroupId(data.group_id);

        return data;
      } catch (e) {
        console.log("postProjectGroup error", e);
        throw e;
      }
    });

    const patchProjectGroup = flow(function*() {
      try {
        const reqData = mapFormDataForRequest();
        if (self.currentForm.dateComplete.search('-') < 1) {
          reqData.date_complete = null;
        }

        const { data }: { data: ProjectGroupResponse } = yield axios.patch(
          `/projectGroups/${reqData.group_id}`,
          reqData
        );

        return data;
      } catch (e) {
        console.log("patchProjectGroup error", e);
        throw e;
      }
    });

    const fetchProjectGroup = flow(function*(groupId: string) {
      try {
        const { data }: { data: ProjectGroupResponse } = yield axios.get(
          `/projectGroups/${groupId}`
        );

        const newForm = ProjectGroupFormModel.create({
          groupId: data.group_id,
          name: data.name,
          client: data.client || "",
          approvedContractId: data.approved_contract_id || '',
          consults: data.consults.map(consult =>
            ConsultSelectionFormModel.create({ value: consult })
          ),
          inspections: data.inspections.map(inspection =>
            InspectionSelectionFormModel.create({ value: inspection })
          ),
          receptions: data.receptions.map(reception =>
            ReceptionSelectionFormModel.create({ value: reception })
          ),
          workers: data.workers,
          managers: data.managers,
          risks: data.risks,
          references: data.references,
          dateComplete: data.date_complete || "",
          dateHolding: data.date_holding || "",
          dateFlawing: data.date_flawing || "",
          dateFlawingComplete: data.date_flawing_complete || "",
          dateKeeping: data.date_keeping || "",
          dateKeepingComplete: data.date_keeping_complete || ""
        });

        self.currentForm = newForm;
      } catch (e) {
        console.log("fetchProjectGroup error", e);
        throw e;
      }
    });

    const setForm = (val:any) =>{
      self.currentForm = val;
    };

    return {
      initForm,
      fillDefaultRisks,
      postProjectGroup,
      patchProjectGroup,
      fetchProjectGroup,
      setForm
    };
  });

type ProjectGroupFormStoreType = typeof ProjectGroupFormStoreModel.Type;
export interface ProjectGroupFormStore extends ProjectGroupFormStoreType {}
