import React, { Component } from "react";

import { observable } from "mobx";
import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import { Button } from "@blueprintjs/core";
import { FocusStyleManager } from "@blueprintjs/core";

import { AppStore } from "../../../store/AppStore";

import AddManyTaskPopup from "../../molecules/TaskManager/AddManyTaskPopup";

const FooterContainer = styled.div`
  display: flex;
`;

interface TaskListFooterProps {
  laneId: number;
  onClick: () => void;
}

interface InjectedProps extends TaskListFooterProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class TaskListFooter extends Component<TaskListFooterProps> {
  @observable isPopupOpen = false;

  componentDidMount() {
    FocusStyleManager.onlyShowFocusOnTabs();
  }

  handleOnPopupClose = async () => {
    this.isPopupOpen = false;
  }

  render() {
    return (
      <FooterContainer
        onClick={(e: any) => {e.stopPropagation();}}
      >
        <Button 
          fill={true}
          onClick={(e: any) => { this.props.onClick(); e.stopPropagation(); } }
          minimal={true}
          icon="add"
          text={'TASK 추가'}
          alignText={'left'}
        />
        <Button 
          icon="add-to-artifact"
          minimal={true}
          style={{marginLeft: 'auto'}}
          onClick={(e: any) => { this.isPopupOpen=true; e.stopPropagation(); }}
        />
        <AddManyTaskPopup
          laneId={this.props.laneId}
          isOpen={this.isPopupOpen}
          onClose={this.handleOnPopupClose}
        />
      </FooterContainer>
    );
  }
}

export default TaskListFooter;