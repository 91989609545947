import React, { FC, ChangeEvent } from "react";
import styled from "styled-components/macro";
import { ToolLink } from "../../../store/models/ToolLink";
import { EProjectGroupTool } from '../../../constants/constants';

import IconTrello from "./icon-trello.svg";
import IconSlack from "./icon-slack.svg";
import IconZeplin from "./icon-zeplin.svg";
import IconGithub from "./icon-github.svg";
import IconGdrive from "./icon-ggdrive.jpg";
import IconHangout from "./icon-gghangout.svg";

const Wrapper = styled.div`
  display: flex;
  margin-right: 20px;
  padding-top: 5px;
  div + div {
    margin-left: 15px;
  }
`;
const ItemIcon = styled.div<{
  isActive?: boolean;
  src: string;
}>`
  cursor: pointer;
  display: ${ p => p.isActive ? 'block' : 'none'};
  background: url(${p => p.src}) no-repeat center center;
  width: 36px;
  height: 36px;
  background-color: #ffffff;
`;

const ProjectGroupTools: FC<{
  toolLinks: ToolLink[]
}> = ({ toolLinks }) => {

  const trello = toolLinks.find(tool => tool.tool === EProjectGroupTool.TRELLO);
  const zeplin = toolLinks.find(tool => tool.tool === EProjectGroupTool.ZEPLIN);
  const slack = toolLinks.find(tool => tool.tool === EProjectGroupTool.SLACK);
  const gdrive = toolLinks.find(tool => tool.tool === EProjectGroupTool.GDRIVE)
  const github = toolLinks.find(tool => tool.tool === EProjectGroupTool.GITHUP);
  const hangout = toolLinks.find(tool => tool.tool === EProjectGroupTool.HANGOUT);


  return (
    <Wrapper>
      <ItemIcon
        isActive={ trello && !!trello.url }
        src={IconTrello}
        onClick={() => trello && window.open(`${trello.url}`, '_blank')} />
      <ItemIcon
        isActive={ zeplin && !!zeplin.url }
        src={IconZeplin}
        onClick={() => zeplin && window.open(`${zeplin.url}`, '_blank')} />
      <ItemIcon
        isActive={ slack && !!slack.url }
        src={IconSlack}
        onClick={() => slack && window.open(`${slack.url}`, '_blank')} />
      <ItemIcon
        isActive={ gdrive && !!gdrive.url }
        src={IconGdrive}
        onClick={() => gdrive && window.open(`${gdrive.url}`, '_blank')} />
      <ItemIcon
        isActive={ github && !!github.url }
        src={IconGithub}
        onClick={() => github && window.open(`${github.url}`, '_blank')} />
      <ItemIcon
        isActive={ hangout && !!hangout.url }
        src={IconHangout}
        onClick={() => hangout && window.open(`${hangout.url}`, '_blank')} />
    </Wrapper>
  );
}

export default ProjectGroupTools