import React, { Component } from "react";
import "./ProgressTracker.css";
import { observer } from "mobx-react";

interface Props {
  pointList: string[];
  doneIndex: number;
}

@observer
class ProgressTracker extends Component<Props> {
  render() {
    const { pointList,doneIndex }= this.props;
    const width = (100 / pointList.length) - 0.01
    return (
      <>
        <ol className="progress-meter">
          {pointList.map((point,index)=> {
            const status = index <= doneIndex ? "done" : "todo";
            return (<li className={`progress-point ${status}`} style={{ width: `${width}%` }}>{point}</li>)
          })}
        </ol>
      </>
    );
  }
}

export default ProgressTracker;