import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";

import { computed } from "mobx";
import {
  Button,
  HTMLTable,
  Classes,
  Intent,
  Tag
} from "@blueprintjs/core";

import { ETEMPLATETYPE, INotificationTemplate } from "../../../store/models/NotificationTemplate";
import { FormMode } from "../../../types/formMode";

import {
  ToolsRow
} from "../../atoms/CalculateTable/CalculateTable";
import SearchBar from "../../organisms/NotificationTemplate/SearchBar";
import DetailDialog from "../../organisms/NotificationTemplate/DetailDialog";
// import SendDialog from "../../organisms/NotificationTemplate/SendDialog";
import { RouteComponentProps } from "react-router";

interface Props extends RouteComponentProps{
  isLoading: boolean;
  templateType: string;
  selectedId?: number;
}

interface InjectedProps extends Props {
  appStore: AppStore;
}

@inject("appStore")
@observer
class NotificationTemplatePanel extends Component<Props> {

  get injected() {
    return this.props as InjectedProps;
  }

  get inspectionStore() {
    return this.injected.appStore.inspectionTemplateStore;
  }

  get store() {
    return this.injected.appStore.notificationTemplateStore;
  }


  @computed
  get templates(){
      return this.store.getTemplatesByType(this.props.templateType);
  }
  @computed
  get inspectionTemplates(){
    return this.inspectionStore.getTemplates();
  }

  componentDidMount() {
  }

  render() {
    const { isLoading, templateType } = this.props;
    const sendIcon = templateType === ETEMPLATETYPE.SMS ? "mobile-phone" : "envelope";

    return (
      <>
        <SearchBar/>

        <ToolsRow
          style={{ "flexDirection": "row-reverse", "marginBottom": "20px" }}
        >
          <Button
            icon="add"
            text="템플릿 추가"
            intent={Intent.PRIMARY}
            onClick={() => {
              if (this.props.templateType !== ETEMPLATETYPE.INSPECTION) {
                this.store.setSelectedTemplate();
                this.store.setFormMode(FormMode.Edit);
                this.store.setOpenPopup(true);
              } else {
                // this.inspectionTemplates.setSelectedTemplate();
                this.props.history.push("/templates/inspections/new");
              }
            }}
          />
        </ToolsRow>

        <HTMLTable
          className={Classes.SMALL}
          striped={true}
          interactive={true}
          style={{ width: "100%", tableLayout: "fixed" }}
        >
          <thead>
          <tr>
            <th style={{ width: "80px" }}></th>
            <th>템플릿명</th>
            {templateType !== ETEMPLATETYPE.INSPECTION && <th style={{ width: "100px" }}>카테고리</th>}
            <th style={{ width: "110px" }}>최종 수정일</th>
            <th style={{ width: "110px" }}>최종 수정자</th>
            <th style={{ width: "100px" }}></th>
          </tr>
          </thead>

          <tbody className={isLoading ? Classes.SKELETON : ""}>
          {this.templates && this.props.templateType !== ETEMPLATETYPE.INSPECTION &&
          this.templates.map((template, i) => {
              return (
                <tr
                  key={i}
                  onClick={() => {
                    // if (this.props.templateType !== ETEMPLATETYPE.INSPECTION) {
                      this.store.setSelectedTemplate(template);
                      this.store.setFormMode(FormMode.Edit);
                      this.store.setOpenPopup(true);
                    // } else {
                    //   this.inspectionStore.setSelectedTemplate(template);
                    //   this.inspectionStore.setFormMode(FormMode.Edit);
                    //   this.inspectionStore.setOpenPopup(true);
                    // }
                  }}
                >
                  <td>{template.condition ? <Tag>자동발송</Tag> : ''}</td>
                  <td>{template.name}</td>
                  <td>{template.categoryTitle}</td>
                  <td>{template.updateDate.format("YYYY-MM-DD")} </td>
                  <td>{template.modifierName}</td>
                  <td>
                    {/* <Button
                        icon={ sendIcon }
                        onClick={ (e: any) => {
                          this.store.setSelectedTemplate(template);
                          this.store.setFormMode(FormMode.Read);
                          this.store.setOpenPopup(true);

                          e.stopPropagation();
                        }} /> */}
                  </td>
                </tr>
              );
            }
          )
          }

          {this.inspectionTemplates && this.props.templateType === ETEMPLATETYPE.INSPECTION &&
          this.inspectionTemplates.map((template, i) => {
              return (
                <tr
                  key={i}
                  onClick={() => {
                      // this.inspectionStore.setSelectedTemplate(template);
                      // this.inspectionStore.setFormMode(FormMode.Edit);
                      // this.inspectionStore.setOpenPopup(true);
                    this.props.history.push(`/templates/inspections/${template.id}/edit`);

                  }}
                >
                  <td/>
                  <td>{template.name}</td>
                  <td>{template.updateDate.format("YYYY-MM-DD")} </td>
                  <td>{template.modifierName}</td>
                  <td>
                    {/* <Button
                        icon={ sendIcon }
                        onClick={ (e: any) => {
                          this.store.setSelectedTemplate(template);
                          this.store.setFormMode(FormMode.Read);
                          this.store.setOpenPopup(true);

                          e.stopPropagation();
                        }} /> */}
                  </td>
                </tr>
              );
            }
          )
          }


          </tbody>

        </HTMLTable>

        <DetailDialog
          templateType={templateType}
          handleClose={() => {
            this.store.setOpenPopup(false);
            this.store.setSelectedTemplate();
          }}/>

        {/* <SendDialog
          templateType={ templateType } /> */}

        {/* <Paginator
          pageGroup={pageGroup}
          totalPages={totalPages}
          currentPage={currentPage}
          setPageGroup={setPageGroup}
          setCurentPage={setCurentPage}
          onFetch={this.handleSearch}
        /> */}
      </>
    );
  }
}

export default NotificationTemplatePanel;
