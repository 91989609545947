import React, { Component } from "react";
import moment from 'moment';
import ReactDOMServer from 'react-dom/server';
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { Sprint } from "../../../store/models/Sprint";
import { SprintReport } from "../../../store/models/SprintReport";
import { ProjectGroup } from "../../../store/models/ProjectGroup";
import { UserStore } from "../../../store/UserStore";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import { Button, Classes, Dialog, Intent, Icon } from "@blueprintjs/core";
import { observable, computed } from "mobx";
import { FormMode } from "../../../types/formMode";

import ReportTemplate from "./ReportTemplate";
import RecipientPopup from './RecipientPopup';

const DialogWrapper = styled(Dialog)`
  position: relative;
  width: 785px !important;
  padding: 0px !important;
  border-radius: 0px !important;
  background: #ffffff;

  .bp3-dialog-footer {
    margin: 0px 40px 30px 0px !important;
  }
  .bp3-dialog-footer-actions {
    padding: 5px;
    button {
      position: absolute;
      left: 785px;
      .bp3-button-text { color: #ffffff; }
    }
    button.disabled {
      cursor: not-allowed;
      .bp3-button-text { color: #738694; }
    }
    button.active {
      background-color: #fcd262 !important;
      font-weight: bold;
    }
    .bp3-icon.close {
      cursor: pointer;
      position: absolute;
      top: 4px;
      left: 795px;
    }
    button:nth-child(2) {
      top: 60px;
    }
    button:nth-child(3) {
      top: 100px;
    }
    button:nth-child(4) {
      top: 140px;
    }
    button:nth-child(5) {
      top: 180px;
    }
  }
`;

interface SprintReportPopupProps {
  mode: FormMode;
  user: UserStore;
  projectGroup: ProjectGroup;
  sprintNumber: number;
  report: SprintReport | undefined;
  sprints: Sprint[];
  isOpened: boolean;
  onClose: () => void;
}

interface InjectedProps extends SprintReportPopupProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class SprintReportPopup extends Component<SprintReportPopupProps> {
  @observable inspectionId = '';
  @observable version = 1;
  @observable postfix = '';
  @observable comment = '';
  @observable roles = [''];
  @observable rufrees = [''];
  @observable services = [''];
  
  @observable isRecipientPopup = false;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get inspection() {
    return this.injected.appStore.inspectionStore.inspections.find(
      x => x.inspectionId === this.inspectionId
    )
  }

  get initialRolesOfSprint() {
    const { sprints } = this.props;
    return sprints.map(sprint => sprint.role);
  }

  get initialRufreesOfSprint() {
    const { sprints } = this.props;
    return sprints.map(sprint => sprint.rufreeName);
  }

  get seesoServices() {
    const { sprintNumber } = this.props;

    if(this.inspection) {
      if(sprintNumber === 1) {
        return this.inspection.seesoServiceOfSprint(0).concat(this.inspection.seesoServiceOfSprint(sprintNumber));
      }
      return this.inspection.seesoServiceOfSprint(sprintNumber);
    }
    return [];
  }

  get initialRoles() {
    const { report } = this.props;

    if(report) {
      return (report.rawData && report.rawData.roles) ? report.rawData.roles : [];
    } else {
      return this.initialRolesOfSprint;
    }
  }

  get initialRufrees() {
    const { report } = this.props;

    if(report) {
      return (report.rawData && report.rawData.rufrees) ? report.rawData.rufrees : [];
    } else {
      return this.initialRufreesOfSprint;
    }
  }
  
  get initialServices() {
    const { report } = this.props;

    if(report) {
      return (report.rawData && report.rawData.services) ? report.rawData.services : [];
    } else {
      return this.seesoServices.map(service => service.service);
    }
  }

  private clearObservable() {
    this.version = 1;
    this.postfix = '';
    this.comment = '';
    this.roles = [''];
    this.rufrees = [''];
  }

  handleOpening = async() => {
    this.clearObservable();

    const { report, projectGroup } = this.props;

    // 시소서비스를 위한 검수서 데이터 준비.
    if(projectGroup.inspections.length > 0) {
      const { inspections } = projectGroup;

      this.inspectionId = inspections[0]
      await this.injected.appStore.inspectionStore.fetchInspectionById(inspections[0]);
    }

    // 스프린트 보고서 데이터 준비.
    if(report) {
      const { rawData } = report;

      this.postfix = rawData && rawData.postfix ? rawData.postfix : '';
      this.comment = rawData && rawData.comment ? rawData.comment : '';
      this.version = report.version;
    } else {
      const { sprintNumber } = this.props;

      this.postfix = '';
      this.comment = '';
      this.version = projectGroup.sprintReports.filter(sr => sr.sprint === sprintNumber).length;
    }

    this.roles = this.initialRoles;
    this.rufrees = this.initialRufrees;
    this.services = this.initialServices;
  }

  handleCreateOrUpdate = async(created:boolean, datePublich?: string) => {
    const { report, sprintNumber, projectGroup, sprints } = this.props;
    const reportTemplateForView = this.renderReportTemplate(
      FormMode.Read,
      true,
      sprintNumber,
      sprints,
      projectGroup,
      report,
      datePublich
    )
    const reportHtmlCode = ReactDOMServer.renderToString(reportTemplateForView);

    if(created) {
      await projectGroup.addSprintReport(
        sprintNumber,
        this.version+1,
        this.postfix,
        this.comment,
        this.roles,
        this.rufrees,
        this.services,
        reportHtmlCode
      )
    } else {
      report && await report.update(
        sprintNumber,
        this.postfix,
        this.comment,
        this.roles,
        this.rufrees,
        this.services,
        reportHtmlCode
      )
    }
    

    projectGroup.fetchSprintReports();

  }

  renderReportTemplate(
    mode: FormMode,
    isGenerateHtml: boolean,
    sprintNumber: number,
    sprints: Sprint[],
    projectGroup: ProjectGroup,
    report: SprintReport | undefined,
    datePublich?: string
  ) {
    return (
      <ReportTemplate
        mode={mode}
        isGenerateHtml={isGenerateHtml}
        projectGroup={projectGroup}
        sprintNumber={sprintNumber}
        sprints={sprints}
        report={report}
        inspectionId={this.inspectionId}
        // datePublish={report ? report.datePublish : ''}
        datePublish={datePublich ? datePublich : ''}
        postfix={this.postfix}
        comment={this.comment}
        roles={this.roles}
        rufrees={this.rufrees}
        services={this.services}
        seesoServices={this.seesoServices}
        handleChangePostfix={ (postfix: string) => this.postfix = postfix}
        handleChangeComment={ (comment: string) => this.comment = comment }
        handleChangeRoles={ (roles: string[], rufrees: string[]) => {
          this.roles = roles;
          this.rufrees = rufrees;
        }}
        handleResetRoles={ () => {
          this.roles = this.initialRolesOfSprint;
          this.rufrees = this.initialRufreesOfSprint;
        }}
        handleChangeServices={ (services: string[]) => this.services = services }
        handleResetServices={ () => this.services = this.seesoServices.map(service => service.service)} />
    )
  }

  render() {
    const { isOpened, mode, sprintNumber, sprints, projectGroup, report } = this.props;
    const reportTemplate = this.renderReportTemplate(
      mode,
      false,
      sprintNumber,
      sprints,
      projectGroup,
      report
    );
  
    return (
      <>
        <DialogWrapper
          onOpening={this.handleOpening}
          isOpen={isOpened}
          onClosing={this.props.onClose}
          canEscapeKeyClose={true}
        >
          { reportTemplate }

          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Icon
                className='close'
                icon='cross'
                iconSize={40}
                color='#ffffff'
                onClick={this.props.onClose} />

              {
                mode !== FormMode.Read &&
                  <Button
                    minimal={true}
                    icon={
                      <Icon
                        icon='saved'
                        iconSize={20}
                        color='#ffffff' />
                    }
                    className='save'
                    onClick={ async () => {
                      try{
                        if(mode === FormMode.Create) {
                          await this.handleCreateOrUpdate(true);
                        } else {
                          if (report) {
                            mode === FormMode.Edit && await this.handleCreateOrUpdate(false);
                          }
                        }
                      } catch (e) {
                        AppToaster.show({
                          message: `저장 중 에러가 발생하였습니다.`,
                          intent: Intent.DANGER
                        });
                      }

                      this.props.onClose();
                      
                      AppToaster.show({
                        message: `스프린트 보고서가 저장되었습니다.`,
                        intent: Intent.SUCCESS
                      });
                    }}>
                      {mode === FormMode.Create ? '생성' : '저장'}
                    </Button>
              }
              

              <Button
                minimal={true}
                icon={
                  <Icon
                    icon='envelope'
                    iconSize={20}
                    color={report ? '#ffffff' : '#738694'} />
                }
                className={`send ${report ? '' : 'disabled'}`}
                onClick={async () => {
                  if(report) {
                    mode !== FormMode.Read && await this.handleCreateOrUpdate(false);
                    this.isRecipientPopup = true;
                  } else {
                    AppToaster.show({
                      message: `먼저 스프린트 보고서를 생성하세요.`,
                      intent: Intent.WARNING
                    });
                  }
                }}
              >
                {mode === FormMode.Read ? '재' : ''}발송(수신인 확인)
              </Button>

              <Button
                minimal={true}
                icon={
                  <Icon
                    icon='import'
                    iconSize={20}
                    color={report ? '#ffffff' : '#738694'} />
                }
                className={`download ${report ? '' : 'disabled'}`}
                onClick={async () => {
                  if(report) {
                    mode !== FormMode.Read && await this.handleCreateOrUpdate(false);
                    await report.download(projectGroup.name);
                  } else {
                    AppToaster.show({
                      message: `먼저 스프린트 보고서를 생성하세요.`,
                      intent: Intent.WARNING
                    });
                  }
              }}>
                PDF 다운받기
              </Button>

            </div>
          </div>
        </DialogWrapper>

        {
          report && 
            <RecipientPopup
              pm={ projectGroup.managers[0].name }
              projectGroupName={ projectGroup.name }
              projectGroupId={ projectGroup.groupId }
              report={ report }
              to={ projectGroup.workers.map(worker => worker.email) }
              cc={ projectGroup.managers.map(manager => manager.email) }
              onClose={ async() => { 
                projectGroup.fetchSprintReports();
                this.isRecipientPopup = false;
                this.props.onClose();
              }}
              onAfterSend = { async() => {
                await this.handleCreateOrUpdate(false, moment().format('YYYY.MM.DD HH:mm'))
              }}
              isOpened={ this.isRecipientPopup } />
        }

      </>
    );
  }
}

export default SprintReportPopup;
