import React, { Component, ChangeEvent, FormEvent, FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import {
  FormGroup,
  InputGroup,
  H3,
  Button,
  Intent,
  Checkbox,
  Label,
  RadioGroup,
  Radio,
  Callout,
  TextArea,
  Collapse,
  Spinner
} from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import ContentContainer from "../../atoms/ContentContainer/ContentContainer";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { computed, observable } from "mobx";
import moment from "moment";
import { getMomentFormatter } from "../../../utils/date";
import { Affinity } from "../../../types/risk";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import { Redirect } from "react-router";
import { FormMode } from "../../../types/formMode";
import ConsultFunctionalityFormView from "../../molecules/ConsultFunctionalityFormView/ConsultFunctionalityFormView";
import ConsultAdminTypeFormView from "../../molecules/ConsultAdminTypeFormView/ConsultAdminTypeFormView";
import PMUserSelection from "../../molecules/PMUserSelection/PMUserSelection";
import { ConvertLinkTextFormat }  from "../../atoms/ConvertLinkTextFormat/ConvertLinkTextFormat";
import { PMUser } from "../../../store/models/PMUser";
import TemporaryStorage from "../../molecules/Utility/TemporaryStorage/TemporaryStorage";
import ReceptionSelection from "../../molecules/ReceptionSelection/ReceptionSelection";
import { Reception } from "../../../store/models/Reception";

const Wrapper = styled.div`
  margin-bottom: 50px;
`;

const Section = styled.section`
  margin-bottom: 40px;
`;

const SubSection = styled.section`
  margin-bottom: 30px;
`;

const FlexRow = styled.div`
  display: flex;
`;

const ProjectNameContainer = styled.div`
  width: 70%;
  padding-right: 20px;
`;

const ClientFieldsRow = styled.div`
  display: flex;
  flex-wrap: nowrap;

  & > * {
    width: 100%;
  }

  & > * + * {
    margin-left: 20px;
  }
`;

const DateContainer = styled.div`
  width: 30%;
`;

const ButtonsRow = styled.div`
  text-align: right;

  & > * + * {
    margin-left: 5px;
  }
`;

const ETCInputWrapper = styled.div`
  max-width: 300px;
`;

const MutedLabel: FC = ({ children }) => {
  return (
    <Label>
      <strong className="bp3-text-muted">{children}</strong>
    </Label>
  );
};

interface ConsultingFormPanelProps {
  mode: FormMode;
  projectId?: number;
}

interface InjectedProps extends ConsultingFormPanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
export class ConsultingFormPanel extends Component<ConsultingFormPanelProps> {
  @observable isPending = false;
  @observable isCreateSuccess = false;
  @observable isEditSuccess = false;
  @observable isFormInitiated = false;
  @observable goToEdit = false;
  @observable showClientReview = false;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get formStore() {
    return this.injected.appStore.createProjectFormStore;
  }

  @computed
  get currentForm() {
    return this.formStore.currentForm;
  }

  @computed
  get isReadMode() {
    return this.props.mode === FormMode.Read;
  }

  @computed
  get selectedConsultant() {
    return this.injected.appStore.pmUserStore.pmUsers.find(
      x => x.id === this.currentForm.consultant
    );
  }

  @computed
  get selectedReception() {
    return this.injected.appStore.receptionStore.receptions.find(
      x => x.id === parseInt(this.currentForm.receptionId)
    );
  }

  async componentDidMount() {
    if (this.formStore.isNewFormWithParams)
      this.formStore.consumeParams();
    else
      await this.formStore.initForm();

    const { mode, projectId } = this.props;

    if (mode !== FormMode.Create && projectId) {
      await this.formStore.fetchProject(projectId);
    }

    if (this.currentForm.receptionId) {
      // await this.injected.appStore.receptionStore.fetchReception(parseInt(this.currentForm.receptionId));
      await this.injected.appStore.receptionStore.fetchReception(this.currentForm.receptionId);
    }

    await this.injected.appStore.pmUserStore.fetchPMUsers();

    this.isFormInitiated = true;
  }

  handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (this.isReadMode) {
      return;
    }

    this.currentForm.setName(e.target.value);
  };

  handleConsultedDateChange = (selectedDate: Date) => {
    if (this.isReadMode) {
      return;
    }

    this.currentForm.setConsultedDate(selectedDate.toISOString());
  };

  handleClientNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.currentForm.setClientName(e.target.value);
  };

  handleClientEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.currentForm.setClientEmail(e.target.value);
  };

  handleClientPhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.currentForm.setClientPhone(e.target.value);
  };

  handleConsultantChange = (selectedValue: PMUser) => {
    this.currentForm.setConsultant(selectedValue.id);
  };

  handleHumanRiskChange = (key: string) => {
    if (this.isReadMode) {
      return;
    }

    this.currentForm.toggleHumanRisk(key);
  };

  handleSlackAffinityChange = (e: FormEvent<HTMLInputElement>) => {
    if (this.isReadMode) {
      return;
    }

    this.currentForm.setSlackAffinity(e.currentTarget.value as Affinity);
  };

  handleTrelloAffinityChange = (e: FormEvent<HTMLInputElement>) => {
    if (this.isReadMode) {
      return;
    }

    this.currentForm.setTrelloAffinity(e.currentTarget.value as Affinity);
  };

  handleOnlineMeetingAffinityChange = (e: FormEvent<HTMLInputElement>) => {
    if (this.isReadMode) {
      return;
    }

    this.currentForm.setOnlineMeetingAffinity(e.currentTarget
      .value as Affinity);
  };

  handleDateChange = (selectedDate: Date, isUserChange: boolean) => {
    if (this.isReadMode) {
      return;
    }

    this.currentForm.setConsultedDate(selectedDate.toISOString());
  };

  handleOpenDateChange = (selectedDate: Date, isUserChange: boolean) => {
    if (this.isReadMode) {
      return;
    }

    this.currentForm.setOpenDate(
      selectedDate ? selectedDate.toISOString() : ""
    );
  };

  toggleOpenDate = () => {
    if (this.isReadMode) {
      return;
    }

    if (this.currentForm.openDate) {
      this.currentForm.setOpenDate(null);
    } else {
      this.currentForm.setOpenDate(new Date().toISOString());
    }
  };

  handleProjectTypeChange = (key: string) => {
    if (this.isReadMode) {
      return;
    }

    this.currentForm.toggleProjectType(key);
  };

  handleExtraProjectTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (this.isReadMode) {
      return;
    }

    this.currentForm.setExtraProjectType(e.target.value);
  };

  handleWorkingTypeChange = (key: string) => {
    if (this.isReadMode) {
      return;
    }

    this.currentForm.toggleWorkingType(key);
  };

  handleExtraWorkingTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (this.isReadMode) {
      return;
    }

    this.currentForm.setExtraWorkingType(e.target.value);
  };

  handleWebTypeChange = (e: FormEvent<HTMLInputElement>) => {
    if (this.isReadMode) {
      return;
    }

    this.currentForm.setWebType(e.currentTarget.value as "분리형" | "반응형");
  };

  handleAppTypeChange = (key: string) => {
    if (this.isReadMode) {
      return;
    }

    this.currentForm.toggleAppType(key);
  };

  handleExtraAppTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (this.isReadMode) {
      return;
    }

    this.currentForm.setExtraAppType(e.target.value);
  };

  handleMemoChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (this.isReadMode) {
      return;
    }

    this.currentForm.setMemo(e.target.value);
  };
  handleDevEnvironmentMemoChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (this.isReadMode) {
      return;
    }

    this.currentForm.setDevEnvironmentMemo(e.target.value);
  };

  // Client Reviews
  handleClientHumanRiskChange = (key: string) => {
    if (this.isReadMode) {
      return;
    }

    this.currentForm.toggleClientHumanRisk(key);
  };

  handleContractRiskChange = (key: string) => {
    if (this.isReadMode) {
      return;
    }

    this.currentForm.toggleContractRisk(key);
  };

  handleReferenceRiskChange = (key: string) => {
    if (this.isReadMode) {
      return;
    }

    this.currentForm.toggleReferenceRisks(key);
  };

  handleProjectRiskChange = (key: string) => {
    if (this.isReadMode) {
      return;
    }

    this.currentForm.toggleProjectRisks(key);
  };

  handleReceptionChange = (reception: Reception) => {
    this.currentForm.setReceptionId(reception.receptionId || "");
  };

  toggleShowClientReview = () => {
    this.showClientReview = !this.showClientReview;
  };

  handleSubmit = async () => {
    try {
      this.isPending = true;

      await this.formStore.postProject();
      AppToaster.show({
        message: "새로운 상담이 생성되었습니다.",
        intent: Intent.SUCCESS
      });

      this.isCreateSuccess = true;
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    } finally {
      this.isPending = false;
    }
  };

  handleEditSubmit = async () => {
    try {
      this.isPending = true;

      await this.formStore.patchProject();
      AppToaster.show({
        message: "수정되었습니다.",
        intent: Intent.SUCCESS
      });

      this.isEditSuccess = true;
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    } finally {
      this.isPending = false;
    }
  };

  handleGoToEdit = () => {
    this.goToEdit = true;
  };

  render() {
    if (this.isCreateSuccess) {
      return <Redirect to={`/consulting/projects/${this.currentForm.projectId}`}/>;
    }

    if (this.isEditSuccess) {
      return (
        <Redirect
          to={`/consulting/projects/${this.currentForm.projectId}`}
          push={true}
        />
      );
    }

    if (this.goToEdit) {
      return (
        <Redirect
          to={`/consulting/projects/${this.currentForm.projectId}/edit`}
          push={true}
        />
      );
    }

    if (!this.isFormInitiated) {
      return <Spinner/>;
    }

    const { currentForm, isReadMode } = this;

    return (
      <Wrapper>
        <ContentContainer>
          <Section> {/*fieldTitle={""}*/}
            <TemporaryStorage keyName={"Consulting"} title={"name"} setForm={(val) => {
              this.formStore.setForm(val);
            }} currentForm ={this.currentForm} />

            <FlexRow>
              <ProjectNameContainer>
                <FormGroup
                  label={<strong>프로젝트 명</strong>}
                  className="bp3-text-muted"
                >
                  <InputGroup
                    value={currentForm.name}
                    onChange={this.handleNameChange}
                    readOnly={isReadMode}
                  />
                </FormGroup>
              </ProjectNameContainer>
              <DateContainer>
                <FormGroup
                  label={<strong>상담일</strong>}
                  className="bp3-text-muted"
                >
                  <DateInput
                    {...getMomentFormatter("YYYY/MM/DD")}
                    locale="ko"
                    closeOnSelection={true}
                    value={moment(currentForm.consultedDate + "").toDate()}
                    onChange={this.handleDateChange}
                    maxDate={new Date("2050-01-01")}
                    disabled={isReadMode}
                  />
                </FormGroup>
              </DateContainer>
            </FlexRow>
          </Section>

          <Section>
            <ClientFieldsRow>
              <FormGroup
                label={<strong>상담 클라이언트 이름</strong>}
                className="bp3-text-muted"
              >
                <InputGroup
                  value={currentForm.clientName}
                  onChange={this.handleClientNameChange}
                  readOnly={isReadMode}
                />
              </FormGroup>
              <FormGroup
                label={<strong>상담 클라이언트 이메일</strong>}
                className="bp3-text-muted"
              >
                <InputGroup
                  value={currentForm.clientEmail}
                  onChange={this.handleClientEmailChange}
                  readOnly={isReadMode}
                />
              </FormGroup>
              <FormGroup
                label={<strong>상담 클라이언트 휴대폰</strong>}
                className="bp3-text-muted"
              >
                <InputGroup
                  value={currentForm.clientPhone}
                  onChange={this.handleClientPhoneChange}
                  readOnly={isReadMode}
                />
              </FormGroup>
            </ClientFieldsRow>
          </Section>

          <Section>
          <ClientFieldsRow>
            <FormGroup
              label={<strong>상담자</strong>}
              className="bp3-text-muted"
            >
              {this.props.mode === FormMode.Read ? (
                <InputGroup
                  value={
                    this.selectedConsultant
                      ? this.selectedConsultant.name
                      : "로딩 중"
                  }
                  readOnly={true}
                  style={{ width: "150px" }}
                />
              ) : (
                <PMUserSelection
                  onChange={this.handleConsultantChange}
                  currentValue={this.selectedConsultant}
                  blockFetchingData={true}
                />
              )}
            </FormGroup>

            <FormGroup
              label={<strong>의뢰서</strong>}
              className="bp3-text-muted"
            >
              {this.props.mode === FormMode.Read ? (
                this.selectedReception ?
                  <Link to={`/receptions/${ this.selectedReception.id}`} target="_blank">
                    [{this.selectedReception.receptionNumber}] { this.selectedReception.title }
                  </Link>
                  : <span>연결된 의뢰서 없음</span>
              ) : (
                <ReceptionSelection
                  onChange={this.handleReceptionChange}
                  currentValue={this.selectedReception} />
              )}
            </FormGroup>

            </ClientFieldsRow>
          </Section>

          <Section>
            <SubSection>
              <MutedLabel>의뢰내용 관련 상세메모</MutedLabel>
              <FormGroup>
              {this.isReadMode ? (<div 
                style={{ minHeight: "300px", border: " 1px solid #e5e5e5", padding: "5px" }}>
                      {currentForm.memo.split('\n').map( (line, j) => {
                        return (<span key={j}>{<ConvertLinkTextFormat>{line}</ConvertLinkTextFormat>}<br/></span>)
                      })}
                </div>) : (<TextArea
                  value={currentForm.memo}
                  onChange={this.handleMemoChange}
                  fill={true}
                  style={{ minHeight: "300px" }}
                />) }
                
              </FormGroup>
            </SubSection>

            <SubSection>
              <MutedLabel>개발환경 상세메모</MutedLabel>
              <FormGroup>
                {this.isReadMode ? (<div
                  style={{ minHeight: "300px", border: " 1px solid #e5e5e5", padding: "5px" }}>
                  {currentForm.devEnvironmentMemo.split('\n').map( (line, j) => {
                    return (<span key={j}>{<ConvertLinkTextFormat>{line}</ConvertLinkTextFormat>}<br/></span>)
                  })}
                </div>) : (<TextArea
                  value={currentForm.devEnvironmentMemo}
                  onChange={this.handleDevEnvironmentMemoChange}
                  fill={true}
                  style={{ minHeight: "300px" }}
                />) }

              </FormGroup>
            </SubSection>

            <SubSection>
              <MutedLabel>관계자들의 협조/개입 관련 리스크</MutedLabel>
              <Checkbox
                checked={currentForm.isHumanRiskChecked("dev_friendly")}
                onChange={() => this.handleHumanRiskChange("dev_friendly")}
              >
                프로젝트 담당자가 개발 업무 프로젝트의 프로세스에 친숙합니다.
              </Checkbox>
              <Checkbox
                checked={currentForm.isHumanRiskChecked("internal_worker")}
                onChange={() => this.handleHumanRiskChange("internal_worker")}
              >
                프로젝트에 실무자로 참여하는 내부 인력이 있습니다.
              </Checkbox>
              <Checkbox
                checked={currentForm.isHumanRiskChecked("other_staff")}
                onChange={() => this.handleHumanRiskChange("other_staff")}
              >
                담당자 외의 전문 관계자가 프로젝트에 개입될 여지가 있습니다.
              </Checkbox>
              <Checkbox
                checked={currentForm.isHumanRiskChecked(
                  "unverified_other_staff"
                )}
                onChange={() =>
                  this.handleHumanRiskChange("unverified_other_staff")
                }
              >
                담당자 외의 비전문 관계자가 프로젝트에 개입될 여지가 있습니다
              </Checkbox>
              <Checkbox
                checked={currentForm.isHumanRiskChecked("external_staff")}
                onChange={() => this.handleHumanRiskChange("external_staff")}
              >
                클라이언트, 시소외의 제3의 관계자와 협업이 필요합니다
              </Checkbox>
              <Checkbox
                checked={currentForm.isHumanRiskChecked("authority_in_manager")}
                onChange={() =>
                  this.handleHumanRiskChange("authority_in_manager")
                }
              >
                프로젝트 담당자가 결정권을 가지고 있습니다
              </Checkbox>
            </SubSection>

            <SubSection>
              <MutedLabel>프로젝트 담당자 슬랙 친숙도</MutedLabel>
              <RadioGroup
                selectedValue={currentForm.slackAffinity}
                onChange={this.handleSlackAffinityChange}
              >
                <Radio label="상" value={Affinity.High}/>
                <Radio label="중" value={Affinity.Middle}/>
                <Radio label="하" value={Affinity.Low}/>
              </RadioGroup>
            </SubSection>

            <SubSection>
              <MutedLabel>프로젝트 담당자 트렐로 친숙도</MutedLabel>
              <RadioGroup
                selectedValue={currentForm.trelloAffinity}
                onChange={this.handleTrelloAffinityChange}
              >
                <Radio label="상" value={Affinity.High}/>
                <Radio label="중" value={Affinity.Middle}/>
                <Radio label="하" value={Affinity.Low}/>
              </RadioGroup>
            </SubSection>

            <SubSection>
              <MutedLabel>프로젝트 담당자 온라인 화상회의 친숙도</MutedLabel>
              <RadioGroup
                selectedValue={currentForm.onlineMeetingAffinity}
                onChange={this.handleOnlineMeetingAffinityChange}
              >
                <Radio label="상" value={Affinity.High}/>
                <Radio label="중" value={Affinity.Middle}/>
                <Radio label="하" value={Affinity.Low}/>
              </RadioGroup>
            </SubSection>

            <SubSection>
              <MutedLabel>오픈일정이 고정시 날짜</MutedLabel>
              {currentForm.openDate && (
                <DateInput
                  {...getMomentFormatter("YYYY/MM/DD")}
                  locale="ko"
                  closeOnSelection={true}
                  value={moment(currentForm.openDate).toDate()}
                  onChange={this.handleOpenDateChange}
                  maxDate={new Date("2050-01-01")}
                  disabled={isReadMode}
                />
              )}
              <Checkbox
                checked={!currentForm.openDate}
                onChange={this.toggleOpenDate}
                inline={true}
                style={{ marginLeft: "10px" }}
              >
                없음
              </Checkbox>
            </SubSection>
          </Section>

          <Section>
            <SubSection>
              <MutedLabel>프로젝트의 종류를 선택</MutedLabel>
              <Checkbox
                checked={currentForm.isProjectTypeChecked("web")}
                onChange={() => this.handleProjectTypeChange("web")}
                inline={true}
              >
                웹
              </Checkbox>
              <Checkbox
                checked={currentForm.isProjectTypeChecked("app")}
                onChange={() => this.handleProjectTypeChange("app")}
                inline={true}
              >
                앱
              </Checkbox>
              <Checkbox
                checked={currentForm.isProjectTypeChecked("etc")}
                onChange={() => this.handleProjectTypeChange("etc")}
                inline={true}
              >
                기타
              </Checkbox>
              {currentForm.isProjectTypeChecked("etc") && (
                <ETCInputWrapper>
                  <InputGroup
                    placeholder="기타 선택시 사용"
                    value={currentForm.extraProjectType}
                    onChange={this.handleExtraProjectTypeChange}
                  />
                </ETCInputWrapper>
              )}
            </SubSection>

            <SubSection>
              <MutedLabel>실무 선택</MutedLabel>
              <Checkbox
                checked={currentForm.isWorkingTypeChecked("planning")}
                onChange={() => this.handleWorkingTypeChange("planning")}
                inline={true}
              >
                기획
              </Checkbox>
              <Checkbox
                checked={currentForm.isWorkingTypeChecked("design")}
                onChange={() => this.handleWorkingTypeChange("design")}
                inline={true}
              >
                디자인
              </Checkbox>
              <Checkbox
                checked={currentForm.isWorkingTypeChecked("dev")}
                onChange={() => this.handleWorkingTypeChange("dev")}
                inline={true}
              >
                개발
              </Checkbox>
              <Checkbox
                checked={currentForm.isWorkingTypeChecked("etc")}
                onChange={() => this.handleWorkingTypeChange("etc")}
                inline={true}
              >
                기타
              </Checkbox>
              {currentForm.isWorkingTypeChecked("etc") && (
                <ETCInputWrapper>
                  <InputGroup
                    placeholder="기타 선택시 사용"
                    value={currentForm.extraWorkingType}
                    onChange={this.handleExtraWorkingTypeChange}
                  />
                </ETCInputWrapper>
              )}
            </SubSection>

            {currentForm.isProjectTypeChecked("web") && (
              <SubSection>
                <MutedLabel>웹 타입</MutedLabel>
                <RadioGroup
                  selectedValue={currentForm.webType}
                  onChange={this.handleWebTypeChange}
                >
                  <Radio label="웹 - 타입 : 분리형" value="분리형"/>
                  <Radio label="웹 - 타입 : 반응형" value="반응형"/>
                </RadioGroup>
              </SubSection>
            )}

            {currentForm.isProjectTypeChecked("app") && (
              <SubSection>
                <MutedLabel>앱 타입</MutedLabel>
                <Checkbox
                  checked={currentForm.isAppTypeChecked("ios")}
                  onChange={() => this.handleAppTypeChange("ios")}
                >
                  앱 - 타입 : iOS
                </Checkbox>
                <Checkbox
                  checked={currentForm.isAppTypeChecked("aos")}
                  onChange={() => this.handleAppTypeChange("aos")}
                >
                  앱 - 타입 : AOS
                </Checkbox>
                <Checkbox
                  checked={currentForm.isAppTypeChecked("device-pad")}
                  onChange={() => this.handleAppTypeChange("device-pad")}
                >
                  앱 - 디바이스 : 패드
                </Checkbox>
                <Checkbox
                  checked={currentForm.isAppTypeChecked("device-phone")}
                  onChange={() => this.handleAppTypeChange("device-phone")}
                >
                  앱 - 디바이스 : 폰
                </Checkbox>
                <Checkbox
                  checked={currentForm.isAppTypeChecked("device-etc")}
                  onChange={() => this.handleAppTypeChange("device-etc")}
                >
                  앱 - 디바이스 : 기타
                </Checkbox>
                {currentForm.isAppTypeChecked("device-etc") && (
                  <FormGroup label="기타 디바이스">
                    <InputGroup
                      value={currentForm.extraAppType}
                      onChange={this.handleExtraAppTypeChange}
                    />
                  </FormGroup>
                )}
                <Checkbox
                  checked={currentForm.isAppTypeChecked("native")}
                  onChange={() => this.handleAppTypeChange("native")}
                >
                  앱 - 언어 : Native
                </Checkbox>
                <Checkbox
                  checked={currentForm.isAppTypeChecked("react-native")}
                  onChange={() => this.handleAppTypeChange("react-native")}
                >
                  앱 - 언어 : React Native
                </Checkbox>
              </SubSection>
            )}

            <SubSection>
              <MutedLabel>기능</MutedLabel>
              {currentForm.functionalities.map((functionality, index) => (
                <ConsultFunctionalityFormView
                  key={index}
                  functionality={functionality}
                  isReadMode={isReadMode}
                  onRemove={currentForm.removeFunctionality}
                />
              ))}
              {!this.isReadMode && (
                <Button
                  text="항목 추가"
                  icon="add"
                  onClick={currentForm.addFunctionality}
                />
              )}
            </SubSection>

            <SubSection>
              <MutedLabel>관리자</MutedLabel>
              {currentForm.adminTypes.map((adminType, index) => (
                <ConsultAdminTypeFormView
                  key={index}
                  adminType={adminType}
                  isReadMode={isReadMode}
                  onRemove={currentForm.removeAdminType}
                />
              ))}
              {!this.isReadMode && (
                <Button
                  text="항목 추가"
                  icon="add"
                  onClick={currentForm.addAdminType}
                />
              )}
            </SubSection>
          </Section>

          <Section>
            <Callout>
              계약금비율
              <br/>
              특약사항
              <br/>
              운영방식
              <br/>
              클라이언트 역할
              <br/>
              *설문지 관련
              <br/>
              *비용집행 프로세스
              <br/>
              *알유프리 삼진아웃제
              <br/>
              *프로젝트 홀딩 관련 제도
              <br/>
              *하자/유지보수관련(태스크태스크)
            </Callout>
          </Section>

          <Section>
            <H3>
              <Button
                minimal={true}
                icon="eye-open"
                text="표시"
                onClick={this.toggleShowClientReview}
              />
            </H3>

            <Collapse isOpen={this.showClientReview} keepChildrenMounted={true}>
              <div>
                <SubSection>
                  <MutedLabel>관계자들의 협조/개입 관련 리스크</MutedLabel>
                  <Checkbox
                    checked={currentForm.isClientHumanRiskChecked(
                      "detailed_requirements"
                    )}
                    onChange={() =>
                      this.handleClientHumanRiskChange("detailed_requirements")
                    }
                  >
                    요구사항이 디테일함
                  </Checkbox>
                  <Checkbox
                    checked={currentForm.isClientHumanRiskChecked("delegation")}
                    onChange={() =>
                      this.handleClientHumanRiskChange("delegation")
                    }
                  >
                    시소에게 위임하려는 경향이 있음
                  </Checkbox>
                </SubSection>

                <SubSection>
                  <MutedLabel>계약관련 리스크</MutedLabel>
                  <Checkbox
                    checked={currentForm.isContractRiskChecked(
                      "expired_by_opening"
                    )}
                    onChange={() =>
                      this.handleContractRiskChange("expired_by_opening")
                    }
                  >
                    오픈으로 계약이 만료되는 경우
                  </Checkbox>
                  <Checkbox
                    checked={currentForm.isContractRiskChecked(
                      "too_much_balance"
                    )}
                    onChange={() =>
                      this.handleContractRiskChange("too_much_balance")
                    }
                  >
                    시소에게 너무 많은 비용을 선지급하는 경우
                  </Checkbox>
                </SubSection>

                <SubSection>
                  <MutedLabel>사전자료로 인한 불확실성 리스크</MutedLabel>
                  <Checkbox
                    checked={currentForm.isReferenceRiskChecked(
                      "reference_exists"
                    )}
                    onChange={() =>
                      this.handleReferenceRiskChange("reference_exists")
                    }
                  >
                    사전자료가 있는 경우
                  </Checkbox>
                  <Checkbox
                    checked={currentForm.isReferenceRiskChecked("no_reference")}
                    onChange={() =>
                      this.handleReferenceRiskChange("no_reference")
                    }
                  >
                    작업을 위한 사전자료가 준비되지 않은 경우
                  </Checkbox>
                  <Checkbox
                    checked={currentForm.isReferenceRiskChecked(
                      "unverified_plan"
                    )}
                    onChange={() =>
                      this.handleReferenceRiskChange("unverified_plan")
                    }
                  >
                    비전문가가 작성 또는 시소에서 검증이 되지 않은 기획서
                  </Checkbox>
                  <Checkbox
                    checked={currentForm.isReferenceRiskChecked(
                      "pm_taking_over"
                    )}
                    onChange={() =>
                      this.handleReferenceRiskChange("pm_taking_over")
                    }
                  >
                    클라이언트가 PM하던 업무를 시소PM 인수인계 받는 경우
                  </Checkbox>
                  <Checkbox
                    checked={currentForm.isReferenceRiskChecked(
                      "work_taking_over"
                    )}
                    onChange={() =>
                      this.handleReferenceRiskChange("work_taking_over")
                    }
                  >
                    기존 작업을 인수인계 받아서 해야하는 업무
                  </Checkbox>
                </SubSection>

                <SubSection>
                  <MutedLabel>프로젝트 내용 리스크</MutedLabel>
                  <Checkbox
                    checked={currentForm.isProjectRiskChecked(
                      "back_front_both"
                    )}
                    onChange={() =>
                      this.handleProjectRiskChange("back_front_both")
                    }
                  >
                    백엔드와 프론트 동시에 들어가야하는 경우
                  </Checkbox>
                  <Checkbox
                    checked={currentForm.isProjectRiskChecked(
                      "external_system"
                    )}
                    onChange={() =>
                      this.handleProjectRiskChange("external_system")
                    }
                  >
                    심사, 연동 등이 필요한 외부 시스템 활용
                  </Checkbox>
                  <Checkbox
                    checked={currentForm.isProjectRiskChecked("ios_project")}
                    onChange={() => this.handleProjectRiskChange("ios_project")}
                  >
                    iOS 프로젝트
                  </Checkbox>
                  <Checkbox
                    checked={currentForm.isProjectRiskChecked(
                      "too_much_boundary"
                    )}
                    onChange={() =>
                      this.handleProjectRiskChange("too_much_boundary")
                    }
                  >
                    범위가 너무 커서 예측도가 많이 떨어지는 경우
                  </Checkbox>
                  <Checkbox
                    checked={currentForm.isProjectRiskChecked("rare_project")}
                    onChange={() =>
                      this.handleProjectRiskChange("rare_project")
                    }
                  >
                    레어한 종류의 업무
                  </Checkbox>
                  <Checkbox
                    checked={currentForm.isProjectRiskChecked(
                      "difficulty_in_ie"
                    )}
                    onChange={() =>
                      this.handleProjectRiskChange("difficulty_in_ie")
                    }
                  >
                    IE 작업에서 까다로울 수 있는 웹 업무
                  </Checkbox>
                </SubSection>
              </div>
            </Collapse>
          </Section>

          {this.props.mode === FormMode.Create && (
            <ButtonsRow>
              <Button text="취소"/>
              <Button
                text="저장"
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit}
                loading={this.isPending}
              />
            </ButtonsRow>
          )}

          {this.props.mode === FormMode.Edit && (
            <ButtonsRow>
              <Button
                text="수정사항 적용"
                intent={Intent.PRIMARY}
                onClick={this.handleEditSubmit}
                loading={this.isPending}
              />
            </ButtonsRow>
          )}

          {this.props.mode === FormMode.Read && (
            <ButtonsRow>
              <Button
                text="수정하기"
                icon="edit"
                onClick={this.handleGoToEdit}
              />
            </ButtonsRow>
          )}
        </ContentContainer>
      </Wrapper>
    );
  }
}

export default ConsultingFormPanel;
