import React, { FC, useState } from "react";
import styled from "styled-components/macro";

import { NavLink } from "react-router-dom";

import { Collapse, Colors, Classes, Tooltip, Position, Icon } from "@blueprintjs/core"

const Wrapper = styled(NavLink)`
  display: block;
  align-items: center;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: ${ Colors.BLUE3 }
  }
`;

const Main = styled.div`
  display: flex;
`;

const Sub = styled(Collapse)`
  padding: 10px 0px 0px 30px;
  font-weight: bold;
  font-size: 14px;
  color: ${ Colors.GRAY1 };
  line-height: 30px;
  text-overflow: ellipsis;
`;

const ItemIcon = styled.span<{ src: string | undefined }>`
  background: url(${p => p.src ? p.src : ''}) no-repeat center center;
  width: 20px;
  height: 20px;
  display: inline-block;
`;

const ItemLabel = styled.div`
  margin-left: 13px;
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
  color: #161d2e;
`;

interface Props {
  collapsed: boolean;
  icon?: string;
  title: string;
  to?: string;

  onClick?: () => void;
}
const SideBarMenu: FC<Props> = (props) => {
  const { collapsed, icon, title, to } = props;

  const [ isSubOpened, setIsSubOpened ] = useState(false);

  return (
    <Wrapper
      to={ to ? to : '' }
      onClick={ props.onClick }
      onMouseLeave={ () => props.children && setIsSubOpened(false) }
      onMouseOver={ () => props.children && setIsSubOpened(true) }
    >
      <Main>
        {
          collapsed ?
            <Tooltip content={ title } position={Position.RIGHT}>
              <ItemIcon src={icon} />
            </Tooltip>
          :
            <>
              <ItemIcon src={icon} />
              <ItemLabel>{ title }</ItemLabel>
              {
                props.children &&
                  <Icon
                    icon={ isSubOpened ? "caret-down" : "caret-right"}
                    color={ Colors.GRAY1 }
                    style={{ marginTop: '3px' }}/>
              }
            </>
        }
      </Main>
      
      
      {
        !collapsed &&
          <Sub
            isOpen={isSubOpened}
            className={Classes.TEXT_OVERFLOW_ELLIPSIS}>
              { props.children }
          </Sub>
      }
      
    </Wrapper>
  );
}

export default SideBarMenu;
