import React, { ChangeEvent, Component } from "react";
import styled from "styled-components/macro";
import {
  Alert,
  Button, Card,
  Classes,
  Dialog,
  EditableText, Elevation,
  H1,
  HTMLSelect, HTMLTable,
  InputGroup, Intent,
  MenuItem,
  Position, TextArea
} from "@blueprintjs/core";
import { GreyLabel } from "../../atoms/DetailReportComponents/DetailReportComponents";

import "./TaskDetailPopup.css";
import { computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import SubTaskListView from "../../molecules/TaskManager/SubTaskListView";
import { AppStore } from "../../../store/AppStore";
import { getMomentFormatter } from "../../../utils/date";
import moment from "moment";
import { DateInput } from "@blueprintjs/datetime";
import { ItemPredicate, ItemRenderer, MultiSelect } from "@blueprintjs/select";
import {
  TaskLabelSimpleForm,
  TaskLabelSimpleFormModel,
  TaskSprintSimpleForm,
  TaskSprintSimpleFormModel
} from "../../../store/forms/TaskManagerForm/TaskFormStore";
import { SubTask } from "../../../store/models/SubTask";
import TaskAttachmentListForm from "../../molecules/TaskManager/TaskAttachmentListForm";
import TaskSprintLabelGenericForm from "../../molecules/TaskManager/TaskSprintLabelGenericForm";
import { AppToaster } from "../AppToaster/AppToaster";
import TaskCommentListView from "../../molecules/TaskManager/TaskCommentListView";

import { DropResult, DraggableLocation } from "react-beautiful-dnd";
import sortBy from "lodash/sortBy";

const LabelListMultiSelect = MultiSelect.ofType<TaskLabelSimpleForm>();
const SprintListMultiSelect = MultiSelect.ofType<TaskSprintSimpleForm>();

const TaskLabelForm = TaskSprintLabelGenericForm.ofType<TaskLabelSimpleForm>();
const TaskSprintForm = TaskSprintLabelGenericForm.ofType<TaskSprintSimpleForm>();


const TitleBar = styled.div`
  font-size: 40px;
  line-height: ;
`;

const TopMenuBar = styled.div`
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  margin-bottom: 20px;
`;
const MainTitleBar = styled.span`
  font-size:20pt
`;
const ExplainContent = styled.div`
  margin-left: 10px;
  margin-top:20px;
`;

const ExplainContainer = styled.div`
  margin-top:30px;
`;
const CommentContainer = styled.div`
  margin-bottom: 10px;
`;
const LeftSection = styled.div`
  margin-right
`
const Section = styled.section`
  margin-bottom: 65px;
`;
const Container = styled.div`
  display: grid;
  grid-template-columns: 75% 25%
`;
const TaskTag = styled.span`
  border-radius: 3px;
`;
const TaskHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;

  color: #000;
`;
const TaskHeaderTag = styled(TaskTag)`
  padding: 1px 5px;
  margin-right: 2px;
  margin-bottom: 2px;
`;
const TaskSprint = styled(TaskHeaderTag)`
  background-color: #abf0e9;
`;
const TaskLabel = styled(TaskHeaderTag)`
  background-color: #ffe277;
`;
const EditButton = styled(Button)`
`;
const GrayLink = styled.a`
  color: #7f8390;
  text-decoration: underline;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-top: 7px;
`;
const LoadingBox = styled.div`
  width: 300px;
  height: 100px;
  border: 1px solid blue;
  box-sizing: border-box;
`;
const LoadingText = styled.div`
  animation-delay: 0s,.3s;
  height: 5px;
  opacity: 0;
  width: 50%;
`;

interface TaskDetailPopupProps {
  idTask: string;
  isOpen: boolean;
  viewMode: boolean | undefined;
  onClose: () => void;
}

interface InjectedProps extends TaskDetailPopupProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class TaskDetailPopup extends Component<TaskDetailPopupProps> {
  @observable loaded = false;

  @observable comment = "";
  @observable onLabelEditMode = false;
  @observable onSprintEditMode = false;
  @observable showTaskDeleteAlert = false;
  divRef = React.createRef<HTMLDivElement>();
  handleOnOpening = () => {

  };

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get formStore() {
    return this.injected.appStore.taskManagerTaskFormStore;
  }

  @computed
  get taskManagerStore() {
    return this.injected.appStore.taskManagerStore;
  }

  get currentUser() {
    return this.injected.appStore.userStore;
  }

  @computed
  get currentForm() {
    return this.formStore.currentForm;
  }

  async componentDidMount() {
    await this.formStore.initForm();
    const id = Number(this.props.idTask);
    id && this.loadForm(this.props.idTask);
  }

  async loadForm(idTask: string) {
    try {
      this.loaded = false;
      idTask && await this.formStore.loadForm(Number(idTask));
    } catch (e) {
      this.props.onClose();
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
    setTimeout(() => { //Start the timer
      this.loaded = true;
    }, 1000);
  }

  async componentDidUpdate(prevProps: any) {
    if (this.props.isOpen && prevProps["idTask"] !== this.props["idTask"]) {
      await this.loadForm(this.props.idTask);
    }
  }


  handleTitleChange = (value: string) => {
    this.currentForm.setTitle(value);
    this.formStore.updateForm();
  };

  handleTitleRemove = () => {
    this.currentForm.setTitle("");
  };

  addNewSubTaskListHandler = (val: string) => {
    this.formStore.updateSubTaskList({
      title: val
    }, "post");
  };
  addNewSubTaskTitleHandler = (subTaskListId: string, val: string) => {
    this.formStore.updateSubTask({ subTaskListId: subTaskListId, title: val }, "post");
    // this.currentForm.addSubTaskTitleTo(subTaskListId, val);
  };
  removeSubTaskTitleHandler = (subTask: SubTask) => {
    this.formStore.updateSubTask(subTask, "delete");
    // this.currentForm.removeSubTaskTitleTo(subTaskListId, subTaskId);
  };

  handleCommentSubmit = async () => {
    console.log("onConfirm");
    this.formStore.updateComment({ comment: this.comment }, "post");
    this.comment = "";
  };

  handleSubTaskDragEnd = async (result: DropResult) => {

    // droppable 이 아닌곳일 경우 리턴
    if (!result.destination) {
      return;
    }

    const source: DraggableLocation = result.source;
    const destination: DraggableLocation = result.destination;

    // 원래 자리에 drop 시켰을 경우 리턴
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const draagable_id = Number(result.draggableId.substring(2)); // list는 l-** 형식, task는 t-** 형식이어서 **부분만 추출

    try {
      if(result.type === 'list') {
        this.formStore.moveSubTaskList(draagable_id, source.index, destination.index);
      } else if(result.type === 'task') {
        const src_subtasklist_id = Number(source.droppableId.substring(2)) // droppableId = 'l-**' 형식이라서 **부분만 추출
        const dst_subtasklist_id = Number(destination.droppableId.substring(2))

        await this.formStore.moveSubTask(draagable_id, src_subtasklist_id, dst_subtasklist_id, source.index, destination.index);   
      }    
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
      // 업데이트 중 문제가 발생했을 경우 DB의 최신값을 가져옴
      this.formStore.loadForm(Number(this.props.idTask));      
    }
  }

  selectPersonInCharges = (item: number) => {
    let idx = this.currentForm.personInChargeList.indexOf(item);
    if(idx > -1) {
      this.currentForm.deletePersonInCharge(item);
    } else {
      this.currentForm.addPersonInCharge(item);
    }
    this.handlePersonInChargeChanged();
  }
  removePersonInChange = (item: number, idx: number) => {
    this.currentForm.deletePersonInChargeAt(idx);
    this.handlePersonInChargeChanged();
  }
  handlePersonInChargeChanged = () => {
    try {
      this.formStore.updatePersonInCharge();
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
      // 업데이트 중 문제가 발생했을 경우 DB의 최신값을 가져옴
      this.formStore.loadForm(Number(this.props.idTask));    
    }
  }
  renderPersonInCharge: ItemRenderer<number> = (item, {modifiers, handleClick}) => {
    if(!modifiers.matchesPredicate) {
      return null;
    }
    const found = this.taskManagerStore.currentBoard && this.taskManagerStore.currentBoard.members.find(member => member.id === item);
    return (
      <MenuItem
        key={item}
        icon={this.currentForm.personInChargeList.includes(item) ? "tick" : "blank"}
        text={found ? found.name : 'not found'}
        onClick={handleClick}
      />
    )
  }
  renderPersonInChargeTag = (item: number) => {
    const found = this.taskManagerStore.currentBoard && this.taskManagerStore.currentBoard.members.find(member => member.id === item);
    return <span>{found ? found.name : 'not found'}</span>;
  }

  render() {

    const { isOpen, onClose, viewMode } = this.props;
    const { currentBoard } = this.taskManagerStore;

    return (
      this.currentForm ?
        <div>
          <Dialog
            icon="annotation"
            onClose={onClose}
            title={this.currentForm.isArchived ? "(보관된 Task) Task 상세 " : "Task 상세"}
            isOpen={isOpen}
            usePortal={true}
            autoFocus={true}
            canEscapeKeyClose={true}
            enforceFocus={true}
            onOpening={this.handleOnOpening}
            style={{ width: "800px" }}
            // backdropProps={this.divRef}
          >
            <div className={Classes.DIALOG_BODY} style={{ marginLeft: "20px", height: "80vh", overflowY: 'auto' }}>
              <TopMenuBar>
              <span style={{ display: "inline-block" }}>
                {this.loaded ?
                  <MultiSelect
                    placeholder={'담당자'}
                    itemRenderer={this.renderPersonInCharge}
                    items={currentBoard ? currentBoard.members.map(member => member.id) : []}
                    onItemSelect={this.selectPersonInCharges}
                    tagRenderer={this.renderPersonInChargeTag}
                    tagInputProps={{
                      onRemove: (item: string, idx: number) => {this.removePersonInChange(Number(item), idx)},
                      inputProps: {style: {width: this.currentForm.personInChargeList.length > 0 ? '0px' : '40px'}},
                      rightElement: <Button icon='caret-down' minimal={true} />,
                      tagProps: {minimal: true}
                    }}
                    selectedItems={this.currentForm.personInChargeList}
                  />
                  :
                  <div className={Classes.SKELETON} style={{ height: "30px", width: "240px" }}></div>
                }
              </span>
                <span style={{ display: "inline-block", margin: "0 10px" }}>
              {/*taskListSelections*/}
                  {this.loaded ?
                    <HTMLSelect value={this.currentForm.taskListId}
                                disabled={viewMode}
                                onChange={event => {
                                  this.currentForm.setTaskListId(event.target.value);
                                  this.formStore.updateForm();
                                }}>
                      <option value={"chose"}>
                        테스크 리스트 선택
                      </option>
                      {this.currentForm.taskListSelections.map((taskList) =>
                        <option key={taskList.id} value={taskList.taskListId}>{taskList.title}</option>
                      )}
                    </HTMLSelect> :
                    <div className={Classes.SKELETON} style={{ height: "30px", width: "140px" }}></div>
                  }

            </span>
              </TopMenuBar>

              <Container>
                <div style={{ marginRight: "30px" }}>
                  <MainTitleBar>
                    {this.loaded ?
                      <div>
                        <EditableText multiline={false} value={this.currentForm.title}
                                      onChange={this.handleTitleChange} disabled={viewMode}/>
                        <Button className={"bp3-minimal"} icon="cross" onClick={this.handleTitleRemove}
                                disabled={viewMode}/>
                      </div> :
                      <div className={Classes.SKELETON} style={{ height: "42px", width: "160px" }}></div>
                    }
                  </MainTitleBar>
                  <ExplainContainer>
                    <GreyLabel style={{ marginTop: "23px" }}>설명</GreyLabel>
                    <ExplainContent style={{ marginLeft: "10px", marginTop: "20px" }}>
                      {this.loaded ?
                        <>
                          <EditableText value={this.currentForm.description} disabled={viewMode}
                                        onChange={(value: string) => {
                                          this.currentForm.setDescription(value);
                                          this.formStore.updateForm();
                                        }} multiline={true} minLines={3} maxLines={12}
                                        placeholder={"Task 의 대한 설명을 입력해주세요"}/>
                        </> :
                        <>
                          <div className={Classes.SKELETON} style={{ height: "12px", width: "160px" }}></div>
                          <div className={Classes.SKELETON}
                               style={{ height: "12px", width: "40px", marginTop: "5px" }}></div>
                          <div className={Classes.SKELETON}
                               style={{ height: "12px", width: "260px", marginTop: "5px" }}></div>
                        </>
                      }
                    </ExplainContent>
                  </ExplainContainer>
                  <SubTaskListView
                    subTaskListForms={sortBy(this.currentForm.subTaskListForms, 'order')}
                    onNewSubTaskList={this.addNewSubTaskListHandler}
                    onNewSubTaskTitle={this.addNewSubTaskTitleHandler}
                    onRemoveSubTaskTitle={this.removeSubTaskTitleHandler}
                    onUpdateSubTask={(subTask: SubTask) => {
                      this.formStore.updateSubTask(subTask, "patch");
                    }}
                    onSubTaskDragEnd={this.handleSubTaskDragEnd}
                    disabled={viewMode}
                    loaded={this.loaded}
                  />

                  <div style={{ marginTop: "30px" }}>
                    <TaskAttachmentListForm disabled={viewMode} taskFiles={this.currentForm.taskFiles}
                                            taskLinks={this.currentForm.taskLinks}
                                            taskId={this.currentForm.taskId}
                                            loaded={this.loaded}
                    />
                  </div>

                  <ExplainContainer>
                    <GreyLabel>댓글</GreyLabel>
                    {this.loaded ?
                      <TaskCommentListView viewMode={viewMode}/> :
                      <div style={{ paddingLeft: "15px", marginTop: "10px" }}>
                        <div className={Classes.SKELETON}
                             style={{ float: "left", height: "18px", width: "57px" }}></div>
                        <div className={Classes.SKELETON}
                             style={{ float: "left", height: "18px", width: "250px", marginLeft: "10px" }}></div>
                        <div style={{ clear: "left" }}></div>
                      </div>
                    }


                  </ExplainContainer>
                </div>

                <div>
                  <div style={{ marginBottom: "10px" }} onClick={() => {
                    if (!viewMode)
                      this.onLabelEditMode = true;
                  }}>
                    <div>
                      <GreyLabel style={{ float: "left", marginBottom: "5px" }} onClick={(event) => {
                        if (!viewMode)
                          this.onLabelEditMode = true;
                        event.stopPropagation();
                      }}>라벨</GreyLabel>

                      {this.onLabelEditMode ?
                        <GrayLink style={{ float: "right" }} onClick={(event) => {
                          if (!viewMode)
                            this.onLabelEditMode = false;
                          event.stopPropagation();
                        }}>확인</GrayLink>
                        : <GrayLink style={{ float: "right" }} onClick={(event) => {
                          if (!viewMode)
                            this.onLabelEditMode = true;
                          event.stopPropagation();
                        }}>추가</GrayLink>}
                    </div>

                    <div style={{ clear: "left", margin: "0 10px" }}>
                      {this.loaded ?
                        this.onLabelEditMode ?
                          <div style={{ display: "inline-block", width: "170px" }}>
                            <TaskLabelForm
                              ItemMultiSelect={LabelListMultiSelect}

                              getItemId={(item: TaskLabelSimpleForm) => item.taskLabelId}
                              getItemValue={(item: TaskLabelSimpleForm) => item.title}
                              itemSelections={
                                this.currentForm.taskLabelSelections
                              }
                              itemList={this.currentForm.labelList}
                              createItem={(title: string) => TaskLabelSimpleFormModel.create({
                                taskLabelId: "",
                                title: title.trim()
                              })}
                              onCreateSelectLabel={async (itemToSelect: TaskLabelSimpleForm) => {
                                if (itemToSelect.title.trim().length > 0) {
                                  const result = await this.formStore.updateLabel(itemToSelect, "post");
                                  result && await this.formStore.currentForm.setTaskLabelSelections(result);
                                }
                              }}
                              onSelectLabel={(itemToSelect: TaskLabelSimpleForm) => {
                                this.formStore.updateLabel(itemToSelect, "put");
                              }}
                              onDeselectLabel={(itemsToDeselect: TaskLabelSimpleForm[]) => {
                                this.formStore.updateLabel(itemsToDeselect, "delete");
                              }}
                            />

                          </div> :
                          <TaskHeader>{
                            this.currentForm.labelList.map((label) => {
                              if (label.title && label.title.length < 10) {
                                return <TaskLabel key={label.taskLabelId}>{label.title}</TaskLabel>;
                              } else {
                                return (<TaskLabel key={label.taskLabelId} title={label.title}>
                                  {label.title.substring(0, 10) + "..."}
                                </TaskLabel>);
                              }
                            })
                          }</TaskHeader> :

                        <div>
                          <div className={Classes.SKELETON}
                               style={{ float: "right", height: "44px", width: "170px", marginTop: "10px" }}></div>
                        </div>
                      }

                    </div>

                  </div>
                  <div style={{ marginBottom: "10px" }} onClick={() => {
                    if (!viewMode)
                      this.onSprintEditMode = true;
                  }}>
                    <div>
                      <GreyLabel style={{ float: "left", marginBottom: "5px" }}>스프린트</GreyLabel>
                      {this.onSprintEditMode ?
                        <GrayLink style={{ float: "right" }} onClick={(event) => {
                          if (!viewMode)
                            this.onSprintEditMode = false;
                          event.stopPropagation();
                        }}>확인</GrayLink>
                        : <GrayLink style={{ float: "right" }} onClick={(event) => {
                          if (!viewMode)
                            this.onSprintEditMode = true;
                          event.stopPropagation();
                        }}>추가</GrayLink>
                      }
                    </div>
                    <div style={{ clear: "left", margin: "0 10px" }}>
                      {this.loaded ?
                        (this.onSprintEditMode ?
                          <TaskSprintForm
                            ItemMultiSelect={SprintListMultiSelect}

                            getItemId={(item: TaskSprintSimpleForm) => item.taskSprintId}
                            getItemValue={(item: TaskSprintSimpleForm) => item.title}
                            itemSelections={this.currentForm.taskSprintSelections}
                            itemList={this.currentForm.sprintList}
                            createItem={(title: string) => TaskSprintSimpleFormModel.create({
                              taskSprintId: "",
                              title: title.trim()
                            })}
                            onCreateSelectLabel={(itemToSelect: TaskSprintSimpleForm) => {
                              // this.currentForm.setSelectSprint(itemToSelect);
                              if (itemToSelect.title.trim().length > 0)
                                this.formStore.updateSprint(itemToSelect, "post");
                            }}
                            onSelectLabel={(itemToSelect: TaskSprintSimpleForm) => {
                              // this.currentForm.setSelectSprint(itemToSelect);
                              this.formStore.updateSprint(itemToSelect, "put");
                            }}
                            onDeselectLabel={(itemsToDeselect: TaskSprintSimpleForm[]) => {
                              // this.currentForm.deselectSprint(itemToDeselect);
                              this.formStore.updateSprint(itemsToDeselect, "delete");
                            }}
                          /> :
                          <TaskHeader>
                            {
                              this.currentForm.sprintList.map((sprint) => {
                                return <TaskSprint key={sprint.taskSprintId}>{sprint.title}</TaskSprint>;
                              })
                            }
                          </TaskHeader>) :

                        <div>
                          <div className={Classes.SKELETON}
                               style={{ float: "right", height: "44px", width: "170px", marginTop: "10px" }}></div>
                        </div>
                      }
                    </div>
                  </div>
                  <div style={{ marginBottom: "10px", marginTop: "10px" }}>
                    <GreyLabel style={{ marginBottom: "5px" }}>마감일</GreyLabel>
                    <span style={{ display: "inline-block", margin: "0 10px" }}>
                    {this.loaded ?
                      <DateInput
                        {...getMomentFormatter("YYYY-MM-DD")}
                        locale="ko"
                        closeOnSelection={true}
                        value={this.currentForm.dueDate ? moment(this.currentForm.dueDate).toDate() : null}
                        maxDate={new Date("2050-01-01")}
                        onChange={(selectedDate: Date) => {
                          console.log("selectedDate",selectedDate);
                          if (selectedDate)
                            this.currentForm.setDueDate(moment(selectedDate).format("YYYY-MM-DD hh:mm:ss"));
                          else
                            this.currentForm.setDueDate(null);
                          this.formStore.updateForm();
                        }}
                        showActionsBar={true}
                        disabled={viewMode}
                        popoverProps={{ position: Position.BOTTOM }}
                      /> :
                      <div>
                        <div className={Classes.SKELETON}
                             style={{ float: "right", height: "44px", width: "170px", marginTop: "10px" }}></div>
                      </div>
                    }

                </span>
                  </div>
                  <div style={{ float: "right" }}>
                    <Button minimal={true} onClick={() => {
                      let url = window.location.href;
                      url = url.indexOf("?") > 0 ? url.substring(0, url.indexOf("?")) : url;
                      const link = `${url}?tk=${this.currentForm.id}`;
                      let tempElem = document.createElement("textarea");
                      tempElem.value = link;
                      document.body.appendChild(tempElem);

                      tempElem.select();
                      document.execCommand("copy");
                      document.body.removeChild(tempElem);

                      AppToaster.show({
                        message: "링크가 복사되었습니다!",
                        intent: Intent.PRIMARY
                      });
                    }}>링크 복사</Button>
                  </div>
                  <div style={{ clear: "right" }}></div>
                  <div style={{ float: "right" }}>
                    {!viewMode ?
                      <Button minimal={true} onClick={() => {
                        this.showTaskDeleteAlert = true;
                      }}>Task 삭제</Button> :
                      <Button minimal={true} onClick={() => {
                        this.showTaskDeleteAlert = true;
                      }}>Task Board로 보내기</Button>}
                  </div>
                </div>

              </Container>
              <Alert
                icon="trash"
                intent={Intent.DANGER}
                isOpen={this.showTaskDeleteAlert}
                onCancel={(e: any) => {
                  this.showTaskDeleteAlert = false;
                  e.stopPropagation();
                }}
                onConfirm={async (e: any) => {
                  try {
                    this.showTaskDeleteAlert = false;
                    await this.taskManagerStore.archiveTask(String(this.currentForm.id), !this.currentForm.isArchived);
                    this.props.onClose();
                  } catch (e) {
                    const error = JSON.stringify(e.response.data);
                    AppToaster.show({
                      message: "오류: " + error,
                      intent: Intent.DANGER
                    });
                  }                  
                }}
                confirmButtonText={"예"}
                cancelButtonText={"아니오"}
              >
                {!viewMode ? <p>선택한 Task를 삭제하시겠습니까?</p> : <p>선택한 Task를 복원하시겠습니까?</p>}
              </Alert>
            </div>
          </Dialog>
        </div> : <Dialog
          icon="annotation"
          onClose={onClose}
          title={"Task 상세"}
          isOpen={isOpen}
          usePortal={true}
          autoFocus={true}
          canEscapeKeyClose={true}
          enforceFocus={true}
          onOpening={this.handleOnOpening}
          style={{ width: "800px" }}
        >Not selected</Dialog>
    );
  }

}

export default TaskDetailPopup;