import { types, flow } from "mobx-state-tree";
import axios from "axios";
import sortBy from "lodash/sortBy";
import { LoginResponse } from "../types/auth";
import jwtDecode from "jwt-decode";
import { AuthToken } from "../types/token";
import { ProjectGroupResponse } from "../types/projectGroup";
import { ProjectGroup, ProjectGroupModel } from "./models/ProjectGroup";
import { mapProjectGroup } from "./ProjectGroupStore";

const AUTH_TOKEN_KEY = "authtoken";


export const UserStoreModel = types
  .model("UserStore", {
    isLoggedIn: types.optional(types.boolean, false),
    userId: types.optional(types.number, -1),
    username: types.optional(types.string, ""), // use this as name...
    email: types.optional(types.string, ""),
    isPM: types.optional(types.boolean, false),
    isRufree: types.optional(types.boolean, false),
    isClient: types.optional(types.boolean, false),
    clientId: types.optional(types.string, ""),
    isIntern: types.optional(types.boolean, false),
    projectGroups: types.array(ProjectGroupModel),
  })
  .views(self => ({
    get authToken() {
      return window.localStorage.getItem(AUTH_TOKEN_KEY);
    },
    get myProjectGroups() {
      return sortBy(
        self.projectGroups.slice(),
        pg => pg.groupId
      ).reverse();
    }
  }))
  .actions(self => ({
    setUserInfo({
      userId,
      username,
      email,
      isPM,
      isClient,
      isRufree,
      clientId,
      isIntern
    }: {
      userId: number;
      username: string;
      email: string;
      isPM: boolean;
      isRufree: boolean;
      isClient: boolean;
      clientId: string;
      isIntern: boolean;
    }) {
      self.userId = userId;
      self.username = username;
      self.email = email;
      self.isPM = isPM;
      self.isClient = isClient;
      self.isRufree = isRufree;
      self.clientId = clientId;
      self.isIntern = isIntern;
    }
  }))
  .actions(self => {
    const checkLoginStatus = () => {
      self.isLoggedIn = !!self.authToken;

      if (self.authToken) {
        const decoded = jwtDecode(self.authToken) as AuthToken;

        self.setUserInfo({
          userId: decoded.user_id,
          username: decoded.name,
          email: decoded.username,
          isPM: decoded.is_pm,
          isRufree: decoded.is_rufree,
          isClient: decoded.is_client,
          clientId: decoded.client_id,
          isIntern: decoded.is_intern
        });
      }
    };

    const PMSTokenLogin = flow(function*(token: string) {
      window.localStorage.removeItem(AUTH_TOKEN_KEY);
      axios.defaults.headers.common["Authorization"] = null;
      try {
        const { data }: { data: LoginResponse } = yield axios.get(
          "/auth/pmsToken/getToken?token=" + token
        );

        window.localStorage.setItem(AUTH_TOKEN_KEY, data.token);
        axios.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;

        checkLoginStatus();
      } catch (e) {
        console.log("PMSTokenLogin error", e);
        throw e;
      }
    });

    const login = flow(function*(email: string, password: string) {
      try {
        const { data }: { data: LoginResponse } = yield axios.post(
          "/auth/login",
          {
            email,
            password
          }
        );

        window.localStorage.setItem(AUTH_TOKEN_KEY, data.token);
        axios.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;

        checkLoginStatus();
      } catch (e) {
        console.log("login error", e);
        throw e;
      }
    });

    const logout = () => {
      window.localStorage.removeItem(AUTH_TOKEN_KEY);
      axios.defaults.headers.common["Authorization"] = null;

      self.userId = -1;
      self.username = "";
      self.isPM = false;

      checkLoginStatus();
    };

    const changePassword = flow(function*(current_password: string, new_password: string) {
      try {
        yield axios.post(
          "/auth/users/set_password",
          {
            'current_password': current_password,
            'new_password': new_password
          }
        );
      } catch (e) {
        console.log("changePassword error", e);
        throw e;
      }
    });    

    const fetchMyProjectGroups = flow(function*() {
      try {
        const { data }: { data: ProjectGroupResponse[] } = yield axios.get(
          "/projectGroups/reportTargets"
        );

        let projectGroups: ProjectGroup[] = [];

        projectGroups = data.map(pg => (
            ProjectGroupModel.create({
              ...mapProjectGroup(pg)
            })
        ))

        self.projectGroups.replace(projectGroups.filter(projectGroup => !projectGroup.dateComplete));
      } catch (e) {
        throw e;
      }

      return [];
    });

    return {
      checkLoginStatus,
      login,
      logout,
      changePassword,
      PMSTokenLogin,
      fetchMyProjectGroups
    };
  });

type UserStoreModelType = typeof UserStoreModel.Type;
export interface UserStore extends UserStoreModelType {}
