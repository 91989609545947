import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { Icon } from "@blueprintjs/core";
import { AppStore } from "../../../store/AppStore";
import { RufreeScheduleForm } from "../../../store/forms/ScheduleForm/RufreeScheduleForm";
import { Row, Column } from "../../atoms/ScheduleForm/ScheduleForm";
import { Rufree } from "../../../store/models/Rufree";
import { computed, observable } from "mobx";
import RufreeSelection from "../RufreeSelection/RufreeSelection";
import { Inspection } from "../../../store/models/Inspection";
import RufreeRoleModelSelection from "../RufreeRoleModelSelection/RufreeRoleModelSelection";
import { RufreeRole } from "../../../store/models/RufreeRole";
import ScheduleSprint from "../ScheduleSprint/ScheduleSprint";
import ScheduleSprintDialog from "../ScheduleSprintDialog/ScheduleSprintDialog";
import { ScheduleSprintForm } from "../../../store/forms/ScheduleForm/ScheduleSprintForm";
import { ProjectGroup } from "../../../store/models/ProjectGroup";
import { ProjectGroupManagerModel } from "../../../store/models/ProjectGroupManager";
import { RufreeRoleForm } from "../../../store/forms/RufreeRoleForm";
import InputWithFormat, {
  KoreanCurrencyOptions
} from "../../atoms/InputWithFormat/InputWithFormat";

const StyledRow = styled(Row)`
  height: 60px;
  white-space: nowrap;

  & * {
    white-space: normal;
  }

  span.bp3-icon {
    color: #5C7080;
    cursor: pointer;
  }
  div.role { width: 200px; text-align: left; }
`;

interface RufreeScheduleFormViewProps {
  rufreeScheduleForm: RufreeScheduleForm;
  selectedRoles: string[];
  inspectionRufreeScheduleForm: RufreeRoleForm[] | null;
  inspection: Inspection;
  projectGroup: ProjectGroup;
  onChange: () => {};
}

interface InjectedProps extends RufreeScheduleFormViewProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class RufreeScheduleFormView extends Component<RufreeScheduleFormViewProps> {
  @observable priviousEndDate: string = '';
  @observable selectedSprint: ScheduleSprintForm | null = null;
  @observable selectedSprintIndex = -1;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get managers() {
    const { managers } = this.props.projectGroup;
    const clientManager = ProjectGroupManagerModel.create({
      name: '클라이언트',
      phone: '',
      email: ''
    });

    return managers.concat(clientManager)
  }

  @computed
  get selectedRufree() {
    const { rufreeId } = this.props.rufreeScheduleForm;
    return this.injected.appStore.rufreeStore.rufrees.find(
      x => x.rufreeId === rufreeId
    );
  }

  @computed
  get rufreeRolesInInspection(): RufreeRole[] {
    const { rufreeScheduleForm, selectedRoles, inspection } = this.props;
    const { role } = rufreeScheduleForm;

    if (!inspection) {
      return [];
    }


    return inspection.rufreeRoles.filter(x =>
      !selectedRoles.includes(x.role) || x.role === rufreeScheduleForm.role
    );
  }

  @computed
  get selectedRufreeRole() {
    return this.rufreeRolesInInspection.find(
      x => x.role === this.props.rufreeScheduleForm.role
    );
  }

  @computed
  get selectedInspectionSprintForm() {
    const { inspectionRufreeScheduleForm } = this.props;
    return inspectionRufreeScheduleForm && inspectionRufreeScheduleForm.find(
      (x: any) => x.role === this.props.rufreeScheduleForm.role
    );
  }

  @computed
  get sprintTitle() {
    const {
      selectedSprint,
      selectedSprintIndex,
      selectedRufree
    } = this;

    if (!selectedSprint || this.props.inspection || !selectedRufree) {
      return "";
    }

    return `${selectedRufree.rufreeId} ${selectedRufree.name} ${
      this.props.rufreeScheduleForm.role
    } ${selectedSprintIndex === 0 ? "PRE" : "S" + selectedSprintIndex}`;
  }

  @computed
  get completeSprints() {
    return this.props.projectGroup.sprints
      .filter(x => x.role === this.props.rufreeScheduleForm.role)
  }
  

  handleRufreeChange = (rufree: Rufree) => {
    this.props.rufreeScheduleForm.setRufreeId(rufree.rufreeId);
  };

  handleRoleChange = (role: RufreeRole) => {
    if (this.props.inspection) {
      this.props.rufreeScheduleForm.setInspectionId(this.props.inspection.inspectionId);
    }
    this.props.rufreeScheduleForm.setRole(role.role);
  };

  handleSprintClick = (sprint: ScheduleSprintForm, index: number) => {
    this.selectedSprint = sprint;
    this.selectedSprintIndex = index;

    if(index > 0) {
      this.priviousEndDate = this.props.rufreeScheduleForm.sprints[index-1].dateEnd;
    }
  };

  handleSprintDialogClose = () => {
    this.selectedSprint = null;
    this.priviousEndDate = '';
  };

  render() {
    const { rufreeScheduleForm: form, inspection } = this.props;

    return (
      <StyledRow>
        <Column className='role'>
          {/* { this.selectedRufreeRole && this.selectedRufreeRole.role } */}
          <RufreeRoleModelSelection
            onChange={this.handleRoleChange}
            currentValue={this.selectedRufreeRole}
            items={this.rufreeRolesInInspection}
          />
        </Column>

        <Column
          style={{ minWidth: "200px", textAlign: "left", verticalAlign: "top" }}
        >
          <RufreeSelection
            onChange={this.handleRufreeChange}
            currentValue={this.selectedRufree}
            blockFetchingData={true}
          />

          <Icon
            iconSize={12}
            icon={'eraser'}
            style={{ marginLeft: '5px' }}
            onClick={() => this.props.rufreeScheduleForm.setRufreeId('')} />
        </Column>
        {
          // <Column
          //   style={{ width: "220px", textAlign: "left", verticalAlign: "top", paddingTop: "5px" }}
          // >
  
          //   {
          //     this.props.inspection && (
          //       <Link to={`/inspections/${this.props.inspection.inspectionId}/read`} target="_blank">
          //         {this.props.inspection.nameWithId}
          //       </Link>
          //     )
          //   }
          // </Column>
        }

        {
          // <Column style={{ width: "80px" }}>
          //   {this.selectedRufreeRole && this.selectedRufreeRole.totalSprintDays}
          // </Column>
        }
        <Column style={{ width: "110px" }}>
          {
            // form.totalSprintDays
          }
          {
            this.selectedRufreeRole && 
              <InputWithFormat
                disabled={ true }
                value={this.selectedRufreeRole.wage}
                options={KoreanCurrencyOptions}
                onChange={(e: any) => {
                  const withoutPrefix = e.target.rawValue.substr(2);
                  if(this.selectedInspectionSprintForm) {
                    this.selectedInspectionSprintForm.setWage(parseInt(withoutPrefix, 10) || 0);
                  }
                  this.props.onChange();
                  
                  // this.selectedRufreeRole.setWage(parseInt(withoutPrefix, 10) || 0);
                }}
              />
          }
          
        </Column>
        {form.sprints.map((sprint, index) => {
          const completeSprint = this.completeSprints.filter(x => x.sprint === index)[0];
          return <ScheduleSprint
            key={sprint.id}
            completeSprint={completeSprint}
            sprint={sprint}
            index={index}
            inspectionSprint={this.selectedInspectionSprintForm ? this.selectedInspectionSprintForm.sprints[index] : undefined}
            onClick={this.handleSprintClick}
            onInspectionSprintCheck={this.props.onChange}
          />
        })}
        <ScheduleSprintDialog
          priviousEndDate={this.priviousEndDate}
          selectedSprint={this.selectedSprint}
          onClose={this.handleSprintDialogClose}
          title={this.sprintTitle}
          updateSprintNeighborDates={form.updateSprintNeighborDates}
          managers={this.managers}
        />
      </StyledRow>
    );
  }
}

export default RufreeScheduleFormView;
