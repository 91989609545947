import React, { Component } from "react";
import styled from "styled-components/macro";

const Wrapper = styled.div`
  max-width: 400px;
`;

interface FormContainerProps {}

class FormContainer extends Component<FormContainerProps> {
  render() {
    return <Wrapper>{this.props.children}</Wrapper>;
  }
}

export default FormContainer;
