import React, { ChangeEvent, Component } from "react";
import styled from "styled-components/macro";
import { Alert, Button, Checkbox, Classes, Dialog, InputGroup, Intent } from "@blueprintjs/core";
import Task from "../../molecules/TaskManager/Task";
import { computed, observable } from "mobx";
import { AppStore } from "../../../store/AppStore";
import { inject, observer } from "mobx-react";
import { TaskLabel, TaskSprint, Task as TaskType } from "../../../store/models/TaskManager";
import TaskDetailPopup from "./TaskDetailPopup";

const Header = styled.div`
  display: flex;
`;
const LeftItem = styled.div`
  flex: none;
`;
const RightItem = styled.div`
  margin-left: auto;
  display: flex;
`;
const GreyLabel = styled.div`
  font-weight: bold;
  color: #7f8390;
  margin-bottom: 15px;
`;
const Row = styled.ul`
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    padding-inline-start: 0px;
`;
const Column = styled.li`
    display: flex;
    flex-direction: column;
    flex: none;
    flex-basis: 25%;
    margin-top: 5px;
    padding: 0 5px;
    box-sizing: border-box;
`;

const Scrollable = styled.div`
  max-height: 600px;
  overflow: scroll;
`
interface ArchivedTaskPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

interface InjectedProps extends ArchivedTaskPopupProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ArchivedTaskPopup extends Component<ArchivedTaskPopupProps> {
  @observable selectedTasks: number[] = [];
  @observable selectedTaskId = "";
  @observable isOpenTaskDetailPopup = false;
  @observable showDeleteAlert = false;

  @observable searchText = "";

  handleSearchText = (val: string) => {
    this.searchText = val;
  };

  handleTaskDetailPopupClose = async () => {
    this.selectedTaskId = "";
    this.isOpenTaskDetailPopup = false;
    if (this.taskMultiSelectStore.currentBoard)
      await this.taskMultiSelectStore.fetchTaskBoard(this.taskMultiSelectStore.currentBoard.projectGroup);
  };

  handleOnOpening = () => {

  };

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get taskMultiSelectStore() {
    return this.injected.appStore.taskManagerStore;
  }

  @computed
  get isAllSelected() {
    return this.archivedTasks.length === this.selectedTasks.length;
  }

  @computed
  get indeterminate() {
    return this.isAllSelected === false && this.selectedTasks.length !== 0;
  }

  onCompleteRemoveSelectedTask = async () => {
    this.showDeleteAlert = false;
    await this.taskMultiSelectStore.deleteTasks(this.selectedTasks);
    this.selectedTasks.splice(0, this.selectedTasks.length);
    if (this.taskMultiSelectStore.currentBoard)
      await this.taskMultiSelectStore.fetchTaskBoard(this.taskMultiSelectStore.currentBoard.projectGroup);
  };

  onRestoreSelectedTask = async () => {
    const list = this.selectedTasks.map(value => {
      return { task_id: value, is_archived: false };
    });
    await this.taskMultiSelectStore.archiveTasks(list);
    this.selectedTasks.splice(0, this.selectedTasks.length);
  };

  onCheckBoxClick = () => {
    if (this.selectedTasks.length > 0) { //if at least one selected deselect All
      this.selectedTasks.forEach((taskId: number) => {
        this.taskMultiSelectStore.addMultiSelectTaskId(taskId);
      });
      this.selectedTasks.splice(0, this.selectedTasks.length);
    } else {// else select All
      this.archivedTasks.forEach(task => {
        this.taskMultiSelectStore.addMultiSelectTaskId(task.id);
        this.selectedTasks.push(task.id);
      });
    }
  };
  onTaskClick = (e: any, taskId: number) => {
    if (e.shiftKey) {
      this.toggleTaskSelect(taskId);
    } else {
      this.handleTaskDetailPopupOpen(taskId);
    }
  };
  handleTaskDetailPopupOpen = (taskId: number) => {
    this.isOpenTaskDetailPopup = true;
    this.selectedTaskId = String(taskId);
  };

  private toggleTaskSelect = (taskId: number) => {
    const index = this.selectedTasks.indexOf(taskId);
    if (index > -1) {//Deselect
      this.taskMultiSelectStore.addMultiSelectTaskId(taskId);
      this.selectedTasks.splice(index, 1);
    } else {//Select
      this.taskMultiSelectStore.addMultiSelectTaskId(taskId);
      this.selectedTasks.push(taskId);
    }
  };

  @computed
  get label() {
    return this.selectedTasks.length === 0 ?
      `보관된 Task ${this.archivedTasks.length}` :
      `선택된 Task ${this.selectedTasks.length}`;
  }

  @computed
  get archivedTasks() {
    return this.store.currentBoard ?
      this.store.currentBoard.taskLists
        .flatMap(taskList => taskList.tasks)
        .filter(task => task.is_archived) :
      [];
  }


  @computed
  get store() {
    return this.injected.appStore.taskManagerStore;
  }


  render() {
    const { isOpen, onClose } = this.props;

    // const map: { [key: string]: JSX.Element[] } = {};
    const map: { [key: string]: number } = {};
    const dateIndexList: string[] = [];
    let mapIndex = 0;


    const rowList: JSX.Element[][] = [];
    // @ts-ignore
    if (this.store.currentBoard) {
      // TaskList
      this.archivedTasks
        .filter(task => task.title.includes(this.searchText))
        .sort((a, b) => { //순서 최신 아카이브순
          const dateB = new Date(b.date_archived ? b.date_archived : 0);
          const dateA = new Date(a.date_archived ? a.date_archived : 0);
          return (dateB.valueOf() - dateA.valueOf());
        })
        .forEach((task) => {
          // @ts-ignore
          const date: string = task.date_archived.substr(0, 10);
          if (map[date] === undefined) {
            map[date] = mapIndex++;
            const index = map[date];
            dateIndexList.push(date);
            rowList[index] = [];
          }

          const index = map[date];

          let srint_list: TaskSprint[] = task.sprint_list ? task.sprint_list : [];
          let label_list: TaskLabel[] = task.label_list ? task.label_list : [];

          // list => Row
          // task => each Item in Col(==
          const colList: JSX.Element[][] = [];
          colList.push();

          let countSubTasksDone = 0;
          let countSubTaskAll = 0;

          // @ts-ignore
          const taskItem: TaskType = task;

          if (taskItem.sub_task_lists) {
            for (let i = 0; i < taskItem.sub_task_lists.length; i++) {
              const sub_tasks = taskItem.sub_task_lists[i].sub_tasks;
              countSubTaskAll += sub_tasks.length;
              for (let j = 0; j < sub_tasks.length; j++) {
                countSubTasksDone += sub_tasks[j].checked ? 1 : 0;
              }
            }
          }

          rowList[index].push(
            <Column key={task.task_id}>
              {/*{this.selectedTasks.includes(task.task_id) && <div>Selected</div>}*/}
              <Task
                title={task.title} 
                person_in_charge_list={task.person_in_charge_list ? task.person_in_charge_list : []}
                metadata={{
                  taskId: task.id,
                  taskListId: task.task_list,
                  taskBoardId: task.task_board,
                  taskNo: task.task_no,
                  dueDate: task.date_archived,
                  sprints: srint_list.map(item => item.title),
                  labels: label_list.map(item => item.title),
                  countSubTasksDone: countSubTasksDone,
                  countSubTasksAll: countSubTaskAll,
                  countAttachments: taskItem.task_links.length + taskItem.task_files.length,
                  countComments: taskItem.task_comments.length,
                  archived: true
                }}
                onClick={(e: any) => {
                  this.onTaskClick(e, task.id);
                }}/>
            </Column>
          );
        });


    }


    return (
      <>
        {
          this.isOpenTaskDetailPopup === false &&
          <Dialog
            icon="trash"
            onClose={() => {
              //Deselect All
              this.selectedTasks.forEach((taskId: number) => {
                this.taskMultiSelectStore.addMultiSelectTaskId(taskId);
              });
              this.selectedTasks.splice(0, this.selectedTasks.length);
              onClose();
            }}
            title={"보관된 Task"}
            isOpen={isOpen}
            usePortal={true}
            autoFocus={true}
            canEscapeKeyClose={true}
            enforceFocus={true}
            onOpening={this.handleOnOpening}
            style={{ width: "65%",minHeight:"600px", }}
          >
            <div className={Classes.DIALOG_BODY}>
              <Header>
                <LeftItem>
                  <Checkbox inline={true} checked={this.isAllSelected}
                            indeterminate={this.indeterminate} label={this.label}
                            onClick={(event) =>
                              this.onCheckBoxClick()
                            }/>
                  {this.selectedTasks.length > 0 &&
                  <div style={{ display: "inline-block" }}>
                    <Button style={{ marginRight: "5px" }} text={"Task Board로 이동"}
                            onClick={this.onRestoreSelectedTask}/>
                    <Button text={"완전 삭제"} onClick={() => {
                      this.showDeleteAlert = true;
                    }}/>
                  </div>
                  }
                  <Alert
                    icon="trash"
                    intent={Intent.DANGER}
                    isOpen={this.showDeleteAlert}
                    onCancel={(e: any) => {
                      this.showDeleteAlert = false;
                      e.stopPropagation();
                    }}
                    onConfirm={async (e: any) => {
                      this.onCompleteRemoveSelectedTask();
                      e.stopPropagation();
                    }}
                    confirmButtonText={"예"}
                    cancelButtonText={"아니오"}>
                    <p>선택한 Task를 완전 삭제하시겠습니까?</p>
                  </Alert>
                </LeftItem>
                <RightItem>
                  <InputGroup
                    placeholder="검색"
                    value={this.searchText}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => this.handleSearchText(e.target.value)}
                  />
                  {this.searchText && <Button icon={"cross"} minimal={true} onClick={() => {
                    this.searchText = "";
                  }}/>}
                </RightItem>
              </Header>

              <Scrollable>
              {rowList.map((item, index) => {
                return (
                  // dateIndexList.map((i)=><div>{i}</div>)
                  <div key={dateIndexList[index]}>
                    <GreyLabel>{dateIndexList[index]}</GreyLabel>
                    <Row>
                      {item}
                    </Row>
                  </div>
                );
              })}
              </Scrollable>
            </div>
          </Dialog>
        }

        <TaskDetailPopup
          idTask={this.selectedTaskId}
          isOpen={this.isOpenTaskDetailPopup}
          onClose={this.handleTaskDetailPopupClose}
          // task={this.selectedTask}
          viewMode={true}
        />
      </>
    );
  }
}

export default ArchivedTaskPopup;