import { types, flow } from "mobx-state-tree";
import axios from "axios";
import { BugTaskResponse } from "../../../types/tasktask";


export const BugFormModel = types
  .model("BugForm", {
    id: types.optional(types.number, -1),
    title: types.optional(types.string, ""),
    comment: types.optional(types.string, ""),
  })
  .actions(self => ({
    setId(value: number) {
      self.id = value;
    },
    setTitle(value: string) {
      self.title = value;
    },
    setComment(value: string) {
      self.comment = value;
    }
  }))
  .actions(self => {
    const postOrPut = flow(function*(taskId: number) {
      if(self.id > 0) {
        try {
          const { data }: { data: BugTaskResponse } = yield axios.put(
            `/tasktasks/${taskId}/bug/${self.id}`,
            {
              title: self.title,
              comment: self.comment,
              tasktask: taskId
            }
          );
          self.setId(data.id);
        } catch (e) {
          throw e;
        }
      } else {
        try {
          const { data }: { data: BugTaskResponse } = yield axios.post(
            `/tasktasks/${taskId}/bug`,
            {
              title: self.title,
              comment: self.comment,
              tasktask: taskId
            }
          );
          self.setId(data.id);
        } catch (e) {
          throw e;
        }
      }
    })
    return {
      postOrPut
    };
  });
type BugFormType = typeof BugFormModel.Type;
export interface BugForm extends BugFormType {}


export const BugTaskFormModel = types
  .model("BugTaskForm", {
    id: types.optional(types.number, -1),
    projectGroup: types.optional(types.string, ""),
    requester: types.optional(types.number, -1),
    tasks: types.array(BugFormModel)
  })
  .actions(self => ({
    setId(value: number) {
      self.id = value;
    },

    setProjectGroup(value: string) {
      self.projectGroup = value;
    },

    addBug() {
      self.tasks.push(BugFormModel.create());
    },

    removeBug(bug: BugForm) {
      self.tasks.remove(bug);
    }
  }));

type BugTaskFormType = typeof BugTaskFormModel.Type;
export interface BugTaskForm extends BugTaskFormType {}
