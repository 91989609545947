import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { Checkbox, InputGroup } from "@blueprintjs/core";
import { ChangeEvent } from "cleave.js/react/props";
import { ConsultAdminTypeForm } from "../../../store/forms/ProjectForm/ConsultAdminTypeForm";
import { mapAdminTypeKeyToText } from "../../../store/models/ConsultAdminType";
import {
  ItemRow,
  RemoveButtonContainer,
  RemoveButton
} from "../../atoms/RemoveItemButton/RemoveItemButton";

const Wrapper = styled.div`
  margin-bottom: 4px;
`;

const CheckItem = styled.div`
  display: flex;
  margin-bottom: 4px;

  & > * + * {
    margin-left: 5px;
  }
`;

interface ConsultAdminTypeFormViewProps {
  adminType: ConsultAdminTypeForm;
  isReadMode: boolean;
  onRemove: (item: ConsultAdminTypeForm) => void;
}

interface InjectedProps extends ConsultAdminTypeFormViewProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ConsultAdminTypeFormView extends Component<
  ConsultAdminTypeFormViewProps
> {
  get injected() {
    return this.props as InjectedProps;
  }

  handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.props.adminType.setText(e.target.value);
  };

  toggleCheck = () => {
    if (this.props.isReadMode) {
      return;
    }

    this.props.adminType.setChecked(!this.props.adminType.checked);
  };

  render() {
    const { adminType, isReadMode, onRemove } = this.props;

    if (!isReadMode && adminType.etc) {
      return (
        <ItemRow>
          <RemoveButtonContainer>
            <RemoveButton onClick={() => onRemove(adminType)} />
          </RemoveButtonContainer>
          <Wrapper>
            <CheckItem>
              <Checkbox
                checked={adminType.checked}
                onChange={this.toggleCheck}
              />
              <InputGroup
                value={adminType.text}
                onChange={this.handleTextChange}
                style={{ width: "369px" }}
              />
            </CheckItem>
          </Wrapper>
        </ItemRow>
      );
    }

    return (
      <ItemRow>
        <Wrapper>
          <Checkbox checked={adminType.checked} onChange={this.toggleCheck}>
            {mapAdminTypeKeyToText(adminType.text)}
          </Checkbox>
        </Wrapper>
      </ItemRow>
    );
  }
}

export default ConsultAdminTypeFormView;
