import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";

import { Wrapper, ItemCard } from "../../atoms/ParttimeComponents/ParttimeComponents";
import StatusTag from "../../molecules/ParttimeRow/StatusTag";

import { Task, TaskSummary, TaskType, TaskStatus } from "../../../store/models/Parttime";

interface TaskPanelProps extends RouteComponentProps {
  task: Task;
}

interface InjectedProps extends TaskPanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class TaskPanel extends Component<TaskPanelProps> {

  get injected() {
    return this.props as InjectedProps;
  }

  handleTaskClick = (task: TaskSummary) => {
    const url = `/parttime/requests/${ task.request }/tasks/${ task.id }`;
    window.open(url, '_blank');
  };

  render() {
    const { task } = this.props;

    return (
        <Wrapper>
            { task.request.tasks && (task.request.tasks
            .filter(obj => obj.id !== task.id)
            .map(obj => (
                <ItemCard 
                    key={obj.id}
                    onClick={() => this.handleTaskClick(obj)} 
                >
                    <StatusTag status={TaskStatus.get(obj.status)}></StatusTag>  
                    <h4 style={{ display: "inline", marginLeft: "10px" }}>{ obj.code }</h4>
                    <div>
                        <h4 style={{ margin: "7px 0px"}}>{ obj.title }</h4>
                        <p style={{ marginBottom: "0px"}}>{ TaskType.get(obj.type) } | { (obj.rufree && obj.rufree.name) ? obj.rufree.name : '-' }</p>
                    </div>
                </ItemCard>
            )))}
        </Wrapper>
    );
  }
}

export default TaskPanel;
