import React, { useState, useEffect } from 'react';
import { inject, observer } from "mobx-react";

import { AppStore } from '../../../../store/AppStore';
import { PartnerStore } from '../../../../store/PartnerStore';
import { IPartner, STATUS } from '../../../../store/models/Partner';
import Paginator from "../../../molecules/Paginator/Paginator";

import {
  ApprovePopup,
  FilterBar,
  RejectPopup,
  RevokePopup,
  PartnerList,
} from '.';


const SecondteamPartner = inject("appStore")(observer((props) => {
  const store: PartnerStore = (props as { appStore: AppStore }).appStore.partnerStore;
  const {
    isFetching,
    pageGroup, currentPage, totalPages, setCurentPage, setPageGroup
  } = store;
  const [ status, setStatus ] = useState(STATUS.ALL);
  const [ search, setSearch ] = useState('');
  const [ selectedPartner, setSelectedPartner ] = useState<IPartner | null>(null);
  const [ showApprovePopup, setShowApprovePopup ] = useState(false);
  const [ showRevokePopup, setShowRevokePopup ] = useState(false);
  const [ showRejectPopup, setShowRejectPopup ] = useState(false);

  const clearPopup = () => {
    setSelectedPartner(null);
    setShowApprovePopup(false);
    setShowRevokePopup(false);
    setShowRejectPopup(false);
  }
  const clearFilter = async() => {
    setSearch('');
    setStatus('');
    await store.fetchPartners();
  }
  const handleClickApprove = (partner: IPartner) => {
    clearPopup();
    setSelectedPartner(partner);
    setShowApprovePopup(true);
  }
  const handleClickRevoke = (partner: IPartner) => {
    clearPopup();
    setSelectedPartner(partner);
    setShowRevokePopup(true);
  }
  const handleClickReject = (partner: IPartner) => {
    clearPopup();
    setSelectedPartner(partner);
    setShowRejectPopup(true);
  }
  const handleApprove = async() => {
    if(selectedPartner === null) {
      return
    }
    await selectedPartner.approve();
    clearPopup();
  }
  const handleRevoke = async() => {
    if(selectedPartner === null) {
      return
    }
    await selectedPartner.revoke();
    clearPopup();
  }
  const handleReject = async() => {
    if(selectedPartner === null) {
      return
    }
    await selectedPartner.reject();
    clearPopup();
  }
  const handleRejectComment = (comment: string) => {
    if(selectedPartner === null) {
      return
    }
    selectedPartner.setRejectComment(comment);
  }

  useEffect(() => {
    const fetchPartners = async () => {
      await store.fetchPartners();
    }

    fetchPartners();
  }, []);

  return (
    <div style={{ maxWidth: '1200px' }}>
      <h3>회사</h3>
      <FilterBar
        status={ status }
        search={ search }
        onClear={ clearFilter }
        onChangeSearch={ (search: string) => setSearch(search) }
        onChangeStatus={ async(status: string) => {
          setStatus(status);
          await store.fetchPartners(search, status);
        } }
        onSearch={ () => store.fetchPartners(search, status) } />

      <div>
        <PartnerList
          isFetching={ isFetching }
          partners={ store.sortedPartners }
          onApprove={ handleClickApprove }
          onRevoke={ handleClickRevoke }
          onReject={ handleClickReject } />
        <Paginator
          pageGroup={ pageGroup }
          totalPages={totalPages}
          currentPage={currentPage}
          setPageGroup={setPageGroup}
          setCurentPage={setCurentPage}
          onFetch={() => store.fetchPartners(search, status) } />
      </div>

      <ApprovePopup
        isOpen={ selectedPartner !== null && showApprovePopup }
        onConfirm={ handleApprove }
        onCancel={ clearPopup } />
      <RevokePopup
        isOpen={ selectedPartner !== null && showRevokePopup }
        onConfirm={ handleRevoke }
        onCancel={ clearPopup } />
      <RejectPopup
        isOpen={ selectedPartner !== null && showRejectPopup }
        onChangeComment={ handleRejectComment }
        onConfirm={ handleReject }
        onCancel={ clearPopup } />
    </div>
  )
}));
export default SecondteamPartner;
