import React, { Component, ChangeEvent } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import {
  Dialog,
  Classes,
  Button,
  Intent,
  FormGroup,
  Icon,
  Colors
} from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import moment from "moment";
import { getMomentFormatter } from "../../../utils/date";
import { AppStore } from "../../../store/AppStore";
import { ProjectGroup } from "../../../store/models/ProjectGroup";
import { AppToaster } from "../AppToaster/AppToaster";

const FormValue = styled.div`
  line-height: 30px;
`;

interface ProjectGroupCompletePopupProps {
  onClose: () => void;
  isOpen: boolean;
  projectGroup: ProjectGroup;
}

interface InjectedProps extends ProjectGroupCompletePopupProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ProjectGroupCompletePopup extends Component<
  ProjectGroupCompletePopupProps
> {
  @observable dateComplete = moment().format("YYYY-MM-DD");
  @observable isPending = false;

  get injected() {
    return this.props as InjectedProps;
  }

  handleOpening = () => {
    const { dateComplete } = this.props.projectGroup;
    this.dateComplete = dateComplete ? dateComplete : moment().format("YYYY-MM-DD");
  };

  handleSave = async () => {
    const { projectGroup } = this.props;

    try {
      this.isPending = true;

      await this.props.projectGroup.patchDateComplete(this.dateComplete);

      AppToaster.show({
        message: `${projectGroup.name} 프로젝트가 종료 되었습니다.`,
        intent: Intent.SUCCESS
      });

      this.props.onClose();
    } catch (e) {
      const error = JSON.stringify(e.response.data);

      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    } finally {
      this.isPending = false;
    }
  };

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { isOpen, onClose, projectGroup } = this.props;

    return (
      <Dialog
        onOpening={this.handleOpening}
        onClose={onClose}
        isOpen={isOpen}
        title="프로젝트 종료"
        usePortal={true}
        autoFocus={true}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        isCloseButtonShown={false}
        enforceFocus={true}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup label="프로젝트그룹" inline={true}>
            <FormValue>{projectGroup.groupId}</FormValue>
          </FormGroup>
          <FormGroup label="프로젝트그룹명" inline={true}>
            <FormValue>{projectGroup.name}</FormValue>
          </FormGroup>
          <FormGroup label="종료일" inline={true}>
            <DateInput
              {...getMomentFormatter("YYYY/MM/DD")}
              locale="ko"
              closeOnSelection={true}
              value={moment(this.dateComplete + "").toDate()}
              onChange={(selectedDate: Date) => this.dateComplete = moment(selectedDate).format("YYYY-MM-DD")}
              maxDate={new Date("2050-01-01")}
            />
          </FormGroup>

          <FormGroup>
            <div style={{ 'color': Colors.RED3, 'fontWeight': 'bold' }}>
              <Icon
                icon={'info-sign'}
                style={{ 'marginRight': '5px !important' }}/>
              아래 아카이브 정보링크도 꼭 처리해주세요!!
            </div>
          </FormGroup>
          <FormGroup>
            <a onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSeBEHcEYSFafud56h_Aa4MDIxWihOK6EpH1e71y_aTpxnhPgA/viewform?usp=sf_link', '_blank')}>
              PM프로젝트 종료 아카이브 링크바로가기
            </a>
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button text="취소" onClick={this.handleClose} />
            <Button
              text="저장"
              intent={Intent.PRIMARY}
              onClick={this.handleSave}
              loading={this.isPending}
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

export default ProjectGroupCompletePopup;
