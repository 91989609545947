import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { observable } from "mobx";
import { RouteComponentProps } from "react-router";
import axios from "axios";
import { H3, Spinner, HTMLTable, Intent, Divider } from "@blueprintjs/core";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";

const Wrapper = styled.div``;

interface RufreeStatPageProps extends RouteComponentProps {
}

interface InjectedProps extends RufreeStatPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class RufreeStatPage extends Component<RufreeStatPageProps> {
  @observable numRufree: number = 0;
  @observable featureWords: Array<any> = [];
  @observable readyToShow: boolean = false;

  get injected() {
    return this.props as InjectedProps;
  }

  async componentDidMount() {
    try {
      let response:any = await axios.post(
        `/rufrees/agg_by_feature_words`
      );
      this.featureWords = response.data.feature_words;
      this.numRufree = response.data.num_rufree;
      this.readyToShow = true;
    } catch (e) {
      console.log(e);

      AppToaster.show({
        message: "오류가 발생했습니다",
        intent: Intent.SUCCESS
      });
    }
  }

  render() {
    return (
      <Wrapper>
        <H3>알유프리 통계</H3>
        <Divider />
        <div>* 인덱싱된 알유프리 수: {this.numRufree}</div>
        {!this.readyToShow ? (
          <Spinner />
        ) : (
          <HTMLTable
            bordered={true}
            striped={true}
            style={{'marginTop': '20px'}}
          >
            <thead>
              <tr>
                <th>No.</th>
                <th>분야명</th>
                <th>Count</th>
                <th>%</th>
              </tr>
            </thead>
            <tbody>
              {this.featureWords.map((feature_word:any, idx:number) => {
                return (
                  <tr key={idx+1}>
                    <td>{idx+1}</td>
                    <td>{feature_word.key}</td>
                    <td>{feature_word.doc_count}</td>
                    <td>{Number((feature_word.doc_count / this.numRufree) * 100).toFixed(1)}</td>
                  </tr>
                );
              })}
            </tbody>
          </HTMLTable>
        )}
  
      </Wrapper>
    );
  }
}

export default RufreeStatPage;