import React, { Component, ChangeEvent, FormEvent } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { computed, observable } from "mobx";
import { RouteComponentProps } from "react-router";
import { FormMode } from "../../../types/formMode";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import { Intent, FormGroup, InputGroup, Button, RadioGroup, Radio, H3, H4, FileInput, Checkbox } from "@blueprintjs/core";
import ContentContainer from "../../atoms/ContentContainer/ContentContainer";
import ClientUserSelectionFormView from "./ClientUserSelectionFormView";
import { getNodeId } from "mobx-state-tree";
import {
  AddItemButtonContainer,
  AddItemButton
} from "../../atoms/AddItemButton/AddItemButton";
import {
  ItemRow,
  RemoveButtonContainer,
  RemoveButton
} from "../../atoms/RemoveItemButton/RemoveItemButton";
import { ClientFileForm } from "../../../store/forms/ClientForm/ClientFileForm";

const Wrapper = styled.div``;

const ButtonsRow = styled.div`
  /* text-align: right; */

  & > * + * {
    margin-left: 5px;
  }
`;

const FormContainer = styled.div`
`;
const FormSection = styled.div`
  width: 300px;
  margin: 5px;
`;
const FormSection2 = styled.div`
  width: 610px;
  margin: 5px;
`;
const FormContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const FormSectionTitle = styled(H4)`
  margin-top: 20px;
  background-color: lightgray;
`;

interface PageParams {
  clientId: string;
}

interface ClientFormPanelProps extends RouteComponentProps<PageParams> {
  mode: FormMode;
}

interface InjectedProps extends ClientFormPanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ClientFormPanel extends Component<ClientFormPanelProps> {
  @observable isPending = false;
  @observable isCreateSuccess = false;
  @observable isEditSuccess = false;

  get injected() {
    return this.props as InjectedProps;
  }

  get formStore() {
    return this.injected.appStore.clientFormStore;
  }

  @computed
  get currentForm() {
    return this.formStore.currentForm;
  }

  @computed
  get bizForm() {
    return this.formStore.bizFileForm;
  }

  @computed
  get bnkForm() {
    return this.formStore.bnkFileForm;
  }

  async componentDidMount() {
    const { mode } = this.props;
    const { clientId } = this.props.match.params;

    try {
      if (mode !== FormMode.Create && clientId) {
        await this.injected.appStore.clientFormStore.fetchClient(clientId);
        await this.injected.appStore.clientFormStore.fetchClientFile(clientId);
      } else {
        await this.injected.appStore.clientFormStore.initForm();
      }

      await this.injected.appStore.clientUserStore.fetchClientUsers();
    } catch (e) {
    } finally {
    }
  }

  handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.currentForm.setName(e.target.value);
  };

  handleClientTypeChange = (e: FormEvent<HTMLInputElement>) => {
    this.currentForm.setClientType(e.currentTarget.value);
  };

  handleIdentityNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.currentForm.setIdentityNumber(e.target.value);
  };

  handleRepresentativeNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.currentForm.setRepresentativeName(e.target.value);
  };

  handleAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.currentForm.setAddress(e.target.value);
  };

  handleUserAdd = () => {};

  handleFileSubmit = async (action: string, form: ClientFileForm, file: File | null, filetype: string) => {
    try {
      if(action === 'delete') {
        await this.formStore.updateFile(form, action);
        AppToaster.show({
          message: "파일이 삭제되었습니다.",
          intent: Intent.SUCCESS
        });
      } else if (action === 'update' && file) {
        const {userStore} = this.injected.appStore;

        form.setFile(file);
        form.setClient(this.currentForm.clientId);
        form.setFiletype(filetype);
        form.setUserId(userStore.userId);
        form.setFilename(file.name);

        await this.formStore.updateFile(form, (form && form.id && form.id > 0) ? 'put' : 'post');
        AppToaster.show({
          message: "파일이 업데이트되었습니다.",
          intent: Intent.SUCCESS
        });        
      }
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }   
  }

  handleSubmit = async () => {
    if (this.isCreateSuccess) {
      AppToaster.show({
        message: "이미 저장된 클라이언트입니다",
        intent: Intent.DANGER
      });

      return;
    }

    this.isPending = true;

    try {
      await this.formStore.postClient();
      AppToaster.show({
        message: "새로운 클라이언트가 생성되었습니다.",
        intent: Intent.SUCCESS
      });
      this.isCreateSuccess = true;

      this.props.history.push("/clients");
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    } finally {
      this.isPending = false;
    }
  };

  handleCancel = () => {
    this.props.history.push("/clients");
  };

  handleEditSubmit = async () => {
    this.isPending = true;

    try {
      await this.formStore.patchClient();
      AppToaster.show({
        message: "수정되었습니다.",
        intent: Intent.SUCCESS
      });

      this.isEditSuccess = true;

      this.props.history.push("/clients");
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    } finally {
      this.isPending = false;
    }
  };

  render() {
    return (
      <Wrapper>
        <FormContainer>
          <FormContent>
          <FormSection>
            <FormSectionTitle>회사 정보</FormSectionTitle>
            <FormGroup label={<strong>회사 이름</strong>}>
              <InputGroup
                value={this.currentForm.name}
                onChange={this.handleNameChange}
              />
            </FormGroup>    

            <FormGroup label={<strong>회사 종류 선택</strong>}>
              <RadioGroup  
                onChange={this.handleClientTypeChange}
                selectedValue={this.currentForm.clientType || '기업'}
              >
                <Radio label="기업" value="기업" />
                <Radio label="개인" value="개인" />
                <Radio label="해외법인" value="해외법인" />
              </RadioGroup>
            </FormGroup>

            <FormGroup label={<strong>대표자 이름</strong>}>
              <InputGroup
                value={this.currentForm.representativeName}
                onChange={this.handleRepresentativeNameChange}
              />
            </FormGroup>

            <FormGroup label={<strong>사업자 등록 번호</strong>}>
              <InputGroup
                placeholder={'000-00-00000'}
                value={this.currentForm.identityNumber}
                onChange={this.handleIdentityNumberChange}
              />
            </FormGroup>

            <FormGroup label={<strong>회사 주소</strong>}>
              <InputGroup
                value={this.currentForm.address}
                onChange={this.handleAddressChange}
              />
            </FormGroup>

            <FormGroup label={
              <strong>
                사업자등록증 &nbsp;
                (
                  {this.bizForm && this.bizForm.id && this.bizForm.id > 0 ? (
                    <span>
                      <Button
                        text={this.bizForm.filename}
                        minimal={true}
                        onClick={() => {window.open(this.bizForm.url, "_blank")}}
                      />
                      ,
                      <Button
                        text={'삭제'}
                        small={true}
                        onClick={() => { this.handleFileSubmit("delete", this.bizForm, null, "") }}
                      />
                    </span>
                  ) : (
                    <span>없음</span>
                  )}
                )
              </strong>
            }>
              <FileInput
                fill={true}
                onInputChange={(e: FormEvent<HTMLInputElement>) => {
                  if(e.currentTarget.files) {
                    this.handleFileSubmit("update", this.bizForm, e.currentTarget.files[0], "BIZ");
                  }
                }}
              />
            </FormGroup>

            <FormGroup label={
              <strong>
                통장사본 &nbsp;
                (
                  {this.bnkForm && this.bnkForm.id && this.bnkForm.id > 0 ? (
                    <span>
                      <Button
                        text={this.bnkForm.filename}
                        minimal={true}
                        onClick={() => {window.open(this.bnkForm.url, "_blank")}}
                      />
                      ,
                      <Button
                        text={'삭제'}
                        small={true}
                        onClick={() => { this.handleFileSubmit("delete", this.bnkForm, null, "") }}
                      />
                    </span>
                  ) : (
                    <span>없음</span>
                  )}
                )
              </strong>
            }>
              <FileInput
                fill={true}
                onInputChange={(e: FormEvent<HTMLInputElement>) => {
                  if(e.currentTarget.files) {
                    this.handleFileSubmit("update", this.bnkForm, e.currentTarget.files[0], "BNK");
                  }
                }}
              />
            </FormGroup>

          </FormSection>
          <FormSection2>
            <FormSectionTitle>담당자 정보</FormSectionTitle>
            <div style={{display:'flex'}}>
              <FormGroup label={<strong>계약 담당자</strong>} style={{width:'300px'}}>
                <InputGroup
                  placeholder={'이름'}
                  value={this.currentForm.contractPersonName}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    this.currentForm.setContractPersonName(e.target.value);
                  }}
                />
                <InputGroup
                  placeholder={'이메일'}
                  style={{marginTop: '2px'}}
                  value={this.currentForm.contractPersonEmail}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    this.currentForm.setContractPersonEmail(e.target.value);
                  }}
                />
                <InputGroup
                  placeholder={'전화번호'}
                  style={{marginTop: '2px'}}
                  value={this.currentForm.contractPersonPhone}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    this.currentForm.setContractPersonPhone(e.target.value);
                  }}
                />              
              </FormGroup>            
              <FormGroup label={
                <div style={{display: 'flex'}}>
                <strong>세금계산서 담당자</strong>
                <Checkbox 
                  label={'(계약담당자와 동일)'}
                  style={{margin: '0 0 0 auto'}}
                  checked={this.currentForm.hasSameContractTaxPerson}
                  onChange={() => {
                    this.currentForm.setHasSameContractTaxPerson(!this.currentForm.hasSameContractTaxPerson);
                  }}
                />
                </div>
              } style={{width:'300px', marginLeft:'5px'}}>
                <InputGroup
                  placeholder={'이름'}
                  value={this.currentForm.taxPersonName}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    this.currentForm.setTaxPersonName(e.target.value);
                  }}
                  disabled={this.currentForm.hasSameContractTaxPerson}
                />
                <InputGroup
                  placeholder={'이메일'}
                  style={{marginTop: '2px'}}
                  value={this.currentForm.taxPersonEmail}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    this.currentForm.setTaxPersonEmail(e.target.value);
                  }}
                  disabled={this.currentForm.hasSameContractTaxPerson}
                />
                <InputGroup
                  placeholder={'전화번호'}
                  style={{marginTop: '2px'}}
                  value={this.currentForm.taxPersonPhone}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    this.currentForm.setTaxPersonPhone(e.target.value);
                  }}
                  disabled={this.currentForm.hasSameContractTaxPerson}
                />              
              </FormGroup>            
            </div>

            <FormSectionTitle>실무자 정보</FormSectionTitle>
            {this.currentForm.users.map(user => (
              <ItemRow key={getNodeId(user)}>
                <RemoveButtonContainer style={{left: '580px'}}>
                  <RemoveButton
                    onClick={() => this.currentForm.removeUser(user)}
                  />
                </RemoveButtonContainer>
                <ClientUserSelectionFormView clientUserForm={user} />
              </ItemRow>
            ))}

            <AddItemButtonContainer>
              <AddItemButton onClick={() => this.currentForm.addUser()} />
            </AddItemButtonContainer>
          </FormSection2>
          </FormContent>
        </FormContainer>
        <ContentContainer>
          {this.props.mode === FormMode.Create && (
            <ButtonsRow>
              <Button text="취소" onClick={this.handleCancel} />
              <Button
                text="생성"
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit}
                loading={this.isPending}
              />
            </ButtonsRow>
          )}

          {this.props.mode === FormMode.Edit && (
            <ButtonsRow>
              <Button text="취소" onClick={this.handleCancel} />
              <Button
                text="수정사항 적용"
                intent={Intent.PRIMARY}
                onClick={this.handleEditSubmit}
                loading={this.isPending}
              />
            </ButtonsRow>
          )}
        </ContentContainer>
      </Wrapper>
    );
  }
}

export default ClientFormPanel;
