import React, { Component, ChangeEvent } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { ProjectGroupManagerForm } from "../../../store/forms/ProjectGroupForm/ProjectGroupManagerForm";
import { computed } from "mobx";
import PMUserSelection from "../../molecules/PMUserSelection/PMUserSelection";
import { PMUser } from "../../../store/models/PMUser";

const Wrapper = styled.div`
  margin-bottom: 10px;
  white-space: nowrap;

  & > * {
    margin-right: 8px;
    display: inline-block;
  }
`;

interface ProjectGroupManagerFormViewProps {
  manager: ProjectGroupManagerForm;
}

interface InjectedProps extends ProjectGroupManagerFormViewProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ProjectGroupManagerFormView extends Component<
  ProjectGroupManagerFormViewProps
> {
  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get currentForm() {
    return this.props.manager;
  }

  @computed
  get selectedPMUser() {
    return this.injected.appStore.pmUserStore.pmUsers.find(
      x => x.name === this.currentForm.name
    );
  }

  handlePMUserChange = (selectedValue: PMUser) => {
    this.currentForm.setName(selectedValue.name);
    this.currentForm.setEmail(selectedValue.email);
    this.currentForm.setPhone(selectedValue.id + ""); // phone 대신 id를 넣는다. 특정하기 위한 것이 아니로 phone 에 값이 있어야 하기 떄문.
  };

  render() {
    return (
      <Wrapper>
        <PMUserSelection
          onChange={this.handlePMUserChange}
          currentValue={this.selectedPMUser}
          blockFetchingData={true}
        />
      </Wrapper>
    );
  }
}

export default ProjectGroupManagerFormView;
