import React, { Component, ChangeEvent } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { FormMode } from "../../../types/formMode";
import { RouteComponentProps } from "react-router";
import { computed, observable } from "mobx";
import { PMUser } from "../../../store/models/PMUser";
import { Rufree } from "../../../store/models/Rufree";
import {
  Callout,
  HTMLTable,
  Spinner,
  FormGroup,
  InputGroup,
  TextArea,
  Button,
  Intent,
  H3,
  HTMLSelect,
  Icon, IOptionProps
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { DateInput } from "@blueprintjs/datetime";
import { getMomentFormatter } from "../../../utils/date";
import FormContainer from "../../atoms/FormContainer/FormContainer";
import FormSection from "../../atoms/FormSection/FormSection";
import InspectionSelectionFormView from "../ProjectGroupFormPage/InpsectionSelectionFormView";
import PMUserSelection from "../../molecules/PMUserSelection/PMUserSelection";
import RufreeSelection from "../../molecules/RufreeSelection/RufreeSelection";
import {
  ItemRow,
  RemoveButton,
  RemoveButtonContainer
} from "../../atoms/RemoveItemButton/RemoveItemButton";
import {
  AddItemButton,
  AddItemButtonContainer
} from "../../atoms/AddItemButton/AddItemButton";
import ContentContainer from "../../atoms/ContentContainer/ContentContainer";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import moment from "moment";
import { InspectionSelectionForm } from "../../../store/forms/ProjectGroupForm/InspectionSelectionForm";
import TemporaryStorage from "../../molecules/Utility/TemporaryStorage/TemporaryStorage";

const Wrapper = styled.div``;
const ButtonsRow = styled.div`
  margin-bottom: 50px;
  & > * + * {
    margin-left: 5px;
  }
`;
const FlexRow = styled.div`
  display: flex;
  & > * + * {
    margin-left: 5px;
    margin-bottom: 5px;
  }
`;
const CalloutWrapper = styled(Callout)`
  padding-top: 0;
`;
const RefreshIcon = styled(Icon)`
  margin-top: 5px;
  margin-left: 10px;
`;
const RightAlignDiv = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

interface PageParams {
  id: string;
}

interface RufreeContractFormPageProps extends RouteComponentProps<PageParams> {
  mode: FormMode;
}

interface InjectedProps extends RufreeContractFormPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class RufreeContractFormPage extends Component<RufreeContractFormPageProps> {
  @observable isFormInitiated = false;
  @observable rufreeEmail = '';
  @observable rufreeAddress = '';

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get formStore() {
    return this.injected.appStore.contractFromStore;
  }

  @computed
  get currentForm() {
    return this.formStore.rufreeContractForm;
  }

  @computed
  get selectedPM() {
    return this.injected.appStore.pmUserStore.pmUsers.find(
      x => x.id === this.currentForm.pmUser
    );
  }

  @computed
  get selectedRufree() {
    return this.injected.appStore.rufreeStore.rufrees.find(
      x => x.rufreeId === this.currentForm.rufreeUser
    );
  }

  @computed
  get returnUrl() {
    const { id } = this.props.match.params;
    if(this.props.mode === FormMode.Create && id) {
      return `/contract-request/rufree/${id}/detail`;
    }
    if(this.props.mode === FormMode.Edit && id) {
      return `/contract-request/rufree/${id}/detail`;
    }
    return '/contract-request?type=rufree';
  }

  redirectToDetail() {
    this.props.history.push(`/contract-request/rufree/${this.currentForm.contractId}/read`);
  }

  updateRufreeInfo(rufreeId: string) {
    this.currentForm.setRufreeUser(rufreeId);
    this.currentForm.setAddress(this.rufreeAddress);
  }

  async componentDidMount() {
    await this.injected.appStore.rufreeStore.fetchAllRufrees();
    await this.formStore.initRufreeContractForm();

    const { mode } = this.props;
    const { id } = this.props.match.params;

    if (mode !== FormMode.Create && id) {
      await this.formStore.fetchRufreeContract(id);
    }

    this.isFormInitiated = true;
  }

  handleRufreeChange = (rufree: Rufree) => {
    this.rufreeEmail = rufree.email;
    this.rufreeAddress = rufree.address;

    this.updateRufreeInfo(rufree.rufreeId);
  };

  handleRefreshRufreeClick = async () => {
    if(this.selectedRufree) {
      const rufree = await this.selectedRufree.fetch();
      this.rufreeEmail = rufree.email;
      this.rufreeAddress = rufree.address;
      this.updateRufreeInfo(rufree.rufreeId);

      console.log(rufree.address)
    } 
  }

  handleCancel = () => {
    if (this.props.mode === FormMode.Create) {
      this.props.history.push(`/contract?tab=request&type=rufree`);
    } else {
      this.redirectToDetail();
    }
  }

  handleEditButtonClick = () => {
    const { id } = this.props.match.params;
    this.props.history.push(`/contract-request/rufree/${id}/edit`);
  }

  handleCreateSubmit = async () => {
    try {
      await this.formStore.postRufreeContract();
      AppToaster.show({
        message: "새로운 알유프리 계약 요청이 생성되었습니다.",
        intent: Intent.SUCCESS
      });

      this.redirectToDetail();
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  handleEditSubmit = async () => {
    try {
      await this.formStore.patchRufreeContract();

      AppToaster.show({
        message: "수정사항이 반영되었습니다.",
        intent: Intent.SUCCESS
      });

      this.redirectToDetail();
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  render() {
    if (!this.isFormInitiated) {
      return <Spinner />;
    }

    const { currentForm } = this;
    const contractTypeOptions: IOptionProps[] = [
      { label: "팀모델", value: "팀모델(디폴트)" }, { label: "일반", value: "일반" },
      { label: "시간제 태스크", value: "시간제 태스크" }
    ];
    return (
      <Wrapper>
        <H3>
          알유프리 계약 요청
          { this.props.mode === FormMode.Create && (<> 생성하기</>) }
          { this.props.mode === FormMode.Edit && (<> 수정하기</>) }
        </H3>

        <TemporaryStorage keyName={"RufreeContractRequest"} title={"contractName"} setForm={(val) => {
          this.formStore.setRufreeContractForm(val);
        }} currentForm ={this.currentForm} />

        <FormContainer>
          <FormSection>
            <FormGroup label={<strong>계약 타입</strong>}>
              <HTMLSelect
                value={currentForm.contractType}
                onChange={(e) => {
                  currentForm.setContractType(e.target.value);
                }}
                options={contractTypeOptions}
              />
            </FormGroup>
            <FormGroup label={<strong>담당매니저</strong>}>
              <PMUserSelection
                onChange={(selectedValue: PMUser) => {currentForm.setPmUser(selectedValue.id);}}
                currentValue={this.selectedPM}
                blockFetchingData={false}
              />
            </FormGroup>
            <FormGroup label={<strong>계약명(프로젝트명)</strong>}>
              <InputGroup
                value={currentForm.contractName}
                onChange={
                  (e: ChangeEvent<HTMLInputElement>) => {
                    currentForm.setContractName(e.target.value);
                  }
                }
              />
            </FormGroup>
            <FormGroup label={<strong>알유프리</strong>}>
              <FlexRow>
                <RufreeSelection
                  onChange={this.handleRufreeChange}
                  currentValue={this.selectedRufree}
                  blockFetchingData={true}
                />
                <RefreshIcon
                  icon={IconNames.REFRESH}
                  intent={Intent.PRIMARY}
                  onClick={this.handleRefreshRufreeClick} />
              </FlexRow>
            </FormGroup>
            <CalloutWrapper>
              {
                this.selectedRufree && (
                  <>
                    
                    <HTMLTable
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr><th>이름</th><th>이메일</th><th>주소</th></tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{this.selectedRufree.name}</td>
                          <td>{this.rufreeEmail}</td>
                          <td>{this.rufreeAddress}</td>
                        </tr>
                      </tbody>
                    </HTMLTable>

                    <RightAlignDiv>
                      <Button
                        className={'bp3-small bp3-minimal'}
                        text="알유프리 정보 수정하기"
                        intent={Intent.PRIMARY}
                        onClick={() => {window.open(`/rufrees/${this.selectedRufree && this.selectedRufree.rufreeId}/edit`, '_blank');}}
                      />
                    </RightAlignDiv>
                  </>
                )
              }
            </CalloutWrapper>
          </FormSection>

          <FormSection>
            <FormGroup label={<strong>계약정보</strong>}>
              <HTMLSelect
                value={ currentForm.info }
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  console.log(e.currentTarget.value)
                  currentForm.setInfo(e.currentTarget.value);
                }}
                options={['개인', '대리인', '개인사업자']}
              >
              </HTMLSelect>
            </FormGroup>

            <FormGroup label={<strong>대리인정보</strong>}>
              <FlexRow>
                <InputGroup placeholder={'이름'}
                  value={currentForm.agentName}
                  onChange={
                    (e: ChangeEvent<HTMLInputElement>) => {
                      currentForm.setAgentName(e.target.value);
                    }
                  }
                />
                <InputGroup placeholder={'이메일'}
                  value={currentForm.agentEmail}
                  onChange={
                    (e: ChangeEvent<HTMLInputElement>) => {
                      currentForm.setAgentEmail(e.target.value);
                    }
                  }
                />
              </FlexRow>
              <InputGroup placeholder={'주소'}
                value={currentForm.agentAddress}
                onChange={
                  (e: ChangeEvent<HTMLInputElement>) => {
                    currentForm.setAgentAddress(e.target.value);
                  }
                }
              />
            </FormGroup>

            <FormGroup label={<strong>계약체결일</strong>}>
              <DateInput
                {...getMomentFormatter("YYYY/MM/DD")}
                locale="ko"
                closeOnSelection={true}
                value={currentForm.contractDate ? moment(currentForm.contractDate).toDate() : null}
                onChange={(selectedDate: Date) =>
                  currentForm.setContractDate(moment(selectedDate).format("YYYY-MM-DDT00:00"))
                }
                maxDate={new Date("2050-01-01")}
              />
            </FormGroup>

            <FormGroup label={<strong>스프린트 기간</strong>}>
              <InputGroup
                value={currentForm.sprintPeriod}
                onChange={
                  (e: ChangeEvent<HTMLInputElement>) => {
                    currentForm.setSprintPeriod(e.target.value);
                  }
                }
              />
            </FormGroup>
            <FormGroup label={<strong>실수령액(검수서 기준 VAT별도)</strong>}>
              <InputGroup
                value={currentForm.paymentFee.toLocaleString("en-US")}
                onChange={
                  (e: ChangeEvent<HTMLInputElement>) => {
                    let val = 0;
                    try{
                      val = parseInt(e.target.value.replace(/,/g, ''))
                    }catch (e) {
                      val = 0;
                    }
                    if(isNaN(val)){
                      val = 0;
                    }
                    currentForm.setPayment(val);
                  }
                }
              />
              {currentForm.payment && <div>최초입력 값: {currentForm.payment}</div>}
            </FormGroup>
            <FormGroup label={<strong>수수료(검수서 기준 VAT별도)</strong>}>
              <InputGroup
                value={currentForm.commissionFee.toLocaleString("en-US")}
                min={0}
                // locale={"en-US"}
                onChange={
                  (e: ChangeEvent<HTMLInputElement>) => {
                    let val = 0;
                    try{
                      val = parseInt(e.target.value.replace(/,/g, ''))
                    }catch (e) {
                      val = 0;
                    }
                    if(isNaN(val)){
                      val = 0;
                    }
                    currentForm.setCommission(val);
                  }
                }
              />
              {currentForm.commission && <div>최초입력 값: {currentForm.commission}</div>}
            </FormGroup>
          </FormSection>

          <FormSection>
            <FormGroup label={<strong>검수서</strong>}>
              <>
                <AddItemButtonContainer>
                  <AddItemButton
                    onClick={this.currentForm.addInspectionSelection}
                  />
                </AddItemButtonContainer>
                {currentForm.inspections.map((inspection:InspectionSelectionForm) => (
                  <ItemRow key={inspection.id}>
                    <RemoveButtonContainer>
                      <RemoveButton
                        onClick={() =>
                          currentForm.removeInspectionSelection(inspection)
                        }
                      />
                    </RemoveButtonContainer>
                    <InspectionSelectionFormView
                      key={inspection.id}
                      inspectionSelection={inspection}
                    />
                  </ItemRow>
                ))}
              </>
            </FormGroup>
            <FormGroup label={<strong>계약범위</strong>}>
              <TextArea
                className={'bp3-fill'}
                value={currentForm.contractRange}
                onChange={
                  (e: ChangeEvent<HTMLTextAreaElement>) => {
                    currentForm.setContractRange(e.currentTarget.value);
                  }
                }
              />
            </FormGroup>
            <FormGroup label={<strong>특약사항</strong>}>
              <TextArea
                className={'bp3-fill'}
                value={currentForm.specialContract}
                onChange={
                  (e: ChangeEvent<HTMLTextAreaElement>) => {
                    currentForm.setSpecialContract(e.currentTarget.value);
                  }
                }
              />
            </FormGroup>
            <FormGroup label={<strong>비고</strong>}>
              <TextArea
                className={'bp3-fill'}
                value={currentForm.comment}
                onChange={
                  (e: ChangeEvent<HTMLTextAreaElement>) => {
                    currentForm.setComment(e.currentTarget.value);
                  }
                }
              />
            </FormGroup>
          </FormSection>

        </FormContainer>

        <ContentContainer>
            <ButtonsRow>
              <Button text="취소" onClick={this.handleCancel} />
              { 
                this.props.mode === FormMode.Read && (
                  <>
                    <Button
                      text="수정하기"
                      intent={Intent.PRIMARY}
                      onClick={this.handleEditButtonClick}
                    />
                    
                    {
                      currentForm.status === '결재대기' ? (
                        <Button
                          text="결재하기"
                          intent={Intent.SUCCESS}
                          onClick={() => {}}
                        />
                      ) : (
                        <Button
                          text="결재요청"
                          intent={Intent.SUCCESS}
                          onClick={() => {}}
                        />
                      )
                    }
                  </>
                  )
              }
              { 
                this.props.mode === FormMode.Create && (
                  <Button
                    text="저장"
                    intent={Intent.PRIMARY}
                    onClick={this.handleCreateSubmit}
                  />)
              }
              {
                this.props.mode === FormMode.Edit && (
                  <Button
                    text="수정사항 적용"
                    intent={Intent.PRIMARY}
                    onClick={this.handleEditSubmit}
                  />
                )
              }

              
              
            </ButtonsRow>
        </ContentContainer>
      </Wrapper>
    );
  }
}

export default RufreeContractFormPage;
