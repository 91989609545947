import { types } from "mobx-state-tree";

export const ProjectGroupManagerModel = types.model("ProjectGroupManager", {
  name: types.string,
  phone: types.string,
  email: types.string
});

type ProjectGroupManagerType = typeof ProjectGroupManagerModel.Type;
export interface ProjectGroupManager extends ProjectGroupManagerType {}
