import React, { Component, ChangeEvent, KeyboardEvent } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import {
  Spinner,
  HTMLSelect,
  IOptionProps,
  InputGroup,
  Button,
  Intent,
  Icon,
  Switch
} from "@blueprintjs/core";
import { RouteComponentProps } from "react-router";
import { computed, observable } from "mobx";
import ProjectGroupRow from "../../molecules/ProjectGroupRow/ProjectGroupRow";
import { ProjectGroup } from "../../../store/models/ProjectGroup";
import LargeContainer from "../../atoms/LargeContainer/LargeContainer";
import {
  PageNavLink,
  PageNavText,
  PageNavWrapper
} from "../../atoms/PageNav/PageNav";
import { AddCircle } from "styled-icons/remix-line/AddCircle/AddCircle";
import { TableHeader, Header } from "../../atoms/CardTable/CardTable";
import Paginator from "../../molecules/Paginator/Paginator";
// import orderBy from "lodash/orderBy";

const TableBody = styled.div`
  min-height: 500px;
`;
const SelectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  * + * {
    margin-left: 3px;
  }
`;
const SearchSection = styled.div`
  display: flex;
  width: 505px;
  div + button {
    margin-left: 3px;
  }
  input {
    width: 350px !important;
  }
`;
const OrderByIcon = styled(Icon)`
  float: right;
  margin-top: 3px;
`;

interface ProjectGroupListPageProps extends RouteComponentProps {}

interface InjectedProps extends ProjectGroupListPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ProjectGroupListPage extends Component<ProjectGroupListPageProps> {
  @observable readyToShow = false;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get appStore() {
    return this.injected.appStore;
  }

  @computed
  get currentPMName() {
    const { email } = this.injected.appStore.userStore;
    const pmUser = this.injected.appStore.pmUserStore.pmByEmail(email);
    if(pmUser) {
      return pmUser.is_support ? '' : pmUser.name;
    } else {
      return '';
    }
  }

  async componentDidMount() {
    if(this.appStore.pmUserStore.needReload) {
      await this.appStore.pmUserStore.fetchPMUsers();
    }

    try {
      this.handleFetch();

      this.readyToShow = true;
    } catch (e) {}
  }

  handleCreateButtonClick = () => {
    this.props.history.push("/project-groups/new");
  };

  handleRowClick = (projectGroup: ProjectGroup) => {
    this.props.history.push(`/project-groups/${projectGroup.groupId}`);
  };

  handleFetch = async () => {
    this.readyToShow = false;

    try {
      await this.injected.appStore.projectGroupStore.fetchProjectGroups();

      // this.readyToShow = true;
    } catch (e) {}
    finally {
      this.readyToShow = true;
    }
  }

  render() {
    let content = [];

    const { isPM } = this.injected.appStore.userStore;
    const {
      projectGroups,
      pageGroup,
      totalPages,
      currentPage,
      filterPM,
      filterQuery,
      filterStatus,
      filteExceptInner,
      orderBy,
      orderAsc,
      setPageGroup,
      setCurentPage,
      setFilterPM,
      setFilterQuery,
      setFilterStatus,
      setFilteExceptInner,
      setOrderAsc,
      setOrderBy
    } = this.appStore.projectGroupStore;

    const OrderIcon = <OrderByIcon
      icon={orderAsc ? 'arrow-up' : 'arrow-down'}
      iconSize={12} />;

    const statusOptions: IOptionProps[] = [
      {label: '전체', value: ''},
      {label: '진행중', value: 'ongoing'},
      {label: '미진행', value: 'notstart'},
      {label: '홀딩중', value: 'pause'},
      {label: '종료', value: 'done'}
    ]
    const pmOptions: IOptionProps[] = [
      {label: '모든 PM', value: ''},
    ].concat(this.injected.appStore.pmUserStore.pmUsers
      .filter(x => !x.is_support)
      .map(pm => (
        {label: pm.name, value: pm.name}
    )))

    if (this.readyToShow) {
      content.push(
        projectGroups.map(projectGroup => (
          <ProjectGroupRow
            key={projectGroup.groupId}
            projectGroup={projectGroup}
            onClick={() => this.handleRowClick(projectGroup)}
          />
        ))
      )
    } else {
      content.push(<Spinner />);
    }

    return (
      <LargeContainer>
        <PageNavWrapper>
          <PageNavText>프로젝트 그룹</PageNavText>
          {isPM && (
          <PageNavLink to="/project-groups/new">
            <PageNavText>
              <AddCircle size={21} /> <span>새 프로젝트 그룹</span>
            </PageNavText>
          </PageNavLink>
          )}
        </PageNavWrapper>

        <SelectionWrapper>
          <div>
            <HTMLSelect
              value={filterStatus}
              onChange={
                async (e: ChangeEvent<HTMLSelectElement>) => {
                  const { value } = e.currentTarget;

                  setCurentPage(1);
                  setFilterStatus(value);
                  await this.handleFetch();
                }
              }
              options={statusOptions}
            />

            <HTMLSelect
              value={filterPM}
              onChange={
                async (e: ChangeEvent<HTMLSelectElement>) => {
                  const { value } = e.currentTarget;

                  setCurentPage(1);
                  setFilterPM(value);

                  await this.handleFetch();
                }
              }
              options={pmOptions}
            />

            {/* 시소 내부 프로젝트 제외 옵션 */}
            <Switch
              checked={filteExceptInner}
              labelElement={"시소 내부 프로젝트 제외"}
              onChange={ async() => {
                setFilteExceptInner(!filteExceptInner);

                setCurentPage(1);
                await this.handleFetch();
              } }
              style={{
                display: 'inline-block',
                fontSize: '12px'
              }} />
          </div>

          <SearchSection>
            <InputGroup
              placeholder="프로젝트번호/프로젝트명/클라이언트명/클라이언트번호"
              value={ filterQuery }
              onChange={ (e: ChangeEvent<HTMLInputElement>) => {
                setFilterQuery(e.target.value);
              } }
              onKeyDown={ async(event: KeyboardEvent<HTMLInputElement>) => {
                if (event.key === 'Enter') {
                  await this.handleFetch();
                }
              } }
            />

            <Button
              icon="search"
              text="검색"
              intent={Intent.PRIMARY}
              onClick={this.handleFetch}
            />

            <Button
              minimal={true}
              small={true}
              icon={<Icon icon={'reset'} iconSize={12} />}
              text='초기화'
              style={{
                fontSize: '12px'
              }}
              onClick={ async () => {

                setFilterPM(this.currentPMName);
                setFilterQuery('');
                setFilterStatus('');

                await this.handleFetch();
              } } />
          </SearchSection>

        </SelectionWrapper>

        <TableHeader>
          <Header
            style={{
              maxWidth: "104px",
              cursor: "pointer",
              color: orderBy === 'group_id' ? '#106ba3' : '#182026'
            }}
            onClick={ async () => {
              setCurentPage(1);
              if(orderBy === 'group_id') setOrderAsc(!orderAsc);
              setOrderBy('group_id');
              await this.handleFetch();
            } }
          >
            그룹번호
            {
              orderBy === 'group_id' && OrderIcon
            }
          </Header>
          <Header style={{ maxWidth: "250px" }}>제목</Header>
          <Header style={{ maxWidth: "270px" }}>클라이언트명</Header>
          <Header style={{ maxWidth: "200px" }}>매니저</Header>
          <Header
            style={{ maxWidth: "200px", cursor: "pointer", color: orderBy === 'order_by_status' ? '#106ba3' : '#182026' }}
            onClick={ async () => {
              setCurentPage(1);
              setOrderBy('order_by_status');
              setOrderAsc(false);
              await this.handleFetch();
            } }
          >상태</Header>
          <Header style={{ maxWidth: "283px" }}>진행상황</Header>
        </TableHeader>

        <TableBody>
          {content}
        </TableBody>

        <Paginator
          pageGroup={pageGroup}
          totalPages={totalPages}
          currentPage={currentPage}
          setPageGroup={setPageGroup}
          setCurentPage={setCurentPage}
          onFetch={this.handleFetch}
        />
      </LargeContainer>
    );
  }
}

export default ProjectGroupListPage;
