import React, { FC } from "react";
import styled from 'styled-components/macro';
import moment from 'moment';

import { Icon, Tag } from "@blueprintjs/core";

// const EventWrapper = styled.div<TagWrapperProps>`
//   color: ${props => props.color};
// `;

// export const Event: FC<any> = props => {
//   let color = '';
//   switch (props.event.category) {
//     case "prg":
//       color = '';
//       break;
//     case "ofh":
//       color = '';
//       break;
//     case "sch":
//       color = '#1D7324';
//       break;
//     case "tas":
//       color = '#5642A6';
//       break;
//     default:
//       color = '#728C23';
//       break;
//   }

//   return (
//     <EventWrapper color={color}>
//       <div>{ props.title }</div>
//     </EventWrapper>
//   )
// }

interface TagWrapperProps {
  color: string;
}

export const Toolbar: FC<any> = props => {
  const navigate = {
      PREVIOUS: 'PREV',
      NEXT: 'NEXT',
      TODAY: 'TODAY',
      DATE: 'DATE',
  }
  const title_month = moment(props.date).format('YYYY년 MM월');
  const title_day = moment(props.date).format('YYYY년 MM월 DD일');

  const Wrapper = styled.div`
    margin-bottom: 5px;
    span {
      cursor: pointer;
    }
    display: flex;
    justify-content: space-between;
  `;
  const LeftWrapper = styled.div`
  `;
  const RightWrapper = styled.div`
  `;
  const TitleWrapper = styled.span`
    font-weight: bold;
  `;
  const TagWrapper = styled(Tag)<TagWrapperProps>`
    background-color: ${props => props.color};
    padding: 0 8px !important;
    font-size: 10px !important;
  `;

  return (
    <Wrapper className="toolbar-container">

      <LeftWrapper>
        {
          props.view === 'month' ? (
            <>
            <Icon
              icon={'caret-left'}
              iconSize={18}
              onClick={() => {props.onNavigate(navigate.PREVIOUS)}} />

            <TitleWrapper
              onClick={() => {props.onNavigate(navigate.TODAY)}}
            >
              { title_month }
            </TitleWrapper>

            <Icon
              icon={'caret-right'}
              iconSize={18}
              onClick={() => {props.onNavigate(navigate.NEXT)}} />
            </>
          ) : (
            <TitleWrapper>
              { title_day }&nbsp;&nbsp;&nbsp;
            </TitleWrapper>
          )
        }

        {
          props.view !== 'month' && (
            <Tag
              minimal={true}
              onClick={() => {props.onView('month')}}>
              <Icon
                icon={'undo'}
                iconSize={12}
                style={{marginRight: '5px'}}
              />
              달력 뷰로 돌아가기
            </Tag>
          )
        }
        
      </LeftWrapper>

      <RightWrapper>
        <TagWrapper round={true} color={'#F29D49'}> 미팅 </TagWrapper>
        <TagWrapper round={true} color={'#FF6E4A'}> 휴가 </TagWrapper> 
        <TagWrapper round={true} color={'#15B371'}> 근무 </TagWrapper>
        <TagWrapper round={true} color={'#A854A8'}> 할일 </TagWrapper>
        <TagWrapper round={true} color={'#2B95D6'}> 프로젝트일정 </TagWrapper>
      </RightWrapper>

    </Wrapper >
  )
}