import React, { Component } from "react";
import { computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { AppStore } from "../../../store/AppStore";
import { FormMode } from "../../../types/formMode";
import { Dialog, Classes, FormGroup, Intent } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import { getMomentFormatter } from "../../../utils/date";
import { PrimaryButton, SecondaryButton } from "../../atoms/ParttimeComponents/ParttimeComponents";

import { AppToaster } from "../../organisms/AppToaster/AppToaster";

import { Task } from "../../../store/models/Parttime";
import { Rufree } from "../../../store/models/Rufree";
import RufreeSelection from "../../molecules/RufreeSelection/RufreeSelection";

interface RufreeMatchDialogProps {
    task: Task;
    mode: FormMode;
    isOpen: boolean;
    onClose: () => void;
}

interface InjectedProps extends RufreeMatchDialogProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class RufreeMatchDialog extends Component<RufreeMatchDialogProps> {
    @observable rufree: string | any = "";
    @observable estimatedDueDate: string | any = this.props.task.estimatedDueDate ? this.props.task.estimatedDueDate : "";

    // @observable rufree: string = (this.props.task.rufree && this.props.task.rufree.rufreeId) ? this.props.task.rufree.rufreeId : "";

    get injected() {
        return this.props as InjectedProps;
    }

    @computed
    get selectedRufree() { 
        return this.injected.appStore.rufreeStore.rufrees.find(
            x => x.rufreeId === this.rufree
        );
    }

    handleRufreeChange = (rufree: Rufree) => {
        this.rufree = rufree.rufreeId;
    };

    handleEstimatedDueDateChange = (selectedDate: Date) => {
        this.estimatedDueDate = selectedDate ? moment(selectedDate).format("YYYY-MM-DD") : "";
    };

    handleOpening = () => {
        if (this.props.mode === FormMode.Edit) {
            this.rufree = this.props.task.rufree ? this.props.task.rufree.rufreeId : "";
            this.estimatedDueDate = this.props.task.estimatedDueDate ? this.props.task.estimatedDueDate : "";
        }

        if(!this.props.task.rufree){
            this.rufree = "";
        }
    };

    handleSubmit = async () => {
        const { task, onClose } = this.props;

        try {
            await this.injected.appStore.parttimeTaskStore.matchRufree(task.request.id, task.id, this.rufree, this.estimatedDueDate);
            onClose();
            await this.injected.appStore.parttimeRequestStore.fetchRequestById(task.request.id);

            AppToaster.show({
                message: "알유프리 매칭이 정상적으로 처리되었습니다.",
                intent: Intent.SUCCESS
            });
        } catch (e) {
            const error = JSON.stringify(e.response.data);
            AppToaster.show({
                message: "오류: " + error,
                intent: Intent.DANGER
            });
        }
      };

    render() {
        const { isOpen, onClose, task } = this.props;
    
        const title = `알유프리 매칭`;
    
        return (
            <Dialog
                onOpening={this.handleOpening}
                isOpen={isOpen}
                onClose={onClose}
                usePortal={true}
                autoFocus={true}
                enforceFocus={true}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                title={title}
            >
            <div className={Classes.DIALOG_BODY}>
                <FormGroup label={<strong>알유프리</strong>}>
                    <RufreeSelection
                    onChange={this.handleRufreeChange}
                    currentValue={this.selectedRufree}
                    blockFetchingData={true}
                    />
                </FormGroup>
                <FormGroup label={<strong>예상 작업 완료일</strong>}>
                    <DateInput
                    {...getMomentFormatter("YYYY/MM/DD")}
                    locale="ko"
                    closeOnSelection={true}
                    value={this.estimatedDueDate ? moment(this.estimatedDueDate).toDate() : null}
                    onChange={this.handleEstimatedDueDateChange}
                    maxDate={new Date("2050-01-01")}
                    />
                </FormGroup>
            </div>
    
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <SecondaryButton text="취소" onClick={onClose}/>
                <PrimaryButton text={"매칭하기"} onClick={this.handleSubmit}/>
              </div>
            </div>
          </Dialog>
        );
    }
}

export default RufreeMatchDialog;