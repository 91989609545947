import { flow, types, getParent } from "mobx-state-tree";
import axios from "axios";


export const CommentModel = types.model("Comment", {
  id: types.number,
  user: types.number,
  user_name: types.string,
  user_email: types.string,
  comment: types.string,
  submit_date: types.string
})
.views(self => ({
  get partnerId() {
    const root: any = getParent(self, 2);
    console.log(root.id);
    return root.id;
  }
}))
.actions(self => {
  const update = flow(function*(comment: string) {
    const parentId = self.partnerId;

    if(!parentId) {
      return;
    }
    try {
      yield axios.patch(
        `/admin/members/${parentId}/comment/`,
        {
          id: self.id,
          comment: comment
        }
      );
    } catch (e) {
      throw e;
    }
  });

  const remove = flow(function*() {
    const parentId = self.partnerId;

    if(!parentId) {
      return;
    }
    try {
      yield axios.delete(
        `/admin/members/${parentId}/comment/`,{
          data: {
            id_list: [self.id]
          }
        }
      );
    } catch (e) {
      throw e;
    }
  });
  return {
    remove,
    update
  };
});
type CommentType = typeof CommentModel.Type;
export interface IComment extends CommentType {};


export const AvailabilityOptions = [
  { value: '', label: ''},
  { value: "HIGH", label: "현재 적극적으로 구직중입니다." },
  { value: "MODERATE", label: "구직중은 아니나 좋은 제안은 언제나 환영입니다." },
  { value: "LOW", label: '현재 구직의향 없습니다.'}
];
export const EmploymentTypeOptions = [
  { value: '', label: ''},
  { value: "EMPLOYED", label: "정직원" },
  { value: "FREELANCE", label: "프리랜서" }
];
export const CoworkingTimeOptions = [
  { value: "MORNING", label: "평일 오전" },
  { value: "AFTERNOON", label: "평일 오후" },
  { value: "EVENING", label: "평일 저녁" },
  { value: "NIGHT", label: "평일 늦은 밤-새벽" },
  { value: "WEEKENDS", label: "주말 또는 공휴일" }
];
export const WorkTypeOptions = [
  { value: '', label: ''},
  { value: "PARTTIME", label: "파트타임" },
  { value: "FULLTIME", label: "풀타임" },
  { value: "EITHER", label: "파트타임/풀타임" }
];
export const ExpectationOptions = [
  { value: "INCOME", label: "부가수입" },
  { value: "CAREER", label: "커리어(경험)" },
  { value: "FREELANCE", label: "프리랜서로의 전향" },
  { value: "JOB", label: "이직" }
];

export const MemberModel = types.model("Member", {
  id: types.number,
  code: types.identifier,
  nickname: types.string,
  email: types.string,
  name: types.string,
  phone: types.string,
  associate: types.maybeNull(types.model("Associate", {
    code: types.string,
    name: types.string
  })),
  invite_code: types.maybeNull(types.string),
  availability: types.enumeration("Availability", AvailabilityOptions.map(op => op.value)),
  work_type: types.enumeration("WorkType", WorkTypeOptions.map(op => op.value)),
  expectations: types.array(types.enumeration("Expectations", ExpectationOptions.map(op => op.value))),
  frameworks: types.array(types.string),
  job_category: types.array(types.model("jobCategory", {
    name: types.string,
    parent: types.string,
  })),
  work_experience_year: types.string,
  employment_type: types.enumeration("EmploymentType", EmploymentTypeOptions.map(option => option.value)),
  company_name: types.string,
  employment_public_open: types.boolean,
  coworking_tools: types.array(types.string),
  coworking_time: types.array(types.enumeration("CoworkingTime", CoworkingTimeOptions.map(option => option.value))),
  introduction: types.string,
  marketing_agreement: types.boolean,
  created: types.string,
  modified: types.string,
  portfolio: types.maybeNull(types.model("Portfolio", {
    file: types.string,
    id: types.number,
    link: types.string,
    name: types.string,
    type: types.string
  })),
  references: types.array(types.model("References", {
    display: types.string,
    id: types.number,
    label: types.string,
    link: types.string
  })),

  comments: types.array(CommentModel)
})
.views(self => ({
  get nameWithId() {
    return `${self.code} ${self.name}`
  },
  get workTypeToString() {
    const finded = WorkTypeOptions.find(op => op.value === self.work_type);
    return finded ? finded.label : '';
  },
  get availabilityToString() {
    const finded = AvailabilityOptions.find(op => op.value === self.availability);
    return finded ? finded.label : '';
  },
  get employmentTypeToString() {
    const finded = EmploymentTypeOptions.find(op => op.value === self.employment_type);
    return finded ? finded.label : '';
  },
  get coworkingTimeToString() {
    return self.coworking_time.map((time: string) => {
      const finded = CoworkingTimeOptions.find(op => op.value === time);
      return finded ? finded.label : '';
    })
  },
  get expectationsToString() {
    return self.expectations.map((expectation: string) => {
      const finded = ExpectationOptions.find(op => op.value === expectation);
      return finded ? finded.label : '';
    })
  }
}))
.actions(self => {
  const addComment = flow(function*(comment: string) {
    try {
      yield axios.post(
        `/admin/members/${self.id}/comment/`, {
          comment: comment
        }
      );
    } catch (e) {
      throw e;
    }
  });

  return {
    addComment
  };
});
type MemberType = typeof MemberModel.Type;
export interface IMember extends MemberType {};
