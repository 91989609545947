import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import styled from "styled-components/macro";
import { Tag } from "@blueprintjs/core";

const Wrapper = styled.div`
  display: inline;
`;

interface StatusTagProps {
  status: string;
}

interface InjectedProps extends StatusTagProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class StatusTag extends Component<StatusTagProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  render() {
    const { status } = this.props;

    return (
        <Wrapper>
            <Tag style={{ minWidth: "50px", textAlign: "center" }}>{ status }</Tag>  
        </Wrapper>
    );
  }
}

export default StatusTag;
