import React, { Component } from "react";
import { Button } from "@blueprintjs/core";
import styled from "styled-components/macro";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { Expense } from "../../../store/models/Expense";
import {
  Table,
  THead,
  FirstColumnTH,
  SubColumnTH,
  GreyBackground,
  TD,
  RightAlignTD,
  SprintSpan,
  HighlightStatus
} from "../../atoms/ExpenseTable/ExpenseTable";

const Wrapper = styled.div`
  position: relative;
`;
export const TR = styled.tr`
  height: 30px;
`;



interface ExpenseRowGroupProps {
  isIncludeVAT: boolean;
  expenses?: Expense[];
  extraExpenses: Expense[];
}

@observer
class ExpenseRowGroup extends Component<ExpenseRowGroupProps> {
  @observable isConfirmOpen = false;

  render() {
    const { isIncludeVAT, expenses, extraExpenses } = this.props;
    const sprint_num = expenses ? expenses[0].sprint : 0;
    const extras = extraExpenses.filter(x => x.sprint == sprint_num)


    return (
      <Wrapper>
        <GreyBackground />
        <Table>
          <THead>
            <tr>
              <FirstColumnTH>
                { sprint_num == 0 ? (
                  <>PRE</>
                  ) : (
                  <>S{sprint_num}</>
                )} 
              </FirstColumnTH>
              <SubColumnTH>알유프리</SubColumnTH>
              <SubColumnTH>시소서비스</SubColumnTH>
              <SubColumnTH>총액</SubColumnTH>
            </tr>
          </THead>
          <tbody>
            {expenses && expenses.map((expense, index) => {
              const calculatePayment = isIncludeVAT ? 
                (expense.payment) * 1.1 : expense.payment;
              const serviceFee = isIncludeVAT ? expense.serviceFee * 1.1 : expense.serviceFee;

              return (
                <TR key={index}>
                  <TD>
                    <SprintSpan>
                      {expense.title}
                    </SprintSpan>
                  </TD>
                  <RightAlignTD>
                    {calculatePayment.toLocaleString("en-US")}
                  </RightAlignTD>
                  <RightAlignTD>{serviceFee.toLocaleString("en-US")}</RightAlignTD>
                  <RightAlignTD>{(calculatePayment+serviceFee).toLocaleString("en-US")}</RightAlignTD>
                </TR>
              )
            })}

            {extras.map((expense, index) => {
              const calculatePayment = isIncludeVAT ?
                (expense.payment) * 1.1 : expense.payment;
              const serviceFee = isIncludeVAT ? expense.serviceFee * 1.1 : expense.serviceFee;

              return (
                <TR key={index}>
                  <TD>
                    <SprintSpan>
                      {expense.title}
                    </SprintSpan>
                  </TD>
                  <RightAlignTD>
                    {calculatePayment.toLocaleString("en-US")}
                  </RightAlignTD>
                  <RightAlignTD>{serviceFee.toLocaleString("en-US")}</RightAlignTD>
                  <RightAlignTD>{(calculatePayment+serviceFee).toLocaleString("en-US")}</RightAlignTD>
                </TR>
              )
            })}
          </tbody>
        </Table>
      </Wrapper>
    );
  }
}

export default ExpenseRowGroup;
