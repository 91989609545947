export enum Affinity {
  High = "상",
  Middle = "중",
  Low = "하"
}

export interface RiskResponse {
  id: number;
  created_at: string;
  updated_at: string;
  human_risks: string[];
  slack_affinity: Affinity;
  trello_affinity: Affinity;
  online_meeting_affinity: Affinity;
  open_date: string | null;
  project: number;
}
