/*
  파트너 회원 리스트
*/
import React from 'react';
import { observer } from "mobx-react";
import { Link } from "react-router-dom";

import styled from "styled-components/macro";
import moment from "moment";

import { IMember } from '../../../../store/models/Member';
import { Button, HTMLTable, Intent, IButtonProps, Spinner } from '@blueprintjs/core';

const Wrapper = styled.div`
  & table {
    width: 100%;
    margin-bottom: 5px;
  }
  & table.bp3-html-table td {
    text-align: center;
    &.actions {
      padding: 3px 0;
      button + button { margin-left: 5px; }
      & > button > span { font-weight: bold; font-size: 13px; }
    }
  }
  & {
    .job + .job:before {
      content: ', ';
    }
  }
`;
const MemberList = (observer((props: {
  isFetching: boolean,
  members: IMember[]
}) => {
  const { isFetching, members } = props;
  const tableHeaders = [
    "ID",
    "이름",
    "닉네임",
    "직군",
    "직무",
    "근무조건",
    "전화번호",
    "이메일",
    "가입일"
  ];

  const headerRenderer = () => tableHeaders.map( (header, i) => <th key={ i }>{ header }</th> );
  const bodyRenderer = (members: IMember[]) => {
    return members.map( member => {

      return <tr key={ member.id }>
        <td><Link to={`/secondteam/member/${ member.id }`}>{ member.code }</Link></td>
        <td>{ member.name }</td>
        <td>{ member.nickname }</td>
        <td>{ member.job_category.map(job => <span className='job'>{ job.name }</span>) }</td>
        <td>{ member.job_category.map(job => <span>{ job.parent }</span>) }</td>
        <td>{ member.work_type === "PARTTIME" ? "파트타임" : member.work_type === 'FULLTIME' ? "풀타임" : "파트타임, 풀타임" }</td>
        <td>{ member.phone }</td>
        <td>{ member.email }</td>
        <td>{ moment(member.created).format('YYYY-MM-DD') }</td>
      </tr>
    });
  }

  return (
    <Wrapper>
      <HTMLTable
        condensed={ true }
        interactive={ true }
        striped={ true} >
        <thead>
          <tr>
            { headerRenderer() }
            <th />
          </tr>
        </thead>

        <tbody>
          {
            isFetching ? 
            <tr><td colSpan={ tableHeaders.length }><Spinner /></td></tr> : bodyRenderer(members)
          }
        </tbody>
    </HTMLTable>
  </Wrapper>
  )
}));
export default MemberList;
