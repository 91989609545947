import { Instance, types } from "mobx-state-tree";
import { RepositoryTemplateType } from "../../types/projectGroup";

export const CollaboratorModel = types.model("Collaborator", {
  id: types.number,
  username: types.string,
  permission: types.string,
})

export const RepositoryModel = types.model("Repository", {
  id: types.number,
  name: types.string,
  description: types.string,
  url: types.string,
  template: types.string,
  collaborators: types.array(CollaboratorModel),
});

export interface Collaborator extends Instance<typeof CollaboratorModel> {}

export interface Repository extends Instance<typeof RepositoryModel> {
  template: RepositoryTemplateType;
}
