import React, { FC } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import ProjectGroupProgressBar from "../../molecules/ProjectGroupProgressBar/ProjectGroupProgressBar";
import { Colors } from "@blueprintjs/core";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  margin: 0px 0px 5px 5px;
  & > * + * {
    margin-left: 5px;
  }
`;
const ProjectGroupNameColumn = styled.div<{ color: string }>`
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  font-weight: bold;
  a { 
    ${p => css`color: ${p.color};`}
  }
`;
const CountColumn = styled.div`
  width: 50px;`;

interface Props {
  color: string;
  id: string;
  name: string;
  totalCount: number;
  ongoingCount: number;
  doneCount: number;
}

// class SprintStatus extends Component<Props> {
const SprintStatus: FC<Props> = props => {

  // render() {
    const { name, id, totalCount, ongoingCount, doneCount, color } = props;
    const scheduledCount = totalCount - doneCount;

    if(doneCount < 0 || scheduledCount < 0) {
      return <></>
    }

    return (
      <Wrapper>
        <ProjectGroupNameColumn color={ color }>
          <Link to={`/project-groups/${id}`} target='_blank'>
            {name}
          </Link>
        </ProjectGroupNameColumn>

        <CountColumn>총 {totalCount}S</CountColumn>
        <CountColumn className="bp3-text-small" style={{ color: '#ff8300', marginTop: '1px' }}>진행 {ongoingCount}S</CountColumn>
        <CountColumn className="bp3-text-small" style={{ color: Colors.FOREST4, marginTop: '1px' }}>완료 {doneCount}S</CountColumn>

        <ProjectGroupProgressBar
          projectGroupId={id}
          doneCount={doneCount}
          onGoingCount={ongoingCount}
          totalCount={totalCount} />

      </Wrapper>
    );
  // }
}

export default SprintStatus;
