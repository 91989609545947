import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import styled from "styled-components/macro";

import { Request, RequestStatus } from "../../../store/models/Parttime";
import StatusTag from "../../molecules/ParttimeRow/StatusTag";

interface RequestRowProps {
  request: Request;
  onClick: () => void;
}

interface InjectedProps extends RequestRowProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class RequestSummaryRow extends Component<RequestRowProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  render() {
    const { request, onClick } = this.props;

    return (
      <Item onClick={onClick}>
        <StatusTag status={RequestStatus.get(request.status)}></StatusTag>  
        <h4 style={{ display: "inline", marginLeft: "10px" }}>{ request.title }</h4>    
      </Item>
    );
  }
}

export default RequestSummaryRow;

const Item = styled.p`
 cursor: pointer;
`;