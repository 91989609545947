import { types } from "mobx-state-tree";

export const ClientUserFormModel = types
  .model("ClientUserForm", {
    value: types.optional(types.number, -1),
    name: types.optional(types.string, ""),
    email: types.optional(types.string, ""),
    phone: types.optional(types.string, "")
  })
  .actions(self => ({
    setValue(value: number) {
      self.value = value;
    },
    setName(value: string) {
      self.name = value;
    },
    setEmail(value: string) {
      self.email = value;
    },
    setPhone(value: string) {
      self.phone = value;
    }
  }));

type ClientUserFormType = typeof ClientUserFormModel.Type;
export interface ClientUserForm extends ClientUserFormType {}
