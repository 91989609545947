import React, { Component } from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { ItemPredicate, Select, IItemRendererProps } from "@blueprintjs/select";
import { ContractTemplate } from "../../../store/models/ContractTemplate";
import { computed } from "mobx";
import { MenuItem, Button, Alignment } from "@blueprintjs/core";
import { highlightText } from "../../../utils/BlueprintSelectionHelpers";

const Wrapper = styled.div`
  .bp3-button-text {
    text-overflow: ellipsis;
  }
`;

export const filterItem: ItemPredicate<ContractTemplate> = (
  query,
  item,
  _index,
  exactMatch
) => {
  const normalizedTitle = item.template_name.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
    return normalizedTitle === normalizedQuery;
  } else {
    return `${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
  }
};

const ItemSelect = Select.ofType<ContractTemplate>();

interface ContractTemplateSelectionProps {
  onChange: (contractTemplate: ContractTemplate) => void;
  currentValue?: ContractTemplate;
  items?: ContractTemplate[]; // items 를 지정하면 전체목록을 불러오지 않고 해당 컬렉션 내에서만 검색
  blockFetchingData?: boolean;
}

interface InjectedProps extends ContractTemplateSelectionProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ContractTemplateSelection extends Component<ContractTemplateSelectionProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get items() {
    return (
      this.props.items || this.injected.appStore.contractStore.templates
    );
  }

  async componentDidMount() {
    if (!this.props.blockFetchingData) {
      await this.injected.appStore.contractStore.fetchTemplates();
    }
  }

  itemRenderer = (
    item: ContractTemplate,
    { handleClick, query, modifiers }: IItemRendererProps
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        key={item.template_id}
        active={modifiers.active}
        text={highlightText(item.template_name, query)}
        onClick={handleClick}
      />
    );
  };

  render() {
    const { onChange, currentValue } = this.props;

    return (
      <Wrapper>
        <ItemSelect
          key={this.items.length}
          items={this.items}
          onItemSelect={onChange}
          itemRenderer={this.itemRenderer}
          itemPredicate={filterItem}
          popoverProps={{ minimal: true }}
        >
          <Button
            rightIcon="caret-down"
            fill={true}
            text={currentValue ? currentValue.template_name : "선택해주세요"}
            alignText={Alignment.LEFT}
            style={{
              textOverflow: "ellipsis"
            }}
          />
        </ItemSelect>
      </Wrapper>
    );
  }
}

export default ContractTemplateSelection;
