import { types } from "mobx-state-tree";
import { ClientUserFormModel, ClientUserForm } from "./ClientUserForm";

export const ClientFormModel = types
  .model("ClientForm", {
    clientId: types.optional(types.string, ""),
    name: types.optional(types.string, ""),
    users: types.array(ClientUserFormModel),
    identityNumber: types.optional(types.string, ""),
    clientType: types.optional(types.string, ""),
    representativeName: types.optional(types.string, ""),
    address: types.optional(types.string, ""),
    contractPersonName: types.optional(types.string, ""),
    contractPersonEmail: types.optional(types.string, ""),
    contractPersonPhone: types.optional(types.string, ""),
    taxPersonName: types.optional(types.string, ""),
    taxPersonEmail: types.optional(types.string, ""),
    taxPersonPhone: types.optional(types.string, ""),
    hasSameContractTaxPerson: types.optional(types.boolean, false),
  }).actions(self => ({
    setClientId(value: string) {
      self.clientId = value;
    },
    setName(value: string) {
      self.name = value;
    },
    setIdentityNumber(value: string) {
      self.identityNumber = value;
    },
    setClientType(value: string) {
      self.clientType = value;
    },
    setRepresentativeName(value: string) {
      self.representativeName = value;
    },
    setAddress(value: string) {
      self.address = value;
    },
    setContractPersonName(value: string) {
      self.contractPersonName = value;
      if(self.hasSameContractTaxPerson) {
        self.taxPersonName = value;
      }
    },
    setContractPersonEmail(value: string) {
      self.contractPersonEmail = value;
      if(self.hasSameContractTaxPerson) {
        self.taxPersonEmail = value;
      }
    },
    setContractPersonPhone(value: string) {
      self.contractPersonPhone = value;
      if(self.hasSameContractTaxPerson) {
        self.taxPersonPhone = value;
      }
    },
    setTaxPersonName(value: string) {
      self.taxPersonName = value;
    },
    setTaxPersonEmail(value: string) {
      self.taxPersonEmail = value;
    },
    setTaxPersonPhone(value: string) {
      self.taxPersonPhone = value;
    },
    setHasSameContractTaxPerson(value: boolean) {
      self.hasSameContractTaxPerson = value;
      if(self.hasSameContractTaxPerson) {
        self.taxPersonName = self.contractPersonName;
        self.taxPersonEmail = self.contractPersonEmail;
        self.taxPersonPhone = self.contractPersonPhone;
      }
    },
    addUser() {
      const newClientUser = ClientUserFormModel.create();
      self.users.push(newClientUser);
    },
    removeUser(clientUserForm: ClientUserForm) {
      self.users.remove(clientUserForm);
    }
  }));

type ClientFormType = typeof ClientFormModel.Type;
export interface ClientForm extends ClientFormType {}
