import { flow, types } from "mobx-state-tree";
import { ClientRoleModel } from "./ClientRole";
import { RufreeRoleModel, RufreeRole } from "./RufreeRole";
import { SeesoServiceModel, SeesoService } from "./SeesoService";
import axios from "axios";
import { InspectionMailPreviewResponse } from "../../types/inspection";
import { InspectionMailModel } from "./InspectionMail";
import { AppToaster } from "../../components/organisms/AppToaster/AppToaster";
import { Intent } from "@blueprintjs/core";
import { InspectionFileModel } from "../forms/ReferenceLinkModel";
import moment from "moment";


// 스프린트 별 rufree role / seeso service 비용등
export const RufreeSeesoPerSprintModel =
  types.model("RufreeSeesoPerSprint", {
    total: types.integer,
    seeso_total: types.integer,
    seeso_total_vat: types.integer,
    seeso_list: types.array(types.model("SeesoServiceSimple", {
      service: types.string,
      cost: types.integer,
      individual: types.boolean
    })),
    rufree_total: types.integer,
    rufree_total_vat: types.integer,
    rufree_list: types.array(types.model("RufreeRoleSimple", {
      role: types.string,
      wage: types.integer
    })),
    sprint_name: types.string
  });

type RufreeSeesoPerSprintType = typeof RufreeSeesoPerSprintModel.Type;

export interface RufreeSeesoPerSprint extends RufreeSeesoPerSprintType {
}

export const InspectionModel = types
  .model("Inspection", {
    inspectionId: types.identifier,
    client: types.maybeNull(types.string),
    clientName: types.maybeNull(types.string),
    sprintDays: types.array(types.number),
    clientRoles: types.array(ClientRoleModel),
    rufreeRoles: types.array(RufreeRoleModel),
    seesoServices: types.array(SeesoServiceModel),
    createdAt: types.string,
    updatedAt: types.string,

    title: types.string,
    requesterName: types.string,
    requesterEmail: types.string,

    inspector: types.maybeNull(types.number),
    inspectorName: types.maybeNull(types.string),
    inspectorEmail: types.maybeNull(types.string),

    references: types.array(
      types.model("InspectionReference", {
        link: types.string,
        comment: types.string
      })
    ),
    inspection_files: types.array(InspectionFileModel),
    dateStart: types.maybeNull(types.string),
    dateEnd: types.maybeNull(types.string),
    vatIncluded: types.boolean,
    budget: types.number,
    review: types.string,
    attention: types.maybeNull(types.string),
    receptions: types.array(types.number),
    reception_date_register: types.maybeNull(types.string),
    consults: types.array(types.string),
    publishedDate: types.maybeNull(types.string),
    historyUrl: types.maybeNull(types.string),
    projectgroupIds: types.array(
      types.model("ProjectgroupIds", {
        group_id: types.string,
        name: types.string
      })
    ),
    historyList: types.array(
      types.model("InspectionHistoryList", {
        "history_id": types.integer,
        "history_change_reason": types.maybeNull(types.string),
        "budget": types.number,
        "published_date": types.maybeNull(types.string),
        "history_date":types.maybeNull(types.string),
        "history_user_id":types.maybeNull(types.integer),
        "history_user":types.maybeNull(types.string),
        "pdf_link":types.maybeNull(types.string),
      })
    ),
    rufree_seeso_per_sprint: types.map(RufreeSeesoPerSprintModel)
  })
  .views(self => ({
    get nameWithId() {
      return `${self.inspectionId} - ${self.title}`;
    },
    get rufreeNumberInEachSprint() {
      const eachSprint: number[] = Array(self.sprintDays.length).fill(0);

      self.rufreeRoles.forEach(rufreeRole => {
        rufreeRole.sprints.forEach((sprint, sprintIndex) => {
          if (sprint) {
            eachSprint[sprintIndex] += 1;
          }
        });
      });

      return eachSprint;
    },
    get days() {
      if(self.dateStart && self.dateEnd) {
        return moment(self.dateEnd).diff(moment(self.dateStart), "day")
      }
      return 0;
    }
  }))
  .views(self => ({
    rufreeRoleTotalWage(rufreeRole: RufreeRole) {
      let totalWage = 0;

      rufreeRole.sprints.forEach((sprint, sprintIndex) => {
        if (sprint) {
          const sprintDays = self.sprintDays[sprintIndex];

          totalWage += rufreeRole.wageWithTaxBySprintDaysRate({
            sprintIndex,
            sprintDays
          });
        }
      });

      return totalWage;
    },
    seesoServiceTotalCost(seesoService: SeesoService) {
      let totalCost = 0;

      seesoService.sprints.forEach((sprint, sprintIndex) => {
        const sprintDays = self.sprintDays[sprintIndex];

        if (sprint) {
          if (seesoService.individual) {
            const costWithTaxBySprintDaysRate = seesoService.costWithTaxBySprintDaysRate(
              {
                sprintIndex,
                sprintDays
              }
            );
            const rufreeNumber = self.rufreeNumberInEachSprint[sprintIndex];

            totalCost += costWithTaxBySprintDaysRate * rufreeNumber;
          } else {
            const costWithTaxBySprintDaysRate = seesoService.costWithTaxBySprintDaysRate(
              {
                sprintIndex,
                sprintDays
              }
            );

            totalCost += costWithTaxBySprintDaysRate;
          }
        }
      });

      return totalCost;
    },
    seesoServiceOfSprint(sprintIndex: number) {
      const BASE_DAYS = 14;
      let services: SeesoService[] = [];

      self.seesoServices.forEach(service => {
        if(service.sprints[sprintIndex]) {
          const sprintDays = sprintIndex > 0 ? self.sprintDays[sprintIndex] : 14;
          services.push(Object.assign(
            {},
            service,
            {'cost': service.cost * (sprintDays / BASE_DAYS)}
          ));
        }
      })

      return services;
    }
  }))
  .views(self => ({
    getTotalCostOfSprint(sprintIndex: number) {
      let cost = 0;
      const sprintDays = self.sprintDays[sprintIndex];

      self.rufreeRoles
        .filter(role => role.sprints[sprintIndex])
        .forEach(rufreeRole => {
          cost += rufreeRole.wageWithTaxBySprintDaysRate({
            sprintIndex,
            sprintDays
          });
        });

      self.seesoServices
        .filter(service => service.sprints[sprintIndex])
        .forEach(seesoService => {
          if (seesoService.individual) {
            const costWithTaxBySprintDaysRate = seesoService.costWithTaxBySprintDaysRate(
              {
                sprintIndex,
                sprintDays
              }
            );
            const rufreeNumber = self.rufreeNumberInEachSprint[sprintIndex];

            cost += costWithTaxBySprintDaysRate * rufreeNumber;
          } else {
            const costWithTaxBySprintDaysRate = seesoService.costWithTaxBySprintDaysRate(
              {
                sprintIndex,
                sprintDays
              }
            );

            cost += costWithTaxBySprintDaysRate;
          }
        });

      return cost;
    },
    get totalCost() {
      let cost = 0;

      cost += self.rufreeRoles.reduce(
        (sum, rufreeRole) => sum + self.rufreeRoleTotalWage(rufreeRole),
        0
      );
      cost += self.seesoServices.reduce(
        (sum, seesoService) => sum + self.seesoServiceTotalCost(seesoService),
        0
      );

      return cost;
    }
  }))
  .actions(self => ({
    setPublishedDate(value: string | null) {
      self.publishedDate = value;
    },
    setVatIncluded(value: boolean) {
      self.vatIncluded = value;
    }

  })).actions(self =>{
    const preview_mail = flow(function* () {
      try {

        const { data }: { data: InspectionMailPreviewResponse } = yield axios.get(`/inspections/${self.inspectionId}/previewMail`);

        return InspectionMailModel.create({
          html: data.html,
          subject: data.subject
        });
      } catch (e) {
        console.log("previewMailInspection error", e);
        throw e;
      }
    });

    const sendComposedMailInspection = flow(function* (
                                                       subject: string, mail: string, to: string[],
                                                       cc: string[],
                                                       bcc: string[]) {
      try {

        const { status } = yield axios.post(`/inspections/${self.inspectionId}/send_composed_mail_and_publish`, {
          subject:subject,
          mail:mail,
          to:to,
          cc:cc,
          bcc:bcc,
        });
        if (status == 204)
          AppToaster.show({
            message: "발송 성공.",
            intent: Intent.SUCCESS
          });
        else
          AppToaster.show({
            message: "발송 실패.",
            intent: Intent.SUCCESS
          });

      } catch (e) {
        console.log("sendMailInspection error", e);
        throw e;
      }
    });
    return{
      preview_mail,
      sendComposedMailInspection
    }
  });

type InspectionType = typeof InspectionModel.Type;
export interface Inspection extends InspectionType {
  // inspection_files: any;
}


export const InspectionSimpleModel = types.model("InspectionSimple",{
  inspectionId: types.identifier,
  title: types.string,
  nameWithId: types.string
})
type InspectionSimpleType = typeof InspectionSimpleModel.Type;
export interface InspectionSimple extends InspectionSimpleType {}
