import React, { Component } from "react";
import styled from "styled-components/macro";
import { extendObservableObjectWithProperties } from "mobx/lib/internal";

const Text = styled.div`
  white-space: pre-wrap;
`;
const TextWithLink = styled(Text)`
  word-break: break-all;
`;

export class ConvertLinkTextFormat extends Component {
  handleTextWithLink = (formText: any) => {
    try {
      var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
      const converText = formText.replace(exp,"<a href='$1' target='_blank'>$1</a>");     
      return (
        <div dangerouslySetInnerHTML={ {__html: converText} }></div>
      )
    } catch {
      return formText;
    }
    
  }

  render() {
    return <TextWithLink>{this.handleTextWithLink(this.props.children)}</TextWithLink>;
  }
}

export default ConvertLinkTextFormat;