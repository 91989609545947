import { types } from "mobx-state-tree";
import { ProjectModel } from "./Project";
import { ConsultFunctionalityModel } from "./ConsultFunctionality";
import { ConsultAdminTypeModel } from "./ConsultAdminType";

export const ConsultModel = types.model("Consult", {
  id: types.identifierNumber,
  createdAt: types.string,
  updatedAt: types.string,
  // projectTypes
  webType: types.enumeration("WebType", ["분리형", "반응형"]),
  appTypes: types.array(types.string),
  functionalities: types.array(ConsultFunctionalityModel),
  adminTypes: types.array(ConsultAdminTypeModel),
  memo: types.string,
  project: types.reference(ProjectModel)
});

type ConsultType = typeof ConsultModel.Type;
export interface Consult extends ConsultType {}
