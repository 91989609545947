import React, { Component, ChangeEvent } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { Inspection } from "../../../store/models/Inspection";
import moment from "moment";
import { Item, Column } from "../../atoms/CardTable/CardTable";

const PublishedState = styled.span<{ isPublished: boolean }>`
  color: ${p => (p.isPublished ? "#0adf8a" : "#ff4343")};
`;

interface InspectionRowProps {
  inspection: Inspection;
  onClick: () => void;
}

interface InjectedProps extends InspectionRowProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class InspectionRow extends Component<InspectionRowProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  render() {
    const { inspection, onClick } = this.props;
    const client = inspection.client
      ? this.injected.appStore.clientStore.findById(inspection.client)
      : null;

    return (
      <Item onClick={onClick}>
        <Column style={{ maxWidth: "141px" }}>{inspection.inspectionId}</Column>
        <Column style={{ maxWidth: "287px" }}>{inspection.title}</Column>
        <Column style={{ maxWidth: "292px" }}>
          {client ? client.name : "찾을수없음"}
        </Column>
        <Column style={{ maxWidth: "168px" }}>
          {moment(inspection.updatedAt).format("YYYY년 MM월 DD일")}
        </Column>
        <Column style={{ maxWidth: "166px" }}>
          {inspection.publishedDate
            ? moment(inspection.publishedDate).format("YYYY년 MM월 DD일")
            : ""}
        </Column>
        <Column style={{ maxWidth: "166px" }}>
          {inspection.reception_date_register
            ? moment(inspection.reception_date_register).format("YYYY년 MM월 DD일")
            // ?inspection.reception_date_register
            : ""}
        </Column>
        <Column style={{ maxWidth: "112px", whiteSpace: "nowrap" }}>
          <PublishedState isPublished={!!inspection.publishedDate}>
            {inspection.publishedDate ? "발행완료" : "미발행"}
          </PublishedState>
        </Column>
      </Item>
    );
  }
}

export default InspectionRow;
