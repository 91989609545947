import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { ProjectGroup } from "../../../store/models/ProjectGroup";
import moment from "moment";
import { computed } from "mobx";
import { Item, Column } from "../../atoms/CardTable/CardTable";
import ProjectGroupStatus from "../../molecules/ProjectGroupStatus/ProjectGroupStatus";
import ProjectGroupProgressBar from "../../molecules/ProjectGroupProgressBar/ProjectGroupProgressBar";

interface ProjectGroupRowProps {
  projectGroup: ProjectGroup;
  onClick: () => void;
}

interface InjectedProps extends ProjectGroupRowProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ProjectGroupRow extends Component<ProjectGroupRowProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get dates() {
    const { groupId, schedule } = this.props.projectGroup;

    let totalCount = 0;
    let doneCount = 0;
    let onGoingCount = 0;
    let tooltip: any = <></>;

    if (schedule) {
      const { sprintsCount, dateStart, dateEnd } = schedule;
      const { total_count, ongoing_count, done_count } = sprintsCount;
      totalCount = total_count;
      doneCount = done_count;
      onGoingCount = ongoing_count;

      const start = dateStart ? moment(dateStart).format("YYYY-MM-DD") : "";
      const end = dateEnd ? moment(dateEnd).format("YYYY-MM-DD") : "";

      if(totalCount > 0) {
        tooltip = <>
          <p>총 {total_count}S의 모든 스프린트가 완료되었습니다.</p>
          <p>(운영일정: {start}~{end})</p>
        </>;

        if(doneCount === 0 && ongoing_count === 0) {
          tooltip = <>
            <p>미진행 프로젝트 입니다. (예정된 총 스프린트: {total_count}S)</p>
            <p>(운영일정: {start}~{end})</p>
          </>;
        } else {
          tooltip = <>
            <p>총 {total_count}S 중, 현재 {ongoing_count}S 진행중입니다.({done_count}S 완료)</p>
            <p>(운영일정: {start}~{end})</p>
          </>;
        }
      }
    }

    return <ProjectGroupProgressBar
      projectGroupId={groupId}
      doneCount={doneCount}
      onGoingCount={onGoingCount}
      totalCount={totalCount}
      tooltip={<span>{tooltip}</span>} />
  }

  render() {
    const { projectGroup, onClick } = this.props;

    return (
      <Item onClick={onClick}>
        <Column style={{ maxWidth: "104px" }}>{projectGroup.groupId}</Column>
        <Column style={{ maxWidth: "250px" }}>{projectGroup.name}</Column>
        <Column style={{ maxWidth: "270px" }}>
          {projectGroup.clientName}
        </Column>
        <Column style={{ maxWidth: "200px" }}>
          {projectGroup.managers.map(x => x.name).join(", ")}
        </Column>
        <Column style={{ maxWidth: "200px" }}>
          <ProjectGroupStatus status={projectGroup.status} />
        </Column>
        <Column style={{ maxWidth: "283px" }}>{this.dates}</Column>
      </Item>
    );
  }
}

export default ProjectGroupRow;
