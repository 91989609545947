import React, { ChangeEvent, KeyboardEvent, FC, useState } from "react";
import styled from "styled-components/macro";
// import { ChangeEvent } from "cleave.js/react/props";
import {
  Button,
  Colors,
  Label,
  TagInput,
  Switch
} from "@blueprintjs/core";

import * as UserSelection from "../UserSelection/UserSelectionAsync";

const Section = styled.section`
  position: relative;

  .bp3-switch {
    width: 150px !important;
    font-size: 12px;
    color: #000000;
  }
  label.bp3-control {
    margin-top: 0px;
  }

  .email-field {
    width: calc(100% - 300px);
  }

  .bp3-tag-input + .bp3-tag-input {
    margin-top: 5px;
  }

  section > section {
    margin-bottom: 5px;
  }
`;
const Row = styled.section`
  display: flex;

  color: ${ Colors.GRAY3 };

  label {
    width: 60px !important;
  }
`;
const ItemCount = styled.div`
  margin-right: 20px;
  position: absolute;
  right: 0;
  font-size: 12px;
`;

interface IItem {
  name: string,
  value: string
}

interface Props {
  email?: boolean;
  sms?: boolean;
  onlySms?: boolean;
  emails: string[];
  phones: string[];
  handleChange?: (mails: string[], ccMails: string[], bccMails: string[], phones: string[]) => void;
}
const RecipientSelection: FC<Props> = props => {
  const [ emails, setEmails ] = useState<string[]>(props.emails);
  const [ ccEmails, setccEmails ] = useState<string[]>([]);
  const [ bccEmails, setbccEmails ] = useState<string[]>([]);

  const [ showCC, setShowCC ] = useState<boolean>(false);
  const [ showBcc, setShowBcc ] = useState<boolean>(false);

  const [ phones, setPhones ] = useState<string[]>(props.phones);

  const clearEmailButton = (
    <Button
      icon={ emails.length > 1 ? "cross" : null }
      minimal={ true }
      onClick={ () => { setEmails([]); } }
    />
  );
  const clearPhoneButton = (
    <Button
      icon={ emails.length > 1 ? "cross" : null }
      minimal={ true }
      onClick={ () => { setPhones([]); } }
    />
  );

    const _updateHandleChange = (
      emails: string[],
      ccEmails: string[],
      bccEmails: string[],
      phones: string[]
    ) => {
      if(props.handleChange) {
        props.handleChange(
          emails.map(email => email.replace(/(\[.*?\]|\(.*?\)) */g, "")),
          ccEmails,
          bccEmails,
          phones.map(phone => phone.replace(/(\[.*?\]|\(.*?\)) */g, ""))
        )
      }
    }

    return (
      <Section>

        <Row>
          <ItemCount>
            { !props.onlySms && props.email && <> 이메일 {emails.length}명 (참조 {ccEmails.length}명, 숨은참조 {bccEmails.length}명)</> }
            { props.sms && <> 문자 { phones.length }명</> }
          </ItemCount>

          {
            <UserSelection.UserSelectionAsync
              onChange={ (item: UserSelection.IItem) => {
                !props.onlySms && item.email && setEmails(emails.concat([`${item.email}(${item.name})`]));
                item.phone && setPhones(phones.concat([`${item.phone}(${item.name})`]));

                _updateHandleChange(emails.concat([item.email]), ccEmails, bccEmails, phones.concat([item.phone]));
              } } />
          }

          {
            (!props.onlySms && props.email) &&
              <>
                <Switch
                  checked={showCC}
                  labelElement={"참조"}
                  style={{
                    position: 'absolute',
                    left: '130px'
                  }}
                  onChange={() => {
                    setShowCC(!showCC);
                  }} />

                <Switch
                  checked={showBcc}
                  labelElement={"숨은참조"}
                  style={{
                    position: 'absolute',
                    left: '200px'
                  }}
                  onChange={() => {
                    setShowBcc(!showBcc);
                  }} />
              </>
            }
        </Row>

        <div>
        {
          ( props.sms || props.onlySms ) &&
            <TagInput
              leftIcon={'mobile-phone'}
              onChange={(values: any) => {
                setPhones(values);
                _updateHandleChange(emails, ccEmails, bccEmails, values);
              }}
              placeholder="받는 사람에 추가 할 휴대전화 번호를 입력하세요."
              rightElement={ clearPhoneButton }
              values={ phones }
              fill={ true } />
        }
        {
          (!props.onlySms && props.email) &&
          <>
            <TagInput
                leftIcon={'envelope'}
                onChange={(values: any) => {
                  setEmails(values);
                  _updateHandleChange(values, ccEmails, bccEmails, phones);
                }}
                placeholder="받는 사람에 추가 할 이메일 주소를 입력하세요."
                rightElement={ clearEmailButton }
                values={ emails }
                fill={ true }
            />

            {
              showCC &&
                <Row
                  style={{ marginTop: '3px'}}
                >
                  <Label>참조</Label>
                  <TagInput
                    leftIcon={'envelope'}
                    onChange={(values: any) => {
                      setccEmails(values);
                      _updateHandleChange(emails, values, bccEmails, phones);
                    }}
                    placeholder="참조로 추가 할 이메일 주소를 입력하세요."
                    rightElement={ clearEmailButton }
                    values={ ccEmails }
                    fill={ true }
                  />
                </Row>
            }
            
            {
              showBcc &&
                <Row
                  style={{ marginTop: '3px'}}
                >
                  <Label>숨은 참조</Label>
                  <TagInput
                    leftIcon={'envelope'}
                    onChange={(values: any) => {
                      setbccEmails(values);
                      _updateHandleChange(emails, ccEmails, values, phones);
                    }}
                    placeholder="숨은 참조로 추가 할 이메일 주소를 입력하세요."
                    rightElement={ clearEmailButton }
                    values={ bccEmails }
                    fill={ true }
                  />
                </Row>
            }
            
          </>
        }
      </div>

      
        
      </Section>
    );
}

export default RecipientSelection;
