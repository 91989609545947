import React, { Component, ChangeEvent } from "react";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { Client } from "../../../store/models/Client";
import { Colors, InputGroup, Intent } from "@blueprintjs/core";
import { observable, computed } from "mobx";
import { Redirect } from "react-router";

interface ClientRowProps {
  client: Client;
}

interface InjectedProps extends ClientRowProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ClientRow extends Component<ClientRowProps> {
  @observable isEditing = false;
  @observable nameToChange = "";
  @observable isClicked = false;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get calculate() {
    return this.injected.appStore.calculateStore.calculateByClient.find(calculate => calculate.clientId === this.props.client.clientId);
  }

  handleEditClick = () => {
    this.nameToChange = this.props.client.name;
    this.isEditing = true;
  };

  handleSubmitEdit = async () => {
    try {
      const { client } = this.props;
      const { clientStore } = this.injected.appStore;

      await clientStore.patchClient({
        clientId: client.clientId,
        name: this.nameToChange
      });

      client.setName(this.nameToChange);
      this.isEditing = false;
    } catch (e) {}
  };

  handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.nameToChange = e.target.value;
  };

  handleClick = () => {
    this.isClicked = true;
  };

  render() {
    const { client } = this.props;

    if (this.isClicked) {
      return <Redirect to={`/clients/${client.clientId}`} push={true} />;
    }

    return (
      <tr onClick={this.handleClick}>
        <td>{client.clientId}</td>
        <td>
          {this.isEditing ? (
            <InputGroup
              placeholder="클라이언트명"
              value={this.nameToChange}
              onChange={this.handleNameChange}
            />
          ) : (
            client.name
          )}
        </td>
        <td>
          {
            this.calculate &&
              <span style={ this.calculate.prePaid < 0 ? { color: Colors.RED3} : {} }>
                {`${this.calculate.prePaid.toLocaleString("en-US")}원`}
              </span>
          }
        </td>
      </tr>
    );
  }
}

export default ClientRow;
