import { types, flow, isAlive } from "mobx-state-tree";
import moment from "moment";
import { ReceptionResponse } from "../../types/reception";
import axios from "axios";
import { ProjectModel } from "./Project";

export enum ReceptionStatus {
  Pending = "pending",
  Accepted = "accepted",
  Rejected = "rejected"
}

export const ReceptionModel = types
  .model("Reception", {
    id: types.identifierNumber,
    receptionNumber: types.maybeNull(types.string),
    receptionId: types.maybeNull(types.string),
    title: types.string,
    areaType: types.array(types.string),
    areaTypeEtcText: types.string,
    area: types.array(types.string),
    areaEtcText: types.string,
    dataPrepared: types.array(types.string),
    etcReference: types.array(types.string),
    existReference: types.maybeNull(types.boolean),
    referenceText: types.string,
    priority: types.string,
    feature: types.array(types.string),
    detail: types.string,
    googleDriveLink: types.maybeNull(types.string),
    driveLinks: types.array(types.string),
    dateMeeting: types.string,
    dateRegister: types.string,
    status: types.string,
    dataFile: types.maybeNull(types.string),
    clientUuid: types.maybeNull(types.string),
    memo: types.string,
    client: types.maybeNull(types.string),
    clientName: types.maybeNull(types.string),
    manager: types.maybeNull(types.number),
    inspector: types.maybeNull(types.number),
    inspectorName: types.maybeNull(types.string),
    clientUser: types.maybeNull(types.number),
    // projectId: types.maybeNull(types.string),
    consults: types.array(ProjectModel),
    inspectionId: types.maybeNull(types.string),
    // inspectionIds: types.array(types.string),

    inspection: types.maybeNull(types.model("InspectionMini",{
      inspection_id:types.maybeNull(types.string),
      title:types.maybeNull(types.string),
      updated_at:types.maybeNull(types.string),
    })),
    serviceType: types.maybeNull(types.string),
    consultsId: types.string
  })
  .views(self => ({
    get nameWithId() {
      return `${self.receptionNumber} - ${self.title} (${moment(self.dateRegister).format("YYYY년MM월DD일")})`;
    },
    get dateRegisterString() {
      return moment(self.dateRegister).format("YYYY/MM/DD");
    },
    get dateMeetingString() {
      return moment(self.dateMeeting).format("YYYY년 M월 D일 hh:mmA");
    }
  }))
  .actions(self => {
    const acceptReception = flow(function*() {
      try {
        const { data } = yield axios.get(`/reception/${self.id}/accept`);

        if (data.error) {
          throw {
            response: {
              data
            }
          };
        }

        if (isAlive(self)) {
          self.status = data.status;
        }
      } catch (e) {
        console.error("acceptReception error", e);
        throw e;
      }
    });

    const rejectReception = flow(function*() {
      try {
        const { data } = yield axios.get(`/reception/${self.id}/reject`);

        if (data.error) {
          throw {
            response: {
              data
            }
          };
        }

        if (isAlive(self)) {
          self.status = data.status;
        }
      } catch (e) {
        console.error("rejectReception error", e);
        throw e;
      }
    });

    const changeInspector = flow(function*(inspectorId: number) {
      try {
        const { data } = yield axios.patch(`/reception/${self.id}`, {
          inspector: inspectorId === -1 ? null : inspectorId
        });

        if (isAlive(self)) {
          self.inspector = data.inspector;
        }
      } catch (e) {
        console.error("changeInspector error", e);
        throw e;
      }
    });

    return {
      acceptReception,
      rejectReception,
      changeInspector
    };
  });

type ReceptionType = typeof ReceptionModel.Type;
export interface Reception extends ReceptionType {}
