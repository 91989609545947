import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { RufreeMatching } from "../../../store/models/RufreeMatching";
import {
  LargeText,
  DividerLine
} from "../../atoms/DetailReportComponents/DetailReportComponents";
import { RufreeMatchingForm } from "../../../store/forms/RufreeMatchingForm/RufreeMatchingForm";
import { ConvertLinkTextFormat }  from "../../atoms/ConvertLinkTextFormat/ConvertLinkTextFormat";

const Wrapper = styled.div`
  padding: 40px 30px;
  border: 1px solid #c8c8c8;
  border-radius: 4px;
  width: 400px;
  background-color: white;
`;

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`;

const NoticeLabel = styled(Label)`
  color: #e89905;
`;

const Role = styled(Label)`
  color: #2d41f7;
`;

const Row = styled.div`
  margin-bottom: 20px;
`;

const InlineRow = styled.div`
  display: flex;
`;

const Text = styled.div`
  white-space: pre-wrap;
`;

const RightText = styled.div`
  text-align: right;
  margin-left: auto;
  font-weight: bold;
`;

const Notice = styled.div`
  color: #e89905;
  font-size: 13px;
  font-weight: bold;
  text-align: right;
  margin-bottom: 20px;
`;

const TextWithLink = styled(Text)`
  word-break: break-all;
`;

interface MatchingPreviewProps {
  matching: RufreeMatchingForm | RufreeMatching;
}

interface InjectedProps extends MatchingPreviewProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class MatchingPreview extends Component<MatchingPreviewProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  

  render() {
    const { matching } = this.props;

    return (
      <>
      <Wrapper>
        <Row>
          <Label>미리보기</Label>
        </Row>
        <Row>
          <Role>{matching.role}</Role>
          <LargeText>{matching.projectGroupName}</LargeText>
        </Row>
        <Row>
          <NoticeLabel>시소코멘트</NoticeLabel>
          <Text>{matching.matchingManagerComment}</Text>
        </Row>
        <Row>
          <Label>프로젝트 요약</Label>
          <DividerLine />
          <InlineRow>
            <Label>작업기간</Label>
            <RightText>{matching.sprintNum} 스프린트</RightText>
          </InlineRow>
          <Notice>한 스프린트는 주당 20시간 x 2주, 필요시 최대 3주</Notice>
          <InlineRow>
            <Label>금액</Label>
            <RightText>
              {(
                matching.sprintWage * matching.sprintNum
              ).toLocaleString("en-US")}{" "}
              원
            </RightText>
          </InlineRow>
          <Notice>
            {(matching.sprintWage).toLocaleString("en-US")}{" "}
            원/스프린트
          </Notice>

          <DividerLine />
        </Row>

        <Row>
          <NoticeLabel>작업범위</NoticeLabel>
          <Text>{matching.roleDetail}</Text>
        </Row>

        <Row>
          <NoticeLabel>사전공유자료</NoticeLabel>
          <ConvertLinkTextFormat>
            {matching.reference}
          </ConvertLinkTextFormat>
          <TextWithLink></TextWithLink>
        </Row>        
      </Wrapper>
      </>
    );
  }
}

export default MatchingPreview;
