import React from "react";

import { PerPersonFieldWrapper as Wrapper } from './_wrapper';


export interface IPerPersonField {
  isActive?: boolean;
  onClick?: () => void;
};

export const PerPersonField: React.FC<IPerPersonField> = (props) => {
  const classes: string[] = [];
  props.isActive && classes.push('active');

  return (
    <Wrapper
      className={ classes.join(' ') }
      onClick={ props.onClick }
    >
      {
        <div>x인원수</div>
      }
    </Wrapper>
  );
}
