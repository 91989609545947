import React, { Component, ChangeEvent } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { Dialog, Classes, FormGroup, Button, Intent, TextArea } from "@blueprintjs/core";
import { InspectionMail } from "../../../store/models/InspectionMail";
import { computed, observable } from "mobx";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import moment from "moment";
import { Inspection } from "../../../store/models/Inspection";

const Wrapper = styled.div``;

const Text = styled.div`
  white-space: pre-wrap;
`;
const ButtonSection = styled.div`
  button {
    margin-top: 10px;
    margin-right: 5px;
  }
`;

// props 관리
interface InspectionMailSendPopupProps {
  inspectionMail: InspectionMail | null;
  onClose: () => void;
  onSend: () => Promise<void>;
}

//
interface InjectedProps extends InspectionMailSendPopupProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class InspectionMailSendPopup extends Component<InspectionMailSendPopupProps> {
  @observable pmComment = "";

  get injected() {
    return this.props as InjectedProps;
  }

  // handleRufreeHomeBtnClick = () => {
  //   if(this.props.inspectionMail) {
  //     window.open('https://rufree.kr/users?uid='+this.props.rufree.rufreeId, '_blank')
  //   }
  // }

  componentDidUpdate(prevProps: InspectionMailSendPopupProps) {
    // Typical usage (don't forget to compare props):
    // if (prevProps.rufree && this.props.rufree && this.props.rufree.rufreeId !== prevProps.rufree.rufreeId) {
    //   console.log(this.props.rufree.pmComment);
    //   this.pmComment = this.props.rufree.pmComment;
    // }

    console.log("componentDidUpdate");
  }

  render() {
    const { inspectionMail, onClose,onSend } = this.props;

    return (
      <Dialog
        icon="envelope"
        onClose={onClose}
        title="검수보고서 발송하기"
        isOpen={inspectionMail !== null}
        usePortal={true}
        autoFocus={true}
        canEscapeKeyClose={true}
        enforceFocus={true}
        style={{width: 800}}
      >
        <div className={Classes.DIALOG_BODY}>
            {inspectionMail && (
              <>
                <FormGroup label={<strong>제목</strong>}>
                  {inspectionMail.subject}
                </FormGroup>

                <FormGroup label={<strong>내용</strong>}>
                  <div className="content" dangerouslySetInnerHTML={{__html: inspectionMail.html}}></div>
                </FormGroup>
              </>
            )}
          <ButtonSection>
            <Button intent={Intent.PRIMARY} text="보내기" onClick={onSend}/>
            <Button text="취소" onClick={onClose}/>
          </ButtonSection>
        </div>
        {/*<div className={Classes.DIALOG_BODY}>
              {rufree && (
              <>
              <FormGroup label={<strong>알유프리번호</strong>}>
              {rufree.rufreeId}
              </FormGroup>
              <FormGroup label={<strong>가입일</strong>}>
              {moment(rufree.createdAt).format('YYYY-MM-DD')}
              </FormGroup>
              <FormGroup label={<strong>이름</strong>}>{rufree.name}</FormGroup>
              <FormGroup label={<strong>이메일</strong>}>
              {rufree.email}
              </FormGroup>
              <FormGroup label={<strong>연락처</strong>}>
              {rufree.phone}
              </FormGroup>
              <FormGroup label={<strong>주소</strong>}>
              {rufree.address}
              </FormGroup>
              <FormGroup label={<strong>현재 소속</strong>}>
              {rufree.affiliation}
              </FormGroup>
              <FormGroup label={<strong>주요경력</strong>}>
              {rufree.experiences.map(exp => (
              <FormGroup key={exp.title}>
              <div>{exp.title}</div>
              <div>{exp.workingTime}</div>
              <div style={{ whiteSpace: "pre-line" }}>{exp.detail}</div>
              </FormGroup>
              ))}
              </FormGroup>
              <FormGroup label={<strong>작업가능시간</strong>}>
              {rufree.workType}
              </FormGroup>
              <FormGroup label={<strong>전문분야</strong>}>
              {rufree.roles.map(role => (
              <div key={role}>{role}</div>
              ))}
              <div>{rufree.rolesEtc}</div>
              </FormGroup>
              <FormGroup label={<strong>포트폴리오</strong>}>
              {
              rufree.portfolio.map((portfolio, index) => {
              return portfolio.type === 'link' && (
              <a href={portfolio.link} target="_blank">
              <Button
              text={`포트폴리오 ${index + 1}`}
              small={true}
              intent={Intent.PRIMARY}
              style={{ marginRight: "5px" }}
              />
              </a>
              )
              })
              }
              {
              rufree.portfolio.map((portfolio, index) => {
              return portfolio.type === 'file' && (
              <a href={portfolio.file} target="_blank">
              <Button
              text={`포트폴리오 ${index +
              1}`}
              small={true}
              intent={Intent.PRIMARY}
              style={{ marginRight: "5px" }}
              />
              </a>
              )
              })
              }
              {
              // rufree.portfolioPdfs.map((pdf, index) => (
              //   <a href={pdf.file} target="_blank">
              //     <Button
              //       text={`포트폴리오 ${index +
              //         1 +
              //         rufree!.portfolioLinks.length}`}
              //       small={true}
              //       intent={Intent.PRIMARY}
              //       style={{ marginRight: "5px" }}
              //     />
              //   </a>
              // ))
              }
              </FormGroup>
              <FormGroup label={<strong>메인</strong>}>
              {rufree.mainSkills.map(mainSkill => (
              <div key={mainSkill}>{mainSkill}</div>
              ))}
              </FormGroup>
              <FormGroup label={<strong>서브</strong>}>
              {rufree.subSkills.map(subSkill => (
              <div key={subSkill}>{subSkill}</div>
              ))}
              </FormGroup>
              <FormGroup label={<strong>특기</strong>}>
              <Text>{rufree.expert}</Text>
              </FormGroup>
              <FormGroup label={<strong>선호 시간</strong>}>
              {rufree.workingTime.map(workingTime => (
              <div key={workingTime}>{workingTime}</div>
              ))}
              </FormGroup>
              <FormGroup label={<strong>선호 업무방식</strong>}>
              {rufree.workingStyle}
              </FormGroup>
              <FormGroup label={<strong>추천인 혹은 알게된 경로</strong>}>
              {rufree.recommender}
              </FormGroup>

              <div className={Classes.CARD}>
              <FormGroup label={<strong>PM 코멘트</strong>}>
              <Text>
              {rufree.pmComment}
              </Text>
              </FormGroup>
              </div>

              <ButtonSection>
              <Button intent={Intent.PRIMARY} text="알유프리 유저페이지 바로가기" onClick={this.handleRufreeHomeBtnClick} />
              <Button text="닫기" onClick={onClose} />
              </ButtonSection>
              </>
              )}
              </div>*/}
      </Dialog>
    );
  }
}

export default InspectionMailSendPopup;
