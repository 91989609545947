import React, { Component } from "react";

import { computed, observable } from "mobx";
import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import {
  InputGroup,
  Button,
  HTMLSelect,
  MenuItem,
} from "@blueprintjs/core";
import { MultiSelect, ItemRenderer } from "@blueprintjs/select";
import { DateRangeInput, DateRange } from "@blueprintjs/datetime";
import moment from "moment";

import { AppStore } from "../../../store/AppStore";

const Container = styled.div`
  position: relative;
  width: calc(100% - 320px);
  display: flex;
  justify-content: space-between;
`;
const ResultContainer = styled.div`
  padding-left: 10px;
  padding-top: 1em;
  line-height: 2em;
`;
const ControlContainer = styled.div`
  display: flex;
  margin-left: auto;
  flex-wrap: wrap;
  align-content: flex-end;
`;
const ResetContainer = styled.div`
  position: absolute;
  top: -24px;
  right: 0px;
`;

interface TaskSearchAndFilterViewProps {
}
interface InjectedProps extends TaskSearchAndFilterViewProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class TaskSearchAndFilterView extends Component<TaskSearchAndFilterViewProps> {
  @observable show_only_my_tasks: boolean = false;
  @observable person_in_charges: Array<string> = [];
  @observable sprint_title_list: Array<string> = [];
  @observable label_title_list: Array<string> = [];
  @observable use_duedate: boolean = false;
  @observable duedate:DateRange = [undefined, undefined];

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get store() {
    return this.injected.appStore.taskManagerStore;
  }

  @computed
  get board() {
    return this.store.currentBoard;
  }

  toggleShowOnlyMyTasks = () => {
    this.show_only_my_tasks = !this.show_only_my_tasks;
    if(this.show_only_my_tasks) {
      this.person_in_charges = [];
    }
    this.handleFilterChanged();
  }
  selectPersonInCharges = (item: string) => {
    let idx = this.person_in_charges.indexOf(item);
    if(idx > -1) {
      this.person_in_charges.splice(idx, 1);
    } else {
      this.person_in_charges.push(item);
    }
    this.handleFilterChanged();
  }
  removePersonInChange = (item: string, idx: number) => {
    // 한명씩 취소하는 로직 참고로 보관
    // if(idx > -1) {
    //   this.person_in_charges = 
    //     this.person_in_charges.slice(0, idx).concat(this.person_in_charges.slice(idx+1));
    // }
    this.person_in_charges = [];
    this.handleFilterChanged();
  }

  selectLabelTitleList = (item: string) => {
    let idx = this.label_title_list.indexOf(item);
    if(idx > -1) {
      this.label_title_list.splice(idx, 1);
    } else {
      this.label_title_list.push(item);
    }
    this.handleFilterChanged();
  }
  removeLabelTitleList = (item: string, idx: number) => {
    this.label_title_list = [];
    this.handleFilterChanged();
  }

  selectSprintTitleList = (item: string) => {
    let idx = this.sprint_title_list.indexOf(item);
    if(idx > -1) {
      this.sprint_title_list.splice(idx, 1);
    } else {
      this.sprint_title_list.push(item);
    }
    this.handleFilterChanged();
  }
  removeSprintTitleList = (item: string, idx: number) => {
    this.sprint_title_list = [];
    this.handleFilterChanged();
  } 

  toggleUseDuedate = () => {
    this.use_duedate = !this.use_duedate;
    if(!this.use_duedate) {
      this.duedate = [undefined, undefined];
    }
    this.handleFilterChanged();
  }

  handleFilterChanged = () => {
    this.store.setFilter({
      my_id: this.injected.appStore.userStore.userId,
      show_only_my_tasks: this.show_only_my_tasks,
      person_in_charges: this.person_in_charges.map(name => {
        if(this.board) {
          const found = this.board.members.find(member => member.name === name)
          return found ? found.id : null;
        } else {
          return null;
        }
      }),
      sprint_title_list: this.sprint_title_list,
      label_title_list: this.label_title_list,
      use_duedate: this.use_duedate,
      duedate_start: this.duedate[0] ? moment(this.duedate[0]).format("YYYY-MM-DD") : '',
      duedate_end: this.duedate[1] ? moment(this.duedate[1]).format("YYYY-MM-DD") : ''
    });
  }

  renderPersonInCharge: ItemRenderer<string> = (item, {modifiers, handleClick}) => {
    if(!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        key={item}
        icon={this.person_in_charges.includes(item) ? "tick" : "blank"}
        text={item}
        onClick={handleClick}
      />
    )
  }
  renderPersonInChargeTag = (item: string) => {
    if(this.person_in_charges.indexOf(item) === 0) {
      if(this.person_in_charges.length === 1) {
        return <span>{item}</span>;
      } else {
        return <span>{item} 외 {this.person_in_charges.length-1}</span>
      }
    } else {
      return null;
    }
  }

  renderSprintTitleList: ItemRenderer<string> = (item, {modifiers, handleClick}) => {
    if(!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        key={item}
        icon={this.sprint_title_list.includes(item) ? "tick" : "blank"}
        text={item}
        onClick={handleClick}
      />
    )
  }
  renderSprintTitleListTag = (item: string) => {
    if(this.sprint_title_list.indexOf(item) === 0) {
      if(this.sprint_title_list.length === 1) {
        return <span>{item}</span>;
      } else {
        return <span>{item} 외 {this.sprint_title_list.length-1}</span>
      }
    } else {
      return null;
    }
  }
  renderLabelTitleList: ItemRenderer<string> = (item, {modifiers, handleClick}) => {
    if(!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        key={item}
        icon={this.label_title_list.includes(item) ? "tick" : "blank"}
        text={item}
        onClick={handleClick}
      />
    )
  }
  renderLabelTitleListTag = (item: string) => {
    if(this.label_title_list.indexOf(item) === 0) {
      if(this.label_title_list.length === 1) {
        return <span>{item}</span>;
      } else {
        return <span>{item} 외 {this.label_title_list.length-1}</span>
      }
    } else {
      return null;
    }
  }    

  render() {
    if(!this.board) {
      return <div></div>;
    } else {
      return (
        <Container>
          <ResultContainer>
            {this.store.searchString && this.store.searchCount > 0 ? (
              <div>검색결과 : {this.store.searchCount} 개</div>
            ) : (
              <div>&nbsp;</div>
            )}
          </ResultContainer>
          <ControlContainer>
            <InputGroup
              placeholder="검색"
              value={this.store.searchString}
              onChange={(e: any) => {this.store.setSearchString(e.target.value);}}
              rightElement={
                <Button
                  text={'X'}
                  minimal={true}
                  onClick={() => {this.store.setSearchString("")}}
                />
              }            
            />
            &nbsp;        
            <Button
              text="내 Task만 보기"
              active={this.show_only_my_tasks}
              onClick={this.toggleShowOnlyMyTasks}
            />
            &nbsp;
            <MultiSelect
              placeholder={'담당자'}
              itemRenderer={this.renderPersonInCharge}
              items={this.board.members.map(member => member.name)}
              onItemSelect={this.selectPersonInCharges}
              tagRenderer={this.renderPersonInChargeTag}
              tagInputProps={{
                disabled: this.show_only_my_tasks,
                onRemove: this.removePersonInChange,
                inputProps: {style: {width: this.person_in_charges.length > 0 ? '0px' : '40px'}},
                rightElement: <Button icon='caret-down' minimal={true} />,
                tagProps: {minimal: true}
              }}
              selectedItems={this.person_in_charges}
            />
            &nbsp;
            {this.use_duedate && (
              <DateRangeInput
                formatDate={(date: Date) => moment(date).format("YYYY-MM-DD")}
                parseDate={(str) => new Date(str)}
                onChange={(range) => {this.duedate = range; this.handleFilterChanged();}}
                value={this.duedate}
                allowSingleDayRange={true}
                singleMonthOnly={true}
                selectAllOnFocus={true}
                startInputProps={{style:{width:'100px'}}}
                endInputProps={{style:{width:'100px'}}}
              />
            )}
            <Button
              text="마감일"
              active={this.use_duedate}
              onClick={this.toggleUseDuedate}
            />
            &nbsp;
            <MultiSelect
              placeholder={'라벨'}
              itemRenderer={this.renderLabelTitleList}
              items={this.board.labelTitleList.slice()}
              onItemSelect={this.selectLabelTitleList}
              tagRenderer={this.renderLabelTitleListTag}
              tagInputProps={{
                onRemove: this.removeLabelTitleList,
                inputProps: {style: {width: this.label_title_list.length > 0 ? '0px' : '40px'}},
                rightElement: <Button icon='caret-down' minimal={true} />,
                tagProps: {minimal: true}
              }}
              selectedItems={this.label_title_list}
            />
            &nbsp;
            <MultiSelect
              placeholder={'스프린트'}
              itemRenderer={this.renderSprintTitleList}
              items={this.board.sprintTitleList.slice()}
              onItemSelect={this.selectSprintTitleList}
              tagRenderer={this.renderSprintTitleListTag}
              tagInputProps={{
                onRemove: this.removeSprintTitleList,
                inputProps: {style: {width: this.sprint_title_list.length > 0 ? '0px' : '40px'}},
                rightElement: <Button icon='caret-down' minimal={true} />,
                tagProps: {minimal: true}
              }}
              selectedItems={this.sprint_title_list}
            />
          </ControlContainer>
          { (
              this.person_in_charges.length > 0 ||
              this.sprint_title_list.length > 0 ||
              this.label_title_list.length > 0 ||
              this.use_duedate
            ) && (
              <ResetContainer>
                <Button 
                  text={"필터초기화"}
                  minimal={true}
                  onClick={() => {
                    this.person_in_charges = [];
                    this.sprint_title_list = [];
                    this.label_title_list = [];
                    this.use_duedate = false;
                    this.duedate = [undefined, undefined];
                    this.handleFilterChanged();
                  }}
                />
              </ResetContainer>
          )}
        </Container>
      );
    }
  }
}

export default TaskSearchAndFilterView;