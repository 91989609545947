import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { Dialog, Classes, FormGroup, Button, Intent } from "@blueprintjs/core";
import { Tasktask } from "../../../store/models/Tasktask";
import moment from "moment";

const FormGroupLabel = styled.div`
  font-weight: bold;
  width: 120px;
  line-height: 18px;
`;

interface TasktaskInfoPopupProps {
  tasktask: Tasktask | null;
  onRequestCancel: () => void;
  onClose: () => void;
}

interface InjectedProps extends TasktaskInfoPopupProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class TasktaskInfoPopup extends Component<TasktaskInfoPopupProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  render() {
    const { isPM } = this.injected.appStore.userStore;
    const { tasktask, onClose, onRequestCancel } = this.props;

    return (
      <Dialog
        icon="applications"
        onClose={onClose}
        title={tasktask && `${tasktask.projectGroup} ${tasktask.projectGroupName} 하자보수 요청`}
        isOpen={tasktask !== null}
        usePortal={true}
        autoFocus={true}
        canEscapeKeyClose={true}
        enforceFocus={true}
      >
        <div className={Classes.DIALOG_BODY}>
          {tasktask && (
            <>
              <FormGroup label={<FormGroupLabel>프로젝트그룹</FormGroupLabel>}  inline={true}>
                {tasktask.projectGroup} {tasktask.projectGroupName}
              </FormGroup>
              <FormGroup label={<FormGroupLabel>카테고리</FormGroupLabel>}  inline={true}>
                {tasktask.typeTask}
              </FormGroup>
              <FormGroup label={<FormGroupLabel>접수일</FormGroupLabel>}  inline={true}>
                {tasktask.requestAt && moment(tasktask.requestAt).format('YYYY-MM-DD')}
              </FormGroup>
              <FormGroup label={<FormGroupLabel>상태</FormGroupLabel>}  inline={true}>
                <span style={{color: '#0A6640'}}>{tasktask.status}</span>
              </FormGroup>
              <FormGroup label={<FormGroupLabel>접수내용</FormGroupLabel>}  inline={true}>
                {
                  tasktask.tasks.map(task => (
                    <FormGroup
                      key={task.id}
                      label={<strong>{task.title}</strong>}
                    >
                      {task.comment}
                    </FormGroup>
                    ))
                }
              </FormGroup>
            </>
          )}
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button text="취소" onClick={onClose} />
            {
              isPM && tasktask && tasktask.status === '접수완료' && (
                <Button
                  text="접수취소"
                  intent={Intent.PRIMARY}
                  onClick={onRequestCancel} />
              )
            }
          </div>
        </div>
      </Dialog>
    );
  }
}

export default TasktaskInfoPopup;
