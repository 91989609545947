import { types, flow } from "mobx-state-tree";
import axios from "axios";
import { IInvitation, InvitationModel } from "./models/Invitation";

const PAGE_SIZE = 25;
export const InvitationStoreModel = types
  .model("InvitationStore", {
    invitation: types.maybeNull(InvitationModel),
    invitations: types.array(InvitationModel),

    // For request status
    isFetching: types.optional(types.boolean, false),
    // For paginator
    currentPage: types.optional(types.number, 1),
    pageGroup: types.optional(types.number, 1),
    totalPages: types.optional(types.number, 1),
  })
  .views(self => ({
  }))
  .actions(self => ({
    setCurentPage(value: number) {
      self.currentPage = value;
    },
    setPageGroup(value: number) {
      self.pageGroup = value;
    }
  }))
  .actions(self => {
    const fetchInvitations = flow(function*(
      search: string='',
    ) {
      try {
        self.isFetching = true;
        const { data }: { data: any } = yield axios.get(
          "/admin/invite-code/",
          {
            params: Object.assign({
                search: search,
                page_size: PAGE_SIZE,
                page: self.currentPage
              }
            )
          }
        );

        const invitations = data.results.map((result: any) =>
          InvitationModel.create(result)
        );

        self.totalPages = Math.round(data.count / PAGE_SIZE);
        self.invitations.replace(invitations.reverse());
        self.isFetching = false;
      } catch (e) {
        self.isFetching = false;
        throw e;
      }
    });
    const issueInvitation = flow(function*(from_member: number) {
      try {
        self.isFetching = true;
        const { data }: { data: any } = yield axios.post(
          "/admin/invite-code/",
          {
            from_member
          }
        );
        self.isFetching = false;
      } catch (e) {
        self.isFetching = false;
        throw e;
      }
    });
    // const fetchMember = flow(function*(
    //   code: string
    // ) {
    //   try {
    //     const { data }: { data: IMember } = yield axios.get(
    //       `/admin/members/${code}/`
    //     );

    //     self.member = MemberModel.create(data);
    //   } catch (e) {
    //     throw e;
    //   }
    // });

    return {
      fetchInvitations,
      issueInvitation
    };
  });

type InvitationStoreType = typeof InvitationStoreModel.Type;
export interface IInvitationStore extends InvitationStoreType {}


// export interface ListResponse {
//   count: number;
//   next: number | null;
//   previous: number | null;
//   results: IMember[]
// }