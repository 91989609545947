import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { observable } from "mobx";
import { 
  HTMLTable, 
  Classes, 
  Button, 
  Intent, 
  InputGroup,
  Spinner,
  Switch
} from "@blueprintjs/core";
import { 
  ToolsRow,
  InputContainer,
  VATRow
} from "../../atoms/CalculateTable/CalculateTable";

const Wrapper = styled.section`
  th.price { text-align: center; width: 100px; }
  th.status { width: 80px; }
  td.price { text-align: right; }
  td.status { text-align: center; font-weight: bold; }
  td.complete { color: #137cbd; font-weight: normal; }
  .diff{ color: red; font-size: 12px; }

  .switch-section {
    float: right;
    display: flex;
    label + label {
      margin-left: 5px;
    }
  }
`;

interface ServiceListPanelProps {}

interface InjectedProps extends ServiceListPanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ServiceListPanel extends Component<ServiceListPanelProps> {
  @observable readyToShow = false;
  @observable searchText = "";
  @observable includeVat = true;
  @observable onlyDiff = false;

  get injected() {
    return this.props as InjectedProps;
  }

  get calculateStore() {
    return this.injected.appStore.calculateStore;
  }

  handleSearch = async () => {
    this.readyToShow = false;

    await this.calculateStore.fetchServiceCalculate(this.searchText);

    this.readyToShow = true;
  };
  handleSearchText = (e: any) => {
    this.searchText = e.target.value;
  };

  async componentDidMount() {
    await this.handleSearch();
  }

  render() {
    const { serviceCalculate } = this.calculateStore;

    return (
      <Wrapper>

        <ToolsRow>
          <InputContainer>
            <InputGroup
              placeholder="프로젝트 그룹/계약번호/클라이언트 명"
              value={this.searchText}
              onChange={this.handleSearchText}
            />
          </InputContainer>

          <Button
            icon="search"
            text="검색"
            intent={Intent.PRIMARY}
            onClick={this.handleSearch}
          />

        </ToolsRow>

        <VATRow className="bp3-text-small switch-section">
          <Switch
            checked={this.onlyDiff}
            labelElement={"차액 존재 아이템만 보기"}
            onChange={() => {
              this.onlyDiff = !this.onlyDiff;
            }} />

          <Switch
            checked={this.includeVat}
            labelElement={"VAT 포함"}
            onChange={() => {
              this.includeVat = !this.includeVat;
            }} />
        </VATRow>
        <HTMLTable
          className={Classes.SMALL}
          striped={true}
          interactive={true}
          style={{ width: "100%", tableLayout: "fixed" }}
        >
          <thead>
            <tr>
              <th>프로젝트그룹</th>
              <th>클라이언트계약</th>
              <th>클라이언트명</th>
              <th className="price">예상 총 비용</th>
              <th className="price">누적 비용</th>
              <th className="price">차액</th>
              <th className="status">상태</th>
            </tr>
          </thead>
          <tbody>

          {
            this.readyToShow ? (
              serviceCalculate
                .map((calculate, index) => {
                  const {
                    projectGroupName,
                    projectGroupId,
                    contractId,
                    clientName,
                    service,
                    cumulative,
                    status
                  } = calculate;
                  const diff = service - cumulative;

                  return ( !this.onlyDiff || ( this.onlyDiff && diff > 0 ) ) && (
                    <tr key={index}>
                      <td>
                        <Link to={`/project-groups/${projectGroupId}`} target="_blank">
                          {projectGroupName}
                        </Link>
                      </td>
                      <td>
                        <Link to={`/contract/client/${contractId}/read`} target="_blank">
                          {contractId}
                        </Link>
                      </td>
                      <td>{clientName}</td>
                      <td className="price">
                        {
                          this.includeVat ?
                            (service * 1.1).toLocaleString("en-US")
                            : service.toLocaleString("en-US")
                        }
                      </td>
                      <td className="price">
                        {
                          this.includeVat ?
                            (cumulative * 1.1).toLocaleString("en-US")
                            : cumulative.toLocaleString("en-US")
                        }
                      </td>
                      <td className="price diff">
                        {
                          diff ?
                            this.includeVat ?
                              (diff * 1.1).toLocaleString("en-US")
                                : diff.toLocaleString("en-US")
                              : ''
                        }
                      </td>
                      <td className={`status ${status === '종료' ? 'complete': ''}`}>
                        {status}
                      </td>
                    </tr>
                  )
                })
            ) : (
              <tr>
                <td colSpan={6}>
                  <Spinner />
                </td>
              </tr>
            )
          }

          </tbody>
        </HTMLTable>

      </Wrapper>
    );
  }
}

export default ServiceListPanel;
