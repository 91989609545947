import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import styled from "styled-components/macro";
import { TaskOutcomeForm } from "../../../store/forms/ParttimeForm/TaskOutcomeForm";
import { Button, Intent } from "@blueprintjs/core";

interface OutcomeFormProps {
    file: TaskOutcomeForm;
    onRemove: (file: TaskOutcomeForm) => void;
}

interface InjectedProps extends OutcomeFormProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class TaskOutcomeFormRow extends Component<OutcomeFormProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  handleRemoveFile = () => {
      const { file, onRemove } = this.props;
      onRemove(file);
  };

  render(){
    const { file } = this.props;
    
    return (
        <InputRow>
            <Button
                icon="minus"
                minimal={true}
                small={true}
                intent={Intent.DANGER}
                onClick={this.handleRemoveFile}
            />
            <FileRow>{file.name}</FileRow>
        </InputRow>
)};
}

export default TaskOutcomeFormRow;

const InputRow = styled.div`
  display: flex;
  padding: 7px 0 0 3px;

  & > * + * {
    margin-left: 5px;
  }
`;

const FileRow = styled.div``;