import React, { Component, ChangeEvent } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import {
  Button,
  Intent,
  InputGroup,
  HTMLTable,
  Spinner
} from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import { getMomentFormatter } from "../../../utils/date";
import moment from "moment";
import { RouteComponentProps } from "react-router";
import { computed, observable } from "mobx";
import Paginator from "../../molecules/Paginator/Paginator";
import PMReportRow from "../../molecules/PMReportRow/PMReportRow";

const Wrapper = styled.div``;

const ButtonsRow = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
`;

const ToolsRow = styled.div`
  margin-bottom: 40px;
  display: flex;
  height: 20px;

  & > * + * {
    margin-left: 10px;
  }
`;

const InputContainer = styled.div`
  max-width: 120px;
  margin-bottom: 30px;
`;

const StyledTable = styled(HTMLTable)`
  width: 100%;
  margin-bottom: 40px;
`;

interface PMReportListPageProps extends RouteComponentProps {}

interface InjectedProps extends PMReportListPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class PMReportListPage extends Component<PMReportListPageProps> {
  @observable readyToShow = false;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get reportStore() {
    return this.injected.appStore.pmReportStore;
  }

  async componentDidMount() {
    this.reportStore.setDateStart('');
    this.reportStore.setDateEnd('');
    this.reportStore.setPmName('');
    this.reportStore.setText('');
    
    await this.handleSearch();
  }

  handleCreateButtonClick = () => {
    this.props.history.push("/pm-reports/new");
  };

  handleDateStartChange = (selectedDate: Date) => {
    this.reportStore.setDateStart(selectedDate ? moment(selectedDate).format("YYYY-MM-DD") : "");
  };

  handleDateEndChange = (selectedDate: Date) => {
    this.reportStore.setDateEnd(selectedDate ? moment(selectedDate).format("YYYY-MM-DD") : "");
  };

  handlePmNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.reportStore.setPmName(e.target.value);
  };

  handleGroupIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.reportStore.setGroupId(e.target.value);
  };

  handleProjectNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.reportStore.setProjectName(e.target.value);
  };

  handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.reportStore.setText(e.target.value);
  };

  handleSearch = async () => {
    this.readyToShow = false;

    try {
      await this.reportStore.searchReports();
    } catch (e) {}

    this.readyToShow = true;
  };

  handlePmNameClick = (pmName: string) => {
    this.reportStore.clear();
    this.reportStore.setPmName(pmName);
    this.handleSearch();
  };

  handleGroupIdClick = (groupId: string) => {
    this.reportStore.clear();
    this.reportStore.setGroupId(groupId);
    this.handleSearch();
  };

  render() {
    const { isPM } = this.injected.appStore.userStore;
    let content = [];

    if (this.readyToShow) {
      content.push(
        this.reportStore.sortedReports.map(report => (
          <PMReportRow
            key={report.id}
            report={report}
            onPmNameClick={this.handlePmNameClick}
            onGroupIdClick={this.handleGroupIdClick}
          />
        ))
      );
    } else {
      content.push(
        <tr key="emptyrow">
          <td colSpan={8}><Spinner /></td>
        </tr>
      );
    }

    return (
      <Wrapper>
        <ButtonsRow>
          {isPM && (
            <>
              <Button
                icon="add"
                text="일지 작성"
                intent={Intent.PRIMARY}
                onClick={this.handleCreateButtonClick}
              />
            </>
          )}
        </ButtonsRow>
        <ToolsRow>
          <InputContainer>
            <DateInput
              {...getMomentFormatter("YYYY-MM-DD")}
              locale="ko"
              closeOnSelection={true}
              value={this.reportStore.dateStart ? moment(this.reportStore.dateStart).toDate() : undefined}
              onChange={this.handleDateStartChange}
              maxDate={new Date("2050-01-01")}
            />
          </InputContainer>
          <InputContainer> ~ </InputContainer>
          <InputContainer>
            <DateInput
              {...getMomentFormatter("YYYY-MM-DD")}
              locale="ko"
              closeOnSelection={true}
              value={this.reportStore.dateEnd ? moment(this.reportStore.dateEnd).toDate() : undefined}
              onChange={this.handleDateEndChange}
              maxDate={new Date("2050-01-01")}
            />
          </InputContainer>
          <InputContainer>
            <InputGroup
              placeholder="매니저명"
              value={this.reportStore.pmName}
              onChange={this.handlePmNameChange}
            />
          </InputContainer>
          <InputContainer>
            <InputGroup
              placeholder="그룹번호"
              value={this.reportStore.groupId}
              onChange={this.handleGroupIdChange}
            />
          </InputContainer>
          <InputContainer>
            <InputGroup
              placeholder="프로젝트명"
              value={this.reportStore.projectName}
              onChange={this.handleProjectNameChange}
            />
          </InputContainer>
          <InputContainer>
            <InputGroup
              placeholder="내용"
              value={this.reportStore.text}
              onChange={this.handleTextChange}
            />
          </InputContainer>
          <Button
            icon="search"
            text="검색"
            intent={Intent.PRIMARY}
            onClick={this.handleSearch}
          />
        </ToolsRow>
        <StyledTable
          bordered={true}
          interactive={true}
          style={{ width: "100%" }}
        >
          <thead>
            <tr>
              <th style={{ minWidth: "140px" }}>날짜</th>
              <th>매니저</th>
              <th style={{ whiteSpace: "nowrap", minWidth: "140px" }}>프로젝트그룹</th>
              <th style={{ whiteSpace: "nowrap", minWidth: "140px" }}>
                프로젝트 무드
              </th>
              <th>내용</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {content}
          </tbody>
        </StyledTable>

        <Paginator
          pageGroup={this.reportStore.pageGroup}
          totalPages={this.reportStore.totalPages}
          currentPage={this.reportStore.currentPage}
          setPageGroup={this.reportStore.setPageGroup}
          setCurentPage={this.reportStore.setCurentPage}
          onFetch={this.handleSearch}
        />

      </Wrapper>
    );
  }
}

export default PMReportListPage;
