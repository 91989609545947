import React, { Component } from "react";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";

import styled from "styled-components/macro";
import CalendarView from '../../organisms/Dashboard/CalendarView';
import DashboardOfficeHour from '../../organisms/Dashboard/DashboardOfficeHour'
import DashboardScheduleToday from '../../organisms/Dashboard/DashboardScheduleToday'
import DashboardScheduleUpcoming from '../../organisms/Dashboard/DashboardScheduleUpcoming'
import DashboardScheduleTask from '../../organisms/Dashboard/DashboardScheduleTask'

const Wrapper = styled.div`
  margin-bottom: 10px;
  & > * + * {
    margin-top: 10px;
  }
`;
const ScheduleSection = styled.div`
  display: flex;
  & > * + * {
    margin-left: 10px;
  }
`;

const Divider = styled.div`
border: dashed 1px #BFCCD6;
margin: 20px 0;
`;

interface SchedulePanelProps {
  setTodayScheduleCount: (count: number) => void;
  setTodayTaskCount: (count: number) => void;
}

interface InjectedProps extends SchedulePanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class SchedulePanel extends Component<SchedulePanelProps> {
  @observable timerID: number = 0;

  get injected() {
    return this.props as InjectedProps;
  }

  get pmStore() {
    return this.injected.appStore.pmUserStore;
  }

  get userStore() {
    return this.injected.appStore.userStore;
  }

  get scheduleStore() {
    return this.injected.appStore.scheduleManagerStore;
  }

  async componentDidMount() {
    await this.pmStore.fetchPMUsers();
    await this.scheduleStore.fetchDashBoardSeesoSchedules();
    await this.scheduleStore.fetchDashboardGoogleSchedules();

    this.timerID = setInterval(
      () => this.tick(),
      5 * 60 * 1000  // 5분마다 일정 정보를 가져온다
    );
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick = async () => {
    await this.pmStore.fetchPMUsers();
    await this.scheduleStore.fetchDashBoardSeesoSchedules();
    await this.scheduleStore.refreshDashboardCalendarSeesoSchedules();
    await this.scheduleStore.fetchDashboardGoogleSchedules();
    await this.scheduleStore.refreshDashboardCalendarGoogleSchedules();

    this.handleTodayScheduleCountChanged();
  }
  
  handleTodayScheduleCountChanged = () => {
    const { email } = this.userStore;
    const { todayMeetings } = this.scheduleStore;
    const myTodayMeetings = todayMeetings.filter(x => x.creator === email || x.attendees.find(attendee => attendee === email));

    this.props.setTodayScheduleCount(myTodayMeetings.length)    
  }

  handleTodayTaskCountChanged = () => {
    const { userId } = this.userStore;
    const { todayTodos } = this.scheduleStore;
    const myTodos = todayTodos.filter(x => x.creator === userId);

    this.props.setTodayTaskCount(myTodos.length);    
  }
  
  render() {
    return (
      <Wrapper>
        <DashboardOfficeHour />
        
        <Divider />

        <CalendarView />

        <Divider />

        <ScheduleSection>
          <DashboardScheduleToday />
          <DashboardScheduleUpcoming />
          <DashboardScheduleTask 
            handleTodayTaskCountChanged={this.handleTodayTaskCountChanged} />
        </ScheduleSection>
      </Wrapper>
    );
  }
}

export default SchedulePanel;