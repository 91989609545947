import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

export const PageNavWrapper = styled.div`
  /* height: 154px; */
  /* height: 70px; */
  height: 134px;
  box-sizing: border-box;
  padding-top: 46px;
  /* padding-top: 10px; */
  display: flex;
`;

export const PageNavText = styled.span<{ color?: string }>`
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${p => p.color || "#161d2e"};

  & > * {
    vertical-align: middle;
  }
`;

export const PageNavLink = styled(NavLink)<{ color?: string }>`
  margin-left: auto;

  text-decoration: none;

  color: ${p => p.color || "#161d2e"};

  &:hover {
    text-decoration: none;
    color: #2d41f7;
  }
`;
