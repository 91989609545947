import React, { Component } from "react";

import { computed, observable } from "mobx";
import { inject, observer } from "mobx-react";

import { Alert, Spinner, Icon, Tag, Intent } from "@blueprintjs/core";

import { AppStore } from "../../../store/AppStore";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";

interface TaskBoardOpenPopupProps {
  projectGroupId: string;
  isOpen: boolean;
  onClose: () => void;
}

interface InjectedProps extends TaskBoardOpenPopupProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class TaskBoardOpenPopup extends Component<TaskBoardOpenPopupProps> {
  @observable readyToShow: boolean = false;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get store() {
    return this.injected.appStore.taskManagerStore;
  }

  @computed
  get board() {
    return this.store.currentBoard;
  }

  handleOnOpening = async () => {
    this.readyToShow = false;
    await this.injected.appStore.taskManagerStore.fetchTaskBoard(this.props.projectGroupId);
    this.readyToShow = true;
  };

  handleConfirm = async () => {
    if(!this.board) {
      try {
        await this.store.createTaskBoard(this.props.projectGroupId);
      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }      
    }
    window.location.href = `/taskmgr/${this.props.projectGroupId}`;
  }

  render() {
    const { isOpen, onClose } = this.props;

    return (
      <Alert
        cancelButtonText="취소"
        confirmButtonText={this.board ? '이동' : '생성'}
        icon={<Icon icon={"application"} intent={Intent.PRIMARY} iconSize={40} />}
        intent={Intent.PRIMARY}
        isOpen={isOpen}
        onOpening={this.handleOnOpening}
        onCancel={onClose}
        onConfirm={this.handleConfirm}
        canEscapeKeyCancel={true}
        canOutsideClickCancel={true}
      >
        <div>
          {!this.readyToShow ? (
            <Spinner/>
          ) : (
            <div>
              <p>프로젝트보드 <Tag intent={Intent.WARNING}>alpha</Tag></p>
              {this.board ? (
                <div>프로젝트 보드로 이동하시겠습니까?</div>
              ) : (
                <div>프로젝트 보드를 생성하시겠습니까?</div>
              )}
            </div>
          )}
        </div>        
      </Alert>      
    );
  }
}

export default TaskBoardOpenPopup;