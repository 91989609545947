import React, { Component, ChangeEvent } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { computed, observable } from "mobx";
import { RouteComponentProps } from "react-router";
import { H4, Spinner, FormGroup, Button, Intent, Dialog, Classes, TextArea, ProgressBar, Icon, Text } from "@blueprintjs/core";
import FormContainer from "../../atoms/FormContainer/FormContainer";
import { getMomentFormatter } from "../../../utils/date";
import moment from "moment";
import { DateInput } from "@blueprintjs/datetime";
import ScheduleTable from "../../organisms/ScheduleTable/ScheduleTable";
// import ScheduleSprintTableClient from "../../organisms/SprintTableClient/ScheduleSprintTableClient";
// import { Inspection } from "../../../store/models/Inspection";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";

const Wrapper = styled.div`
  .info { float: right; font-size: 12px; color: #7f8390 }
`;

const Section = styled.section`
  margin-bottom: 40px;
`;

const ButtonsRow = styled.div`
  /* text-align: right; */
  margin-top: 80px;

  & > * + * {
    margin-left: 5px;
  }
`;

const LoadingText = styled(Text)<{loaded: boolean}>`
  display: flex;
  justify-content: left;
  color: #5C7080;
  font-style: italic;
  ${p =>
    p.loaded &&
    css`
      font-style: normal;
      font-weight: 900;
      color: #137cbd;
    `}
`;

interface PageParams {
  projectGroupId: string;
}

interface ScheduleFormPageProps extends RouteComponentProps<PageParams> {}

interface InjectedProps extends ScheduleFormPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ScheduleFormPage extends Component<ScheduleFormPageProps> {
  @observable isFormInitiated = false;
  @observable isInspectionChanged = false;  // 임금수정, 시소서비스 수정 시.
  @observable isChangeReasonOpened = false;

  @observable loadedSchedule = false;
  @observable loadedRufrees = false;
  @observable loadedInspection = false;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get formStore() {
    return this.injected.appStore.scheduleFormStore;
  }

  @computed
  get currentForm() {
    return this.formStore.currentForm;
  }

  @computed
  get inspectionFormStore() {
    return this.injected.appStore.inspectionFormStore;
  }

  @computed
  get inspectionCurrentForm() {
    return this.inspectionFormStore.currentForm;
  }

  @computed
  get projectGroup() {
    const { projectGroupId } = this.props.match.params;
    return this.injected.appStore.projectGroupStore.projectGroups.find(
      x => x.groupId === projectGroupId
    );
  }

  @computed
  get selectedInspection() {
    const { projectGroup } = this;

    if (!projectGroup) {
      return null;
    }
    const inspection = this.injected.appStore.inspectionStore.inspections.find(
      x => x.inspectionId === projectGroup.inspections[0]
    );

    return inspection;
  }

  async componentDidMount() {
    try {
      await this.formStore.initForm();

      const { projectGroupId } = this.props.match.params;

      await this.injected.appStore.projectGroupStore.fetchProjectGroup(
        projectGroupId
      );
      await this.formStore.fetchScheduleOfProjectGroup(projectGroupId);
      this.loadedSchedule = true;

      // await this.injected.appStore.inspectionStore.fetchInspections();
      const { projectGroup } = this;
      if (projectGroup) {
        await this.injected.appStore.inspectionStore.fetchInspectionById(projectGroup.inspections[0]);
  
        // 검수서 formstore 준비.
        await this.inspectionFormStore.initForm();
        if (this.selectedInspection) {
          await this.inspectionFormStore.fetchInspection(this.selectedInspection.inspectionId);
          this.loadedInspection = true;
        }
  
        // 알유프리 정보 준비.
        await this.injected.appStore.rufreeStore.fetchAllRufreesByGraphQL();
        this.loadedRufrees = true;  
      }

      this.isFormInitiated = true;
    } catch (e) {}
  }

  handleCancel = () => {
    this.props.history.push(
      `/project-groups/${this.props.match.params.projectGroupId}`
    );
  };

  handleEdit = async () => {
    try {
      await this.formStore.putSchedule();
      // await this.formStore.updateSprintReports();

      if(this.isInspectionChanged) {
        // 검수서 form 저장.
        await this.inspectionFormStore.putInspection();
        await this.inspectionFormStore.postChangeReason();
      }

      AppToaster.show({
        message: "수정사항이 반영되었습니다.",
        intent: Intent.SUCCESS
      });

      this.props.history.push(
        `/project-groups/${this.currentForm.projectGroupId}`
      );
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  render() {
    if (!this.isFormInitiated) {
      return (
      <>
        <ProgressBar
          intent={ Intent.PRIMARY }
          value={ this.loadedSchedule && this.loadedInspection ? 0.6 : 0.3 }
        />
        <div style={{'padding': '30px 0px'}}>
          <LoadingText loaded={this.loadedSchedule}>
            프로젝트그룹 운영일정 정보...{this.loadedSchedule ? 'OK' : <div><Spinner size={15} /></div>}
          </LoadingText>

          <LoadingText loaded={this.loadedInspection}>
            플랜 정보...{this.loadedInspection ? 'OK' : <div><Spinner size={15} /></div> }
          </LoadingText>

          <LoadingText loaded={this.loadedRufrees}>
            알유프리 정보...{this.loadedRufrees ? 'OK' : <Spinner size={15} />}
          </LoadingText>
        </div>
      </>
      )
    }

    const { projectGroup } = this;

    if (!projectGroup) {
      return <div>프로젝트그룹을 찾을 수 없습니다.</div>;
    }

    return (
      <Wrapper>
        <FormContainer>
          <Section>
            <FormGroup
              label={
                <H4>
                  <strong>
                    {projectGroup.groupId} - {projectGroup.name}
                  </strong>
                </H4>
              }
            />
            <FormGroup label={<strong>프로젝트 전체 일정 (자동계산)</strong>}>
              <DateInput
                {...getMomentFormatter("YYYY/MM/DD")}
                locale="ko"
                closeOnSelection={true}
                value={moment(this.currentForm.expectedStartDate + "").toDate()}
                maxDate={new Date("2050-01-01")}
                disabled={true}
              />{" "}
              ~{" "}
              <DateInput
                {...getMomentFormatter("YYYY/MM/DD")}
                locale="ko"
                closeOnSelection={true}
                value={moment(this.currentForm.expectedEndDate + "").toDate()}
                maxDate={new Date("2050-01-01")}
                disabled={true}
              />
            </FormGroup>

            <FormGroup label={<strong>프로젝트 플랜</strong>}>
              {
                this.selectedInspection && (
                  <Link to={`/inspections/${this.selectedInspection.inspectionId}/read`} target="_blank">
                    {this.selectedInspection.nameWithId}
                  </Link>
                )
              }
            </FormGroup>
          </Section>
        </FormContainer>

        <span className='info'>
          * 스프린트의 추가/삭제는 해당 플랜 화면에서 진행 해주세요. 운영일정 정보는 플랜에 설계된 스프린트 기준입니다.
          * 지정된 알유프리를 해제하려면, 알유프리 이름 옆 <Icon iconSize={12} icon={'eraser'} />를 클릭하세요.
        </span>

        <ScheduleTable
          currentForm={this.currentForm}
          inspectionForm={this.inspectionCurrentForm}
          inspection={this.selectedInspection}
          projectGroup={projectGroup}
          onChange={() => this.isInspectionChanged=true} />

        {/* <ScheduleSprintTableClient
          currentForm={this.inspectionCurrentForm}
          onChange={() => this.isInspectionChanged=true} /> */}

        <ButtonsRow>
          <Button text="취소" onClick={this.handleCancel} />
          <Button
            text="저장"
            intent={Intent.PRIMARY}
            onClick={() => {
              if(this.isInspectionChanged) {
                this.isChangeReasonOpened = true;
              } else {
                this.handleEdit();
              }
            }}
          />
        </ButtonsRow>


        <Dialog
          isOpen={this.isChangeReasonOpened}
          usePortal={true}
          autoFocus={true}
          enforceFocus={true}
          canEscapeKeyClose={false}
          canOutsideClickClose={false}
          isCloseButtonShown={false}
          title="수정사유 입력"
        >
          <div className={Classes.DIALOG_BODY}>
            <TextArea
              style={{
                width: "100%",
                minHeight: "300px"
              }}
              value={this.inspectionCurrentForm.changeReason}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                this.inspectionCurrentForm.setChangeReason(e.target.value);
              }}
            />
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button text="취소" onClick={() => {
                this.isChangeReasonOpened = false;
              }}/>
              <Button
                text="저장"
                onClick={this.handleEdit}
                intent={Intent.PRIMARY}
              />
            </div>
          </div>
        </Dialog>

      </Wrapper>
    );
  }
}

export default ScheduleFormPage;
