import styled from "styled-components/macro";

export const FlexRow = styled.div`
  display: flex;
`;

export const VerticallyCenteredRow = styled.div`
  display: flex;
  align-items: center;
`;

export const DividerLine = styled.div`
  border-top: solid 1px #a0a7bb;
  opacity: 0.5;
  margin-bottom: 51px;
`;

export const DashedDividerLine = styled.div`
  opacity: 0.5;
  border: dashed 1px #a0a7bb;
  margin-bottom: 75px;
`;

export const LeftColumn = styled.div`
  width: 299px;
  flex-shrink: 0;
`;

export const ContentColumn = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;

export const LargeText = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  color: #161d2e;
`;

export const NormalText = styled.div`
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #161d2e;
  word-break: keep-all;
  white-space: pre-line;
`;

export const GreyLabel = styled.div`
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7f8390;
  padding-top: 7px;
`;

export const LabelPadding = styled.div`
  height: 35px;
`;

export const MetaHead = styled.section`
  position: relative;
`;

export const SubTitle = styled.div`
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2d41f7;
  margin-bottom: 11px;
`;

export const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #161d2e;
  padding-bottom: 37px;
  width: 480px;
`;

export const HeadMenu = styled.div`
  position: absolute;
  bottom: 8px;
  right: 0;
  display: flex;

  & > * + * {
    margin-left: 10px;
  }
`;

export const CardContainer = styled.div`
  border-radius: 4px;
  box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #eeeeee;
  background-color: #ffffff;
  padding: 69px 72px 120px 72px;
`;

export const GrayLink = styled.a`
  color: #7f8390;
  text-decoration: underline;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-top: 7px;
`;

export const DefaultButton = styled.button`
  width: 99px;
  height: 42px;
  border-radius: 21px;
  border: none;
  background-color: #eff6ff;

  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2d41f7;
  cursor: pointer;
`;