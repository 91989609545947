import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import {
  H3, Tab, Tabs, TabId
} from "@blueprintjs/core";
import { RouteComponentProps } from "react-router";
import { observable, computed } from "mobx";
import PMReportListPage from "./PMReportListPage";
import SupportPMReportListPage from "./SupportPMReportListPage";

const Wrapper = styled.div``;


interface PMReportPageProps extends RouteComponentProps {}

interface InjectedProps extends PMReportPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class PMReportPage extends Component<PMReportPageProps> {
  @observable currentTab: TabId = "";

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get reportStore() {
    return this.injected.appStore.pmReportStore;
  }

  // async loadReports() {
  //   switch (this.currentTab) {
  //     case "project":
  //       await this.reportStore.searchReports();
  //       break;
  //     case "support":
  //       await this.reportStore.searchReports(true);
  //       break;
  //     default:
  //       await this.reportStore.searchReports();
  //       break;
  //   }
  // }

  async componentDidMount() {
    const category = this.props.location.search.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function(str, key, value) {
        return (key === 'tab') ? value : '';
      }
    );
    this.currentTab = category ? category : 'project';
    // this.loadReports();
  }

  handleSearch = async () => {
    try {
      await this.reportStore.searchReports(true);
    } catch (e) {}
  };

  handleChangeTab = async(navbarTabId: TabId) => {
    this.currentTab = navbarTabId;

    // this.loadReports();
  }

  render() {
    return (
      <Wrapper>
        <H3>매니저 일지</H3>

        <Tabs id="TabsExample" onChange={this.handleChangeTab} selectedTabId={this.currentTab} renderActiveTabPanelOnly={true}>
          <Tab id="project" title="PM팀 일지" panel={<PMReportListPage {...this.props} />} />
          <Tab id="support" title="경영지원팀 일지" panel={<SupportPMReportListPage {...this.props} />} />
        </Tabs>
        
      </Wrapper>
    );
  }
}

export default PMReportPage;
