import { types, flow } from "mobx-state-tree";
import axios from "axios";
import { TasktaskModel, mapBugtask, mapTasktask } from "./models/Tasktask";
import { TasktaskResponse } from "../types/tasktask";
import sortBy from "lodash/sortBy";



export const TasktaskStoreModel = types
  .model("TasktaskStore", {
    tasktasks: types.array(TasktaskModel),
  })
  .views(self => ({
    get sortedTasktasks() {
      return sortBy(self.tasktasks.slice(), task => task.id).reverse();
    },
    findByProjectGroup(projectGroup: string) {
      return self.tasktasks.find(x => x.projectGroup === projectGroup);
    }
  }))
  .actions(self => {

     const fetchTasktask = flow(function*(projectGroup?: string) {
       try {
        const { data }: { data: TasktaskResponse[] } = yield axios.get(
          `/tasktasks?search=${projectGroup ? projectGroup : ''}`
        );

        const tasktask = data.map(x =>
          TasktaskModel.create(mapTasktask(x))
        );
        self.tasktasks.replace(tasktask);
      } catch (e) {
        console.error("fetchTasktask  error", e);
        throw e;
      }
     });

    return {
      fetchTasktask
    };
  });

type TasktaskStoreModelType = typeof TasktaskStoreModel.Type;
export interface TasktaskStore extends TasktaskStoreModelType {}
