import { types, flow } from "mobx-state-tree";
import { InspectionModel, Inspection } from "./models/Inspection";
import { InpsectionListResponse, InspectionMailPreviewResponse, InspectionResponse } from "../types/inspection";
import axios from "axios";
import moment from "moment";
import { InspectionMailModel } from "./models/InspectionMail";
import { AppToaster } from "../components/organisms/AppToaster/AppToaster";
import { Intent } from "@blueprintjs/core";
import { ClientUserModel } from "./models/ClientUser";
import { ClientUserResponse } from "../types/clientUsers";
import { mapResponseToClientUser } from "./ClientUserStore";

export const mapInspection = (x: InspectionResponse) => {
  return {
    inspectionId: x.inspection_id,
    client: x.client,
    clientName: x.client_name,
    sprintDays: x.sprint_days,
    clientRoles: x.client_roles,
    rufreeRoles: x.rufree_roles,
    seesoServices: x.seeso_services,
    createdAt: x.created_at,
    updatedAt: x.updated_at,
    title: x.title,
    requesterName: x.requester_name,
    requesterEmail: x.requester_email,
    inspectorName: x.inspector_name,
    inspectorEmail: x.inspector_email,
    references: x.references,
    inspection_files: x.inspection_files,
    dateStart: x.date_start,
    dateEnd: x.date_end,
    vatIncluded: x.vat_included,
    budget: x.budget,
    review: x.review,
    attention: x.attention,
    publishedDate: x.published_date,
    receptions: x.receptions,
    reception_date_register: x.reception_date_register,
    historyUrl: x.history_url,
    consults: x.consults,
    projectgroupIds: x.projectgroup_ids,
    historyList: x.history_list,
    rufree_seeso_per_sprint: x.rufree_seeso_per_sprint
  };
};
const mapInspectionMail = (x: InspectionMailPreviewResponse) => {
  return {
    html: x.html,
    subject: x.text
  };
};

const mapInspectionForRequest = (x: Inspection) => {
  return {
    inspection_id: x.inspectionId,
    client: x.client,
    sprint_days: x.sprintDays,
    client_roles: x.clientRoles,
    rufree_roles: x.rufreeRoles,
    seeso_services: x.seesoServices,
    created_at: x.createdAt,
    updated_at: x.updatedAt,
    title: x.title,
    requester_name: x.requesterName,
    requester_email: x.requesterEmail,
    inspector_name: x.inspectorName,
    inspector_email: x.inspectorEmail,
    vat_included: x.vatIncluded,
    references: x.references,
    inspection_files: x.inspection_files,
    date_start: x.dateStart,
    date_end: x.dateEnd,
    budget: x.budget,
    review: x.review,
    attention: x.attention,
    published_date: x.publishedDate,
    receptions: x.receptions,
    history_url: x.historyUrl,
    projectgroup_ids: x.projectgroupIds
  };
};

export const InspectionStoreModel = types
  .model("InspectionStore", {
    inspections: types.array(InspectionModel),

    // For Pagination Query
    currentPage: types.optional(types.number, 1),
    pageGroup: types.optional(types.number, 1),
    pageSize: types.optional(types.number, 15),
    totalPages: types.optional(types.number, 1)
  })
  .views(self => ({
    get sortedInspections() {
      return self.inspections
        .slice()
        .sort(
          (a, b) =>
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
    }
  }))
  .views(self => ({
    get onlyPublishedInspections() {
      return self.sortedInspections.filter(x => x.publishedDate);
    }
  }))
  .actions(self => ({
    setCurentPage(value: number) {
      self.currentPage = value;
    },
    setPageGroup(value: number) {
      self.pageGroup = value;
    },
    setInspections(values: any[]) {
      const items = values.map(x =>
        InspectionModel.create({
          inspectionId: x.inspectionId,
          title: x.title,
          client: null,
          clientName: null,
          sprintDays: [],
          clientRoles: [],
          rufreeRoles: [],
          seesoServices: [],
          createdAt: "",
          updatedAt: "",
          requesterName: "",
          requesterEmail: "",
          inspector: null,
          inspectorName: null,
          inspectorEmail: null,
          references: x.references.map((r: any) => { const ref = JSON.parse(r); return { link: ref.link, comment: ref.comment } }),
          inspection_files: [],
          dateStart: null,
          dateEnd: null,
          vatIncluded: false,
          budget: 0,
          review: "",
          attention: null,
          receptions: [],
          reception_date_register: null,
          consults: [],
          publishedDate: null,
          historyUrl: null,
          projectgroupIds: [],
          historyList: [],
          rufree_seeso_per_sprint: {}
        })
      );
      self.inspections.replace(items);
    }
  }))
  .actions(self => {
    const fetchInspectionById = flow(function* (inspectionId: string, publishedOnly?: boolean) {
      const params: {
        published_only?: boolean
      } = {};
      params.published_only = publishedOnly !== undefined ? publishedOnly : true;
      const { data }: { data: InspectionResponse } = yield axios.get(
        `/inspections/${inspectionId}`, {
          params: params
        }
      );
      // console.log("data",data);
      const inspection = InspectionModel.create(mapInspection(data));
      // console.log("inspection", inspection);
      self.inspections.forEach(x => {
        if (x.inspectionId === inspection.inspectionId) {
          self.inspections.remove(x);
        }
      });

      self.inspections.push(inspection);
    });
    const fetchPageableInspections = flow(function* (
      { created_after, search }: { created_after?: string, search: string }) {
      const params: {
        created_at__gt?: string,
        search?: string,
        "page": number,
        "page_size": number,
      } = {
        "page": self.currentPage,
        "page_size": self.pageSize
      };

      if (created_after) {
        params.created_at__gt = created_after;
      }
      if (search)
        params.search = search;

      try {
        const { data }: { data: InpsectionListResponse } = yield axios.get(
          "/inspections",
          {
            params
          }
        );

        const inspections = data.results.map(x =>
          InspectionModel.create(mapInspection(x))
        );
        self.totalPages = data.total_pages;
        self.inspections.replace(inspections);
      } catch (e) {
        console.log(e);
        throw e;
      }
    });
    const fetchInspections = flow(function* (created_after?: string) {
      const params: {
        created_at__gt?: string
      } = {};

      if (created_after) {
        params.created_at__gt = created_after;
      }

      try {
        const { data }: { data: InspectionResponse[] } = yield axios.get(
          "/inspections",
          {
            params
          }
        );

        const inspections = data.map(x =>
          InspectionModel.create(mapInspection(x))
        );

        self.inspections.replace(inspections);
      } catch (e) {
        console.log(e);
        throw e;
      }
    });

    const toggleInspectionPublished = flow(function* (inspection: Inspection) {
      try {
        const publishedDate = inspection.publishedDate
          ? null
          : moment(new Date()).format("YYYY-MM-DD");

        const { data }: { data: InspectionResponse } = yield axios.put(
          `/inspections/${inspection.inspectionId}/publishDateChange`,
          {
            ...mapInspectionForRequest(inspection),
            published_date: publishedDate
          }
        );

        inspection.setPublishedDate(publishedDate);

        return data;
      } catch (e) {
        console.log("toggleInpectionPublished error", e);
        throw e;
      }
    });

    const duplicateInspection = flow(function* (inspection: Inspection) {
      try {
        const reqData = mapInspectionForRequest(inspection);

        delete reqData["inspection_id"];
        delete reqData["created_at"];
        delete reqData["updated_at"];

        const { data }: { data: InspectionResponse } = yield axios.post(
          "/inspections",
          {
            ...reqData,
            title: inspection.title + "의 사본",
            published_date: null
          }
        );

        return data;
      } catch (e) {
        console.log("duplicateInspection error", e);
        throw e;
      }
    });

    const deleteInspection = flow(function* (inspection: Inspection) {
      try {
        yield axios.delete(`/inspections/${inspection.inspectionId}`);
        self.inspections.remove(inspection);
      } catch (e) {
        console.log("deleteInspection error", e);
        throw e;
      }
    });

    const previewMailInspection = flow(function* (inspection: Inspection) {
      try {

        const { data }: { data: InspectionMailPreviewResponse } = yield axios.get(`/inspections/${inspection.inspectionId}/previewMail`);

        return InspectionMailModel.create({
          html: data.html,
          subject: data.subject
        });
      } catch (e) {
        console.log("previewMailInspection error", e);
        throw e;
      }
    });


    const sendMailInspection = flow(function* (inspection: Inspection) {
      try {

        const { status } = yield axios.get(`/inspections/${inspection.inspectionId}/sendMail`);
        if (status == 204)
          AppToaster.show({
            message: "발송 성공.",
            intent: Intent.SUCCESS
          });
        else
          AppToaster.show({
            message: "발송 실패.",
            intent: Intent.SUCCESS
          });

      } catch (e) {
        console.log("sendMailInspection error", e);
        throw e;
      }
    });

    const downloadPDF = flow(function* (inspection: Inspection
      , budget?: number, showVatIncluded: boolean = true
      , history_id?: number, historyDate?: string) {
      try {
        const url = `/inspections/${inspection.inspectionId}/download_pdf`;
        const params: {
          budget?: number,
          showVatIncluded: boolean,
          history_id?: number
        } = {
          // budget: budget,
          showVatIncluded: showVatIncluded
        };
        if (history_id)
          params.history_id = history_id;
        if (budget)
          params.budget = budget;
        // budget=${budget}&showVatIncluded=${showVatIncluded}
        yield axios.get(url, { responseType: "blob", params })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            let filename = `[시소] ${inspection.title}`;
            if (history_id) {
              filename = `[시소] ${inspection.title} - (히스토리)프로젝트 플랜 - ${(moment(historyDate).format("YYYY-MM-DD"))}.pdf`;
            } else {
              filename = `[시소] ${inspection.title} - 프로젝트 플랜 - ${(moment(new Date()).format("YYYY-MM-DD"))}.pdf`;
            }

            link.setAttribute("download", filename); //or any other extension
            document.body.appendChild(link);
            link.click();
          });
      } catch (e) {
        console.log("downloadPDF error", e);
        throw e;
      }
    });

    const toggleVatIncluded = flow(function* (inspection: Inspection) {
      try {
        const { data }: { data: InspectionResponse } = yield axios.put(
          `/inspections/${inspection.inspectionId}`,
          {
            ...mapInspectionForRequest(inspection),
            vat_included: !inspection.vatIncluded
          }
        );

        inspection.setVatIncluded(!inspection.vatIncluded);

      } catch (e) {

      }
    });

    const fetchContractor = flow(function* (clientId: string) {
      try {
        const { data }: { data: ClientUserResponse } = yield axios.get(
          `/clients/${clientId}/getContractor`
        );

        const clientUser = ClientUserModel.create(mapResponseToClientUser(data));

        return clientUser;
      } catch (e) {
        console.log("fetchClient error", e);
      }
    });


    return {
      fetchInspectionById,
      fetchInspections,
      fetchPageableInspections,
      toggleInspectionPublished,
      duplicateInspection,
      deleteInspection,
      previewMailInspection,

      sendMailInspection,
      downloadPDF,
      toggleVatIncluded,
      fetchContractor
    };
  });

type InspectionStoreType = typeof InspectionStoreModel.Type;

export interface InspectionStore extends InspectionStoreType {
}