import React, { FC, ChangeEvent } from "react";
import styled from "styled-components/macro";
import { InspectionFile, ReferenceLink } from "../../../store/forms/ReferenceLinkModel";
import { RemoveButtonContainer } from "../../atoms/RemoveItemButton/RemoveItemButton";
import { Button, Intent, InputGroup } from "@blueprintjs/core";
import { observer } from "mobx-react";

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 5px;
`;

const ReferenceRow = styled.div`
  display: flex;

  & > * + * {
    margin-left: 5px;
  }
`;

interface ReferenceLinkFormViewProps {
  inspectionFile: InspectionFile;
  onRemove: (inspectionFile: InspectionFile) => void;
}

const ReferenceLinkFormView: FC<ReferenceLinkFormViewProps> = ({
  inspectionFile,
  onRemove
}) => {
  const handleRemove = () => {
    onRemove(inspectionFile);
  };

  /*const handleLinkChange = (e: ChangeEvent<HTMLInputElement>) => {
    inspectionFile.setLink(e.target.value);
  };

  const handleCommentChange = (e: ChangeEvent<HTMLInputElement>) => {
    inspectionFile.setComment(e.target.value);
  };*/

  return (
    <Wrapper>
      <RemoveButtonContainer>
        <Button
          icon="minus"
          minimal={true}
          small={true}
          intent={Intent.DANGER}
          onClick={handleRemove}
        />
      </RemoveButtonContainer>
      <ReferenceRow>
        <InputGroup
          placeholder="관련자료 링크"
          value={inspectionFile.file_url}
          // onChange={handleLinkChange}
          style={{ width: "230px" }}
          disabled={true}
        />
        <InputGroup
          placeholder="메모"
          value={inspectionFile.file_name}
          // onChange={handleCommentChange}
          disabled={true}
        />
      </ReferenceRow>
    </Wrapper>
  );
};

export default observer(ReferenceLinkFormView);
