import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import {
  Dialog,
  Classes,
  Button,
  Intent,
  FormGroup
} from "@blueprintjs/core";
import { computed, observable } from "mobx";
import { ProjectGroup } from "../../../store/models/ProjectGroup";
import { AppToaster } from "../AppToaster/AppToaster";
import {
  AddItemButton,
  AddItemButtonContainer
} from "../../atoms/AddItemButton/AddItemButton";
import ReferenceLinkFormView from "../../molecules/ReferenceLinkFormView/ReferenceLinkFormView";

interface ProjectGroupLinkEditPopupProps {
  onClose: () => void;
  isOpen: boolean;
  projectGroup: ProjectGroup;
}

interface InjectedProps extends ProjectGroupLinkEditPopupProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ProjectGroupLinkEditPopup extends Component<
  ProjectGroupLinkEditPopupProps
> {
  @observable pendingName = "";
  @observable pendingDescription = "";

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get projectGroupStore() {
    return this.injected.appStore.projectGroupStore
  }

  @computed
  get formStore() {
    return this.injected.appStore.projectGroupFormStore;
  }

  @computed
  get currentForm() {
    return this.formStore.currentForm;
  }

  handleSave = async () => {
    try {
      await this.formStore.patchProjectGroup();
      AppToaster.show({
        message: "수정되었습니다.",
        intent: Intent.SUCCESS
      });

      await this.projectGroupStore.fetchProjectGroup(this.formStore.currentForm.groupId);
      this.props.onClose();
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    } finally {
    }
  };

  handleClose = () => {
    this.props.onClose();
  };

  handleOpening = async() => {
    await this.formStore.initForm();

    const { groupId } = this.props.projectGroup;

    if (groupId) {
      await this.formStore.fetchProjectGroup(groupId);
    }
  };

  render() {
    const { isOpen } = this.props;

    return (
      <Dialog
        onOpening={this.handleOpening}
        isOpen={isOpen}
        title="주요링크"
        usePortal={true}
        autoFocus={true}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        enforceFocus={true}
      >

        <div className={Classes.DIALOG_BODY}>
          <FormGroup style={{marginLeft: '20px'}}>
            <AddItemButtonContainer>
              <AddItemButton onClick={this.currentForm.addReferenceLink} />
            </AddItemButtonContainer>
            {this.currentForm.references.map((reference, index) => (
              <ReferenceLinkFormView
                key={index}
                referenceLink={reference}
                onRemove={this.currentForm.removeReferenceLink}
              />
            ))}
          </FormGroup>
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button text="취소" onClick={this.handleClose} />
            <Button
              text="수정"
              intent={Intent.PRIMARY}
              onClick={this.handleSave}
            />
          </div>
        </div>

      </Dialog>
    );
  }
}

export default ProjectGroupLinkEditPopup;
