import { types } from "mobx-state-tree";
import { type } from "os";

export let RequestType = new Map();
RequestType.set("BUG", "오류수정");
RequestType.set("IMPROVEMENT", "개선");
RequestType.set("ETC", "기타");

export let RequestOS = new Map();
RequestOS.set("AND", "안드로이드");
RequestOS.set("IOS", "iOS");
RequestOS.set("WEB", "Web(PC)");
RequestOS.set("MOB", "Web(Mobile)");
RequestOS.set("RES", "Web(반응형)");
RequestOS.set("ETC", "기타");

export const ReferenceFileModel = types
    .model("ReferenceFile", {
        id: types.number,
        file: types.optional(types.string, ""),
        name: types.optional(types.string, ""),
        format: types.optional(types.string, ""),
        description:types.optional(types.string, "")
    })
    .views(self => ({
    }));

type ReferenceFileType = typeof ReferenceFileModel.Type;
export interface ReferenceFile extends ReferenceFileType {}

export const ReferenceLinkModel = types
    .model("ReferenceLink", {
        id: types.number,
        url: types.optional(types.string, ""),
        description: types.optional(types.string, "")
    })
    .views(self => ({
    })); 

type ReferenceLinkType = typeof ReferenceLinkModel.Type;
export interface ReferenceLink extends ReferenceLinkType {}

export const ClientModel = types
.model("Client", {
    id: types.number,
    clientId: types.string,
    name: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    affiliation: types.string
})
.views(self => ({
})); 

export const RufreeModel = types
.model("Rufree", {
    rufreeId: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    phone: types.maybeNull(types.string)
})
.views(self => ({
})); 

export const ProjectGroupModel = types
.model("ProjectGroup", {
    groupId: types.maybeNull(types.string),
    name: types.maybeNull(types.string)
})
.views(self => ({
})); 

export const TaskSummaryModel = types
    .model("Task", {
        id: types.number,
        code: types.string,
        status: types.string,
        request: types.number,
        title: types.string,
        type: types.string,
        rufree: types.maybeNull(RufreeModel),
        role: types.string
    })
    .actions(
        self => ({
    }));

type TaskSummaryType = typeof TaskSummaryModel.Type;
export interface TaskSummary extends TaskSummaryType {}

export let RequestStatus = new Map();
RequestStatus.set("SUBMITTED", "신청");
RequestStatus.set("IN_PROGRESS", "진행");
RequestStatus.set("COMPLETED", "완료");
RequestStatus.set("CANCELED", "취소");

export const RequestModel = types
    .model("Request", {
        id: types.number,
        code: types.string,
        status: types.string,
        os: types.maybeNull(types.array(types.string)),
        type: types.string,
        title: types.string,
        serviceName: types.maybeNull(types.string),
        asis: types.maybeNull(types.string),
        tobe: types.maybeNull(types.string),
        accessInfo: types.maybeNull(types.string),
        cancelReason: types.maybeNull(types.string),
        cancelConsultant: types.maybeNull(types.string),
        cancelDate: types.maybeNull(types.string),
        expectedDueDate: types.maybeNull(types.string),
        referenceFiles: types.maybeNull(types.array(ReferenceFileModel)),
        referenceLinks: types.maybeNull(types.array(ReferenceLinkModel)),
        client: ClientModel,
        tasks: types.maybeNull(types.array(TaskSummaryModel)),
        created: types.string,
        modified: types.maybeNull(types.string)
    })
    .actions(
        self => ({
    }));

type RequestType = typeof RequestModel.Type;
export interface Request extends RequestType {}

export const TaskStatusData = {
    created: {
        'db': 'CREATED',
        'text': '생성'
    },
    arranging: {
        'db': 'ARRANGING',
        'text': '매칭대기'
    },
    matched: {
        'db': 'MATCHED',
        'text': '매칭완료'
    },
    inProgress: {
        'db': 'IN_PROGRESS',
        'text': '작업중'
    },
    review: {
        'db': 'REVIEW',
        'text': '검수중'
    },
    settlement: {
        'db': 'SETTLEMENT',
        'text': '정산중'
    },
    completed: {
        'db': 'COMPLETED',
        'text': '완료'
    },
    calculated: {
        'db': 'CALCULATED',
        'text': '정산완료'
    },
    canceled: {
        'db': 'CANCELED',
        'text': '취소'
    }
};

export let TaskStatus = new Map();
TaskStatus.set("CREATED", "신규");
TaskStatus.set("ARRANGING", "매칭대기");
TaskStatus.set("MATCHED", "매칭완료");
TaskStatus.set("IN_PROGRESS", "작업중");
TaskStatus.set("REVIEW", "검수중");
TaskStatus.set("SETTLEMENT", "정산중");
TaskStatus.set("COMPLETED", "완료");
TaskStatus.set("CALCULATED", "정산완료");
TaskStatus.set("CANCELED", "취소");

export let TaskType = new Map();
TaskType.set("PER_HOUR", "시급제");
TaskType.set("OVERALL", "총비용");

export let RufreeRole = new Map();
RufreeRole.set("PLANNING", "기획");
RufreeRole.set("DESIGN", "디자인");
RufreeRole.set("PUBLISHING", "퍼블리싱");
RufreeRole.set("FRONTEND", "프론트엔드");
RufreeRole.set("BACKEND", "백엔드");
RufreeRole.set("FULLSTACK", "프론트+백");
RufreeRole.set("QA", "QA");
RufreeRole.set("ETC", "기타");

export const ManagerModel = types
.model("Manager", {
    id: types.number,
    name: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    phone: types.maybeNull(types.string)
})
.views(self => ({
})); 

export const OutcomeModel = types
    .model("Outcome", {
        id: types.number,
        file: types.optional(types.string, ""),
        name: types.optional(types.string, ""),
        format: types.optional(types.string, "")
    })
    .views(self => ({
    }));

export const TaskModel = types
    .model("Task", {
        id: types.number,
        code: types.string,
        status: types.string,
        request: RequestModel,
        serviceName: types.maybeNull(types.string),
        title: types.string,
        type: types.string,
        expectedDueDate: types.maybeNull(types.string),
        estimatedDueDate: types.maybeNull(types.string),
        estimatedWorkingTime: types.maybeNull(types.number),
        memo: types.maybeNull(types.string),
        description: types.maybeNull(types.string),
        criteria: types.maybeNull(types.array(types.string)),
        rufree: types.maybeNull(RufreeModel),
        role: types.string,
        manager: types.maybe(ManagerModel),
        client: types.string,
        clientName: types.maybeNull(types.string),
        projectGroup: types.maybeNull(ProjectGroupModel),
        totalAmount: types.maybeNull(types.number),
        paymentRemark: types.string,
        outcomes: types.maybeNull(types.array(OutcomeModel)),
        created: types.maybeNull(types.string),
        modified: types.maybeNull(types.string)
    })
    .actions(
        self => ({
            setTitle(value: string) {
                self.title = value;
            },
    }));

type TaskType = typeof TaskModel.Type;
export interface Task extends TaskType {}