import React, { useState } from "react";

import * as style from './styles';
import * as Plan from '../../molecules/Plan';

import { Inspection } from '../../../store/models/Inspection';

const _convertCost = (cost: number, includeVAT: boolean) => {
 return Math.floor((cost * (includeVAT ? 1.1 : 1)));
}
const DISPLAY_COUNT_DETAIL = 4;
export interface IPlanReadSectionProps {
  title?: string;
  showAllPaymentDetail: boolean;
  showVATToggle: boolean;
  inspection: Inspection;
};

export const PlanReadSection: React.FC<IPlanReadSectionProps> = (props) => {
  const [ includeVAT, setIncluetVAT ] = useState(props.showVATToggle);
  const [ showAllPaymentDetail, setShowAllPaymentDetail] = useState(props.showAllPaymentDetail);
  const {
    clientRoles,
    rufreeRoles,
    seesoServices,
    rufree_seeso_per_sprint
  } = props.inspection;

  const resourcePerSprint = Array.from(rufree_seeso_per_sprint.values());
  const resourcePRE = resourcePerSprint.find(resource => resource.sprint_name === 'PRE');
  const resourceWithoutPRE = resourcePerSprint.filter(resource => resource.sprint_name !== 'PRE');

  const getCost = (cost_type: string = '') => {
    return resourcePerSprint.reduce(
      (prev, curr) =>
        prev
          + (cost_type !== 'seeso' ? curr.rufree_total : 0)
          + (cost_type !== 'rufree' ?  curr.seeso_total : 0),
      0
    );
  }

  return (
    <style.Wrapper>
      {
        props.showVATToggle &&
          <Plan.VATCheckbox
            className='vat-checkbox'
            checked={ includeVAT }
            onChange={ (checked) => setIncluetVAT(checked) } />
      }

      <style.PlanReadSection>
        <style.Section>
          <h4>{ props.title ? props.title : '리소스 구성' }</h4>

          <div className='role-group'>
            <h5>클라이언트 리소스</h5>
            {
              clientRoles.map((role, i) => {
                return <Plan.Resource
                  key={ i }
                  role={ role.role }
                  sprints={ role.sprintsToNumber }
                />
              })
            }
          </div>
          
          <div className='role-group'>
            <h5>알유프리 리소스</h5>
            {
              rufreeRoles.map((role, i) => {
                return <Plan.Resource
                  key={ i }
                  role={ role.role }
                  sprints={ role.sprintsToNumber }
                />
              })
            }
          </div>

          <div className='role-group'>
            <h5>시소 서비스</h5>
            {
              seesoServices
              .filter(service => service.isValid)
              .map((service, i) => {
                return <Plan.Resource
                  key={ i }
                  role={ service.service }
                  sprints={ service.sprintsToNumber }
                />
              })
            }
          </div>
        </style.Section>

        <style.Section>
          <h4>스프린트별 청구 상세내역</h4>
          <span>{ `(VAT ${ includeVAT ? '포함' : '미포함' })` }</span>
          <span className='description'>
            *PRE는 S1에 포함되어 청구됩니다
          </span>

          <style.PaymentDetail>
            <style.PaymentHeader>
              <style.PaymentColumn>스프린트</style.PaymentColumn>
              <style.PaymentColumn>내역</style.PaymentColumn>
              <style.PaymentColumn>총 금액</style.PaymentColumn>
            </style.PaymentHeader>
          

          {
            resourceWithoutPRE
            .slice(0, showAllPaymentDetail ? resourcePerSprint.length : DISPLAY_COUNT_DETAIL)
            .map(resource =>
              <style.PaymentRow
                key={ resource.sprint_name }
              >
                <style.PaymentColumn className='sprint-name'>{ resource.sprint_name } </style.PaymentColumn>
                <style.PaymentColumn className='costs'>
                  <div className='cost-group'>
                    <div className='cost-title'>
                      알유프리
                    </div>
                    <div className='cost-detail'>
                      {
                        resource.sprint_name === 'S1' &&
                          resourcePRE &&
                            resourcePRE.rufree_list
                              .map( (rufree, i) =>
                                <div key={ i } className='cost-detail-row'>
                                  <div>{ `*${ rufree.role }` }</div>
                                  <div>{ `${_convertCost(rufree.wage, includeVAT).toLocaleString("en-US") }원` }</div>
                                </div>
                              )
                      }
                      {
                        resource.rufree_list.map( (rufree, i) =>
                          <div key={ i } className='cost-detail-row'>
                            <div>{ rufree.role }</div>
                            <div>{ `${ _convertCost(rufree.wage, includeVAT).toLocaleString("en-US") }원` }</div>
                          </div>
                        )
                      }
                    </div>
                  </div>

                  <div className='cost-group'>
                    <div className='cost-title'>
                      시소 서비스
                    </div>
                    <div className='cost-detail'>
                      {
                        resource.sprint_name === 'S1' &&
                          resourcePRE &&
                            resourcePRE.seeso_list
                              .map( (seeso, i) =>
                                <div key={ i } className='cost-detail-row'>
                                  <div>*{ seeso.service }</div>
                                  <div>{ `${ _convertCost(seeso.cost, includeVAT).toLocaleString("en-US") }원`  }</div>
                                </div>
                              )
                      }
                      {
                        resource.seeso_list.map( (seeso, i) =>
                          <div key={ i } className='cost-detail-row'>
                            <div>{ seeso.service }</div>
                            <div>{ `${ _convertCost(seeso.cost, includeVAT).toLocaleString("en-US") }원` }</div>
                          </div>
                        )
                      }
                    </div>
                  </div>
                  
                  
                </style.PaymentColumn>
                <style.PaymentColumn className='total-vat'>
                  { _convertCost(resource.rufree_total + resource.seeso_total, includeVAT).toLocaleString("en-US") }원
                </style.PaymentColumn>
              </style.PaymentRow>
            )
          }

          {
            !showAllPaymentDetail &&
              resourceWithoutPRE.length > 4 &&
              <style.MoreButtonWrapper
                onClick={ () => setShowAllPaymentDetail(true) } />
          }
          </style.PaymentDetail>
        </style.Section>

        <style.Section>
          <h4>예상 총 비용</h4>
          <span>{ `(VAT ${ includeVAT ? '포함' : '미포함' })` }</span>

          <style.TotalCost>
            <div>
              <h6>알유프리 리소스</h6>
              <span>{ _convertCost(getCost('rufree'), includeVAT).toLocaleString("en-US") }원</span>
            </div>
            <div>
              <h6>시소 서비스</h6>
              <span>{ _convertCost(getCost('seeso'), includeVAT).toLocaleString("en-US") }원</span>
            </div>
            <div>
              <div>
                <h6>총 스프린트</h6>
                <span>{ resourceWithoutPRE.length }개</span>
              </div>
              <div>
                <h6>총 금액</h6>
                <span>{ _convertCost(getCost(), includeVAT).toLocaleString("en-US") }원</span>
              </div>
            </div>
          </style.TotalCost>
        </style.Section>
      </style.PlanReadSection>
    </style.Wrapper>
  );
}
