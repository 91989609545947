import React, { FC } from "react";
import styled from 'styled-components/macro';
import { Tooltip } from "@blueprintjs/core";


const Wrapper = styled.div`
  width: 160px;
  padding-top: 3px;
  padding-bottom: 0px;
  height: 16px;
  display: flex;
  flex-direction: row;
`;
const Done = styled.div`
  width: 100%;
  background: #43BF4D;
`;
const OnGoing = styled.div`
  width: 100%;
  background: #ff8300;
`;
const Scheduled = styled.div`
  width: 100%;
  background: #5C7080;
`;


interface Props {
  projectGroupId: string;
  doneCount: number;
  onGoingCount: number;
  totalCount: number;
  tooltip?: any;
}
const ProjectGroupProgressBar: FC<Props> = props => {
  const { doneCount, onGoingCount, totalCount, projectGroupId, tooltip } = props;

  return (
      <Tooltip
        inheritDarkTheme={false}
        disabled={tooltip ? false : true}
        content={tooltip}
      >
        
        <Wrapper>

        {
          doneCount > 0 &&
          [...Array(doneCount)].map((value, index) => 
            <Done key={`done_${projectGroupId}_${index}`}>
              &nbsp;
            </Done>
          )
        }
        {
          onGoingCount > 0 &&
            [...Array(onGoingCount)].map((value, index) =>
              <OnGoing key={`ongoing_${projectGroupId}_${index}`} />
            )
        }
        
        {
          totalCount - doneCount - onGoingCount > 0 &&
            [...Array(totalCount - doneCount - onGoingCount)].map((value, index) =>
              <Scheduled key={`scheduled_${projectGroupId}_${index}`}>
                &nbsp;
              </Scheduled>
          )
        }
          
        </Wrapper>

      </Tooltip>
  );
}

export default ProjectGroupProgressBar;