import React, { Component, ChangeEvent, KeyboardEvent } from "react";

import { observable, computed } from "mobx";
import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import { Button, Intent, Alert, InputGroup, Dialog, Classes, HTMLSelect } from "@blueprintjs/core";
import { FocusStyleManager } from "@blueprintjs/core";

import { AppStore } from "../../../store/AppStore";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";

const HeaderContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  border-bottom: 1px dashed white;
  padding-bottom: 5px;
`;
const TitleView = styled.div`
  line-height: 30px;
  text-align: center;
  font-weight: bold;
  word-wrap: break-word;
`;
const TitleHoverView = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 2;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.55);
`;
const TitleHoverViewButton = styled(Button)`
`;

const TitleEditor = styled(InputGroup)`
`;

interface TaskListHeaderProps {
  id: string;
  index: number;
  title: string;
  landDraggable: boolean;
  metadata: any;
  onDelete: () => void;
  updateTitle: (title: string) => void;
}

interface InjectedProps extends TaskListHeaderProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class TaskListHeader extends Component<TaskListHeaderProps> {
  @observable showHoverView: boolean = false;
  @observable showDeleterAlert: boolean = false;
  @observable inputComponent: any = null;

  @observable personInCharge:string = '';
  @observable personInChargeList: Array<number> = [];
  @observable showPersonInChargeDialog: boolean = false;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get formStore() {
    return this.injected.appStore.taskManagerTaskListFormStore;
  }

  @computed
  get currentForm() {
    return this.formStore.currentForm;
  }

  @computed
  get store() {
    return this.injected.appStore.taskManagerStore;
  }

  @computed
  get board() {
    return this.store.currentBoard;
  }

  @computed
  get showTitleEditor() {
    return this.store.taskListEditing.includes(Number(this.props.id))
  }

  componentDidMount() {
    FocusStyleManager.onlyShowFocusOnTabs();
    this.personInCharge = this.props.metadata.person_in_charge ? this.props.metadata.person_in_charge : '';
    this.personInChargeList = this.props.metadata.person_in_charge_list ? this.props.metadata.person_in_charge_list : [];

    if(this.personInChargeList.length > 0) {
      let pic_member = this.board && this.board.members.find(member => member.id === this.personInChargeList[0]);
      if(pic_member) {
        this.personInCharge = pic_member.email;
      }      
    }
  }
  componentDidUpdate() {
    if(this.showTitleEditor && 
      this.inputComponent && 
      this.inputComponent !== document.activeElement
    ) {
      this.inputComponent.focus();
      this.inputComponent.select();
      this.inputComponent.onkeypress = this.handleOnKeyPress;
    }        
  }

  toggleStartEditing = () => {
    this.store.setDraggable(false);
    this.store.addEditingTaskListId(Number(this.props.id));
  }
  toggleFinishEditing = () => {
    this.store.setDraggable(true);
    this.store.delEditingTaskListId(Number(this.props.id));
  }

  handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.currentForm.setTitle(e.target.value);
  };
  handleOnKeyPress = async(event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      if(this.currentForm.title.trim() !== '') {
        this.handleEditSubmit();
      }
    }
  }

  handleEdit = async () => {
    // 목록 헤더에 카드의 개수가 포함되어 제목 (0) 과 같은 형태로 오기때문에, 뒤쪽 괄호부분을 제거해야함
    let title = this.props.title.substring(0, this.props.title.lastIndexOf(' ('));

    this.formStore.initForm(title, this.board ? this.board.taskBoardId : '');
    this.toggleStartEditing();
  }
  handleEditSubmit = async () => {
    try {
      await this.formStore.putTaskList(this.props.id);
      AppToaster.show({
        message: "태스크 목록 이름이 수정되었습니다.",
        intent: Intent.SUCCESS
      });

      this.props.updateTitle(this.currentForm.title);
      this.toggleFinishEditing();

      // 업데이트된 내용을 반영
      await this.injected.appStore.taskManagerStore.fetchTaskBoard(this.board ? this.board.projectGroup : '');
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }    
  }
  handleDeleteSubmit = async () => {
    try {
      await this.injected.appStore.taskManagerStore.deleteTaskList(
        this.props.id
      );
      AppToaster.show({
        message: "태스크 목록이 삭제되었습니다.",
        intent: Intent.SUCCESS
      });

      this.props.onDelete();

      // 업데이트된 내용을 반영
      await this.injected.appStore.taskManagerStore.fetchTaskBoard(this.board ? this.board.projectGroup : '');
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }    
  }

  handleChangePersonInCharge = async (e: any) => {
    try {
      this.injected.appStore.taskManagerStore.updateTaskListPersonInCharge(Number(this.props.id), this.personInChargeList);
      AppToaster.show({
        message: "기본 담당자가 변경되었습니다.",
        intent: Intent.SUCCESS
      });

      e.stopPropagation();
      this.showPersonInChargeDialog=false;

        // 업데이트된 내용을 반영
      await this.injected.appStore.taskManagerStore.fetchTaskBoard(this.board ? this.board.projectGroup : '');
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }       
  }

  render() {
    // 임시구현: 담당자 목록 중 첫번째 사람만 출력
    const person_in_charge_member = this.board && this.personInChargeList.length > 0 ?
      this.board.members.find(member => member.id === this.personInChargeList[0]): '';

    let person_in_charge_name = person_in_charge_member ? person_in_charge_member.name : '';
    if(this.personInChargeList.length > 1) {
      person_in_charge_name = person_in_charge_name + ' 외 ' + String(this.personInChargeList.length - 1) + '명';
    }

    return (
      <>
      <HeaderContainer style={{cursor:'grab'}}>
        {this.showTitleEditor ? (
          <TitleEditor
            inputRef={(input: any) => {this.inputComponent=input}}
            placeholder={'목록 타이틀을 입력해주세요'}
            rightElement={
              <>
              <Button
                text={'수정'}
                small={true}
                disabled={this.currentForm.title.trim() === ''}
                onClick={(e: any) => {this.handleEditSubmit(); e.stopPropagation();}}
              />
              <Button
                text={'X'}
                minimal={true}
                onClick={(e: any) => {this.toggleFinishEditing(); e.stopPropagation();}}
              />
              </>
            }
            value={this.currentForm.title}
            onChange={this.handleTitleChange}
            onClick={(e: any) => {e.stopPropagation();}}
          />
        ) : (
          <div
            onMouseEnter={(e) => {this.showHoverView = true}}
            onMouseLeave={(e) => {this.showHoverView = false}}
          >
          <TitleView
          >{this.props.title}</TitleView>
          {this.showHoverView && (
            <TitleHoverView
              onClick={(e: any) => {e.stopPropagation();}}
            >
              <>
                {person_in_charge_name ? (
                  <Button 
                    minimal={true}
                    text={person_in_charge_name}
                    style={{marginRight: 'auto'}}
                    onClick={(e: any) => {this.showPersonInChargeDialog=true; e.stopPropagation();}}
                  />
                ) : (
                  <Button 
                    minimal={true}
                    icon="user"
                    style={{marginRight: 'auto'}}
                    onClick={(e: any) => {this.showPersonInChargeDialog=true; e.stopPropagation();}}
                  />
                )}
                {this.props.metadata.is_editable && (
                <>
                  <TitleHoverViewButton 
                    icon="edit" 
                    minimal={true} 
                    onClick={(e: any) => {this.handleEdit(); e.stopPropagation();}}
                  />
                  <TitleHoverViewButton 
                    icon="trash" 
                    minimal={true} 
                    onClick={(e: any) => {this.showDeleterAlert=true; e.stopPropagation();}} 
                  />
                </>
                )}
              </>
            </TitleHoverView>
          )}
          </div>
        )}

        <Alert 
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.showDeleterAlert}
          onCancel={() => {this.showDeleterAlert=false}}
          onConfirm={this.handleDeleteSubmit}
          confirmButtonText={'예'}
          cancelButtonText={'아니오'}
        ><p>리스트를 삭제하시겠습니까?</p></Alert>

      </HeaderContainer>
      <Dialog
      icon="annotation"
      onClose={(e: any) => {this.showPersonInChargeDialog=false; e.stopPropagation();}}
      title={"목록 기본 담당자"}
      isOpen={this.showPersonInChargeDialog}
      usePortal={true}
      autoFocus={true}
      canEscapeKeyClose={true}
      enforceFocus={true}
      >
      <div 
        className={Classes.DIALOG_BODY}
        onClick={(e: any) => { e.stopPropagation(); }}
      >
        <p style={{textAlign:'center'}}>신규 태스크 생성 시, 담당자가 자동 지정됩니다.</p>
        {this.board && (
          <div style={{textAlign:'center'}}>
            <HTMLSelect
              onChange={(e: any) => { 
                this.personInCharge = e.target.value; 
                {
                  let pic_member = this.board && this.board.members.find(member => member.email === e.target.value);
                  if(pic_member) {
                    this.personInChargeList = [pic_member.id];
                  }
                }
                e.stopPropagation(); 
              }}
              value={this.personInCharge}
            >
              <option value=''>없음</option>
              {this.board.members.map(member => {
                return (
                  <option key={member.email} value={member.email}>{member.name}</option>
                );
              })}
            </HTMLSelect>
            &nbsp;
            <Button
              text={"지정"}
              intent={Intent.PRIMARY}
              onClick={this.handleChangePersonInCharge}
            />           
          </div>
        )}
      </div>
    </Dialog>
    </>
    );
  }
}

export default TaskListHeader;