import React, { Component, ChangeEvent, KeyboardEvent } from "react";

import { computed, observable, reaction } from "mobx";
import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import { Button, InputGroup, Intent } from "@blueprintjs/core";
import { FocusStyleManager } from "@blueprintjs/core";

import { AppStore } from "../../../store/AppStore";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";

const Container = styled.div`
`;

// 에디터
const TitleEditor = styled(InputGroup)`
`;

interface AddTaskBulletinPostFormProps {
  showTitleEditor: boolean;
  task_bulletin: string;
  onClose: () => void;
}

interface InjectedProps extends AddTaskBulletinPostFormProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class AddTaskBulletinPostForm extends Component<AddTaskBulletinPostFormProps> {
  @observable inputComponent: any = null;
  @observable title: string = '';

  get injected() {
    return this.props as InjectedProps;
  }
  
  @computed
  get store() {
    return this.injected.appStore.taskManagerStore;
  }
  @computed
  get board() {
    return this.store.currentBoard;
  }

  componentDidMount() {
    FocusStyleManager.onlyShowFocusOnTabs();

    this.inputComponent.focus();
    this.inputComponent.select();
    this.inputComponent.onkeypress = this.handleOnKeyPress;    
  }

  handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.title = e.target.value;
  };
  handleOnKeyPress = async(event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      if(this.title.trim() !== '') {
        this.handleSubmit();
      }
    }
  }
  handleCancel = () => {
    this.title = '';
    this.props.onClose();
  }  
  handleSubmit = async () => {
    try {
      const { email } = this.injected.appStore.userStore;
      await this.store.postTaskBulletinPost(this.props.task_bulletin, this.title, email);

      // 업데이트된 내용을 반영
      await this.store.fetchTaskBulletin(this.board ? this.board.projectGroup : '');

      this.props.onClose();
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  }  
  
  render() {
    return (
      <>
      {this.props.showTitleEditor && (
        <Container>
          <TitleEditor
            inputRef={(input: any) => {this.inputComponent=input}}
            placeholder={'운영정보 항목 타이틀'}
            rightElement={
              <>
              <Button
                text={'추가'}
                small={true}
                disabled={this.title.trim() === ''}
                onClick={this.handleSubmit}
              />
              <Button
                text={'X'}
                minimal={true}
                onClick={this.handleCancel}
              />
              </>
            }
            value={this.title}
            onChange={this.handleTitleChange}
          />
        </Container>
      )}
      </>
    );
  }
}

export default AddTaskBulletinPostForm;