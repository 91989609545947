import React, { Component, createRef, useRef } from "react";
import { GreyLabel } from "../../atoms/DetailReportComponents/DetailReportComponents";
import { Button, EditableText, Intent, TextArea, Tooltip } from "@blueprintjs/core";
import { TaskCommentForm } from "../../../store/forms/TaskManagerForm/TaskFormStore";
import styled from "styled-components/macro";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { Position } from "@blueprintjs/core/lib/esm/common/position";
import moment from "moment";

const EditButton = styled(Button)`
`;
const CommentContentView = styled.div`
  display: inline-block;
  word-wrap: break-word;
  width: 370px;
`;
const WhiteSpace = styled.div`
  white-space: pre-wrap;
`;
const CommentContentSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SaveCloseSection = styled.div``;

interface Props {
  viewMode: boolean | undefined;
  comment: TaskCommentForm;
  onEditConfirm: (e: any) => void;
  onDeleteConfirm: (e: any) => void;
}

@observer
class TaskCommentItemForm extends Component<Props> {
  @observable onEdit = false;
  @observable tempComment = "";

  private textAreaRef: HTMLTextAreaElement | null = null;

  handleCommentSubmit = (e: any) => {
    this.props.comment.setComment(this.tempComment);
    this.props.onEditConfirm(e);
    this.onEdit = false;
    this.tempComment = "";
  };
  handleCommentCancleSubmit = (e: any) => {
    this.tempComment = "";
    this.onEdit = false;
  };

  render() {
    const { comment } = this.props;

    let formattedDate = "";
    let now = moment();
    let updatedMoment = moment(comment.updatedAt);
    if (updatedMoment.isAfter(moment(now).subtract(1, "minute"))) {
      formattedDate = "< 1분전";
    } else if (updatedMoment.isAfter(moment(now).subtract(1, "hour"))) {
      formattedDate = `${Math.abs(updatedMoment.diff(moment(now), "minutes"))}분전`;
    } else if (updatedMoment.isAfter(moment(now).subtract(1, "day"))) {
      formattedDate = `${Math.abs(updatedMoment.diff(moment(now), "hours"))}시간전`;
    } else if (updatedMoment.isAfter(moment(now).subtract(1, "week"))) {
      formattedDate = `${Math.abs(updatedMoment.diff(moment(now), "day"))}일전`;
    } else {
      formattedDate = updatedMoment.format("YYYY-MM-DD");
    }
    return (
      <>
        <tr key={`comment${comment.id}`}>
          <td><GreyLabel>{comment.writerName}</GreyLabel></td>
          <td>
            <div>
              <div style={{
                float: "left",
                width: "400px",
                display: "flex",
                alignContent: "center",
                flexDirection: "column"
              }}>

                {this.onEdit ?
                  <TextArea
                    autoFocus={true}
                    style={{ width: "100%", overflowWrap: "break-word", overflow: "hidden" }}
                    growVertically={true}
                    large={true}
                    intent={Intent.PRIMARY}
                    onChange={(event) => {
                      this.tempComment = event.target.value;
                    }}
                    value={this.tempComment}
                    onKeyPress={async e => {
                      if ((e.key === "Enter" && e.shiftKey)) {
                        this.handleCommentSubmit(e);
                      }
                    }}

                    inputRef={
                      (ref: HTMLTextAreaElement | null) => {
                        this.textAreaRef = ref;
                      }
                    }
                    onFocus={(event) => {
                      event.target.style.height = "auto";
                      event.target.style.height = event.target.scrollHeight + "px";
                    }}
                  /> :
                  <CommentContentSection>
                    <Tooltip content={<span style={{ color: "white" }}>{comment.updatedAt}</span>}
                             position={Position.BOTTOM}>
                      <CommentContentView>
                        <WhiteSpace onClick={() => {
                          if (!this.tempComment)
                            this.tempComment = comment.comment;
                          this.onEdit = true;
                        }}>
                          {comment.comment}
                        </WhiteSpace>
                      </CommentContentView>
                    </Tooltip>
                    <div style={{ display: "inline-block" }}>
                      {(!this.props.viewMode && !this.onEdit) &&
                      <EditButton
                        icon="trash"
                        minimal={true}
                        onClick={this.props.onDeleteConfirm}
                      />
                      }
                    </div>
                  </CommentContentSection>
                }
                {/*<span style={{ color: "grey", fontSize: "11px" }}>{comment.updatedAt}</span>*/}
                <span style={{ color: "grey", fontSize: "11px" }}>{formattedDate}</span>

              </div>
            </div>
            {this.onEdit &&
            <SaveCloseSection style={{ marginTop: "10px" }}>
              <Button onClick={this.handleCommentSubmit}>저장</Button>
              <Button icon="cross" minimal={true}
                      onClick={this.handleCommentCancleSubmit}/>
            </SaveCloseSection>}

          </td>
        </tr>
      </>
    );
  }
}

export default TaskCommentItemForm;