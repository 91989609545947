import React, { Component, ChangeEvent, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { RouteComponentProps, Redirect } from "react-router";
import { AppStore } from "../../../store/AppStore";
import { FormMode } from "../../../types/formMode";
import { computed, observable } from "mobx";
import {
  Button, H3, H5, HTMLTable, Intent, Checkbox, Spinner, FormGroup, InputGroup,
  Icon, Callout
} from "@blueprintjs/core";
import {
  ClientContractRequest,
  RufreeContractRequest,
  RufreeContractRequestSimple
} from "../../../store/models/ContractRequest"; //RufreeContractRequestSimpleModel
import { Client } from "../../../store/models/Client";
import { ContractTemplate } from "../../../store/models/ContractTemplate";
import ClientSelection from "../../molecules/ClientSelection/ClientSelection";
import ContractTemplateSelection from "../../molecules/ContractTemplateSelection/ContractTemplateSelection";
import ClientContractRequestSelection from "../../molecules/ContractRequestSelection/ClientContractRequestSelection";
import RufreeContractRequestSelection from "../../molecules/ContractRequestSelection/RufreeContractRequestSelection";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";

import { FroalaConfigs } from "../../../utils/FroalaConfigs";
import FroalaEditor from "react-froala-wysiwyg";
import moment from "moment";
import CopyPaste from "../../organisms/CopyPaste/CopyPaste";
import { clearScreenDown } from "readline";
import TemporaryStorage from "../../molecules/Utility/TemporaryStorage/TemporaryStorage";
// import { mapRufreeContractSimple } from "../../../store/ContractStore";

const ContainerWrapper = styled.div`
  min-width: 1200px;
  display: flex;
`;
const Container = styled.div`
  margin-right: 20px;
`;
const ContractTitleRow = styled.div`
  display: flex;

  & > * + * {
    margin-left: 10px;
  }
`;
const ButtonsRow = styled.div`
  margin-top: 25px;
  float: right;

  & > * + * {
    margin-left: 5px;
  }
`;
const FlexTopRow = styled.div`
  display: flex;
  margin-bottom: 20px;

  & > * + * {
    margin-left: 50px;
  }
`;
const FlexRow = styled.div`
  display: flex;

  & > * + * {
    margin-left: 5px;
  }
`;

const FieldRow = styled.div`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 10px;
  }
`;
const CalloutWrapper = styled(Callout)`
  font-size: 12px;
  text-align: center;
`;
const Editor = styled(FroalaEditor)`
  margin-top: 50px;
`;

interface ApprovedContractFormPageProps {
  id: string;
  type: string;
  mode: FormMode;
}

interface InjectedProps extends ApprovedContractFormPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ApprovedContractFormPage extends Component<ApprovedContractFormPageProps> {
  @observable isFormInitiated = false;
  @observable isSaveSuccess = false;
  @observable editor_content = "";
  @observable pmUser = null;

  @observable explicitUpdateToggler = false;
  @observable intervalId = 0;

  @observable lastNode: any;
  private _ref: any;

  get injected() {
    return this.props as InjectedProps;
  }

  get pmUserStore() {
    return this.injected.appStore.pmUserStore;
  }

  @computed
  get formStore() {
    return this.injected.appStore.contractFromStore;
  }

  @computed
  get currentForm() {
    return this.formStore.approvedContractForm;
  }

  @computed
  get selectedTemplate() {
    const { template } = this.currentForm;
    const foundTemplate = this.injected.appStore.contractStore.templates.find(
      x => x.template_id === template
    );
    return foundTemplate;
  }

  @computed
  get selectedRufreeContract(): RufreeContractRequestSimple | undefined {
    const { contract, rufree_contract_detail } = this.currentForm;
    console.log("contract rufree", contract);
    console.log("contract_detail", rufree_contract_detail);

    //select from loaded data
    if (rufree_contract_detail && rufree_contract_detail.contractId == contract) {
      return rufree_contract_detail;
    }

    // return this.injected.appStore.contractStore.rufreeContracts.find(
    //   x => x.contractId === contract
    // );
    return this.injected.appStore.contractStore.unUsedRufreeContractsSimple
      .find(x => x.contractId == contract);
  }

  @computed
  get selectedClientContractRequestDetail() {
    const { contract, contract_detail } = this.currentForm;

    //select from loaded data
    if (contract_detail && contract_detail.contractId == contract) {
      return contract_detail;
    }
    //Select from unused LIst
    let unused_contract_list = this.injected.appStore.contractStore.unUsedClientContracts;
    const contract_detail_uu = unused_contract_list.find(x => x.contractId === contract);
    console.log("contract_detail_uu", contract_detail_uu);
    return contract_detail_uu;
  }


  @computed
  get selectedClientContractRequest() {
    const { contract, contract_detail } = this.currentForm;
    console.log("contract", contract);
    console.log("contract_detail", contract_detail);
    let contracts = this.injected.appStore.contractStore.unUsedClientContracts;
    const contract_detail_from_list = contracts.find(x => x.contractId === contract);
    console.log("contract_detail_from_list", contract_detail_from_list);
    // return this.injected.appStore.contractStore.clientContracts.find(
    //   x => x.contractId === contract
    // );
    if (contract_detail != null)
      return contract_detail;
    else
      return contracts.find(x => x.contractId === contract);
    // return undefined;
  }

  @computed
  get selectedClient() {
    return this.injected.appStore.clientStore.clients.find(
      x => x.clientId === this.currentForm.client
    );
  }

  @computed
  get clientContractRequestItems() {
    let contracts = this.injected.appStore.contractStore.unUsedClientContracts;
    if (this.selectedClientContractRequest) {
      return contracts.concat([this.selectedClientContractRequest]);
      // contracts.push(this.selectedClientContractRequest);
    }

    return contracts;
  }

  @computed
  get rufreeContractRequestItems() {
    // const unused_contracts = this.injected.appStore.contractStore.unUsedRufreeContracts;
    const unused_contracts = this.injected.appStore.contractStore.unUsedRufreeContractsSimple;
    // let contracts = undefined
    //   unused_contracts.map(x =>
    //   RufreeContractRequestSimpleModel.create({
    //     ...x
    //   })
    // )

    //TODO 여기서 시작하기
    if (this.selectedRufreeContract) {
      // contracts.push(this.selectedRufreeContract);
      return unused_contracts.concat([this.selectedRufreeContract]);
    }
    return unused_contracts;
  }


  handleTemplateSelect = (selectedValue: ContractTemplate) => {
    this.currentForm.setTemplate(selectedValue.template_id);
  };

  handleGetTemplateBody = () => {
    if (this.selectedTemplate != null) {
      this.editor_content = this.selectedTemplate.template_body;
    }
  };

  handleClientChange = (client: Client) => {
    this.currentForm.setClient(client.clientId);
  };

  handleClientContractRequestSelect = (selectedValue: ClientContractRequest) => {
    console.log("selectedValue", selectedValue);
    this.currentForm.setContract(selectedValue.contractId);
    this.currentForm.setClient(selectedValue.client);
  };

  handleRufreeContractRequestSelect = (selectedValue: RufreeContractRequestSimple) => {
    this.currentForm.setContract(selectedValue.contractId);
  };

  handleSaveClick = async () => {
    try {
      this.handleEditorContentChange(this._ref.editor.html.get());
      await this.formStore.saveApprovedContract();
      AppToaster.show({
        message: `${(this.props.mode === FormMode.Edit) ? "" : "새로운"} 계약서가 저장되었습니다.`,
        intent: Intent.SUCCESS
      });
    } catch (e) {
      let error = e;
      // try{
      //   const data = e.response.data;
      //   error = JSON.stringify(data);
      // } catch(e){
      //   error = e
      // }

      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  handleCompleteClick = async () => {
    try {
      this.handleEditorContentChange(this._ref.editor.html.get());
      await this.formStore.saveApprovedContract();
      AppToaster.show({
        message: `${(this.props.mode === FormMode.Edit) ? "" : "새로운"} 계약서가 저장되었습니다.`,
        intent: Intent.SUCCESS
      });

      this.isSaveSuccess = true;
    } catch (e) {
      let error = "";
      try {
        error = JSON.stringify(e.response.data);
      } catch (e) {
        error = e;
      }
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  paste = (value: string) => {
    // this._ref.editor.selection.save();

    console.log("copyPaste " + value);
    // console.log(this._ref.,'this._ref.editor');
    // if (this.lastNode) {
    //   this._ref.editor.selection.setAtStart(this._ref.editor.$el.get(0));
    this._ref.editor.selection.restore();
    // console.log("this._ref.editor.selection.get()",this._ref.editor.selection.get());
    // this._ref.editor.selection.setAtStart(this._ref.editor.$el.get(0));
    // const html = this._ref.editor.html.get(true);
    // console.log("html",html);

    // this._ref.editor.selection.setAtStart(this._ref.editor.$el.get(0));
    this._ref.editor.html.insert(value);
    // this._ref.editor.undo.saveStep();

    // }
  };
  handleEditorContentChange = (model: string) => {
    console.log("onModelChange");
    this.currentForm.setEditorContent(model);

    this.editor_content = model;
  };

  async componentDidMount() {
    const { id, type, mode } = this.props;
    const template_id = window.location.search.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function(str, key, value) {
        return (key === "template_id") ? value : "";
      }
    );

    await this.pmUserStore.fetchPMUsers();
    await this.injected.appStore.contractStore.fetchTemplates();
    if (type === "client") {
      // await this.injected.appStore.contractStore.fetchClientContracts({
      //   status:'',
      //   searchText:''
      // });
      await this.injected.appStore.contractStore.fetchUnusedClientContracts();
    } else {
      // await this.injected.appStore.contractStore.fetchRufreeContracts({
      //   status:'',
      //   searchText:''
      // });
      await this.injected.appStore.contractStore.fetchUnusedRufreeContracts();
    }

    if (mode === FormMode.Edit) {
      await this.formStore.fetchApprovedContract(id, type);
      this.editor_content = this.currentForm.editor_content;
    } else {
      this.formStore.initApprovedContractForm(type);

      if (template_id) {
        this.currentForm.setTemplate(template_id);
        this.handleGetTemplateBody();
      }
    }

    this.intervalId = setInterval(()=>{
      // console.log("auto saving");
      this.currentForm.setEditorContent(this._ref.editor.html.get());
      this.explicitUpdateToggler = !this.explicitUpdateToggler;
      // this.handleEditorContentChange(this._ref.editor.html.get());
    },5000);

    this.isFormInitiated = true;
  }
  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  render() {
    const { type, mode } = this.props;
    if (!this.isFormInitiated) {
      return <Spinner/>;
    }

    const {
      currentForm,
      selectedClientContractRequest,
      selectedRufreeContract,
      selectedTemplate
    } = this;

    if (this.isSaveSuccess) {
      if (type === "client") {
        return <Redirect to={`/contract/client/${currentForm.approved_contract_id}/read`} push={true}/>;
      }
      return <Redirect to={`/contract/rufree/${currentForm.approved_contract_id}/read`} push={true}/>;
    }

    return (
      <>
        <H3>{type === "client" ? "클라이언트" : "알유프리"} 계약서</H3>


        <TemporaryStorage keyName={`${type}ContrcreateProjectFormStoreactApproved`} title={"contract_name"} setForm={(val) => {
          this.formStore.setApprovedContractForm(val);
          this.editor_content = this.currentForm.editor_content;
        }} currentForm={this.currentForm} explicitUpdateToggler={this.explicitUpdateToggler}/>

        <FlexTopRow>
          <H5>{currentForm.approved_contract_id}</H5>
          <H5>{selectedTemplate && selectedTemplate.template_name}</H5>
          <H5>{currentForm.contract_name}</H5>
        </FlexTopRow>


        <ContainerWrapper>

          <Container style={{ minWidth: "793.701px" }}>
            <FlexTopRow>
              <HTMLTable
                condensed={true}
                style={{ width: "500px", tableLayout: "fixed", marginBottom: "30px" }}
              >
                <thead>
                <tr>
                  <th>계약요청서</th>
                  <th>종류</th>
                  <th>고유번호</th>
                  <th>계약자</th>
                  <th>담당메니저</th>
                </tr>
                </thead>
                <tbody>
                {
                  type === "client" ? (
                    selectedClientContractRequest ? (
                      <tr>
                        <td>{selectedClientContractRequest.contractId}</td>
                        <td>{"클라이언트"}</td>
                        <td>{selectedClientContractRequest.client}</td>
                        <td>{selectedClientContractRequest.contractorName}</td>
                        <td>{selectedClientContractRequest.managerName}</td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={5}>
                          <CalloutWrapper>
                            선택된 계약요청서가 있을 경우에만, 해당 정보가 나타납니다.
                          </CalloutWrapper>
                        </td>
                      </tr>
                    )
                  ) : (
                    selectedRufreeContract ? (
                      <tr>
                        <td>{selectedRufreeContract.contractId}</td>
                        <td>{"알유프리"}</td>
                        <td>{selectedRufreeContract.rufreeUser}</td>
                        <td>{selectedRufreeContract.contractId}</td>
                        <td>{currentForm.manager_name}</td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={5}>
                          <CalloutWrapper>
                            선택된 계약요청서가 있을 경우에만, 해당 정보가 나타납니다.
                          </CalloutWrapper>
                        </td>
                      </tr>
                    )
                  )
                }

                </tbody>
              </HTMLTable>

              <ButtonsRow>
                <Link to={`/contract?tab=contract&type=client`}>
                  <Button text="취소"/>
                </Link>
                <Button
                  text="저장"
                  intent={Intent.SUCCESS}
                  onClick={this.handleSaveClick}
                />
                <Button
                  text="완료"
                  intent={Intent.PRIMARY}
                  onClick={this.handleCompleteClick}
                />
              </ButtonsRow>

            </FlexTopRow>

            {
              <Editor
                ref={(ref) => (this._ref = ref)}
                model={this.editor_content}
                config={{
                  ...FroalaConfigs,
                  events: {
                    click: (e: any, editor: any) => {
                      // console.log(e);
                      // console.log("ref editor", this._ref.editor.html.get());
                      // this.lastNode = this._ref.editor.selection.save()
                      this._ref.editor.selection.save();
                      // this._ref.editor.selection.setAfter()
                      // console.log("this._ref.editor.selection.get()",this._ref.editor.selection.get());
                      // this._ref.editor.selection.save();
                      // this._ref.editor.selection.save();

                      // console.log("ref editor",this._ref.editor.selection.info(this._ref.editor.node));
                      // console.log("ref editor",this._ref.editor.position.forSelection());
                      // console.log("ref editor",this._ref.editor.getBoundingRect());
                      // console.log(editor.selection.get());
                    },
                    keyup: (e: any, editor: any) => {
                      // console.log("keyup", e, editor);

                      this._ref.editor.selection.save();
                      // this._ref.editor.commands.bold();
                      // this._ref.editor.commands.clearFormatting();

                      // this._ref.editor.save.force();

                      // currentForm.setEditorContent(this._ref.editor.html.get(true));

                    }
                    // keypress: (e:any)=>{
                    //   console.log("keypress");
                    //   this._ref.editor.selection.save();
                    // }
                    // contentChanged: (e:any)=>{
                    //   console.log('contentChanged');
                    // }
                  },
                  height: 800
                }}
                // onModelChange={(model: string) => {
                //   console.log('onModelChange');
                //   currentForm.setEditorContent(model);
                //   this.editor_content = model;
                // }}
                onModelChange={this.handleEditorContentChange}
              >


              </Editor>

            }
          </Container>


          <Container style={{ maxWidth: "300px" }}>
            <Checkbox checked={currentForm.comment_opened} label="코멘트 오픈" onChange={() => {
              currentForm.toggleCommentOpened();
            }}/>

            <FormGroup label={<strong>양식</strong>}>
              <FlexRow>
                <ContractTemplateSelection
                  currentValue={this.selectedTemplate}
                  onChange={this.handleTemplateSelect}
                  blockFetchingData={false}/>
                <Button
                  text="가져오기"
                  intent={Intent.PRIMARY}
                  onClick={this.handleGetTemplateBody}/>
              </FlexRow>
            </FormGroup>

            <FormGroup label={<strong>계약명(프로젝트명)</strong>}>
              {
                type === "client" ? (
                  <InputGroup
                    style={{ width: "300px" }}
                    value={currentForm.contract_name}
                    onChange={
                      (e: ChangeEvent<HTMLInputElement>) => {
                        currentForm.setContractName(e.target.value);
                      }
                    }
                  />
                ) : (
                  <InputGroup
                    style={{ width: "300px" }}
                    value={currentForm.contract_name}
                    onChange={
                      (e: ChangeEvent<HTMLInputElement>) => {
                        currentForm.setContractName(e.target.value);
                      }
                    }
                  />
                )
              }

            </FormGroup>

            <FormGroup label={<strong>계약요청서</strong>}>
              {
                type === "client" ? (
                  <ClientContractRequestSelection
                    currentValue={this.selectedClientContractRequestDetail}
                    onChange={this.handleClientContractRequestSelect}
                    blockFetchingData={true}
                    items={this.clientContractRequestItems}/>
                ) : (
                  <RufreeContractRequestSelection
                    currentValue={this.selectedRufreeContract}
                    onChange={this.handleRufreeContractRequestSelect}
                    blockFetchingData={true}
                    items={this.rufreeContractRequestItems}/>
                )
              }
            </FormGroup>


            {
              type === "client" && (
                <FormGroup label={<strong>클라이언트</strong>}>
                  <ClientSelection
                    onChange={this.handleClientChange}
                    currentValue={this.selectedClient}
                    blockFetchingData={false}
                  />
                </FormGroup>
              )
            }

            {
              type === "client" ? (
                selectedClientContractRequest && (
                  <>
                    <CopyPaste
                      title="대표자명"
                      value={selectedClientContractRequest.representativeName}
                      paste={this.paste}
                    />
                    <CopyPaste
                      title="사업자등록번호"
                      value={selectedClientContractRequest.representativeName}
                      paste={this.paste}
                    />
                    <CopyPaste
                      title="주소"
                      value={selectedClientContractRequest.representativeName}
                      paste={this.paste}
                    />
                    <CopyPaste
                      title="계약체결일"
                      value={moment(selectedClientContractRequest.contractDate).format("YYYY-MM-DD")}
                      paste={this.paste}
                    />
                    <CopyPaste
                      title="계약금비율"
                      value={selectedClientContractRequest.contractFeeRate}
                      paste={this.paste}
                    />
                    <CopyPaste
                      title="계약범위"
                      value={selectedClientContractRequest.contractRange}
                      paste={this.paste}
                    />
                    <CopyPaste
                      title="특약사항"
                      value={selectedClientContractRequest.specialContract}
                      paste={this.paste}
                    />
                    <CopyPaste
                      title="비고"
                      value={selectedClientContractRequest.comment}
                      paste={this.paste}
                    />
                  </>
                )
              ) : (
                selectedRufreeContract && (
                  <>
                    <CopyPaste
                      title="알유프리"
                      value={selectedRufreeContract.rufreeUser||""}
                      paste={this.paste}
                    />
                    <CopyPaste
                      title="이메일"
                      value={selectedRufreeContract.rufreeUser||""}
                      paste={this.paste}
                    />
                    <CopyPaste
                      title="주소"
                      value={selectedRufreeContract.rufreeUser||""}
                      paste={this.paste}
                    />
                    <CopyPaste
                      title="계약체결일"
                      value={moment(selectedRufreeContract.contractDate).format("YYYY-MM-DD")}
                      paste={this.paste}
                    />
                    <CopyPaste
                      title="계약범위"
                      value={selectedRufreeContract.contractRange}
                      paste={this.paste}
                    />
                    <CopyPaste
                      title="스프린트 기간"
                      value={selectedRufreeContract.sprintPeriod}
                      paste={this.paste}
                    />
                    <CopyPaste
                      title="특약사항"
                      value={selectedRufreeContract.specialContract}
                      paste={this.paste}
                    />
                    <CopyPaste
                      title="비고"
                      value={selectedRufreeContract.comment}
                      paste={this.paste}
                    />
                  </>
                )
              )
            }


          </Container>

        </ContainerWrapper>
      </>
    );
  }
}

export default ApprovedContractFormPage;
