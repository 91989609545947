import { types, getParent } from "mobx-state-tree";
import { SprintCheckModel } from "./SprintCheck";
import { getSprintDaysRate } from "../../utils/getSprintDaysRate";
import { InspectionForm } from "./InspectionForm";


export const SeesoServiceFormModel = types
  .model("SeesoServiceForm", {
    service: types.optional(types.string, ""),
    cost: types.optional(types.number, 0),
    individual: types.optional(types.boolean, true),
    sprints: types.array(SprintCheckModel)
  })
  .views(self => ({
    costBySprintDaysRate({
      sprintIndex,
      sprintDays
    }: {
      sprintIndex: number;
      sprintDays: number;
    }) {
      return getSprintDaysRate({
        sprintIndex,
        sprintDays,
        originalPrice: self.cost
      });
    },
    get totalCost() {
      const parent: InspectionForm = getParent(self, 2);
      let cost = 0;

      self.sprints.forEach((sprint, sprintIndex) => {
        if (sprint.value) {
          const sprintDays = parent.sprintDays[sprintIndex].value;
          const costBySprintDaysRate = this.costBySprintDaysRate({
            sprintIndex,
            sprintDays
          });

          // 해당 스프린트에 투입된 알유프리만큼 금액을 곱하는 기능
          if (self.individual) {
            cost += costBySprintDaysRate * parent.rufreeNumInSprint(sprintIndex);
          } else {
            cost += costBySprintDaysRate;
          }
        }
      });
      
      return cost;
    },
    get sprintsToNumber() {
      const sprints: number[] = [];

      self.sprints.forEach((s, index) => {
        if(!!s.value) sprints.push(index);
      });

      return sprints;
    }
  }))
  .actions(self => ({
    setService(value: string) {
      self.service = value;
    },
    setCost(value: number) {
      self.cost = value;
    },
    setIndividual(value: boolean) {
      self.individual = value;
    }
  }));

type SeesoServiceFormType = typeof SeesoServiceFormModel.Type;
export interface SeesoServiceForm extends SeesoServiceFormType {}
