import React, { Component, ChangeEvent } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import ProjectGroupSelection from "../ProjectGroupSelection/ProjectGroupSelection";
import { computed } from "mobx";
import {
  PMReportForm,
  PMReportMood
} from "../../../store/forms/PMReportForm/PMReportForm";
import { ProjectGroup } from "../../../store/models/ProjectGroup";
import { TextArea } from "@blueprintjs/core";
import MoodSelection from "../MoodSelection/MoodSelection";

const Wrapper = styled.div`
  margin-bottom: 30px;
`;

const TextContainer = styled.div`
  margin-top: 10px;
`;

const MoodContainer = styled.div`
  margin-top: 10px;
`;

interface PMReportFormViewProps {
  reportForm: PMReportForm;
}

interface InjectedProps extends PMReportFormViewProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class PMReportFormView extends Component<PMReportFormViewProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get pmProjectGroups() {
    const { userStore, projectGroupStore } = this.injected.appStore;

    const { username } = userStore;
    return projectGroupStore.projectGroups.filter(x =>
      x.managers.find(manager => manager.name === username)
    );
  }

  @computed
  get selectedProjectGroup() {
    return this.injected.appStore.projectGroupStore.projectGroups.find(
      x => x.groupId === this.props.reportForm.projectGroup
    );
  }

  componentDidMount() {}

  handleProjectGroupChange = (projectGroup: ProjectGroup) => {
    this.props.reportForm.setProjectGroup(projectGroup.groupId);
  };

  handleTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.props.reportForm.setText(e.currentTarget.value);
  };

  handleMoodChange = (mood: PMReportMood) => {
    this.props.reportForm.setMood(mood);
  };

  render() {
    const { reportForm } = this.props;

    return (
      <Wrapper>
        <ProjectGroupSelection
          blockFetchingData={true}
          items={this.pmProjectGroups}
          currentValue={this.selectedProjectGroup}
          onChange={this.handleProjectGroupChange}
        />
        <TextContainer>
          <TextArea
            value={reportForm.text}
            onChange={this.handleTextChange}
            placeholder="내용 기록"
            style={{ width: "100%", minHeight: "200px" }}
          />
        </TextContainer>
        <MoodContainer>
          <MoodSelection
            currentValue={reportForm.mood as PMReportMood}
            onChange={this.handleMoodChange}
          />
        </MoodContainer>
      </Wrapper>
    );
  }
}

export default PMReportFormView;
