import React, { Component, FC, useRef, useEffect } from "react";
import { select, scaleLinear, axisBottom, scaleBand, axisLeft, event } from "d3";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { observable } from "mobx";
import moment from "moment";
import { 
  Classes,
} from "@blueprintjs/core";
import {
  EtcSection, EtcSectionBody, EtcSectionTitle, H6Wrapper
} from "../../atoms/DashboardComponents/DashboardComponents";

const BarWrapper = styled.svg`
  width: 100%;
  height: 95%;
  rect {
    fill: #137CBD;
  }
  .bar-text {
    font-size: 10px;
    font-weight: 800;
  }
  text {
    font-size: 10px;
  }
`;

const RegistedRufreeBar: FC<{
  registedCounts: {
    date: string, count: number
  }[]
}> = ({ registedCounts }) => {
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if(svgRef && registedCounts.length > 0) {
      const margin = {top: 10, right: 0, bottom: 0, left: 20};
      const width = svgRef.current ? svgRef.current.getBoundingClientRect().width : 0;
      const height = svgRef.current ? svgRef.current.getBoundingClientRect().height-20 : 0;
      // const barWidth = svgRef.current ? (width - margin.left) / registedCounts.length : 0;
      const maxCount = Math.max.apply(null, registedCounts.map(d => d.count));
      const yScaleMax = maxCount < 10 ? 10 : maxCount+1;
      const barHeight = (height - margin.top - margin.bottom) / yScaleMax;

      const xScale = scaleBand()
        .domain(registedCounts.map(function(d) { return d.date; } ))
        .range([0, width-margin.left-margin.right]);
      const yScale = scaleLinear().domain([0, yScaleMax]).range([height - margin.bottom, margin.top]);

      const xAxis = axisBottom(xScale);
      const yAxis = axisLeft(yScale);
      
      const svg = select(svgRef.current);
      const tooltip = select(".rufreebar-container")
        .append("div")
        .attr("class", "toolTip")
        .style("position", "absolute")
        .style("min-width", "40px")
        .style("background", "#000000")
        .style("color", "#ffffff")
        .style("font-size", "12px")
        .style("padding", "3px")
        .style("display", "none");

      svg.selectAll("*").remove();
      svg.selectAll("rect")
        .data(registedCounts)
        .enter().append("rect")
          .attr("height", function(d, i) {return d.count * barHeight})
          .attr("width", xScale.bandwidth())
          .attr("x", function(d, i) {return (xScale.bandwidth() * i) + margin.left})
          .attr("y", function(d, i) {return ((height - d.count * barHeight))})
          .on("mousemove", function(d){
            tooltip
              .style("left", event.pageX - 5 + "px")
              .style("top", event.pageY - 25 + "px")
              .style("display", "inline-block")
              .html(`${d.date}: ${d.count}명`);
          })
          .on("mouseout", function(d){ tooltip.style("display", "none");});
      svg.selectAll("text")
        .data(registedCounts)
        .enter().append("text")
        .text(function(d, i) {return d.count > 0 ? d.count : ''})
          .attr("class", "bar-text")
          .attr("x", function(d, i) {return (xScale.bandwidth() * i) + margin.left + 3})
          .attr("y", function(d, i) {return ((height - d.count * barHeight) - 3)});

      svg.append("g")
        .attr("transform", `translate(${margin.left}, 0)`)
        .call(yAxis);
          
      svg.append("g")
        .attr("transform", `translate(${margin.left}, ${height - margin.bottom})`)
        .call(xAxis)
    }
  }, [registedCounts]);

  return (
      <BarWrapper ref={svgRef} />
  );
}


interface RegistedRufreeSectionProps {
  daysbefore: number;
}
interface InjectedProps extends RegistedRufreeSectionProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class RegistedRufreeSection extends Component<RegistedRufreeSectionProps> {
  @observable readyToShow = false;

  get injected() {
    return this.props as InjectedProps;
  }

  get rufreeStore() {
    return this.injected.appStore.rufreeStore;
  }

  async componentDidMount() {
    this.readyToShow = false;

    await this.rufreeStore.fetchAllRufrees();

    this.readyToShow = true;
  }

  render() {
    const days = this.props.daysbefore;
    const baseDate = moment().subtract(days, 'days');
    const { rufrees } = this.rufreeStore;
    const recentRegistedRufrees = rufrees.filter(rufree => moment(rufree.createdAt).isAfter(baseDate));
    const registedCount: {
      date: string, count: number
    }[] = [];


    for (let i = 0; i < days; i++) {
      registedCount.push({
          date: baseDate.format('MM-DD'),
          count: recentRegistedRufrees.filter(rufree => (
            moment(rufree.createdAt, 'YYYY-MM-DD').isSame(baseDate.format('YYYY-MM-DD'))
          )).length
        }
      )

      baseDate.add(1, 'days');
    }

    return (
      <EtcSection style={{width: 'calc(100% - 350px)'}}>

        <EtcSectionTitle>
          <H6Wrapper>알유프리 가입 추이({recentRegistedRufrees.length})</H6Wrapper>
        </EtcSectionTitle>

        <EtcSectionBody
          className={`rufreebar-container ${this.readyToShow ? '' : Classes.SKELETON}`}
        >
          <RegistedRufreeBar registedCounts={registedCount} />
        </EtcSectionBody>

      </EtcSection>
    );
  }
}

export default RegistedRufreeSection;