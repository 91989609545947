import React, { FC } from "react";
import styled from 'styled-components/macro';
import moment from 'moment';

import { Text } from "@blueprintjs/core";


const ProjectGroupStatus: FC<{status: string}> = props => {

  const COLORS: {[key: string]: string} = {
    '진행중': '#FF8300',
    '미진행': '#5C7080',
    '종료': '#0D8050',
    '홀딩중': '#752F75'
  }

  const Wrapper = styled(Text)<{color: string}>`
    color: ${props => props.color};
  `;

  const color = COLORS[props.status];

  return (
    <Wrapper color={color}>
      {props.status}
    </Wrapper>
  )
}

export default ProjectGroupStatus;
