import { types, flow } from "mobx-state-tree";
import { RiskModel } from "./Risk";
import axios from "axios";

export const RiskStoreModel = types
  .model("RiskStore", {
    risks: types.map(RiskModel)
  })
  .views(self => ({
    riskOfProject(projectId: string) {
      return Array.from(self.risks.values()).find(
        x => x.project.projectId === projectId
      );
    }
  }))
  .actions(self => {
    const fetchRiskOfProject = flow(function*(projectId) {
      try {
        const { data } = yield axios.get(`/projects/${projectId}/risk`);

        const risk = RiskModel.create({
          id: data.id,
          createdAt: data.created_at,
          updatedAt: data.updated_at,
          humanRisks: data.human_risks,
          slackAffinity: data.slack_affinity,
          trelloAffinity: data.trello_affinity,
          onlineMeetingAffinity: data.online_meeting_affinity,
          openDate: data.open_date,
          project: data.project
        });

        self.risks.put(risk);
      } catch (e) {
        console.log("fetchRiskOfProject error", e);
        throw e;
      }
    });

    return {
      fetchRiskOfProject
    };
  });

type RiskStoreType = typeof RiskStoreModel.Type;
export interface RiskStore extends RiskStoreType {}
