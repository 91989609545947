import { types, flow } from "mobx-state-tree";
import { ReceptionModel } from "./models/Reception";
import { ReceptionListResponse, ReceptionResponse } from "../types/reception";
import axios from "axios";
import sortBy from "lodash/sortBy";

const mapReception = (x: ReceptionResponse) => {
  return {
    id: x.id,
    receptionNumber: x.receptionNumber,
    receptionId: x.reception_id,
    title: x.title,
    areaType: x.area_type,
    areaTypeEtcText: x.area_type_etc_text,
    area: x.area,
    areaEtcText: x.area_etc_text,
    dataPrepared: x.data_prepared,
    etcReference: x.etc_reference,
    existReference: x.exist_reference,
    referenceText: x.reference_text,
    priority: x.priority,
    feature: x.feature,
    detail: x.detail,
    googleDriveLink: x.google_drive_link,
    driveLinks: x.drive_links,
    dateMeeting: x.date_meeting,
    dateRegister: x.date_register,
    status: x.status,
    dataFile: x.data_file,
    clientUuid: x.client_uuid,
    memo: x.memo,
    client: x.client,
    clientName: x.client_name,
    manager: x.manager,
    inspector: x.inspector,
    inspectorName: x.inspector_name,
    clientUser: x.clientUser,
    serviceType: x.service_type,
    // projectId: x.project_id,
    consults: x.consults.map((item)=> {
      return ({
        projectId: item.project_id,
        createdAt: item.created_at,
        updatedAt: item.updated_at,
        name: item.name,
        consultedDate: item.consulted_date,
        consultant: item.consultant,
        consultantName: item.consultant_name,
        consultantEmail: item.consultant_email,

        clientName: item.client_name,
        clientEmail: item.client_email,
        clientPhone: item.client_phone,

        receptionId: item.reception_id
      });
    }),
    // inspectionIds: x.inspection_ids,
    inspection: x.inspection,
    consultsId: x.consults_id
  };
};

export const ReceptionStoreModel = types
  .model("ReceptionStore", {
    receptions: types.array(ReceptionModel),

    // For Pagination Query
    currentPage: types.optional(types.number, 1),
    pageGroup: types.optional(types.number, 1),
    pageSize: types.optional(types.number, 15),
    totalPages: types.optional(types.number, 1),

    statusFilter: types.optional(types.string, ""),
    searchFilter: types.optional(types.string, "")
  })
  .views(self => ({
    get sortedReceptions() {
      return sortBy(
        self.receptions.slice(),
        reception => reception.id
      ).reverse();
    }
  }))
  .actions(self => ({
    setCurentPage(value: number) {
      self.currentPage = value;
    },
    setPageGroup(value: number) {
      self.pageGroup = value;
    },
    setStatusFilter(value: string) {
      self.statusFilter = value;
    },
    setSearchFilter(value: string) {
      self.searchFilter = value;
    },
    getReceptionDetail(id: number){
      console.log("self.receptions",self.receptions);
      console.log("id",id);
      const found = self.receptions.find(reception => {
        console.log("reception.id",reception.id);
        return reception.id === id
      }) || undefined;
      console.log("found",found);

      return found
    },
    setReceptions(values: any[]) {
      /*
        graphQL 응닶값으로 생성합니다.
      */
      const items = values.map(x =>
        ReceptionModel.create({
          id: parseInt(x.id),
          receptionNumber: x.receptionId,
          receptionId: x.receptionId,
          title: x.title,
          areaType: [],
          areaTypeEtcText: "",
          area: [],
          areaEtcText: "",
          dataPrepared: [],
          etcReference: [],
          existReference: null,
          referenceText: "",
          priority: "",
          feature: [],
          detail: "",
          googleDriveLink: null,
          driveLinks: [],
          dateMeeting: "",
          dateRegister: x.dateRegister,
          status: "",
          dataFile: null,
          clientUuid: null,
          memo: "",
          client: null,
          clientName: null,
          manager: null,
          inspector: null,
          inspectorName: null,
          clientUser: null,
          serviceType: null,
          consults: [],
          inspection: null,
          consultsId: ""
        })
      );
      self.receptions.replace(items);
    }
  }))
  .actions(self => {
    const fetchReceptions = flow(function* (created_after?: string) {
      const params: {
        date_register__gt?: string,
        page?: number,
        page_size?: number,
        status?: string,
        search?: string,

      } = {};

      if (created_after) {
        params.date_register__gt = created_after;
      }
      if (self.searchFilter)
        params.search = self.searchFilter;
      params.status = self.statusFilter;
      params.page = self.currentPage;
      params.page_size = self.pageSize;

      try {
        const { data }: { data: ReceptionListResponse } = yield axios.get(
          "/reception",
          {
            params
          }
        );
        const newReceptions = data.results.map(x =>
          ReceptionModel.create(mapReception(x))
        );

        self.totalPages = data.total_pages;
        self.receptions.replace(newReceptions);
      } catch (e) {
        console.log("fetchReception error", e);
        throw e;
      }
    });
    const fetchAllReceptions = flow(function* (created_after?: string) {
      const params: {
        date_register__gt?: string,
      } = {};

      if (created_after) {
        params.date_register__gt = created_after;
      }

      try {
        const { data }: { data: ReceptionResponse[] } = yield axios.get(
          "/reception/v2",
          {
            params
          }
        );
        const newReceptions = data.map(x =>
          ReceptionModel.create(mapReception(x))
        );

        self.receptions.replace(newReceptions);
      } catch (e) {
        console.log("fetchReception error", e);
        throw e;
      }
    });

    const fetchReception = flow(function* (receptionId: string) {
      try {
        const { data }: { data: ReceptionResponse } = yield axios.get(
          `/reception/v2/${receptionId}`
        );

        const newReception = ReceptionModel.create(mapReception(data));

        self.receptions.forEach(x => {
          if (x.receptionId === receptionId) {
            self.receptions.remove(x);
          }
        });

        self.receptions.push(newReception);
      } catch (e) {
        console.log("fetchReception error", e);
        throw e;
      }
    });

    return {
      fetchAllReceptions,
      fetchReceptions,
      fetchReception
    };
  });

type ReceptionStoreType = typeof ReceptionStoreModel.Type;

export interface ReceptionStore extends ReceptionStoreType {
}
