import { types, flow } from "mobx-state-tree";

export const RequestCancelFormModel = types
  .model("RequestCancelForm", {
    id: types.maybeNull(types.number),
    manager: types.maybeNull(types.number),
    cancelReason: types.optional(types.string, ""),
  })
  .actions(self => ({
    setId(value: number) {
      self.id = value;
    },
    setManager(value: number) {
      self.manager = value;
    },
    setCancelReason(value: string) {
      self.cancelReason = value;
    }
  }));
type RequestCancelFormType = typeof RequestCancelFormModel.Type;
export interface RequestCancelForm extends RequestCancelFormType {}
