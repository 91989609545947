import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { RouteComponentProps } from "react-router";
import { computed, observable } from "mobx";
import { Spinner, Button, Intent, Card } from "@blueprintjs/core";
import SprintReportCard from "../../organisms/SprintReportCard/SprintReportCard";

const Wrapper = styled.div``;

const CardContainer = styled.div`
  max-width: 750px;
`;

const ButtonsRow = styled.div`
  margin-top: 30px;

  & > * + * {
    margin-left: 10px;
  }
`;

interface PageParams {
  projectGroupId: string;
  sprintReportId: string;
}

interface SprintReportDetailPageProps extends RouteComponentProps<PageParams> {}

interface InjectedProps extends SprintReportDetailPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class SprintReportDetailPage extends Component<SprintReportDetailPageProps> {
  @observable readyToShow = false;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get projectGroup() {
    const { projectGroupId } = this.props.match.params;

    return this.injected.appStore.projectGroupStore.projectGroups.find(
      x => x.groupId === projectGroupId
    );
  }

  @computed
  get sprintReport() {
    if (!this.projectGroup) {
      return null;
    }

    const { sprintReportId } = this.props.match.params;
    return this.projectGroup.sprintReports.find(
      x => x.compoundKey === sprintReportId
    );
  }

  async componentDidMount() {
    try {
      const { projectGroupId } = this.props.match.params;

      const { projectGroupStore, inspectionStore } = this.injected.appStore;

      await projectGroupStore.fetchProjectGroup(projectGroupId);

      if (this.projectGroup) {
        await Promise.all(
          this.projectGroup.inspections.map(async inspectionId => {
            await inspectionStore.fetchInspectionById(inspectionId);
          })
        );
      }
    } catch (e) {
    } finally {
      this.readyToShow = true;
    }
  }

  handleCancel = () => {
    const { projectGroupId } = this.props.match.params;

    this.props.history.push(`/project-groups/${projectGroupId}`);
  };

  handleDownload = () => {
    const { projectGroupId, sprintReportId } = this.props.match.params;
    const windowTitle = "스프린트 보고서";

    window.open(
      `/project-groups/${projectGroupId}/sprint-reports/${sprintReportId}/print`,
      windowTitle,
      "width=750,height=950"
    );
  };

  render() {
    if (!this.readyToShow) {
      return <Spinner />;
    }

    if (!this.sprintReport) {
      return <div>표시할 SprintReport 가 없습니다.</div>;
    }

    return (
      <Wrapper>
        <CardContainer>
          <Card>
            <SprintReportCard sprintReport={this.sprintReport} />
          </Card>
        </CardContainer>
        <ButtonsRow>
          <Button text="뒤로" onClick={this.handleCancel} />
          <Button
            text="PDF 다운로드"
            onClick={this.handleDownload}
            intent={Intent.PRIMARY}
          />
        </ButtonsRow>
      </Wrapper>
    );
  }
}

export default SprintReportDetailPage;
