import React, { FC, ChangeEvent } from "react";
import styled from "styled-components/macro";
import { ReferenceLink } from "../../../store/forms/ReferenceLinkModel";
import { RemoveButtonContainer } from "../../atoms/RemoveItemButton/RemoveItemButton";
import { Button, Intent, InputGroup } from "@blueprintjs/core";
import { observer } from "mobx-react";

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 5px;
`;

const ReferenceRow = styled.div`
  display: flex;

  & > * + * {
    margin-left: 5px;
  }
`;

interface ReferenceLinkFormViewProps {
  referenceLink: ReferenceLink;
  onRemove: (referenceLink: ReferenceLink) => void;
}

const ReferenceLinkFormView: FC<ReferenceLinkFormViewProps> = ({
  referenceLink,
  onRemove
}) => {
  const handleRemove = () => {
    onRemove(referenceLink);
  };

  const handleLinkChange = (e: ChangeEvent<HTMLInputElement>) => {
    referenceLink.setLink(e.target.value);
  };

  const handleCommentChange = (e: ChangeEvent<HTMLInputElement>) => {
    referenceLink.setComment(e.target.value);
  };

  return (
    <Wrapper>
      <RemoveButtonContainer>
        <Button
          icon="minus"
          minimal={true}
          small={true}
          intent={Intent.DANGER}
          onClick={handleRemove}
        />
      </RemoveButtonContainer>
      <ReferenceRow>
        <InputGroup
          placeholder="관련자료 링크"
          value={referenceLink.link}
          onChange={handleLinkChange}
          style={{ width: "230px" }}
        />
        <InputGroup
          placeholder="메모"
          value={referenceLink.comment}
          onChange={handleCommentChange}
        />
        {
          referenceLink.isFromInspection &&
            <div style={{fontSize: '12px', paddingTop: '8px', color: 'grey'}}>
              (from. 검수서)
            </div>
        }
      </ReferenceRow>
    </Wrapper>
  );
};

export default observer(ReferenceLinkFormView);
