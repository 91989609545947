import { types, flow } from "mobx-state-tree";
import axios from "axios";

import {
  ITemplateResponse,
  ITemplatePreviewResponse,
  ITemplatePreviewParams,
  ITemplatePatchParams,
  IParametersResponse,
  ITemplateSendParames } from "../../types/notificationTemplate";
import moment from "moment";


export enum ETEMPLATETYPE {
  EMAIL = 'email',
  SMS = 'sms',
  INSPECTION = 'inspection',
}

const _getCategory = (category: string) => {
  switch (category) {
    case 'PM':
      return 'PM'
    case 'SUPPORT':
      return '경영지원'
    case 'BUSINESS':
      return '영업'
      case 'ETC':
        return '기타'
    default:
      return ''
  }
}

export const NotificationConditionModel = types
  .model("NotificationCondition", {
    id: types.number,
    enabled: types.boolean,
    name: types.string,
    comment: types.string
  })

export const NotificationTemplateSimpleModel = types
  .model("NotificationTemplateSimple", {
    id: types.identifierNumber,
    name: types.string,
    condition: types.maybeNull(NotificationConditionModel)
  })
type NotificationTemplateSimpleType = typeof NotificationTemplateSimpleModel.Type;
export interface INotificationTemplateSimple extends NotificationTemplateSimpleType {}

export const NotificationTemplateModel = types
  .model("NotificationTemplate", {
    id: types.identifierNumber,
    category: types.string,
    categoryEtc: types.string,
    name: types.string,
    htmlSubject: types.string,
    htmlBody: types.string,
    textBody: types.string,
    dateCreated: types.string,
    dateUpdated: types.string,
    creatorName: types.string,
    modifierName: types.string,
    condition: types.maybeNull(NotificationConditionModel),

    isLoading: types.boolean,
  })
  .views(self => ({
    get categoryTitle() {
      return _getCategory(self.category);
    }
  }))
  .views(self => ({
    get title() {
      return `${ self.category ? `[${ self.categoryTitle }]` : ''} ${ self.name }`;
    },
    get isSms() {
      if(self.textBody) {
        return true;
      }
      return false;
    },
    get isEmail() {
      if(self.htmlBody) {
        return true;
      }
      return false;
    },
    get updateDate() {
      return self.dateUpdated ? moment(self.dateUpdated) : moment(self.dateCreated);
    }
  }))
  .actions(self => ({
    setLoading() {
      self.isLoading = true;
    },
    setLoaded() {
      self.isLoading = false;
    }
  }))
  .actions(self => {

    const patch = flow(function*(params: ITemplatePatchParams) {
      
      try {
        const { data }: { data: ITemplateResponse } = yield axios.patch(
          `/template/${self.id}`,
          { ...params }
        );

        const template = NotificationTemplateModel.create(mapper(data));
        self = { ...template };

      } catch (e) {
        throw e;
      }
    });

    const remove = flow(function*() {
      
      try {
        const { data }: { data: ITemplateResponse } = yield axios.delete(
          `/template/${self.id}`
        );

      } catch (e) {
        throw e;
      }
    });

    const send = flow(function*(params: ITemplateSendParames) {
      self.setLoading();
      try {
        const { data }: { data: ITemplatePreviewResponse } = yield axios.post(
          `/template/${self.id}/send_without_parameters`,
          { ...params }
        );

        // console.log(data)
        self.setLoaded();

        return data;
      } catch (e) {
        throw e;
      }
    });

    const getPreview = flow(function*(params?: ITemplatePreviewParams) {
      
      try {
        const { data }: { data: ITemplatePreviewResponse } = yield axios.post(
          `/template/${self.id}/preview`,
            params ? { parameters: {...params} } : {}
        );

        return data;
      } catch (e) {
        throw e;
      }
    });

    const getParameters = flow(function*() {
      
      try {
        const { data }: { data: IParametersResponse } = yield axios.get(
          `/template/${self.id}/parameters`
        );

        return data;
      } catch (e) {
        throw e;
      }
    });
    
    return {
      remove,
      patch,
      getPreview,
      getParameters,
      send
    };
  });

type NotificationTemplateType = typeof NotificationTemplateModel.Type;
export interface INotificationTemplate extends NotificationTemplateType {}


export const mapper = (x: ITemplateResponse) => {
  return {
    id: x.id,
    category: x.category,
    categoryEtc: x.category_etc,
    name: x.name,
    htmlSubject: x.html_subject,
    htmlBody: x.html_body,
    textBody: x.text_body,
    dateCreated: x.date_created,
    dateUpdated: x.date_updated ? x.date_updated : '',
    creatorName: x.creator ? x.creator.name : '',
    modifierName: x.modifier ? x.modifier.name : '',
    condition: x.condition,

    isLoading: false
  }
}