import React, { Component, ChangeEvent, KeyboardEvent } from "react";

import { observable, computed } from "mobx";
import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import { Button, TextArea, Intent } from "@blueprintjs/core";

import { AppStore } from "../../../store/AppStore";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";

const AddTaskContainer = styled.div`
  padding: 5px;
`;
const AddTaskEditor = styled(TextArea)`
`;

const AddTaskAddButton = styled(Button)`
`; 

const AddTaskCancelButton = styled(Button)`
`; 

interface AddTaskFormProps {
  laneId: string;
  onAdd: (values: object) => void;
  onCancel: () => void;
}

interface InjectedProps extends AddTaskFormProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class AddTaskForm extends Component<AddTaskFormProps> {
  @observable inputComponent: any = null;
  @observable title: string = '';

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get board() {
    return this.injected.appStore.taskManagerStore.currentBoard;
  }

  componentDidMount() {
    if(this.inputComponent) {
      this.inputComponent.focus();
      this.inputComponent.onkeypress = this.handleOnKeyPress;
    }
  }

  handleTitleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.title = e.target.value;
  };
  handleOnKeyPress = async(event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      if(this.title.trim() !== '') {
        this.handleSubmit();
      }
    }
  }  

  handleSubmit = async () => {
    try {
      await this.injected.appStore.taskManagerStore.postTask(this.props.laneId, this.title);

      this.title = '';
      this.inputComponent.focus();
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
      // 오류 발생 시 DB 의 데이터로 초기화
      await this.injected.appStore.taskManagerStore.fetchTaskBoard(this.board ? this.board.projectGroup : '');
    }
  }

  render() {
    return (
      <AddTaskContainer>
        <div>
          <AddTaskEditor
            fill={true}
            inputRef={(input: any) => {this.inputComponent=input}}
            placeholder={'Task 타이틀'}
            value={ this.title }
            onChange={this.handleTitleChange}
          />
        </div>
        <div style={{height:'5px'}}>&nbsp;</div>
        <div style={{display:'flex'}}>
          <AddTaskCancelButton 
              minimal={true}
              onClick={(e: any) => {this.props.onCancel(); e.stopPropagation();}}
              text={'취소'}
              style={{marginLeft: 'auto'}}
          />
          <AddTaskAddButton 
            small={true} disabled={ this.title === ''}
            onClick={(e: any) => { this.handleSubmit(); e.stopPropagation(); }}
            text={'추가'}
            intent={Intent.PRIMARY}
          />
        </div>
      </AddTaskContainer>
    );
  }
}

export default AddTaskForm;