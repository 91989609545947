import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { computed, observable } from "mobx";
import {
  Button,
  Callout,
  H3,
  Intent,
  Spinner,
  FormGroup,
  HTMLTable,
  Tag,
  IOptionProps, HTMLSelect
} from "@blueprintjs/core";
import ContentContainer from "../../atoms/ContentContainer/ContentContainer";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import ContractComments from "../../organisms/ContractComments/ContractComments";
import moment from "moment";
import ProgressTracker from "../../atoms/ProgressTracker/ProgressTracker";

const ButtonsRow = styled.div`
  margin-bottom: 50px;
  float: right;
  & > * + * {
    margin-left: 5px;
  }
`;
const ContainerWrapper = styled.div`
  display: flex;
`;

const Container = styled.div`
  width: 550px;
  margin-right: 20px;
`;
const Section = styled.div`
  margin-bottom: 40px;
`;
const CalloutWrapper = styled(Callout)`
  padding-top: 0;
`;
const FlexRow = styled.div`
  display: flex;
  & > * + * {
    margin-left: 5px;
  }
  & > div {
    width: 100%;
  }
`;
const RightAlignDiv = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;
const Text = styled.div`
  white-space: pre-wrap;
`;

const statusOption: IOptionProps[] = [
  { label: "결재대기", value: "결재대기" },
  { label: "계약서 작성 완료", value: "계약서 작성 완료" },
  { label: "계약서 검토/수정 완료", value: "계약서 검토/수정 완료" },
  { label: "계약서 발송 완료", value: "계약서 발송 완료" },
  { label: "계약 완료", value: "계약 완료" },
  { label: "계약내용 기록 완료", value: "계약내용 기록 완료" },
  { label: "계약보류", value: "계약보류" },
  { label: "계약파기", value: "계약파기" },
];
const statusValues = statusOption.map(i => i.value.toString());

interface RufreeContractRequestDetailPageProps {
  id: string;
}

interface InjectedProps extends RufreeContractRequestDetailPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class RufreeContractRequestDetailPage extends Component<RufreeContractRequestDetailPageProps> {
  @observable readyToShow = false;
  @observable tempStatus = "";

  get injected() {
    return this.props as InjectedProps;
  }

  get contractStore() {
    return this.injected.appStore.contractStore;
  }

  @computed
  get contract() {
    const { id } = this.props;
    return this.injected.appStore.contractStore.rufreeContracts.find(
      x => x.contractId === id
    );
  }

  handleAddComment = async (comment: string) => {
    if (this.contract) {
      try {
        await this.contract.addComment(comment);

        AppToaster.show({
          message: "코멘트가 저장되었습니다.",
          intent: Intent.SUCCESS
        });

        this.contract.fetchComments();
      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }
    }
  };

  handleUpdateComment = async (id: number, comment: string) => {
    if (this.contract) {
      try {
        await this.contract.updateComment(id, comment);

        AppToaster.show({
          message: "코멘트가 수정되었습니다.",
          intent: Intent.SUCCESS
        });

        this.contract.fetchComments();
      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }
    }
  };

  handleDeleteComment = async (id: number) => {
    if (this.contract) {
      try {
        await this.contract.deleteComment(id);

        AppToaster.show({
          message: "코멘트가 삭제되었습니다.",
          intent: Intent.SUCCESS
        });

        this.contract.fetchComments();
      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }
    }
  };

  handleRequestApproveSubmit = async () => {
    if (this.contract) {
      try {
        await this.contract.requestApprove();
        const { id } = this.props;
        await this.injected.appStore.contractStore.fetchRufreeContractById(id);
        AppToaster.show({
          message: "결재 요청되었습니다.",
          intent: Intent.SUCCESS
        });
      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }
    }
  };

  handleCancelRequestApproveSubmit = async () => {
    if (this.contract) {
      try {
        await this.contract.cancelRequestApprove();
        const { id } = this.props;
        await this.injected.appStore.contractStore.fetchRufreeContractById(id);
        AppToaster.show({
          message: "결재 요청이 취소되었습니다.",
          intent: Intent.SUCCESS
        });
      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }
    }
  };

  handleApproveSubmit = async () => {
    if (this.contract) {
      try {
        await this.contract.approve();

        AppToaster.show({
          message: "결재되었습니다.",
          intent: Intent.SUCCESS
        });
      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }
    }
  };

  handleDeleteSubmit = async () => {
    if (this.contract) {
      try {
        await this.contract.remove();

        AppToaster.show({
          message: "삭제되었습니다.",
          intent: Intent.SUCCESS
        });

        window.location.href = `/contract?tab=request&type=rufree`;
      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }
    }
  };

  handleRejectSubmit = async () => {
    if (this.contract) {
      try {
        await this.contract.reject();

        AppToaster.show({
          message: "반려되었습니다.",
          intent: Intent.SUCCESS
        });
      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }
    }
  };

  get currentUser() {
    return this.injected.appStore.userStore;
  }

  async componentDidMount() {
    this.readyToShow = false;

    try {
      const { id } = this.props;
      await this.injected.appStore.contractStore.fetchRufreeContractById(id);
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER,
        timeout: 0
      });
    }
    this.readyToShow = true;
  }

  renderButtons() {
    const { contract } = this;
    const { isPM } = this.injected.appStore.userStore;
    if (contract) {
      if (statusValues.includes(contract.status)) {
        return (
          <>{
            (this.currentUser.userId == contract.approveRequester ||
              this.currentUser.userId == 1) &&
            <Button
              text="결재요청 취소하기"
              intent={Intent.WARNING}
              onClick={this.handleCancelRequestApproveSubmit}
            />}
            {isPM && <>
              <Button
                text="반려하기"
                intent={Intent.DANGER}
                onClick={this.handleRejectSubmit}
              />
              <HTMLSelect
                value={this.tempStatus === "" ? contract.status : this.tempStatus}
                onChange={(e) => {
                  this.tempStatus = e.target.value;
                  if(this.tempStatus === contract.status) this.tempStatus = "";
                }}
                options={statusOption}
              />
              <Button
                text="저장하기"
                intent={Intent.SUCCESS}
                disabled={this.tempStatus===""}
                onClick={async () => {
                  try {
                    contract.setStatus(this.tempStatus);
                    this.tempStatus = "";
                    await contract.changeStatusPreRequest();
                    AppToaster.show({
                      message: "Status가 변경되었습니다.",
                      intent: Intent.SUCCESS
                    });

                  } catch (e) {
                    const error = JSON.stringify(e.response.data);
                    AppToaster.show({
                      message: "오류: " + error,
                      intent: Intent.DANGER
                    });
                  }
                }}
              />
            </>}
          </>
        );
      }

      if (contract.status === "결재완료") {
        return (
          <>
            {isPM && <>
              <Button
                text="반려하기"
                intent={Intent.DANGER}
                onClick={this.handleRejectSubmit}
              />
            </>}
            <Tag large={true}>
              결재완료
            </Tag>
          </>
        );
      }

      return (
        <>
          <Link to={`/contract-request/rufree/${contract.contractId}/edit`}>
            <Button text="수정하기" intent={Intent.PRIMARY}/>
          </Link>
          <Button
            text="결재요청"
            intent={Intent.SUCCESS}
            onClick={this.handleRequestApproveSubmit}
          />
        </>
      );
    }
  }

  render() {
    const { contract } = this;

    if (!contract) {
      return <Spinner/>;
    }

    if (!this.readyToShow) {
      return <Spinner/>;
    }

    const pmUser = contract.pm_user_detail;
    const rufreeUser = contract.rufreeUserDetail;

    return (
      <>
        <H3>계약 요청(알유프리) 상세보기</H3>

        <ContainerWrapper>
          <Container>
            <Section>
              <FormGroup label={<strong>계약 타입</strong>}>
                {contract.contractType}
              </FormGroup>
              <FormGroup label={<strong>담당매니저</strong>}>
                {pmUser ? pmUser.name : ""}
              </FormGroup>
              <FormGroup label={<strong>계약명(프로젝트명)</strong>}>
                {contract.contractName}
              </FormGroup>
            </Section>

            <Section>
            <FormGroup label={<strong>계약정보</strong>}>
                { contract.info }
              </FormGroup>
              <FormGroup label={<strong>알유프리</strong>}>
                {rufreeUser ? rufreeUser.name : ""}
              </FormGroup>
              <CalloutWrapper>
                {
                  rufreeUser && (
                    <>
                      <HTMLTable
                        style={{ width: "100%" }}
                      >
                        <thead>
                        <tr>
                          <th>이름</th>
                          <th>이메일</th>
                          <th>주소</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td>{rufreeUser.name}</td>
                          <td>{rufreeUser.email}</td>
                          <td>{rufreeUser.address}</td>
                        </tr>
                        </tbody>
                      </HTMLTable>

                      <RightAlignDiv>
                        <Button
                          className={"bp3-small bp3-minimal"}
                          text="알유프리 정보 수정하기"
                          intent={Intent.PRIMARY}
                          onClick={() => {
                            window.open(`/rufrees/${rufreeUser && rufreeUser.rufreeId}/edit`, "_blank");
                          }}
                        />
                      </RightAlignDiv>
                    </>
                  )
                }
              </CalloutWrapper>
              <FormGroup label={<strong>대리인정보</strong>}>
                <FlexRow>
                  <FormGroup label={<strong>이름</strong>}>
                    {contract.agentName}
                  </FormGroup>
                  <FormGroup label={<strong>이메일</strong>}>
                    {contract.agentEmail}
                  </FormGroup>
                  <FormGroup label={<strong>주소</strong>}>
                    {contract.agentAddress}
                  </FormGroup>
                </FlexRow>
              </FormGroup>

              <FormGroup label={<strong>계약체결일</strong>}>
                <FlexRow>
                  {moment(contract.contractDate).format("YYYY-MM-DD")}
                </FlexRow>
              </FormGroup>

              <FormGroup label={<strong>스프린트 기간</strong>}>
                <FlexRow>
                  {contract.sprintPeriod}
                </FlexRow>
              </FormGroup>
              <FormGroup label={<strong>실수령액(검수서 기준 VAT별도)</strong>}>
                <FlexRow>
                  {contract.paymentFee.toLocaleString("en-US")}원
                  {contract.payment && <div>(최초입력 값: {contract.payment}</div>}
                </FlexRow>
              </FormGroup>
              <FormGroup label={<strong>수수료(검수서 기준 VAT별도)</strong>}>
                <FlexRow>
                  {contract.commissionFee.toLocaleString("en-US")}원
                  {contract.commission && <div>(최초입력 값: {contract.commission}</div>}
                </FlexRow>
              </FormGroup>
            </Section>

            <Section>
              <FormGroup label={<strong>검수서</strong>}>
                {contract.inspectionsDetail.map(inspection => {
                  if (inspection) {
                    return (
                      <div key={inspection.inspectionId}>
                        <Link
                          to={`/inspections/${inspection.inspectionId}/read`}
                          target={"_blank"}>
                          {inspection.nameWithId}
                        </Link>
                      </div>
                    );
                  }
                })}
              </FormGroup>

              <FormGroup label={<strong>계약범위</strong>}>
                <Text>
                  {contract.contractRange}
                </Text>
              </FormGroup>
              <FormGroup label={<strong>특약사항</strong>}>
                <Text>
                  {contract.specialContract}
                </Text>
              </FormGroup>
              <FormGroup label={<strong>비고</strong>}>
                <Text>
                  {contract.comment}
                </Text>
              </FormGroup>
            </Section>
            <Section style={{ width: "200%" }}>
              <ProgressTracker pointList={statusValues} doneIndex={statusValues.indexOf(contract.status)}/>
            </Section>


            <ButtonsRow>
              <Button
                text="삭제"
                minimal={true}
                intent={Intent.DANGER}
                onClick={this.handleDeleteSubmit}/>

              <Link to={`/contract?tab=request&type=rufree`}>
                <Button text="취소"/>
              </Link>

              {this.renderButtons()}
            </ButtonsRow>
          </Container>

          <Container>
            <ContractComments
              comments={contract.contractComments}
              onAddComment={this.handleAddComment}
              onDeleteComment={this.handleDeleteComment}
              onUpdateComment={this.handleUpdateComment}
            />
          </Container>
        </ContainerWrapper>

        <ContentContainer>

        </ContentContainer>
      </>
    );
  }
}

export default RufreeContractRequestDetailPage;
