import { types, flow } from "mobx-state-tree";
import axios from "axios";
import moment from "moment";
import sortBy from "lodash/sortBy";
import {
  BulletinPostFile,
  BulletinPostLink, Task,
  TaskBoardModel, TaskBulletin,
  TaskBulletinModel, TaskBulletinPost,
  TaskBulletinPostModel,
  TaskFilterModel, TaskModel,
  TaskMultiSelectModel
} from "./models/TaskManager";
import { TaskBoardResponse, TaskResponse, TaskBulletinResponse, TaskBulletinPostResponse } from "../types/taskManager";

import { BaseModel } from "./models/BaseModel";

export const mapTaskBoard = (x: TaskBoardResponse) => {
  return {
    id: x.id,
    createdAt: x.created_at,
    updatedAt: x.updated_at,
    taskBoardId: x.task_board_id,
    projectGroup: x.project_group,
    projectGroupName: x.project_group_name,
    sprintTitleList: x.sprint_title_list,
    labelTitleList: x.label_title_list,
    members: x.members,
    taskLists: x.task_lists
  };
};

export const mapTaskBulletinPost = (x: TaskBulletinPostResponse) => {
  return {
    id: x.id,
    createdAt: x.created_at,
    updatedAt: x.updated_at,
    order: x.order,
    task_bulletin_post_id: x.task_bulletin_post_id,
    title: x.title,
    content: x.content,
    task_bulletin: x.task_bulletin,
    writer: x.writer,
    links: x.task_bulletin_post_links,
    files: x.task_bulletin_post_files
  };
};

export const mapTaskBulletin = (x: TaskBulletinResponse) => {
  return {
    id: x.id,
    createdAt: x.created_at,
    updatedAt: x.updated_at,
    order: x.order,
    task_bulletin_id: x.task_bulletin_id,
    title: x.title,
    taskBoardId: x.task_board,
    task_bulletin_posts: x.task_bulletin_posts ? x.task_bulletin_posts.map(mapTaskBulletinPost) : []
  };
};

export const TaskManagerStoreModel = BaseModel.named("TaskManagerStore")
  .props({
    taskBoards: types.array(TaskBoardModel),
    taskBulletins: types.array(TaskBulletinModel),
    searchString: types.optional(types.string, ""),
    taskFilters: types.optional(TaskFilterModel, {}),
    taskMultiSelect: types.optional(TaskMultiSelectModel, {}),
    taskEditing: types.optional(types.array(types.number), []),
    taskListEditing: types.optional(types.array(types.number), []),
    boardDraggable: types.optional(types.boolean, true),
  })
  .views(self => ({
    get currentBoard() {
      return self.taskBoards.length > 0 ? self.taskBoards[0] : null;
    },
    // get getArchivedTasks() {
    //   const tasks: Task[] = [];
    //   if (self.taskBoards.length < 1) return tasks;
    //   console.log("self.taskBoards[0]",self.taskBoards[0]);
    //   self.taskBoards[0].taskLists.forEach(taskList => {
    //     tasks.concat(taskList.tasks);
    //   });
    //   console.log("tasks",tasks);
    //   return tasks.filter((task) => task.is_archived);
    //
    // },
    get currentBulletins() {
      return self.taskBulletins;
    },
    get sortedBulletins() {
      return sortBy(
        self.taskBulletins.slice(), 
        ['order']
      );      
    },
    get searchResults() {
      // 태스크 목록 탐색
      const taskLists: Array<object> = this.currentBoard ? (
        this.currentBoard.taskLists.map((taskList) => {
          // 태스크 탐색
          let tasks = taskList.tasks.map((task) => {

            // 완료/전체 서브태스크 개수
            let countSubTasksDone = 0;
            let countSubTaskAll = 0;
            for (let i = 0; i < task.sub_task_lists.length; i++) {
              countSubTaskAll += task.sub_task_lists[i].sub_tasks.length;
              for (let j = 0; j < task.sub_task_lists[i].sub_tasks.length; j++) {
                countSubTasksDone += task.sub_task_lists[i].sub_tasks[j].checked ? 1 : 0;
              }
            }

            return {
              id: String(task.id),
              title: task.title,
              is_archived: task.is_archived,
              person_in_charge_list: task.person_in_charge_list.slice(),  // mobx 에러 대응
              draggable: true,
              metadata: {
                taskId: task.id,
                taskListId: task.task_list,
                taskBoardId: task.task_board,
                taskNo: task.task_no,
                dueDate: task.due_date,
                sprints: task.sprint_list.map(sprint => sprint.title),
                labels: task.label_list.map(label => label.title),
                countSubTasksDone: countSubTasksDone,
                countSubTasksAll: countSubTaskAll,
                countAttachments: task.task_links.length + task.task_files.length,
                countComments: task.task_comments.length
              }
            };
          }).filter(task => !task.is_archived).filter(task => {
            return (
              !self.searchString) || 
              (task.title.includes(self.searchString)
            );
          });

          // 내 Task 만 보기 처리
          if (self.taskFilters.show_only_my_tasks) {
            tasks = tasks.filter(task => task.person_in_charge_list.includes(self.taskFilters.my_id));
          }

          // 담당자 지정 필터 처리
          if (self.taskFilters.person_in_charges.length > 0) {
            tasks = tasks.filter(task => task.person_in_charge_list.some(userId => self.taskFilters.person_in_charges.includes(userId)));
          }

          // 스프린트 지정 필터 처리
          if (self.taskFilters.sprint_title_list.length > 0) {
            tasks = tasks.filter(task => task.metadata.sprints.some(sprint => self.taskFilters.sprint_title_list.includes(sprint)));
          }

          // 라벨 지정 필터 처리
          if (self.taskFilters.label_title_list.length > 0) {
            tasks = tasks.filter(task => task.metadata.labels.some(label => self.taskFilters.label_title_list.includes(label)));
          }

          // 마감일 필터 처리
          if (self.taskFilters.use_duedate) {
            tasks = tasks.filter(
              task => {

                if(
                  task.metadata.dueDate &&
                  self.taskFilters.duedate_start &&
                  moment(task.metadata.dueDate).isBefore(moment(self.taskFilters.duedate_start), "days")
                ) {
                  // 시작일 보다 이전 태스크는 보이지 않도록 처리
                  return false;
                }

  
                if(
                  task.metadata.dueDate &&
                  self.taskFilters.duedate_end &&
                  moment(task.metadata.dueDate).isAfter(moment(self.taskFilters.duedate_end), "days")
                ) {
                  // 종료일 보다 이후 태스크는 보이지 않도록 처리
                  return false;
                }

                if (!task.metadata.dueDate && 
                  (self.taskFilters.duedate_start || self.taskFilters.duedate_end)
                ) {
                  // 마감일 정보가 없는 태스크는 날짜 필터 입력 시 보이지 않도록 처리
                  return false;
                }
                
                return true;
              }
            );
          }

          // 복수선택 모드 입력값에 대한 프리뷰 처리
          if (self.taskMultiSelect.task_ids.length > 0) {
            tasks = tasks.map(task => {
              let copiedTask = JSON.parse(JSON.stringify(task));  // mobx 에러 대응

              if (self.taskMultiSelect.task_ids.includes(task.metadata.taskId)) {
                // 담당자
                if (self.taskMultiSelect.person_in_charge_list.length > 0) {
                  self.taskMultiSelect.person_in_charge_list.map(person_in_charge => {
                    if (!copiedTask.person_in_charge_list.includes(person_in_charge)) {
                      copiedTask.person_in_charge_list.push(person_in_charge);
                    }
                  });
                }                

                // 마감일
                if (self.taskMultiSelect.duedate) {
                  copiedTask.metadata.dueDate = self.taskMultiSelect.duedate;
                }

                // 라벨
                if (self.taskMultiSelect.label_title_list.length > 0) {
                  self.taskMultiSelect.label_title_list.map(label_title => {
                    if (!copiedTask.metadata.labels.includes(label_title)) {
                      copiedTask.metadata.labels.push(label_title);
                    }
                  });
                }

                // 스프린트
                if (self.taskMultiSelect.sprint_title_list.length > 0) {
                  self.taskMultiSelect.sprint_title_list.map(sprint_title => {
                    if (!copiedTask.metadata.sprints.includes(sprint_title)) {
                      copiedTask.metadata.sprints.push(sprint_title);
                    }
                  });
                }
              }

              return copiedTask;
            });
          }

          // 수정 중 드래그 방지
          if (!self.boardDraggable) {
            tasks = tasks.map(task => {
              task.draggable = false;
              return task;
            });
          }

          // 위에서 구한 tasks 로 태스크 목록 정보 리턴
          return {
            id: String(taskList.id),
            title: taskList.title + " (" + tasks.length + ")",
            style: {
              width: "260px",
              backgroundColor: taskList.color
            },
            cards: tasks,
            metadata: {
              person_in_charge_list: taskList.person_in_charge_list.slice(),
              is_editable: taskList.is_editable
            }
          };
        })
      ) : [];

      return taskLists;
    },
    get searchCount() {
      let count = 0;
      this.searchResults.map((taskList: any) => {
        count += taskList.cards.length;
      });
      return count;
    }

  }))
  .actions(self => {
    // 수정 중 태스크, 태스크 목록 관련 함수 (D&D 제어용)
    const setDraggable = (value: boolean) => {
      self.boardDraggable = value;
    }
    const addEditingTaskId = (value: number) => {
      if(!self.taskEditing.includes(value)) {
        self.taskEditing.push(value);
      }
    }
    const delEditingTaskId = (value: number) => {
      const idx = self.taskEditing.indexOf(value);
      if (idx > -1) 
        self.taskEditing.splice(idx, 1);
    }
    const addEditingTaskListId = (value: number) => {
      if(!self.taskListEditing.includes(value)) {
        self.taskListEditing.push(value);
      }
    }
    const delEditingTaskListId = (value: number) => {
      const idx = self.taskListEditing.indexOf(value);
      if (idx > -1) 
        self.taskListEditing.splice(idx, 1);
    }    
    // 복수선택 관련 함수
    const addMultiSelectTaskId = (value: number) => {
      if (self.taskMultiSelect.task_ids.includes(value)) {
        self.taskMultiSelect.task_ids.remove(value);
      } else {
        self.taskMultiSelect.task_ids.push(value);
      }
    };
    const clearMultiSelectTaskIds = () => {
      self.taskMultiSelect.task_ids.clear();
      self.taskMultiSelect.person_in_charge_list.clear();
      self.taskMultiSelect.duedate = "";
      self.taskMultiSelect.label_title_list.clear();
      self.taskMultiSelect.sprint_title_list.clear();
    };
    const previewMultiSelectInputs = (inputs: any) => {
      self.taskMultiSelect.person_in_charge_list = inputs.person_in_charge_list;
      self.taskMultiSelect.duedate = inputs.duedate;
      self.taskMultiSelect.label_title_list = inputs.label_title_list;
      self.taskMultiSelect.sprint_title_list = inputs.sprint_title_list;
    };
    const updateMultiTasks = flow(function* () {
      try {
        const { data }: { data: object } = yield axios.put(
          `/taskmgr/task/updatemultitask`,
          {
            task_board_id: self.taskBoards[0].taskBoardId,
            task_ids: self.taskMultiSelect.task_ids,
            person_in_charge_list: self.taskMultiSelect.person_in_charge_list,
            duedate: self.taskMultiSelect.duedate,
            label_title_list: self.taskMultiSelect.label_title_list,
            sprint_title_list: self.taskMultiSelect.sprint_title_list
          }
        );
      } catch (e) {
        console.log("updateMultiTasks error", e);
        throw e;
      }
    });
    const resetMultiTasks = flow(function* (resource_type: string) {
      try {
        const { data }: { data: object } = yield axios.put(
          `/taskmgr/task/resetmultitask`,
          {
            task_ids: self.taskMultiSelect.task_ids,
            resource_type: resource_type,
          }
        );
      } catch (e) {
        console.log("resetMultiTasks error", e);
        throw e;
      }      
    });
    const createLabel = flow(function* (label_title: string) {
      try {
        self.taskBoards[0].labelTitleList.push(label_title);
        const { data }: { data: object } = yield axios.put(
          `/taskmgr/taskboard/${self.taskBoards[0].id}/label`,
          {
            label_title: label_title
          }
        );
      } catch (e) {
        console.log("createLabel error", e);
        throw e;
      }
    });
    const createSprint = flow(function* (sprint_title: string) {
      try {
        self.taskBoards[0].sprintTitleList.push(sprint_title);
        const { data }: { data: object } = yield axios.put(
          `/taskmgr/taskboard/${self.taskBoards[0].id}/sprint`,
          {
            sprint_title: sprint_title
          }
        );
      } catch (e) {
        console.log("createSprint error", e);
        throw e;
      }
    });

    // 검색, 필터 관련 함수
    const setSearchString = (value: string) => {
      self.searchString = value;
    };

    const setFilter = (filter: object) => {
      self.taskFilters = TaskFilterModel.create(filter);
    };

    // 태스크 보드 관련 함수
    const createTaskBoard = flow(function* (projectGroupId: string) {
      try {
        const { data }: { data: object } = yield axios.put(
          `/taskmgr/taskboard/create`,
          {
            project_group_id: projectGroupId
          }
        );

      } catch (e) {
        console.log("createTaskBoard error", e);
        throw e;
      }
    });    
    const fetchTaskBoard = flow(function* (projectGroupId: string) {
      try {
        const { data }: { data: TaskBoardResponse[] } = yield axios.get(
          `/taskmgr/taskboard`,
          {
            params: {
              search: projectGroupId
            }
          }
        );
        self.taskBoards.replace(
          data.map(x => TaskBoardModel.create(mapTaskBoard(x)))
        );
      } catch (e) {
        console.error("fetchTaskBoard  error", e);
        throw e;
      }
    });

    const moveTaskList = flow(function* (targetListId: number, orderTo: number) {
      try {
        const { data }: { data: object } = yield axios.put(
          `/taskmgr/tasklist/moved`,
          {
            target_list_id: targetListId,
            order_to: orderTo
          }
        );

      } catch (e) {
        console.log("moveTaskList error", e);
        throw e;
      }
    });

    const deleteTaskList = flow(function* (taskListId: string) {
      try {
        yield axios.delete(`/taskmgr/tasklist/${taskListId}`);
      } catch (e) {
        console.log("deleteTaskList error", e);
        throw e;
      }
    });

    const updateTaskListPersonInCharge = flow(function* (id: number, person_in_charge_list: Array<number>) {
      try {
        const { data }: { data: object } = yield axios.put(
          `/taskmgr/tasklist/${id}/person-in-charge`,
          {
            person_in_charge_list: person_in_charge_list
          }
        );
      } catch (e) {
        console.log("updateTaskListPersonInCharge error", e);
        throw e;
      }
    });


    // task 관련 함수
    const postTask = flow(function* (taskListId: string, title: string) {
      try {
        const { data }: { data: TaskResponse } = yield axios.post(
          `/taskmgr/task`,
          {
            title: title,
            task_list_id: taskListId
          }
        );

        const tasklist = self.taskBoards[0].taskLists.find(x => String(x.id) === taskListId);
        tasklist && tasklist.tasks.push(TaskModel.create({
          id: data.id,
          created_at: data.created_at,
          updated_at: data.updated_at,
          order: data.order,
          task_id: data.task_id,
          task_no: data.task_no,
          title: data.title,
          description: data.description,
          due_date: data.due_date,
          is_archived: data.is_archived,
          date_archived: data.date_archived,
          task_board: data.task_board,
          task_list: data.task_list,
          person_in_charge_list: data.person_in_charge_list,
          sprint_list: [],
          label_list: [],
          sub_task_lists: [],
          task_links: [],
          task_files: [],
          task_comments: []
        }));

        return data;

      } catch (e) {
        console.log("postTask error", e);
        throw e;
      }
    });

    const moveTask = flow(function* (taskId: string, srcTaskListId: string, tgtTaskListId: string, orderTo: number) {
      try {
        const { data }: { data: object } = yield axios.put(
          `/taskmgr/task/moved`,
          {
            task_id: taskId,
            src_tasklist_id: srcTaskListId,
            tgt_tasklist_id: tgtTaskListId,
            order_to: orderTo
          }
        );

        let board = self.currentBoard;
        if(board) {
          
          let srcListIdx = board.taskLists.map(taskList => {return taskList.id}).indexOf(Number(srcTaskListId));
          let tgtListIdx = board.taskLists.map(taskList => {return taskList.id}).indexOf(Number(tgtTaskListId));

          let srcTaskIdx = board.taskLists[srcListIdx].tasks.map(task => {return task.id}).indexOf(Number(taskId));
          let tgtTaskIdx = orderTo + board.taskLists[tgtListIdx].tasks.filter(task => task.is_archived).length;

          // 태스크 이동 처리
          const srcTask = board.taskLists[srcListIdx].tasks.splice(srcTaskIdx, 1)[0];
          board.taskLists[tgtListIdx].tasks.splice(tgtTaskIdx, 0, JSON.parse(JSON.stringify(srcTask)));
        }
        
      } catch (e) {
        console.log("moveTask error", e);
        throw e;
      }
    });

    const updateTaskTitle = flow(function* (taskId: string, title: string) {
      try {
        const { data }: { data: object } = yield axios.put(
          `/taskmgr/task/${taskId}/title`,
          {
            title: title
          }
        );

        self.currentBoard && self.currentBoard.taskLists.map((tasklist) => {
          tasklist.tasks.map((task) => {
            if(task.id === Number(taskId)) {
              task.title = title;
            }
          });
        });
      } catch (e) {
        console.log("updateTaskTitle error", e);
        throw e;
      }
    });

    const _archiveTask = (taskId: string, is_archived: boolean) => {
      let ref_tasklist: any = null;
      let ref_task: any = null;
      let ref_order: number = 99999; // 큰값으로 초기화

      // 조건에 맞는 태스크 정보를 탐색
      self.currentBoard && self.currentBoard.taskLists.map((tasklist) => {
        tasklist.tasks.map((task) => {
          if(task.id === Number(taskId)) {
            ref_tasklist = tasklist;
            ref_task = task;
            ref_order = task.order;
          }
        });
      });

      if(ref_tasklist && ref_task) { 
        if(is_archived) {
          // 태스크 보관 처리
          ref_tasklist.tasks.map((task: any) => {

            if(task.id == ref_task.id) {
              task.order = 0;
              task.is_archived = true;
              task.date_archived = moment().toISOString();
            }
            // 보관할 태스크 보다 아래에 있는 태스크 순서 조정
            if(task.order > ref_order) {
              task.order -= 1;
            }
          })
        } else {
          // 태스크 복원 처리

          // 현재 목록에서 가장 큰 순서값 계산
          let list_order = ref_tasklist.tasks.map((t: any) => { return t.order; });
          let max_order = Math.max.apply(null, list_order);
  
          // 프론트에서 필요한 복원 처리
          ref_task.order = max_order + 1;
          ref_task.is_archived = false;
          ref_task.date_archived = null;
        }

        // 변경된 순서를 화면에 적용
        ref_tasklist.tasks = sortBy(
          ref_tasklist.tasks.slice(), ['order']
        );          
      }
    }

    const archiveTask = flow(function* (taskId: string, is_archived: boolean) {
      try {
        const { data }: { data: object } = yield axios.put(
          `/taskmgr/task/archived`,
          {
            task_id: taskId,
            is_archived: is_archived
          }
        );
        _archiveTask(taskId, is_archived);
      } catch (e) {
        console.log("archiveTask error", e);
        throw e;
      }
    });
    const archiveTasks = flow(function* (list:any) {
      try {
        const { data }: { data: object } = yield axios.put(
          `/taskmgr/task/archiveds`,
          {
            list: list
          }
        );

        list.map((item: any) => {
          _archiveTask(item.task_id, item.is_archived);
        });
      } catch (e) {
        console.log("archiveTask error", e);
        throw e;
      }
    });
    const deleteTask = flow(function* (taskId: string) {
      try {
        const { data }: { data: object } = yield axios.delete(
          `/taskmgr/task/${taskId}`,
        );
      } catch (e) {
        console.log("archiveTask error", e);
        throw e;
      }
    });

    const deleteTasks = flow(function* (list:any) {
      try {
        const { data }: { data: object } = yield axios.delete(
          `/taskmgr/task/destroys`,
          {
            data:{list: list}
          }
        );
      } catch (e) {
        console.log("archiveTask error", e);
        throw e;
      }
    });
    const fetchSeesoTasks = flow(function* () {
      try {
        const { data }: { data: object } = yield axios.put(
          `/taskmgr/task/seeso-tasks`, {}
        );
        return data;
      } catch (e) {
        console.log("fetchSeesoTasks error", e);
        throw e;
      }
    });

    // 운영정보목록, 항목 관련 함수
    const fetchTaskBulletin = flow(function* (projectGroupId: string) {
      try {
        const { data }: { data: TaskBulletinResponse[] } = yield axios.get(
          `/taskmgr/bulletin`,
          {
            params: {
              search: projectGroupId
            }
          }
        );

        self.taskBulletins.replace(
          data.map(x => TaskBulletinModel.create(mapTaskBulletin(x)))
        );
      } catch (e) {
        console.error("fetchTaskBulettin  error", e);
        throw e;
      }
    });

    const updateTaskBulletinTitle = flow(function* (id: number, title: string) {
      try {
        const { data }: { data: object } = yield axios.put(
          `/taskmgr/bulletin/${id}/title`,
          {
            title: title
          }
        );
      } catch (e) {
        console.log("updateTaskBulletinTitle error", e);
        throw e;
      }
    });

    const postTaskBulletin = flow(function* (taskBoardId: string, title: string) {
      try {
        const { data }: { data: TaskBulletinResponse } = yield axios.post(
          `/taskmgr/bulletin`,
          {
            title: title,
            task_board: taskBoardId
          }
        );
        return data;

      } catch (e) {
        console.log("postTaskBulletin error", e);
        throw e;
      }
    });

    const deleteTaskBulletin = flow(function* (id: number) {
      try {
        yield axios.delete(`/taskmgr/bulletin/${id}`);
      } catch (e) {
        console.log("deleteTaskBulletin error", e);
        throw e;
      }
    });

    const updateTaskBulletinPostTitle = flow(function* (task_bulletin: string, id: number, title: string) {
      try {
        const { data }: { data: object } = yield axios.put(
          `/taskmgr/bulletinpost/${id}/title`,
          {
            title: title,
            task_bulletin: task_bulletin
          }
        );
      } catch (e) {
        console.log("updateTaskBulletinPostTitle error", e);
        throw e;
      }
    });

    const fetchTaskBulletinPost = flow(function* (id: number) {
      try {
        const { data }: { data: TaskBulletinPostResponse } = yield axios.get(
          `/taskmgr/bulletinpost/${id}`
        );
        return TaskBulletinPostModel.create(mapTaskBulletinPost(data));
      } catch (e) {

      }
    });
    const postTaskBulletinPost = flow(function* (task_bulletin: string, title: string, writer_email: string) {
      try {
        const { data }: { data: TaskBulletinPostResponse } = yield axios.post(
          `/taskmgr/bulletinpost`,
          {
            title: title,
            task_bulletin: task_bulletin,
            writer: writer_email
          }
        );
        return data;

      } catch (e) {
        console.log("postTaskBulletinPost error", e);
        throw e;
      }
    });
    const putTaskBulletinPost = flow(function* (id: number, title: string, content: string) {
      try {
        const { data }: { data: TaskBulletinPostResponse } = yield axios.patch(
          `/taskmgr/bulletinpost/${id}`,
          {
            title: title,
            content: content
          }
        );
        return data;

      } catch (e) {
        console.log("postTaskBulletinPost error", e);
        throw e;
      }
    });

    const changeBulletinPos = async (order_from: number, order_to: number) => {
      const bulletins = self.sortedBulletins;

      bulletins[order_from].order = order_to;
      if(order_from > order_to) {
        // 아래에서 위로 올라간 경우
        for(let i=order_to; i<order_from; i++) {
          bulletins[i].order = (bulletins[i].order+1);
        }
      } else {
        // 위에서 아래로 내려간 경우
        for(let i=order_from+1; i<=order_to; i++) {
          bulletins[i].order = (bulletins[i].order-1);
        }
      }
      self.taskBulletins.replace(bulletins);
    };
    const changeBulletinPostPos = async (src_bulletin_id: number, dst_bulletin_id: number, order_from: number, order_to: number) => {
      // 깜빡거림을 막기 위해 모델 리프레시 전에 위치값을 조정해준다
      const bulletins = self.currentBulletins;
      const src_bulletin = self.currentBulletins[bulletins.findIndex(b => b.id === src_bulletin_id)]
      const dst_bulletin = self.currentBulletins[bulletins.findIndex(b => b.id === dst_bulletin_id)]

      if(src_bulletin === dst_bulletin) {
        // bulletin 내부에서 이동
        const bulletin_posts = dst_bulletin.sortedTaskBulletinPosts;

        bulletin_posts[order_from].order = order_to;
        if(order_from > order_to) {
          // 아래에서 위로 올라간 경우
          for(let i=order_to; i<order_from; i++) {
            bulletin_posts[i].order = (bulletin_posts[i].order+1);
          }
        } else {
          // 위에서 아래로 내려간 경우
          for(let i=order_from+1; i<=order_to; i++) {
            bulletin_posts[i].order = (bulletin_posts[i].order-1);
          }
        }
        dst_bulletin.task_bulletin_posts.replace(bulletin_posts);
      } else {
        // bulletin 외부로 이동
        const src_bulletin_posts = src_bulletin.sortedTaskBulletinPosts;
        const dst_bulletin_posts = dst_bulletin.sortedTaskBulletinPosts;

        let src_post = src_bulletin_posts.splice(order_from, 1)[0];
        for(let i=order_from; i<src_bulletin_posts.length; i++) {
          src_bulletin_posts[i].order -= 1;
        }

        const dst_post = TaskBulletinPostModel.create({
          id: src_post.id,
          createdAt: src_post.createdAt,
          updatedAt: src_post.updatedAt,
          order: order_to,
          task_bulletin_post_id: src_post.task_bulletin_post_id,
          title: src_post.title,
          content: src_post.content,
          task_bulletin: dst_bulletin.task_bulletin_id,
          writer: src_post.writer,
          links: src_post.links.slice(),
          files: src_post.files.slice()
        });

        dst_bulletin_posts.splice(order_to, 0, dst_post);
        for(let i=order_to+1; i<dst_bulletin_posts.length; i++) {
          dst_bulletin_posts[i].order += 1;
        }

        src_bulletin.task_bulletin_posts.replace(src_bulletin_posts);
        dst_bulletin.task_bulletin_posts.replace(dst_bulletin_posts);

      }
    };

    const moveTaskBulletin = flow(function* (id: number, order_to: number) {
      try {
        const { data }: { data: object } = yield axios.put(
          `/taskmgr/bulletin/${id}/moved`,
          {
            order_to: order_to
          }
        );

      } catch (e) {
        console.log("moveTaskBulletin error", e);
        throw e;
      }
    });
    const moveTaskBulletinPost = flow(function* (src_post_id:number, dst_bulletin_id: number, order_to: number) {
      try {
        const { data }: { data: object } = yield axios.put(
          `/taskmgr/bulletinpost/${src_post_id}/moved`,
          {
            dst_bulletin_id: dst_bulletin_id,
            order_to: order_to
          }
        );

      } catch (e) {
        console.log("moveTaskBulletinPost error", e);
        throw e;
      }
    });

    const deleteTaskBulletinPost = flow(function* (id: number) {
      try {
        yield axios.delete(`/taskmgr/bulletinpost/${id}`);
      } catch (e) {
        console.log("deleteTaskBulletinPost error", e);
        throw e;
      }
    });

    const updateBulletinPostLink = flow(function* (item: any, method: string) {
      try {
        if (method == "post") {

          const { data }: { data: BulletinPostLink[] } = yield axios.post(
            `/taskmgr/taskbulletinpostlink`,
            {
              "task_bulletin_post": item.task_bulletin_post_id,
              "link": item.link
            }
          );
          const taskBulletin: TaskBulletin | undefined = self.currentBulletins.find((bulletin: TaskBulletin) => {
            if (bulletin.task_bulletin_id === item.task_bulletin)
              return true;
          });
          const taskBulletinPost: TaskBulletinPost | undefined = taskBulletin && taskBulletin.task_bulletin_posts
            .find((bulletinPost) => bulletinPost.task_bulletin_post_id === item.task_bulletin_post_id);

          taskBulletinPost && taskBulletinPost.links.replace(data);
        } else if (method == "patch") {
          const { data }: { data: BulletinPostLink[] } = yield axios.patch(
            `/taskmgr/taskbulletinpostlink/${item.id}`, {
              link: item.link
            }
          );
          const taskBulletin: TaskBulletin | undefined = self.currentBulletins.find((bulletin: TaskBulletin) => {
            if (bulletin.task_bulletin_id === item.task_bulletin)
              return true;
          });
          const taskBulletinPost: TaskBulletinPost | undefined = taskBulletin && taskBulletin.task_bulletin_posts
            .find((bulletinPost) => bulletinPost.task_bulletin_post_id === item.task_bulletin_post_id);

          taskBulletinPost && taskBulletinPost.links.replace(data);
        } else if (method == "delete") {
          const { data }: { data: BulletinPostLink[] } = yield axios.delete(
            `/taskmgr/taskbulletinpostlink/${item.id}`
          );
          const taskBulletin: TaskBulletin | undefined = self.currentBulletins.find((bulletin: TaskBulletin) => {
            if (bulletin.task_bulletin_id === item.task_bulletin)
              return true;
          });
          const taskBulletinPost: TaskBulletinPost | undefined = taskBulletin && taskBulletin.task_bulletin_posts
            .find((bulletinPost) => bulletinPost.task_bulletin_post_id === item.task_bulletin_post_id);

          taskBulletinPost && taskBulletinPost.links.replace(data);
        }

      } catch (e) {
        console.log("TaskManageStore=>updateBulletinPostLink error", e);
        throw e;
      }
    });
    const updateBulletinPostFile = flow(function* (item: any, method: string) {
      try {
        if (method == "post") {
          const formData = new FormData();
          formData.append("file", item.fileModel.file!);
          formData.append("task_bulletin_post", item.task_bulletin_post_id);
          const { data }: { data: BulletinPostFile[] } = yield axios.post(
            `/taskmgr/taskbulletinpostfile`, formData
          );
          const taskBulletinPost = boundBulletinPost(item.task_bulletin, item.task_bulletin_post_id);
          taskBulletinPost && taskBulletinPost.files.replace(data);

        } else if (method == "patch") {

        } else if (method == "delete") {
          const { data }: { data: BulletinPostFile[] } = yield axios.delete(
            `/taskmgr/taskbulletinpostfile/${item.id}`
          );
          const taskBulletinPost = boundBulletinPost(item.task_bulletin, item.task_bulletin_post_id);
          taskBulletinPost && taskBulletinPost.files.replace(data);

        }

      } catch (e) {
        console.log("TaskManageStore=>updateBulletinPostLink error", e);
        throw e;
      }
    });


    const boundBulletinPost = (task_bulletin: string, task_bulletin_post_id: string): TaskBulletinPost | undefined => {
      const taskBulletin: TaskBulletin | undefined = self.currentBulletins.find((bulletin: TaskBulletin) => {
        if (bulletin.task_bulletin_id === task_bulletin)
          return true;
      });
      const taskBulletinPost: TaskBulletinPost | undefined = taskBulletin && taskBulletin.task_bulletin_posts
        .find((bulletinPost) => bulletinPost.task_bulletin_post_id === task_bulletin_post_id);

      return taskBulletinPost;
    };

    return {
      // 수정 중 draggable 설정
      setDraggable,
      addEditingTaskId,
      delEditingTaskId,
      addEditingTaskListId,
      delEditingTaskListId,      
      // 복수 선택
      addMultiSelectTaskId,
      clearMultiSelectTaskIds,
      previewMultiSelectInputs,
      updateMultiTasks,
      resetMultiTasks,
      createLabel,
      createSprint,
      // 검색, 필터
      setSearchString,
      setFilter,
      // 태스크 보드
      createTaskBoard,
      fetchTaskBoard,
      // 태스크 목록
      moveTaskList,
      deleteTaskList,
      updateTaskListPersonInCharge,
      // 태스크
      postTask,
      moveTask,
      updateTaskTitle,
      archiveTask,
      archiveTasks,
      deleteTask,
      deleteTasks,
      fetchSeesoTasks,
      // 운영정보
      fetchTaskBulletin,
      updateTaskBulletinTitle,
      postTaskBulletin,
      deleteTaskBulletin,
      updateTaskBulletinPostTitle,
      postTaskBulletinPost,
      deleteTaskBulletinPost,
      fetchTaskBulletinPost,
      putTaskBulletinPost,
      updateBulletinPostLink,
      updateBulletinPostFile,
      changeBulletinPos,
      changeBulletinPostPos,
      moveTaskBulletin,
      moveTaskBulletinPost,
    };
  });

type TaskManagerStoreModelType = typeof TaskManagerStoreModel.Type;

export interface TaskManagerStore extends TaskManagerStoreModelType {
}
