import React, { Component, ChangeEvent } from 'react';
import styled from "styled-components/macro";
import moment from "moment";
import { inject, observer } from "mobx-react";
import { observable, action } from "mobx";
import { Spinner, H5 } from "@blueprintjs/core";
import { AppStore } from "../../../store/AppStore";
import { ProjectGroup } from "../../../store/models/ProjectGroup";

import { SectionRightWrapper, SectionTitleWrapper } from '../../atoms/DashboardComponents/DashboardComponents';
import { DashboardProjectGroup } from '../../molecules/Dashboard/DashboardProjectGroup';
import { DashboardTimelineHeader } from '../../molecules/Dashboard/DashboardTimelineHeader';


const Wrapper = styled.div`
`;
const TimelineMainWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
`;
const SectionColorIndicator = styled.div`
  margin-left: 20px;
  display: flex;
  & > * + * {
    margin-left: 15px;
  }
`;
const PMIndicator = styled.span<{ color: string }>`
  color: ${p => p.color};
  font-weight: bold;
  font-size: 12px;
`


interface ProjectgroupTimelineProps {
  projectGroups: ProjectGroup[]
}

interface InjectedProps extends ProjectgroupTimelineProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ProjectgroupTimeline extends Component<ProjectgroupTimelineProps> {
  @observable projects: Array<ProjectGroup> = [];
  @observable onlyMyProjects = false;
	@observable readyToShow = false;
  @observable top = 0;
  @observable left = 0;
  @observable height = 0;
  divRef = React.createRef<HTMLDivElement>();

  get injected() {
    return this.props as InjectedProps;
  }

  get baseDate() {
    return moment();
  }

  get projectGroupStore() {
    return this.injected.appStore.projectGroupStore;
  }

  get pmUserStore() {
    return this.injected.appStore.pmUserStore;
  }

  get userStore() {
    return this.injected.appStore.userStore;
  }

  async componentDidMount() {
    try {
      await this.pmUserStore.fetchPMUsers();
      this.setProjects();

      this.readyToShow = true;
    } catch (e) {}

    if (this.divRef.current) {
      const rect = this.divRef.current.getBoundingClientRect()
      this.top = rect.top;
      this.left = rect.left;
      this.height = rect.height;
    }
  }

  setProjects = () => {
    const { username } = this.userStore;
    const baseProjectGroups = this.props.projectGroups;

    let haveToAdd = false;
    let projects: Array<ProjectGroup> = [];
    if (this.onlyMyProjects) {
      const date = this.baseDate.subtract(7, 'days');
      { baseProjectGroups.map(projectGroup => {
        haveToAdd = false;
        if(projectGroup.schedule && projectGroup.schedule.rufreeSchedules){
          projectGroup.schedule.rufreeSchedules.map(rs => {
            rs.sprints.filter(s => s.isValid).map(s => {
              if(date.diff(moment(s.dateEnd), 'days') < 0 && username === s.manager) {
                haveToAdd = true;
              }
            });
          });
        }

        if(haveToAdd) {
          projects.push(projectGroup);
        }
      }) }
      this.projects = projects;
    } else {
      this.projects = baseProjectGroups;
    }
  }

  @action
  handleOnlyMyProjects = () => {
    this.onlyMyProjects = true;
    this.setProjects();
  }

  @action
  handleAllProjects = () => {
    this.onlyMyProjects = false;
    this.setProjects();
  }

  render() {
    const dateWidth = 25;
    // const { baseDate } = this.dashboardStore;
    const baseDate = this.baseDate.format('YYYY-MM-DD');
    const { pmUsers } = this.pmUserStore;
    const { projectGroups } = this.props;

  	return (
      <Wrapper ref={this.divRef}>
        <SectionTitleWrapper>
          <H5>프로젝트 타임라인</H5>
          <SectionColorIndicator>
            {
              pmUsers.filter(x => x.is_manager
                && x.email!=='partner@seeso.kr'
                && x.email !== 'client@seeso.kr'
                && x.email !== 'jy@seeso.kr').map(pm => {
                  let projectCount = 0;
                  projectGroups.map(pg => {
                    if (pg.managers.find(managers => managers.name === pm.name)) {
                      projectCount += 1;
                    }
                  })

                  return (
                    <div key={`pmci_${pm.id}`}>
                      <PMIndicator
                        color={pm.color ? pm.color : '#BFCCD6'}>
                          {`${pm.nickname}(${projectCount})`}
                      </PMIndicator>
                    </div>
                  )
              })
            }
            <div>
              <PMIndicator color={'#BFCCD6'}>미정</PMIndicator>
            </div>

          </SectionColorIndicator>
          <SectionRightWrapper>
            <span onClick={ this.handleOnlyMyProjects } style={(this.onlyMyProjects ? {fontWeight: 'bold'} : {})}>내 프로젝트</span> 
             | <span onClick={ this.handleAllProjects } style={(this.onlyMyProjects ? {} : {fontWeight: 'bold'})}>전체 프로젝트</span>
          </SectionRightWrapper>
        </SectionTitleWrapper>

          {this.readyToShow ? (
            <div>

              <TimelineMainWrapper>
                <DashboardTimelineHeader baseDate={baseDate} dateWidth={ dateWidth } />

                  { this.projects.map(projectGroup => {
                    if (projectGroup.schedule)
                      return <DashboardProjectGroup
                        key={`dpg_${projectGroup.groupId}`}
                        baseDate={baseDate}
                        dateWidth={dateWidth}
                        pmUsers={pmUsers}
                        projectGroup={projectGroup} />
                  }) }

              </TimelineMainWrapper>
            </div>
            ) : (
            <Spinner />
            )
          }
      </Wrapper>
	  );
  }
}

export default ProjectgroupTimeline;
