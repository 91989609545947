import { types } from "mobx-state-tree";
import {
  ScheduleSprintFormModel,
  ScheduleSprintForm,
  updateSprintNeighborDates
} from "./ScheduleSprintForm";

export const RufreeScheduleFormModel = types
  .model("RufreeScheduleForm", {
    rufreeId: types.optional(types.string, ""),
    rufreeName: types.optional(types.string, ""),
    inspectionId: types.optional(types.string, ""),
    role: types.optional(types.string, ""),
    sprints: types.array(ScheduleSprintFormModel),
    numInspectionSprints: types.optional(types.number, 0), // TODO: 백엔드에서 자동 갱신해줄 거 아니라면 computed 로 구현해야할듯.
    numScheduleSprints: types.optional(types.number, 0)
  })
  .views(self => ({
    get totalSprintDays() {
      return self.sprints.filter(sprint => sprint.isValid).length;
    }
  }))
  .actions(self => ({
    setRufreeId(value: string) {
      self.rufreeId = value;
    },
    setRufreeName(value: string) {
      self.rufreeName = value;
    },
    setInspectionId(value: string) {
      self.inspectionId = value;
    },
    setRole(value: string) {
      self.role = value;
    },
    setNumInspectionSprints(value: number) {
      self.numInspectionSprints = value;
    },
    setNumScheduleSprints(value: number) {
      self.numScheduleSprints = value;
    },

    addSprint() {
      self.sprints.push(ScheduleSprintFormModel.create());
    },

    updateSprintNeighborDates(sprint: ScheduleSprintForm) {
      updateSprintNeighborDates(self.sprints, sprint);
    }
  }));

type RufreeScheduleFormType = typeof RufreeScheduleFormModel.Type;
export interface RufreeScheduleForm extends RufreeScheduleFormType {}
