import { types } from "mobx-state-tree";

export const AdminTypeTexts = [
  {
    key: "user",
    text: "유저관리자"
  },
  {
    key: "product",
    text: "상품관리자"
  },
  {
    key: "contents",
    text: "컨텐츠관리자"
  },
  {
    key: "message",
    text: "문자/메일발송"
  }
];

export const mapAdminTypeKeyToText = (key: string) => {
  const type = AdminTypeTexts.find(x => x.key === key);

  return type ? type.text : key;
};

export const ConsultAdminTypeModel = types.model("ConsultAdminType", {
  checked: types.boolean,
  etc: types.boolean,
  text: types.string
});

type ConsultAdminTypeType = typeof ConsultAdminTypeModel.Type;
export interface ConsultAdminType extends ConsultAdminTypeType {}
