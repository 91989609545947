import { types, flow, IMSTArray, ISimpleType } from "mobx-state-tree";
import axios from "axios";
import {
  InspectionSelectionFormModel,
  InspectionSelectionForm
} from "../ProjectGroupForm/InspectionSelectionForm";
import { ClientContractFormModel } from "./ClientContractForm";
import {
  ClientContractRequestModel,
  RufreeContractRequestModel,
  RufreeContractRequestSimpleModel
} from "../../models/ContractRequest";

export const ApprovedContractFormModel = types
  .model("ApprovedContractForm", {
    type: types.string,  // client or rufree
    approved_contract_id: types.optional(types.string, ""),
    contract: types.optional(types.string, ""),
    contract_detail: types.maybeNull(ClientContractRequestModel),
    rufree_contract_detail: types.maybeNull(RufreeContractRequestSimpleModel),
    template: types.maybeNull(types.string),
    comment_opened: types.optional(types.boolean, false),
    editor_content: types.optional(types.string, ""),
    manager_name: types.optional(types.string, ""),
    contract_name: types.optional(types.string, ""),
    client: types.optional(types.string, "")
  })
  .views(self => ({
  }))
  .actions(self => ({
    setContract(value: string) {
      self.contract = value;
    },
    setTemplate(value: string) {
      self.template = value;
    },
    toggleCommentOpened() {
      self.comment_opened = !self.comment_opened;
    },
    setEditorContent(value: string) {
      self.editor_content = value;
    },
    setContractName(value: string) {
      self.contract_name = value;
    },
    setClient(value: string) {
      self.client = value;
    }
  }))

type ApprovedContractFormType = typeof ApprovedContractFormModel.Type;
export interface ApprovedContractForm extends ApprovedContractFormType {}
