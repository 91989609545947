import React, { Component } from "react";
import { Switch, Route } from "react-router";
import MainTemplate from "./components/templates/MainTemplate/MainTemplate";
import ConsultingPage from "./components/pages/ConsultingPage/ConsultingPage";
import HomePage from "./components/pages/HomePage/HomePage";
import ClientsPage from "./components/pages/ClientsPage/ClientsPage";
import InspectionPrintPanel from "./components/panels/InspectionPrintPanel/InspectionPrintPanel";
import RufreeListPage from "./components/pages/RufreeListPage/RufreeListPage";
import RufreeFormPage from "./components/pages/RufreeFormPage/RufreeFormPage";
import RufreeStatPage from "./components/pages/RufreeStatPage/RufreeStatPage";
import { FormMode } from "./types/formMode";
import ProjectGroupListPage from "./components/pages/ProjectGroupListPage/ProjectGroupListPage";
import ProjectGroupFormPage from "./components/pages/ProjectGroupFormPage/ProjectGroupFormPage";
import ProjectGroupDetailPage from "./components/pages/ProjectGroupDetailPage/ProjectGroupDetailPage";
import ScheduleFormPage from "./components/pages/ScheduleFormPage/ScheduleFormPage";
import PMReportPage from "./components/pages/PMReportListPage/PMReportPage";
import PMReportFormPage from "./components/pages/PMReportFormPage/PMReportFormPage";
import SupportPMReportFormPage from "./components/pages/PMReportFormPage/SupportPMReportFormPage";
import ProjectGroupRiskFormPage from "./components/pages/ProjectGroupRiskFormPage/ProjectGroupRiskFormPage";
import ProjectGroupTasktaskFormPage from "./components/pages/ProjectGroupTasktaskFormPage/ProjectGroupTasktaskFormPage";
// import ExpensePrintTable from "./components/organisms/ExpenseTable/ExpensePrintTable";
import SprintReportDetailPage from "./components/pages/SprintReportDetailPage/SprintReportDetailPage";
import SprintReportPrintPage from "./components/pages/SprintReportPrintPage/SprintReportPrintPage";
import InspectionListPanel from "./components/panels/InspectionListPanel/InspectionListPanel";
import InspectionFormPanel from "./components/panels/InspectionFormPanel/InspectionFormPanel";
import InspectionDetailPanel from "./components/panels/InspectionDetailPanel/InspectionDetailPanel";
import ReceptionDetailPage from "./components/pages/ReceptionDetailPage/ReceptionDetailPage";
import ReceptionListPage from "./components/pages/ReceptionListPage/ReceptionListPage";
import RufreeMatchingFormPage from "./components/pages/RufreeMatchingFormPage/RufreeMatchingFormPage";
import RufreeMatchingListPage from "./components/pages/RufreeMatchingListPage/RufreeMatchingListPage";
import RufreeMatchingDetailPage from "./components/pages/RufreeMatchingDetailPage/RufreeMatchingDetailPage";
import CalculatePage from "./components/pages/CalculatePage/CalculatePage";
import AuthPage from "./components/pages/AuthPage/AuthPage";
import LoginPage from "./components/pages/LoginPage/LoginPage";
import ClientFormPage from "./components/pages/ClientFormPage/ClientFormPage";
import TasktaskListPage from "./components/pages/TasktaskListPage/TasktaskListPage";
import ContractPage from "./components/pages/ContractPage/ContractPage";
import ClientContractRequestFormPage from "./components/pages/ContractFormPage/ClientContractRequestFormPage";
import RufreeContractRequestFormPage from "./components/pages/ContractFormPage/RufreeContractRequestFormPage";
import ApprovedContractFormPage from "./components/pages/ContractFormPage/ApprovedContractFormPage";
import ContractTemplateFormPage from "./components/pages/ContractFormPage/ContractTemplateFormPage";
import ApprovedClientContractDetailPage from "./components/pages/ContractDetailPage/ApprovedClientContractDetailPage";
import ApprovedRufreeContractDetailPage from "./components/pages/ContractDetailPage/ApprovedRufreeContractDetailPage";
import ClientContractRequestDetailPage from "./components/pages/ContractDetailPage/ClientContractRequestDetailPage";
import RufreeContractRequestDetailPage from "./components/pages/ContractDetailPage/RufreeContractRequestDetailPage";
import ContractTemplateDetailPage from "./components/pages/ContractDetailPage/ContractTemplateDetailPage";
import ApprovedContractPrintPage from "./components/pages/ContractDetailPage/ApprovedContractPrintPage";
import TaskManagerPage from "./components/pages/TaskManagerPage/TaskManagerPage";
import PartTimeDashboardPage from "./components/pages/PartTimePage/DashboardPage";
import PartTimeRequestListPage from "./components/pages/PartTimePage/RequestListPage";
import PartTimeRequestDetailPage from "./components/pages/PartTimePage/RequestDetailPage";
import PartTimeTaskListPage from "./components/pages/PartTimePage/TaskListPage";
import PartTimeTaskDetailPage from "./components/pages/PartTimePage/TaskDetailPage";
import ReceptionStatsPage from "./components/pages/ReceptionStatsPage/ReceptionStatsPage";
import TemplateManagerPage from "./components/pages/NotificationTemplatePage/NotificationTemplatePage";
import InspectionTemplateFormPanel from "./components/panels/InspectionFormPanel/InspectionTemplateFormPanel";
import WorkingModePage from './components/pages/WorkingModePage/WorkingModePage';

import PlanPrintSection from './components/organisms/Plan/PlanPrintSection';

import SecondteamCalculatePage from './components/pages/secondteam/Calculate';

import {
  SecondteamMember,
  SecondteamMemberDetail,
  SecondteamPartner,
  SecondteamPartnerDetail,
  SecondteamInvitationPage
} from './components/pages/secondteam';

class Routes extends Component {
  render() {
    const mode = process.env.REACT_APP_MODE;
    if(mode === 'working') {
      return (
        <Switch>
          <Route path="/" exact={true} component={WorkingModePage} />

          <Route component={ WorkingModePage } />
        </Switch>
      )
    }
    return (
      <Switch>
        <Route
          path="/inspections/:inspectionId/print"
          render={({ match }) => (
            <InspectionPrintPanel inspectionId={match.params.inspectionId} />
          )}
        />
        <Route
          path="/inspections/:inspectionId/pdf"
          render={({ match }) => (
            <PlanPrintSection inspectionId={match.params.inspectionId} />
          )}
        />
        <Route
          path="/project-groups/:projectGroupId/sprint-reports/:sprintReportId/print"
          render={props => <SprintReportPrintPage {...props} />}
        />
        <Route
          path="/contract/:type/:id/print"
          render={({ match, ...props }) => (
            <ApprovedContractPrintPage
              id={match.params.id}
              type={match.params.type}
              {...props} />
          )}
        />
        {
        // <Route
        //   path="/project-groups/:projectGroupId/expense-table/print"
        //   render={props => <ExpensePrintTable {...props} />} />
        //   )}
        // />
      }

        {/* 태스크매니저 시작 */}
        <Route path="/taskmgr/:projectGroupId">
          <MainTemplate collapsed={true}>
            <Route
                path="/taskmgr/:projectGroupId"
                exact={true}
                render={props => <TaskManagerPage {...props} />}
              />            
          </MainTemplate>
        </Route>
        {/* 태스크매니저 끝 */}

        {/* 모델하우스 페이지들 시작 */}
        <Route path="/modelhouse/working" exact={true} component={WorkingModePage} />
        {/* 모델하우스 페이지들 끝 */}

        <Route path="/">
          <MainTemplate collapsed={false}>
            <Switch>
              <Route path="/" exact={true} component={HomePage} />
              <Route path="/login" exact={true} component={LoginPage} />
              <Route path="/auth" exact={true} component={AuthPage} />

              {/* 세컨팀 페이지 시작 */}
              <Route path="/secondteam/member" exact={true} component={SecondteamMember} />
              <Route path='/secondteam/member/:code' exact={true} component={SecondteamMemberDetail} />
              <Route path="/secondteam/calculate" exact={true} component={SecondteamCalculatePage} />
              <Route path="/secondteam/partner" exact={true} component={SecondteamPartner} />
              <Route path="/secondteam/partner/:code" exact={true} component={SecondteamPartnerDetail} />
              <Route path="/secondteam/invitation" exact={true} component={SecondteamInvitationPage} />
              {/* 세컨팀 페이지 끝 */}



              <Route path="/consulting" component={ConsultingPage} />
              <Route path="/clients" exact={true} component={ClientsPage} />
              <Route
                path="/inspections"
                exact={true}
                component={InspectionListPanel}
              />
              <Route
                path="/inspections/new"
                render={props => (
                  <InspectionFormPanel mode={FormMode.Create} {...props} />
                )}
              />
              <Route
                path="/inspections/:inspectionId/read"
                render={({ match, ...props }) => (
                  <InspectionDetailPanel
                    mode={FormMode.Read}
                    inspectionId={match.params.inspectionId}
                    {...props}
                  />
                )}
              />
              <Route
                path="/inspections/:inspectionId/edit"
                render={props => (
                  <InspectionFormPanel
                    mode={FormMode.Edit}
                    inspectionId={props.match.params.inspectionId}
                    {...props}
                  />
                )}
              />
              <Route path="/rufrees" exact={true} component={RufreeListPage} />
              <Route
                path="/rufrees/new"
                render={props => (
                  <RufreeFormPage mode={FormMode.Create} {...props} />
                )}
              />
              <Route
                path="/rufrees/stat"
                render={props => (
                  <RufreeStatPage {...props} />
                )}
              />              
              <Route
                path="/rufrees/:rufreeId/edit"
                render={props => (
                  <RufreeFormPage mode={FormMode.Edit} {...props} />
                )}
              />
              <Route
                path="/project-groups"
                exact={true}
                component={ProjectGroupListPage}
              />
              <Route
                path="/project-groups/new"
                exact={true}
                render={props => (
                  <ProjectGroupFormPage mode={FormMode.Create} {...props} />
                )}
              />
              <Route
                path="/project-groups/:projectGroupId"
                exact={true}
                render={props => <ProjectGroupDetailPage {...props} />}
              />
              <Route
                path="/project-groups/:projectGroupId/edit"
                render={props => (
                  <ProjectGroupFormPage mode={FormMode.Edit} {...props} />
                )}
              />
              <Route
                path="/project-groups/:projectGroupId/schedule"
                render={props => <ScheduleFormPage {...props} />}
              />
              <Route
                path="/project-groups/:projectGroupId/risk"
                render={props => <ProjectGroupRiskFormPage {...props} />}
              />
              <Route
                path="/project-groups/:projectGroupId/tasktask/:tasktaskId"
                render={props => <ProjectGroupTasktaskFormPage {...props} />}
              />
              <Route
                path="/project-groups/:projectGroupId/sprint-reports/:sprintReportId"
                exact={true}
                render={props => <SprintReportDetailPage {...props} />}
              />

              <Route
                path="/pm-reports"
                exact={true}
                component={PMReportPage}
              />
              <Route
                path="/pm-reports/new"
                exact={true}
                render={props => (
                  <PMReportFormPage mode={FormMode.Create} {...props} />
                )}
              />
              <Route
                path="/pm-reports/support/new"
                exact={true}
                render={props => (
                  <SupportPMReportFormPage mode={FormMode.Create} {...props} />
                )}
              />
              <Route
                path="/pm-reports/:pmReportId/edit"
                render={props => (
                  <PMReportFormPage mode={FormMode.Edit} {...props} />
                )}
              />
              <Route
                path="/pm-reports/:pmReportId/support/edit"
                render={props => (
                  <SupportPMReportFormPage mode={FormMode.Edit} {...props} />
                )}
              />
              <Route
                path="/receptions/stats"
                exact={true}
                render={props => (
                  <ReceptionStatsPage {...props}/>
                )}
              />
              <Route
                path="/receptions"
                exact={true}
                render={props => <ReceptionListPage {...props} />}
              />
              <Route
                path="/receptions/:receptionId"
                render={props => <ReceptionDetailPage {...props} />}
              />

              <Route
                path="/rufree-matchings"
                exact={true}
                render={props => <RufreeMatchingListPage {...props} />}
              />
              <Route
                path="/rufree-matchings/:rufreeMatchingId"
                exact={true}
                render={props => <RufreeMatchingDetailPage {...props} />}
              />
              <Route
                path="/project-groups/:projectGroupId/rufree-matchings/new"
                exact={true}
                render={props => (
                  <RufreeMatchingFormPage mode={FormMode.Create} {...props} />
                )}
              />
              <Route
                path="/project-groups/:projectGroupId/rufree-matchings/:rufreeMatchingId/edit"
                exact={true}
                render={props => (
                  <RufreeMatchingFormPage mode={FormMode.Edit} {...props} />
                )}
              />

              <Route
                path="/clients/new"
                exact={true}
                render={props => (
                  <ClientFormPage mode={FormMode.Create} {...props} />
                )}
              />
              <Route
                path="/clients/:clientId"
                exact={true}
                render={props => (
                  <ClientFormPage mode={FormMode.Edit} {...props} />
                )}
              />
              <Route
                path="/calculate"
                exact={true}
                render={props => (
                  <CalculatePage {...props} />
                )}
              />

              <Route
                path="/tasktask"
                exact={true}
                render={props => (
                  <TasktaskListPage {...props} />
                )}
              />

              <Route
                path="/contract"
                exact={true}
                render={props => (
                  <ContractPage {...props} />
                )}
              />
              <Route
                path="/contract/client/:id/read"
                render={({ match, ...props }) => (
                  <ApprovedClientContractDetailPage
                    id={match.params.id}
                    {...props} />
                )}
              />
              <Route
                path="/contract/rufree/:id/read"
                render={({ match, ...props }) => (
                  <ApprovedRufreeContractDetailPage
                    id={match.params.id}
                    {...props} />
                )}
              />
              <Route
                path="/contract/:type/new"
                render={({ match, ...props }) => (
                  <ApprovedContractFormPage
                    id={""}
                    type={match.params.type}
                    mode={FormMode.Create}
                    {...props} />
                )}
              />
              <Route
                path="/contract/:type/:id/edit"
                render={({ match, ...props }) => (
                  <ApprovedContractFormPage
                    id={match.params.id}
                    type={match.params.type}
                    mode={FormMode.Edit}
                    {...props} />
                )}
              />
              <Route
                path="/contract-request/client/new"
                render={props => (
                  <ClientContractRequestFormPage mode={FormMode.Create} {...props} />
                )}
              />
              <Route
                path="/contract-request/client/:id/edit"
                render={props => (
                  <ClientContractRequestFormPage mode={FormMode.Edit} {...props} />
                )}
              />
              <Route
                path="/contract-request/client/:id/read"
                render={({ match, ...props }) => (
                  <ClientContractRequestDetailPage
                    id={match.params.id}
                    {...props} />
                )}
              />
              <Route
                path="/contract-request/rufree/new"
                render={props => (
                  <RufreeContractRequestFormPage mode={FormMode.Create} {...props} />
                )}
              />
              <Route
                path="/contract-request/rufree/:id/edit"
                render={props => (
                  <RufreeContractRequestFormPage mode={FormMode.Edit} {...props} />
                )}
              />
              <Route
                path="/contract-request/rufree/:id/read"
                render={({ match, ...props }) => (
                  <RufreeContractRequestDetailPage
                    id={match.params.id}
                    {...props} />
                )}
              />
              <Route
                path="/contract-templates/new"
                render={props => (
                  <ContractTemplateFormPage mode={FormMode.Create} {...props} />
                )}
              />
              <Route
                path="/contract-templates/:id/edit"
                render={props => (
                  <ContractTemplateFormPage mode={FormMode.Edit} {...props} />
                )}
              />
              <Route
                path="/contract-templates/:id/read"
                render={props => (
                  <ContractTemplateDetailPage {...props} />
                )}
              />
              <Route
                path="/parttime"
                exact={true}
                render={props => (
                  <PartTimeDashboardPage {...props} />
                )}
              />
              <Route
                path="/parttime/requests"
                exact={true}
                render={props => (
                  <PartTimeRequestListPage {...props} />
                )}
              />
              <Route
                path="/parttime/requests/:requestId/tasks/:taskId"
                render={props => (
                  <PartTimeTaskDetailPage {...props} />
                )}
              />
              <Route
                path="/parttime/requests/:id"
                render={props => (
                  <PartTimeRequestDetailPage {...props} />
                )}
              />
              <Route
                path="/parttime/tasks"
                exact={true}
                render={props => (
                  <PartTimeTaskListPage {...props} />
                )}
              />

              <Route
                path="/templates"
                exact={true}
                render={props => (
                  <TemplateManagerPage {...props} />
                )}
              />

              <Route
                path="/templates/inspections/new"
                render={props => (
                  <InspectionTemplateFormPanel mode={FormMode.Create} {...props} />
                )}
              />
              <Route
                path="/templates/inspections/:id/edit"
                render={props => (
                  <InspectionTemplateFormPanel mode={FormMode.Edit} {...props} />
                )}
              />

            </Switch>
          </MainTemplate>
        </Route>
      </Switch>
    );
  }
}

export default Routes;
