import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { ItemPredicate, Select, IItemRendererProps } from "@blueprintjs/select";
import { computed } from "mobx";
import { MenuItem, Button } from "@blueprintjs/core";
import { highlightText } from "../../../utils/BlueprintSelectionHelpers";

const rufreeRoles = [
  "기획",
  "웹디자인",
  "앱디자인",
  "기타디자인",
  "풀스택",
  "백엔드",
  "프론트엔드",
  "퍼블리싱",
  "iOS",
  "AOS",
  "RN",
  "WP개발자",
  "QA테스트",
  "PM",
  "기타"
];


export const filterItem: ItemPredicate<string> = (
  query,
  item,
  _index,
  exactMatch
) => {
  const normalizedTitle = item.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
    return normalizedTitle === normalizedQuery;
  } else {
    return `${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
  }
};

const ItemSelect = Select.ofType<string>();

interface RufreeRoleSelectionProps {
  onChange: (rufreeRole: string) => void;
  className?: string;
  currentValue?: string;
  items?: string[];
}

interface InjectedProps extends RufreeRoleSelectionProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class RufreeRoleSelection extends Component<RufreeRoleSelectionProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get items() {
    return this.props.items || rufreeRoles;
  }

  itemRenderer = (
    item: string,
    { handleClick, query, modifiers }: IItemRendererProps
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        active={modifiers.active}
        text={highlightText(item, query)}
        onClick={handleClick}
      />
    );
  };

  render() {
    const { onChange, currentValue, className } = this.props;

    return (
      <ItemSelect
        items={this.items}
        onItemSelect={onChange}
        itemRenderer={this.itemRenderer}
        itemPredicate={filterItem}
        popoverProps={{ minimal: true }}
        className={ className }
      >
        <Button
          rightIcon="caret-down"
          text={currentValue ? currentValue : "선택하세요"}
          // style={{ display: "block", width: "100%" }}
        />
      </ItemSelect>
    );
  }
}

export default RufreeRoleSelection;
