import React, { Component, ChangeEvent } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { ProjectGroupWorkerForm } from "../../../store/forms/ProjectGroupForm/ProjectGroupWorkerForm";
import { computed, observable } from "mobx";
import { InputGroup, HTMLSelect } from "@blueprintjs/core";
import ClientUserSelection from "../../molecules/ClientUserSelection/ClientUserSelection";
import { ClientUser } from "../../../store/models/ClientUser";
import { Client } from "../../../store/models/Client";

const Wrapper = styled.div`
  margin-bottom: 10px;
  white-space: nowrap;

  & > * {
    margin-right: 8px;
    display: inline-block;
  }
`;

enum ValueType {
  Contact = "contact",
  ClientUser = "clientUser"
}

interface ProjectGroupWorkerFormViewProps {
  client?: Client;
  worker: ProjectGroupWorkerForm;
}

interface InjectedProps extends ProjectGroupWorkerFormViewProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ProjectGroupWorkerFormView extends Component<
  ProjectGroupWorkerFormViewProps
> {
  @observable type = ValueType.Contact;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get currentForm() {
    return this.props.worker;
  }

  @computed
  get clientUsers() {
    if(this.props.client && this.props.client.clientId) {
      return this.injected.appStore.clientUserStore.clientUsers.filter(
        x => x.clientId !== undefined && this.props.client !== undefined && x.clientId === this.props.client.clientId
      );
    }

    return this.injected.appStore.clientUserStore.clientUsers;
  }

  @computed
  get selectedClientUser() {
    return this.injected.appStore.clientUserStore.clientUsers.find(
      x => x.id === this.currentForm.clientUserId
    );
  }

  handleTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    this.type = e.currentTarget.value as ValueType;
  };

  handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.currentForm.setName(e.target.value);
  };

  handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.currentForm.setPhone(e.target.value);
  };

  handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.currentForm.setEmail(e.target.value);
  };

  handleClientUserChange = (selectedValue: ClientUser) => {
    this.currentForm.setClientUserId(selectedValue.id);
    this.currentForm.setName(selectedValue.name);
    this.currentForm.setPhone(selectedValue.phone);
    this.currentForm.setEmail(selectedValue.email);
  };

  render() {
    return (
      <Wrapper>
        <HTMLSelect value={this.type} onChange={this.handleTypeChange}>
          <option value={ValueType.Contact}>연락처</option>
          <option value={ValueType.ClientUser}>소속유저</option>
        </HTMLSelect>
        {this.type === ValueType.Contact ? (
          <>
            <InputGroup
              placeholder="이름"
              value={this.currentForm.name}
              onChange={this.handleNameChange}
            />
            <InputGroup
              placeholder="전화번호"
              value={this.currentForm.phone}
              onChange={this.handlePhoneChange}
            />
            <InputGroup
              placeholder="이메일"
              value={this.currentForm.email}
              onChange={this.handleEmailChange}
            />
          </>
        ) : (
          <ClientUserSelection
            onChange={this.handleClientUserChange}
            currentValue={this.selectedClientUser}
            items={this.clientUsers}
            blockFetchingData={true}
          />
        )}
      </Wrapper>
    );
  }
}

export default ProjectGroupWorkerFormView;
