import React, { Component } from "react";
import styled from "styled-components/macro";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { H6, Icon, Intent, HTMLTable } from "@blueprintjs/core";
import moment from 'moment';

import DashboardSchedulePopup from "../../molecules/Dashboard/DashboardSchedulePopup";

const Schedule = styled.div`
  width: 100%;
`;
const ScheduleTitle = styled.div`
  display: flex;
  flex-direction: row;
`;
const ScheduleBody = styled.div`
  height: 450px;
  padding: 5px;
  background: #f1f1f1;
  overflow-y: auto;
`;
const H6Wrapper = styled(H6)`
  margin-right: 5px;
`;
const IconWrapper = styled(Icon)`
  cursor: pointer;
`;
const RightWrapper = styled.div`
  margin-left: auto;
  font-size: 12px;
  cursor: pointer;
  & > * + * {
    margin-left: 8px;
  }
`;
const ScheduleTable = styled(HTMLTable)`
  width: 100%;
`;

const ScheduleTab = styled.span`
  font-weight: bold;
`;
const ScheduleTabDim = styled.span`
  color: #888;
`;

const ScheduleBodyText = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

interface DashboardScheduleTodayProps {}

interface InjectedProps extends DashboardScheduleTodayProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class DashboardScheduleToday extends Component<DashboardScheduleTodayProps> {
  @observable showAll = false;
  @observable isPopupOpen: boolean = false;
  @observable selectedId: number = -1;

  get injected() {
    return this.props as InjectedProps;
  }

  async componentDidMount() {
  }

  handlePopupClick(id: number) {
    this.selectedId = id ? id : -1;
    this.isPopupOpen = true;
  }

  handlePopupClose = async () => {
    this.isPopupOpen = false;
  }

  handleMeegingPopupClick(url: string) {
    window.open(url, '_blank');
  }

  setShowAll = (value:boolean) => {
    this.showAll = value;
  }

  render_sch_category(category: string) {
    switch (category) {
      case 'vac':
        return <>휴가</>
      case 'vam':
        return <>오전반차</>
      case 'vpm':
        return <>오후반차</>
      default:
        return <>{category}</>
    }
  }

  render_startAt(x: any) {
    if(x.category === 'sch' && x.schCategory === 'vac') {
      // 연차
      return `~ ${moment(x.endAt).format('MM-DD')}`
    } else {
      // 반차
      return moment(x.startAt).format('MM-DD')
    }
  }

  render_meeting_startAt(x: any) {
    return moment(x.start).format('HH:mm')
  }

  render_pm_name_by_userid(x: any) {
    let pm = this.injected.appStore.pmUserStore.pmByUserId(x.creator);
    return pm ? pm.nickname : '';
  }

  render_pm_name_by_email(x: any) {
    let pm = this.injected.appStore.pmUserStore.pmByEmail(x.creator);
    return pm ? pm.nickname : '';
  }

  render() {
    const { userStore, scheduleManagerStore: scheduleStore } = this.injected.appStore;
    const { userId, email } = userStore;

    const { todayVacations } = scheduleStore;
    const myTodayVacations = todayVacations.filter(x => x.creator === userId || x.participants.find(id => id == userId));
    const vacations = this.showAll ? todayVacations : myTodayVacations;

    const { todayMeetings } = scheduleStore;
    const myTodayMeetings = todayMeetings.filter(x => x.creator === email || x.attendees.find(attendee => attendee === email));
    const meetings = this.showAll ? todayMeetings : myTodayMeetings;

    return (
      <Schedule>
      <ScheduleTitle>
        <H6Wrapper>오늘 일정</H6Wrapper>
        <IconWrapper 
          icon={'add'} 
          intent={Intent.PRIMARY} 
          iconSize={14}
          onClick={() => this.handlePopupClick(-1)} />
        <RightWrapper>
          { this.showAll ? (<ScheduleTabDim onClick={() => this.setShowAll(false)}>내 일정</ScheduleTabDim>) : (<ScheduleTab onClick={() => this.setShowAll(false)}>내 일정</ScheduleTab>) }
          { this.showAll ? (<ScheduleTab onClick={() => this.setShowAll(true)}>전체일정</ScheduleTab>) : (<ScheduleTabDim onClick={() => this.setShowAll(true)}>전체일정</ScheduleTabDim>) }          
        </RightWrapper>
      </ScheduleTitle>
      <ScheduleBody>
        <ScheduleTable
          bordered={false}
          condensed={true}
          interactive={true}
        >
          <tbody>
            { vacations.map(
              x => 
              this.showAll ? 
              (
                <tr key={x.id} onClick={() => this.handlePopupClick(x.id)}>
                  <td style={{boxShadow:'none', minWidth:'75px'}}>{this.render_startAt(x)}</td>
                  <td style={{boxShadow:'none', maxWidth:'60px'}}><ScheduleBodyText>{this.render_pm_name_by_userid(x)}</ScheduleBodyText></td>
                  <td style={{boxShadow:'none', minWidth:'75px'}}>{this.render_sch_category(x.schCategory)}</td>
                  <td style={{boxShadow:'none', maxWidth:'125px'}}>
                    <ScheduleBodyText>{x.title}</ScheduleBodyText>
                  </td>
                </tr>
              )
              :
              (
              <tr key={x.id} onClick={() => this.handlePopupClick(x.id)}>
                <td style={{boxShadow:'none', minWidth:'75px'}}>{this.render_startAt(x)}</td>
                <td style={{boxShadow:'none', minWidth:'75px'}}>{this.render_sch_category(x.schCategory)}</td>
                <td style={{boxShadow:'none', maxWidth:'185px'}}>
                  <ScheduleBodyText>{x.title}</ScheduleBodyText>
                </td>
              </tr>
            ))}
            { meetings.map(
              x => 
              this.showAll ? 
              (
                <tr key={x.id} onClick={() => this.handleMeegingPopupClick(x.htmlLink)}>
                  <td style={{boxShadow:'none', minWidth:'75px'}}>{this.render_meeting_startAt(x)}</td>
                  <td style={{boxShadow:'none', maxWidth:'60px'}}><ScheduleBodyText>{this.render_pm_name_by_email(x)}</ScheduleBodyText></td>
                  <td style={{boxShadow:'none', minWidth:'75px'}}>{x.type}</td>
                  <td style={{boxShadow:'none', maxWidth:'125px'}}>
                    <ScheduleBodyText>{x.summary}</ScheduleBodyText>
                  </td>
                </tr>
              )
              :
              (
              <tr key={x.id} onClick={() => this.handleMeegingPopupClick(x.htmlLink)}>
                <td style={{boxShadow:'none', minWidth:'75px'}}>{this.render_meeting_startAt(x)}</td>
                <td style={{boxShadow:'none', minWidth:'75px'}}>{x.type}</td>
                <td style={{boxShadow:'none', maxWidth:'185px'}}>
                  <ScheduleBodyText>{x.summary}</ScheduleBodyText>
                </td>
              </tr>
            ))}            
          </tbody>
        </ScheduleTable>
      </ScheduleBody>
      <DashboardSchedulePopup
          id={this.selectedId}
          onClose={this.handlePopupClose}
          isOpened={this.isPopupOpen} />
    </Schedule>
    );
  }
}

export default DashboardScheduleToday;