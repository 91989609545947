import React, { Component } from "react";
import styled from "styled-components/macro";
import { computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { ProjectGroup } from "../../../store/models/ProjectGroup";
import SprintReviewRow from "../../molecules/SprintReviewRow/SprintReviewRow";
import SprintReportPopup from "../../molecules/SprintReviewPopup/SprintReportPopup";
import { FormMode } from "../../../types/formMode";
import {
  FlexRow,
  GreyLabel
} from "../../atoms/DetailReportComponents/DetailReportComponents";
import { LeftColumn } from "../SprintTableClient/SprintTableClient";
import {
  GreyBackground,
  Table,
  THead
} from "../../atoms/ExpenseTable/ExpenseTable";


const Wrapper = styled.div`
  margin-bottom: 50px;
`;
const TableWrapper = styled.div`
  position: relative;
`;




interface SprintReviewTableProps {
  projectGroup: ProjectGroup;
  currentReportId: string;
}

interface InjectedProps extends SprintReviewTableProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class SprintReviewTable extends Component<SprintReviewTableProps> {
  @observable reportMode = this.props.currentReportId ? FormMode.Read : FormMode.Create;
  @observable reportSprint = 1;
  @observable currentReportId = this.props.currentReportId;
  @observable isReportOpen = this.props.currentReportId ? true : false;

  get injected() {
    return this.props as InjectedProps;
  }

  get currentUser() {
    return this.injected.appStore.userStore;
  }

  get clientUserStore() {
    return this.injected.appStore.clientUserStore;
  }

  get report() {
    const { sprintReports } = this.props.projectGroup;

    if(this.reportMode === FormMode.Read) {
      return sprintReports.find(report => report.reportId === this.currentReportId);
    }
    if(this.reportMode === FormMode.Edit) {
      return sprintReports
        .filter(report => report.sprint === this.reportSprint)
        .find(report => !report.datePublish);
    }

    return undefined;
  }

  @computed
  get reports() {
    return this.props.projectGroup.sprintReports;
  }

  render() {
    const { projectGroup } = this.props;
    const { sprintBySprint } = projectGroup;

    return (
      <Wrapper>
        <FlexRow>
          <LeftColumn>
            <GreyLabel>스프린트 리뷰</GreyLabel>
          </LeftColumn>
        </FlexRow>

          {
            sprintBySprint.map((sprints, i) => {
              return(
                <TableWrapper key={i}>
                  <GreyBackground />
                    <Table>
                      <THead>
                        <tr>
                          <th style={{width: '100px'}}>S{sprints[0].sprint}</th>
                          <th style={{width: '170px'}}>작업기간</th>
                          <th colSpan={2} style={{width: '200px'}}>스프린트 보고서</th>
                          <th style={{textAlign: 'left'}}>리뷰</th>
                          <th style={{ width: '50px'}}></th>
                        </tr>
                      </THead>
                      <tbody>
                        {
                          sprints.map((sprint, j) => (
                            <SprintReviewRow
                              projectGroup={projectGroup}
                              sprints={sprints}
                              reports={this.reports.filter(report => report.sprint === sprint.sprint)}
                              sprint={sprint}
                              index={j}
                              key={j}
                              handleReportEditClick={(mode, sprint, savedReportId) => {
                                this.reportMode = mode;
                                this.reportSprint = sprint;
                                this.currentReportId = savedReportId;

                                this.isReportOpen = true;
                              }}
                              handleReportReadClick={(sprint, reportId) => {
                                this.reportMode = FormMode.Read;
                                this.reportSprint = sprint;
                                this.currentReportId = reportId;

                                this.isReportOpen = true;
                              }}/>
                          ))
                        }
                      </tbody>
                    </Table>
                 </TableWrapper>
              )
            })
          }

        <SprintReportPopup
          mode={this.reportMode}
          user={this.currentUser}
          projectGroup={projectGroup}
          sprintNumber={this.reportSprint}
          // report={projectGroup.sprintReports.find(report => report.reportId === this.currentReportId)}
          report={this.report}
          sprints={projectGroup.sprints.filter(sprint => sprint.sprint === this.reportSprint)}
          onClose={() => { this.isReportOpen = false; }}
          isOpened={this.isReportOpen} />
      </Wrapper>
    );
  }
}

export default SprintReviewTable;
