import { types, flow } from "mobx-state-tree";
import axios from "axios";
import {
  NotificationLogModel,
  mapper
} from "./models/NotificationLog";
import {
  INotificationLogListResponse
} from "../types/notificationLog";
import { orderBy } from "lodash";



export const NotificationLogStoreModel = types
  .model("NotificationLogStore", {
    logs: types.array(NotificationLogModel),

    isFetching: types.boolean,

    currentPage: types.optional(types.number, 1),
    pageGroup: types.optional(types.number, 1),
    pageSize: types.optional(types.number, 20),
    totalPages: types.optional(types.number, 1),
  })
  .views(self => ({
  }))
  .actions(self => ({
    setIsFeaching(isFeaching: boolean) {
      self.isFetching = isFeaching;
    },
    setCurentPage(value: number) {
      self.currentPage = value;
    },
    setPageGroup(value: number) {
      self.pageGroup = value;
    }
  }))
  .actions(self => ({
  }))
  .actions(self => {

    const fetch = flow(function*() {
      
      try {
        self.setIsFeaching(true);

        const { data }: { data: INotificationLogListResponse } = yield axios.get(
          `/notificationLog/pagination?template_type=email/text`,
          {
            params: {
              page: self.currentPage,
              page_size: self.pageSize
            }
          }
        );

        const logs = data.results.map(x =>
          NotificationLogModel.create(mapper(x))
        );

        self.totalPages = data.total_pages;
        self.logs.replace(logs);
      } catch (e) {
        throw e;
      }
      self.setIsFeaching(false);
    });

    return {
      fetch
    };
  });

type NotificationLogStoreType = typeof NotificationLogStoreModel.Type;
export interface NotificationLogStore extends NotificationLogStoreType {}
