import { types } from "mobx-state-tree";
import shortid from "shortid";

export const ConsultSelectionFormModel = types
  .model("ConsultSelectionForm", {
    id: types.optional(types.string, ""),
    value: types.optional(types.string, "")
  })
  .actions(self => ({
    afterCreate() {
      self.id = shortid.generate();
    },
    setValue(value: string) {
      self.value = value;
    }
  }));

type ConsultSelectionFormType = typeof ConsultSelectionFormModel.Type;
export interface ConsultSelectionForm extends ConsultSelectionFormType {}
