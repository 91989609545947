import React, { Component } from "react";
import styled from "styled-components/macro";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { H6, Icon, Intent, HTMLTable } from "@blueprintjs/core";
import moment from 'moment';

import DashboardTaskPopup from "../../molecules/Dashboard/DashboardTaskPopup";

const Schedule = styled.div`
  width: 100%;
`;
const ScheduleTitle = styled.div`
  display: flex;
  flex-direction: row;
`;
const ScheduleBody = styled.div`
  height: 450px;
  padding: 5px;
  background: #f1f1f1;
  overflow-y: auto;
`;
const H6Wrapper = styled(H6)`
  margin-right: 5px;
`;
const IconWrapper = styled(Icon)`
  cursor: pointer;
`;
const RightWrapper = styled.div`
  margin-left: auto;
  font-size: 12px;
  cursor: pointer;
  & > * + * {
    margin-left: 8px;
  }
`;
const ScheduleTable = styled(HTMLTable)`
  width: 100%;
`;

const ScheduleTab = styled.span`
  font-weight: bold;
`;
const ScheduleTabDim = styled.span`
  color: #888;
`;

const ScheduleBodyText = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const TaskIcon = styled(Icon)`
  vertical-align: middle;
`;

interface DashboardScheduleTaskProps {
  handleTodayTaskCountChanged: () => void;
}

interface InjectedProps extends DashboardScheduleTaskProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class DashboardScheduleTask extends Component<DashboardScheduleTaskProps> {
  @observable showAll = false;
  @observable isPopupOpen: boolean = false;
  @observable selectedId: number = -1;

  get injected() {
    return this.props as InjectedProps;
  }

  async componentDidMount() {
  }

  handlePopupClick(id: number) {
    this.selectedId = id ? id : -1;
    this.isPopupOpen = true;
  }

  handlePopupClose = async () => {
    this.isPopupOpen = false;

    // 스케줄 입력 시 calandar에 바로 반영되도록 fetch 추가
    await this.injected.appStore.scheduleManagerStore.fetchDashBoardSeesoSchedules();
    await this.injected.appStore.scheduleManagerStore.refreshDashboardCalendarSeesoSchedules();      

    this.props.handleTodayTaskCountChanged();
  }
  
  setShowAll = (value:boolean) => {
    this.showAll = value;
  }

  render_pm_name_by_userid(x: any) {
    let pm = this.injected.appStore.pmUserStore.pmByUserId(x.creator);
    return pm ? pm.nickname : '';
  }  

  render() {
    const { userId } = this.injected.appStore.userStore;
    const { sortedTodos } = this.injected.appStore.scheduleManagerStore;
    const myTodos = sortedTodos.filter(x => x.creator === userId);
    const schedules = this.showAll ? sortedTodos : myTodos;

    return (
      <Schedule>
      <ScheduleTitle>
        <H6Wrapper>할일</H6Wrapper>
        <IconWrapper 
          icon={'add'} 
          intent={Intent.PRIMARY} 
          iconSize={14}
          onClick={() => this.handlePopupClick(-1)} />
        <RightWrapper>
          { this.showAll ? (<ScheduleTabDim onClick={() => this.setShowAll(false)}>내 할일</ScheduleTabDim>) : (<ScheduleTab onClick={() => this.setShowAll(false)}>내 할일</ScheduleTab>) }
          { this.showAll ? (<ScheduleTab onClick={() => this.setShowAll(true)}>전체할일</ScheduleTab>) : (<ScheduleTabDim onClick={() => this.setShowAll(true)}>전체할일</ScheduleTabDim>) }          
        </RightWrapper>
      </ScheduleTitle>
      <ScheduleBody>
        <ScheduleTable
          bordered={false}
          condensed={true}
          interactive={true}
        >
          <tbody>
            { schedules.map(
              x =>
              this.showAll ?
              (
                <tr key={x.id} onClick={() => this.handlePopupClick(x.id)}>
                  <td style={{boxShadow:'none', maxWidth:'10px'}}><TaskIcon icon={x.taskDone ? 'tick-circle' : 'circle'} iconSize={10} /></td>
                  <td style={{boxShadow:'none', maxWidth:'60px'}}><ScheduleBodyText>{this.render_pm_name_by_userid(x)}</ScheduleBodyText></td>
                  <td style={{boxShadow:'none', maxWidth:'200px'}}>
                    <ScheduleBodyText>{x.title}</ScheduleBodyText>
                  </td>
                  <td style={{boxShadow:'none', minWidth:'65px', textAlign: 'right'}}>
                    {x.allDay ? '' : moment(x.startAt).format('MM-DD')}
                  </td>
                </tr>
              ) 
              :
              (
              <tr key={x.id} onClick={() => this.handlePopupClick(x.id)}>
                <td style={{boxShadow:'none', maxWidth:'10px'}}><TaskIcon icon={x.taskDone ? 'tick-circle' : 'circle'} iconSize={10} /></td>
                <td style={{boxShadow:'none', maxWidth:'260px'}}>
                  <ScheduleBodyText>{x.title}</ScheduleBodyText>
                </td>
                <td style={{boxShadow:'none', minWidth:'65px', textAlign: 'right'}}>
                  {x.allDay ? '' : moment(x.startAt).format('MM-DD')}
                </td>
              </tr>
            ))}            
          </tbody>
        </ScheduleTable>
      </ScheduleBody>
      <DashboardTaskPopup
          id={this.selectedId}
          onClose={this.handlePopupClose}
          isOpened={this.isPopupOpen} />      
    </Schedule>
    );
  }
}

export default DashboardScheduleTask;