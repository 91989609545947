import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import { Button, Dialog, Classes, HTMLTable, HTMLSelect } from "@blueprintjs/core";
import moment from 'moment';

import { AppStore } from "../../../store/AppStore";

interface VacationSummaryPopupProps {
  pmUserId: number;
  pmName: string;
  isOpened: boolean;
  onClose: () => void;
}

interface InjectedProps extends VacationSummaryPopupProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class VacationSummaryPopup extends Component<VacationSummaryPopupProps> {
  @observable vacation_year = moment().year();

  get injected() {
    return this.props as InjectedProps;
  }

  async componentDidMount() {
  }

  handleOpening = async() => {
    try {
      await this.injected.appStore.scheduleManagerStore.fetchSummarySchedules('vacation', String(this.vacation_year));
      await this.injected.appStore.scheduleManagerStore.fetchWeekdayHolidays();
    } catch (e) {}
  };

  async handleOnYearChange(e: any) {
    this.vacation_year = Number(e.currentTarget.value);
    await this.handleOpening();
  }

  render() {
    const { isOpened, onClose } = this.props;
    const { summaryVacationSchedules } = this.injected.appStore.scheduleManagerStore;

    const pmUserVacations = summaryVacationSchedules.filter(schedule => {
      return schedule.creator === this.props.pmUserId
    })

    const weekdayHolidays = this.injected.appStore.scheduleManagerStore.weekdayHolidays.map(x => x.date);

    return (
      <Dialog
        onOpening={this.handleOpening}
        isOpen={isOpened}
        usePortal={true}
        autoFocus={true}
        enforceFocus={true}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        isCloseButtonShown={false}
        title={this.props.pmName + '님 휴가 상세'}
      >

      <div className={Classes.DIALOG_BODY}>
        <HTMLSelect
          value={this.vacation_year}
          onChange={(e) => {this.handleOnYearChange(e);}}
        >
          <option value={2020}>2020</option>
          <option value={2021}>2021</option>
        </HTMLSelect>

        <HTMLTable
          bordered={true}
          condensed={true}
          interactive={true}
          striped={true}
          style={{ width: "100%", tableLayout: "fixed", textAlign:'center'}}
        >
          <thead>
            <tr>
              <th style={{width: '50px'}}>번호</th>
              <th>시작일</th>
              <th>종료일</th>
              <th style={{width: '80px'}}>휴가구분</th>
              <th style={{width: '80px'}}>사용일수</th>
            </tr>
          </thead>
          <tbody>
            {
              pmUserVacations.map((vacation: any, idx:number) => {
                let vacation_type = vacation.schCategory === 'vac' ? vacation.title : vacation.schCategory === 'vam' ? '오전반차' : '오후반차';
                let vacation_days = vacation.schCategory === 'vac' ? 0 : 0.5;

                if(vacation.schCategory === 'vac') {
                  let startDate = moment(vacation.startAt);
                  let endDate = moment(vacation.endAt);
                  
                  while(startDate.isSameOrBefore(endDate, 'days')) {
                    if(startDate.isoWeekday() < 6 && !(weekdayHolidays.includes(startDate.format("YYYY-MM-DD")))) { 
                      vacation_days += 1;
                    }
                    startDate.add(1, 'days');
                  }
                }

                return (
                  <tr key={vacation.id}>
                    <td>{idx+1}</td>
                    <td>{moment(vacation.startAt).format("YYYY-MM-DD")}</td>
                    <td>{moment(vacation.endAt).format("YYYY-MM-DD")}</td>
                    <td>{vacation_type}</td>
                    <td>{vacation_days}</td>
                  </tr>
                );
              })
            }
          </tbody>

        </HTMLTable>
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text="닫기" onClick={onClose} />
        </div>
      </div>
      </Dialog>
    );
  }
}

export default VacationSummaryPopup;
