import { types, flow } from "mobx-state-tree";

export const RufreeMatchingFormModel = types
  .model("RufreeMatchingForm", {
    id: types.maybeNull(types.number),
    manager: types.maybeNull(types.string),
    macthingType: types.maybeNull(types.string),
    projectGroup: types.maybeNull(types.string),
    projectGroupName: types.maybeNull(types.string),
    pGStartOrNot: types.maybeNull(types.boolean),
    role: types.maybeNull(types.string),
    sprintWage: types.optional(types.number, 0),
    sprintFee: types.optional(types.number, 0),
    sprintNum: types.optional(types.number, 0),
    dateDue: types.maybeNull(types.string),
    dateRequest: types.maybeNull(types.string),
    reference: types.optional(types.string, ""),
    roleDetail: types.optional(types.string, ""),
    pmManagerComment: types.optional(types.string, ""),
    matchingManagerComment: types.optional(types.string, ""),
    finalMatchingManagerComment: types.optional(types.string, ""),
    needUpdate: types.optional(types.boolean, false),
    onRecommend: types.optional(types.boolean, false),
    inspections: types.maybeNull(types.string),
    matchedRufree: types.maybeNull(types.string),
    status: types.maybeNull(types.string)
  })
  .actions(self => ({
    setManager(value: string) {
      self.manager = value;
    },
    setProjectGroup(value: string) {
      self.projectGroup = value;
    },
    setRole(value: string) {
      self.role = value;
    },
    setSprintWage(value: number) {
      self.sprintWage = value;
    },
    setSprintFee(value: number) {
      self.sprintFee = value;
    },
    setSprintNum(value: number) {
      self.sprintNum = value;
    },
    setDateDue(value: string) {
      self.dateDue = value;
    },
    setDateRequest(value: string) {
      self.dateRequest = value;
    },
    setReference(value: string) {
      self.reference = value;
    },
    setRoleDetail(value: string) {
      self.roleDetail = value;
    },
    setPmManagerComment(value: string) {
      self.pmManagerComment = value;
    },
    setMatchingManagerComment(value: string) {
      self.matchingManagerComment = value;
    },
    setFinalMatchingManagerComment(value: string) {
      self.finalMatchingManagerComment = value;
    },
    setNeedUpdate(value: boolean) {
      self.needUpdate = value;
    },
    setOnRecommend(value: boolean) {
      self.onRecommend = value;
    },
    setInspection(value: string) {
      self.inspections = value;
    }
  }));

type RufreeMatchingFormType = typeof RufreeMatchingFormModel.Type;
export interface RufreeMatchingForm extends RufreeMatchingFormType {}
