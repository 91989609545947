import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import FormContainer from "../../atoms/FormContainer/FormContainer";
import { Intent, Spinner, H4, FormGroup, Button } from "@blueprintjs/core";
import { observable, computed } from "mobx";
import { RouteComponentProps } from "react-router";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import BugTaskFormView from "../../molecules/BugTaskFormView/BugTaskFormView";
import {
  ItemRow,
  RemoveButtonContainer,
  RemoveButton
} from "../../atoms/RemoveItemButton/RemoveItemButton";
import {
  MetaHead,
  SubTitle,
  Title
} from "../../atoms/DetailReportComponents/DetailReportComponents";
import { FormMode } from "../../../types/formMode";

const Wrapper = styled.div``;

const Section = styled.section`
  margin-bottom: 20px;
`;

const AddButtonContainer = styled.div`
  margin-bottom: 30px;
`;

const ButtonsRow = styled.div`
  & > * + * {
    margin-left: 5px;
  }
`;

interface PageParams {
  projectGroupId: string;
  tasktaskId: string;
}

interface ProjectGroupTasktaskFormPageProps extends RouteComponentProps<PageParams> {
  // mode: FormMode;
}

interface InjectedProps extends ProjectGroupTasktaskFormPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ProjectGroupTasktaskFormPage extends Component<ProjectGroupTasktaskFormPageProps> {

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get formStore() {
    return this.injected.appStore.tasktaskFormStore;
  }

  @computed
  get userStore() {
    return this.injected.appStore.userStore;
  }

  @computed
  get currentForm() {
    return this.formStore.bugTaskForm;
  }

  async componentDidMount() {
    const { projectGroupId, tasktaskId } = this.props.match.params;

    await this.formStore.initForm(projectGroupId, this.userStore.userId);
    if(tasktaskId) {
      await this.formStore.fetchTask(tasktaskId);
    }
  }

  handleAddBug = () => {
    this.currentForm.addBug();
  };

  handleSave = async () => {
    try {
      await this.formStore.saveBugTask();

      AppToaster.show({
        message: "저장되었습니다.\n 작성 후, 요청하기 버튼을 누르셔야 최종 요청됩니다.",
        intent: Intent.SUCCESS
      });
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  handleRequest = async () => {
    await this.formStore.saveBugTask();
    
    if(this.currentForm.id > 0) {
      try {
        await this.formStore.request();

        AppToaster.show({
          message: "하자보수가 요청되었습니다.",
          intent: Intent.SUCCESS
        });
        this.props.history.push(
          `/project-groups/${this.props.match.params.projectGroupId}`
        );
      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }
    } else {
      AppToaster.show({
        message: "요청할 내용이 없습니다. 임시 저장 후, 요청해주세요.",
        intent: Intent.DANGER
      });
    }
    
  };

  handleCancel = () => {
    this.props.history.push(
      `/project-groups/${this.props.match.params.projectGroupId}`
    );
  };

  render() {
    // const { mode } = this.props;
    const { projectGroupId } = this.props.match.params;

    // if (!this.isFormInitiated) {
    //   return <Spinner />;
    // }

    if (!this.currentForm) {
      return <div>프로젝트그룹을 찾을 수 없습니다.</div>;
    }

    return (
      <Wrapper>
        <Title>하자보수 요청하기</Title>

        <MetaHead style={{ marginBottom: "33px" }}>
          <SubTitle>{projectGroupId}</SubTitle>
          <Title>{projectGroupId}</Title>
        </MetaHead>

        <FormContainer>
          <Section>
            <FormGroup label={<H4>요청사항</H4>} />

            <Button icon="add" text="항목 추가" minimal={true}
              onClick={this.currentForm.addBug} />

            {
              <>
                {this.currentForm.tasks.map((taskForm, index) => (
                  <ItemRow key={index}>
                    <RemoveButtonContainer>
                      <RemoveButton
                        onClick={() =>
                          this.currentForm.removeBug(taskForm)
                        }
                      />
                    </RemoveButtonContainer>
                    <BugTaskFormView bugForm={taskForm} />
                  </ItemRow>
                ))}
                
              </>
            }

          </Section>

          <ButtonsRow>
            <Button text="취소" onClick={this.handleCancel} />
            <Button
              text="임시저장"
              intent={Intent.PRIMARY}
              onClick={this.handleSave}
            />

            <Button
              text="요청하기"
              intent={Intent.SUCCESS}
              onClick={this.handleRequest}
            />
          </ButtonsRow>
        </FormContainer>
        
      </Wrapper>
    );
  }
}

export default ProjectGroupTasktaskFormPage;
