import { types, getParent, getRoot, flow } from "mobx-state-tree";
// import { RufreeSchedule } from "./RufreeSchedule";
// import { Inspection } from "./Inspection";
// import { RufreeRole } from "./RufreeRole";
// import { SeesoService } from "./SeesoService";
import axios from "axios";
import { BaseModel } from "./BaseModel";
import { mapSprintReport } from "../models/ProjectGroup";
import { SprintReportReponse } from "../../types/projectGroup";

export const SprintReportRawDataModel = types.model({
  postfix: types.maybe(types.string),
  comment: types.maybe(types.string),
  roles: types.array(types.string),
  rufrees: types.array(types.string),
  services: types.array(types.string)
})

export const SprintReportModel = BaseModel.named("SprintReport")
  .props({
    id: types.number,
    projectGroupId: types.string,
    sprint: types.number,
    reportId: types.string,
    version: types.number,
    datePublish: types.maybeNull(types.string),
    rawData: types.maybeNull(SprintReportRawDataModel),
    htmlData: types.string,
    createdAt: types.string,
    updatedAt: types.string
  })
  .views(self => ({
    get projectGroup() {
      return getParent(self, 2);
    }
  }))
  .views(self => ({
    get compoundKey() {
      return `${self.reportId}`;
    },
  }))
  .actions(self => ({
    setRawData(
      postfix: string,
      comment: string,
      roles: string[],
      rufrees: string[],
      services: string[]
    ) {
      self.rawData = SprintReportRawDataModel.create({
        'postfix': postfix,
        'comment': comment,
        'roles': roles,
        'rufrees': rufrees,
        'services': services
      })
    }
  }))
  .actions(self => {
    const update = flow(function*(
      sprint: number,
      postfix: string,
      comment: string,
      roles: string[],
      rufrees: string[],
      services: string[],
      reportHtmlCode: string
    ) {
      self.rawData && self.rawData.rufrees.replace(rufrees);
      try {
        const { data }: { data: SprintReportReponse } = yield axios.patch(`/projectGroups/${self.projectGroupId}/sprintReports/${self.id}`, {
          project_group: self.projectGroupId,
          sprint: sprint,
          raw_data: {
            postfix: postfix,
            comment: comment,
            roles: roles,
            rufrees: rufrees,
            services: services
          },
          html_data: reportHtmlCode
        });

        self = SprintReportModel.create(mapSprintReport(data));
      } catch (e) {
        throw e;
      }
    });

    const download = flow(function*(
      projectGroupName: string
    ) {
      try {
        yield axios.get(`/projectGroups/${self.projectGroupId}/sprintReports/${self.id}/download`, { responseType: "blob" })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            const filename = `[${projectGroupName}] 스프린트 보고서 ${self.sprint}.pdf`;

            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
          });
      } catch (e) {
        throw e;
      }
    });

    const send = flow(function*(
      to: string[],
      cc: string[],
      bcc: string[]
      ) {
        try {
          const { data }: { data: SprintReportReponse } = yield axios.post(
            `/projectGroups/${self.projectGroupId}/sprintReports/${self.id}/send`,
            {
                to: to,
                cc: cc,
                bcc: bcc
            }
          );
  
          self = SprintReportModel.create(mapSprintReport(data));
        } catch (e) {
          throw e;
        }
      });

    const preview_mail = flow(function*(
      pm_name: string,
      projectgroup_name: string,
      projectgroup_id: string,
      sprint_number: number,
      report_link: '',
      rufree_name: string,
      ) {
        const TEMPLATE_ID = 14; // 스프린트 보고서 이메일 템플릿 Id(백앤드)
        try {
          const { data }: { data: {
            html: string;
            subject: string;
          } } = yield axios.post(
            `/template/${TEMPLATE_ID}/preview`,
            {
              parameters: {
                pm_name: pm_name,
                projectgroup_name: projectgroup_name,
                projectgroup_id: projectgroup_id,
                sprint_number: sprint_number,
                report_link: report_link,
                rufree_name: rufree_name
              }
            }
          );

          return data;
  
        } catch (e) {
          throw e;
        }
      });

    return {
      update,
      download,
      send,
      preview_mail,
    };
  });

type SprintReportType = typeof SprintReportModel.Type;
export interface SprintReport extends SprintReportType {}
