import { types, flow } from "mobx-state-tree";
import { ClientFormModel } from "./ClientForm";
import axios from "axios";
import { ClientUserFormModel } from "./ClientUserForm";
import { ClientFileFormModel, ClientFileForm } from "./ClientFileForm";
import { ClientResponse, ClientFileResponse } from "../../../types/cilent";
import { ClientUserResponse } from "../../../types/clientUsers";

export const ClientFormStoreModel = types
  .model("ClientFormStore", {
    currentForm: types.optional(ClientFormModel, {}),
    bizFileForm: types.optional(ClientFileFormModel, {}),
    bnkFileForm: types.optional(ClientFileFormModel, {})
  })
  .actions(self => {
    const initForm = () => {
      self.currentForm = ClientFormModel.create();
      self.bizFileForm = ClientFileFormModel.create();
      self.bnkFileForm = ClientFileFormModel.create();
    };

    const mapFormDataForRequest = () => {
      const form = self.currentForm;
      return {
        name: form.name,
        identity_number: form.identityNumber,
        client_type: form.clientType,
        representative_name: form.representativeName,
        address: form.address,
        contract_person: {
          name: form.contractPersonName,
          email: form.contractPersonEmail,
          phone: form.contractPersonPhone
        },
        tax_person: {
          name: form.taxPersonName,
          email: form.taxPersonEmail,
          phone: form.taxPersonPhone
        },
        has_same_contract_tax_person: form.hasSameContractTaxPerson
      };
    };

    const mapUsersFormDataForRequest = () => {
      const form = self.currentForm;

      return {
        users: form.users
          .filter(clientUserForm => clientUserForm.value !== -1)
          .map(clientUserForm => clientUserForm.value)
      };
    };

    const postClient = flow(function*() {
      try {
        const { data }: { data: ClientResponse } = yield axios.post("/clients", mapFormDataForRequest());

        yield axios.post(
          `/clients/${data.client_id}/addUsers`,
          mapUsersFormDataForRequest()
        );

        self.currentForm.setClientId(data.client_id);

        // FO 에서 사용자가 업로드한 파일을 importFileFromFO로 불러들였을 경우 데이터를 업데이트 해줌
        if(self.bizFileForm && self.bizFileForm.id) {
          self.bizFileForm.setClient(data.client_id);
          yield axios.put(
            `/clientFiles/${self.bizFileForm.id}/import`, {
              client_id: data.client_id
            }
          );
        }

        if(self.bnkFileForm && self.bnkFileForm.id) {
          self.bnkFileForm.setClient(data.client_id);
          yield axios.put(
            `/clientFiles/${self.bnkFileForm.id}/import`, {
              client_id: data.client_id
            }
          );
        }
        return data;
      } catch (e) {
        console.log("postClient error", e);
        throw e;
      }
    });

    const patchClient = flow(function*() {
      try {
        const { data }: { data: ClientResponse } = yield axios.patch(
          `/clients/${self.currentForm.clientId}`,
          mapFormDataForRequest()
        );

        yield axios.post(
          `/clients/${self.currentForm.clientId}/addUsers`,
          mapUsersFormDataForRequest()
        );

        return data;
      } catch (e) {
        console.log("patchClient error", e);
        throw e;
      }
    });

    const fetchClient = flow(function*(clientId: string) {
      try {
        const { data } = yield axios.get(`/clients/${clientId}`);

        const newForm = ClientFormModel.create({
          clientId: data.client_id,
          name: data.name,
          users: data.users.map((user: ClientUserResponse) =>
            ClientUserFormModel.create({ value: user.id })
          ),
          identityNumber: data.identity_number,
          clientType: data.client_type,
          representativeName: data.representative_name,
          address: data.address,
          contractPersonName: data.contract_person.name,
          contractPersonEmail: data.contract_person.email,
          contractPersonPhone: data.contract_person.phone,
          taxPersonName: data.tax_person.name,
          taxPersonEmail: data.tax_person.email,
          taxPersonPhone: data.tax_person.phone,
          hasSameContractTaxPerson: data.has_same_contract_tax_person,
        });

        self.currentForm = newForm;
      } catch (e) {
        console.log("fetchClient error", e);
        throw e;
      }
    });

    const fetchClientFile = flow(function*(clientId: string) {
      try {
        const { data }: { data: ClientFileResponse[] } = yield axios.get(
          `/clientFiles`,
          {
            params: {
              client: clientId
            }
          }
        );

        const biz = data.find(x => x.filetype === 'BIZ');
        if(biz) {
          self.bizFileForm = ClientFileFormModel.create({
            id: biz.id,
            uuid: biz.uuid,
            filetype: biz.filetype,
            name: biz.name,
            format: biz.format,
            client: biz.client,
            userId: biz.auth_id,
            filename: biz.filename,
            url: biz.file 
          });
        }

        const bnk = data.find(x => x.filetype === 'BNK');
        if(bnk) {
          self.bnkFileForm = ClientFileFormModel.create({
            id: bnk.id,
            uuid: bnk.uuid,
            filetype: bnk.filetype,
            name: bnk.name,
            format: bnk.format,
            client: bnk.client,
            userId: bnk.auth_id,
            filename: bnk.filename,
            url: bnk.file     
          });
        }        

      } catch (e) {
        console.log("fetchClientFile error", e);
        throw e;
      }
    });    

    const updateFile = flow(function* (form: ClientFileForm, method: string) {
      try {

        if (method == "post") {

          const formData = new FormData();
          formData.append("file", form.file!);
          formData.append("filetype", form.filetype);
          formData.append("client", form.client);
          formData.append("auth_id", String(form.userId));
          formData.append("filename", form.filename);

          const { data }: { data: ClientFileResponse } = yield axios.post(
            '/clientFiles', formData
          );
          form.setId(data.id);
          form.setUrl(data.file);

        } else if (method == "put") {

          const formData = new FormData();
          formData.append("file", form.file!);
          formData.append("filetype", form.filetype);
          formData.append("client", form.client);
          formData.append("auth_id", String(form.userId));
          formData.append("filename", form.filename);

          const { data }: { data: ClientFileResponse } = yield axios.put(
            `/clientFiles/${form.id}`, formData
          );
          form.setUrl(data.file);

        } else if (method == "delete") {

          const { data }: { data: ClientFileResponse } = yield axios.delete(
            `/clientFiles/${form.id}`
          );
          form.setId(-1);

        }

      } catch (e) {
        console.log("ClientFormStore=>updateFile error", e);
        throw e;
      }
    });

    const importFileFromFO = (bizFileInfo: any, bnkFileInfo: any) => {

      if(bizFileInfo) {
        self.bizFileForm.id = bizFileInfo.id;
        self.bizFileForm.uuid = bizFileInfo.uuid;
        self.bizFileForm.filetype = bizFileInfo.filetype;
        self.bizFileForm.name = bizFileInfo.name;
        self.bizFileForm.format = bizFileInfo.format;
        // self.bizFileForm.client = bizFileInfo.client;
        self.bizFileForm.userId = bizFileInfo.auth_id;
        self.bizFileForm.filename = bizFileInfo.filename;
        self.bizFileForm.url = bizFileInfo.file;
      }

      if(bnkFileInfo) {
        self.bnkFileForm.id = bnkFileInfo.id;
        self.bnkFileForm.uuid = bnkFileInfo.uuid;
        self.bnkFileForm.filetype = bnkFileInfo.filetype;
        self.bnkFileForm.name = bnkFileInfo.name;
        self.bnkFileForm.format = bnkFileInfo.format;
        // self.bnkFileForm.client = bnkFileInfo.client;
        self.bnkFileForm.userId = bnkFileInfo.auth_id;
        self.bnkFileForm.filename = bnkFileInfo.filename;
        self.bnkFileForm.url = bnkFileInfo.file;
      }

    }

    return {
      initForm,
      postClient,
      patchClient,
      fetchClient,
      fetchClientFile,
      updateFile,
      importFileFromFO
    };
  });

type ClientFormStoreType = typeof ClientFormStoreModel.Type;
export interface ClientFormStore extends ClientFormStoreType {}
