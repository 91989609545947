import { types, flow } from "mobx-state-tree";
import axios from "axios";
import moment from "moment";
import { RequestResponse, TaskResponse, OutcomeResponse } from "../../../types/parttime";
import {
  TaskManagerFormModel
} from "./TaskManagerForm";
import {
  TaskRufreeFormModel
} from "./TaskRufreeForm";
import {
  TaskProjectGroupFormModel
} from "./TaskProjectGroupForm";
import {
  TaskOutcomeForm, TaskOutcomeFormModel
} from "./TaskOutcomeForm";

export const TaskFormModel = types
.model("TaskForm", {
  id: types.maybeNull(types.number),
  requestId: types.maybeNull(types.number),
  client: types.optional(types.string, ""),
  manager: types.optional(TaskManagerFormModel, {}),
  rufree: types.optional(TaskRufreeFormModel, {}),
  projectGroup: types.optional(TaskProjectGroupFormModel, {}),
  role: types.optional(types.string, ""),
  title: types.optional(types.string, ""),
  type: types.optional(types.string, ""),
  expectedDueDate: types.maybeNull(types.string),
  estimatedDueDate: types.maybeNull(types.string),
  estimatedWorkingTime: types.maybeNull(types.number),
  description: types.optional(types.string, ""),
  criteria: types.array(types.string),
  memo: types.optional(types.string, ""),
  totalAmount: types.optional(types.string, ""),
  paymentRemark: types.optional(types.string, ""),
  outcomes: types.array(TaskOutcomeFormModel),
  status: types.optional(types.string, "")
})
.actions(self => ({
  setId(value: number) {
    self.id = value;
  },
  setRequestId(value: number) {
    self.requestId = value;
  },
  setClient(value: string) {
    self.client = value;
  },
  setManager(value: number) {
    self.manager.id = value;
  },
  setProjectGroup(value: string) {
    self.projectGroup.groupId = value;
  },
  setRole(value: string) {
    self.role = value;
  },
  setTitle(value: string) {
    self.title = value;
  },
  setType(value: string) {
    self.type = value;
  },
  setExpectedDueDate(value: string | null) {
    self.expectedDueDate = value;
  },
  setEstimatedDueDate(value: string | null) {
    self.estimatedDueDate = value;
  },
  setEstimatedWorkingTime(value: number | null) {
    self.estimatedWorkingTime = value;
  },
  setDescription(value: string) {
    self.description = value;
  },
  setMemo(value: string) {
    self.memo = value;
  },
  setTotalAmount(value: string) {
    self.totalAmount = value;
  },
  setPaymentRemark(value: string) {
    self.paymentRemark = value;
  },
  addCriteria(value: string) {
    self.criteria.push(value);
  },
  removeCriteria(value: number) {
    self.criteria.splice(value, 1);
  },
  addOutcomes(target: TaskOutcomeForm) {
      self.outcomes.push(target);
  },
  removeOutcomes(target: TaskOutcomeForm) {
      self.outcomes.remove(target);

      if(target.id !== null){
        try {
          axios.delete(`/parttime/requests/${self.requestId}/tasks/${self.id}/outcomes/${target.id}`);
        } catch (e) {
          console.log("edit error", e);
          throw e;
        }
      }
  }
}));
type TaskFormType = typeof TaskFormModel.Type;
export interface TaskForm extends TaskFormType {}


const mapToOutcome= (data: OutcomeResponse) => ({
  id: data.id,
  file: data.file,
  name: data.name,
  format: data.format
});

const mapTaskFormToData = (form: TaskForm) => {
  return {
    manager: form.manager.id,
    project_group: form.projectGroup.groupId,
    client: form.client,
    role: form.role,
    title: form.title,
    type: form.type,
    expected_due_date: form.expectedDueDate
      ? moment(form.expectedDueDate).format("YYYY-MM-DD")
      : undefined,
    estimated_due_date: form.estimatedDueDate
      ? moment(form.estimatedDueDate).format("YYYY-MM-DD")
      : undefined,
    estimated_working_time: form.estimatedWorkingTime,
    description: form.description,
    criteria: form.criteria,
    memo: form.memo,
    total_amount: form.totalAmount,
    payment_remark: form.paymentRemark
  }
};

export const ParttimeTaskFormStoreModel = types
.model("TaskFormStore", {
  taskForm: types.optional(TaskFormModel, {})
})
.actions(self => ({
  initForm() {
    self.taskForm = TaskFormModel.create({
      manager: TaskManagerFormModel.create(),
      rufree: TaskRufreeFormModel.create(),
    });
  }
}))
.actions(self => {
  const createTask = flow(function*() {
    try {
      const form = self.taskForm;

      const { data }: { data: TaskResponse } = yield axios.post(
        `/parttime/requests/${form.requestId}/tasks`, mapTaskFormToData(form)
        );

      self.taskForm.id = data.id;
    } catch (e) {
      console.log("createTask error", e);
      throw e;
    }
  });

  const patchTask = flow(function*() {
    try {
      const form = self.taskForm;
      yield axios.patch(`/parttime/requests/${form.requestId}/tasks/${form.id}`, mapTaskFormToData(form));
    
      // 결과파일 저장
      const formData = new FormData();
      if(form.outcomes.length > 0){
        form.outcomes.forEach(item => {
          if(item.id === null){
            formData.append('outcomes', item.file!);
          }
        });
        if(formData.has('outcomes')){
          yield axios.post(`/parttime/requests/${form.requestId}/tasks/${form.id}/outcomes`, formData, {
              headers: {
                  'content-type': 'multipart/form-data'
              }
          });
        }
      } 
    } catch (e) {
      console.log("patchTask error", e);
      throw e;
    }
  });

  const fetchClient = flow(function*(requestId: number) {
    try {
      const { data }: { data: RequestResponse } = yield axios.get(
        `/parttime/requests/${requestId}`
      );
      self.taskForm.setClient(data.client.client_id);
    } catch (e) {
      throw e;
    }
  });

  const fetchTask = flow(function*(requestId: number, taskId: number) {
    try {
      const { data }: { data: TaskResponse } = yield axios.get(
        `/parttime/requests/${requestId}/tasks/${taskId}`
      );

      const newForm = TaskFormModel.create({
        id: data.id,
        requestId: data.request.id,
        manager: {
          id: data.manager.id,
          name: data.manager.name,
          phone: data.manager.phone,
          email: data.manager.email
        },
        client: data.client,
        projectGroup: {
          groupId: data.project_group.group_id,
          name: data.project_group.name
        },
        role: data.role,
        title: data.title,
        type: data.type,
        expectedDueDate: data.expected_due_date,
        estimatedDueDate: data.estimated_due_date,
        estimatedWorkingTime: data.estimated_working_time,
        description: data.description,
        memo: data.memo,
        criteria: data.criteria,
        totalAmount: data.total_amount,
        paymentRemark: data.payment_remark,
        outcomes: data.outcomes ? data.outcomes.map(mapToOutcome) : [],
        status: data.status
      })
      self.taskForm = newForm;
    } catch (e) {
      throw e;
    }
  });

  const completeTask = flow(function*(requestId: number, taskId: number, totalAmount: string) {
    try {
      yield axios.put(`/parttime/requests/${requestId}/tasks/${taskId}/complete`, {
        total_amount: totalAmount
      });
    } catch (e) {
      throw e;
    }
  });

  const cancelTask = flow(function*(requestId: number, taskId: number) {
    try {
      yield axios.put(`/parttime/requests/${requestId}/tasks/${taskId}/cancel`, {});
    } catch (e) {
      throw e;
    }
  });

  const cancelRufreeMatch = flow(function*(requestId: number, taskId: number) {
    try {
      yield axios.post(`/parttime/requests/${requestId}/tasks/${taskId}/match-cancel`, {});
    } catch (e) {
      throw e;
    }
  });

  return {
    createTask,
    fetchClient,
    fetchTask,
    patchTask,
    completeTask,
    cancelTask,
    cancelRufreeMatch
  };
});

type ParttimeTaskFormStoreType = typeof ParttimeTaskFormStoreModel.Type;
export interface ParttimeTaskFormStore extends ParttimeTaskFormStoreType {}
