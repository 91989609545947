import React, { Component, ChangeEvent } from "react";

import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { computed, observable } from "mobx";
import {
  Button,
  Callout,
  H3,
  H4,
  Intent,
  FormGroup,
  Spinner,
  HTMLTable,
  TextArea,
  Tag,
  HTMLSelect, IOptionProps
} from "@blueprintjs/core";
import ContentContainer from "../../atoms/ContentContainer/ContentContainer";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import ContractComments from "../../organisms/ContractComments/ContractComments";
import moment from "moment";
import ProgressTracker from "../../atoms/ProgressTracker/ProgressTracker";

const ButtonsRow = styled.div`
  margin-bottom: 50px;
  float: right;
  & > * + * {
    margin-left: 5px;
  }
`;
const ContainerWrapper = styled.div`
  display: flex;
`;
const Container = styled.div`
  width: 550px;
  margin-right: 20px;
`;
const Section = styled.div`
  margin-bottom: 40px;
`;
const CalloutWrapper = styled(Callout)`
  padding-top: 0;
`;
const FlexRow = styled.div`
  display: flex;
  & {
    width: 100%;
  }
  & > * + * {
    margin-left: 5px;
  }
`;
const RightAlignDiv = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;
const Text = styled.div`
  white-space: pre-wrap;
`;
const Comment = styled.div`
  margin-bottom: 20px;
`;
const CommentDate = styled.div`
  font-weight: bold;
`;
const CommentContent = styled.div``;

const statusOption: IOptionProps[] = [
  { label: "결재대기", value: "결재대기" },
  { label: "계약서 작성 완료", value: "계약서 작성 완료" },
  { label: "계약서 검토/수정 완료", value: "계약서 검토/수정 완료" },
  { label: "계약서 발송 완료", value: "계약서 발송 완료" },
  { label: "계약 완료", value: "계약 완료" },
  { label: "계약내용 기록 완료", value: "계약내용 기록 완료" },
  { label: "세금계산서 발행 완료", value: "세금계산서 발행 완료" },
  { label: "계약보류", value: "계약보류" },
  { label: "계약파기", value: "계약파기" },
];
const statusValues = statusOption.map(i => i.value.toString());

interface ClientContractRequestDetailPageProps {
  id: string;
}

interface InjectedProps extends ClientContractRequestDetailPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ClientContractRequestDetailPage extends Component<ClientContractRequestDetailPageProps> {
  @observable readyToShow = false;
  @observable tempStatus = "";

  get injected() {
    return this.props as InjectedProps;
  }

  get contractStore() {
    return this.injected.appStore.contractStore;
  }

  // get inspectionStore() {
  //   return this.injected.appStore.inspectionStore;
  // }

  @computed
  get contract() {
    const { id } = this.props;
    return this.injected.appStore.contractStore.clientContracts.find(
      x => x.contractId === id
    );
  }

  handleAddComment = async (comment: string) => {
    if (this.contract) {
      try {
        await this.contract.addComment(comment);

        AppToaster.show({
          message: "코멘트가 저장되었습니다.",
          intent: Intent.SUCCESS
        });

        this.contract.fetchComments();
      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }
    }
  };

  handleUpdateComment = async (id: number, comment: string) => {
    if (this.contract) {
      try {
        await this.contract.updateComment(id, comment);

        AppToaster.show({
          message: "코멘트가 수정되었습니다.",
          intent: Intent.SUCCESS
        });

        this.contract.fetchComments();
      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }
    }
  };

  handleDeleteComment = async (id: number) => {
    if (this.contract) {
      try {
        await this.contract.deleteComment(id);

        AppToaster.show({
          message: "코멘트가 삭제되었습니다.",
          intent: Intent.SUCCESS
        });

        this.contract.fetchComments();
      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }
    }
  };

  handleRequestApproveSubmit = async () => {
    if (this.contract) {
      try {
        await this.contract.requestApprove();
        const { id } = this.props;
        await this.injected.appStore.contractStore.fetchClientContractById(id);

        AppToaster.show({
          message: "결재 요청되었습니다.",
          intent: Intent.SUCCESS
        });

      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }
    }
  };

  handleCancelRequestApproveSubmit = async () => {
    if (this.contract) {
      try {
        await this.contract.cancelRequestApprove();
        const { id } = this.props;
        await this.injected.appStore.contractStore.fetchClientContractById(id);
        AppToaster.show({
          message: "결재 요청이 취소되었습니다.",
          intent: Intent.SUCCESS
        });
      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }
    }
  };

  handleApproveSubmit = async () => {
    if (this.contract) {
      try {
        await this.contract.approve();

        AppToaster.show({
          message: "결재되었습니다.",
          intent: Intent.SUCCESS
        });
      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }
    }
  };

  handleDeleteSubmit = async () => {
    if (this.contract) {
      try {
        await this.contract.remove();

        AppToaster.show({
          message: "삭제되었습니다.",
          intent: Intent.SUCCESS
        });

        window.location.href = `/contract?tab=request&type=client`;
      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }
    }
  };

  handleRejectSubmit = async () => {
    if (this.contract) {
      try {
        await this.contract.reject();

        AppToaster.show({
          message: "반려되었습니다.",
          intent: Intent.SUCCESS
        });
      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }
    }
  };

  get currentUser() {
    return this.injected.appStore.userStore;
  }

  async componentDidMount() {
    this.readyToShow = false;

    try {
      const { id } = this.props;
      await this.injected.appStore.contractStore.fetchClientContractById(id);
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER,
        timeout: 0,
        onDismiss: () => {
          // this.props.history.push('/contract')
        }
      });
    }
    this.readyToShow = true;
  }

  renderButtons() {
    // const statusOption: IOptionProps[] = [
    //   { label: "결재대기", value: "결재대기" },
    //   { label: "계약서 작성 완료", value: "계약서 작성 완료" },
    //   { label: "계약서 검토/수정 완료", value: "계약서 검토/수정 완료" },
    //   { label: "계약서 발송 완료", value: "계약서 발송 완료" },
    //   { label: "계약 완료", value: "계약 완료" },
    //   { label: "계약내용 기록 완료", value: "계약내용 기록 완료" },
    //   { label: "세금계산서 발행 완료", value: "세금계산서 발행 완료" }
    // ];
    // const statusValues = statusOption.map(i => i.value);

    const { contract } = this;
    const { isPM } = this.injected.appStore.userStore;
    if (contract) {
      if (statusValues.includes(contract.status)) {
        return (
          <>{
            (this.currentUser.userId == contract.approveRequester ||
              this.currentUser.userId == 1) &&
            <Button
              text="결재요청 취소하기"
              intent={Intent.WARNING}
              onClick={this.handleCancelRequestApproveSubmit}
            />}
            {isPM && <>
              <Button
                text="반려하기"
                intent={Intent.DANGER}
                onClick={this.handleRejectSubmit}
              />
              <HTMLSelect
                value={this.tempStatus === "" ? contract.status : this.tempStatus}
                onChange={(e) => {
                  this.tempStatus = e.target.value;
                  if(this.tempStatus === contract.status) this.tempStatus = "";
                }}
                options={statusOption}
              />
              <Button
                text="저장하기"
                intent={Intent.SUCCESS}
                onClick={async () => {
                  try {
                    contract.setStatus(this.tempStatus);
                    this.tempStatus = "";
                    await contract.changeStatusPreRequest();
                    AppToaster.show({
                      message: "Status가 변경되었습니다.",
                      intent: Intent.SUCCESS
                    });
                  } catch (e) {
                    const error = JSON.stringify(e.response.data);
                    AppToaster.show({
                      message: "오류: " + error,
                      intent: Intent.DANGER
                    });
                  }
                }}
              />
            </>}
          </>
        );
      }

      if (contract.status === "결재완료") {
        return (
          <>
            {isPM && <>
              <Button
                text="반려하기"
                intent={Intent.DANGER}
                onClick={this.handleRejectSubmit}
              />
            </>}
            <Tag large={true}>
              결재완료
            </Tag>
          </>
        );
      }

      return (
        <>
          <Link to={`/contract-request/client/${contract.contractId}/edit`}>
            <Button text="수정하기" intent={Intent.PRIMARY}/>
          </Link>
          <Button
            text="결재요청"
            intent={Intent.SUCCESS}
            onClick={this.handleRequestApproveSubmit}
          />
        </>
      );
    }
  }

  render() {
    const { contract } = this;

    if (!contract) {
      return <Spinner/>;
    }

    if (!this.readyToShow) {
      return <Spinner/>;
    }

    const pmUser = contract.pm_user_detail;
    const client = contract.clientDetail;


    return (
      <>
        <H3>계약 요청(클라이언트) 상세보기</H3>

        <ContainerWrapper>
          <Container>
            <Section>
              <FormGroup label={<strong>계약 타입</strong>}>
                {contract.contractType}
              </FormGroup>
              <FormGroup label={<strong>담당매니저</strong>}>
                {pmUser ? pmUser.name : ""}
              </FormGroup>
              <FormGroup label={<strong>계약명(프로젝트명)</strong>}>
                {contract.contractName}
              </FormGroup>
              <FormGroup label={<strong>클라이언트 선택</strong>}>
                {client ? client.name : ""}
              </FormGroup>
              <CalloutWrapper>
                {
                  client && (
                    <>
                      <HTMLTable
                        style={{ width: "100%" }}
                      >
                        <thead>
                        <tr>
                          <th>타입</th>
                          <th>대표자명</th>
                          <th>사업자등록번호</th>
                          <th>주소</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td>{client.clientType}</td>
                          <td>{client.representativeName}</td>
                          <td>{client.identityNumber}</td>
                          <td>{client.address}</td>
                        </tr>
                        </tbody>
                      </HTMLTable>

                      <RightAlignDiv>
                        <Button
                          className={"bp3-small bp3-minimal"}
                          text="클라이언트 정보 수정하기"
                          intent={Intent.PRIMARY}
                          onClick={() => {
                            window.open(`/clients/${client && client.clientId}`, "_blank");
                          }}
                        />
                      </RightAlignDiv>
                    </>
                  )
                }
              </CalloutWrapper>


            </Section>

            <Section>
              <FormGroup label={<strong>계약담당자</strong>}>
                <FlexRow>
                  <FormGroup label={<strong>이름</strong>}>
                    {contract.contractorName}
                  </FormGroup>
                  <FormGroup label={<strong>전화번호</strong>}>
                    {contract.contractorPhone}
                  </FormGroup>
                  <FormGroup label={<strong>이메일</strong>}>
                    {contract.contractorEmail}
                  </FormGroup>
                </FlexRow>
              </FormGroup>

              <FormGroup label={<strong>세금계산서 담당자</strong>}>
                <FlexRow>
                  <FormGroup label={<strong>이름</strong>}>
                    {contract.taxInvoiceManagerName}
                  </FormGroup>
                  <FormGroup label={<strong>전화번호</strong>}>
                    {contract.taxInvoiceManagerContact}
                  </FormGroup>
                  <FormGroup label={<strong>이메일</strong>}>
                    {contract.taxInvoiceManagerEmail}
                  </FormGroup>
                </FlexRow>
              </FormGroup>

              <FormGroup label={<strong>NDA 작성요청 여부</strong>}>
                {contract.nda ? "Y" : "N"}
              </FormGroup>
              <FormGroup label={<strong>계약체결일</strong>}>
                {moment(contract.contractDate).format("YYYY-MM-DDT00:00")}
              </FormGroup>
              <FormGroup label={<strong>계약금비율</strong>}>
                {contract.contractFeeRate}
              </FormGroup>
            </Section>

            <Section>
              <FormGroup label={<strong>검수서</strong>}>
                {contract.inspectionsDetail.map(inspection => {
                  // const inspection = this.getInspection(inspection_id);

                  if (inspection) {
                    return (
                      <div key={inspection.inspectionId}>
                        <Link
                          to={`/inspections/${inspection.inspectionId}/read`}
                          target={"_blank"}>
                          {inspection.nameWithId}
                        </Link>
                      </div>
                    );
                  }
                })}
              </FormGroup>

              <FormGroup label={<strong>계약범위</strong>}>
                <Text>
                  {contract.contractRange}
                </Text>
              </FormGroup>
              <FormGroup label={<strong>특약사항</strong>}>
                <Text>
                  {contract.specialContract}
                </Text>
              </FormGroup>
              <FormGroup label={<strong>비고</strong>}>
                <Text>
                  {contract.comment}
                </Text>
              </FormGroup>
            </Section>
            <Section style={{ width: "200%" }}>
              {/*{statusValues.map(i=><div key={i}>{i}</div>)}*/}
              <ProgressTracker pointList={statusValues} doneIndex={statusValues.indexOf(contract.status)}/>
            </Section>


            <ButtonsRow>
              <Button
                text="삭제"
                minimal={true}
                intent={Intent.DANGER}
                onClick={this.handleDeleteSubmit}/>

              <Link to={`/contract?tab=request&type=client`}>
                <Button text="취소"/>
              </Link>

              {this.renderButtons()}
            </ButtonsRow>
          </Container>

          <Container>
            <ContractComments
              comments={contract.contractComments}
              onAddComment={this.handleAddComment}
              onDeleteComment={this.handleDeleteComment}
              onUpdateComment={this.handleUpdateComment}
            />
          </Container>
        </ContainerWrapper>

        <ContentContainer>

        </ContentContainer>
      </>
    );
  }
}

export default ClientContractRequestDetailPage;
