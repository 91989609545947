import React, { Component, FormEvent } from "react";
import { RouteComponentProps } from "react-router";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { ChangeEvent } from "cleave.js/react/props";
import { AppStore } from "../../../store/AppStore";
// import { ContractTemplate } from "../../../store/models/ContractTemplate";
import { computed, observable } from "mobx";
import { Button, H3, H4, Intent, Spinner, FormGroup } from "@blueprintjs/core";
import FormSection from "../../atoms/FormSection/FormSection";
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import moment from "moment";

const Wrapper = styled.div`
  width: 793.701px;
`;
const HeaderSection = styled.div``;
const TitleSection = styled.div`
  display: flex;
  flex-direction: row;
`;
const BodySection = styled.div`
  padding: 10px;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 10px;
`;
const Title = styled.div`
  width: 600px;
`;
const TemplateId = styled.span``;
const TemplateName = styled.span`
  margin-left: 20px;
`;
const Buttons = styled.div`
  margin-top: 23px;
  margin-left: 100px;
  & > * + * {
    margin-left: 5px;
  }
`;

interface PageParams {
  id: string;
}
interface ContractTemplateDetailPageProps extends RouteComponentProps<PageParams> {
}

interface InjectedProps extends ContractTemplateDetailPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ContractTemplateDetailPage extends Component<ContractTemplateDetailPageProps> {
  @observable readyToShow = false;

  get injected() {
    return this.props as InjectedProps;
  }

  get contractStore() {
    return this.injected.appStore.contractStore;
  }

  @computed
  get template() {
    const { id } = this.props.match.params;

    return this.contractStore.templates.find(
      x => x.id === +id
    );
  }

  handleListButtonClick = () => {
    this.props.history.push('/contract?tab=template');
  }

  handleEditButtonClick = () => {
    this.props.history.push(`/contract-templates/${this.props.match.params.id}/edit`);
  }


  async componentDidMount() {
    try {
      await this.contractStore.fetchTemplates();
    } catch (e) {
    } finally {
      this.readyToShow = true;
    }
  }

  render() {
    if (!this.readyToShow) {
      return <Spinner />;
    }

    const { template } = this;
    if (!template) {
      return <Wrapper>계약서 양식을 가져오지 못했습니다.</Wrapper>;
    }

    return (
      <Wrapper>
        <HeaderSection>
          <H3>
            계약서 양식
          </H3>
          <H4>{template.template_id}</H4>
        </HeaderSection>

        <TitleSection>
          <Title>
            <FormSection>
            <FormGroup label={<strong>계약명(프로젝트명)</strong>}>
              {template.template_name}
            </FormGroup>
          </FormSection>
          </Title>
          <Buttons>
            <Button
              text="목록"
              onClick={this.handleListButtonClick}
            />
            <Button
              text="수정"
              intent={Intent.PRIMARY}
              onClick={this.handleEditButtonClick}
            />
          </Buttons>
          
        </TitleSection>

        { template.template_body && (
          <BodySection>
            <FroalaEditorView model={template.template_body} />
          </BodySection>
        )}
        
      </Wrapper>
    );
  }
}

export default ContractTemplateDetailPage;
