import React, { Component, ChangeEvent } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
// import { AppStore } from "../../../store/AppStore";
import { computed } from "mobx";
import { BugForm } from "../../../store/forms/TasktaskForm/TasktaskForm";
import { InputGroup, TextArea } from "@blueprintjs/core";

const Wrapper = styled.div`
  margin-bottom: 30px;
`;

const TextContainer = styled.div`
  margin-top: 10px;
`;

const MoodContainer = styled.div`
  margin-top: 10px;
`;

interface BugTaskFormViewProps {
  bugForm: BugForm;
}

interface InjectedProps extends BugTaskFormViewProps {
  // appStore: AppStore;
}

@inject("appStore")
@observer
class BugTaskFormView extends Component<BugTaskFormViewProps> {
  // get injected() {
  //   return this.props as InjectedProps;
  // }

  componentDidMount() {
  }

  handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.props.bugForm.setTitle(e.currentTarget.value);
  }

  handleCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.props.bugForm.setComment(e.currentTarget.value);
  };

  render() {
    const { bugForm } = this.props;

    return (
      <Wrapper>
        <TextContainer>
          <InputGroup
              placeholder="항목명 (예: 회원가입)"
              value={bugForm.title}
              onChange={this.handleTitleChange}
            />
        </TextContainer>

        <TextContainer>  
          <TextArea
            value={bugForm.comment}
            onChange={this.handleCommentChange}
            placeholder="문제 및 요청내용 (예: 네이버 메일로 가입이 되지 않습니다.)"
            style={{ width: "100%", minHeight: "200px" }}
          />
        </TextContainer>
      </Wrapper>
    );
  }
}

export default BugTaskFormView;
