import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
// import { RufreeCalculate } from "../../../store/models/RufreeCalculate"
import { Sprint } from "../../../store/models/Sprint";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import { Intent, Dialog, Classes, TextArea, Button, NumericInput, FormGroup } from "@blueprintjs/core";
import { observable } from "mobx";
import { ChangeEvent } from "cleave.js/react/props";
import InputWithFormat, {
  KoreanCurrencyOptions
} from "../../atoms/InputWithFormat/InputWithFormat";

const FormGroupLabel = styled.div`
  font-weight: bold;
  width: 90px;
`;
const FormGroupValue = styled.div`
  line-height: 30px;
`;
const StyledTextArea = styled(TextArea)`
  width: 100%;
`;

interface SprintConfirmPopupProps {
  projectGroupName: string;
  sprint: Sprint;
  sprint_sequential_id: number;
  isOpened: boolean;
  onClose: () => void;
}

interface InjectedProps extends SprintConfirmPopupProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class SprintConfirmPopup extends Component<SprintConfirmPopupProps> {
  @observable total = 0;
  @observable payment = 0;
  @observable commission = 0;
  @observable comment = "";

  get injected() {
    return this.props as InjectedProps;
  }

  get calculateStore() {
    return this.injected.appStore.calculateStore
  }

  handleOpening = () => {
  };

  handlePaymentChange = (e: any) => {
    this.payment = parseInt(e.target.rawValue, 10);
    this.commission = this.total - this.payment;
  };

  handleCommissionChange = (e: any) => {
    this.commission = parseInt(e.target.rawValue, 10);
    this.payment = this.total - this.commission;
  };

  handleCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.comment = e.target.value;
  };

  handleSave = async () => {
    const { payment, commission, comment } = this;
    const { sprint, sprint_sequential_id } = this.props;
    try {
      await this.calculateStore.createRufreeCalculate(
        sprint.id,
        payment > 0 ? payment : 0,
        commission,
        comment
      );
      await sprint.confirm();

      AppToaster.show({
        message: `${sprint.rufreeName}(${sprint.role}) ${sprint.sprint ? "S" + sprint_sequential_id : "PRE"} 스프린트가 종료 처리 되었습니다.`,
        intent: Intent.SUCCESS
      });

      this.props.onClose();
    } catch (e) {
      const error = JSON.stringify(e.response.data);

      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  componentDidMount() {
    const { sprint } = this.props;

    this.total = sprint.payment + sprint.commission;
    this.payment = sprint.payment > 0 ? sprint.payment : 0;
    this.commission = sprint.payment > 0 ? sprint.commission : this.total;
  }

  render() {
    const { isOpened, onClose, projectGroupName, sprint, sprint_sequential_id } = this.props;

    const title = `${sprint.rufreeName}(${sprint.role})의 ${sprint.sprint ? "S" + sprint_sequential_id : "PRE"} 스프린트 종료`;

    return (
      <Dialog
        onOpening={this.handleOpening}
        isOpen={isOpened}
        usePortal={true}
        autoFocus={true}
        enforceFocus={true}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        title={title}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup label={<FormGroupLabel>프로젝트그룹</FormGroupLabel>} inline={true}>
            <FormGroupValue>{sprint.projectGroupId}</FormGroupValue>
          </FormGroup>
          <FormGroup label={<FormGroupLabel>프로젝트그룹명</FormGroupLabel>} inline={true}>
            <FormGroupValue>{projectGroupName}</FormGroupValue>
          </FormGroup>
          <FormGroup label={<FormGroupLabel>알유프리</FormGroupLabel>} inline={true}>
            <FormGroupValue>{sprint.rufreeName}</FormGroupValue>
          </FormGroup>
          <FormGroup label={<FormGroupLabel>스프린트</FormGroupLabel>} inline={true}>
            <FormGroupValue>S{sprint_sequential_id}</FormGroupValue>
          </FormGroup>
          <FormGroup label={<FormGroupLabel>스프린트기간</FormGroupLabel>} inline={true}>
            <FormGroupValue>{sprint.dateStart}~{sprint.expectedDateEnd}</FormGroupValue>
          </FormGroup>
          <FormGroup label={<FormGroupLabel>총 비용</FormGroupLabel>} inline={true}>
            <FormGroupValue>{this.total.toLocaleString("en-US")}</FormGroupValue>
          </FormGroup>

          <FormGroup label={<FormGroupLabel>지급비용</FormGroupLabel>} inline={true}>
            <InputWithFormat
                value={this.payment}
                onChange={this.handlePaymentChange}
                options={{ numeral: true }}
              />
          </FormGroup>
          <FormGroup label={<FormGroupLabel>수수료</FormGroupLabel>} inline={true}>
            <InputWithFormat
                value={this.commission}
                onChange={this.handleCommissionChange}
                options={{ numeral: true }}
              />
          </FormGroup>
          <FormGroup label={<FormGroupLabel>지급관련메모</FormGroupLabel>} inline={true}>
            <StyledTextArea
              value={this.comment}
              onChange={this.handleCommentChange}
            />
          </FormGroup>
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button text="취소" onClick={onClose} />
            <Button
              text="종료"
              onClick={this.handleSave}
              intent={Intent.PRIMARY}
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

export default SprintConfirmPopup;
