import React, { useState } from "react";
import Cleave from "cleave.js/react";
import { CleaveOptions } from "cleave.js/options";
import { observer } from "mobx-react";

import { SprintCheck } from '../../../store/forms/SprintCheck';
import { SprintDay, TResourceType } from '../../../store/forms/InspectionForm';
import { SprintField } from './SprintField';
import { ISprintPopupValue } from './SprintPopup';
import { PerPersonField } from './PerPersonField';
import RufreeRoleSelection from '../RufreeRoleSelection/RufreeRoleSelection';

import { ResourceFieldWrapper as Wrapper } from './_wrapper';

const ETC_ROLE = '기타';
const cleaveOptions: CleaveOptions = {
  numeral: true,
  numeralThousandsGroupStyle: 'thousand',
  numeralPositiveOnly: true
}

export interface IResourceFieldProps {
  id: string;
  role: string;
  /*
  * roleOptions(역할 옵션)이
  * 주어진 경우,  dropdown 메뉴로 랜더링한다.
  * 그렇지 않으면, 일반 input type='text'필드로 랜더링 한다. (default)
  */
  roleOptions?: string[];  
  sprints: SprintCheck[];
  sprintDays: SprintDay[];
  wage?: number;
  totalWage?: number;
  wageTitle?: string;
  disabledpre?: boolean;
  isIndividual?: boolean;
  resourceType: TResourceType;

  onChangeRole: (role: string) => void;
  onChangeWage?: (wage: number) => void;
  onToggleIndividual?: () => void;
  onRemove?: () => void
};

export const ResourceField: React.FC<IResourceFieldProps> = observer((props) => {
  const [ etcRole, setEtcRole ] = useState('');
  const [ showEtcRoleField, setShowEtcRoleField ] = useState(false);
  const sprints: { number: number, sprint: SprintCheck }[] = [];
  props.sprints.forEach((sprint, index) => {
    if(index > 0 && !!sprint.value) sprints.push({ number: index, sprint: sprint });
  });

  const handleSave = (values: ISprintPopupValue) => {
    const { days, sprintNumber } = values;

    if(sprintNumber > -1) {
      // 스프린트 on/off
      props.sprints[sprintNumber].setValue(true);
      // 스프린트 운영 일수 설정
      props.sprintDays[sprintNumber].setValue(days);
      // 서비스 리소스의 경우, x인원수 설정
    } 
  }

  return (
    <Wrapper>
      
      <div>
        <div
          className='role'
        >
          <label>역할</label>
          {
            props.roleOptions ?
            <>
              <RufreeRoleSelection
                onChange={ (role: string) => {
                  if(role === ETC_ROLE) {
                    setShowEtcRoleField(true);
                    props.onChangeRole(etcRole);
                  } else {
                    setEtcRole('');
                    setShowEtcRoleField(false);
                    props.onChangeRole(role);
                  }
                } }
                currentValue={ props.role }
                items={ props.roleOptions }
                className='rufree-role-selection'
              />
              {
                showEtcRoleField &&
                  <input
                    className='etc-role-input'
                    type="text"
                    placeholder="기타"
                    value={ etcRole }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                      setEtcRole(e.target.value);
                      props.onChangeRole(e.target.value);
                    } } />
              }
              </>
            : <input
              type="text"
              placeholder="역할"
              value={ props.role }
              onChange={ (e: React.ChangeEvent<HTMLInputElement>) => props.onChangeRole(e.target.value) } />
          }
          
        </div>

        {
          props.wage !== undefined &&
            <div
              className='wage'
            >
              <label>{ `${ props.wageTitle ? props.wageTitle : '임금' }/S` }</label>
              <Cleave
                type="text"
                placeholder={ `${ props.wageTitle }/S`}
                value={ props.wage ? props.wage : 0 }
                options={ cleaveOptions }
                onChange={
                  (e: React.ChangeEvent<HTMLInputElement>) => {
                    const wage = parseInt(e.target.value.replace(/[^0-9]/g, '')) || 0;
                    if(props.onChangeWage) {
                      props.onChangeWage(wage)
                    }
                  }
                } />
            </div>
          }

          {
            props.resourceType === 'service' &&
              <div
                className=''
              >
                <PerPersonField
                  isActive={ props.isIndividual ? true : false }
                  onClick={ () => props.onToggleIndividual && props.onToggleIndividual()} />
              </div>
          }
          {
            props.totalWage !== undefined &&
              <div className='role-total-wage'>
                <label>리소스별 비용 ({ props.wageTitle ? props.wageTitle : '임금' }*스프린트 개수)</label>
                <span className='role-total-wage'>{ props.totalWage.toLocaleString("en-US") }</span>
              </div>
          }

      </div>

      <div className='sprint-field'>
        <SprintField
          disabled={ props.disabledpre }
          isPre={ true }
          isActive={ props.sprints[0].value }
          role={ props.role }
          sprint={ 0 }
          sprintdays={ props.sprintDays[0].value}
          resourceType={ props.resourceType }
          onDelete={ () => props.sprints[0].setValue(false) }
          onSave={ handleSave } />
        {
          sprints
          .map(sprint =>
            <SprintField
              key={ sprint.number }
              role={ props.role }
              sprint={ sprint.number }
              sprintdays={ props.sprintDays[sprint.number].value}
              isActive={ true }
              resourceType={ props.resourceType }
              onDelete={ () => props.sprints[sprint.number].setValue(false) }
              onSave={ handleSave }
              />
          )
        }
        {
          sprints.length < 20 &&
            <SprintField
              sprint={ undefined }
              role={ props.role }
              sprintdays={ 14 }
              resourceType={ props.resourceType }
              onDelete={ () => {} }
              // onSave={ (values: ISprintPopupValue) =>{
              //   handleSave(Object.assign(values, { days: props.sprintDays[values.sprintNumber].value }))
              //   handleSave(values);
              // } }
              onSave={ handleSave } />
        }
        
      </div>

      <button
        className='remove'
        onClick={ props.onRemove }
      />
    </Wrapper>
  );
});
