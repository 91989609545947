import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { computed } from "mobx";
import InspectionCard from "../../organisms/InspectionCard/InspectionCard";
import { Spinner } from "@blueprintjs/core";
import { Helmet } from "react-helmet";
import moment from "moment";

const Wrapper = styled.div`
  padding-bottom: 30px;
`;

interface InspectionPrintPanelProps {
  inspectionId: string;
}

interface InjectedProps extends InspectionPrintPanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class InspectionPrintPanel extends Component<InspectionPrintPanelProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get inspection() {
    return this.injected.appStore.inspectionStore.inspections.find(
      x => x.inspectionId === this.props.inspectionId
    );
  }

  async componentDidMount() {
    document.body.style.zoom = "0.74";

    try {
      const { inspectionId } = this.props;
      await this.injected.appStore.inspectionStore.fetchInspectionById(
        inspectionId
      );

      window.print();
      window.close();
    } catch (e) {}
  }

  render() {
    const { inspection } = this;

    if (inspection) {
      return (
        <Wrapper>
          <Helmet>
            <title>{`[시소] ${inspection.title} - 검수서 - ${moment().format(
              "YYYYMMDD"
            )}`}</title>
          </Helmet>
          <InspectionCard showVatIncluded={true} inspection={inspection} hideButtons={true} />
        </Wrapper>
      );
    }

    return <Spinner />;
  }
}

export default InspectionPrintPanel;
