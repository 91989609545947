import axios from "axios";
import { types, flow } from "mobx-state-tree";
import { RufreeExperienceModel } from "./RufreeExperience";
import { RufreePdfModel } from "./RufreePdf";
import { mapRufree } from "../RufreeStore";
import { RufreeResponse } from "../../types/rufree";
import { RufreeMatchingHistoryCommentModel } from "./RufreeMatchingHistory";

export const RufreePortfolioModel = types.model("RufreePortfolio", {
  type: types.string,
  file: types.maybeNull(types.string),
  link: types.maybeNull(types.string)
});
type RufreePortfolioType = typeof RufreePortfolioModel.Type;

export interface RufreePortfolio extends RufreePortfolioType {
}

// 툴추가 
export const RufreeToolModel = types.model("RufreeToolModel", {
  tool: types.string,
  label: types.string,
  account: types.string,
});
type RufreeToolModelType = typeof RufreePortfolioModel.Type;
export interface RufreeToolModel extends RufreeToolModelType {
}

export const CommentModel = types.model("Comment", {
  id: types.number,
  object_pk: types.string,
  user: types.maybeNull(types.integer),
  user_name: types.string,
  comment: types.string,
  submit_date: types.string
});
type CommentType = typeof CommentModel.Type;

export interface GenericComment extends CommentType {
}

export const MatchingHistoryModel = types.model("MatchingHistoryModel", {
    pgId: types.string,
    pgName: types.string,
    role: types.string,
    phase: types.string,
    dateSubmitted: types.maybeNull(types.string),
    dateViewed: types.maybeNull(types.string),
    dateAccepted: types.maybeNull(types.string),
    dateRejected: types.maybeNull(types.string),
    dateExpired: types.maybeNull(types.string),
    dateMatched: types.maybeNull(types.string),
    dateDeclined: types.maybeNull(types.string),
    dateCanceled: types.maybeNull(types.string),
    rejectReason: types.maybeNull(types.string),
    comments: types.array(RufreeMatchingHistoryCommentModel)
});
type MatchingHistoryModelType = typeof MatchingHistoryModel;
export interface MatchingHistoryModel extends MatchingHistoryModelType {}

export const RufreePGHistoryModel = types.model("RufreePGHistoryModel", {
    pgId: types.string,
    pgName: types.string,
    role: types.string,
    dateStart: types.string,
    dateComplete: types.string,
});
type RufreePGHistoryModelType = typeof RufreePGHistoryModel.Type;
export interface RufreePGHistoryModel extends RufreePGHistoryModelType {}

export const FolloweeModel = types.model("Followee", {
  rufreeId: types.string,
  name: types.string,
  uuid: types.string
});


export const RufreeModel = types
  .model("Rufree", {
    uuid: types.maybeNull(types.string),
    rufreeId: types.identifier,
    name: types.string,
    email: types.string,
    phone: types.string,
    address: types.string,
    affiliation: types.string, // 소속
    getOffer: types.boolean,
    getOfferChangedAt: types.maybeNull(types.string),

    projectGroupHistory: types.array(RufreePGHistoryModel),
    matchingHistory: types.array(MatchingHistoryModel),
    experiences: types.array(RufreeExperienceModel),
    workType: types.string, // 파트타임, 풀타임
    roles: types.array(types.string),
    rolesEtc: types.string,
    portfolio: RufreePortfolioModel,
    portfolioPdfs: types.array(RufreePdfModel),

    mainSkills: types.array(types.string),
    subSkills: types.array(types.string),

    oldMainSkills: types.array(types.string),
    oldSubSkills: types.array(types.string),

    languages: types.array(types.string),
    tools: types.array(types.string),
    toolAccounts: types.array(RufreeToolModel),

    tags: types.array(types.string),
    references: types.array(types.string),
    interview: types.string,
    followee: types.array(FolloweeModel), // 같이 일하고 싶은 멤버

    // expert: types.string,

    workingTime: types.array(types.string),
    workingStyle: types.string, // 장기간, 단기간
    recommender: types.string,
    createdAt: types.string,
    updatedAt: types.string,
    pmComment: types.string,
    comments: types.array(CommentModel),
  })
  .views(self => ({
    get nameWithId() {
      return `${self.rufreeId} ${self.name}`;
    }
  }))
  .actions(self => {
    const fetch = flow(function* () {
      // try {
      const { data }: { data: RufreeResponse } = yield axios.get(
        `/rufrees/${self.rufreeId}`
      );

      self = RufreeModel.create(mapRufree(data));
      // } catch (e) {
      //   console.log("fetch Rufree error", e);
      //   throw e;
      // }

      return self;
    });

    return {
      fetch
    };
  });

type RufreeType = typeof RufreeModel.Type;

export interface Rufree extends RufreeType {
}
