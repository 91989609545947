import { types, flow } from "mobx-state-tree";
import axios from "axios";
import moment from "moment";
import sortBy from "lodash/sortBy";

const DEPOSIT = 'deposit';
const PAYOUT = 'payout';

const withVAT = (amount: number) => {
  return Math.floor(Math.round(amount * 1.1)/10) * 10;
}

export const TransactionModel = types
  .model("Transaction", {
    id: types.number,
    title: types.string,
    category: types.string,
    date: types.string,
    amount: types.number,
    // balance: types.optional(types.number, 0),
    comment: types.string
  })
  .views(self => ({
    get isDeposit() {
      return self.category === DEPOSIT ? true : false;
    },
    get isPayout() {
      return self.category === PAYOUT ? true : false;
    },
    get amountWithVAT() {
      return withVAT(self.amount);
    }
  }))
  .actions(self => ({
    // setBalance(value: number) {
    //   self.balance = value;
    // }
  }));
type TransactionModelType = typeof TransactionModel.Type;
export interface ITransaction extends TransactionModelType {}

interface ITransactionWithBalance extends ITransaction {
  balance: number;
}

export const CalculateByClientModel = types
  .model("CalculateByClient", {
    clientId: types.string,
    deposits: types.array(TransactionModel),
    depositAmount: types.number,
    payouts: types.array(TransactionModel),
    payoutAmount: types.number
  })
  .views(self => ({
    get depositAmountWithVAT() {
      return withVAT(self.depositAmount);
    }
  }))
  .views(self => ({
    get prePaid() {
      return self.depositAmountWithVAT - self.payoutAmount;
    },
    get transactionHistory() {
      let balance: number = 0;
      let transactions: ITransactionWithBalance[] = [];

      transactions = Object.assign(
        sortBy(
          self.deposits.concat(self.payouts).filter(transaction => transaction.date),
          ['date', 'id']
          // transaction => moment(transaction.date)
        )
      );

      for (var transaction of transactions) {
        if(transaction.isDeposit) {
          balance += transaction.amountWithVAT;
        } else {
          balance -= transaction.amount;
        }
        transaction.balance = balance;
      }

      return transactions;
    }
  }))
  .actions(self => {


    return{
    };
  });

type CalculateByClientModelType = typeof CalculateByClientModel.Type;
export interface ICalculateByClient extends CalculateByClientModelType {}
