import { types } from "mobx-state-tree";
import { SprintDayModel } from "../forms/InspectionForm";
import { ClientRoleForm, ClientRoleFormModel } from "../forms/ClientRoleForm";
import { RufreeRoleForm, RufreeRoleFormModel } from "../forms/RufreeRoleForm";
import { SeesoServiceForm, SeesoServiceFormModel } from "../forms/SeesoServiceForm";
import moment from "moment";
import { createDefaultSprints, createDefaultServiceSprints } from "../forms/SprintCheck";

export const InspectionTemplateModel = types
  .model("InspectionTemplate", {
    id: types.identifierNumber,

    name: types.string,
    review: types.string,
    attention: types.optional(types.string,''),

    sprintDays: types.array(SprintDayModel),
    clientRoles: types.array(ClientRoleFormModel),
    rufreeRoles: types.array(RufreeRoleFormModel),
    seesoServices: types.array(SeesoServiceFormModel),

    creatorName: types.string,
    modifierName: types.string,
    dateCreated: types.string,
    dateUpdated: types.string,
  }).views(self=>({
    get updateDate() {
      return self.dateUpdated ? moment(self.dateUpdated) : moment(self.dateCreated);
    },
    get totalCost() {
      let cost = 0;

      self.rufreeRoles.forEach(rufreeRole => {
        cost += rufreeRole.totalWage;
      });
       self.seesoServices.forEach(seesoService => {
        cost += seesoService.totalCost;
      });

      return cost;
    },
    rufreeNumInSprint(sprintIndex: number) {
      let sum = 0;

      self.rufreeRoles.forEach(rufreeRole => {
        if (rufreeRole.sprints[sprintIndex].value) {
          sum++;
        }
      });

      return sum;
    }
  })).actions(self => ({
    setName(value: string) {
      self.name = value;
    },
    setReview(value: string) {
      self.review = value;
    },
    setAttention(value: string) {
      self.attention = value;
    },
    addClientRole() {
      self.clientRoles.push(
        ClientRoleFormModel.create({
          sprints: createDefaultSprints()
        })
      );
    },
    addRufreeRole() {
      self.rufreeRoles.push(
        RufreeRoleFormModel.create({
          sprints: createDefaultSprints()
        })
      );
    },
    addSeesoService() {
      self.seesoServices.push(
        SeesoServiceFormModel.create({
          sprints: createDefaultServiceSprints()
        })
      );
    },
    removeClientRole(target: ClientRoleForm) {
      self.clientRoles.remove(target);
    },
    removeRufreeRole(target: RufreeRoleForm) {
      self.rufreeRoles.remove(target);
    },
    removeSeesoService(target: SeesoServiceForm) {
      self.seesoServices.remove(target);
    },
  }));
type InspectionTemplateType = typeof InspectionTemplateModel.Type;
export interface IInspectionTemplate extends InspectionTemplateType {}