import React, { Component } from "react";
import styled from "styled-components/macro";
import { observable, computed } from "mobx";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { ProjectGroup } from "../../../store/models/ProjectGroup";
import { Sprint } from "../../../store/models/Sprint";
import { SprintReport } from "../../../store/models/SprintReport";
import { Button, Icon, Intent } from "@blueprintjs/core";
import SprintReviewPopup from "../SprintReviewPopup/SprintReviewPopup";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
// import SprintReportPopup from "../SprintReviewPopup/SprintReportPopup";
import { FormMode } from "../../../types/formMode";
import moment from "moment";
import sortBy from "lodash/sortBy";

const Wrapper = styled.tr`
  td { vertical-align: top; }

  td:nth-child(1) { width: 110px; }
  td:nth-child(2) { width: 200px; }
  td:nth-child(3) { width: 70px; }
  td:nth-child(4) {
    width: 150px;
    div.gray-text { font-weight: bold; color: #202B33; }
    div.report {
      cursor: pointer;
      font-size: 12px;
    }
    div.report:hover {
      text-decoration: underline;
    }
    div + div { 
      margin-top: 8px;
    }
  }
  td:nth-child(5) { min-width: 200px; }
  td:nth-child(6) { width: 50px; }

  .publish-row {
    line-height: 15px;
    display: flex;
    .bp3-icon {
      cursor: pointer;
      margin: 2px 0px 0px 8px;
    }
  }
`;

const ReportButton = styled(Button)`
  box-shadow: none !important;
  background-image: none !important;
  background-color: #fcd262 !important;
  font-size: 12px;
  font-weight: 600;
  border-radius: 27px;
  & + & {
    margin-left: 5px;
  }
`;

interface SprintReviewRowProps {
  sprints: Sprint[];
  reports: SprintReport[];
  sprint: Sprint;
  index: number;
  projectGroup: ProjectGroup;
  handleReportEditClick: (mode: FormMode, sprint: number, savedReportId: string) => void;
  handleReportReadClick: (sprint: number, reportId: string) => void;
}

interface InjectedProps extends SprintReviewRowProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class SprintReviewRow extends Component<SprintReviewRowProps> {
  @observable isConfirmOpen = false;
  @observable isReportOpen = false;
  @observable reportMode = FormMode.Create;
  @observable currentReport: SprintReport | undefined = undefined;

  get injected() {
    return this.props as InjectedProps;
  }

  get currentUser() {
    return this.injected.appStore.userStore;
  }

  @computed
  get sprintReports() {
    return this.props.reports;
  }

  handleConfirmOpen = () => {
    this.isConfirmOpen = true;
  };

  handleConfirmClose = () => {
    this.isConfirmOpen = false;
    this.injected.appStore.projectGroupStore.fetchProjectGroup(this.props.sprint.projectGroupId);
  };

  handleReportOpen = () => {
    this.isReportOpen = true;
  }

  handleReportClose = () => {
    this.isReportOpen = false;
  };

  render() {
    const currentUser = this.currentUser;
    const {
      sprint,
      index,
      handleReportEditClick,
      handleReportReadClick
    } = this.props;
    const reports = this.sprintReports;
    let reviewMode = FormMode.Create;
    let myReview = undefined;

    let lastReport: SprintReport | undefined = undefined;
    let reportStatus = '작성';
    if(reports.length === 0) {
      reportStatus = '작성';
    } else {
      lastReport = reports.find(r => r.version === reports.length);
    }

    if(lastReport) {
      reportStatus = lastReport.datePublish ? '재작성' : '임시저장';
    }

    return (
      <Wrapper>
          <td>
            <b>{sprint.rufreeName}({sprint.role})</b>
          </td>
          <td>
            {sprint.dateStart} ~ {sprint.expectedDateEnd}
          </td>

          {
            index === 0 ? 
              <td>
                <ReportButton
                  text={reportStatus}
                  disabled={ !(this.props.sprints.every(sprint => sprint.dateEnd)) }
                  onClick={() => {
                    this.currentReport = reportStatus === '임시저장' ? lastReport : undefined;
                    this.reportMode = reportStatus === '임시저장' ? FormMode.Edit : FormMode.Create
                    this.isReportOpen = true;
                    
                    handleReportEditClick(this.reportMode, sprint.sprint, this.currentReport ? this.currentReport.reportId : '');
                  }} />
              </td> 
            : <td />
          }

          {
            index === 0 ? <td>
              {
                reports.length > 0  && <div className='gray-text'>발송일 :</div>
              }
              {
                reports && sortBy(reports, 'id').map((report, i) => (
                  report.datePublish &&
                  <div
                    key={i}
                    className='publish-row'>
                    <div
                      className='report'
                      key={`reportversion${i}`}
                      onClick={() => {
                        this.currentReport = report;
                        this.reportMode = FormMode.Read;
                        this.isReportOpen = true;

                        handleReportReadClick(sprint.sprint, report.reportId);
                      } }
                    >
                      { moment(report.datePublish).format('YYYY-MM-DD HH:mm') }
                    </div>
                    <Icon
                      icon={'link'}
                      iconSize={11}
                      color={'#137cbd'}
                      onClick={() => {
                        let url = window.location.href;
                        url = url.indexOf("?") > 0 ? url.substring(0, url.indexOf("?")) : url;
                        const link = `${url}?tab=review&reportId=${report.reportId}`;
                        let tempElem = document.createElement("textarea");
                        tempElem.value = link;
                        document.body.appendChild(tempElem);

                        tempElem.select();
                        document.execCommand("copy");
                        document.body.removeChild(tempElem);

                        AppToaster.show({
                          message: "링크가 복사되었습니다!",
                          intent: Intent.PRIMARY
                        });
                      }} />
                  </div>
                ))
              }
            </td> : 
            <td />
          }

          <td>
            {
              sprint.reviews.map((review, index) => {
                if(review.creator == currentUser.userId) {
                  reviewMode = FormMode.Edit;
                  myReview = review;
                }
                return (
                  <p key={index}>
                    <b>{review.creatorName}: </b>
                    {
                      review.comment.split('\n').map( (line, j) => {
                        return (<span key={j}>{line}<br/></span>)
                      })
                    }
                  </p>
                )
              })
            }
          </td>
          <td>
            {(sprint.dateEnd) ? (
                (reviewMode == FormMode.Create) ? (
                  <Button text="작성" className="bp3-small" intent={Intent.PRIMARY} onClick={this.handleConfirmOpen} />
                  ) : (
                  <Button text="수정" className="bp3-small" onClick={this.handleConfirmOpen} />
                )
              ) : (
                <Button text="작성" className="bp3-small" intent={Intent.PRIMARY} disabled={true} />
              )
            }
          </td>

        <SprintReviewPopup
          sprint={sprint}
          mode={reviewMode}
          review={myReview}
          user={currentUser}
          onClose={this.handleConfirmClose}
          isOpened={this.isConfirmOpen} />

        {/* <SprintReportPopup
          mode={this.reportMode}RecipientPopup
          user={currentUser}
          projectGroup={projectGroup}
          sprintNumber={sprint.sprint}
          report={this.currentReport}
          sprints={sprints}
          onClose={this.handleReportClose}
          isOpened={this.isReportOpen} /> */}
      </Wrapper>
    );
  }
}

export default SprintReviewRow;
