import styled from "styled-components/macro";

export const Row = styled.div`
  margin: 5px 0;
  position: relative;
`;

export const Column = styled.div`
  display: inline-block;
  width: 120px;
  padding-right: 5px;
  text-align: center;
`;

export const LabelColumn = styled(Column)`
  text-align: left;
  padding-left: 4px;
`;
