import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { H3, Tab, Tabs, TabId, Classes } from "@blueprintjs/core";
import { observable } from "mobx";
import { RouteComponentProps } from "react-router";

import { FormMode } from "../../../types/formMode";

import NotificationTemplatePanel from "../../panels/NotificationTemplatePanel/NotificationTemplatePanel";
import NotificationLogPanel from "../../panels/NotificationTemplatePanel/NotificationLogPanel";

import { ETEMPLATETYPE, INotificationTemplate } from "../../../store/models/NotificationTemplate";


const Wrapper = styled.div``;
interface Props extends RouteComponentProps {}
interface InjectedProps extends Props {
  appStore: AppStore;
}

@inject("appStore")
@observer
class TemplateManagerPage extends Component<Props> {
  @observable currentTab: TabId = ETEMPLATETYPE.EMAIL;
  @observable selectedItem: INotificationTemplate | undefined = undefined;

  get injected() {
    return this.props as InjectedProps;
  }

  get store() {
    return this.injected.appStore.notificationTemplateStore;
  }


  get inspectionTemplateStore() {
    return this.injected.appStore.inspectionTemplateStore;
  }

  async componentDidMount() {
    const tabId = this.props.location.search.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function(str, key, value) {
        return (key === 'tab') ? value : '';
      }
    );
    this.currentTab = tabId ? tabId : this.currentTab;
    
    
    await this.handleSearch();

    const itemId = this.props.location.search.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function(str, key, value) {
        return (key === 'id') ? value : '';
      }
    );
    if(itemId) {
      const template = this.store.templates.find(template => template.id === parseInt(itemId));

      this.store.setSelectedTemplate(template);
      this.store.setFormMode(FormMode.Edit);
      this.store.setOpenPopup(true);
    }
  }

  handleSearch = async () => {
    await this.store.fetch();
    await this.inspectionTemplateStore.fetch();
  };


  handleChangeTab = (navbarTabId: TabId) => {
    this.currentTab = navbarTabId;
  };

  render() {
    const { isLoading } = this.store;

    return (
      <Wrapper>
        <H3>템플릿 관리</H3>

        <Tabs
          id="CalculateTabs"
          onChange={this.handleChangeTab}
          selectedTabId={this.currentTab}
          renderActiveTabPanelOnly={true}
        >

          <Tab
            id={ ETEMPLATETYPE.EMAIL }
            title="이메일"
            panel={
              <NotificationTemplatePanel
                {...this.props}
                templateType={ ETEMPLATETYPE.EMAIL }
                isLoading={ isLoading } />
            }
          />

          <Tab
            id={ ETEMPLATETYPE.SMS }
            title="문자"
            panel={
              <NotificationTemplatePanel
                {...this.props}
                templateType={ ETEMPLATETYPE.SMS }
                isLoading={ isLoading } />
            }
          />

          <Tab
            id={ ETEMPLATETYPE.INSPECTION }
            title="프로젝트 플랜"
            panel={
              <NotificationTemplatePanel
                {...this.props}
                templateType={ ETEMPLATETYPE.INSPECTION }
                isLoading={ isLoading } />
            }
          />

          <Tab
            id={ 'log' }
            title="발송목록"
            panel={
              <NotificationLogPanel
                {...this.props}
                // templateType={ ETEMPLATETYPE.INSPECTION }
                 />
            }
          />

          <Tabs.Expander />

        </Tabs>

      </Wrapper>
    );
  }
}

export default TemplateManagerPage;
