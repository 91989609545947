import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import InspectionSelection from "../../molecules/InspectionSelection/InspectionSelection";
import { computed } from "mobx";
import { InspectionSelectionForm } from "../../../store/forms/ProjectGroupForm/InspectionSelectionForm";
import { Inspection } from "../../../store/models/Inspection";

const Wrapper = styled.div``;

interface InspectionSelectionFormViewProps {
  inspectionSelection: InspectionSelectionForm;
}

interface InjectedProps extends InspectionSelectionFormViewProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class InspectionSelectionFormView extends Component<
  InspectionSelectionFormViewProps
> {
  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get selectedInspection() {
    return this.injected.appStore.inspectionStore.inspections.find(
      inspection =>
        inspection.inspectionId === this.props.inspectionSelection.value
    );
  }

  handleChange = (inspection: Inspection) => {
    this.props.inspectionSelection.setValue(inspection.inspectionId);
  };

  render() {
    return (
      <Wrapper>
        <InspectionSelection
          onChange={this.handleChange}
          currentValue={this.selectedInspection}
        />
      </Wrapper>
    );
  }
}

export default InspectionSelectionFormView;
