import React from 'react';
import styled from "styled-components/macro";

import { getMomentFormatter } from "../../../../utils/date";
import {
  Button,
  Classes,
  HTMLSelect,
  HTMLTable,
  Intent,
  IOptionProps,
  InputGroup
} from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import {
  StyledDateRangeSelector,
  Content,
  FilterRow,
  StyledSearchBar,
  StyledHeader,
  StyledTr,
  SearchButton
} from './styledComponents';

interface ISprintPanel {
  sprints: Array<any>;
}
const SprintPanel = ({ sprints }: ISprintPanel) => {
  return (
    <>
      <FilterRow>
        <StatusSelector />
        <DateRangeSelector />
        <SearchBar />

        <SearchButton />
      </FilterRow>

      <Content>
        <HTMLTable
          className={ Classes.SMALL }
          condensed={ true }
          striped={ true }
          interactive={ true }
          // style={{ width: "100%", tableLayout: "fixed" }}
        >
          <TableHeader />

          <tbody>
            { sprints.map(sprint =>
              <TableRow {...sprint} />
            )}
          </tbody>
        </HTMLTable>
      </Content>

      {/* <RufreeCalculateConfirmPopup
          calculate={calculate}
          onClose={this.handleConfirmClose}
          isOpened={this.isConfirmOpen} /> */}
    </>
  )
};
SprintPanel.defaultProps = {
  sprints: [
    {
      id: '00000001',
      secondteam: '세컨팀 BI리뉴얼',
      member: '이하젤',
      sprint: 'S1',
      endDate: '2021-04-04',
      amount: 1500000,
      tax: -3.3,
      date: '2021-05-04',
      comment: '메모메모메모메모메모'
    },
    {
      id: '00000002',
      secondteam: '세컨팀 BI리뉴얼',
      member: '오샐리',
      sprint: 'S2',
      endDate: '2021-06-04',
      amount: 1500000,
      tax: -3.3,
      date: null,
      comment: '메모메모메모메모메모'
    }
  ]
};
export default SprintPanel;

const StatusSelector = () => {
  const options: Array<IOptionProps> = [
    { label: '전체', value: '' },
    { label: '지급완료', value: 'paid' },
    { label: '미지급', value: 'notpaid' }
  ];

  return (
    <HTMLSelect
      options={ options } />
  )
};

const DateRangeSelector = () => {
  return (
    <StyledDateRangeSelector>
      <label>지급일:</label>
      <DateInput
        {...getMomentFormatter("YYYY/MM/DD")}
        locale="ko"
        showActionsBar={true}
        placeholder={"시작일"}
        closeOnSelection={true}
        value={ null }
        // onChange={ () => { }}
        // maxDate={new Date("2050-01-01")}
        // disabled={this.statusFilter === 'notpaid' ? true : false}

      />
      <span>~</span>
      <DateInput
        {...getMomentFormatter("YYYY/MM/DD")}
        locale="ko"
        showActionsBar={true}
        placeholder={"종료일"}
        closeOnSelection={true}
        value={ null }
        // onChange={this.handleSearchCreatedAtBefore}
        // maxDate={new Date("2050-01-01")}
        // disabled={this.statusFilter === 'notpaid' ? true : false}
      />
    </StyledDateRangeSelector>
  )
};

const SearchBar = () => {
  return (
    <StyledSearchBar>
      <InputGroup
        placeholder="정산ID/세컨팀/멤버"
        // value={this.searchText}
        // onChange={this.handleSearchText}
        // onKeyDown={this.handleSearchTextKeyDown}
      />
    </StyledSearchBar>
  )
}

const TableHeader = () => {
  return (
    <StyledHeader>
      <StyledTr>
        <th>정산ID</th>
        <th>세컨팀</th>
        <th>멤버</th>
        <th>S(n)</th>
        <th>종료일</th>
        <th>비용</th>
        <th>세액</th>
        <th>실지급액</th>
        <th>지급일</th>
        <th>비고</th>
        <th />
      </StyledTr>
    </StyledHeader>
  )
};
const TableRow = (props: any) => {
  const buttonProps = {
    minimal: true,
    small: true,
    intent: Intent.PRIMARY
  }
  return (
    <StyledTr>
      <td>{ props.id }</td>
      <td>{ props.secondteam }</td>
      <td>{ props.member }</td>
      <td>{ props.sprint }</td>
      <td>{ props.endDate }</td>
      <td>{ props.amount }</td>
      <td>{ props.tax }</td>
      <td>{ props.amount * props.tax }</td>
      <td>{ props.date }</td>
      <td>{ props.comment }</td>
      <td>
        <Button { ...buttonProps } text='문자' />
        <Button { ...buttonProps } text={ props.date ? '수정' : '지급' } />
      </td>
    </StyledTr>
  )
}
