import { types, flow } from "mobx-state-tree";
import axios from "axios";
import { CommentModel, RufreeModel } from "./models/Rufree";
import { CommentResponse, RufreeListResponse, RufreeResponse, RufreeGraphQLResponse } from "../types/rufree";
import sortBy from "lodash/sortBy";

export const mapGraphQLRufree = (x: RufreeGraphQLResponse) => {
  return {
    uuid: "",
    rufreeId: x.rufreeId,
    name: x.name,
    email: x.auth ? x.auth.email || "" : "",
    phone: "",
    address: x.address,
    affiliation: "",
    projectGroupHistory: [],
    matchingHistory: [],
    experiences: [],
    workType: "",
    roles: [],
    rolesEtc: "",
    portfolio: {
      type: "",
      file: "",
      link: ""
    },
    portfolioPdfs: [],
    mainSkills: [],
    subSkills: [],
    getOffer: false,
    getOfferChangedAt: null,

    tags: [],
    references: [],

    oldMainSkills: [],
    oldSubSkills: [],
    interview: "",
    languages: [],
    tools: [],
    toolAccounts: [],
    expert: "",
    workingTime: [],
    workingStyle: "",
    recommender: "",
    createdAt: "",
    updatedAt: "",
    pmComment: "",
    comments: []
  }
}
export const mapRufree = (x: RufreeResponse) => {
  return {
    uuid: x.uuid || "",
    rufreeId: x.rufree_id,
    name: x.name || "",
    email: x.email,
    phone: x.phone || "",
    address: x.address || "",
    affiliation: x.affiliation,
    projectGroupHistory: x.projectgroup_history.map(k => ({
      pgId: k.projectgroup_id,
      pgName: k.name,
      role: k.role,
      dateStart: k.date_start,
      dateComplete: k.date_complete
    })),
    matchingHistory: x.matching_history.map(k => ({
      pgId: k.project_group_id,
      pgName: k.project_group_name,
      role: k.role,
      phase: k.phase,
      dateSend: k.date_send,
      dateViewed: k.date_viewed,
      dateAccepted: k.date_accepted,
      dateRejected: k.date_rejected,
      dateExpired: k.date_expired,
      dateMatched: k.date_matched,
      dateDeclined: k.date_declined,
      dateCanceled: k.date_canceled,
      rejectReason: k.reject_reason,
      comments: k.comments.map(c => ({
        id: c.id,
        creator: c.creator,
        isRufree: c.is_rufree,
        comment: c.comment,
        createdAt: c.created_at,
        updatedAt: c.updated_at
      }))
    })),
    experiences: x.experiences.map(k => ({
      title: k.title,
      workingTime: k.working_time,
      detail: k.detail,
      startAt: k.start_at,
      endAt: k.end_at,
      role: k.role_name,
      tools: k.tools,
      languages: k.languages,
      outcomeAos: k.outcome_aos,
      outcomeIos: k.outcome_ios,
      outcomeWeb: k.outcome_web
    })),
    workType: x.work_type || "",
    roles: x.roles,
    rolesEtc: x.roles_etc || "",
    portfolio: {
      type: x.portfolio ? x.portfolio.type : "",
      file: x.portfolio ? x.portfolio.file : "",
      link: x.portfolio ? x.portfolio.link : ""
    },
    portfolioPdfs: x.portfolio_pdfs,
    mainSkills: x.main_skills,
    subSkills: x.sub_skills,
    getOffer: x.get_offer,
    getOfferChangedAt: x.get_offer_changed_at,

    tags: x.tags,
    references: x.references,
    followee: x.followee.map(k => ({
      rufreeId: k.rufree_id,
      name: k.name,
      uuid: k.uuid
    })),

    oldMainSkills: x.old_main_skills,
    oldSubSkills: x.old_sub_skills,

    interview: x.interview,
    languages: x.languages,
    tools: x.tools,
    toolAccounts: x.tool_accounts,
    expert: x.expert,//TODO 삭제 예정
    // S12: "밤/새벽 " 을 "밤/새벽"으로 표시하기 위해 임시로 매핑
    workingTime: x.working_time ? x.working_time.map(x => {
      if (x === "밤/새벽\u001b") {
        return "밤/새벽";
      }

      return x;
    }) : [],
    workingStyle: x.working_style || "",
    recommender: x.recommender || "",
    createdAt: x.created_at || "",
    updatedAt: x.updated_at || "",
    pmComment: x.pm_comment || "",
    comments: x.comments.map(c => CommentModel.create(c)),
  };
};

export const RufreeStoreModel = types
  .model("RufreeStore", {
    rufrees: types.array(RufreeModel),
    rufree: types.maybeNull(RufreeModel),
    // For Pagination Query
    currentPage: types.optional(types.number, 1),
    pageGroup: types.optional(types.number, 1),
    pageSize: types.optional(types.number, 15),
    totalPages: types.optional(types.number, 1)
  })
  .views(self => ({
    get sortedRufrees() {
      return sortBy(self.rufrees.slice(), rufree => rufree.rufreeId).reverse();
    },
    rufreeById(rufreeId: string) {
      return self.rufrees.find(x => x.rufreeId === rufreeId);
      // return self.rufree;
    }
  }))
  .actions(self => ({
    setCurentPage(value: number) {
      self.currentPage = value;
    },
    setPageGroup(value: number) {
      self.pageGroup = value;
    }
  }))
  .actions(self => {
    const fetchRufrees = flow(function* ({
                                           nameOrId,
                                           role,
                                           skill,
                                           recommender,
                                           workType
                                         }: {
      nameOrId: string;
      role: string;
      skill: string;
      recommender: string;
      workType: string;
    }) {
      try {
        const { data }: { data: RufreeListResponse } = yield axios.get(
          "/rufrees",
          {
            params: {
              name_or_id: nameOrId,
              role,
              skill,
              recommender,
              work_type: workType === "전체" ? undefined : workType,
              "page": self.currentPage,
              "page_size": self.pageSize
            }
          }
        );

        const rufrees = data.results.map(x => RufreeModel.create(mapRufree(x)));
        self.totalPages = data.total_pages;
        self.rufrees.replace(rufrees);
      } catch (e) {
        console.log("fetchRufrees error", e);
        throw e;
      }
    });
    const fetchRufreeById = flow(function* (rufree_id: string) {
      try {
        const { data }: { data: RufreeResponse } = yield axios.get(
          `/rufrees/${rufree_id}`
        );
        const rufree = RufreeModel.create(mapRufree(data));
        self.rufree = rufree;
      } catch (e) {
        console.log("fetchRufreeById error", e);
        throw e;
      }
    });

    const fetchRufreeByUuid = flow(function* (uuid: string) {
      try {
        const { data }: { data: RufreeResponse } = yield axios.get(
          `/rufrees/${uuid}/uuid`
        );
        const rufree = RufreeModel.create(mapRufree(data));
        self.rufree = rufree;
      } catch (e) {
        console.log("fetchRufreeByUuid error", e);
        throw e;
      }
    });

    const fetchAllRufrees = flow(function* () {
      try {
        const { data }: { data: RufreeResponse[] } = yield axios.get(
          "/rufrees",
          {}
        );

        const rufrees = data.map(x => RufreeModel.create(mapRufree(x)));

        self.rufrees.replace(rufrees);
      } catch (e) {
        console.log("fetchAllRufrees error", e);
        throw e;
      }
    });
    const fetchAllRufreesByGraphQL = flow(function* () {
      const graphql_q = `{
        rufrees{
          rufreeId,
          name,
          auth { email },
          address
        }
      }`;

      try {
        const { data }: { data: { data: { rufrees: RufreeGraphQLResponse[] } }} = yield axios.post(
          `graphql/`,
          {
            query: graphql_q,
          }
        );
        const rufrees = data.data.rufrees.map((x: RufreeGraphQLResponse) => RufreeModel.create(mapGraphQLRufree(x)));
        self.rufrees.replace(rufrees);
      } catch (e) {
        throw e;
      }
    });
    const addComment = flow(function* (rufree_id: string, comment: string) {
      try {
        const { data }: { data: CommentResponse[] } = yield axios.post(
          `/rufrees/${rufree_id}/comment`,
          {
            comment: comment
          }
        );

        const comments = data.map(x => CommentModel.create(x));

        const selectedRufree = self.rufreeById(rufree_id);
        selectedRufree && selectedRufree.comments.replace(comments);
      } catch (e) {
        console.log("addComment Rufrees error", e);
        throw e;
      }
    });

    const updateComment = flow(function* (rufree_id: string, comment_id: number, comment: string) {
      try {
        const { data }: { data: CommentResponse[] } = yield axios.patch(
          `/rufrees/${rufree_id}/comment`,
          {
            id: comment_id,
            comment: comment
          }
        );

        const comments = data.map(x => CommentModel.create(x));

        const selectedRufree = self.rufreeById(rufree_id);
        selectedRufree && selectedRufree.comments.replace(comments);
      } catch (e) {
        console.log("addComment Rufrees error", e);
        throw e;
      }
    });
    const deleteComment = flow(function* (rufree_id: string, comment_id: number) {
      try {
        const { data }: { data: CommentResponse[] } = yield axios.delete(
          `/rufrees/${rufree_id}/comment`,
          {
            data: { id_list: [comment_id] }
          }
        );

        const comments = data.map(x => CommentModel.create(x));

        const selectedRufree = self.rufreeById(rufree_id);
        selectedRufree && selectedRufree.comments.replace(comments);
      } catch (e) {
        console.log("addComment Rufrees error", e);
        throw e;
      }
    });

    return {
      fetchRufrees,
      fetchRufreeById,
      fetchRufreeByUuid,
      fetchAllRufrees,
      fetchAllRufreesByGraphQL,
      addComment,
      deleteComment,
      updateComment
    };
  });

type RufreeStoreType = typeof RufreeStoreModel.Type;

export interface RufreeStore extends RufreeStoreType {
}
