import React, { Component, ChangeEvent, KeyboardEvent, FormEvent, createRef } from "react";
import { AppStore } from "../../../store/AppStore";
import { inject, observer } from "mobx-react";
import { GrayLink, GreyLabel } from "../../atoms/DetailReportComponents/DetailReportComponents";
import styled from "styled-components/macro";
import { computed, observable } from "mobx";
import { Button, FileInput, InputGroup } from "@blueprintjs/core";
import { PdfToUploadForm, PdfToUploadFormModel } from "../../../store/forms/RufreeForm/PdfToUploadForm";
import { BulletinPostFile, BulletinPostLink, TaskFile, TaskLink } from "../../../store/models/TaskManager";
import moment from "moment";
import TaskAttachmentFileForm from "./TaskAttachmentFileForm";
import TaskAttchmentLinkForm from "./TaskAttchmentLinkForm";

interface TaskAttachmentListFormProps {
  links: Array<BulletinPostLink>;
  files: Array<BulletinPostFile>;
  bulletinPostId: string;
  task_bulletin: string;
  // onAdd: (values: object) => void;
  // onCancel: () => void;
}

interface InjectedProps extends TaskAttachmentListFormProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class BulletinAttachmentListForm extends Component<TaskAttachmentListFormProps> {

  @observable create_status = 0;
  @observable newLink = false;
  @observable newLinkVal = "";
  @observable file: PdfToUploadForm | undefined;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get formStore() {
    return this.injected.appStore.taskManagerStore;
  }

  fileUpload = (e: FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      const newNode = PdfToUploadFormModel.create();
      newNode.setFile(e.currentTarget.files[0]);
      this.formStore.updateBulletinPostFile({
        task_bulletin_post_id: this.props.bulletinPostId,
        fileModel: newNode,
        task_bulletin: this.props.task_bulletin
      }, "post");
    }
  };
  fileRemove = (fileId: number) => {
    this.formStore.updateBulletinPostFile({
      task_bulletin_post_id: this.props.bulletinPostId,
      task_bulletin: this.props.task_bulletin,
      id: fileId
    }, "delete");
  };

  linkUpload = () => {
    this.formStore.updateBulletinPostLink({
      task_bulletin_post_id: this.props.bulletinPostId,
      link: this.newLinkVal,
      task_bulletin: this.props.task_bulletin
    }, "post");
    this.newLinkVal = "";
  };

  linkRemove = (linkId: number) => {
    this.formStore.updateBulletinPostLink({
      task_bulletin_post_id: this.props.bulletinPostId,
      // link: this.newLinkVal,
      task_bulletin: this.props.task_bulletin,
      id: linkId
    }, "delete");
  };

  linkEdit = (changedLink: string, linkId: number) => {
    this.formStore.updateBulletinPostLink({
      task_bulletin: this.props.task_bulletin,
      task_bulletin_post_id: this.props.bulletinPostId,
      id: linkId,
      link: changedLink
    }, "patch");
  };

  render() {
    const { links, files } = this.props;
    return (
      <div style={{ marginBottom: "10px" }}>

        <GreyLabel style={{ display: "inline-block" }}>첨부</GreyLabel>
        <div style={{ display: "inline-block", marginLeft: "30px" }}>
          <label htmlFor="upload-button"><GrayLink>파일 추가</GrayLink></label>
        </div>
        <div style={{ display: "inline-block", marginLeft: "30px" }}>
          <GrayLink onClick={() => {
            this.newLink = !this.newLink;
          }}>링크 추가</GrayLink>
        </div>

        <div style={{ padding: "5px" }}>
          <input
            type="file"
            id="upload-button"
            style={{ display: "none" }}
            onChange={this.fileUpload}
          />
          {this.newLink ? <div>
              <div style={{ float: "left" }}>
                <InputGroup placeholder="링크를 입력해주세요." value={this.newLinkVal}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              this.newLinkVal = e.target.value;
                            }}/>
              </div>
              <div style={{ float: "left" }}><Button text={"추가"} onClick={() => this.linkUpload()}/></div>
              <div style={{ clear: "left" }}></div>

            </div>
            : null}

        </div>

        <div>
          {links ?
            links.map((link) => {
              return (
                <TaskAttchmentLinkForm key={`${link.id}link`} id={link.id} link={link.link} created_at={link.created_at}
                                       onRemove={(id: number) => this.linkRemove(link.id)}
                                       onEdit={(linkToChange: string) => {
                                         this.linkEdit(linkToChange, link.id);
                                       }}
                                       disabled={false}/>
              );
            }) : null}

          {files ?
            files.map((file) => {
              return (
                <TaskAttachmentFileForm filename={file.filename}
                                        key={`${file.id}file`} file={file.file} id={file.id}
                                        created_at={file.created_at}
                                        isImage={file.is_image}
                                        onRemove={(id: number) => this.fileRemove(file.id)}
                                        disabled={false}/>

              );
            }) : null}
        </div>
      </div>
    );
  }
}

export default BulletinAttachmentListForm;