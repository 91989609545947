import React, { Component, ChangeEvent, FormEvent } from "react";
import { inject, observer } from "mobx-react";
import { computed, observable } from "mobx";
import { AppStore } from "../../../store/AppStore";
import styled from "styled-components/macro";
import { ReferenceLinkForm, ReferenceLinkFormStoreModel } from "../../../store/forms/ParttimeForm/ReferenceLinkForm";
import { Button, Intent, InputGroup } from "@blueprintjs/core";

interface ReferenceLinkFormProps {
    referenceLink: ReferenceLinkForm;
    disabled: boolean;
    onRemove: (referenceLink: ReferenceLinkForm) => void;
}

interface InjectedProps extends ReferenceLinkFormProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ReferenceLinkFormRow extends Component<ReferenceLinkFormProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  handleRemoveReferenceLink = () => {
    const { referenceLink, onRemove } = this.props;
    onRemove(referenceLink);
    
  };

  handleURLChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { referenceLink } = this.props;
    referenceLink.setURL(e.target.value);
  };

  handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { referenceLink } = this.props;
    referenceLink.setDescription(e.target.value);
  };

  render(){
    const { referenceLink, disabled } = this.props;
    return (
    <InputRow>
      <Button
          icon="minus"
          minimal={true}
          small={true}
          intent={Intent.DANGER}
          onClick={this.handleRemoveReferenceLink}
          disabled={disabled}
      />
      <InputGroup
      placeholder="참고링크"
      value={referenceLink.url}
      onChange={this.handleURLChange}
      style={{ width: "230px" }}
      disabled={disabled}
      />
      <InputGroup
      placeholder="설명"
      value={referenceLink.description}
      onChange={this.handleDescriptionChange}
      disabled={disabled}
      />
  </InputRow>
  )};
}

export default ReferenceLinkFormRow;

const InputRow = styled.div`
  display: flex;

  & > * + * {
    margin-left: 5px;
  }
`;
