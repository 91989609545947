import React, { Component }  from 'react';
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import { H5, Button } from "@blueprintjs/core";
import moment from 'moment';

import { AppStore } from "../../../store/AppStore";
import { SectionTitleWrapper, SectionRightWrapper } from '../../atoms/DashboardComponents/DashboardComponents';
import DashboardOfficeHourPopup from "../../molecules/Dashboard/DashboardOfficeHourPopup";

import IconVacation from "./vacation.png";

const HeaderWrapper = styled.div`
  min-height: 50px;
  display: flex;
`;

const HeaderTitleWrapper = styled(SectionTitleWrapper)`
display: flex;
align-items: center;
`;

const HeaderTitle = styled(H5)`
  margin-right: 10px;
  margin-bottom: 0;
`;

const Circle = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: ${p => p.color};
  display: inline-block;
  margin-right: 5px;
`;

const VacationIcon = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 5px;
`;

const OfficeHourButton = styled(Button)`
  margin-left: 10px;
  font-size: 12px;
`;

const ContentWrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  background-color: #f1f1f1;
 `;

 const OfficeHourWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 20px 10px 0px;
 `;

 interface DashboardOfficeHourProps {}
 interface InjectedProps extends DashboardOfficeHourProps {
   appStore: AppStore;
 }
 
 @inject("appStore")
 @observer
 class DashboardOfficeHour extends Component<DashboardOfficeHourProps> {
  @observable isOpenOfficeHourPopup = false;
  @observable isLoading = false;
    
  get injected() {
     return this.props as InjectedProps;
  }

  fetchList = async () => {
    try {
      this.isLoading = true;
      await this.injected.appStore.pmUserStore.fetchPMUsers();

      let me = this.injected.appStore.userStore.userId;
      await this.injected.appStore.dashboardOfficeHourFormStore.fetchForm(me);

      await this.injected.appStore.scheduleManagerStore.fetchDashBoardSeesoSchedules();
      await this.injected.appStore.scheduleManagerStore.refreshDashboardCalendarSeesoSchedules();
    } catch (e) {} finally { this.isLoading = false; }
  };  

  async componentDidMount() {
    try {
      this.isLoading = true;
      // 중복 호출을 피하기 위해 fetchList를 호출하지 않는다
      let me = this.injected.appStore.userStore.userId;
      await this.injected.appStore.dashboardOfficeHourFormStore.fetchForm(me);
    } catch (e) {} finally { this.isLoading = false; }
  }

  handleOfficeHourPopupOpen = () => {
    this.isOpenOfficeHourPopup = true;
  }

  handleOfficeHourPopupClose = async () => {
    this.isOpenOfficeHourPopup = false;
    await this.fetchList();
    this.forceUpdate();
  }

  isCurrentSchedule = (startAt: string, endAt: string) => {
    return (
      moment(startAt).isBefore(moment()) && moment(endAt).isAfter(moment())
    );
  }

  getTodayVacation = (userId: number) => {
    const { todayVacations } = this.injected.appStore.scheduleManagerStore;
    
    let vacation = null;

    // 휴가 처리
    vacation = todayVacations.find(
      s =>
      s.schCategory === 'vac' && 
      s.creator === userId && 
      (
        moment(s.startAt).hour(0).minute(0).isBefore(moment()) && 
        moment(s.endAt).hour(24).minute(0).isAfter(moment())
      )        
    );
    if (vacation) {
      return vacation;
    }

    // 오전반차
    vacation = todayVacations.find(
      s => 
        s.schCategory === 'vam' && 
        s.creator === userId && 
        (
          moment(s.startAt).hour(0).minute(0).isBefore(moment()) && 
          moment(s.startAt).hour(12).minute(0).isAfter(moment())
        )      
    );
    if(vacation) {
      return vacation
    }

    vacation = todayVacations.find(
      s => 
      s.schCategory === 'vpm' && 
      s.creator === userId && 
      (
        moment(s.startAt).hour(12).minute(0).isBefore(moment()) && 
        moment(s.startAt).hour(24).minute(0).isAfter(moment())
      )   
    );
    if(vacation) {
      return vacation
    }
  
    return null;
  }

  render_schedule_icon = (schedules: Array<any>, vacation:any) => {
    if (schedules.filter(
      schedule => this.isCurrentSchedule(schedule.startAt, schedule.endAt)
    ).length <= 0 ) {
      // 현재 매칭되는 근무시간이 없는 경우
      if (vacation && ['vac', 'vam', 'vpm'].includes(vacation.schCategory)) {
        // 휴가 일정이 있다면 해당 아이콘 반영
        return <VacationIcon src={IconVacation} />;
      } else {
        // 휴가 일정이 없다면, 휴게시간이거나, 미입력인 경우임
        if (schedules.length > 0) {
          // 근무시간 입력이 있었으므로, 휴게시간으로 판단
          return <Circle color={"gray"} />;
        } else {
          return <></>;
        }
      }
    } else {
      if (vacation && vacation.schCategory == 'vac') {
        return <VacationIcon src={IconVacation} />;
      } else {
        return <Circle color={"#00aa00"} />;
      }
    }
  }

  render_schedule_text = (schedules: Array<any>, vacation: any) => {
    let scheduleText = '';
    if(vacation && vacation.schCategory == 'vac') {
      scheduleText = '휴가 ('+vacation.title+')';
    } else {
      if (schedules.length == 0) {
        if (vacation && vacation.schCategory == 'vam') { scheduleText = '오전반차'; }
        else if (vacation && vacation.schCategory == 'vpm') { scheduleText = '오후반차'; }
        else {
          scheduleText = '';
        }
      } else {
        scheduleText = schedules.map(schedule => {
          return (schedule.startAt.substring(11) + ' ~ ' + schedule.endAt.substring(11) + (schedule.title ? ' @'+schedule.title : ''));
        }).join(', ');
      }
    }
    return scheduleText;    
  }

  render_my_officehour = () => {
    const { userId } = this.injected.appStore.userStore;
    const { dashboardOfficeHourFormStore : scheduleStore} = this.injected.appStore;
    
    const schedules = scheduleStore.myTodayOfficeHours;
    const vacation = this.getTodayVacation(userId);

    const scheduleIcon = this.render_schedule_icon(schedules, vacation);
    const scheduleText = this.render_schedule_text(schedules, vacation);

    return (
      <>
        {scheduleIcon}
        {scheduleText ? scheduleText : '등록되지 않았습니다'}
      </>
    );
  }

  render_others_officehour = () => {
    const { userId } = this.injected.appStore.userStore;
    const { pmUsers } = this.injected.appStore.pmUserStore;

    const { todayOfficeHours } = this.injected.appStore.scheduleManagerStore;
    
    const others = pmUsers.filter(x => x.user_id !== userId)
    const others_officehour = others.map(pmUser => {
      let schedules = todayOfficeHours.filter(x => x.creator === pmUser.user_id);
      let vacation = this.getTodayVacation(pmUser.user_id)

      const hasSchedule = !(vacation == null && schedules.length == 0)
      const scheduleIcon = this.render_schedule_icon(schedules, vacation);
      const scheduleText = this.render_schedule_text(schedules, vacation);

      if (hasSchedule) {
        return (
          <OfficeHourWrapper key={pmUser.id}>
            {scheduleIcon}
            {' ' + pmUser.nickname + ' '}
            {scheduleText}
          </OfficeHourWrapper>
        );            
      }
    });

    return others_officehour.filter(x => x != null);
  }

  render() {
    const my_officehour = this.render_my_officehour();
    const others_officehour = this.render_others_officehour();

    return (
      <div>
        <div>
        <HeaderWrapper>
          <HeaderTitleWrapper>
            <HeaderTitle>오늘 근무시간</HeaderTitle>
            <HeaderTitleWrapper>
              내 근무시간: &nbsp;&nbsp;
              {my_officehour}
            </HeaderTitleWrapper>
          </HeaderTitleWrapper>
          <SectionRightWrapper>
            <OfficeHourButton 
              text="이번주 근무시간 관리" 
              onClick={() => this.handleOfficeHourPopupOpen()}
              loading={this.isLoading}
            />
          </SectionRightWrapper>
        </HeaderWrapper>
        </div>
        <div>
        <ContentWrapper>
          { others_officehour.length > 0 ? (
            <>
            { others_officehour }          
            </>
          ) : (
            <span>팀원들의 근무시간이 등록되지 않았습니다.</span>
          )}
        </ContentWrapper>
        </div>
        <DashboardOfficeHourPopup
            isOpen={this.isOpenOfficeHourPopup}
            onClose={this.handleOfficeHourPopupClose}
        />   
      </div>

    );
  }
}

export default DashboardOfficeHour; 