import React, { Component } from "react";
import styled from "styled-components";

const IMG = styled.img`
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 110px;
  height: 80px;
  &:hover{
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }
`;

const EmptyIMG = styled.div`
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 110px;
  height: 80px;
  &:hover{
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }
  display: flex;
  justify-content: center;
  
`;
// const Center = styled.div`
//  margin: auto;
//   width: 40%;
//   `;

interface Props {
  src?: string;
  isImage: boolean;
}

class Thumbnail extends Component<Props> {

  render() {
    return (
      <div>
        {this.props.isImage ?
          <IMG src={this.props.src}/> :
          <EmptyIMG>
            {/*<Center>*/}
            <div>
              {this.props.children ? this.props.children : null}
            </div>
            {/*</Center>*/}
          </EmptyIMG>}
      </div>

    );
  }
}

export default Thumbnail;