import React, { Component } from "react";
import styled from "styled-components/macro";
import { RouteComponentProps } from "react-router";
import { computed, observable } from "mobx";
import { inject, observer } from "mobx-react";

import { AppStore } from "../../../store/AppStore";
import { FormMode } from "../../../types/formMode";

import { TaskStatus,TaskStatusData } from "../../../store/models/Parttime";

import { Tab, TabId, Tabs, Spinner, Alert, Intent } from "@blueprintjs/core";

import { PrimaryButton, SecondaryButton } from "../../atoms/ParttimeComponents/ParttimeComponents";
import { PageNavWrapper } from "../../atoms/PageNav/PageNav";
import LargeContainer from "../../atoms/LargeContainer/LargeContainer";
import { BreadCrumb } from "../../molecules/BreadCrumb/BreadCrumb";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";

import BasicPanel from "../../panels/ParttimePanel/TaskBasicTabPanel"
import ReferencePanel from "../../panels/ParttimePanel/TaskReferenceTabPanel"
import TaskPanel from "../../panels/ParttimePanel/TaskTaskTabPanel"
import RepresentativePanel from "../../panels/ParttimePanel/TaskRepresentativeTabPanel"
import AdminPanel from "../../panels/ParttimePanel/TaskAdminTabPanel"
import OutcomePanel from "../../panels/ParttimePanel/TaskOutcomeTabPanel"

import TaskFormDialog from "../../molecules/ParttimeDialog/TaskFormDialog";
import RufreeMatchDialog from "../../molecules/ParttimeDialog/RufreeMatchDialog";
import TaskCompleteDialog from "../../molecules/ParttimeDialog/TaskCompleteDialog";

import { Task} from "../../../store/models/Parttime";

interface PageParams {
    requestId: string;
    taskId: string;
}
  
interface TaskDetailPageProps extends RouteComponentProps<PageParams> {}

interface InjectedProps extends TaskDetailPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class PartTimeTaskDetailPage extends Component<TaskDetailPageProps> {
    @observable task: any | null = null;
    @observable readyToShow = false;
    @observable currentTabId: string | number = "1";
    @observable isTaskFormDialogOpen: boolean = false;
    @observable showTaskCancelAlert: boolean = false;
    @observable isRufreeMatchDialogOpen: boolean = false;
    @observable isTaskCompleteDialogOpen: boolean = false;

    get injected() {
        return this.props as InjectedProps;
    }

    @computed
    get taskStore() {
        return this.injected.appStore.parttimeTaskStore;
    }

    handleTabChange = (tabId: TabId) => {
      this.currentTabId = tabId;
    };

    handleTaskFormDialogOpen = () => {
      this.isTaskFormDialogOpen = true;
    };

    handleTaskFormDialogClose = async() => {
      this.isTaskFormDialogOpen = false;
      const { requestId, taskId } = this.props.match.params;
      this.task = await this.injected.appStore.parttimeTaskStore.fetchTaskById(parseInt(requestId), parseInt(taskId));
    };  

    handleTaskCancelSubmit = async () => {
      try {
          const { task } = this;
        await this.injected.appStore.parttimeTaskFormStore.cancelTask(task.request.id, task.id);
        this.showTaskCancelAlert = false;
        this.task = await this.injected.appStore.parttimeTaskStore.fetchTaskById(task.request.id, task.id);
      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }    
    }

    handleRufreeMatchDialogOpen = () => {
      this.isRufreeMatchDialogOpen = true;
    };
  
    handleRufreeMatchDialogClose = async() => {
      const { task } = this;
      this.isRufreeMatchDialogOpen = false;
      this.task = await this.injected.appStore.parttimeTaskStore.fetchTaskById(task.request.id, task.id);
    }; 

    handleTaskCompleteDialogOpen = () => {
      this.isTaskCompleteDialogOpen = true;
    };
  
    handleTaskCompleteDialogClose = async() => {
      const { task } = this;
      this.isTaskCompleteDialogOpen = false;
      this.task = await this.injected.appStore.parttimeTaskStore.fetchTaskById(task.request.id, task.id);
    };

    handleTaskUpdate = (updatedTask: Task | any) => {
      this.task = updatedTask;
    };

    async componentDidMount() {
        const { requestId, taskId } = this.props.match.params;

        try {
          await this.injected.appStore.rufreeStore.fetchAllRufrees();
          this.task = await this.injected.appStore.parttimeTaskStore.fetchTaskById(requestId, taskId);
        } catch (e) {
        }finally {
          this.readyToShow = true;
        }
    }
    
    render() {
        const { isPM } = this.injected.appStore.userStore;
        const { requestId, taskId } = this.props.match.params;
        const { task } = this;

        if (!this.readyToShow) {
          return <Spinner />;
        }

        if (!task) {
            return <Wrapper>TASK 상세내역을 가져오지 못했습니다.</Wrapper>;
          }      

        return (
            <LargeContainer>
                <PageNavWrapper>
                    <BreadCrumb
                        items={[
                          { name: "대시보드", link: "/parttime/" },
                          { name: "TASK 목록", link: "/parttime/tasks" },
                          "TASK 상세내역"
                        ]}
                    />
                </PageNavWrapper>

                { isPM && (task.status !== "CANCELED")  && (
                  <PrimaryButton 
                    text={"수정"} 
                    style={{width: "150px", float: "right"}}
                    onClick={this.handleTaskFormDialogOpen}
                  ></PrimaryButton>
                )}

                <Title>
                  <StatusTag>{TaskStatus.get(task.status)}</StatusTag>  
                  <h2 style={{ display: "inline", marginLeft: "10px" }}>{ task.title }</h2>    
                </Title>

                <Tabs id="TabsExample" onChange={this.handleTabChange} selectedTabId={this.currentTabId}>
                  <Tab id="1" title={"기본정보"} panel={<BasicPanel {...this.props} task={task}/>} />
                  <Tab id="2" title={"참고자료"} panel={<ReferencePanel {...this.props} task={task} />}/>
                  <Tab id="3" title={"연관 TASK"} panel={<TaskPanel {...this.props} task={task} />} />
                  <Tab id="4" title={"담당&연락처"} panel={<RepresentativePanel {...this.props} task={task} updateTask={this.handleTaskUpdate}/>} />
                  { isPM && (<Tab id="5" title={"관리자메모"} panel={<AdminPanel {...this.props} task={task} />} />) }
                  {(task.status === TaskStatusData.completed.db) && (<Tab id="6" title={"정산&결과물"} panel={<OutcomePanel {...this.props} task={task} />} />)}
                  <Tabs.Expander />
                </Tabs>

                <TaskFormDialog
                  requestId={ parseInt(requestId) }
                  taskId={ parseInt(taskId) }
                  mode={ FormMode.Edit }
                  onClose={ this.handleTaskFormDialogClose }
                  isOpen={ this.isTaskFormDialogOpen } 
                />

                { isPM && 
                  (task.status === TaskStatusData.created.db || task.status === TaskStatusData.arranging.db) && 
                  (<PrimaryButton 
                      text={"알유프리 매칭"} 
                      onClick={this.handleRufreeMatchDialogOpen}
                      style={{width: "100%", marginTop: "10px"}}
                  />) }
                
                { isPM && ([TaskStatusData.canceled.db, TaskStatusData.completed.db].indexOf(task.status) < 0) && (<PrimaryButton 
                    text={"TASK 완료"} 
                    onClick={this.handleTaskCompleteDialogOpen}
                    style={{width: "100%", marginTop: "10px"}}
                />) }

                { isPM && (task.status !== TaskStatusData.canceled.db) && (<SecondaryButton 
                    text={"TASK 취소"} 
                    onClick={() => {this.showTaskCancelAlert=true;}}
                    style={{width: "100%", marginTop: "10px"}}
                />) }

                <Alert 
                  icon="undo"
                  intent={Intent.DANGER}
                  isOpen={this.showTaskCancelAlert}
                  onCancel={() => this.showTaskCancelAlert=false}
                  onConfirm={this.handleTaskCancelSubmit}
                  confirmButtonText={'확인'}
                  cancelButtonText={'닫기'}
                ><p>이 TASK를 취소합니다. 기존 작업 상황과 관계없이 정산에서 제외처리 됩니다.</p>
                </Alert>

                <RufreeMatchDialog
                  task={ task }
                  mode={ FormMode.Create }
                  onClose={ this.handleRufreeMatchDialogClose }
                  isOpen={ this.isRufreeMatchDialogOpen } 
                />

                <TaskCompleteDialog
                  task={ task }
                  onClose={ this.handleTaskCompleteDialogClose }
                  isOpen={ this.isTaskCompleteDialogOpen } 
                />
            </LargeContainer>
        );
    }
}

export default PartTimeTaskDetailPage;

const Wrapper = styled.div``;

const Title = styled.div`
  margin-bottom: 40px;
`;

const StatusTag = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  top: -2px;
  border: none;
  border-radius: 3px;
  box-shadow: none;
  background-color: #1162a2;
  min-width: 20px;
  max-width: 100%;
  min-height: 27px;
  padding: 2px 6px;
  line-height: 16px;
  color: #f5f8fa;
  font-size: 15px;
`;
