import { types, flow } from "mobx-state-tree";
import { ClientUserModel } from "./models/ClientUser";
import axios from "axios";
import { ClientUserResponse } from "../types/clientUsers";
import { putItemForArray } from "../utils/MSTUtils";

export const mapResponseToClientUser = (data: ClientUserResponse) => {
  return {
    id: data.id,
    name: data.name,
    email: data.email,
    uuid: data.uuid,
    phone: data.phone,
    affiliation: data.affiliation,
    affiliationType: data.affiliation_type,
    clientId: data.client_id
  };
};

export const ClientUserStoreModel = types
  .model("ClientUserStore", {
    clientUsers: types.array(ClientUserModel)
  })
  .views(self => ({
    clientUserByEmail(email: string) {
      return self.clientUsers.find(x => x.email === email);
    }
  }))
  .actions(self => ({
    setClientUsers(values: any[]) {
      /*
        graphQL 응닶값으로 생성합니다.
      */
     const items = values.map(x =>
      ClientUserModel.create({
        id: parseInt(x.id),
        name: x.name || "",
        email: x.email,
        uuid: x.uuid,
        phone: x.phone || "",
        affiliation: x.affiliation || "",
        affiliationType: x.affiliation_type || "",
        clientId: x.client ? x.client.clientId || "" : ""
      })
    );
    self.clientUsers.replace(items);
    }
  }))
  .actions(self => {
    const fetchClientUser = flow(function* (id: number) {
      try {
        const { data }: { data: ClientUserResponse } = yield axios.get(
          `/clientUsers/${id}`
        );

        const clientUser = ClientUserModel.create(
          mapResponseToClientUser(data)
        );

        putItemForArray({
          array: self.clientUsers,
          item: clientUser,
          key: "id"
        });
      } catch (e) {
        console.error("fetchClientUser error", e);
        throw e;
      }
    });

    const fetchClientUsers = flow(function* () {
      try {
        const { data }: { data: ClientUserResponse[] } = yield axios.get(
          `/clientUsers`
        );

        const clientUsers = data.map(x =>
          ClientUserModel.create(mapResponseToClientUser(x))
        );

        self.clientUsers.replace(clientUsers);
      } catch (e) {
        console.error("fetchClientUsers error", e);
        throw e;
      }
    });

    return {
      fetchClientUser,
      fetchClientUsers
    };
  });

type ClientUserStoreType = typeof ClientUserStoreModel.Type;

export interface ClientUserStore extends ClientUserStoreType {
}
