import { types } from "mobx-state-tree";
import { SprintCheckModel } from "./SprintCheck";

export const ClientRoleFormModel = types
  .model("ClientRoleForm", {
    role: types.optional(types.string, ""),
    sprints: types.array(SprintCheckModel)
  })
  .views(self => ({
    get sprintsToNumber() {
      const sprints: number[] = [];

      self.sprints.forEach((s, index) => {
        if(!!s.value) sprints.push(index);
      });

      return sprints;
    }
  }))
  .actions(self => ({
    setRole(value: string) {
      self.role = value;
    }
  }));

type ClientRoleFormType = typeof ClientRoleFormModel.Type;
export interface ClientRoleForm extends ClientRoleFormType {}
