import { types } from "mobx-state-tree";

export const ClientUserModel = types
  .model("ClientUser", {
    id: types.identifierNumber,
    name: types.string,
    email: types.string,
    uuid: types.string,
    phone: types.string,
    affiliation: types.string, // 소속
    affiliationType: types.string,
    clientId: types.union(types.string, types.undefined)
  })
  .views(self => ({
    get nameWithEmail() {
      return `${self.name} - ${self.email}`;
    }
  }));

type ClientUserType = typeof ClientUserModel.Type;
export interface ClientUser extends ClientUserType {}
