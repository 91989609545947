import React, { Component, MouseEvent } from "react";
import styled from "styled-components/macro";
import { Inspection } from "../../../store/models/Inspection";
import moment from "moment";
import { MUTED_GRAY } from "../../../constants/colors";
import { inject, observer } from "mobx-react";
import { computed, observable } from "mobx";
import { RoundedButton } from "../../atoms/RoundedButton/RoundedButton";
import {
  FlexRow,
  GreyLabel,
  ContentColumn,
  DividerLine,
  LeftColumn,
  LargeText,
  NormalText,
  LabelPadding
} from "../../atoms/DetailReportComponents/DetailReportComponents";
import {
  Checkbox,
  Dialog,
  Classes,
  FormGroup,
  H3,
  Intent,
  Button,
  HTMLTable,
  Colors,
  ProgressBar,
  // Tooltip,
  // Position,
  Collapse,
  Icon
} from "@blueprintjs/core";
import { ConvertLinkTextFormat } from "../../atoms/ConvertLinkTextFormat/ConvertLinkTextFormat";
import { Link } from "react-router-dom";
import { AppStore } from "../../../store/AppStore";
import { AppToaster } from "../AppToaster/AppToaster";

import { PlanReadSection } from "../../organisms/Plan/PlanReadSection";
import { ScheduleBrief } from '../../molecules/Plan/ScheduleBrief';
import { ScheduleIcon } from '../../molecules/Plan/_wrapper';

const Wrapper = styled.div``;

const InspectionHistory = styled.div`
  margin-top: 50px
`;
const InspectionHistoryBody = styled.div`
  margin-top: 10px
`;
const DefaultRow = styled(FlexRow)`
  margin-bottom: 55px;
`;
const Section = styled.section`
  margin-bottom: 25px;

  & > div.title {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    padding-top: 30px;
    padding-bottom: 16px;
    border-top: 1px solid #666666;
  }
`;
const AttentionSection = styled.section`
  background-color: #e7e7e7;
  padding: 20px;
  border-radius: 3px;
  margin: 5px 5px 40px 5px;
  margin-bottom: 40px;

  & > div:first-child {
    color: ${Colors.VERMILION2};
  }
`;
const ReceptionsSection = styled.section`
  margin-bottom: 40px;
`;
const ResourcesSection = styled.section`
  margin-bottom: 49px;
`;
export const Row = styled.div`
  margin-bottom: 40px;
  display: flex;
`;
export const Column = styled.div`
  min-width: 125px;
  max-width: 500px;
  margin-right: 55px;
`;
export const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
`;
export const Label = styled.div`
  font-weight: 600;
  color: ${MUTED_GRAY};
  margin-bottom: 7px;
`;
export const CostRow = styled(Row)`
  margin-bottom: 28px;
  display: block;
`;
export const SecondLabel = styled.div`
  font-weight: 600;
  color: #989898;
  margin-bottom: 4px;
`;
export const ResourceRow = styled.div`
  display: flex;
  margin-bottom: 2px;
  flex-wrap: nowrap;
`;
export const ResourceColumn = styled.div`
  width: 200px;
  min-width: 200px;
`;
export const SprintInfoColumn = styled.div`
  width: 180px;
  display: flex;
  justify-content: space-between;
`;
const ReviewText = styled(NormalText)`
  whiteSpace: pre-line;
  font-weight: 400;
  font-size: 14px;
`;
export const Text = styled.div`
  font-weight: 600;
  white-space: pre-wrap;
`;
const ExtraInfos = styled.div`
  word-break: keep-all;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: #666666;
`;
const ExtraInfoDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  color: #7E838F;
  margin-top: 20px;
`;
const InfoRow = styled.div`
  display: flex;
  margin-bottom: 2px;
  & + & {
    margin-top: 12px;
  }
`;
const InfoHead = styled.div`
  min-width: 128px;
  font-weight: 500;
`;
const InfoContent = styled.div``;
export const ReferenceLinksRow = styled.div`
  margin-top: 15px;
  word-break: break-all;

  & > * + * {
    margin-top: 5px;
  }
`;
export const LinkComment = styled.span`
  margin-left: 10px;
`;

const removeWhitespace = (str: string) => str.replace(" ", "");

interface InspectionCardProps {
  inspection: Inspection;
  showSchedule?: boolean;
  hideButtons?: boolean;
  showVatIncluded?: boolean;
  scheduleRef?: React.RefObject<HTMLDivElement>;
}

interface InjectedProps extends InspectionCardProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class InspectionCard extends Component<InspectionCardProps> {
  @observable totalCostOfSprint = 0;
  @observable isSprintPopupOpen = false;
  @observable downloadLoadingOpen = false;
  @observable downloadLoadingPercent: number = 0;
  @observable publishedOnly = true;
  @observable vatIncludedHistory = true;
  @observable isReviewOpen = false;
  @observable isResourceOpen = true;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get showPM() {
    return [
      "운영 관리",
      "업무 관리",
      "리스크 관리",
      "QA 검수"
    ].every(serviceName =>
      this.props.inspection.seesoServices.find(
        service =>
          service.totalSprintDays &&
          removeWhitespace(service.service) === removeWhitespace(serviceName)
      )
    );
  }

  async componentDidMount() {
    // await this.injected.appStore.receptionStore.fetchReceptions();
    await this.injected.appStore.receptionStore.fetchAllReceptions();
  }

  @computed
  get receptions(): Array<any> {
    return this.injected.appStore.receptionStore.receptions;
  }

  @computed
  get showPO() {
    if (this.showPM) {
      return false;
    }

    return ["운영 관리", "업무 관리", "리스크 관리"].every(serviceName =>
      this.props.inspection.seesoServices.find(
        service =>
          service.totalSprintDays &&
          removeWhitespace(service.service) === removeWhitespace(serviceName)
      )
    );
  }

  handleSprintNumberClick = (sprintIndex: number) => {
    this.totalCostOfSprint = this.props.inspection.getTotalCostOfSprint(
      sprintIndex
    );
    this.isSprintPopupOpen = true;
  };

  handleSprintPopupClose = () => {
    this.isSprintPopupOpen = false;
  };

  renderServiceExplanation = () => {
    return (
      <ExtraInfos>
        <InfoRow>
          <InfoHead>CS 매니지먼트</InfoHead>
          <InfoContent>
          시소에서 제공하는 프로젝트 운영방식과 협업 프로세스가 원활히 진행되게끔 지원합니다.
          </InfoContent>
        </InfoRow>
        <InfoRow>
          <InfoHead>워크플로우 설계</InfoHead>
          <InfoContent>
          새롭게 꾸려진 팀이 리모트 환경에서 효율적으로 실무를 진행하도록 업무 흐름을 세팅하는 것으로, 해당 프로젝트에서 발생 가능한 이슈를 미리 예측하여 리스크를 최소화하여 프로젝트 매니징을 보완합니다.
          </InfoContent>
        </InfoRow>

        <ExtraInfoDescription>
          *상기 내용은 2020년 하반기 이후 '세컨팀' 모델로 의뢰한 프로젝트에만 해당됩니다. 이전 일반 의뢰로 발행된 검수서의 비목과는 무관하며, 시소 서비스 리뉴얼로 인해 최신 버전으로 안내되는 점 양해부탁드립니다.
        </ExtraInfoDescription>

      </ExtraInfos>
    )
  }

  render() {
    const { inspection, showSchedule, hideButtons, showVatIncluded } = this.props;
    const receptionLink = inspection.receptions.map((reception, index) => {
      const foundReception = this.receptions ?
        this.receptions.find(instance => instance.id === reception) :
        null;
      const title = foundReception ? foundReception["title"] : "제목 없음";
      const dateRegister = foundReception ? foundReception["dateRegister"] : "";
      return (<div key={index}>
        <Link to={`/receptions/${reception}`}>
          <RoundedButton style={{ boxShadow: "none" }}>
            {`${title} - ${dateRegister ? moment(dateRegister).format("YYYY년MM월DD일") : "날짜 없음"}`}
          </RoundedButton>
        </Link>
      </div>);
    });
    const consultLink = inspection.consults.map((consultId, index) => {
      return (<div key={`${consultId}${index}`}>
        <Link to={`/consulting/projects/${consultId}`}>
          <RoundedButton style={{ boxShadow: "none" }}>
            {consultId}
          </RoundedButton>
        </Link>
      </div>);
    });

    return (
      <Wrapper>
        <DefaultRow>
          <LeftColumn>
            <GreyLabel>프로젝트 명</GreyLabel>
          </LeftColumn>
          <ContentColumn>
            <LargeText>{ inspection.title }</LargeText>
          </ContentColumn>
        </DefaultRow>
        <DefaultRow>
          <LeftColumn>
            <GreyLabel>클라이언트</GreyLabel>
          </LeftColumn>
          <ContentColumn>
            <Link to={ `/clients/${inspection.client}` } target="_blank">
              {inspection.clientName}
            </Link>
            <LargeText>{ inspection.requesterName }</LargeText>
            <NormalText>{ inspection.requesterEmail }</NormalText>
          </ContentColumn>
        </DefaultRow>
        <DefaultRow>
          <LeftColumn>
            <GreyLabel>플랜 담당자</GreyLabel>
          </LeftColumn>
          <ContentColumn>
            <LargeText>{ inspection.inspectorName }</LargeText>
            <NormalText>{ inspection.inspectorEmail }</NormalText>
          </ContentColumn>
        </DefaultRow>

        <DividerLine/>

        

        <Section>
          <GreyLabel
            style={ {cursor: 'pointer', marginBottom: '15px'}}
            onClick={ () => this.isReviewOpen = !this.isReviewOpen }
          >
            <Icon
              iconSize={ 20 }
              icon={ this.isReviewOpen ? 'chevron-down' : 'chevron-right' }
              style={ { marginRight: '10px' }} />
            플랜 설계
          </GreyLabel>
          <Collapse isOpen={ this.isReviewOpen }>
            <ReviewText>
              <ConvertLinkTextFormat>{inspection.review}</ConvertLinkTextFormat>
            </ReviewText>
          </Collapse>
        </Section>

        <Section>
          <GreyLabel
            style={ {cursor: 'pointer', marginBottom: '25px'}}
            onClick={ () => this.isResourceOpen = !this.isResourceOpen }
          >
            <Icon
              iconSize={ 20 }
              icon={ this.isResourceOpen ? 'chevron-down' : 'chevron-right' }
              style={ { marginRight: '10px' }} />
            리소스 구성
          </GreyLabel>
          <Collapse isOpen={ this.isResourceOpen }>
            <FlexRow
              style={{
                marginBottom: '15px',
                borderBottom: '1px solid #666666',
                paddingBottom: '15px'
              }}>
              <ScheduleIcon />
              <ScheduleBrief
                className='schedule'
                days={ inspection.days } />
            </FlexRow>
            <PlanReadSection
                inspection={ inspection }
                showVATToggle={ true }
                showAllPaymentDetail={ false } />
            
            <Section
              style={{ width: '705px' }}
            >
              <div className='title'>시소 서비스</div>
              { this.renderServiceExplanation() }
            </Section>
          </Collapse>
        </Section>

        <FlexRow>
          <ContentColumn>
            {!hideButtons && (
              <ReceptionsSection
                style={{ marginTop: '20px' }}
              >
                <GreyLabel>의뢰서</GreyLabel>
                <ReferenceLinksRow>
                  {receptionLink}
                </ReferenceLinksRow>
              </ReceptionsSection>
            )}
            {!hideButtons && (
              <ReceptionsSection>
                <GreyLabel>영업상담</GreyLabel>
                <ReferenceLinksRow>
                  {consultLink}
                </ReferenceLinksRow>
              </ReceptionsSection>
            )}

            {!hideButtons && (
              <ResourcesSection>
                <GreyLabel>관련자료</GreyLabel>
                <ReferenceLinksRow>
                  {inspection.references.map((reference, index) => {
                      const link: string = reference.link.toString().includes("http") ?
                        reference.link : `https://${reference.link}`;
                      let title: string = `${reference.comment} - ${link}`;
                      title = title.length < 60 ? title : `${title.substring(0, 60)}...`;

                      return (<div key={index}>
                        <a target="_blank" href={link}>
                          <RoundedButton
                            style={{
                              boxShadow: "none"
                            }}
                          >{title}</RoundedButton>
                        </a>
                      </div>);
                    }
                  )}

                  {inspection.inspection_files.map((inspection_file, index) => {
                      const link: string = inspection_file.file_url.toString().includes("http") ?
                        inspection_file.file_url : `https://${inspection_file.file_url}`;
                      const fileName = inspection_file.file_name.replace('inspection/','')
                      let title: string = `${fileName} - ${link}`;
                      title = title.length < 60 ? title : `${title.substring(0, 60)}...`

                      return (<div key={index}>
                        <a target="_blank" href={link}>
                          <RoundedButton
                            style={{
                              boxShadow: "none"
                            }}
                          >{ title }</RoundedButton>
                        </a>
                      </div>);
                    }
                  )}
                </ReferenceLinksRow>
              </ResourcesSection>
            )}

            {
              inspection.attention &&
              <AttentionSection>
                <GreyLabel>매니징시 주의사항</GreyLabel>
                <LabelPadding/>
                <NormalText style={{ whiteSpace: "pre-line" }}>
                  <ConvertLinkTextFormat>
                    {inspection.attention}
                  </ConvertLinkTextFormat>
                </NormalText>
              </AttentionSection>
            }
          </ContentColumn>
        </FlexRow>
        <InspectionHistory>
          <div>
            <H3 style={{ float: "left" }}>플랜 관리 History</H3>
            <div style={{ float: "right" }}>
              <Checkbox
                checked={!this.publishedOnly}
                onChange={async () => {
                  this.publishedOnly = !this.publishedOnly;
                  await this.injected.appStore.inspectionStore.fetchInspectionById(
                    inspection.inspectionId,
                    this.publishedOnly
                  );
                }}
                label="검수서 수정내역 ON"
              />
            </div>
            <div style={{ float: "right", marginRight: "10px" }}>
              <Checkbox
                checked={this.vatIncludedHistory}
                onChange={() => {
                  this.vatIncludedHistory = !this.vatIncludedHistory;
                }}
                label="VAT 포함"
              />
            </div>
            <div style={{ clear: "right" }}></div>
          </div>
          <InspectionHistoryBody>
            <HTMLTable
              bordered={true}
              interactive={true}
              style={{ width: "100%", tableLayout: "fixed", margin: "auto", textAlign: "center" }}
            >
              <thead>
              <tr>
                <th>번호</th>
                <th>변경사유</th>
                <th>예상비용({this.vatIncludedHistory ? "VAT 포함" : "VAT 미포함"})</th>
                <th>수정일</th>
                <th>작성자</th>
                <th>PDF</th>
              </tr>
              </thead>
              <tbody>
              {inspection.historyList.filter((history) => this.publishedOnly ? history.published_date : true).map((history) => {
                let history_date = history.history_date ? moment(history.history_date).format("YYYY년 MM월 DD일") : "";
                let published = history.published_date ? <div style={{ color: "blue" }}>(발행)</div> : null;

                return (
                  <tr>
                    <td>{history.history_id}</td>
                    <td>{history.history_change_reason}</td>
                    <td>{
                      this.vatIncludedHistory ?
                        (Math.floor(history.budget * 1.1)).toLocaleString("en-US") :
                        (Math.floor(history.budget)).toLocaleString("en-US")
                    }원
                    </td>
                    <td>
                      {history_date}
                      {published}
                    </td>
                    <td>{history.history_user}</td>
                    <td>
                      <Button small={true} intent={Intent.SUCCESS} text={"PDF 다운로드"}
                              onClick={(e: MouseEvent) => {
                                this.handleHistoryPdfDownload(history.history_id, history.history_date ? history.history_date : "", this.vatIncludedHistory);
                              }}/>
                    </td>
                  </tr>
                );
              })}
              </tbody>
            </HTMLTable>

          </InspectionHistoryBody>
        </InspectionHistory>

        <Dialog
          isOpen={this.isSprintPopupOpen}
          onClose={this.handleSprintPopupClose}
          title="스프린트당 총 비용"
          usePortal={true}
          autoFocus={true}
          canEscapeKeyClose={true}
          canOutsideClickClose={true}
          isCloseButtonShown={true}
          enforceFocus={true}
        >
          <div className={Classes.DIALOG_BODY}>
            <FormGroup label={<strong>VAT 미포함</strong>}>
              {Math.floor((this.totalCostOfSprint * 10) / 11).toLocaleString(
                "en-US"
              )}
            </FormGroup>

            <FormGroup label={<strong>VAT 포함</strong>}>
              {this.totalCostOfSprint.toLocaleString("en-US")} (VAT포함)
            </FormGroup>
          </div>
        </Dialog>

        <Dialog
          isOpen={this.downloadLoadingOpen}
          onClose={() => {
            this.downloadLoadingPercent = 0;
          }}
          isCloseButtonShown={false}
          autoFocus={true}
          enforceFocus={true}
        >
          <div className={Classes.DIALOG_BODY}>
            <ProgressBar animate={true} intent={Intent.PRIMARY} value={this.downloadLoadingPercent}/>
          </div>
        </Dialog>
      </Wrapper>
    );
  }

  private handleHistoryPdfDownload = async (history_id: number, historyDate: string, vatIncluded: boolean) => {
    const { inspection } = this.props;

    if (!inspection) {
      AppToaster.show({
        message: "검수서가 존재하지 않습니다.",
        intent: Intent.DANGER
      });

      return;
    }
    this.downloadLoadingOpen = true;
    this.downloadLoadingPercent = 0.1;
    const interval = setInterval(() => {
      this.downloadLoadingPercent += Math.random() * (0.1 - 0.01) + 0.01;
    }, 500);
    await this.injected.appStore.inspectionStore.downloadPDF(
      inspection, undefined, vatIncluded,
      history_id, historyDate
    );
    clearInterval(interval);
    this.downloadLoadingPercent = 1;
    this.downloadLoadingOpen = false;
  };
}

export default InspectionCard;