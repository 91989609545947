import { types, flow, IMSTArray, ISimpleType } from "mobx-state-tree";
import axios from "axios";
import moment from "moment";
import { RiskResponse, Affinity } from "../../types/risk";
import { ProjectResponse } from "../../types/project";
import { ConsultResponse } from "../../types/consult";
import { ClientReviewResponse } from "../../types/clientReview";
import {
  ConsultFunctionalityFormModel,
  ConsultFunctionalityForm
} from "./ProjectForm/ConsultFunctionalityForm";
import {
  ConsultAdminTypeFormModel,
  ConsultAdminTypeForm
} from "./ProjectForm/ConsultAdminTypeForm";
import {
  FunctionalityTexts,
  ConsultFunctionality
} from "../models/ConsultFunctionality";
import { AdminTypeTexts } from "../models/ConsultAdminType";
import { calculateGradientDirection } from "html2canvas/dist/types/css/types/functions/gradient";

const affinityEnum = types.enumeration([
  Affinity.High,
  Affinity.Middle,
  Affinity.Low
]);

const webTypeEnum = types.enumeration(["분리형", "반응형"]);

export const CreateProjectFormModel = types
  .model("CreateProjectForm", {
    projectId: types.optional(types.string, ""),
    name: types.optional(types.string, ""),
    consultedDate: types.maybeNull(types.optional(types.string, "")),
    consultant: types.maybeNull(types.number),
    clientName: types.optional(types.string, ""),
    clientEmail: types.optional(types.string, ""),
    clientPhone: types.optional(types.string, ""),

    // Risks
    humanRisks: types.array(types.string),
    slackAffinity: types.optional(affinityEnum, Affinity.High),
    trelloAffinity: types.optional(affinityEnum, Affinity.High),
    onlineMeetingAffinity: types.optional(affinityEnum, Affinity.High),
    openDate: types.maybeNull(types.string),

    // Consult
    projectTypes: types.array(types.string),
    extraProjectType: types.optional(types.string, ""),

    workingTypes: types.array(types.string),
    extraWorkingType: types.optional(types.string, ""),

    webType: types.optional(webTypeEnum, "분리형"),
    appTypes: types.array(types.string),
    extraAppType: types.optional(types.string, ""),

    functionalities: types.array(ConsultFunctionalityFormModel),
    adminTypes: types.array(ConsultAdminTypeFormModel),
    memo: types.optional(types.string, ""),
    devEnvironmentMemo: types.optional(types.string, ""),

    // Client Review
    clientHumanRisks: types.array(types.string),
    contractRisks: types.array(types.string),
    referenceRisks: types.array(types.string),
    projectRisks: types.array(types.string),

    receptionId: types.optional(types.string, ""),
  })
  .views(self => ({
    isHumanRiskChecked(key: string) {
      return !!self.humanRisks.find(x => x === key);
    },
    isProjectTypeChecked(key: string) {
      return !!self.projectTypes.find(x => x === key);
    },
    isWorkingTypeChecked(key: string) {
      return !!self.workingTypes.find(x => x === key);
    },
    isAppTypeChecked(key: string) {
      return !!self.appTypes.find(x => x === key);
    },

    isClientHumanRiskChecked(key: string) {
      return !!self.clientHumanRisks.includes(key);
    },
    isContractRiskChecked(key: string) {
      return self.contractRisks.includes(key);
    },
    isReferenceRiskChecked(key: string) {
      return self.referenceRisks.includes(key);
    },
    isProjectRiskChecked(key: string) {
      return self.projectRisks.includes(key);
    }
  }))
  .actions(self => ({
    toggleArrayItem(array: IMSTArray<ISimpleType<string>>, key: string) {
      const hasItem = !!array.find(x => x === key);

      if (hasItem) {
        array.remove(key);
      } else {
        array.push(key);
      }
    }
  }))
  .actions(self => ({
    // Project
    setProjectId(value: string) {
      self.projectId = value;
    },
    setName(value: string) {
      self.name = value;
    },
    setConsultedDate(value: string) {
      self.consultedDate = value;
    },
    setConsultant(value: number | null) {
      self.consultant = value;
    },
    setClientName(value: string) {
      self.clientName = value;
    },
    setClientEmail(value: string) {
      self.clientEmail = value;
    },
    setClientPhone(value: string) {
      self.clientPhone = value;
    },

    // Risk
    toggleHumanRisk(key: string) {
      self.toggleArrayItem(self.humanRisks, key);
    },
    setSlackAffinity(value: Affinity) {
      self.slackAffinity = value;
    },
    setTrelloAffinity(value: Affinity) {
      self.trelloAffinity = value;
    },
    setOnlineMeetingAffinity(value: Affinity) {
      self.onlineMeetingAffinity = value;
    },
    setOpenDate(value: string | null) {
      self.openDate = value;
    },

    // Consult
    toggleProjectType(key: string) {
      self.toggleArrayItem(self.projectTypes, key);
    },
    setExtraProjectType(value: string) {
      self.extraProjectType = value;
    },
    toggleWorkingType(key: string) {
      self.toggleArrayItem(self.workingTypes, key);
    },
    setExtraWorkingType(value: string) {
      self.extraWorkingType = value;
    },
    setWebType(value: "분리형" | "반응형") {
      self.webType = value;
    },
    toggleAppType(key: string) {
      self.toggleArrayItem(self.appTypes, key);
    },
    setExtraAppType(value: string) {
      self.extraAppType = value;
    },
    setMemo(value: string) {
      self.memo = value;
    },
    setDevEnvironmentMemo(value: string) {
      self.devEnvironmentMemo = value;
    },
    setReceptionId(value: string) {
      self.receptionId = value;
    },
    addFunctionality() {
      self.functionalities.push(
        ConsultFunctionalityFormModel.create({ etc: true, checked: true })
      );
    },
    addAdminType() {
      self.adminTypes.push(
        ConsultAdminTypeFormModel.create({ etc: true, checked: true })
      );
    },
    removeFunctionality(item: ConsultFunctionalityForm) {
      self.functionalities.remove(item);
    },
    removeAdminType(item: ConsultAdminTypeForm) {
      self.adminTypes.remove(item);
    },

    // Client Review
    toggleClientHumanRisk(key: string) {
      self.toggleArrayItem(self.clientHumanRisks, key);
    },
    toggleContractRisk(key: string) {
      self.toggleArrayItem(self.contractRisks, key);
    },
    toggleReferenceRisks(key: string) {
      self.toggleArrayItem(self.referenceRisks, key);
    },
    toggleProjectRisks(key: string) {
      self.toggleArrayItem(self.projectRisks, key);
    }
  }));

type CreateProjectFormType = typeof CreateProjectFormModel.Type;

export interface CreateProjectForm extends CreateProjectFormType {
}

export const CreateProjectFormStoreModel = types
  .model("CreateProjectFormStore", {
    currentForm: types.optional(CreateProjectFormModel, {}),
    newFormWithParams: types.optional(types.boolean, false)
  })
  .views(self => ({
    get isNewFormWithParams() {
      return self.newFormWithParams;
    }
  }))
  .actions(self => {
    const consumeParams = () => {
      self.newFormWithParams = false;
    };
    const initNewFormWithParams = (item: any) => {
      self.newFormWithParams = true;
      self.currentForm = CreateProjectFormModel.create({
        consultedDate: new Date().toISOString(),
        clientName: item.clientName,
        clientEmail: item.clientEmail,
        clientPhone: item.clientPhone,
        memo: item.memo,
        name: item.name,
        consultant: item.consultant, // 박병규 id
        openDate: new Date().toISOString(),
        functionalities: FunctionalityTexts.map(x => ({
          etc: false,
          checked: false,
          text: x.key
        })),
        adminTypes: AdminTypeTexts.map(x => ({
          etc: false,
          checked: false,
          text: x.key
        })),
        receptionId: item.receptionId
      });
    };

    const initForm = () => {
      self.currentForm = CreateProjectFormModel.create({
        consultedDate: new Date().toISOString(),
        consultant: 4, // 박병규 id
        openDate: new Date().toISOString(),
        functionalities: FunctionalityTexts.map(x => ({
          etc: false,
          checked: false,
          text: x.key
        })),
        adminTypes: AdminTypeTexts.map(x => ({
          etc: false,
          checked: false,
          text: x.key
        }))
      });
    };
    const setForm = (val:any) =>{
      self.currentForm = val;
    };
    const postProject = flow(function* () {
      const form = self.currentForm;

      try {
        const { data: project }: { data: ProjectResponse } = yield axios.post(
          `/projects`,
          {
            name: form.name,
            consulted_date: form.consultedDate,
            consultant: form.consultant,
            client_name: form.clientName,
            client_email: form.clientEmail,
            client_phone: form.clientPhone,
            reception_id: form.receptionId
          }
        );

        const projectId = project.project_id;
        form.setProjectId(projectId);

        const { data: risk }: { data: RiskResponse } = yield axios.post(
          `/projects/${projectId}/risk`,
          {
            human_risks: form.humanRisks,
            slack_affinity: form.slackAffinity,
            trello_affinity: form.trelloAffinity,
            online_meeting_affinity: form.onlineMeetingAffinity,
            open_date:
              form.openDate && moment(form.openDate).format("YYYY-MM-DD")
          }
        );

        const { data: consult }: { data: ConsultResponse } = yield axios.post(
          `/projects/${projectId}/consult`,
          {
            project_types: form.extraProjectType
              ? [...form.projectTypes, form.extraProjectType]
              : form.projectTypes,
            working_types: form.extraWorkingType
              ? [...form.workingTypes, form.extraWorkingType]
              : form.workingTypes,
            web_type: form.webType,
            app_types: form.extraAppType
              ? [...form.appTypes, form.extraAppType]
              : form.appTypes,
            functionalities: form.functionalities.filter(x => !!x.text),
            admin_types: form.adminTypes.filter(x => !!x.text),
            memo: form.memo
          }
        );

        const {
          data: clientReview
        }: { data: ClientReviewResponse } = yield axios.post(
          `/projects/${projectId}/clientReview`,
          {
            human_risks: form.clientHumanRisks,
            contract_risks: form.contractRisks,
            reference_risks: form.referenceRisks,
            project_risks: form.projectRisks
          }
        );

        return {
          ...project,
          ...risk,
          ...consult,
          ...clientReview
        };
      } catch (e) {
        console.log("createProject error", e);
        throw e;
      }
    });

    const patchProject = flow(function* () {
      const form = self.currentForm;
      const projectId = form.projectId;

      try {
        const { data: project }: { data: ProjectResponse } = yield axios.patch(
          `/projects/${projectId}`,
          {
            name: form.name,
            consulted_date: form.consultedDate,
            consultant: form.consultant,
            client_name: form.clientName,
            client_email: form.clientEmail,
            client_phone: form.clientPhone,
            reception_id: form.receptionId
          }
        );

        const { data: risk }: { data: RiskResponse } = yield axios.patch(
          `/projects/${projectId}/risk`,
          {
            human_risks: form.humanRisks,
            slack_affinity: form.slackAffinity,
            trello_affinity: form.trelloAffinity,
            online_meeting_affinity: form.onlineMeetingAffinity,
            open_date:
              form.openDate && moment(form.openDate).format("YYYY-MM-DD")
          }
        );

        const { data: consult }: { data: ConsultResponse } = yield axios.patch(
          `/projects/${projectId}/consult`,
          {
            project_types: form.extraProjectType
              ? [...form.projectTypes, form.extraProjectType]
              : form.projectTypes,
            working_types: form.extraWorkingType
              ? [...form.workingTypes, form.extraWorkingType]
              : form.workingTypes,
            web_type: form.webType,
            app_types: form.extraAppType
              ? [...form.appTypes, form.extraAppType]
              : form.appTypes,
            functionalities: form.functionalities.filter(x => !!x.text),
            admin_types: form.adminTypes.filter(x => !!x.text),
            memo: form.memo,
            dev_environment_memo: form.devEnvironmentMemo
          }
        );

        const {
          data: clientReview
        }: { data: ClientReviewResponse } = yield axios.patch(
          `/projects/${projectId}/clientReview`,
          {
            human_risks: form.clientHumanRisks,
            contract_risks: form.contractRisks,
            reference_risks: form.referenceRisks,
            project_risks: form.projectRisks
          }
        );

        return {
          ...project,
          ...risk,
          ...consult,
          ...clientReview
        };
      } catch (e) {
        console.log("createProject error", e);
        throw e;
      }
    });

    const fetchProject = flow(function* (projectId: number) {
      try {
        const { data: project }: { data: ProjectResponse } = yield axios.get(
          `/projects/${projectId}`
        );
        const { data: risk }: { data: RiskResponse } = yield axios.get(
          `/projects/${projectId}/risk`
        );
        const { data: consult }: { data: ConsultResponse } = yield axios.get(
          `/projects/${projectId}/consult`
        );
        const {
          data: clientReview
        }: { data: ClientReviewResponse } = yield axios.get(
          `/projects/${projectId}/clientReview`
        );

        let projectTypes: string[] = [];
        let extraProjectType: string = "";

        let workingTypes: string[] = [];
        let extraWorkingType: string = "";

        let appTypes: string[] = [];
        let extraAppType: string = "";

        consult.project_types.forEach(x => {
          if (x === "web" || x === "app" || x === "etc") {
            projectTypes.push(x);
          } else {
            extraProjectType = x;
          }
        });

        consult.working_types.forEach(x => {
          if (
            x === "planning" ||
            x === "design" ||
            x === "dev" ||
            x === "etc"
          ) {
            workingTypes.push(x);
          } else {
            extraWorkingType = x;
          }
        });

        consult.app_types.forEach(x => {
          if (
            x === "ios" ||
            x === "aos" ||
            x === "device-pad" ||
            x === "device-phone" ||
            x === "device-etc" ||
            x === "native" ||
            x === "react-native"
          ) {
            appTypes.push(x);
          } else {
            extraAppType = x;
          }
        });

        const functionalitiesMap: Map<string,
          ConsultFunctionalityForm> = new Map();
        const adminTypesMap: Map<string, ConsultAdminTypeForm> = new Map();

        FunctionalityTexts.forEach(set => {
          functionalitiesMap.set(
            set.key,
            ConsultFunctionalityFormModel.create({
              etc: false,
              checked: false,
              text: set.key
            })
          );
        });

        consult.functionalities.forEach(functionality => {
          functionalitiesMap.set(
            functionality.text,
            ConsultFunctionalityFormModel.create(functionality)
          );
        });

        AdminTypeTexts.forEach(set => {
          adminTypesMap.set(
            set.key,
            ConsultAdminTypeFormModel.create({
              etc: false,
              checked: false,
              text: set.key
            })
          );
        });

        consult.admin_types.forEach(adminType => {
          adminTypesMap.set(
            adminType.text,
            ConsultAdminTypeFormModel.create(adminType)
          );
        });

        const newForm = CreateProjectFormModel.create({
          projectId: project.project_id,
          name: project.name,
          consultedDate: project.consulted_date,
          consultant: project.consultant,
          clientName: project.client_name,
          clientEmail: project.client_email,
          clientPhone: project.client_phone,
          receptionId: project.reception_id,

          // Risk
          humanRisks: risk.human_risks,
          slackAffinity: risk.slack_affinity,
          trelloAffinity: risk.trello_affinity,
          onlineMeetingAffinity: risk.online_meeting_affinity,
          openDate: risk.open_date,

          // Consult
          projectTypes,
          extraProjectType,
          workingTypes,
          extraWorkingType,
          webType: consult.web_type,
          appTypes,
          extraAppType,
          functionalities: Array.from(functionalitiesMap.values()),
          adminTypes: Array.from(adminTypesMap.values()),
          memo: consult.memo,
          devEnvironmentMemo: consult.dev_environment_memo,

          // Client Review
          clientHumanRisks: clientReview.human_risks,
          contractRisks: clientReview.contract_risks,
          referenceRisks: clientReview.reference_risks,
          projectRisks: clientReview.project_risks
        });

        self.currentForm = newForm;
      } catch (e) {
        console.log("fetchProject in CreateProjectForm error", e);
        throw e;
      }
    });

    return {
      initForm,
      setForm,
      postProject,
      patchProject,
      fetchProject,
      consumeParams,
      initNewFormWithParams
    };
  });
