import { types } from "mobx-state-tree";
import { ProjectModel } from "./Project";

export const RiskModel = types.model("Risk", {
  id: types.identifierNumber,
  createdAt: types.string,
  updatedAt: types.string,
  humanRisks: types.array(types.string),
  slackAffinity: types.enumeration(["상", "중", "하"]),
  trelloAffinity: types.enumeration(["상", "중", "하"]),
  onlineMeetingAffinity: types.enumeration(["상", "중", "하"]),
  openDate: types.maybeNull(types.string),
  project: types.reference(ProjectModel)
});

type RiskType = typeof RiskModel.Type;
export interface Risk extends RiskType {}
