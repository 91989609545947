import React, { Component, ChangeEvent } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { InspectionForm } from "../../../store/forms/InspectionForm";
import { computed, observable } from "mobx";
import { InputGroup, Checkbox, Button, Intent, HTMLSelect } from "@blueprintjs/core";
import { MUTED_GRAY } from "../../../constants/colors";
import { MAX_SPRINTS } from "../../../constants/constants";
import RufreeRoleSelection from "../../molecules/RufreeRoleSelection/RufreeRoleSelection";
import InputWithFormat, {
  KoreanCurrencyOptions
} from "../../atoms/InputWithFormat/InputWithFormat";

const sprintArray = Array(MAX_SPRINTS).fill(null);

const Wrapper = styled.div``;

const Row = styled.div`
  margin: 5px 0;
  position: relative;
`;

export const LeftColumn = styled.div`
  display: inline-block;
  width: 270px;
`;

export const InputColumn = styled.div`
  box-sizing: border-box;
  display: inline-block;
  width: 115px;
  padding-right: 5px;
  vertical-align: top;
`;

const SeesoInputColumn = styled(InputColumn)`
  width: 108px;
`;

const IndividualColumn = styled(InputColumn)`
  width: 52px;
  text-align: center;
`;

export const SprintColumn = styled.div`
  display: inline-flex;
  width: 30px;
  min-width: 30px;
  margin: 0 3px;
  font-weight: 500;
  justify-content: center;

  & > label {
    margin-left: 10px;
  }

  .bp3-input {
    padding: 0 6px !important;
  }
`;

export const SectionLabel = styled.span`
  font-weight: bold;
  color: ${MUTED_GRAY};
`;

const Padding = styled.div`
  height: 30px;
`;

const RemoveButtonContainer = styled.div`
  position: absolute;
  left: -30px;
  top: 0px;
`;

export const renderSprintNums = (onClick?: (sprintIndex: number) => void) => {
  return sprintArray.map((_, index) => (
    <SprintColumn key={index}>
      {onClick ? (
        <a onClick={() => onClick(index)} style={{ color: "#000" }}>
          {index === 0 ? "PRE" : `S${index}`}
        </a>
      ) : index === 0 ? (
        "PRE"
      ) : (
        `S${index}`
      )}
    </SprintColumn>
  ));
};

interface SprintTableClientProps {
  currentForm: InspectionForm;
}

interface InjectedProps extends SprintTableClientProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class SprintTableClient extends Component<SprintTableClientProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  render() {
    const {
      sprintDays,
      clientRoles,
      rufreeRoles,
      seesoServices,
      addClientRole,
      addRufreeRole,
      addSeesoService,
      removeClientRole,
      removeRufreeRole,
      removeSeesoService
    } = this.props.currentForm;

    return (
      <Wrapper>
        <Row>
          <LeftColumn>
            <SectionLabel>클라이언트</SectionLabel>
          </LeftColumn>
          {sprintDays.map((sprintDay, index) => {
            if(index == 0){
              return <SprintColumn key={index}></SprintColumn>
            }
            return (
              <SprintColumn key={index}>
                {/*<InputGroup
                small={true}
                value={sprintDay.value + ""}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  sprintDay.setValue(+e.target.value)
                }
              />*/}
                <HTMLSelect style={{ width: "auto" }} value={sprintDay.value} onChange={(event) => {
                  sprintDay.setValue(Number(event.target.value));
                }} iconProps={undefined}>
                  <option value={14}>1</option>
                  <option value={7}>1/2</option>
                </HTMLSelect>
              </SprintColumn>
            );
          })}
        </Row>
        <Row>
          <LeftColumn>
            <InputColumn>
              <strong>역할</strong>
            </InputColumn>
          </LeftColumn>
          {renderSprintNums()}
        </Row>
        {clientRoles.map((clientRole, index) => (
          <Row key={index}>
            <RemoveButtonContainer>
              <Button
                icon="minus"
                minimal={true}
                small={true}
                intent={Intent.DANGER}
                onClick={() => removeClientRole(clientRole)}
              />
            </RemoveButtonContainer>
            <LeftColumn>
              <InputColumn>
                <InputGroup
                  value={clientRole.role}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    clientRole.setRole(e.target.value)
                  }
                />
              </InputColumn>
            </LeftColumn>
            {clientRole.sprints.map((sprint, sprintIndex) => (
              <SprintColumn key={sprintIndex}>
                <Checkbox
                  checked={sprint.value}
                  onChange={() => sprint.setValue(!sprint.value)}
                />
              </SprintColumn>
            ))}
          </Row>
        ))}
        <Row>
          <Button icon="add" text="추가" onClick={addClientRole} />
        </Row>

        <Padding />

        <Row>
          <LeftColumn>
            <SectionLabel>알유프리</SectionLabel>
          </LeftColumn>
        </Row>
        <Row>
          <LeftColumn>
            <InputColumn>
              <strong>역할</strong>
            </InputColumn>
            <InputColumn>
              <strong>임금/S</strong>
            </InputColumn>
          </LeftColumn>
          {renderSprintNums()}
        </Row>
        {rufreeRoles.map((rufreeRole, index) => (
          <Row key={index}>
            <RemoveButtonContainer>
              <Button
                icon="minus"
                minimal={true}
                small={true}
                intent={Intent.DANGER}
                onClick={() => removeRufreeRole(rufreeRole)}
              />
            </RemoveButtonContainer>
            <LeftColumn>
              <InputColumn>
                <RufreeRoleSelection
                  onChange={(item: string) => rufreeRole.setRole(item)}
                  currentValue={rufreeRole.role}
                />
                {rufreeRole.role === "기타" && (
                  <InputGroup
                    style={{ marginTop: "4px" }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      rufreeRole.setRoleTextBufferForEtc(e.target.value)
                    }
                    value={rufreeRole.roleTextBufferForETC}
                  />
                )}
              </InputColumn>
              <InputColumn>
                <InputWithFormat
                  value={rufreeRole.wage}
                  options={KoreanCurrencyOptions}
                  onChange={(e: any) => {
                    const withoutPrefix = e.target.rawValue.substr(2);
                    rufreeRole.setWage(parseInt(withoutPrefix, 10) || 0);
                  }}
                />
              </InputColumn>
            </LeftColumn>
            {rufreeRole.sprints.map((sprint, sprintIndex) => (
              <SprintColumn key={sprintIndex}>
                <Checkbox
                  checked={sprint.value}
                  onChange={() => sprint.setValue(!sprint.value)}
                />
              </SprintColumn>
            ))}
          </Row>
        ))}
        <Row>
          <Button icon="add" text="추가" onClick={addRufreeRole} />
        </Row>

        <Padding />

        <Row>
          <LeftColumn>
            <SectionLabel>시소서비스</SectionLabel>
          </LeftColumn>
        </Row>
        <Row>
          <LeftColumn>
            <SeesoInputColumn>
              <strong>항목</strong>
            </SeesoInputColumn>
            <SeesoInputColumn>
              <strong>비용/S</strong>
            </SeesoInputColumn>
            <IndividualColumn>
              <span>x인원수</span>
            </IndividualColumn>
          </LeftColumn>
          {renderSprintNums()}
        </Row>
        {seesoServices.map((seesoService, index) => (
          <Row key={index}>
            <RemoveButtonContainer>
              <Button
                icon="minus"
                minimal={true}
                small={true}
                intent={Intent.DANGER}
                onClick={() => removeSeesoService(seesoService)}
              />
            </RemoveButtonContainer>
            <LeftColumn>
              <SeesoInputColumn>
                <InputGroup
                  value={seesoService.service}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    seesoService.setService(e.target.value)
                  }
                />
              </SeesoInputColumn>
              <SeesoInputColumn>
                <InputWithFormat
                  value={seesoService.cost}
                  options={KoreanCurrencyOptions}
                  onChange={(e: any) => {
                    const withoutPrefix = e.target.rawValue.substr(2);
                    seesoService.setCost(parseInt(withoutPrefix, 10) || 0);
                  }}
                />
              </SeesoInputColumn>
              <IndividualColumn>
                <Checkbox
                  checked={seesoService.individual}
                  onChange={() =>
                    seesoService.setIndividual(!seesoService.individual)
                  }
                />
              </IndividualColumn>
            </LeftColumn>
            {seesoService.sprints.map((sprint, sprintIndex) => (
              <SprintColumn key={sprintIndex}>
                <Checkbox
                  checked={sprint.value}
                  onChange={() => sprint.setValue(!sprint.value)}
                />
              </SprintColumn>
            ))}
          </Row>
        ))}
        <Row>
          <Button icon="add" text="추가" onClick={addSeesoService} />
        </Row>
      </Wrapper>
    );
  }
}

export default SprintTableClient;
