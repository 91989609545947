import { types, flow, cast } from "mobx-state-tree";
import axios from "axios";

import { 
  RequestResponse, RequestListResponse, ReferenceFileResponse, ReferenceLinkResponse,
  TaskResponse, TaskListResponse, TaskDashboardResponse, OutcomeResponse
} from "../types/parttime";

import { RequestModel, TaskModel } from "./models/Parttime";

const mapToReferenceFile = (data: ReferenceFileResponse) => ({
    id: data.id,
    file: data.file,
    name: data.name,
    format: data.format,
    description: data.description
});

const mapToReferenceLink = (data: ReferenceLinkResponse) => ({
    id: data.id,
    url: data.url,
    description: data.description
});

const mapToRequest = (data: RequestResponse) => ({
    id: data.id,
    code: data.code,
    status: data.status,
    os: data.os,
    type: data.type,
    title: data.title,
    serviceName: data.service_name,
    asis: data.asis,
    tobe: data.tobe,
    accessInfo: data.access_info,
    cancelReason: data.cancel_reason,
    cancelConsultant: data.cancel_consultant,
    cancelDate: data.cancel_date,
    expectedDueDate: data.expected_due_date,
    referenceFiles: data.reference_files ? data.reference_files.map(mapToReferenceFile) : null,
    referenceLinks: data.reference_links ? data.reference_links.map(mapToReferenceLink) : null,
    client: {
      id: data.client.id,
      clientId: data.client.client_id ? data.client.client_id : '',
      name: data.client.name,
      email: data.client.email,
      phone: data.client.phone,
      affiliation: data.client.affiliation,
    },
    tasks: data.tasks,
    created: data.created,
    modified: data.modified,
});

export const ParttimeRequestStoreModel = types
  .model("RequestStore", {
      requests: types.array(RequestModel),
      
      currentPage: types.optional(types.number, 1),
      pageGroup: types.optional(types.number, 1),
      pageSize: types.optional(types.number, 20),
      totalPages: types.optional(types.number, 1)
  })
  .views(self => ({
  }))
  .actions(self => ({
    setCurentPage(value: number) {
      self.currentPage = value;
    },
    setPageGroup(value: number) {
      self.pageGroup = value;
    }
  }))
  .actions(self => {
    const fetchRequests = flow(function*({searchText, status, pageSize}: {
      searchText?: string;
      status?: string[];
      pageSize?: number;
    }) {
      try {
        const { data }: { data: RequestListResponse } = yield axios.get(
          "/parttime/requests",
          {
            params: {
              search: searchText? searchText : null,
              status: status ? status : 'SUBMITTED',
              page: self.currentPage,
              page_size: pageSize ? pageSize : self.pageSize
            }
          }
        );
        console.log(data);
        const requests = data.results.map(data =>
            RequestModel.create(mapToRequest(data))
        );

        self.requests.replace(requests);
      } catch (e) {
        console.log("fetchRequests error", e);
        throw e;
      }
    });

    const fetchRequestById = flow(function*(id: number) {
        const { data }: { data: RequestResponse } = yield axios.get(
          `/parttime/requests/${id}`
        );

        const request = RequestModel.create(mapToRequest(data));

        self.requests.replace([request]);
    });

    const restoreRequest = flow(function*(id: number) {
      try {
        yield axios.put(`/parttime/requests/${id}/restore`);
      } catch (e) {
        console.log("restoreRequest error", e);
        throw e;
      }
    });

    const deleteReferenceFile = flow(function*(requestId: number, referenceFileId: number) {
      try {
        yield axios.delete(`/parttime/requests/${requestId}/reference-files/${referenceFileId}`);   
      } catch (e) {
        throw e;
      }
    });

    const deleteReferenceFileDescription = flow(function*(requestId: number, referenceFileId: number) {
      try {
        yield axios.patch(`/parttime/requests/${requestId}/reference-files/${referenceFileId}`, {
          description: ""
        });   
      } catch (e) {
        throw e;
      }
    });

    const deleteReferenceLink = flow(function*(requestId: number, referenceLinkId: number) {
      try {
        yield axios.delete(`/parttime/requests/${requestId}/reference-links/${referenceLinkId}`);   
      } catch (e) {
        throw e;
      }
    });

    const deleteReferenceLinkDescription = flow(function*(requestId: number, referenceLinkId: number) {
      try {
        yield axios.patch(`/parttime/requests/${requestId}/reference-links/${referenceLinkId}`, {
          description: ""
        });   
      } catch (e) {
        throw e;
      }
    });

    return {
        fetchRequests,
        fetchRequestById,
        restoreRequest,
        deleteReferenceFile,
        deleteReferenceFileDescription,
        deleteReferenceLink,
        deleteReferenceLinkDescription
    };
  });
type ParttimeRequestStoreType = typeof ParttimeRequestStoreModel.Type;
export interface ParttimeRequestStore extends ParttimeRequestStoreType {}


const mapToOutcome= (data: OutcomeResponse) => ({
  id: data.id,
  file: data.file,
  name: data.name,
  format: data.format
});

export const mapToTask = (data: TaskResponse) => ({
  id: data.id,
  code: data.code,
  status: data.status,
  request: {
    id: data.request.id,
    code: data.request.code,
    status: data.request.status,
    serviceName: data.request.service_name,
    title: data.request.title,
    type: data.request.type,
    os: data.request.os,
    asis: data.request.asis,
    tobe: data.request.tobe,
    accessInfo: data.request.access_info,
    cancelReason: data.request.cancel_reason,
    cancelConsultant: data.request.cancel_consultant,
    cancelDate: data.request.cancel_date,
    expectedDueDate: data.request.expected_due_date,
    referenceFiles: data.request.reference_files,
    referenceLinks: data.request.reference_links,
    client: data.request.client ? {
      id: data.request.client.id,
      clientId: data.request.client.client_id,
      name: data.request.client.name,
      email: data.request.client.email,
      phone: data.request.client.phone,
      affiliation: data.request.client.affiliation,
    } : data.request.client,
    tasks: data.request.tasks,
    created: data.request.created,
    modified: data.request.modified,
  },
  serviceName: data.request.service_name,
  title: data.title,
  type: data.type,
  expectedDueDate: data.expected_due_date,
  estimatedDueDate: data.estimated_due_date,
  estimatedWorkingTime: data.estimated_working_time,
  description: data.description,
  memo: data.memo,
  criteria: data.criteria,
  client: data.client,
  clientName: data.client_name,
  rufree: data.rufree ? {
    rufreeId: data.rufree.rufree_id,
    name: data.rufree.name,
    email: data.rufree.email,
    phone: data.rufree.phone
  } : data.rufree,
  role: data.role,
  manager: data.manager,
  projectGroup: {
    groupId: data.project_group.group_id,
    name: data.project_group.name
  },
  totalAmount: parseInt(data.total_amount),
  paymentRemark: data.payment_remark,
  outcomes: data.outcomes ? data.outcomes.map(mapToOutcome) : null,
  created: data.created,
  modified: data.modified
});

export const ParttimeTaskStoreModel = types
  .model("TaskStore", {
      //  Task list
      tasks: types.array(TaskModel),
      tasksInSettlement: types.array(TaskModel),
      tasksInProgress: types.array(TaskModel),
      
      // Status
      created: types.optional(types.boolean, true),
      arranging: types.optional(types.boolean, true),
      matched: types.optional(types.boolean, true),
      inProgress: types.optional(types.boolean, true),
      review: types.optional(types.boolean, true),
      settlement: types.optional(types.boolean, false),
      completed: types.optional(types.boolean, false),
      calculated: types.optional(types.boolean, false),
      canceled: types.optional(types.boolean, false),
      status: types.optional(types.array(types.string), ['IN_PROGRESS']),
      // status: types.optional(types.array(types.string), ['CREATED', 'ARRANGING', 'MATCHED', 'IN_PROGRESS', 'REVIEW']),

      // Pagination
      currentPage: types.optional(types.number, 1),
      pageGroup: types.optional(types.number, 1),
      pageSize: types.optional(types.number, 20),
      totalPages: types.optional(types.number, 1)
  })
  .views(self => ({
  }))
  .actions(self => ({
    setStatus(key: string, value: boolean){
      switch (key) {
        case "CREATED":
          self.created = value;
          break;
        case "ARRANGING":
          self.arranging = value;
          break;
        case "MATCHED":
          self.matched = value;
          break;
        case "IN_PROGRESS":
          self.inProgress = value;
          break;
        case "REVIEW":
          self.review = value;
          break;
        case "SETTLEMENT":
          self.settlement = value;
          break;
        case "COMPLETED":
          self.completed = value;
          break;
        case "CALCULATED":
          self.calculated = value;
          break;
        case "CANCELED":
          self.canceled = value;
          break;
      }
      
      if(value == true){
            self.status.push(key);
      } else {
          let newStatus = self.status.filter(s => s !== key);
          self.status=cast(newStatus);
      }
    },
    setCurentPage(value: number) {
      self.currentPage = value;
    },
    setPageGroup(value: number) {
      self.pageGroup = value;
    },
    clearStatus() {
      self.created = false;
      self.arranging = false;
      self.matched = false;
      self.inProgress = false;
      self.review = false;
      self.settlement = false;
      self.completed = false;
      self.calculated = false;
      self.canceled = false;
      self.status = cast([]);
    },
    clear() {
      self.currentPage = 1;
      self.pageGroup = 1;
      self.created = false;
      self.arranging = false;
      self.matched = false;
      self.inProgress = true;
      self.review = false;
      self.settlement = false;
      self.completed = false;
      self.calculated = false;
      self.canceled = false;
      self.status = cast(['IN_PROGRESS']);
      // self.status = cast(['CREATED', 'ARRANGING', 'MATCHED', 'IN_PROGRESS', 'REVIEW']);
    }
  }))
  .actions(self => {
    const fetchTasks = flow(function*({searchText, status, pageSize}: {
      searchText?: string;
      status?: string[];
      pageSize?: number;
    }) {
      try {

        const { data }: { data: TaskListResponse } = yield axios.get(
          "/parttime/tasks",
          {
            params: {
              search: searchText? searchText : null,
              status: status? status : self.status,
              page: self.currentPage,
              page_size: pageSize ? pageSize : self.pageSize
            }
          }
        );

        const tasks = data.results.map(data =>
            TaskModel.create(mapToTask(data))
        );
        self.tasks.replace(tasks);
      } catch (e) {
        console.log("fetchTasks error", e);
        throw e;
      }
    });

    const fetchDashboardTasks = flow(function*() {
        try {
          const { data }: { data: TaskDashboardResponse }  = yield axios.get("/parttime/tasks/dashboard");

          const tasksInProgress = data.results.in_progress.map(data =>
            TaskModel.create(mapToTask(data))
          );

          const tasksInSettlement = data.results.in_settlement.map(data =>
            TaskModel.create(mapToTask(data))
          );

          self.tasksInProgress.replace(tasksInProgress);
          self.tasksInSettlement.replace(tasksInSettlement);
        } catch (e) {
          console.log("dashboardTasks error", e);
          throw e;
        }
    });

    const fetchTaskById =  flow(function*(requestId: number | string, taskId: number | string) {
      try {
        const { data }: { data: TaskResponse } = yield axios.get(
          `/parttime/requests/${requestId}/tasks/${taskId}`
        );
        const task = TaskModel.create(mapToTask(data));
        return task;
      } catch (e) {
        console.log("dashboardTasks error", e);
        throw e;
      }
  });

    const matchRufree = flow(function*(requestId: number, taskId: number, rufreeId: string, estimatedDueDate: string) {
      let data = {};

      if(estimatedDueDate){
        data = {
          rufree: rufreeId,
          estimated_due_date: estimatedDueDate
        };
      } else {
        data = {
          rufree: rufreeId
        };
      }

      try {
        yield axios.post(`/parttime/requests/${requestId}/tasks/${taskId}/match`, data);   
      } catch (e) {
        throw e;
      }
    });

    return {
        fetchTasks,
        fetchDashboardTasks,
        fetchTaskById,
        matchRufree
    };
  });
type ParttimeTaskStoreType = typeof ParttimeTaskStoreModel.Type;
export interface ParttimeTaskStore extends ParttimeTaskStoreType {}