import React, { Component, ChangeEvent } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { ContractComment } from "../../../store/models/ContractRequest";
import { observable } from "mobx";
import sortBy from "lodash/sortBy";
import {
  Button, H4, FormGroup, TextArea, Intent
} from "@blueprintjs/core";
import moment from "moment";

const Wrapper = styled.div`
  width: 100%;
`;
const Comment = styled.div`
  margin-bottom: 20px;
`;
const CommentDate = styled.div`
  font-weight: bold;
`;
const CommentContent = styled.div`
  white-space: pre-wrap
`;
const ButtonsRow = styled.div`
  margin-bottom: 50px;
  float: right;
  & > * + * {
    margin-left: 5px;
  }
`;
const EditText = styled.span`
  font-weight: bolder;
  font-size: 12px;
  margin-right: 5px;
  color: #000;
  float: right;
`;


interface ContractCommentsProps {
  comments: ContractComment[];
  onAddComment: (comment: string) => void;
  onDeleteComment: (id: number) => void;
  onUpdateComment: (id: number, comment: string) => void;
}

interface InjectedProps extends ContractCommentsProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ContractComments extends Component<ContractCommentsProps> {
  @observable edit_mode = false;
  @observable id = 0;
  @observable comment = "";

  get injected() {
    return this.props as InjectedProps;
  }

  handleCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.comment = e.currentTarget.value;
  };

  handleSaveClick = () => {
    if (this.edit_mode) {
      this.props.onUpdateComment(this.id, this.comment);
    } else {
      this.props.onAddComment(this.comment);
    }

    this.edit_mode = false;
    this.id = 0;
    this.comment = "";
  };

  async componentDidMount() {
  }

  render() {
    const { comments, onAddComment, onDeleteComment, onUpdateComment } = this.props;
    return (
      <Wrapper>
        <H4>코멘트</H4>

        {
          sortBy(comments.slice(), comment => comment.id)
            .map(comment => (
              <Comment key={`comment_${comment.id}`}>
                <CommentDate>
                  {moment(comment.createdAt).format("YYYY-MM-DD")} {comment.authorName}
                </CommentDate>
                <EditText onClick={
                  onDeleteComment.bind(this, comment.id)
                }>
                  삭제
                </EditText>

                <EditText onClick={
                  () => {
                    this.edit_mode = true;
                    this.id = comment.id;
                    this.comment = comment.content;
                  }
                }>
                  수정
                </EditText>


                <CommentContent>
                  {comment.content}
                </CommentContent>
              </Comment>
            ))
        }
        <FormGroup>
          <TextArea
            className={"bp3-fill"}
            value={this.comment}
            onChange={this.handleCommentChange}
          />
        </FormGroup>

        <ButtonsRow>
          <Button
            text="저장"
            intent={Intent.PRIMARY}
            onClick={this.handleSaveClick}
            disabled={this.comment ? false : true}
          />
        </ButtonsRow>
      </Wrapper>
    );
  }
}

export default ContractComments;
