import React, { Component, ChangeEvent } from 'react';
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import styled from 'styled-components/macro';
import moment from 'moment';
import { Collapse, Icon, Tag, HTMLSelect, Switch } from "@blueprintjs/core";

import { AppStore } from "../../../store/AppStore";
import ScheduleCalendar from "../../molecules/Dashboard/ScheduleCalendar";
import DashboardSchedulePopup from "../../molecules/Dashboard/DashboardSchedulePopup";

interface TagWrapperProps {
  color: string;
}

const Wrapper = styled.div`
`;
const HeaderWrapper = styled.div`
  display: flex;
  margin-bottom: 5px;
  span.bp3-icon {
    margin-top: 5px;
    margin-right: 10px;
  }
  span.bp3-tag {
    padding: 0 15px;
    height: 25px;
    cursor: pointer;
    width: 95px;
  }
  & + & { 
    margin-right: 5px;
  }
  justify-content: space-between;
`;
const LeftWrapper = styled.div`
`;
const RightWrapper = styled.div`
  display: flex;
`;
const TagWrapper = styled(Tag)<TagWrapperProps>`
  background-color: ${props => props.color};
`;
const SwitchWrapper = styled(Switch)`
  margin-bottom: 0px;
  margin-top: 10px;
  margin-right: 5px;
`;


interface InjectedProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class CalendarView extends Component {
  @observable readyToShow = false;
  @observable isOpen = false;
  @observable onlyMySchedules = false;
  @observable viewType = 'all';

  @observable isSchedulePopupOpen = false;

  get injected() {
    return this.props as InjectedProps;
  }

  async componentDidMount() {
    this.readyToShow = false;

    let firstDate = moment().date(1).day(0);
    let lastDate = moment().date(moment().daysInMonth()).day(6);

    await this.refreshEvents(firstDate.format("YYYY-MM-DD"), lastDate.format("YYYY-MM-DD"))
    await this.injected.appStore.scheduleManagerStore.fetchWeekdayHolidays();

    this.readyToShow = true;
  }

  refreshEvents = async (startDate: string, endDate: string) => {
    await this.injected.appStore.scheduleManagerStore.fetchDashboardCalendarSeesoSchedules(startDate, endDate);
    await this.injected.appStore.scheduleManagerStore.fetchDashboardCalendarGoogleSchedules(startDate, endDate);
  }

  handlePopupClose = async () => {
    await this.injected.appStore.scheduleManagerStore.fetchDashBoardSeesoSchedules();
    await this.injected.appStore.scheduleManagerStore.refreshDashboardCalendarSeesoSchedules();
  }

  getEventObject = (schedule:any, title: string, startAt: any, endAt: any, allDay: boolean) => {
    return {
      id: schedule.id,
      category: schedule.category,
      schCategory: schedule.schCategory,
      title: title,
      start: startAt.toDate(),
      end: endAt ? endAt.toDate() : startAt.toDate(),
      allDay: allDay,
      htmlLink: ''
    }    
  }

  isWorkDay = (date: any) => {
    // 공휴일 정보를 가져옴
    const weekdayHolidays = this.injected.appStore.scheduleManagerStore.weekdayHolidays.map(x => x.date);
    return date.isoWeekday() < 6 && !(weekdayHolidays.includes(date.format("YYYY-MM-DD")));
  }

  render_pm_name_by_userid(x: any) {
    let pm = this.injected.appStore.pmUserStore.pmByUserId(x.creator);
    return pm ? pm.nickname : '';
  }  

  render() {
    const { userId, email } = this.injected.appStore.userStore;
    const { calendarSeesoSchedules: seesoSchedules } = this.injected.appStore.scheduleManagerStore;
    const { calendarGoogleSchedules: googleSchedules } = this.injected.appStore.scheduleManagerStore;

    let schedules = seesoSchedules.filter(s => 
      s.category !== 'sch' || (['vac', 'vam', 'vpm'].includes(s.schCategory)) // 미팅일정은 구글로 관리, 휴가는 포함
    );  
    let meetings = googleSchedules.slice();

    // 내 일정만 보기 처리
    if (this.onlyMySchedules) {
      schedules = schedules.filter(x => x.creator === userId);
      meetings = meetings.filter(x => x.creator === email || x.attendees.find(attendee => attendee === email))
    }

    // 미팅 외의 일정을 캘린더에 등록할 수 있는 형태로 변환
    let events = schedules.flatMap(schedule => {
      const startAt = moment(schedule.startAt);
      const endAt = schedule.endAt ? moment(schedule.endAt) : '';
      const startAtText = schedule.allDay ? '종일' : startAt.format('HH:mm');
      const endAtText = endAt ? endAt.format('HH:mm') : '';
      let title = schedule.title;
      let allDay = true;

      switch (schedule.category) {
        case "prg":
          title = `${startAtText} [${schedule.projectGroupName}] ${schedule.title}`;
          break;
        case "ofh":
          title = `${startAtText}~${endAtText} ${this.render_pm_name_by_userid(schedule)} @${schedule.title}`;
          break;
        case "sch":
          const schCategory = schedule.schCategory;
          if (schCategory === 'vac') {
            title = `[휴가] ${this.render_pm_name_by_userid(schedule)}` + (schedule.title ? ` (${schedule.title})` : ``);
          } else if (schCategory === 'vam') {
            title = `[오전반차] ${this.render_pm_name_by_userid(schedule)}`;
          } else if (schCategory === 'vpm') {
            title = `[오후반차] ${this.render_pm_name_by_userid(schedule)}`;
          }
          break;
        case "tas":
          title = `[${this.render_pm_name_by_userid(schedule)}] ${schedule.title}`;
          break;
        default:
          break;
      }

      // 일정 중 주말/공휴일을 낀 휴가가 있으면 여러개의 일정으로 분리한다
      let results = [];
      if(schedule.category === 'sch' && schedule.schCategory === 'vac') {
        let startDate = moment(startAt)
        let idxDate = moment(startAt)
        let endDate = moment(endAt)

        while(endDate.diff(idxDate, 'days') > 0) {
          idxDate.add(1, 'days');
          if(!this.isWorkDay(idxDate)) {

            idxDate.add(-1, 'days').hours(23).minutes(59).seconds(59);
            results.push(
              this.getEventObject(schedule, title, startDate, idxDate, allDay)
            );
            idxDate.add(1, 'days').hours(0).minutes(0).seconds(0);

            while(!this.isWorkDay(idxDate)) {
              idxDate.add(1, 'days');
            }
            startDate = moment(idxDate);
          }
        }
        if(startDate.isSameOrBefore(endDate, 'days')) {
          results.push(this.getEventObject(schedule, title, startDate, endDate, allDay));
        }
      } else {
        results.push(this.getEventObject(schedule, title, startAt, endAt, allDay));
      }

      return results;
    });

    // 구글 캘린더 미팅 일정 추가
    meetings.map(meeting => {
      events.push({
        id: 0,
        category: 'sch',
        schCategory: meeting.type === 'inner' ? 'imt' : 'omt',
        title: meeting.summary,
        start: moment(meeting.start).toDate(),
        end: moment(meeting.end).toDate(),
        allDay: false,
        htmlLink: meeting.htmlLink
      });
    });

    // 필터 조건을 적용
    if(this.viewType !== 'all') {
      if(this.viewType === 'vac') {
        events = events.filter(evt => ['vac', 'vam', 'vpm'].includes(evt.schCategory));
      } else if(this.viewType === 'sch') {
        events = events.filter(evt => ['imt', 'omt'].includes(evt.schCategory));
      } else {
        events = events.filter(evt => evt.category === this.viewType);
      }
    }

    // 공휴일 추가: 위 필터 조건 적용 후 추가해야 카테고리 선택 후에도 공휴일이 보임
    this.injected.appStore.scheduleManagerStore.weekdayHolidays.map(x => {
      events.push(
        {
          id: 0,
          category: 'hld',
          schCategory: '',
          title: x.name,
          start: moment(x.date).toDate(),
          end: moment(x.date).hours(23).minutes(59).seconds(59).toDate(),
          allDay: true,
          htmlLink: ''
        }             
      );
    });    

    return (
      <Wrapper>

        <HeaderWrapper>
          <LeftWrapper>
            <Icon icon={'calendar'} />
            <Tag
              onClick={
                () => this.isOpen = !this.isOpen
              }
              style={{position: 'static'}}
            >
              달력 {this.isOpen ? "접기" : "펼치기"}
            </Tag>
            <Icon icon={'add'} style={{marginLeft: '20px'}} />
            <Tag
              onClick={
                () => this.isSchedulePopupOpen = !this.isSchedulePopupOpen
              }
            >
              일정 추가
            </Tag>            
          </LeftWrapper>

          <RightWrapper>

          {
            this.isOpen && (
              <>
                <SwitchWrapper
                  checked={this.onlyMySchedules}
                  onChange={() => {this.onlyMySchedules = !this.onlyMySchedules;}}
                  labelElement={"내 일정"}
                />

                <HTMLSelect
                  value={this.viewType}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => this.viewType = e.target.value}
                >
                  <option value='all'>전체</option>
                  <option value='sch'>미팅</option>
                  <option value='vac'>휴가</option>
                  <option value='ofh'>근무</option>
                  <option value='tas'>할일</option>
                  <option value='prg'>프로젝트일정</option>
                </HTMLSelect>
              </>
             )
          }
            
          </RightWrapper>
        </HeaderWrapper>

        <Collapse
          isOpen={this.isOpen}
          keepChildrenMounted={true}
        >

          <ScheduleCalendar
            readyToShow={this.readyToShow}
            events={events} 
            onChangeRange={(dates: any) => {
              this.refreshEvents(moment(dates.start).format("YYYY-MM-DD"), moment(dates.end).format("YYYY-MM-DD"));
            }}
            onPopupClose={this.handlePopupClose}
          />

          {/* 캘린더 뷰 옆 일정 추가 버튼을 위한 팝업 */}
          <DashboardSchedulePopup
            id={-1}
            onClose={() => {this.isSchedulePopupOpen = false}}
            isOpened={this.isSchedulePopupOpen} />
          
        </Collapse>

      </Wrapper>
    );
  }
}


export default CalendarView;