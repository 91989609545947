import koLocale from "date-fns/locale/ko";
import format from "date-fns/format";
import moment from "moment";
import { IDateFormatProps } from "@blueprintjs/datetime";

export const koFormat = (date: Date, formatStr: string) => {
  let formattedDate = "";

  try {
    formattedDate = format(date, formatStr, { locale: koLocale });
  } catch (e) {
    console.log("koFormat error", e);
    formattedDate = format(new Date(), formatStr, { locale: koLocale });
  }

  return formattedDate;
};

export const getMomentFormatter = (format: string): IDateFormatProps => {
  return {
    formatDate: (date, locale) =>
      moment(date)
        .locale(locale + "")
        .format(format),
    parseDate: (str, locale) =>
      moment(str, format)
        .locale(locale + "")
        .toDate(),
    placeholder: format
  };
};
