export enum RequestType {
    Bug = "BUG",
    Improvement = "IMPROVEMENT",
    Etc = "ETC"
}

export enum RequestOsType {
    ANDROID = "AND",
    IOS = "IOS",
    WEB_PC = "WEB",
    WEB_MOBILE = "MOB",
    WEB_RESPONSE = "RES",
    ETC = "ETC"
}

export enum TaskType {
    PER_HOUR = "PER_HOUR",
    OVERALL = "OVERALL"
}

export enum RufreeRole {
    PLANNING = "PLANNING",
    DESIGN = "DESIGN",
    PUBLISHING = "PUBLISHING",
    FRONTEND = "FRONTEND",
    BACKEND = "BACKEND",
    FULLSTACK = "FULLSTACK",
    QA = "QA",
    ETC = "ETC"
}

export interface ClientResponse {
    id: number,
    client_id: string,
    name: string,
    email: string,
    phone: string,
    affiliation: string,
}

export interface ManagerResponse {
    id: number,
    name: string,
    email: string,
    phone: string
}

export interface RufreeResponse {
    rufree_id: string,
    name: string,
    email: string,
    phone: string
}

export interface ProjectGroupResponse {
    group_id: string,
    name: string
}

export interface ReferenceFileResponse {
    id: number,
    file: string,
    name: string,
    format: string,
    description: string
}

export interface ReferenceLinkResponse {
    id: number,
    url: string,
    description: string
}

export interface TaskSummaryResponse {
    id: number;
    code: string;
    status: string;
    request: number;
    title: string;
    type: string;
    memo: string;
    rufree: RufreeResponse;
    role: string;
    estimated_working_time: number;
    client: string;
    project_group: ProjectGroupResponse;
}

export interface RequestResponse {
    id: number;
    code: string;
    status: string;
    os: string[],
    type: string,
    title: string,
    service_name: string,
    asis: string,
    tobe: string,
    access_info: string,
    cancel_reason: string | null,
    cancel_consultant: string | null,
    cancel_date: string | null,
    expected_due_date: string | null,
    reference_files: ReferenceFileResponse[],
    reference_links: ReferenceLinkResponse[],
    client: ClientResponse,
    tasks: TaskSummaryResponse[] | null,
    created: string,
    modified: string   
}

export interface RequestSummaryResponse {
    id: number,
    code: string,
    status: string,
    client: {
        id: number,
        affiliation: string
    },
    type: string,
    title: string,
    created: string
}

export interface RequestListResponse {
    count: number,
    next: string | null,
    previous: string | null,
    results: RequestResponse[]
}

export interface OutcomeResponse {
    id: number,
    file: string,
    name: string,
    format: string
}

export interface TaskResponse {
    id: number;
    code: string;
    status: string;
    request: RequestResponse;
    title: string;
    type: string;
    expected_due_date: string;
    estimated_due_date: string;
    estimated_working_time: number;
    description: string,
    memo: string;
    criteria: string[];
    manager: ManagerResponse;
    rufree: RufreeResponse;
    project_group: ProjectGroupResponse;
    client: string;
    client_name: string;
    role: string;
    total_amount: string;
    payment_remark: string;
    outcomes: OutcomeResponse[];
    created: string;
    modified: string;
}

export interface TaskListResponse {
    count: number,
    next: string | null,
    previous: string | null,
    results: TaskResponse[]
}

export interface TaskDashboardResponse {
    results: {
        in_progress: TaskResponse[],
        in_settlement: TaskResponse[]
    }
}
