import { types, flow } from "mobx-state-tree";
import axios from "axios";
import { TaskListFormModel } from './TaskListForm';
import { TaskListResponse } from "../../../types/taskManager"


export const TaskListFormStoreModel = types
  .model("TaskListFormStore", {
    currentForm: types.optional(TaskListFormModel, {})
  })
  .actions(self => {
    const initForm = (title: string, taskBoardId: string) => {
      self.currentForm = TaskListFormModel.create({
        title: title,
        taskBoardId: taskBoardId
      });
    };

    const mapFormDataForRequest = () => {
      const form = self.currentForm;
      let obj: any = {
        title: form.title,
        task_board: form.taskBoardId
      };
      return obj;
    };


    const postTaskList = flow(function*() {
      try {
        const { data }: { data: TaskListResponse } = yield axios.post(
          `/taskmgr/tasklist`,
          mapFormDataForRequest()
        );

        return data;

      } catch (e) {
        console.log("postTaskList error", e);
        throw e;
      }
    });

    const putTaskList = flow(function*(id: string) {
      try {
        const { data }: { data: TaskListResponse } = yield axios.put(
          `/taskmgr/tasklist/${id}`,
          mapFormDataForRequest()
        );

      } catch (e) {
        console.log("putTaskList error", e);
        throw e;
      }
    });

    return {
      initForm,
      postTaskList,
      putTaskList
    };
  });

type TaskListFormStoreType = typeof TaskListFormStoreModel.Type;
export interface TaskListFormStore extends TaskListFormStoreType {}