import React, { Component, ChangeEvent, KeyboardEvent } from "react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { observable, computed } from "mobx";
import sortBy from "lodash/sortBy";
import {
  HTMLTable,
  HTMLSelect,
  Classes,
  Colors,
  Button, 
  Intent, 
  InputGroup,
  IOptionProps,
  Spinner,
  Switch,
  Icon
} from "@blueprintjs/core";
import { 
  ToolsRow,
  InputContainer,
  VATRow
} from "../../atoms/CalculateTable/CalculateTable";

import { ICalculateByProjectgroup } from "../../../store/models/CalculateByProjectgroupModel";

const getStatusColor = (status: string) => {
  switch (status) {
    case '종료':
      return Colors.GREEN3;
    case '진행중':
      return Colors.ORANGE3;
    return Colors.DARK_GRAY3;
  }
}

const statusOptions: IOptionProps[] = [
  {label: '전체', value: ''},
  {label: '진행중', value: '진행중'},
  {label: '미진행', value: '미진행'},
  {label: '종료', value: '종료'}
]


interface IProps {}

interface InjectedProps extends IProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class CalculateListByProjectPanel extends Component<IProps> {
  @observable readyToShow = false;
  @observable searchText = "";
  @observable statusFilter = "";
  // @observable includeVat = true;

  get injected() {
    return this.props as InjectedProps;
  }

  get calculateStore() {
    return this.injected.appStore.calculateStore;
  }

  @computed
  get calculates() {
    let calculates: any[] = this.calculateStore.calculatesByProjectgroup;

    if(this.statusFilter) {
      calculates = calculates.filter(calculate => calculate.status === this.statusFilter);
    }
    return sortBy(
      calculates,
      calculate => calculate.groupId
    ).reverse();
  }

  handleSearch = async () => {
    this.readyToShow = false;
    await this.calculateStore.fetchCalculateByProjectgroup(this.searchText);
    this.readyToShow = true;
  };
  handleSearchText = (e: any) => {
    this.searchText = e.target.value;
  };
  handleSearchTextKeyDown = async(e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      await this.handleSearch();
    }
  };

  async componentDidMount() {
    await this.handleSearch();
  }

  render() {
    // const { calculatesByProjectgroup } = this.calculateStore;

    return (
      <>

        <ToolsRow>
          <HTMLSelect
            value={this.statusFilter}
            onChange={ (e: ChangeEvent<HTMLSelectElement>) => {
              this.statusFilter = e.currentTarget.value;
            }}
            options={statusOptions}
          />

          <InputContainer>
            <InputGroup
              placeholder="프로젝트 그룹/클라이언트 명"
              value={this.searchText}
              onChange={this.handleSearchText}
              onKeyDown={this.handleSearchTextKeyDown}
            />
          </InputContainer>

          <Button
            icon="search"
            text="검색"
            intent={Intent.PRIMARY}
            onClick={this.handleSearch}
          />

          <Button
            minimal={true}
            small={true}
            icon={<Icon icon={'reset'} iconSize={12} />}
            text='초기화'
            style={{
              fontSize: '12px'
            }}
            onClick={ async () => {
              this.statusFilter = '';
              this.searchText = '';

              await this.handleSearch();
            } } />

        </ToolsRow>

        <VATRow className="bp3-text-small">
          * VAT 포함
          {/* <Switch
            checked={this.includeVat}
            labelElement={"VAT 포함"}
            onChange={() => {
              this.includeVat = !this.includeVat;
            }} /> */}
        </VATRow>

        <HTMLTable
          className={Classes.SMALL}
          striped={true}
          interactive={true}
          style={{ width: "100%", tableLayout: "fixed" }}
        >
          <thead>
            <tr>
              <th style={{ width: '' }}>프로젝트그룹명</th>
              <th style={{ width: '' }}>클라이언트명</th>
              <th style={{ width: '120px', textAlign: 'right' }}>검수비용(A)</th>
              <th style={{ width: '120px', textAlign: 'right' }}>총 지급요청액(B)</th>
              <th style={{ width: '120px', textAlign: 'right' }}>검수차액(B-A)</th>
              <th style={{ width: '100px', textAlign: 'center' }}>상태</th>
            </tr>
          </thead>
          <tbody>
            {
              this.readyToShow ?
                this.calculates.length === 0 ?
                  <tr>
                    <td
                      colSpan={6}
                      style={{ textAlign: 'center', color: Colors.GRAY3 }}>아이템이 없습니다.</td>
                  </tr>
                :
                  this.calculates
                    .map((calculate: ICalculateByProjectgroup, i) => {
                      const { groupName, groupId, clientName, clientId, status } = calculate;
                      const inspectionAmount = calculate.inspectionAmount;
                      const depositAmount = calculate.depositAmount;
                      const diffAmount = depositAmount - inspectionAmount;

                      return <tr key={i}>
                        <td>
                          <Link to={`/project-groups/${groupId}`} target="_blank">
                            { groupName }
                          </Link>
                        </td>
                        <td>
                          <Link to={`/clients/${clientId}`} target="_blank">
                            { clientName }
                          </Link>
                        </td>
                        <td style={{ textAlign: 'right' }}>{ inspectionAmount.toLocaleString("en-US") }</td>
                        <td style={{ textAlign: 'right' }}>{ depositAmount.toLocaleString("en-US") }</td>
                        <td style={{ textAlign: 'right', color: diffAmount < 0 ? Colors.RED3 : 'inherit' }}>{ diffAmount.toLocaleString("en-US") }</td>
                        <td
                          style={{ textAlign: 'center', color: getStatusColor(status) }}>
                          { status }
                        </td>
                      </tr>
                    })
              : <tr>
                  <td colSpan={6}><Spinner /></td>
                </tr>
            }
          </tbody>
        </HTMLTable>

      </>
    );
  }
}

export default CalculateListByProjectPanel;
