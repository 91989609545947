import React, { Component } from "react";
import { observable, computed } from "mobx";
import { DateRange, DateRangeInput } from "@blueprintjs/datetime";
import axios from "axios";
import moment from "moment";
import {
  Radio,
  RadioGroup
} from "@blueprintjs/core";
import styled from "styled-components/macro";
import { observer } from "mobx-react";

import ProjectConversionRateGraph from "./ProjectConversionRateGraph";
import ProjectConversionRateTable from "./ProjectConversionRateTable";
import ProjectConversionDurationTable from "./ProjectConversionDurationTable";


const ToolsRow = styled.div`
  margin-bottom: 40px;
  display: flex;
  height: 20px;

  & > * + * {
    margin-left: 10px;
  }
`;

const InputContainer = styled.div`
  max-width: 220px;
  margin-bottom: 30px;
`;

const Container = styled.div`
  margin-top: 60px;
  height: 300px;
  position: relative;

  .duration-table {
    position: absolute;
    top: 0;
    left: 800px;
  }
`;

interface IItem {
  id: string;
  title: string;
  date: string;
}
interface IData extends IItem {
  linked_item: {
    project: IItem[];
    inspection: IItem[];
    projectgroup: IItem[];
  }
}
interface IResponse {
  count: {
    reception: number;
    project: number;
    inspection: number;
    projectgroup: number;
  },
  duration: {
    count: number;
    project: number;
    inspection: number;
    projectgroup: number;
  },
  data: IData[];
}
const INITIAL_RESPONSE = {
  count: {
    reception: 0,
    project: 0,
    inspection: 0,
    projectgroup: 0
  },
  duration: {
    count: 0,
    project: 0,
    inspection: 0,
    projectgroup: 0
  },
  data: []
}

interface Props {
}
interface InjectedProps extends Props {
}
@observer
class ProjectConversionRate extends Component {
  @observable response: IResponse = INITIAL_RESPONSE;

  @observable isLoading: boolean = false;
  @observable dateRangeType = "period";
  @observable dateRange: DateRange = [ moment().subtract(1, 'months').toDate(), moment().toDate() ];

  async componentDidMount() {
    await this.loadData();
  }

  async loadData() {
    this.isLoading = true;

    const dateStart = this.dateRangeType === 'period' ?
      this.dateRange[0] ? moment(this.dateRange[0]).format("YYYY-MM-DD HH:mm") : ""
      : '';
    const dateEnd = this.dateRangeType === 'period' ?
      this.dateRange[1] ? moment(this.dateRange[1]).format("YYYY-MM-DD HH:mm") : ""
      : '';

    const { data }: { data: IResponse } = await
      axios.get(`/stats/conversion_rate_from_reception_to_kickoff?&dateStart=${dateStart}&dateEnd=${dateEnd}`);
    this.response = data;

    this.isLoading = false;
  }

  @computed
  get graphData() {
    const { reception, project, inspection, projectgroup } = this.response.count;
    const { duration } = this.response;

    return [
      { title: '의뢰서', count: reception, ratio: reception / reception, duration: 0 },
      { title: '영업상담서', count: project, ratio: project / reception, duration: duration.project },
      { title: '검수보고서', count: inspection, ratio: inspection / reception, duration: duration.inspection },
      { title: '프로젝트그룹', count: projectgroup, ratio: projectgroup / reception, duration: duration.projectgroup }
    ]
  }

  render() {
    const { duration } = this.response;

    return (
      <div>
        <ToolsRow>
          <InputContainer>
              <RadioGroup
                selectedValue={this.dateRangeType}
                onChange={ async (e: any) => {
                  this.dateRangeType = e.target.value;
                  // this.dateRange = [undefined, undefined];
                  await this.loadData();
                }}
                inline={true}
              >
                <Radio
                  label="전체"
                  value="whole"
                  inline={true}
                  style={{ verticalAlign: "sub" }}
                />
                <Radio
                  label="기간선택"
                  value="period"
                  inline={true}
                  style={{ verticalAlign: "sub" }}
                />
              </RadioGroup>
          </InputContainer>

          <InputContainer>
            <DateRangeInput
              disabled={this.dateRangeType !== "period"}
              formatDate={(date: Date) => moment(date).format("YYYY-MM-DD")}
              parseDate={(str) => new Date(str)}
              onChange={async (range) => {
                this.dateRange = range;

                await this.loadData();
              }}
              value={this.dateRange}
              allowSingleDayRange={true}
              singleMonthOnly={true}
              selectAllOnFocus={true}
              startInputProps={{ style: { width: "100px" } }}
              endInputProps={{ style: { width: "100px" } }}
            />
          </InputContainer>
        </ToolsRow>


        <Container>
          <ProjectConversionRateGraph
            className={ this.isLoading ? 'bp3-skeleton' : ''}
            data={ this.graphData }/>

          {
            duration.project ?
              <ProjectConversionDurationTable
                className={ `duration-table ${this.isLoading ? 'bp3-skeleton' : ''}` }
                count={duration.count}
                project={duration.project}
                inspection={duration.inspection}
                projectgroup={duration.projectgroup} />
              : <></>
          } 

          <div className={ this.isLoading ? 'bp3-skeleton' : ''}>
            <ProjectConversionRateTable data={ this.response.data } />
          </div>
        </Container>
      </div>
    );
  }
}

export default ProjectConversionRate;