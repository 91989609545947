import React, { Component, FormEvent } from "react";
import styled from "styled-components/macro";
import sortBy from "lodash/sortBy";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { ProjectGroup } from "../../../store/models/ProjectGroup";
import ProjectgroupTimeline from '../../organisms/Dashboard/ProjectgroupTimeline';
import SprintStatus from "../../organisms/Dashboard/SprintStatus";
import { observable } from "mobx";
import {
  Icon,
  Spinner,
  Callout,
  Radio,
  RadioGroup
} from "@blueprintjs/core";

enum ProjectsType {
  ongoing,
  pause,
}

const Wrapper = styled.div`
  maring-top: 10px;
  .bp3-callout {
    text-align: center !important;
    background-color: #ffffff;
    color: #5C7080;

    .bp3-icon {
      margin-right: 8px;
    }
  }

  .dashed-divider {
    opacity: 0.5;
    border: dashed 1px #a0a7bb;
    margin: 50px 0px;
  }
`;
const SprintStatusWrapper = styled.div`
  width: 50%;
  height: 230px;
  display: flex;
  flex-flow: column wrap;
`;

interface SprintGroup {
  color: string;
  id: string;
  name: string;
  totalCount: number;
  ongoingCount: number;
  doneCount: number;
}

interface ProjectPanelProps {
  setOngoingProjectCount: (count: number) => void;
}

interface InjectedProps extends ProjectPanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ProjectPanel extends Component<ProjectPanelProps> {
  @observable readyToShow = false;
  @observable currentType = ProjectsType.ongoing;
  @observable sprintGroups: Array<SprintGroup> = [];

  @observable projects: Array<ProjectGroup> = [];
  @observable ongoingProjects: Array<ProjectGroup> = [];
  @observable pauseProjects: Array<ProjectGroup> = [];


  get injected() {
    return this.props as InjectedProps;
  }

  get projectGroupStore() {
    return this.injected.appStore.projectGroupStore;
  }

  private setProjects = () => {
    this.readyToShow = false;

    this.sprintGroups = [];
    let projects: Array<ProjectGroup> = [];

    projects = this.currentType === ProjectsType.ongoing ? this.ongoingProjects : this.pauseProjects
    projects.map(projectGroup => {
      const sprintGroup: SprintGroup = {
        color: '#5C7080',
        id: projectGroup.groupId,
        name: projectGroup.name,
        totalCount: 0,
        ongoingCount: 0,
        doneCount: 0
      };

      if(projectGroup.schedule) {
        const { total_count, ongoing_count, done_count } = projectGroup.schedule.sprintsCount;

        sprintGroup.totalCount = total_count;
        sprintGroup.ongoingCount = ongoing_count;
        sprintGroup.doneCount = done_count;
      }

      if(projectGroup.isHolding) {
        sprintGroup.color = '#752F75';
      }

      this.sprintGroups.push(sprintGroup);
    })

    this.readyToShow = true;
  }

  async componentDidMount() {
    const { projectGroups, fetchProjectgroupsDashboardTargets } = this.projectGroupStore;

    await fetchProjectgroupsDashboardTargets();
    this.ongoingProjects = sortBy(
      projectGroups
        .filter(projectGroup => projectGroup.status === '진행중'),
      projectGroup => projectGroup.groupId
    ).reverse();
    this.pauseProjects = projectGroups
      .filter(projectGroup => projectGroup.status === '미진행' || projectGroup.status === '홀딩중');
    this.props.setOngoingProjectCount(projectGroups.length);

    this.setProjects();
  }

  private handleTypeChange = (e: FormEvent<HTMLInputElement>) => {
    this.currentType = parseInt(e.currentTarget.value);
    this.setProjects();
  }


  render() {
    if(!this.readyToShow) {
      return <Spinner />
    }

    return (
      <Wrapper>

        <RadioGroup
          inline={true}
          name="group"
          onChange={this.handleTypeChange}
          selectedValue={this.currentType}
        >
          <Radio
            label={`진행중 (${this.ongoingProjects.length})`}
            value={ProjectsType.ongoing} />
          <Radio
            label={`홀드/미진행 (${this.pauseProjects.length})`}
            value={ProjectsType.pause} />
        </RadioGroup>
        
        {
          this.sprintGroups.length === 0 ?
            <Callout>
              <Icon
                icon='info-sign'
                color='#5C7080' />
              { this.currentType === ProjectsType.ongoing ? '진행 ' : '홀드/미진행'} 중인 프로젝트가 없습니다.
            </Callout>
          :
            <SprintStatusWrapper>
              {
                sortBy(
                  this.sprintGroups.slice(),
                  group => group.id
                )
                .reverse()
                .map((sprintGroup: SprintGroup, index: number) => (
                  <SprintStatus
                    key={index}
                    { ...sprintGroup }
                    color={sprintGroup.color}
                  />
                ))
              }
            </SprintStatusWrapper>
        }

        <div className='dashed-divider' />

        <ProjectgroupTimeline
          projectGroups={this.ongoingProjects} />
       
      </Wrapper>
    );
  }
}

export default ProjectPanel;
