import { types } from "mobx-state-tree";

export const FunctionalityTexts = [
  {
    key: "sign",
    text: "회원 - 가입, 로그인"
  },
  {
    key: "mypage",
    text: "마이페이지"
  },
  {
    key: "msg",
    text: "메세지 - DM, 채팅, 그룹메세징 등"
  },
  {
    key: "payment",
    text: "결제 (결제방식은 메모에)"
  },
  {
    key: "product",
    text: "상품 디지털, 현물"
  },
  {
    key: "contents",
    text: "컨텐츠 (대략의 내용은 메모에)"
  },
  {
    key: "social",
    text: "소셜기능 - 댓글, 좋아요"
  },
  {
    key: "banner",
    text: "배너 혹은 팝업"
  },
  {
    key: "advancement",
    text: "기존 서비스 고도화"
  }
];

export const mapFunctionalityKeyToText = (key: string) => {
  const value = FunctionalityTexts.find(x => x.key === key);

  return value ? value.text : key;
};

export const ConsultFunctionalityModel = types.model("ConsultFunctionality", {
  checked: types.boolean,
  etc: types.boolean,
  text: types.string
});

type ConsultFunctionalityType = typeof ConsultFunctionalityModel.Type;
export interface ConsultFunctionality extends ConsultFunctionalityType {}
