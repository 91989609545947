import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { Checkbox, InputGroup } from "@blueprintjs/core";
import { ChangeEvent } from "cleave.js/react/props";
import { ConsultFunctionalityForm } from "../../../store/forms/ProjectForm/ConsultFunctionalityForm";
import { mapFunctionalityKeyToText } from "../../../store/models/ConsultFunctionality";
import {
  ItemRow,
  RemoveButtonContainer,
  RemoveButton
} from "../../atoms/RemoveItemButton/RemoveItemButton";

const Wrapper = styled.div`
  margin-bottom: 4px;
`;

const CheckItem = styled.div`
  display: flex;
  margin-bottom: 4px;

  & > * + * {
    margin-left: 5px;
  }
`;

interface ConsultFunctionalityFormViewProps {
  functionality: ConsultFunctionalityForm;
  isReadMode: boolean;
  onRemove: (item: ConsultFunctionalityForm) => void;
}

interface InjectedProps extends ConsultFunctionalityFormViewProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ConsultFunctionalityFormView extends Component<
  ConsultFunctionalityFormViewProps
> {
  get injected() {
    return this.props as InjectedProps;
  }

  handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.props.functionality.setText(e.target.value);
  };

  toggleCheck = () => {
    if (this.props.isReadMode) {
      return;
    }

    this.props.functionality.setChecked(!this.props.functionality.checked);
  };

  render() {
    const { functionality, isReadMode, onRemove } = this.props;

    if (!isReadMode && functionality.etc) {
      return (
        <ItemRow>
          <RemoveButtonContainer>
            <RemoveButton onClick={() => onRemove(functionality)} />
          </RemoveButtonContainer>
          <Wrapper>
            <CheckItem>
              <Checkbox
                checked={functionality.checked}
                onChange={this.toggleCheck}
              />
              <InputGroup
                value={functionality.text}
                onChange={this.handleTextChange}
                style={{ width: "369px" }}
              />
            </CheckItem>
          </Wrapper>
        </ItemRow>
      );
    }

    return (
      <ItemRow>
        <Wrapper>
          <Checkbox checked={functionality.checked} onChange={this.toggleCheck}>
            {mapFunctionalityKeyToText(functionality.text)}
          </Checkbox>
        </Wrapper>
      </ItemRow>
    );
  }
}

export default ConsultFunctionalityFormView;
