import { types, flow } from "mobx-state-tree";
import {
  RufreeMatchingFormModel,
  RufreeMatchingForm
} from "./RufreeMatchingForm";
import { RufreeMatchingResponse } from "../../../types/rufreeMatching";
import axios from "axios";
import moment from "moment";

const mapFormToData = (form: RufreeMatchingForm) => ({
  project_group: form.projectGroup,
  project_group_name: form.projectGroupName,
  inspections: form.inspections || undefined,
  pg_start_or_not: form.pGStartOrNot,
  role: form.role || undefined,
  sprint_wage: form.sprintWage,
  sprint_fee: form.sprintFee,
  sprint_num: form.sprintNum,
  date_due: form.dateDue
    ? moment(form.dateDue).format("YYYY-MM-DD")
    : undefined,
  date_request: form.dateRequest
    ? moment(form.dateRequest).format("YYYY-MM-DD")
    : undefined,
  reference: form.reference,
  role_detail: form.roleDetail,
  pm_manager_comment: form.pmManagerComment,
  matching_manager_comment: form.matchingManagerComment,
  final_matching_manager_comment: form.finalMatchingManagerComment,
  need_update: form.needUpdate,
  on_recommend: form.onRecommend,
  status: form.status
});

export const RufreeMatchingFormStoreModel = types
  .model("RufreeMatchingFormStore", {
    currentForm: types.optional(RufreeMatchingFormModel, {})
  })
  .actions(self => {
    const initForm = () => {
      const newForm = RufreeMatchingFormModel.create({});
      self.currentForm = newForm;
    };

    const setDefaultValue = ({
      projectGroupId,
      projectName,
      pGStartOrNot,
      manager,
      macthingType,
      role,
      inspectionId,
      sprintWage,
      sprintNum
    }: {
      projectGroupId: string;
      projectName: string;
      pGStartOrNot: boolean;
      manager: string;
      macthingType: string;
      role: string;
      inspectionId: string;
      sprintWage: number;
      sprintNum: number;
    }) => {
      const newForm = RufreeMatchingFormModel.create({
        manager,
        macthingType,
        projectGroup: projectGroupId,
        projectGroupName: projectName,
        pGStartOrNot,
        inspections: inspectionId,
        role,
        sprintWage,
        sprintFee: 300000,
        sprintNum,
        dateDue: new Date().toISOString()
      });

      self.currentForm = newForm;
    };

    const fetchRufreeMatching = flow(function*(id: number) {
      try {
        const { data }: { data: RufreeMatchingResponse } = yield axios.get(
          `/rufreeMatching/${id}`
        );

        const newForm = RufreeMatchingFormModel.create({
          id: data.id,
          manager: data.manager,
          projectGroup: data.project_group,
          projectGroupName: data.project_group_name,
          pGStartOrNot: data.pg_start_or_not,
          role: data.role,
          sprintWage: data.sprint_wage,
          sprintFee: data.sprint_fee,
          sprintNum: data.sprint_num,
          dateDue: data.date_due,
          dateRequest: data.date_request,
          reference: data.reference,
          roleDetail: data.role_detail,
          pmManagerComment: data.pm_manager_comment,
          matchingManagerComment: data.matching_manager_comment,
          finalMatchingManagerComment: data.final_matching_manager_comment,
          needUpdate: data.need_update,
          onRecommend: data.on_recommend,
          inspections: data.inspections,
          matchedRufree: data.matched_rufree,
          status: data.status
        });

        self.currentForm = newForm;
      } catch (e) {
        console.error("fetchRufreeMatching error", e);
        throw e;
      }
    });

    const postRufreeMatching = flow(function*() {
      try {
        const { data }: { data: RufreeMatchingResponse } = yield axios.post(
          "/rufreeMatching",
          mapFormToData(self.currentForm)
        );

        self.currentForm.id = data.id;
      } catch (e) {
        console.error("postRufreeMatching error", e);
        throw e;
      }
    });

    const putRufreeMatching = flow(function*() {
      try {
        yield axios.put(
          `/rufreeMatching/${self.currentForm.id}`,
          mapFormToData(self.currentForm)
        );
      } catch (e) {
        console.error("putRufreeMatching error", e);
        throw e;
      }
    });

    const toggleRequestMatching = flow(function*() {
      try {
        yield axios.get(
          `/rufreeMatching/${self.currentForm.id}/requestMatching`
        );
      } catch (e) {
        console.error("toggleRequestMatching error", e);
        throw e;
      }
    });
    
    const cancelMatchingRequest = flow(function*(matching) {
      console.log(matching)
      try {
        yield axios.get(
          `/rufreeMatching/${matching.id}/cancelMatchingRequest`
        );
      } catch (e) {
        console.error("cancelMatchingRequest error", e);
        throw e;
      }
    });

    const toggleRequestUpdate = flow(function*() {
      try {
        yield axios.get(
          `/rufreeMatching/${self.currentForm.id}/requestUpdate`
        );
      } catch (e) {
        console.error("toggleRequestUpdate error", e);
        throw e;
      }
    });

    const setForm = (val:any) =>{
      self.currentForm = val;
    };

    return {
      initForm,
      setDefaultValue,
      fetchRufreeMatching,
      postRufreeMatching,
      putRufreeMatching,
      toggleRequestMatching,
      cancelMatchingRequest,
      toggleRequestUpdate,
      setForm
    };
  });

type RufreeMatchingFormStoreType = typeof RufreeMatchingFormStoreModel.Type;
export interface RufreeMatchingFormStore extends RufreeMatchingFormStoreType {}
