import React, { Component, KeyboardEvent, SyntheticEvent } from "react";

import { computed, observable } from "mobx";
import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import {
  Button,
  MenuItem,
  Intent,
} from "@blueprintjs/core";
import { MultiSelect, ItemRenderer, ItemPredicate } from "@blueprintjs/select";
import { DateInput } from "@blueprintjs/datetime";
import moment from "moment";

import { AppStore } from "../../../store/AppStore";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";

const Container = styled.div`
  position: relative;
  width: calc(100% - 320px);
  display: flex;
  justify-content: space-between;
`;
const InfoTextContainer = styled.div`
  padding-left: 10px;
  padding-top: 1em;
  line-height: 2em;
`;
const ControlContainer = styled.div`
  display: flex;
  margin-left: auto;
  flex-wrap: wrap;
  align-content: flex-end;
`;

interface TaskMultiSelectActionViewProps {
}
interface InjectedProps extends TaskMultiSelectActionViewProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class TaskMultiSelectActionView extends Component<TaskMultiSelectActionViewProps> {
  @observable is_changed: boolean = false;

  @observable person_in_charges: Array<string> = [];

  @observable duedate: Date | undefined = undefined;

  @observable label_title_list: Array<string> = [];
  @observable labelInputComponent: any = null;
  @observable labelInputText: string = '';

  @observable sprint_title_list: Array<string> = [];
  @observable sprintInputComponent: any = null;
  @observable sprintInputText: string = '';

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get store() {
    return this.injected.appStore.taskManagerStore;
  }

  @computed
  get board() {
    return this.store.currentBoard;
  }

  componentDidMount() {
    this.labelInputComponent.onkeypress = this.labelKeyPress;
    this.sprintInputComponent.onkeypress = this.sprintKeyPress;
  }

  selectPersonInCharges = (item: string) => {
    let idx = this.person_in_charges.indexOf(item);
    if(idx > -1) {
      this.person_in_charges.splice(idx, 1);
    } else {
      this.person_in_charges.push(item);
    }
    this.handleInputChanged();
  }
  removePersonInChange = (item: string, idx: number) => {
    // 한명씩 취소하는 로직 참고로 보관
    // if(idx > -1) {
    //   this.person_in_charges = 
    //     this.person_in_charges.slice(0, idx).concat(this.person_in_charges.slice(idx+1));
    // }
    this.person_in_charges = [];
    this.handleInputChanged();
  }  

  selectLabelTitleList = (item: string, event?: SyntheticEvent<HTMLElement>) => {
    let idx = this.label_title_list.indexOf(item);
    if(idx > -1) {
      this.label_title_list.splice(idx, 1);
    } else {
      this.label_title_list.push(item);
    }
    this.handleInputChanged();
  }
  removeLabelTitleList = (item: string, idx: number) => {
    this.label_title_list.splice(idx, 1);
    this.handleInputChanged();
  }

  selectSprintTitleList = (item: string, event?: SyntheticEvent<HTMLElement>) => {
    let idx = this.sprint_title_list.indexOf(item);
    if(idx > -1) {
      this.sprint_title_list.splice(idx, 1);
    } else {
      this.sprint_title_list.push(item);
    }
    this.handleInputChanged();
  }
  removeSprintTitleList = (item: string, idx: number) => {
    this.sprint_title_list.splice(idx, 1);
    this.handleInputChanged();
  }

  handleInputChanged = () => {
    this.is_changed = (
      this.person_in_charges.length > 0 ||
      this.duedate !== undefined ||
      this.label_title_list.length > 0 ||
      this.sprint_title_list.length > 0
    );

    this.store.previewMultiSelectInputs({
      person_in_charge_list: this.person_in_charges.map(name => {
        if(this.board) {
          const found = this.board.members.find(member => member.name === name)
          return found ? found.id : null;
        } else {
          return null;
        }
      }),
      duedate: this.duedate ? moment(this.duedate).format("YYYY-MM-DD") : '',
      label_title_list: this.label_title_list,
      sprint_title_list: this.sprint_title_list
    });
  }
  handleSubmit = async () => {
    try {
      await this.store.updateMultiTasks();
      AppToaster.show({
        message: "수정되었습니다.",
        intent: Intent.SUCCESS
      });

    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }    
    this.handleCancel();
  }
  handleCancel = () => {
    this.store.clearMultiSelectTaskIds();
    this.is_changed = false;
    if(this.board) {
      this.store.fetchTaskBoard(this.board.projectGroup);
    }
  }
  handleReset = async () => {
    // 스프린트, 라벨, 담당자, 마감일을 초기화한다
    try {
      await this.store.resetMultiTasks('person_in_charge');
      await this.store.resetMultiTasks('due_date');
      await this.store.resetMultiTasks('label');
      await this.store.resetMultiTasks('sprint');
      AppToaster.show({
        message: "리셋되었습니다.",
        intent: Intent.SUCCESS
      });

    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }    
    this.handleCancel();    
  }

  createLabel = (query: string) => {
    this.labelInputText = query;
    return query;
  }
  submitCreateLabel = async () => {
    if(!this.labelInputText || this.labelInputText.trim() === '') {
      alert('빈칸은 입력할 수 없습니다');
      return;
    }
    this.labelInputText = this.labelInputText.trim();
    if(this.board && this.board.labelTitleList.includes(this.labelInputText)) {
      return;
    }
    await this.store.createLabel(this.labelInputText);
    this.forceUpdate();

    this.handleInputChanged();
  }
  labelKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      if(this.labelInputText.trim() !== '') {
        // submitCreateLabel 에서도 trim 체크하지만, 여기 조건을 없애면 엔터로 라벨을 선택할 수 없음
        this.submitCreateLabel();
      }
    }    
  }
  renderCreateLabel = (query: string, active: boolean, handleClick: React.MouseEventHandler<HTMLElement>) => {
    return (
      <MenuItem
        icon="add"
        text={`Create "${query}"`}
        active={active}
        onClick={(e: any) => {
          this.submitCreateLabel();
          handleClick(e);
        }}
        shouldDismissPopover={false}    
      />
    );
  }
  renderLabelTitleList: ItemRenderer<string> = (item, {modifiers, handleClick}) => {
    if(!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        key={item}
        active={modifiers.active}
        icon={this.label_title_list.includes(item) ? "tick" : "blank"}
        text={item}
        onClick={handleClick}
      />
    )
  }

  createSprint = (query: string) => {
    this.sprintInputText = query;
    return query;
  }
  submitCreateSprint = async () => {
    if(!this.sprintInputText || this.sprintInputText.trim() === '') {
      alert('빈칸은 입력할 수 없습니다');
      return;
    }
    this.sprintInputText = this.sprintInputText.trim();
    if(this.board && this.board.sprintTitleList.includes(this.sprintInputText)) {
      return;
    }

    await this.store.createSprint(this.sprintInputText);
    this.forceUpdate();

    this.handleInputChanged();
  }
  sprintKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      if(this.sprintInputText.trim() !== '') {
        this.submitCreateSprint();
      }
    }    
  }
  renderCreateSprint = (query: string, active: boolean, handleClick: React.MouseEventHandler<HTMLElement>) => {
    return (
      <MenuItem
        icon="add"
        text={`Create "${query}"`}
        active={active}
        onClick={(e: any) => {
          this.submitCreateSprint();
          handleClick(e);
        }}
        shouldDismissPopover={false}    
      />
    );
  }
  renderSprintTitleList: ItemRenderer<string> = (item, {modifiers, handleClick}) => {
    if(!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        key={item}
        active={modifiers.active}
        icon={this.sprint_title_list.includes(item) ? "tick" : "blank"}
        text={item}
        onClick={handleClick}
      />
    )
  }

  filterItem: ItemPredicate<string> = (query: string, item: string, index?: number, exactMatch?: boolean) => {
    const normalizedItem = item.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
        return normalizedItem === normalizedQuery;
    } else {
        return normalizedItem.indexOf(normalizedQuery) >= 0;
    }
  }

  renderPersonInCharge: ItemRenderer<string> = (item, {modifiers, handleClick}) => {
    if(!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        key={item}
        icon={this.person_in_charges.includes(item) ? "tick" : "blank"}
        text={item}
        onClick={handleClick}
      />
    )
  }
  renderPersonInChargeTag = (item: string) => {
    if(this.person_in_charges.indexOf(item) === 0) {
      if(this.person_in_charges.length === 1) {
        return <span>{item}</span>;
      } else {
        return <span>{item} 외 {this.person_in_charges.length-1}</span>
      }
    } else {
      return null;
    }
  }  

  render() {
    if(!this.board) {
      return <div></div>;
    } else {
      return (
        <Container>
          <InfoTextContainer>
          선택한 Task 일괄 지정
          </InfoTextContainer>
          <ControlContainer>
            <MultiSelect
              placeholder={'담당자'}
              itemRenderer={this.renderPersonInCharge}
              items={this.board.members.map(member => member.name)}
              onItemSelect={this.selectPersonInCharges}
              tagRenderer={this.renderPersonInChargeTag}
              tagInputProps={{
                onRemove: this.removePersonInChange,
                inputProps: {style: {width: this.person_in_charges.length > 0 ? '0px' : '40px'}},
                rightElement: <Button icon='caret-down' minimal={true} />,
                tagProps: {minimal: true}
              }}
              selectedItems={this.person_in_charges}
            />            
            &nbsp;
            <DateInput
              placeholder={"마감일"}
              showActionsBar={true}
              formatDate={(date: Date) => moment(date).format("YYYY-MM-DD")}
              parseDate={(str) => new Date(str)}
              onChange={(selectedDate:Date, isUserChange:boolean) => {
                if(isUserChange) {
                  this.duedate = selectedDate ? selectedDate : undefined;
                }
                this.handleInputChanged();
              }}
              value={this.duedate}
              inputProps={{style:{width:'100px'}}}
            />
            &nbsp;
            <MultiSelect
              placeholder={'라벨'}
              createNewItemFromQuery={this.createLabel}
              createNewItemRenderer={this.renderCreateLabel}
              itemPredicate={this.filterItem}
              itemRenderer={this.renderLabelTitleList}
              items={this.board.labelTitleList}
              onItemSelect={this.selectLabelTitleList}
              tagRenderer={(item: string) => { return item; }}
              tagInputProps={{
                onRemove: this.removeLabelTitleList,
                inputProps: {style: {width: this.label_title_list.length > 0 ? '40px' : '80px'}},
                tagProps: {minimal: true},
                inputRef: (input: any) => {this.labelInputComponent = input; }
              }}
              selectedItems={this.label_title_list}
            />
            &nbsp;
            <MultiSelect
              placeholder={'스프린트'}
              createNewItemFromQuery={this.createSprint}
              createNewItemRenderer={this.renderCreateSprint}
              itemPredicate={this.filterItem}
              itemRenderer={this.renderSprintTitleList}
              items={this.board.sprintTitleList}
              onItemSelect={this.selectSprintTitleList}
              tagRenderer={(item: string) => { return item; }}
              tagInputProps={{
                onRemove: this.removeSprintTitleList,
                inputProps: {style: {width: this.sprint_title_list.length > 0 ? '40px' : '80px'}},
                tagProps: {minimal: true},
                inputRef: (input: any) => {this.sprintInputComponent = input; }
              }}
              selectedItems={this.sprint_title_list}
            />             
            &nbsp;
            <Button 
              text="확정"
              intent={Intent.PRIMARY}
              disabled={!this.is_changed}
              onClick={this.handleSubmit}
            />
            &nbsp;
            <Button 
              text="취소"
              onClick={this.handleCancel}
            />
            &nbsp;&nbsp;&nbsp;
            <Button 
              text="리셋(속성초기화)"
              intent={Intent.DANGER}
              onClick={this.handleReset}
            />
          </ControlContainer>
        </Container>
      );
    }
  }
}

export default TaskMultiSelectActionView;