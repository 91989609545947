import React, { FC, useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Button, Classes, Dialog, FormGroup, TagInput, Intent } from "@blueprintjs/core";
import { SprintReport } from "../../../store/models/SprintReport";
import { reportObserved } from "mobx/lib/internal";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import { Inspection } from "../../../store/models/Inspection";

const DEFAULT_CC = "support@seeso.kr";
const DEFAULT_BCC = "pm@seeso.kr";
const DialogWrapper = styled(Dialog)`
  h3 {
    color: #137cbd;
    margin-bottom: 40px;
  }

  h4 {
    color: #137cbd;
    margin: 30px 0px 20px 0px;
  }

  label {
    font-weight: bold;
    width: 70px;
  }

  .bp3-form-content {
    line-height: 30px;
    width: 100% !important;
  }

  .mail-preview {
    margin-bottom: 10px;
    border-radius: 3px;
    padding: 5px;
    background-color: #ffffff;
  }
`;

interface Props {
  pm: string;
  projectGroupName: string;
  // report: SprintReport;
  inspection: Inspection;
  to: string[];
  cc: string[];
  isOpened: boolean;
  onClose: () => void;
  onAfterSend: () => void;
}

const RecipientPopup: FC<Props> = props => {
  const [to, setTo] = useState(props.to);
  const [cc, setCc] = useState(props.cc.concat([DEFAULT_CC]));
  const [bcc, setBcc] = useState([DEFAULT_BCC]);
  const [mailSubject, setMailSubject] = useState("");
  const [mailBody, setMailBody] = useState("");
  const [isSending, setIsSending] = useState(false);

  const subjectRef: React.RefObject<HTMLDivElement> = React.createRef();
  const bodyRef: React.RefObject<HTMLDivElement> = React.createRef();
  const clearToButton = (
    <Button
      icon={props.to.length > 1 ? "cross" : "reset"}
      minimal={true}
      onClick={() => setTo(props.to)}
    />
  );
  const clearCcButton = (
    <Button
      icon={props.cc.length > props.cc.length + 1 ? "cross" : "reset"}
      minimal={true}
      onClick={() => setCc(props.cc.concat([DEFAULT_CC]))}
    />
  );
  const clearBccButton = (
    <Button
      disabled={bcc.length === 0 ? false : true}
      icon={"cross"}
      minimal={true}
      onClick={() => setBcc([])}
    />
  );

  const handleOpening = async () => {
    const { pm, projectGroupName, inspection } = props;
    const previewData: {
      html: string;
      subject: string;
    } = await inspection.preview_mail();


    setMailSubject(previewData.subject);
    setMailBody(previewData.html);
  };


  useEffect(() => {
    setTo(props.to);
    setCc(props.cc.concat([DEFAULT_CC]));
    setBcc([DEFAULT_BCC]);
  }, []);


  return (
    <DialogWrapper
      isOpen={props.isOpened}
      onOpening={handleOpening}
      onClosing={props.onClose}
    >

      <div
        className={`${isSending ? `${Classes.DIALOG_BODY} bp3-skeleton` : `${Classes.DIALOG_BODY}`}`}>
        <h3>수신인 정보를 확인하세요.</h3>
        <FormGroup label={<span>받는 사람:</span>} inline={true}>
          <TagInput
            leftIcon={"user"}
            onChange={(values: any) => {
              setTo(values);
            }}
            placeholder="받는 사람에 추가 할 이메일 주소를 입력하세요."
            rightElement={clearToButton}
            values={to}
            fill={true}
          />
        </FormGroup>

        <FormGroup label={<span>참조:</span>} inline={true}>
          <TagInput
            leftIcon={"user"}
            onChange={(values: any) => {
              setCc(values);
            }}
            placeholder="참조에 추가 할 이메일 주소를 입력하세요."
            rightElement={clearCcButton}
            values={cc}
            fill={true}
          />
        </FormGroup>

        <FormGroup label={<span>비밀 참조:</span>} inline={true}>
          <TagInput
            leftIcon={"user"}
            onChange={(values: any) => {
              setBcc(values);
            }}
            placeholder="비밀 참조에 추가 할 이메일 주소를 입력하세요."
            rightElement={clearBccButton}
            values={bcc}
            fill={true}
          />
        </FormGroup>


        <h4>아래 본문 내용으로 메일 발송됩니다.</h4>
        {/*<input type="text" style={{ width: "100%" }} className='mail-preview'*/}
        {/*  // dangerouslySetInnerHTML={{ __html: mailSubject }}*/}
        {/*       value={mailSubject}*/}
        {/*       onChange={(event => {*/}
        {/*         console.log(event.target.value);*/}
        {/*       })}/>*/}
        <div
          className='mail-preview'
          dangerouslySetInnerHTML={{ __html: mailSubject }}
          contentEditable
          ref={subjectRef}
          // onInput={(event) => {
          //   // subjectRef
          //   if(subjectRef.current) {
          //     setMailSubject(subjectRef.current.innerHTML)
          //   }
          // }}
          onBlur={(event) => {
            // subjectRef
            // console.log(subjectRef.current);
              if(subjectRef.current) {
                setMailSubject(subjectRef.current.innerHTML)
              }
          }}
          // onChange={(event => {
          //   setMailSubject(event.target.value)
          // })}
        />
        {/*<div*/}
        {/*  className='mail-preview'*/}
        {/*  dangerouslySetInnerHTML={{ __html: mailBody }}/>*/}
        <div
          ref={bodyRef}
          className='mail-preview'
          dangerouslySetInnerHTML={{ __html: mailBody }}
          contentEditable
          onBlur={(event) => {
            if(bodyRef.current) {
              setMailBody(bodyRef.current.innerHTML)
            }
          }}
        />

      </div>


      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            text="취소"
            disabled={isSending}
            onClick={props.onClose}/>
          <Button
            text="이메일 발송"
            loading={isSending}
            disabled={mailBody.length < 1}
            onClick={async () => {
              if (to.length === 0) {
                AppToaster.show({
                  message: `수신인 정보를 확인하세요. (받는 사람 ${to.length}명)`,
                  intent: Intent.WARNING
                });
              } else {
                setIsSending(true);
                await props.inspection.sendComposedMailInspection(mailSubject, mailBody, to, cc, bcc);
                await props.onAfterSend();
                setIsSending(false);
                props.onClose();
              }
            }}/>
        </div>
      </div>
    </DialogWrapper>
  );
};

export default RecipientPopup;
