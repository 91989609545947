import React, { Component } from "react";
import styled from "styled-components/macro";
import { RufreeMatchingHistory } from "../../../store/models/RufreeMatchingHistory";
import moment from "moment";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { ChangeEvent } from "cleave.js/react/props";
import {Button, TextArea, Intent, InputGroup} from "@blueprintjs/core";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import {
  HistoryCommentResponse
} from "../../../types/rufreeMatching";

const StyledTextArea = styled(TextArea)`
  width: 100%;
`;

const Wrapper = styled.div`
  .focused {
    background-color: #feffbd;
    padding: 5px;
  }
`

const CommentWrapper = styled.div`
  white-space: pre-wrap;
  margin-bottom: 1em;

  .focused {
    background-color: red;
  }
`

const CommentMeta = styled.div``

const CommentText = styled.div``

interface Comment {
  id: number;
  createdAt: string;
  updatedAt: string;
  creator: string;
  isRufree: boolean;
  comment: string;
}

interface RufreeMatchingHistoryCommentProps {
  matchingId: number;
  historyId: number;
  comments: Comment[];
}

interface InjectedProps extends RufreeMatchingHistoryCommentProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class RufreeMatchingHistoryComment extends Component<RufreeMatchingHistoryCommentProps> {
  @observable comments: Comment[] = this.props.comments;
  @observable commentToRufree = '';
  @observable onSubmit: boolean = false;

  get injected() {
    return this.props as InjectedProps;
  }

  handleCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.commentToRufree = e.target.value;
  };

  handleCommentSave = async () => {
    try {
      if(this.commentToRufree){
        this.onSubmit = true;
        await this.injected.appStore.rufreeMatchingStore.saveRufreeHistoryComment(this.props.historyId, this.commentToRufree);

        // 메시지 발송 후, 리프레쉬
        let matching = await this.injected.appStore.rufreeMatchingStore.fetchRufreeMatching(this.props.matchingId);
        let history = matching.matchingHistory.find(
            x => x.id === this.props.historyId
        );
        if (history) {
          this.comments = history.comments
        }
        this.commentToRufree = '';
        this.onSubmit = false;

        AppToaster.show({
          message: "발송되었습니다.",
          intent: Intent.SUCCESS
        });
      }
    } catch (e) {
      const error = JSON.stringify(e.response.data);

      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  render() {
    const { comments } = this;

    return (
      <Wrapper>
        {comments.map(comment => (
          <CommentWrapper key={comment.id} className={ comment.isRufree ? 'focused' : '' }>
            <CommentMeta className="bp3-text-small bp3-text-muted">
              {moment(comment.updatedAt).format("YYYY-MM-DD HH:mm")} {comment.creator}
            </CommentMeta>
            <CommentText>
              {comment.comment}
            </CommentText>
          </CommentWrapper>
        ))}
        <StyledTextArea
          placeholder="메시지를 입력해주세요."
          value={this.commentToRufree}
          disabled={this.onSubmit}
          onChange={this.handleCommentChange}
        />
        <Button
          text="발송"
          disabled={this.onSubmit}
          className="bp3-small bp3-fill"
          onClick={this.handleCommentSave}
        />
      </Wrapper>
    );
  }
}

export default RufreeMatchingHistoryComment;
