import { types } from "mobx-state-tree";
import { ScheduleSprintModel } from "./ScheduleSprint";
import shortid from "shortid";

export const RufreeScheduleModel = types
  .model("RufreeSchedule", {
    id: types.optional(types.string, ""),
    rufreeId: types.maybe(types.string),
    rufreeName: types.maybe(types.string),
    inspectionId: types.maybe(types.string),
    role: types.maybe(types.string),
    sprints: types.array(ScheduleSprintModel),
    numInspectionSprints: types.number,
    numScheduleSprints: types.maybe(types.number)
  })
  .actions(self => ({
    afterCreate() {
      self.id = shortid();
    }
  }));

type RufreeScheduleType = typeof RufreeScheduleModel.Type;
export interface RufreeSchedule extends RufreeScheduleType {}
