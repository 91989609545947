import React, { Component } from "react";
// import { observable } from "mobx";
import styled, { css } from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { ScheduleSprintForm } from "../../../store/forms/ScheduleForm/ScheduleSprintForm";
import { Sprint } from "../../../store/models/Sprint";
import { Column } from "../../atoms/ScheduleForm/ScheduleForm";
import { Intent, Checkbox } from "@blueprintjs/core";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import { SprintCheck } from "../../../store/forms/SprintCheck";
import { Tooltip, Position } from "@blueprintjs/core";

const Wrapper = styled(Column)<{
  active?: boolean,
  complete?: boolean
}>`
  width: 200px;
  height: 35px;
  cursor: pointer;
  vertical-align: middle;
  position: relative;
  font-size: 12px;

  ${p =>
    !p.active &&
    css`
      background: #EBF1F5;
    `}
  ${p =>
    p.complete &&
    css`
      font-weight: 800;
      background: #BFCCD6;
      cursor: not-allowed;
    `}
`;

const CompleteSectionWrapper = styled.div`
  font-size: 12px;
`;

interface ScheduleSprintProps {
  completeSprint?: Sprint;
  sprint: ScheduleSprintForm;
  index: number;
  inspectionSprint?: SprintCheck;
  onClick: (sprint: ScheduleSprintForm, index: number) => void;
  onInspectionSprintCheck?: () => void;
}

interface InjectedProps extends ScheduleSprintProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ScheduleSprint extends Component<ScheduleSprintProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  handleClick = () => {
    const { sprint, index, onClick, inspectionSprint } = this.props;


    if(inspectionSprint && !inspectionSprint.value) {
      const error = <>
        <p>플랜에 체크되어 있지 않는 스프린트 입니다.</p>
        <p>스프린트의 추가/삭제는 해당 플랜 화면에서 진행 해주세요.</p>
      </>;
      AppToaster.show({
        message: error,
        intent: Intent.WARNING
      });
    } else {
      onClick(sprint, index);
    }
  };

  render() {
    const {
      completeSprint,
      sprint,
      inspectionSprint,
      onInspectionSprintCheck
    } = this.props;

    const isComplete = completeSprint && Boolean(completeSprint.dateCalculate);
    const completeSection = <CompleteSectionWrapper>
      <p>정산 완료된 스프린트입니다. (* 변경/삭제 불가)</p>
      정산일: { completeSprint && completeSprint.dateCalculate }<br/>
      정산금액: { completeSprint && completeSprint.calculatePayment.toLocaleString("en-US") }
    </CompleteSectionWrapper>
    const sprintSection = <>
      <div
        onClick={isComplete ? () => {} : this.handleClick}>
        {sprint.isValid || inspectionSprint ? (
          <div>
            {sprint.dateStart} ~ {sprint.dateEnd}
            <br />
            {sprint.manager}
          </div>
        ) : (
          <div style={{height: '35px'}}></div>
        )}
      </div>

      {/* <div>
        {
          inspectionSprint && inspectionSprint.value && (
            <div style={{ position: 'absolute', right: '0px', top: '17px' }}>
              <Checkbox
                checked={inspectionSprint.value}
                disabled={isComplete}
                onChange={(e) => {
                  // this.isInspectionSprintChecked = !this.isInspectionSprintChecked;
                  inspectionSprint.setValue(!inspectionSprint.value);

                  onInspectionSprintCheck && onInspectionSprintCheck();
                }} />
            </div>
          )
        }
      </div> */}
    </>

    return (
      <Wrapper
          active={inspectionSprint && inspectionSprint.value}
          complete={isComplete}
        >

        {
          isComplete ?
            <Tooltip
              content={completeSection}
              position={Position.BOTTOM}
            >
                { sprintSection }
            </Tooltip> :
            sprintSection
        }
      </Wrapper>
    );
  }
}

export default ScheduleSprint;
