import { types, flow } from "mobx-state-tree";
import { InspectionModel, Inspection, InspectionSimpleModel } from "./models/Inspection";
import {
  ContractResponse,
  ContractModel,
  ClientContractRequestModel,
  RufreeContractRequestModel,
  GenericContractRequestModel, RufreeContractRequestSimpleModel
} from "./models/ContractRequest";
import { ApprovedClientContractModel, ApprovedRufreeContractModel } from "./models/ApprovedContract";
import { ContractTemplateModel } from "./models/ContractTemplate";
import { RufreeResponse } from "../types/rufree";
import {
  ClientContractResponse,
  ClientContractListResponse,
  RufreeContractResponse,
  RufreeContractListResponse,
  ContractTemplateResponse,
  ApprovedClientContractListResponse,
  ApprovedRufreeContractListResponse,
  ApprovedClientContractResponse,
  ApprovedRufreeContractResponse, GenericContractRequestListResponse, GenericContractRequestResponse
} from "../types/contract";
import axios from "axios";
import moment from "moment";
import sortBy from "lodash/sortBy";
import { mapRufree } from "./RufreeStore";
import { mapInspection } from "./InspectionStore";
import { ClientModel } from "./models/Client";
import { mapDataToClient } from "./ClientStore";


const mapContract = (x: ContractResponse) => {
  //TODO: 삭제필요!!
  return {
    id: x.id,
    projectName: x.project_name || "",
    clientId: x.client_id || ""
  };
};

export const mapGenericContractRequest = (x: GenericContractRequestResponse) => {
  return {
    contractId: x.contract_id,
    contractName: x.contract_name,
    contractTarget: x.contract_target,
    contractorId: x.contractor_id,
    contractorName: x.contractor_name,
    pmUserName: x.pm_user_name,
    contractStatus: x.contract_status,
    contractUpdatedAt: x.contract_updated_at,
    contractCreatedAt: x.contract_created_at
  };
};

export const mapClientContract = (x: ClientContractResponse) => {
  return {
    id: x.id,
    contractId: x.contract_id,
    createdAt: x.created_at,
    updatedAt: x.updated_at,
    contractName: x.contract_name || "",
    address: x.address || "",
    representativeName: x.representative_name || "",
    contractorName: x.contractor_name || "",
    contractorEmail: x.contractor_email || "",
    contractorPhone: x.contractor_phone || "",
    taxInvoiceManagerName: x.tax_invoice_manager_name || "",
    taxInvoiceManagerEmail: x.tax_invoice_manager_email || "",
    taxInvoiceManagerContact: x.tax_invoice_manager_contact || "",
    nda: x.nda || false,
    contractDate: x.contract_date || "",
    contractFeeRate: x.contract_fee_rate || "",
    contractRange: x.contract_range || "",
    specialContract: x.special_contract || "",
    comment: x.comment || "",
    status: x.status,
    client: x.client || "",
    clientDetail: x.client_detail ? ClientModel.create(mapDataToClient(x.client_detail)) : null,
    inspections: x.inspections,
    inspectionsDetail: x.inspections_detail.map(data => InspectionSimpleModel.create({
        inspectionId: data.inspection_id,
        title: data.title,
        nameWithId: `${data.inspection_id} - ${data.title}`
      })),
    pmUser: x.pm_user,
    pm_user_detail: x.pm_user_detail,
    managerName: x.manager_name,
    projectGroup: x.project_group || "",
    contractorNumber: x.contractor_number || "",
    contractorIdentityNumber: x.contractor_identity_number || "",
    approveRequester: x.approve_requester || null,
    contractType: x.contract_type
  };
};

export const mapRufreeContractSimple = (x: RufreeContractResponse) => {
  return {
    contractId: x.contract_id,
    contractName: x.contract_name || "",
    rufreeUser: x.rufreeUser || "",
    contractDate: x.contract_date,
    sprintPeriod: x.sprint_period || "",
    contractRange: x.contract_range,
    specialContract: x.special_contract,
    comment: x.comment,
  };
};

export const mapRufreeContract = (x: RufreeContractResponse) => {
  return {
    id: x.id,
    contractId: x.contract_id,
    createdAt: x.created_at,
    updatedAt: x.updated_at,
    contractName: x.contract_name || "",
    info: x.info,
    agentName: x.agent_name,
    agentEmail: x.agent_email,
    agentAddress: x.agent_address,
    address: x.address,
    contractDate: x.contract_date,
    sprintPeriod: x.sprint_period,
    payment: x.payment,
    commission: x.commission,
    paymentFee: x.payment_fee,
    commissionFee: x.commission_fee,
    contractRange: x.contract_range,
    specialContract: x.special_contract,
    comment: x.comment,
    status: x.status,
    rufreeUser: x.rufreeUser,
    rufreeUserDetail: x.rufreeUserDetail && mapRufree(x.rufreeUserDetail),
    inspections: x.inspections,
    inspectionsDetail: x.inspections_detail.map(data => InspectionSimpleModel.create({
      inspectionId: data.inspection_id,
      title: data.title,
      nameWithId: `${data.inspection_id} - ${data.title}`
    })),
    pmUser: x.pm_user,
    pm_user_detail: x.pm_user_detail,
    managerName: x.manager_name,
    projectGroup: x.project_group || "",
    approveRequester: x.approve_requester || null,
    contractType: x.contract_type
  };
};

export const mapApprovedClientContract = (x: ApprovedClientContractResponse) => {
  return {
    approvedContractId: x.approved_contract_id,
    contractorId: x.contractor_id || "",
    status: x.status,
    contractorName: x.contractor_name,
    clientContract: x.client_contract || "",
    template: x.template || "",
    templateName: x.template_name,
    commentOpened: x.comment_opened,
    contractName: x.contract_name || "",
    createdAt: x.created_at,
    updatedAt: x.updated_at,
    editorContent: x.editor_content,
    managerName: x.manager_name,
    managerId: x.manager_id
  };
};

export const mapApprovedRufreeContract = (x: ApprovedRufreeContractResponse) => {
  return {
    approvedContractId: x.approved_contract_id,
    contractorId: x.contractor_id || "",
    status: x.status,
    contractorName: x.contractor_name,
    rufreeContract: x.rufree_contract || "",
    template: x.template || "",
    templateName: x.template_name,
    commentOpened: x.comment_opened,
    contractName: x.contract_name,
    createdAt: x.created_at,
    updatedAt: x.updated_at,
    editorContent: x.editor_content,
    managerName: x.manager_name,
    managerId: x.manager_id
  };
};

export const ContractStoreModel = types
  .model("ContractStore", {
    contracts: types.array(ContractModel), //TODO: 삭제필요!!
    allRequestContracts: types.array(GenericContractRequestModel),
    clientContracts: types.array(ClientContractRequestModel),
    rufreeContracts: types.array(RufreeContractRequestModel),
    unUsedClientContracts: types.array(ClientContractRequestModel),
    unUsedRufreeContracts: types.array(RufreeContractRequestModel),
    approvedClientContracts: types.array(ApprovedClientContractModel),
    approvedRufreeContracts: types.array(ApprovedRufreeContractModel),
    templates: types.array(ContractTemplateModel),

    // orderingList: types.array(types.string),
    currentPage: types.optional(types.number, 1),
    pageGroup: types.optional(types.number, 1),
    pageSize: types.optional(types.number, 20),
    totalPages: types.optional(types.number, 1)
  })
  .views(self => ({
    contractById(id: number) {
      return self.contracts.find(x => x.id === id);
    },
    get sortedClientContracts() {
      return sortBy(self.clientContracts.slice(), contract => moment(contract.updatedAt)).reverse();
    },
    get sortedRufreeContracts() {
      return sortBy(self.rufreeContracts.slice(), contract => moment(contract.updatedAt)).reverse();
    },
    get sortedAllRequestContracts() {
      return sortBy(self.allRequestContracts.slice(), contract => moment(contract.contractUpdatedAt)).reverse();
    },

    get sortedTemplates() {
      return sortBy(self.templates.slice(), template => moment(template.id)).reverse();
    },
    get unUsedRufreeContractsSimple() {
      return self.unUsedRufreeContracts.map(x =>
        RufreeContractRequestSimpleModel.create({
          ...x
        })
      );
    }
  }))
  .actions(self => ({
    // setOrderingList(list:string[]){
    //   self.orderingList.replace(list);
    // },
    setCurentPage(value: number) {
      self.currentPage = value;
    },
    setPageGroup(value: number) {
      self.pageGroup = value;
    }
  }))
  .actions(self => {
    const fetchContracts = flow(function* () {
      //TODO: 삭제필요!!
      try {
        const { data }: { data: ContractResponse[] } = yield axios.get(
          "/clientContract"
        );

        const contracts = data.map(x =>
          ContractModel.create(mapContract(x))
        );

        self.contracts.replace(contracts);
      } catch (e) {
        throw e;
      }
    });
    const fetchAllRequestContracts = flow(function* ({
                                                       status,
                                                       searchText,
                                                       orderingList
                                                     }: { status: string; searchText: string; orderingList: string[]; }) {
      try {
        const ordering: string[] = [];
        orderingList.forEach(subject => {
          const sign = subject.includes("+") ? "" : "-";
          if (subject.includes("contractId")) {
            ordering.push(`${sign}request_contract_list_rc__contract_id`);
            ordering.push(`${sign}request_contract_list_cc__contract_id`);
          }
          if (subject.includes("createdAt")) {
            ordering.push(`${sign}request_contract_list_rc__created_at`);
            ordering.push(`${sign}request_contract_list_cc__created_at`);
          }
          if (subject.includes("updatedAt")) {
            ordering.push(`${sign}request_contract_list_rc__updated_at`);
            ordering.push(`${sign}request_contract_list_cc__updated_at`);
          }
        });
        const { data }: { data: GenericContractRequestListResponse } = yield axios.get(
          `/requestContract`,
          {
            params: {
              page: self.currentPage,
              page_size: self.pageSize,
              status: status,
              search: searchText,
              ordering: ordering.join()
            }
          }
        );
        const contracts = data.results.map(x => GenericContractRequestModel.create(mapGenericContractRequest(x)));
        self.totalPages = data.total_pages;

        self.allRequestContracts.replace(contracts);
      } catch (e) {
        throw e;
      }
    });
    const fetchClientContractById = flow(function* (id: string) {
      const { data }: { data: ClientContractResponse } = yield axios.get(
        `/clientContract/${id}`
      );
      const contract = ClientContractRequestModel.create(mapClientContract(data));
      contract.fetchComments();

      self.clientContracts.replace([contract]);
    });

    const fetchClientContracts = flow(function* ({
                                                   status,
                                                   searchText,
                                                   orderingList
                                                 }: {
      status: string; searchText: string; orderingList: string[];
    }) {
      try {
        const ordering: string[] = [];
        orderingList.forEach(subject => {
          const sign = subject.includes("+") ? "" : "-";
          if (subject.includes("contractId")) {
            ordering.push(`${sign}contract_id`);
          }
        });
        console.log("client contracts");
        const { data }: { data: ClientContractListResponse } = yield axios.get(
          `/clientContract`,
          {
            params: {
              page: self.currentPage,
              page_size: self.pageSize,
              status: status,
              search: searchText,
              ordering: ordering.join()
            }
          }
        );

        const contracts = data.results.map(x => ClientContractRequestModel.create(mapClientContract(x)));
        console.log("contracts", contracts);
        self.totalPages = data.total_pages;

        self.clientContracts.replace(contracts);
      } catch (e) {
        throw e;
      }
    });

    const fetchUnusedClientContracts = flow(function* () {
      try {
        const { data }: { data: ClientContractResponse[] } = yield axios.get(
          `/clientContract/getUnused`
        );

        const contracts = data.map(x => ClientContractRequestModel.create(mapClientContract(x)));
        self.unUsedClientContracts.replace(contracts);
      } catch (e) {
        throw e;
      }
    });

    // 알유프리 계약 요청서
    const fetchRufreeContractById = flow(function* (id: string) {
      const { data }: { data: RufreeContractResponse } = yield axios.get(
        `/rufreeContract/${id}`
      );
      const contract = RufreeContractRequestModel.create(mapRufreeContract(data));
      contract.fetchComments();

      self.rufreeContracts.replace([contract]);
    });

    const fetchRufreeContracts = flow(function* ({
                                                   status,
                                                   searchText,
                                                   orderingList
                                                 }: {
      status: string; searchText: string; orderingList: string[];
    }) {
      try {
        const ordering: string[] = [];
        orderingList.forEach(subject => {
          const sign = subject.includes("+") ? "" : "-";
          if (subject.includes("contractId")) {
            ordering.push(`${sign}contract_id`);
          }
        });
        const { data }: { data: RufreeContractListResponse } = yield axios.get(
          `/rufreeContract`,
          {
            params: {
              page: self.currentPage,
              page_size: self.pageSize,
              status: status,
              search: searchText,
              ordering: ordering.join()
            }
          }
        );

        const contracts = data.results.map(x => RufreeContractRequestModel.create(mapRufreeContract(x)));

        self.totalPages = data.total_pages;
        self.rufreeContracts.replace(contracts);
      } catch (e) {
        throw e;
      }
    });

    const fetchUnusedRufreeContracts = flow(function* () {
      try {
        const { data }: { data: RufreeContractResponse[] } = yield axios.get(
          `/rufreeContract/getUnused`
        );

        const contracts = data.map(x => RufreeContractRequestModel.create(mapRufreeContract(x)));
        self.unUsedRufreeContracts.replace(contracts);
      } catch (e) {
        throw e;
      }
    });

    const fetchTemplates = flow(function* () {
      try {
        const { data }: { data: ContractTemplateResponse[] } = yield axios.get(
          "/contractTemplate"
        );

        const templates = data.map(x =>
          ContractTemplateModel.create(x)
        );
        self.templates.replace(templates);
      } catch (e) {
        console.log(e);
        throw e;
      }
    });

    const fetchApprovedClientContracts = flow(function* ({
                                                           status,
                                                           searchText
                                                         }: {
      status: string;
      searchText: string;
    }) {
      try {
        const { data }: { data: ApprovedClientContractListResponse } = yield axios.get(
          "/approvedClientContract",
          {
            params: {
              page: self.currentPage,
              page_size: self.pageSize,
              client_contract__status: status,
              search: searchText
            }
          }
        );

        const contracts = data.results.map(x => ApprovedClientContractModel.create({
          ...mapApprovedClientContract(x),
          contractComments: []
        }));
        self.totalPages = data.total_pages;
        self.approvedClientContracts.replace(contracts);
      } catch (e) {
        throw e;
      }
    });

    const fetchApprovedClientContractById = flow(function* (id: string) {
      const { data }: { data: ApprovedClientContractResponse } = yield axios.get(
        `/approvedClientContract/${id}`
      );
      const contract = ApprovedClientContractModel.create(mapApprovedClientContract(data));
      contract.fetchComments();

      self.approvedClientContracts.replace([contract]);
      return contract;
    });

    const fetchApprovedRufreeContracts = flow(function* ({
                                                           status,
                                                           searchText
                                                         }: {
      status: string;
      searchText: string;
    }) {
      try {
        const { data }: { data: ApprovedRufreeContractListResponse } = yield axios.get(
          "/approvedRufreeContract",
          {
            params: {
              page: self.currentPage,
              page_size: self.pageSize,
              rufree_contract__status: status,
              search: searchText
            }
          }
        );

        const contracts = data.results.map(x => ApprovedRufreeContractModel.create({
          ...mapApprovedRufreeContract(x),
          contractComments: []
        }));
        self.totalPages = data.total_pages;
        self.approvedRufreeContracts.replace(contracts);
      } catch (e) {
        throw e;
      }
    });

    const fetchApprovedRufreeContractById = flow(function* (id: string) {
      const { data }: { data: ApprovedRufreeContractResponse } = yield axios.get(
        `/approvedRufreeContract/${id}`
      );
      const contract = ApprovedRufreeContractModel.create(mapApprovedRufreeContract(data));
      contract.fetchComments();

      // self.approvedClientContracts.replace([contract]);
      return contract;
    });
    return {
      fetchContracts,
      fetchAllRequestContracts,
      fetchClientContractById,
      fetchClientContracts,
      fetchUnusedClientContracts,
      fetchRufreeContractById,
      fetchRufreeContracts,
      fetchUnusedRufreeContracts,
      fetchTemplates,
      fetchApprovedClientContracts,
      fetchApprovedClientContractById,
      fetchApprovedRufreeContracts,
      fetchApprovedRufreeContractById
    };
  });

type ContractStoreType = typeof ContractStoreModel.Type;

export interface ContractStore extends ContractStoreType {
}
