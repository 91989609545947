import styled from "styled-components/macro";

export const TableHeader = styled.div`
  display: flex;
  margin-bottom: 21px;
`;

export const Header = styled.div`
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7f8390;
  text-align: center;
  width: 100%;
`;

export const Item = styled.div`
  border: solid 1px #eeeeee;
  background-color: #ffffff;
  min-height: 100px;
  border-radius: 4px;
  box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.08);
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Column = styled.div`
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #161d2e;
  width: 100%;
  word-break: keep-all;
`;
