export const MAX_SPRINTS = 21;

export enum EProjectGroupTool {
  TRELLO = 'trello',
  SLACK = 'slack',
  ZEPLIN = 'zeplin',
  GDRIVE = 'gdrive',
  GITHUP = 'githup',
  HANGOUT = 'hangout'
}

export const SNS_TYPE = {
  'github': {
    'name': 'Github',
    'keyword': 'github.io'
  },
  'dribbble': {
    'name': 'Dribbble',
    'keyword': 'dribbble.com'
  },
  'medium': {
    'name': 'Medium',
    'keyword': 'medium.com'
  },
  'notion': {
    'name': 'Notion',
    'keyword': 'notion.so',
  },
  'instagram': {
    'name': 'Instagram',
    'keyword': 'instagram.com'
  },
  'facebook': {
    'name': 'Facebook',
    'keyword': 'facebook.com'
  },
  'linkedin': {
    'name': 'Linkedin',
    'keyword': 'linkedin.com'
  },
  'behance': {
    'name': 'Behance',
    'keyword': 'behance.net'
  },
  'youtube': {
    'name': 'Youtube',
    'keyword': 'youtube.com'
  }
};
