import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import styled from "styled-components/macro";
import { ReferenceFileForm } from "../../../store/forms/ParttimeForm/ReferenceFileForm";
import { Button, Intent } from "@blueprintjs/core";

interface ReferenceFileFormProps {
    referenceFile: ReferenceFileForm;
    onRemove: (referenceFile: ReferenceFileForm) => void;
}

interface InjectedProps extends ReferenceFileFormProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ReferenceFileFormRow extends Component<ReferenceFileFormProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  handleRemoveReferenceFile = () => {
      const { referenceFile, onRemove } = this.props;
      onRemove(referenceFile);
  };

  render(){
    const { referenceFile } = this.props;
    
    return (
        <InputRow>
            <Button
                icon="minus"
                minimal={true}
                small={true}
                intent={Intent.DANGER}
                onClick={this.handleRemoveReferenceFile}
            />
            <ReferenceFileRow>{referenceFile.name}</ReferenceFileRow>
        </InputRow>
)};
}

export default ReferenceFileFormRow;

const InputRow = styled.div`
  display: flex;
  padding: 7px 0 0 3px;

  & > * + * {
    margin-left: 5px;
  }
`;

const ReferenceFileRow = styled.div``;