import React, { Component, KeyboardEvent } from "react";
import styled from "styled-components/macro";
import moment from "moment";
import { inject, observer } from "mobx-react";
import { ChangeEvent } from "cleave.js/react/props";
import { AppStore } from "../../../store/AppStore";
import { observable } from "mobx";
import Paginator from "../../molecules/Paginator/Paginator";
import RufreeCalculateRow from "../../molecules/CalculateRow/RufreeCalculateRow";
import {
  Icon,
  HTMLTable, 
  Classes, 
  Button, 
  Intent, 
  InputGroup,
  HTMLSelect,
  IOptionProps,
  Spinner
} from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import { getMomentFormatter } from "../../../utils/date";
import { 
  ToolsRow,
  InputContainer,
  RightTools
} from "../../atoms/CalculateTable/CalculateTable";
import SendDialog from "../../organisms/NotificationTemplate/SendDialog";
import { ETEMPLATETYPE } from "../../../store/models/NotificationTemplate";
import { FormMode } from "../../../types/formMode";

const ToolLabel = styled.div`
  margin-top: 5px;
`;
const DateInputWrapper = styled(DateInput)`
  .bp3-input {
    width: 100px;
  }
`;
const ServiceListButton = styled(Button)`
  margin-left: 10px;
`

const OrderByIcon = styled(Icon)`
  float: right;
  margin-top: 3px;
`;
const SummaryTR = styled.tr`
  td {
    box-shadow: inset 0 1px 0 0 rgba(16, 22, 26, 0.15);
    color: #106ba3 !important;
    font-size: 15px;
    font-weight: bold;
    text-align: right !important;
  }
`;

// interface ParttimeCalculateListPanelProps extends RouteComponentProps {}

interface ParttimeCalculateListPanelProps {
  isSupportPM: boolean;
}

interface InjectedProps extends ParttimeCalculateListPanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ParttimeCalculateListPanel extends Component<ParttimeCalculateListPanelProps> {
  @observable readyToShow = false;
  @observable statusFilter = "";
  @observable searchText = "";
  @observable is_filtered_notpaid = false;
  @observable is_filtered_datepayment = false;
  // @observable searchCreatedAtAfter: string | null = null;
  // @observable searchCreatedAtBefore: string | null = null;
  @observable searchCreatedAtAfter: Date | null = null;
  @observable searchCreatedAtBefore: Date | null = null;
  @observable serviceListMonth = moment().format('YYYY-MM');
  @observable order_by = 'id';
  @observable order_asc = false;

  get injected() {
    return this.props as InjectedProps;
  }

  get calculateStore() {
    return this.injected.appStore.calculateStore;
  }

  get notificationStore() {
    return this.injected.appStore.notificationTemplateStore;
  }

  handleOrderBy = async (by: string, asc: boolean=false) => {
    this.order_asc = this.order_by === by ? asc : false;
    this.order_by = by;

    await this.handleSearch();
  }

  handleSearch = async () => {
    this.readyToShow = false;
    
    await this.calculateStore.fetchRufreeCalculate(
      this.order_by,
      this.order_asc,
      this.statusFilter,
      this.searchText,
      this.searchCreatedAtAfter ? moment(this.searchCreatedAtAfter).format('YYYY-MM-DD') : '',
      this.searchCreatedAtBefore ? moment(this.searchCreatedAtBefore).format('YYYY-MM-DD') : '',
      'parttime'
    );

    this.is_filtered_notpaid = this.statusFilter === 'notpaid' ? true : false;
    this.is_filtered_datepayment = this.searchCreatedAtAfter || this.searchCreatedAtBefore ? true : false;
    
    this.readyToShow = true;
  };

  handleClickSearchButton = async () => {
    this.calculateStore.setCurentPage(1);
    this.calculateStore.setPageGroup(1);

    await this.handleSearch();
  }
  handleSearchCreatedAtAfter = (selectedDate: Date) => {
    this.searchCreatedAtAfter = selectedDate;
  };
  handleSearchCreatedAtBefore = (selectedDate: Date) => {
    this.searchCreatedAtBefore =selectedDate;
  };
  handleSearchText = (e: any) => {
    this.searchText = e.target.value;
  };
  handleSearchTextKeyDown = async(event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      await this.handleSearch();
    }
  }
  handleFilterSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    this.statusFilter = e.currentTarget.value;

    if(e.currentTarget.value === 'notpaid') {
      this.searchCreatedAtAfter = null;
      this.searchCreatedAtBefore = null;
    }
  };
  handleMonthSelect = (e: any) => {
    this.serviceListMonth = e.target.value;
  }
  handleServiceList = () => {
    this.calculateStore.downloadRufreeCalculateCSV(this.serviceListMonth);
  };
  handleNotPaidList = () => {
    this.calculateStore.downloadNotpaidRufreeCalculateCSV();
  };

  async componentDidMount() {
    this.calculateStore.setCurentPage(1);
    this.calculateStore.setPageGroup(1);

    await this.handleSearch();
  }

  render() {
    const statusOptions: IOptionProps[] = [
      {label: '전체', value: ''},
      {label: '지급완료', value: 'paid'},
      {label: '미지급', value: 'notpaid'}
    ]

    const date_for_month = moment('2019-12-01');
    const monthOptions: IOptionProps[] = [...Array(moment().diff(date_for_month, 'months'))].map((value, index) => {
      date_for_month.add(1, 'months');
      return { label: date_for_month.format('YYYY년 MM월'), value: date_for_month.format('YYYY-MM') }
    });

    const { 
      rufreeCalculates,
      pageGroup,
      totalPages,
      currentPage,
      setPageGroup,
      setCurentPage
    } = this.calculateStore;

    const OrderIcon = <OrderByIcon
      icon={this.order_asc ? 'arrow-up' : 'arrow-down'}
      iconSize={12}
      intent={Intent.PRIMARY} />;

    return (
      <>
        <ToolsRow>
          <HTMLSelect
            value={this.statusFilter}
            onChange={this.handleFilterSelect}
            options={statusOptions}
          />
          <ToolLabel>지급일:</ToolLabel>
          <DateInputWrapper
            {...getMomentFormatter("YYYY/MM/DD")}
            locale="ko"
            showActionsBar={true}
            placeholder={"시작일"}
            closeOnSelection={true}
            value={this.searchCreatedAtAfter}
            onChange={this.handleSearchCreatedAtAfter}
            maxDate={new Date("2050-01-01")}
            disabled={this.statusFilter === 'notpaid' ? true : false}

          />
          <ToolLabel>~</ToolLabel>
          <DateInputWrapper
            {...getMomentFormatter("YYYY/MM/DD")}
            locale="ko"
            showActionsBar={true}
            placeholder={"종료일"}
            closeOnSelection={true}
            value={this.searchCreatedAtBefore}
            onChange={this.handleSearchCreatedAtBefore}
            maxDate={new Date("2050-01-01")}
            disabled={this.statusFilter === 'notpaid' ? true : false}
          />

          <InputContainer>
            <InputGroup
              placeholder="번호/클라이언트/담당PM/알유프리/프로젝트번호(or 명)"
              value={this.searchText}
              onChange={this.handleSearchText}
              onKeyDown={this.handleSearchTextKeyDown}
            />
          </InputContainer>

          <Button
            icon="search"
            text="검색"
            intent={Intent.PRIMARY}
            onClick={this.handleClickSearchButton}
          />
        </ToolsRow>

        <HTMLTable
          className={Classes.SMALL}
          striped={true}
          interactive={true}
          style={{ width: "100%", tableLayout: "fixed" }}
        >
          <thead>
            <tr>
              <th
                style={{width: '80px', cursor: 'pointer', color: '#106ba3'}}
                onClick={() => {this.handleOrderBy('id', !this.order_asc)}}
              >
                번호
                {
                  this.order_by === 'id' && OrderIcon
                }
                
              </th>
              <th style={{width: '100px'}}>프로젝트그룹</th>
              <th style={{width: '100px'}}>시간제태스크</th>
              <th style={{width: '200px'}}>클라이언트명</th>
              <th style={{width: '110px'}}>알유프리명</th>
              <th style={{width: '70px'}}>담당PM</th>
              <th style={{width: '100px'}}>알유프리비용</th>
              <th style={{width: '70px'}}>세액</th>
              <th style={{width: '100px'}}>실지급액</th>
              <th
                style={{width: '120px', cursor: 'pointer', color: '#106ba3'}}
                onClick={() => this.handleOrderBy('created_at', !this.order_asc)}
              >
                정산요청일
                {
                  this.order_by === 'created_at' && OrderIcon
                }
              </th>
              <th
                style={{width: '120px', cursor: 'pointer', color: '#106ba3'}}
                onClick={() => this.handleOrderBy('date_payment', !this.order_asc)}
              >
                지급일
                {
                  this.order_by === 'date_payment' && OrderIcon
                }
              </th>
              <th style={{width: '150px'}}>지급관련메모</th>
              <th style={{width: '150px'}}>비고</th>
              <th style={{width: '150px'}}></th>
            </tr>
          </thead>

          <tbody>

            {
              this.readyToShow ? (
                rufreeCalculates
                .map((calculate, index) => (
                  <RufreeCalculateRow
                    key={index}
                    editable={this.props.isSupportPM}
                    calculate={calculate}
                    onSearch={this.handleSearch}
                    onClickSend={ async (phone: string) => {
                      this.notificationStore.setPhones([phone]);
                      this.notificationStore.setFormMode(FormMode.Read);
                      this.notificationStore.setOpenPopup(true);
                    } } />
                ))
              ) : (
                <tr>
                  <td colSpan={12}>
                    <Spinner />
                  </td>
                </tr>
              )
            }

            {
              this.is_filtered_datepayment && (
                <SummaryTR>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{rufreeCalculates.length} 스프린트</td>
                  <td></td>
                  <td>{this.calculateStore.totalRufreeCalculatesExpectedPayment.toLocaleString("en-US")}</td>
                  <td></td>
                  <td>{this.calculateStore.totalRufreeCalculatesPayment.toLocaleString("en-US") }</td>
                  <td></td>
                  <td></td>
                  <td>
                  </td>
                </SummaryTR>
              )
            }

          </tbody>

        </HTMLTable>

        <Paginator
          pageGroup={pageGroup}
          totalPages={totalPages}
          currentPage={currentPage}
          setPageGroup={setPageGroup}
          setCurentPage={setCurentPage}
          onFetch={this.handleSearch}
        />

        <SendDialog
          isOnlySmsMode={ true }
          templateType={ETEMPLATETYPE.SMS}
          restrictedCategories={['SUPPORT', 'ETC']} />
      </>
    );
  }
}

export default ParttimeCalculateListPanel;
