import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import InspectionRow from "../../molecules/InspectionRow/InspectionRow";
import { Inspection } from "../../../store/models/Inspection";
import { RouteComponentProps } from "react-router";
import LargeContainer from "../../atoms/LargeContainer/LargeContainer";
import {
  PageNavText,
  PageNavWrapper,
  PageNavLink
} from "../../atoms/PageNav/PageNav";
import { AddCircle } from "styled-icons/remix-line/AddCircle";
import { TableHeader, Header } from "../../atoms/CardTable/CardTable";
import { computed, observable } from "mobx";
import Paginator from "../../molecules/Paginator/Paginator";
import { Button, H3, InputGroup, Intent, Spinner } from "@blueprintjs/core";
import styled from "styled-components/macro";
import { ChangeEvent } from "cleave.js/react/props";

interface InspectionListPanelProps extends RouteComponentProps {
}

interface InjectedProps extends InspectionListPanelProps {
  appStore: AppStore;
}

const ToolsRow = styled.div`
  margin-bottom: 40px;
  display: flex;
  height: 20px;

  & > * + * {
    margin-left: 10px;
  }
`;

const InputContainer = styled.div`
  max-width: 200px;
  margin-bottom: 30px;
`;

@inject("appStore")
@observer
class InspectionListPanel extends Component<InspectionListPanelProps> {
  @observable readyToShow = false;
  @observable search = "";


  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get inspections() {
    const { userStore, inspectionStore } = this.injected.appStore;

    return userStore.isPM
      ? inspectionStore.sortedInspections
      : inspectionStore.onlyPublishedInspections;
  }

  searchList = async () => {
    await this.injected.appStore.inspectionStore.setCurentPage(1);
    await this.injected.appStore.inspectionStore.setPageGroup(1);
    await this.fetchList();
  };

  private fetchList = async () => {
    this.readyToShow = false;
    try {
      await this.injected.appStore.inspectionStore.fetchPageableInspections({ search: this.search });
    } catch (e) {
    }
    this.readyToShow = true;
  };

  async componentDidMount() {
    try {
      await this.injected.appStore.clientStore.fetchClients();
      await this.fetchList();
    } catch (e) {
    }
  }

  handleInspectionClick = (inspection: Inspection) => {
    this.props.history.push(`/inspections/${inspection.inspectionId}/read`);
  };

  render() {
    const { isPM } = this.injected.appStore.userStore;
    const {
      pageGroup,
      totalPages,
      currentPage,
      setPageGroup,
      setCurentPage
    } = this.injected.appStore.inspectionStore;

    return (
      <LargeContainer>
        <PageNavWrapper>
          <H3>프로젝트 플랜</H3>
          {isPM && (
            <PageNavLink to="/inspections/new">
              <PageNavText>
                <AddCircle size={21}/> <span>새 플랜</span>
              </PageNavText>
            </PageNavLink>
          )}
        </PageNavWrapper>
        <ToolsRow>
          <InputContainer>
            <InputGroup
              placeholder="번호/제목/클라이언트명"
              value={this.search}
              onChange={(e: ChangeEvent<HTMLInputElement>) => this.search = e.target.value}
              onKeyPress={(event) => {
                if (event.key === "Enter")
                  this.searchList();
              }}
            />
          </InputContainer>
          <Button
            icon="search"
            text="검색"
            intent={Intent.PRIMARY}
            onClick={this.searchList}
          />
        </ToolsRow>
        <TableHeader>
          <Header style={{ maxWidth: "141px" }}>번호</Header>
          <Header style={{ maxWidth: "287px" }}>제목</Header>
          <Header style={{ maxWidth: "292px" }}>클라이언트명</Header>
          <Header style={{ maxWidth: "168px" }}>최종수정일</Header>
          <Header style={{ maxWidth: "166px" }}>최초발행일</Header>
          <Header style={{ maxWidth: "166px" }}>의뢰접수일</Header>
          <Header style={{ maxWidth: "112px" }}>발행여부</Header>
        </TableHeader>
        {this.readyToShow ?
          this.inspections.map(inspection => (
            <InspectionRow
              key={inspection.inspectionId}
              inspection={inspection}
              onClick={() => this.handleInspectionClick(inspection)}
            />
          ))
          : <Spinner key={"rufreeListSpinner"}/>}
        <div style={{ marginTop: "15px" }}>
          <Paginator
            pageGroup={pageGroup}
            totalPages={totalPages}
            currentPage={currentPage}
            setPageGroup={setPageGroup}
            setCurentPage={setCurentPage}
            onFetch={() => this.fetchList()}
          />
        </div>
      </LargeContainer>
    );
  }
}

export default InspectionListPanel;
