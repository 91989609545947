import { types, getParent } from "mobx-state-tree";
import shortid from "shortid";
import moment from "moment";

export const ScheduleSprintModel = types
  .model("ScheduleSprint", {
    id: types.optional(types.string, ""),
    dateStart: types.maybe(types.string),
    dateEnd: types.maybe(types.string),
    manager: types.maybe(types.string),
    isValid: types.optional(types.boolean, false)
  })
  .views(self => ({
    get duration() {
      const start = moment(self.dateStart);
      const end = moment(self.dateEnd);
      return moment.duration(end.diff(start)).asDays() + 1;
    },
    get sprintNumber() {
      return getParent(self, 2).sprints.findIndex(
        (x: ScheduleSprint) => x.id === self.id
      );
    },
    get sprintSequentialNumber() {
     return getParent(self, 2).sprints.filter((x: ScheduleSprint) => x.isValid).findIndex(
        (x: ScheduleSprint) => x.id === self.id
      );
    }
  }))
  .views(self => ({
    get sprintName() {
      return self.sprintNumber === 0 ? "PRE" : "S" + self.sprintNumber;
    }
  }))
  .actions(self => ({
    afterCreate() {
      self.id = shortid();
    },
    setValid(value: boolean) {
      self.isValid = value;
    }
  }));

type ScheduleSprintType = typeof ScheduleSprintModel.Type;
export interface ScheduleSprint extends ScheduleSprintType {}
