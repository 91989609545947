import { types } from "mobx-state-tree";
import axios from "axios";

import {
  INotificationLogResponse,
 } from "../../types/notificationLog";
 import {
   NotificationTemplateSimpleModel
 } from "./NotificationTemplate";
 

export const NotificationLogReceiverModel = types
  .model("NotificationLogReceiver", {
    to_text: types.array(types.string),
    to_mail: types.array(types.string),
    bcc: types.array(types.string),
    cc: types.array(types.string)
  })

export const NotificationLogSenderModel = types
  .model("NotificationLogSender", {
    id: types.number,
    email: types.string,
    name: types.string,
  })


export const NotificationLogModel = types
  .model("NotificationLog", {
    id: types.identifierNumber,
    template: types.maybeNull(NotificationTemplateSimpleModel),
    receiver: NotificationLogReceiverModel,
    dateCreated: types.string,
    sender: types.maybeNull(NotificationLogSenderModel)
  })
  .views(self => ({
  }))
  .actions(self => ({
  }))
  .actions(self => {
    
    return {
    };
  });

type NotificationLogType = typeof NotificationLogModel.Type;
export interface INotificationLog extends NotificationLogType {}


export const mapper = (x: INotificationLogResponse) => {
  return {
    id: x.id,
    template: x.template,
    receiver: x.receiver,
    dateCreated: x.date_created,
    sender: x.sender
  }
}