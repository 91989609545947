import React, { Component, ChangeEvent } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import FormContainer from "../../atoms/FormContainer/FormContainer";
import FormSection from "../../atoms/FormSection/FormSection";
import { FormGroup, Button, Intent, H3, Spinner, TextArea } from "@blueprintjs/core";
import ContentContainer from "../../atoms/ContentContainer/ContentContainer";
import { FormMode } from "../../../types/formMode";
import { RouteComponentProps, Redirect } from "react-router";
import { computed, observable } from "mobx";
import { DateInput } from "@blueprintjs/datetime";
import { getMomentFormatter } from "../../../utils/date";
import moment from "moment";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";

const Wrapper = styled.div``;

const Padding = styled.div`
  height: 30px;
`;

const ButtonsRow = styled.div`
  margin-bottom: 50px;
  & > * + * {
    margin-left: 5px;
  }
`;

const TextContainer = styled.div`
  margin-top: 10px;
`;

interface PageParams {
  pmReportId: string;
}

interface SupportPMReportFormPageProps extends RouteComponentProps<PageParams> {
  mode: FormMode;
}

interface InjectedProps extends SupportPMReportFormPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class SupportPMReportFormPage extends Component<SupportPMReportFormPageProps> {
  @observable readyToShow = false;
  @observable isCreateSuccess = false;
  @observable isEditSuccess = false;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get formStore() {
    return this.injected.appStore.pmReportFormStore;
  }

  @computed
  get currentForm() {
    return this.formStore.currentForm;
  }

  async componentDidMount() {
    this.formStore.initForm();

    const { mode } = this.props;
    const { pmReportId } = this.props.match.params;

    if (mode !== FormMode.Create && pmReportId) {
      await this.formStore.fetchPMReport(pmReportId);
    } else {
      this.currentForm.addReport('');
    }

    this.readyToShow = true;
  }

  handleDateChange = (selectedDate: Date) => {
    this.currentForm.setDate(moment(selectedDate).format("YYYY-MM-DD"));
  };

  handleTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.currentForm.pmReports[0].setText(e.currentTarget.value);
  };

  handleCancel = () => {
    this.props.history.push("/pm-reports?tab=support");
  };

  handleSubmit = async () => {
    if (this.isCreateSuccess) {
      AppToaster.show({
        message: "이미 저장되었습니다.",
        intent: Intent.WARNING
      });

      return;
    }

    try {
      await this.formStore.postPMReport();

      AppToaster.show({
        message: "새로운 일지가 생성되었습니다.",
        intent: Intent.SUCCESS
      });

      this.isCreateSuccess = true;
    } catch (e) {
      const error = JSON.stringify(e.response.data);

      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  handleEditSubmit = async () => {
    const { pmReportId } = this.props.match.params;

    try {
      await this.formStore.patchPMReport(pmReportId);

      AppToaster.show({
        message: "수정사항이 반영되었습니다.",
        intent: Intent.SUCCESS
      });

      this.isEditSuccess = true;
    } catch (e) {
      const error = JSON.stringify(e.response.data);

      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  handleDelete = async () => {
    const { pmReportId } = this.props.match.params;

    const isAgreed = window.confirm("삭제하시겠습니까?");

    if (isAgreed) {
      try {
        await this.formStore.deletePMReport(pmReportId);

        AppToaster.show({
          message: "삭제되었습니다.",
          intent: Intent.SUCCESS
        });

        this.props.history.push("/pm-reports?tab=support");
      } catch (e) {
        const error = JSON.stringify(e.response.data);

        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }
    }
  };

  render() {
    if (!this.readyToShow) {
      return <Spinner />;
    }

    if (this.isCreateSuccess || this.isEditSuccess) {
      return <Redirect to={`/pm-reports?tab=support`} push={true} />;
    }

    const { mode } = this.props;
    const { username } = this.injected.appStore.userStore;

    return (
      <Wrapper>
        <H3>{username} 매니저의 일지</H3>
        <Padding />

        <FormContainer>
          <FormSection>
            <FormGroup label={<strong>날짜</strong>}>
              <DateInput
                {...getMomentFormatter("YYYY-MM-DD")}
                locale="ko"
                closeOnSelection={true}
                value={moment(this.currentForm.date + "").toDate()}
                onChange={this.handleDateChange}
                maxDate={new Date("2050-01-01")}
              />
            </FormGroup>

            <TextContainer>
              <TextArea
                value={this.currentForm.pmReports[0].text}
                onChange={this.handleTextChange}
                placeholder="내용 기록"
                style={{ width: "100%", minHeight: "200px" }}
              />
            </TextContainer>
          </FormSection>
        </FormContainer>

        <ContentContainer>
          {mode === FormMode.Create && (
            <ButtonsRow>
              <Button text="취소" onClick={this.handleCancel} />
              <Button
                icon="floppy-disk"
                text="저장"
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit}
              />
            </ButtonsRow>
          )}
          {mode === FormMode.Edit && (
            <ButtonsRow>
              <Button text="취소" onClick={this.handleCancel} />
              <Button
                text="수정"
                intent={Intent.SUCCESS}
                onClick={this.handleEditSubmit}
              />
              <Button
                text="삭제"
                intent={Intent.DANGER}
                onClick={this.handleDelete}
              />
            </ButtonsRow>
          )}
        </ContentContainer>
      </Wrapper>
    );
  }
}

export default SupportPMReportFormPage;
