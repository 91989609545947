import { types } from "mobx-state-tree";

export const TaskRufreeFormModel = types
  .model("TaskRufreeForm", {
    name: types.optional(types.string, ""),
    phone: types.optional(types.string, ""),
    email: types.optional(types.string, "")
  })
  .actions(self => ({
    setName(value: string) {
      self.name = value;
    },
    setPhone(value: string) {
      self.phone = value;
    },
    setEmail(value: string) {
      self.email = value;
    }
  }));

type TaskRufreeFormType = typeof TaskRufreeFormModel.Type;
export interface TaskRufreeForm extends TaskRufreeFormType {}
