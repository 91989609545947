import React, { Component, KeyboardEvent } from "react";
import styled from "styled-components/macro";
import { RouteComponentProps } from "react-router";
import { computed, observable } from "mobx";
import { inject, observer } from "mobx-react";

import RequestRow from "../../molecules/ParttimeRow/RequestRow";
import Paginator from "../../molecules/Paginator/Paginator";
import RequestFormDialog from "../../molecules/ParttimeDialog/RequestFormDialog";

import { AppStore } from "../../../store/AppStore";
import { FormMode } from "../../../types/formMode";
import { Request } from "../../../store/models/Parttime";
import { TableHeader, Header } from "../../atoms/CardTable/CardTable";
import { PageNavWrapper } from "../../atoms/PageNav/PageNav";
import LargeContainer from "../../atoms/LargeContainer/LargeContainer";
import { BreadCrumb } from "../../molecules/BreadCrumb/BreadCrumb";
import { PrimaryButton } from "../../atoms/ParttimeComponents/ParttimeComponents";
import {
    Button,
    Checkbox,
    InputGroup,
    Card,
    Label
  } from "@blueprintjs/core";
interface RequestListPageProps extends RouteComponentProps {}

interface InjectedProps extends RequestListPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class PartTimeRequestListPage extends Component<RequestListPageProps> {
    @observable readyToShow = false;
    @observable searchText = "";
    @observable status = ['SUBMITTED'];
    @observable isRequestFormDialogOpen: boolean = false;

    get injected() {
        return this.props as InjectedProps;
    }

    @computed
    get requestStore() {
        return this.injected.appStore.parttimeRequestStore;
    }

    handleStatus = (e: any) => {
        if(e.target.checked){
            this.status.push(e.target.value);
        } else {
            let newStatus = this.status.filter(event => event !== e.target.value);
            this.status=newStatus;
        }
    };

    handleSearch = async () => {
        this.readyToShow = false;
        await this.requestStore.fetchRequests({searchText: this.searchText, status: this.status});
        this.readyToShow = true;
    };

    handleSearchText = (e: any) => {
        this.searchText = e.target.value;
    };

    handleSearchTextKeyDown = async(event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
          await this.handleSearch();
        }
    }

    handleRequestFormDialogOpen = () => {
        this.isRequestFormDialogOpen = true;
    };

    handleRequestFormDialogClose = async () => {
        this.isRequestFormDialogOpen = false;
        await this.fetchRequests();
    };  
  

    fetchRequests = async () => {
        try {
          this.readyToShow = false;
          await this.requestStore.fetchRequests({searchText: this.searchText, status: this.status});
        } catch (e) {
        } finally {
          this.readyToShow = true;
        }
    }
    
    async componentDidMount() {
        await this.fetchRequests();
    }

    handleRequestClick = (request: Request) => {
        this.props.history.push(`/parttime/requests/${ request.id }`);
    };

    render() {
        const { isClient } = this.injected.appStore.userStore;
        const { requests } = this.requestStore;

        return (
            <LargeContainer>
                {isClient && (
                    <PrimaryButton 
                    text={"새 TASK 신청"} 
                    onClick={this.handleRequestFormDialogOpen}
                    style={{width: "150px", float: "right"}}
                    />
                )}

                <PageNavWrapper>
                    <BreadCrumb
                        items={[
                            { name: "대시보드", link: "/parttime/" },
                            "신청내역 목록"
                        ]}
                    />
                </PageNavWrapper>
                
                <Card style={{ marginBottom: "20px"}}>
                    <InputContainer>
                        <Label>검색어</Label>  
                        <InputGroup
                        placeholder="신청제목, 신청번호 또는 소속명"
                        value={this.searchText}
                        onChange={this.handleSearchText}
                        onKeyDown={this.handleSearchTextKeyDown}
                        />
                    </InputContainer>
                    <div>
                        <Label>상태</Label>
                        <Checkbox {...this.state} value='SUBMITTED' label="신청" defaultChecked={true} onChange={this.handleStatus} style={checkboxStyle} />
                        <Checkbox {...this.state} value='IN_PROGRESS' label="진행" onChange={this.handleStatus} style={checkboxStyle} />
                        <Checkbox {...this.state} value='COMPLETED' label="완료" onChange={this.handleStatus} style={checkboxStyle} />
                        <Checkbox {...this.state} value='CANCELED' label="취소" onChange={this.handleStatus} style={checkboxStyle} />
                    </div>
                    <div style={{ textAlign: "center", marginTop: "20px"}}>
                        <Button
                            text="검색"
                            alignText="center"
                            style={{ width: "150px", borderRadius: "15px", backgroundColor: "#353535", backgroundImage: "none", color: "white"}}
                            onClick={this.fetchRequests}
                        />
                    </div>
                </Card>

                <Divider></Divider>

                <TableHeader>
                    <Header style={{ maxWidth: "104px" }}>번호</Header>
                    <Header style={{ maxWidth: "250px" }}>신청제목</Header>
                    <Header style={{ maxWidth: "270px" }}>유형</Header>
                    <Header style={{ maxWidth: "200px" }}>클라이언트</Header>
                    <Header style={{ maxWidth: "200px" }}>상태</Header>
                    <Header style={{ maxWidth: "283px" }}>신청일시</Header>
                </TableHeader>

                <TableBody style={{ minHeight: "auto" }}>
                    { requests.map(request => (
                        <RequestRow
                            key={ request.id }
                            request={ request }
                            onClick={() => this.handleRequestClick(request)}
                        />
                    )) }
                </TableBody>

                <Paginator
                    pageGroup={this.requestStore.pageGroup}
                    totalPages={this.requestStore.totalPages}
                    currentPage={this.requestStore.currentPage}
                    setPageGroup={this.requestStore.setPageGroup}
                    setCurentPage={this.requestStore.setCurentPage}
                    onFetch={this.fetchRequests}
                />

                <RequestFormDialog
                    id={ -1 }
                    mode={ FormMode.Create }
                    onClose={ this.handleRequestFormDialogClose }
                    isOpen={ this.isRequestFormDialogOpen } 
                />
                </LargeContainer>
        );
    }
}

export default PartTimeRequestListPage;

const Wrapper = styled.div``;

const Divider = styled.div`
  margin: 40px 0 40px 0;
  border-bottom: 1px solid #d6d6d6;
`;

const TableBody = styled.div`
  min-height: 500px;
`;
const ToolsRow = styled.div`
  margin-bottom: 30px;
  display: flex;
  height: 20px;

  & > * + * {
    margin-left: 10px;
  }
`;
const InputContainer = styled.div`
  margin-bottom: 30px;
`;

const checkboxStyle = {
    display: 'inline',
    marginRight: '30px'
}
