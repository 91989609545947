import { types, flow, IMSTArray, ISimpleType } from "mobx-state-tree";
import axios from "axios";
import {
  InspectionSelectionFormModel,
  InspectionSelectionForm
} from "../ProjectGroupForm/InspectionSelectionForm";

export const RufreeContractFormModel = types
  .model("RufreeContractForm", {
    id: types.optional(types.number, -1),
    contractId: types.optional(types.string, ""),
    // createdAt: types.optional(types.string, ""),
    // updatedAt: types.optional(types.string, ""),
    contractName: types.optional(types.string, ""),
    info: types.optional(types.string, "개인"),
    agentName: types.optional(types.string, ""),
    agentEmail: types.optional(types.string, ""),
    agentAddress: types.optional(types.string, ""),
    address: types.optional(types.string, "."),
    contractDate: types.optional(types.string, ""),
    sprintPeriod: types.optional(types.string, ""),
    payment: types.optional(types.string, ""),
    commission: types.optional(types.string, ""),
    paymentFee: types.optional(types.number, 0),
    commissionFee: types.optional(types.number, 0),
    contractRange: types.optional(types.string, ""),
    status: types.optional(types.string, ""),
    specialContract: types.optional(types.string, ""),
    comment: types.optional(types.string, ""),
    rufreeUser: types.optional(types.string, ""),
    inspections: types.array(InspectionSelectionFormModel),
    pmUser: types.optional(types.number, -1),
    // projectGroup: types.optional(types.string, ""),
    contractType: types.optional(types.string,""),
  })
  .views(self => ({
  }))
  .actions(self => ({
  }))
  .actions(self => ({
    setContractType(value:string){
      self.contractType = value;
    },
    setContractName(value: string) {
      self.contractName = value;
    },
    setInfo(value: string) {
      self.info = value;
    },
    setAgentName(value: string) {
      self.agentName = value;
    },
    setAgentEmail(value: string) {
      self.agentEmail = value;
    },
    setAgentAddress(value: string) {
      self.agentAddress = value;
    },
    setAddress(value: string) {
      self.address = value;
    },
    setContractDate(value: string) {
      self.contractDate = value;
    },
    setSprintPeriod(value: string) {
      self.sprintPeriod = value;
    },
    setPayment(value: number) {
      self.paymentFee = value;
    },
    setCommission(value: number) {
      self.commissionFee = value;
    },
    setContractRange(value: string) {
      self.contractRange = value;
    },
    setSpecialContract(value: string) {
      self.specialContract = value;
    },
    setRufreeUser(value: string) {
      self.rufreeUser = value;
    },
    setComment(value: string) {
      self.comment = value;
    },
    setPmUser(value: number) {
      self.pmUser = value;
    },

    addInspectionSelection() {
      self.inspections.push(InspectionSelectionFormModel.create());
    },
    removeInspectionSelection(inspectionSelection: InspectionSelectionForm) {
      self.inspections.remove(inspectionSelection);
    }
  }));

type RufreeContractFormType = typeof RufreeContractFormModel.Type;
export interface RufreeContractForm extends RufreeContractFormType {}
