import React, { Component } from "react";
import styled from "styled-components/macro";

const Wrapper = styled.div`
  max-width: 750px;
`;

export class ContentContainer extends Component {
  render() {
    return <Wrapper>{this.props.children}</Wrapper>;
  }
}

export default ContentContainer;
