import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { ClientCalculate } from "../../../store/models/ClientCalculate";
import {
  Button,
  Classes,
  Divider,
  Dialog,
  FormGroup,
  Intent
} from "@blueprintjs/core";
import moment from "moment";


const DialogBodyWrapper = styled.div`
  .bp3-divider { margin: 20px 0; }
  .bp3-label { font-weight: bold; width: 120px; line-height: 18px !important; }
  .bp3-form-group.sub {
    font-size: 13px;
    color: #5C7080;

    label { padding-left: 15px; }
  }

  .comment { width: 100%; }
  .deposit-row:first-child { font-weight: bold; }
  div.deposit-row { display: flex; line-height: 25px; }
  div.deposit-item-date { width: 100px; }
  div.deposit-item-payout { width: 110px; text-align: right; font-size: 12px; }
  .sprints + .sprints { margin-left: 10px; }
`;

interface ClientCalculateDetailPopupProps {
  calculate: ClientCalculate;
  includeVat: boolean;
  isOpened: boolean;
  onClose: () => void;
}

interface InjectedProps extends ClientCalculateDetailPopupProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ClientCalculateDetailPopup extends Component<ClientCalculateDetailPopupProps> {

  get injected() {
    return this.props as InjectedProps;
  }

  get contractStore() {
    return this.injected.appStore.contractStore;
  }

  handleOpening = () => { };

  render() {
    const { isOpened, includeVat, onClose, calculate } = this.props;

    const title = `입금내역 상세보기`;

    return (
      <Dialog
        onOpening={this.handleOpening}
        isOpen={isOpened}
        usePortal={true}
        autoFocus={true}
        enforceFocus={true}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        title={title}
      >
        <DialogBodyWrapper className={Classes.DIALOG_BODY}>
          <FormGroup label={'클라이언트명'} inline={true}>
            [{ calculate.clientId }] {calculate.clientName}
          </FormGroup>

          <FormGroup label={'프로젝트그룹'} inline={true}>
            {
              calculate.projectGroup &&
                <>[{calculate.projectGroup}] {calculate.projectGroupName}</>
            }
          </FormGroup>
          {
            calculate.projectGroup &&
              <FormGroup
                label={'- 스프린트'}
                inline={true}
                className='sub'
              >
                  {
                    calculate.sprints
                    .sort()
                    .map((s, i) => <span key={i} className='sprints'>S{s}</span>)
                  }
              </FormGroup>
          }

          <FormGroup label={'계약서'} inline={true}>
            {
              calculate.contract && <>[{calculate.contract}] {calculate.contractName}</>
            }
          </FormGroup>

          <Divider />

          <FormGroup label={'세금계산서발급일'} inline={true}>
            {calculate.dateIssue}
          </FormGroup>
          <FormGroup label={'입금요청금액'} inline={true}>
            {
              calculate.payout ?
                includeVat ? calculate.payoutWithVAT.toLocaleString("en-US") : calculate.payout.toLocaleString("en-US")
                : ""
            }
            {includeVat ? ' (VAT 포함)' : ' (VAT 불포함)'}
          </FormGroup>
          <FormGroup label={'입금내역'} inline={true}>
            <div className='deposit-row'>
              <div className='deposit-item-payout'>
                총 입금액
              </div>
              <div className='deposit-item-payout'>
                {
                  calculate.receivedPayout ?
                    includeVat ? calculate.receivedPayoutWithVAT.toLocaleString("en-US") : calculate.receivedPayout.toLocaleString("en-US")
                    : ""
                }
              </div>
            </div>
            {calculate.deposits.map((deposit, i) => (
              <div className='deposit-row' key={i}>
                <div className='deposit-item-payout'>{ moment(deposit.date).format('YYYY-MM-DD HH:mm') }</div>
                <div className='deposit-item-payout'>
                  {
                    deposit.payout ?
                      includeVat ? deposit.payoutWithVATForRead.toLocaleString("en-US") : deposit.payout.toLocaleString("en-US")
                      : ""
                  }
                </div>
              </div>
            ))}
          </FormGroup>
          <FormGroup
            label={'비고'}
            inline={true}
            className='comment'
          >
            { calculate.comment && calculate.comment.split('\n').map((item, i) => <div key={i}>{item}</div>) }
          </FormGroup>
        </DialogBodyWrapper>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button text="취소" onClick={onClose} />
          </div>
        </div>
      </Dialog>
    );
  }
}

export default ClientCalculateDetailPopup;
