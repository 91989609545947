import styled from "styled-components/macro";

import {
  Popover
} from "@blueprintjs/core";

import RoleButtonSVG from "./btn_add_role.svg";
import SprintButtoSVG from "./btn_add_sprint.svg";
import DeleteSVG from "./btn_delete.svg";
import CalendarSVG from './icon_calendar.svg';


export const ResourceFormWrapper = styled.div`
  & + & {
    margin-top: 60px;
  }
}
`;

export const RoleButtonWrapper = styled.button`
  background: url(${ RoleButtonSVG }) no-repeat center center;
  width: 44px;
  height: 30px;
  margin-left: 477px;
  margin-top: 12px;
  border: none;
  cursor: pointer;
}
`;

export const SprintButtonWrapper = styled.div`
  & > button {
    background: url(${ SprintButtoSVG }) no-repeat center center;
    width: 39px;
    height: 39px;
    border: none;
    cursor: pointer;
  }

  & > div {
    width: 38px;
    height: 38px;
    line-height: 36px;
    border-radius: 12px;
    font-weight: bold;
    font-size: 13px;
    text-align: center;

    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    color: #6241FE;
    cursor: pointer;
  }

  &.pre > div {
    font-weight: bold;
    font-size: 12px;
  }
  &.active > div {
    background: #6241FE;
    color: #FFFFFF;
    border: none;
  }
  &.disabled > div {
    background: #F8F8F8;
    color: #DDDDDD;
  }
  &.selected > div {
    background: #AB41FE;
    color: #FFFFFF;
    border: none;
  }
}
`;

export const PerPersonFieldWrapper = styled.div`
  width: 65px;
  height: 30px;
  line-height: 28px;
  border-radius: 10px;
  font-weight: inherit;
  font-size: 14px;
  text-align: center;
  margin-top: 3px;

  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  color: #6241FE;
  cursor: pointer;

  &.active {
    background: #6241FE;
    color: #FFFFFF;
    border: none;
    font-weight: bold;
  }
`;

export const SprintFieldWrapper = styled(Popover)`
  & + & {
    margin-left: 12px;
  }

  & > div.popup-button {
    cursor: pointer;
  }

  .bp3-popover.bp3-minimal.sprintfield-popup {
    box-shadow: none;
    margin-left: 5px !important;
  }
`;

export const ResourceFieldWrapper = styled.div`
  & {
    background: #FFFFFF;
    border: 1px solid #E6E1FF;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 20px 25px;
    position: relative;
    width: 948px;
    line-height: 36px;
  }
  

  & > label {
    font-weight: bold;
    font-size: 14px;
    color: #333333;
    margin-right: 15px;
  }
  input {
    height: 36px;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    padding: 8px 10px;
  }
  input[type='text'] {
    width: 160px;
  }
  div.wage input {
    width: 120px;
  }

  & + & {
    margin-top: 22px;
  }

  & > div {
    display: flex;
  }
  & > div:first-child > div:nth-child(3) {
    margin-left: 40px;
  }
  div.role {
    width: 240px;
  }
  div.role > label, div.wage > label {
    margin-right: 15px;
  }
  div.role > input.etc-role-input {
    display: block;
    width: 200px;
    height: 36px;
    margin-left: 40px;
    margin-top: 9px;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
  div.role > label + span.rufree-role-selection button {
    width: 160px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 2px;
    box-shadow: none;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    justify-content: left;
    color: #000000;
    position: relative;

    & span.bp3-icon-caret-down {
      justify-content: right;
      display: block;
      position: absolute;
      right: 9.44px;
    }
  }
  div.role-total-wage > span::after {
    content: '원';
    margin-left: 5px;
  }

  div.role-total-wage {
    position: absolute;
    right: 30px;
  }

  div.role-total-wage > label {
    margin-right: 22px;
    font-size: 14px;
    color: #7E838F;
    font-weight: normal;
  }

  div.role-total-wage > span {
    font-weight: bold;
    font-size: 18px;
    text-align: right;
    color: #6241FE;
  }

  div.sprint-field {
    height: 39px;
    margin-top: 30px;
    overflow-y: hidden;
    overflow-x: auto;
    scrollbar-width: thin;
  }

  button.remove {
    background: url(${ DeleteSVG }) no-repeat top left;
    height: 40px;
    width: 40px;
    border: none;
    right: -50px;
    top: 0;
    position: absolute;
    border-radius: 12px;
    cursor: pointer;
  }
`;

export const RoleOptionWrapper = styled.li``;

export const SprintPopupWrapper = styled.div`
  width: 354px;
  // min-height: 173px;

  background: #FAFAFA;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 10px;

  &.show {
    display: block;
  }

  div.body {
    padding: 18px 22px;
    border-bottom: 1px solid #DDDDDD;
    text-align: left;
    padding-left: 13px;
    line-height: 25px;

    & > h5 {
      margin: 0;
      font-weight: bold;
      font-size: 15px;
      line-height: 22px;
      color: #333333;
      // margin-bottom: 15px;
      display: inline-block;
      margin-left: 5px;
    }
    & > h5 + div {
      margin-top: 15px;
    }
    & > div {
      padding-left: 13px;
    }
    & > div + div {
      margin-top: 25px;
    }
    label {
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      font-style: normal;
      font-weight: normal;
      display: block;
      margin-bottom: 7px;
    }
    span.input-seperator {
      width: 9px;
      margin-left: 8px;
      margin-right: 9px;
      display: inline-block;
    }
    input[type="number"] {
      width: 80px;
      padding: 6px 15px 6px 10px;
    }
    // input + input {
    //   margin-left: 26px;

    //   &:before {
    //     content: '~';
    //   }
    // }
    input[type="checkbox"] {
      width: 15px;
      height: 15px;
      margin: 0;
    }
    input[type="checkbox"]:checked {
      background: #6241FE;
      border-radius: 3px;
    }
    input[type="checkbox"] ~ label {
      display: inline;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      margin-right: 25px;
      margin-left: 10px;
      color: #000000;
    }
    span.sprint-number {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 23px;
      color: #AB41FE;
    }
  }

  div.popup-footer {
    height: 46px;
    padding: 10px 25px;
    button {
      padding: 0;
      border: none;
      font-weight: bold;
      font-size: 16px;
      line-height: 23px;
      background: none;
      width: 30px;
      height: 23px;
      color: #333333;
      position: absolute;
      right: 85px;
      cursor: pointer;
    }
    button.delete {
      color: #D54962;
      left: 22px;
    }
    button.edit {
      color: #6241FE;
      right: 25px;
    }
  }
`;

export const ResourceWapper = styled.div`
  width: 705px;
  background: #FCFCFC;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 15px 20px 7px 20px;
  mix-blend-mode: multiply;

  & > h5 {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 19px;
    color: #000000;
    margin: 0;
    margin-bottom: 12px;
  }
  & > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    span {
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }
`;

export const SprintWrapper = styled.span`
  width: 38px;
  height: 31px;
  background: #FFFFFF;
  border: 1px solid #E6E1FF;
  box-sizing: border-box;
  border-radius: 12px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  padding-top: 7px;
  color: #6241FE;
`;

export const VATCheckboxWrapper = styled.label`
  & {
    display: block;
    position: relative;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    color: #000000;

    input {
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      z-index: -1;
    }

    span.indicator {
      position: relative;
      background: rgba(167,182,194,.5);
      border: none;
      border-radius: 100px;
      width: 35px;
      height: 20px;
      transition: background-color .1s cubic-bezier(.4,1,.75,.9);
      cursor: pointer;
      display: inline-block;
      margin-right: 10px;
      top: 4px;
    }

    input:checked~.indicator {
      background-color: #6241FE;
      color: #fff;
    }

    span.indicator:before {
      left: calc(100% - 1em);
      background: #fff;
      border-radius: 50%;
      left: 0;
      margin: 2px;
      position: absolute;
      -webkit-transition: left .1s cubic-bezier(.4,1,.75,.9);
      transition: left .1s cubic-bezier(.4,1,.75,.9);
      width: calc(1em - 4px);
      content: "";
      display: block;
      height: 16px;
      width: 16px;
    }

    input:checked~.indicator:before {
      left: 15px;
    }
  }
`;

export const ScheduleBriefWrapper = styled.div`
  & {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;

    color: #000000;
  }
  & > span.highlight {
    font-weight: bold;
    color: #6241FE;
  }
`;

export const ScheduleIcon = styled.span`
  background: url(${ CalendarSVG }) no-repeat top left;
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-top: 4px;
  margin-right: 6px;
`;