import React, { ChangeEvent, Component, FormEvent } from "react";
import { AppStore } from "../../../store/AppStore";
import { inject, observer } from "mobx-react";
import { GrayLink, GreyLabel } from "../../atoms/DetailReportComponents/DetailReportComponents";
import styled from "styled-components/macro";
import { computed, observable } from "mobx";
import { Button, Classes, InputGroup } from "@blueprintjs/core";
import { PdfToUploadForm, PdfToUploadFormModel } from "../../../store/forms/RufreeForm/PdfToUploadForm";
import { TaskFile, TaskLink } from "../../../store/models/TaskManager";
import TaskAttachmentFileForm from "./TaskAttachmentFileForm";
import TaskAttchmentLinkForm from "./TaskAttchmentLinkForm";

const AttachmentCol1 = styled.div`
  float: left;
   width: 120px;
  padding: 15px;
  

`;
const AttachmentCol2 = styled.div`
  float: left;
  // width: 80%;
  padding: 15px;
`;

const AttachmentRow = styled.div`
  content: "";
  display: table;
  clear: both;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface TaskAttachmentListFormProps {
  taskLinks: Array<TaskLink>;
  taskFiles: Array<TaskFile>;
  taskId: string;
  disabled: boolean | undefined;
  // onAdd: (values: object) => void;
  // onCancel: () => void;
  loaded: boolean;
}

interface InjectedProps extends TaskAttachmentListFormProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class TaskAttachmentListForm extends Component<TaskAttachmentListFormProps> {

  @observable create_status = 0;
  @observable newLink = false;
  @observable newLinkVal = "";
  @observable file: PdfToUploadForm | undefined;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get formStore() {
    return this.injected.appStore.taskManagerTaskFormStore;
  }

  fileUpload = (e: FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      const newNode = PdfToUploadFormModel.create();
      newNode.setFile(e.currentTarget.files[0]);
      this.formStore.updateFile({
        fileModel: newNode,
        // file: e.currentTarget.files[0],
        taskId: this.props.taskId
      }, "post");
    }
  };

  render() {
    const { taskLinks, taskFiles, disabled } = this.props;
    return (
      <div style={{ marginBottom: "10px" }}>
        <Container>
          <GreyLabel style={{ display: "inline-block" }}>첨부</GreyLabel>
          <div>
            <div style={{ display: "inline-block" }}>
              <label htmlFor="upload-button"><GrayLink>파일 추가</GrayLink></label>
            </div>
            <div style={{ display: "inline-block", marginLeft: "10px" }}>
              <GrayLink onClick={() => {
                if (!disabled)
                  this.newLink = !this.newLink;
              }}>링크 추가</GrayLink>
            </div>
          </div>
        </Container>

        <div style={{ marginLeft: "10px", marginTop: "20px" }}>
          <input
            type="file"
            id="upload-button"
            disabled={disabled}
            style={{ display: "none" }}
            onChange={this.fileUpload}
          />
          {this.newLink ? <div>
              <div style={{ float: "left" }}>
                <InputGroup placeholder="링크를 입력해주세요." value={this.newLinkVal}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              this.newLinkVal = e.target.value;
                            }}/>
              </div>
              <div style={{ float: "left" }}><Button text={"추가"} onClick={() => {
                this.formStore.updateLink({
                  link: this.newLinkVal
                }, "post");
                this.newLinkVal = "";
              }}/></div>
              <div style={{ clear: "left" }}></div>


            </div>
            : null}

          {this.props.loaded ?
            <div>
              {taskLinks ?
                taskLinks.map((link) => {
                  return (
                    <TaskAttchmentLinkForm key={`${link.id}link`} id={link.id} link={link.link}
                                           created_at={link.created_at}
                                           onRemove={(id: number) => {
                                             this.formStore.updateLink({ id: id }, "delete");
                                           }}
                                           disabled={disabled}
                                           onEdit={(linkToChange: string) => {
                                             this.formStore.updateLink({ id: link.id, link: linkToChange }, "patch");
                                           }}
                    />
                  );
                }) : null}

              {taskFiles ?
                taskFiles.map((file) => {
                  return (
                    <TaskAttachmentFileForm filename={file.filename}
                                            key={`${file.id}file`} file={file.file} id={file.id}
                                            created_at={file.created_at}
                                            isImage={file.is_image}
                                            disabled={disabled}
                                            onRemove={(id: number) => {
                                              this.formStore.updateFile({ id: id }, "delete");
                                            }}/>
                  );
                }) : null}
            </div> :
            <div>
              <div className={Classes.SKELETON} style={{ float: "left", height: "63px", width: "150px" }}></div>
              <div className={Classes.SKELETON}
                   style={{ float: "left", height: "18px", width: "300px", marginLeft: "10px" }}></div>
              <div className={Classes.SKELETON}
                   style={{
                     float: "left",
                     height: "18px",
                     width: "150px",
                     marginTop: "5px",
                     marginLeft: "10px"
                   }}></div>
              <div style={{ clear: "left" }}></div>
            </div>
          }
        </div>


      </div>
    );
  }
}

export default TaskAttachmentListForm;