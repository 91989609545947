import { types } from "mobx-state-tree";

export const ScheduleManagerModel = types
  .model("ScheduleManager", {
    id: types.number,
    createdAt: types.string,
    updatedAt: types.string,
    category: types.string,
    schCategory: types.string,  // (명) 미팅/휴가 일정 카테고리 추가
    taskDone: types.boolean,  // (명) 할일 완료 상태 추가
    startAt: types.string,
    endAt: types.string,
    allDay: types.boolean,
    title: types.string,
    comment: types.string,
    creator: types.number,
    creatorName: types.string,
    creatorPmName: types.string,
    projectGroup: types.union(types.string, types.null), // (명) 근무시간 등 프로젝트그룹 외 일정은 null 값이 올 수 있음
    projectGroupName: types.union(types.string, types.undefined), // (명) 근무시간 등 프로젝트그룹 외 일정은 undefined 값이 올 수 있음
    participants: types.array(types.number)
  })
  .actions(self => {
    // const requestCancel = flow(function*() {
    // });

    return {
    };
  });

type ScheduleManagerType = typeof ScheduleManagerModel.Type;
export interface ScheduleManager extends ScheduleManagerType {}

export const GoogleScheduleModel = types
  .model("GoogleSchedule", {
    id: types.string,
    type: types.string,
    htmlLink: types.string,
    summary: types.string,
    creator: types.string,
    start: types.string,
    end: types.string,
    attendees: types.optional(types.array(types.string), [])    
  })

type GoogleScheduleType = typeof GoogleScheduleModel.Type;
export interface GoogleSchedule extends GoogleScheduleType {}

export const WeekdayHolidayModel = types.model("WeekdayHoliday", {
  date: types.string,
  name: types.string,
});
type WeekdayHolidayType = typeof WeekdayHolidayModel.Type;
export interface WeekdayHoliday extends WeekdayHolidayType {}
