import { types } from "mobx-state-tree";

export const ReferenceLinkModel = types
  .model("ReferenceLink", {
    link: types.optional(types.string, ""),
    comment: types.optional(types.string, ""),
    org_link: types.optional(types.string, ""),
    org_comment: types.optional(types.string, ""),
    created_date: types.optional(types.string, ""),
    isFromInspection: types.optional(types.boolean, false)
  })
  .actions(self => ({
    setLink(value: string) {
      self.link = value;
    },
    setComment(value: string) {
      self.comment = value;
    }
  }));

type ReferenceLinkType = typeof ReferenceLinkModel.Type;
export interface ReferenceLink extends ReferenceLinkType {}

export const InspectionFileModel = types
  .model("InspectionFile", {
    file_url: types.optional(types.string, ""),
    file_name: types.optional(types.string, ""),
    created_date: types.optional(types.string, ""),
  })
  .actions(self => ({
    setFileUrl(value: string) {
      self.file_url = value;
    },
    setFileName(value: string) {
      self.file_name = value;
    },
    setUpdatedDate(value:string){
      self.created_date = value;
    }
  }));

type InspectionFileType = typeof InspectionFileModel.Type;
export interface InspectionFile extends InspectionFileType {}
