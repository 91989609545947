import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";

// import { observable } from "mobx";

import VacationSummary from "../../organisms/Dashboard/VacationSummary";

const Wrapper = styled.div`
  margin-bottom: 10px;
  & > * + * {
    margin-top: 10px;
  }
`;
const LeftMargin = styled.div`
  display: flex;
  & > * + * {
    margin-left: 10px;
  }
`;

interface VacationPanelProps {
}

interface InjectedProps extends VacationPanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class VacationPanel extends Component<VacationPanelProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  async componentDidMount() {
  }

  render() {
    return (
      <Wrapper>

        <LeftMargin>
          <VacationSummary />
        </LeftMargin>

      </Wrapper>
    );
  }
}

export default VacationPanel;