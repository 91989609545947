/*
  파트너 회원 거절 팝업
*/
import React from 'react';
import styled from "styled-components/macro";
import { Alert, IAlertProps, Intent, TextArea } from '@blueprintjs/core';

const Wrapper = styled(Alert)`
  & .bp3-alert-body {
    display: block;
  }
  & label {
    display: block;
    margin-bottom: 5px;
  }
`;
interface IProps extends IAlertProps {
  onChangeComment: (comment: string) => void
} 
const RejectPopup = (props: IProps) => {
  return (
    <Wrapper
      confirmButtonText="확인"
      cancelButtonText="취소"
      intent={ Intent.DANGER } 
      { ...props }
    >
      <label>
        거절 사유를 적어주세요.
      </label>
      <div>
        <TextArea
          fill={ true }
          growVertically={ true }
          onChange={ (e) => props.onChangeComment(e.target.value) } />
      </div>
    </Wrapper>
  )
}
export default RejectPopup;