import React, { Component } from "react";
import styled from "styled-components/macro";
import ConsultingListPanel from "../../panels/ConsultingListPanel/ConsultingListPanel";
import ConsultingFormPanel from "../../panels/ConsultingFormPanel/ConsultingFormPanel";
import { Switch, Route } from "react-router";
import { NavLink } from "react-router-dom";
import { FormMode } from "../../../types/formMode";
import { observable } from "mobx";

const Wrapper = styled.div``;

const Menu = styled.div`
  & > * + * {
    margin-left: 20px;
  }
`;

const MenuLink = styled(NavLink)`
  line-height: 1.28581;
  letter-spacing: 0;
  font-size: 14px;
  font-weight: 400px;
  color: #182026;
  text-decoration: none;
  padding-bottom: 5px;

  &:hover {
    text-decoration: none;
  }

  &.active {
    color: #106ba3;
    border-bottom: 3px solid #106ba3;
  }
`;

const Padding = styled.div`
  height: 40px;
`;

class ConsultingPage extends Component {
  render() {
    return (
      <Wrapper>
        <Menu>
          <MenuLink to="/consulting" activeClassName="active" exact={true}>
            상담목록
          </MenuLink>
          <MenuLink to="/consulting/new">새 상담</MenuLink>
          <Route
            path="/consulting/projects/:projectId"
            exact={true}
            render={({ match }) => (
              <MenuLink to={`/consulting/projects/${match.params.projectId}`}>
                프로젝트 상세
              </MenuLink>
            )}
          />
          <Route
            path="/consulting/projects/:projectId/edit"
            render={({ match }) => (
              <MenuLink
                to={`/consulting/projects/${match.params.projectId}/edit`}
              >
                프로젝트 수정
              </MenuLink>
            )}
          />
        </Menu>
        <Padding />

        <Switch>
          <Route
            path="/consulting"
            exact={true}
            component={ConsultingListPanel}
          />
          <Route
            path="/consulting/new"
            render={() => (
              <ConsultingFormPanel key="create" mode={FormMode.Create} />
            )}
          />
          <Route
            path="/consulting/projects/:projectId"
            exact={true}
            render={({ match }) => (
              <ConsultingFormPanel
                key="read"
                mode={FormMode.Read}
                projectId={match.params.projectId}
              />
            )}
          />
          <Route
            path="/consulting/projects/:projectId/edit"
            render={({ match }) => (
              <ConsultingFormPanel
                key="edit"
                mode={FormMode.Edit}
                projectId={match.params.projectId}
              />
            )}
          />
        </Switch>
      </Wrapper>
    );
  }
}

export default ConsultingPage;
