import React, { FC, useRef, useEffect } from "react";
import ReactDOM from 'react-dom';
import styled from "styled-components/macro";
import {
  Button,
  ButtonGroup,
  Intent
} from "@blueprintjs/core";

const Wrapper = styled.div`
  text-align: center;
`;

interface PaginatorProps {
  pageGroup: number;
  totalPages: number;
  currentPage: number;
  setPageGroup: (value: number) => void;
  setCurentPage: (value: number) => void;
  onFetch: () => void;
}

const Paginator: FC<PaginatorProps> = props => {
  const { pageGroup, totalPages, currentPage, setPageGroup, setCurentPage, onFetch } = { ...props };


  return (
    <Wrapper>
      <ButtonGroup>
        {
          pageGroup > 1 && (
            <Button
              icon="chevron-left"
              onClick={
                () => {
                  setPageGroup(pageGroup - 1);
                  setCurentPage((pageGroup - 1) * 10);
                  onFetch();
                }
              }
            />
          )
        }
        {
          Array(
            pageGroup * 10 > totalPages
              ? totalPages % 10
              : 10
          )
          .fill(null)
          .map((_, index) => {
            const pageNumber = index + 1 + (pageGroup - 1) * 10;

            return (
              <Button
                key={index}
                text={pageNumber}
                onClick={() => {
                  setCurentPage(pageNumber);
                  onFetch();
                }}
                intent={
                  currentPage === pageNumber
                    ? Intent.PRIMARY
                    : Intent.NONE
                }
              />
            );
          })
        }
        {
          pageGroup * 10 < totalPages && (
            <Button
              icon="chevron-right"
              onClick={() => {
                setPageGroup(pageGroup + 1);
                setCurentPage(
                  (pageGroup) * 10 + 1
                );
                onFetch();
              }}
            />
          )
        }
      </ButtonGroup>
    </Wrapper>
  );
};

export default Paginator;
