import { types } from "mobx-state-tree";
import { ClientDepositFormModel } from "../forms/CalculateForm/ClientCalculateForm";


export const ClientCalculateModel = types
  .model("ClientCalculate", {
    id: types.number,
    contract: types.string,
    contractName: types.string,
    projectGroup: types.string,
    projectGroupName: types.string,
    sprints: types.array(types.number),
    clientId: types.string,
    clientName: types.string,
    dateIssue: types.maybeNull(types.string),
    dateLastDeposit: types.maybeNull(types.string),
    payout: types.number,
    deposits: types.array(ClientDepositFormModel),
    comment: types.maybeNull(types.string)
  })
  .views(self => ({
    get title() {
      if(self.projectGroup) {
        return `${ self.projectGroupName } ${ self.sprints.map(sprint => sprint === 0 ? 'PRE' : `S${sprint}`) }`;
      }
      return '기타 입금';
    },
    // get lastDepositDate() {
    //   return self.deposits.length && sortBy(
    //     self.deposits.slice(),
    //     deposit => moment(deposit.date)
    //   ).reverse()[0].date;
    // },

    get receivedPayout() {
      return self.deposits.length 
        && self.deposits.reduce((s, deposit) => s + deposit.payout, 0);
    }
  }))
  .views(self => ({
    get payoutWithVAT() {
      return Math.round(self.payout * 1.1)
    },
    get receivedPayoutWithVAT() {
      return Math.floor(Math.round(self.receivedPayout * 1.1)/10) * 10;
    }
  }))
  .actions(self => {
    return{
    };
  });

type ClientCalculateModelType = typeof ClientCalculateModel.Type;
export interface ClientCalculate extends ClientCalculateModelType {}
