import { types } from "mobx-state-tree";
import { MAX_SPRINTS } from "../../constants/constants";

export const SprintCheckModel = types
  .model("SprintCheck", {
    value: types.optional(types.boolean, false)
  })
  .actions(self => ({
    setValue(value: boolean) {
      self.value = value;
    }
  }));

type SprintCheckType = typeof SprintCheckModel.Type;
export interface SprintCheck extends SprintCheckType {}

export const createDefaultSprints = () =>
  Array(MAX_SPRINTS)
    .fill(null)
    .map(_ => SprintCheckModel.create());

export const createDefaultServiceSprints = () => {
  let sprints = Array(MAX_SPRINTS)
    .fill(null)
    .map(_ => SprintCheckModel.create());
  sprints[0].setValue(true);
  return sprints;
}