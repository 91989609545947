import React, { Component, ChangeEvent, FormEvent } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { computed, observable } from "mobx";
import { 
  Button, 
  Dialog, 
  Classes, 
  FormGroup, 
  InputGroup, 
  TextArea, 
  Intent, 
  HTMLSelect, 
  IOptionProps, 
  Icon, 
  Checkbox,
  RadioGroup, Radio 
} from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import moment from 'moment';

import { AppStore } from "../../../store/AppStore";
import { FormMode } from "../../../types/formMode";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import { getMomentFormatter } from "../../../utils/date";

const FormGroupLabel = styled.div`
  font-weight: bold;
  width: 120px;
  line-height: 18px;
`;
const IconButton = styled(Icon)`
  margin-left: 10px;
  cursor: pointer;
`;
const DialogFooterLeftWrapper = styled.span`
  position: relative;
  left: -10px;
  margin-left: 0px;
  margin-right: auto;
`;

interface DashboardSchedulePopupProps {
  id: number;
  isOpened: boolean;
  onClose: () => void;
}

interface InjectedProps extends DashboardSchedulePopupProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class DashboardSchedulePopup extends Component<DashboardSchedulePopupProps> {
  @observable readyToShow: boolean = true;
  @observable startDate = moment().format('YYYY-MM-DD');
  @observable startTime = '00:00';
  @observable endDate = moment().format('YYYY-MM-DD');
  @observable endTime = '23:59';
  @observable formMode: FormMode = FormMode.Create;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get formStore() {
    return this.injected.appStore.dashboardScheduleFormStore;
  }

  @computed
  get currentForm() {
    return this.formStore.currentForm;
  }

  handleSchCategoryChanged = (e: ChangeEvent<HTMLSelectElement>) => {
    this.currentForm.setSchCategory(e.currentTarget.value);
    if(e.currentTarget.value == 'vac') {
      this.currentForm.setTitle('연차');
    } else {
      this.currentForm.setTitle('');
    }
  }

  handleAllDayChanged = () => {
    this.currentForm.setAllDay(!this.currentForm.allDay);
  }

  handleStartDate = (selectedDate: Date) => {
    this.startDate = moment(selectedDate).format('YYYY-MM-DD');
    this.handleSetStartAt();

    // 휴가: 시작일자를 옮겼을때, 종료일자가 시작일자 이전이면, 종료일자를 시작에 맞춰줌
    if(['vac'].includes(this.currentForm.schCategory)) {
      if(this.endDate && moment(this.endDate).isBefore(moment(this.startDate))) {
        this.endDate = moment(this.startDate).format('YYYY-MM-DD');
        this.handleSetEndAt();
      }
    }
    // 반차, 미팅: 시작날짜와 종료일자가 동일해야 함
    if(['vpm', 'vam', 'imt', 'omt'].includes(this.currentForm.schCategory)) {
      this.endDate = moment(this.startDate).format('YYYY-MM-DD');
      this.handleSetEndAt();
    }

  }

  handleStartTime = (e: ChangeEvent<HTMLSelectElement>) => {
    this.startTime = e.currentTarget.value;
    this.handleSetStartAt();
  }

  handleSetStartAt = () => {
    this.currentForm.setStartAt(`${this.startDate}T${this.startTime}`)
  }

  handleEndDate = (selectedDate: Date) => {
    this.endDate = moment(selectedDate).format('YYYY-MM-DD');
    this.handleSetEndAt();
  }
  handleEndTime = (e: ChangeEvent<HTMLSelectElement>) => {
    this.endTime = e.currentTarget.value;
    this.handleSetEndAt();
  }
  handleSetEndAt = () => {
    this.currentForm.setEndAt(`${this.endDate}T${this.endTime}`)
  }

  handleAddParticipant = () => {
    this.currentForm.addParticipant(0);
  }

  handleSetParticipant = (e: ChangeEvent<HTMLSelectElement>, idx: number) => {
    this.currentForm.setParticipant(idx, Number(e.currentTarget.value));
  }

  handleDelParticipant = (idx: number) => {
    this.currentForm.delParticipant(idx);
  }

  handleCreatorChange = (e: ChangeEvent<HTMLSelectElement>) => {
    this.currentForm.setCreator(Number(e.currentTarget.value));
  }

  handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.currentForm.setTitle(e.target.value);
  }

  handleCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.currentForm.setComment(e.target.value);
  }

  handleChangeToEdit = async () => {
    this.formMode = FormMode.Edit;
  }

  handleSubmit = async () => {
    let validateMsg = this.validateBeforeSubmit();

    if(validateMsg !== '') {
      AppToaster.show({
        message: validateMsg,
        intent: Intent.DANGER
      });      
      return;
    }

    try {
      // request
      await this.formStore.postSchedule();
      AppToaster.show({
        message: "새로운 일정이 등록되었습니다.",
        intent: Intent.SUCCESS
      });

      this.refreshCalendarView();
      this.props.onClose();

    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  }
  handleEditSubmit = async () => {
    let validateMsg = this.validateBeforeSubmit();

    if(validateMsg !== '') {
      AppToaster.show({
        message: validateMsg,
        intent: Intent.DANGER
      });      
      return;
    }
 
    try {
      // request
      await this.formStore.putSchedule();
      AppToaster.show({
        message: "일정이 수정되었습니다.",
        intent: Intent.SUCCESS
      });

      this.refreshCalendarView();
      this.props.onClose();

    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  }
  handleDeleteSubmit = async () => {
    try {
      await this.formStore.deleteSchedule();
      AppToaster.show({
        message: "일정이 삭제되었습니다.",
        intent: Intent.SUCCESS
      });
      
      this.refreshCalendarView();
      this.props.onClose();

    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }      
  }
  refreshCalendarView = async () => {
    await this.injected.appStore.scheduleManagerStore.fetchDashBoardSeesoSchedules();
    await this.injected.appStore.scheduleManagerStore.refreshDashboardCalendarSeesoSchedules();
  }

  async componentDidMount() {
  }

  handleOpening = async() => {
    const { id } = this.props;
    const { userId, username } = this.injected.appStore.userStore;

    const pmUser = this.injected.appStore.pmUserStore.pmUsers.find(x => x.user_id === userId)
    await this.formStore.initForm(userId, (typeof(pmUser) == "undefined" || pmUser == null) ? username: pmUser.nickname);

    if(id > 0) {
      this.formMode = FormMode.Read;
      try {
        this.readyToShow = false;
        await this.formStore.fetchSchedule(id);
      } catch (e) {
        this.props.onClose();
        AppToaster.show({
          message: '일정 정보가 없습니다',
          intent: Intent.DANGER
        });
      } finally {
        this.readyToShow = true;
      }
      this.startDate = moment(this.currentForm.startAt).format('YYYY-MM-DD');
      this.startTime = moment(this.currentForm.startAt).format('HH:mm');
      if(this.currentForm.endAt) {
        this.endDate = moment(this.currentForm.endAt).format('YYYY-MM-DD');
        this.endTime = moment(this.currentForm.endAt).format('HH:mm');
      } else {
        this.endDate = this.startDate;
        this.endTime = this.startTime;
      }
    } else {
      this.formMode = FormMode.Create;
      this.startDate = moment().format('YYYY-MM-DD');
      this.startTime = '00:00';
      this.endDate = moment().format('YYYY-MM-DD');
      this.endTime = '23:59';
    }

    this.handleSetStartAt();
    this.handleSetEndAt();
  };

  validateBeforeSubmit = () => {
    let form = this.currentForm;

    if(form.creator <= 0) {
      return '작성자는 필수입니다'
    }

    // 휴가 시간 처리
    if(form.schCategory === 'vac') {
      this.currentForm.setStartAt(`${this.startDate}T00:00`)
      this.currentForm.setEndAt(`${this.endDate}T23:59`)

      if(moment(this.currentForm.startAt).isAfter(moment(this.currentForm.endAt))) {
        return '휴가 시작일이 종료일보다 늦습니다';
      }
    }
    if(form.schCategory === 'vam') {
      this.currentForm.setStartAt(`${this.startDate}T00:00`)
      this.currentForm.setEndAt(`${this.startDate}T11:59`)
    }
    if(form.schCategory === 'vpm') {
      this.currentForm.setStartAt(`${this.startDate}T12:00`)
      this.currentForm.setEndAt(`${this.startDate}T23:59`)
    }

    // 시간 미정 일정 처리
    if(form.allDay) {
      this.currentForm.setStartAt(`${this.startDate}T00:00`)
      this.currentForm.setEndAt(`${this.endDate}T23:59`)
    }

    // 관련자가 없는 htmlselect 제거
    form.trimParticipant();

    return '';
  }

  render_category(category: string, sch_category: string) {
    if(category === 'ofh') {
      return <>근무시간</>
    } else if (category === 'sch') {
      switch (sch_category) {
        case 'imt':
          return <>내부미팅</>
        case 'omt':
          return <>외부미팅</>
        case 'vac':
          return <>휴가</>
        case 'vam':
          return <>오전반차</>
        case 'vpm':
          return <>오후반차</>
      }
    }
    return <>{category}</>
  }  

  render_pm_name_by_userid(x: any) {
    let pm = this.injected.appStore.pmUserStore.pmByUserId(x);
    return pm ? pm.nickname : '';
  }  

  render() {
    const { isOpened, onClose } = this.props;
    const { userId, email } = this.injected.appStore.userStore;
    const { pmUserStore: pmStore } = this.injected.appStore;

    const schCategoryOptions: IOptionProps[] = [
      {label: '휴가',    value: 'vac' },
      {label: '오전반차', value: 'vam' },
      {label: '오후반차', value: 'vpm' }
    ]
    let timeOptions = [];
    for(let hour=0; hour<24; hour++) {
      timeOptions.push(hour < 10 ? '0'+hour+':00' : hour+':00');
      timeOptions.push(hour < 10 ? '0'+hour+':30' : hour+':30');
    }
    let participantOptions: IOptionProps[] = [{ label: '없음', value: 0 }];
    pmStore.pmUsers.map(
      pmUser => 
      {
        // partner 가 아니면 본인 계정은 작성자, 관계자 select 시 사용되지 않음
        if(pmUser.user_id !== userId || pmUser.email === 'partner@seeso.kr') {
          participantOptions.push({
            label: pmUser.nickname, value: pmUser.user_id
          })
        }
      }
    );    

    let participantsNames = '';
    if(this.formMode === FormMode.Read) {
      let participantsNameList = this.currentForm.participants.map(
        p => {
          let pm = pmStore.pmByUserId(p);
          return pm ? pm.nickname : ''
        }
      );

      participantsNames = participantsNameList.length > 0 ? participantsNameList.join(', ') : '없음';
    }

    return (

      <Dialog
        onOpening={this.handleOpening}
        isOpen={isOpened}
        usePortal={true}
        autoFocus={true}
        enforceFocus={true}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        isCloseButtonShown={false}
        title={this.formMode === FormMode.Create ? '일정 등록': this.currentForm.title}
      >

      {!this.readyToShow ? (
      <div style={{textAlign: 'center'}}>일정 정보를 불러오고 있습니다</div>
      ) : (
      <>
      <div className={Classes.DIALOG_BODY}>
        {this.currentForm && (
          <>
            <FormGroup label={<FormGroupLabel>작성자</FormGroupLabel>} inline={true}>
              {this.formMode === FormMode.Read ? (
                <div>{this.render_pm_name_by_userid(this.currentForm.creator)}</div>
              ) : (
                <>
                {email !== 'partner@seeso.kr' ? (
                  <div>{this.render_pm_name_by_userid(userId)}</div>
                ) : (
                  <div>
                    <HTMLSelect
                      value={this.currentForm.creator}
                      onChange={(e) => this.handleCreatorChange(e)}
                      options={participantOptions}
                    />                    
                  </div>
                )}
                </>
              )}
            </FormGroup>

            <FormGroup label={<FormGroupLabel>카테고리</FormGroupLabel>} inline={true}>
              {/* 새로 만들때를 제외하고는 카테고리를 수정할 수는 없도록 함 */}
              {this.formMode === FormMode.Create || this.formMode === FormMode.Edit ? (
                <HTMLSelect 
                  value={this.currentForm.schCategory} 
                  onChange={this.handleSchCategoryChanged}
                  options={schCategoryOptions}
                />
              ) : (
                <div>{this.render_category(this.currentForm.category, this.currentForm.schCategory)}</div>
              )}
            </FormGroup>

            {/* 근무시간 폼 처리 */}
            {(this.currentForm.category === 'ofh') && (
            <>
            <FormGroup label={<FormGroupLabel>시간</FormGroupLabel>} inline={true}>
              {this.formMode === FormMode.Read ? (
                  <div>{this.startDate}&nbsp;{moment(this.currentForm.startAt).format("HH:mm")} - {moment(this.currentForm.endAt).format("HH:mm")}</div>
              ) : (
                <>
                <span>{this.startDate}&nbsp;</span>
                <HTMLSelect
                  value={moment(this.currentForm.startAt).format("HH:mm")}
                  onChange={this.handleStartTime}
                  options={timeOptions} 
                />                
                <span>&nbsp; - &nbsp;</span>
                <HTMLSelect
                  value={moment(this.currentForm.endAt).format("HH:mm")}
                  onChange={this.handleEndTime}
                  options={timeOptions} 
                />                
                </>
              )}              
            </FormGroup>

            <FormGroup label={<FormGroupLabel>장소</FormGroupLabel>} inline={true}>
              {this.formMode === FormMode.Read ? (
                <div>{this.currentForm.title}</div>
              ): (
                <InputGroup
                  value={this.currentForm.title}
                  onChange={this.handleTitleChange}
                  style={{ width: "300px" }}
                />
              )}              
            </FormGroup>
            </>
            )}

            {/* 내부미팅, 외부미팅 폼 처리 */}
            {/* {(this.currentForm.schCategory === 'imt' || this.currentForm.schCategory === 'omt') && (
            <>
            <FormGroup label={<FormGroupLabel>날짜</FormGroupLabel>} inline={true}>
              {this.formMode === FormMode.Read ? (
                  <div>{moment(this.startDate).format("YYYY/MM/DD")}</div>
                ) : (
                  <DateInput 
                    {...getMomentFormatter("YYYY/MM/DD")}
                    locale="ko"
                    closeOnSelection={true}
                    value={moment(this.startDate).toDate()}
                    onChange={this.handleStartDate}
                    maxDate={new Date("2050-01-01")}
                  />
              )}              
            </FormGroup>

            <FormGroup label={<FormGroupLabel>시간(옵션)</FormGroupLabel>} inline={true}>
              {this.formMode === FormMode.Read ? (
                  <div>{this.currentForm.allDay ? '미정' : moment(this.currentForm.startAt).format("HH:mm")}</div>
                ) : (
                  <>
                  <Checkbox checked={this.currentForm.allDay}
                  label="미정"
                  onChange={this.handleAllDayChanged}
                  inline={true}
                  />
                  <HTMLSelect
                    value={moment(this.currentForm.startAt).format("HH:mm")}
                    disabled={this.currentForm.allDay}
                    onChange={this.handleStartTime}
                    options={timeOptions} 
                  />
                  </>
              )}              
            </FormGroup>

            <FormGroup 
              label={
                <FormGroupLabel>
                  관계자
                  {
                    this.formMode !== FormMode.Read && (
                      <IconButton icon="add" iconSize={14} intent={Intent.PRIMARY} onClick={() => this.handleAddParticipant()} />
                    )
                  }
                </FormGroupLabel>
              } 
              inline={true}
            >
              {this.formMode === FormMode.Read ? (
                  <div>{participantsNames}</div>
                ) : this.currentForm.participants.map(
                  (participantId, idx) => (
                    <div key={idx}>
                    <HTMLSelect
                      value={participantId}
                      onChange={(e) => this.handleSetParticipant(e, idx)}
                      options={participantOptions}
                    />
                    <IconButton icon="delete" iconSize={14} intent={Intent.DANGER} onClick={() => this.handleDelParticipant(idx)} />
                    </div>
                  )
                )}                
            </FormGroup>

            <FormGroup label={<FormGroupLabel>일정제목</FormGroupLabel>} inline={true}>
              {this.formMode === FormMode.Read ? (
                  <div>{this.currentForm.title}</div>
                ) : (
                  <InputGroup
                    value={this.currentForm.title}
                    onChange={this.handleTitleChange}
                    style={{ width: "300px" }}
                  />
              )}  
            </FormGroup>

            <FormGroup label={<FormGroupLabel>일정내용(옵션)</FormGroupLabel>} inline={true}>
              {this.formMode === FormMode.Read ? (
                  <div>{this.currentForm.comment}</div>
                ) : (
                  <TextArea
                    value={this.currentForm.comment}
                    onChange={this.handleCommentChange}
                    style={{ width: "300px" }}
                  />
              )}  
            </FormGroup>
            </>
            )} */}

            {/* 휴가 폼 처리 */}
            {(this.currentForm.schCategory === 'vac') && (
            <>
            <FormGroup label={<FormGroupLabel>휴가종류</FormGroupLabel>} inline={true}>
              {this.formMode === FormMode.Read ? (
                  <div>{this.currentForm.title}</div>
                ) : (
                  <div>
                    <RadioGroup onChange={(e: FormEvent<HTMLInputElement>) => {
                      this.currentForm.setTitle(e.currentTarget.value);
                    }} inline={true} selectedValue={this.currentForm.title}>
                      <Radio label="연차" value="연차" />
                      <Radio label="경조사" value="경조사" />
                      <Radio label="민방위" value="민방위" />
                    </RadioGroup>
                  </div>
              )}
            </FormGroup>

            <FormGroup label={<FormGroupLabel>시작날짜</FormGroupLabel>} inline={true}>
              {this.formMode === FormMode.Read ? (
                  <div>{moment(this.startDate).format("YYYY/MM/DD")}</div>
                ) : (
                  <DateInput 
                    {...getMomentFormatter("YYYY/MM/DD")}
                    locale="ko"
                    closeOnSelection={true}
                    value={moment(this.startDate).toDate()}
                    onChange={this.handleStartDate}
                    maxDate={new Date("2050-01-01")}
                  />
              )}              
            </FormGroup>

            <FormGroup label={<FormGroupLabel>종료날짜</FormGroupLabel>} inline={true}>
              {this.formMode === FormMode.Read ? (
                  <div>{moment(this.endDate).format("YYYY/MM/DD")}</div>
                ) : (
                  <DateInput 
                    {...getMomentFormatter("YYYY/MM/DD")}
                    locale="ko"
                    closeOnSelection={true}
                    value={moment(this.endDate).toDate()}
                    onChange={this.handleEndDate}
                    maxDate={new Date("2050-01-01")}
                  />
              )}              
            </FormGroup>
            </>
            )}

            {/* 반차 폼 처리 */}
            {(this.currentForm.schCategory === 'vam' || this.currentForm.schCategory === 'vpm') && (
            <>
            <FormGroup label={<FormGroupLabel>날짜</FormGroupLabel>} inline={true}>
              {this.formMode === FormMode.Read ? (
                  <div>{moment(this.startDate).format("YYYY/MM/DD")}</div>
                ) : (
                  <DateInput 
                    {...getMomentFormatter("YYYY/MM/DD")}
                    locale="ko"
                    closeOnSelection={true}
                    value={moment(this.startDate).toDate()}
                    onChange={this.handleStartDate}
                    maxDate={new Date("2050-01-01")}
                  />
              )}              
            </FormGroup>
            </>
            )}
          </>
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {
            this.formMode === FormMode.Create && (
              <>
              <Button text="등록" intent={Intent.PRIMARY} onClick={this.handleSubmit} />
              <Button text="취소" onClick={onClose} />
              </>
            )
          }
          {
            this.formMode === FormMode.Read && (
              <>
              {(this.currentForm.creator === userId || email === 'partner@seeso.kr') && (
                <DialogFooterLeftWrapper>
                  <Button text="삭제" intent={Intent.DANGER} onClick={this.handleDeleteSubmit} />
                </DialogFooterLeftWrapper>
              )}              
              {(this.currentForm.creator === userId || email === 'partner@seeso.kr') && (
                <Button text="수정" intent={Intent.PRIMARY} onClick={this.handleChangeToEdit} />
              )}
              <Button text="닫기" onClick={onClose} />
              </>
            )
          }
          {
            this.formMode === FormMode.Edit && (
              <>
              <Button text="저장" intent={Intent.PRIMARY} onClick={this.handleEditSubmit} />
              <Button text="취소" onClick={onClose} />
              </>
            )
          }
          </div>
      </div>
      </>
      )}
      </Dialog>
    );
  }
}

export default DashboardSchedulePopup;
