import styled from "styled-components/macro";

export const TableContainer = styled.div`
  /* padding-left: calc(72 + 222) px; */
  padding-left: 294px;
  padding-right: 132px;
`;

export const Table = styled.table`
  width: 100%;
  background: none;
  z-index: 1;
  position: relative;
`;

export const THead = styled.thead`
  tr {
    height: 40px;
    text-align: left;
  }
`;
export const FirstColumnTH = styled.th`
  width: 450px;
`
export const SubColumnTH = styled.th`
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7f8390;
  text-align: center;
  width: 100px;
`;

export const GreyBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 40px;
  background: #f8f8f8;
  z-index: 0;
`;
export const TR = styled.tr`
`;
export const TD = styled.td`
  color: #161d2e;
`;

export const RightAlignTD = styled(TD)`
  text-align: right;
`

export const SprintSpan = styled.span`
  font-weight: bold;
  padding-right: 15px;
`

export const HighlightStatus = styled.span`
  color: red;
`