import styled from "styled-components/macro";

export const ToolsRow = styled.div`
  margin-bottom: 40px;
  display: flex;
  height: 20px;

  & > * + * {
    margin-left: 10px;
  }
`;

export const InputContainer = styled.div`
  width: 300px;
  margin-bottom: 30px;
`;

export const RightTools = styled.span`
  margin-left: auto;
`

export const TR = styled.tr`
`;
export const TD = styled.td`
`;
export const IDDiv =styled.div`
  width: 80px;
`;
export const PaymentDiv = styled.div`
  width: 120px;
  text-align: right;
`;
export const DateDiv = styled.div`
  width: 120px;
`;

export const VATRow = styled.div`
  text-align: right; 
`;