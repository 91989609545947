import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { Button, Intent } from "@blueprintjs/core";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import { ClientCalculate } from "../../../store/models/ClientCalculate";
import ClientCalculateFormPopup from "../CalculatePopup/ClientCalculateFormPopup";
import ClientCalculateDetailPopup from "../CalculatePopup/ClientCalculateDetailPopup";
import { FormMode } from "../../../types/formMode";
import { 
  TD, 
  TR,
  IDDiv,
} from "../../atoms/CalculateTable/CalculateTable";


interface ClientCalculateRowProps {
  calculate: ClientCalculate;
  includeVat: boolean;
  editable: boolean;
  onSearch: () => void;
}

interface InjectedProps extends ClientCalculateRowProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ClientCalculateRow extends Component<ClientCalculateRowProps> {
  @observable isDetailPopupOpen = false;
  @observable isFormPopupOpen = false;

  get injected() {
    return this.props as InjectedProps;
  }

  get contractStore() {
    return this.injected.appStore.contractStore;
  }

  get calculateStore() {
    return this.injected.appStore.calculateStore;
  }

  handleFormPopupOpen = () => {
    this.isFormPopupOpen = true;
  };

  handleFormPopupClose = () => {
    this.isFormPopupOpen = false;
    this.props.onSearch();
  };

  handleDetailPopupOpen = () => {
    this.isDetailPopupOpen = true;
  };

  handleDetailPopupClose = () => {
    this.isDetailPopupOpen = false;
    this.props.onSearch();
  };

  render() {
    const { calculate, includeVat, editable } = this.props;

    return (
      <>
         <TR key={calculate.id}>
          <TD>
            {/* <IDDiv>{this.contract ? this.contract.approvedContractId : ""}</IDDiv> */}
            <IDDiv>{calculate.contract}</IDDiv>
          </TD>
          <TD>{calculate.contractName}</TD>
          <TD>
            <Link to={`/project-groups/${calculate.projectGroup}`} target="_blank">
              {calculate.projectGroup}
            </Link>
          </TD>
          <TD>{calculate.clientName}</TD>
          <TD>
            {calculate.dateIssue} 
          </TD>
          <TD>
             {
               calculate.payout ?
                includeVat ? calculate.payoutWithVAT.toLocaleString("en-US") : calculate.payout.toLocaleString("en-US")
                : ""
             }
          </TD>
          <TD>
              {calculate.deposits.length > 0 && calculate.dateLastDeposit}
          </TD>
          <TD>
              {
                calculate.receivedPayout ?
                  includeVat ? calculate.receivedPayoutWithVAT.toLocaleString("en-US") : calculate.receivedPayout.toLocaleString("en-US")
                  : ""
              }
          </TD>
          <TD>{ calculate.comment && calculate.comment.split('\n').map((item, i) => <div key={i}>{item}</div>) }</TD>
          <TD>
            {
              editable ? (
                <Button
                  text="수정하기"
                  small={true}
                  onClick={this.handleFormPopupOpen} />
              ) : (
                <span />
              )
            }
            <Button
              text="상세보기"
              small={true}
              onClick={this.handleDetailPopupOpen}
              intent={Intent.PRIMARY} />
          </TD>
        </TR>

        <ClientCalculateFormPopup
          calculate={calculate}
          mode={FormMode.Edit}
          onClose={this.handleFormPopupClose}
          isOpened={this.isFormPopupOpen} />

        <ClientCalculateDetailPopup
          calculate={calculate}
          includeVat={includeVat}
          onClose={this.handleDetailPopupClose}
          isOpened={this.isDetailPopupOpen} />
      </>
    );
  }
}

export default ClientCalculateRow;
