import styled, { css } from "styled-components/macro";

export const RoundedButton = styled.button<{ disabled?: boolean }>`
  border: none;
  border-radius: 21px;
  box-shadow: 0 5px 25px 0 rgba(53, 62, 91, 0.05);
  background-color: #2d41f7;
  height: 41px;
  padding: 0 20px;
  min-width: 130px;

  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  cursor: pointer;

  ${p =>
    p.disabled &&
    css`
      user-select: none;
      opacity: 0.3;
      cursor: not-allowed;
    `}
`;
