import React, { FC, useState } from "react";
import styled from "styled-components/macro";
import moment from "moment";
import { ProjectGroup } from '../../../store/models/ProjectGroup';
import { PMUser } from '../../../store/models/PMUser';
import { Colors, Tooltip, Position } from "@blueprintjs/core";


const Wrapper = styled.div`
  display: flex;
  align-items: center;

  border: 1px solid #a0a7bb;

  margin-top: 10px;
`;
const TitleWrapper = styled.div`
  // display: flex;
  width: 130px;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 900;
`;
const TitleManager = styled.div`
  font-weight:600;
  font-size:10px;
  color: #5C7080;
  * + * {
    margin-right: 5px;
  }
`;
const TimelineWrapper = styled.div`
  border-left: 1px solid #a0a7bb;
  position: relative;
`;
const SprintInnerWrapper = styled.div`
  display: inline-block;
  
  background: #FFE4A0;
  padding: 5px 5px;
  border-right: 1px solid #a0a7bb;
  border-left: 1px solid #a0a7bb;
  border-bottom: 1px solid #a0a7bb;
  height: 100%;
  overflow: hidden;
  
`;
const SprintGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 40px;
`;
const SprintTitleWrapper = styled.div`
  height: 100%;
  font-size: 12px;
`;
const SprintTitleManagerWrapper = styled.div`
  font-size: 10px;
  font-weight: 600;
`;

interface SprintProps {
  name: string | undefined;
  role: string | undefined;
  pm: PMUser | undefined;
  numSprint: number | undefined;
  width: number | undefined;
  left: number | undefined;
  dateStart: string | undefined;
  dateEnd: string | undefined;
}
const Sprint: FC<SprintProps> = props => {
  const { name, role, pm, numSprint, width, left, dateStart, dateEnd } = props;

  let color = [props.pm ? props.pm.color : Colors.LIGHT_GRAY2, Colors.LIGHT_GRAY5];

  const [ background, setBackground ] = useState(color[0]);

  return (
    
      <SprintInnerWrapper
        style={{'width': `${width}px`, 'left': `${left}px`, background: background }}
        onMouseEnter={() => setBackground(color[1])}
        onMouseLeave={() => setBackground(color[0])}
      >
        <Tooltip content={`S${numSprint} ${role} ${name} - ${pm ? pm.nickname : ''} : ${dateStart}~${dateEnd}`} position={Position.RIGHT}>
         <SprintTitleWrapper>
           S{ numSprint } { role } { name }<br/>
           <SprintTitleManagerWrapper>{ pm ? pm.nickname : '' }</SprintTitleManagerWrapper>
         </SprintTitleWrapper>
        </Tooltip>
      </SprintInnerWrapper>
    
  );
}

interface DashboardProjectGroupProps {
  baseDate: string;
  dateWidth: number;
  pmUsers: PMUser[];
  projectGroup: ProjectGroup;
}

export const DashboardProjectGroup: FC<DashboardProjectGroupProps> = props => {
  const baseDate = moment(props.baseDate).subtract(7, 'days');
  const baseEndDate = moment(baseDate.format('YYYY-MM-DD')).add(7 * 6 + 1, 'days');
  const { name, managers, schedule } = { ...props.projectGroup };

  let sprintGroups: any = {};
  if(schedule && schedule.rufreeSchedules){
    schedule.rufreeSchedules.map(rs => {
        rs.sprints.map(s => {          
          const dateStart = moment(s.dateStart);
          const dateEnd = moment(s.dateEnd);


          if(baseDate.diff(dateEnd, 'days') < 0 && dateEnd < baseEndDate)  {
            let width = 0;
            const left = (dateStart.diff(baseDate, 'days') - 1) * props.dateWidth;
            if(baseDate.diff(dateStart, 'days') > 0){
              width = (dateEnd.diff(baseDate, 'days')) * props.dateWidth;
            } else {
              width = (dateEnd.diff(dateStart, 'days') + 1) * props.dateWidth;
            }
            

            if (!(s.sprintNumber in sprintGroups)) {
              sprintGroups[s.sprintNumber] = [];
            }

            const pm = props.pmUsers.find(x => x.name === s.manager);

            sprintGroups[s.sprintNumber].push(
              {
                width: width,
                left: left < 0 ? 0 : left,
                name: rs.rufreeName,
                role: rs.role,
                pm: pm,
                numSprint: s.sprintNumber,
                dateStart: dateStart.format('MM-DD'),
                dateEnd: dateEnd.format('MM-DD')
              }
            );
          }
        })
    })
    
    // sorting
    let sorted = [];
    for(let key in sprintGroups) {
      sorted[sorted.length] = key;
    }
    sorted.sort();

    let tempDict: any = {};
    for(let i = 0; i < sorted.length; i++) {
      tempDict[sorted[i]] = sprintGroups[sorted[i]];
    }

    sprintGroups = tempDict;
  }

  

  if(Object.keys(sprintGroups).length > 0) {
    return (
      <Wrapper>
        <TitleWrapper>
          <div>
          { name }
          </div>
          <TitleManager>
            {
              managers.map(manager => (
                <span key={`sgm_${manager.name}`}>{manager.name}</span>
              ))
            }
          </TitleManager>
        </TitleWrapper>
        
        {
          Object.keys(sprintGroups).map((key, index) => {
            return (
              <TimelineWrapper key={`tw_${key}_${index}`} style={{'height': `${sprintGroups[key].length * 40}px` }}>
                <SprintGroupWrapper key={`sgw_${key}_${index}`} style={{'height': `${sprintGroups[key].length * 40}px`, 'left': `${sprintGroups[key][0].left}px`}}>
                  { 
                    sprintGroups[key].map((sg:SprintProps, i:number) => 
                      <Sprint key={`sgws_${key}${i}`} { ...sg }  />
                    ) 
                  }
                </SprintGroupWrapper>
              </TimelineWrapper>
            )
          })
        }
        
      </Wrapper>
    )
  }

  return ( <div /> );
};
