import React, { Component, ChangeEvent, KeyboardEvent } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { observable } from "mobx";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import { FormGroup, InputGroup, Button, Intent } from "@blueprintjs/core";

const Wrapper = styled.div``;

const AuthForm = styled.div`
  padding: 40px;
  max-width: 400px;
`;

interface AuthPageProps {
}

interface InjectedProps extends AuthPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class AuthPage extends Component<AuthPageProps> {
  @observable curPassword = "";
  @observable newPassword = "";
  @observable newConfirmPassword = "";

  get injected() {
    return this.props as InjectedProps;
  }

  get userStore() {
    return this.injected.appStore.userStore;
  }

  componentDidMount() {
    this.userStore.checkLoginStatus();
  }

  handleCurPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.curPassword = e.target.value;
  };
  handleNewPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.newPassword = e.target.value;
  };
  handleNewConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.newConfirmPassword = e.target.value;
  };
  handleNewConfirmPasswordKeyDown = async(event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      await this.handleSubmit();
    }
  }  

  handleSubmit = async () => {
    if(!this.curPassword || !this.newPassword || !this.newConfirmPassword) {
      AppToaster.show({
        message: '입력값이 비어있습니다.',
        intent: Intent.DANGER
      });
      return;
    }

    if(this.newPassword !== this.newConfirmPassword) {
      AppToaster.show({
        message: '새 비밀번호와 확인 비밀번호가 일치하지 않습니다.',
        intent: Intent.DANGER
      });
      return;
    }

    try {
      await this.userStore.changePassword(this.curPassword, this.newPassword);
      AppToaster.show({
        message: "비밀번호를 변경했습니다.",
        intent: Intent.SUCCESS
      });      
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }

  }

  render() {
    return (
      <Wrapper>
        <AuthForm>
          <FormGroup label="현재 비밀번호">
            <InputGroup
              value={this.curPassword}
              type="password"
              onChange={this.handleCurPasswordChange}
            />
          </FormGroup>
          <FormGroup label="새 비밀번호">
            <InputGroup
              value={this.newPassword}
              type="password"
              onChange={this.handleNewPasswordChange}
            />
          </FormGroup>
          <FormGroup label="새 비밀번호 확인">
            <InputGroup
              value={this.newConfirmPassword}
              type="password"
              onChange={this.handleNewConfirmPasswordChange}
              onKeyDown={this.handleNewConfirmPasswordKeyDown}
            />
          </FormGroup>                  
          <Button text="저장" onClick={this.handleSubmit} />
        </AuthForm>
      </Wrapper>
    );
  }

}

export default AuthPage;