import { types, flow } from "mobx-state-tree";
import axios from "axios";
import { IMember, MemberModel } from "./models/Member";

const PAGE_SIZE = 25;
export const MemberStoreModel = types
  .model("MemberStore", {
    member: types.maybeNull(MemberModel),
    members: types.array(MemberModel),

    // For request status
    isFetching: types.optional(types.boolean, false),
    // For paginator
    currentPage: types.optional(types.number, 1),
    pageGroup: types.optional(types.number, 1),
    totalPages: types.optional(types.number, 1),
  })
  .views(self => ({
    // get sortedPartners() {
    //   return self.partners.sort(
    //     (a, b) =>
    //       new Date(b.created).getTime() -
    //       new Date(a.created).getTime()
    //   );
    // }
  }))
  .actions(self => ({
    setCurentPage(value: number) {
      self.currentPage = value;
    },
    setPageGroup(value: number) {
      self.pageGroup = value;
    }
  }))
  .actions(self => {
    const fetchAllMembers = flow(function*() {
      try {
        self.isFetching = true;
        const { data }: { data: ListResponse } = yield axios.get(
          "/admin/members/",
        );

        const members = data.results.map(result =>
          MemberModel.create(result)
        );
        self.members.replace(members.reverse());
        self.isFetching = false;
      } catch (e) {
        self.isFetching = false;
        throw e;
      }
    });
    const fetchMembers = flow(function*(
      search: string='',
    ) {
      try {
        self.isFetching = true;
        const { data }: { data: ListResponse } = yield axios.get(
          "/admin/members/",
          {
            params: Object.assign({
                search: search,
                page_size: PAGE_SIZE,
                page: self.currentPage
              }
            )
          }
        );

        const members = data.results.map(result =>
          MemberModel.create(result)
        );

        self.totalPages = Math.round(data.count / PAGE_SIZE);
        self.members.replace(members.reverse());
        self.isFetching = false;
      } catch (e) {
        self.isFetching = false;
        throw e;
      }
    });
    const fetchMember = flow(function*(
      code: string
    ) {
      try {
        const { data }: { data: IMember } = yield axios.get(
          `/admin/members/${code}/`
        );

        self.member = MemberModel.create(data);
      } catch (e) {
        throw e;
      }
    });

    return {
      fetchAllMembers,
      fetchMembers,
      fetchMember
    };
  });

type MemberStoreType = typeof MemberStoreModel.Type;
export interface IMemberStore extends MemberStoreType {}


export interface ListResponse {
  count: number;
  next: number | null;
  previous: number | null;
  results: IMember[]
}