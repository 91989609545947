import { types, flow } from "mobx-state-tree";
import axios from "axios";
import { ClientContractFormModel, ClientContractForm } from "./ClientContractForm";
import { RufreeContractFormModel, RufreeContractForm } from "./RufreeContractForm";
import { ApprovedContractFormModel, ApprovedContractForm } from "./ApprovedContractForm";
import { InspectionSelectionFormModel } from "../ProjectGroupForm/InspectionSelectionForm";
import {
  ClientContractResponse,
  RufreeContractResponse,
  ContractTemplateResponse,
  ApprovedClientContractResponse,
  ApprovedRufreeContractResponse
} from "../../../types/contract";
import {
  ClientContractRequestModel,
  RufreeContractRequestModel,
  RufreeContractRequestSimpleModel
} from "../../models/ContractRequest";
import { mapClientContract, mapRufreeContract, mapRufreeContractSimple } from "../../ContractStore";

const mapClientContractForm = (x: ClientContractResponse) => {
  return {
    id: x.id,
    contractId: x.contract_id,
    contractName: x.contract_name || "",
    address: x.address,
    representativeName: x.representative_name,
    contractorName: x.contractor_name,
    contractorEmail: x.contractor_email,
    contractorPhone: x.contractor_phone,
    taxInvoiceManagerName: x.tax_invoice_manager_name,
    taxInvoiceManagerEmail: x.tax_invoice_manager_email,
    taxInvoiceManagerContact: x.tax_invoice_manager_contact,
    nda: x.nda,
    contractDate: x.contract_date,
    contractFeeRate: x.contract_fee_rate,
    contractRange: x.contract_range,
    specialContract: x.special_contract,
    comment: x.comment,
    status: x.status,
    client: x.client || "",
    inspections: x.inspections.map(inspection =>
      InspectionSelectionFormModel.create({ value: inspection })
    ),
    pmUser: x.pm_user,
    contractorNumber: x.contractor_number || "",
    contractorIdentityNumber: x.contractor_identity_number || "",
    contractType: x.contract_type
  };
};

const mapRufreeContractForm = (x: RufreeContractResponse) => {
  return {
    id: x.id,
    contractId: x.contract_id,
    contractName: x.contract_name,
    info: x.info,
    agentName: x.agent_name,
    agentEmail: x.agent_email,
    agentAddress: x.agent_address,
    address: x.address,
    contractDate: x.contract_date,
    sprintPeriod: x.sprint_period,
    payment: x.payment,
    commission: x.commission,
    paymentFee: x.payment_fee,
    commissionFee: x.commission_fee,
    contractRange: x.contract_range,
    specialContract: x.special_contract,
    comment: x.comment,
    status: x.status,
    rufreeUser: x.rufreeUser,
    inspections: x.inspections.map(inspection =>
      InspectionSelectionFormModel.create({ value: inspection })
    ),
    pmUser: x.pm_user
  };
};
const mapApprovedRufreeContractForm = (x: ApprovedRufreeContractResponse) => {
  return {
    type: "rufree",
    approved_contract_id: x.approved_contract_id,
    contract: x.rufree_contract || "",
    contract_detail: null, // for client contract
    // rufree_contract_detail: null,
    rufree_contract_detail: x.rufree_contract_detail && RufreeContractRequestSimpleModel.create(
      mapRufreeContractSimple(x.rufree_contract_detail)
    ),
    template: x.template || "",
    comment_opened: x.comment_opened,
    editor_content: x.editor_content,
    manager_name: x.manager_name,
    contract_name: x.contract_name
  };
};
export const ContractFormStoreModel = types
  .model("ContractFormStore", {
    clientContractForm: types.optional(ClientContractFormModel, {}),
    rufreeContractForm: types.optional(RufreeContractFormModel, {}),
    approvedContractForm: types.optional(ApprovedContractFormModel, { type: "client" })
  })
  .actions(self => {
    const initClientContractForm = () => {
      self.clientContractForm = ClientContractFormModel.create({});
    };

    const initRufreeContractForm = () => {
      self.rufreeContractForm = RufreeContractFormModel.create({});
    };

    const initApprovedContractForm = (type: string) => {
      self.approvedContractForm = ApprovedContractFormModel.create({
        type: type
      });
    };

    const mapClientContractFormDataForRequest = () => {
      const form = self.clientContractForm;

      return {
        id: form.id,
        contract_id: form.contractId,
        contract_name: form.contractName,
        address: form.address,
        representative_name: form.representativeName,
        contractor_name: form.contractorName,
        contractor_email: form.contractorEmail,
        contractor_phone: form.contractorPhone,
        tax_invoice_manager_name: form.taxInvoiceManagerName,
        tax_invoice_manager_email: form.taxInvoiceManagerEmail,
        tax_invoice_manager_contact: form.taxInvoiceManagerContact,
        nda: form.nda,
        contract_date: form.contractDate,
        contract_fee_rate: form.contractFeeRate,
        contract_range: form.contractRange,
        special_contract: form.specialContract,
        comment: form.comment,
        client: form.client,
        inspections: form.inspections.filter(x => x.value).map(x => x.value),
        pm_user: form.pmUser,
        // project_group: form.projectGroup,
        contractorNumber: form.contractorNumber,
        contractor_number: form.contractorNumber,
        contractor_identity_number: form.contractorIdentityNumber,
        contract_type: form.contractType
      };
    };

    const mapRufreeContractFormDataForRequest = () => {
      const form = self.rufreeContractForm;

      return {
        id: form.id,
        contract_id: form.contractId,
        contract_name: form.contractName,
        info: form.info,
        agent_name: form.agentName,
        agent_email: form.agentEmail,
        agent_address: form.agentAddress,
        address: form.address,
        contract_date: form.contractDate,
        sprint_period: form.sprintPeriod,
        payment: form.payment,
        commission: form.commission,
        payment_fee: form.paymentFee,
        commission_fee: form.commissionFee,
        contract_range: form.contractRange,
        special_contract: form.specialContract,
        comment: form.comment,
        rufreeUser: form.rufreeUser,
        inspections: form.inspections.filter(x => x.value).map(x => x.value),
        pm_user: form.pmUser,
        contract_type: form.contractType
      };
    };

    const postClientContract = flow(function* () {
      try {
        // create rufree first
        const { data }: { data: ClientContractResponse } = yield axios.post(
          `/clientContract`,
          mapClientContractFormDataForRequest()
        );
        const newForm = ClientContractFormModel.create(mapClientContractForm(data));
        self.clientContractForm = newForm;
      } catch (e) {
        throw e;
      }
    });

    const patchClientContract = flow(function* () {
      try {
        const { data }: { data: ClientContractResponse } = yield axios.patch(
          `/clientContract/${self.clientContractForm.contractId}`,
          mapClientContractFormDataForRequest()
        );
        const newForm = ClientContractFormModel.create(mapClientContractForm(data));
        self.clientContractForm = newForm;
      } catch (e) {
        throw e;
      }
    });

    const fetchClientContract = flow(function* (id: string) {
      try {
        const { data }: { data: ClientContractResponse } = yield axios.get(
          `/clientContract/${id}`
        );

        const newForm = ClientContractFormModel.create(mapClientContractForm(data));

        self.clientContractForm = newForm;
      } catch (e) {
        throw e;
      }
    });

    const postRufreeContract = flow(function* () {
      try {
        const { data }: { data: RufreeContractResponse } = yield axios.post(
          `/rufreeContract`,
          mapRufreeContractFormDataForRequest()
        );
        const newForm = RufreeContractFormModel.create(mapRufreeContractForm(data));
        self.rufreeContractForm = newForm;
      } catch (e) {
        throw e;
      }
    });

    const patchRufreeContract = flow(function* () {
      try {
        const { data }: { data: RufreeContractResponse } = yield axios.patch(
          `/rufreeContract/${self.rufreeContractForm.contractId}`,
          mapRufreeContractFormDataForRequest()
        );
        const newForm = RufreeContractFormModel.create(mapRufreeContractForm(data));
        self.rufreeContractForm = newForm;
      } catch (e) {
        throw e;
      }
    });

    const fetchRufreeContract = flow(function* (id: string) {
      try {
        const { data }: { data: RufreeContractResponse } = yield axios.get(
          `/rufreeContract/${id}`
        );

        const newForm = RufreeContractFormModel.create(mapRufreeContractForm(data));
        self.rufreeContractForm = newForm;
      } catch (e) {
        throw e;
      }
    });

    const saveApprovedContract = flow(function* () {
      console.log("self.approvedContractForm.type", self.approvedContractForm.type);
      if (self.approvedContractForm.type === "client") {
        if (self.approvedContractForm.client == "")
          throw "Client를 선택해주세요";
        try {
          let data: ApprovedClientContractResponse;
          if (self.approvedContractForm.approved_contract_id) {
            data = yield axios.patch(
              `/approvedClientContract/${self.approvedContractForm.approved_contract_id}`,
              {
                ...self.approvedContractForm,
                client_contract: self.approvedContractForm.contract
              }
            );
          } else {
            const { data }: { data: ApprovedClientContractResponse } = yield axios.post(
              `/approvedClientContract`,
              {
                ...self.approvedContractForm,
                client_contract: self.approvedContractForm.contract
              }
            );
            console.log("data", data);
            const newForm = ApprovedContractFormModel.create({
              type: "client",
              approved_contract_id: data.approved_contract_id,
              contract: data.client_contract,
              contract_detail: data.client_contract_detail && mapClientContract(data.client_contract_detail),
              template: data.template,
              comment_opened: data.comment_opened,
              editor_content: data.editor_content,
              manager_name: data.manager_name,
              contract_name: data.contract_name,
              client: data.client
            });
            self.approvedContractForm = newForm;
          }
        } catch (e) {
          throw e;
        }
      } else {
        try {
          let data: ApprovedRufreeContractResponse;
          if (self.approvedContractForm.approved_contract_id) {
            data = yield axios.patch(
              `/approvedRufreeContract/${self.approvedContractForm.approved_contract_id}`,
              {
                ...self.approvedContractForm,
                rufree_contract: self.approvedContractForm.contract
              }
            );
          } else {
            const { data }: { data: ApprovedRufreeContractResponse } = yield axios.post(
              `/approvedRufreeContract`,
              {
                ...self.approvedContractForm,
                rufree_contract: self.approvedContractForm.contract
              }
            );

            const newForm = ApprovedContractFormModel.create(
              mapApprovedRufreeContractForm(data)
            );
            self.approvedContractForm = newForm;
          }
          // const newForm = ApprovedContractFormModel.create({
          //   type: 'rufree',
          //   ...data
          // })

          // self.approvedContractForm = { ...data };
          // self.approvedContractForm.approved_contract_id = data.approved_contract_id;
        } catch (e) {
          throw e;
        }
      }
    });
    const fetchApprovedContract = flow(function* (id: string, type: string) {
      if (type === "client") {
        try {
          const { data }: { data: ApprovedClientContractResponse } = yield axios.get(
            `/approvedClientContract/${id}`
          );
          console.log("data.client_contract_detail", data.client_contract_detail);
          if (data.client_contract_detail) {
            const b = mapClientContractForm(data.client_contract_detail);
            // if(b)
            //   ClientContractRequestModel.create(b)
            // console.log("bbb", b);
            // const contract =ClientContractRequestModel.create(mapClientContract(data.client_contract_detail))
          }
          // const a = data.client_contract_detail ? mapClientContract(data.client_contract_detail):null;
          // console.log("aaaa",a);
          const contract_detail = data.client_contract_detail ? mapClientContract(data.client_contract_detail) : null;

          const newForm = ApprovedContractFormModel.create({
            type: "client",
            contract: data.client_contract || "",
            contract_detail: contract_detail ? ClientContractRequestModel.create(contract_detail) : null,
            //   ClientContractRequestModel.create(mapClientContract(data.client_contract_detail))
            //   : undefined,
            // client_contract_detail: mapClientContract(data.client_contract_detail),
            rufree_contract_detail: null,
            ...data,
            template: data.template || "",
            client: data.client || ""
          });
          self.approvedContractForm = newForm;
        } catch (e) {
          throw e;
        }
      } else {
        try {
          const { data }: { data: ApprovedRufreeContractResponse } = yield axios.get(
            `/approvedRufreeContract/${id}`
          );
          const newForm = ApprovedContractFormModel.create(mapApprovedRufreeContractForm(data));
          self.approvedContractForm = newForm;
        } catch (e) {
          throw e;
        }
      }
    });
    const setClientContractForm = (val:any) =>{
      self.clientContractForm = val;
    };
    const setRufreeContractForm = (val:any) =>{
      self.rufreeContractForm = val;
    };
    const setApprovedContractForm = (val:any) =>{
      self.approvedContractForm = val;
    };

    return {
      initClientContractForm,
      postClientContract,
      patchClientContract,
      fetchClientContract,
      initRufreeContractForm,
      postRufreeContract,
      patchRufreeContract,
      fetchRufreeContract,

      initApprovedContractForm,
      saveApprovedContract,
      fetchApprovedContract,

      setClientContractForm,
      setRufreeContractForm,
      setApprovedContractForm,

    };
  });

type ContractFormStoreType = typeof ContractFormStoreModel.Type;

export interface ContractFormStore extends ContractFormStoreType {
}