import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import styled from "styled-components/macro";
import { ItemCard } from "../../atoms/ParttimeComponents/ParttimeComponents";

import { Task, TaskStatus, TaskType, RufreeRole } from "../../../store/models/Parttime";
import StatusTag from "../../molecules/ParttimeRow/StatusTag";

const Wrapper = styled.div``;

interface TaskRowProps {
  task: Task;
  onClick: () => void;
}

interface InjectedProps extends TaskRowProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class TaskSummaryRow extends Component<TaskRowProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  render() {
    const { task, onClick } = this.props;
    const is_settlement = (task.status === 'SETTLEMENT') ? true : false

    return (
        <Wrapper>
            {is_settlement ? (
                <Item onClick={onClick}>
                  <StatusTag status={TaskStatus.get(task.status)}></StatusTag>  
                  <h4 style={{ display: "inline", marginLeft: "10px" }}>{ task.code } | { task.title }</h4>    
                </Item>
            ) : (
                 <ItemCard onClick={onClick}>
                    <StatusTag status={TaskStatus.get(task.status)}></StatusTag>  
                    <h4 style={{ display: "inline", marginLeft: "10px" }}>{ task.code }</h4>
                    <div>
                        <h4 style={{ margin: "7px 0px"}}>{ task.title }</h4>
                        <p style={{ marginBottom: "0px"}}>{ RufreeRole.get(task.role) } | { task.rufree ? task.rufree.name : '-' }</p>
                    </div>
                </ItemCard>
            )}
        </Wrapper>
    );
  }
}

export default TaskSummaryRow;

const Item = styled.p`
 cursor: pointer;
`;