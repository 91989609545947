import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { ProjectGroupRiskForm } from "../../../store/forms/ProjectGroupForm/ProjectGroupRiskForm";
import { Checkbox, InputGroup, TextArea } from "@blueprintjs/core";
import { ChangeEvent } from "cleave.js/react/props";

const Wrapper = styled.div`
  margin-bottom: 4px;
`;

const CheckItem = styled.div`
  display: flex;
  margin-bottom: 4px;

  & > * + * {
    margin-left: 5px;
  }
`;

const TextAreaContainer = styled.div`
  margin-bottom: 20px;
`;

interface ProjectGroupRiskFormViewProps {
  risk: ProjectGroupRiskForm;
}

interface InjectedProps extends ProjectGroupRiskFormViewProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ProjectGroupRiskFormView extends Component<
  ProjectGroupRiskFormViewProps
> {
  get injected() {
    return this.props as InjectedProps;
  }

  handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.props.risk.setTitle(e.target.value);
  };

  handleContentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.props.risk.setContent(e.target.value);
  };

  toggleCheck = () => {
    this.props.risk.setChecked(!this.props.risk.checked);
  };

  render() {
    const { risk } = this.props;

    return (
      <Wrapper>
        {risk.etc ? (
          <CheckItem>
            <Checkbox checked={risk.checked} onChange={this.toggleCheck} />
            <InputGroup
              value={risk.title}
              onChange={this.handleTitleChange}
              style={{ width: "369px" }}
            />
          </CheckItem>
        ) : (
          <Checkbox checked={risk.checked} onChange={this.toggleCheck}>
            {risk.title}
          </Checkbox>
        )}

        {risk.checked && (
          <TextAreaContainer>
            <TextArea
              style={{ width: "100%" }}
              placeholder="내용 기록"
              value={risk.content}
              onChange={this.handleContentChange}
            />
          </TextAreaContainer>
        )}
      </Wrapper>
    );
  }
}

export default ProjectGroupRiskFormView;
