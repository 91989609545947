import React, { Component, useState } from 'react';
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import { Divider, Tab, Tabs, TabId } from "@blueprintjs/core";
import { DashedDividerLine } from "../../atoms/DetailReportComponents/DetailReportComponents";
import LargeContainer from "../../atoms/LargeContainer/LargeContainer";
import { DashboardStatusDate, DashboardStatusTime } from "../../molecules/Dashboard/DashboardStatusTime";
import { AppStore } from "../../../store/AppStore";

import SchedulePanel from "../../panels/DashboardPanel/SchedulePanel";
import ProjectPanel from "../../panels/DashboardPanel/ProjectPanel";
import TaskPanel from "../../panels/DashboardPanel/TaskPanel";
import EtcPanel from "../../panels/DashboardPanel/EtcPanel";
import VacationPanel from "../../panels/DashboardPanel/VacationPanel";
import SeesoBotPanel from "../../panels/DashboardPanel/SeesoBotPanel";

const SectionWrapper = styled.div`
  max-width: 750px;
  padding: 10px;
`;
const TopSection = styled.div`
  min-height: 50px;
  padding: 10px;
  align-items: baseline;
  display: flex;
`;
const TabSection = styled.div`
  margin-top: 10px;
  min-height 300px;
`;

interface DashboardPageProps {}

interface InjectedProps extends DashboardPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class DashboardPage extends Component<DashboardPageProps> {
  @observable currentTab: TabId = "schedule";
  @observable ongoingProjectCount: number = 0;
  @observable todayScheduleCount: number = 0;
  @observable todayTaskCount: number = 0;

  get injected() {
    return this.props as InjectedProps;
  }

  componentDidMount() {
  }

  handleChangeTab = (navbarTabId: TabId) => {
    this.currentTab = navbarTabId;
  };

  setOngoingProjectCount = (count: number) => {
    this.ongoingProjectCount = count;
  }

  setTodayScheduleCount = (count: number) => {
    this.todayScheduleCount = count;
  }

  setTodayTaskCount = (count: number) => {
    this.todayTaskCount = count;
  }

  render() {
    const { userStore, pmUserStore } = this.injected.appStore
    const pm = pmUserStore.pmByEmail(userStore.email);
    
    return (
      <LargeContainer>

        <TopSection>
          <DashboardStatusDate />

          <DashboardStatusTime />
        </TopSection>

        <Divider />

        <TabSection>
          <Tabs
            id="TabsDashboard"
            onChange={this.handleChangeTab}
            selectedTabId={this.currentTab}
            renderActiveTabPanelOnly={true}
          >
            <Tab
              id="schedule"
              title={`오늘 할일 ${this.todayTaskCount} / 오늘 일정 ${this.todayScheduleCount}`}
              panel={<SchedulePanel
                setTodayScheduleCount={this.setTodayScheduleCount} 
                setTodayTaskCount={this.setTodayTaskCount} />
              } />
            {pm && !pm.is_intern && (
            <Tab
              id="project"
              title={`진행 중 프로젝트 ${this.ongoingProjectCount > 0 ? this.ongoingProjectCount : ''}`}
              panel={
                <ProjectPanel
                  setOngoingProjectCount={this.setOngoingProjectCount} />
              } />
            )}
            {pm && !pm.is_intern && (
            <Tab
              id="task"
              title={`태스크`}
              panel={<TaskPanel />} />
            )}
            {pm && !pm.is_intern && (
            <Tab
              id="etc"
              title={`기타현황`}
              panel={<EtcPanel />} />
            )}
            {pm && !pm.is_intern && (
            <Tab 
              id="vacation"
              title={`연차 및 휴가 관리`}
              panel={<VacationPanel />} />
            )}
            {pm && (
            <Tab 
              id="seesobot"
              title={`슬랙 봇 설정`}
              panel={<SeesoBotPanel />} />
            )}            
          </Tabs>
        </TabSection>

      </LargeContainer>
    )
  }
}

export default DashboardPage;
