import React, { Component } from "react";
import { GreyLabel } from "../../atoms/DetailReportComponents/DetailReportComponents";
import { Button, Card, EditableText, Elevation, HTMLTable, Intent, TextArea } from "@blueprintjs/core";
import { AppStore } from "../../../store/AppStore";
import { inject, observer } from "mobx-react";
import { computed, observable } from "mobx";
import styled from "styled-components/macro";
import TaskCommentItemForm from "./TaskCommentItemForm";

const NewCommentSection = styled.div``;


interface Props {
  viewMode: boolean | undefined;
}

interface InjectedProps extends Props {
  appStore: AppStore;
}

@inject("appStore")
@observer
class TaskCommentListView extends Component<Props> {
  @observable comment = "";

  get injected() {
    return this.props as InjectedProps;
  }

  get currentUser() {
    return this.injected.appStore.userStore;
  }

  @computed
  get formStore() {
    return this.injected.appStore.taskManagerTaskFormStore;
  }

  @computed
  get currentForm() {
    return this.formStore.currentForm;
  }

  handleCommentSubmit = async () => {
    this.formStore.updateComment({ comment: this.comment }, "post");
    this.comment = "";
  };

  render() {
    const { viewMode } = this.props;
    return (
      <>
        <HTMLTable
          bordered={false}
          interactive={true}
          style={{ width: "100%", marginTop: "20px" }}
        >
          <thead>
          <tr>
            <td>
              <GreyLabel
                style={{
                  display: "inline-block",
                  paddingRight: "10px"
                }}>{this.currentUser.username}</GreyLabel>
            </td>
            <td style={{ paddingRight: "0px" }}>

              <NewCommentSection>
                <Card interactive={true} elevation={Elevation.TWO}>
                  <div>
                    <TextArea
                      style={{ width: "100%", height: "auto" }}
                      growVertically={true}
                      large={true}
                      intent={Intent.PRIMARY}
                      onChange={(event) => {
                        this.comment = event.target.value;
                      }}
                      value={this.comment}
                      onKeyPress={async e => {
                        if ((e.key === "Enter" && e.shiftKey)) {
                          await this.handleCommentSubmit();
                          setTimeout(
                            () => this.comment = "",
                            100
                          );
                        }
                      }}
                    />
                  </div>
                  <div>
                    <Button style={{ marginTop: "15px" }} onClick={
                      () => {
                        this.handleCommentSubmit();
                      }
                    }>저장</Button>
                  </div>
                </Card>
              </NewCommentSection>
            </td>

          </tr>
          </thead>
          <tbody>
          {this.currentForm.comments.map((comment) =>
            <TaskCommentItemForm
            key={comment.id}
              viewMode={viewMode} comment={comment}
                 onEditConfirm={(e: any) => {
                   this.formStore.updateComment({ id: comment.id,comment: comment.comment }, "patch");
                   e.stopPropagation();
                 }}
                 onDeleteConfirm={(e: any) => {
                   this.formStore.updateComment({ id: comment.id }, "delete");
                   e.stopPropagation();
                 }}

            />
          )}
          </tbody>
        </HTMLTable>
      </>
    );
  }
}

export default TaskCommentListView;