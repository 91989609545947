import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { Item, Column } from "../../atoms/CardTable/CardTable";

import { Task, TaskStatus, RufreeRole } from "../../../store/models/Parttime";

interface TaskRowProps {
  task: Task;
  onClick: () => void;
}

interface InjectedProps extends TaskRowProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class TaskRow extends Component<TaskRowProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  render() {
    const { task, onClick } = this.props;

    return (
      <Item onClick={onClick}>
        <Column style={{ maxWidth: "104px" }}>{ task.code }</Column>
        <Column style={{ maxWidth: "250px" }}>{ task.title }</Column>
        <Column style={{ maxWidth: "270px" }}>{ RufreeRole.get(task.role) }</Column>
        <Column style={{ maxWidth: "200px" }}>{ task.request.client.affiliation }</Column>
        <Column style={{ maxWidth: "200px" }}>{ task.rufree ? task.rufree.name : '-' }</Column>
        <Column style={{ maxWidth: "200px" }}>{ TaskStatus.get(task.status) }</Column>
        <Column style={{ maxWidth: "283px" }}>{ task.estimatedWorkingTime ? task.estimatedWorkingTime : '-' }</Column>
      </Item>
    );
  }
}

export default TaskRow;
