import React, { Component, FormEvent, MouseEvent, KeyboardEvent } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { RouteComponentProps } from "react-router";
import {
  H3,
  H4,
  H5,
  Button,
  Intent,
  HTMLTable,
  Spinner,
  Switch,
  InputGroup,
  Dialog,
  Classes,
  FormGroup,
  RadioGroup,
  Radio,
  Text,
  Card,
  Elevation,
  Icon,
  Colors,
  HTMLSelect
} from "@blueprintjs/core";
import { AppToaster } from "../../atoms/Toaster/Toaster";
import { ChangeEvent } from "cleave.js/react/props";
import {
  EtcSectionBody, EtcSectionTitle
} from "../../atoms/DashboardComponents/DashboardComponents";

import { observable } from "mobx";
import { RufreeMatching } from "../../../store/models/RufreeMatching";
import { RufreeMatchingCandidate } from "../../../store/models/RufreeMatchingCandidate";
import { RufreeMatchingHistory, PHASE, STATUS } from "../../../store/models/RufreeMatchingHistory";
import moment from "moment";
import MatchingPreview from "../../organisms/MatchingPreview/MatchingPreview";
import RufreeMatchingHistoryComment from "../../organisms/RufreeMatchingHistoryComment/RufreeMatchingHistoryComment";
import CandidateInfoPopup from "../../organisms/CandidateInfoPopup/CandidateInfoPopup";

import Paginator from "../../molecules/Paginator/Paginator";
import { BreadCrumb } from "../../molecules/BreadCrumb/BreadCrumb";
import { PageNavWrapper } from "../../atoms/PageNav/PageNav";

import LargeContainer from "../../atoms/LargeContainer/LargeContainer";
import {FormMode} from "../../../types/formMode";
import {ETEMPLATETYPE} from "../../../store/models/NotificationTemplate";
import SendDialog from "../../organisms/NotificationTemplate/SendDialog";


interface PageParams {
  rufreeMatchingId: string;
}

interface RufreeMatchingDetailPageProps
  extends RouteComponentProps<PageParams> {
}

interface InjectedProps extends RufreeMatchingDetailPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class RufreeMatchingDetailPage extends Component<RufreeMatchingDetailPageProps> {
  @observable matching: RufreeMatching | null = null;
  @observable readyToShow = false;
  @observable onSubmit: boolean = false;
  @observable readyForSearchResult = true;
  @observable isPreviewOpen = false;
  @observable job = "";
  @observable skill = "";
  @observable info = "";
  @observable searchText = "";
  @observable workType = "전체";
  @observable selectedCandidate: RufreeMatchingCandidate | null = null;
  @observable isRecommendedSearch: boolean = false;
  @observable orderBy: string = 'pg_count';

  get injected() {
    return this.props as InjectedProps;
  }

  get notificationTemplateStore() {
    return this.injected.appStore.notificationTemplateStore;
  }

  async componentDidMount() {
    try {
      await this.fetchMatching();

      if (this.matching && this.matching.status == "작성중") {
        this.props.history.push(
          `/project-groups/${this.matching.projectGroup}/rufree-matchings/${this.matching.id}/edit`
        );
      }
    } catch (e) {
    } finally {
      this.readyToShow = true;
    }
  }

  handleSearchTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.searchText = e.target.value;
  };

  handleInfoChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.info = e.target.value;
  };

  handleWorkTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    this.workType = e.currentTarget.value;
  };

  handleSearchJobChange = (e: ChangeEvent<HTMLSelectElement>) => {
    this.job = e.currentTarget.value;
  };

  handleSearchSkillChange = (e: ChangeEvent<HTMLSelectElement>) => {
    this.skill = e.currentTarget.value;
  };

  fetchMatching = async () => {
    const { rufreeMatchingId } = this.props.match.params;

    this.matching = await this.injected.appStore.rufreeMatchingStore.fetchRufreeMatching(
      +rufreeMatchingId
    );
  };

  refreshMatching = async () => {
    setTimeout(async () => {
      await this.fetchMatching();
    }, 200);
  };

  toggleOnRecommend = () => {
    if (!this.matching) {
      return;
    }

    this.matching.toggleOnRecommend();
  };

  cancelMatchedRufree = async () => {
    if (!this.matching) {
      return;
    }

    await this.matching.cancelMatchedRufree();
    this.refreshMatching();
  };

  togglePreview = () => {
    this.isPreviewOpen = !this.isPreviewOpen;
  };

  handleMatchingEditClick = () => {
    if (!this.matching) {
      return;
    }

    this.props.history.push(
      `/project-groups/${this.matching.projectGroup}/rufree-matchings/${this.matching.id}/edit`
    );
  };

  handleCandidateClick = (candidate: RufreeMatchingCandidate) => {
    this.selectedCandidate = candidate;
  };

  handlePopupClose = () => {
    this.selectedCandidate = null;
  };

  handleMatchingCancelClick = async () => {
    if (!this.matching) {
      return;
    }
    let cancelable = !this.matching.matchingHistory.some((history, index) => {
      return history.dateSend != null;
    });

    if ( !this.matching.rufreeOfferSend || this.matching.matchingHistory.length < 1 || cancelable ) {
      const cancelConfirm = window.confirm("취소 처리 시 삭제되지 않고, 목록의 취소 탭으로 이동합니다. 취소하시겠습니까?");
      if (cancelConfirm) {
        const { rufreeMatchingId } = this.props.match.params;

        await this.injected.appStore.rufreeMatchingStore.cancelRufreeMatching(
          +rufreeMatchingId
        );
        this.props.history.push(
          `/rufree-matchings`
        );
      }
    } else {
      AppToaster.show({
        message: "알유프리에게 이미 제안 중일 때는 취소할 수 없습니다",
        intent: Intent.DANGER
      });
    }
  };

  handleCancelMatchingRequest = async () => {
    if (!this.matching) {
      return;
    }

    let cancelable: boolean = false;
    if ( !this.matching.rufreeOfferSend ) {
      cancelable = true;
    }

    if (cancelable) {
      try {
        await this.injected.appStore.rufreeMatchingFormStore.cancelMatchingRequest(this.matching);
        this.props.history.push(`/rufree-matchings`);

        AppToaster.show({
          message: "성공적으로 매칭요청이 취소 되었습니다.",
          intent: Intent.SUCCESS
        });
      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }
    } else {
      AppToaster.show({
        message: "알유프리에게 이미 제안 중일 때는 매칭 요청을 취소할 수 없습니다",
        intent: Intent.DANGER
      });
    }

  };

  handleMatchingRestoreClick = async () => {
    const restoreConfirm = window.confirm("매칭을 복원하시겠습니까?");
    if (restoreConfirm) {
      const { rufreeMatchingId } = this.props.match.params;

      await this.injected.appStore.rufreeMatchingStore.restoreRufreeMatching(
        +rufreeMatchingId
      );
      this.props.history.push(
        `/rufree-matchings`
      );
    }
  };

  handlePasswordKeyDown = async (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      await this.searchRufree();
    }
  };

  handleSearch = async () => {
    if(this.isRecommendedSearch){
      await this.searchRecommendedRufree();
    } else {
      await this.searchRufree();
    }
  };

  searchRufree = async () => {
    this.readyForSearchResult = false;

    await this.injected.appStore.RufreeMatchingCandidateStore.fetchRufree(
      this.info, this.job, this.skill, this.workType, this.searchText, this.orderBy);

    this.readyForSearchResult = true;
  };

  searchRecommendedRufree = async () => {
    this.readyForSearchResult = false;

    let queryInput: string[] = [];
    this.info.length > 0 ? queryInput.push(this.info) : queryInput = queryInput;
    this.skill.length > 0 ? queryInput.push(this.skill) : queryInput = queryInput;
    const query = queryInput.map(x => x).join(", ");

    // await this.injected.appStore.RufreeMatchingCandidateStore.fetchRecommendedRufree(
    //   query,
    //   this.workType,
    //   this.orderBy
    // );

    this.readyForSearchResult = true;
  };

  handleAdd = async (rufree: any) => {
    if (!this.matching) {
      return;
    }

    if (!rufree.getOffer) {
      return AppToaster.show({
        message: "해당 유저는 알유프리 제안을 당분간 받지 않기로 하여 추가할 수 없습니다",
        intent: Intent.DANGER
      });
    }

    const { currentMatchings } = this.injected.appStore.rufreeMatchingStore;

    try {
      await this.matching.addRufree({ rufree: rufree.rufreeId });
      await this.refreshMatching();
      AppToaster.show({
        message: "추가되었습니다.",
        intent: Intent.SUCCESS
      });
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  handleHistorySendRequest = async (history: RufreeMatchingHistory) => {
    try {
      this.onSubmit = true;
      await history.sendRequest();
      AppToaster.show({
        message: "발송되었습니다.",
        intent: Intent.SUCCESS
      });
      this.refreshMatching();
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    } finally {
      this.onSubmit = false;
    }
  };

  handleHistoryDelete = async (history: RufreeMatchingHistory) => {
    try {
      await history.deleteHistory();
      this.refreshMatching();
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  handleHistoryElectRequest = async (history: RufreeMatchingHistory) => {
    try {
      await history.electRequest();
      this.refreshMatching();
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  handleHistoryCancelRequest = async (history: RufreeMatchingHistory) => {
    try {
      await history.cancelRequest();
      this.refreshMatching();
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  handleCopyURL = (rufreeId: string, rufreeName: string) => {
    let url = window.location.host;
    const link = `${url}/rufrees?rufree_id=${rufreeId}`;

    let tempElem = document.createElement("textarea");
    tempElem.value = link;
    document.body.appendChild(tempElem);

    tempElem.select();
    document.execCommand("copy");
    document.body.removeChild(tempElem);

    AppToaster.show({
      message: `알유프리 [${rufreeId}] ${rufreeName}님의 바로 가기 링크가 복사되었습니다`,
      intent: Intent.SUCCESS
    });
  }

  renderStatus = (history: RufreeMatchingHistory, isMatched: boolean, email: string, phone: string) => {
    const projectName = this.matching ? this.matching.projectGroupName : '';
    if ([PHASE.accepted].includes(history.phase)) {
      // status: 확정대기중
      return (
        <div>
          <SecondaryButton
              text="확정하기"
              onClick={() => this.handleHistoryElectRequest(history)}
          />
        </div>
      );
    }
    else if ([PHASE.pending].includes(history.phase)) {
      // status: 제안전
      return (
        <div>
          <SecondaryButton
            text="제안하기"
            onClick={() => this.handleHistorySendRequest(history)}
            disabled={this.onSubmit}
          />
          <PrimaryButton
              text="템플릿 발송하기"
              intent={Intent.PRIMARY}
              onClick={async () => {
                this.notificationTemplateStore.setEmails([email]);
                this.notificationTemplateStore.setPhones([phone]);
                this.notificationTemplateStore.setName(history.rufreeName);
                this.notificationTemplateStore.setProjectName(projectName);
                this.notificationTemplateStore.setRufreeName(history.rufreeName);
                this.notificationTemplateStore.setFormMode(FormMode.Read);
                this.notificationTemplateStore.setOpenPopup(true);
              }}
          />
          <DangerButton
            text="삭제"
            onClick={() => this.handleHistoryDelete(history)}
          />
        </div>
      );
    }
    else if ([PHASE.submitted].includes(history.phase)) {
      // 응답대기중
      const diff = moment().diff(history.dateSend || new Date(), "h");

      return (
        <div>
          <p>대기중({diff}h)</p>
          <SecondaryButton
            text="재발송"
            onClick={() => this.handleHistorySendRequest(history)}
          />
        </div>
      );
    }
    else if ([PHASE.expired, PHASE.rejected].includes(history.phase)) {
      // status: 제안거절, 제안만료
      return (
        <div>
          <SecondaryButton
            text="다시 제안하기"
            onClick={() => this.handleHistorySendRequest(history)}
            disabled={this.onSubmit}
          />
          <PrimaryButton
              text="템플릿 발송하기"
              intent={Intent.PRIMARY}
              onClick={() => {
                this.notificationTemplateStore.setEmails([email]);
                this.notificationTemplateStore.setPhones([phone]);
                this.notificationTemplateStore.setName(history.rufreeName);
                this.notificationTemplateStore.setProjectName(projectName);
                this.notificationTemplateStore.setRufreeName(history.rufreeName);
                this.notificationTemplateStore.setFormMode(FormMode.Read);
                this.notificationTemplateStore.setOpenPopup(true);
              }}
          />
          <DangerButton
            text="삭제"
            onClick={() => this.handleHistoryDelete(history)}
          />
        </div>
      );
    }
  };

  renderForcingConfirm = (history: RufreeMatchingHistory, isMatched: boolean) => {
    const { email } = this.injected.appStore.userStore;

    // if (!history.isAccepted && !isMatched && email == "partner@seeso.kr") {
    if ([PHASE.accepted, PHASE.matched].includes(history.phase) === false && email === "partner@seeso.kr") {
      return (<WarningButton
        text="강제확정하기"
        intent={Intent.WARNING}
        onClick={() => this.handleHistoryElectRequest(history)}
        style={{ "marginTop": "5px" }}
      />);
    } else {
      return ("");
    }
  };

  renderForcingCancel = (history: RufreeMatchingHistory) => {
    const { email } = this.injected.appStore.userStore;
    if (email === "partner@seeso.kr") {
      return (<Button
        text="강제취소하기"
        intent={Intent.DANGER}
        onClick={() => this.handleHistoryCancelRequest(history)}
        style={{ "marginTop": "5px" }}
      />);
    } else {
      return ("");
    }
  };

  renderStopMatching = (matching: any) => {

    let stopBtn: any;

    if ( !matching.rufreeOfferSend ) {
      stopBtn = (
        <>
          <Button text="매칭 진행 정지" intent={Intent.DANGER} onClick={this.handleCancelMatchingRequest}/>
        </>
      )
    } else {
      stopBtn = (<Button className="disabled" text="매칭 진행 정지" intent={Intent.NONE} style={{ cursor: "not-allowed", background: "#e6e6e6" }} />)
    }
    return stopBtn;
  };

  renderPgHistories = (pgHistories: any[]) => {
    let currentPgHistories: any[] = [];

    pgHistories.map((history, idx) => { // 진행하고 있는 프로젝트 그룹이 종료되었는지 확인
      let endDay = moment(history.dateComplete, "YYYY-MM-DD");
      let today = moment(new Date());
      if (today < endDay) {
        currentPgHistories.push(history);
      }
    });

    let others = currentPgHistories.length < 1 ? pgHistories.length : pgHistories.length - currentPgHistories.length;

    if (currentPgHistories.length > 0) {
      return (
        <>
          {currentPgHistories.map((history, idx) => {
            return (
                <div key={idx}>
                  <Link to={`/project-groups/${history.pgId}`} target="_blank">
                    <span>{history.pgName}(진행중)</span><br/>
                  </Link>
                </div>
            );
          })}
          {others > 0 ? "(외 " + others + "건)" : ""} {/* 더 있는 경우 보여줌 */}
        </>
      );
    } else { // 최근 첫 번째 프로젝트
      let others = pgHistories.length - 1;
      return (
        <>
          <Link to={`/project-groups/${pgHistories[0].pgId}`} target="_blank">
            <span>{pgHistories[0].pgName}</span><br/>
            {others > 0 ? "(외 " + others + "건)" : ""} {/* 더 있는 경우 보여줌 */}
          </Link>
        </>
      );
    }
  };

  render() {
    const SKILLS = ['웹 기획', '앱 기획', '웹 디자인', '앱 디자인', '기타 디자인 (BI 등)', '백엔드 개발', '프론트 개발', '퍼블리싱',
      'iOS 개발', '안드로이드 개발', 'React Native', 'QA 테스팅', '프로덕트 매니징', '프로젝트 매니징'
    ]
    const JOBS = [
      '기획자', '디자이너', '백엔드 개발자', '프론트 개발자', '풀스택 개발자', '퍼블리셔'
    ]

    const { matching } = this;
    const { sortedMatchingCandidates, pageGroup, totalPages, currentPage, setPageGroup, setCurrentPage } = this.injected.appStore.RufreeMatchingCandidateStore;

    if (!this.readyToShow) {
      return <Spinner/>;
    }

    if (!matching) {
      return <div>알유프리 매칭을 찾을 수 없습니다. (ID 잘못됨)</div>;
    }

    return (
      <LargeContainer>
      <Wrapper>
        <PageNavWrapper>
          <BreadCrumb
            items={[
              { name: `${matching.currentTab} 목록`, link: `/rufree-matchings?tab=${matching.currentTab}`, },
              "알유프리 매칭 내용"
            ]}
          />
        </PageNavWrapper>
        <H3>알유프리 매칭</H3>
        <ButtonsRow style={{ float: "right" }}>
          <Button text="미리보기" onClick={this.togglePreview}/>
          <Button text="수정하기" onClick={this.handleMatchingEditClick}/>
          {matching.matchedRufree ? (<Button text="확정취소하기" onClick={this.cancelMatchedRufree}/>) : (<span/>)}
          {this.renderStopMatching(matching)}
          {matching.status == "취소" ? (
            <Button text="복원처리" intent={Intent.SUCCESS} onClick={this.handleMatchingRestoreClick}/>
          ) : (<Button text="DROP" intent={Intent.WARNING} onClick={this.handleMatchingCancelClick}/>) }
        </ButtonsRow>

        <MatchingInfo>
          <HTMLTable
            bordered={true}
            style={{ width: "100%", tableLayout: "fixed", border: "1px solid #dcdcdd"}}
          >
            <thead style={{ backgroundColor: "#e8e8e8" }}>
            <tr>
              <th style={{ textAlign: "center" }}>요청자</th>
              <th style={{ textAlign: "center" }}>그룹번호</th>
              <th style={{ textAlign: "center" }}>프로젝트명</th>
              <th style={{ textAlign: "center" }}>역할</th>
              <th style={{ textAlign: "center" }}>희망일</th>
              <th style={{ textAlign: "center" }}>상태</th>
              <th style={{ textAlign: "center" }}>확정알유프리</th>
            </tr>
            </thead>
            <tbody>
            {matching && (
              <tr>
                <td style={{ textAlign: "center" }}>{matching.manager}</td>
                <td style={{ textAlign: "center" }}>
                  <Link to={`/project-groups/${ matching.projectGroup }`} target="_blank">
                    {matching.projectGroup}
                  </Link>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Link to={`/project-groups/${ matching.projectGroup }`} target="_blank">
                    {matching.projectGroupName}
                  </Link>
                </td>
                <td style={{ textAlign: "center" }}>{matching.role}</td>
                <td style={{ textAlign: "center" }}>{matching.dateDue}</td>
                <td style={{ textAlign: "center" }}>{ matching.status == '취소' ? (
                  <span style={{ color: "red" }}>DROP</span>
                ) : (
                  matching.matchedRufree ? '매칭성공' : matching.currentTab
                )}</td>
                <td style={{ textAlign: "center" }}>{matching.status == '매칭완료' && `[${matching.matchedRufree}] ${matching.matchedRufreeName}`}</td>
              </tr>
            )}
            </tbody>
          </HTMLTable>
        </MatchingInfo>

        <Divider></Divider>

        <Card elevation={Elevation.TWO} style={{ margin: "20px 0 40px 0"}}>
          <OnRecommend>
            <>추천받는중{" "}</>
            <Switch large={true} inline={true} checked={matching.onRecommend} onChange={this.toggleOnRecommend}/>
          </OnRecommend>
          <H3 style={{ margin: "10px 0 30px 5px"}}>알유프리매칭 현황</H3>
          <Counts>
            <CountItem>총 {matching.historyCount}명</CountItem>
            <CountItem>수락 {matching.statusCount("수락")}명</CountItem>
            <CountItem>대기 {matching.statusCount("대기중")}명</CountItem>
            <CountItem>거절 {matching.statusCount("거절")}명</CountItem>
            <CountItem>미응답 {matching.statusCount("미응답")}명</CountItem>
          </Counts>
        </Card>

        <Card elevation={Elevation.TWO} style={{ margin: "20px 0 20px 0"}}>

          <H3 style={{ margin: "10px 0 30px 5px"}}>알유프리 검색</H3>
          <ToolsRow>
            <FormGroup style={{ maxWidth: "360px", marginRight: "40px"}}>
              <FormGroupLabel>
                <H5 style={{ display: "inline-block" }}>검색어</H5>
                <HelpText> *검색시에 ','를 사용해서 함께 검색할 수 있습니다. ex - django, react</HelpText>
              </FormGroupLabel>
              <SearchInputGroup
                placeholder="이름/번호/이메일"
                onChange={this.handleInfoChange}
                onKeyDown={this.handlePasswordKeyDown}
              />
              <SearchInputGroup
                placeholder="경력/언어/툴 등"
                onChange={this.handleSearchTextChange}
                onKeyDown={this.handlePasswordKeyDown}
                className={ this.isRecommendedSearch ? "recommended-search" : "" }
              />
            </FormGroup>
            <FormGroup style={{ marginRight: "40px" }}>
              <H5>역할</H5>
              <HTMLSelect value={ this.job } onChange={ this.handleSearchJobChange } style={{ width: "145px"}}>
                <option value="">전체</option>
                { JOBS.map((value, index) => {
                  return <option key={ index } value={ value }>{ value }</option>
                }) }
              </HTMLSelect>
            </FormGroup>
            <FormGroup style={{ marginRight: "40px"}}>
              <H5>분야</H5>
              <HTMLSelect value={ this.skill } onChange={ this.handleSearchSkillChange } style={{ width: "145px"}}>
                <option value="">전체</option>
                { SKILLS.map((skill, index) => {
                  return <option key={ index } value={ skill }>{ skill }</option>
                }) }
              </HTMLSelect>
            </FormGroup>
            <FormGroup>
              <H5>근무시간</H5>
              <HTMLSelect style={{ width: "145px"}} value={ this.workType } onChange={ this.handleWorkTypeChange }>
                <option value="전체">전체</option>
                <option value="파트타임">파트타임</option>
                <option value="풀타임">풀타임</option>
              </HTMLSelect>
              {/*<RadioGroup*/}
              {/*  onChange={this.handleWorkTypeChange}*/}
              {/*  selectedValue={this.workType}*/}
              {/*  inline={true}*/}
              {/*>*/}
              {/*  <Radio label="전체" value="전체" inline={true} style={{ verticalAlign: "sub", marginLeft: "20px" }}/>*/}
              {/*  <Radio label="파트타임" value="파트타임" inline={true} style={{ verticalAlign: "sub" }} />*/}
              {/*  <Radio label="풀타임" value="풀타임" inline={true} style={{ verticalAlign: "sub" }} />*/}
              {/*</RadioGroup>*/}
            </FormGroup>
          </ToolsRow>
          <ButtonGroup>
            <Button
              icon="search"
              text="검색"
              alignText="center"
              intent={Intent.PRIMARY}
              onClick={() => {
                this.isRecommendedSearch=false;
                setCurrentPage(1);
                this.searchRufree();
              }}
              style={{ width: "150px", borderRadius: "15px", backgroundColor: "#353535", backgroundImage: "none", color: "white", marginRight: "10px"}}
            />
            <Button
              text="자동추천"
              alignText="center"
              // disabled={true}
              onClick={() => {AppToaster.show({ message: "Coming soon..!", timeout: 1000, intent: Intent.PRIMARY })}}
              // style={{ width: "150px", borderRadius: "15px", backgroundColor: "purple", backgroundImage: "none", color: "white"}}
              style={{ width: "150px", borderRadius: "15px", backgroundColor: "#cecece36", backgroundImage: "none", color: "#8080804f", boxShadow: "none"}}
            />
          </ButtonGroup>
          <Divider></Divider>
          <CandidatesSection>
            <EtcSectionTitle>
              <Icon icon="caret-right" iconSize={20} />
              <H4 style={{ margin: "0 0 10px 10px"}}>{ this.isRecommendedSearch ? `자동추천결과` : `검색결과` }</H4>
            </EtcSectionTitle>
            <OrderByPG>
              <Switch
                  large={true}
                  checked={ this.orderBy === "pg_count" ? true : false}
                  label="참여한 시소프로젝트가 많은 순으로 보기"
                  onChange={() => {
                    this.orderBy = this.orderBy === "id" ? "pg_count" : "id";
                    this.handleSearch();
                  }}
              />
            </OrderByPG>
            <EtcSectionBody className={ this.isRecommendedSearch ? "recommended-search" : "" }>
              { this.readyForSearchResult ?
                (<>
                <HTMLTable
                bordered={true}
                interactive={true}
                style={{ width: "100%", tableLayout: "fixed", marginBottom: "40px"}}
              >
                <thead>
                <tr>
                  <th>알유번호</th>
                  <th>이름</th>
                  <th>현재 매칭 상태</th>
                  <th>시소프로젝트</th>
                  <th>메인스킬</th>
                  <th>보조스킬</th>
                  <th>추가</th>
                </tr>
                </thead>
                <tbody>
                  { sortedMatchingCandidates.length > 0 ? (
                        sortedMatchingCandidates.map(candidate => (
                          <tr
                            key={candidate.id}
                          >
                            <td onClick={() => this.handleCandidateClick(candidate)}>
                              {candidate.rufreeId}
                              <Button
                                  icon={<Icon icon={'link'} iconSize={12} color={Colors.GREEN3} />}
                                  minimal={true}
                                  small={true}
                                  onClick={ (e: any) => {
                                    e.stopPropagation();
                                    this.handleCopyURL(candidate.rufreeId, candidate.name);
                                  }}
                              />
                            </td>
                            <td onClick={() => this.handleCandidateClick(candidate)}>
                              {candidate.name}
                              { !candidate.getOffer &&
                                <span style={{color: Colors.GRAY1, fontSize: '12px'}}> { `(OFF${ candidate.getOfferChangedAt ? `-${ moment(candidate.getOfferChangedAt).format("YYYY.MM.DD") }` : '' })` }</span>
                              }
                            </td>
                            <td onClick={() => this.handleCandidateClick(candidate)}>
                              {candidate.currentMatchings.length > 0 ?
                                candidate.currentMatchings.map(matching => (
                                  <><span>{matching.pgName}</span>
                                    {matching.isAccepted ? "(수락)" : "(제안중)"}
                                    <br/></>
                                )) : "-"}
                            </td>
                            <td>
                              {candidate.pgHistories.length > 0 ? this.renderPgHistories(candidate.pgHistories) : ""}
                            </td>

                            <td onClick={() => this.handleCandidateClick(candidate)}>{candidate.mainSkills.map(x => x).join(", ")}</td>
                            <td onClick={() => this.handleCandidateClick(candidate)}>{candidate.subSkills.map(x => x).join(", ")}</td>
                            <td>
                              <Button
                                intent={Intent.PRIMARY}
                                text="추가"
                                onClick={(e: MouseEvent<HTMLElement>) => {
                                  this.handleAdd(candidate);
                                }}
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            <NoItemText>후보군이 존재하지 않습니다.</NoItemText>
                          </td>
                        </tr>
                      )
                  }
                </tbody>
              </HTMLTable>
              { sortedMatchingCandidates.length > 0 && (
                <Paginator
                  pageGroup={pageGroup}
                  totalPages={totalPages}
                  currentPage={currentPage}
                  setPageGroup={setPageGroup}
                  setCurentPage={setCurrentPage}
                  onFetch={this.handleSearch}
                />
              )}
                </>) :
              <Spinner/>
              }
            </EtcSectionBody>
          </CandidatesSection>
        </Card>

        <Divider></Divider>

        <Card elevation={Elevation.TWO} style={{ margin: "20px 0 20px 0"}}>
          <H3 style={{ margin: "10px 0 20px 5px"}}>알유프리매칭 히스토리</H3>
          <HTMLTable
            bordered={true}
            interactive={true}
            style={{ width: "100%", tableLayout: "fixed", border: "1px solid #dcdcdd"}}
          >
            <thead style={{ backgroundColor: "#e8e8e8" }}>
            <tr>
              <th style={{ textAlign: "center" }}>번호</th>
              <th style={{ textAlign: "center" }}>이름</th>
              <th style={{ textAlign: "center" }}>제안일</th>
              <th style={{ textAlign: "center" }}>상태</th>
              <th style={{ textAlign: "center" }}>메세지</th>
            </tr>
            </thead>
            <tbody>
            {matching.matchingHistory.map(history => (
              <tr key={history.id} className={([PHASE.accepted, PHASE.matched].includes(history.phase) ? 'accepted' : '') + ' ' + ([PHASE.expired, PHASE.rejected].includes(history.phase) ? 'rejected' : '')}>
                <td style={{ textAlign: "center" }}>
                  <Link to={`/rufrees?rufree_id=${history.rufree}`} target="_blank">
                    {history.rufree}
                  </Link>
                </td>
                <td style={{ textAlign: "center" }}>
                  {history.rufreeName}
                </td>
                <td style={{ textAlign: "center" }}>
                  {history.dateSend && moment(history.dateSend).format("YYYY-MM-DD HH:mm")}
                  <br/>
                  { history.dateViewed && `(최종확인: ${ moment(history.dateViewed).format("YYYY-MM-DD HH:mm")})` }
                </td>
                <td style={{ textAlign: "center" }}>
                  <div>
                    <p>
                      <span>{ STATUS[history.phase]['status'] }</span>
                      <br/>
                      { (history.phase === PHASE.accepted && history.dateAccepted) &&
                        <span>{`(수락: ${ moment(history.dateAccepted).format("YYYY-MM-DD HH:mm")})`}</span>
                      }
                    </p>
                    { history.phase === PHASE.rejected && history.rejectReason && `(거절사유: ${history.rejectReason})` }
                  </div>

                  {this.renderStatus(history, (matching.matchedRufree ? true : false), history.rufreeEmail, history.rufreePhone)}
                  {this.renderForcingConfirm(history, matching.matchedRufree ? true : false)}
                  {/*{!(history.status === "거절" || history.status === "미응답" || history.status === "발송전") ? this.renderForcingCancel(history) : ""}*/}
                  { [PHASE.submitted, PHASE.accepted, PHASE.matched].includes(history.phase) ? this.renderForcingCancel(history) : ''}
                </td>
                <td>
                  <RufreeMatchingHistoryComment
                    matchingId={matching.id}
                    historyId={history.id}
                    comments={history.comments}/>
                </td>
              </tr>
            ))}
            </tbody>
          </HTMLTable>
        </Card>

        <Dialog
          isOpen={this.isPreviewOpen}
          onClose={this.togglePreview}
          title="매칭 미리보기"
          usePortal={true}
          autoFocus={true}
          canEscapeKeyClose={true}
          canOutsideClickClose={true}
          isCloseButtonShown={true}
          enforceFocus={true}
        >
          <div className={Classes.DIALOG_BODY}>
            <MatchingPreview matching={matching}/>
          </div>
        </Dialog>

        <CandidateInfoPopup
          rufree={this.selectedCandidate}
          onClose={this.handlePopupClose}
        />

        <SendDialog
            templateType={ETEMPLATETYPE.EMAIL}
            excludeSupport={true}
            restrictedCategories={['PM', 'BUSINESS', 'ETC']}
        />

      </Wrapper>
      </LargeContainer>
    );
  }
}

export default RufreeMatchingDetailPage;

//*CSS*//
const Wrapper = styled.div`
  .recommended-search {
    background-color: #dcc6dc;
  }
  tbody.recommended-search { 
    background-color: #dcc6dc;
  }
  .recommended-search input{ 
    background-color: #dcc6dc;
  }
  tr.accepted {
    background-color: #ddfddd;
  }
  tr.rejected {
    background-color: #ffe7eb;
  }
  th.center {
    text-align: center;
  }
  td.center {
    text-align: center;
  }

  th.order-by {
    cursor: pointer;
    color: #106ba3;

    .active {
      text-decoration: underline;
    }
  }

  th.order-by.active {
    text-decoration: underline;
  }
`;

const ButtonsRow = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;

  & > * + * {
    margin-left: 10px;
  }
`;

const MatchingInfo = styled.div`
  margin: 60px 0 0px 0;
  min-width: 700px;
`;

const Counts = styled.div`
  display: flex;
  width: 400px;
  // margin-bottom: 30px;

  & > * + * {
    border-left: 1px solid grey;
  }
`;

const CountItem = styled.div`
  text-align: center;
  padding: 0 10px;
`;

const OnRecommend = styled.div`
  float: right;
  margin-top: 10px;
  text-align: right;
`;

const OrderByPG = styled.div`
  margin-right: 10px;
  text-align: right;
`;

const ButtonGroup = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const ToolsRow = styled.div`
  margin-left: 20px;
  display: flex;
`;

const SearchInputGroup = styled(InputGroup)`
  display: inline-block;
  width: 300px;
  margin: 5px 10px;
`;

const FormGroupLabel = styled.div`
`

const NoItemText = styled(Text)`
  text-align: center;
  font-size: 15px;
  color: #5C7080;
`;

const HelpText = styled(Text)` 
  display: inline-block;
  margin-left: 10px;
  font-size: 10px;
  color: #5C7080;
`;

const CandidatesSection = styled.div`
  width: 100%;
  max-height: 400px;
  overflow-y: hidden;
  margin-bottom: 4vh;
`;

const Divider = styled.div`
  border-top: 1px dashed #d2d2d2;
  margin: 60px 20px 60px 20px;
`

const PrimaryButton = styled(Button)`
  width: 100%;
  margin: 3px;
  color: white !important;
`;

const SecondaryButton = styled(Button)`
  width: 100%;
  margin: 3px;
`;

const WarningButton = styled(Button)`
  width: 100%;
  margin: 3px;
  color: white !important;
`;

const DangerButton = styled(Button)`
  width: 100%;
  margin: 3px;
  color: red !important;
`;
