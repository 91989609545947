import React, { Component } from "react";
import moment from "moment";
import { Button, Icon } from "@blueprintjs/core";
import { observable } from "mobx";
import { observer } from "mobx-react";
import Thumbnail from "../../atoms/Thumbnail/Thumbnail";
import { Intent } from "@blueprintjs/core/lib/esm/common/intent";
import styled from "styled-components/macro";

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const RightSection = styled.div`
  padding-left: 10px;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
`;

interface Props {
  file: string;
  id: number;
  created_at: string;
  onRemove: (id: number) => void;
  isImage: boolean;
  filename: string;
  disabled: boolean | undefined;
}

// @inject("appStore")
@observer
class TaskAttachmentFileForm extends Component<Props> {
  @observable onHover = false;

  render() {
    const { file, onRemove, created_at, id, isImage, filename, disabled } = this.props;
    return (
      <div style={{ marginTop: "10px" }}
           onMouseEnter={() => {
             if (!disabled)
               this.onHover = true;
           }}
           onMouseLeave={() => {
             this.onHover = false;
           }}>
        <Container>
          <div>
            <a href={file} download target="_blank">
              <div style={{ float: "left" }}>
                <Thumbnail isImage={isImage} src={file}>
                  <Icon icon={"document"} iconSize={60} intent={Intent.NONE}/>
                </Thumbnail>
              </div>
            </a>
          </div>
          <RightSection>
            <div>
              <div>{filename.length < 45 ? filename : filename.substring(0, 45) + "..."}</div>
              <div>{moment(created_at).format("YYYY-MM-DDT00:00")}</div>
            </div>
            {
              this.onHover &&
              <div>
                <Button
                  icon="trash"
                  minimal={true}
                  onClick={() => {
                    onRemove(id);
                  }}
                /></div>
            }
          </RightSection>
        </Container>
      </div>
    );
  }
}

export default TaskAttachmentFileForm;