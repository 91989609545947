import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { ConsultSelectionForm } from "../../../store/forms/ProjectGroupForm/ConsultSelectionForm";
import ProjectSelection from "../../molecules/ProjectSelection/ProjectSelection";
import { Project } from "../../../store/models/Project";
import { computed } from "mobx";
import ReceptionSelection from "../../molecules/ReceptionSelection/ReceptionSelection";
import { ReceptionSelectionForm } from "../../../store/forms/ProjectGroupForm/ReceptionSelectionForm";
import { Reception } from "../../../store/models/Reception";

const Wrapper = styled.div``;

interface ProjectGroupReceptionSelectionProps {
  receptionSelection: ReceptionSelectionForm;
}

interface InjectedProps extends ProjectGroupReceptionSelectionProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ProjectGroupReceptionSelection extends Component<
  ProjectGroupReceptionSelectionProps
> {
  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get selectedReception() {
    return this.injected.appStore.receptionStore.receptions.find(
      reception => reception.id === this.props.receptionSelection.value
    );
  }

  handleChange = (reception: Reception) => {
    this.props.receptionSelection.setValue(reception.id);
  };

  render() {
    return (
      <Wrapper>
        <ReceptionSelection
          onChange={this.handleChange}
          currentValue={this.selectedReception}
          blockFetchingData={ true }
        />
      </Wrapper>
    );
  }
}

export default ProjectGroupReceptionSelection;
