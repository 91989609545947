import React, { Component, ChangeEvent, FormEvent } from "react";
import { inject, observer } from "mobx-react";
import { computed, observable } from "mobx";
import { AppStore } from "../../../store/AppStore";
import { FormMode } from "../../../types/formMode";

import moment from "moment";
import styled from "styled-components/macro";
import { Checkbox, Dialog, Classes, FileInput, FormGroup, InputGroup, Intent, Text, TextArea, HTMLSelect, IOptionProps } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import { getMomentFormatter } from "../../../utils/date";
import { PrimaryButton, SecondaryButton } from "../../atoms/ParttimeComponents/ParttimeComponents";

import { AppToaster } from "../../organisms/AppToaster/AppToaster";

import { RequestType, RequestOsType } from "../../../types/parttime";
import { ReferenceFileFormModel } from "../../../store/forms/ParttimeForm/ReferenceFileForm";

import { Client } from "../../../store/models/Client";
import ClientSelection from "../../molecules/ClientSelection/ClientSelection";

import ReferenceLinkFormRow from "../../molecules/ParttimeRow/ReferenceLinkFormRow";
import ReferenceFileFormRow from "../../molecules/ParttimeRow/ReferenceFileFormRow";

interface RequestFormDialogProps {
    id: number;
    mode: FormMode;
    isOpen: boolean;
    onClose: () => void;
}

interface InjectedProps extends RequestFormDialogProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class RequestFormDialog extends Component<RequestFormDialogProps> {
    @observable onSubmit: boolean = false;

    get injected() {
        return this.props as InjectedProps;
    }

    @computed
    get formStore() {
        return this.injected.appStore.parttimeRequestFormStore
    }

    @computed
    get currentForm() {
        return this.formStore.requestForm;
    }

    @computed
    get selectedClient() {
        return this.injected.appStore.clientStore.clients.find(
            x => x.clientId === this.currentForm.client
        );
    }

    initForm = async () => {
        await this.formStore.initForm();

        const { id, mode } = this.props;

        if (mode === FormMode.Edit) {
            await this.formStore.fetchRequest(id);
        }
    };

   
    handleOpening = () => {};

    handleServiceNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.currentForm.setServiceName(e.target.value);
    };

    handleClientChange = (client: Client) => {
        this.currentForm.setClient(client.clientId);
    };

    handleOsChange = (e: FormEvent<HTMLInputElement>) => {
        this.currentForm.toggleOs(e.currentTarget.value as RequestOsType);
    };

    handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.currentForm.setTitle(e.target.value);
    };

    handleTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        this.currentForm.setType(e.currentTarget.value);
    };

    handleAsisChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        this.currentForm.setAsis(e.target.value);
    };

    handleTobeChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        this.currentForm.setTobe(e.target.value);
    };

    handleAccessInfoChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        this.currentForm.setAccessInfo(e.target.value);
    };

    handleExpectedDueDateChange = (selectedDate: Date) => {
       this.currentForm.setExpectedDueDate(selectedDate ? selectedDate.toISOString() : "");
    };

    handleSubmit = async () => {
        const { onClose, mode } = this.props;

        try {
            if (mode === FormMode.Create) {
                this.onSubmit = true;
                await this.formStore.createRequest();
                this.onSubmit = false;
                this.initForm();
            } else if(mode === FormMode.Edit) {
                this.onSubmit = true;
                await this.formStore.patchRequest();
                this.onSubmit = false;
                this.initForm();
            }
            onClose();
        } catch (e) {
            const error = JSON.stringify(e.response.data);
            AppToaster.show({
                message: "오류: " + error,
                intent: Intent.DANGER
            });
            this.onSubmit = false;
        }
      };
    
    async componentDidMount() {
        this.initForm();
    }
    render() {
        const { isOpen, onClose, mode } = this.props;

        const typeOptions: IOptionProps[] = [
            {label: '선택', value: ''},
            {label: '오류수정', value: RequestType.Bug},
            {label: '개선', value: RequestType.Improvement},
            {label: '기타', value: RequestType.Etc}
          ]
        
        const title = (mode === FormMode.Create) ? `신청 추가` : `신청 수정` ;

        return (
            <Dialog
                onOpening={this.handleOpening}
                isOpen={isOpen}
                onClose={onClose}
                usePortal={true}
                autoFocus={true}
                enforceFocus={true}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                title={title}
                style={{backgroundColor: "white"}}
            >
            <div className={Classes.DIALOG_BODY}>
                <RequiredText>*표시는 필수입력</RequiredText>
                <FormGroup label={<strong>서비스명</strong>} labelInfo="*" disabled={this.onSubmit}>
                    <InputGroup
                        placeholder="당사의 서비스명을 입력해주세요(최대 15자)"
                        value={this.currentForm.serviceName}
                        onChange={this.handleServiceNameChange}
                        maxLength={15}
                        disabled={this.onSubmit}
                    />
                </FormGroup>
                <FormGroup label={<strong>클라이언트</strong>} labelInfo="*" style={{marginTop: "30px"}} disabled={this.onSubmit}>
                    <ClientSelection
                    onChange={this.handleClientChange}
                    currentValue={this.selectedClient}
                    blockFetchingData={false}
                    />
                </FormGroup>
                <FormGroup label={<strong>OS</strong>} labelInfo="*" style={{marginTop: "30px"}} disabled={this.onSubmit}>
                        <StyledCheckbox {...this.state} value={RequestOsType.WEB_PC} checked={this.currentForm.os.includes(RequestOsType.WEB_PC)} label="WEB(PC)" onChange={this.handleOsChange} disabled={this.onSubmit}/>
                        <StyledCheckbox {...this.state} value={RequestOsType.WEB_MOBILE} checked={this.currentForm.os.includes(RequestOsType.WEB_MOBILE)} label="WEB(MO)" onChange={this.handleOsChange} disabled={this.onSubmit}/>
                        <StyledCheckbox {...this.state} value={RequestOsType.WEB_RESPONSE} checked={this.currentForm.os.includes(RequestOsType.WEB_RESPONSE)} label="WEB(반응형)" onChange={this.handleOsChange} disabled={this.onSubmit}/>
                        <StyledCheckbox {...this.state} value={RequestOsType.ANDROID} checked={this.currentForm.os.includes(RequestOsType.ANDROID)} label="aOS" onChange={this.handleOsChange} disabled={this.onSubmit}/>
                        <StyledCheckbox {...this.state} value={RequestOsType.IOS} checked={this.currentForm.os.includes(RequestOsType.IOS)} label="iOS" onChange={this.handleOsChange} disabled={this.onSubmit}/>
                        <StyledCheckbox {...this.state} value={RequestOsType.ETC} checked={this.currentForm.os.includes(RequestOsType.ETC)} label="기타" onChange={this.handleOsChange} disabled={this.onSubmit}/>
                    </FormGroup>
                <FormGroup label={<strong>제목</strong>} labelInfo="*" style={{marginTop: "30px"}} disabled={this.onSubmit}>
                    <InputGroup
                        placeholder="신청 제목을 입력해주세요. (최대 100자)"
                        value={this.currentForm.title}
                        onChange={this.handleTitleChange}
                        maxLength={100}
                        disabled={this.onSubmit}
                    />
                </FormGroup>
                <FormGroup label={<strong>유형</strong>} labelInfo="*" style={{marginTop: "30px"}} disabled={this.onSubmit}>
                    <HTMLSelect
                        value={this.currentForm.type}
                        onChange={this.handleTypeChange}
                        options={typeOptions}
                        disabled={this.onSubmit}
                    />
                </FormGroup>
                <FormGroup label={<strong>현재상태(ASIS)</strong>} labelInfo="*" style={{marginTop: "30px"}} disabled={this.onSubmit}>
                    <TextArea
                        rows={5}
                        value={this.currentForm.asis}
                        placeholder="현재 상태 또는 오류를 입력해주세요. (최대 1000자)"
                        onChange={this.handleAsisChange}
                        style={{ width: "100%" }}
                        maxLength={1000}
                        disabled={this.onSubmit}
                    />
                </FormGroup>
                <FormGroup label={<strong>변경상태(TOBE)</strong>} labelInfo="*" style={{marginTop: "30px"}} disabled={this.onSubmit}>
                    <TextArea
                        rows={5}
                        value={this.currentForm.tobe}
                        placeholder="수정 또는 개선 되어야할 상태를 입력해주세요. (최대 1000자)"
                        onChange={this.handleTobeChange}
                        style={{ width: "100%" }}
                        maxLength={1000}
                        disabled={this.onSubmit}
                    />
                </FormGroup>
                <FormGroup label={<strong>참고파일</strong>} style={{marginTop: "30px"}} disabled={this.onSubmit}>
                    <FileInput 
                        text="파일 추가" 
                        fill={true} 
                        disabled={this.onSubmit}
                        onInputChange={(e: FormEvent<HTMLInputElement>) => {
                            if (e.currentTarget.files) {
                                const file = ReferenceFileFormModel.create();
                                file.setFile(e.currentTarget.files[0], e.currentTarget.files[0].name);
                                this.currentForm.addReferenceFile(file);
                        }
                    }} />
                    <ReferenceFileBoard>
                        {Array.isArray(this.currentForm.referenceFiles) && this.currentForm.referenceFiles.length 
                        ? (<ReferenceFileWrapper>
                                {this.currentForm.referenceFiles.map((file, index) => (
                                    <ReferenceFileFormRow
                                        key={index}
                                        referenceFile={file}
                                        onRemove={this.currentForm.removeReferenceFile}
                                    ></ReferenceFileFormRow>
                                ))}
                            </ReferenceFileWrapper>)
                        : (
                            <DefaultTextWrapper>
                                <DefaultText>
                                오류이미지 또는 TASK 해결에 필요한 파일 <br/>
                                (최대 10개 파일, 파일당 최대 10MB)
                                </DefaultText>
                            </DefaultTextWrapper>
                        )}
                    </ReferenceFileBoard>
                </FormGroup>
                <FormGroup label={<strong>참고링크</strong>} style={{marginTop: "30px"}} disabled={this.onSubmit}>
                    <ReferenceLinkWrapper>
                    {this.currentForm.referenceLinks.map((referenceLink, index) => (
                        <ReferenceLinkFormRow
                            key={index}
                            referenceLink={referenceLink}
                            disabled={this.onSubmit}
                            onRemove={this.currentForm.removeReferenceLink}
                        ></ReferenceLinkFormRow>
                    ))}
                    </ReferenceLinkWrapper>
                    
                    <SecondaryButton 
                        text={"링크 추가 입력"} 
                        onClick={this.currentForm.addReferenceLink}
                        style={{width: "100%", marginTop: "10px"}}
                        disabled={this.onSubmit}
                    />
                </FormGroup>
                <FormGroup label={<strong>서비스 접속 정보</strong>} style={{marginTop: "30px"}} disabled={this.onSubmit}>
                    <TextArea
                        rows={5}
                        value={this.currentForm.accessInfo ? this.currentForm.accessInfo : undefined}
                        placeholder="서비스 접속 정보를 입력해주세요.(최대 200자)"
                        onChange={this.handleAccessInfoChange}
                        style={{ width: "100%" }}
                        maxLength={200}
                        disabled={this.onSubmit}
                    />  
                </FormGroup>
                <FormGroup label={<strong>희망 완료일</strong>} style={{marginTop: "30px"}} disabled={this.onSubmit}>
                    <DateInput
                    {...getMomentFormatter("YYYY/MM/DD")}
                    locale="ko"
                    closeOnSelection={true}
                    value={this.currentForm.expectedDueDate ? moment(this.currentForm.expectedDueDate).toDate() : null}
                    onChange={this.handleExpectedDueDateChange}
                    maxDate={new Date("2050-01-01")}
                    disabled={this.onSubmit}
                    />
                </FormGroup>
            </div>
    
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <PrimaryButton text={(mode === FormMode.Create) ? "추가" : "수정"} style={{width: "100%"}} onClick={this.handleSubmit} disabled={this.onSubmit}/>
              </div>
            </div>
          </Dialog>
        );
    }
}

export default RequestFormDialog;

const DefaultTextWrapper = styled.div`
    display: flex;
    min-height: 140px;
    flex-direction: column;
    justify-content: center; 
    align-items: center;  
`;

const DefaultText = styled.div`
    text-align: center;
    color: #9e9e9e;
`;

const StyledCheckbox = styled(Checkbox)`
    display: inline;
    margin-right: 30px;
`;

const ReferenceLinkWrapper = styled.div`
  & > * {
    margin-top: 5px;
  }
`;

const ReferenceFileBoard = styled.div`
    background-color: #f3f3f3;
    height: 150px;
    margin-top: 5px;
    overflow: overlay;
`;
const ReferenceFileWrapper = styled.div`
    padding: 5px 0;
`;

const RequiredText = styled(Text)`
    color: #ff6565;
    font-size: 12px;
    margin: 0 0 20px 0;
`;
