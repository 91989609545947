import { types } from "mobx-state-tree";


export const DashboardTaskFormModel = types
  .model("DashboardTaskForm", {
    id: types.optional(types.number, -1),
    category: types.optional(types.string, ""),
    startAt: types.optional(types.string, ""),
    endAt: types.optional(types.string, ""),
    allDay: types.optional(types.boolean, false),
    taskDone: types.optional(types.boolean, false),
    title: types.optional(types.string, ""),
    comment: types.optional(types.string, ""),
    creator: types.optional(types.number, -1),
    creatorName: types.optional(types.string, ""),
    creatorPmName: types.optional(types.string, ""),
    projectGroup: types.optional(types.string, ""),
    projectGroupName: types.optional(types.string, ""),
    // participants: types.optional(types.string, ""),
  })
  .views(self => ({
  }))
  .actions(self => ({
  }))
  .actions(self => ({
    setId(value: number) {
      self.id = value;
    },
    setCategory(value: string) {
      self.category = value;
    },
    setStartAt(value: string) {
      self.startAt = value;
    },
    setEndAt(value: string) {
      self.endAt = value;
    },
    setAllDay(value: boolean) {
      self.allDay = value;
    },
    setTaskDone(value: boolean) {
      self.taskDone = value;
    },    
    setTitle(value: string) {
      self.title = value;
    },
    setComment(value: string) {
      self.comment = value;
    },
    setCreator(value: number) {
      self.creator = value;
    },
    setCreatorName(value: string) {
      self.creatorName = value;
    }, 
    setCreatorPmName(value: string) {
      self.creatorPmName = value;
    },       
    setProjectGroup(value: string) {
      self.projectGroup = value;
    }
  }));

type DashboardTaskFormType = typeof DashboardTaskFormModel.Type;
export interface DashboardTaskForm extends DashboardTaskFormType {}
