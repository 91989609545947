import React, { Component } from "react";

import { Card, H2 } from "@blueprintjs/core";

interface WorkingModePageProps {}

class WorkingModePage extends Component<WorkingModePageProps> {

  render() {

    return (
      <div>
        <Card
          style={ {
            width: '800px',
            margin: 'auto',
            marginTop: '300px'
          }}
        >
          <H2>
            <a href="#">백오피스 정비 중</a>
          </H2>
          <p style={{ marginTop: '30px' }}>
            현재 백오피스 정비 중입니다. 급한 건은 슬랙 re-re 채널에 @헤이즐/@조이/@기댄 멘션하여 요청해주세요.
          </p>
      </Card>
      </div>
    );
  }
}

export default WorkingModePage;
