import React, { FC, useState } from "react";
import styled from "styled-components/macro";
import { ChangeEvent } from "cleave.js/react/props";
import {
  HTMLSelect,
  IOptionProps
} from "@blueprintjs/core";

const statusOptions: IOptionProps[] = [
  {label: '전체', value: ''},
  {label: '경영지원', value: 'SUPPORT'},
  {label: '영업', value: 'BUSINESS'},
  {label: 'PM', value: 'PM'},
  {label: '기타', value: 'ETC'}
]

interface Props {
  className?: string;
  readOnly?: boolean;
  value: string;
  handleChange: (category: string) => void;
}

const CategorySelection: FC<Props> = ({
  className,
  readOnly,
  value,
  handleChange
}) => {
  return (
      <HTMLSelect
        className={ className }
        disabled={ readOnly }
        value={ value }
        onChange={ (e: ChangeEvent<HTMLSelectElement>) => {
          handleChange(e.currentTarget.value);
        } }
        options={statusOptions}
      />
  );
}

export default CategorySelection;
