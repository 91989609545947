import React, { Component } from "react";
import styled from "styled-components/macro";

const Wrapper = styled.div`
  width: 1166px;
  padding-bottom: 40px;
`;

interface LargeContainerProps {}

class LargeContainer extends Component<LargeContainerProps> {
  render() {
    return <Wrapper>{this.props.children}</Wrapper>;
  }
}

export default LargeContainer;
