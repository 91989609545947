import React, { Component, ChangeEvent } from "react";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import {
  Dialog,
  Classes,
  Button,
  Intent,
  FormGroup,
  InputGroup,
  HTMLSelect,
} from "@blueprintjs/core";
import { observable } from "mobx";
import { ProjectGroup } from "../../../store/models/ProjectGroup";
import { Repository } from '../../../store/models/Repository';
import { AppToaster } from "../AppToaster/AppToaster";
import { RepositoryTemplateType } from '../../../types/projectGroup';

interface ProjectGroupRepositoryAddPopupProps {
  onClose: () => void;
  isOpen: boolean;
  projectGroup: ProjectGroup;
}

interface InjectedProps extends ProjectGroupRepositoryAddPopupProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ProjectGroupRepositoryAddPopup extends Component<
  ProjectGroupRepositoryAddPopupProps
> {
  @observable pendingName = '';
  @observable pendingDescription = '';
  @observable pendingTemplate: RepositoryTemplateType = '--';

  get injected() {
    return this.props as InjectedProps;
  }

  handleOpening = () => {
    this.pendingName = '';
    this.pendingDescription = '';
    this.pendingTemplate = '--';
  };

  handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.pendingName = e.target.value;
  };

  handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.pendingDescription = e.target.value;
  };

  handleTemplateChange = (e: ChangeEvent<HTMLSelectElement>) => {
    this.pendingTemplate = e.target.value as RepositoryTemplateType;
  };

  handleSave = async () => {
    try {
      await this.props.projectGroup.addRepository(
        this.pendingName,
        this.pendingDescription,
        this.pendingTemplate,
      );

      AppToaster.show({
        message: "저장소가 생성되었습니다.",
        intent: Intent.SUCCESS
      });
      this.handleClose();
    } catch (e) {
      const error = JSON.stringify(e.response.data);

      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { isOpen } = this.props;

    return (
      <Dialog
        onOpening={this.handleOpening}
        isOpen={isOpen}
        title="프로젝트 저장소 추가"
        usePortal={true}
        autoFocus={true}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        enforceFocus={true}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup label="저장소 이름">
            <InputGroup
              placeholder="Repository 이름 (영문)"
              value={this.pendingName}
              onChange={this.handleNameChange}
            />
          </FormGroup>
          <FormGroup label="저장소 개요">
            <InputGroup
              placeholder="Repository 개요"
              value={this.pendingDescription}
              onChange={this.handleDescriptionChange}
            />
          </FormGroup>
          <FormGroup label="템플릿">
            <HTMLSelect
              placeholder="Repository 개요"
              value={this.pendingTemplate}
              onChange={this.handleTemplateChange}
            >
                <option value='--'>기본형</option>
                <option value='WF'>웹 프론트엔드</option>
                <option value='WB'>웹 백엔드</option>
            </HTMLSelect>
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button text="취소" onClick={this.handleClose} />
            <Button
              text="생성"
              intent={Intent.PRIMARY}
              onClick={this.handleSave}
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

export default ProjectGroupRepositoryAddPopup;
