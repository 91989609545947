import React, {ChangeEvent, Component} from "react";
import styled from "styled-components/macro";
import {inject, observer} from "mobx-react";
import {AppStore} from "../../../store/AppStore";
import FormContainer from "../../atoms/FormContainer/FormContainer";
import FormSection from "../../atoms/FormSection/FormSection";
import {Button, FormGroup, H2, InputGroup, Intent, Spinner, TextArea} from "@blueprintjs/core";
import {FormMode} from "../../../types/formMode";
import {computed, observable} from "mobx";
import ContentContainer from "../../atoms/ContentContainer/ContentContainer";
import {Redirect, RouteComponentProps} from "react-router";
import {AppToaster} from "../../organisms/AppToaster/AppToaster";
import {PMUser} from "../../../store/models/PMUser";
import PMUserSelection from "../../molecules/PMUserSelection/PMUserSelection";
import InspectionSelection from "../../molecules/InspectionSelection/InspectionSelection";
import {Inspection} from "../../../store/models/Inspection";
import InputWithFormat, {KoreanCurrencyOptions} from "../../atoms/InputWithFormat/InputWithFormat";
import {ConvertLinkTextFormat} from "../../atoms/ConvertLinkTextFormat/ConvertLinkTextFormat";
import {DateInput} from "@blueprintjs/datetime";
import {getMomentFormatter} from "../../../utils/date";
import moment from "moment";
import MatchingPreview from "../../organisms/MatchingPreview/MatchingPreview";
import LargeContainer from "../../atoms/LargeContainer/LargeContainer";
import TemporaryStorage from "../../molecules/Utility/TemporaryStorage/TemporaryStorage";

const Wrapper = styled.div`
  position: relative;
`;

const ButtonsRow = styled.div`
  /* text-align: right; */

  & > * + * {
    margin-left: 5px;
  }
`;

const StyledTextArea = styled(TextArea)`
  width: 100%;
`;

const PreviewContainer = styled.div`
  position: absolute;
  top: 40px;
  right: 100px;
`;

const NoticeText = styled.strong`
  color: #e89905;
`;

interface PageParams {
  projectGroupId: string;
  rufreeMatchingId: string;
}

interface RufreeMatchingFormPageProps extends RouteComponentProps<PageParams> {
  mode: FormMode;
}

interface InjectedProps extends RufreeMatchingFormPageProps {
  appStore: AppStore;
}



@inject("appStore")
@observer
class RufreeMatchingFormPage extends Component<RufreeMatchingFormPageProps> {
  @observable isFormInitiated = false;
  @observable isPending = false;
  @observable isCreateSuccess = false;
  @observable isEditSuccess = false;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get formStore() {
    return this.injected.appStore.rufreeMatchingFormStore;
  }

  @computed
  get currentForm() {
    return this.formStore.currentForm;
  }

  @computed
  get selectedProjectGroup() {
    return this.injected.appStore.projectGroupStore.projectGroups.find(
      x => x.groupId === this.props.match.params.projectGroupId
    );
  }

  @computed
  get selectedManager() {
    const { manager } = this.currentForm;
    return this.injected.appStore.pmUserStore.pmUsers.find(
      x => x.name === manager
    );
  }

  @computed
  get selectedInspection() {
    const { inspections } = this.currentForm;
    return this.injected.appStore.inspectionStore.inspections.find(
      x => x.inspectionId === inspections + ""
    );
  }

  // 정보만을 받는 용도. 나머지는 안 씀.
  @computed
  get matching() {
    const { rufreeMatchingId } = this.props.match.params;

    return this.injected.appStore.rufreeMatchingStore.rufreeMatchings.find(
        x => x.id === +rufreeMatchingId
    );
  }

  async componentDidMount() {
    const { mode } = this.props;
    const { projectGroupId, rufreeMatchingId } = this.props.match.params;
    
    if (projectGroupId) {
      await this.injected.appStore.projectGroupStore.fetchProjectGroup(
        projectGroupId
      );
    }

    if (mode !== FormMode.Create && rufreeMatchingId) {
      await this.formStore.fetchRufreeMatching(+rufreeMatchingId);
    } else {
      // 검수서로부터 사전공유자료 가져옴
      let projectReferences:string = '';
      if(this.selectedProjectGroup) {
        this.selectedProjectGroup.references.map(reference => (
            projectReferences +=`${reference.comment} - ${reference.link}\n`)
        )
      }
      if(this.selectedInspection) {
        this.selectedInspection.references.map(reference => (
            projectReferences +=`${reference.comment} - ${reference.link}\n`)
        )
      }

      this.currentForm.setReference(projectReferences);
    }

    await this.injected.appStore.pmUserStore.fetchPMUsers();
    this.isFormInitiated = true;
  }

  handleProjectGroupChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.currentForm.setProjectGroup(e.target.value);
  };

  handleManagerChange = (manager: PMUser) => {
    this.currentForm.setManager(manager.name);
  };

  handleInspectionChange = (inspection: Inspection) => {
    this.currentForm.setInspection(inspection.inspectionId);
  };

  handleDateDueChange = (selectedDate: Date) => {
    this.currentForm.setDateDue(selectedDate.toISOString());
  };

  handlePmManagerCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.currentForm.setPmManagerComment(e.currentTarget.value);
  };

  handleReferenceChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.currentForm.setReference(e.currentTarget.value);
  };

  handleRoleDetailChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.currentForm.setRoleDetail(e.currentTarget.value);
  };

  handleMatchingManagerCommentChange = (
    e: ChangeEvent<HTMLTextAreaElement>
  ) => {
    this.currentForm.setMatchingManagerComment(e.currentTarget.value);
  };

  handleFinalMatchingManagerCommentChange = (
    e: ChangeEvent<HTMLTextAreaElement>
  ) => {
    this.currentForm.setFinalMatchingManagerComment(e.currentTarget.value);
  };

  handleSubmit = async () => {
    if (this.isCreateSuccess) {
      AppToaster.show({
        message: "이미 저장된 매칭입니다",
        intent: Intent.DANGER
      });

      return;
    }

    this.isPending = true;

    try {
      await this.formStore.postRufreeMatching();
      AppToaster.show({
        message: "새로운 알유프리 매칭이 생성되었습니다.",
        intent: Intent.SUCCESS
      });
      this.isCreateSuccess = true;
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    } finally {
      this.isPending = false;
    }
  };

  handleCancel = () => {
    const { projectGroupId, rufreeMatchingId } = this.props.match.params;

    if (this.props.mode === FormMode.Create) {
      this.props.history.push(`/project-groups/${projectGroupId}`);
    } else {
      this.props.history.push(`/rufree-matchings/${rufreeMatchingId}`);
    }
  };
  
  handleMatchingDeleteClick = async () => {
    const deleteConfirm = window.confirm("삭제 하시겠습니까?");
    
    if (deleteConfirm) {
      const { rufreeMatchingId } = this.props.match.params;

      await this.injected.appStore.rufreeMatchingStore.deleteRufreeMatching(
        +rufreeMatchingId
      );
      this.props.history.push(
        `/rufree-matchings`
      );
    }
  };

  handleEditSubmit = async () => {
    this.isPending = true;

    try {
      await this.formStore.putRufreeMatching();
      AppToaster.show({
        message: "수정되었습니다.",
        intent: Intent.SUCCESS
      });

      this.isEditSuccess = true;
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    } finally {
      this.isPending = false;
    }
  };

  handleRequestMatching = async () => {
    try {
      await this.injected.appStore.rufreeMatchingFormStore.toggleRequestMatching();
      this.props.history.push(`/rufree-matchings`);
      
      AppToaster.show({
        message: "매칭요청 되었습니다.",
        intent: Intent.SUCCESS
      });
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  handleRequestUpdate = async () => {
    try {
      await this.injected.appStore.rufreeMatchingFormStore.toggleRequestUpdate();

      AppToaster.show({
        message: "수정요망 되었습니다.",
        intent: Intent.SUCCESS
      });
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  renderMatchingRequest = (matching: any) => {
    let requestBtn: any;

    if ( matching.status == "작성중" ) {
      requestBtn = (
        <>
          <Button
            text="매칭요청"
            intent={Intent.SUCCESS}
            onClick={this.handleRequestMatching}
          />
        </>
    )} else if ( matching.status == "제안대기" ) {
      requestBtn = (<Button text="수정요망" onClick={this.handleRequestUpdate} />);
    } else {
        requestBtn = "";
    }

    return requestBtn ;
  };

  renderDeleteRequest = (matching: any) => {
    let deleteRequestBtn: any;

    if ( !matching.rufreeOfferSend ) {
      deleteRequestBtn = (
        <>
          <Button text="삭제" intent={Intent.DANGER} onClick={this.handleMatchingDeleteClick}/>
        </>
      )
    } else { 
      deleteRequestBtn = (<Button className="disabled" text="삭제" intent={Intent.NONE} style={{ cursor: "not-allowed", background: "#e6e6e6" }} />)
    }

    return deleteRequestBtn;
  };

  render() {
    
    if (!this.isFormInitiated || !this.selectedProjectGroup) {
      return <Spinner />;
    }

    if (this.isCreateSuccess || this.isEditSuccess) {
      return (
        <Redirect to={`/rufree-matchings/${this.currentForm.id}`} push={true} />
      );
    }
    const id_matched_status = this.currentForm.matchedRufree ? true : false;
    const { matching } = this;
    const matchable = (this.currentForm.status == '작성중')  ? true : false;

    return (
      <LargeContainer>
      <Wrapper>
        <H2 style={ {marginBottom: "30px" }}>매칭정보</H2>
        <TemporaryStorage keyName={"RufreeMatching"} title={"projectGroupName"} setForm={(val) => {
          this.formStore.setForm(val);
        }} currentForm ={this.currentForm} />
        <FormContainer>
          <FormSection>
            <FormGroup label={<strong>프로젝트 그룹 번호</strong>}>
              <InputGroup value={this.selectedProjectGroup.groupId} disabled={id_matched_status} />
            </FormGroup>

            <FormGroup label={<strong>프로젝트 제목</strong>}>
              <InputGroup value={this.currentForm.projectGroupName || ""} disabled={id_matched_status} />
            </FormGroup>

            <FormGroup label={<strong>요청자</strong>}>
              {
                id_matched_status ? (
                  this.selectedManager && this.selectedManager.name
                ) : (
                  <PMUserSelection
                    onChange={this.handleManagerChange}
                    currentValue={this.selectedManager}
                  />
                )
              }

            </FormGroup>

            <FormGroup label={<strong>검수서</strong>}>
              {
                id_matched_status && this.selectedInspection ? (
                  <a
                    target="_blank"
                    href={`/inspections/${this.selectedInspection.inspectionId}/read`}
                  >
                    {this.selectedInspection.nameWithId}
                  </a>
                ) : (
                  <div style={{ display: "flex" }}>
                    <InspectionSelection
                      onChange={this.handleInspectionChange}
                      currentValue={this.selectedInspection}
                      items={this.selectedProjectGroup.inspectionInstances}
                    />
                    {this.selectedInspection && (
                      <a
                        target="_blank"
                        href={`/inspections/${this.selectedInspection.inspectionId}/read`}
                      >
                        <Button text="보기" />
                      </a>
                    )}
                  </div>
                )
              }

            </FormGroup>

            <FormGroup label={<strong>역할</strong>}>
              <InputGroup value={this.currentForm.role || ""} disabled={id_matched_status} />
            </FormGroup>

            <FormGroup label={<strong>스프린트 당 비용</strong>}>
              <InputWithFormat
                disabled={id_matched_status}
                value={this.currentForm.sprintWage}
                onChange={(e: any) => {
                  const withoutPrefix = e.target.rawValue.substr(2);
                  this.currentForm.setSprintWage(
                    parseInt(withoutPrefix, 10) || 0
                  );
                }}
                options={KoreanCurrencyOptions}
              />
            </FormGroup>

            <FormGroup label={<strong>스프린트 당 수수료</strong>}>
              <InputWithFormat
                disabled={id_matched_status}
                value={this.currentForm.sprintFee}
                onChange={(e: any) => {
                  const withoutPrefix = e.target.rawValue.substr(2);
                  this.currentForm.setSprintFee(
                    parseInt(withoutPrefix, 10) || 0
                  );
                }}
                options={KoreanCurrencyOptions}
              />
            </FormGroup>

            <FormGroup label={<strong>예상 스프린트 개수</strong>}>
              <InputWithFormat
                disabled={id_matched_status}
                value={this.currentForm.sprintNum}
                onChange={(e: any) => {
                  const withoutPrefix = e.target.rawValue;
                  this.currentForm.setSprintNum(
                    parseInt(withoutPrefix, 10) || 0
                  );
                }}
                options={{ numeral: true }}
              />
            </FormGroup>

            <FormGroup label={<strong>희망 날짜</strong>}>
              <DateInput
                {...getMomentFormatter("YYYY/MM/DD")}
                locale="ko"
                disabled={id_matched_status}
                closeOnSelection={true}
                value={moment(this.currentForm.dateDue + "").toDate()}
                onChange={this.handleDateDueChange}
                maxDate={new Date("2050-01-01")}
              />
            </FormGroup>
            <FormGroup label={<strong>검수보고서 내 매니징시 주의사항</strong>}>
              <ConvertLinkTextFormat>
                <div style={{ whiteSpace: "pre-line" }}>
                  {this.selectedInspection && this.selectedInspection.attention ? 
                    this.selectedInspection.attention
                    : 
                    '입력된 주의사항이 없습니다.'}
                </div>
              </ConvertLinkTextFormat>
            </FormGroup>

            <FormGroup label={<strong>매칭 담당자에게 남기는 코멘트</strong>}>
              <StyledTextArea rows={10}
                disabled={id_matched_status}
                onChange={this.handlePmManagerCommentChange}
              >
                { this.currentForm.pmManagerComment ? this.currentForm.pmManagerComment :
                  "📝 담당자 연락처\n" +
                  "- 이름:  클라이언트 정보와 다를 경우\n" +
                  "- 이메일: 클라이언트 정보와 다를 경우\n" +
                  "\n" +
                  "📝 알유프리 적합도 관련\n" +
                  "- 초급, 중급, 특급 이상의 역량 : 작성 요청\n" +
                  "- (기술) 스택 및 필수 요구사항(툴) 등 : 작성 요청\n" +
                  "- 커뮤니케이션 및 작업 스타일 요구 사항 : 작성 요청\n" +
                  "- 작업 시간대 : 작성 요청\n" +
                  "- 결과물 요구사항 등 : 작성 요청\n" +
                  "\n" +
                  "📝일정 관련\n" +
                  "- 매칭 희망일 : 작성 요청\n" +
                  "- 매칭 최대 데드라인 : 작성 요청\n" +
                  "- 작업 시작 예정일 : 작성 요청\n" +
                  "- 스프린트 범위(추가 등) 조정 가능성 : 작성 요청\n" +
                  "- 기타 의견 : 작성 요청"
                }
              </StyledTextArea>
            </FormGroup>

            <FormGroup label={<NoticeText>작업범위 *</NoticeText>}>
              <StyledTextArea rows={10}
                disabled={id_matched_status}
                value={this.currentForm.roleDetail || ""}
                onChange={this.handleRoleDetailChange}
              />
            </FormGroup>

            <FormGroup label={<NoticeText>사전공유자료 *</NoticeText>}>
              <StyledTextArea rows={10}
                disabled={id_matched_status}
                value={this.currentForm.reference}
                onChange={this.handleReferenceChange}
              />
            </FormGroup>

            <FormGroup label={<NoticeText>알유프리에게 남기는 코멘트 *</NoticeText>}>
              <StyledTextArea rows={10}
                disabled={id_matched_status}
                value={this.currentForm.matchingManagerComment}
                onChange={this.handleMatchingManagerCommentChange}
              />
            </FormGroup>

            <FormGroup label={<strong>매칭 결과 코멘트</strong>}>
              <StyledTextArea
                value={this.currentForm.finalMatchingManagerComment}
                onChange={this.handleFinalMatchingManagerCommentChange}
              />
            </FormGroup>

            {/* <FormGroup label={<strong>매니저</strong>}>
              <AddItemButtonContainer>
                <AddItemButton onClick={this.currentForm.addManager} />
              </AddItemButtonContainer>
              {this.currentForm.managers.map((manager, index) => (
                <ItemRow key={index}>
                  <RemoveButtonContainer>
                    <RemoveButton
                      onClick={() => this.currentForm.removeManager(manager)}
                    />
                  </RemoveButtonContainer>
                  <RufreeMatchingManagerFormView manager={manager} />
                </ItemRow>
              ))}
            </FormGroup> */}
          </FormSection>
        </FormContainer>

        <ContentContainer>
          {this.props.mode === FormMode.Create && (
            <ButtonsRow>
              <Button text="취소" onClick={this.handleCancel} />
              <Button
                text="저장"
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit}
                loading={this.isPending}
              />
            </ButtonsRow>
          )}
          {this.props.mode === FormMode.Edit && (
            <ButtonsRow>
              <Button text="취소" onClick={this.handleCancel} />
              <Button
                text="수정사항 적용"
                intent={Intent.PRIMARY}
                onClick={this.handleEditSubmit}
                loading={this.isPending}
              />
              {this.renderMatchingRequest(matching)} 
              {this.renderDeleteRequest(matching)}
            </ButtonsRow>
          )}
        </ContentContainer>

        <PreviewContainer>
          <MatchingPreview matching={this.currentForm} />
          <div>
              <strong>* 표시된 입력칸의 내용은 알유프리에게 그대로 노출</strong>
          </div>          
        </PreviewContainer>
      </Wrapper>
      </LargeContainer>
    );
  }
}

export default RufreeMatchingFormPage;
