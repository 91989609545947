import { flow, types } from "mobx-state-tree";
import axios from "axios";
import { ClientContractResponse, CommentResponse, RufreeContractResponse } from "../../types/contract";
import { PMUserModel } from "./PMUser";
import { RufreeModel } from "./Rufree";
import { InspectionSimpleModel } from "./Inspection";
import { ClientModel } from "./Client";

export interface ContractResponse {
  id: number;
  project_name: string;
  client_id: string;
}

export const ContractModel = types
  .model("Contract", {
    id: types.number,
    projectName: types.string,
    clientId: types.string
  })
  .views(self => ({}))
  .actions(self => {

    return {};
  });
type ContractType = typeof ContractModel.Type;

export interface Contract extends ContractType {
}


export const ContractCommentModel = types
  .model("ContractComment", {
    id: types.number,
    author: types.number,
    authorName: types.string,
    createdAt: types.string,
    updatedAt: types.string,
    content: types.string
  });
type ContractCommentType = typeof ContractCommentModel.Type;

export interface ContractComment extends ContractCommentType {
}

export const GenericContractRequestModel = types.model("GenericContractRequest", {
  contractId: types.string,
  contractName: types.string,
  contractTarget: types.string,
  contractorId: types.string,
  contractorName: types.string,
  pmUserName: types.string,
  contractStatus: types.string,
  contractUpdatedAt: types.string,
  contractCreatedAt: types.string,
});
export const ClientContractRequestModel = types
  .model("ClientContractRequest", {
    id: types.number,
    contractId: types.string,
    createdAt: types.string,
    updatedAt: types.string,
    contractName: types.string,
    address: types.string,
    representativeName: types.string,
    contractorName: types.string,
    contractorEmail: types.string,
    contractorPhone: types.string,
    taxInvoiceManagerName: types.string,
    taxInvoiceManagerEmail: types.string,
    taxInvoiceManagerContact: types.string,
    nda: types.boolean,
    contractDate: types.string,
    contractFeeRate: types.string,
    contractRange: types.string,
    specialContract: types.string,
    comment: types.string,
    status: types.string,
    client: types.string,
    clientDetail: types.maybeNull(ClientModel),
    // inspection: types.number,
    inspections: types.array(types.string),
    inspectionsDetail: types.array(InspectionSimpleModel),
    pmUser: types.number,
    pm_user_detail: PMUserModel,
    managerName: types.string,
    projectGroup: types.string,
    contractorNumber: types.string,
    contractorIdentityNumber: types.string,
    contractComments: types.array(ContractCommentModel),
    approveRequester: types.maybeNull(types.integer),
    contractType: types.string
  })
  .views(self => ({}))
  .actions(self => ({
    setStatus(value: string) {
      self.status = value;
    },
  }))
  .actions(self => {
    // 대기 이후의 status 변경
    const changeStatusPreRequest = flow(function* () {
      try {
        const { data }: { data: RufreeContractResponse } = yield axios.put(
          `/clientContract/${self.contractId}/post_request`,
          {
            "status":self.status,
          }
        );

        self.status = data.status;
      } catch (e) {
        throw e;
      }
    });

    const requestApprove = flow(function* () {
      try {
        const { data }: { data: ClientContractResponse } = yield axios.put(
          `/clientContract/${self.contractId}/request`
        );
        self.status = data.status;
      } catch (e) {
        throw e;
      }
    });

    const cancelRequestApprove = flow(function* () {
      try {
        const { data }: { data: ClientContractResponse } = yield axios.put(
          `/clientContract/${self.contractId}/cancel`
        );
        self.status = data.status;
      } catch (e) {
        throw e;
      }
    });

    const approve = flow(function* () {
      try {
        const { data }: { data: ClientContractResponse } = yield axios.put(
          `/clientContract/${self.contractId}/approve`
        );
        self.status = data.status;
      } catch (e) {
        throw e;
      }
    });

    const reject = flow(function* () {
      try {
        const { data }: { data: ClientContractResponse } = yield axios.put(
          `/clientContract/${self.contractId}/reject`
        );
        self.status = data.status;
      } catch (e) {
        throw e;
      }
    });

    const remove = flow(function* () {
      yield axios.delete(
        `/clientContract/${self.contractId}`
      );
    });

    const fetchComments = flow(function* () {
      try {
        const { data }: { data: CommentResponse[] } = yield axios.get(
          `/clientContract/${self.contractId}/comments`);

        const comments = data.map(x => ContractCommentModel.create({
          id: x.id,
          author: x.author || -1,
          authorName: x.author_name || "",
          createdAt: x.created_at,
          updatedAt: x.updated_at,
          content: x.content
        }));

        self.contractComments.replace(comments.reverse());
      } catch (e) {
        throw e;
      }
    });

    const addComment = flow(function* (comment: string) {
      try {
        yield axios.post(
          `/clientContract/${self.contractId}/addComments`,
          {
            content: comment
          }
        );
      } catch (e) {
        throw e;
      }
    });

    const updateComment = flow(function* (id: number, comment: string) {
      try {
        yield axios.patch(
          `/clientContract/${self.contractId}/updateComments`,
          {
            id: id,
            content: comment
          }
        );
      } catch (e) {
        throw e;
      }
    });

    const deleteComment = flow(function* (id: number) {
      try {
        yield axios.delete(
          `/clientContract/${self.contractId}/deleteComments`,
          {
            data: { id: id }
          }
        );
      } catch (e) {
        throw e;
      }
    });

    return {
      changeStatusPreRequest,
      requestApprove,
      cancelRequestApprove,
      approve,
      reject,
      remove,
      fetchComments,
      addComment,
      updateComment,
      deleteComment
    };
  });
type ClientContractRequestType = typeof ClientContractRequestModel.Type;

export interface ClientContractRequest extends ClientContractRequestType {
}

export const RufreeContractRequestSimpleModel = types
  .model("RufreeContractRequestSimple", {
    contractId: types.string,
    contractName: types.string,
    rufreeUser: types.maybeNull(types.string),
    contractDate: types.string,
    sprintPeriod: types.string,
    contractRange: types.string,
    specialContract: types.string,
    comment: types.string,
  })
type RufreeContractRequestSimpleType = typeof RufreeContractRequestSimpleModel.Type;
export interface RufreeContractRequestSimple extends RufreeContractRequestSimpleType{}

export const RufreeContractRequestModel = types
  .model("RufreeContractRequest", {
    id: types.number,
    contractId: types.string,
    createdAt: types.string,
    updatedAt: types.string,
    contractName: types.string,
    info: types.string,
    agentName: types.string,
    agentEmail: types.string,
    agentAddress: types.string,
    address: types.string,
    contractDate: types.string,
    sprintPeriod: types.string,
    payment: types.string,
    commission: types.string,
    paymentFee: types.number,
    commissionFee: types.number,
    contractRange: types.string,
    specialContract: types.string,
    comment: types.string,
    status: types.string,
    rufreeUser: types.maybeNull(types.string),
    rufreeUserDetail: types.maybeNull(RufreeModel),
    // inspection: types.number,
    inspections: types.array(types.string),
    inspectionsDetail: types.array(InspectionSimpleModel),
    pmUser: types.number,
    pm_user_detail: PMUserModel,
    managerName: types.string,
    projectGroup: types.string,
    contractComments: types.array(ContractCommentModel),
    approveRequester: types.maybeNull(types.integer),
    contractType: types.string
  })
  .views(self => ({}))
  .actions(self => ({
    setStatus(value: string) {
      self.status = value;
    },
  }))
  .actions(self => {
    // 대기 이후의 status 변경
    const changeStatusPreRequest = flow(function* () {
      try {
        const { data }: { data: RufreeContractResponse } = yield axios.put(
          `/rufreeContract/${self.contractId}/post_request`,
          {
            "status":self.status,
          }
        );

        self.status = data.status;
      } catch (e) {
        throw e;
      }
    });


    const requestApprove = flow(function* () {
      try {
        const { data }: { data: RufreeContractResponse } = yield axios.put(
          `/rufreeContract/${self.contractId}/request`
        );
        self.status = data.status;
      } catch (e) {
        throw e;
      }
    });

    const cancelRequestApprove = flow(function* () {
      try {
        const { data }: { data: RufreeContractResponse } = yield axios.put(
          `/rufreeContract/${self.contractId}/cancel`
        );
        self.status = data.status;
      } catch (e) {
        throw e;
      }
    });

    const approve = flow(function* () {
      try {
        const { data }: { data: RufreeContractResponse } = yield axios.put(
          `/rufreeContract/${self.contractId}/approve`
        );
        self.status = data.status;
      } catch (e) {
        throw e;
      }
    });

    const reject = flow(function* () {
      try {
        const { data }: { data: RufreeContractResponse } = yield axios.put(
          `/rufreeContract/${self.contractId}/reject`
        );
        self.status = data.status;
      } catch (e) {
        throw e;
      }
    });


    const remove = flow(function* () {
      yield axios.delete(
        `/rufreeContract/${self.contractId}`
      );
    });

    const fetchComments = flow(function* () {
      try {
        const { data }: { data: CommentResponse[] } = yield axios.get(
          `/rufreeContract/${self.contractId}/comments`);

        const comments = data.map(x => ContractCommentModel.create({
          id: x.id,
          author: x.author || -1,
          authorName: x.author_name || "",
          createdAt: x.created_at,
          updatedAt: x.updated_at,
          content: x.content
        }));

        self.contractComments.replace(comments.reverse());
      } catch (e) {
        throw e;
      }
    });

    const addComment = flow(function* (comment: string) {
      try {
        yield axios.post(
          `/rufreeContract/${self.contractId}/addComments`,
          {
            content: comment
          }
        );
      } catch (e) {
        throw e;
      }
    });

    const updateComment = flow(function* (id: number, comment: string) {
      try {
        yield axios.patch(
          `/rufreeContract/${self.contractId}/updateComments`,
          {
            id: id,
            content: comment
          }
        );
      } catch (e) {
        throw e;
      }
    });

    const deleteComment = flow(function* (id: number) {
      try {
        yield axios.delete(
          `/rufreeContract/${self.contractId}/deleteComments`,
          {
            data: { id: id }
          }
        );
      } catch (e) {
        throw e;
      }
    });



    return {
      changeStatusPreRequest,
      requestApprove,
      cancelRequestApprove,
      approve,
      reject,
      remove,
      fetchComments,
      addComment,
      updateComment,
      deleteComment
    };
  });
type RufreeContractRequestType = typeof RufreeContractRequestModel.Type;

export interface RufreeContractRequest extends RufreeContractRequestType {
}

