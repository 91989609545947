import React, { Component } from "react";
import styled from "styled-components/macro";
import { RouteComponentProps, Redirect } from "react-router";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import {
  Spinner,
  H3,
  Button,
  Intent,
  NonIdealState,
} from "@blueprintjs/core";

import { AppStore } from "../../../store/AppStore";
import TaskBoard from "../../organisms/TaskManager/TaskBoard";
import TaskBulletinBoard from "../../organisms/TaskManager/TaskBulletinBoard";
import TaskDetailPopup from "../../organisms/TaskManager/TaskDetailPopup";
import ArchivedTaskPopup from "../../organisms/TaskManager/ArchivedTaskPopup";
import TaskStatusAggregationView from "../../organisms/TaskManager/TaskStatusAggregationView";
import TaskSearchAndFilterView from "../../organisms/TaskManager/TaskSearchAndFilterView";
import TaskMultiSelectActionView from "../../organisms/TaskManager/TaskMultiSelectActionView";
import { Task } from "../../../store/models/Task";

// layout - container
const TaskMgrContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

// layout - section
const TaskMgrHeaderSection = styled.div`
  min-height: 120px;
  padding: 20px;
`;

const TaskMgrContentSection = styled.div`
  height: calc(100% - 120px);
  display: flex;
`;

const TaskMgrHeaderTopSection = styled.div`
  display: flex;
`;

const TaskMgrHeaderBottomSection = styled.div`
  display: flex;
`;

const TaskMgrContentLeftSection = styled.div`
  width: 320px;
  height: 100%;
  padding-left: 20px;
`;

const TaskMgrContentMainSection = styled.div`
  width: 100%;
  overflow: auto;
`;

// layout - views
const BreadcrumbView = styled.div`
`;

const TaskBulletinBoardContainer = styled.div`
  box-sizing: border-box;
  height: calc(100% - 60px)
`;

const ArchivedTaskContainer = styled.div`
  box-sizing: border-box;
  margin: 5px 0px;
  padding: 5px 0px;
`;

// layout - widgets
const Breadcrumb = styled(H3)`
`;

interface PageParams {
  projectGroupId: string;
}

interface TaskManagerPageProps extends RouteComponentProps<PageParams> {}

interface InjectedProps extends TaskManagerPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class TaskManagerPage extends Component<TaskManagerPageProps> {
  @observable readyToShow = false;
  @observable isOpenTaskDetailPopup = false;
  @observable isOpenArchivedTaskPopup = false;

  @observable selectedTaskId = "";

  @observable selectedTask: Task | null = null;
  @observable test = "";

  get injected() {
    return this.props as InjectedProps;
  }

  async componentDidMount() {
    try {
      // 보드 정보 가져오기
      const { projectGroupId } = this.props.match.params;
      await this.injected.appStore.taskManagerStore.fetchTaskBoard(projectGroupId);
      await this.injected.appStore.taskManagerStore.fetchTaskBulletin(projectGroupId);

      // 권한체크
      this.checkPermission();

      const query = new URLSearchParams(this.props.location.search);
      const taskId = query.get("tk")
      if(taskId){
        this.handleTaskDetailPopupOpen(String(taskId));
      }
    } catch (e) {
    } finally {
      this.readyToShow = true;
    }
  }

  checkPermission = () => {
    const { email } = this.injected.appStore.userStore;

    const { currentBoard: board } = this.injected.appStore.taskManagerStore;
    if (board && board.members.filter(member => member.email === email).length === 0 && !email.endsWith("@seeso.kr")) {
      alert("접근 권한이 없습니다");
      this.props.history.push(`/`);
    }
  };

  handleArchivedTaskPopupOpen = () => {
    this.isOpenArchivedTaskPopup = true;
  };
  handleArchivedTaskPopupClose = () => {
    this.isOpenArchivedTaskPopup = false;
  };

  handleOnCardClick = (e: any, cardId: string) => {
    if(e.shiftKey) {
      this.injected.appStore.taskManagerStore.addMultiSelectTaskId(Number(cardId));
    } else {
      this.handleTaskDetailPopupOpen(cardId);
    }
  };
  handleTaskDetailPopupOpen = (cardId: string) => {
    this.selectedTaskId = cardId;
    this.isOpenTaskDetailPopup = true;
  };
  handleTaskDetailPopupClose = async () => {
    this.selectedTaskId = "";
    this.isOpenTaskDetailPopup = false;
    await this.injected.appStore.taskManagerStore.fetchTaskBoard(this.props.match.params.projectGroupId);
    // await this.injected.appStore.taskManagerStore.fetchTaskBulletin(this.props.match.params.projectGroupId);
  };


  render() {

    const { isLoggedIn } = this.injected.appStore.userStore;

    if (!isLoggedIn) {
      const { projectGroupId } = this.props.match.params;
      return <Redirect to={"/login?next=/taskmgr/"+projectGroupId} />;
    }

    if (!this.readyToShow) {
      return <Spinner/>;
    }

    const { currentBoard: board } = this.injected.appStore.taskManagerStore;

    if (!board) {
      return (
        <div style={{paddingTop:'100px'}}>
          <NonIdealState
            icon={'search'}
            title={'보드 정보 없음'}
            description={'프로젝트 보드 정보가 없습니다. 유효하지 않은 프로젝트이거나, 오래된 프로젝트일 수 있습니다. 관리자에 문의바랍니다.'}
          />
        </div>
      );
    } else {
      return (
        <TaskMgrContainer>
          <TaskMgrHeaderSection>
            <TaskMgrHeaderTopSection>
              <BreadcrumbView>
                <Breadcrumb>프로젝트그룹 > {board.projectGroupName} > TaskManager</Breadcrumb>
              </BreadcrumbView>
            </TaskMgrHeaderTopSection>
            <TaskMgrHeaderBottomSection>
              <TaskStatusAggregationView />
              {this.injected.appStore.taskManagerStore.taskMultiSelect.task_ids.length > 0 ? (
                <TaskMultiSelectActionView />
              ) : (
                <TaskSearchAndFilterView />
              )}
            </TaskMgrHeaderBottomSection>
          </TaskMgrHeaderSection>
          <TaskMgrContentSection>
            <TaskMgrContentLeftSection>
              <TaskBulletinBoardContainer>
                <TaskBulletinBoard />
              </TaskBulletinBoardContainer>
              <ArchivedTaskContainer>
                <Button
                  fill={true}
                  minimal={true}
                  text={"보관된 Task"}
                  alignText={'left'}
                  icon="trash"
                  intent={Intent.WARNING}
                  onClick={this.handleArchivedTaskPopupOpen}
                />
                <ArchivedTaskPopup
                  isOpen={this.isOpenArchivedTaskPopup}
                  onClose={this.handleArchivedTaskPopupClose}
                />
              </ArchivedTaskContainer>
            </TaskMgrContentLeftSection>
            <TaskMgrContentMainSection>
              <TaskBoard
                onCardClick={this.handleOnCardClick}
              />
            </TaskMgrContentMainSection>
          </TaskMgrContentSection>

          <TaskDetailPopup
            idTask={this.selectedTaskId}
            isOpen={this.isOpenTaskDetailPopup}
            onClose={this.handleTaskDetailPopupClose}
            viewMode = {false}
          />
        </TaskMgrContainer>
      );
    }
  }
}

export default TaskManagerPage;