import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { H3, Tab, Tabs, TabId, Classes } from "@blueprintjs/core";
import { observable } from "mobx";
import { RouteComponentProps } from "react-router";
import ContractListPanel from "../../panels/ContractListPanel/ContractListPanel";
import ContractRequestListPanel from "../../panels/ContractListPanel/ContractRequestListPanel";
import ContractTemplateListPanel from "../../panels/ContractListPanel/ContractTemplateListPanel";
import LargeContainer from "../../atoms/LargeContainer/LargeContainer";

interface ContractPageProps extends RouteComponentProps {}

interface InjectedProps extends ContractPageProps {
  appStore: AppStore;
}



@inject("appStore")
@observer
class ContractPage extends Component<ContractPageProps> {
  @observable currentTab: TabId = "request";
  @observable isSupportPM = false;

  get injected() {
    return this.props as InjectedProps;
  }

  get calculateStore() {
    return this.injected.appStore.calculateStore;
  }

  async componentDidMount() {
    const tabId = this.props.location.search.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function(str, key, value) {
        return (key === 'tab') ? value : '';
      }
    );
    this.currentTab = tabId ? tabId : this.currentTab;

    await this.injected.appStore.pmUserStore.fetchPMUsers();
    const { isLoggedIn, email, userId, isPM } = this.injected.appStore.userStore;

    if(isPM && email) {
      const pmUser = await this.injected.appStore.pmUserStore.pmByEmail(email);
      if(pmUser) {
        this.isSupportPM = pmUser.is_support;
      }
    }
  }

  handleChangeTab = (navbarTabId: TabId) => {
    this.currentTab = navbarTabId;
  };

  render() {
    const { isPM } = this.injected.appStore.userStore;

    return (
      <LargeContainer>
        <H3>계약</H3>
        {
          isPM ? (
            <Tabs id="TabsComtract" onChange={this.handleChangeTab} selectedTabId={this.currentTab} renderActiveTabPanelOnly={true} >
              <Tab id="request" title="계약요청" panel={<ContractRequestListPanel {...this.props} isSupportPM={this.isSupportPM} />} />
              <Tab id="contract" title="계약서" panel={<ContractListPanel {...this.props} isSupportPM={this.isSupportPM} />} />
              <Tab id="template" title="템플릿" panel={<ContractTemplateListPanel {...this.props} isSupportPM={this.isSupportPM} />} />
            </Tabs>
          ) : (
            <ContractListPanel {...this.props} isSupportPM={this.isSupportPM} />
          )
        }
        

      </LargeContainer>
    );
  }
}

export default ContractPage;
