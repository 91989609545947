import React, {useEffect,  useState } from "react";

import { SprintPopupWrapper as Wrapper } from './_wrapper';


export interface ISprintPopupValue {
  sprintNumber: number; // -1: 새 스프린트, 0: PRE스프린트, 1~20: 스프린트
  days: number;
  perPerson?: boolean;
}

export interface ISprintPopup extends ISprintPopupValue {
  isEdit?: boolean;
  isServiceSprint?: boolean;
  onClose: () => void;
  onConfirm: (values: ISprintPopupValue) => void;
  onDelete: () => void;
}

export const SprintPopup: React.FC<ISprintPopup> = (props) => {
  const [ startSprint, setStartSprint ] = useState(props.sprintNumber || -1);
  const [ endSprint, setEndSprint ] = useState(props.sprintNumber || -1);
  const [ days, setDays ] = useState(props.days);

  return (
    <Wrapper>
      <div className='body'>
        {
          props.sprintNumber > 0 &&
            <span className='sprint-number'>{ `S${props.sprintNumber}` }</span>
        }
        <h5>스프린트 { props.isEdit ? props.isServiceSprint ? '삭제' : '수정' : '추가'}</h5>

        {
          !props.isEdit &&
            <div>
              <label>스프린트 기간</label>
              <input
                type="number"
                placeholder={ `숫자 입력`}
                value={ startSprint > 0 ? startSprint : '' }
                onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                  setStartSprint(parseInt(e.target.value));
                  setEndSprint(parseInt(e.target.value));
                } } />
              <span className='input-seperator'>~</span>
              <input
                type="number"
                placeholder={ `숫자 입력`}
                value={ endSprint > 0 ? endSprint : '' }
                onChange={ (e: React.ChangeEvent<HTMLInputElement>) => setEndSprint(parseInt(e.target.value)) } />
            </div>
        }
        
        {
          !props.isServiceSprint &&
            <div>
              <label>스프린트 운영 일수</label>
              
              <input
                type="checkbox"
                id="14days"
                name="14days"
                checked={ days === 14 ? true : false }
                onChange={ (e: React.ChangeEvent<HTMLInputElement>) => e.target.checked && setDays(14) } />
              <label htmlFor="14days">14일</label>
              <input
                type="checkbox"
                id="7days"
                name="7days"
                checked={ days === 7 ? true : false }
                onChange={ (e: React.ChangeEvent<HTMLInputElement>) => e.target.checked && setDays(7) } />
              <label htmlFor="7days">7일</label>
            </div>
        }
      </div>
      

      {
        props.isServiceSprint ?
          <div className='popup-footer'>
            
            <button
              onClick={ props.onClose }
            >취소</button>

            {
              props.isEdit ?
              <button
                className='delete'
                onClick={ props.onDelete }
                style={{ left: '299px' }}
                >삭제</button>
              :
              <button
                className='edit'
                onClick={ () => {
                  if(startSprint < 0) return;

                  for (let sprintNumber = startSprint; sprintNumber <= endSprint; sprintNumber++) {
                    const values: ISprintPopupValue = {
                      sprintNumber: sprintNumber,
                      days: days,
                      perPerson: props.perPerson
                    };
                    props.onConfirm(values);
                    props.onClose();
                  }
                } }>
                  { props.isEdit ? '수정' : '추가'}
              </button>
            }
            
          </div>
        :
          <div className='popup-footer'>
            {
              props.isEdit &&
                <button
                  className='delete'
                  onClick={ props.onDelete }
                  >삭제</button>
            }
            <button
              onClick={ props.onClose }
            >취소</button>
            <button
              className='edit'
              onClick={ () => {
                if(startSprint < 0) return;

                // console.log(days)
                for (let sprintNumber = startSprint; sprintNumber <= endSprint; sprintNumber++) {
                  const values: ISprintPopupValue = {
                    sprintNumber: sprintNumber,
                    days: days,
                    perPerson: props.perPerson
                  };
                  props.onConfirm(values);
                }
                props.onClose();
              } }>
                { props.isEdit ? '수정' : '추가'}
            </button>
          </div>
      }
    </Wrapper>
  )
}
