import React, { Component, ChangeEvent, KeyboardEvent } from "react";
import styled from "styled-components/macro";
import moment from "moment";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { observable } from "mobx";
// import { RouteComponentProps } from "react-router";
import ClientCalculateRequestListPanel from "../../panels/CalculateListPanel/ClientCalculateRequestListPanel";
import ClientCalculateRow from "../../molecules/CalculateRow/ClientCalculateRow";
import ClientCalculateFormPopup from "../../molecules/CalculatePopup/ClientCalculateFormPopup";
import Paginator from "../../molecules/Paginator/Paginator";
import { FormMode } from "../../../types/formMode";
import {
  Icon,
  HTMLTable, 
  Classes, 
  Button, 
  Intent, 
  InputGroup,
  HTMLSelect,
  IOptionProps,
  Spinner,
  Switch
} from "@blueprintjs/core";
import { 
  ToolsRow,
  InputContainer,
  VATRow
} from "../../atoms/CalculateTable/CalculateTable";

const Wrapper = styled.div`
  th {
    padding: 5px !important;
  }
`;
const OrderByIcon = styled(Icon)`
  float: right;
  margin-top: 3px;
`;


const SERVICESTARTMONTH = moment("20200101", "YYYYMMDD");

// interface ClientCalculateListPanelProps extends RouteComponentProps {}

interface ClientCalculateListPanelProps {
  isSupportPM: boolean;
}

interface InjectedProps extends ClientCalculateListPanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ClientCalculateListPanel extends Component<ClientCalculateListPanelProps> {
  @observable readyToShow = false;
  @observable statusFilter = "";
  @observable searchText = "";
  @observable isFormPopupOpen = false;
  @observable order_by = 'date_issue';
  @observable order_asc = false;
  @observable includeVat = true;

  get injected() {
    return this.props as InjectedProps;
  }

  get calculateStore() {
    return this.injected.appStore.calculateStore;
  }

  handleOrderBy = async (by: string, asc: boolean=false) => {
    this.order_asc = this.order_by === by ? asc : false;
    this.order_by = by;

    await this.handleSearch();
  }

  handleSearch = async () => {
    this.readyToShow = false;

    await this.calculateStore.fetchClientCalculate(
      this.order_by,
      this.order_asc,
      this.statusFilter,
      this.searchText
    );

    this.readyToShow = true;
  };
  handleSearchText = (e: any) => {
    this.searchText = e.target.value;
  };
  handleSearchTextKeyDown = async(event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      await this.handleSearch();
    }
  }
  handleFilterSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    this.statusFilter = e.currentTarget.value;
  };
  handleFormPopupOpen = () => {
    this.isFormPopupOpen = true;
  };
  handleFormPopupClose = () => {
    this.isFormPopupOpen = false;

    this.handleSearch();
  };

  async componentDidMount() {
    this.calculateStore.setCurentPage(1);
    this.calculateStore.setPageGroup(1);

    await this.handleSearch();
  }

  render() {
    const { 
      clientCalculates,
      pageGroup,
      totalPages,
      currentPage,
      setPageGroup,
      setCurentPage
    } = this.calculateStore;

    const statusOptions: IOptionProps[] = [
      {label: '전체', value: ''},
      {label: '입금완료', value: 'deposit'},
      {label: '미입금', value: 'notdeposit'},
      {label: '계약서누락', value: 'nocontract'}
    ];
    const OrderIcon = <OrderByIcon
      icon={this.order_asc ? 'arrow-up' : 'arrow-down'}
      iconSize={12}
      intent={Intent.PRIMARY} />;

    return (
      <Wrapper>
        {this.props.isSupportPM && (
          <>
            <ToolsRow>
              <Button
                text="새 입금"
                className="bp3-icon-add"
                onClick={this.handleFormPopupOpen}
                intent={Intent.PRIMARY}
              />
            </ToolsRow>

            <ClientCalculateRequestListPanel />
          </>
        )}

        <ToolsRow>

          <HTMLSelect
            value={this.statusFilter}
            onChange={this.handleFilterSelect}
            options={statusOptions}
          />

          <InputContainer>
            <InputGroup
              placeholder="계약번호(or 명)/프로젝트번호(or 명)/클라이언트"
              value={this.searchText}
              onChange={this.handleSearchText}
              onKeyDown={this.handleSearchTextKeyDown}
            />
          </InputContainer>

          <Button
            icon="search"
            text="검색"
            intent={Intent.PRIMARY}
            onClick={this.handleSearch}
          />

        </ToolsRow>

        <VATRow className="bp3-text-small">
          <Switch
            checked={this.includeVat}
            labelElement={"VAT 포함"}
            onChange={() => {
              this.includeVat = !this.includeVat;
            }} />
        </VATRow>
        <HTMLTable
          className={Classes.SMALL}
          striped={true}
          interactive={true}
          style={{ width: "100%", tableLayout: "fixed" }}
        >
          <thead>
            <tr>
              <th
                style={{width: '100px', cursor: 'pointer', color: '#106ba3'}}
                onClick={() => {this.handleOrderBy('contract_id', !this.order_asc)}}
              >
                계약번호
                { this.order_by === 'contract_id' && OrderIcon }
              </th>
              <th>계약명</th>
              <th style={{width: '100px'}}>프로젝트그룹</th>
              <th>클라이언트명</th>
              <th
                style={{width: '120px', cursor: 'pointer', color: '#106ba3'}}
                onClick={() => {this.handleOrderBy('date_issue', !this.order_asc)}}
              >
                세금계산서발급일
                { this.order_by === 'date_issue' && OrderIcon }
              </th>
              <th style={{width: '120px'}}>입금요청금액</th>
              <th
                style={{width: '120px', cursor: 'pointer', color: '#106ba3'}}
                onClick={() => {this.handleOrderBy('date_last_deposit', !this.order_asc)}}
              >
                최종입금일
                { this.order_by === 'date_last_deposit' && OrderIcon }
              </th>
              <th style={{width: '120px'}}>총 입금액</th>
              <th>비고</th>
              <th style={{width: '150px'}}></th>
            </tr>
          </thead>

          <tbody>
            {
              this.readyToShow ? (
                clientCalculates
                  .filter(x => x.dateIssue)
                  .map((calculate, index) => (
                    <ClientCalculateRow
                      key={index}
                      includeVat={this.includeVat}
                      editable={this.props.isSupportPM}
                      calculate={calculate}
                      onSearch={this.handleSearch} />
                ))
              ) : (
                <tr>
                  <td colSpan={9}>
                    <Spinner />
                  </td>
                </tr>
              )
            }
          </tbody>
        </HTMLTable>

        <Paginator
          pageGroup={pageGroup}
          totalPages={totalPages}
          currentPage={currentPage}
          setPageGroup={setPageGroup}
          setCurentPage={setCurentPage}
          onFetch={this.handleSearch}
        />



        <ClientCalculateFormPopup
          mode={FormMode.Create}
          onClose={this.handleFormPopupClose}
          isOpened={this.isFormPopupOpen} />
      </Wrapper>
    );
  }
}

export default ClientCalculateListPanel;
