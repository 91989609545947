import React, { FC } from "react";
import { Colors, Position, Text, Tooltip } from "@blueprintjs/core";
import moment from "moment";

import styled from "styled-components/macro";


const Wrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 20px;

  background-color: rgba(138,155,168,.15);
  border-radius: 8px;
`;

const Row = styled.div`
  display: flex;

  padding: 5px 0px;
  font-size: 12px;
  & > div:first-child {
    color: ${ Colors.DARK_GRAY5 };
    font-weight: bold;
    width: 120px;
  }
`;
const HeaderRow = styled(Row)`
  margin-bottom: 10px;
  font-weight: bold;
`;

const Info = styled.div`
  font-size: 11px;
  margin-top: 30px;
`;

const HeaderRowComponent: FC = () => {
  return (
    <HeaderRow>
      <div>(평균)소요시간</div>
      <div></div>
    </HeaderRow>
  )
}

const ProjectConversionDurationTable: FC<{
  className?: string;
  count: number;
  project: number;
  inspection: number;
  projectgroup: number;
}> = ({ count, className, project, inspection, projectgroup }) => {
  console.log(moment.duration(projectgroup).days());
  console.log(moment.duration(projectgroup).hours());

  return (
    <Wrapper className={ className }>

      <HeaderRowComponent />

      <Row>
        <div>영업상담</div>
        <div>
          { moment.duration(project).days() > 0 ? `${moment.duration(project).days()}일 ` : '' }
          { `${moment.duration(project).hours()}시간` }
        </div>
      </Row>

      <Row>
        <div>검수보고서(최초발행일)</div>
        <div>
          { moment.duration(inspection).days() > 0 ? `${moment.duration(inspection).days()}일 ` : '' }
          { `${moment.duration(inspection).hours()}시간` }
        </div>
      </Row>

      <Row>
        <div>킥오프(프로젝트그룹)</div>
        <div>
          { moment.duration(projectgroup).days() > 0 ? `${moment.duration(projectgroup).days()}일 ` : '' }
          { `${moment.duration(projectgroup).hours()}시간` }
        </div>
      </Row>

      <Info>
        * (평균)소요시간은 의뢰서 > 영업상담 > 검수보고서(최초발행일) > 킥오프(프로젝트그룹) 날짜 정보가 <strong>모두 있는 아이템들</strong>의 평균값입니다.
        (총 { count } 건)
      </Info>
      
    </Wrapper>
  );
}

export default ProjectConversionDurationTable;