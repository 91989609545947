import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import {
  Row,
  Column,
  Label,
  Text,
  SecondLabel,
  Title,
  CostRow
} from "../InspectionCard/InspectionCard";
import { SprintReport } from "../../../store/models/SprintReport";
import { ProjectGroup } from "../../../store/models/ProjectGroup";
import moment from "moment";
import { Spinner } from "@blueprintjs/core";
import { ScheduleSprint } from "../../../store/models/ScheduleSprint";
import { computed } from "mobx";
import { Inspection } from "../../../store/models/Inspection";

const Wrapper = styled.div``;

const ItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 400px;
  margin-bottom: 2px;
`;

interface SprintReportCardProps {
  sprintReport: SprintReport;
}

interface InjectedProps extends SprintReportCardProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class SprintReportCard extends Component<SprintReportCardProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get totalPrice() {
    // const { rufreeWage, seesoServiceCost } = this.props.sprintReport;

    // if (rufreeWage === null && seesoServiceCost === null) {
    //   return "알 수 없음";
    // } else {
    //   return rufreeWage! + seesoServiceCost!;
    // }
    return 0;
  }

  render() {
    const { sprintReport: report } = this.props;
    const projectGroup = report.projectGroup as ProjectGroup | null;
    // const sprintInfo = report.sprintInfo as ScheduleSprint | null;
    // const inspection = report.inspection as Inspection | null;

    // if (!projectGroup || !sprintInfo || !inspection) {
    //   return <Spinner />;
    // }

    // const dateStart = moment(sprintInfo.dateStart).format("YYYY/MM/DD");
    // const dateEnd = moment(sprintInfo.dateEnd).format("YYYY/MM/DD");

    // const { seesoServices } = report;

    return (
      <Wrapper>
        <Row>
          <Title> 스프린트 보고서</Title>
        </Row>
        <Row>
          <Column>
            <Label>진행일정</Label>
            <Text>
            </Text>
          </Column>
        </Row>
        <Row>
          <Column>
            <Label>종합의견</Label>
            <Text>{}</Text>
          </Column>
        </Row>

        <CostRow>
          <Column>
            <Label>상세비용</Label>
            <SecondLabel>알유프리 리소스</SecondLabel>
            <ItemRow>
              <Text>{}</Text>
              <Text>{}</Text>
              <Text>{}</Text>
              <Text>
                원
              </Text>
            </ItemRow>
          </Column>
        </CostRow>

        <CostRow>
          <Column>
            <SecondLabel>시소서비스</SecondLabel>
            {
            // seesoServices && seesoServices.length ? (
            //   seesoServices.map(seesoService => {
            //     const sprintDays = inspection.sprintDays[report.sprint];

            //     return (
            //       <ItemRow>
            //         <Text>{seesoService.service}</Text>
            //         <Text>
            //           {seesoService
            //             .costWithTaxBySprintDaysRate({
            //               sprintDays,
            //               sprintIndex: report.sprint
            //             })
            //             .toLocaleString("en-US")}
            //           원
            //         </Text>
            //       </ItemRow>
            //     );
            //   })
            // ) : (
            //   <Text>없음</Text>
            // )
            }
          </Column>
        </CostRow>

        <Row>
          <Column>
            <Label>총 금액 (VAT포함)</Label>
            <Text>{this.totalPrice.toLocaleString("en-US")}원 </Text>
          </Column>
        </Row>
      </Wrapper>
    );
  }
}

export default SprintReportCard;
