import React, { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { Icon, Button, H6 } from "@blueprintjs/core";


export const SectionTitleWrapper = styled.div`
display: flex;
`;
export const TitleWrapper = styled.h5`
`;
export const SectionRightWrapper = styled.span`
  margin-left: auto;
  font-size: 12px;
  cursor: pointer;
`;

export const ScheduleTitle = styled.span`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;


// for EtcPanel
export const EtcLinkWrapper = styled(Link)`
  &:hover {
    text-decoration: none;
    color: #182026;
  }
  color: #182026;
`;
export const EtcItemWrapper = styled.div`
  display: flex;
  font-size: 12px;
  cursor: pointer;
  margin-bottom: 5px;
  &:hover {
    background-color: white;
    color: black;
  }
`;
export const EtcStatus = styled.div`
  width: 60px;
  color: #5642A6;
`;
export const EtcTitle = styled.div`
  width: 70%;
`;
export const EtcDate = styled.div`
  width: 45px;
`;

export const EtcSection = styled.div`
  width: 100%;
`;
export const EtcSectionTitle = styled.div`
  display: flex;
  flex-direction: row;
`;
export const EtcSectionBody = styled.div`
  // height: calc(100% - 26px);
  // min-height: 200px;
  // max-height: 400px;
  height: 350px;
  overflow-y: auto;
  padding: 5px 10px;
  background: #f1f1f1;
`;
export const H6Wrapper = styled(H6)`
  margin-right: 10px;
`;
// for EtcPanel END

