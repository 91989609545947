import { types, flow } from "mobx-state-tree";
import axios from "axios";
import { STATUS, IPartner, PartnerModel } from "./models/Partner";

const PAGE_SIZE = 25;
export const PartnerStoreModel = types
  .model("PartnerStore", {
    partner: types.maybeNull(PartnerModel),
    partners: types.array(PartnerModel),

    // For request status
    isFetching: types.optional(types.boolean, false),
    // For paginator
    currentPage: types.optional(types.number, 1),
    pageGroup: types.optional(types.number, 1),
    totalPages: types.optional(types.number, 1),
  })
  .views(self => ({
    get sortedPartners() {
      return self.partners.sort(
        (a, b) =>
          new Date(b.created).getTime() -
          new Date(a.created).getTime()
      );
    }
  }))
  .actions(self => ({
    setCurentPage(value: number) {
      self.currentPage = value;
    },
    setPageGroup(value: number) {
      self.pageGroup = value;
    }
  }))
  .actions(self => {
    const fetchPartners = flow(function*(
      search: string='',
      status: string=STATUS.ALL,
    ) {
      try {
        self.isFetching = true;
        const { data }: { data: ListResponse } = yield axios.get(
          "/admin/partners/",
          {
            params: Object.assign({
                search: search,
                page_size: PAGE_SIZE,
                page: self.currentPage
              },
              status !== STATUS.ALL ? { status: status } : undefined
            )
          }
        );

        const partners = data.results.map(result =>
          PartnerModel.create(result)
        );

        self.totalPages = Math.round(data.count / PAGE_SIZE);
        self.partners.replace(partners.reverse());
        self.isFetching = false;
      } catch (e) {
        self.isFetching = false;
        throw e;
      }
    });
    const fetchPartner = flow(function*(
      code: string
    ) {
      try {
        const { data }: { data: IPartner } = yield axios.get(
          `/admin/partners/${code}/`
        );

        self.partner = PartnerModel.create(data);
      } catch (e) {
        throw e;
      }
    });

    return {
      fetchPartners,
      fetchPartner
    };
  });

type PartnerStoreType = typeof PartnerStoreModel.Type;
export interface PartnerStore extends PartnerStoreType {}


export interface ListResponse {
  count: number;
  next: number | null;
  previous: number | null;
  results: IPartner[]
}