import { types } from "mobx-state-tree";

export const ConsultAdminTypeFormModel = types
  .model("ConsultAdminTypeForm", {
    checked: types.optional(types.boolean, false),
    etc: types.optional(types.boolean, false),
    text: types.optional(types.string, "")
  })
  .actions(self => ({
    setChecked(value: boolean) {
      self.checked = value;
    },
    setEtc(value: boolean) {
      self.etc = value;
    },
    setText(value: string) {
      self.text = value;
    }
  }));

type ConsultAdminTypeFormType = typeof ConsultAdminTypeFormModel.Type;
export interface ConsultAdminTypeForm extends ConsultAdminTypeFormType {}
