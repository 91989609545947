import React, { Component, ChangeEvent, KeyboardEvent } from "react";

import { computed, observable } from "mobx";
import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import { Button, InputGroup, Intent } from "@blueprintjs/core";
import { FocusStyleManager } from "@blueprintjs/core";

import { AppStore } from "../../../store/AppStore";
import { AppToaster } from "../AppToaster/AppToaster";

import TaskBulletin from "../../molecules/TaskManager/TaskBulletin";

import {
  DragDropContext, DropResult, DraggableLocation,
  Droppable, DroppableProvided, DroppableStateSnapshot,
  Draggable, DraggableProvided, DraggableStateSnapshot
} from "react-beautiful-dnd";

const Container = styled.div`
  width: 300px;
  height: 100%;
  overflow: auto;

  margin-top: 10px;
  padding: 10px;

  border-radius: 5px;
  background-color: #fef9e7
`;

// 운영정보목록 추가 버튼
const AddTaskBulletinView = styled.div`
  margin-top: 10px;
`;

// 에디터
const TitleEditor = styled(InputGroup)`
  margin-top: 10px;
`;

interface BulletinBoardProps {
}

interface InjectedProps extends BulletinBoardProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class TaskBulletinBoard extends Component<BulletinBoardProps> {
  @observable showTitleEditor: boolean = false;
  @observable inputComponent: any = null;
  @observable title: string = '';

  @observable withScrollableColumns: boolean = false;
  @observable isCombineEnabled: boolean = false;
  @observable containerHeight: string = '';
  @observable useClone: boolean = false;

  get injected() {
    return this.props as InjectedProps;
  }
  
  @computed
  get store() {
    return this.injected.appStore.taskManagerStore;
  }
  @computed
  get board() {
    return this.store.currentBoard;
  }  

  componentDidMount() {
    FocusStyleManager.onlyShowFocusOnTabs();
  }
  componentDidUpdate() {
    if(this.showTitleEditor && 
      this.inputComponent && 
      this.inputComponent !== document.activeElement
    ) {
      this.inputComponent.focus();
      this.inputComponent.select();
      this.inputComponent.onkeypress = this.handleOnKeyPress;
    }        
  }  

  handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.title = e.target.value;
  };
  handleOnKeyPress = async(event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      if(this.title.trim() !== '') {
        this.handleSubmit();
      }
    }
  }
  handleCancel = () => {
    this.title = '';
    this.showTitleEditor = false;
  }  
  handleSubmit = async () => {
    try {
      await this.store.postTaskBulletin(this.board ? this.board.taskBoardId : '', this.title);

      this.showTitleEditor = false;

      // 업데이트된 내용을 반영
      await this.store.fetchTaskBulletin(this.board ? this.board.projectGroup : '');
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  }

  onDragEnd = async (result: DropResult) => {
    // droppable 이 아닌곳일 경우 리턴
    if (!result.destination) {
      return;
    }

    const source: DraggableLocation = result.source;
    const destination: DraggableLocation = result.destination;

    // 원래 자리에 drop 시켰을 경우 리턴
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    try {
      if(result.type === 'bulletin') {        
        // 1. bulletin 을 이동한다. 디버깅 시 result 를 콘솔에 출력해볼것
        await this.store.changeBulletinPos(source.index, destination.index);

        const bulletin_id = Number(result.draggableId.substring(2))
        await this.store.moveTaskBulletin(bulletin_id, destination.index);
      } else if(result.type === 'post') {
        // 2. post 이동을 처리한다.
        const src_bulletin_id = Number(source.droppableId.substring(2)) // droppableId = 'b-**' 형식이라서 **부분만 추출
        const dst_bulletin_id = Number(destination.droppableId.substring(2))

        await this.store.changeBulletinPostPos(src_bulletin_id, dst_bulletin_id, source.index, destination.index);           

        const src_post_id = Number(result.draggableId.substring(2))
        await this.store.moveTaskBulletinPost(src_post_id, dst_bulletin_id, destination.index);
      }

    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
      // 오류 발생 시 원래 DB의 내용을 반영한다
      this.store.fetchTaskBulletin(this.board ? this.board.projectGroup : '');
    }    
  }
  
  render() {
    const { sortedBulletins: bulletins } = this.store;
    const board = (
      <Droppable
        droppableId="board"
        type="bulletin"
        ignoreContainerClipping={Boolean(this.containerHeight)}
        isCombineEnabled={this.isCombineEnabled}
      >
        {(droppableProvided: DroppableProvided, droppableSnapshot: DroppableStateSnapshot) => (
          <div
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}
            style={{background: droppableSnapshot.isDraggingOver ? "lightgrey": ""}}
          >
            {bulletins.map((bulletin, index) => (
              <Draggable key={bulletin.id} draggableId={`b-${bulletin.id}`} index={index}>
                {(draggableProvided: DraggableProvided, draggableSnapshot: DraggableStateSnapshot) => (
                  <div 
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                    ref={draggableProvided.innerRef}
                  >
                    <TaskBulletin
                      id={bulletin.id}
                      index={index}
                      title={bulletin.title}
                      isScrollable={this.withScrollableColumns}
                      isCombineEnabled={this.isCombineEnabled}
                      useClone={this.useClone}
                    />
                  </div> 
                )}
              </Draggable>
            ))}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    );

    return (
      <Container>
        <DragDropContext onDragEnd={this.onDragEnd}>
          {board}
          {this.showTitleEditor ? (
            <TitleEditor
              inputRef={(input: any) => {this.inputComponent=input}}
              placeholder={'운영정보 목록 타이틀'}
              rightElement={
                <>
                <Button
                  text={'추가'}
                  small={true}
                  disabled={this.title.trim() === ''}
                  onClick={this.handleSubmit}
                />
                <Button
                  text={'X'}
                  minimal={true}
                  onClick={this.handleCancel}
                />
                </>
              }
              value={this.title}
              onChange={this.handleTitleChange}
            />
          ) : (
            <AddTaskBulletinView>
              <Button
                fill={true}
                minimal={true}
                icon={'plus'}
                alignText={'left'}
                text={'운영정보목록 추가'}
                onClick={() => {this.title = ''; this.showTitleEditor=true;}}
              />
            </AddTaskBulletinView>
          )}
        </DragDropContext>
      </Container>
    );
  }
}

export default TaskBulletinBoard;