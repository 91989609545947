import { types, flow } from "mobx-state-tree";
import axios from "axios";
import moment from 'moment';

import { ScheduleManagerFormModel } from './ScheduleManagerForm';
import { ScheduleManagerResponse } from "../../../types/scheduleManager";
import { mapSchedule } from "../../ScheduleManagerStore";
import { DashboardOfficeHourFormModel } from './DashboardOfficeHourForm';
import sortBy from "lodash/sortBy";

export const DashboardOfficeHourFormStoreModel = types
  .model("DashboardOfficeHourFormStore", {
    currentForm: types.optional(DashboardOfficeHourFormModel, {}),
    targetDates: types.optional(types.array(types.string), [])
  })
  .views(self => ({
    get myTodayOfficeHours() {
      let idx = self.targetDates.findIndex(x => x === moment().format("YYYYMMDD"));
      return idx > -1 ? sortBy(
        self.currentForm.schedules[idx], officehour => officehour.startAt
      ) : [];
    }
  }))
  .actions(self => {

    const initForm = () => {
      // 근무시간 폼 두개로 나오는 상황 대비하여 명시적 초기화 추가
      if(self.currentForm) {
        self.currentForm.clearSchedule();
      }
      
      self.currentForm = DashboardOfficeHourFormModel.create({
        schedules: [
          [], [], [], [], [], [], [],
          [], [], [], [], [], [], []
        ],  // 2주에 대해 월~일요일 모두 빈 값으로 초기화
        scheduleIdsTobeDeleted: []
      })

      self.targetDates.clear();
      for(let i=1; i<=14; i++) {
        self.targetDates.push(moment().isoWeekday(i).format("YYYYMMDD"))
      }
    };

    const fetchForm = flow(function*(creator: number) {
      try {
        // 모델 초기화
        initForm();

        // 전체 스케줄 가져오기 
        // TODO: 백엔드 연계하여 나의 이번주 일정만 가져오게 수정 필요
        const { data }: { data: ScheduleManagerResponse[] } = yield axios.get(
          `/schedule`,
          {
            params: {
              search: 'ofh '+creator,
            }
          }
        );
        
        // 전체 스케줄을 모델에 추가
        addSchedules(
          data.map(x => ScheduleManagerFormModel.create(mapSchedule(x))));
      } catch (e) {
        console.error("fetchForm error", e);
        throw e;
      }     
    });

    const addSchedules = (allSchedules: Array<any>) => {
      // 요일별로 쪼개서 추가한다
      for(let i=0; i<self.targetDates.length; i++) {

        // 해당 날짜의 일정 목록 얻기
        allSchedules.filter(
          schedule => moment(self.targetDates[i]).isSame(schedule.startAt, 'day')
        ).map(schedule => {

            // 개별 일정 추가
            self.currentForm.addSchedule(
              schedule.id,
              schedule.creator, 
              i,  // dayOfWeek
              schedule.startAt, 
              schedule.endAt, 
              schedule.title
            )
        });
      }      
    }

    const saveForm = async () => {
      try {
        self.currentForm.schedules.map(schedules => {
          schedules.map(async (schedule) => {
            if (schedule.id === 0) {
              // 새로 추가된 스케줄
              await postSchedule(schedule)
            } else {
              // 업데이트할 스케줄
              await putSchedule(schedule)
            }
          });
        });
    
        self.currentForm.scheduleIdsTobeDeleted.map(async (scheduleId) => {
          // 삭제할 스케줄
          await deleteSchedule(scheduleId)
        });
      } catch (e) {
        console.log("saveForm error", e);
        throw e;
      }
    };

    // 일정 추가
    const postSchedule = flow(function*(schedule: any) {
      try {
        const { data }: { data: ScheduleManagerResponse } = yield axios.post(
          `/schedule`,
          {
            category: schedule.category,
            start_at: schedule.startAt,
            end_at: schedule.endAt,
            all_day: false,
            title: schedule.title,
            creator: schedule.creator
          }
        );

      } catch (e) {
        console.log("postSchedule error", e);
        throw e;
      }
    });

    // 일정 수정
    const putSchedule = flow(function*(schedule: any) {
      try {
        const { data }: { data: ScheduleManagerResponse } = yield axios.put(
          `/schedule/${schedule.id}`,
          {
            id: schedule.id,
            category: schedule.category,
            start_at: schedule.startAt,
            end_at: schedule.endAt,
            all_day: false,
            title: schedule.title,
            creator: schedule.creator
          }
        );

      } catch (e) {
        console.log("putSchedule error", e);
        throw e;
      }
    }); 
    
    // 일정 삭제
    const deleteSchedule = flow(function*(scheduleId: number) {
      try {
        yield axios.delete(`/schedule/${scheduleId}`);
      } catch (e) {
        console.log("deleteSchedule error", e);
        throw e;
      }
    });    

    return {
      fetchForm,
      saveForm
    };
  });

type DashboardOfficeHourFormStoreType = typeof DashboardOfficeHourFormStoreModel.Type;
export interface DashboardOfficeHourFormStore extends DashboardOfficeHourFormStoreType {}