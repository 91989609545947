import React, { useState } from 'react';
import styled from "styled-components/macro";
import moment from 'moment';

import { IPartner, IComment } from '../../../../store/models/Partner';
import { Icon, Button, Intent, TextArea } from '@blueprintjs/core';

const Wrapper = styled.div`
  & {
    margin-top: 40px;
  }
  & .comment + .comment {
    border-top: 1px dashed #394B59;
    margin-top: 30px;
    padding-top: 7px;
  }
  & .comment-header {
    color: #0E5A8A;
    font-weight: bold;
    display: flex;
    // justify-content: space-between;
  }
  & .comment-date {
    color: #5C7080;
    font-size: 12px;
    margin-left: 30px;
    margin-right: 20px;
  }
  & .comment-comment {
    padding-left: 20px;
    font-size: 13px;
  }
  & .comment-form {
    // display: flex;
    .action-row {
      margin-top: 5px;
      display:flex;
      justify-content: flex-end;
      button {
        width: 80px;
      }
    }
  }
  & .comment {
    button {
      width: 24px;
    }
  }
`;
interface IProps {
  currentUserEmail: string;
  partner: IPartner;
  fetchPartner: (id: string) => void;
} 
const Comments = (props: IProps) => {
  const { currentUserEmail, partner, fetchPartner } = props;
  const [ newComment, setNewComment ] = useState('');
  const [ selectedComment, setSelectedComment ] = useState<IComment | null>(null);
  const [ editMode, setEditMode ] = useState<boolean>(false);
  const [ editComment, setEditComment ] = useState('');

  const formClear = () => {
    setSelectedComment(null);
    setEditComment('');
    setEditMode(false);

    setNewComment('');
  }

  return (
    <Wrapper>
      <h3>메모</h3>
      {
        editMode === false &&
          <div className='comment-form'>
            <div>
              <TextArea
                fill={ true }
                growVertically={ true }
                value={ newComment }
                onChange={ (e) => setNewComment(e.target.value) } />
            </div>
            <div className='action-row'>
              <Button
                intent={ Intent.PRIMARY }
                text='저장'
                small={ true }
                onClick={ async() => {
                  await partner.addComment(newComment);
                  await fetchPartner(partner.id+'');
                  formClear();
                } } />
            </div>
          </div>
      }
      
      <div style={{marginTop: '20px', padding: '0 20px' }}>
        {
          partner.comments.map(comment =>
            <div key={ comment.id } className="comment">
              <div className='comment-header'>
                <div>
                  <span>{ comment.user_name }</span>
                  <span className='comment-date'>{ moment(comment.submit_date).format('YYYY-MM-DD hh:mm:ss') }</span>
                  {
                    currentUserEmail === comment.user_email &&
                    <>
                      <Button
                        icon={ <Icon icon='annotation' iconSize={ 13 } /> }
                        minimal={ true }
                        small={ true }
                        onClick={ () => {
                          setSelectedComment(comment);
                          setEditComment(comment.comment);
                          setEditMode(true);
                        }} />
                      <Button
                        icon={ <Icon icon='trash' iconSize={ 13 } /> }
                        minimal={ true }
                        small={ true }
                        onClick={ async() => {
                          const isDeleting = window.confirm("코멘트를 삭제할까요?");
                          if(isDeleting) {
                            await comment.remove();
                            await fetchPartner(partner.id+'');
                          }
                      }} />
                    </>
                  }
                </div>
              </div>
              {
                editMode && selectedComment && selectedComment.id === comment.id ?
                <div className='comment-form'>
                  <div>
                  <TextArea
                    fill={ true }
                    growVertically={ true }
                    value={ editComment }
                    onChange={ (e) => setEditComment(e.target.value) } />
                  </div>
                  <div className='action-row'>
                    <Button
                      intent={ Intent.PRIMARY }
                      text='저장'
                      small= { true }
                      onClick={ async() => {
                        await comment.update(editComment);
                        await fetchPartner(partner.id+'');

                        formClear();
                      } } />
                    <Button
                      minimal={ true }
                      text='취소'
                      small= { true }
                      onClick={ async() => {
                        formClear();
                      } } />
                  </div>
                  
                  </div>
                  : <div className='comment-comment'>
                    { comment.comment.split('\n').map(line => <div>{line}</div>) }
                  </div>
              }
            </div>
          )
        }
      </div>

    </Wrapper>
  )
}
export default Comments;