import { types, flow } from "mobx-state-tree";
import axios from "axios";
import { BaseModel } from "./BaseModel";
import { SprintModel } from "./Sprint";
import { TaskModel } from "./Parttime";
import { RufreeCalculateResponse } from "../../types/Calculate";


export const RufreeCalculateModel = types
  .model("RufreeCalculate", {
    id: types.number,
    calculateId: types.maybeNull(types.string),
    sprintSequentialId: types.number,
    projectGroup: types.maybeNull(types.string),
    projectGroupName: types.maybeNull(types.string),
    clientContract: types.string,
    clientName: types.string,
    rufreeName: types.string,
    rufreePhone: types.string,
    managerName: types.string,
    rufreeContract: types.model({
      contractId: types.string,
      info: types.string,
      agentName: types.string,
      cpmsUrl: types.string,
    }),
    createdAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
    expectedPayment: types.number,
    commission: types.maybeNull(types.number),
    typeTax: types.maybeNull(types.string),
    payment: types.maybeNull(types.number),
    datePayment: types.maybeNull(types.string),
    sprint: types.maybeNull(SprintModel),
    parttimeTask: types.maybeNull(TaskModel),
    pmComment: types.maybeNull(types.string),
    comment: types.maybeNull(types.string)
  })
  .views(self => ({
    get tax() {
      if(self.typeTax) {
        return (self.typeTax === 'BU') ? 10 : -3.3;
      }
      return '';
    },
    get title() {
      if(self.sprint) {
        if(self.sprint.sprint === 0) {
          return 'PRE';
        }
        return `S${self.sprint.sprint}`;
      }
      return '';
    },
    get calculateType() {
      if(self.sprint) {
        return 'sprint';
      }
      return 'parttime';
    }
  }))
  .actions(self => {
    const pay = flow(function*(type_tax: string, payment: number, datePayment:string | null, comment: string) {
      try {
        const { data }: { data: RufreeCalculateResponse } = yield axios.post(
          `/calculate/rufree/${self.id}/pay`,
          {
            type_tax: type_tax,
            payment: payment,
            date_payment: datePayment,
            comment: comment
          }
        );
      } catch (e) {
        throw e;
      }
    })

    const cancelPay = flow(function*() {
      try {
        const { data }: { data: RufreeCalculateResponse } = yield axios.post(
          `/calculate/rufree/${self.id}/cancelPay`
        );
      } catch (e) {
        throw e;
      }
    })

    return{
      pay,
      cancelPay
    };
  });

type RufreeCalculateModelType = typeof RufreeCalculateModel.Type;
export interface RufreeCalculate extends RufreeCalculateModelType {}
