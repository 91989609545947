import { types, flow } from "mobx-state-tree";
import axios from "axios";
import { RequestCancelFormModel } from "./RequestCancelForm";

export const RequestCancelFormStoreModel = types
  .model("RequestCancelFormStore", {
    currentForm: types.optional(RequestCancelFormModel, {})
  })
  .actions(self => ({
    initForm() {
      self.currentForm = RequestCancelFormModel.create();
    }
  }))
  .actions(self => {
    const cancelRequest = flow(function*() {
      try {
        const form = self.currentForm;

        yield axios.put(`/parttime/requests/${form.id}/cancel`, {
          id: form.id,
          cancel_reason: form.cancelReason,
          cancel_consultant: form.manager
        });
      } catch (e) {
        console.log("cancelRequest error", e);
        throw e;
      }
    });

    return {
        cancelRequest
    };
  });

type RequestCancelFormStoreType = typeof RequestCancelFormStoreModel.Type;
export interface RequestCancelFormStore extends RequestCancelFormStoreType {}
