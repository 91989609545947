import { types } from "mobx-state-tree";

export const PMUserModel = types.model("PMUser", {
  id: types.identifierNumber,
  user_id: types.number,
  email: types.string,
  name: types.string,
  nickname: types.string,
  phone: types.string,
  color: types.string,
  is_support: types.boolean,
  is_manager: types.boolean,
  is_intern: types.boolean,
  date_joined: types.string
});

type PMUserType = typeof PMUserModel.Type;
export interface PMUser extends PMUserType {}
