import React, { Component, ChangeEvent, KeyboardEvent } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { FormGroup, InputGroup, H2, Button } from "@blueprintjs/core";
import { observable } from "mobx";
import { AppStore } from "../../../store/AppStore";
import { RouteComponentProps } from "react-router";
import { Redirect } from "react-router";

import LargeContainer from "../../atoms/LargeContainer/LargeContainer";

const Wrapper = styled.div``;

const LoginForm = styled.div`
  padding: 40px;
  max-width: 400px;
`;

interface LoginPageProps extends RouteComponentProps {}

interface InjectedProps extends LoginPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class LoginPage extends Component<LoginPageProps> {
  @observable email = "";
  @observable password = "";

  get injected() {
    return this.props as InjectedProps;
  }

  get userStore() {
    return this.injected.appStore.userStore;
  }

  componentDidMount() {
    this.userStore.checkLoginStatus();
  }

  handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.email = e.target.value;
  };

  handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.password = e.target.value;
  };

  handlePasswordKeyDown = async(event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      await this.handleLogin();
    }
  }

  handleLogin = async () => {
    try {
      await this.userStore.login(this.email, this.password);

      if(this.userStore.isPM) {
        this.injected.appStore.pmUserStore.fetchPMUsers();
        this.userStore.fetchMyProjectGroups();
      } else {
        alert("관리자에게 문의하세요. \n시소 내부 멤버만 시스템 사용이 허용됩니다.");
        this.userStore.logout();
        this.props.history.push("/login");
      }

      const nextUrl = window.localStorage.getItem('nexturl');
      window.localStorage.removeItem('nexturl');
      if (nextUrl && nextUrl !== '/login') {
        this.props.history.push(nextUrl);
      } else{
        this.props.history.push("/");
      }

    } catch (e) {
      alert("로그인에 실패하였습니다.\n아이디와 비밀번호를 다시 확인하세요.");
      this.password = '';
    } finally {
    }
  };

  render() {
    if (this.userStore.isLoggedIn) {
      return <Redirect to="/" />;
    }

    return (
      <LargeContainer>
        <LoginForm>
          <H2>로그인</H2>
          <FormGroup label="email">
            <InputGroup
              value={this.email}
              onChange={this.handleEmailChange}
              type="email"
            />
          </FormGroup>
          <FormGroup label="password">
            <InputGroup
              value={this.password}
              onChange={this.handlePasswordChange}
              type="password"
              onKeyDown={this.handlePasswordKeyDown}
            />
          </FormGroup>
          <Button text="로그인" onClick={this.handleLogin} />
        </LoginForm>
      </LargeContainer>
    );
  }
}

export default LoginPage;
