import axios from "axios";
import { types, flow } from "mobx-state-tree";
import { TaskOutcomeFormModel, TaskOutcomeForm } from "./TaskOutcomeForm";

export const TaskCompleteFormModel = types
  .model("TaskCompleteForm", {
    totalAmount: types.optional(types.string, ""),
    paymentRemark: types.optional(types.string, ""),
    outcomes: types.array(TaskOutcomeFormModel),
  })
  .actions(self => ({
    setTotalAmount(value: string) {
      self.totalAmount = value;
    },
    setPaymentRemark(value: string) {
      self.paymentRemark = value;
    },
    addOutcomes(target: TaskOutcomeForm) {
        self.outcomes.push(target);
    },
    removeOutcomes(target: TaskOutcomeForm) {
        self.outcomes.remove(target);
    },
  }));
type TaskCompleteFormType = typeof TaskCompleteFormModel.Type;
export interface TaskCompleteForm extends TaskCompleteFormType {}


export const TaskCompleteFormStoreModel = types
  .model("TaskCompleteFormStore", {
    currentForm: types.optional(TaskCompleteFormModel, {})
  })
  .actions(self => ({
    initForm() {
      self.currentForm = TaskCompleteFormModel.create();
    }
  }))
  .actions(self => {
    const completeTask = flow(function*(requestId: number, taskId: number) {
      try {
        const form = self.currentForm;

        yield axios.put(`/parttime/requests/${requestId}/tasks/${taskId}/complete`, {
            total_amount: form.totalAmount,
            payment_remark: form.paymentRemark
        });

        // 결과파일 저장
        const formData = new FormData();
        if(form.outcomes.length > 0){
          form.outcomes.forEach(item => {
            if(item.id === null){
              formData.append('outcomes', item.file!);
            }
          });
          if(formData.has('outcomes')){
            yield axios.post(`/parttime/requests/${requestId}/tasks/${taskId}/outcomes`, formData, {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            });
          }
        } 
      } catch (e) {
            console.log("completeTask error", e);
            throw e;
      }
    });

    return {
        completeTask
    };
  });

type TaskCompleteFormStoreType = typeof TaskCompleteFormStoreModel.Type;
export interface TaskCompleteFormStore extends TaskCompleteFormStoreType {}
