import React, { Component } from "react";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import { HTMLSelect, H5, HTMLTable, Spinner, Tooltip, Icon, Position } from "@blueprintjs/core";
import { SectionTitleWrapper } from '../../atoms/DashboardComponents/DashboardComponents';
import VacationSummaryPopup from '../../molecules/Dashboard/VacationSummaryPopup';

import { AppStore } from "../../../store/AppStore";
import { PmUserResponse } from "../../../types/pmUser";

import moment from 'moment';
import sortBy from 'lodash/sortBy';


const Wrapper = styled.div`
  margin-bottom: 10px;
  & > * + * {
    margin-top: 10px;
  }
`;

const HeaderWrapper = styled.div`
  min-height: 50px;
  display: flex;
`;

const HeaderTitleWrapper = styled(SectionTitleWrapper)`
display: flex;
align-items: center;
`;

const HeaderTitle = styled(H5)`
  margin-right: 10px;
  margin-bottom: 0;
`;

const TableContainer = styled.div`
`;

const Divider = styled.div`
opacity: 0.5;
border: dashed 1px #a0a7bb;
margin: 20px 0;
`;

interface VacationSummaryProps {
}

interface InjectedProps extends VacationSummaryProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class VacationSummary extends Component<VacationSummaryProps> {
  @observable readyToShow = false;
  @observable vacation_year = moment().year();
  @observable isPopupOpen = false;
  @observable selectedPmUserId = -1;
  @observable selectedPmName = '';
  
  get injected() {
    return this.props as InjectedProps;
  }
  fetchList = async () => {
    try {
      this.readyToShow = false;
      await this.injected.appStore.pmUserStore.fetchPMUsers();
      await this.injected.appStore.scheduleManagerStore.fetchSummarySchedules('vacation', String(this.vacation_year));
      await this.injected.appStore.scheduleManagerStore.fetchSummarySchedules('officehour', String(this.vacation_year));
      await this.injected.appStore.scheduleManagerStore.fetchWeekdayHolidays();

      this.readyToShow = true;
    } catch (e) {}
  }; 

  async componentDidMount() {
    await this.fetchList();
  }

  async handleOnYearChange(e: any) {
    this.vacation_year = Number(e.currentTarget.value);
    await this.fetchList();
  }

  handlePopupOpen(id: number, name: string) {
    this.selectedPmUserId = id ? id : -1;
    this.selectedPmName = name ? name : '';
    this.isPopupOpen = true;
  }

  handlePopupClose = async () => {
    this.isPopupOpen = false;
  }

  render_row = (row_type:string, row_idx:number, pm: PmUserResponse) => {
    // 휴가, 반차 집계 변수
    let vac_monthly_aggrs = [ .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0];
    let vac_yearly_aggrs = 0;

    // 경조사/민방위 집계 변수
    let vac_monthly_aggrs_special_vacs = [ .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0];
    let vac_yearly_aggrs_special_vacs = 0;

    // 갱신연차, 보상휴가, 특별(추가)휴가 집계 변수
    let vac_adjusted_vacations = 15;
    let vac_compensation_vacations = 0;
    let vac_special_plus_vacations = 0;

    // 근무시간 집계 변수
    let ofh_monthly_aggrs = [ .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0];
    let ofh_monthly_work_days = [ .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0];
    let ofh_monthly_overwork_hours = [ .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0];
    let ofh_work_days = new Set<string>();  // 근무일 저장 변수

    // 휴가, 근무시간 공통 변수
    const { summaryVacationSchedules } = this.injected.appStore.scheduleManagerStore;
    const { summaryOfficeHourSchedules } = this.injected.appStore.scheduleManagerStore;
    const weekdayHolidays = this.injected.appStore.scheduleManagerStore.weekdayHolidays.map(x => x.date);

    // 휴가, 반차, 경조사/민방위 집계 처리
    {
      summaryVacationSchedules.filter(schedule => {
        return schedule.creator === pm.user_id 
      }).map(schedule => {
        if(schedule.schCategory === 'vam' || schedule.schCategory === 'vpm') {
          // 반차 집계
          vac_monthly_aggrs[moment(schedule.startAt).month()] += 0.5;
          vac_yearly_aggrs += 0.5;
        } else if(schedule.schCategory === 'vac') {
          // 휴가 집계
          let startDate = moment(schedule.startAt);
          let endDate = moment(schedule.endAt);

          while(startDate.isSameOrBefore(endDate, 'days')) {
            if(
              startDate.isoWeekday() < 6 && 
              !(weekdayHolidays.includes(startDate.format("YYYY-MM-DD"))) && 
              startDate.format("YYYY") === String(this.vacation_year)
            ) {
              if(schedule.title === '경조사' || schedule.title === '민방위') {
                // 경조사, 민방위 휴가는 추가 집계
                vac_monthly_aggrs_special_vacs[startDate.month()] += 1;
                vac_yearly_aggrs_special_vacs += 1;
              } else {
                vac_monthly_aggrs[startDate.month()] += 1;
                vac_yearly_aggrs += 1;
              }
            }
            startDate.add(1, 'days');
          }
        }
      })
    }

    // 월별 근무시간 총합, 근무일 모음 처리
    {
      summaryOfficeHourSchedules.filter(schedule => {
        return schedule.creator === pm.user_id && (schedule.endAt)
      }).map(schedule => {
        let startDate = moment(schedule.startAt);
        let endDate = moment(schedule.endAt);

        if(startDate.format("YYYY") === String(this.vacation_year)) {
          let minutes = endDate.diff(startDate, 'minutes');
          minutes = Math.ceil(minutes / 10) * 10  // 분 단위 근무는 몇 분 추가해줘서 10분단위로 만든다
          let hours = minutes / 60; 
    
          ofh_monthly_aggrs[startDate.month()] += hours;
          if(startDate.isoWeekday() < 6) {  // 주중 근무일만 집계한다
            ofh_work_days.add(startDate.format("YYYY-MM-DD"));
          }
        }      
      })
    }

    // 월별 근무일수, 일평균 근무시간, 월초과 근무시간 집계
    {
      ofh_monthly_aggrs.map((hours, idx) => {
        let month_idx = String(idx + 1);
        month_idx = String(this.vacation_year)+"-"+ ((month_idx.length < 2) ? "0"+month_idx : month_idx);

        ofh_monthly_work_days[idx] += Array.from(ofh_work_days).filter(work_day => {
          return work_day.substring(0, 7) === month_idx;
        }).length;

        ofh_monthly_overwork_hours[idx] += Math.max(hours - (ofh_monthly_work_days[idx] * 9), 0);  // 점심시간 1시간 추가한 9시간 단위로 초과근무 처리

        // 월별 근무시간 집계를 소수점 1자리로 맞춤
        ofh_monthly_aggrs[idx] = Math.round(ofh_monthly_aggrs[idx] * 10) / 10
      })
    }

    // 갱신연차 처리
    {
      let vac_work_year = this.vacation_year - moment(pm.date_joined).year()
      if(vac_work_year === 0) {
        // 당해년도 입사자
        vac_adjusted_vacations = 12 - moment(pm.date_joined).month();
      }
      if(vac_work_year > 0) {
        // 3년차에 1일추가, 이후부터는 2년마다 1일 추가
        vac_adjusted_vacations += Math.floor((vac_work_year-1)/2);
      }
      if(vac_adjusted_vacations > 25) {
        // 최대 연차 가능일은 25일
        vac_adjusted_vacations = 25;
      }
    }

    // 보상휴가 처리
    {
      ofh_monthly_overwork_hours.map(hours => {
        vac_compensation_vacations += Math.floor(hours / 8);
      });
    }

    // 특별(추가)휴가 처리
    {
      if(vac_yearly_aggrs > (vac_adjusted_vacations+vac_compensation_vacations)) {
        vac_special_plus_vacations = vac_yearly_aggrs - (vac_adjusted_vacations+vac_compensation_vacations);
      }
    }

    if(row_type === 'vacation') {
      return (
        <tr key={pm.user_id} onClick={() => { this.handlePopupOpen(pm.user_id, pm.name); }}>
          <td style={{textAlign:"center"}}>{row_idx}</td>
          <td>{pm.name} ({pm.nickname})</td>
          <td style={{textAlign:"center"}}>{pm.date_joined}</td>
          <td style={{textAlign:"center"}}>{vac_adjusted_vacations}</td>
          <td style={{textAlign:"center"}}>{vac_compensation_vacations}</td>
          <td style={{textAlign:"center"}}>{vac_special_plus_vacations}</td>
          <td style={{textAlign:"center"}}>{vac_yearly_aggrs} ({vac_yearly_aggrs_special_vacs})</td>
          {Array.from(Array(12).keys()).map(month => {
            return (
              <td key={month} style={{textAlign:"center"}}>
                {vac_monthly_aggrs[month]} 
                {vac_monthly_aggrs_special_vacs[month] > 0 && (
                  <>({vac_monthly_aggrs_special_vacs[month]})</>
                )}
              </td>
            );
          })}                    
        </tr>
      )
    }

    if(row_type === 'officehour') {
      return (
        <tr key={pm.user_id}>
          <td style={{textAlign:"center", verticalAlign:"middle"}}>{row_idx}</td>
          <td style={{verticalAlign:"middle"}}>{pm.name} ({pm.nickname})</td>
          <td style={{textAlign:"center", verticalAlign:"middle"}}>근무(H)<br/>근무(D)<br/>평균(H)<br/>초과(H)</td>
          {Array.from(Array(12).keys()).map(month => {
            return (
              <td key={month} style={{textAlign:"center"}}>
                {ofh_monthly_aggrs[month]}<br/>
                {ofh_monthly_work_days[month]}<br/>
                {ofh_monthly_work_days[month] > 0 ? (Math.round((ofh_monthly_aggrs[month] / ofh_monthly_work_days[month]) * 10) / 10) : 0}<br/>
                {Math.floor(ofh_monthly_overwork_hours[month])}
              </td>
            );          
          })}                    
        </tr>
      );
    }

    return (<></>);

  }

  render() {
    const { pmUsers } = this.injected.appStore.pmUserStore;

    if(!this.readyToShow) {
      return (
        <Wrapper>
          <Spinner />
        </Wrapper>
      );
    } else {
      return (
        <Wrapper>
          <HeaderWrapper>
            <HeaderTitleWrapper>
              <HeaderTitle>집계연도</HeaderTitle>
              <HTMLSelect
                value={this.vacation_year}
                onChange={(e) => {this.handleOnYearChange(e);}}
              >
                <option value={2020}>2020</option>
                <option value={2021}>2021</option>
              </HTMLSelect>
            </HeaderTitleWrapper>
          </HeaderWrapper>

          <HeaderWrapper>
            <HeaderTitleWrapper>
              <HeaderTitle>휴가 사용 현황</HeaderTitle>
              <div style={{marginLeft:"20px"}}>
                <Tooltip position={Position.RIGHT} content={(
                  <div>
                    <div>갱신연차: 기본 15일, 입사후 3년차에 1일, 이후 2년에 1일씩 추가 (입사첫해에는 근무월수)</div>
                    <div>보상휴가: 월 초과 근무시간 8시간 당 1일 추가 (하단 근무시간 표와 연계)</div>
                    <div>특별휴가: 사용일수가 갱신연차와 보상휴가의 합보다 많을 경우 발생</div>
                    <div>사용일수: 사용한 휴가 (괄호안의 수치는 경조사/민방위 등 기타휴가유형)</div>
                  </div>
                )}>
                  <Icon icon="help" color="gray"/>
                </Tooltip>
              </div>
            </HeaderTitleWrapper>
          </HeaderWrapper>

          <TableContainer>
            <HTMLTable
              condensed={true}
              interactive={true}
              striped={true}
              style={{ }}
            >
              <thead>
                <tr>
                  <th>번호</th>
                  <th>사원명</th>
                  <th>입사일</th>
                  <th>갱신연차</th>
                  <th>보상휴가</th>
                  <th>특별휴가</th>
                  <th>사용일수</th>
                  {Array.from(Array(12).keys()).map(month => {
                    return <th key={month}>{month+1}월</th>;
                  })}
                </tr>
              </thead>

              <tbody>
                {
                  sortBy(pmUsers.slice(), ['date_joined']).map((pm, idx) => {
                    return this.render_row('vacation', idx+1, pm);
                  })
                }
              </tbody>

            </HTMLTable>
          </TableContainer>
          <VacationSummaryPopup
            pmUserId={this.selectedPmUserId}
            pmName={this.selectedPmName}
            isOpened={this.isPopupOpen}
            onClose={this.handlePopupClose}
          />

          <Divider />

          <HeaderWrapper>
            <HeaderTitleWrapper>
              <HeaderTitle>근무시간 현황</HeaderTitle>
              <div style={{marginLeft:"20px"}}>
                <Tooltip position={Position.RIGHT} content={(
                  <div>
                    <div>근무(H): 월 근무시간: 월별 입력된 근무시간의 총합</div>
                    <div>근무(D): 월 근무일수: 근무시간이 입력된 날짜의 수 (주말은 근무시간 입력이 있어도 제외)</div>
                    <div>평균(H): 일 평균근무시간: 근무(H) / 근무(D)</div>
                    <div>초과(H): 월 초과근무시간: (근무(D) * 9) - 근무(H) (하루 기대근무시간을 점심 포함 9시간으로 처리)</div>
                    <div>- 최종적으로 월별 (초과(H) / 8시간 의 몫)을 보상휴가로 처리</div>
                  </div>
                )}>
                  <Icon icon="help" color="gray"/>
                </Tooltip>                
              </div>
            </HeaderTitleWrapper>
          </HeaderWrapper>

          <TableContainer>
            <HTMLTable
              condensed={true}
              interactive={false}
              striped={true}
              style={{ width:"100%", tableLayout:"fixed" }}
            >
              <thead>
                <tr>
                  <th style={{width:"60px", textAlign:"center"}}>번호</th>
                  <th style={{width:"130px"}}>사원명</th>
                  <th style={{}}>지표</th>
                  {Array.from(Array(12).keys()).map(month => {
                    return <th key={month} style={{textAlign:"center"}}>{month+1}월</th>;
                  })}
                </tr>
              </thead>

              <tbody>
                {
                  sortBy(pmUsers.slice(), ['date_joined']).map((pm, idx) => {
                    return this.render_row('officehour', idx+1, pm);
                  })
                }
              </tbody>

            </HTMLTable>
          </TableContainer>

        </Wrapper>
      );
    }
  }
}

export default VacationSummary;