import React, { Component, ChangeEvent } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { FormMode } from "../../../types/formMode";
import ContentContainer from "../../atoms/ContentContainer/ContentContainer";
import {
  FormGroup,
  InputGroup,
  Button,
  Intent,
  Spinner
} from "@blueprintjs/core";
import { computed, observable } from "mobx";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import FormContainer from "../../atoms/FormContainer/FormContainer";
import { RouteComponentProps } from "react-router";
import TemporaryStorage from "../../molecules/Utility/TemporaryStorage/TemporaryStorage";
import { ETEMPLATETYPE } from "../../../store/models/NotificationTemplate";


import { PlanResourceEditSection } from "../../organisms/Plan/PlanResourceEditSection";
import { FroalaConfigs } from "../../../utils/FroalaConfigs";
import FroalaEditor from 'react-froala-wysiwyg';

const Wrapper = styled.div`
  font-family: 'Noto Sans KR';
  width: 1053px;
  h2, h3, h4 {
    margin: 0;
  }
  h3 {
    margin-bottom: 10px;
  }
  h2 {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    margin-bottom: 20px;
  }
`;

const Section = styled.section`
  margin-bottom: 40px;
`;

const SprintContainer = styled.div`
  max-width: 1060px;
`;

const ButtonsRow = styled.div`
  /* text-align: right; */

  & > * + * {
    margin-left: 5px;
  }
`;

const ReviewInputArea = styled.div`
  width: 1040px;
`;



interface PageParams {
  id: string;
}
interface InspectionFormPanelProps extends RouteComponentProps<PageParams> {
  mode: FormMode;
  id?: number;
}
interface InjectedProps extends InspectionFormPanelProps {
  appStore: AppStore;
}


@inject("appStore")
@observer
class InspectionTemplateFormPanel extends Component<InspectionFormPanelProps> {
  @observable isEditSuccess = false;
  @observable isFormInitiated = false;
  @observable isChangeReasonOpened = false;
  @observable isPending = false;
  @observable tempFormTitle = "";
  @observable popoverOpened = false;

  @observable selectedResource: string = '';  // client | rufree | service
  @observable selectedRole: string = '';
  @observable showSprintEditPopup: boolean = false;
  @observable totalAmount: number = 5170000;
  
  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get formStore() {
    return this.injected.appStore.inspectionTemplateStore;
  }

  @computed
  get currentForm() {
    return this.formStore.selectedTemplate;
  }

  async saveToLocalStorage() {
    localStorage.setItem(`storedInspectionTemplateForm`, JSON.stringify(this.currentForm));
  }

  async componentDidMount() {
    await this.formStore.initForm();

    const { id } = this.props.match.params;
    const { mode } = this.props;

    if (mode !== FormMode.Create && id) {
      await this.formStore.fetchById(Number(id));
    }
    this.isFormInitiated = true;
  }

  handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.currentForm.setName(e.target.value);
  };

  handleSubmit = async () => {
    try {
      await this.formStore.post();
      AppToaster.show({
        message: "새로운 플랜 템플릿이 생성되었습니다.",
        intent: Intent.SUCCESS
      });

      await this.formStore.fetch();

      this.props.history.push(`/templates?tab=${ETEMPLATETYPE.INSPECTION}`);
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  handleEditSubmit = async () => {
    try {
      await this.formStore.update();
      AppToaster.show({
        message: "플랜 템플릿이 수정되었습니다.",
        intent: Intent.SUCCESS
      });

      await this.formStore.fetch();

      this.props.history.push(`/templates?tab=${ETEMPLATETYPE.INSPECTION}`);
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  handleCancel = () => {
    const { history } = this.props;

    history.push(`/templates?tab=${ETEMPLATETYPE.INSPECTION}`);
  };

  render() {
    if (!this.isFormInitiated) {
      return <Spinner/>;
    }

    return (
      <Wrapper>
      
        <FormContainer>
          <Section>
            <TemporaryStorage keyName={"InspectionTemplate"} title={"name"} setForm={(val) => {
              this.formStore.setForm(val);
            }} currentForm={this.currentForm}/>


            <FormGroup label={<strong>프로젝트 플랜 템플릿 제목</strong>}>
              <InputGroup
                placeholder="템플릿명"
                value={this.currentForm.name}
                onChange={this.handleNameChange}
              />
            </FormGroup>
          </Section>
        </FormContainer>

        <Section>
          <ReviewInputArea>
            <FormGroup label={<strong>플랜 설계</strong>}>
              <FroalaEditor
                model={ this.currentForm.review }
                config={{
                  ...FroalaConfigs,
                  width: '100%',
                  height: 385     
                }}
                onModelChange={(model: string) => {
                  this.currentForm.setReview(model);
                }} />
            </FormGroup>
          </ReviewInputArea>
        </Section>

        <Section>
          <ReviewInputArea>
            <FormGroup label={<strong>매니징시 주의사항</strong>}>
              <FroalaEditor
                model={ this.currentForm.attention }
                config={{
                  ...FroalaConfigs,
                  width: '100%',
                  height: 385     
                }}
                onModelChange={(model: string) => {
                  this.currentForm.setAttention(model);
                }} />
            </FormGroup>
          </ReviewInputArea>
        </Section>

        <SprintContainer>
          <Section>
            <PlanResourceEditSection
              currentForm={ this.currentForm } />
          </Section>
        </SprintContainer>

        <ContentContainer>
          {this.props.mode === FormMode.Create && (
            <ButtonsRow>
              <Button text="취소" onClick={this.handleCancel}/>
              <Button
                text="저장"
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit}
              />
            </ButtonsRow>
          )}

          {this.props.mode === FormMode.Edit && (
            <ButtonsRow>
              <Button text="취소" onClick={this.handleCancel}/>
              <Button
                text="수정사항 적용"
                intent={Intent.PRIMARY}
                onClick={this.handleEditSubmit}
              />
            </ButtonsRow>
          )}
        </ContentContainer>

      </Wrapper>
    );
  }
}

export default InspectionTemplateFormPanel;
