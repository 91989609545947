import { types } from "mobx-state-tree";

export const TaskManagerFormModel = types
  .model("TaskManagerForm", {
    id: types.optional(types.number, -1),
    name: types.optional(types.string, ""),
    phone: types.optional(types.string, ""),
    email: types.optional(types.string, "")
  })
  .actions(self => ({
    setId(value: number) {
      self.id = value;
    },
    setName(value: string) {
      self.name = value;
    },
    setPhone(value: string) {
      self.phone = value;
    },
    setEmail(value: string) {
      self.email = value;
    }
  }));

type TaskManagerFormType = typeof TaskManagerFormModel.Type;
export interface TaskManagerForm extends TaskManagerFormType {}
