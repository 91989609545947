import React, { ChangeEvent, FC, useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components/macro";
import {
  Button,
  Classes,
  Colors,
  Dialog,
  FormGroup,
  Intent,
  InputGroup,
  TextArea,
  Icon
} from "@blueprintjs/core";
import { FroalaConfigs } from "../../../utils/FroalaConfigs";
import FroalaEditor from 'react-froala-wysiwyg';

import { AppStore } from "../../../store/AppStore";
import { ETEMPLATETYPE } from "../../../store/models/NotificationTemplate";
import { FormMode } from "../../../types/formMode";
import { ITemplatePreviewResponse } from "../../../types/notificationTemplate";

import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import RecipientSelection from "../../molecules/RecipientSelection/RecipientSelection";
import NotificationTemplateSelection from "../../molecules/NotificationTemplate/NotificationTemplateSelection";

const DialogWrapper = styled(Dialog)`
  width: 800px;

  .bp3-dialog-body {
    position: relative;
  }
  .bp3-dialog-body .bp3-form-group label {
    width: 50px;
  }
  .label-onelne label {
    width: 100% !important;
  }
  .bp3-dialog-body div.bp3-form-content {
    line-height: 30px; width: calc(100% - 50px);
  }

  .dialog-form-mode {
    position: absolute;
    right: 0;
  }

  textarea {
    width: 100%;
  }

  /* FroalaEditorView */
  .bp3-dialog-body > .fr-view {
    background-color: ${ Colors.LIGHT_GRAY5 };
    height: 600px;
    overflow-y: auto;
  }

  span.info {
    color: ${ Colors.DARK_GRAY4 };
  }
`;

const InfoSection = styled.section`
  font-size: 12px;
  padding: 5px;
  margin-bottom: 10px;
  color: ${ Colors.DARK_GRAY5 };
`;

const dialogSettings = {
  autoFocus: true,
  canEscapeKeyClose: false,
  canOutsideClickClose: true,
  enforceFocus: true,
  isOpen: true,
  usePortal: true,
};

interface Props {
  templateType: string;
  excludeSupport?: boolean;
  restrictedCategories?: string[];
  isOnlySmsMode?: boolean;
  handleClose?: () => void;
}
interface InjectedProps extends Props {
  appStore: AppStore;
}
const SendDialog: FC<Props> = inject("appStore")(observer((props => {
  const store = (props as InjectedProps).appStore.notificationTemplateStore;
  const { templateType, isOnlySmsMode, excludeSupport, restrictedCategories, handleClose } = props;
  const { selectedTemplate, openPopup, formMode } = store;

  const [ isEmail, setIsEmail ] = useState(templateType === ETEMPLATETYPE.EMAIL);
  const icon = isEmail ? 'envelope' : 'mobile-phone';
  
  // 발송을 위한 state 준비.
  // const [ parameters, setParameters ] = useState<string[]>([]);
  const [ subject, setSubject ] = useState('');
  const [ mailMessage, setMailMessage ] = useState('');
  const [ smsMessage, setSmsMessage ] = useState('');

  const [ toMail, setToMail ] = useState<string[]>(store.emails);
  const [ toCcMail, setToCcMail ] = useState<string[]>([]);
  const [ toBccMail, setToBccMail ] = useState<string[]>([]);
  const [ toSMS, setToSMS ] = useState<string[]>(store.phones);

  useEffect(() => {
    if(selectedTemplate) {

      (async () => {
        const data: ITemplatePreviewResponse = await selectedTemplate.getPreview({
          name: store.name ? store.name : '',
          project_name: store.projectName ? store.projectName : '',
          rufree_name: store.rufreeName ? store.rufreeName : ''
        });

        setSubject(data.subject);
        // if(isEmail) {
          setMailMessage(data.html);
        // } else {
          setSmsMessage(data.text);
        // }
        
      })();
    } else {
      setSubject('');
      setMailMessage('');
      setSmsMessage('');
      setToMail(store.emails);
      setToCcMail([]);
      setToBccMail([]);
      setToSMS(store.phones);
    }
  }, [ selectedTemplate ]);

  if(!openPopup || formMode !==FormMode.Read) {
    return (<></>)
  }

  const dialogTitle = isOnlySmsMode ? '문자 발송' :  '이메일/문자 발송';

  const _handleClose = () => {
    if(handleClose) {
      handleClose();
    }
    store.setOpenPopup(false);
    store.setSelectedTemplate();
    store.setEmails([]);
    store.setPhones([]);
  };

  return (
    <DialogWrapper
      icon={ icon }
      onClose={ _handleClose }
      title={ dialogTitle }
      { ...dialogSettings }
    >

      <div className={Classes.DIALOG_BODY}>
        <FormGroup
          label={<strong>템플릿</strong>}
          inline={true}
        >
          <NotificationTemplateSelection
            onlySms={ isOnlySmsMode }
            restrictedCategories={ restrictedCategories }
            value={ selectedTemplate ? selectedTemplate.id : undefined }
            handleChange={ async(id: number) => {
              const template = await store.fetchById(id);
              store.setSelectedTemplate(template);
            }} />
        </FormGroup>
        
        <div className={ store.isLoading || (selectedTemplate && selectedTemplate.isLoading) ? Classes.SKELETON : ''}>
        {
          !isOnlySmsMode && mailMessage && smsMessage &&
            <InfoSection>
              <div style={{color: Colors.BLUE3, fontWeight: 'bold'}}>
                <Icon icon={'envelope'} iconSize={12} />메일, <Icon icon={'mobile-phone'} iconSize={12} />문자 모두 발송 가능한 템플릿입니다.
              </div>
              <div>* 메일, 문자 <strong>수신인</strong> 모두 확인해주세요 ( 수신인 입력된 타입의 알림만 발송됩니다. ex. 문자 수신번호 미 입력시, 문자 미발송.)</div>
              <div>* 메일, 문자 <strong>발송 본문</strong> 모두 확인해주세요</div>
            </InfoSection>
        }
        
        {
          selectedTemplate &&
          <FormGroup
            label={<strong>받는사람</strong>}
            inline={true}
          >
          
            <RecipientSelection
              email={ mailMessage ? true : false }
              sms={ smsMessage ? true : false }
              onlySms={ isOnlySmsMode }
              emails={ toMail }
              phones={ toSMS }
              handleChange={(mails: string[], ccMails: string[], bccMails: string[], phones: string[]) => {
                setToMail(mails);
                setToCcMail(ccMails);
                setToBccMail(bccMails);

                setToSMS(phones);
              }} />
          
          </FormGroup>
        }

        {
          !isOnlySmsMode &&
            <FormGroup
              label={<strong>제목</strong>}
              inline={true}
            >
              <InputGroup
                value={ subject }
                disabled={ !selectedTemplate }
                onChange={
                  (e: ChangeEvent<HTMLInputElement>) =>
                    setSubject(e.target.value)
                }
              />
            </FormGroup>
        }
        

        <FormGroup
          label={<></>}
          inline={false}
        >
        </FormGroup>

        {
          !isOnlySmsMode && mailMessage &&
            <>
              {
                mailMessage && smsMessage && <strong>메일</strong>
              }
              <FroalaEditor
                model={ mailMessage }
                config={{
                  ...FroalaConfigs,
                  width: '100%',
                  height: 600
                }}
                onModelChange={(model: string) => {
                  setMailMessage(model);
                }} />
            </>
        }

        {
          smsMessage &&
            <>
              {
                mailMessage && smsMessage && <strong>문자</strong>
              }
              <TextArea
                value={ smsMessage }
                growVertically={true}
                onChange={
                  (e: ChangeEvent<HTMLTextAreaElement>) =>
                    setSmsMessage(e.target.value)
                }
              />
            </>
        }
    </div>

    </div>
    <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {/* <Button
            intent={ Intent.DANGER}
            minimal={ true }
            onClick={ _handleClose }
          >
            삭제
          </Button> */}

          <Button
            onClick={ _handleClose }
          >
            취소
          </Button>


          <Button
            intent={Intent.PRIMARY}
            loading={ selectedTemplate && selectedTemplate.isLoading }
            onClick={ async () => {
              if(selectedTemplate && (toMail.length > 0 || toSMS.length > 0)) {
                try {
                  // console.log(toMail, toSMS);
                  selectedTemplate && await selectedTemplate.send({
                    subject: subject,
                    mail: mailMessage,
                    sms: smsMessage,
                    to_mail: toMail.join(','),
                    to_mail_cc: toCcMail.join(','),
                    to_mail_bcc: toBccMail.join(','),
                    to_text: toSMS.join(',')
                  })  // NotificationTemplate 모델 파일 내, ITemplateSendParames 인터페이스 참고.
  
                  AppToaster.show({
                    message: "전송되었습니다.",
                    intent: Intent.SUCCESS
                  });
                } catch (e) {
                  AppToaster.show({
                    message: "메일 발송 중 오류가 발생하였습니다.",
                    intent: Intent.DANGER
                  });
                }
              } else {
                AppToaster.show({
                  message: "입력값을 모두 입력후 발송해주세요.",
                  intent: Intent.WARNING
                });
              }
            }}
          >
            발송
          </Button>
        </div>
    </div>
    </DialogWrapper>
  );
})));

export default SendDialog;
