import { types } from "mobx-state-tree";
import shortid from "shortid";
import { MAX_SPRINTS } from "../../../constants/constants";
import moment from "moment";

export const ScheduleSprintFormModel = types
  .model("ScheduleSprintForm", {
    id: types.optional(types.string, ""),
    dateStart: types.optional(types.string, ""),
    dateEnd: types.optional(types.string, ""),
    manager: types.optional(types.string, ""),
    isValid: types.optional(types.boolean, false) // 해당 스프린트에 있는지 없는지.
  })
  .actions(self => ({
    afterCreate() {
      self.id = shortid();

      if (!self.manager) {
        self.manager = "-";
      }
    },
    setDateStart(value: string) {
      self.dateStart = value;
    },
    setDateEnd(value: string) {
      self.dateEnd = value;
    },
    setManager(value: string) {
      self.manager = value;
    },
    setValid(value: boolean) {
      self.isValid = value;
    }
  }));

type ScheduleSprintFormType = typeof ScheduleSprintFormModel.Type;
export interface ScheduleSprintForm extends ScheduleSprintFormType {}

export const createDefaultSprints = () =>
  Array(MAX_SPRINTS)
    .fill(null)
    .map(_ => ScheduleSprintFormModel.create());

export const updateSprintNeighborDates = (
  sprints: ScheduleSprintForm[],
  sprint: ScheduleSprintForm
) => {
  const sprintIndex = sprints.findIndex(x => x.id === sprint.id);
  const maxIndex = sprints.length - 1;

  // 현스프린트와 이전 스프린트가 겹치면 이전 스프린트의 종료일을 현 스프린트 시작일 이전으로 한다.
  for (let i = sprintIndex; i > 0; i--) {
    const curSprint = sprints[i];
    const prevSprint = sprints[i - 1];

    if (prevSprint.isValid && curSprint.isValid) {
      if (
        moment(prevSprint.dateEnd).isSameOrAfter(curSprint.dateStart, "day")
      ) {
        const prevSprintDiff = moment(prevSprint.dateEnd).diff(
          prevSprint.dateStart,
          "day"
        );

        prevSprint.setDateEnd(
          moment(curSprint.dateStart)
            .add(-1, "day")
            .format("YYYY-MM-DD")
        );

        prevSprint.setDateStart(
          moment(prevSprint.dateEnd)
            .add(-prevSprintDiff, "day")
            .format("YYYY-MM-DD")
        );
      }
    }
  }

  // 현스프린트와 다음 스프린트가 겹치면 다음 스프린트의 시작일을 현 스프린트 종료일 다음으로 한다.
  for (let i = sprintIndex; i < maxIndex; i++) {
    const curSprint = sprints[i];
    const nextSprint = sprints[i + 1];

    if (curSprint.isValid && nextSprint.isValid) {
      if (
        moment(nextSprint.dateStart).isSameOrBefore(curSprint.dateEnd, "day")
      ) {
        const nextSprintDiff = moment(nextSprint.dateEnd).diff(
          nextSprint.dateStart,
          "day"
        );

        nextSprint.setDateStart(
          moment(curSprint.dateEnd)
            .add(1, "day")
            .format("YYYY-MM-DD")
        );
        nextSprint.setDateEnd(
          moment(nextSprint.dateStart)
            .add(nextSprintDiff, "day")
            .format("YYYY-MM-DD")
        );
      }
    }
  }
};
