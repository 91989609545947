import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { FormMode } from "../../../types/formMode";
import moment from "moment";

import RequestFormDialog from "../../molecules/ParttimeDialog/RequestFormDialog";

import {
    Button,
  } from "@blueprintjs/core";

import { 
    Wrapper,
    StyledCard, 
    CardTitle, 
    CardContent, 
    Table, 
    TableRow, 
    HeadColumn, 
    ContentColumn,
    GrayLabel,
    NormalText,
    EmphasizedText,
    HelpText,
    Paragraph
} from "../../atoms/ParttimeComponents/ParttimeComponents";
import { ConvertLinkTextFormat }  from "../../atoms/ConvertLinkTextFormat/ConvertLinkTextFormat";
import { RequestType, RequestOS, Task, TaskType, TaskStatusData, RufreeRole } from "../../../store/models/Parttime";

interface BasicPanelProps extends RouteComponentProps {
  task: Task;
}

interface InjectedProps extends BasicPanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class BasicPanel extends Component<BasicPanelProps> {
    @observable task: Task = this.props.task;
    @observable isRequestFormDialogOpen: boolean = false;

  get injected() {
    return this.props as InjectedProps;
  }
 
  handleRequestFormDialogOpen = () => {
    this.isRequestFormDialogOpen = true;
  };

  handleRequestFormDialogClose = async() => {
    this.isRequestFormDialogOpen = false;
    const { task } = this.props; 
    this.task = await this.injected.appStore.parttimeTaskStore.fetchTaskById(task.request.id, task.id);
  }; 

  render() {
    const { isPM, isClient } = this.injected.appStore.userStore;
    const { task } = this.props;
    const request = this.task.request;

    let isCanceled = false;
    if(task.status === TaskStatusData.canceled.db) {
        isCanceled = true;
    }

    let isCompleted = false;
    if(task.status === TaskStatusData.completed.db) {
        isCompleted = true;
    }
    return (
        <Wrapper>
            <StyledCard style={{margin: "20px 0 30px 0"}}>
            <CardContent>
            {((isPM || isClient) && (isCanceled === false)) && (
                <Button icon="edit" text={"신청정보수정"} onClick={this.handleRequestFormDialogOpen} style={{float: "right"}}></Button>
            )}
            <Table>
                <TableRow>
                <HeadColumn>
                    <GrayLabel>서비스명</GrayLabel>
                </HeadColumn>
                <ContentColumn>
                    <EmphasizedText>{ request.serviceName ? request.serviceName : '-' }</EmphasizedText>
                </ContentColumn>
                </TableRow>
                <TableRow>
                <HeadColumn>
                    <GrayLabel>OS</GrayLabel>
                </HeadColumn>
                <ContentColumn>
                    <EmphasizedText>
                        { request.os ? (request.os.map((os, idx) => {
                            return (idx == 0) ? (<span key={idx}>{RequestOS.get(os)}</span>) : (<span key={idx}>, {RequestOS.get(os)}</span>)
                        })) : '-'}
                    </EmphasizedText>
                </ContentColumn>
                </TableRow>
                <TableRow>
                <HeadColumn>
                    <GrayLabel>유형</GrayLabel>
                </HeadColumn>
                <ContentColumn>
                    <EmphasizedText>{ RequestType.get(request.type) }</EmphasizedText>
                </ContentColumn>
                </TableRow>
                <TableRow>
                <HeadColumn>
                    <GrayLabel>알유프리 역할</GrayLabel>
                </HeadColumn>
                <ContentColumn>
                    <EmphasizedText>{ RufreeRole.get(task.role) }</EmphasizedText>
                </ContentColumn>
                </TableRow>
                <TableRow>
                <HeadColumn>
                    <GrayLabel>프로젝트그룹</GrayLabel>
                </HeadColumn>
                <ContentColumn>
                    <EmphasizedText>
                        { (task.projectGroup && task.projectGroup.groupId) ? 
                            <Link to={`/project-groups/${task.projectGroup.groupId}`} target="_blank">
                                { task.projectGroup.name} 
                            </Link> : 
                        '-' }
                    </EmphasizedText>
                </ContentColumn>
                </TableRow>
                <TableRow>
                <HeadColumn>
                    <GrayLabel>TASK 타입</GrayLabel>
                </HeadColumn>
                <ContentColumn>
                    <EmphasizedText>{ TaskType.get(task.type) }</EmphasizedText>
                </ContentColumn>
                </TableRow>
            </Table>
            </CardContent>
            </StyledCard>

            <StyledCard>
            <CardTitle>현재상태(ASIS)</CardTitle>
            <CardContent>
                <>{ request.asis ? request.asis : '-'}</>
            </CardContent>
            </StyledCard>

            <StyledCard>
            <CardTitle>변경상태(TOBE)</CardTitle>
            <CardContent>
            <>{ request.tobe ? request.tobe : '-'}</>
            </CardContent>
            </StyledCard>

            <StyledCard>
                <CardTitle>희망완료일</CardTitle>
                <CardContent>
                <>{ task.expectedDueDate ? moment(task.expectedDueDate).format("YYYY.MM.DD") : '-'}</>
                </CardContent>
            </StyledCard>

            <StyledCard>
                <CardTitle>예상작업완료일</CardTitle>
                <CardContent>
                <>{ task.estimatedDueDate ? moment(task.estimatedDueDate).format("YYYY.MM.DD") : '-'}</>
                </CardContent>
            </StyledCard>

            <StyledCard>
                <CardTitle>예상작업시간</CardTitle>
                <CardContent>
                    <>{ task.estimatedWorkingTime ? task.estimatedWorkingTime + ' 시간' : '-'}</>
                    { task.estimatedWorkingTime ? (
                        <HelpText style={{marginLeft: "10px", display: "inline"}}>
                            * {task.estimatedWorkingTime * 30000}원 (시간당 3만원)
                            클라이언트에서 책정한 예산 기준이며, 실제작업시간과는 다를 수 있음.
                        </HelpText>
                    ) : '' }
                    
                </CardContent>
            </StyledCard>

            <StyledCard>
                <CardTitle>작업내용</CardTitle>
                <CardContent>
                    <Paragraph>
                        <ConvertLinkTextFormat>{ task.description? task.description : '-'}</ConvertLinkTextFormat>
                    </Paragraph>
                </CardContent>
            </StyledCard>

            <StyledCard>
                <CardTitle>완료조건</CardTitle>
                <CardContent>
                <Table>
                {(Array.isArray(task.criteria) && task.criteria.length) ? (task.criteria.map((obj, idx) => (
                    <TableRow key={idx}>
                        <NormalText>{ obj }</NormalText> 
                    </TableRow>
                ))) : '-' }
                </Table>
                </CardContent>
            </StyledCard>

            <RequestFormDialog
                id={ task.request.id }
                mode={ FormMode.Edit }
                onClose={ this.handleRequestFormDialogClose }
                isOpen={ this.isRequestFormDialogOpen } 
            />
        </Wrapper>
    );
  }
}

export default BasicPanel;
