import { types, flow } from "mobx-state-tree";
import axios from "axios";
import { ScheduleManagerModel } from "./models/ScheduleManager";
import { ScheduleManagerResponse } from "../types/scheduleManager";
import { GoogleScheduleModel } from './models/ScheduleManager';
import { GoogleScheduleResponse } from "../types/scheduleManager";
import { WeekdayHolidayModel } from './models/ScheduleManager';
import sortBy from "lodash/sortBy";
import moment from 'moment';

export const mapSchedule = (x: ScheduleManagerResponse) => {
  return {
    id: x.id,
    createdAt: x.created_at,
    updatedAt: x.updated_at,
    category: x.category,
    schCategory: x.sch_category || '',  // (명) 미팅/휴가 일정 카테고리 추가
    taskDone: x.task_done,  // (명) 할일 완료 상태 추가
    startAt: x.start_at || '',
    endAt: x.end_at || '',
    allDay: x.all_day,
    title: x.title,
    comment: x.comment,
    creator: x.creator,
    creatorName: x.creator_name || '',
    creatorPmName: x.creator_pmname || '',
    projectGroup: x.project_group || '',  // (명) 근무시간 등 프로젝트그룹 외 일정에 대해 '' 처리
    projectGroupName: x.project_group_name || '',  // (명) 근무시간 등 프로젝트그룹 외 일정에 대해 '' 처리
    participants: x.participants
  };
};

export const mapGoogleSchedule = (x: GoogleScheduleResponse) => {
  return {
    id: x.id,
    type: x.type,
    htmlLink: x.htmlLink,
    summary: x.summary,
    creator: x.creator,
    start: x.start,
    end: x.end,
    attendees: x.attendees
  };
};

export const ScheduleManagerStoreModel = types
  .model("ScheduleManagerStore", {
    // 기존 스케줄 사용하던 부분을 위해 유지 (프로젝트그룹 일정, 전체일정 페이지)
    schedules: types.array(ScheduleManagerModel),

    // 대시보드에서 사용될 스케줄: 이번주와 다음주 모든 일정
    dashboardSeesoSchedules: types.array(ScheduleManagerModel),
    dashboardGoogleSchedules: types.array(GoogleScheduleModel),

    // 대시보드 캘린더뷰는 월단위이며, 사용자가 월을 바꿀수 있기 때문에 따로 관리
    calendarSeesoSchedules: types.array(ScheduleManagerModel),
    calendarGoogleSchedules: types.array(GoogleScheduleModel),
    calendarStartAt: types.optional(types.string, ''),
    calendarEndAt: types.optional(types.string, ''),

    // 연차/근무시간 통계, 연단위 호출
    summaryVacationSchedules: types.array(ScheduleManagerModel),
    summaryOfficeHourSchedules: types.array(ScheduleManagerModel),
    summaryYear: types.optional(types.string, ''),

    weekdayHolidays: types.array(WeekdayHolidayModel),
  })
  .views(self => ({
    get sortedSchedules() {
      return sortBy(self.schedules.slice(), schedule => schedule.startAt);
    },
    get passedSchedules() {
      return sortBy(
        self.schedules.filter(x => moment(x.startAt).isBefore(moment()) === true).slice(),
        schedule => schedule.startAt
      );
    },
    get upcomingSchedules() {
      return sortBy(
        self.schedules.filter(x => moment(x.startAt).isSameOrAfter(moment().subtract(2, 'day')) === true).slice(),
        schedule => schedule.startAt
      );
    },

    // Dashboard 스케줄 관련 함수
    get todayOfficeHours() {
      return sortBy(
        self.dashboardSeesoSchedules.filter(x => x.category === 'ofh' && moment(x.startAt).isSame(moment(), 'day')).slice(),
        schedule => schedule.startAt
      );
    },
    get todayVacations() {
      return sortBy(
        self.dashboardSeesoSchedules.filter(
          x => 
          (
            x.category === 'sch' &&
            // sch 일정의 경우 휴가(vac)가 아니면 endAt이 비어 있음
            (x.endAt ? (
              moment(x.startAt).isSameOrBefore(moment(), 'day') && moment(x.endAt).isSameOrAfter(moment(), 'day')
            ) : (
              moment(x.startAt).isSame(moment(), 'day')
            ))
          )
        ),
        ['allDay', 'startAt']
      );
    },
    get todayMeetings() {
      return sortBy(
        self.dashboardGoogleSchedules.filter(
          x => (
            moment(x.start).isSame(moment(), 'day') || moment(x.end).isSame(moment(), 'day')
          )
        ),
        ['start']
      );
    },       
    get upcomingVacations() {
      return sortBy(
        self.dashboardSeesoSchedules.filter(
          x => x.category === 'sch' && moment(x.startAt).isAfter(moment(), 'day') && moment(x.startAt).isSameOrBefore(moment().add(7, 'day'), 'day')
        ),
        ['allDay', 'startAt']
      );
    },
    get upcomingMeetings() {
      return sortBy(
        self.dashboardGoogleSchedules.filter(
          x => (
            moment(x.start).isAfter(moment(), 'day') && moment(x.start).isSameOrBefore(moment().add(7, 'day'), 'day')
          )
        ),
        ['start']
      );      
    },
    get sortedTodos() {
      return sortBy(
        self.dashboardSeesoSchedules.filter(
          x => x.category === 'tas'
        ), 
        ['taskDone', 'startAt']
      );
    },
    get todayTodos() {
      return sortBy(
        self.dashboardSeesoSchedules.filter(x => x.category === 'tas' && moment(x.startAt).isSame(moment(), 'day') && !x.allDay),
        ['taskDone', 'startAt']
      );
    },
  }))
  .actions(self => {
    const fetchSchedule = flow(function*(id: number) {
       try {
        const { data }: { data: ScheduleManagerResponse } = yield axios.get(
          `/schedule/${id}`
        );

        const schedule = ScheduleManagerModel.create(mapSchedule(data));
        return schedule;
      } catch (e) {
        console.error("fetchSchedules  error", e);
        throw e;
      }
     });

     const fetchSchedules = flow(function*(projectGroup?: string) {
       try {
        const { data }: { data: ScheduleManagerResponse[] } = yield axios.get(
          `/schedule`,
          {
            params: {
              search: projectGroup ? projectGroup : '',
            }
          }
        );

        const schedules = data.map(x =>
          ScheduleManagerModel.create(mapSchedule(x))
        );
        self.schedules.replace(schedules);
      } catch (e) {
        console.error("fetchSchedules  error", e);
        throw e;
      }
     });

     const fetchDashBoardSeesoSchedules = flow(function*() {
      try {
        let startDate = moment().day(0); // 이번주 일요일부터
        let endDate = moment().add(7, 'days').day(6).add(1, 'days');  // 다음주 일요일까지

        const { data }: { data: ScheduleManagerResponse[] } = yield axios.get(
         `/schedule`,
         {
           params: {
             startAt: startDate.format("YYYY-MM-DD"), 
             endAt: endDate.format("YYYY-MM-DD")
           }
         }
        );

       const schedules = data.map(x =>
         ScheduleManagerModel.create(mapSchedule(x))
       );
       self.dashboardSeesoSchedules.replace(schedules);
     } catch (e) {
       console.error("fetchDashBoardSeesoSchedules  error", e);
       throw e;
     }
    });  

    const fetchDashboardCalendarSeesoSchedules = async (startAt: string, endAt: string) => {
      self.calendarStartAt = startAt;
      self.calendarEndAt = endAt;

      await refreshDashboardCalendarSeesoSchedules();
    };

    const refreshDashboardCalendarSeesoSchedules = flow(function*() {
      try {
        const { data }: { data: ScheduleManagerResponse[] } = yield axios.get(
         `/schedule`,
         {
           params: {
             startAt: self.calendarStartAt, 
             endAt: self.calendarEndAt
           }
         }
        );

       const schedules = data.map(x =>
         ScheduleManagerModel.create(mapSchedule(x))
       );

       self.calendarSeesoSchedules.replace(schedules);
     } catch (e) {
       console.error("refreshDashboardCalendarSeesoSchedules  error", e);
       throw e;
     }
    });

    const fetchDashboardGoogleSchedules = flow(function*() {
      try {
        let startDate = moment().day(0); // 이번주 일요일부터
        let endDate = moment().add(7, 'days').day(6).add(1, 'days');  // 다음주 일요일까지

        const { data }: { data: GoogleScheduleResponse[] } = yield axios.get(
         `/schedule/google-schedule`,
         {
           params: {
             startAt: startDate.format("YYYY-MM-DD"), 
             endAt: endDate.format("YYYY-MM-DD")
           }
         }
        );

       const schedules = data.map(x =>
         GoogleScheduleModel.create(mapGoogleSchedule(x))
       );
       self.dashboardGoogleSchedules.replace(schedules);
     } catch (e) {
       console.error("fetchDashboardGoogleSchedules  error", e);
       throw e;
     }
    });  

    const fetchDashboardCalendarGoogleSchedules = async (startAt: string, endAt: string) => {
      self.calendarStartAt = startAt;
      self.calendarEndAt = endAt;

      await refreshDashboardCalendarGoogleSchedules();
    };

    const refreshDashboardCalendarGoogleSchedules = flow(function*() {
      try {
        const { data }: { data: GoogleScheduleResponse[] } = yield axios.get(
         `/schedule/google-schedule`,
         {
           params: {
             startAt: self.calendarStartAt, 
             endAt: self.calendarEndAt
           }
         }
        );

       const schedules = data.map(x =>
         GoogleScheduleModel.create(mapGoogleSchedule(x))
       );
       self.calendarGoogleSchedules.replace(schedules);
     } catch (e) {
       console.error("refreshDashboardCalendarGoogleSchedules  error", e);
       throw e;
     }
    });

    // 연차 및 휴가 현황, 근무시간 현황
    const fetchSummarySchedules = flow(function*(schedule_type:string, year: string) {
      try {
        const { data }: { data: ScheduleManagerResponse[] } = yield axios.get(
          `/schedule/summary-schedule`,
          {
            params: {
              scheduleType: schedule_type,
              startAt: `${year}-01-01`, 
              endAt: `${year}-12-31`
            }
          }
        );

       const schedules = data.map(x =>
         ScheduleManagerModel.create(mapSchedule(x))
       );
       
       if(schedule_type === 'vacation') {
        self.summaryVacationSchedules.replace(schedules);
       } else if(schedule_type === 'officehour') {
         self.summaryOfficeHourSchedules.replace(schedules);
       }

     } catch (e) {
       console.error("fetchSummarySchedules  error", e);
       throw e;
     }
    });

    // 유틸리티 함수
    const fetchWeekdayHolidays = flow(function*() {
      // 공휴일 정보는 한 번만 호출하도록 함
      if (self.weekdayHolidays.length > 0) { return; }
      try {
        const { data }: { data: any } = yield axios.get(
          `/schedule/weekday-holidays`
        );
        self.weekdayHolidays.replace(data.map((x: any) => {
          return WeekdayHolidayModel.create({ date: x.date, name: x.name });
        }))
      } catch (e) {
        console.error("fetchWeekdayHolidays error", e);
        throw e;
      }
    });    
    
    return {
      fetchSchedule,
      fetchSchedules,
      // 대시보드 스케줄
      fetchDashBoardSeesoSchedules,
      fetchDashboardCalendarSeesoSchedules,
      refreshDashboardCalendarSeesoSchedules,
      // 대시보드 구글 스케줄
      fetchDashboardGoogleSchedules,
      fetchDashboardCalendarGoogleSchedules,
      refreshDashboardCalendarGoogleSchedules,
      // 연차, 근무시간 현황 관리
      fetchSummarySchedules,
      // 유틸리티 함수
      fetchWeekdayHolidays
    };
  });

type ScheduleManagerStoreModelType = typeof ScheduleManagerStoreModel.Type;
export interface ScheduleManagerStore extends ScheduleManagerStoreModelType {}
