import React, { Component } from "react";
import styled from "styled-components/macro";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import {
  Button,
  Intent
} from "@blueprintjs/core";
import ClientCalculateRequestRow from "../../molecules/CalculateRow/ClientCalculateRequestRow";

const Wrapper = styled.div`
  margin-bottom: 20px;
  div.hide {
    display: none;
  }
  div.collapse_msg {
    color: #C23030;
    font-size: 12px;
    margin-bottom: 10px;
    margin-left: 30px;
    pointer: cursor;
    button {
      margin-left: 5px;
      margin-right: 10px;
    }
    .total_request {
      font-weight: 600;
    }
  }
`;

interface ClientCalculateRequestListPanelProps {}

interface InjectedProps extends ClientCalculateRequestListPanelProps {
  appStore: AppStore;
}


@inject("appStore")
@observer
class ClientCalculateRequestListPanel extends Component<ClientCalculateRequestListPanelProps> {
  visibleCount = 5;
  @observable showCollapse = false;

  get injected() {
    return this.props as InjectedProps;
  }

  get calculateStore() {
    return this.injected.appStore.calculateStore;
  }

  async componentDidMount() {
    this.calculateStore.fetchClientCalculateNotIssued();
  }

  render() {
    const {
      showCollapse,
      visibleCount,
      calculateStore
    } = this;
    const { clientCalculatesNotIssued } = calculateStore;
    const rowCount = clientCalculatesNotIssued.length;

    return (
      <Wrapper>

        {
          rowCount > visibleCount &&
            <div
              className='collapse_msg'
              onClick={ () => { this.showCollapse = !this.showCollapse }}>
              * { rowCount - visibleCount }개의 입금요청 건이 더 존재합니다.

              <Button
                small={true}
                intent={ Intent.DANGER }
                icon={this.showCollapse ? 'double-chevron-up' : 'double-chevron-down'} />

              <span className='total_request'>
                (입금요청 건 총: { rowCount })
              </span>
            </div>
        }

        {
          clientCalculatesNotIssued
            .map((calculate, index) => (
              <ClientCalculateRequestRow
                key={index}
                calculate={calculate}
                className={!showCollapse && index >= visibleCount ? 'hide' : ''} />
          ))
        }

      </Wrapper>
    );
  }
}

export default ClientCalculateRequestListPanel;
