import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { observable } from "mobx";
import { FormGroup, Button, Dialog, Classes, Intent } from "@blueprintjs/core";

import axios from "axios";

const FormValue = styled.div`
  line-height: 30px;
`;

interface ClientUserCompanyInfoPopupProps {
  id: number;
  isOpened: boolean;
  onClose: () => void;
}

interface InjectedProps extends ClientUserCompanyInfoPopupProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ClientUserCompanyInfoPopup extends Component<
  ClientUserCompanyInfoPopupProps
> {

  @observable companyName: string = "";
  @observable clientType: string = "";
  @observable identityNumber: string = "";
  @observable representativeName: string = "";
  @observable address: string = "";
  @observable contractPersonName: string = "";
  @observable contractPersonEmail: string = "";
  @observable contractPersonPhone: string = "";
  @observable taxPersonName: string = "";
  @observable taxPersonEmail: string = "";
  @observable taxPersonPhone: string = "";
  @observable bizFileInfo: any = null;
  @observable bnkFileInfo: any = null;

  get injected() {
    return this.props as InjectedProps;
  }

  handleOpening = () => {

    if(this.props.id > 0) {
      axios.get(
        `/clientPersonal?auth_id=${this.props.id}`
      )
      .then((response) => {
  
        const data = response.data;
        if(data.length > 1) {
          this.companyName = data[1].name;
          this.clientType = data[1].client_type;
          this.identityNumber = data[1].identity_number;
          this.representativeName = data[1].representative_name;
          this.address = data[1].address;
          this.contractPersonName = data[1].contract_person.name;
          this.contractPersonEmail = data[1].contract_person.email;
          this.contractPersonPhone = data[1].contract_person.phone;
          this.taxPersonName = data[1].tax_person.name;
          this.taxPersonEmail = data[1].tax_person.email;
          this.taxPersonPhone = data[1].tax_person.phone;
        } else {
          this.companyName = "";
          this.clientType = "";
          this.identityNumber = "";
          this.representativeName = "";
          this.address = "";
          this.contractPersonName = "";
          this.contractPersonEmail = "";
          this.contractPersonPhone = "";
          this.taxPersonName = "";
          this.taxPersonEmail = "";
          this.taxPersonPhone = "";          
        }
      });

      axios.get(
        `/clientFiles`,
        {
          params: {
            auth_id: this.props.id
          }
        }
      )
      .then((response) => {
        const data = response.data;

        const biz = data.find((x: any) => x.filetype === 'BIZ');
        if(biz) {
          this.bizFileInfo = biz;
        }
        const bnk = data.find((x: any) => x.filetype === 'BNK');
        if(bnk) {
          this.bnkFileInfo = bnk;
        }        
      });
    }
  }

  render() {
    const { isOpened, onClose } = this.props;
    return (
      <Dialog
        onOpening={this.handleOpening}
        isOpen={isOpened}
        onClose={onClose}
        usePortal={true}
        autoFocus={true}
        enforceFocus={true}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        title={'회사정보'}      
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup label={'회사이름'} inline={true}><FormValue>{this.companyName}</FormValue></FormGroup>
          <FormGroup label={'회사종류'} inline={true}><FormValue>{this.clientType}</FormValue></FormGroup>
          <FormGroup label={'대표자이름'} inline={true}><FormValue>{this.representativeName}</FormValue></FormGroup>
          <FormGroup label={'사업자등록번호'} inline={true}><FormValue>{this.identityNumber}</FormValue></FormGroup>
          <FormGroup label={'회사주소'} inline={true}><FormValue>{this.address}</FormValue></FormGroup>
          <FormGroup label={'계약담당자'}>
            <div>이름 : {this.contractPersonName}</div>
            <div>이메일 : {this.contractPersonEmail}</div>
            <div>연락처 : {this.contractPersonPhone}</div>
          </FormGroup>
          <FormGroup label={'세금계산서담당자'}>
            <div>이름 : {this.taxPersonName}</div>
            <div>이메일 : {this.taxPersonEmail}</div>
            <div>연락처 : {this.taxPersonPhone}</div>
          </FormGroup>
          <FormGroup label={'첨부파일'}>
            <div>사업자등록증: {this.bizFileInfo ? (
              <Button
                text={this.bizFileInfo.filename}
                minimal={true}
                onClick={() => {window.open(this.bizFileInfo.file, "_blank")}}
              />              
            ) : (<span>없음</span>)} </div>
            <div>통장사본: {this.bnkFileInfo ? (
              <Button
                text={this.bnkFileInfo.filename}
                minimal={true}
                onClick={() => {window.open(this.bnkFileInfo.file, "_blank")}}
              />              
            ) : (<span>없음</span>)}</div>
          </FormGroup>        
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            
            <Button text="닫기" onClick={onClose} />
            <Button
              text="정보반영하기"
              onClick={() => {
                const { currentForm: form } = this.injected.appStore.clientFormStore;
                form.setName(this.companyName);
                form.setClientType(this.clientType);
                form.setIdentityNumber(this.identityNumber);
                form.setRepresentativeName(this.representativeName);
                form.setAddress(this.address);
                form.setContractPersonName(this.contractPersonName);
                form.setContractPersonEmail(this.contractPersonEmail);
                form.setContractPersonPhone(this.contractPersonPhone);
                form.setTaxPersonName(this.taxPersonName);
                form.setTaxPersonEmail(this.taxPersonEmail);
                form.setTaxPersonPhone(this.taxPersonPhone);

                const formStore = this.injected.appStore.clientFormStore;
                formStore.importFileFromFO(this.bizFileInfo, this.bnkFileInfo);

                this.props.onClose();
              }}
              intent={Intent.SUCCESS}
            />
          </div>
        </div>        
      </Dialog>  
    );
  }
}

export default ClientUserCompanyInfoPopup;
