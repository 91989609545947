import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import {
  Spinner,
  Intent,
  Alert,
  Menu,
  MenuItem,
  Popover,
  Position,
  Checkbox,
  Classes, ProgressBar, Dialog
} from "@blueprintjs/core";
import { computed, observable } from "mobx";
import { FormMode } from "../../../types/formMode";
import moment from "moment";
import { Redirect } from "react-router";
import InspectionCard from "../../organisms/InspectionCard/InspectionCard";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import LargeContainer from "../../atoms/LargeContainer/LargeContainer";
import IconCancel from "./icons/ic-cancel.svg";
import IconDelete from "./icons/ic-delete.svg";
import IconDownload from "./icons/ic-download.svg";
import IconEdit from "./icons/ic-edit.svg";
import IconSchedule from "./icons/ic-schedule.svg";
import IconBudget from "./icons/ic-budget.svg";
import IconSend from "./icons/ic-send.svg";
import IconCopy from "./icons/ic-copy.svg";


// @ts-ignore
import AnchorLink from "react-anchor-link-smooth-scroll";

import {
  PageNavWrapper,
  PageNavText,
  PageNavLink
} from "../../atoms/PageNav/PageNav";
import { AddCircle } from "styled-icons/remix-line/AddCircle";
import { BreadCrumb } from "../../molecules/BreadCrumb/BreadCrumb";
import {
  MetaHead,
  SubTitle,
  Title,
  HeadMenu,
  CardContainer
} from "../../atoms/DetailReportComponents/DetailReportComponents";
import InspectionMailSendPopup from "../../organisms/InspectionMailSendPopup/InspectionMailSendPopup";
import { InspectionMail } from "../../../store/models/InspectionMail";
import { Inspection } from "../../../store/models/Inspection";
import RecipientPopup from "../../molecules/Inspection/RecipientPopup";

const StyledHeadMenu = styled(HeadMenu)`
  & > * + * {
    margin-left: 0;
  }
`;

const MenuButton = styled.button`
  display: inline-block;
  width: 85px;
  height: 70px;
  text-align: center;
  background: none;
  border: none;
  cursor: pointer;
`;

const MenuButtonIcon = styled.div<{ src: string }>`
  background: url(${p => p.src}) no-repeat center center;
  width: 57px;
  height: 57px;
  margin: auto;
`;

const MenuButtonText = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  position: relative;
  top: -5px;
`;

const HistoryUrl = styled.a`
  color: #ff5400;
  font-size: 13px;
  position: absolute;
  bottom: 10px;
`;

interface InspectionDetailPanelProps {
  mode: FormMode;
  inspectionId: string;
}

interface InjectedProps extends InspectionDetailPanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class InspectionDetailPanel extends Component<InspectionDetailPanelProps> {
  scheduleRef: React.RefObject<HTMLDivElement> = React.createRef();

  @observable goToEdit = false;
  @observable duplicatedInspectionId: string | null = null;
  @observable isRemoveAlertOpen = false;
  @observable isRemoveSuccess = false;
  @observable showSchedule = false;

  @observable mailToSend: InspectionMail | null = null;
  @observable mailPreviewHtml: string | null = null;
  @observable mailPreviewSubject: string | null = null;

  @observable showVatIncluded = true;

  @observable downloadLoadingOpen = false;
  @observable downloadLoadingPercent: number = 0;

  @observable isRecipientPopup = false;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get inspection() {
    const { inspectionId } = this.props;
    return this.injected.appStore.inspectionStore.inspections.find(
      x => x.inspectionId === inspectionId + ""
    );
  }

  @computed
  get notificationTemplateStore() {
    return this.injected.appStore.notificationTemplateStore;
  }

  componentDidMount() {
    const { inspectionId } = this.props;
    return this.injected.appStore.inspectionStore.fetchInspectionById(
      inspectionId
    );
  }

  previewSendInspection = async (inspection: Inspection) => {
    if (inspection) {
      const previewMail: InspectionMail = await this.injected.appStore.inspectionStore.previewMailInspection(
        inspection
      );
      console.log(previewMail);
      this.mailToSend = previewMail;
    }
  };

  handleEditClick = () => {
    this.goToEdit = true;
  };

  handlePopupClose = () => {
    this.mailToSend = null;
  };

  handleSendInpsection = async () => {
    const { inspection } = this;
    if (inspection) {
      await this.injected.appStore.inspectionStore.sendMailInspection(
        inspection
      );
      this.mailToSend = null;
    }
  };

  handlePublish = async () => {
    try {
      const { inspection } = this;

      if (inspection) {
        await this.injected.appStore.inspectionStore.toggleInspectionPublished(
          inspection
        );
        await this.injected.appStore.inspectionStore.fetchInspectionById(
          inspection.inspectionId,
          false
        );

      }
    } catch (e) {
    }
  };

  handleDuplicate = async () => {
    try {
      const { inspection } = this;

      if (inspection) {
        const duplicated = await this.injected.appStore.inspectionStore.duplicateInspection(
          inspection
        );
        this.duplicatedInspectionId = duplicated.inspection_id;
      }
    } catch (e) {
    }
  };

  handleSetRemove = (value: boolean) => {
    this.isRemoveAlertOpen = value;
  };

  handleDelete = async () => {
    try {
      const { inspection } = this;

      if (inspection) {
        await this.injected.appStore.inspectionStore.deleteInspection(
          inspection
        );
        this.handleSetRemove(false);
        this.isRemoveSuccess = true;
      }
    } catch (e) {
    }
  };

  handlePdfDownload = async () => {
    const { inspection } = this;

    if (!inspection) {
      AppToaster.show({
        message: "검수서가 존재하지 않습니다.",
        intent: Intent.DANGER
      });

      return;
    }


    if (!inspection.publishedDate) {
      AppToaster.show({
        message: "발행 후 다운로드 해주세요.",
        intent: Intent.DANGER
      });

      return;
    }
    this.downloadLoadingOpen = true;
    this.downloadLoadingPercent = 0.1;
    const interval = setInterval(() => {
      this.downloadLoadingPercent += Math.random() * (0.15 - 0.01) + 0.01;
    }, 500);
    let cost = 0;
    for (let i: number = 0; i < 22; i++) {
      cost += inspection.getTotalCostOfSprint(i);
    }

    const budget = this.showVatIncluded && inspection.vatIncluded ? cost : inspection.budget;
    await this.injected.appStore.inspectionStore.downloadPDF(
      inspection, budget, this.showVatIncluded && inspection.vatIncluded
    );
    clearInterval(interval);
    this.downloadLoadingPercent = 1;
    this.downloadLoadingOpen = false;
  };

  setShowSchedule = (status: boolean) => {
    this.showSchedule = status;
    if (status)
      window.scrollTo(0, document.body.scrollHeight - 2200);
    else
      window.scrollTo(0, document.body.scrollHeight - 1800);
  };

  renderProjectgroupIcon() {
    const { inspection } = this;
    if (!inspection) {
      return (<></>);
    }

    if (inspection.projectgroupIds.length > 1) {
      const menu = (
        <Menu>
          {
            inspection.projectgroupIds.map(projectgroup => (
              <Link to={`/project-groups/${projectgroup.group_id}`} target="_blank">
                <MenuItem key={`pgi_${projectgroup.group_id}`}
                          text={`${projectgroup.name} - ${projectgroup.group_id}`}/>
              </Link>
            ))
          }
        </Menu>
      );

      return (
        <Popover content={menu} position={Position.BOTTOM}>
          <MenuButton>
            <MenuButtonIcon src={IconCopy}/>
            <MenuButtonText>프로젝트그룹</MenuButtonText>
          </MenuButton>
        </Popover>
      );
    }

    if (inspection.projectgroupIds.length === 1) {
      return (
        <Link to={`/project-groups/${inspection.projectgroupIds[0].group_id}`} target="_blank">
          <MenuButton>
            <MenuButtonIcon src={IconCopy}/>
            <MenuButtonText>{inspection.projectgroupIds[0].group_id}</MenuButtonText>
          </MenuButton>
        </Link>
      );
    }

    return (
      <Link
        to={{
          pathname: "/project-groups/new",
          search: `?inspection=${inspection.inspectionId}`
        }} target="_blank">
        <MenuButton>
          <MenuButtonIcon src={IconCopy}/>
          <MenuButtonText>프로젝트그룹 생성</MenuButtonText>
        </MenuButton>
      </Link>
    );
  }

  render() {
    const {
      inspection,
      duplicatedInspectionId,
      goToEdit,
      isRemoveSuccess
    } = this;

    const { isPM } = this.injected.appStore.userStore;

    if (isRemoveSuccess) {
      return <Redirect to="/inspections"/>;
    }

    if (!inspection) {
      return <Spinner/>;
    }

    if (goToEdit) {
      return (
        <Redirect
          to={`/inspections/${inspection.inspectionId}/edit`}
          push={true}
        />
      );
    }

    if (duplicatedInspectionId) {
      return (
        <Redirect
          to={`/inspections/${duplicatedInspectionId}/edit`}
          push={true}
        />
      );
    }

    return (
      <LargeContainer>
        <PageNavWrapper>
          <BreadCrumb
            items={[
              { name: "프로젝트 플랜 목록", link: "/inspections" },
              "플랜 상세"
            ]}
          />
          {isPM && (
            <PageNavLink to="/inspections/new">
              <PageNavText>
                <AddCircle size={21}/> <span>새 플랜</span>
              </PageNavText>
            </PageNavLink>
          )}
        </PageNavWrapper>
        <MetaHead>
          <div>
            <SubTitle>
              최종수정 {moment(inspection.updatedAt).format("YYYY/MM/DD")}
            </SubTitle>

          </div>

          <Title>{inspection.title}</Title>
          {isPM && inspection.historyUrl && (
            <HistoryUrl target="_blank" href={inspection.historyUrl}>
              {inspection.historyUrl}
            </HistoryUrl>
          )}
          <StyledHeadMenu>
            {this.renderProjectgroupIcon()}
            <AnchorLink href="#budget">
              <MenuButton>
                <MenuButtonIcon src={IconBudget}/>
                <MenuButtonText>예산안</MenuButtonText>
              </MenuButton>
            </AnchorLink>
            <AnchorLink href="#schedule">
              <MenuButton>
                <MenuButtonIcon src={IconSchedule}/>
                <MenuButtonText>스케줄</MenuButtonText>
              </MenuButton>
            </AnchorLink>
            <MenuButton onClick={this.handlePdfDownload}>
              <MenuButtonIcon src={IconDownload}/>
              <MenuButtonText>PDF 다운로드</MenuButtonText>
            </MenuButton>
            {isPM && (
              <>
                {inspection.publishedDate ? (
                  <MenuButton onClick={this.handlePublish}>
                    <MenuButtonIcon src={IconCancel}/>
                    <MenuButtonText>발행 취소</MenuButtonText>
                  </MenuButton>
                ) : (
                  <MenuButton onClick={this.handlePublish}>
                    <MenuButtonIcon src={IconCancel}/>
                    <MenuButtonText>발행 하기</MenuButtonText>
                  </MenuButton>
                )}
                {<MenuButton onClick={() => {
                  this.isRecipientPopup = true;

                }}>
                  <MenuButtonIcon src={IconSend}/>
                  <MenuButtonText>이메일 발송</MenuButtonText>
                </MenuButton>}


                <MenuButton onClick={this.handleEditClick}>
                  <MenuButtonIcon src={IconEdit}/>
                  <MenuButtonText>수정 하기</MenuButtonText>
                </MenuButton>
                <MenuButton onClick={() => this.handleSetRemove(true)}>
                  <MenuButtonIcon src={IconDelete}/>
                  <MenuButtonText>삭제 하기</MenuButtonText>
                </MenuButton>
              </>
            )}
          </StyledHeadMenu>
        </MetaHead>
        <CardContainer id="inspection">
          <InspectionCard
            showVatIncluded={this.showVatIncluded}
            inspection={inspection}
            showSchedule={this.showSchedule}
            scheduleRef={this.scheduleRef}
          >
            {inspection.vatIncluded ?
              <Checkbox checked={this.showVatIncluded && inspection.vatIncluded}
                        disabled={!inspection.vatIncluded}
                        onChange={() => {
                          this.showVatIncluded = !this.showVatIncluded;
                        }}
              >
                VAT 포함보기
              </Checkbox> : null
            }
          </InspectionCard>
        </CardContainer>

        <Alert
          cancelButtonText="취소"
          confirmButtonText="삭제"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isRemoveAlertOpen}
          onCancel={() => this.handleSetRemove(false)}
          onConfirm={this.handleDelete}
          canEscapeKeyCancel={true}
          canOutsideClickCancel={true}
        >
          <p>플랜을 삭제하시겠습니까?</p>
        </Alert>
        <InspectionMailSendPopup
          inspectionMail={this.mailToSend}
          onClose={this.handlePopupClose}
          onSend={this.handleSendInpsection}
        />
        <Dialog
          isOpen={this.downloadLoadingOpen}
          onClose={() => {
            this.downloadLoadingPercent = 0;
          }}
          isCloseButtonShown={false}
          autoFocus={true}
          enforceFocus={true}
        >
          <div className={Classes.DIALOG_BODY}>
            <ProgressBar animate={true} intent={Intent.PRIMARY} value={this.downloadLoadingPercent}/>
          </div>
        </Dialog>

        <RecipientPopup
          pm={inspection.inspectorName || ""}
          projectGroupName={"projectGroup.name"}
          inspection={inspection}
          to={inspection.requesterEmail ? [] : [inspection.requesterEmail]}
          cc={inspection.inspectorEmail ? [inspection.inspectorEmail] : ["support@seeso.kr"]}
          onClose={async () => {
            this.isRecipientPopup = false;
          }}
          onAfterSend={async () => {
            await this.injected.appStore.inspectionStore.fetchInspectionById(
              inspection.inspectionId,
              false
            );
          }}
          isOpened={this.isRecipientPopup}/>
      </LargeContainer>

    );
  }
}

export default InspectionDetailPanel;