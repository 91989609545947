import { types } from "mobx-state-tree";

export const ProjectGroupRiskModel = types.model("ProjectGroupRisk", {
  checked: types.boolean,
  content: types.string,
  etc: types.boolean,
  title: types.string
});

type ProjectGroupRiskType = typeof ProjectGroupRiskModel.Type;
export interface ProjectGroupRisk extends ProjectGroupRiskType {}
