import React, { FC } from "react";
import styled from "styled-components/macro";
import { ChevronRight } from "styled-icons/feather/ChevronRight";

const Wrapper = styled.span`
  width: 8px;
  height: 8px;
  margin: 0 5px;
  position: relative;
  top: -1px;
`;

export const BreadCrumbDivider: FC = () => (
  <Wrapper>
    <ChevronRight style={{ width: "24px" }} />
  </Wrapper>
);
