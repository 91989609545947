import React, { Component, ChangeEvent, KeyboardEvent } from "react";

import { observable, computed } from "mobx";
import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import { Button, InputGroup, Intent } from "@blueprintjs/core";

import { AppStore } from "../../../store/AppStore";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";

const Section = styled.section`
  width: 260px;
  background-color: #e3e3e3;
  border-radius: 3px;
  margin: 5px 5px;
  position: relative;
  padding: 10px;
  display: inline-flex;
  height: auto;
  max-height: 90%;
  flex-direction: column;
`;

const AddLaneTitleEditor = styled(InputGroup)`

`;

const AddLaneTitleAddButton = styled(Button)`
`; 

const AddLaneTitleCancelButton = styled(Button)`
`; 

interface AddTaskListFormProps {
  onAdd: (values: object) => void;
  onCancel: () => void;
}

interface InjectedProps extends AddTaskListFormProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class AddTaskListForm extends Component<AddTaskListFormProps> {
  @observable inputComponent: any = null;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get formStore() {
    return this.injected.appStore.taskManagerTaskListFormStore;
  }

  @computed
  get currentForm() {
    return this.formStore.currentForm;
  }

  @computed
  get board() {
    return this.injected.appStore.taskManagerStore.currentBoard;
  }

  componentDidMount() {
    this.formStore.initForm('', this.board ? this.board.taskBoardId : '');
    if(this.inputComponent) {
      this.inputComponent.focus();
      this.inputComponent.onkeypress = this.handleOnKeyPress;
    }
  }

  handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.currentForm.setTitle(e.target.value);
  };
  handleOnKeyPress = async(event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      if(this.currentForm.title.trim() !== '') {
        this.handleSubmit();
      }
    }
  }  

  handleSubmit = async () => {
    try {
      await this.formStore.postTaskList();
      AppToaster.show({
        message: "새로운 태스크 목록이 등록되었습니다.",
        intent: Intent.SUCCESS
      });
      this.props.onAdd({});
      
      // 업데이트된 내용을 반영
      await this.injected.appStore.taskManagerStore.fetchTaskBoard(this.board ? this.board.projectGroup : '');
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  }

  render() {
    return (
      <Section>
        <div>
          <AddLaneTitleEditor
            inputRef={(input: any) => {this.inputComponent=input}}
            placeholder={'목록 타이틀을 입력해주세요'}
            rightElement={(
              <>
                <AddLaneTitleAddButton 
                  small={true} disabled={this.currentForm.title.trim() === ''}
                  onClick={this.handleSubmit}
                >
                  추가
                </AddLaneTitleAddButton>
                <AddLaneTitleCancelButton 
                  minimal={true}
                  onClick={this.props.onCancel}
                >
                    X
                </AddLaneTitleCancelButton>
              </>
            )}
            value={this.currentForm.title}
            onChange={this.handleTitleChange}
          />
        </div>
      </Section>
    );
  }
}

export default AddTaskListForm;