import React, { Component, ChangeEvent } from "react";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import moment from "moment";
import { Item, Column } from "../../atoms/CardTable/CardTable";

import { Request } from "../../../store/models/Parttime";

interface RequestRowProps {
  request: Request;
  onClick: () => void;
}

interface InjectedProps extends RequestRowProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class RequestRow extends Component<RequestRowProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  render() {
    const { request, onClick } = this.props;

    return (
      <Item onClick={onClick}>
        <Column style={{ maxWidth: "104px" }}>{request.code}</Column>
        <Column style={{ maxWidth: "250px" }}>{request.title}</Column>
        <Column style={{ maxWidth: "270px" }}>{request.type}</Column>
        <Column style={{ maxWidth: "200px" }}>{request.client.name}</Column>
        <Column style={{ maxWidth: "200px" }}>{request.status}</Column>
        <Column style={{ maxWidth: "283px" }}>{moment(request.created).format("YYYY년 MM월 DD일")}</Column>
      </Item>
    );
  }
}

export default RequestRow;
