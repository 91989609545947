/*
  초대코드 발행하기 버튼
*/
import React, { useState } from "react";
import styled from "styled-components/macro";

import { Button, Dialog, Classes, Intent } from '@blueprintjs/core';
import MemberSelection from "../../../molecules/MemberSelection/MemberSelection";
import { IMember } from "../../../../store/models/Member";


const Wrapper = styled.div`
  & {
    display: flex;
    flex-direction: row-reverse;
    padding-right: 30px;
    padding-bottom: 10px;
  }
`;
interface IProps {
  // search: string,
  // onChangeSearch: (search: string) => void,
  // onClear: () => void,
  // onSearch: () => void
  onIssue: (memberId: number) => void
}
const IssueInvitation = (props: IProps) => {
  const [ showDialog, setShowDialog ] = useState(false);
  const [ selectedMember, setSelectedMember ] = useState<IMember | undefined>(undefined);

  return (
    <Wrapper>
      <Button
        text='초대코드 발행'
        small={ true }
        intent={ Intent.PRIMARY }
        onClick={ () => setShowDialog(true) } />
      <Dialog
        isOpen={ showDialog }
        icon="envelope"
        onClose={ () => setShowDialog(false) }
        title="초대코드 발행">
          <div className={ Classes.DIALOG_BODY }>
            지급대상 선택:
            <MemberSelection
              onChange={ (member: IMember) => setSelectedMember(member) }
              currentValue={ selectedMember }
              blockFetchingData={ false }
              />
          </div>
          <div className={ Classes.DIALOG_FOOTER} >
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                text='닫기'
                small={ true }
                onClick={ () => setShowDialog(false) } />
              <Button
                text='초대코드 발행'
                intent={ Intent.PRIMARY }
                small={ true }
                onClick={ async() => {
                  if(!selectedMember) {
                    return;
                  }

                  await props.onIssue(selectedMember.id);
                  setShowDialog(false);
                }} />
            </div>
          </div>
      </Dialog>
    </Wrapper>
  )
};
export default IssueInvitation;