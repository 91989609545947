import React, { FC } from "react";
import { IButtonProps, Button, Intent } from "@blueprintjs/core";
import styled from "styled-components/macro";

export const AddItemButtonContainer = styled.div`
  padding: 3px;
`;

export const AddItemButton: FC<IButtonProps> = props => {
  return (
    <Button
      icon="add"
      minimal={true}
      intent={Intent.PRIMARY}
      text="추가"
      {...props}
    />
  );
};
