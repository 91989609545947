import { types, flow } from "mobx-state-tree";
// import axios from "axios";
// import moment from "moment";
// import sortBy from "lodash/sortBy";
// import { CalculateByContractResponse } from "../../types/Calculate";
// import { ClientCalculateModel } from "./ClientCalculate";
// import { RufreeCalculateModel } from "./RufreeCalculate";
// import { ServiceCalculateModel } from "./ServiceCalculate";

const withVAT = (amount: number) => {
  return Math.floor(Math.round(amount * 1.1)/10) * 10;
}


export const CalculateByProjectgroupModel = types
  .model("CalculateByProjectgroup", {
    groupId: types.string,
    groupName: types.string,
    clientId: types.string,
    clientName: types.string,
    inspectionAmount: types.number,
    depositAmount: types.number,
    status: types.string
  })
  .views(self => ({
    // get inspectionAmountWithVAT() {
    //   return withVAT(self.inspectionAmount);
    // },
    // get depositAmountWithVAT() {
    //   return withVAT(self.depositAmount);
    // }
  }))
  .actions(self => {

    return{
    };
  });

type CalculateByProjectgroupModelType = typeof CalculateByProjectgroupModel.Type;
export interface ICalculateByProjectgroup extends CalculateByProjectgroupModelType {}
