import React, { Component, ChangeEvent, FormEvent } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { FormMode } from "../../../types/formMode";
import { RouteComponentProps, Redirect } from "react-router";
import { computed, observable } from "mobx";
import { Client } from "../../../store/models/Client";
import { PMUser } from "../../../store/models/PMUser";
import {
  Callout,
  HTMLTable,
  Spinner,
  FormGroup,
  InputGroup,
  RadioGroup,
  Radio,
  Checkbox,
  TagInput,
  TextArea,
  Button,
  Intent,
  FileInput,
  H3,
  HTMLSelect,
  Icon, IOptionProps
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { DateInput } from "@blueprintjs/datetime";
import { getMomentFormatter } from "../../../utils/date";
import FormContainer from "../../atoms/FormContainer/FormContainer";
import FormSection from "../../atoms/FormSection/FormSection";
import InspectionSelectionFormView from "../ProjectGroupFormPage/InpsectionSelectionFormView";
import PMUserSelection from "../../molecules/PMUserSelection/PMUserSelection";
import ClientSelection from "../../molecules/ClientSelection/ClientSelection";
import RufreeExperienceFormView from "../../molecules/RufreeExperienceFormView/RufreeExperienceFormView";
import {
  ItemRow,
  RemoveButton,
  RemoveButtonContainer
} from "../../atoms/RemoveItemButton/RemoveItemButton";
import {
  AddItemButton,
  AddItemButtonContainer
} from "../../atoms/AddItemButton/AddItemButton";
import ContentContainer from "../../atoms/ContentContainer/ContentContainer";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import moment from "moment";
import {
  InspectionSelectionForm,
  InspectionSelectionFormModel
} from "../../../store/forms/ProjectGroupForm/InspectionSelectionForm";
import TemporaryStorage from "../../molecules/Utility/TemporaryStorage/TemporaryStorage";

const Wrapper = styled.div``;
const ButtonsRow = styled.div`
  margin-bottom: 50px;
  & > * + * {
    margin-left: 5px;
  }
`;
const FlexRow = styled.div`
  display: flex;
  & > * + * {
    margin-left: 5px;
  }
`;
const CalloutWrapper = styled(Callout)`
  padding-top: 0;
`;
const RefreshIcon = styled(Icon)`
  margin-top: 5px;
  margin-left: 10px;
`;
const RightAlignDiv = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

interface PageParams {
  id: string;
}

interface ClientContractFormPageProps extends RouteComponentProps<PageParams> {
  mode: FormMode;
}

interface InjectedProps extends ClientContractFormPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ClientContractFormPage extends Component<ClientContractFormPageProps> {
  @observable isFormInitiated = false;
  @observable clientType = '';
  @observable representativeName = '';
  @observable identityNumber = '';
  @observable address = '';

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get formStore() {
    return this.injected.appStore.contractFromStore;
  }

  @computed
  get currentForm() {
    return this.formStore.clientContractForm;
  }

  @computed
  get selectedPM() {
    if(this.currentForm.pmUser) {
      return this.injected.appStore.pmUserStore.pmUsers.find(
        x => x.id === this.currentForm.pmUser
      );
    } else {
      const { email } = this.injected.appStore.userStore;
      return this.injected.appStore.pmUserStore.pmByEmail(email);
    }
  }

  @computed
  get selectedClient() {
    return this.injected.appStore.clientStore.clients.find(
      x => x.clientId === this.currentForm.client
    );
  }

  redirectToDetail() {
    this.props.history.push(`/contract-request/client/${this.currentForm.contractId}/read`);
  }

  updateClientInfo(client: Client) {
    this.clientType = client.clientType;
    this.representativeName = client.representativeName;
    this.identityNumber = client.identityNumber;
    this.address = client.address;

    this.currentForm.setClient(client.clientId);
    this.currentForm.setAddress(this.address);
    this.currentForm.setRepresentativeName(this.representativeName);
  }

  async componentDidMount() {
    await this.injected.appStore.clientStore.fetchClients();
    await this.formStore.initClientContractForm();

    const { id } = this.props.match.params;

    if (id) {
      await this.formStore.fetchClientContract(id);
    }

    this.selectedClient && this.updateClientInfo(this.selectedClient)

    this.isFormInitiated = true;
  }

  handleClientChange = (client: Client) => {
    this.updateClientInfo(client);
  };

  handleRefreshClientClick = async () => {
    if(this.selectedClient) {
      const client = await this.injected.appStore.clientStore.fetchClient(this.selectedClient.clientId)
      if(client){
        this.updateClientInfo(client);
      }
    }
  }

  handleCancel = () => {
    if (this.props.mode === FormMode.Create) {
      this.props.history.push(`/contract?tab=request&type=client`);
    } else {
      this.redirectToDetail();
    }
  }

  handleEditButtonClick = () => {
    const { id } = this.props.match.params;
    this.props.history.push(`/contract-request/client/${id}/edit`);
  }

  handleCreateSubmit = async () => {
    try {
      await this.formStore.postClientContract();
      AppToaster.show({
        message: "새로운 클라이언트 계약 요청이 생성되었습니다.",
        intent: Intent.SUCCESS
      });

      this.redirectToDetail();
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  handleEditSubmit = async () => {
    try {
      await this.formStore.patchClientContract();

      AppToaster.show({
        message: "수정사항이 반영되었습니다.",
        intent: Intent.SUCCESS
      });

      this.redirectToDetail();
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  render() {
    if (!this.isFormInitiated) {
      return <Spinner />;
    }

    const { currentForm } = this;

    const contractTypeOptions: IOptionProps[] = [
      { label: "팀모델", value: "팀모델(디폴트)" }, { label: "일반", value: "일반" },
      { label: "시간제 태스크", value: "시간제 태스크" }
    ];
    return (
      <Wrapper>
        <H3>
          클라이언트 계약 요청
          { this.props.mode === FormMode.Create && (<> 생성하기</>) }
          { this.props.mode === FormMode.Edit && (<> 수정하기</>) }
        </H3>

        <TemporaryStorage keyName={"ClientContractRequest"} title={"contractName"} setForm={(val) => {
          this.formStore.setClientContractForm(val);
        }} currentForm ={this.currentForm} />

        <FormContainer>
          <FormSection>
            <FormGroup label={<strong>계약 타입</strong>}>
              <HTMLSelect
                value={currentForm.contractType}
                onChange={(e) => {
                  currentForm.setContractType(e.target.value);
                }}
                options={contractTypeOptions}
              />
            </FormGroup>
            <FormGroup label={<strong>담당매니저</strong>}>
              <PMUserSelection
                onChange={(selectedValue: PMUser) => {currentForm.setPmUser(selectedValue.id);}}
                currentValue={this.selectedPM}
                blockFetchingData={false}
              />
            </FormGroup>
            <FormGroup label={<strong>계약명(프로젝트명)</strong>}>
              <InputGroup
                value={currentForm.contractName}
                onChange={
                  (e: ChangeEvent<HTMLInputElement>) => {
                    currentForm.setContractName(e.target.value);
                  }
                }
              />
            </FormGroup>
            <FormGroup label={<strong>클라이언트 선택</strong>}>
              <FlexRow>
                <ClientSelection
                  onChange={this.handleClientChange}
                  currentValue={this.selectedClient}
                  blockFetchingData={true}
                />
                <RefreshIcon
                    icon={IconNames.REFRESH}
                    intent={Intent.PRIMARY}
                    onClick={this.handleRefreshClientClick} />
              </FlexRow>
            </FormGroup>
            <CalloutWrapper>
              {
                this.selectedClient && (
                  <>
                    <HTMLTable
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr><th>타입</th><th>대표자명</th><th>사업자등록번호</th><th>주소</th></tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{this.clientType}</td>
                          <td>{this.representativeName}</td>
                          <td>{this.identityNumber}</td>
                          <td>{this.address}</td>
                        </tr>
                      </tbody>
                    </HTMLTable>

                    <RightAlignDiv>
                      <Button
                        className={'bp3-small bp3-minimal'}
                        text="클라이언트 정보 수정하기"
                        intent={Intent.PRIMARY}
                        onClick={() => {window.open(`/clients/${this.selectedClient && this.selectedClient.clientId}`, '_blank');}}
                      />
                    </RightAlignDiv>
                  </>
                )
              }
            </CalloutWrapper>


          </FormSection>

          <FormSection>
            <FormGroup label={<strong>계약담당자</strong>}>
              <FlexRow>
                <InputGroup placeholder={'이름'}
                  value={currentForm.contractorName}
                  onChange={
                    (e: ChangeEvent<HTMLInputElement>) => {
                      currentForm.setContractorName(e.target.value);
                    }
                  }
                />
                <InputGroup placeholder={'전화번호'}
                  value={currentForm.contractorPhone}
                  onChange={
                    (e: ChangeEvent<HTMLInputElement>) => {
                      currentForm.setContractorPhone(e.target.value);
                    }
                  }
                />
                <InputGroup placeholder={'이메일'}
                  value={currentForm.contractorEmail}
                  onChange={
                    (e: ChangeEvent<HTMLInputElement>) => {
                      currentForm.setContractorEmail(e.target.value);
                    }
                  }
                />
              </FlexRow>
            </FormGroup>

            <FormGroup label={<strong>세금계산서 담당자</strong>}>
              <FlexRow>
                <InputGroup placeholder={'이름'}
                  value={currentForm.taxInvoiceManagerName}
                  onChange={
                    (e: ChangeEvent<HTMLInputElement>) => {
                      currentForm.setTaxInvoiceManagerName(e.target.value);
                    }
                  }
                />
                <InputGroup placeholder={'전화번호'}
                  value={currentForm.taxInvoiceManagerContact}
                  onChange={
                    (e: ChangeEvent<HTMLInputElement>) => {
                      currentForm.setTaxInvoiceManagerContact(e.target.value);
                    }
                  }
                />
                <InputGroup placeholder={'이메일'}
                  value={currentForm.taxInvoiceManagerEmail}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      currentForm.setTaxInvoiceManagerEmail(e.target.value);
                    }}
                />
              </FlexRow>
            </FormGroup>

            <FormGroup label={<strong>NDA 작성요청 여부</strong>}>
              <HTMLSelect
                value={currentForm.nda ? 'Y' : 'N'}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  if(e.currentTarget.value === 'Y') {
                    currentForm.setNda(true);
                  } else {
                    currentForm.setNda(false);
                  }
                }}
                options={['Y', 'N']}
              />
            </FormGroup>
            <FormGroup label={<strong>계약체결일</strong>}>
              <DateInput
                {...getMomentFormatter("YYYY/MM/DD")}
                locale="ko"
                closeOnSelection={true}
                value={currentForm.contractDate ? moment(currentForm.contractDate).toDate() : null}
                onChange={(selectedDate: Date) =>
                  currentForm.setContractDate(moment(selectedDate).format("YYYY-MM-DDT00:00"))
                }
                maxDate={new Date("2050-01-01")}
              />
            </FormGroup>
            <FormGroup label={<strong>계약금비율</strong>}>
              <TextArea
                rows={5}
                className={'bp3-fill'}
                value={currentForm.contractFeeRate ? currentForm.contractFeeRate :
                  `계약체결 후 착수금액 : 1스프린트 비용 + 프로젝트 플래닝 비용
2스프린트 이후 : 각 스프린트 종료 시 각 스프린트에 해당하는 금액을 입금`
                }
                onChange={
                  (e: ChangeEvent<HTMLTextAreaElement>) => {
                    currentForm.setContractFeeRate(e.currentTarget.value);
                  }
                }
              />
            </FormGroup>
          </FormSection>

          <FormSection>
            <FormGroup label={<strong>검수서</strong>}>
              <>
                <AddItemButtonContainer>
                  <AddItemButton
                    onClick={this.currentForm.addInspectionSelection}
                  />
                </AddItemButtonContainer>

                {currentForm.inspections.map((inspection:InspectionSelectionForm) => (
                  <ItemRow key={inspection.id}>
                    <RemoveButtonContainer>
                      <RemoveButton
                        onClick={() =>
                          currentForm.removeInspectionSelection(inspection)
                        }
                      />
                    </RemoveButtonContainer>
                    <InspectionSelectionFormView
                      key={inspection.id}
                      inspectionSelection={inspection}
                    />
                  </ItemRow>
                ))}
              </>
            </FormGroup>
            <FormGroup label={<strong>계약범위</strong>}>
              <TextArea
                className={'bp3-fill'}
                value={currentForm.contractRange}
                onChange={
                  (e: ChangeEvent<HTMLTextAreaElement>) => {
                    currentForm.setContractRange(e.currentTarget.value);
                  }
                }
              />
            </FormGroup>
            <FormGroup label={<strong>특약사항</strong>}>
              <TextArea
                className={'bp3-fill'}
                value={currentForm.specialContract}
                onChange={
                  (e: ChangeEvent<HTMLTextAreaElement>) => {
                    currentForm.setSpecialContract(e.currentTarget.value);
                  }
                }
              />
            </FormGroup>
            <FormGroup label={<strong>비고</strong>}>
              <TextArea
                className={'bp3-fill'}
                value={currentForm.comment}
                onChange={
                  (e: ChangeEvent<HTMLTextAreaElement>) => {
                    currentForm.setComment(e.currentTarget.value);
                  }
                }
              />
            </FormGroup>
          </FormSection>

        </FormContainer>

        <ContentContainer>
            <ButtonsRow>
              <Button text="취소" onClick={this.handleCancel} />
              {
                this.props.mode === FormMode.Create && (
                  <Button
                    text="저장"
                    intent={Intent.PRIMARY}
                    onClick={this.handleCreateSubmit}
                  />)
              }
              {
                this.props.mode === FormMode.Edit && (
                  <Button
                    text="수정사항 적용"
                    intent={Intent.PRIMARY}
                    onClick={this.handleEditSubmit}
                  />
                )
              }
            </ButtonsRow>
        </ContentContainer>
      </Wrapper>
    );
  }
}

export default ClientContractFormPage;
