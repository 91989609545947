import React, { Component } from "react";
import styled from "styled-components/macro";
import { computed, observable } from "mobx";
import { RouteComponentProps } from "react-router";
import { inject, observer } from "mobx-react";

import { AppStore } from "../../../store/AppStore";
import { FormMode } from "../../../types/formMode";

import { Request, Task } from "../../../store/models/Parttime";
import RequestSummaryRow from "../../molecules/ParttimeRow/RequestSummaryRow";
import TaskSummaryRow from "../../molecules/ParttimeRow/TaskSummaryRow";
import RequestFormDialog from "../../molecules/ParttimeDialog/RequestFormDialog";

import { PageNavText, PageNavWrapper, PageNavLink } from "../../atoms/PageNav/PageNav";
import { Button, Tab, TabId, Tabs } from "@blueprintjs/core";
import ContractRequestListPanel from "../../panels/ContractListPanel/ContractRequestListPanel";
import ContractListPanel from "../../panels/ContractListPanel/ContractListPanel";
import ReceivedState from "./ReceivedState";
import ToProjectState from "./ToProjectState";
import ProjectConversionRate from "./ProjectConversionRate";

const Wrapper = styled.div``;

const Container = styled.div``;

const Content = styled.div`
  margin-bottom: 50px;
`;

const PageNavLeftLink = styled(PageNavLink)`
  margin-left: 0;
`;

interface PartTimeDashboardPageProps extends RouteComponentProps {
}

interface InjectedProps extends PartTimeDashboardPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ReceptionStatsPage extends Component<PartTimeDashboardPageProps> {
  @observable currentTab: TabId = "reception";

  @observable isRequestFormDialogOpen: boolean = false;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get requestStore() {
    return this.injected.appStore.parttimeRequestStore;
  }

  @computed
  get taskStore() {
    return this.injected.appStore.parttimeTaskStore;
  }

  handleRequestClick = (request: Request) => {
    this.props.history.push(`/parttime/requests/${request.id}`);
  };

  handleTaskClick = (task: Task) => {
    this.props.history.push(`/parttime/requests/${task.request.id}/tasks/${task.id}`);
  };

  handleRequestFormDialogOpen = () => {
    this.isRequestFormDialogOpen = true;
  };

  handleRequestFormDialogClose = async () => {
    this.isRequestFormDialogOpen = false;
    await this.injected.appStore.parttimeRequestStore.fetchRequests({ pageSize: 5, status: ["SUBMITTED", "CANCELED"] });
  };

  async componentDidMount() {
    try {
      await this.injected.appStore.parttimeRequestStore.fetchRequests({
        pageSize: 5,
        status: ["SUBMITTED", "CANCELED"]
      });
      await this.injected.appStore.parttimeTaskStore.fetchDashboardTasks();
    } catch (e) {
    }
  }

  handleChangeTab = (navbarTabId: TabId) => {
    this.currentTab = navbarTabId;
  };

  render() {
    const { isPM, isClient, isRufree } = this.injected.appStore.userStore;
    const { requests } = this.requestStore;
    const { tasksInProgress, tasksInSettlement } = this.taskStore;

    return (
      <Wrapper>

        {(isPM || isClient) && (
          <Container>
            <PageNavWrapper style={{ height: "40px", paddingTop: "0" }}>
              <PageNavLeftLink to="/receptions/stats">
                <PageNavText>
                  <span>통계</span>
                </PageNavText>
              </PageNavLeftLink>
            </PageNavWrapper>
            <Content>
              <Tabs id="TabsStats" onChange={this.handleChangeTab} selectedTabId={this.currentTab}
                    renderActiveTabPanelOnly={true}>
                <Tab id="reception" title="의뢰접수현황" panel={
                  <ReceivedState {...this.props} />}/>
                <Tab id="project" title="프로젝트전환현황" panel={
                       <ToProjectState {...this.props} />}/>
                <Tab id="receptionToKickoff" title="프로젝트전환율" panel={
                  <ProjectConversionRate {...this.props} />}/>
              </Tabs>
            </Content>
          </Container>
        )}

      </Wrapper>
    );
  }
}

export default ReceptionStatsPage;