import React, { ChangeEvent, Component } from "react";
import moment from "moment";
import { Button, Icon, InputGroup, Intent } from "@blueprintjs/core";
import { observable } from "mobx";
import { observer } from "mobx-react";
import Thumbnail from "../../atoms/Thumbnail/Thumbnail";
import styled from "styled-components/macro";

// import "./TaskAttchmentLinkForm.css";

interface Props {
  link: string;
  id: number;
  created_at: string;
  onRemove: (id: number) => void;
  onEdit: (link: string) => void;
  disabled: boolean | undefined;
}

const TaskEditButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;

  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
`;
const Container = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const RightSection = styled.div`
  padding-left: 10px;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
`;

// @inject("appStore")
@observer
class TaskAttchmentLinkForm extends Component<Props> {
  @observable onHover = false;
  @observable editMode = false;
  @observable tempLink = "";

  render() {
    const { link, onRemove, created_at, id, disabled } = this.props;
    return (
      <div style={{ marginTop: "10px" }}
           onMouseEnter={() => {
             if (!disabled)
               this.onHover = true;
           }}
           onMouseLeave={() => {
             this.onHover = false;
           }}>

        {this.editMode ?
          <Container>
            <div style={{width: "350px" }}>
              <InputGroup placeholder="내용을 입력해주세요."
                          fill={true}
                          value={this.tempLink}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            this.tempLink = e.target.value;
                          }}/>
            </div>
            <div>
              <Button style={{ marginLeft: "10px" }} text={"수정"} onClick={() => {
                this.props.onEdit(this.tempLink);
                this.tempLink = "";
                this.editMode = false;
              }}/>
              <Button style={{ marginLeft: "15px" }} text={"취소"} onClick={() => {
                this.tempLink = "";
                this.editMode = false;
              }}/>
            </div>
          </Container> :
          <Container>
            <div>
              <a href={link} target="_blank">
                <div>
                  <Thumbnail isImage={false} src={""}>
                    <Icon icon={"link"} iconSize={60} intent={Intent.NONE}/>
                  </Thumbnail>
                </div>
              </a>
            </div>
            <RightSection>
              <div>
                <div>{link.length < 45 ? link : link.substring(0, 45) + "..."}</div>
                <div>{moment(created_at).format("YYYY-MM-DDT00:00")}</div>
              </div>
              {
                this.onHover &&
                <div>
                  <Button
                    icon="edit"
                    minimal={true}
                    onClick={() => {
                      this.editMode = true;
                      this.tempLink = link;
                    }}
                  />
                  <Button
                    icon="trash"
                    minimal={true}
                    onClick={() => {
                      onRemove(id);
                    }}
                  />
                </div>
              }
            </RightSection>
          </Container>
        }
      </div>
    );
  }
}

export default TaskAttchmentLinkForm;