import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../store/AppStore";
import { observable, computed } from "mobx";
import { Spinner } from "@blueprintjs/core";
import axios from "axios";
import { withRouter, RouteComponentProps } from "react-router";

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface CheckLoginProps extends RouteComponentProps {}
interface InjectedProps extends CheckLoginProps {
  appStore: AppStore;
}

// const AUTH_TOKEN_KEY = "authtoken";

// const getParamFromLocation = (location: string, key: string) => {
//   return location.replace(
//     /[?&]+([^=&]+)=([^&]*)/gi,
//     function(str, k, v) {
//       if (k === key)
//         return v;
//       else
//         return '';
//     }
//   )
// }

@inject("appStore")
@observer
class CheckLogin extends Component<CheckLoginProps> {
  @observable readyToShow = false;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get isLoggedIn() {
    return this.injected.appStore.userStore.isLoggedIn;
  }

  @computed
  get authToken() {
    return this.injected.appStore.userStore.authToken;
  }

  async componentDidMount() {
    const {
      calculateStore,
      userStore,
      projectGroupStore,
      pmUserStore
    } = this.injected.appStore;
    const authToken = this.authToken;

    window.localStorage.setItem('nexturl', this.props.location.pathname);
    axios.defaults.headers.common["Authorization"] = authToken ? `Bearer ${authToken}` : '';

    // 인증 토큰 먼저 체크 후, axios 헤더 처리.
    if (authToken) {
      userStore.checkLoginStatus();
      try {
        await pmUserStore.fetchPMUsers();
      } catch (e) {
        window.localStorage.removeItem("authtoken");
        axios.defaults.headers.common["Authorization"] = "";
        this.props.history.push(`/login`);
      }
    }

    if (!this.isLoggedIn) {
      // 로그인 상태가 아니면
      this.props.history.push(`/`);
    } else {
      // 로그인 상태면
      const { isPM, email } = userStore;
      

      // PM이 아니면
      if(!isPM) {
        this.injected.appStore.userStore.logout();
        this.props.history.push("/");    
      }

      // PM이면, 프로젝트그룹 필터링 중 매니저 옵션에 본인 기본 설정
      await pmUserStore.fetchPMUsers();
      const pmUser = this.injected.appStore.pmUserStore.pmByEmail(email);
      if(pmUser) {
        if(pmUser.is_support) {
          projectGroupStore.setFilterPM('');
        } else {
          projectGroupStore.setFilterPM(pmUser.name);
        }
      }

      // 미리 fetch할 정보들 처리
      // 클라이언트 정산: /calculate/list/client
      calculateStore.fetchCalculateByClient();
    }

    this.readyToShow = true;
  }

  render() {
    if (!this.readyToShow) {
      return (
        <Wrapper>
          <Spinner />
        </Wrapper>
      );
    }

    return this.props.children;
  }
}

export default withRouter(CheckLogin);
