import React, { Component, ChangeEvent } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { HTMLSelect, InputGroup, Intent, Icon } from "@blueprintjs/core";
import moment from 'moment';

import { AppStore } from "../../../store/AppStore";

// 스타일
const IconButton = styled(Icon)`
  cursor: pointer;
`;

// 상태 관리
interface DashboardOfficeHourPopupInputUnitProps {
  rowKey: number;
  dayOfWeek: number;
  startAt: string;
  endAt: string;
  title: string;
  onStartAtChange: (dayOfWeek: number, rowKey: number, hour: string, minute: string) => void;
  onEndAtChange: (dayOfWeek: number, rowKey: number, hour: string, minute: string) => void;
  onPlaceChange: (dayOfWeek: number, rowKey: number, newValue: string) => void;
  onDeleteClick: (dayOfWeek: number, rowKey: number) => void;
}
interface InjectedProps extends DashboardOfficeHourPopupInputUnitProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class DashboardOfficeHourPopupInputUnit extends Component<DashboardOfficeHourPopupInputUnitProps> {

  // 이벤트 처리
  handleStartAtChange = (e: ChangeEvent<HTMLSelectElement>) => {
    let hour = e.currentTarget.value.substring(0,2);
    let minute = e.currentTarget.value.substring(3);

    this.props.onStartAtChange(
      this.props.dayOfWeek, this.props.rowKey, hour, minute);
  };

  handleEndAtChange = (e: ChangeEvent<HTMLSelectElement>) => {
    let hour = e.currentTarget.value.substring(0,2);
    let minute = e.currentTarget.value.substring(3);

    this.props.onEndAtChange(
      this.props.dayOfWeek, this.props.rowKey, hour, minute);
  };

  handlePlaceChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.props.onPlaceChange(
      this.props.dayOfWeek, this.props.rowKey, e.target.value);
  };

  render() {
    // select 에 추가할 옵션 생성
    let times = [];
    for(let hour=0; hour<24; hour++) {
      times.push(hour < 10 ? '0'+hour+':00' : hour+':00');
      times.push(hour < 10 ? '0'+hour+':30' : hour+':30');
    }
    times.push("23:59");

    return (
      <span>
        <span
          style={{display: "inline-block", marginRight: "10px"}}
        >
          <HTMLSelect
            value={moment(this.props.startAt).format("HH:mm")}
            onChange={this.handleStartAtChange}
            >
              {times.map((time, index) => (
                <option key={index} value={time}>
                  {time}
                </option>
              ))}
          </HTMLSelect>
        </span>
          ~ 
        <span
          style={{display: "inline-block", margin: "0 10px"}}
        >
          <HTMLSelect
            value={moment(this.props.endAt).format("HH:mm")}
            onChange={this.handleEndAtChange}
            >
              {times.map((time, index) => (
                <option key={index} value={time}>
                  {time}
                </option>
              ))}
          </HTMLSelect>
        </span>
        <span
          style={{display: "inline-block", marginRight: "10px"}}
        >
          <InputGroup
            leftIcon="map-marker"
            placeholder="장소"
            value={this.props.title}
            style={{width: "150px"}}
            onChange={this.handlePlaceChange}
          />            
        </span>            
        <IconButton icon="delete" iconSize={14} intent={Intent.DANGER} onClick={() => this.props.onDeleteClick(this.props.dayOfWeek, this.props.rowKey)} />
      </span>
    );
  }
}

export default DashboardOfficeHourPopupInputUnit;