import React, { Component, KeyboardEvent } from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { ItemPredicate, ItemRenderer } from "@blueprintjs/select";
import { Button, MenuItem, PopoverPosition } from "@blueprintjs/core";

// const LabelListMultiSelect = MultiSelect.ofType<T>();
// const SprintListMultiSelect = MultiSelect.ofType<TaskSprintSimpleForm>();
import './TaskSprintLabelGenericForm.css'
export interface Props<T> {
  // value: T;
  getItemId: (item: T) => string;
  getItemValue: (item: T) => string;
  // 선택 초이스 목록
  itemSelections: T[];
  //선택 한 목록
  itemList: T[];
  //새 아이템 생성시 모델
  createItem: (title: string) => T;
  //새로운 라벨 생성시(목록에도 추가도함)
  onCreateSelectLabel: (itemToSelect: T) => void;
  //라벨 선택시
  onSelectLabel: (itemToSelect: T) => void;
  //목록에서 선택 취소
  onDeselectLabel: (itemToSelect: T[]) => void;

  ItemMultiSelect: any;
}


@observer
class TaskSprintLabelGenericForm<T> extends Component<Props<T>> {
  @observable activeItem: T | null = null;
  @observable isCreateNewItem: boolean = false;

  @observable inputComponent: any = null;
  @observable inputValue: string = '';
  @observable isInputChangedByArrowKey: boolean = false;

  @observable lastUpdateTime:number = 0;

  public static ofType<T>() {
    return TaskSprintLabelGenericForm as new (props: Props<T>) => TaskSprintLabelGenericForm<T>;
  }

  componentDidMount() {
    this.inputComponent.onkeydown = this.inputKeyDown;
  }  

  inputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
      this.isInputChangedByArrowKey = true;
    }
  }

  render() {
    const { ItemMultiSelect } = this.props;
    return (
      <ItemMultiSelect
        createNewItemFromQuery={this.props.createItem}
        createNewItemRenderer={this.renderCreateItem}

        activeItem={this.activeItem}
        onActiveItemChange={this.handleActiveItemChanged}

        itemRenderer={this.renderLabel}
        items={this.props.itemSelections}

        onItemSelect={this.handleLabelSelect}
        tagRenderer={(item: T) => this.getItemValue(item)}
        tagInputProps={{
          onRemove: (_tag: string, index: number) => {
            this.props.onDeselectLabel([this.props.itemList[index]]);
          },
          rightElement: this.props.itemList.length > 0 ?
            <Button icon="cross" minimal={true}
                    onClick={() => {
                      const listToClear = this.props.itemList;
                      this.props.onDeselectLabel(listToClear);
                    }}/> : undefined,
          inputProps: {
            value: this.inputValue,
            onChange: (e: any) => {this.inputValue = e.currentTarget.value;}
          },
          inputRef: (input: any) => {this.inputComponent = input;}
        }}
        fill={true}
        selectedItems={this.props.itemList}
        itemPredicate={this.filterLabel}

        noResults={<MenuItem disabled={true} text={`입력하세요`} />}

        popoverProps={{ position: PopoverPosition.BOTTOM }}
        resetOnQuery={true}
        resetOnSelect={true}
        // intent={false}
        // openOnKeyDown={false}
        // resetOnSelect={true}
      />
    );

  }

  filterLabel: ItemPredicate<T> = (query, item, _index, exactMatch) => {
    const normalizedTitle = this.getItemValue(item).toLowerCase();
    const normalizedQuery = query.toLowerCase();
    if (exactMatch) {
      return normalizedTitle === normalizedQuery;
    } else {
      return `${this.getItemId(item)}.${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
    }
  };

  private handleLabelSelect = (item: T) => {
    let currentTime = Date.now();
    if(currentTime - this.lastUpdateTime > 250) { // 250ms 이전에 들어오는 select 는 무시
      if(this.inputValue.trim() && !this.hasValue(this.inputValue)) {
        this.props.onCreateSelectLabel(item);
      } else {
        if (!this.isItemSelected(item)) {
          this.props.onSelectLabel(item);
        } else {
          this.props.onDeselectLabel([item]);
        }
      }
      this.inputValue = ''; // 입력창 초기화
      this.forceUpdate();   // 라벨 변화를 바로 반영
    }
    this.lastUpdateTime = currentTime;
};

  private handleActiveItemChanged = (activeItem: T | null, isCreateNewItem: boolean) => {
    if(activeItem) {
      this.activeItem = activeItem;
      this.isCreateNewItem = false;

      if(this.isInputChangedByArrowKey) {
        this.isInputChangedByArrowKey = false;
        this.inputValue = this.getItemValue(activeItem);
      } else {
        if(!this.hasValue(this.inputComponent.value)) {
          this.activeItem = null;
          this.isCreateNewItem = true;
        }
      }
    } else {
      this.activeItem = null;
      this.isCreateNewItem = true;
    }

    this.forceUpdate(); // isCreateNewItem 수정 후 update 하지 않으면 UI 상에 반영 안되기 때문에 필수
  }

  hasValue(value: string): boolean {
    return this.props.itemSelections.filter(item => this.getItemValue(item) === value).length > 0;
  }

  isItemSelected(itemParam: T): boolean {
    return this.props.itemList.filter(item => this.getItemId(item) === this.getItemId(itemParam)).length > 0;
  }

  private renderLabel: ItemRenderer<T> = (item, { modifiers, handleClick }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    const selected = this.isItemSelected(item);

    const title = this.getItemValue(item);
    let text = (title.length > 10) ? title.substring(0, 10) + "..." : title;

    return (
      <div key={this.getItemId(item)} title={title}>
        <MenuItem
          active={modifiers.active}
          icon={selected ? "tick" : "blank"}
          // label={this.getItemValue(item)}
          onClick={handleClick}
          text={text}
          shouldDismissPopover={false}
        />
      </div>
    );
  };

  private getItemId(item: T) {
    return this.props.getItemId(item);
  }

  private getItemValue(item: T) {
    return this.props.getItemValue(item);
  }

  renderCreateItem = (
    query: string,
    active: boolean,
    handleClick: React.MouseEventHandler<HTMLElement>
  ) => {
    return !this.hasValue(query) ? (
      <MenuItem
        icon="add"
        text={`Create "${query}"`}
        active={this.isCreateNewItem}
        onClick={handleClick}
        shouldDismissPopover={false}
      />
    ) : undefined;    
  }
}

export default TaskSprintLabelGenericForm;
// export type As<T> = new(props: Props<T>) => TaskSprintLabelGenericForm<T>;