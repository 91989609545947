import { types } from "mobx-state-tree";


export const ScheduleManagerFormModel = types
  .model("ScheduleManagerForm", {
    id: types.optional(types.number, -1),
    category: types.optional(types.string, ""),
    startAt: types.optional(types.string, ""),
    endAt: types.optional(types.string, ""),
    allDay: types.optional(types.boolean, false),
    title: types.optional(types.string, ""),
    comment: types.optional(types.string, ""),
    creator: types.optional(types.number, -1),
    projectGroup: types.optional(types.string, ""),
    projectGroupName: types.optional(types.string, ""),
    // participants: types.optional(types.string, ""),
  })
  .views(self => ({
  }))
  .actions(self => ({
  }))
  .actions(self => ({
    setId(value: number) {
      self.id = value;
    },
    setCategory(value: string) {
      self.category = value;
    },
    setStartAt(value: string) {
      self.startAt = value;
    },
    setEndAt(value: string) {
      self.endAt = value;
    },
    setAllDay(value: boolean) {
      self.allDay = value;
    },
    setTitle(value: string) {
      self.title = value;
    },
    setComment(value: string) {
      self.comment = value;
    },
    setCreator(value: number) {
      self.creator = value;
    },
    setProjectGroup(value: string) {
      self.projectGroup = value;
    }
  }));

type ScheduleManagerFormType = typeof ScheduleManagerFormModel.Type;
export interface ScheduleManagerForm extends ScheduleManagerFormType {}
