import React, { Component, ChangeEvent, KeyboardEvent } from "react";

import { observable, computed } from "mobx";
import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import { Button, Intent, Alert, InputGroup } from "@blueprintjs/core";
import { FocusStyleManager } from "@blueprintjs/core";

import { AppStore } from "../../../store/AppStore";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";

import AddTaskBulletinPostForm from "./AddTaskBulletinPostForm";
import TaskBulletinPostList from "./TaskBulletinPostList";

const TitleContainer = styled.div`
  position: relative;
  width: 280px;
  line-height: 30px;

  &:hover {
    background-color: #f0f0f0;
    color: #000;
  }  
`;
const TitleView = styled.div`
  font-weight: bold;
`;

// 마우스 오버시 나타나는 수정 버튼 컨테이너
const EditButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;

  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

// 에디터
const TitleEditor = styled(InputGroup)`
`;

interface TaskBulletinProps {
  id: number,
  title: string,

  index: number,
  isScrollable: boolean,
  isCombineEnabled: boolean,
  useClone: boolean;
}

interface InjectedProps extends TaskBulletinProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class TaskBulletin extends Component<TaskBulletinProps> {
  @observable showEditButtons: boolean = false;
  @observable showTitleEditor: boolean = false;
  @observable inputComponent: any = null;
  @observable title: string = '';
  @observable showDeleteAlert: boolean = false;

  @observable showAddBulletinPostForm: boolean = false;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get store() {
    return this.injected.appStore.taskManagerStore;
  }
  @computed
  get board() {
    return this.store.currentBoard;
  }

  componentDidMount() {
    FocusStyleManager.onlyShowFocusOnTabs();
    this.title = this.props.title;
  }
  componentDidUpdate() {
    if(this.showTitleEditor && 
      this.inputComponent && 
      this.inputComponent !== document.activeElement
    ) {
      this.inputComponent.focus();
      this.inputComponent.select();
      this.inputComponent.onkeypress = this.handleOnKeyPress;
    }        
  }

  handleShowAddBulletinPostForm = () => {
    this.showAddBulletinPostForm = true;
  }
  handleHideAddBulletinPostForm = () => {
    this.showAddBulletinPostForm = false;
  }
  handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.title = e.target.value;
  };
  handleOnKeyPress = async(event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      if(this.title.trim() !== '') {
        this.handleSubmit();
      }
    }
  }
  handleCancel = () => {
    this.title = this.props.title;
    this.showTitleEditor = false;
  }  
  handleSubmit = async () => {
    try {
      
      await this.store.updateTaskBulletinTitle(this.props.id, this.title);

      this.showTitleEditor = false;

      // 업데이트된 내용을 반영
      await this.store.fetchTaskBulletin(this.board ? this.board.projectGroup : '');
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  }
  handleDeleteSubmit = async () => {
    try {
      await this.injected.appStore.taskManagerStore.deleteTaskBulletin(
        this.props.id
      );
      AppToaster.show({
        message: "운영정보 목록이 삭제되었습니다.",
        intent: Intent.SUCCESS
      });

      // 업데이트된 내용을 반영
      await this.store.fetchTaskBulletin(this.board ? this.board.projectGroup : '');
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }    
  }  

  render() {
    const bulletin = this.store.currentBulletins.find(b => b.id === this.props.id);

    return (
      <div>
      <TitleContainer
        onMouseEnter={(e: any) => {this.showEditButtons=true}}
        onMouseLeave={(e: any) => {this.showEditButtons=false}}
      >
        {this.showTitleEditor ? (
          <TitleEditor
            inputRef={(input: any) => {this.inputComponent=input}}
            placeholder={'운영정보목록 타이틀'}
            rightElement={
              <>
              <Button
                text={'수정'}
                small={true}
                disabled={this.title.trim() === ''}
                onClick={this.handleSubmit}
              />
              <Button
                text={'X'}
                minimal={true}
                onClick={this.handleCancel}
              />
              </>
            }
            value={this.title}
            onChange={this.handleTitleChange}
          />
        ) : (
          <>
          <TitleView>{this.props.title}</TitleView>
          {this.showEditButtons && (
            <EditButtonContainer>
            <Button 
                icon="edit" 
                minimal={true} 
                onClick={() => {this.showTitleEditor=true;}}
            />
            <Button 
                icon="plus" 
                minimal={true} 
                onClick={this.handleShowAddBulletinPostForm}
            />
            <Button 
                icon="trash" 
                minimal={true} 
                onClick={() => {this.showDeleteAlert=true;}} 
            />
            </EditButtonContainer>
          )}
          </>
        )}
      </TitleContainer>
      {bulletin && (
        <TaskBulletinPostList
          id={bulletin.id}
          listId={bulletin.title}
          listType={'POST'}
          internalScroll={this.props.isScrollable}
          isCombineEnabled={Boolean(this.props.isCombineEnabled)}
          useClone={Boolean(this.props.useClone)}
        />
      )}
      {bulletin && this.showAddBulletinPostForm && (
        <AddTaskBulletinPostForm
          showTitleEditor={this.showAddBulletinPostForm}
          task_bulletin={bulletin.task_bulletin_id}
          onClose={this.handleHideAddBulletinPostForm}
        />
      )}
      <Alert 
        icon="trash"
        intent={Intent.DANGER}
        isOpen={this.showDeleteAlert}
        onCancel={() => {this.showDeleteAlert=false;}}
        onConfirm={() => {this.handleDeleteSubmit();}}
        confirmButtonText={'예'}
        cancelButtonText={'아니오'}
      ><p>선택한 목록을 삭제하시겠습니까?</p><p>포함된 항목도 완전히 삭제되며, 되돌릴 수 없습니다.</p></Alert>           
      </div>
    );
  }
}

export default TaskBulletin;