import React, { Component } from "react";
import styled from "styled-components/macro";
import { computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { Button, Intent } from "@blueprintjs/core";
import { ClientCalculate } from "../../../store/models/ClientCalculate";
import ClientCalculateFormPopup from "../CalculatePopup/ClientCalculateFormPopup";
import { FormMode } from "../../../types/formMode";

const Row = styled.div`
  display: flex;
  line-height: 25px;
`;
const RowItem = styled.div`
  margin-left: 20px;
  display: table-cell;
  vertical-align: middle;
`;
const Circle = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: ${p => p.color};
  display: inline-block;
  margin-right: 5px;
`;

interface ClientCalculateRequestRowProps {
  calculate: ClientCalculate;
  className?: string;
}

interface InjectedProps extends ClientCalculateRequestRowProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ClientCalculateRequestRow extends Component<ClientCalculateRequestRowProps> {
  @observable isDetailPopupOpen = false;
  @observable isFormPopupOpen = false;

  get injected() {
    return this.props as InjectedProps;
  }

  get contractStore() {
    return this.injected.appStore.contractStore;
  }

  // @computed
  // get contract() {
  //   const { calculate } = this.props;
  //   return this.injected.appStore.contractStore.approvedClientContracts.find(
  //     x => x.approvedContractId === calculate.contract
  //   );
  // }

  handleFormPopupOpen = () => {
    this.isFormPopupOpen = true;
  };

  handleFormPopupClose = () => {
    this.isFormPopupOpen = false;
  };

  render() {
    const { calculate, className } = this.props;
    // const contract = this.contract;

    return (
      <Row className={ className }>
        <RowItem>
          <Circle color={"red"} />
        </RowItem>
        {/* <RowItem>{calculate.contract}</RowItem> */}
        <RowItem>{calculate.projectGroup}</RowItem>
        <RowItem>{calculate.projectGroupName}</RowItem>
        <RowItem>{calculate.payout ? calculate.payoutWithVAT.toLocaleString("en-US") : ""} 입금 요청해주세요.</RowItem>
        <RowItem>
          <Button text="추가하기" className="bp3-minimal bp3-small" onClick={this.handleFormPopupOpen} intent={Intent.PRIMARY} />
        </RowItem>

        <ClientCalculateFormPopup
          calculate={calculate}
          mode={FormMode.Edit}
          onClose={this.handleFormPopupClose}
          isOpened={this.isFormPopupOpen} />

      </Row>
    );
  }
}

export default ClientCalculateRequestRow;
