import React, { Component, FormEvent, MouseEvent } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { Button, H3, HTMLTable, InputGroup, Intent, Radio, RadioGroup, Spinner, Icon, Colors } from "@blueprintjs/core";
import { observable } from "mobx";
import { RouteComponentProps } from "react-router";
import { Rufree } from "../../../store/models/Rufree";
import { ChangeEvent } from "cleave.js/react/props";
import RufreeInfoPopup from "../../organisms/RufreeInfoPopup/RufreeInfoPopup";
import Paginator from "../../molecules/Paginator/Paginator";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";

import LargeContainer from "../../atoms/LargeContainer/LargeContainer";
import moment from "moment";

const ButtonsRow = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
`;

const ToolsRow = styled.div`
  margin-bottom: 40px;
  display: flex;
  height: 20px;

  & > * + * {
    margin-left: 10px;
  }
`;

const InputContainer = styled.div`
  max-width: 120px;
  margin-bottom: 30px;
`;

const RufreeListContainer = styled.div``;

const Text = styled.div`
  white-space: pre-wrap;
`;

interface RufreeListPageProps extends RouteComponentProps {
}

interface InjectedProps extends RufreeListPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class RufreeListPage extends Component<RufreeListPageProps> {
  @observable selectedRufree: Rufree | null = null;
  @observable readyToShow = false;
  // search options
  @observable nameOrId = "";
  @observable role = "";
  @observable skill = "";
  @observable recommender = "";
  @observable workType = "전체";

  get injected() {
    return this.props as InjectedProps;
  }

  async componentDidMount() {
    await this.fetchList();

    // 1) rufreeId를 통해서 팝업에 접근한 경우
    const rufreeId = this.props.location.search.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function(str, key, value) {
        return (key === "rufree_id") ? value : "";
      }
    );
    if (rufreeId) {
      try {
        await this.injected.appStore.rufreeStore.fetchRufreeById(rufreeId);
        const rufree = this.injected.appStore.rufreeStore.rufree;
        this.selectedRufree = rufree ? rufree : null;
      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }
    } else {
      // 2) uuid를 통해서 팝업에 접근한 경우
      const uuid = this.props.location.search.replace(
        /[?&]+([^=&]+)=([^&]*)/gi,
        function(str, key, value) {
          return (key === "uuid") ? value : "";
        }
      );
      if (uuid) {
        try {
          await this.injected.appStore.rufreeStore.fetchRufreeByUuid(uuid);
          const rufree = this.injected.appStore.rufreeStore.rufree;
          this.selectedRufree = rufree ? rufree : null;
        } catch (e) {
          const error = JSON.stringify(e.response.data);
          AppToaster.show({
            message: "오류: " + error,
            intent: Intent.DANGER
          });
        }
      }
    }

  }

  searchList = async () => {
    await this.injected.appStore.rufreeStore.setCurentPage(1);
    await this.injected.appStore.rufreeStore.setPageGroup(1);
    await this.fetchList();
  };
  private fetchList = async () => {
    this.readyToShow = false;
    try {
      await this.injected.appStore.rufreeStore.fetchRufrees({
        nameOrId: this.nameOrId, // 이름 번호 이메일
        role: this.role, // 분야
        skill: this.skill, // 경력
        recommender: this.recommender, // 추천인 및 알게된 경로
        workType: this.workType // 파트, 풀타임
      });
    } catch (e) {
    }
    this.readyToShow = true;
  };

  handleNameOrIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.nameOrId = e.target.value;
  };

  handleRoleChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.role = e.target.value;
  };

  handleSkillChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.skill = e.target.value;
  };

  handleRecommenderChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.recommender = e.target.value;
  };


  handleWorkTypeChange = (e: FormEvent<HTMLInputElement>) => {
    this.workType = e.currentTarget.value;
  };

  handleCreateButtonClick = () => {
    this.props.history.push("/rufrees/new");
  };

  handleStatButtonClick = () => {
    this.props.history.push("/rufrees/stat");
  }

  handleRufreeClick = (rufree: Rufree) => {
    this.selectedRufree = rufree;
  };

  handlePopupClose = () => {
    this.selectedRufree = null;
  };

  handleEditClick = (rufree: Rufree) => {
    this.props.history.push(`/rufrees/${rufree.rufreeId}/edit`);
  };

  handleCopyURL = (rufreeId: string, rufreeName: string) => {
    let url = window.location.href;
    url = url.indexOf("?") > 0 ? url.substring(0, url.indexOf("?")) : url;
    const link = `${url}?rufree_id=${rufreeId}`;

    let tempElem = document.createElement("textarea");
    tempElem.value = link;
    document.body.appendChild(tempElem);

    tempElem.select();
    document.execCommand("copy");
    document.body.removeChild(tempElem);

    AppToaster.show({
      message: `알유프리 [${rufreeId}] ${rufreeName}님의 바로 가기 링크가 복사되었습니다`,
      intent: Intent.SUCCESS
    });
  }

  render() {
    const {
      sortedRufrees,
      pageGroup,
      totalPages,
      currentPage,
      setPageGroup,
      setCurentPage
    } = this.injected.appStore.rufreeStore;

    return (
      <LargeContainer>
        <H3>알유프리 목록</H3>
        <ToolsRow>
          <InputContainer>
            <InputGroup
              placeholder="이름/번호/이메일"
              value={this.nameOrId}
              onChange={this.handleNameOrIdChange}
              onKeyPress={(event) => {
                if (event.key === "Enter")
                  this.searchList();
              }}
            />
          </InputContainer>
          <InputContainer>
            <InputGroup
              placeholder="분야"
              value={this.role}
              onChange={this.handleRoleChange}
              onKeyPress={(event) => {
                if (event.key === "Enter")
                  this.searchList();
              }}
            />
          </InputContainer>
          <InputContainer>
            <InputGroup
              placeholder="경력"
              value={this.skill}
              onChange={this.handleSkillChange}
              onKeyPress={(event) => {
                if (event.key === "Enter")
                  this.searchList();
              }}
            />
          </InputContainer>
          <InputContainer>
            <InputGroup
              placeholder="추천인 및 알게된 경로"
              value={this.recommender}
              onChange={this.handleRecommenderChange}
              onKeyPress={(event) => {
                if (event.key === "Enter")
                  this.searchList();
              }}
            />
          </InputContainer>
          <RadioGroup
            selectedValue={this.workType}
            onChange={this.handleWorkTypeChange}
            inline={true}
          >
            <Radio
              label="전체"
              value="전체"
              inline={true}
              style={{ verticalAlign: "sub" }}
            />
            <Radio
              label="파트타임"
              value="파트타임"
              inline={true}
              style={{ verticalAlign: "sub" }}
            />
            <Radio
              label="풀타임"
              value="풀타임"
              inline={true}
              style={{ verticalAlign: "sub" }}
            />
          </RadioGroup>
          <Button
            icon="search"
            text="검색"
            intent={Intent.PRIMARY}
            onClick={this.searchList}
          />
          <Button
            icon="timeline-bar-chart"
            text="통계"
            intent={Intent.PRIMARY}
            onClick={this.handleStatButtonClick}
          />
        </ToolsRow>
        <RufreeListContainer>
          {this.readyToShow ? <HTMLTable
            bordered={true}
            // striped={true}
            interactive={true}
            style={{ width: "100%", tableLayout: "fixed" }}
          >
            <thead>
            <tr>
              <th>번호</th>
              <th>이름</th>
              <th>근무시간</th>
              <th colSpan={2}>(전문분야)</th>
              <th colSpan={2}>주분야 / (메인)</th>
              <th colSpan={2}>보조 분야 / (서브)</th>
              <th colSpan={2}>개발언어 및 프레임워크</th>
              <th colSpan={2}>사용 툴</th>
              <th>선호시간</th>
              {
                <th>수정</th>
              }
            </tr>
            </thead>
            <tbody>

            {sortedRufrees.map(rufree => (
              <tr
                key={rufree.rufreeId}
                onClick={() => this.handleRufreeClick(rufree)}
              >
                <td>
                  {rufree.rufreeId}
                  <Button
                    icon={<Icon icon={'link'} iconSize={12} color={Colors.GREEN3} />}
                    minimal={true}
                    small={true}
                    onClick={ (e: any) => {
                      e.stopPropagation();

                      this.handleCopyURL(rufree.rufreeId, rufree.name);
                    }
                    } />
                </td>
                <td>
                  <div style={{color: rufree.getOffer ? 'inherit' : Colors.GRAY1 }}>{rufree.name}</div>
                  { !rufree.getOffer &&
                    <div style={{color: Colors.GRAY1, fontSize: '12px'}}>
                      { `(OFF${ rufree.getOfferChangedAt ? `-${ moment(rufree.getOfferChangedAt).format("YYYY.MM.DD") }` : '' })` }
                    </div>
                  }
                </td>
                <td>{rufree.workType}</td>
                <td colSpan={2}>
                  {rufree.roles
                    .map(role => (role === "기타" ? rufree.rolesEtc : role))
                    .join(", ")}
                </td>
                <td colSpan={2}>
                  {rufree.mainSkills.join(", ")}
                  {(rufree.mainSkills.length > 0 && rufree.oldMainSkills.length > 0 )  && <hr/>}
                  {rufree.oldMainSkills.length>0 && `(${rufree.oldMainSkills.join(", ")})`}
                </td>
                <td colSpan={2}>
                  {rufree.subSkills.join(", ")}
                  {(rufree.subSkills.length > 0 && rufree.oldSubSkills.length > 0 ) && <hr/>}
                  {rufree.oldSubSkills.length>0 && `(${rufree.oldSubSkills.join(", ")})`}
                </td>
                <td colSpan={2}>{rufree.languages.join(", ")}</td>
                <td colSpan={2}>{rufree.tools.join(", ")}</td>
                <td
                  style={{
                    wordBreak: "keep-all"
                  }}
                >
                  {rufree.workingTime.join(", ")}
                </td>
                {
                  <td>
                    <Button
                      small={true}
                      intent={Intent.SUCCESS}
                      text="수정하기"
                      style={{
                        wordBreak: "keep-all"
                      }}
                      onClick={(e: MouseEvent) => {
                        e.stopPropagation();
                        this.handleEditClick(rufree);
                      }}
                    />
                  </td>
                }
              </tr>
            ))}
            </tbody>
          </HTMLTable> : <Spinner key={"rufreeListSpinner"}/>}
          <div style={{ marginTop: "15px" }}>
            <Paginator
              pageGroup={pageGroup}
              totalPages={totalPages}
              currentPage={currentPage}
              setPageGroup={setPageGroup}
              setCurentPage={setCurentPage}
              onFetch={() => this.fetchList()}
            /></div>
        </RufreeListContainer>
        <RufreeInfoPopup
          rufree={this.selectedRufree}
          onClose={this.handlePopupClose}
        />
      </LargeContainer>
    );
  }
}

export default RufreeListPage;
