import React, { Component, ChangeEvent } from "react";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import {
  Dialog,
  Classes,
  Button,
  Intent,
  FormGroup,
  InputGroup
} from "@blueprintjs/core";
import { observable } from "mobx";
import { ProjectGroup } from "../../../store/models/ProjectGroup";
import { Repository } from "../../../store/models/Repository";
import { AppToaster } from "../AppToaster/AppToaster";

interface ProjectGroupRepositoryEditPopupProps {
  onClose: () => void;
  isOpen: boolean;
  projectGroup: ProjectGroup;
  repository: Repository;
}

interface InjectedProps extends ProjectGroupRepositoryEditPopupProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ProjectGroupRepositoryEditPopup extends Component<
  ProjectGroupRepositoryEditPopupProps
> {
  @observable pendingName = "";
  @observable pendingDescription = "";

  get injected() {
    return this.props as InjectedProps;
  }

  handleOpening = () => {
    this.pendingName = this.props.repository.name;
    this.pendingDescription = this.props.repository.description;
  };

  handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.pendingName = e.target.value;
  };

  handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.pendingDescription = e.target.value;
  };

  handleSave = async () => {
    try {
      await this.props.projectGroup.editRepository(
        this.props.repository.id,
        this.pendingName,
        this.pendingDescription
      );

      AppToaster.show({
        message: "저장소가 수정되었습니다.",
        intent: Intent.SUCCESS
      });
      this.handleClose();
    } catch (e) {
      const error = JSON.stringify(e.response.data);

      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { isOpen } = this.props;

    return (
      <Dialog
        onOpening={this.handleOpening}
        isOpen={isOpen}
        title="프로젝트 저장소 수정"
        usePortal={true}
        autoFocus={true}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        enforceFocus={true}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup label="저장소 이름">
            <InputGroup
              placeholder="Repository 이름 (영문)"
              value={this.pendingName}
              onChange={this.handleNameChange}
            />
          </FormGroup>
          <FormGroup label="저장소 개요">
            <InputGroup
              placeholder="Repository 개요"
              value={this.pendingDescription}
              onChange={this.handleDescriptionChange}
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button text="취소" onClick={this.handleClose} />
            <Button
              text="수정"
              intent={Intent.PRIMARY}
              onClick={this.handleSave}
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

export default ProjectGroupRepositoryEditPopup;
