import React, { Component, FormEvent } from "react";
import { computed, observable } from "mobx";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { ChangeEvent } from "cleave.js/react/props";
import moment from "moment";
import sortBy from "lodash/sortBy";

import { AppStore } from "../../../store/AppStore";
import { ClientCalculate } from "../../../store/models/ClientCalculate";
import { FormMode } from "../../../types/formMode";

import {
  AddItemButton,
  AddItemButtonContainer
} from "../../atoms/AddItemButton/AddItemButton";
import InputWithFormat from "../../atoms/InputWithFormat/InputWithFormat";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import ClientSelectionAsync from "../../molecules/ClientSelection/ClientSelectionAsync";
import ProjectGroupSelectionAsync from "../ProjectGroupSelection/ProjectGroupSelectionAsync";
import ApprovedClientContractSelectionAsync from "../../molecules/ApprovedClientContractSelection/ApprovedClientContractSelectionAsync";
import ClientDepositFormView from "./ClientDepositFormView";

import {
  Alert,
  Intent,
  Dialog,
  Divider,
  Classes,
  Colors,
  TextArea,
  Button,
  FormGroup,
  IOptionProps,
  Checkbox,
  Text,
  Icon,
  Popover,

  RadioGroup,
  Radio
} from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import { getMomentFormatter } from "../../../utils/date";

const DialogWrapper = styled(Dialog)`
  min-width: 550px;

  .help-msg { color: #738694; font-size: 12px; }
  .highlight-msg { color: ${Colors.BLUE3 }; }

  .bp3-label { font-weight: bold; width: 120px; line-height: 18px; }
  .bp3-label .mandatory { color: #106ba3; }
  .bp3-label .mandatory::after { content: '*'; }
  .bp3-icon { margin-left: 7px; cursor: pointer; color: #5C7080; }

  .bp3-divider { margin: 20px 0; }
  div.comment {
    .bp3-form-content {
      width: 70% !important;
      textarea { width: 100% !important; }
    }
  }
`;


interface ClientCalculateFormPopupProps {
  calculate?: ClientCalculate;
  isOpened: boolean;
  mode: FormMode;
  onClose: () => void;
}

interface InjectedProps extends ClientCalculateFormPopupProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ClientCalculateFormPopup extends Component<ClientCalculateFormPopupProps> {
  @observable readyToShow: boolean = false;
  @observable isDeleteOpen: boolean = false;
  @observable exsitsInNotIssued: boolean = false;

  get injected() {
    return this.props as InjectedProps;
  }

  get appStore() {
    return this.injected.appStore
  }

  get formStore() {
    return this.appStore.calculateFormStore
  }

  @computed
  get currentForm() {
    return this.formStore.clientCalculateForm;
  }

  @computed
  get selectedContract() {
    const { contract } = this.currentForm;
    return this.appStore.contractStore.approvedClientContracts.find(
      x => x.approvedContractId === contract
    );
  }

  @computed
  get selectedClient() {
    const { clientId } = this.currentForm;
    return this.appStore.clientStore.clients.find(
      x => x.clientId === clientId
    );
  }

  @computed
  get selectedProjectgroup() {
    const { projectGroupId } = this.currentForm;
    return this.appStore.projectGroupStore.projectGroups.find(
      x => x.groupId === projectGroupId
    );
  }

  @computed
  get sprintOptions() {
    let sprints: {sprint: number, checked: boolean}[] = []
    if(this.selectedProjectgroup) {
      sprints = this.selectedProjectgroup.inspectionSprints.map(sprint => ({sprint: sprint, checked: false}));
    }
    return sortBy(
      sprints.slice(),
      sprint => sprint.sprint
    )
  }

  checkExsits = async() => {
    const { projectGroupId, sprints } = this.currentForm;
    const { clientCalculatesNotIssued } = this.appStore.calculateStore;

    this.exsitsInNotIssued = !!clientCalculatesNotIssued.find(calculate => calculate.projectGroup === projectGroupId && calculate.sprints.includes(sprints[0]))

    if(this.exsitsInNotIssued) {
      AppToaster.show({
        message: '자동으로 생성된 입금요청 아이템이 있습니다. 해당 아이템을 추가하여 사용하거나, 자동 목록에서 삭제후 수동으로 새입금을 생성하세요.',
        intent: Intent.WARNING
      });
    }
  }

  handleOpening = async() => {
    const { calculate } = this.props;
    const { fetchClient } = this.appStore.clientStore;

    await this.formStore.initClientCalculateForm(calculate);

    if(calculate) {
      const { projectGroup, contract, clientId } = calculate;

      await fetchClient(clientId);

      if(projectGroup) {
        await this.appStore.projectGroupStore.fetchProjectGroup(projectGroup);
        this.currentForm.setProjectGroupId(projectGroup);
      }
      if(contract) {
        await this.appStore.contractStore.fetchApprovedClientContractById(contract);
        this.currentForm.setContract(contract);
      }
    }

    this.readyToShow = true;
  };

  handleSubmit = async () => {
    const { mode, onClose } = this.props;

    let toasterMsg = "새로운 입금내역이 생성되었습니다.";

    if(this.currentForm.clientId && this.currentForm.payout > 0) {
      try {
        if(mode == FormMode.Edit) {
          toasterMsg = "입금내역이 수정되었습니다."
          await this.formStore.putClientCalculate();
        } else {
          await this.formStore.postClientCalculate();
        }

        await Promise.all([
          this.appStore.calculateStore.fetchClientCalculateNotIssued(),
          this.appStore.calculateStore.fetchClientCalculate()
        ])

        AppToaster.show({
          message: toasterMsg,
          intent: Intent.SUCCESS
        });

        onClose();
      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      } finally {}
    } else {
      if(this.currentForm.clientId === null) {
        AppToaster.show({
          message: '클라이언트를 선택해주세요.',
          intent: Intent.WARNING
        });
      }

      if(this.currentForm.payout === 0) {
        AppToaster.show({
          message: '입금요청금액이 0원입니다.',
          intent: Intent.WARNING
        });
      }
    }
  };

  render() {
    const { isOpened, onClose } = this.props;
    const title = `입금내역 입력`;

    return (
        <DialogWrapper
          onOpening={this.handleOpening}
          isOpen={isOpened}
          usePortal={true}
          autoFocus={true}
          enforceFocus={true}
          canEscapeKeyClose={false}
          canOutsideClickClose={false}
          isCloseButtonShown={false}
          title={title}
        >
          <div className={Classes.DIALOG_BODY}>
            { this.renderClientSection() }
            { this.renderProjectgroupSection() }
            { this.renderTasktaskSection() }
            { this.renderContractSection() }

            <Text className='help-msg'>
              * 프로젝트그룹 or 계약서 선택 시, 해당 아이템에 연결되어 있는 클라이언트로 지정됩니다.<br/>
              * 계약서 선택 시, 해당 아이템에 연결되어 있는 프로젝트그룹, 클라이언트로 지정됩니다.<br/>
            </Text>

            <Divider />

            <Text className='help-msg highlight-msg'>
            * 금액 입력시, <strong>부가세 포함금액</strong>으로 입력해주세요.
            </Text>
            <FormGroup label={<span className='mandatory'>세금계산서발급일</span>} inline={true}>
              <DateInput
                {...getMomentFormatter("YYYY/MM/DD")}
                locale="ko"
                closeOnSelection={true}
                value={moment(this.currentForm.dateIssue).toDate()}
                onChange={(selectedDate: Date) =>
                  this.currentForm.setDateIssue(moment(selectedDate).format("YYYY-MM-DD"))
                }
                maxDate={new Date("2050-01-01")}
              />
            </FormGroup>
            <FormGroup label={<span className='mandatory'>입금요청금액</span>} inline={true}>
              <InputWithFormat
                value={this.currentForm.payout}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  this.currentForm.setPayout(parseInt(e.target.rawValue, 10))
                }
                options={{ numeral: true }}
              />
            </FormGroup>
            <FormGroup label={'입금내역'} inline={true}>
              <AddItemButtonContainer>
                <AddItemButton onClick={this.currentForm.addDeposit} />
              </AddItemButtonContainer>
              {this.currentForm.deposits.map((deposit, index) => (
                <ClientDepositFormView
                  key={index}
                  deposit={deposit}
                  onRemove={this.currentForm.removeDeposit}
                />
              ))}
            </FormGroup>
            <FormGroup
              label={'비고'}
              inline={true}
              className='comment'
            >
              <TextArea
                value={this.currentForm.comment}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                  this.currentForm.setComment(e.target.value)
                }
              />
            </FormGroup>
          </div>

          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                text="삭제"
                intent={Intent.DANGER}
                minimal={true}
                onClick={ () => this.isDeleteOpen = true } />
              <Button text="취소" onClick={onClose} />
              <Button
                text="저장"
                disabled={ this.exsitsInNotIssued }
                onClick={this.handleSubmit}
                intent={Intent.PRIMARY}
              />
            </div>
          </div>

          <Alert
            cancelButtonText="Cancel"
            confirmButtonText="삭제"
            icon="trash"
            intent={Intent.DANGER}
            isOpen={this.isDeleteOpen}
            onCancel={() => this.isDeleteOpen = false }
            onConfirm={async() => {

              await this.formStore.deleteClientCalculate();

              this.isDeleteOpen = true;
              onClose();
            }} >
              <p>
                  삭제하시겠습니까?
              </p>
          </Alert>
        </DialogWrapper>
    );
  }

  private renderClientSection() {
    return (
      <FormGroup
        label={<span className='mandatory'>클라이언트</span>}
        inline={true}>
        <div className={this.readyToShow ? '' : Classes.SKELETON}>
          <ClientSelectionAsync
            currentValue={this.selectedClient && this.selectedClient.nameWithId}
            onChange={ async(clientId: string) => {
              await this.appStore.clientStore.fetchClient(clientId);
              this.currentForm.setClient(clientId);
            } }
          />
        </div>
      </FormGroup>
    )
  }

  private renderContractSection() {
    return (
      <FormGroup label={'계약서'} inline={true}>
        <ApprovedClientContractSelectionAsync
          currentValue={this.selectedContract && this.selectedContract.nameWithId}
          onChange={ async(contractId: string) => {
            await this.appStore.contractStore.fetchApprovedClientContractById(contractId);
            this.currentForm.setContract(contractId);
          }}
        />
        <Icon
          iconSize={14}
          icon={'eraser'}
          onClick={() => this.currentForm.setContract('')} />
      </FormGroup>
    )
  }

  private renderProjectgroupSection() {
    let sprintOptions: IOptionProps[] = this.sprintOptions.map(sprint => ({label: `S${ sprint.sprint }`, value: sprint.sprint}));
    sprintOptions.unshift({label: '선택안함', value: ''});


    return (
      <>
        <FormGroup label={'프로젝트그룹'} inline={true}>
          <div className={this.readyToShow ? '' : Classes.SKELETON}>
            <ProjectGroupSelectionAsync
              currentValue={this.selectedProjectgroup && this.selectedProjectgroup.nameWithId}
              onChange={ async(groupId: string) => {
                this.readyToShow=false;

                await this.appStore.projectGroupStore.fetchProjectGroup(groupId);
                this.currentForm.setProjectGroupId(groupId);

                if(this.selectedProjectgroup) {
                  const pg = this.selectedProjectgroup;

                  // 클라이언트 정보 반영.
                  if(pg.client) {
                    await this.appStore.clientStore.fetchClient(pg.client);
                    this.currentForm.setClient(pg.client ? pg.client : '');
                  }

                  this.checkExsits();

                  // 계약서 필드 리셋.
                  // this.currentForm.setContract('');
                }

                this.readyToShow=true;
              } }
            />
            <Icon
              iconSize={14}
              icon={'eraser'}
              onClick={() => {
                this.currentForm.resetSprints();
                this.currentForm.setProjectGroupId('');
              }} />
          </div>
        </FormGroup>
        <FormGroup label={'스프린트'} inline={true}>
          <div className={this.readyToShow ? '' : Classes.SKELETON}>
            <RadioGroup
              disabled={!!!this.currentForm.projectGroupId}
              inline={ true }
              onChange={ (e: FormEvent<HTMLElement>) => {
                this.currentForm.setSprint(Number((e.target as HTMLInputElement).value));
                this.checkExsits();
              } }
              selectedValue={ this.currentForm.sprints.length > 0 ? this.currentForm.sprints[0] : '' }
              options={ sprintOptions }
            />
            {
              // this.sprintOptions.length > 0 &&
              //   this.sprintOptions.map((s, index) => {
              //     // const isChecked = this.currentForm.sprints.includes(s.sprint) ? true : false;

              //     return
              //       <RadioGroup
              //         inline={ true }
              //         name="sprint"
              //         onChange={ this.handleRadioChange }
              //         selectedValue={ s.sprint }
              //       >
              //         <Radio {...this.state} label="Soup" value="one" />
              //         <Radio {...this.state} label="Salad" value="two" />
              //         <Radio {...this.state} label="Sandwich" value="three" />
              //       </RadioGroup>

              //     // return <Checkbox
              //     //   key={index}
              //     //   checked={isChecked}
              //     //   label={`S${s.sprint}`} inline={true}
              //     //   onChange={(e) => {
              //     //     isChecked ? this.currentForm.removeSprints(s.sprint) : this.currentForm.addSprints(s.sprint)
              //     //   }} />
              //   })
            }
          </div>
        </FormGroup>
      </>
    )
  }

  private renderTasktaskSection() {
    return (
      <></>
    )
  }
}

export default ClientCalculateFormPopup;
