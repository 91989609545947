import React, { Component } from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { RouteComponentProps } from "react-router";
import { Tasktask } from "../../../store/models/Tasktask";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import TasktaskInfoPopup from "../../organisms/TasktaskInfoPopup/TasktaskInfoPopup";
import LargeContainer from "../../atoms/LargeContainer/LargeContainer";
import {
  H3, HTMLTable, Spinner, Intent
} from "@blueprintjs/core";
import moment from "moment";

interface TasktaskListPageProps extends RouteComponentProps {}

interface InjectedProps extends TasktaskListPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class TasktaskListPage extends Component<TasktaskListPageProps> {
  @observable readyToShow = false;
  @observable selectedTasktask: Tasktask | null = null;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get tasktaskStore() {
    return this.injected.appStore.tasktaskStore;
  }

  async refresh() {
    try {
      this.readyToShow = false;
      await this.tasktaskStore.fetchTasktask();
    } catch (e) {
    } finally {
      this.readyToShow = true;
    }
  }

  handleTasktaskClick = (tasktask: Tasktask) => {
    this.selectedTasktask = tasktask;
  };

  handlePopupClose = () => {
    this.selectedTasktask = null;
  };

  handleRequestCancel = async() => {
    if(this.selectedTasktask) {
      this.selectedTasktask.requestCancel();

      AppToaster.show({
        message: `${this.selectedTasktask.projectGroup} 하자보수 요청이 접수취소처리되었습니다.`,
        intent: Intent.SUCCESS
      });
    }

    this.selectedTasktask = null;
    await this.refresh();
  }

  async componentDidMount() {
    await this.refresh();
  }


  render() {
    const { sortedTasktasks } = this.tasktaskStore;

    return (
      <LargeContainer>
        <H3>전체 보수 요청 목록</H3>

        <HTMLTable
          striped={true}
          interactive={true}
          style={{ width: "100%", tableLayout: "fixed" }}
        >

          <thead>
            <tr>
              <th style={{width: '80px'}}>번호</th>
              <th>프로젝트그룹</th>
              <th style={{width: '160px'}}>카테고리</th>
              <th style={{width: '160px'}}>상태</th>
              <th style={{width: '200px'}}>접수일</th>
              <th >내용</th>
            </tr>
          </thead>

          <tbody>
            {!this.readyToShow && (
              <tr><td colSpan={6}><Spinner /></td></tr>
            )}

            {
              sortedTasktasks.map(tasktask => (
                <tr
                  key={tasktask.id}
                  onClick={() => this.handleTasktaskClick(tasktask)}
                >
                  <td>{tasktask.id}</td>
                  <td>
                    <Link 
                      to={`/project-groups/${tasktask.projectGroup}`}
                      target="_blank"
                      onClick={(e) => {e.stopPropagation();}}
                    >
                      {tasktask.projectGroup} {tasktask.projectGroupName}
                    </Link>
                  </td>
                  <td>{tasktask.typeTask}</td>
                  <td>{tasktask.status}</td>
                  <td>{tasktask.requestAt && moment(tasktask.requestAt).format('YYYY-MM-DD')}</td>
                  <td style={{minWidth: '400px'}}>
                    {
                      tasktask.tasks.length > 1 ? (
                        <>{tasktask.tasks[0].title} 외 {tasktask.tasks.length - 1}건</>
                      ) : (
                        <>{tasktask.tasks[0].title}</>
                      )
                    }
                  </td>
                </tr>
              ))
            }
          </tbody>
        </HTMLTable>

        <TasktaskInfoPopup
          tasktask={this.selectedTasktask}
          onClose={this.handlePopupClose}
          onRequestCancel={this.handleRequestCancel}
        />
      </LargeContainer>
    );
  }
}

export default TasktaskListPage;
