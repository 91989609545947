import { types, flow } from "mobx-state-tree";
import axios from "axios";
import { DashboardTaskFormModel } from './DashboardTaskForm';
import { ScheduleManagerResponse } from "../../../types/scheduleManager";
import { mapSchedule } from "../../ScheduleManagerStore";


export const DashboardTaskFormStoreModel = types
  .model("DashboardTaskFormStore", {
    currentForm: types.optional(DashboardTaskFormModel, {})
  })
  .actions(self => {
    const initForm = (creator: number, creatorName: string) => {
      self.currentForm = DashboardTaskFormModel.create({
        creator: creator,
        creatorName: creatorName,
        creatorPmName: creatorName
      });
    };

    const mapFormDataForRequest = () => {
      const form = self.currentForm;

      return {
        id: form.id,
        category: 'tas',
        start_at: form.startAt === '' ? null : form.startAt,
        // end_at: form.endAt,
        all_day: form.allDay,
        task_done: form.taskDone,
        title: form.title,
        comment: form.comment,
        creator: form.creator
      };
    };


    const postSchedule = flow(function*() {
      try {
        const { data }: { data: ScheduleManagerResponse } = yield axios.post(
          `/schedule`,
          mapFormDataForRequest()
        );

      } catch (e) {
        console.log("postDashboardTaskForm error", e);
        throw e;
      }
    });

    const putSchedule = flow(function*() {
      try {
        const { data }: { data: ScheduleManagerResponse } = yield axios.put(
          `/schedule/${self.currentForm.id}`,
          mapFormDataForRequest()
        );

      } catch (e) {
        console.log("putDashboardTaskForm error", e);
        throw e;
      }
    });

    const fetchSchedule = flow(function*(id: number) {
      try {
        const { data }: { data: ScheduleManagerResponse } = yield axios.get(
          `/schedule/${id}`
        );

        const newForm = DashboardTaskFormModel.create(mapSchedule(data));
        self.currentForm = newForm;

      } catch (e) {
        console.log("fetchSchedule Form error", e);
        throw e;
      }
    });

    // 일정 삭제
    const deleteSchedule = flow(function*() {
      try {
        yield axios.delete(`/schedule/${self.currentForm.id}`);
      } catch (e) {
        console.log("deleteSchedule error", e);
        throw e;
      }
    });    
    return {
      initForm,
      postSchedule,
      fetchSchedule,
      putSchedule,
      deleteSchedule
    };
  });

type DashboardTaskFormStoreType = typeof DashboardTaskFormStoreModel.Type;
export interface DashboardTaskFormStore extends DashboardTaskFormStoreType {}