import styled from "styled-components/macro";

import moreSVG from "../../molecules/Plan/icon_more.svg";

export const Wrapper = styled.div`
  & > label.vat-checkbox {
    margin-bottom: 30px;
  }
`;
export const PlanReadSection = styled.div`
  font-family: Noto Sans KR;
  width: 1048px;

  & > section {
    margin-bottom: 60px;
  }
`;

export const Section = styled.section`
  width: 705px;
  position: relative;

  & > h5 {
    margin: 0;
  }
  & > h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    color: #6241FE;
    display: inline;
    margin: 0;
    margin-bottom: 20px;
  }
  & > h4 + span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-left: 6px;
    color: #7E838F;
  }
  & > h4 + span + span.description {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    color: #7E838F;
    position: absolute;
    right: 0;
  }

  & > div.role-group + div.role-group {
    margin-top: 30px;
  }
  & > div.role-group > div + div {
    margin-top: 15px;
  }
  & > div.role-group > h5 {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 8px;
  }
`;

export const PaymentDetail = styled.div`
  margin-top: 12px;
`;
export const PaymentRow = styled.div`
  display: flex;
  width: 100%;
  & {
    border-top: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
  }
  & + & {
    border-top: none;
  }
  & > div:first-child {
    width: 80px;
    background: #FCFCFC;
  }
  & > div:nth-child(2) {
    width: 459px;
  }
  & > div:last-child {
    width: 165px;
  }
`;
export const PaymentColumn = styled.div`
  text-align: center;
  margin: auto;

  &.sprint-name {
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    color: #6241FE;
  }
  &.costs {
    border-left: 1px solid #F4F4F4;
    border-right: 1px solid #F4F4F4;
    padding: 12px 0;

    & > div.cost-group + div.cost-group {
      border-top: 1px solid #F4F4F4;
      padding-top: 12px;
      margin-top: 12px;
    }
    & > div.cost-group {
      display: flex;
      & > div.cost-title {
        width: 128px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 17px;
        color: #333333;
        text-align: left;
        padding-left: 30px;
      }
      & > div.cost-detail {
        & > div.cost-detail-row {
          display: flex;
          font-style: normal;
          font-weight: 500;
          font-size: 11px;
          line-height: 16px;
          color: #000000;
          text-align: left;

          & > div:first-child {
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 16px;
            color: #7E838F;
            width: 174px;
          }
        }

        & > div.cost-detail-row + div.cost-detail-row {
          margin-top: 6px;
        }
      }
    }
  }
  &.total-vat {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #6241FE;
  }
`;
export const PaymentHeader = styled(PaymentRow)`
  height: 39px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  color: #333333;
  border-bottom: none !important;

  & > div {
    margin: 0;
    padding-top: 10px;
  }
  & > div + div {
    border-left: 1px solid #F4F4F4;
  }
`;

export const TotalCost = styled.div`
  height: 105px;
  position: relative;
  background: #FCFCFC;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  margin-top: 10px;

  h6 {
    margin: 0;
    display: inline;
  }

  & > div {
    width: 204px;
    position: relative;
  }
  & > div:first-child {
    position: absolute;
    top: 25px;
    left: 25px;
  }
  & > div:nth-child(2) {
    position: absolute;
    top: 60px;
    left: 25px;
  }
  & > div:last-child {
    position: absolute;
    top: 40px;
    right: 30px;
  }

  & > div > h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
  }
  & > div > span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #000000;
    position: absolute;
    right: 0;
  }

  & > div:last-child {
    width: fit-content;
    height: 26px;

    div {
      display : inline;
    }
    & > div:first-child {
      border-right: 1px solid #C0B3FF;
      padding-right: 20px;
      margin-right: 25px;
    }
    div > h6 {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      text-align: right;
      color: #8F8F8F;
      margin-right: 10px;
    }
    div > span {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 26px;

      text-align: right;

      color: #6241FE;

    }
  }
`;

export const MoreButtonWrapper = styled.button`
  background: url(${ moreSVG }) no-repeat center center;
  width: 38px;
  height: 38px;
  margin-left: 333px;
  margin-top: 12px;
  border: none;
  cursor: pointer;
}
`;