import { types, flow } from "mobx-state-tree";
import axios from "axios";
import { BugTaskForm, BugForm, BugFormModel, BugTaskFormModel } from "./TasktaskForm";
import { TasktaskResponse } from "../../../types/tasktask";
import { mapTasktask } from "../../models/Tasktask";

const mapBugFormToData = (form: BugForm) => ({
  id: form.id,
  title: form.title,
  comment: form.comment,
});

const mapFormToData = (form: BugTaskForm) => ({
  project_group: form.projectGroup,
  requestor: form.requester,
  tasks: form.tasks,
  // tasks: form.tasks.map(mapBugFormToData),
});

export const TasktaskFormStoreModel = types
  .model("TasktaskFormStore", {
    bugTaskForm: types.optional(BugTaskFormModel, {})
  })
  .actions(self => ({
    initForm(projectGroup: string, userId: number) {
      self.bugTaskForm = BugTaskFormModel.create({
        projectGroup: projectGroup,
        requester: userId
      });
      self.bugTaskForm.addBug();
    }
  }))
  .actions(self => {
    const saveBugTask = flow(function*() {
      let data: TasktaskResponse | null = null;

      if(self.bugTaskForm.id > 0) {
        try {
          const { data }: { data: TasktaskResponse } = yield axios.put(
            `/tasktasks/${self.bugTaskForm.id}`,
            mapFormToData(self.bugTaskForm)
          );
          self.bugTaskForm.setId(data.id);
        } catch (e) {
          throw e;
        }
      } else {
        try {
          const { data }: { data: TasktaskResponse } = yield axios.post(
            "/tasktasks",
            mapFormToData(self.bugTaskForm)
          );
          self.bugTaskForm.setId(data.id);
        } catch (e) {
          throw e;
        }
      }
    })

    const request = flow(function*() {
      try {
        const { data }: { data: TasktaskResponse } = yield axios.post(
          `/tasktasks/${self.bugTaskForm.id}/request`
        );
      } catch (e) {
        throw e;
      }
    });

    const fetchTask = flow(function*(taskTaskId: string) {
      try {
        const { data }: { data: TasktaskResponse } = yield axios.get(
          `/tasktasks/${taskTaskId}`
        );

        self.bugTaskForm.id = data.id;
        self.bugTaskForm.projectGroup = data.project_group;
        self.bugTaskForm.tasks = data.tasks.map(task => BugFormModel.create({
          id: task.id,
          title: task.title,
          comment: task.comment
        })) as any;
      } catch (e) {
        throw e;
      }
    });

    // const delete = flow(function*(taskTaskId: string) {
    // });

    return {
      saveBugTask,
      request,
      fetchTask
    };
  });

type TasktaskFormStoreType = typeof TasktaskFormStoreModel.Type;
export interface TasktaskFormStore extends TasktaskFormStoreType {}