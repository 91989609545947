import React, { Component } from "react";

import { inject, observer } from "mobx-react";

import { Redirect, RouteComponentProps } from "react-router";

import styled from "styled-components/macro";

import { AppStore } from "../../../store/AppStore";
import DashboardPage from "../DashboardPage/DashboardPage";

const Wrapper = styled.div``;

interface HomePageProps extends RouteComponentProps {}

interface InjectedProps extends HomePageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class HomePage extends Component<HomePageProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  get userStore() {
    return this.injected.appStore.userStore;
  }

  componentDidMount() {
    this.userStore.checkLoginStatus();
  }

  render() {
    const { isLoggedIn } = this.userStore;

    if (!isLoggedIn) {
      return <Redirect to="/login" />;
    }

    return (
      <Wrapper>
        <DashboardPage />
      </Wrapper>
    );
  }
}

export default HomePage;
