import React, { Component, ChangeEvent } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { FormMode } from "../../../types/formMode";
import { RouteComponentProps, Redirect } from "react-router";
import { computed, observable } from "mobx";
import {
  Spinner,
  FormGroup,
  InputGroup,
  Button,
  Intent,
  H3, H4
} from "@blueprintjs/core";
import FormSection from "../../atoms/FormSection/FormSection";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import { FroalaConfigs } from "../../../utils/FroalaConfigs";

import FroalaEditor from "react-froala-wysiwyg";
import TemporaryStorage from "../../molecules/Utility/TemporaryStorage/TemporaryStorage";

const Wrapper = styled.div`
  width: 793.701px;
`;
const HeaderSection = styled.div``;
const TitleSection = styled.div`
  display: flex;
  flex-direction: row;
`;
const EditorSection = styled.div``;
const Title = styled.div`
  width: 600px;
`;
const Buttons = styled.div`
  margin-top: 23px;
  margin-left: 10px;

  & > * + * {
    margin-left: 5px;
  }
`;

interface PageParams {
  id: string;
}

interface ContractTemplateFormPageProps extends RouteComponentProps<PageParams> {
  mode: FormMode;
  // newlySaved: () => {},
}

interface InjectedProps extends ContractTemplateFormPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ContractTemplateFormPage extends Component<ContractTemplateFormPageProps> {
  @observable isFormInitiated = false;
  @observable isSaveComplete = false;


  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get formStore() {
    return this.injected.appStore.contractTemplateFromStore;
  }

  @computed
  get currentForm() {
    return this.formStore.currentForm;
  }

  @computed
  get isReadMode() {
    return this.props.mode === FormMode.Read;
  }

  handleCancel = () => {
    this.props.history.push("/contract?tab=template");
  };

  handleCreateSubmit = async () => {
    try {
      await this.formStore.postContractTemplate();
      AppToaster.show({
        message: "새로운 계약서 양식이 생성되었습니다.",
        intent: Intent.SUCCESS
      });
      this.props.history.push(`/contract-templates/${this.currentForm.id}/edit`);
      // this.props.newlySaved()
      // http://localhost:3001/contract-templates/5/edit
      // return <Redirect to={`/contract-templates/${this.currentForm.id}/edit`} push={true}/>;

    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  handleEditSubmit = async () => {
    try {
      await this.formStore.patchContractTemplate();
      AppToaster.show({
        message: "계약서 양식이 수정되었습니다.",
        intent: Intent.SUCCESS
      });

      // this.isSaveComplete = true;
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  handleCreateCompleteSubmit = async () => {
    try {
      await this.formStore.postContractTemplate();
      AppToaster.show({
        message: "새로운 계약서 양식이 생성되었습니다.",
        intent: Intent.SUCCESS
      });

      this.isSaveComplete = true;
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  handleEditCompleteSubmit = async () => {
    try {
      await this.formStore.patchContractTemplate();
      AppToaster.show({
        message: "계약서 양식이 수정되었습니다.",
        intent: Intent.SUCCESS
      });

      this.isSaveComplete = true;
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  async componentDidMount() {
    this.formStore.initContractTemplateForm();

    const { userId } = this.injected.appStore.userStore;
    this.currentForm.setAuthor(userId);

    const { id } = this.props.match.params;

    if (id) {
      await this.formStore.fetchContractTemplate(id);
    }

    this.isFormInitiated = true;
  }

  render() {
    if (!this.isFormInitiated) {
      return <Spinner/>;
    }

    if (this.isSaveComplete) {
      // console.log(this.currentForm.id,"IDIDIDI");
      return <Redirect to={`/contract-templates/${this.currentForm.id}/read`} push={true}/>;
    }

    const { currentForm } = this;

    return (
      <Wrapper>
        <HeaderSection>
          <H3>
            계약서 양식
            {this.props.mode === FormMode.Create && (<> 생성하기</>)}
            {this.props.mode === FormMode.Edit && (<> 수정하기</>)}
          </H3>
          <H4>{currentForm.id > 1 && `${currentForm.template_id}`}</H4>
        </HeaderSection>

        <TemporaryStorage keyName={`ContractTemplate`} title={"template_name"} setForm={(val) => {
          this.formStore.setForm(val);
        }} currentForm ={this.currentForm} />
        <TitleSection>
          <Title>
            <FormSection>
              <FormGroup label={<strong>계약명(프로젝트명)</strong>}>
                <InputGroup
                  value={currentForm.template_name}
                  onChange={
                    (e: ChangeEvent<HTMLInputElement>) => {
                      currentForm.setTemplateName(e.target.value);
                    }
                  }
                />
              </FormGroup>
            </FormSection>
          </Title>
          <Buttons>
            <Button text="취소" onClick={this.handleCancel}/>
            {
              this.props.mode === FormMode.Create ? (
                <Button
                  text="저장"
                  intent={Intent.SUCCESS}
                  onClick={this.handleCreateSubmit}
                />
              ) : (
                <Button
                  text="저장"
                  intent={Intent.SUCCESS}
                  onClick={this.handleEditSubmit}
                />
              )
            }
            {
              this.props.mode === FormMode.Create ? (
                <Button
                  text="완료"
                  intent={Intent.PRIMARY}
                  onClick={this.handleCreateCompleteSubmit}
                />
              ) : (
                <Button
                  text="완료"
                  intent={Intent.PRIMARY}
                  onClick={this.handleEditCompleteSubmit}
                />
              )
            }

          </Buttons>

        </TitleSection>

        <EditorSection>
          {
            <FroalaEditor
              model={currentForm.template_body}
              config={FroalaConfigs}
              onModelChange={(model: string) => {
                currentForm.setTemplateBody(model);
              }}>
            </FroalaEditor>
          }

        </EditorSection>
      </Wrapper>
    );
  }
}

export default ContractTemplateFormPage;
