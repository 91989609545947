import React, { FC, useState, Component } from 'react';
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import styled from "styled-components/macro";
import { Icon, H2, H6 } from "@blueprintjs/core";
import moment from 'moment';
import "moment-timezone";


const TimeWrapper = styled(H6)`
  width: 200px;
  font-weight: normal;
  font-size: 13px;
  color: #5C7080;
`;


@observer
export class DashboardStatusTime extends Component {
  @observable time = moment();
  interval: number;

  constructor(props: {}) {
    super(props);
    this.interval = setInterval(() => this.clockTick(), 1000);
  }

  @action
  clockTick(newTime = moment()) {
    this.time = newTime;
  }

  render() {
    return (
      <TimeWrapper>
        { this.time.tz('Asia/Seoul').format('HH:mm') } Seoul
      </TimeWrapper>
    );
  }
}


export const DashboardStatusDate: FC<any> = props => {
  const Wrapper = styled.div`
    display: flex;
    width: 160px;
    align-items: center;
    text-align: center;
  `;
  const WEEK = ['일', '월', '화', '수', '목', '금', '토'];
  const today = moment().tz("Asia/Seoul");
  const dayOfWeek = WEEK[today.day()];

  return (
    <Wrapper>

      {
        // <Icon icon="chevron-left" onClick={ () => setBaseDate(today.add(-1, 'days').format("YYYY-MM-DD")) } />
      }

      <H2>
        { today.month()+1 }/{ today.date() } { dayOfWeek }요일
      </H2>

      {
        // <Icon icon="chevron-right" onClick={ () => setBaseDate(today.add(1, 'days').format("YYYY-MM-DD")) } />
      }
      
    </Wrapper>
  );
}