import React, { Component } from "react";

import { inject, observer } from "mobx-react";
import { observable } from "mobx";

import { AppStore } from "../../../store/AppStore";

import styled from "styled-components/macro";
import { Button, Spinner, Intent, H5, HTMLSelect, HTMLTable } from "@blueprintjs/core";

import { AppToaster } from "../../organisms/AppToaster/AppToaster";

import moment from 'moment';


const Wrapper = styled.div`
  margin-bottom: 10px;
  & > * + * {
    margin-top: 10px;
  }
`;
const LeftMargin = styled.div`
  margin-left: 10px;
`;

const Container = styled.div`
  margin-bottom: 10px;
  & > * + * {
    margin-top: 10px;
  }
`;
const HeaderTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 50px;
`;
const HeaderTitle = styled(H5)`
  margin-bottom: 0;
`;
const TableContainer = styled.div``;

interface TaskPanelProps {
}

interface InjectedProps extends TaskPanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class TaskPanel extends Component<TaskPanelProps> {
  @observable readyToShow: boolean = false;
  @observable tasks: Array<any> = [];
  @observable selectedPmUserId = 0;

  get injected() {
    return this.props as InjectedProps;
  }

  get store() {
    return this.injected.appStore.taskManagerStore;
  }

  fetchList = async () => {
    try {
      this.readyToShow = false;
      await this.injected.appStore.pmUserStore.fetchPMUsers();
      
      this.tasks = [];
      const data:any = await this.store.fetchSeesoTasks();
      data.map((task: any) => { this.tasks.push(task) });

      this.readyToShow = true;
    } catch (e) {}
  }; 

  async componentDidMount() {
    this.readyToShow = false;
    await this.fetchList();
    this.readyToShow = true;
  }

  async selectedPMChanged(e: any) {
    this.selectedPmUserId = Number(e.currentTarget.value);
  }  

  render() {
    const { pmUsers } = this.injected.appStore.pmUserStore;

    return (
      <Wrapper>
        {!this.readyToShow ? (
          <Spinner />
        ) : (
          <LeftMargin>
            <Container>
              <HeaderTitleWrapper>
                <HeaderTitle
                  style={{marginRight:'50px'}}
                >
                  예정/진행 목록
                </HeaderTitle>
                <HTMLSelect
                  value={this.selectedPmUserId}
                  onChange={(e) => {this.selectedPMChanged(e);}}
                >
                  <option value={0}>전체</option>
                  {pmUsers.map(pm => {
                    return pm.nickname ? <option key={pm.id} value={pm.user_id}>{pm.nickname}</option> : null;
                  })}
                </HTMLSelect>
              </HeaderTitleWrapper>

              <TableContainer>
                <HTMLTable
                  condensed={true}
                  interactive={true}
                  striped={true}
                  style={{width:'100%'}}
                >
                  <thead>
                    <tr>
                      <th>프로젝트그룹</th>
                      <th>태스크</th>
                      <th>상태</th>
                      <th>태스크목록</th>
                      <th>담당자명</th>
                      <th>마감일</th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.tasks.map((task:any) => {
                      if(this.selectedPmUserId === 0 || task.person_in_charge_list.includes(this.selectedPmUserId)) {
                        return (
                          <tr key={task.id} onClick={() => { window.open(task.url, '_blank') }}>
                            <td><div style={{width:'150px', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}>{task.project_group_name}</div></td>
                            <td><div style={{width:'500px', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}>{task.title}</div></td>
                            <td><div style={{width:'50px'}}>{task.status}</div></td>
                            <td><div style={{width:'100px', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}>{task.tasklist_title}</div></td>
                            <td><div style={{width:'100px'}}>
                              {task.person_in_charge_list.map((pic:any) => {
                                const found = pmUsers.find(pm => pm.user_id === pic)
                                return found ? (found.nickname ? found.nickname : found.name) : '';
                              }).join(', ')}
                            </div></td>
                            <td><div style={{width:'100px'}}>{task.due_date ? moment(task.due_date).format("YYYY-MM-DD") : ''}</div></td>
                          </tr>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </tbody>

                </HTMLTable>
              </TableContainer>
            </Container>
          </LeftMargin>
        )}

      </Wrapper>
    );
  }
}

export default TaskPanel;