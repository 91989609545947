import { types, flow } from "mobx-state-tree";
import axios from "axios";
import sortBy from "lodash/sortBy";
// import { Sprint } from "./models/Sprint";
import { ClientCalculateModel } from "./models/ClientCalculate";
import { RufreeCalculateModel } from "./models/RufreeCalculate";
import { ServiceCalculateModel } from "./models/ServiceCalculate";
import { CalculateByProjectgroupModel } from './models/CalculateByProjectgroupModel';
import { CalculateByClientModel } from './models/CalculateByClient';
import { 
  ClientCalculateResponse,
  RufreeCalculateResponse,
  ClientCalculateListResponse,
  RufreeCalculateListResponse,
  ServiceCalculateResponse,
  // CalculateByContractResponse,
  CalculateByProjectgroupResponse,
  CalculateByClientResponse
} from "../types/Calculate";
import { mapSprint } from "./models/ProjectGroup";
import { mapToTask } from "./ParttimeStore";

const mapClientCalculate = (x: ClientCalculateResponse) => {
  return {
    id: x.id,
    contract: x.contract || '',
    contractName: x.contract_name || '',
    projectGroup: x.project_group || '',
    projectGroupName: x.project_group_name || '',
    sprints: x.sprints,
    clientId: x.client_id || '',
    clientName: x.client_name || '',
    dateIssue: x.date_issue,
    dateLastDeposit: x.date_last_deposit,
    payout: x.payout,
    deposits: x.deposits,
    comment: x.comment
  };
};

const mapRufreeCalculate = (x: RufreeCalculateResponse) => {
  return {
    id: x.id,
    calculateId: x.calculate_str_id,
    sprintSequentialId: x.sprint_sequential_id || 0,
    projectGroup: x.project_group,
    projectGroupName: x.project_group_name,
    clientContract: x.client_contract || '',
    clientName: x.client_name || '',
    rufreeName: x.rufree_name,
    rufreePhone: x.rufree_phone,
    managerName: x.manager_name,
    rufreeContract: x.rufree_contract ? {
      contractId: x.rufree_contract.contract_id || '',
      info: x.rufree_contract.info || '',
      agentName: x.rufree_contract.agent_name || '',
      cpmsUrl: x.rufree_contract.cpms_url || ''
    } : {
      contractId: '',
      info: '',
      agentName: '',
      cpmsUrl: ''
    },
    createdAt: x.created_at,
    updatedAt: x.updated_at,
    expectedPayment: x.expected_payment,
    commission: x.commission,
    typeTax: x.type_tax,
    payment: x.payment,
    datePayment: x.date_payment,
    sprint: x.sprint
      ? mapSprint(x.sprint) || null
      : null,
    parttimeTask: x.parttime_task
      ? mapToTask(x.parttime_task) || null
      : null,
    pmComment: x.pm_comment,
    comment: x.comment
  };
};

const mapServiceCalculate = (x: ServiceCalculateResponse) => {
  return {
    projectGroupId: x.project_group_id,
    projectGroupName: x.project_group_name,
    contractId: x.contract_id,
    clientName: x.client_name,
    service: x.service,
    cumulative: x.cumulative,
    status: x.status
  };
};

const mapCalculateByProjectgroup = (x: CalculateByProjectgroupResponse) => {
  return {
    groupId: x.group_id,
    groupName: x.group_name,
    clientId: x.client_id,
    clientName: x.client_name,
    inspectionAmount: x.inspection_amount,
    depositAmount: x.deposit_amount,
    status: x.status
  };
};

const mapCalculateByClient = (x: CalculateByClientResponse) => {
  return {
    clientId: x.client_id,
    deposits: x.deposits,
    depositAmount: x.deposit_amount,
    payouts: x.payouts,
    payoutAmount: x.payout_amount
  }
}

export const CalculateStoreModel = types
  .model("CalculateStore", {
    clientCalculates: types.array(ClientCalculateModel),
    clientCalculatesNotIssued: types.array(ClientCalculateModel),
    rufreeCalculates: types.array(RufreeCalculateModel),
    serviceCalculate: types.array(ServiceCalculateModel),
    // calculateByContract: types.array(CalculateContractModel),
    calculatesByProjectgroup: types.array(CalculateByProjectgroupModel),
    calculateByClient: types.array(CalculateByClientModel),

    currentPage: types.optional(types.number, 1),
    pageGroup: types.optional(types.number, 1),
    pageSize: types.optional(types.number, 20),
    totalPages: types.optional(types.number, 1)
  })
  .views(self => ({
    get sortedClientCalculate() {
      return sortBy(
        self.clientCalculates.filter(x => x.dateIssue).slice(),
        calculate => calculate.id
      ).reverse();
    },

    get clientCalculateRequests() {
      return self.clientCalculates.filter(x => !x.dateIssue);
    },

    get sortedRufreeCalculate() {
      return sortBy(
        self.rufreeCalculates.slice(),
        calculate => calculate.id
      ).reverse();
    },

    get totalRufreeCalculatesExpectedPayment() {
      return self.rufreeCalculates.reduce((a, b) => a + b.expectedPayment, 0)
    },

    get totalRufreeCalculatesPayment() {
      return self.rufreeCalculates.reduce(function(prev, next) {
        if(next.payment) {
          return prev + next.payment;
        } else {
          return prev;
        }

      }, 0);
      // return self.rufreeCalculates.reduce((a, b) => a + b.payment, 0)
    },

    // get summaryCalculateByContract() {
    //   let budget:number = 0;
    //   let deposit:number = 0;
    //   let paid:number = 0;
    //   let service:number = 0;
    //   let balance:number = 0;

    //   self.calculateByContract.map((calculate) => {
    //     budget += calculate.budget;
    //     deposit += calculate.deposit;
    //     paid += calculate.paid;
    //     service += calculate.service;
    //     balance += calculate.balance;
    //   });

    //   return {
    //     budget: budget,
    //     deposit: deposit,
    //     paid: paid,
    //     service: service,
    //     balance: balance
    //   }
    // }
  }))
  .actions(self => ({
    setCurentPage(value: number) {
      self.currentPage = value;
    },
    setPageGroup(value: number) {
      self.pageGroup = value;
    }
  }))
  .actions(self => {
    const downloadRufreeCalculateCSV = flow(function*(month: string='') {
      const baseDate = month.split('-');
      try {
        yield axios({
          url: 'calculate/rufree/csv',
          method: 'POST',
          data: {year: baseDate[0], month: baseDate[1]},
          responseType: 'blob', //important
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${baseDate[0]}년 ${baseDate[1]}월 용역신고.csv`);
          document.body.appendChild(link);
          link.click();
        });
      } catch (e) {
        throw e;
      }
    });

    const downloadNotpaidRufreeCalculateCSV = flow(function*() {
      try {
        yield axios({
          url: 'calculate/rufree/notpaid_csv',
          method: 'POST',
          responseType: 'blob', //important
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `그룹지급시트.csv`);
          document.body.appendChild(link);
          link.click();
        });
      } catch (e) {
        throw e;
      }
    });

    const fetchRufreeCalculate = flow(function*(
      order_by: string='id',
      order_asc: boolean=false,
      status: string='',
      text: string='',
      created_at_after: string='',
      created_at_before: string='',
      type: string
    ) {
      try {
        const { data }: { data: RufreeCalculateListResponse } = yield axios.get(
          `/calculate/rufree`,
          {
            params: {
              type_calculate: type,
              order_by: order_asc ? order_by : '-'+order_by,
              status: status,
              text: text,
              date_payment_after: created_at_after,
              date_payment_before: created_at_before,
              page: self.currentPage,
              page_size: self.pageSize
            }
          }
        );

        const calculates = data.results.map(x =>
          RufreeCalculateModel.create(mapRufreeCalculate(x))
        );
        self.totalPages = data.total_pages;
        self.rufreeCalculates.replace(calculates);
      } catch (e) {
        throw e;
      }
    });

    const fetchClientCalculate = flow(function*(
      order_by: string='date_issue',
      order_asc: boolean=false,
      status: string='',
      text: string='',
      dateIssue: boolean=true
    ) {
      try {
        const { data }: { data: ClientCalculateListResponse } = yield axios.get(
          `/calculate/client`,
          {
            params: {
              order_by: order_asc ? order_by : '-'+order_by,
              status: status,
              text: text,
              date_issue: dateIssue,
              page: self.currentPage,
              page_size: self.pageSize
            }
          }
        );

        const calculates = data.results.map(x =>
          ClientCalculateModel.create(mapClientCalculate(x))
        );
        self.totalPages = data.total_pages;
        self.clientCalculates.replace(calculates);
      } catch (e) {
        throw e;
      }
    });

    const fetchClientCalculateNotIssued = flow(function*(
      order_by: string='-contract_id',
      dateIssue: boolean=false
    ) {
      try {
        const { data }: { data: ClientCalculateResponse[] } = yield axios.get(
          `/calculate/client`,
          {
            params: {
              order_by: order_by,
              date_issue: dateIssue
            }
          }
        );

        const calculates = data.map(x =>
          ClientCalculateModel.create(mapClientCalculate(x))
        );
        // self.totalPages = data.total_pages;
        self.clientCalculatesNotIssued.replace(calculates);
      } catch (e) {
        throw e;
      }
    });

    const fetchAllByClientId = flow(function*(
      clientId: string=''
    ) {
      if(clientId) {
        // fetch 입금
        try {
          const { data }: { data: ClientCalculateResponse[] } = yield axios.get(
            `/calculate/client`,
            {
              params: {
                text: clientId
              }
            }
          );
          const calculates = data.map(x =>
            ClientCalculateModel.create(mapClientCalculate(x))
          );
          self.clientCalculates.replace(calculates);

        } catch (e) {
          throw e;
        }
        // fetch 출금.
        try {
          const { data }: { data: RufreeCalculateResponse[] } = yield axios.get(
            `/calculate/rufree`,
            {
              params: {
                text: clientId
              }
            }
          );
          const calculates = data.map(x =>
            RufreeCalculateModel.create(mapRufreeCalculate(x))
          );
          self.rufreeCalculates.replace(calculates);

        } catch (e) {
          throw e;
        }
      }
    });


    const fetchServiceCalculate = flow(function*(text: string='') {
      try {
        const { data }: { data: ServiceCalculateResponse[] } = yield axios.get(
          `/calculate/list/service?text=${text}`
        );

        const calculates = data.map(x =>
          ServiceCalculateModel.create(mapServiceCalculate(x))
        );
        self.serviceCalculate.replace(calculates);
      } catch (e) {
        throw e;
      }
    });

    const fetchCalculateByProjectgroup = flow(function*(text: string='') {
      try {
        const { data }: { data: CalculateByProjectgroupResponse[] } = yield axios.get(
          `/calculate/list/projectgroup?text=${text}`
        );

        const calculates = data
        .filter(d => d.client_id !== 'CL197')
        .map(x =>
          CalculateByProjectgroupModel.create(mapCalculateByProjectgroup(x))
        );
        self.calculatesByProjectgroup.replace(calculates);
      } catch (e) {
        throw e;
      }
    });

    const fetchCalculateByClient = flow(function*(text: string='') {
      try {
        const { data }: { data: CalculateByClientResponse[] } = yield axios.get(
          `/calculate/list/client?text=${text}`
        );

        const calculates = data.map(x =>
          CalculateByClientModel.create(mapCalculateByClient(x))
        );
        self.calculateByClient.replace(calculates);
      } catch (e) {
        throw e;
      }
    });

    const createRufreeCalculate = flow(function*(sprint_id: number, expected_payment: number, commission: number, comment: string) {
      try {
        const { data }: { data: RufreeCalculateResponse } = yield axios.post(
          `/calculate/rufree`,
          {
            type_calculate: 'sprint',
            sprint_id: sprint_id,
            expected_payment: expected_payment,
            commission: commission,
            pm_comment: comment
          }
        );
      } catch (e) {
        throw e;
      }
    });

    const createRufreeParttimeCalculate = flow(function*(
      client_id: string,
      rufree_id: string,
      projectgroup_id: string,
      expected_payment: number,
      commission: number = 0,
      comment: string = ''
    ) {
      try {
        const { data }: { data: RufreeCalculateResponse } = yield axios.post(
          `/calculate/rufree`,
          {
            type_calculate: 'parttime',
            client_id: client_id,
            rufree_id: rufree_id,
            projectgroup_id: projectgroup_id,
            expected_payment: expected_payment,
            commission: commission,
            pm_comment: comment
          }
        );
      } catch (e) {
        throw e;
      }
    });

    return {
      fetchRufreeCalculate,
      fetchClientCalculate,
      fetchAllByClientId,
      fetchClientCalculateNotIssued,
      fetchServiceCalculate,
      fetchCalculateByProjectgroup,
      fetchCalculateByClient,
      createRufreeCalculate,
      createRufreeParttimeCalculate,
      downloadRufreeCalculateCSV,
      downloadNotpaidRufreeCalculateCSV
    };
  });

type CalculateStoreType = typeof CalculateStoreModel.Type;
export interface CalculateStore extends CalculateStoreType {}
