/*
  파트너 회원 승인 팝업
*/
import React from 'react';
import { Alert, IAlertProps, Intent } from '@blueprintjs/core';

const ApprovePopup = (props: IAlertProps) => {
  return (
    <Alert
      confirmButtonText="확인"
      cancelButtonText="취소"
      intent={ Intent.PRIMARY } 
      { ...props }
    >
      <p>
        승인 하시겠습니까?
      </p>
    </Alert>
  )
}
export default ApprovePopup;