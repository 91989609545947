import { types, flow } from "mobx-state-tree";
import { ScheduleFormModel } from "./ScheduleForm";
import axios from "axios";
import { ScheduleResponse } from "../../../types/schedule";
import { ScheduleSprintFormModel } from "./ScheduleSprintForm";

export const ScheduleFormStoreModel = types
  .model("ScheduleFormStore", {
    currentForm: types.optional(ScheduleFormModel, {})
  })
  .actions(self => ({
    initForm() {
      self.currentForm = ScheduleFormModel.create();
    }
  }))
  .actions(self => {
    const updateSprintReports = flow(function*() {
      const { projectGroupId } = self.currentForm;

      const reports: any[] = [];

      self.currentForm.rufreeSchedules.forEach(rufreeSchedule => {
        rufreeSchedule.sprints.forEach((sprint, index) => {
          if (sprint.isValid) {
            const sprintNumber = index;

            reports.push({
              rufree: rufreeSchedule.rufreeId,
              role: rufreeSchedule.role,
              sprint: sprintNumber
            });
          }
        });
      });

      try {
        yield Promise.all(
          reports.map(async report => {
            await axios.post(
              `/projectGroups/${projectGroupId}/sprintReports`,
              report
            );
          })
        );
      } catch (e) {
        console.log("updateSprintReports error", e);
        throw e;
      }
    });

    const putSchedule = flow(function*() {
      try {
        const form = self.currentForm;
        const { projectGroupId } = form;
        const { data } = yield axios.put(
          `/projectGroups/${projectGroupId}/schedule`,
          {
            date_start: form.expectedStartDate, // TODO: 예상시작일 업데이트하기
            date_end: form.expectedEndDate,
            // client_schedules: form.clientSchedules.map(schedule => ({
            //   name: schedule.name,
            //   inspection_id: schedule.inspectionId,
            //   role: schedule.role,
            //   num_inspection_sprints: schedule.numInspectionSprints, // TODO
            //   num_schedule_sprints: schedule.totalSprintDays,
            //   sprints: schedule.sprints.map(sprint =>
            //     sprint.isValid
            //       ? {
            //           date_start: sprint.dateStart,
            //           date_end: sprint.dateEnd,
            //           manager: sprint.manager
            //         }
            //       : null
            //   )
            // })),
            rufree_schedules: form.rufreeSchedules.map(schedule => ({
              rufree_id: schedule.rufreeId,
              rufree_name: schedule.rufreeName,
              inspection_id: schedule.inspectionId,
              role: schedule.role,
              num_inspection_sprints: schedule.numInspectionSprints, // TODO
              num_schedule_sprints: schedule.totalSprintDays,
              sprints: schedule.sprints.map(sprint =>
                sprint.isValid
                  ? {
                      date_start: sprint.dateStart ? sprint.dateStart : null,
                      date_end: sprint.dateEnd ? sprint.dateEnd : null,
                      manager: sprint.manager
                    }
                  : null
              )
            }))
          }
        );

        return data;
      } catch (e) {
        console.log("putSchedule error", e);
        throw e;
      }
    });

    const fetchScheduleOfProjectGroup = flow(function*(projectGroupId: string) {
      try {
        yield axios.post(`/projectGroups/${projectGroupId}/schedule`);
      } catch (e) {
        if (e.response.status !== 400) {
          console.log("create projectGroup error", e);
          return;
        }
      }

      try {
        const { data }: { data: ScheduleResponse } = yield axios.get(
          `/projectGroups/${projectGroupId}/schedule`
        );

        const newForm = ScheduleFormModel.create({
          projectGroupId,
          rufreeSchedules: data.rufree_schedules.map(x => ({
            rufreeId: x.rufree_id,
            rufreeName: x.rufree_name,
            inspectionId: x.inspection_id,
            role: x.role,
            sprints: x.sprints.map(sprint => ({
              dateStart: sprint && sprint.date_start ? sprint.date_start : "",
              dateEnd: sprint && sprint.date_end ? sprint.date_end : "",
              manager: sprint ? sprint.manager : "",
              isValid: !!sprint
            })),
            numInspectionSprints: x.num_inspection_sprints,
            numScheduleSprints: x.num_schedule_sprints
          })),
          createdAt: data.created_at,
          updatedAt: data.updated_at,
          dateStart: data.date_start || "",
          dateEnd: data.date_end || ""
        });

        self.currentForm = newForm;
      } catch (e) {
        console.log("fetchScheduleOfProjectGroup error", e);
        throw e;
      }
    });

    return {
      putSchedule,
      fetchScheduleOfProjectGroup,
      updateSprintReports
    };
  });

type ScheduleFormStoreType = typeof ScheduleFormStoreModel.Type;
export interface ScheduleFormStore extends ScheduleFormStoreType {}
