import axios from "axios";
import { types, flow } from "mobx-state-tree";
import { ContractTemplateForm, ContractTemplateFormModel } from "./ContractTemplateForm";
import { ContractTemplateResponse } from "../../../types/contract";


// const mapContractTemplate = (x: ContractTemplateResponse) => {
//   return {
//     id: x.id,
//     template_name: x.template_name,
//     template_body: x.template_body || '',
//     author: x.author,
//   }
// }

export const ContractTemplateFormStoreModel = types
.model("ContractTemplateFormStore", {
    currentForm: types.optional(ContractTemplateFormModel, {})
  })
  .actions(self => {
    const initContractTemplateForm = () => {
      self.currentForm = ContractTemplateFormModel.create({});
    };

    const fetchContractTemplate = flow(function*(id: string) {
      try {
        const { data }: { data: ContractTemplateResponse } = yield axios.get(
          `/contractTemplate/${id}`
        );

        const newForm = ContractTemplateFormModel.create(data)
        self.currentForm = newForm;
      } catch (e) {
        throw e;
      }
    });

    const postContractTemplate = flow(function*() {
      try {
        const { data }: { data: ContractTemplateResponse } = yield axios.post(
          `/contractTemplate`,
          self.currentForm
        );
        const newForm = ContractTemplateFormModel.create(data)
        self.currentForm = newForm;
        return data;
      } catch (e) {
        throw e;
      }
    });

    const patchContractTemplate = flow(function*() {
      try {
        const { data }: { data: ContractTemplateResponse } = yield axios.patch(
          `/contractTemplate/${self.currentForm.id}`,
          {
              template_name: self.currentForm.template_name,
              template_body: self.currentForm.template_body,
              author: self.currentForm.author
          }
        );

        const newForm = ContractTemplateFormModel.create(data)
        self.currentForm = newForm;
        return data;
      } catch (e) {
        throw e;
      }
    });
    const setForm = (val:any) =>{
      self.currentForm = val;
    };
    return {
      initContractTemplateForm,
      fetchContractTemplate,
      postContractTemplate,
      patchContractTemplate,
      setForm
    };
  });

type ContractTemplateFormStoreType = typeof ContractTemplateFormStoreModel.Type;
export interface ContractTemplateFormStore extends ContractTemplateFormStoreType {}