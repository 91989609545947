import { types } from "mobx-state-tree";

export const ProjectGroupRiskFormModel = types
  .model("ProjectGroupRiskForm", {
    checked: types.optional(types.boolean, false),
    title: types.optional(types.string, ""),
    content: types.optional(types.string, ""),
    etc: types.optional(types.boolean, false)
  })
  .actions(self => ({
    setChecked(value: boolean) {
      self.checked = value;
    },
    setTitle(value: string) {
      self.title = value;
    },
    setContent(value: string) {
      self.content = value;
    },
    setEtc(value: boolean) {
      self.etc = value;
    }
  }));

type ProjectGroupRiskFormType = typeof ProjectGroupRiskFormModel.Type;
export interface ProjectGroupRiskForm extends ProjectGroupRiskFormType {}
