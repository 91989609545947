import { types } from "mobx-state-tree";

export const RufreeExperienceFormModel = types
  .model("RufreeExperienceForm", {
    title: types.optional(types.string, ""),
    workingTime: types.optional(types.string, ""),
    detail: types.optional(types.string, "")
  })
  .actions(self => ({
    setTitle(value: string) {
      self.title = value;
    },
    setWorkingTime(value: string) {
      self.workingTime = value;
    },
    setDetail(value: string) {
      self.detail = value;
    }
  }));

type RufreeExperienceFormType = typeof RufreeExperienceFormModel.Type;
export interface RufreeExperienceForm extends RufreeExperienceFormType {}
