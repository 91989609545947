import React, { Component } from "react";
import styled from "styled-components/macro";
import { computed, observable } from "mobx";
import { RouteComponentProps } from "react-router";
import { inject, observer } from "mobx-react";

import { AppStore } from "../../../store/AppStore";
import { FormMode } from "../../../types/formMode";

import { Request, Task } from "../../../store/models/Parttime";
import RequestSummaryRow from "../../molecules/ParttimeRow/RequestSummaryRow";
import TaskSummaryRow from "../../molecules/ParttimeRow/TaskSummaryRow";
import RequestFormDialog from "../../molecules/ParttimeDialog/RequestFormDialog";


import { Button } from "@blueprintjs/core";
import {PageNavText, PageNavWrapper, PageNavLink } from "../../atoms/PageNav/PageNav";
import { TableHeader, Header } from "../../atoms/CardTable/CardTable";
import LargeContainer from "../../atoms/LargeContainer/LargeContainer";
import TaskRow from "../../molecules/ParttimeRow/TaskRow";

const Wrapper = styled.div``;

const Container = styled.div``;

const Content = styled.div`
  margin-bottom: 50px;
`;

const PageNavLeftLink = styled(PageNavLink)`
  margin-left: 0;
`;

interface PartTimeDashboardPageProps extends RouteComponentProps {}

interface InjectedProps extends PartTimeDashboardPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class PartTimeDashboardPage extends Component<PartTimeDashboardPageProps> {
    @observable isRequestFormDialogOpen: boolean = false;

    get injected() {
        return this.props as InjectedProps;
    }

    @computed
    get requestStore() {
        return this.injected.appStore.parttimeRequestStore;
    }

    @computed
    get taskStore() {
        return this.injected.appStore.parttimeTaskStore;
    }

    handleRequestClick = (request: Request) => {
        this.props.history.push(`/parttime/requests/${ request.id }`);
    };

    handleTaskClick = (task: Task) => {
        this.props.history.push(`/parttime/requests/${ task.request.id }/tasks/${ task.id }`);
    };

    handleRequestFormDialogOpen = () => {
        this.isRequestFormDialogOpen = true;
    };

    handleRequestFormDialogClose = async () => {
        this.isRequestFormDialogOpen = false;
        await this.injected.appStore.parttimeRequestStore.fetchRequests({pageSize: 5, status: ['SUBMITTED', 'CANCELED']});
    };  

    async componentDidMount() {
        try {
            await this.injected.appStore.parttimeRequestStore.fetchRequests({pageSize: 5, status: ['SUBMITTED', 'CANCELED']});
            await this.injected.appStore.parttimeTaskStore.fetchDashboardTasks();
        } catch (e) {}
    }

    render() {
        const { isPM, isClient, isRufree } = this.injected.appStore.userStore;
        const { requests } = this.requestStore;
        const { tasksInProgress, tasksInSettlement } = this.taskStore;
        
        return (
            <LargeContainer>

            {(isClient) && (
                <Button 
                    text={"새 TASK 신청"} 
                    onClick={this.handleRequestFormDialogOpen}
                    style={{width: "150px", borderRadius: "20px", background: "#ff6565", float: "right", boxShadow: "none", color: "white"}}
                />
            )}
                {(isPM || isClient) && (
                    <Container>    
                        <PageNavWrapper style={{height: "40px", paddingTop: "0"}}>
                            <PageNavLeftLink to="/parttime/requests">
                                <PageNavText>
                                    <span>신청 ></span>
                                </PageNavText>
                            </PageNavLeftLink>
                        </PageNavWrapper>
                        <Content>
                            { requests ? (
                                requests.map(request => (
                                    <RequestSummaryRow
                                        key={ request.id }
                                        request={ request }
                                        onClick={() => this.handleRequestClick(request)}
                                    />
                                ))
                            ) : (
                                <>신청내역이 없거나 TASK로 전환되었습니다.</>
                            )
                            }
                        </Content>
                    </Container>
                )}

                {(isPM || isClient) && (
                    <Container>
                        <PageNavWrapper style={{height: "40px", paddingTop: "0"}}>
                            <PageNavLeftLink to="/parttime/tasks">
                                <PageNavText>
                                    <span>TASK ></span>
                                </PageNavText>
                            </PageNavLeftLink>
                        </PageNavWrapper>
                        <Content>
                            {(Array.isArray(tasksInProgress) && tasksInProgress.length) ? (
                                <TableHeader>
                                    <Header style={{ maxWidth: "104px" }}>번호</Header>
                                    <Header style={{ maxWidth: "250px" }}>제목</Header>
                                    <Header style={{ maxWidth: "270px" }}>역할</Header>
                                    <Header style={{ maxWidth: "200px" }}>소속명</Header>
                                    <Header style={{ maxWidth: "200px" }}>알유프리</Header>
                                    <Header style={{ maxWidth: "283px" }}>상태</Header>
                                    <Header style={{ maxWidth: "200px" }}>예상작업시간</Header>
                                </TableHeader>
                            ) : (<></>)}
                            { (Array.isArray(tasksInProgress) && tasksInProgress.length) ? (
                                <TableBody style={{ minHeight: "auto" }}>
                                    { tasksInProgress.map(task => (
                                        <TaskRow
                                            key={ task.id }
                                            task={ task }
                                            onClick={() => this.handleTaskClick(task)}
                                        />
                                    )) }
                                </TableBody>
                            ) : (
                                <>현재 진행 중인 TASK가 없습니다.</>
                            )
                            }
                        </Content>
                    </Container>
                )}
                
                {(isRufree) && (
                    <Container>
                        <PageNavWrapper style={{height: "40px", paddingTop: "0"}}>
                            <PageNavLeftLink to="/parttime/tasks">
                                <PageNavText>
                                    <span>나의 TASK ></span>
                                </PageNavText>
                            </PageNavLeftLink>
                        </PageNavWrapper>
                        <Content>
                            { (Array.isArray(tasksInProgress) && tasksInProgress.length) ? (
                                tasksInProgress.map(task => (
                                    <TaskSummaryRow
                                        key={ task.id }
                                        task={ task }
                                        onClick={() => this.handleTaskClick(task)}
                                    />
                                ))
                            ) : (
                                <>현재 진행 중인 나의 TASK가 없습니다.</>
                            )
                            }
                        </Content>
                    </Container>
                )}
                
                {/*{(isPM || isRufree) && (*/}
                {/*    <Container>*/}
                {/*        <PageNavWrapper style={{height: "40px", paddingTop: "0"}}>*/}
                {/*            <PageNavLeftLink to="/parttime/tasks?status=SETTLEMENT">*/}
                {/*                <PageNavText>*/}
                {/*                    <span>정산 ></span>*/}
                {/*                </PageNavText>*/}
                {/*            </PageNavLeftLink>*/}
                {/*        </PageNavWrapper>*/}
                {/*        <Content>*/}
                {/*            { (Array.isArray(tasksInSettlement) && tasksInSettlement.length) ? (*/}
                {/*                tasksInSettlement.map(task => (*/}
                {/*                    <TaskSummaryRow*/}
                {/*                        key={ task.id }*/}
                {/*                        task={ task }*/}
                {/*                        onClick={() => this.handleTaskClick(task)}*/}
                {/*                    />*/}
                {/*                ))*/}
                {/*            ) : (*/}
                {/*                <>정산 되지 않은 TASK가 없습니다.</>*/}
                {/*            )*/}
                {/*            }*/}
                {/*        </Content>*/}
                {/*    </Container>*/}
                {/*)}  */}

                <RequestFormDialog
                    id={ -1 }
                    mode={ FormMode.Create }
                    onClose={ this.handleRequestFormDialogClose }
                    isOpen={ this.isRequestFormDialogOpen } 
                />
            </LargeContainer>
          );
    }
}

export default PartTimeDashboardPage;


const TableBody = styled.div`
  min-height: 500px;
`;