import { types } from "mobx-state-tree";

export const ProjectGroupWorkerModel = types.model("ProjectGroupWorker", {
  name: types.string,
  phone: types.string,
  email: types.string
});

type ProjectGroupWorkerType = typeof ProjectGroupWorkerModel.Type;
export interface ProjectGroupWorker extends ProjectGroupWorkerType {}
