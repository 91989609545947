import React, { Component, ChangeEvent } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { computed, observable } from "mobx";
import { AppStore } from "../../../store/AppStore";
import { Button, Dialog, Classes, FormGroup, InputGroup, TextArea, Intent } from "@blueprintjs/core";
import { DateInput, TimePicker, TimePrecision } from "@blueprintjs/datetime";
import { getMomentFormatter } from "../../../utils/date";
import moment from 'moment';
import { FormMode } from "../../../types/formMode";
import { ProjectGroup } from "../../../store/models/ProjectGroup";
import { ScheduleManager } from "../../../store/models/ScheduleManager";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";

const FormGroupLabel = styled.div`
  font-weight: bold;
  width: 120px;
  line-height: 18px;
`;

interface ScheduleDetailPopupProps {
  schedule: ScheduleManager | null;
  projectGroup?: ProjectGroup;
  creator: number;
  isOpened: boolean;
  onClose: () => void;
  mode: FormMode;
}

interface InjectedProps extends ScheduleDetailPopupProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ScheduleDetailPopup extends Component<ScheduleDetailPopupProps> {
  // @observable schedule: ScheduleManager | null = null;
  @observable startDate = moment().format('YYYY-MM-DD');
  @observable startTime = '00:00';
  // @observable title: string = '';
  // @observable comment: string= '';

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get formStore() {
    return this.injected.appStore.scheduleManagerFormStore;
  }

  @computed
  get currentForm() {
    return this.formStore.currentForm;
  }

  handleStartDate = (selectedDate: Date) => {
    this.startDate = moment(selectedDate).format('YYYY-MM-DD');
    this.handleSetStartAt();
  }

  handleStartTime = (selectedTime: Date) => {
    this.startTime = moment(selectedTime).format('HH:mm');
    this.handleSetStartAt();
  }

  handleSetStartAt = () => {
    this.currentForm.setStartAt(`${this.startDate}T${this.startTime}`)
  }

  handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.currentForm.setTitle(e.target.value);
  };

  handleCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.currentForm.setComment(e.target.value);
  };

  handleSubmit = async () => {
    try {
      await this.formStore.postSchedule();
      AppToaster.show({
        message: "새로운 일정이 등록되었습니다.",
        intent: Intent.SUCCESS
      });

      this.props.onClose();
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  }
  handleEditSubmit = async () => {
    try {
      await this.formStore.putSchedule();
      AppToaster.show({
        message: "새로운 일정이 수정되었습니다.",
        intent: Intent.SUCCESS
      });
      this.props.onClose();

    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  }

  async componentDidMount() {
  }

  handleOpening = async() => {
     const { schedule, mode, projectGroup, creator } = this.props;

      if(projectGroup) {
        await this.formStore.initForm(projectGroup.groupId, projectGroup.name, creator);
      } else {
        await this.formStore.initForm('', '', -1);
      }
      if (mode === FormMode.Edit && schedule) {
        await this.formStore.fetchSchedule(schedule.id);
        this.startDate = moment(this.currentForm.startAt).format('YYYY-MM-DD');
        this.startTime = moment(this.currentForm.startAt).format('HH:mm');
      } else {
        this.startDate = moment().format('YYYY-MM-DD');
        this.startTime = '00:00';
      }

      this.handleSetStartAt();
  };

  render() {
    const { schedule, isOpened, onClose } = this.props;
    const title = `${this.currentForm ? this.currentForm.title : '일정 등록'}`;

    return (
      <Dialog
        onOpening={this.handleOpening}
        isOpen={isOpened}
        usePortal={true}
        autoFocus={true}
        enforceFocus={true}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        isCloseButtonShown={false}
        title={title}
      >

      <div className={Classes.DIALOG_BODY}>
        {this.currentForm && (
          <>
            <FormGroup label={<FormGroupLabel>프로젝트그룹</FormGroupLabel>} inline={true}>
              {this.currentForm.projectGroup}
            </FormGroup>
            <FormGroup label={<FormGroupLabel>프로젝트그룹명</FormGroupLabel>} inline={true}>
              {this.currentForm.projectGroupName}
            </FormGroup>
            <FormGroup label={<FormGroupLabel>날짜</FormGroupLabel>} inline={true}>
            {
              <DateInput
                {...getMomentFormatter("YYYY/MM/DD")}
                locale="ko"
                closeOnSelection={true}
                value={moment(this.startDate).toDate()}
                onChange={this.handleStartDate}
                maxDate={new Date("2050-01-01")}
              />
            }
            </FormGroup>
            <FormGroup label={<FormGroupLabel>시간(옵션)</FormGroupLabel>} inline={true}>
              <TimePicker 
                precision = {TimePrecision.MINUTE}
                selectAllOnFocus = {false}
                showArrowButtons = {true}
                useAmPm = {false}
                value={moment(`${this.startDate}T${this.startTime}`).toDate()}
                onChange={this.handleStartTime}
              />
            </FormGroup>
            <FormGroup label={<FormGroupLabel>일정제목</FormGroupLabel>} inline={true}>
              <InputGroup
                value={this.currentForm.title}
                onChange={this.handleTitleChange}
              />
            </FormGroup>
            <FormGroup label={<FormGroupLabel>일정내용(옵션)</FormGroupLabel>} inline={true}>
              <TextArea
                value={this.currentForm.comment}
                onChange={this.handleCommentChange}
                style={{ width: "100%" }}
              />
            </FormGroup>
          </>
        )}
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {
            !schedule ? (
              <Button text="등록" intent={Intent.PRIMARY} onClick={this.handleSubmit} />
            ) : (
              <Button text="수정" intent={Intent.PRIMARY} onClick={this.handleEditSubmit} />
            )
          }
            <Button text="취소" onClick={onClose} />
          </div>
      </div>
      </Dialog>
    );
  }
}

export default ScheduleDetailPopup;
