import { flow, types } from "mobx-state-tree";
import { InspectionTemplateResponse } from "../types/inspection";
import axios from "axios";
import { orderBy } from "lodash";
import { SprintDayModel } from "./forms/InspectionForm";
import { MAX_SPRINTS } from "../constants/constants";
import { createDefaultSprints, createDefaultServiceSprints, SprintCheckModel } from "./forms/SprintCheck";
import { IInspectionTemplate, InspectionTemplateModel } from "./models/InspectionTemplate";

export const mapInspectionTemplate = (x: InspectionTemplateResponse) => {
  return {
    id: x.id,
    name: x.name,
    review: x.review,
    attention: x.attention,

    sprintDays: x.sprint_days.map(sprintDay =>
      SprintDayModel.create({ value: sprintDay })
    ),
    clientRoles: x.client_roles.map(clientRole => {
      return {
        ...clientRole,
        sprints: clientRole.sprints.map(sprint =>
          SprintCheckModel.create({ value: sprint })
        )
      };
    }),
    rufreeRoles: x.rufree_roles.map(clientRole => {
      return {
        ...clientRole,
        sprints: clientRole.sprints.map(sprint =>
          SprintCheckModel.create({ value: sprint })
        )
      };
    }),
    seesoServices: x.seeso_services.map(clientRole => {
      return {
        ...clientRole,
        sprints: clientRole.sprints.map(sprint =>
          SprintCheckModel.create({ value: sprint })
        )
      };
    }),

    creatorName: x.creator ? x.creator.name : "",
    modifierName: x.modifier ? x.modifier.name : "",
    dateCreated: x.date_created,
    dateUpdated: x.date_updated ? x.date_updated : ""
  };
};


export const InspectionTemplateStoreModel = types
  .model("InspectionStore", {
    templates: types.array(InspectionTemplateModel),

    isLoading: types.boolean,
    formMode: types.number,// FormMode
    openPopup: types.boolean,
    selectedTemplate: types.optional(InspectionTemplateModel, {
      id: -1,
      name: "",
      review: "",
      attention: "",
      sprintDays: [],
      clientRoles: [],
      rufreeRoles: [],
      seesoServices: [],
      creatorName: "",
      modifierName: "",
      dateCreated: "",
      dateUpdated: ""
    }),
    // 검색
    searchText: types.maybe(types.string)
  })
  .views(self => ({
    get descTemplates() {
      return orderBy(self.templates, ["id"], ["desc"]);
    }
  }))
  .actions(self => ({
    setFetching() {
      self.isLoading = true;
    },
    setFetched() {
      self.isLoading = false;
    },
    setFormMode(mode: number) {
      self.formMode = mode;
    },
    setOpenPopup(open: boolean) {
      self.openPopup = open;
    },
    /*setSelectedTemplate(template?: IInspectionTemplate) {
      // self.selectedTemplate = template;
      if(template) {
        self.selectedTemplate = { ...template };
      } else {
        self.selectedTemplate = undefined;
      }
    },*/
    setSearchText(text: string) {
      self.searchText = text;
    }
  }))
  .actions(self => ({
    getTemplates() {
      let templates: IInspectionTemplate[] = self.templates;
      return orderBy(templates, ["id"], ["desc"]);
    },

  }))
  .actions(self => {

    const initForm = () => {
      self.selectedTemplate = InspectionTemplateModel.create({
        id: -1,
        name: "",
        review: "",
        attention: "",
        sprintDays: Array(MAX_SPRINTS)
          .fill(null)
          .map((_, index) =>
            SprintDayModel.create({ value: index === 0 ? 0 : 14 })
          ),
        clientRoles: [
          {
            role: "",
            sprints: createDefaultSprints()
          }
        ],
        rufreeRoles: [
          {
            role: "백엔드",
            wage: 2000000,
            sprints: createDefaultSprints()
          },
          {
            role: "프론트엔드",
            wage: 2000000,
            sprints: createDefaultSprints()
          }
        ],
        seesoServices: [
          {
            service: "CS 매니지먼트",
            cost: 0,
            individual: true,
            sprints: createDefaultServiceSprints()
          },
          {
            service: "워크플로우 설계",
            cost: 0,
            individual: true,
            sprints: createDefaultServiceSprints()
          },
          // {
          //   service: "업무 프로세스 교육",
          //   cost: 150000,
          //   individual: false,
          //   sprints: createDefaultSprints()
          // },
          // {
          //   service: "프로젝트 환경 구성",
          //   cost: 200000,
          //   individual: false,
          //   sprints: createDefaultSprints()
          // },
          // {
          //   service: "업무 분석 및 최초 업무 카드 세팅",
          //   cost: 3600000,
          //   individual: false,
          //   sprints: createDefaultSprints()
          // },
          // {
          //   service: "스프린트 운영",
          //   cost: 150000,
          //   individual: true,
          //   sprints: createDefaultSprints()
          // },
          // {
          //   service: "실무자 리스크 관리",
          //   cost: 100000,
          //   individual: true,
          //   sprints: createDefaultSprints()
          // },
          // {
          //   service: "(옵션)스프린트 업무카드 검수",
          //   cost: 750000,
          //   individual: false,
          //   sprints: createDefaultSprints()
          // },
          // {
          //   service: "(옵션)통합QA 서비스",
          //   cost: 3000000,
          //   individual: false,
          //   sprints: createDefaultSprints()
          // },
          // {
          //   service: "(옵션)기획리뷰 레포트 서비스",
          //   cost: 500000,
          //   individual: false,
          //   sprints: createDefaultSprints()
          // }
        ],

        creatorName: "",
        modifierName: "",
        dateCreated: "",
        dateUpdated: ""
      });
    };

    const fetchById = flow(function* (id: number) {

      try {
        self.setFetching();

        const { data }: { data: InspectionTemplateResponse } = yield axios.get(
          `/inspectionTemplate/${id}`
        );

        self.setFetched();

        self.selectedTemplate =  InspectionTemplateModel.create(mapInspectionTemplate(data));
      } catch (e) {
        throw e;
      }

    });

    const fetch = flow(function* () {

      try {
        self.setFetching();

        const { data }: { data: InspectionTemplateResponse[] } = yield axios.get(
          `/inspectionTemplate?${self.searchText ? `search=${self.searchText}` : ""}`
        );

        const templates = data.map(x =>
          InspectionTemplateModel.create(mapInspectionTemplate(x))
        );
        self.templates.replace(templates);
      } catch (e) {
        throw e;
      }
      self.setFetched();
    });
    const mapFormDataForRequest = () => {
      const form = self.selectedTemplate;

      return {
        id: form.id == -1 ? null : form.id,
        name: form.name || "제목없음",
        review: form.review,
        attention: form.attention,

        sprint_days: form.sprintDays.map(x => x.value),
        client_roles: form.clientRoles
          .filter(x => !!x.role)
          .map(clientRole => ({
            role: clientRole.role,
            sprints: clientRole.sprints.map(x => x.value)
          })),
        rufree_roles: form.rufreeRoles
          .filter(x => !!x.role)
          .map(rufreeRole => ({
            role:
              rufreeRole.role === "기타"
                ? rufreeRole.roleTextBufferForETC
                : rufreeRole.role,
            wage: rufreeRole.wage,
            sprints: rufreeRole.sprints.map(x => x.value)
          })),
        seeso_services: form.seesoServices
          .filter(x => !!x.service)
          .map(seesoService => ({
            service: seesoService.service,
            cost: seesoService.cost,
            individual: seesoService.individual,
            sprints: seesoService.sprints.map(x => x.value)
          }))
      };
    };
    const post = flow(function* () {
      try {
        self.setFetching();

        const { data }: { data: InspectionTemplateResponse } = yield axios.post(
          `/inspectionTemplate`,
          mapFormDataForRequest()
        );

        // const templates = data.map(x =>
        //   NotificationTemplateModel.create(mapper(x))
        // );
        // self.templates.replace(templates);
      } catch (e) {
        throw e;
      }
      self.setFetched();
    });
    const update = flow(function* () {
      try {
        self.setFetching();

        const { data }: { data: InspectionTemplateResponse } = yield axios.patch(
          `/inspectionTemplate/${self.selectedTemplate.id}`,
          mapFormDataForRequest()
        );

        // const templates = data.map(x =>
        //   NotificationTemplateModel.create(mapper(x))
        // );
        // self.templates.replace(templates);
      } catch (e) {
        throw e;
      }
      self.setFetched();
    });
    const setForm = (val: any) => {
      self.selectedTemplate = val;
    };
    return {
      initForm,
      setForm,
      fetchById,
      fetch,
      post,
      update
    };
  });

type InspectionTemplateStoreType = typeof InspectionTemplateStoreModel.Type;

export interface InspectionTemplateStore extends InspectionTemplateStoreType {
}
