/*
  파트너 회원 상세보기
*/
import React, { useEffect } from 'react';
import { inject, observer } from "mobx-react";
import { RouteComponentProps } from "react-router";
import styled from "styled-components/macro";
import moment from "moment";

import { AppStore } from '../../../../store/AppStore';
import { PartnerStore } from '../../../../store/PartnerStore';
import { STATUS } from '../../../../store/models/Partner';

import { Spinner, Card, Colors } from '@blueprintjs/core';
import { Comments } from '.';


const Wrapper = styled.div`
  & {
    max-width: 800px;
    h2:first-child {
      margin-block-end: 0;
    }
    h2 + h2 {
      margin-block-start: 0;
    }
    label {
      font-weight: bold;
    }
    .bp3-card > h4 {
      color: ${ Colors.BLUE2 }
    }
    .bp3-card > div + div {
      margin-top: 5px;
    }
    .bp3-card + .bp3-card {
      margin-top: 15px;
    }
    span.resource + span.resource:before {
      content: ', '
    }
    h4 {
      margin-block-start: 0;
    }
  }
`;
interface IProps extends RouteComponentProps<{code: string;}> {
  appStore: AppStore
}
const SecondteamPartnerDetail = inject("appStore")(observer((props: IProps) => {
  const userStore = (props as { appStore: AppStore }).appStore.userStore;
  const store: PartnerStore = (props as { appStore: AppStore }).appStore.partnerStore;
  const { partner, fetchPartner } = store;

  useEffect(() => {
    const fetch = async () => {
      await fetchPartner(props.match.params.code);
    }

    fetch();
  }, []);

  if(partner === null) {
    return (
      <Spinner />
    )
  }

  return (
    <Wrapper>
      <h2>{ partner.code }</h2>
      <h2>{ partner.company_name }</h2><br/>
      <h3>프로필</h3>
      <Card>
        <h4>가입 상태</h4>
        <Field label='가입 상태'>
          { partner.statusToString }
        </Field>
        {
          partner.status === STATUS.REJECTED &&
          <Field label='거절 사유'>
            { partner.status_detail }
          </Field>
        }
        <Field label='가입일'>
          { moment(partner.created).format('YYYY-MM-DD') }
        </Field>
        <Field label='가입승인일'>
          { partner.date_approved }
        </Field>
        <Field label='가입가입완료일'>
          { partner.date_completed }
        </Field>
      </Card>

      <Card>
        <h4>계정 정보</h4>
        <Field label='이름'>
          { partner.name }
        </Field>
        <Field label='이메일'>
          { partner.email }
        </Field>
        <Field label='핸드폰 번호'>
          { partner.phone }
        </Field>
        <Field label='마케팅 정보 수신 동의 여부'>
          { partner.marketing_agreement ? '동의' : '미동의' }
        </Field>
      </Card>

      <Card>
        <h4>회사 정보</h4>
        <Field label='회사 이름'>
          { partner.company_name }
        </Field>
        <Field label='서비스 종류'>
          { partner.business_area }
        </Field>
        <Field label='IT 멤버 구성'>
          { partner.resourceToString.map(resource => <span className='resource'>{ resource }</span>) }
        </Field>
      </Card>

      
      <Comments
        currentUserEmail= { userStore.email }
        partner={ partner }
        fetchPartner={ fetchPartner } />
    </Wrapper>
  )
}));
export default SecondteamPartnerDetail;


const Field = (props: any) => {
  return <div>
    <label>{ props.label } : </label>
    { props.children }
  </div>;
}