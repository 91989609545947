import React, { FC, useState } from "react";
import styled from 'styled-components/macro';
import moment from 'moment';
import { Classes } from "@blueprintjs/core";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";

import { Toolbar } from "../../atoms/DashboardComponents/CalendarComponents";
import DashboardSchedulePopup from "../../molecules/Dashboard/DashboardSchedulePopup";
import DashboardTaskPopup from "../../molecules/Dashboard/DashboardTaskPopup";

const localizer = momentLocalizer(moment);


const Wrapper = styled.div`
`;
const CalendarWrapper = styled(Calendar)`
  height: 600px !important;
  scroll-y: auto;
`
interface EventProps {
  id: number;
  category: string;
  title: string;
  start: Date;
  end: Date;
}

interface ScheduleCalendarProps {
  readyToShow: boolean;
  events: EventProps[] | undefined;
  onChangeRange: (dates: object) => void;
  onPopupClose: () => void;
}
const ScheduleCalendar: FC<ScheduleCalendarProps> = props => {
  const [ isPopupOpen, setIsPopupOpen ] = useState(false);
  const [ isTaskPopupOpen, setIsTaskPopupOpen ] = useState(false);
  const [ selectedId, setSelectedId ] = useState(-1);

  return (
      <Wrapper
        className={props.readyToShow ? '' : Classes.SKELETON}>

        <CalendarWrapper
          localizer={localizer}
          events={props.events}
          defaultDate={new Date()}
          views={{ month: true, day: true }}
          defaultView="month"
          components={
            {
              // event: Event,
              // dateCellWrapper: Event,
              toolbar: Toolbar,
              // agenda: {
              //   event: Event
              // }
            }
          }
          eventPropGetter={
            (event: any, start, end, isSelected) => {
              let backgroundColor = '#A7B6C2';
              switch (event.category) {
                case "prg":
                  backgroundColor = '#2B95D6';
                  break;
                case "ofh":
                  backgroundColor = '#15B371';
                  break;
                case "sch":
                  backgroundColor = '#F29D49';

                  const schCategory = event.schCategory;
                  if (schCategory === 'vac' || schCategory === 'vam' || schCategory === 'vpm') {
                    backgroundColor = '#FF6E4A';
                  }

                  break;
                case "tas":
                  backgroundColor = '#A854A8';
                  break;
                default:
                  break;
              }

              return {
                  style: {
                    border: 'none',
                    boxSizing: 'border-box',
                    boxShadow: 'none',
                    margin: 0,
                    padding: '2px 5px',
                    backgroundColor: backgroundColor,
                    borderRadius: '5px',
                    color: '#fff',
                    cursor: 'pointer',
                    width: '100%',
                    textAlign: 'left',
                    fontSize: '11px',
                    fontWeight: 'bold'
                  }
              };
            }
          }

          onSelectEvent={
            (event:any , e) => {
              if(event.hasOwnProperty('id') && event.id > 0) {
                setSelectedId(event.id);
                if(event.category === 'tas') {
                  setIsTaskPopupOpen(true);
                } else {
                  setIsPopupOpen(true);
                }
              } else if(event.htmlLink) {
                window.open(event.htmlLink, '_black');
              }
            }
          }

          onRangeChange={ (dates: object) => {
            props.onChangeRange(dates);
          }}
          
        />

        <DashboardSchedulePopup
          id={selectedId}
          onClose={async() => {
            setIsPopupOpen(false);
            props.onPopupClose();
          }}
          isOpened={isPopupOpen} />

        <DashboardTaskPopup
          id={selectedId}
          onClose={async() => {
            setIsTaskPopupOpen(false);
            props.onPopupClose();
          }}
          isOpened={isTaskPopupOpen} />          

    </Wrapper>
  );
}

export default ScheduleCalendar;