import React, { ChangeEvent, Component, MouseEvent } from "react";
import styled from "styled-components/macro";
import { GrayLink, GreyLabel } from "../../atoms/DetailReportComponents/DetailReportComponents";
import { Alert, Button, Classes, InputGroup, Intent } from "@blueprintjs/core";
import { computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { SubTaskListForm } from "../../../store/forms/TaskManagerForm/SubTaskListForm";
import { SubTask } from "../../../store/models/SubTask";
import { AppStore } from "../../../store/AppStore";
import SubTaskListItemView from "./SubTaskListItemView";
import {
  DragDropContext, DropResult,
  Droppable, DroppableProvided, DroppableStateSnapshot,
  Draggable, DraggableProvided, DraggableStateSnapshot
} from "react-beautiful-dnd";

const SubTaskListContainer = styled.div`
  margin-left: 10px;
`
const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Wrapper = styled.div`
  marginBottom: 10px;
`;

interface Props {
  subTaskListForms: SubTaskListForm[];
  onNewSubTaskList: (val: string) => void;
  onNewSubTaskTitle: (subTaskListId: string, val: string) => void;
  onRemoveSubTaskTitle: (subTask: SubTask) => void;
  onUpdateSubTask: (subTask: SubTask) => void;
  onSubTaskDragEnd: (result: DropResult) => void;
  disabled: boolean | undefined;
  loaded: boolean;
}

interface InjectedProps extends Props {
  appStore: AppStore;
}

@inject("appStore")
@observer
class SubTaskListView extends Component<Props> {
  @observable newSubTaskList = false;
  @observable newSubTaskListTitle = "";

  @observable newSubTask = false;

  @observable selectedSubTask: SubTask | undefined;
  @observable selectedSubTaskId = "";
  @observable selectedSubTaskListId = "";
  @observable showEditButtons: boolean = false;
  @observable showDeleteAlert: boolean = false;
  @observable showEditAlert: boolean = false;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get formStore() {
    return this.injected.appStore.taskManagerTaskFormStore;
  }

  @computed
  get currentForm() {
    return this.formStore.currentForm;
  }

  componentDidMount() {
  }

  handleCreateSubTitleList = () => {
    this.props.onNewSubTaskList(this.newSubTaskListTitle);
    this.newSubTaskListTitle = "";
    this.newSubTaskList = false;
  };

  /*handleDeleteSubmit = async () => {
    console.log("handleDeleteSubmit");

    this.selectedSubTaskId = ""
    this.selectedSubTaskListId = ""
    this.showDeleteAlert = false;
  };*/

  handleDragEnd = async (result: DropResult) => {
    await this.props.onSubTaskDragEnd(result);
  }

  render() {
    const { disabled, subTaskListForms, onNewSubTaskList, onNewSubTaskTitle, onRemoveSubTaskTitle, onUpdateSubTask } = this.props;

    return (
      this.props.loaded ?
        <Wrapper>
          <Container>
            <GreyLabel style={{ display: "inline-block" }}>Subtask 목록</GreyLabel>
            <GrayLink onClick={() => {
              if (disabled === false)
                this.newSubTaskList = !this.newSubTaskList;
            }}>Subtask 목록 추가</GrayLink>
          </Container>

          {
            this.newSubTaskList ?
              <div>
                <div style={{ float: "left", marginLeft: "5px", marginRight: "10px", width: "200px" }}>
                  <InputGroup id="text-input" placeholder="목록 타이틀을 입력해주세요." value={this.newSubTaskListTitle}
                              disabled={disabled}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                this.newSubTaskListTitle = e.target.value;
                              }} fill={true}
                              onKeyDown={(event => {
                                if (event.key === "Enter") {
                                  this.handleCreateSubTitleList();
                                }
                              })}
                  />
                </div>
                <div style={{ float: "left" }}>
                  <Button style={{ display: "inline-block" }} text="저장"
                          onClick={(e: MouseEvent<HTMLElement>) => {
                            this.handleCreateSubTitleList();
                          }}
                          disabled={disabled}
                  />
                  <Button disabled={disabled} style={{ display: "inline-block" }} text="취소"
                          onClick={() => this.newSubTaskList = false}/>
                </div>
                <div style={{ clear: "left" }}></div>
              </div>
              : null
          }


          <SubTaskListContainer>
            <DragDropContext onDragEnd={this.handleDragEnd}>
              <Droppable droppableId="board" type="list">
                {(droppableProvided: DroppableProvided, droppableSnapshot: DroppableStateSnapshot) => (
                  <div
                    {...droppableProvided.droppableProps}
                    ref={droppableProvided.innerRef}
                    style={{background: droppableSnapshot.isDraggingOver ? "lightgrey": ""}}
                  >
                    {subTaskListForms.map((subTaskList, index) => (
                      <Draggable key={subTaskList.id} draggableId={`l-${subTaskList.id}`} index={index}>
                        {(draggableProvided: DraggableProvided, draggableSnapshot: DraggableStateSnapshot) => (
                          <div 
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                            ref={draggableProvided.innerRef}
                          >
                            <SubTaskListItemView disabled={disabled} key={`${index}stliv`} subTaskList={subTaskList}/>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {droppableProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </SubTaskListContainer>


          <Alert
              icon="trash"
              intent={Intent.DANGER}
              isOpen={this.showDeleteAlert}
              onCancel={(e: any) => {
                this.showDeleteAlert = false;
                e.stopPropagation();
              }}
              onConfirm={(e: any) => {
                // this.handleDeleteSubmit();
                // if (this.selectedSubTask)
                //   onRemoveSubTaskTitle(this.selectedSubTask);
                //
                // this.selectedSubTask = undefined;
                // this.selectedSubTaskId = "";
                // this.selectedSubTaskListId = "";
                // this.showDeleteAlert = false;
                e.stopPropagation();
              }}
              confirmButtonText={"예"}
              cancelButtonText={"아니오"}
            ><p>선택한 SubTask를 삭제하시겠습니까?</p></Alert>
        </Wrapper> :
        <>
          <GreyLabel style={{ display: "inline-block" }}>Subtask 목록</GreyLabel>
          <div style={{ marginLeft: "30px" }}>
            <div className={Classes.SKELETON} style={{ height: "16px", width: "130px", marginTop: "10px" }}></div>
            <div className={Classes.SKELETON}
                 style={{ height: "8px", width: "300px", marginLeft: "5px", marginTop: "10px" }}></div>
            <div className={Classes.SKELETON} style={{ height: "14px", width: "150px", marginTop: "15px" }}></div>
            <div className={Classes.SKELETON} style={{ height: "14px", width: "150px", marginTop: "10px" }}></div>
            <div className={Classes.SKELETON} style={{ height: "14px", width: "150px", marginTop: "10px" }}></div>
            <div className={Classes.SKELETON} style={{ height: "30px", width: "70px", marginTop: "10px" }}></div>
          </div>
        </>
    );
  }
}

export default SubTaskListView;