import { types } from "mobx-state-tree";
import shortid from "shortid";

export const InspectionSelectionFormModel = types
  .model("InspectionSelectionForm", {
    id: types.optional(types.string, ""),
    value: types.optional(types.string, "")
  })
  .actions(self => ({
    afterCreate() {
      self.id = shortid.generate();
    },
    setValue(value: string) {
      self.value = value;
    }
  }));

type InspectionSelectionFormType = typeof InspectionSelectionFormModel.Type;
export interface InspectionSelectionForm extends InspectionSelectionFormType {}
