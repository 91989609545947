import { types } from "mobx-state-tree";

export enum PMReportMood {
  Normal = "NORMAL",
  NotGood = "NOT GOOD",
  Risky = "RISKY",
  Bad = "BAD",
  Issue = "ISSUE",
  Meeting = "MEETING"
}

export const PMReportFormModel = types
  .model("PMReportForm", {
    projectGroup: types.optional(types.string, ""),
    text: types.optional(types.string, ""),
    date: types.optional(types.string, ""),
    mood: types.optional(types.string, PMReportMood.Normal)
  })
  .actions(self => ({
    setProjectGroup(value: string) {
      self.projectGroup = value;
    },
    setText(value: string) {
      self.text = value;
    },
    setDate(value: string) {
      self.date = value;
    },
    setMood(value: PMReportMood) {
      self.mood = value;
    }
  }));

type PMReportFormType = typeof PMReportFormModel.Type;
export interface PMReportForm extends PMReportFormType {}
