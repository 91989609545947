import { types } from "mobx-state-tree";

export const ClientPersonInChargeModel = types.model("ClientPersonInChargeModel", {
  name: types.optional(types.string, ""),
  email: types.optional(types.string, ""),
  phone: types.optional(types.string, "")
});
type ClientPersonInChargeType = typeof ClientPersonInChargeModel.Type;
export interface ClientPersonInCharge extends ClientPersonInChargeType {}

export const ClientFileModel = types.model("ClientFile", {
  id: types.number,
  uuid: types.string,
  fileType: types.string,
  file: types.string,
  name: types.string,
  format: types.string,
  client: types.string  
});
type ClientFileType = typeof ClientFileModel.Type;
export interface ClientFile extends ClientFileType {}


export const ClientModel = types
  .model("Client", {
    clientId: types.identifier,
    name: types.string,
    // users
    createdAt: types.string,
    updatedAt: types.string,
    identityNumber: types.string,
    clientType: types.string,
    representativeName: types.string,
    address: types.string,
    contractPerson: ClientPersonInChargeModel,
    taxPerson: ClientPersonInChargeModel
  })
  .views(self => ({
    get nameWithId() {
      return `${self.clientId} - ${self.name}`;
    }
  }))
  .actions(self => ({
    setName(value: string) {
      self.name = value;
    }
  }));

type ClientType = typeof ClientModel.Type;
export interface Client extends ClientType {}
