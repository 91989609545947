import React, { FC } from "react";
import styled from "styled-components/macro";
import { ClientDepositForm } from "../../../store/forms/CalculateForm/ClientCalculateForm";
import { RemoveButtonContainer } from "../../atoms/RemoveItemButton/RemoveItemButton";
import { Button, Intent, InputGroup } from "@blueprintjs/core";
import InputWithFormat from "../../atoms/InputWithFormat/InputWithFormat";
import { observer } from "mobx-react";
import { ChangeEvent } from "cleave.js/react/props";
import { DateInput } from "@blueprintjs/datetime";
import { getMomentFormatter } from "../../../utils/date";
import moment from "moment";

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 5px;
  margin-left: 30px;
`;

const DepositRow = styled.div`
  display: flex;

  & > * + * {
    margin-left: 5px;
  }
`;

interface ClientDepositFormViewProps {
  deposit: ClientDepositForm
  onRemove: (deposit: ClientDepositForm) => void;
}

const ClientDepositFormView: FC<ClientDepositFormViewProps> = ({
  deposit,
  onRemove
}) => {
  const handleRemove = () => {
    onRemove(deposit);
  };

  return (
    <Wrapper>
      <RemoveButtonContainer>
        <Button
          icon="minus"
          minimal={true}
          small={true}
          intent={Intent.DANGER}
          onClick={handleRemove}
        />
      </RemoveButtonContainer>
      <DepositRow>
        <DateInput
          {...getMomentFormatter("YYYY/MM/DD HH:mm")}
          locale="ko"
          closeOnSelection={false}
          defaultValue={ new Date() }
          value={deposit.date ? moment(`${deposit.date}`).toDate() : new Date() }
          onChange={(selectedDate: Date) => {
            deposit.setDate(moment(selectedDate).format("YYYY-MM-DD HH:mm"))
          }
          }
          maxDate={new Date("2050-01-01 00:00")}
          timePickerProps={ { showArrowButtons: true, precision: undefined } }
        />

        <InputWithFormat
          placeholder="입금액"
          value={deposit.payout}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            deposit.setPayout(parseInt(e.target.rawValue, 10))
          }
          options={{ numeral: true }}
        />
      </DepositRow>
    </Wrapper>
  );
};

export default observer(ClientDepositFormView);
