import React from "react";

import { SprintWrapper as Wrapper } from './_wrapper';

export interface ISprintProps {
  title: string;
}

export const Sprint: React.FC<ISprintProps> = (props) => {
  return (
    <Wrapper>
      { props.title }
    </Wrapper>
  )
}
