import React, { ChangeEvent, Component } from "react";
import { Button, FormGroup, H4, Intent, TextArea } from "@blueprintjs/core";
import sortBy from "lodash/sortBy";
import moment from "moment";
import styled from "styled-components/macro";
import { ContractComment } from "../../../store/models/ContractRequest";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { GenericComment } from "../../../store/models/Rufree";
import { ConvertLinkTextFormat } from "../../atoms/ConvertLinkTextFormat/ConvertLinkTextFormat";


const Wrapper = styled.div`
  width: 100%;
`;
const Comment = styled.div`
  margin-bottom: 20px;
`;
const CommentDate = styled.div`
  font-weight: bold;
`;
const CommentContent = styled.div`
  white-space: pre-wrap
`;
const ButtonsRow = styled.div`
  margin-bottom: 50px;
  float: right;
  & > * + * {
    margin-left: 5px;
  }
`;
const EditText = styled.span`
  font-size: 12px;
  margin-right: 5px;
  color: #5C7080;
  float: right;
`;

interface CommentsProps {
  comments: GenericComment[];
  onAddComment: (comment: string) => void;
  onDeleteComment: (id: number) => void;
  onUpdateComment: (id: number, comment: string) => void;
}

@observer
class CommonComment extends Component<CommentsProps> {
  @observable edit_mode = false;
  @observable id = 0;
  @observable comment = "";

  handleCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.comment = e.currentTarget.value;
  };

  handleSaveClick = () => {
    if (this.edit_mode) {
      this.props.onUpdateComment(this.id, this.comment);
    } else {
      this.props.onAddComment(this.comment);
    }

    this.edit_mode = false;
    this.id = 0;
    this.comment = "";
  };

  render() {
    const { comments, onDeleteComment } = this.props;
    return (
      <Wrapper>
        <H4>코멘트</H4>

        {
          sortBy(comments.slice(), comment => comment.id)
            .map(comment => (
              <Comment key={`comment_${comment.id}`}>
                <CommentDate>
                  {moment(comment.submit_date).format("YYYY-MM-DD")} {comment.user_name}
                </CommentDate>

                <EditText onClick={
                  onDeleteComment.bind(this, comment.id)
                }>
                  삭제
                </EditText>

                <EditText onClick={
                  () => {
                    this.edit_mode = true;
                    this.id = comment.id;
                    this.comment = comment.comment;
                  }
                }>
                  수정
                </EditText>

                <CommentContent>
                  <ConvertLinkTextFormat>{comment.comment}</ConvertLinkTextFormat>
                </CommentContent>
              </Comment>
            ))
        }
        <FormGroup>
          <TextArea
            className={"bp3-fill"}
            value={this.comment}
            onChange={this.handleCommentChange}
          />
        </FormGroup>

        <ButtonsRow>
          <Button
            text="저장"
            intent={Intent.PRIMARY}
            onClick={this.handleSaveClick}
            disabled={this.comment ? false : true}
          />
        </ButtonsRow>
      </Wrapper>
    );
  }
}

export default CommonComment;