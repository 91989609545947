import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import ContentContainer from "../../atoms/ContentContainer/ContentContainer";
import ClientRow from "../../molecules/ClientRow/ClientRow";
import { HTMLTable, Button, Intent, Spinner } from "@blueprintjs/core";
import { computed } from "mobx";
import { RouteComponentProps } from "react-router";

const Wrapper = styled.div`
  th:nth-child(1) {
    width: 120px;
  }
  th:nth-child(2) {
    width: calc(100% - 240px);
  }
  th:nth-child(3) {
    width: 120px;
  }
  td:nth-child(3) {
    text-align: right;
  }

  .bp3-spinner {
    display: inline-block;
  }
`;

const Padding = styled.div`
  height: 20px;
`;

interface ClientsPageProps extends RouteComponentProps {}

interface InjectedProps extends ClientsPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ClientsPage extends Component<ClientsPageProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get clientStore() {
    return this.injected.appStore.clientStore;
  }

  @computed
  get calculatesByClient() {
    return this.injected.appStore.calculateStore.calculateByClient;
  }

  componentDidMount() {
    try {
      this.clientStore.fetchClients();
      this.injected.appStore.clientUserStore.fetchClientUsers();
    } catch (e) {}
  }

  createNewClient = async () => {
    this.props.history.push("/clients/new");
  };

  render() {
    const { sortedClients } = this.injected.appStore.clientStore;

    return (
      <Wrapper>
        <ContentContainer>
          <Button
            text="새 클라이언트"
            icon="add"
            intent={Intent.PRIMARY}
            onClick={this.createNewClient}
          />
          <Padding />
          <HTMLTable
            bordered={true}
            striped={true}
            interactive={true}
            style={{ width: "100%" }}
          >
            <thead>
              <tr>
                <th>클라이언트 번호</th>
                <th>클라이언트명</th>
                <th>
                  {
                    this.calculatesByClient.length === 0 &&
                      <Spinner
                        intent={Intent.PRIMARY}
                        size={Spinner.SIZE_SMALL} />
                  }
                  충전금
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedClients.map(client => (
                <ClientRow key={client.clientId} client={client} />
              ))}
            </tbody>
          </HTMLTable>
        </ContentContainer>
      </Wrapper>
    );
  }
}

export default ClientsPage;
