import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";

import { 
    Wrapper,
    StyledCard,
    CardContent,
    Paragraph
} from "../../atoms/ParttimeComponents/ParttimeComponents";
import { ConvertLinkTextFormat }  from "../../atoms/ConvertLinkTextFormat/ConvertLinkTextFormat";
import { Task } from "../../../store/models/Parttime";

interface AdminPanelProps extends RouteComponentProps {
  task: Task;
}

interface InjectedProps extends AdminPanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class AdminPanel extends Component<AdminPanelProps> {

  get injected() {
    return this.props as InjectedProps;
  }

  render() {
    const { task } = this.props;

    return (
        <Wrapper>
            <StyledCard>
            <CardContent>
              <Paragraph>
                <ConvertLinkTextFormat>{ task.memo ? task.memo : '-'}</ConvertLinkTextFormat>
              </Paragraph>
            </CardContent>
            </StyledCard>
        </Wrapper>
    );
  }
}

export default AdminPanel;
