import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { computed, observable } from "mobx";
import ContractComments from "../../organisms/ContractComments/ContractComments";
import { ApprovedClientContract } from "../../../store/models/ApprovedContract";
import { 
  Button, H3, H4, Spinner, HTMLTable, Intent
} from "@blueprintjs/core";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

const BodySection = styled.div`
  padding: 10px;
  box-sizing: border-box;
`;

const Wrapper = styled.div`
  width: 793.701px;
  padding: 35px;
`;


interface ApprovedContractPrintPageProps {
  id: string;
  type: string;
}

interface InjectedProps extends ApprovedContractPrintPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ApprovedContractPrintPage extends Component<ApprovedContractPrintPageProps> {
  @observable contract: any | null = null;
  @observable comment = '';

  get injected() {
    return this.props as InjectedProps;
  }

  get contractStore() {
    return this.injected.appStore.contractStore;
  }

  async componentDidMount() {
    const { id, type } = this.props;
    if (id) {
      if (type === 'client') {
        this.contract = await this.injected.appStore.contractStore.fetchApprovedClientContractById(id);
      } else {
        this.contract = await this.injected.appStore.contractStore.fetchApprovedRufreeContractById(id);
      }
    }
    
  }

  render() {
    const { contract } = this;

    if (!contract) {
      return <Spinner />;
    }

    return (
      <Wrapper>
        { contract.editorContent && (
          <BodySection>
            <FroalaEditorView model={contract.editorContent}/>
          </BodySection>
        )}
      </Wrapper>
    );
  }
}

export default ApprovedContractPrintPage;
