import React, { useEffect, useState } from 'react';
import { inject, observer } from "mobx-react";
import Paginator from "../../../molecules/Paginator/Paginator";

import { AppStore } from '../../../../store/AppStore';
import { IMemberStore } from '../../../../store/MemberStore';
import {
  FilterBar,
  MemberList
} from '.';


const SecondteamMember = inject("appStore")(observer((props) => {
  const store: IMemberStore = (props as { appStore: AppStore }).appStore.memberStore;
  const [ search, setSearch ] = useState('');

  const clearFilter = async() => {
    setSearch('');
    await store.fetchMembers();
  };

  useEffect(() => {
    const fetch = async () => {
      await store.fetchMembers();
    }

    fetch();
  }, []);

  return (
    <div style={{ maxWidth: '1200px' }}>
      <h3>세컨팀 멤버</h3>

      <FilterBar
        search={ search }
        onChangeSearch={ (search: string) => setSearch(search) }
        onClear={ clearFilter }
        onSearch={ () => store.fetchMembers(search) } />
      
      <div>
        <MemberList
          isFetching={ false }
          members={ store.members }
           />
        <Paginator
          pageGroup={ store.pageGroup }
          totalPages={ store.totalPages }
          currentPage={ store.currentPage}
          setPageGroup={ store.setPageGroup }
          setCurentPage={ store.setCurentPage }
          onFetch={() => store.fetchMembers(search) } />
      </div>
    </div>
  )
}));
export default SecondteamMember;
