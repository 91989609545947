export const getValueFromSearch = (
  key: string,
  search: string
) => {
  return search.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    function(str, k, value) {
      return (k === key) ? value : '';
    }
  );
};
