import { types } from "mobx-state-tree";
import {
  RufreeScheduleFormModel,
  RufreeScheduleForm
} from "./RufreeScheduleForm";
import {
  ClientScheduleFormModel,
  ClientScheduleForm
} from "./ClientScheduleForm";
import { createDefaultSprints } from "./ScheduleSprintForm";
import moment from "moment";

export const ScheduleFormModel = types
  .model("ScheduleForm", {
    projectGroupId: types.optional(types.string, ""),
    clientSchedules: types.array(ClientScheduleFormModel),
    rufreeSchedules: types.array(RufreeScheduleFormModel),
    createdAt: types.optional(types.string, ""),
    updatedAt: types.optional(types.string, ""),
    dateStart: types.optional(types.string, ""),
    dateEnd: types.optional(types.string, "")
  })
  .views(self => ({
    get expectedStartDate(): string {
      let firstDay: string | null = null;

      self.clientSchedules.forEach(clientSchedule =>
        clientSchedule.sprints.forEach(sprint => {
          if (!firstDay && sprint.isValid) {
            firstDay = sprint.dateStart;
          }

          if (
            sprint.isValid &&
            firstDay &&
            moment(firstDay) > moment(sprint.dateStart)
          ) {
            firstDay = sprint.dateStart;
          }
        })
      );

      self.rufreeSchedules.forEach(rufreeSchedule =>
        rufreeSchedule.sprints.forEach(sprint => {
          if (!firstDay && sprint.isValid) {
            firstDay = sprint.dateStart;
          }

          if (
            sprint.isValid &&
            firstDay &&
            moment(firstDay) > moment(sprint.dateStart)
          ) {
            firstDay = sprint.dateStart;
          }
        })
      );

      if (!firstDay) {
        firstDay = moment().format("YYYY-MM-DD");
      }

      return firstDay;
    },
    get expectedEndDate(): string {
      let lastDay = "2000-01-01";

      self.clientSchedules.forEach(clientSchedule =>
        clientSchedule.sprints.forEach(sprint => {
          if (sprint.isValid && moment(lastDay) < moment(sprint.dateEnd)) {
            lastDay = sprint.dateEnd;
          }
        })
      );

      self.rufreeSchedules.forEach(rufreeSchedule =>
        rufreeSchedule.sprints.forEach(sprint => {
          if (sprint.isValid && moment(lastDay) < moment(sprint.dateEnd)) {
            lastDay = sprint.dateEnd;
          }
        })
      );

      return lastDay;
    }
  }))
  .actions(self => ({
    addClientSchedule() {
      self.clientSchedules.push(
        ClientScheduleFormModel.create({
          sprints: createDefaultSprints()
        })
      );
    },
    addRufreeSchedule() {
      self.rufreeSchedules.push(
        RufreeScheduleFormModel.create({
          sprints: createDefaultSprints()
        })
      );
    },

    removeClientSchedule(clientSchedule: ClientScheduleForm) {
      self.clientSchedules.remove(clientSchedule);
    },
    removeRufreeSchedule(rufreeSchedule: RufreeScheduleForm) {
      self.rufreeSchedules.remove(rufreeSchedule);
    }
  }));

type ScheduleFormType = typeof ScheduleFormModel.Type;
export interface ScheduleForm extends ScheduleFormType {}
