import { types, flow } from "mobx-state-tree";
import { RufreeMatchingCandidateModel } from "./models/RufreeMatchingCandidate";
import {
	MatchingCandidateResponse, 
	MatchingCandidateQueryResponse,
	candidatesProjectExperiences,
	candidatesProjectGroups,
	candidatesCurrentMatchings
} from "../types/rufreeMatchingCandidate";
import axios from "axios";
import sortBy from "lodash/sortBy";
import {CommentModel} from "./models/Rufree";

const mapResponseToCandidateQuery = (data: MatchingCandidateQueryResponse) => ({
	id: data.id,
	uuid: data.uuid,
	rufreeId: data.rufree_id,
	name: data.name,
	email: data.email,
	phone: data.phone,
	address: data.address,
	affiliation: data.affiliation,
	workType: data.work_type,
	// roles: data.roles,
	// rolesEtc: data.roles_etc,
	portfolio: {
		type: data.portfolio ? data.portfolio.type : "",
		file: data.portfolio ? data.portfolio.file : "",
		link: data.portfolio ? data.portfolio.link : ""
	},
	// portfolioLinks: data.portfolio_links,
	// expert: data.expert,
	mainSkills: data.main_skills,
	subSkills: data.sub_skills,
	// oldMainSkills: data.old_main_skills,
	// oldSubSkills: data.old_sub_skills,
	languages: data.languages,
	tools: data.tools,
	toolAccounts: data.tool_accounts,
	tags: data.tags,
	references: data.references,
	interview: data.interview,
	workingStyle: data.working_style,
	workingTime: data.working_time,
	recommender: data.recommender,
	pmComment: data.pm_comment,
	experiences: data.experiences.map(
		mapProjectExperiences
	) as any,
	pgHistories: data.projectgroup_history.map(
		mapProjectGroups
	) as any,
	currentMatchings: data.current_matching.map(
		mapCurrentMatchings
	) as any,
	matchingHistory: data.matching_history.map(k => ({
		pgId: k.project_group_id,
		pgName: k.project_group_name,
		role: k.role,
		phase: k.phase,
		dateSend: k.date_send,
		dateViewed: k.date_viewed,
		dateAccepted: k.date_accepted,
		dateRejected: k.date_rejected,
		dateExpired: k.date_expired,
		dateMatched: k.date_matched,
		dateDeclined: k.date_declined,
		dateCanceled: k.date_canceled,
		rejectReason: k.reject_reason,
		comments: k.comments.map(c => ({
			id: c.id,
			creator: c.creator,
			isRufree: c.is_rufree,
			comment: c.comment,
			createdAt: c.created_at,
			updatedAt: c.updated_at
		}))
	})),
	getOffer: data.get_offer,
	getOfferChangedAt: data.get_offer_changed_at,
	comments: data.comments.map(c => CommentModel.create(c)),
	followee: data.followee.map(k => ({
		rufreeId: k.rufree_id,
		name: k.name,
		uuid: k.uuid
	})),
	createdAt: data.created_at
})

const mapProjectExperiences = (data: candidatesProjectExperiences) => ({
	title: data.title, 
	workingTime: data.working_time, 
	detail: data.detail,
	startAt: data.start_at,
	endAt: data.end_at,
	role: data.role_name,
	tools: data.tools,
	languages: data.languages,
	outcomeAos: data.outcome_aos,
	outcomeIos: data.outcome_ios,
	outcomeWeb: data.outcome_web
})

const mapProjectGroups = (data: candidatesProjectGroups) => ({
	pgId: data.projectgroup_id,
	pgName: data.name,
	role: data.role, 
	dateComplete: data.date_complete
})

const mapCurrentMatchings = (data: candidatesCurrentMatchings) => ({
	pgId: data.pg_id, 
	pgName: data.pg_name, 
	isAccepted: data.is_accepted
})

// const mapResponseToMatchingCandidate = (data: MatchingCandidateResponse) => ({
// 	max_score: data.max_score,
// 	hits: data.hits.map(
//     	mapResponseToCandidateQuery
//   ) as any,
// });

export const RufreeMatchingCandidateStoreModel = types
  .model("RufreeMatchingCandidateStore", {
    rufreeMatchingCandidates: types.array(RufreeMatchingCandidateModel),

    currentPage: types.optional(types.number, 1),
    pageGroup: types.optional(types.number, 1),
    pageSize: types.optional(types.number, 10),
    totalPages: types.optional(types.number, 1)
  })
  .views(self => ({
    get sortedMatchingCandidates() {
      return sortBy(self.rufreeMatchingCandidates.slice(), item => item);
    }
  }))
  .actions(self => ({
    setCurrentPage(value: number) {
      self.currentPage = value;
    },
    setPageGroup(value: number) {
      self.pageGroup = value;
    }
  }))
  .actions(self => {
		const fetchRufree = flow(function*(name_or_id: string, job: string, skill: string, work_type: string, searchText: string, order_by: string) {
			try {
				const { data }: { data: MatchingCandidateResponse } = yield axios.get(
					"/rufrees/search",
					{
						params: {
							name_or_id: name_or_id,
							job: job,
							role: skill,
							skill: searchText,
							recommender: '',
							work_type: work_type === "전체" ? undefined : work_type,
							order_by: order_by,
							page: self.currentPage,
							page_size: self.pageSize
						}
					}
				);

				const rufreeMatchingCandidates = data.results.map(x => RufreeMatchingCandidateModel.create(mapResponseToCandidateQuery(x)) );
				self.totalPages = data.total_pages;
				self.rufreeMatchingCandidates.replace(rufreeMatchingCandidates)	
			} catch (e) {
				console.log("error---->>>", e)
				throw e;
			}
		});
		// const fetchRecommendedRufree = flow(function*(query_string: string, work_type: string, order_by: string) {
		// 	try {
		// 		const { data }: { data: MatchingCandidateResponse } = yield axios.post(
		// 			`/rufrees/recommend`,
		// 			{
		// 				query_string: query_string,
		// 				work_type: work_type,
		// 				size: self.pageSize
		// 			},
		// 			{
		// 				params: {
		// 					order_by: order_by,
		// 					page: self.currentPage,
		// 					page_size: self.pageSize
		// 				}
		// 			}
		// 		);
		// 		const rufreeMatchingCandidates = data.hits.map(x => RufreeMatchingCandidateModel.create(mapResponseToCandidateQuery(x)) );
		// 		self.totalPages = data.total_pages;
		// 		self.rufreeMatchingCandidates.replace(rufreeMatchingCandidates)
		// 	} catch (e) {
		// 		console.log("error---->>>", e)
		// 		throw e;
		// 	}
		// });
	
		return {
			fetchRufree,
			// fetchRecommendedRufree,
    };
  });

type RufreeMatchingStoreType = typeof RufreeMatchingCandidateStoreModel.Type;
export interface RufreeMatchingCandidateStore extends RufreeMatchingStoreType {}
