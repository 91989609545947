import React, { Component, ChangeEvent } from "react";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { Dialog, Classes, FormGroup, InputGroup } from "@blueprintjs/core";
import { PrimaryButton, SecondaryButton } from "../../atoms/ParttimeComponents/ParttimeComponents";

import { computed } from "mobx";

import { Request, ReferenceLink } from "../../../store/models/Parttime";

interface ReferenceLinkFormDialogProps {
    request: Request;
    referenceLink: ReferenceLink | null;
    isOpen: boolean;
    onClose: () => void;
}

interface InjectedProps extends ReferenceLinkFormDialogProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ReferenceLinkFormDialog extends Component<ReferenceLinkFormDialogProps> {
    get injected() {
        return this.props as InjectedProps;
    }

    @computed
    get formStore() {
        return this.injected.appStore.ReferenceLinkFormStore
    }

    @computed
    get currentForm() {
        return this.formStore.currentForm;
    }
   
    handleOpening = () => {};

    handleSubmit = async () => {
        const { request, referenceLink, onClose } = this.props;

        try {
            if(referenceLink){
                this.currentForm.setId(referenceLink.id);
                await this.formStore.edit(request.id);
                onClose();
                await this.injected.appStore.parttimeRequestStore.fetchRequestById(request.id)
            }
        } catch (e) {
            const error = JSON.stringify(e.response.data);
        }
      };

    render() {
        const { isOpen, onClose, referenceLink } = this.props;
        const { currentForm } = this;

        return (
            <Dialog
                onOpening={this.handleOpening}
                isOpen={isOpen}
                onClose={onClose}
                usePortal={true}
                autoFocus={true}
                enforceFocus={true}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                title={(referenceLink && referenceLink.description) ? <>설명 추가</> : <>설명 수정</>}
            >
            <div className={Classes.DIALOG_BODY}>
                <FormGroup>
                    <InputGroup
                        placeholder="설명을 입력해주세요. (최대 15자)"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            currentForm.setDescription(e.target.value);
                        }}
                        style={{ width: "100%" }}
                        maxLength={15}
                    />
                </FormGroup>
            </div>
    
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <SecondaryButton text="취소" onClick={onClose}/>
                <PrimaryButton text={"저장"} onClick={this.handleSubmit}/>
              </div>
            </div>
          </Dialog>
        );
    }
}

export default ReferenceLinkFormDialog;
