import axios from "axios";
import { types, flow } from "mobx-state-tree";
import { ContractCommentModel } from "../../store/models/ContractRequest";
import { CommentResponse } from "../../types/contract";

// export const ApprovedContractCommentModel = types
//   .model("ApprovedContractComment", {
//     id: types.number,
//     author: types.number,
//     authorName: types.string,
//     createdAt: types.string,
//     updatedAt: types.string,
//     content: types.string
//   })
// type ApprovedContractCommentType = typeof ApprovedContractCommentModel.Type;
// export interface ApprovedContractComment extends ApprovedContractCommentType {}


export const ApprovedClientContractModel = types
  .model("ApprovedClientContract", {
    contractorId: types.maybeNull(types.string),
    status: types.string,
    contractorName: types.string,
    approvedContractId: types.identifier,
    clientContract: types.string,
    template: types.string,
    templateName: types.string,
    commentOpened: types.boolean,
    contractComments: types.array(ContractCommentModel),
    contractName: types.string,
    createdAt: types.string,
    updatedAt: types.string,
    editorContent: types.string,
    managerName: types.string,
    managerId: types.number
  })
  .views(self => ({
    get nameWithId() {
      return `${self.approvedContractId} - ${self.contractName}`;
    }
  }))
  .actions(self => {
    const fetchComments = flow(function*() {
      try {
        const { data }: { data: CommentResponse[] } = yield axios.get(
          `/approvedClientContract/${self.approvedContractId}/comments`);

        const comments = data.map(x => ContractCommentModel.create({
          id: x.id,
          author: x.author || -1,
          authorName: x.author_name || '',
          createdAt: x.created_at,
          updatedAt: x.updated_at,
          content: x.content
        }));

        self.contractComments.replace(comments.reverse());
      } catch (e) {
        throw e;
      }
    });

    const addComment = flow(function*(comment: string) {
      try {
        yield axios.post(
          `/approvedClientContract/${self.approvedContractId}/addComments`,
          {
            content: comment
          }
        );
      } catch (e) {
        throw e;
      }
    });

    const updateComment = flow(function*(id: number, comment: string) {
      try {
        yield axios.patch(
          `/approvedClientContract/${self.approvedContractId}/updateComments`,
          {
            id: id,
            content: comment
          }
        );
      } catch (e) {
        throw e;
      }
    });

    const deleteComment = flow(function*(id: number) {
      try {
        yield axios.delete(
          `/approvedClientContract/${self.approvedContractId}/deleteComments`,
          {
            data: {id: id}
          }
        );
      } catch (e) {
        throw e;
      }
    });

    return {
      fetchComments,
      addComment,
      updateComment,
      deleteComment
    };
  });
type ApprovedClientContractType = typeof ApprovedClientContractModel.Type;
export interface ApprovedClientContract extends ApprovedClientContractType {}

export const ApprovedRufreeContractModel = types
  .model("ApprovedRufreeContract", {
    contractorId: types.maybeNull(types.string),
    status: types.string,
    contractorName: types.string,
    approvedContractId: types.identifier,
    rufreeContract: types.string,
    template: types.string,
    templateName: types.string,
    commentOpened: types.boolean,
    contractComments: types.array(ContractCommentModel),
    contractName: types.string,
    createdAt: types.string,
    updatedAt: types.string,
    editorContent: types.string,
    managerName: types.string,
    managerId: types.number
  })
  .actions(self => {
    const fetchComments = flow(function*() {
      try {
        const { data }: { data: CommentResponse[] } = yield axios.get(
          `/approvedRufreeContract/${self.approvedContractId}/comments`);

        const comments = data.map(x => ContractCommentModel.create({
          id: x.id,
          author: x.author || -1,
          authorName: x.author_name || '',
          createdAt: x.created_at,
          updatedAt: x.updated_at,
          content: x.content
        }));

        self.contractComments.replace(comments.reverse());
      } catch (e) {
        throw e;
      }
    });

    const addComment = flow(function*(comment: string) {
      try {
        yield axios.post(
          `/approvedRufreeContract/${self.approvedContractId}/addComments`,
          {
            content: comment
          }
        );
      } catch (e) {
        throw e;
      }
    });

    const updateComment = flow(function*(id: number, comment: string) {
      try {
        yield axios.patch(
          `/approvedRufreeContract/${self.approvedContractId}/updateComments`,
          {
            id: id,
            content: comment
          }
        );
      } catch (e) {
        throw e;
      }
    });

    const deleteComment = flow(function*(id: number) {
      try {
        yield axios.delete(
          `/approvedRufreeContract/${self.approvedContractId}/deleteComments`,
          {
            data: {id: id}
          }
        );
      } catch (e) {
        throw e;
      }
    });

    return {
      fetchComments,
      addComment,
      updateComment,
      deleteComment
    };
  });
type ApprovedRufreeContractType = typeof ApprovedRufreeContractModel.Type;
export interface ApprovedRufreeContract extends ApprovedRufreeContractType {}