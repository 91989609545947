import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { observable } from "mobx";
import {
  InputGroup, Spinner, Text
} from "@blueprintjs/core";
import moment from "moment";
import {
  EtcItemWrapper, EtcSection, EtcSectionBody, EtcSectionTitle, H6Wrapper
} from "../../atoms/DashboardComponents/DashboardComponents";

import { RouteComponentProps } from "react-router";

const Wrapper = styled.div`
  margin-bottom: 10px;
  & > * + * {
    margin-top: 10px;
  }
`;
const SectionWrapper = styled.div`
  min-height: 200px;
  display: flex;
  & > * + * {
    margin-left: 10px;
  }
  margin-bottom: 10px;
`;

const EtcItem = styled.div`
  display: inline-block;
  width: 15%;
  color: #000;
  cursor: default;
`;

const ItemTitle = styled.div`
  display: inline-block;
  width: 40%;
  color: #5642A6;
`;


interface RufreeDashboardPanelProps extends RouteComponentProps {
  handleRowClick: ({ matchingId }: { matchingId: number }) => void
}

interface InjectedProps extends RufreeDashboardPanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class RufreeDashboardPanel extends Component<RufreeDashboardPanelProps> {
  @observable readyToShow = false;
  @observable daysbefore: number = 7;
  @observable baseDate: moment.Moment = moment().subtract(this.daysbefore, "days");

  get injected() {
    return this.props as InjectedProps;
  }

  async componentDidMount() {
    try {
      await this.injected.appStore.rufreeMatchingStore.fetchAllRufreeMatchings();
    } catch (e) {
    }finally {
      this.readyToShow = true;
    }
  }

  calDuration = (matching: any) => {
    const startTime = moment(matching.dateRequest, "YYYY-MM-DD");
    let endTime;
    let hours = "";
    if (!matching.matchedTime && matching.matchedRufree) { // 매칭이 되었어지만 매칭시간이 없는 경우(기존)
      return "";
    } else if (matching.matchedTime && matching.matchedRufree) {
      endTime = moment(matching.matchedTime, "YYYY-MM-DD");
    } else {
      endTime = moment(new Date());
    }
    const duration = moment.duration(endTime.diff(startTime));
    Number(duration.asHours().toFixed(2)) > 24 ? hours = `${(duration.asHours() / 24).toFixed()}일` : hours = `< 1일`;
    if (matching.status == "취소") {
      hours = "-";
    }
    return hours;
  };

  makeItem = (matchings: any, type: string) => {
    return matchings.map((item: any, idx: string) => {
      return (
        <div key={idx}>
          <EtcItemWrapper onClick={() => {
            this.props.handleRowClick({
              matchingId: item.id
            });
          }}>
            <EtcItem>{item.manager}</EtcItem>
            <EtcItem>프로젝트그룹</EtcItem>
            <EtcItem>{item.projectGroup}</EtcItem>
            <ItemTitle>{item.projectGroupName}</ItemTitle>
            <EtcItem>{item.role}</EtcItem>
            <EtcItem>{this.calDuration(item)}</EtcItem>
            <EtcItem>{item.status !== "매칭완료" ? item.dateDue.substring(2) : ""}</EtcItem>
          </EtcItemWrapper>
        </div>
      );
    });
  };

  creatItems = (matchings: any, type: string) => {
    let convertedMatchings = [];

    matchings.map((matching: any) => {
      let rufreeAccept = false;
      const matchingHistory = Object.assign({}, matching.matchingHistory);
      Object.keys(matchingHistory).map((key) => {
        if (matchingHistory[key].isAccepted && !rufreeAccept) { // 확정대기중 사용하기 위함(한명이라도 수락했을 때)
          rufreeAccept = true;
        }
      });
      matching.rufreeAccept = rufreeAccept;
    });

    switch (type) {
      case "제안중":
        convertedMatchings = matchings.filter((item: { status: string, rufreeAccept: boolean }) => item.status == "제안중" && !item.rufreeAccept);
        break;
      case "제안전":
        convertedMatchings = matchings.filter((item: { status: string; }) => item.status == "제안대기" || item.status == "작성중");
        break;
      case "확정대기중":
        convertedMatchings = matchings.filter((item: { status: string, rufreeAccept: boolean }) => item.status == "제안중" && item.rufreeAccept);
        break;
      case "킥오프대기중":
        convertedMatchings = matchings.filter((item: { pGStartOrNot: boolean, status: string }) => !item.pGStartOrNot && item.status == "매칭완료");
        break;
      default:
        break;
    }
    return this.makeItem(convertedMatchings, type);
  };

  render() {
    const { allRufreeMatchings } = this.injected.appStore.rufreeMatchingStore;

    if (!this.readyToShow) {
      return <Spinner />;
    }

    return (
      <Wrapper>
        <SectionWrapper>
          <EtcSection>
            <EtcSectionTitle>
              <H6Wrapper>제안전</H6Wrapper>
            </EtcSectionTitle>
            <EtcSectionBody>
              {this.creatItems(allRufreeMatchings, "제안전")}
            </EtcSectionBody>
          </EtcSection>
          <EtcSection>
            <EtcSectionTitle>
              <H6Wrapper>제안중</H6Wrapper>
            </EtcSectionTitle>
            <EtcSectionBody>
              {this.creatItems(allRufreeMatchings, "제안중")}
            </EtcSectionBody>
          </EtcSection>
        </SectionWrapper>
        <SectionWrapper>
          <EtcSection>
            <EtcSectionTitle>
              <H6Wrapper>확정대기중</H6Wrapper>
            </EtcSectionTitle>
            <EtcSectionBody>
              {this.creatItems(allRufreeMatchings, "확정대기중")}
            </EtcSectionBody>
          </EtcSection><EtcSection>
          <EtcSectionTitle>
            <H6Wrapper>킥오프대기중</H6Wrapper>
          </EtcSectionTitle>
          <EtcSectionBody>
            {this.creatItems(allRufreeMatchings, "킥오프대기중")}
          </EtcSectionBody>
        </EtcSection>
        </SectionWrapper>
      </Wrapper>
    );
  }
}

export default RufreeDashboardPanel;