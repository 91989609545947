import { types } from "mobx-state-tree";
import {
  ScheduleSprintFormModel,
  ScheduleSprintForm,
  updateSprintNeighborDates
} from "./ScheduleSprintForm";

export const ClientScheduleFormModel = types
  .model("ClientScheduleForm", {
    name: types.optional(types.string, ""),
    inspectionId: types.optional(types.string, ""),
    role: types.optional(types.string, ""),
    sprints: types.array(ScheduleSprintFormModel),
    numInspectionSprints: types.optional(types.number, 0),
    numScheduleSprints: types.optional(types.number, 0)
  })
  .views(self => ({
    get totalSprintDays() {
      return self.sprints.filter(sprint => sprint.isValid).length;
    }
  }))
  .actions(self => ({
    setName(value: string) {
      self.name = value;
    },
    setInspectionId(value: string) {
      self.inspectionId = value;
    },
    setRole(value: string) {
      self.role = value;
    },
    setNumInspectionSprints(value: number) {
      self.numInspectionSprints = value;
    },
    setNumScheduleSprints(value: number) {
      self.numScheduleSprints = value;
    },

    addSprint() {
      self.sprints.push(ScheduleSprintFormModel.create());
    },

    updateSprintNeighborDates(sprint: ScheduleSprintForm) {
      updateSprintNeighborDates(self.sprints, sprint);
    }
  }));

type ClientScheduleFormType = typeof ClientScheduleFormModel.Type;
export interface ClientScheduleForm extends ClientScheduleFormType {}
