import React, { Component } from "react";
import styled from "styled-components/macro";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { Button, Intent, Icon } from "@blueprintjs/core";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import { Sprint } from "../../../store/models/Sprint";
import SprintConfirmPopup from "../SprintReportReviewPopup/SprintConfirmPopup";

const TR = styled.tr`
`;
const TD = styled.td`
  color: #161d2e;
`;

const SprintSpan = styled.span`
  font-weight: bold;
  padding-right: 15px;
`

const RightAlignTD = styled(TD)`
  text-align: right;
`

const CenterAlignTD = styled(TD)`
  text-align: center;
`

const HighlightStatus = styled.span`color: red;font-weight: bold;`
const DisableStatus = styled.span`color: #7f8390;`
const ConfirmStatus = styled.span`color: #137cbd;font-weight: bold;`
const PayedStatus = styled.span`color: #0F9960;font-weight: bold;`

interface SprintBoardRowProps {
  projectGroupName: string;
  sequential_id: number;
  sprint: Sprint;
  onPopupClose: () => {};
}

interface InjectedProps extends SprintBoardRowProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class SprintBoardRow extends Component<SprintBoardRowProps> {
  @observable isConfirmOpen = false;

  get injected() {
    return this.props as InjectedProps;
  }

  handleConfirmOpen = () => {
    this.isConfirmOpen = true;
  };

  handleConfirmClose = () => {
    this.isConfirmOpen = false;
    this.props.onPopupClose();
  };

  handleCancleConfirm = async () => {
    const { sprint, sequential_id } = this.props;
    try {
      await sprint.cancleConfirm();
      this.props.onPopupClose();

      AppToaster.show({
        message: `${sprint.rufreeName}(${sprint.role}) ${sprint.sprint ? "S" + sequential_id : "PRE"} 스프린트 종료처리가 취소 되었습니다.`,
        intent: Intent.SUCCESS
      });
    } catch (e) {
      const error = JSON.stringify(e.response.data);

      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  render_status(status: string) {
    if(status === '종료') {
      return <ConfirmStatus>{status}</ConfirmStatus>;
    }
    if(status === '종료필요') {
      return <HighlightStatus>{status}</HighlightStatus>;
    }
    if(status === '진행예정') {
      return <DisableStatus>{status}</DisableStatus>;
    }
    return <span>{status}</span>;
  }

  render_button(sprint: Sprint) {
    if(!sprint.manager) {
      return (
        <span className="bp3-text-small bp3-text-disabled">
          <Icon icon={'error'} iconSize={11} /> PM지정필요
        </span>
      )
    }
    if(!sprint.dateCalculate && sprint.dateEnd) {
      return (
        <Button text="취소" className="bp3-small" onClick={this.handleCancleConfirm} />
      )
    }
    if(sprint.dateCalculate) {
      return <PayedStatus>지급완료</PayedStatus>
    }

    return <Button text="종료" className="bp3-small" intent={Intent.PRIMARY}
                  onClick={this.handleConfirmOpen} />
  }

  render() {
    const { projectGroupName, sprint, sequential_id } = this.props;

    let payment, commission;
    payment = commission = 0;

    if(sprint.calculatePayment === 0 && sprint.calculateCommission === 0) {
      payment = sprint.payment < 0 ? 0 : sprint.payment;
      commission = sprint.payment < 0 ? sprint.payment + sprint.commission : sprint.commission;
    } else {
      payment = sprint.calculatePayment;
      commission = sprint.calculateCommission;
    }

    return (
      <>
        <TR>
          <TD>
            <SprintSpan>S{sequential_id}</SprintSpan>
              {sprint.dateStart} ~ {sprint.expectedDateEnd}
          </TD>
          <RightAlignTD>{payment.toLocaleString("en-US")}</RightAlignTD>
          <RightAlignTD>{commission.toLocaleString("en-US")}</RightAlignTD>
          <RightAlignTD>{sprint.serviceFee.toLocaleString("en-US")}</RightAlignTD>
          <CenterAlignTD>{this.render_status(sprint.status)}</CenterAlignTD>
          <TD>{sprint.comment}</TD>
          <TD>
            {
              this.render_button(sprint)
            }
           </TD>
        </TR>

        <SprintConfirmPopup
          projectGroupName={projectGroupName}
          sprint={sprint}
          sprint_sequential_id={sequential_id}
          onClose={this.handleConfirmClose}
          isOpened={this.isConfirmOpen} />
      </>
    );
  }
}

export default SprintBoardRow;
