import React, { useEffect } from "react";
import styled from "styled-components/macro";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";

import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/line_height.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/js/plugins/print.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/image.min.js';
// import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/plugins/code_view.min.css';


import * as historyLibrary from "history";
import { RouterStore, syncHistoryWithStore } from "mobx-react-router";

import { Router } from "react-router";
import Routes from "./Routes";
import ScrollToTop from "./utils/ScrollToTop";

import { Provider } from "mobx-react";
import { AppStoreModel } from "./store/AppStore";
import { connectReduxDevtools } from "mst-middlewares";

import axios from "axios";
import CheckLogin from "./utils/CheckLogin";

let title = '시소 백오피스';

if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = "https://api-dev.cpms.seeso.kr";
  // axios.defaults.baseURL = "http://localhost:8000";
} else {
  axios.defaults.baseURL = "https://api.cpms.seeso.kr";
}
if (process.env.REACT_APP_BASEURL) {
  axios.defaults.baseURL = process.env.REACT_APP_BASEURL;
}

if(axios.defaults.baseURL.startsWith('https://api-dev.cpms.seeso.kr')) {
  title = title + ' 모델하우스'
} else if(axios.defaults.baseURL.startsWith('https://api.cpms.seeso.kr')) {
} else {
  title = title + ' 로컬'
}

// Global axios interceptor
// 토큰 만료시 로그인 페이지로 이동하기 위함
axios.interceptors.response.use(
  response => {
    // window.location.href = `/?next=${window.location.pathname}`;
    return response;
  },
  error => {

    if (window.location.pathname !== "/" && error.response.status === 401 ) {
      window.location.href = `/?next=${window.location.pathname}`;
    }

    if(error.response.data.detail && error.response.data.detail.includes("Token has expired.")){
      window.location.href = `/?next=${window.location.pathname}`;
    }

    return Promise.reject(error);
  }
);

const Wrapper = styled.div`
  min-height: 100vh;
`;

const browserHistory = historyLibrary.createBrowserHistory();
const routerStore = new RouterStore();
const history = syncHistoryWithStore(browserHistory, routerStore);

const rootStore = AppStoreModel.create({});
// connectReduxDevtools(require("remotedev"), rootStore); // socket 제거 일단 안 쓰는 것 같음

const App: React.FC = () => {
  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <Provider routerStore={routerStore} appStore={rootStore}>
      <Wrapper className="App">
        <Router history={history}>
          <ScrollToTop>
            <CheckLogin>
              <Routes />
            </CheckLogin>
          </ScrollToTop>
        </Router>
      </Wrapper>
    </Provider>
  );
};

export default App;
