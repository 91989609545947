import React, { Component } from "react";
import styled from "styled-components/macro";

import { computed } from "mobx";
import { inject, observer } from "mobx-react";

import { AppStore } from "../../../store/AppStore";


const DoneTaskLegend = styled.span`
  font-size: 0.8em;
  color: #5C7080;
`;
const OngoingTaskLegend = styled.span`
  font-size: 0.8em;
  color: #5C7080;
`;
const ScheduledTaskLegend = styled.span`
  font-size: 0.8em;
  color: #5C7080;
`;
const TaskStatusAggregationBar = styled.div`
  width: 100%;
  display: flex;
`;
const TaskStatusAggregationScheduledColumn = styled.div`
  background: lightgray;
`;
const TaskStatusAggregationOngoingColumn = styled.div`
  background: lightgray;
`;
const TaskStatusAggregationDoneColumn = styled.div`
  background: lightgray;
`;

interface TaskStatusAggregationViewProps {
}
interface InjectedProps extends TaskStatusAggregationViewProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class TaskStatusAggregationView extends Component<TaskStatusAggregationViewProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get store() {
    return this.injected.appStore.taskManagerStore;
  }

  @computed
  get board() {
    return this.store.currentBoard;
  }

  render() {
    if(!this.board) {
      return <div></div>;
    } else {
      let countScheduledTasks = 0;
      let countOngoingTasks = 0;
      let countDoneTasks = 0;

      this.board.taskLists.map((task_list) => {
        if(task_list.title === '작업예정' && !task_list.is_editable) { countScheduledTasks += task_list.tasks.filter(task => !task.is_archived).length; }
        else if(task_list.title === '최종완료' && !task_list.is_editable) { countDoneTasks += task_list.tasks.filter(task => !task.is_archived).length; }
        else { countOngoingTasks += task_list.tasks.filter(task => !task.is_archived).length; }
      })

      let countAll = countScheduledTasks + countOngoingTasks + countDoneTasks;
      let ratioScheduledTasks = countAll > 0 ? (countScheduledTasks / countAll) : 1;
      let ratioOngoingTasks = countAll > 0 ? (countOngoingTasks / countAll) : 0;
      let ratioDoneTasks = countAll > 0 ? (countDoneTasks / countAll) : 0;
      countAll = countAll > 0 ? countAll : 1;

      return (
        <div style={{width:'300px'}}>
          <div>
            전체 Task 진행 현황
            &nbsp;<ScheduledTaskLegend>예정 ({countScheduledTasks})</ScheduledTaskLegend>
            &nbsp;<OngoingTaskLegend>진행 ({countOngoingTasks})</OngoingTaskLegend>
            &nbsp;<DoneTaskLegend>완료 ({countDoneTasks})</DoneTaskLegend>
          </div>
          <TaskStatusAggregationBar>
            {ratioScheduledTasks > 0 && (
              <TaskStatusAggregationScheduledColumn style={{flex:ratioScheduledTasks}}>&nbsp;</TaskStatusAggregationScheduledColumn>
            )}
            &nbsp;
            {ratioOngoingTasks > 0 && (
              <TaskStatusAggregationOngoingColumn style={{flex:ratioOngoingTasks}}>&nbsp;</TaskStatusAggregationOngoingColumn>
            )}
            &nbsp;
            {ratioDoneTasks > 0 && (
              <TaskStatusAggregationDoneColumn style={{flex:ratioDoneTasks}}>&nbsp;</TaskStatusAggregationDoneColumn>
            )}
          </TaskStatusAggregationBar>        
        </div>
      );
    }
  }
}

export default TaskStatusAggregationView;