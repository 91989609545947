import React, { Component } from "react";
import styled from "styled-components/macro";
import { RouteComponentProps } from "react-router";
import { computed, observable } from "mobx";
import moment from "moment";
import { inject, observer } from "mobx-react";

import { AppStore } from "../../../store/AppStore";
import { FormMode } from "../../../types/formMode";

import { Request, RequestStatus, RequestType, RequestOS, TaskSummary, TaskStatus, TaskType } from "../../../store/models/Parttime";
import StatusTag from "../../molecules/ParttimeRow/StatusTag";
import RequestCancelDialog from "../../molecules/ParttimeDialog/RequestCancelDialog";
import RequestRestoreConfirmDialog from "../../molecules/ParttimeDialog/RequestRestoreConfirmDialog";
import ReferenceFileFormDialog from "../../molecules/ParttimeDialog/ReferenceFileFormDialog";
import ReferenceLinkFormDialog from "../../molecules/ParttimeDialog/ReferenceLinkFormDialog";
import TaskFormDialog from "../../molecules/ParttimeDialog/TaskFormDialog";
import RequestFormDialog from "../../molecules/ParttimeDialog/RequestFormDialog";

import {
    Icon,
    Intent,
    Button,
    Card,
    Alert
  } from "@blueprintjs/core";
import { ItemCard, Paragraph } from "../../atoms/ParttimeComponents/ParttimeComponents";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import { PageNavWrapper } from "../../atoms/PageNav/PageNav";
import LargeContainer from "../../atoms/LargeContainer/LargeContainer";
import { BreadCrumb } from "../../molecules/BreadCrumb/BreadCrumb";
import { ReferenceLink, ReferenceFile } from "../../../store/models/Parttime";

interface PageParams {
    id: string;
}
  
interface RequestDetailPageProps extends RouteComponentProps<PageParams> {}

interface InjectedProps extends RequestDetailPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class PartTimeRequestDetailPage extends Component<RequestDetailPageProps> {
  @observable isDialogOpen: boolean = false;
  @observable isRestoreDialogOpen: boolean = false;
  @observable isReferenceFileFormDialogOpen: boolean = false;
  @observable isReferenceLinkFormDialogOpen: boolean = false;
  @observable isRequestFormDialogOpen: boolean = false;
  @observable isTaskFormDialogOpen: boolean = false;
  @observable showReferenceFileDeleteAlert: boolean = false;
  @observable showReferenceFileDescriptionDeleteAlert: boolean = false;
  @observable currentReferenceFile: ReferenceFile | null = null;
  @observable showReferenceLinkDeleteAlert: boolean = false;
  @observable showReferenceLinkDescriptionDeleteAlert: boolean = false;
  @observable currentReferenceLink: ReferenceLink | null = null;

    get injected() {
        return this.props as InjectedProps;
    }

    @computed
    get requestStore() {
        return this.injected.appStore.parttimeRequestStore;
    }
    
    @computed
    get request() {
      const { id } = this.props.match.params;
  
      return this.requestStore.requests.find(
        x => x.id === +id
      );
    }

    handleEditRequest = (request: Request) => {
      this.props.history.push(`/parttime/requests/${ request.id }/edit`);
    };

    handleTaskClick = (task: TaskSummary) => {
      this.props.history.push(`/parttime/requests/${ task.request }/tasks/${ task.id }`);
    };

    handleDialogOpen = () => {
      this.isDialogOpen = true;
    };

    handleRestoreDialogOpen = () => {
      this.isRestoreDialogOpen = true;
    };

    handleReferenceLinkFormDialogOpen = (referenceLink: ReferenceLink) => {
      this.currentReferenceLink = referenceLink;
      this.isReferenceLinkFormDialogOpen = true;
    };

    handleReferenceFileFormDialogOpen = (referenceFile: ReferenceFile) => {
      this.currentReferenceFile = referenceFile;
      this.isReferenceFileFormDialogOpen = true;
    };

    handleReferenceFileFormDialogClose = () => {
      this.isReferenceFileFormDialogOpen = false;
      const { id } = this.props.match.params;
      this.props.history.push(`/parttime/requests/${ id }`);
    }; 
  
    handleDialogClose = () => {
      this.isDialogOpen = false;
      const { id } = this.props.match.params;
      this.props.history.push(`/parttime/requests/${ id }`);
    };  

    handleRestoreDialogClose = () => {
      this.isRestoreDialogOpen = false;
      const { id } = this.props.match.params;
      this.props.history.push(`/parttime/requests/${ id }`);
    }; 

    handleReferenceLinkFormDialogClose = () => {
      this.isReferenceLinkFormDialogOpen = false;
      const { id } = this.props.match.params;
      this.props.history.push(`/parttime/requests/${ id }`);
    }; 

    handleTaskFormDialogOpen = () => {
      this.isTaskFormDialogOpen = true;
    };

    handleTaskFormDialogClose = async() => {
      this.isTaskFormDialogOpen = false;
      const { id } = this.props.match.params;
      await this.injected.appStore.parttimeRequestStore.fetchRequestById(parseInt(id));
    };  

    handleRequestFormDialogOpen = () => {
      this.isRequestFormDialogOpen = true;
    };

    handleRequestFormDialogClose = async() => {
      this.isRequestFormDialogOpen = false;
      const { id } = this.props.match.params;
      await this.injected.appStore.parttimeRequestStore.fetchRequestById(parseInt(id));
    };  

    handleReferenceFileDeleteSubmit = async () => {
      try {
        const { id } = this.props.match.params;
        await this.injected.appStore.parttimeRequestStore.deleteReferenceFile(parseInt(id), this.currentReferenceFile ? this.currentReferenceFile.id : -1);
        await this.injected.appStore.parttimeRequestStore.fetchRequestById(parseInt(id));
        this.showReferenceFileDeleteAlert = false;
      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }    
    }

    handleReferenceFileDescriptionDeleteSubmit = async () => {
      try {
        const { id } = this.props.match.params;
        await this.injected.appStore.parttimeRequestStore.deleteReferenceFileDescription(parseInt(id), this.currentReferenceFile ? this.currentReferenceFile.id : -1);
        await this.injected.appStore.parttimeRequestStore.fetchRequestById(parseInt(id));
        this.showReferenceFileDescriptionDeleteAlert = false;
      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }    
    }

    handleReferenceLinkDeleteSubmit = async () => {
      try {
        const { id } = this.props.match.params;
        await this.injected.appStore.parttimeRequestStore.deleteReferenceLink(parseInt(id), this.currentReferenceLink ? this.currentReferenceLink.id : -1);
        await this.injected.appStore.parttimeRequestStore.fetchRequestById(parseInt(id));
        this.showReferenceLinkDeleteAlert = false;
      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }    
    }

    handleReferenceLinkDescriptionDeleteSubmit = async () => {
      try {
        const { id } = this.props.match.params;
        await this.injected.appStore.parttimeRequestStore.deleteReferenceLinkDescription(parseInt(id), this.currentReferenceLink ? this.currentReferenceLink.id : -1);
        await this.injected.appStore.parttimeRequestStore.fetchRequestById(parseInt(id));
        this.showReferenceLinkDescriptionDeleteAlert = false;
      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message: "오류: " + error,
          intent: Intent.DANGER
        });
      }    
    }

    async componentDidMount() {
        const { id } = this.props.match.params;

        try {
            await this.injected.appStore.parttimeRequestStore.fetchRequestById(parseInt(id));
        } catch (e) {}
    }

    render() {
        const { isPM, isClient } = this.injected.appStore.userStore;
        const { request } = this;

        if (!request) {
            return <Wrapper>신청상세내역을 가져오지 못했습니다.</Wrapper>;
          }     
          
        let isCanceled = false;
        if(request.status === "CANCELED") {
          isCanceled = true;
        }
 

        return (
            <LargeContainer>
                <PageNavWrapper>
                  <BreadCrumb
                      items={[
                        { name: "대시보드", link: "/parttime/" },
                        { name: "신청 목록", link: "/parttime/requests" },
                        "신청내역 상세"
                      ]}
                  />
                </PageNavWrapper>

                {(isPM || isClient) && !isCanceled && (
                  <StyledButton text={"수정"} onClick={this.handleRequestFormDialogOpen}></StyledButton>
                )}
                
                <Title>
                  <StatusHeaderTag>{RequestStatus.get(request.status)}</StatusHeaderTag>  
                  <h2 style={{ display: "inline", marginLeft: "10px" }}>{ request.title }</h2>    
                </Title>

                <Card style={{margin: "20px 0 30px 0"}}>
                  <CardTitle>기본정보</CardTitle>
                  <CardContent>
                    <Table>
                      <TableRow>
                        <HeadColumn>
                          <GrayLabel>서비스명</GrayLabel>
                        </HeadColumn>
                        <ContentColumn>
                          <EmphasizedText>{ request.serviceName }</EmphasizedText>
                        </ContentColumn>
                      </TableRow>
                      <TableRow>
                        <HeadColumn>
                          <GrayLabel>OS</GrayLabel>
                        </HeadColumn>
                        <ContentColumn>
                          <EmphasizedText>
                            { request.os ? (request.os.map((os, idx) => {
                            return (idx == 0) ? (<>{RequestOS.get(os)}</>) : (<>, {RequestOS.get(os)}</>)
                            })) : '-'}
                          </EmphasizedText>
                        </ContentColumn>
                      </TableRow>
                      <TableRow>
                        <HeadColumn>
                          <GrayLabel>유형</GrayLabel>
                        </HeadColumn>
                        <ContentColumn>
                          <EmphasizedText>{ RequestType.get(request.type) }</EmphasizedText>
                        </ContentColumn>
                      </TableRow>
                    </Table>
                  </CardContent>
                </Card>

                <StyledCard>
                  <CardTitle>클라이언트</CardTitle>
                  <CardContent>
                  <Table>
                      <TableRow>
                        <HeadColumn>
                          <GrayLabel>번호</GrayLabel>
                        </HeadColumn>
                        <ContentColumn>
                          <EmphasizedText>{ request.client ? request.client.clientId : '-' }</EmphasizedText>
                        </ContentColumn>
                      </TableRow>
                      <TableRow>
                        <HeadColumn>
                          <GrayLabel>이름</GrayLabel>
                        </HeadColumn>
                        <ContentColumn>
                          <EmphasizedText>{ request.client ? request.client.name : '-' }</EmphasizedText>
                        </ContentColumn>
                      </TableRow>
                    </Table>
                  </CardContent>
                </StyledCard>

                <StyledCard>
                  <CardTitle>현재상태(ASIS)</CardTitle>
                  <CardContent>
                    <Paragraph>{ request.asis ? request.asis : '-' }</Paragraph>
                  </CardContent>
                </StyledCard>

                <StyledCard>
                  <CardTitle>변경상태(TOBE)</CardTitle>
                  <CardContent>
                  <Paragraph>{ request.tobe ? request.tobe : '-'}</Paragraph>
                  </CardContent>
                </StyledCard>

                <StyledCard>
                  <CardTitle>참고파일</CardTitle>
                  <CardContent>
                    <Table>
                      { (Array.isArray(request.referenceFiles) && request.referenceFiles.length) ? (request.referenceFiles.map(file => (
                        <TableRow key={ file.id }>
                          <TruncatedText><a href={file.file} target="_blank">{ file.name }</a></TruncatedText>
                          <Icon 
                            icon="remove" 
                            iconSize={Icon.SIZE_STANDARD} 
                            intent={Intent.DANGER} 
                            style={{margin: "0px 10px"}}
                            onClick={() => {this.showReferenceFileDeleteAlert=true; this.currentReferenceFile = file;}} 
                          />
                          { file.description
                            ? (
                              <>
                                <NormalText style={{display: "inline-block", margin: "0 10px"}}>{ file.description }</NormalText> 
                                <div style={{marginLeft: "auto"}}>
                                  <LinkButton onClick={ () => this.handleReferenceFileFormDialogOpen(file) }>수정</LinkButton>
                                  <> | </>
                                  <LinkButton onClick={() => {this.showReferenceFileDescriptionDeleteAlert=true; this.currentReferenceFile=file;}} >삭제</LinkButton>     
                                </div>      
                              </>
                            )
                            : <LinkButton 
                                onClick={ () => this.handleReferenceFileFormDialogOpen(file) }
                                style={{margin: "0 10px"}}
                              >설명추가</LinkButton>
                          }
                        </TableRow>
                      ))) : '-' }
                    </Table>
                  </CardContent>
                </StyledCard>

                <StyledCard>
                  <CardTitle>참고링크</CardTitle>
                  <CardContent>
                    <Table>
                        { (Array.isArray(request.referenceLinks) && request.referenceLinks.length) ? (request.referenceLinks.map(link => (
                          <TableRow key={ link.id }>
                            <TruncatedText><a>{ link.url }</a></TruncatedText>
                            <Icon 
                              icon="remove" 
                              iconSize={Icon.SIZE_STANDARD} 
                              intent={Intent.DANGER} 
                              style={{margin: "0px 10px"}}
                              onClick={() => {this.showReferenceLinkDeleteAlert=true; this.currentReferenceLink = link;}} 
                            />
                            { link.description
                              ? (
                                <>
                                  <NormalText style={{display: "inline-block", margin: "0 10px"}}>{ link.description }</NormalText> 
                                  <div style={{marginLeft: "auto"}}>
                                    <LinkButton onClick={ () => this.handleReferenceLinkFormDialogOpen(link) }>수정</LinkButton>
                                    <> | </>
                                    <LinkButton onClick={() => {this.showReferenceLinkDescriptionDeleteAlert=true; this.currentReferenceLink = link;}} >삭제</LinkButton>     
                                  </div>  
                                </>
                              )
                              : <LinkButton 
                                  onClick={ () => this.handleReferenceLinkFormDialogOpen(link) }
                                  style={{margin: "0 10px"}}
                                >설명추가</LinkButton>
                            }
                          </TableRow>
                        ))) : '-' }
                    </Table>
                  </CardContent>
                </StyledCard>

                <StyledCard>
                  <CardTitle>서비스 접속정보</CardTitle>
                  <CardContent>
                  <Paragraph>{ request.accessInfo ? request.accessInfo : '-'}</Paragraph>
                  </CardContent>
                </StyledCard>

                <StyledCard>
                  <CardTitle>희망완료일</CardTitle>
                  <CardContent>
                    <>{ request.expectedDueDate ? moment(request.expectedDueDate).format("YYYY.MM.DD") : '-'}</>
                  </CardContent>
                </StyledCard>

                <StyledCard>
                  <CardTitle>
                    TASK
                    { isPM && !isCanceled && (
                     <StyledButton 
                      text={"TASK 추가"}
                      onClick={this.handleTaskFormDialogOpen}/>
                    )}
                  </CardTitle>
                  <CardContent>
                    { request.tasks ? (
                      request.tasks.map(task => (
                        <ItemCard 
                          key= {task.id}
                          onClick={() => this.handleTaskClick(task)} 
                        >
                          <StatusTag status={TaskStatus.get(task.status)}></StatusTag>  
                          <h4 style={{ display: "inline", marginLeft: "10px" }}>{ task.code }</h4>
                          { isPM && (
                            <LinkButton style={{float: "right"}}>수정</LinkButton>
                          )}
                          <div>
                              <h4 style={{ margin: "7px 0px"}}>{ task.title }</h4>
                              <p style={{ marginBottom: "0px"}}>{ TaskType.get(task.type) } | { (task.rufree && task.rufree.name) ? task.rufree.name : '-' }</p>
                          </div>
                        </ItemCard>
                      ))
                  ) : (
                      <>TASK를 추가해주세요.</>
                  )
                  } 
                  </CardContent>
                </StyledCard>

                {isCanceled && (
                  <StyledCard>
                    <CardTitle>
                      <>{ "취소사유" }</>
                      <SubTitle style={{marginLeft: "10px"}}>{ request.cancelConsultant }</SubTitle>
                      <SubTitle>{ " | " }</SubTitle>
                      <SubTitle>{ request.cancelDate? moment(request.cancelDate).format("YYYY.MM.DD hh:mm") : '-'}</SubTitle>
                    </CardTitle>
                    <CardContent>
                    <Paragraph>{ request.cancelReason }</Paragraph>
                    </CardContent>
                  </StyledCard>
                )}

                <ButtonGroup>
                {(isPM) && (
                  isCanceled ? (
                    <StyledButton 
                    text={"신청상태로 되돌리기"} 
                    style={{width: "100%"}}
                    onClick={ this.handleRestoreDialogOpen }
                    />) : (
                    <Button 
                      text={"신청취소"} 
                      style={{width: "100%", background: "white", border: "1px solid #9c9c9c",
                      borderRadius: "20px", boxShadow: "none", color: "#ff6565"}}
                      onClick={ this.handleDialogOpen }
                    />)
                )}
                </ButtonGroup>

                <ReferenceFileFormDialog
                  request = { request }
                  referenceFile={ this.currentReferenceFile }
                  onClose={ this.handleReferenceFileFormDialogClose }
                  isOpen={ this.isReferenceFileFormDialogOpen } 
                />    

                <ReferenceLinkFormDialog
                  request = { request }
                  referenceLink={ this.currentReferenceLink }
                  onClose={ this.handleReferenceLinkFormDialogClose }
                  isOpen={ this.isReferenceLinkFormDialogOpen } 
                />    

                <RequestCancelDialog
                  request={ request }
                  onClose={ this.handleDialogClose }
                  isOpen={ this.isDialogOpen } 
                />
                
                <RequestRestoreConfirmDialog
                  request={ request }
                  onClose={ this.handleRestoreDialogClose }
                  isOpen={ this.isRestoreDialogOpen } 
                />

                <RequestFormDialog
                  id={ request.id }
                  mode={ FormMode.Edit }
                  onClose={ this.handleRequestFormDialogClose }
                  isOpen={ this.isRequestFormDialogOpen } 
                />

                <TaskFormDialog
                  requestId={ request.id }
                  taskId={ -1 }
                  mode={ FormMode.Create }
                  onClose={ this.handleTaskFormDialogClose }
                  isOpen={ this.isTaskFormDialogOpen } 
                />
                <Alert 
                  icon="trash"
                  intent={Intent.DANGER}
                  isOpen={this.showReferenceFileDeleteAlert}
                  onCancel={() => this.showReferenceFileDeleteAlert=false}
                  onConfirm={this.handleReferenceFileDeleteSubmit}
                  confirmButtonText={'예'}
                  cancelButtonText={'아니오'}
                ><p>선택한 파일을 삭제하시겠습니까?</p>
                </Alert>
                
                <Alert 
                  icon="trash"
                  intent={Intent.DANGER}
                  isOpen={this.showReferenceFileDescriptionDeleteAlert}
                  onCancel={() => this.showReferenceFileDescriptionDeleteAlert=false}
                  onConfirm={this.handleReferenceFileDescriptionDeleteSubmit}
                  confirmButtonText={'확인'}
                  cancelButtonText={'취소'}
                ><p>등록된 설명을 삭제합니다.</p>
                </Alert> 

              <Alert 
                icon="trash"
                intent={Intent.DANGER}
                isOpen={this.showReferenceLinkDeleteAlert}
                onCancel={() => this.showReferenceLinkDeleteAlert=false}
                onConfirm={this.handleReferenceLinkDeleteSubmit}
                confirmButtonText={'예'}
                cancelButtonText={'아니오'}
              ><p>선택한 링크를 삭제하시겠습니까?</p>
              </Alert>

              <Alert 
                icon="trash"
                intent={Intent.DANGER}
                isOpen={this.showReferenceLinkDescriptionDeleteAlert}
                onCancel={() => this.showReferenceLinkDescriptionDeleteAlert=true}
                onConfirm={this.handleReferenceLinkDescriptionDeleteSubmit}
                confirmButtonText={'확인'}
                cancelButtonText={'취소'}
              ><p>등록된 설명을 삭제합니다.</p>
              </Alert>     
            </LargeContainer>
        );
    }
}

export default PartTimeRequestDetailPage;

const Wrapper = styled.div``;

const Title = styled.div`
  margin-bottom: 40px;
`;

const StyledCard = styled(Card)`
  margin-bottom: 30px;
`;

const CardTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 2px;
  margin-bottom: 20px;
`;

const SubTitle = styled.div`
  display: inline;
  font-size: 13px;
  font-weight: bold;
`;

const CardContent= styled.div`
  display: block;
`;

const Table = styled.div``;
const TableRow = styled.div`
  display: flex;
  margin: 10px;
`;
const HeadColumn = styled.div`
  width: 120px;
  flex-shrink: 0;  
`;
const ContentColumn = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;
const GrayLabel = styled.div`
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7f8390;
`;
const NormalText = styled.div`
  font-size: 15px;
  letter-spacing: normal;
  color: #161d2e;
  word-break: keep-all;
  white-space: pre-line;
`;

const EmphasizedText = styled.div`
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #161d2e;
  word-break: keep-all;
  white-space: pre-line;
`;

const TruncatedText = styled.div`
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LinkButton = styled.a`
  text-decoration: underline;
`;

const StyledButton = styled(Button)`
  margin-bottom: 30px;
  width: 150px; 
  border-radius: 20px;
  background-image: none !important; 
  background-color: #ff6565 !important;
  float: right;
  box-shadow:none !important; 
  color: white !important;
`;

const ButtonGroup = styled.div``;

const StatusHeaderTag = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  top: -2px;
  border: none;
  border-radius: 3px;
  box-shadow: none;
  background-color: #18750d;
  min-width: 20px;
  max-width: 100%;
  min-height: 27px;
  padding: 2px 6px;
  line-height: 16px;
  color: #f5f8fa;
  font-size: 15px;
`;
