import React, { Component, FormEvent } from "react";
import { RouteComponentProps } from "react-router";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { ChangeEvent } from "cleave.js/react/props";
import { AppStore } from "../../../store/AppStore";
import { ContractTemplate } from "../../../store/models/ContractTemplate";
import Paginator from "../../molecules/Paginator/Paginator";
import ContractTemplateSelection from "../../molecules/ContractTemplateSelection/ContractTemplateSelection";
import { observable } from "mobx";
import { 
  Button,
  ButtonGroup,
  Classes,
  HTMLTable,
  Intent,
  InputGroup,
  HTMLSelect,
  IOptionProps,
  Spinner
} from "@blueprintjs/core";
import moment from "moment";

export const ToolsRow = styled.div`
  margin-bottom: 30px;
  display: flex;
  height: 20px;

  & > * + * {
    margin-left: 10px;
  }
`;
const InputContainer = styled.div`
  width: 250px;
  margin-bottom: 30px;
`;
const PaginationContainer = styled.div`
  text-align: center;
`;


interface ContractListPanelProps extends RouteComponentProps {
  isSupportPM: boolean;
}

interface InjectedProps extends ContractListPanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ContractListPanel extends Component<ContractListPanelProps> {
  // search options
  @observable readyToShow = false;
  @observable contractTemplateForCreate: ContractTemplate | undefined =  undefined;
  @observable contractTemplateForSearch: ContractTemplate | undefined =  undefined;
  @observable contractTarget = "";
  @observable status = "";
  @observable searchText = "";

  get injected() {
    return this.props as InjectedProps;
  }

  get contractStore() {
    return this.injected.appStore.contractStore;
  }

  fetchList = async () => {
    this.readyToShow = false;

    if(this.contractTarget === "클라이언트") {
      try {
        await this.contractStore.fetchApprovedClientContracts({
          status: this.status,
          searchText: this.searchText
        });
      } catch (e) {}
    } else {
      try {
        await this.contractStore.fetchApprovedRufreeContracts({
          status: this.status,
          searchText: this.searchText
        });
      } catch (e) {}
    }

    this.readyToShow = true;
  };

  handleTemplateForCreateSelect = (selectedValue: ContractTemplate) => {
    this.contractTemplateForCreate = selectedValue;
  }
  handleTemplateForSearchSelect = (selectedValue: ContractTemplate) => {
    this.contractTemplateForSearch = selectedValue;
  }
  handleCreateButtonClick = (type: string) => {
    const params = this.contractTemplateForCreate ? `template_id=${this.contractTemplateForCreate.template_id}`: '';
    this.props.history.push(`/contract/${type}/new?${params}`);
  }
  handleRowClick = (contractTarget:string, id: string) => {
    if(contractTarget === 'client') {
      this.props.history.push(`/contract/client/${id}/read`);
    }
    if(contractTarget === 'rufree') {
      this.props.history.push(`/contract/rufree/${id}/read`);
    }
  }
  handleContractTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    this.contractTarget = e.currentTarget.value;
    this.status = '';
    this.searchText = '';
    this.contractStore.setCurentPage(1);
    this.fetchList();
  }
  handleStatusChange = (e: ChangeEvent<HTMLSelectElement>) => {
    this.status = e.currentTarget.value;
    // this.fetchList();
  }
  handleSearchTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.searchText = e.currentTarget.value;
  }

  async componentDidMount() {
    this.readyToShow = false;
    
    const contractTarget = this.props.location.search.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function(str, key, value) {
        return (key === 'type') ? value : '';
      }
    );
    this.contractTarget = contractTarget === 'rufree' ? '알유프리' : '클라이언트';

    await this.fetchList();
  }

  render() {
    let content = [];

    const { isPM } = this.injected.appStore.userStore;

    const { handleRowClick } = this;
    const { 
      approvedClientContracts,
      approvedRufreeContracts,
      pageGroup,
      totalPages,
      currentPage,
      setPageGroup,
      setCurentPage
    }  = this.contractStore;

    const typeOptions: IOptionProps[] = [
      { label: '클라이언트', value: '클라이언트'},
      { label: '알유프리', value: '알유프리'}
    ]
    const targetOptions: IOptionProps[] = [
      {label: '전체(상태)', value: ''},
      {label: '작성중', value: '작성중'},
      {label: '반려', value: '반려'},
      {label: '결재대기', value: '결재대기'},
      {label: '결재완료', value: '결재완료'},
      {label: '결재완료', value: '결재완료'},
      {label: '결재완료', value: '결재완료'},
    ]

    if (this.readyToShow) {
      content.push(
        (this.contractTarget === '클라이언트') ? (
          approvedClientContracts.map(contract => {
            return (
              <tr
                key={contract.approvedContractId}
                onClick={() => handleRowClick('client', contract.approvedContractId)}
              >
                <td>{contract.approvedContractId}</td>
                <td>{contract.templateName}</td>
                <td>{contract.contractName}</td>
                <td>{contract.clientContract}</td>
                <td>{'클라이언트'}</td>
                <td>{contract.contractorId}</td>
                <td>{contract.contractorName}</td>
                <td>{contract.managerName}</td>
                <td>{moment(contract.updatedAt).format('YYYY-MM-DD')}</td>
                <td>{moment(contract.createdAt).format('YYYY-MM-DD')}</td>
              </tr>
            )
          })
        ) : (
          approvedRufreeContracts.map(contract => {
            return (
              <tr
                key={contract.approvedContractId}
                onClick={() => handleRowClick('rufree', contract.approvedContractId)}
              >
                <td>{contract.approvedContractId}</td>
                <td>{contract.templateName}</td>
                <td>{contract.contractName}</td>
                <td>{contract.rufreeContract}</td>
                <td>{'알유프리'}</td>
                <td>{contract.contractorId}</td>
                <td>{contract.contractorName}</td>
                <td>{contract.managerName}</td>
                <td>{moment(contract.updatedAt).format('YYYY-MM-DD')}</td>
                <td>{moment(contract.createdAt).format('YYYY-MM-DD')}</td>
              </tr>
            )
          })
        )
      )
    } else {
      content.push(
        <tr key="emptyrow">
          <td colSpan={10}><Spinner /></td>
        </tr>);
    }

    return (
      <>
      {
        isPM && (
          <>
            <ToolsRow>
              {
                // <ContractTemplateSelection
                //   onChange={this.handleTemplateForCreateSelect}
                //   currentValue={this.contractTemplateForCreate}
                //   blockFetchingData={false} />
              }
               <Button
                text="새 클라이언트 계약"
                onClick={() => this.handleCreateButtonClick('client')}
                intent={Intent.PRIMARY}
              />
              <Button
                text="새 알유프리 계약"
                onClick={() => this.handleCreateButtonClick('rufree')}
                intent={Intent.PRIMARY}
              />
            </ToolsRow>

            <ToolsRow>

              <HTMLSelect
                value={this.contractTarget}
                onChange={this.handleContractTypeChange}
                options={typeOptions}
              />

              {
              // <ContractTemplateSelection
              //   onChange={this.handleTemplateForSearchSelect}
              //   currentValue={this.contractTemplateForSearch}
              //   blockFetchingData={false} />
              }

              <HTMLSelect
                value={this.status}
                onChange={this.handleStatusChange}
                options={targetOptions}
              />

              <InputContainer>
                <InputGroup
                  placeholder="번호/계약명/고유번호/계약자"
                  value={this.searchText}
                  onChange={this.handleSearchTextChange}
                />
              </InputContainer>

              <Button
                icon="search"
                text="검색"
                intent={Intent.PRIMARY}
                onClick={this.fetchList}
              />
            </ToolsRow>
          </>
        )
      }
        

        <HTMLTable
          className={Classes.SMALL}
          striped={true}
          interactive={true}
          style={{ width: "100%", tableLayout: "fixed" }}
        >
          <thead>
            <tr>
              <th style={{width: '80px'}}>번호</th>
              <th>양식</th>
              <th>계약명</th>
              <th>계약요청서</th>
              <th>대상</th>
              <th>고유번호</th>
              <th>계약자</th>
              <th>담당매니저</th>
              <th style={{width: '110px'}}>수정일</th>
              <th style={{width: '110px'}}>생성일</th>
            </tr>
          </thead>

          <tbody>
            {content}
          </tbody>
        </HTMLTable>

        <Paginator
          pageGroup={pageGroup}
          totalPages={totalPages}
          currentPage={currentPage}
          setPageGroup={setPageGroup}
          setCurentPage={setCurentPage}
          onFetch={this.fetchList}
        />

      </>
    );
  }
}

export default ContractListPanel;
