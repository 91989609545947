import { types } from "mobx-state-tree";

export const PortfolioLinksFormModel = types
  .model("PortfolioLinksForm", {
      type: types.optional(types.string, "link"),
	  file: types.optional(types.string, ""),
	  link: types.optional(types.string, "")
  })
  .actions(self => ({
    setType(value: string) {
      self.type = value;
    },
    setFile(value: string) {
      self.file = value;
    },
    setLink(value: string) {
      self.link = value;
    }
  }));

type PortfolioLinksFormType = typeof PortfolioLinksFormModel.Type;
export interface PortfolioLinksForm extends PortfolioLinksFormType {}
