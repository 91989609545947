import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { observable } from "mobx";
import { 
  Spinner
} from "@blueprintjs/core";
import moment from "moment";
import { 
  EtcSection, EtcSectionTitle, EtcSectionBody, H6Wrapper,  EtcLinkWrapper, EtcItemWrapper, EtcStatus, EtcTitle
} from "../../atoms/DashboardComponents/DashboardComponents";
const EtcRole = styled.div`
  width: 55px;
`;

interface OngoingRufreeMatchingProps {
}

interface InjectedProps extends OngoingRufreeMatchingProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class OngoingRufreeMatching extends Component<OngoingRufreeMatchingProps> {
  @observable readyToShow = false;

  get injected() {
    return this.props as InjectedProps;
  }

  get rufreeMatchingStore() {
    return this.injected.appStore.rufreeMatchingStore;
  }

  async componentDidMount() {
    this.readyToShow = false;

    await this.rufreeMatchingStore.searchMatchings({ // TODO: 대쉬보드 업데이트 할 때 한 번 정리할 필요가 있음 
      projectGroup: '',
      projectName: '',
      manager: '',
      role: '',
      status: '진행중'
    });

    this.readyToShow = true;
  }

  render() {
    const { rufreeMatchings } = this.rufreeMatchingStore;

    // if(!this.readyToShow) {
    //   return <Spinner />
    // }

    const body = !this.readyToShow ? (
      <Spinner />
    ) : (
      rufreeMatchings.filter(x => x.dateRequest)
        .map(rufreeMatching => {
          const firstSendDate = rufreeMatching.matchingHistory.reduce((acc, cur, i) => {
            if(!cur.dateSend)
              return acc;
            return moment(cur.dateSend).isBefore(acc) ? moment(cur.dateSend) : acc;
          }, moment());

          return (
            <EtcLinkWrapper
              key={`ogrm_${rufreeMatching.id}`}
              to={`/rufree-matchings/${rufreeMatching.id}`}
              target="_blank"
            >
              <EtcItemWrapper>
                <EtcStatus>{rufreeMatching.status === '제안대기' ? '제안대기' : `${moment().diff(firstSendDate, 'hours')}H`}</EtcStatus>
                <EtcTitle>{rufreeMatching.projectGroupName}</EtcTitle>
                <EtcRole>{rufreeMatching.role}</EtcRole>
              </EtcItemWrapper>
            </EtcLinkWrapper>
          )
        })
    )

    return (
      <EtcSection style={{width: '350px'}}>

        <EtcSectionTitle>
          <H6Wrapper>제안중인 알유프리 매칭</H6Wrapper>
        </EtcSectionTitle>

        <EtcSectionBody>
          {body}
        </EtcSectionBody>
      </EtcSection>
    );
  }
}

export default OngoingRufreeMatching;