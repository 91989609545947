import { types, flow } from "mobx-state-tree";
import axios from "axios";
import moment from "moment";
import { ClientCalculate } from "../../models/ClientCalculate";
import {
  ClientDepositFormModel,
  ClientCalculateFormModel,
  ClientCalculateForm,
  mapForDepositForm
} from "./ClientCalculateForm";

import { ClientCalculateResponse } from "../../../types/Calculate";

export const CalculateFormStoreModel = types
  .model("CalculateFormStore", {
    clientCalculateForm: types.optional(ClientCalculateFormModel, {})
  })
  .actions(self => {
    const initClientCalculateForm = (instance?: ClientCalculate) => {
      if(instance) {
        const depositsForm = instance.deposits.map( deposit => (
          ClientDepositFormModel.create({
            date: deposit.date,
            payout: deposit.payoutWithVATForRead
          })
        ))
          
        self.clientCalculateForm = ClientCalculateFormModel.create({
          id: instance.id,
          projectGroupId: instance.projectGroup,
          sprints: instance.sprints.map(s => s),
          contract: instance.contract,
          clientId: instance.clientId,
          dateIssue: instance.dateIssue || moment().toDate().toISOString().substring(0, 10),
          payout: instance.payoutWithVAT || 0,
          deposits: depositsForm || [ClientDepositFormModel.create()],
          comment: instance.comment || ""
        });
      } else {
        self.clientCalculateForm = ClientCalculateFormModel.create();
      }
    };

    const mapFormDataForRequest = (form: ClientCalculateForm) => {
      let date_last_deposit: string | undefined = undefined;
      if(form.deposits.length > 0) {
        date_last_deposit = '2000-01-01T00:00:00';
        form.deposits.map((deposit) => {
          if(moment(date_last_deposit) < moment(deposit.date)) {
            date_last_deposit = moment(deposit.date).format('YYYY-MM-DD');
          }
        });
      }

      return {
        id: form.id,
        contract: form.contract,
        client: form.clientId,
        project_group: form.projectGroupId,
        sprints: form.sprints,
        date_issue: form.dateIssue,
        date_last_deposit: date_last_deposit ? date_last_deposit : null,
        payout: Math.round(form.payout / 1.1),  // 입력시, VAT 포함으로 입력.
        deposits: form.deposits.map(deposit => mapForDepositForm(deposit)), // 입력시, VAT 포함으로 입력.
        comment: form.comment
      }
    };

    const postClientCalculate = flow(function*() {
      try {
        const { data }: { data: ClientCalculateResponse } = yield axios.post(
          `/calculate/client`,
          mapFormDataForRequest(self.clientCalculateForm)
        );

        return data;
      } catch (e) {
        throw e;
      }
    });

    const putClientCalculate = flow(function*() {
      // let date_last_deposit = '2000-01-01';
      // self.clientCalculateForm.deposits.map((deposit) => {
      //   if(moment(date_last_deposit) < moment(deposit.date)) {
      //     date_last_deposit = deposit.date;
      //   }
      // });
      
      try {
        const { data }: { data: ClientCalculateResponse } = yield axios.put(
          `/calculate/client/${self.clientCalculateForm.id}`,
          mapFormDataForRequest(self.clientCalculateForm)
          // {
          //   ...mapFormDataForRequest(self.clientCalculateForm),
          //   date_last_deposit: date_last_deposit
          // }
          
        );
      } catch (e) {
        throw e;
      }
    });

    const deleteClientCalculate = flow(function*() {
      if(self.clientCalculateForm && self.clientCalculateForm.id) {
        try {
          yield axios.delete(
            `/calculate/client/${ self.clientCalculateForm.id }`
          );
        } catch (e) {
          throw e;
        }
      }
    });

    return {
      initClientCalculateForm,
      postClientCalculate,
      putClientCalculate,
      deleteClientCalculate
    };
  });

type CalculateFormStoreType = typeof CalculateFormStoreModel.Type;
export interface CalculateFormStore extends CalculateFormStoreType {}
