import React, { Component } from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";
import styled from "styled-components/macro";
import { Button, Intent, Icon } from "@blueprintjs/core";
import Clipboard from 'react-clipboard.js';
import moment from "moment";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;
const FlexRow = styled.div`
  display: flex;
  & > * + * {
    margin-left: 5px;
  }
`;
const Message = styled.div`
  color: #0E5A8A;
  font-size: 12px;
  margin-top: 5px;
`;

const ClipboardButton = styled(Clipboard)`
  font-size: 12px;
  padding: 0 10px !important;
`;


interface CopyPasteProps {
  title: string;
  value: string;
  paste: (value:string) => void;
}

@observer
class CopyPaste extends Component<CopyPasteProps> {
  @observable message = '';

  onSuccess(title: string) {
    this.props.paste(this.props.value)
    this.message = `복사완료 ${moment().format('HH:mm:ss')}`;
  }

  copyAfterPaste(value: string) {
    console.log(value);
    document.execCommand('insertText', false, value);
  }

  render() {
    const { title, value } = this.props;
    return (
      <Wrapper>
        <div>
          <strong>{title}</strong>
        </div>
        <div>
          {value}
        </div>

        <FlexRow>
          {
            // <ClipboardButton
            //   component={'button'}
            //   className={'bp3-button bp3-intent-success bp3-small bp3-outlined'}
            //   data-clipboard-text={value}
            //   onSuccess={() => this.copyAfterPaste(value)}>
            //   <Icon icon={'arrow-left'} iconSize={12} />
            // </ClipboardButton>
          }
          

          <ClipboardButton
            component={'button'}
            className={'bp3-button bp3-intent-primary bp3-small bp3-outlined'}
            data-clipboard-text={value}
            onSuccess={() => this.onSuccess(title)}>
            복사
          </ClipboardButton>
          <Message className={'bp3-text-small'}>
            {this.message}
          </Message>
        </FlexRow>
      </Wrapper>
    );
  }
}

export default CopyPaste;
