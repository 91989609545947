/*
  초대코드 관리
*/
import React, { useState, useEffect } from 'react';
import { inject, observer } from "mobx-react";
import { RouteComponentProps } from "react-router";
import Paginator from "../../../molecules/Paginator/Paginator";
import { AppToaster } from "../../../atoms/Toaster/Toaster";

import { AppStore } from '../../../../store/AppStore';
import { IInvitationStore } from '../../../../store/InvitationStore';

import { Spinner, Intent } from '@blueprintjs/core';

import {
  FilterBar,
  InvitationList,
  IssueInvitation
} from '.';


interface IProps extends RouteComponentProps<{code: string;}> {
  appStore: AppStore
}
const SecondteamInvitationPage = inject("appStore")(observer((props: IProps) => {
  const store: IInvitationStore = (props as { appStore: AppStore }).appStore.invitationStore;
  const { invitations, fetchInvitations, issueInvitation, isFetching } = store;

  const [ search, setSearch ] = useState('');

  const clearFilter = async() => {
    setSearch('');
    await store.fetchInvitations();
  };

  useEffect(() => {
    const fetch = async () => {
      await fetchInvitations();
    }

    fetch();
  }, []);

  if(isFetching) {
    return (
      <Spinner />
    )
  }

  return (
    <div style={{ maxWidth: '1200px' }}>
      <h3>초대코드 관리</h3>

      <FilterBar
        search={ search }
        onChangeSearch={ (search: string) => setSearch(search) }
        onClear={ clearFilter }
        onSearch={ () => store.fetchInvitations(search) } />
      <IssueInvitation
        onIssue={ async(memberId: number) => {
          try {
            await issueInvitation(memberId);
            AppToaster.show({
              message: "발행되었습니다",
              intent: Intent.SUCCESS
            });
            await fetchInvitations();
          } catch(e) {
            AppToaster.show({
              message: '에러가 발생하였습니다.',
              intent: Intent.DANGER
            });
          }
        }} />
      
      <div>
        <InvitationList
          isFetching={ false }
          invitations={ invitations }
           />
        <Paginator
          pageGroup={ store.pageGroup }
          totalPages={ store.totalPages }
          currentPage={ store.currentPage}
          setPageGroup={ store.setPageGroup }
          setCurentPage={ store.setCurentPage }
          onFetch={() => store.fetchInvitations(search) } />
      </div>
    </div>
  )
}));
export default SecondteamInvitationPage;
