import React, { FC } from "react";
import { PageNavText, PageNavLink } from "../../atoms/PageNav/PageNav";
import { BreadCrumbDivider } from "../../atoms/BreadCrumbDivider/BreadCrumbDivider";

interface BreadCrumbItem {
  name: string;
  link: string;
}

interface BreadCrumbProps {
  items: (string | BreadCrumbItem)[];
}

export const BreadCrumb: FC<BreadCrumbProps> = ({ items }) => {
  const itemsToRender: React.ReactElement<any>[] = [];

  let x = 0;
  items.forEach((item, index) => {
    if (index !== 0) {
      itemsToRender.push(<BreadCrumbDivider key={x} />);
      ++x;
    }

    if (typeof item !== "string") {
      const i = item as BreadCrumbItem;
      itemsToRender.push(
        <PageNavLink
          key={x}
          to={i.link}
          color={index === items.length - 1 ? "#000" : "#7f8390"}
        >
          {i.name}
        </PageNavLink>
      );
    } else {
      const i = item as string;
      itemsToRender.push(<PageNavText key={x}>{i}</PageNavText>);
    }

    ++x;
  });

  return <div>{itemsToRender}</div>;
};
