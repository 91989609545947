import React, { Component } from "react";
import { Intent } from "@blueprintjs/core";

import ReactTrello from "./TaskBoard/index.js";

import { computed } from "mobx";
import { inject, observer } from "mobx-react";

import { AppStore } from "../../../store/AppStore";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";

import { AddTaskListView } from "../../molecules/TaskManager/AddTaskListView";
import TaskListHeader from "../../molecules/TaskManager/TaskListHeader";
import TaskListFooter from "../../molecules/TaskManager/TaskListFooter";
import AddTaskListForm from "../../molecules/TaskManager/AddTaskListForm";
import AddTaskForm from "../../molecules/TaskManager/AddTaskForm";
import Task from "../../molecules/TaskManager/Task";

interface TaskBoardProps {
  onCardClick: (e: any, cardId: string) => void;
}
interface InjectedProps extends TaskBoardProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class TaskBoard extends Component<TaskBoardProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get store() {
    return this.injected.appStore.taskManagerStore;
  }

  @computed
  get board() {
    return this.store.currentBoard;
  }

  handleOnCardClick = (e:any, cardId: string, metaData: object, laneId: string) => {
    this.props.onCardClick(e, cardId);
  };
  handleLaneDragEnd = async (removedIndex: number, addedIndex: number, payload: any) => {
    try {
      await this.injected.appStore.taskManagerStore.moveTaskList(payload.id, addedIndex);

      // 업데이트된 내용을 반영
      await this.injected.appStore.taskManagerStore.fetchTaskBoard(this.board ? this.board.projectGroup : "");
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };
  handleDragEnd = async (cardId: string, sourceLaneId: string, targetLaneId: string, position: number, cardDetails: any) => {
    try {
      await this.injected.appStore.taskManagerStore.moveTask(cardId, sourceLaneId, targetLaneId, position);
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
      // 정상적인 경우, 서버 데이터를 재반영하지 않아도 무방. 오뷰 발생 시, 현재 서버 상황을 반영해야 함
      await this.injected.appStore.taskManagerStore.fetchTaskBoard(this.board ? this.board.projectGroup : "");
    }
  };
  handleClickOutside = () => {
    this.injected.appStore.taskManagerStore.clearMultiSelectTaskIds();
  }

  render() {
    const { searchResults } = this.store;

    return (
      <div style={{height: "100%"}} onClick={this.handleClickOutside}>
        <ReactTrello
          editable={true}
          draggable={this.injected.appStore.taskManagerStore.boardDraggable}
          canAddLanes={true}
          style={{
            height: "100%",
            backgroundColor: "white"
          }}
          components={{
            LaneHeader: TaskListHeader,
            NewLaneSection: AddTaskListView,
            NewLaneForm: AddTaskListForm,
            AddCardLink: TaskListFooter,
            NewCardForm: AddTaskForm,
            Card: Task
          }}
          onCardClick={this.handleOnCardClick}
          handleLaneDragEnd={this.handleLaneDragEnd}
          handleDragEnd={this.handleDragEnd}
          data={{
            lanes: searchResults
          }}
        />
      </div>
    );
  }
}

export default TaskBoard;