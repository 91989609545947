import React, { Component } from "react";
import { observer } from "mobx-react";
import { PMReport } from "../../../store/models/PMReport";
import { Button } from "@blueprintjs/core";
import { Link } from "react-router-dom";

interface SupportPMReportRowProps {
  report: PMReport;
  onPmNameClick: (pmName: string) => void;
}

@observer
class SupportPMReportRow extends Component<SupportPMReportRowProps> {
  handlePmNameClick = () => {
    this.props.onPmNameClick(this.props.report.pm);
  };

  render() {
    const { report } = this.props;

    return (
      <tr>
        <td style={{width: '100px'}}>{report.date}</td>
        <td style={{ whiteSpace: 'nowrap', width: '100px' }}>
          <a onClick={this.handlePmNameClick}>{report.pm}</a>
        </td>
        <td style={{ whiteSpace: 'pre-wrap' }}>{report.text}</td>
        <td style={{ width: '100px' }}>
          <Link to={`/pm-reports/${report.id}/support/edit`}>
            <Button
              text="수정하기"
              small={true}
              style={{ whiteSpace: "nowrap" }}
            />
          </Link>
        </td>
      </tr>
    );
  }
}

export default SupportPMReportRow;
