import React, { Component } from "react";
import { observable } from "mobx";
import { Button, Callout, Intent, Popover, PopoverInteractionKind } from "@blueprintjs/core";
import { observer } from "mobx-react";

interface Props {
  keyName: string;
  title: string;
  fieldTitle?: string;
  currentForm: any;
  setForm: (val: any) => void;
  explicitUpdateToggler?: boolean;
}

@observer
class TemporaryStorage extends Component<Props> {
  @observable tempFormExist = false;
  @observable tempFormTitle = "";
  @observable strCurrentForm= "";

  async componentDidMount() {
    this.tempFormExist = await this.initFromLocalStorage();
  }
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
    const propsStrCurrentForm = JSON.stringify(this.props.currentForm);
    let explicitUpdate = false;
    if(prevProps.explicitUpdateToggler != this.props.explicitUpdateToggler){
      explicitUpdate = true;
    }

    if (propsStrCurrentForm != this.strCurrentForm || explicitUpdate) {
      this.strCurrentForm = propsStrCurrentForm;
      this.saveToLocalStorage();
    }

  }

  render() {
    return (
      <div style={{ marginBottom: "20px" }}>
        <Callout title={"임시 저장소"}>
          {this.tempFormExist ?
            <div>
              <Popover key={"popover"}
                position={"bottom"}
                interactionKind={PopoverInteractionKind.HOVER}
                enforceFocus={false}
              >
                <Button intent={Intent.PRIMARY} style={{ marginRight: "10px" }} icon={"document-open"}
                        text={"임시 저장본 불러오기"} onClick={() => {
                  this.loadFromLocalStorage();
                }}/>
                <div style={{ padding: "10px" }}>
                  {this.props.fieldTitle} 제목: {this.tempFormTitle}
                </div>
              </Popover>

              <Button intent={Intent.DANGER} style={{ marginRight: "10px" }} icon={"delete"} text={"임시 저장본 삭제"}
                      onClick={() => {
                        this.clearLocalStorage();
                      }}/>
            </div> : <div>임시 저장소가 비어있습니다.</div>
          }
        </Callout>
      </div>
    );
  }


  async saveToLocalStorage() {
    localStorage.setItem(`current${this.props.keyName}Form`, this.strCurrentForm);
  }

  async initFromLocalStorage() {
    const val = localStorage.getItem(`current${this.props.keyName}Form`) || "";
    const parsedVal = val !== "" ? JSON.parse(val) : "";

    if (parsedVal !== "") {
      this.tempFormTitle = parsedVal[this.props.title] || '제목없음';
      localStorage.setItem(`stored${this.props.keyName}Form`, val);
      localStorage.removeItem(`current${this.props.keyName}Form`);
      return true;
    } else {
      const storedFormVal = localStorage.getItem(`stored${this.props.keyName}Form`) || "";
      const parsedStoredFormVal = storedFormVal !== "" ? JSON.parse(storedFormVal) : "";
      if (parsedStoredFormVal !== "") {
        this.tempFormTitle = parsedStoredFormVal[this.props.title]|| '제목없음';
        localStorage.removeItem(`current${this.props.keyName}Form`);
        return true;
      }
      return false;
    }

  }

  async loadFromLocalStorage() {
    const val = JSON.parse(localStorage.getItem(`stored${this.props.keyName}Form`) || "");
    this.props.setForm(val);
  }

  async clearLocalStorage() {
    localStorage.removeItem(`stored${this.props.keyName}Form`);
    localStorage.removeItem(`current${this.props.keyName}Form`);
    this.tempFormExist = false;
  }
}

export default TemporaryStorage;