import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { ItemPredicate, Select, IItemRendererProps } from "@blueprintjs/select";
import { computed } from "mobx";
import { MenuItem, Button } from "@blueprintjs/core";
import { highlightText } from "../../../utils/BlueprintSelectionHelpers";
import { PMReportMood as Mood } from "../../../store/forms/PMReportForm/PMReportForm";
import styled from "styled-components/macro";

export const MoodMap = {
  [Mood.Normal]: {
    color: "#00aa00",
    text: "NORMAL : 문제없음"
  },
  [Mood.NotGood]: {
    color: "#fcba03",
    text: "NOT GOOD : 매끄럽지는 않음"
  },
  [Mood.Risky]: {
    color: "red",
    text: "RISKY : 이슈발생 여지 있음"
  },
  [Mood.Bad]: {
    color: "#8c00ab",
    text: "BAD : 문제발생"
  },
  [Mood.Issue]: {
    color: "blue",
    text: "ISSUE : 이슈"
  },
  [Mood.Meeting]: {
    color: "black",
    text: "MEETING : 회의록"
  }
};

export const renderMood = (mood: Mood) => {
  const moodData = MoodMap[mood];

  return (
    <>
      <Circle color={moodData.color} /> {moodData.text}
    </>
  );
};

const Circle = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: ${p => p.color};
  display: inline-block;
  margin-right: 5px;
`;

const ItemSelect = Select.ofType<Mood>();

interface MoodSelectionProps {
  onChange: (projectGroup: Mood) => void;
  currentValue?: Mood;
}

interface InjectedProps extends MoodSelectionProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class MoodSelection extends Component<MoodSelectionProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get items() {
    return Object.values(Mood);
  }

  itemRenderer = (
    item: Mood,
    { handleClick, modifiers }: IItemRendererProps
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        key={MoodMap[item].color}
        active={modifiers.active}
        onClick={handleClick}
        text={renderMood(item)}
      />
    );
  };

  render() {
    const { onChange, currentValue } = this.props;

    return (
      <ItemSelect
        key={this.items.length}
        items={this.items}
        onItemSelect={onChange}
        itemRenderer={this.itemRenderer}
        popoverProps={{ minimal: true }}
        filterable={false}
      >
        <Button
          rightIcon="caret-down"
          fill={true}
          text={currentValue ? renderMood(currentValue) : "선택해주세요"}
        />
      </ItemSelect>
    );
  }
}

export default MoodSelection;
