import { types, flow, getRoot } from "mobx-state-tree";
import { ProjectModel, Project } from "./models/Project";
import axios from "axios";
import { ProjectListResponse, ProjectResponse } from "../types/project";

export const ProjectStoreModel = types
  .model("ProjectStore", {
    projects: types.array(ProjectModel),
    // For Pagination Query
    currentPage: types.optional(types.number, 1),
    pageGroup: types.optional(types.number, 1),
    pageSize: types.optional(types.number, 15),
    totalPages: types.optional(types.number, 1)
  })
  .views(self => ({
    get app() {
      return getRoot(self);
    }
  }))
  .views(self => ({
    get sortedProjects() {
      return self.projects.sort(
        (a, b) =>
          new Date(b.consultedDate).getTime() -
          new Date(a.consultedDate).getTime()
      );
    }
  }))
  .actions(self => ({
    setCurentPage(value: number) {
      self.currentPage = value;
    },
    setPageGroup(value: number) {
      self.pageGroup = value;
    }
  }))
  .actions(self => {
    const addProject = (project: Project) => {
      self.projects.push(project);
    };

    const fetchProjects = flow(function* (created_after?: string) {
      const params: {
        created_at__gt?: string
      } = {};

      if (created_after) {
        params.created_at__gt = created_after;
      }

      try {
        const { data } = yield axios.get(
          "/projects",
          {
            params
          }
        );

        if (Array.isArray(data)) {
          const projects: any = [];

          data.forEach((item: ProjectResponse) => {
            projects.push({
              projectId: item.project_id,
              createdAt: item.created_at,
              updatedAt: item.updated_at,
              name: item.name,
              consultedDate: item.consulted_date,
              consultant: item.consultant,
              consultantName: item.consultant_name,
              consultantEmail: item.consultant_email,

              clientName: item.client_name,
              clientEmail: item.client_email,
              clientPhone: item.client_phone,

              receptionId: item.reception_id
            });
          });

          self.projects.replace(projects);
        }
      } catch (e) {
        console.log("fetchProjects error", e);
        throw e;
      }
    });
    const fetchPageableProjects = flow(function* ({ created_after, search }: { created_after?: string, search?: string }) {
      const params: {
        created_at__gt?: string,
        search?: string,
        page: number,
        page_size: number,
      } = {
        "page": self.currentPage,
        "page_size": self.pageSize
      };

      if (created_after) {
        params.created_at__gt = created_after;
      }
      if (search) {
        params.search = search;
      }

      try {
        const { data }: { data: ProjectListResponse } = yield axios.get(
          "/projects",
          {
            params
          }
        );

        const projects: any = [];

        data.results.forEach((item: ProjectResponse) => {
          projects.push({
            projectId: item.project_id,
            createdAt: item.created_at,
            updatedAt: item.updated_at,
            name: item.name,
            consultedDate: item.consulted_date,
            consultant: item.consultant,
            consultantName: item.consultant_name,
            consultantEmail: item.consultant_email,

            clientName: item.client_name,
            clientEmail: item.client_email,
            clientPhone: item.client_phone,

            receptionId: item.reception_id
          });
        });
        self.totalPages = data.total_pages;
        self.projects.replace(projects);
      } catch (e) {
        console.log("fetchProjects error", e);
        throw e;
      }
    });
    const createProject = flow(function* () {
      try {
        yield axios.post("/projects", {});
      } catch (e) {
        console.log("createProject error", e);
        throw e;
      }
    });

    return {
      addProject,
      fetchProjects,
      fetchPageableProjects,
      createProject
    };
  });

type ProjectStoreType = typeof ProjectStoreModel.Type;

export interface ProjectStore extends ProjectStoreType {
}
