import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";

import { 
    Wrapper,
    StyledCard, 
    CardTitle, 
    CardContent, 
    Table, 
    TableRow, 
    NormalText,
    TruncatedText
} from "../../atoms/ParttimeComponents/ParttimeComponents";

import { Task} from "../../../store/models/Parttime";

interface ReferencePanelProps extends RouteComponentProps {
  task: Task;
}

interface InjectedProps extends ReferencePanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ReferencePanel extends Component<ReferencePanelProps> {

  get injected() {
    return this.props as InjectedProps;
  }

  render() {
    const { task } = this.props;

    return (
        <Wrapper>
            <StyledCard>
                <CardTitle>참고파일</CardTitle>
                <CardContent>
                <Table>
                    { (Array.isArray(task.request.referenceFiles) && task.request.referenceFiles.length) ? (task.request.referenceFiles.map(file => (
                        <TableRow>
                        <TruncatedText><a href={file.file} target="_blank">{ file.name }</a></TruncatedText>
                        <NormalText style={{display: "inline-block", margin: "0 10px"}}>{ file.description }</NormalText> 
                        </TableRow>
                    ))) : '-' }
                </Table>
                </CardContent>
            </StyledCard>

            <StyledCard>
                <CardTitle>참고링크</CardTitle>
                <CardContent>
                <Table>
                    { (Array.isArray(task.request.referenceLinks) && task.request.referenceLinks.length) ? (task.request.referenceLinks.map(link => (
                        <TableRow>
                        <TruncatedText><a>{ link.url }</a></TruncatedText>
                        <NormalText style={{display: "inline-block", margin: "0 10px"}}>{ link.description }</NormalText> 
                        </TableRow>
                    ))) : '-' }
                </Table>
                </CardContent>
            </StyledCard>

            <StyledCard>
                <CardTitle>서비스 접속정보</CardTitle>
                <CardContent>
                <>{ task.request.accessInfo ? task.request.accessInfo : '-'}</>
                </CardContent>
            </StyledCard>
        </Wrapper>
    );
  }
}

export default ReferencePanel;
