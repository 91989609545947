import React, { Component } from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { ItemPredicate, Select, IItemRendererProps } from "@blueprintjs/select";
import { ClientContractRequest } from "../../../store/models/ContractRequest";
import { computed } from "mobx";
import { MenuItem, Button, Alignment } from "@blueprintjs/core";
import { highlightText } from "../../../utils/BlueprintSelectionHelpers";

const Wrapper = styled.div`
  .bp3-button-text {
    text-overflow: ellipsis;
  }
`;

export const filterItem: ItemPredicate<ClientContractRequest> = (
  query,
  item,
  _index,
  exactMatch
) => {
  const normalizedTitle = item.contractName.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
    return normalizedTitle === normalizedQuery;
  } else {
    return `${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
  }
};

const ItemSelect = Select.ofType<ClientContractRequest>();

interface ClientContractRequestSelectionProps {
  onChange: (clientContractRequest: ClientContractRequest) => void;
  currentValue?: ClientContractRequest;
  items?: ClientContractRequest[]; // items 를 지정하면 전체목록을 불러오지 않고 해당 컬렉션 내에서만 검색
  blockFetchingData?: boolean;
}

interface InjectedProps extends ClientContractRequestSelectionProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ClientContractRequestSelection extends Component<ClientContractRequestSelectionProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get items() {
    return (
      this.props.items || this.injected.appStore.contractStore.clientContracts
    );
  }

  async componentDidMount() {
    if (!this.props.blockFetchingData) {
      if (this.props.currentValue) {
        await this.injected.appStore.contractStore.fetchClientContracts({
          status: '',
          searchText: '',
          orderingList:[]
        });
      } else {
        await this.injected.appStore.contractStore.fetchUnusedClientContracts();
      }
    }
  }

  itemRenderer = (
    item: ClientContractRequest,
    { handleClick, query, modifiers }: IItemRendererProps
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    const active = this.props.currentValue && item.contractId == this.props.currentValue.contractId;
    return (
      <MenuItem
        key={item.contractId}
        // active={modifiers.active}
        active={active}
        text={highlightText(`${item.contractId} - ${item.contractName}`, query)}
        onClick={handleClick}
      />
    );
  };

  render() {
    const { onChange, currentValue } = this.props;
    console.log("currentValue",currentValue);
    return (
      <Wrapper>
        <ItemSelect
          key={this.items.length}
          items={this.items}
          onItemSelect={onChange}
          itemRenderer={this.itemRenderer}
          itemPredicate={filterItem}
          popoverProps={{ minimal: true }}
        >
          <Button
            rightIcon="caret-down"
            fill={true}
            text={currentValue ? `${currentValue.contractId} - ${currentValue.contractName}` : "선택해주세요"}
            alignText={Alignment.LEFT}
            style={{
              textOverflow: "ellipsis"
            }}
          />
        </ItemSelect>
      </Wrapper>
    );
  }
}

export default ClientContractRequestSelection;
