import React, { Component } from "react";
import styled from "styled-components/macro";
// import SideMenu from "../../organisms/SideMenu/SideMenu";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { withRouter, RouteComponentProps } from "react-router";
// import TopBar from "../../organisms/TopBar/TopBar";
import NewSideBar from "../../organisms/NewSideBar/NewSideBar";

const Wrapper = styled.div`
  display: flex;
`;

const Content = styled.div`
  padding: 20px;
  padding-left: 55px;
  width: calc(100% - 250px);
`;

interface MainTemplateProps extends RouteComponentProps {
  collapsed: boolean;
}

interface InjectedProps extends MainTemplateProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class MainTemplate extends Component<MainTemplateProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  handleLogout = () => {
    this.injected.appStore.userStore.logout();

    alert("로그아웃 되었습니다.");
    this.props.history.push("/");
  };

  render() {
    return (
      <Wrapper>
        {/* <TopBar onLogout={this.handleLogout} /> */}
        {/* <SideMenu /> */}
        <NewSideBar collapsed={this.props.collapsed} />
        <Content
          style={
            this.props.collapsed ? { padding:'0px', width: 'calc(100% - 70px)'} : {width: 'calc(100% - 250px)'}
          }>
            {this.props.children}
        </Content>
      </Wrapper>
    );
  }
}

export default withRouter(MainTemplate);
