import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { Button, Intent } from "@blueprintjs/core";
import moment from "moment";
// import { AppToaster } from "../../organisms/AppToaster/AppToaster";

import { RufreeCalculate } from "../../../store/models/RufreeCalculate";

import RufreeCalculateConfirmPopup from "../CalculatePopup/RufreeCalculateConfirmPopup";


const TR = styled.tr`
`;
const TD = styled.td`
  color: #161d2e;
`;

const SprintSpan = styled.span`
  font-weight: bold;
  padding-right: 15px;
`

const RightAlignTD = styled(TD)`
  text-align: right !important;
`

const HighlightStatus = styled.span`
  color: red;
`

interface RufreeCalculateRowProps {
  calculate: RufreeCalculate;
  editable: boolean;
  onSearch: () => void;
  onClickSend: (phone: string) => void;
}

interface InjectedProps extends RufreeCalculateRowProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class RufreeCalculateRow extends Component<RufreeCalculateRowProps> {
  @observable isConfirmOpen = false;

  get injected() {
    return this.props as InjectedProps;
  }

  handleConfirmOpen = () => {
    this.isConfirmOpen = true;
  };

  handleConfirmClose = () => {
    this.isConfirmOpen = false;
    this.props.onSearch();
  };

  handleSendOpen = (e: any) => {
    this.props.onClickSend(this.props.calculate.rufreePhone);
    // this.notificationStore.setFormMode(FormMode.Read);
    // this.notificationStore.setOpenPopup(true);
  
    // e.stopPropagation();
  }

  // handleCancleConfirm = async () => {
  //   try {
  //     await this.props.sprint.cancleConfirm();

  //     AppToaster.show({
  //       message: "종료 취소 처리 되었습니다.",
  //       intent: Intent.SUCCESS
  //     });
  //   } catch (e) {
  //     const error = JSON.stringify(e.response.data);

  //     AppToaster.show({
  //       message: "오류: " + error,
  //       intent: Intent.DANGER
  //     });
  //   }
  // };

  handleCancel = () => {};
  handlePay = () => {};

  render() {
    const { calculate, editable } = this.props;
    const { sprint } = calculate;
    let payment = calculate.parttimeTask ? calculate.parttimeTask.totalAmount : calculate.payment;

    return (
      <>
         <TR key={calculate.id}>
          <TD>{'RSP'+calculate.id}</TD>
          <TD>
            <Link to={`/project-groups/${calculate.projectGroup}`} target="_blank">
              {calculate.projectGroup}
            </Link>
          </TD>
          {
            calculate.calculateType === 'sprint' ?
              <TD>{calculate.clientContract}</TD>
              : calculate.parttimeTask ?
                <TD>
                  <Link to={`/parttime/requests/${calculate.parttimeTask.request.id}/tasks/${calculate.parttimeTask.id}`} target="_blank">
                    {calculate.parttimeTask && calculate.parttimeTask.id}
                  </Link>
                </TD> : <TD />
          }
          <TD>{ calculate.parttimeTask ? calculate.parttimeTask.clientName : calculate.clientName}</TD>
          <TD>{`${calculate.rufreeName} ${ sprint ? `(S${calculate.sprintSequentialId})` : '' } `}</TD>
          <TD>{calculate.managerName}</TD>
          <RightAlignTD>{calculate.expectedPayment ? calculate.expectedPayment.toLocaleString("en-US") : "0"}</RightAlignTD>
          <TD>{calculate.tax ? calculate.tax+'%' : ""}</TD>
          <RightAlignTD>{payment ? payment.toLocaleString("en-US") : ""}</RightAlignTD>
          <TD>{calculate.createdAt ? moment(calculate.createdAt).format('YYYY-MM-DD') : ''}</TD>
          <TD>{calculate.datePayment ? calculate.datePayment : "지급필요"}</TD>
          <TD>{ calculate.parttimeTask ? calculate.parttimeTask.paymentRemark : calculate.pmComment}</TD>
          <TD
            onClick={this.handleConfirmOpen}
          >
            {calculate.comment}
          </TD>
          {editable ? (
            <TD style={{'width': '150px'}}>
              <Button
                small={true}
                text="문자발송"
                onClick={this.handleSendOpen}
                intent={Intent.PRIMARY}
                style={{marginRight: '3px'}} />
              {calculate.datePayment ? (
                <Button text="수정하기" small={true} onClick={this.handleConfirmOpen} />
                ):(
                <Button text="지급하기" small={true} onClick={this.handleConfirmOpen} intent={Intent.PRIMARY} />
              )}
            </TD>
          ) : (<TD />)}
        </TR>

        <RufreeCalculateConfirmPopup
          calculate={calculate}
          onClose={this.handleConfirmClose}
          isOpened={this.isConfirmOpen} />

      </>
    );
  }
}

export default RufreeCalculateRow;
