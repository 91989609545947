import { types } from "mobx-state-tree";

export const TaskProjectGroupFormModel = types
  .model("TaskProjectGroupForm", {
    id: types.optional(types.number, -1),
    groupId: types.optional(types.string, ""),
    name: types.optional(types.string, "")
  })
  .actions(self => ({
    setId(value: number) {
      self.id = value;
    },
    setGroupId(value: string) {
      self.groupId = value;
    },
    setName(value: string) {
      self.name = value;
    }
  }));

type TaskProjectGroupFormType = typeof TaskProjectGroupFormModel.Type;
export interface TaskProjectGroupForm extends TaskProjectGroupFormType {}
