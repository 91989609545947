import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import {
  Dialog,
  Classes,
  Button,
  Intent,
  FormGroup,
  HTMLSelect,
  IOptionProps
} from "@blueprintjs/core";
import { ScheduleSprintForm } from "../../../store/forms/ScheduleForm/ScheduleSprintForm";
import { DateRangePicker, DateRange } from "@blueprintjs/datetime";
import moment from "moment";
import { observable, runInAction } from "mobx";
import { ProjectGroupManager } from "../../../store/models/ProjectGroupManager";
import { ChangeEvent } from "cleave.js/react/props";

const CalendarWrapper = styled.div``;

const ManagerWrapper = styled.div`
  margin-top: 20px;
`;

interface ScheduleSprintDialogProps {
  title: string;
  priviousEndDate?: string;
  selectedSprint: ScheduleSprintForm | null;
  onClose: () => void;
  updateSprintNeighborDates: (sprint: ScheduleSprintForm) => void;
  managers: ProjectGroupManager[];
}

interface InjectedProps extends ScheduleSprintDialogProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ScheduleSprintDialog extends Component<ScheduleSprintDialogProps> {
  @observable dateStart = "";
  @observable dateEnd = "";
  @observable manager = "";

  get injected() {
    return this.props as InjectedProps;
  }

  handleOpening = () => {
    const { priviousEndDate, selectedSprint } = this.props;
    const { username, email, isPM } = this.injected.appStore.userStore;

    if(isPM) {
      this.manager = username;
    }

    if(email === 'partner@seeso.kr') {
      this.manager = '-';
    }

    if (selectedSprint) {
      this.dateStart = selectedSprint.dateStart;
      this.dateEnd = selectedSprint.dateEnd;
      this.manager = selectedSprint.manager !== '-' ? selectedSprint.manager : this.manager;

      if (priviousEndDate && !this.dateStart) {
        const baseDate = moment(priviousEndDate);
        this.dateStart = baseDate.add(1, 'days').format('YYYY-MM-DD');
        this.dateEnd = baseDate.add(13, 'days').format('YYYY-MM-DD');
      }
    }
  };

  handleDateChange = (selectedDates: DateRange) => {
    const [newDateStart, newDateEnd] = selectedDates;

    runInAction(() => {
      this.dateStart = newDateStart
        ? moment(newDateStart).format("YYYY-MM-DD")
        : "";
      this.dateEnd = newDateEnd ? moment(newDateEnd).format("YYYY-MM-DD") : "";
    });
  };

  handleManagerChange = (e: ChangeEvent<HTMLSelectElement>) => {
    this.manager = e.currentTarget.value;
  };

  handleRemove = () => {
    const { selectedSprint, onClose } = this.props;

    if (selectedSprint) {
      selectedSprint.setValid(false);
      selectedSprint.setDateStart('');
      selectedSprint.setDateEnd('');
      selectedSprint.setManager('');
      onClose();
    }
  };

  handleSave = () => {
    const { selectedSprint, onClose, updateSprintNeighborDates } = this.props;

    if (selectedSprint) {
      selectedSprint.setDateStart(this.dateStart);
      selectedSprint.setDateEnd(this.dateEnd);
      selectedSprint.setManager(this.manager);

      if (this.dateStart && this.dateEnd) {
        selectedSprint.setValid(true);
        updateSprintNeighborDates(selectedSprint);
      }

      onClose();
    }
  };

  render() {
    const { title, selectedSprint: sprint, onClose } = this.props;

    const managerOptions: IOptionProps[] = [
      {label: '없음', value: '-'},
    ].concat(this.props.managers.map(manager => (
      {label: manager.name, value: manager.name}
    )))

    return (
      <Dialog
        onOpening={this.handleOpening}
        onClose={onClose}
        isOpen={sprint !== null}
        title={title}
        usePortal={true}
        autoFocus={true}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        enforceFocus={true}
      >
        <div className={Classes.DIALOG_BODY}>
          {sprint && (
            <CalendarWrapper>
              <DateRangePicker
                value={[
                  this.dateStart ? moment(this.dateStart).toDate() : undefined,
                  this.dateEnd ? moment(this.dateEnd).toDate() : undefined
                ]}
                onChange={this.handleDateChange}
                shortcuts={false}
                // singleMonthOnly={true}
                reverseMonthAndYearMenus={true}
                maxDate={new Date("2050-01-01")}
                minDate={new Date("2000-01-01")}
              />
            </CalendarWrapper>
          )}
          <ManagerWrapper>
            <FormGroup label={<strong>매니저</strong>}>
              <HTMLSelect
                value={this.manager}
                onChange={this.handleManagerChange}
                options={managerOptions}
              />
              {
                // <option value="">없음</option>
                // {this.props.managers.map((manager, index) => (
                //   <option key={index} value={manager.name}>
                //     {manager.name}
                //   </option>
                // ))}
              }
            </FormGroup>
          </ManagerWrapper>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button text="취소" onClick={onClose} />
            <Button
              text="삭제"
              intent={Intent.DANGER}
              onClick={this.handleRemove}
            />
            <Button
              text="저장"
              intent={Intent.SUCCESS}
              onClick={this.handleSave}
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

export default ScheduleSprintDialog;