import React, { Component, ChangeEvent, FormEvent } from "react";
import { computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";

import styled from "styled-components/macro";
import { Dialog, Classes, FormGroup, FileInput, Intent, InputGroup, NumericInput, TextArea } from "@blueprintjs/core";
import { PrimaryButton, SecondaryButton, HelpText } from "../../atoms/ParttimeComponents/ParttimeComponents";

import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import OutcomeFormRow from "../../molecules/ParttimeRow/OutcomeFormRow";

import { Task } from "../../../store/models/Parttime";
import { TaskOutcomeFormModel } from "../../../store/forms/ParttimeForm/TaskOutcomeForm";

interface TaskCompleteDialogProps {
    task: Task;
    isOpen: boolean;
    onClose: () => void;
}

interface InjectedProps extends TaskCompleteDialogProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class TaskCompleteDialog extends Component<TaskCompleteDialogProps> {
    @observable onSubmit: boolean = false;
    @observable totalWorkingTime: number | any = null;

    get injected() {
        return this.props as InjectedProps;
    }

    @computed
    get formStore() {
        return this.injected.appStore.parttimeTaskCompleteFormStore
    }

    @computed
    get currentForm() {
        return this.formStore.currentForm;
    }

    initForm = async () => {
        await this.formStore.initForm();
    };

    handleOpening = () => {};

    handleSubmit = async () => {
        const { task, onClose } = this.props;

        try {
            this.onSubmit = true;
            await this.formStore.completeTask(task.request.id, task.id);
            this.onSubmit = false;
            onClose();
            await this.injected.appStore.parttimeTaskStore.fetchTaskById(task.request.id, task.id);
            AppToaster.show({
                message: "TASK가 완료 되었습니다.",
                intent: Intent.SUCCESS
            });
        
            } catch (e) {
            const error = JSON.stringify(e.response.data);
            AppToaster.show({
                message: "오류: " + error,
                intent: Intent.DANGER
            });
            this.onSubmit = false;
        }
      };
  
    handleTotalAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.currentForm.setTotalAmount(e.target.value);
    };

    handlePaymentRemarkChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        this.currentForm.setPaymentRemark(e.target.value);
    };

    handleTotalWorkingTimeChange = (value: number) => {
        if(value > 99){
            alert('최대 99시간까지 입력 가능합니다.');
            this.totalWorkingTime = null;
            return;
        }

        this.totalWorkingTime = value;
        this.currentForm.setTotalAmount((this.totalWorkingTime * 30000).toString());
    }

    async componentDidMount() {
        this.initForm();
    }

    render() {
        const { isOpen, onClose, task } = this.props;
    
        const title = `TASK 완료`;

        return (
            <Dialog
                onOpening={this.handleOpening}
                isOpen={isOpen}
                onClose={onClose}
                usePortal={true}
                autoFocus={true}
                enforceFocus={true}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                title={title}
            >
            <div className={Classes.DIALOG_BODY}>
                { (task.type === "OVERALL") ? (
                    <FormGroup label={<strong>총비용</strong>} labelInfo="*" disabled={this.onSubmit}>
                        <InputGroup
                            placeholder="총비용을 입력해주세요."
                            value={this.currentForm.totalAmount}
                            onChange={this.handleTotalAmountChange}
                            maxLength={30}
                            disabled={this.onSubmit}
                        />
                    </FormGroup>
                    ) : (
                    <FormGroup label={<strong>총 작업시간</strong>} labelInfo="*" disabled={this.onSubmit}>
                        <NumericInput
                            placeholder="00"
                            min={0}
                            max={99}
                            value={this.totalWorkingTime ? this.totalWorkingTime : undefined}
                            onValueChange={this.handleTotalWorkingTimeChange}
                            disabled={this.onSubmit}
                        /> 
                        <HelpText style={{display: "inline"}}>
                            * 총비용: {this.totalWorkingTime * 30000}원 (시간당 3만원)
                        </HelpText>
                    </FormGroup>
                 )}

                 <FormGroup label={<strong>정산비고</strong>} style={{marginTop: "30px"}} disabled={this.onSubmit}>
                     <TextArea
                         rows={5}
                         defaultValue={`요청 클라이언트:
비용: 
클라이언트 세금계산서 발행 일정: 
충전금 사용유무: 
==== 
시소입금의 경우, '시소 PM 재량으로 입금 요청 기재' 요청
                         `}
                         onChange={this.handlePaymentRemarkChange}
                         style={{ width: "100%" }}
                         maxLength={1000}
                         disabled={this.onSubmit}
                     />
                 </FormGroup>

                <FormGroup label={<strong>관련 파일</strong>} style={{marginTop: "30px"}} disabled={this.onSubmit}>
                    <FileInput 
                        text="파일 추가" 
                        fill={true} 
                        disabled={this.onSubmit}
                        onInputChange={(e: FormEvent<HTMLInputElement>) => {
                            if (e.currentTarget.files) {
                                const file = TaskOutcomeFormModel.create();
                                file.setFile(e.currentTarget.files[0], e.currentTarget.files[0].name);
                                this.currentForm.addOutcomes(file);
                        }
                    }} />
                    <OutcomeBoard>
                        {Array.isArray(this.currentForm.outcomes) && this.currentForm.outcomes.length 
                        ? (<OutcomeWrapper>
                                {this.currentForm.outcomes.map((file, index) => (
                                    <OutcomeFormRow
                                        key={index}
                                        file={file}
                                        onRemove={this.currentForm.removeOutcomes}
                                    ></OutcomeFormRow>
                                ))}
                            </OutcomeWrapper>)
                        : (
                            <DefaultTextWrapper>
                                <DefaultText>
                                TASK 완료와 관련된 파일 또는 이미지
                                </DefaultText>
                            </DefaultTextWrapper>
                        )}
                    </OutcomeBoard>
                </FormGroup>
            </div>
    
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <SecondaryButton text="취소" onClick={onClose} disabled={this.onSubmit}/>
                <PrimaryButton text={"완료하기"} onClick={this.handleSubmit} disabled={this.onSubmit}/>
              </div>
            </div>
          </Dialog>
        );
    }
}

export default TaskCompleteDialog;

const OutcomeBoard = styled.div`
    background-color: #f3f3f3;
    height: 150px;
    margin-top: 5px;
    overflow: overlay;
`;
const OutcomeWrapper = styled.div`
    padding: 5px 0;
`;

const DefaultTextWrapper = styled.div`
    display: flex;
    min-height: 140px;
    flex-direction: column;
    justify-content: center; 
    align-items: center;  
`;

const DefaultText = styled.div`
    text-align: center;
    color: #9e9e9e;
`;