import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { Sprint, SprintReview } from "../../../store/models/Sprint";
import { UserStore } from "../../../store/UserStore";
import { AppToaster } from "../../organisms/AppToaster/AppToaster";
import { ChangeEvent } from "cleave.js/react/props";
import { Intent, Dialog, Classes, TextArea, Button, NumericInput, FormGroup } from "@blueprintjs/core";
import { observable } from "mobx";
import { FormMode } from "../../../types/formMode";

const FormGroupLabel = styled.div`
  font-weight: bold;
  width: 90px;
`;
const FormGroupValue = styled.div`
  line-height: 30px;
`;
const StyledTextArea = styled(TextArea)`
  width: 100%;
`;

interface SprintReviewPopupProps {
  sprint: Sprint;
  review?: SprintReview;
  user: UserStore;
  mode: FormMode;
  isOpened: boolean;
  onClose: () => void;
}

interface InjectedProps extends SprintReviewPopupProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class SprintReviewPopup extends Component<SprintReviewPopupProps> {
  @observable comment = "";

  get injected() {
    return this.props as InjectedProps;
  }

  handleOpening = () => {};

  handleCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.comment = e.target.value;
  };

  handleSave = async () => {
    const { comment } = this;
    const { mode, sprint, review, user, onClose } = this.props;
    let message = '저장되었습니다.';

    try {
      if(mode == FormMode.Edit && review) {
        await sprint.updateReview(review.id, comment);
        message = '수정되었습니다.';
      } else {
        await sprint.createReview(user.userId, comment);
      }

      AppToaster.show({
        message: message,
        intent: Intent.SUCCESS
      });

      onClose();
    } catch (e) {
      const error = JSON.stringify(e.response.data);

      AppToaster.show({
        message: "오류: " + error,
        intent: Intent.DANGER
      });
    }
  };

  componentDidMount() {
    const { review } = this.props;
    if(review) {
      this.comment = review.comment;
    }
  }

  render() {
    const reviewPlaceholder = '1. 클라이언트와의 업무 커뮤니케이션이 원할한 편인지 (상,중,하) :\n\n'
      + '2. 다른 동료 알유프리와의 협업이 있을때, 업무 커뮤니케이션이 원할한 편이지 (상,중,하) :\n\n'
      + '3. 스프린트내에서 클라이언트의 추가 업무가 있을때 어떤 측면을 고려하는 편인지 (비용, 일정, 관계, 등):\n\n'
      + '4. 이번 스프린트에 주 20시간 이상 업무를 한것으로 판단되는지 :\n\n'
      + '5. 트렐로 업무카드 생성 및 이동, 일정 관리를 잘 하고 있다고 판단되는지 :\n\n';
    const { isOpened, onClose, sprint } = this.props;

    const title = `${sprint.rufreeName} ${sprint.role} ${sprint.sprint ? "S" + sprint.sprint : "PRE"} 스프린트 리뷰`;

    return (
      <Dialog
        onOpening={this.handleOpening}
        isOpen={isOpened}
        usePortal={true}
        autoFocus={true}
        enforceFocus={true}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        title={title}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup label={<FormGroupLabel>프로젝트그룹</FormGroupLabel>} inline={true}>
            <FormGroupValue>{sprint.projectGroupId}</FormGroupValue>
          </FormGroup>
          <FormGroup label={<FormGroupLabel>프로젝트그룹명</FormGroupLabel>} inline={true}>
            <FormGroupValue>{sprint.projectGroupId}</FormGroupValue>
          </FormGroup>
          <FormGroup label={<FormGroupLabel>알유프리</FormGroupLabel>} inline={true}>
            <FormGroupValue>{sprint.rufreeName}({sprint.role})</FormGroupValue>
          </FormGroup>
          <FormGroup label={<FormGroupLabel>스프린트</FormGroupLabel>} inline={true}>
            <FormGroupValue>S{sprint.sprint}</FormGroupValue>
          </FormGroup>
          <FormGroup label={<FormGroupLabel>스프린트기간</FormGroupLabel>} inline={true}>
            <FormGroupValue>{sprint.dateStart}~{sprint.dateEnd}</FormGroupValue>
          </FormGroup>

          <FormGroup label={<FormGroupLabel>리뷰</FormGroupLabel>}>
            <StyledTextArea
              rows={15}
              value={this.comment ? this.comment : reviewPlaceholder}
              onChange={this.handleCommentChange}
            />
          </FormGroup>
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button text="취소" onClick={onClose} />
            <Button
              text="저장"
              onClick={this.handleSave}
              intent={Intent.PRIMARY}
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

export default SprintReviewPopup;
