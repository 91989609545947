import { types, flow } from "mobx-state-tree";
import axios from "axios";
  
export const ReferenceFileFormModel = types
    .model("ReferenceFile", {
        id: types.maybeNull(types.number),
        name: types.optional(types.string, ""),
        format: types.optional(types.string, ""),
        description: types.optional(types.string, "")
    })
    .volatile(self => ({
        file: null as File | null,
    }))
    .actions(self => ({
        setId(value: number) {
          self.id = value;
        },
        setDescription(value: string) {
            self.description = value;
        },
        setFile(file: File, name: string) {
            self.file = file;
            self.name = name;
        }
    })); 

type ReferenceFileFormType = typeof ReferenceFileFormModel.Type;
export interface ReferenceFileForm extends ReferenceFileFormType {}


export const ReferenceFileFormStoreModel = types
.model("ReferenceFileFormStore", {
  currentForm: types.optional(ReferenceFileFormModel, {})
})
.actions(self => ({
  initForm() {
    self.currentForm = ReferenceFileFormModel.create();
  }
}))
.actions(self => {
  const edit = flow(function*(requestId: number) {
    try {
      const form = self.currentForm;

      yield axios.patch(`/parttime/requests/${requestId}/reference-files/${form.id}`, {
        description: form.description
      });
    } catch (e) {
      console.log("edit error", e);
      throw e;
    }
  });

  const deleteReferenceFile= flow(function*(requestId: number) {
    try {
      const form = self.currentForm;

      yield axios.delete(`/parttime/requests/${requestId}/reference-files/${form.id}`);
    } catch (e) {
      console.log("edit error", e);
      throw e;
    }
  });

  return {
    edit,
    deleteReferenceFile
  };
});

type ReferenceFileFormStoreType = typeof ReferenceFileFormStoreModel.Type;
export interface ReferenceFileFormStore extends ReferenceFileFormStoreType {}