import { types, flow, IMSTArray, ISimpleType } from "mobx-state-tree";
import axios from "axios";
import {
  InspectionSelectionFormModel,
  InspectionSelectionForm
} from "../ProjectGroupForm/InspectionSelectionForm";

export const ClientContractFormModel = types
  .model("ClientContractForm", {
    id: types.optional(types.number, -1),
    contractId: types.optional(types.string, ""),
    contractName: types.optional(types.string, ""),
    address: types.optional(types.string, ""),
    representativeName: types.optional(types.string, ""),
    contractorName: types.optional(types.string, ""),
    contractorEmail: types.optional(types.string, ""),
    contractorPhone: types.optional(types.string, ""),
    taxInvoiceManagerName: types.optional(types.string, ""),
    taxInvoiceManagerEmail: types.optional(types.string, ""),
    taxInvoiceManagerContact: types.optional(types.string, ""),
    nda: types.optional(types.boolean, false),
    contractDate: types.optional(types.string, ""),
    contractFeeRate: types.optional(types.string, ""),
    contractRange: types.optional(types.string, ""),
    specialContract: types.optional(types.string, ""),
    comment: types.optional(types.string, ""),
    status: types.optional(types.string, ""),
    client: types.optional(types.string, ""),
    inspections: types.array(InspectionSelectionFormModel),
    pmUser: types.optional(types.number, -1),
    contractorNumber: types.optional(types.string, ""),
    contractorIdentityNumber: types.optional(types.string, ""),
    contractType: types.optional(types.string,""),
  })
  .views(self => ({
  }))
  .actions(self => ({
    setContractType(value:string){
      self.contractType = value;
    },
    setContractName(value: string) {
      self.contractName = value;
    },
    setAddress(value: string) {
      self.address = value;
    },
    setRepresentativeName(value: string) {
      self.representativeName = value;
    },
    setContractorName(value: string) {
      self.contractorName = value;
    },
    setContractorEmail(value: string) {
      self.contractorEmail = value;
    },
    setContractorPhone(value: string) {
      self.contractorPhone = value;
    },
    setTaxInvoiceManagerName(value: string) {
      self.taxInvoiceManagerName = value;
    },
    setTaxInvoiceManagerEmail(value: string) {
      self.taxInvoiceManagerEmail = value;
    },
    setTaxInvoiceManagerContact(value: string) {
      self.taxInvoiceManagerContact = value;
    },
    setNda(value: boolean) {
      self.nda = value;
    },
    setContractDate(value: string) {
      self.contractDate = value;
    },
    setContractFeeRate(value: string) {
      self.contractFeeRate = value;
    },
    setContractRange(value: string) {
      self.contractRange = value;
    },
    setSpecialContract(value: string) {
      self.specialContract = value;
    },
    setComment(value: string) {
      self.comment = value;
    },
    setClient(value: string) {
      self.client = value;
    },
    setPmUser(value: number) {
      self.pmUser = value;
    },
    setContractorNumber(value: string) {
      self.contractorNumber = value;
    },
    setContractorIdentityNumber(value: string) {
      self.contractorIdentityNumber = value;
    },

    addInspectionSelection() {
      self.inspections.push(InspectionSelectionFormModel.create());
    },
    removeInspectionSelection(inspectionSelection: InspectionSelectionForm) {
      self.inspections.remove(inspectionSelection);
    }
  }))
  // .actions(self => {
  //   const requestClientContract = flow(function*() {
  //     try {
  //       yield axios.put(
  //         `/clientContract/${self.id}/request/`
  //       );
  //     } catch (e) {
  //       console.log("ClientContract Request error", e);
  //       throw e;
  //     }
  //   });

  //   return {
  //     requestClientContract
  //   };
  // });

type ClientContractFormType = typeof ClientContractFormModel.Type;
export interface ClientContractForm extends ClientContractFormType {}
