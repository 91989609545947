import React, { Component } from "react";
import styled, { css } from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { Button, Intent, Tooltip, Position } from "@blueprintjs/core";
import { Schedule } from "../../../store/models/Schedule";
import moment from "moment";
import { computed, observable } from "mobx";
import { Rufree } from "../../../store/models/Rufree";
import RufreeInfoPopup from "../RufreeInfoPopup/RufreeInfoPopup";
import { ProjectGroup } from "../../../store/models/ProjectGroup";
import { RufreeMatching } from "../../../store/models/RufreeMatching";
import { RufreeRole } from "../../../store/models/RufreeRole";
import { Link } from "react-router-dom";
const BLOCK_WIDTH = 15;

const Wrapper = styled.div`
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
`;

const Section = styled.section`
  margin-bottom: 40px;
  display: flex;
  min-height: 130px;
`;

const ScheduleRow = styled.div`
  display: flex;
  border: none;
  border-radius: 2px;
  color: #a220ff;

  & > * {
    background-color: #a220ff;
    /* background-color: #5834ff; */
    font-size: 13px;
    color: #ffffff;
    height: 40px;
  }
`;

const ResourceRow = styled.div`
  display: flex;
  height: 46px;
`;

const ScheduleColumn = styled.div`
  width: 90px;
  display: inline-flex;
  align-items: center;

  padding: 5px;
  flex-shrink: 0;
  /* border-bottom: 1px solid #c8c8c8; */
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  a {
    color: #7f8390;
  }
`;

const SprintDateColumn = styled.div`
  width: ${BLOCK_WIDTH}px;
  flex-shrink: 0;
  padding: 8px 0px;

  display: inline-flex;
  align-items: center;
  /* border-bottom: 1px solid #c8c8c8; */
`;

const SprintDateFullColumn = styled.div`
  width: 800px;
  flex-shrink: 0;
  padding: 8px 0px;

  display: inline-flex;
  align-items: center;
  /* border-bottom: 1px solid #c8c8c8; */
`;

const SprintColumn = styled.div`
  width: ${BLOCK_WIDTH}px;
  flex-shrink: 0;
  padding: 6px 0px;
  /* border-bottom: 1px solid #c8c8c8; */
  white-space: nowrap;
`;

const SprintBar = styled.div<{
  duration: number;
  closeLeft: boolean;
  closeRight: boolean;
}>`
  background-color: darkcyan;
  background: #a220ff;
  height: 37px;
  padding: 8px;
  text-align: center;
  color: white;
  font-size: 13px;
  font-weight: bold;
  border: 1px solid white;
  border-radius: 20px;

  ${p =>
    p.closeLeft &&
    css`
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    `}

  ${p =>
    p.closeRight &&
    css`
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    `}

  width: ${p => p.duration * BLOCK_WIDTH}px;
`;

const SprintTip = styled.div`
  text-align: center;
  font-weight: bold;
`;

const ScrollArea = styled.div`
  ::-webkit-scrollbar {
    display: none;
  }

  overflow-x: auto;
  margin-left: -34px;
  padding-left: 34px;
  padding-bottom: 8px;

  & > * {
    white-space: nowrap;
  }
`;

const MatchingStatus = styled.span`
  color: #00aa00;
`;

interface ProjectGroupScheduleProps {
  projectGroup: ProjectGroup;
  rufreeRolesInInspection: RufreeRole[];
  schedule: Schedule;
  onHighlightRufree: ({
    inspectionId,
    rufreeRole
  }: {
    inspectionId: string;
    rufreeRole: string;
  }) => void;
}

interface InjectedProps extends ProjectGroupScheduleProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ProjectGroupSchedule extends Component<ProjectGroupScheduleProps> {
  @observable selectedRufree: Rufree | null = null;

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get rufreeStore() {
    return this.injected.appStore.rufreeStore;
  }

  @computed
  get duration() {
    const { schedule } = this.props;

    if (schedule.dateStart && schedule.dateEnd) {
      const startDate = moment(schedule.dateStart);
      const endDate = moment(schedule.dateEnd);

      const diff = moment.duration(endDate.diff(startDate)).asDays() + 1;

      return Math.max(diff, 0);
    } else {
      return 0;
    }
  }

  renderDates = () => {
    const { schedule } = this.props;
    const startDate = moment(schedule.dateStart);

    if(this.duration > 0) {
      return Array(Math.round(this.duration))
      .fill(null)
      .map((_, index) => {
        const date =
          index % 7 === 0
            ? moment(startDate)
                .add(index, "days")
                .format("MM/DD")
            : null;

        return (
          <SprintDateColumn key={index} style={{ zIndex: date ? 1 : 0 }}>
            {date}
          </SprintDateColumn>
        );
      });
    } else {
      return (
        <SprintDateFullColumn style={{ zIndex: 0 }} />
      );
    }
  };

  handleSelectRufree = async (rufreeId: string | undefined) => {
    const { isPM } = this.injected.appStore.userStore;

    if(!rufreeId) {
      return;
    }

    await this.rufreeStore.fetchRufreeById(rufreeId);
    const rufree = this.rufreeStore.rufree;

    if (!rufree || !isPM) {
      return;
    }

    this.selectedRufree = rufree;
  };

  handleCloseRufreePopup = () => {
    this.selectedRufree = null;
  };

  handleCreateMatching = () => {};

  renderMatchingStatus = ({
    matching,
    inspectionId,
    role
  }: {
    matching: RufreeMatching | undefined;
    inspectionId: string;
    role: string;
  }) => {
    if (!matching) {
      // TODO : Set default values
      // 프로젝트 그룹번호, 프로젝트 명, 요청자(매니저), 해당 인력 검수서, 역할, 스프린트 당 비용, 예상 스프린트 개수 자동 기입
      const { projectGroup } = this.props;
      const { username, isPM } = this.injected.appStore.userStore;

      if (!isPM) {
        return null;
      }

      const handleClick = () => {
        const inspection = this.injected.appStore.inspectionStore.inspections.find(
          x => x.inspectionId === inspectionId
        );

        let sprintWage = 0;
        let sprintNum = 0;

        if (inspection) {
          const roleInstance = inspection.rufreeRoles.find(
            rufreeRole => rufreeRole.role === role
          );

          if (roleInstance) {
            sprintWage = roleInstance.wage - 300000;
            sprintNum = roleInstance.totalSprintDays;
          }
        }

        this.injected.appStore.rufreeMatchingFormStore.setDefaultValue({
          projectGroupId: projectGroup.groupId,
          projectName: projectGroup.name,
          pGStartOrNot: false,
          manager: username,
          role,
          macthingType: "PG",
          inspectionId,
          sprintWage,
          sprintNum
        });
      };

      return (
        <Link
          onClick={handleClick}
          to={`/project-groups/${this.props.projectGroup.groupId}/rufree-matchings/new`}
        >
          <Button intent={Intent.SUCCESS}>매칭</Button>
        </Link>
      );
    }

    if (matching.onRecommend) {
      return <MatchingStatus>추천요청중</MatchingStatus>;
    }

    if (!matching.matchingHistory.length) {
      return <MatchingStatus>매칭전</MatchingStatus>;
    }

    return (
      <MatchingStatus>매칭중 {matching.matchingHistory.length}</MatchingStatus>
    );
  };

  render() {
    const { schedule, rufreeRolesInInspection } = this.props;
    const rolesInspection: string[] = rufreeRolesInInspection.map(role => role.role);

    return (
      <Wrapper>

          <Section>
            <div>
              <ScheduleRow>
                <ScheduleColumn />
                <ScheduleColumn />
              </ScheduleRow>

              {
                schedule.rufreeSchedules.map(rufreeSchedule => {
                  const matching = this.props.projectGroup.rufreeMatchings.find(
                    x =>
                      x.role === rufreeSchedule.role &&
                      x.inspections === rufreeSchedule.inspectionId
                  );

                  return (
                    <ResourceRow key={rufreeSchedule.id}>
                      <ScheduleColumn>
                        <Info>{ rufreeSchedule.role ? rolesInspection.includes(rufreeSchedule.role) ? rufreeSchedule.role : "" : "" }</Info>
                      </ScheduleColumn>

                      <ScheduleColumn>
                        <Info>
                          {rufreeSchedule.rufreeId ? (
                            <a
                              onClick={async () =>
                                await this.handleSelectRufree(rufreeSchedule.rufreeId)
                              }
                            >
                              { rufreeSchedule.rufreeName }
                            </a>
                          ) : (
                            this.renderMatchingStatus({
                              matching,
                              inspectionId: rufreeSchedule.inspectionId + "",
                              role: rufreeSchedule.role + ""
                            })
                          )}
                        </Info>
                      </ScheduleColumn>
                    </ResourceRow>
                  );
                })}

            </div>

            <ScrollArea>
            <div>
              <ScheduleRow>
                { this.renderDates() }
              </ScheduleRow>

              {
                schedule.rufreeSchedules.map(rufreeSchedule => {
                  // 각 알유프리 직무에 대한 매칭은 한개만 생성가능
                  const matching = this.props.projectGroup.rufreeMatchings.find(
                    x =>
                      x.role === rufreeSchedule.role &&
                      x.inspections === rufreeSchedule.inspectionId
                  );

                  return (
                    <ResourceRow key={rufreeSchedule.id}>
                      {Array(Math.round(this.duration))
                        .fill(null)
                        .map((_, index) => {
                          const currentDate = moment(schedule.dateStart).add(
                            index,
                            "days"
                          );

                          const sprintBars: any[] = [];

                          rufreeSchedule.sprints.map(sprint => {
                            const isSameDate =
                              sprint.isValid &&
                              currentDate.isSame(
                                moment(sprint.dateStart),
                                "days"
                              );

                            if (isSameDate) {
                              sprintBars.push(
                                <Tooltip
                                  key={sprint.id}
                                  content={
                                    <SprintTip>
                                      <div>
                                        {rufreeSchedule.rufreeName}{" "}
                                        {rufreeSchedule.role}{"의 S"}
                                        {sprint.sprintSequentialNumber+1}
                                      </div>
                                      <div>
                                        {moment(sprint.dateStart).format(
                                          "YYYY/MM/DD"
                                        )}
                                        ~
                                        {moment(sprint.dateEnd).format(
                                          "YYYY/MM/DD"
                                        )}
                                      </div>
                                    </SprintTip>
                                  }
                                  position={Position.TOP}
                                >
                                  <SprintBar
                                    duration={sprint.duration}
                                    closeLeft={true}
                                    closeRight={true}
                                  >
                                    {sprint.sprintName}{" "}
                                    {sprint.manager !== "-"
                                      ? sprint.manager + " 매니저"
                                      : ""}
                                  </SprintBar>
                                </Tooltip>
                              );
                            }
                          });

                          return (
                            <SprintColumn
                              key={index}
                              style={{ zIndex: sprintBars.length ? 1 : 0 }}
                            >
                              {sprintBars}
                            </SprintColumn>
                          );
                        })}
                    </ResourceRow>
                  );
                })}
            </div>
            </ScrollArea>
          </Section>
        <RufreeInfoPopup
          rufree={this.selectedRufree}
          onClose={this.handleCloseRufreePopup}
        />
      </Wrapper>
    );
  }
}

export default ProjectGroupSchedule;
