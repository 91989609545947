import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../../../store/AppStore";
import { H3, Tab, Tabs, TabId, Classes } from "@blueprintjs/core";
import { observable } from "mobx";
import { RouteComponentProps } from "react-router";
import RufreeCalculateListPanel from "../../panels/CalculateListPanel/RufreeCalculateListPanel";
import ParttimeCalculateListPanel from "../../panels/CalculateListPanel/ParttimeCalculateListPanel";
import ClientCalculateListPanel from "../../panels/CalculateListPanel/ClientCalculateListPanel";
import ServiceListPanel from "../../panels/CalculateListPanel/ServiceListPanel";
import CalculateListByProjectPanel from "../../panels/CalculateListPanel/CalculateListByProjectPanel";
import LargeContainer from "../../atoms/LargeContainer/LargeContainer";


interface CalculatePageProps extends RouteComponentProps {}

interface InjectedProps extends CalculatePageProps {
  appStore: AppStore;
}

const CalculateContainer = styled.div`
  width: 1550px;
  padding-bottom: 40px;
`;



@inject("appStore")
@observer
class CalculatePage extends Component<CalculatePageProps> {
  @observable currentTab: TabId = "sprint";
  @observable isSupportPM = false;

  get injected() {
    return this.props as InjectedProps;
  }

  async componentDidMount() {
    await this.injected.appStore.pmUserStore.fetchPMUsers();
    const { isLoggedIn, email, userId, isPM } = this.injected.appStore.userStore;

    if(isPM && email) {
      const pmUser = await this.injected.appStore.pmUserStore.pmByEmail(email);
      if(pmUser) {
        this.isSupportPM = pmUser.is_support;
      }
    }

    const tabId = this.props.location.search.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function(str, key, value) {
        return (key === 'tab') ? value : '';
      }
    );
    this.currentTab = tabId ? tabId : this.currentTab;
  }

  handleChangeTab = (navbarTabId: TabId) => {
    this.currentTab = navbarTabId;
  };

  render() {

    return (
      <CalculateContainer>
        <H3>정산</H3>

        <Tabs
          id="CalculateTabs"
          onChange={this.handleChangeTab}
          selectedTabId={this.currentTab}
          renderActiveTabPanelOnly={true}
        >
          <Tab
            id="sprint"
            title="스프린트정산"
            panel={<RufreeCalculateListPanel isSupportPM={this.isSupportPM} />}
          />

          <Tab
            id="parttime"
            title="시간제태스크"
            panel={<ParttimeCalculateListPanel isSupportPM={this.isSupportPM} />}
          />

          <Tab
            id="deposit"
            title="입금내역"
            panel={<ClientCalculateListPanel isSupportPM={this.isSupportPM} />}
          />

          <Tab
            id="service"
            title="서비스비용내역"
            panel={<ServiceListPanel />}
          />

          <Tab
            id="project"
            title="프로젝트종료 정산"
            panel={<CalculateListByProjectPanel />}
          />

          <Tabs.Expander />

        </Tabs>

      </CalculateContainer>
    );
  }
}

export default CalculatePage;
